import * as THREE from "three";
import { vectorLength } from "../../utilities/conversions";
import { BACKGROUND_CLEAR_DEPTH, BACKGROUND_RENDER_ORDER, RSUN } from "../../utilities/three-constants";
import VisualElement from "./VisualElement";
export class ThreeImage extends VisualElement {
  private type: string;
  mesh: THREE.Mesh;
  meshPromise: Promise<THREE.Mesh>;
  private rotationSpeedsRadians: Float32Array;
  private isHiddenInsideGlobe = false;
  private startingPositions: Float32Array;
  private material: THREE.MeshBasicMaterial;
  private uvTexture;
  private opacity: number;
  threeObservationReference;

  constructor({ type, name, q, uvTexture, scene, threeObservationReference }) {
    super(q, name, scene, threeObservationReference);
    this.type = type;
    this.opacity = 1.0;
    if (this.type === "corona") {
      this.opacity = 0.99;
      if (this.name.includes("XRT-REAL-ISH")) {
        this.opacity = 0.3;
      }
    }
    this.uvTexture = uvTexture;
    this.threeObservationReference = threeObservationReference;
  }

  public setImageLayer = (newLayer, oldB) => {
    if (!this.mesh) {
      return;
    }
    if (this.type === "corona") {
      this.coronaRegenerateStartingPositionsFromVertices0(); // Must be flattened from original, then oberver position reapplied
    } else {
      this.surfaceRescaleStartingPositions(this.q.b / oldB);
    }
    this.mesh.renderOrder = newLayer;
    this.applyDeltaTimeDays(0, { reapply: true });

    this.mesh.geometry.attributes.position.needsUpdate = true;
  };

  //! Mesh should always be on canvas if visible,
  //! and never be on canvas if not visible

  private setVisible = () => {
    if (this.isHiddenInsideGlobe) {
      return;
    }
    if (!this.mesh) {
      this.createMesh();
    }
    const isInScene = this.scene.getObjectByName(this.mesh.name);
    if (isInScene) {
      return;
    }
    // Needs to be in this order, b/c applyDeltaTimeDays does not take
    // effect when mesh.visible = false
    this.mesh.visible = true;
    this.applyDeltaTimeDays(0, { reapply: true });
    this.scene.add(this.mesh);
  };

  private setInvisible = () => {
    if (this.isHiddenInsideGlobe || !this.mesh) {
      // Would never have been added
      return;
    }
    // Mesh exists and should be taken off if it is present
    const isInScene = !!this.scene.getObjectByName(this.mesh.name);
    if (!isInScene) {
      return;
    }
    this.scene.remove(this.mesh);
    this.mesh.visible = false;
    return;
  };

  public setImageVisibility = (visible: boolean) => {
    if (!visible) {
      this.setInvisible();
    } else {
      this.setVisible();
    }
  };

  public setUvTexture = (uvTexture: THREE.Texture) => {
    if (this.isHiddenInsideGlobe) {
      return;
    }
    this.uvTexture = uvTexture;
    if (!this.mesh) {
      this.createMesh();
      console.log(`ThreeImage.setUvTexture: mesh created for ${this.name} at ${this.q.layer}`);
      return;
    }
    this.material.map = uvTexture;
    this.material.needsUpdate = true;
  };

  dispose = () => this.disposables.forEach(d => d.dispose());

  public applyDeltaTimeDays = (deltaTimeDays, { reapply } = { reapply: false }) => {
    if (this.isHiddenInsideGlobe) {
      return;
    }
    deltaTimeDays = reapply ? this.currentDeltaTimeDays : deltaTimeDays;
    const shouldApply = reapply || this.currentDeltaTimeDays !== deltaTimeDays;
    if (!shouldApply) {
      return;
    }
    if (this.mesh?.visible) {
      const { mesh, startingPositions, rotationSpeedsRadians } = this;
      this.applyDifferentialRotation(mesh, startingPositions, rotationSpeedsRadians, deltaTimeDays);
    }
    this.currentDeltaTimeDays = deltaTimeDays;
  };

  private shrinkVerticesToLimb = vertices => {
    const verticesLength = vertices.length;
    for (let i = 0; i < verticesLength; i += 3) {
      const [x, y] = [vertices[i], vertices[i + 1]];
      const R = vectorLength([x, y, 0]);
      if (R > RSUN) {
        const shrinkFactor = RSUN / R;
        vertices[i] *= shrinkFactor;
        vertices[i + 1] *= shrinkFactor;
      }
    }
    return vertices;
  };

  private applyObserverPositionToVertices = vertices => {
    const numVertices = vertices.length;
    const pointVector = new THREE.Vector3();
    for (let i = 0; i < numVertices; i += 3) {
      pointVector.fromArray(vertices, i);
      pointVector.applyMatrix4(this.q.observerPositionMatrix);
      pointVector.toArray(vertices, i);
    }
    return vertices;
  };

  private createMaterial = () => {
    // TODO: uvTexture should be loaded async (see constructor() at the top)
    const material = new THREE.MeshBasicMaterial({
      map: this.uvTexture,
      side: THREE.DoubleSide,
      transparent: this.opacity < 1,
      opacity: this.opacity
    });
    this.disposables.push(material, this.uvTexture);
    return material;
  };

  private flattenCoronaVertices = vertices => {
    const verticesLength = vertices.length;
    let isOutsideGlobe = false;
    for (let i = 0; i < verticesLength; i += 3) {
      vertices[i + 2] = this.q.coronaPlaneZCoord;
      isOutsideGlobe = isOutsideGlobe || vectorLength([vertices[i], vertices[i + 1], vertices[i + 2]]) > RSUN;
    }
    this.isHiddenInsideGlobe = !isOutsideGlobe;
    return vertices;
  };

  private coronaRegenerateStartingPositionsFromVertices0 = () => {
    let vertices = this.q.computeImageVertices0.slice();
    vertices = this.flattenCoronaVertices(vertices); // With this.q set to new values, snaps to correct Z coordinates
    vertices = this.applyObserverPositionToVertices(vertices);
    this.startingPositions = vertices;
  };

  // To change surface layer, rescale all three coordinates by same factor
  private surfaceRescaleStartingPositions = factor => {
    for (let i = 0; i < this.startingPositions!.length; i++) {
      this.startingPositions![i] *= factor;
    }
  };

  private createMesh = () => {
    let vertices = this.q.computeImageVertices0.slice();
    if (this.type === "corona") {
      vertices = this.flattenCoronaVertices(vertices);
    } else {
      vertices = this.shrinkVerticesToLimb(vertices);
    }
    if (this.isHiddenInsideGlobe) {
      return;
    }
    this.material = this.createMaterial();
    vertices = this.applyObserverPositionToVertices(vertices);
    const geometry = this.assembleThreeGeometry(vertices, this.q.computeImageUvs0, this.q.indices);
    geometry.name = this.name + " geometry";
    geometry.userData.threeObservation = this.threeObservationReference;
    this.mesh = new THREE.Mesh(geometry, this.material);
    this.mesh.renderOrder = this.q.layer;
    if (this.threeObservationReference.props.isBackground) {
      this.mesh.renderOrder = BACKGROUND_RENDER_ORDER;
      this.mesh.onBeforeRender = renderer => (BACKGROUND_CLEAR_DEPTH ? renderer.clearDepth() : null);
    }
    this.mesh.name = this.name;
    this.mesh.userData.threeObservation = this.threeObservationReference;
    this.startingPositions = Float32Array.from(this.mesh.geometry.attributes.position.array);
    this.rotationSpeedsRadians = this.q.differentialRotationSpeedsRadians(this.startingPositions);
  };
}
