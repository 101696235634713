import * as THREE from "three";
import { Raycaster } from "three";
import threeStore from "../../../../Stores/ThreeStore";

class RaycasterPickHelper {
  raycaster: Raycaster;
  previousList = [] as any[];
  callback;
  alwaysCallback: boolean;

  constructor(callback, { alwaysCallback }) {
    this.raycaster = new THREE.Raycaster();
    this.callback = callback;
    this.alwaysCallback = alwaysCallback;
  }

  lastNormalizedX;
  lastNormalizedY;
  lastScale;
  pick(normalizedPosition, scene, camera) {
    const undefinedPosition = normalizedPosition.x === null || normalizedPosition.y === null;
    const unchangedXPosition = normalizedPosition.x === this.lastNormalizedX;
    const unchangedYPosition = normalizedPosition.y === this.lastNormalizedY;
    const currentScale = threeStore.three?.threeCanvas.orbitControls.scale;
    const unchangedScale = currentScale === this.lastScale;
    if (undefinedPosition) {
      return;
    }
    if (!this.alwaysCallback && unchangedScale && unchangedXPosition && unchangedYPosition) {
      return;
    }
    this.lastNormalizedX = normalizedPosition.x;
    this.lastNormalizedY = normalizedPosition.y;
    this.lastScale = threeStore.three?.threeCanvas.camera.zoom;
    this.raycaster.setFromCamera(normalizedPosition, camera);
    const intersectedThings = this.raycaster.intersectObjects(scene.children);
    if (this.alwaysCallback) {
      this.callback(intersectedThings);
      return;
    }
    const lengthIsEqual = intersectedThings.length === this.previousList.length;
    const isSameList =
      lengthIsEqual && intersectedThings.every((thing, index) => thing.object === this.previousList[index].object);
    if (!isSameList) {
      this.previousList = intersectedThings;
      this.callback(intersectedThings);
    }
  }
}

export default RaycasterPickHelper;
