import { observable, action, configure, makeObservable } from "mobx";
import { isChromeOrOpera, macKeys, shutdownEvent } from "../Utilities/Utilities";
import myLocalStorage from "./MyLocalStorage";
import resultsStore from "./ResultsStore";
import threeStore from "./ThreeStore";
import File from "../classes/File";

configure({ enforceActions: "always" });

type GlobalKeyHandler = { id: string; function: (ev: any) => void };

export class AppStore {
  selectorTypes = () => ["IRIS/SJI", "IRIS/SPEC", "EIS", "XRT", "SOT/NB", "SOT/WB", "SOT/SP"];

  // SOT NB/WB will give zero files unless we go far back in time, so we don't bother having them
  // initially. This will also make the selector list less cluttered.
  defaultSelectorList = () => ["GLOBAL", "EIS"];

  globalOnMouseDownHandlers: GlobalKeyHandler[] = [];
  globalOnMouseUpHandlers: GlobalKeyHandler[] = [];
  globalOnMouseOverHandlers: GlobalKeyHandler[] = [];
  globalOnMouseMoveHandlers: GlobalKeyHandler[] = [];
  globalOnMouseLeaveHandlers: GlobalKeyHandler[] = [];
  globalOnFocusHandlers: GlobalKeyHandler[] = [];
  globalKeyDownHandlers: GlobalKeyHandler[] = [];
  globalKeyUpHandlers: GlobalKeyHandler[] = [];
  globalOnScrollHandlers: GlobalKeyHandler[] = [];
  activeOverlays: any = new Map();
  hoverText: string = ``;
  hoveredTextIsInverted = false;
  hoverTextHookElement: any = null;
  contextMenuHookPoint = { x: 0, y: 0 };
  contextMenuHoveredFiles: File[] = [];
  contextMenuAllFiles: File[] = [];
  contextMenuLabels: any;

  constructor() {
    makeObservable(this);
  }

  @observable
  showShortcutsHelp = true;

  @observable
  sidebarIsCollapsed = false;

  @observable
  resultTableVisibleWidth = 0;

  @observable
  hoverTextIsActive = false;

  @observable
  selectionIsEnabled = true;

  @observable
  browserWarningIsActive = false;

  @observable
  contextMenuIsActive = false;

  @observable
  altKey = false;

  @observable
  shiftKey = false;

  @observable
  cmdKey = false;

  @observable
  mouseLocation: "canvas" | "results" | "normalSelector" | "globalCriteria" | "none" = "none";

  mouseIsOver = (place: "canvas" | "results" | "normalSelector" | "globalCriteria") => this.mouseLocation === place;

  @action mouseEnters = (place: "canvas" | "results" | "normalSelector" | "globalCriteria") =>
    (this.mouseLocation = place);
  @action mouseLeaves = (place: "canvas" | "results" | "normalSelector" | "globalCriteria" | "none") => {
    if (this.mouseLocation === place) this.mouseLocation = "none";
  };

  @action toggleShowShortcutsHelp = () => (this.showShortcutsHelp = !this.showShortcutsHelp);

  @action
  restoreSidebarStatusAfterReload() {
    if (this.sidebarIsCollapsed !== myLocalStorage.getSidebarStatus()) {
      this.sidebarIsCollapsed = myLocalStorage.getSidebarStatus();
    }
  }

  @action
  toggleSidebarIsCollapsed = () => {
    this.sidebarIsCollapsed = !this.sidebarIsCollapsed;
    myLocalStorage.storeSidebarStatus(this.sidebarIsCollapsed);
  };

  @action
  showHoverText = (text, elementId, inverted = false) => {
    this.hoverText = text;
    this.hoveredTextIsInverted = inverted;
    this.hoverTextHookElement = document.getElementById(elementId);
    this.hoverTextIsActive = true;
  };

  @action
  hideHoverText = () => (this.hoverTextIsActive = false);

  @action
  enableSelection = () => (this.selectionIsEnabled = true);

  @action
  disableSelection = () => (this.selectionIsEnabled = false);

  @action
  showBrowserWarning = () => (this.browserWarningIsActive = true);

  @action
  hideBrowserWarning = () => (this.browserWarningIsActive = false);

  @action
  public showContextMenu = () => (this.contextMenuIsActive = true);

  @action
  public hideContextMenu = () => {
    this.contextMenuIsActive = false;
    resultsStore.clearHoveredStatus();
  };

  handleGlobalOnMouseDown = ev => this.globalOnMouseDownHandlers.forEach(handler => handler.function?.(ev));

  handleGlobalOnMouseUp = ev => this.globalOnMouseUpHandlers.forEach(handler => handler.function?.(ev));

  handleGlobalOnMouseMove = ev => this.globalOnMouseMoveHandlers.forEach(handler => handler.function?.(ev));

  handleGlobalOnMouseOver = ev => this.globalOnMouseOverHandlers.forEach(handler => handler.function?.(ev));

  handleGlobalOnFocus = ev => this.globalOnFocusHandlers.forEach(handler => handler.function?.(ev));

  //! To ensure atomicity wrt. history, plus making all of the reactions visible in AppStore (UX),
  //! performActionOnTargetFiles should take (files, setStatus [singular], unsetStatuses [plural])
  performActionOnTargetFiles(targetFiles: any[], action: string, { clearSelection } = { clearSelection: false }) {
    if (this.altKey) {
      resultsStore.removeStatusFromFiles(targetFiles, action);
      return; // No further changes/clearing of other statuses
    }
    resultsStore.setStatusForFiles(targetFiles, action);
    if (clearSelection) {
      resultsStore.removeStatusFromFiles(targetFiles, "selected");
    }
  }

  metaKeyDownActions = () => {
    resultsStore.cmdKeyTemporarilyShowImageIsActive = true;
    if (resultsStore.currentResultsRowFile) {
      threeStore.three.showImageByFile(
        resultsStore.currentResultsRowFile,
        resultsStore.currentResultsRowFile.selectionImageUrl
      );
    }
  };

  @action
  handleGlobalKey = ev => {
    const { cmdShiftKey, onlyCmdKey, directionalFullCleanedCode, direction } = macKeys(ev);

    // The callbacks typically handle Escape to cancel overlays
    const handlersToCall = direction === "down" ? this.globalKeyDownHandlers : this.globalKeyUpHandlers;
    handlersToCall.forEach(handler => {
      console.log(`Keyhandler(${direction}): ` + handler.id, directionalFullCleanedCode, handler.function.name);
      handler.function?.(ev);
    });

    if (document.activeElement?.tagName === "INPUT" || document.activeElement?.tagName === "TEXTAREA") {
      return;
    }

    let synonymedDirectionalFullCleanedCode = directionalFullCleanedCode.replace(/Shift-[?]/, "?");
    console.log("synonymedDirectionalFullCleanedCode: " + synonymedDirectionalFullCleanedCode);

    let targetFiles = resultsStore.computeHoveredFilesForCurrentMouseOverPosition;
    const allApplicableFiles = resultsStore.computeAllApplicableFilesForCurrentMousePosition;
    if (onlyCmdKey) {
      targetFiles = allApplicableFiles.filter(file => file.selected);
    }
    if (cmdShiftKey) {
      targetFiles = allApplicableFiles.filter(file => !file.selected);
    }

    const firstTargetFile = targetFiles?.[0];

    const actions = {
      "Shift.down": () => (this.shiftKey = true),
      "Shift.up": () => (this.shiftKey = false),
      "Alt.down": () => (this.altKey = true),
      "Alt.up": () => (this.altKey = false),
      "Meta.down": () => {
        this.cmdKey = true;
        this.metaKeyDownActions();
      },
      "Meta.up": () => (this.cmdKey = false),
      "s.down": () => this.performActionOnTargetFiles(targetFiles, "cart"),
      "Cmd-s.down": () => this.performActionOnTargetFiles(targetFiles, "cart", { clearSelection: true }),
      "h.down": () => this.performActionOnTargetFiles(targetFiles, "hidden", { clearSelection: true }),
      "Cmd-h.down": () => this.performActionOnTargetFiles(targetFiles, "hidden", { clearSelection: true }),
      "d.down": () => this.performActionOnTargetFiles(targetFiles, "trash", { clearSelection: true }),
      "Cmd-d.down": () => this.performActionOnTargetFiles(targetFiles, "trash", { clearSelection: true }),
      "Cmd-z.down": () => resultsStore.undoFileChange(),
      "Cmd-y.down": () => resultsStore.redoFileChange(),
      "Cmd-a.down": () => resultsStore.toggleSelected(allApplicableFiles),
      "v.down": () => threeStore.three.centreViewOnObserverByFile(firstTargetFile),
      "?.down": () => resultsStore.showHelpOverlay(),
      "1.down": () => this.handlePinUnpinTime()
    };

    const action = actions[synonymedDirectionalFullCleanedCode];
    if (action) {
      action();
      shutdownEvent(ev);
    }

    threeStore.three.updateCanvasVisuals();
  };

  handleGlobalScroll = ev => this.globalOnScrollHandlers.forEach(handler => handler.function?.(ev));

  handleGlobalOnMouseLeave = ev => this.globalOnMouseLeaveHandlers.forEach(handler => handler.function?.(ev));

  handleResize = ev => resultsStore?.resultsComponentForForcedUpdate.forceUpdate();

  removeGlobalHandlers = id => {
    console.log("removeGlobalHandlers: " + id);
    this.globalOnMouseDownHandlers = this.globalOnMouseDownHandlers.filter(handler => handler.id !== id);
    this.globalOnMouseUpHandlers = this.globalOnMouseUpHandlers.filter(handler => handler.id !== id);
    this.globalOnMouseOverHandlers = this.globalOnMouseOverHandlers.filter(handler => handler.id !== id);
    this.globalOnMouseMoveHandlers = this.globalOnMouseMoveHandlers.filter(handler => handler.id !== id);
    this.globalOnMouseLeaveHandlers = this.globalOnMouseLeaveHandlers.filter(handler => handler.id !== id);
    this.globalOnFocusHandlers = this.globalOnFocusHandlers.filter(handler => handler.id !== id);
    this.globalKeyUpHandlers = this.globalKeyUpHandlers.filter(handler => handler.id !== id);
    this.globalKeyDownHandlers = this.globalKeyDownHandlers.filter(handler => handler.id !== id);
    this.globalOnScrollHandlers = this.globalOnScrollHandlers.filter(handler => handler.id !== id);
  };

  deactivateAllOverlays = () => {
    this.activeOverlays.forEach((unregisterCallback, key) => {
      unregisterCallback();
      this.activeOverlays.delete(key);
    });
  };

  someOverlayIsActive = () => this.activeOverlays.size > 0;

  checkBrowser = () => {
    if (!isChromeOrOpera()) {
      this.showBrowserWarning();
    }
  };

  setContextMenuData = (x, y, allFiles, hoveredFiles) => {
    this.contextMenuHookPoint = { x, y };
    this.contextMenuAllFiles = allFiles;
    this.contextMenuHoveredFiles = hoveredFiles;
  };

  handleCentreMove = () => threeStore.three.centreViewOnObserverByFile(null);

  handlePinUnpinTime = () => {
    if (this.mouseLocation !== "canvas" && this.mouseLocation !== "results") {
      return;
    }
    const hoveredFile = resultsStore.computeHoveredFilesForCurrentMouseOverPosition[0] || null;
    if (hoveredFile) {
      resultsStore.handlePinUnpinTime(hoveredFile);
    }
  };
}

const appStore = new AppStore();
export default appStore;
