import React from "react";
import { observer } from "mobx-react";
import { configure } from "mobx";
import { v4 as uuidv4 } from "uuid";

import resultsStore from "../../Stores/ResultsStore";
import Button from "../../Utilities/Button";
import { InvertSelectionButton, SortButton } from "../../Utilities/Buttons";
import { fullUrl, isColumnSortable } from "../../Utilities/Utilities";
configure({ enforceActions: "always" });

@observer
class ResultsHeader extends React.Component<{ resultsTableColumns: Array<any> }> {
  handleDragStart = headerName => {
    resultsStore.setDraggedColumn(headerName);
  };

  // See https://codeburst.io/lets-build-dnd-column-reordering-in-an-html-table-w-react-hooks-eef7d9da5a07
  handleDragOver = (e, headerName) => {
    if (resultsStore.dragoverColumn !== headerName) {
      resultsStore.setDragOverColumn(headerName);
    }
    e.preventDefault();
  };

  handleDrop = headerName => {
    resultsStore.dropOnColumn(headerName);
  };

  handleDragEnd = (e, headerName) => {
    if (e.dataTransfer.dropEffect === "none") {
      resultsStore.cancelDragging();
    }
  };

  dragProps = (draggable, headerName) => {
    if (!draggable) {
      return null;
    }
    return {
      draggable: true,
      onDragStart: e => this.handleDragStart(headerName),
      onDragOver: e => this.handleDragOver(e, headerName),
      onDrop: e => this.handleDrop(headerName),
      onDragEnd: e => this.handleDragEnd(e, headerName),
      style: { cursor: "grab" }
    };
  };

  headerCell = (header, draggable) => {
    const headerClassName = header.name === resultsStore.dragoverColumn ? " dragover" : "";
    const fullUrlFromPath = fullUrl("icons/drag-drop.png");
    const dragDropArrow = draggable ? (
      <div style={{ width: "100%" }}>
        {" "}
        <img style={{ height: "20px", cursor: "grab" }} src={fullUrlFromPath} alt="Drag and drop" />
      </div>
    ) : null;
    const dragProps = this.dragProps(draggable, header.name);
    const deleteBox = {
      id: "close-column-icon",
      path: "icons/close-column.png",
      name: "Remove column",
      onClick: e => resultsStore.removeColumn(header.name),
      height: "10px"
    };
    const deleteButton = name => <Button {...deleteBox} />;
    const isSortable = isColumnSortable(header.name);
    return (
      <th key={header.name} className={headerClassName} {...dragProps}>
        <div>
          <div>
            <div className="header-top-line">
              <div style={{ width: "100%" }}>{header.description}</div>
              &nbsp;
              {deleteButton(header.name)}
            </div>
            <div className="base-flexbox-row" style={{ justifyContent: "space-between" }}>
              {dragDropArrow}
              {isSortable ? <SortButton columnName={header.name} /> : null}
            </div>
          </div>
        </div>
      </th>
    );
  };

  actionsHeaderCell = () => {
    let iconPath = "icons/indeterminate-checkbox.png";
    if (resultsStore.computeNumberOfSelectedFiles === resultsStore.computeFilteredData.length) {
      iconPath = "icons/checked.png";
    }
    if (resultsStore.computeNumberOfSelectedFiles === 0) {
      iconPath = "icons/unchecked.png";
    }
    const checkbox = {
      id: "checkbox-icon",
      path: iconPath,
      name: "Select/unselect all files",
      onClick: resultsStore.toggleAllFilesSelectionStatus,
      height: "20px"
    };
    const sticky: React.CSSProperties = { position: "sticky", left: 0, backgroundColor: "white", zIndex: "5" };

    return (
      <th key={uuidv4()} style={sticky}>
        <div>
          <div>
            <div style={{ width: "100%" }} className="actionsHeader">
              <Button {...checkbox} />
              <InvertSelectionButton />
              Actions
            </div>
          </div>
        </div>
      </th>
    );
  };

  renderBlankHeaderColumn = () => <th className="empty-status-column"></th>;
  render() {
    const { resultsTableColumns } = this.props;

    return (
      <tr id="resultsHeader">
        {this.actionsHeaderCell()}
        {resultsTableColumns.map(header => this.headerCell(header, true))}
      </tr>
    );
  }
}

export default ResultsHeader;
