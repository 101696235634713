import React from "react";
import Calendar from "react-calendar";
import searchStore from "../../Stores/SearchStore";
import appStore from "../../Stores/AppStore";
import { labeledUuidV4 } from "../../Utilities/Utilities";

export default class DatePicker extends React.Component {
  id = labeledUuidV4("date-picker-overlay");

  handleGlobalKeyUp = ev => {
    if (ev.key === "Escape") {
      searchStore.hideDatePickerOverlay();
    }
  };

  componentDidMount() {
    appStore.globalKeyUpHandlers.push({ id: this.id, function: this.handleGlobalKeyUp });
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
  }

  render() {
    if (
      !searchStore.datePickerOverlayHookElement ||
      !searchStore.datePickerOverlayIsActive ||
      !searchStore.dataForDatePickerOverlay
    ) {
      return null;
    }
    const { minSelectableDate, startDate, onClickDay } = searchStore.dataForDatePickerOverlay;
    const geometry = searchStore.datePickerOverlayHookElement.getBoundingClientRect();
    const top = geometry?.top + 30;
    const left = geometry?.left;
    return (
      <div id="date-picker-overlay" style={{ top: `${top}px`, left: `${left}px` }} className="date-picker-overlay">
        <Calendar value={startDate} onClickDay={onClickDay} minDate={minSelectableDate} />
      </div>
    );
  }
}
