import React from "react";
import { observer } from "mobx-react";
import searchStore from "../../Stores/SearchStore";
import { baseUrl, glob2Regex, macKeys } from "../../Utilities/Utilities";
import { action, makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";

import appStore from "../../Stores/AppStore";

@observer
class OptionsListOverlay extends React.Component {
  id = uuidv4();
  componentDestroyInProgress = false;
  valuesFilterRegex;

  @observable
  valuesFilter = "";

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable
  showOnlySelectedValues = false;

  @action
  setValuesFilter = value => {
    this.valuesFilter = value;
  };

  componentDidMount() {
    const OptionsListIsFetched = searchStore.enumCriteriaValues[searchStore.criterionForOverlay.composedName];
    if (!OptionsListIsFetched) {
      searchStore.criterionForOverlay.fetchEnumValuesIfNeeded();
    }
    appStore.globalOnMouseDownHandlers.push({ id: this.id, function: this.handleGlobalMouseDown });
    appStore.globalOnFocusHandlers.push({ id: this.id, function: this.handleGlobalOnFocus });
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
    searchStore.onBlurActionForEnumOverlay();
  }

  handleOnClick = (ev, element) => {
    const { cmdKey } = macKeys(ev);
    const criterion = searchStore.criterionForOverlay;
    const value = element.value;
    if (cmdKey) {
      criterion.removeValue(value);
      return;
    }
    if (criterion.values.some(currentValue => currentValue === value)) {
      criterion.removeValue(value);
    } else {
      criterion.values.push(value);
    }
  };

  @action
  handleToggleSelected = ev => {
    this.showOnlySelectedValues = !this.showOnlySelectedValues;
  };

  handleChangeValue = ev => {
    this.setValuesFilter(ev.target.value);
    this.valuesFilterRegex = glob2Regex(ev.target.value);
  };

  handleKeyDown = ev => {
    if (ev.key === "Escape") {
      searchStore.hideOptionsListOverlay();
    }
  };

  clearSelected = ev => {
    if (searchStore.lastEditedInputField) {
      document.getElementById(searchStore.lastEditedInputField)?.focus();
    }
    searchStore.criterionForOverlay.clearValues();
  };

  selectAllShown = () => {
    const visibleOptionsList = searchStore.enumCriteriaValues[searchStore.criterionForOverlay.composedName].filter(
      this.optionIsVisible
    );
    visibleOptionsList.forEach(option => {
      searchStore.criterionForOverlay.addValue(option.value);
    });
  };

  handleGlobalMouseDown = ev => {
    const myDiv = document.getElementById(this.id);
    if (this.componentDestroyInProgress || !myDiv) {
      return;
    }
    const isChildElement = myDiv?.contains(ev.target);
    const isSourceElement = searchStore.sourceElementForEnumOverlay.contains(ev.target);
    if (!isChildElement && !isSourceElement) {
      this.componentDestroyInProgress = true;
      searchStore.hideOptionsListOverlay();
    }
  };

  handleGlobalOnFocus = ev => {
    const myDiv = document.getElementById(this.id);
    if (this.componentDestroyInProgress || !myDiv) {
      return;
    }
    const isChildElement = myDiv?.contains(ev.target);
    const isSourceElement = searchStore.sourceElementForEnumOverlay.contains(ev.target);
    if (!isChildElement && !isSourceElement) {
      this.componentDestroyInProgress = true;
      searchStore.hideOptionsListOverlay();
    }
  };

  optionIsVisible = option => {
    const optionMatchesFilter = this.valuesFilter === "" || option.value.match(this.valuesFilterRegex) !== null;
    if (!optionMatchesFilter) {
      return false;
    }
    const optionIsSelected = searchStore.criterionForOverlay.values.indexOf(option.value) !== -1;
    if (!optionIsSelected && this.showOnlySelectedValues) {
      return false;
    }
    return true;
  };

  renderVisibleElement = option => {
    const optionIsSelected = searchStore.criterionForOverlay.values.indexOf(option.value) !== -1;
    return (
      <div
        key={option.value}
        onClick={ev => this.handleOnClick(ev, option)}
        className={optionIsSelected ? "options-list-item-selected" : "options-list-item"}
      >
        {option.value || "<blank>"}
      </div>
    );
  };

  render() {
    if (!searchStore.optionsListOverlayIsActive) {
      return null;
    }
    if (!searchStore.criterionForOverlay) {
      return null;
    }
    const OptionsListIsFetched = searchStore.enumCriteriaValues[searchStore.criterionForOverlay.composedName];
    const optionsList = OptionsListIsFetched
      ? searchStore.enumCriteriaValues[searchStore.criterionForOverlay.composedName]
      : [];
    const visibleOptionsList = optionsList.filter(this.optionIsVisible);
    const visibleOptions = visibleOptionsList.map(this.renderVisibleElement);
    const buttonTitle = this.showOnlySelectedValues ? " SHOW ALL VALUES" : "SHOW SELECTED VALUES";
    return (
      <div className="options-list-div" id={this.id}>
        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
          <button onClick={this.handleToggleSelected} className="click-button" style={{ width: "220px" }}>
            {buttonTitle}
          </button>
          <button onClick={this.selectAllShown} className="click-button" style={{ width: "150px" }}>
            SELECT ALL SHOWN
          </button>
          <button onClick={this.clearSelected} className="click-button" style={{ width: "150px" }}>
            CLEAR SELECTIONS
          </button>
          <div style={{ textAlign: "right" }}>
            <img
              className="click-button close-icon"
              src={baseUrl() + "/icons/close.png"}
              alt="X"
              onClick={searchStore.hideOptionsListOverlay}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <input
            placeholder=" FILTER VALUES"
            id="filter-for-values"
            type="text"
            value={this.valuesFilter}
            className="criterion"
            onChange={this.handleChangeValue}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        <div className="options-list-overlay">{visibleOptions}</div>
      </div>
    );
  }
}
export default OptionsListOverlay;
