import React, { Component } from "react";
import Modal from "react-overlays/Modal";
import appStore from "../../Stores/AppStore";
import { baseUrl, labeledUuidV4 } from "../../Utilities/Utilities";
import { observer } from "mobx-react";

@observer
export default class HelpOverlay extends Component {
  id = labeledUuidV4("browser-warning-overlay");

  componentDidMount() {
    appStore.globalOnMouseDownHandlers.push({ id: this.id, function: this.handleGlobalMouseOrKeyDown });
    appStore.globalKeyDownHandlers.push({ id: this.id, function: this.handleGlobalMouseOrKeyDown });
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
  }

  handleGlobalMouseOrKeyDown = ev => appStore.hideBrowserWarning();
  handleKeyDown = ev => appStore.hideBrowserWarning();

  renderBackdrop = props => <div className="backdrop" {...props} />;

  render() {
    return (
      <Modal
        className="centered-overlay help-overlay"
        show
        renderBackdrop={this.renderBackdrop}
        aria-labelledby="modal-label"
        onKeyDown={this.handleKeyDown}
      >
        <div>
          <div style={{ textAlign: "right" }}>
            <img
              className="click-button close-icon"
              src={baseUrl() + "/icons/close.png"}
              alt="X"
              onClick={appStore.hideBrowserWarning}
            />
          </div>
          <h1>Browser warning</h1>
          <p>Please use a browser such as Chrome or Opera.</p>
        </div>
      </Modal>
    );
  }
}
