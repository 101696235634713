import React from "react";
import { observer } from "mobx-react";
import { configure } from "mobx";

import resultsStore from "../../Stores/ResultsStore";
import ManageColumnsOverlay from "./ManageColumnsOverlay";
import {
  CartButton,
  CollapseLeftPanButton,
  DownloadButton,
  HelpButton,
  HiddenButton,
  ManageColumnsButton,
  SelectedButton,
  SettingsButton,
  SortOrderButton,
  TrashButton,
  UnmarkedButton
} from "../../Utilities/Buttons";

configure({ enforceActions: "always" });

@observer
class ResultsButtonMenu extends React.Component<{}> {
  button = props => (
    <img
      fetchpriority="low"
      loading="lazy"
      id={props.id}
      className={props.class}
      src={props.path}
      alt={props.alt}
      onClick={props.onClick}
    />
  );

  showHelp = () => {
    resultsStore.showHelpOverlay();
  };

  manageColumns = () => {
    resultsStore.showManageColumnsOverlay();
  };

  manageColumnsOverlayIfActive = () => {
    return resultsStore.manageColumnsOverlayIsActive && <ManageColumnsOverlay />;
  };

  render() {
    return (
      <div id="icon-container">
        <CollapseLeftPanButton />
        <SettingsButton />
        <HelpButton />
        <SelectedButton />
        <UnmarkedButton />
        <CartButton />
        <HiddenButton />
        <TrashButton />
        <DownloadButton />
        <ManageColumnsButton />
        <SortOrderButton />
        {this.manageColumnsOverlayIfActive()}
      </div>
    );
  }
}

export default ResultsButtonMenu;
