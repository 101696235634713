import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

import { InputNumberParameterProps } from "../Interfaces";
configure({ enforceActions: "always" });

@observer
class InputStringParameter extends React.Component<InputNumberParameterProps> {
  render() {
    const excludedClassName = this.props.criterion.excluded ? "criterion-input-excluded" : "";
    const className = `${this.props.criterion.type} ${this.props.criterion.afterPrefetchStatus} ${excludedClassName}`;
    return (
      <input
        type="text"
        placeholder={this.props.placeholder}
        value={this.props.value}
        className={className}
        onChange={this.props.onValueChange}
        onBlur={this.props.onBlur}
      />
    );
  }
}
export default InputStringParameter;
