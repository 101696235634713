import React, { CSSProperties } from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";
import resultsStore from "../../Stores/ResultsStore";
import searchStore from "../../Stores/SearchStore";
import { macKeys } from "../../Utilities/Utilities";
import threeStore from "../../Stores/ThreeStore";

configure({ enforceActions: "always" });

@observer
class FullSizeImageOverlay extends React.Component {
  private renderImage() {
    const imageUrl = resultsStore.fullSizeImageUrl;
    const topPosition = Math.max((window.innerHeight - 640) / 2, 20);
    const xPositionRight = window.innerWidth - resultsStore.iconImagesOverlayGeometry.x - 7;
    const xPositionLeft = resultsStore.iconImagesOverlayGeometry.right - 7;
    const imageOverlayGeometryRight: CSSProperties = {
      top: topPosition,
      left: xPositionLeft
    };
    const imageOverlayGeometryLeft: CSSProperties = {
      top: topPosition,
      right: xPositionRight
    };
    const geometry = searchStore.leftPanelCollapsed ? imageOverlayGeometryRight : imageOverlayGeometryLeft;
    return (
      <div>
        <img style={geometry} key={imageUrl} src={imageUrl} alt={imageUrl} className={"imageView"} />
      </div>
    );
  }

  handleOnClick = event => {
    const { onlyShiftKey } = macKeys(event);
    if (onlyShiftKey) {
      const thumbImageUrl = resultsStore.fullSizeImageUrl.replace(".jpg", "_thumb.png");
      resultsStore.overlayBoxFile.selectionImageUrl = thumbImageUrl;
      if (resultsStore.overlayBoxFile.selected) {
        threeStore.three.showImageByFile(resultsStore.overlayBoxFile, resultsStore.overlayBoxFile.selectionImageUrl);
      }
    }
  };

  render() {
    return resultsStore.fullSizeImageOverlayIsActive ? (
      <div onClick={this.handleOnClick} id="fullSizeImageOverlay">
        {this.renderImage()}
      </div>
    ) : null;
  }
}

export default FullSizeImageOverlay;
