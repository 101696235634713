import React, { CSSProperties } from "react";
import { configure } from "mobx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import appStore from "./Stores/AppStore";

import SearchSpan from "./components/SearchSpan/SearchSpan";
import ResultsDiv from "./components/ResultsSpan/ResultList";
import TopCommandBar from "./components/ResultsSpan/TopCommandBar";
import resultsStore from "./Stores/ResultsStore";
import HoverText from "./Utilities/HoverText";
import ContextMenu from "./components/ContextMenu";
import ShortcutsHelpOverlay from "./components/HelpComponents/ShortcutsHelpOverlay";
import FullSizeImageOverlay from "./components/ResultsSpan/FullSizeImageOverlay";
configure({ enforceActions: "always" });

class App extends React.Component<{}> {
  constructor(props) {
    super(props);
    appStore.restoreSidebarStatusAfterReload();
  }

  selectionChange = () => {
    const selection = window.getSelection();
    if (selection?.anchorNode?.parentElement !== selection?.focusNode?.parentElement) {
      selection?.empty();
    }
  };

  componentDidMount() {
    document.addEventListener("keyup", appStore.handleGlobalKey);
    document.addEventListener("keydown", appStore.handleGlobalKey);
    document.addEventListener("wheel", appStore.handleGlobalScroll);
    document.addEventListener("selectionchange", this.selectionChange);
    window.addEventListener("resize", appStore.handleResize);
    resultsStore.purgeOutdatedFiles();
    appStore.checkBrowser();
  }

  render() {
    const style = { userSelect: appStore.selectionIsEnabled ? "text" : "none" } as CSSProperties;
    return (
      <div
        style={style}
        onMouseDown={appStore.handleGlobalOnMouseDown}
        onMouseUp={appStore.handleGlobalOnMouseUp}
        onMouseOver={appStore.handleGlobalOnMouseOver}
        onMouseMove={appStore.handleGlobalOnMouseMove}
        onMouseLeave={appStore.handleGlobalOnMouseLeave}
        onFocus={appStore.handleGlobalOnFocus}
      >
        <FullSizeImageOverlay />
        <HoverText />
        <ToastContainer closeButton={false} position="top-right" pauseOnFocusLoss={false} hideProgressBar={true} />
        <ContextMenu />
        <ShortcutsHelpOverlay />
        <TopCommandBar />
        <div className="main-div">
          <SearchSpan />
          <div id="m-results-cell">
            <ResultsDiv />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
