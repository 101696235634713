export const ephemerisSoho = [
  { isoDate: "2006-10-17T00:00:00Z", dsun: 212.271391, hgln: 0.255021, hglt: 5.702608 },
  { isoDate: "2006-10-18T00:00:00Z", dsun: 212.222722, hgln: 0.255927, hglt: 5.625914 },
  { isoDate: "2006-10-19T00:00:00Z", dsun: 212.174138, hgln: 0.256478, hglt: 5.547524 },
  { isoDate: "2006-10-20T00:00:00Z", dsun: 212.125639, hgln: 0.256682, hglt: 5.467461 },
  { isoDate: "2006-10-21T00:00:00Z", dsun: 212.077224, hgln: 0.256548, hglt: 5.385748 },
  { isoDate: "2006-10-22T00:00:00Z", dsun: 212.028892, hgln: 0.256088, hglt: 5.302407 },
  { isoDate: "2006-10-23T00:00:00Z", dsun: 211.980643, hgln: 0.255315, hglt: 5.217461 },
  { isoDate: "2006-10-24T00:00:00Z", dsun: 211.932475, hgln: 0.25424, hglt: 5.130935 },
  { isoDate: "2006-10-25T00:00:00Z", dsun: 211.884391, hgln: 0.252879, hglt: 5.042853 },
  { isoDate: "2006-10-26T00:00:00Z", dsun: 211.836391, hgln: 0.251246, hglt: 4.95324 },
  { isoDate: "2006-10-27T00:00:00Z", dsun: 211.788479, hgln: 0.249354, hglt: 4.862122 },
  { isoDate: "2006-10-28T00:00:00Z", dsun: 211.74066, hgln: 0.247215, hglt: 4.769523 },
  { isoDate: "2006-10-29T00:00:00Z", dsun: 211.69294, hgln: 0.244841, hglt: 4.67547 },
  { isoDate: "2006-10-30T00:00:00Z", dsun: 211.645328, hgln: 0.242239, hglt: 4.579989 },
  { isoDate: "2006-10-31T00:00:00Z", dsun: 211.59783, hgln: 0.239413, hglt: 4.483108 },
  { isoDate: "2006-11-01T00:00:00Z", dsun: 211.550459, hgln: 0.236366, hglt: 4.384853 },
  { isoDate: "2006-11-02T00:00:00Z", dsun: 211.503223, hgln: 0.233092, hglt: 4.285253 },
  { isoDate: "2006-11-03T00:00:00Z", dsun: 211.456134, hgln: 0.229583, hglt: 4.184334 },
  { isoDate: "2006-11-04T00:00:00Z", dsun: 211.409205, hgln: 0.225825, hglt: 4.082127 },
  { isoDate: "2006-11-05T00:00:00Z", dsun: 211.362449, hgln: 0.2218, hglt: 3.978658 },
  { isoDate: "2006-11-06T00:00:00Z", dsun: 211.315878, hgln: 0.217488, hglt: 3.873957 },
  { isoDate: "2006-11-07T00:00:00Z", dsun: 211.269505, hgln: 0.21287, hglt: 3.768054 },
  { isoDate: "2006-11-08T00:00:00Z", dsun: 211.223351, hgln: 0.207929, hglt: 3.660978 },
  { isoDate: "2006-11-09T00:00:00Z", dsun: 211.17743, hgln: 0.202652, hglt: 3.552758 },
  { isoDate: "2006-11-10T00:00:00Z", dsun: 211.131758, hgln: 0.197032, hglt: 3.443425 },
  { isoDate: "2006-11-11T00:00:00Z", dsun: 211.086356, hgln: 0.191064, hglt: 3.333008 },
  { isoDate: "2006-11-12T00:00:00Z", dsun: 211.041243, hgln: 0.18475, hglt: 3.22154 },
  { isoDate: "2006-11-13T00:00:00Z", dsun: 210.996443, hgln: 0.178095, hglt: 3.10905 },
  { isoDate: "2006-11-14T00:00:00Z", dsun: 210.951979, hgln: 0.171108, hglt: 2.99557 },
  { isoDate: "2006-11-15T00:00:00Z", dsun: 210.907877, hgln: 0.163798, hglt: 2.88113 },
  { isoDate: "2006-11-16T00:00:00Z", dsun: 210.864165, hgln: 0.156179, hglt: 2.765763 },
  { isoDate: "2006-11-17T00:00:00Z", dsun: 210.820872, hgln: 0.148267, hglt: 2.649499 },
  { isoDate: "2006-11-18T00:00:00Z", dsun: 210.778027, hgln: 0.140079, hglt: 2.532371 },
  { isoDate: "2006-11-19T00:00:00Z", dsun: 210.73566, hgln: 0.131634, hglt: 2.414411 },
  { isoDate: "2006-11-20T00:00:00Z", dsun: 210.6938, hgln: 0.122955, hglt: 2.295651 },
  { isoDate: "2006-11-21T00:00:00Z", dsun: 210.652472, hgln: 0.114064, hglt: 2.176125 },
  { isoDate: "2006-11-22T00:00:00Z", dsun: 210.611702, hgln: 0.104988, hglt: 2.055864 },
  { isoDate: "2006-11-23T00:00:00Z", dsun: 210.571511, hgln: 0.095754, hglt: 1.934902 },
  { isoDate: "2006-11-24T00:00:00Z", dsun: 210.531921, hgln: 0.086392, hglt: 1.813274 },
  { isoDate: "2006-11-25T00:00:00Z", dsun: 210.492953, hgln: 0.076929, hglt: 1.691013 },
  { isoDate: "2006-11-26T00:00:00Z", dsun: 210.45463, hgln: 0.067395, hglt: 1.568153 },
  { isoDate: "2006-11-27T00:00:00Z", dsun: 210.416972, hgln: 0.057816, hglt: 1.444728 },
  { isoDate: "2006-11-28T00:00:00Z", dsun: 210.380002, hgln: 0.048215, hglt: 1.320774 },
  { isoDate: "2006-11-29T00:00:00Z", dsun: 210.34374, hgln: 0.038615, hglt: 1.196325 },
  { isoDate: "2006-11-30T00:00:00Z", dsun: 210.308207, hgln: 0.02903, hglt: 1.071416 },
  { isoDate: "2006-12-01T00:00:00Z", dsun: 210.27342, hgln: 0.019472, hglt: 0.946083 },
  { isoDate: "2006-12-02T00:00:00Z", dsun: 210.239397, hgln: 0.009947, hglt: 0.820362 },
  { isoDate: "2006-12-03T00:00:00Z", dsun: 210.206152, hgln: 0.000458, hglt: 0.694289 },
  { isoDate: "2006-12-04T00:00:00Z", dsun: 210.173698, hgln: -0.008998, hglt: 0.5679 },
  { isoDate: "2006-12-05T00:00:00Z", dsun: 210.142043, hgln: -0.018424, hglt: 0.441233 },
  { isoDate: "2006-12-06T00:00:00Z", dsun: 210.111196, hgln: -0.027825, hglt: 0.314325 },
  { isoDate: "2006-12-07T00:00:00Z", dsun: 210.081161, hgln: -0.037204, hglt: 0.187214 },
  { isoDate: "2006-12-08T00:00:00Z", dsun: 210.05194, hgln: -0.046562, hglt: 0.059938 },
  { isoDate: "2006-12-09T00:00:00Z", dsun: 210.023533, hgln: -0.055896, hglt: -0.067463 },
  { isoDate: "2006-12-10T00:00:00Z", dsun: 209.995939, hgln: -0.065202, hglt: -0.194952 },
  { isoDate: "2006-12-11T00:00:00Z", dsun: 209.969154, hgln: -0.074471, hglt: -0.322489 },
  { isoDate: "2006-12-12T00:00:00Z", dsun: 209.943241, hgln: -0.083694, hglt: -0.450034 },
  { isoDate: "2006-12-13T00:00:00Z", dsun: 209.918069, hgln: -0.092854, hglt: -0.577546 },
  { isoDate: "2006-12-14T00:00:00Z", dsun: 209.893687, hgln: -0.10194, hglt: -0.704987 },
  { isoDate: "2006-12-15T00:00:00Z", dsun: 209.870089, hgln: -0.110933, hglt: -0.832315 },
  { isoDate: "2006-12-16T00:00:00Z", dsun: 209.847268, hgln: -0.119816, hglt: -0.959489 },
  { isoDate: "2006-12-17T00:00:00Z", dsun: 209.825216, hgln: -0.12857, hglt: -1.08647 },
  { isoDate: "2006-12-18T00:00:00Z", dsun: 209.803927, hgln: -0.137172, hglt: -1.213216 },
  { isoDate: "2006-12-19T00:00:00Z", dsun: 209.783398, hgln: -0.145601, hglt: -1.339687 },
  { isoDate: "2006-12-20T00:00:00Z", dsun: 209.763625, hgln: -0.153831, hglt: -1.46584 },
  { isoDate: "2006-12-21T00:00:00Z", dsun: 209.744605, hgln: -0.161836, hglt: -1.591635 },
  { isoDate: "2006-12-22T00:00:00Z", dsun: 209.726335, hgln: -0.16959, hglt: -1.71703 },
  { isoDate: "2006-12-23T00:00:00Z", dsun: 209.708813, hgln: -0.177068, hglt: -1.841984 },
  { isoDate: "2006-12-24T00:00:00Z", dsun: 209.692035, hgln: -0.184243, hglt: -1.966454 },
  { isoDate: "2006-12-25T00:00:00Z", dsun: 209.675994, hgln: -0.191093, hglt: -2.090399 },
  { isoDate: "2006-12-26T00:00:00Z", dsun: 209.660686, hgln: -0.197598, hglt: -2.213776 },
  { isoDate: "2006-12-27T00:00:00Z", dsun: 209.646106, hgln: -0.203741, hglt: -2.336545 },
  { isoDate: "2006-12-28T00:00:00Z", dsun: 209.632252, hgln: -0.20951, hglt: -2.458663 },
  { isoDate: "2006-12-29T00:00:00Z", dsun: 209.619122, hgln: -0.214901, hglt: -2.580089 },
  { isoDate: "2006-12-30T00:00:00Z", dsun: 209.606718, hgln: -0.21991, hglt: -2.700782 },
  { isoDate: "2006-12-31T00:00:00Z", dsun: 209.595042, hgln: -0.224542, hglt: -2.8207 },
  { isoDate: "2007-01-01T00:00:00Z", dsun: 209.584099, hgln: -0.228805, hglt: -2.939803 },
  { isoDate: "2007-01-02T00:00:00Z", dsun: 209.573895, hgln: -0.232711, hglt: -3.05805 },
  { isoDate: "2007-01-03T00:00:00Z", dsun: 209.564436, hgln: -0.236273, hglt: -3.175401 },
  { isoDate: "2007-01-04T00:00:00Z", dsun: 209.555732, hgln: -0.239507, hglt: -3.291814 },
  { isoDate: "2007-01-05T00:00:00Z", dsun: 209.54779, hgln: -0.242428, hglt: -3.40725 },
  { isoDate: "2007-01-06T00:00:00Z", dsun: 209.54062, hgln: -0.245049, hglt: -3.521669 },
  { isoDate: "2007-01-07T00:00:00Z", dsun: 209.534233, hgln: -0.247383, hglt: -3.635031 },
  { isoDate: "2007-01-08T00:00:00Z", dsun: 209.528638, hgln: -0.249439, hglt: -3.747296 },
  { isoDate: "2007-01-09T00:00:00Z", dsun: 209.523858, hgln: -0.251225, hglt: -3.858435 },
  { isoDate: "2007-01-10T00:00:00Z", dsun: 209.519882, hgln: -0.252747, hglt: -3.968391 },
  { isoDate: "2007-01-11T00:00:00Z", dsun: 209.516733, hgln: -0.254008, hglt: -4.077135 },
  { isoDate: "2007-01-12T00:00:00Z", dsun: 209.514422, hgln: -0.25501, hglt: -4.184629 },
  { isoDate: "2007-01-13T00:00:00Z", dsun: 209.512963, hgln: -0.255754, hglt: -4.290834 },
  { isoDate: "2007-01-14T00:00:00Z", dsun: 209.512368, hgln: -0.256238, hglt: -4.395714 },
  { isoDate: "2007-01-15T00:00:00Z", dsun: 209.512652, hgln: -0.256459, hglt: -4.499232 },
  { isoDate: "2007-01-16T00:00:00Z", dsun: 209.51383, hgln: -0.256408, hglt: -4.601352 },
  { isoDate: "2007-01-17T00:00:00Z", dsun: 209.515915, hgln: -0.256079, hglt: -4.702038 },
  { isoDate: "2007-01-18T00:00:00Z", dsun: 209.518926, hgln: -0.255458, hglt: -4.801254 },
  { isoDate: "2007-01-19T00:00:00Z", dsun: 209.522879, hgln: -0.254532, hglt: -4.898966 },
  { isoDate: "2007-01-20T00:00:00Z", dsun: 209.527791, hgln: -0.253283, hglt: -4.995139 },
  { isoDate: "2007-01-21T00:00:00Z", dsun: 209.533682, hgln: -0.251694, hglt: -5.08974 },
  { isoDate: "2007-01-22T00:00:00Z", dsun: 209.540567, hgln: -0.249748, hglt: -5.182734 },
  { isoDate: "2007-01-23T00:00:00Z", dsun: 209.548463, hgln: -0.247431, hglt: -5.27409 },
  { isoDate: "2007-01-24T00:00:00Z", dsun: 209.557385, hgln: -0.24473, hglt: -5.363774 },
  { isoDate: "2007-01-25T00:00:00Z", dsun: 209.567346, hgln: -0.241639, hglt: -5.451756 },
  { isoDate: "2007-01-26T00:00:00Z", dsun: 209.578362, hgln: -0.238156, hglt: -5.538003 },
  { isoDate: "2007-01-27T00:00:00Z", dsun: 209.590445, hgln: -0.234286, hglt: -5.622486 },
  { isoDate: "2007-01-28T00:00:00Z", dsun: 209.603611, hgln: -0.230036, hglt: -5.705176 },
  { isoDate: "2007-01-29T00:00:00Z", dsun: 209.617875, hgln: -0.225419, hglt: -5.786042 },
  { isoDate: "2007-01-30T00:00:00Z", dsun: 209.633251, hgln: -0.220453, hglt: -5.865057 },
  { isoDate: "2007-01-31T00:00:00Z", dsun: 209.649754, hgln: -0.215155, hglt: -5.942192 },
  { isoDate: "2007-02-01T00:00:00Z", dsun: 209.667397, hgln: -0.209549, hglt: -6.017422 },
  { isoDate: "2007-02-02T00:00:00Z", dsun: 209.686196, hgln: -0.203656, hglt: -6.090718 },
  { isoDate: "2007-02-03T00:00:00Z", dsun: 209.706162, hgln: -0.1975, hglt: -6.162057 },
  { isoDate: "2007-02-04T00:00:00Z", dsun: 209.727307, hgln: -0.191104, hglt: -6.231413 },
  { isoDate: "2007-02-05T00:00:00Z", dsun: 209.749643, hgln: -0.184489, hglt: -6.298761 },
  { isoDate: "2007-02-06T00:00:00Z", dsun: 209.77318, hgln: -0.177676, hglt: -6.364079 },
  { isoDate: "2007-02-07T00:00:00Z", dsun: 209.797927, hgln: -0.170685, hglt: -6.427345 },
  { isoDate: "2007-02-08T00:00:00Z", dsun: 209.823892, hgln: -0.163533, hglt: -6.488536 },
  { isoDate: "2007-02-09T00:00:00Z", dsun: 209.851081, hgln: -0.156235, hglt: -6.547631 },
  { isoDate: "2007-02-10T00:00:00Z", dsun: 209.8795, hgln: -0.148808, hglt: -6.604612 },
  { isoDate: "2007-02-11T00:00:00Z", dsun: 209.909155, hgln: -0.141262, hglt: -6.659458 },
  { isoDate: "2007-02-12T00:00:00Z", dsun: 209.940049, hgln: -0.13361, hglt: -6.712151 },
  { isoDate: "2007-02-13T00:00:00Z", dsun: 209.972185, hgln: -0.125859, hglt: -6.762675 },
  { isoDate: "2007-02-14T00:00:00Z", dsun: 210.005566, hgln: -0.118016, hglt: -6.811012 },
  { isoDate: "2007-02-15T00:00:00Z", dsun: 210.040194, hgln: -0.110082, hglt: -6.857147 },
  { isoDate: "2007-02-16T00:00:00Z", dsun: 210.076071, hgln: -0.102057, hglt: -6.901066 },
  { isoDate: "2007-02-17T00:00:00Z", dsun: 210.113197, hgln: -0.093935, hglt: -6.942753 },
  { isoDate: "2007-02-18T00:00:00Z", dsun: 210.15157, hgln: -0.085708, hglt: -6.982198 },
  { isoDate: "2007-02-19T00:00:00Z", dsun: 210.191189, hgln: -0.077365, hglt: -7.019387 },
  { isoDate: "2007-02-20T00:00:00Z", dsun: 210.232049, hgln: -0.068897, hglt: -7.054309 },
  { isoDate: "2007-02-21T00:00:00Z", dsun: 210.274142, hgln: -0.060293, hglt: -7.086956 },
  { isoDate: "2007-02-22T00:00:00Z", dsun: 210.317461, hgln: -0.05155, hglt: -7.117316 },
  { isoDate: "2007-02-23T00:00:00Z", dsun: 210.361994, hgln: -0.042666, hglt: -7.145383 },
  { isoDate: "2007-02-24T00:00:00Z", dsun: 210.407732, hgln: -0.033647, hglt: -7.17115 },
  { isoDate: "2007-02-25T00:00:00Z", dsun: 210.454664, hgln: -0.024503, hglt: -7.194609 },
  { isoDate: "2007-02-26T00:00:00Z", dsun: 210.502774, hgln: -0.015243, hglt: -7.215759 },
  { isoDate: "2007-02-27T00:00:00Z", dsun: 210.552054, hgln: -0.005897, hglt: -7.23459 },
  { isoDate: "2007-02-28T00:00:00Z", dsun: 210.602484, hgln: 0.00352, hglt: -7.251101 },
  { isoDate: "2007-03-01T00:00:00Z", dsun: 210.654043, hgln: 0.012983, hglt: -7.265289 },
  { isoDate: "2007-03-02T00:00:00Z", dsun: 210.706724, hgln: 0.022468, hglt: -7.277154 },
  { isoDate: "2007-03-03T00:00:00Z", dsun: 210.760506, hgln: 0.031948, hglt: -7.286695 },
  { isoDate: "2007-03-04T00:00:00Z", dsun: 210.815371, hgln: 0.041396, hglt: -7.293912 },
  { isoDate: "2007-03-05T00:00:00Z", dsun: 210.871297, hgln: 0.050787, hglt: -7.298807 },
  { isoDate: "2007-03-06T00:00:00Z", dsun: 210.928262, hgln: 0.060095, hglt: -7.301381 },
  { isoDate: "2007-03-07T00:00:00Z", dsun: 210.986239, hgln: 0.069294, hglt: -7.301639 },
  { isoDate: "2007-03-08T00:00:00Z", dsun: 211.045204, hgln: 0.078361, hglt: -7.299584 },
  { isoDate: "2007-03-09T00:00:00Z", dsun: 211.10513, hgln: 0.087274, hglt: -7.295222 },
  { isoDate: "2007-03-10T00:00:00Z", dsun: 211.165989, hgln: 0.096013, hglt: -7.288558 },
  { isoDate: "2007-03-11T00:00:00Z", dsun: 211.22775, hgln: 0.10456, hglt: -7.2796 },
  { isoDate: "2007-03-12T00:00:00Z", dsun: 211.290385, hgln: 0.112898, hglt: -7.268355 },
  { isoDate: "2007-03-13T00:00:00Z", dsun: 211.35386, hgln: 0.121013, hglt: -7.254831 },
  { isoDate: "2007-03-14T00:00:00Z", dsun: 211.41815, hgln: 0.128893, hglt: -7.23904 },
  { isoDate: "2007-03-15T00:00:00Z", dsun: 211.483219, hgln: 0.136531, hglt: -7.22099 },
  { isoDate: "2007-03-16T00:00:00Z", dsun: 211.549038, hgln: 0.143924, hglt: -7.200693 },
  { isoDate: "2007-03-17T00:00:00Z", dsun: 211.615572, hgln: 0.151071, hglt: -7.178161 },
  { isoDate: "2007-03-18T00:00:00Z", dsun: 211.682789, hgln: 0.157981, hglt: -7.153407 },
  { isoDate: "2007-03-19T00:00:00Z", dsun: 211.750652, hgln: 0.164663, hglt: -7.126444 },
  { isoDate: "2007-03-20T00:00:00Z", dsun: 211.819126, hgln: 0.171132, hglt: -7.097286 },
  { isoDate: "2007-03-21T00:00:00Z", dsun: 211.888171, hgln: 0.177403, hglt: -7.065949 },
  { isoDate: "2007-03-22T00:00:00Z", dsun: 211.95775, hgln: 0.183491, hglt: -7.032449 },
  { isoDate: "2007-03-23T00:00:00Z", dsun: 212.027823, hgln: 0.189407, hglt: -6.996802 },
  { isoDate: "2007-03-24T00:00:00Z", dsun: 212.098351, hgln: 0.195157, hglt: -6.959025 },
  { isoDate: "2007-03-25T00:00:00Z", dsun: 212.169297, hgln: 0.200742, hglt: -6.919137 },
  { isoDate: "2007-03-26T00:00:00Z", dsun: 212.240621, hgln: 0.206159, hglt: -6.877157 },
  { isoDate: "2007-03-27T00:00:00Z", dsun: 212.312286, hgln: 0.211399, hglt: -6.833102 },
  { isoDate: "2007-03-28T00:00:00Z", dsun: 212.384251, hgln: 0.216451, hglt: -6.786995 },
  { isoDate: "2007-03-29T00:00:00Z", dsun: 212.45648, hgln: 0.221303, hglt: -6.738854 },
  { isoDate: "2007-03-30T00:00:00Z", dsun: 212.528932, hgln: 0.22594, hglt: -6.688701 },
  { isoDate: "2007-03-31T00:00:00Z", dsun: 212.601569, hgln: 0.230344, hglt: -6.636559 },
  { isoDate: "2007-04-01T00:00:00Z", dsun: 212.67435, hgln: 0.2345, hglt: -6.582449 },
  { isoDate: "2007-04-02T00:00:00Z", dsun: 212.747236, hgln: 0.238389, hglt: -6.526394 },
  { isoDate: "2007-04-03T00:00:00Z", dsun: 212.820186, hgln: 0.241994, hglt: -6.468417 },
  { isoDate: "2007-04-04T00:00:00Z", dsun: 212.893161, hgln: 0.245299, hglt: -6.408543 },
  { isoDate: "2007-04-05T00:00:00Z", dsun: 212.966121, hgln: 0.248285, hglt: -6.346797 },
  { isoDate: "2007-04-06T00:00:00Z", dsun: 213.039026, hgln: 0.250938, hglt: -6.283203 },
  { isoDate: "2007-04-07T00:00:00Z", dsun: 213.111837, hgln: 0.253242, hglt: -6.217786 },
  { isoDate: "2007-04-08T00:00:00Z", dsun: 213.184514, hgln: 0.255185, hglt: -6.150572 },
  { isoDate: "2007-04-09T00:00:00Z", dsun: 213.257019, hgln: 0.256755, hglt: -6.081589 },
  { isoDate: "2007-04-10T00:00:00Z", dsun: 213.329302, hgln: 0.257938, hglt: -6.010856 },
  { isoDate: "2007-04-11T00:00:00Z", dsun: 213.401352, hgln: 0.258736, hglt: -5.938412 },
  { isoDate: "2007-04-12T00:00:00Z", dsun: 213.473122, hgln: 0.25914, hglt: -5.86428 },
  { isoDate: "2007-04-13T00:00:00Z", dsun: 213.544577, hgln: 0.259149, hglt: -5.788488 },
  { isoDate: "2007-04-14T00:00:00Z", dsun: 213.615683, hgln: 0.258768, hglt: -5.711063 },
  { isoDate: "2007-04-15T00:00:00Z", dsun: 213.686409, hgln: 0.258006, hglt: -5.632034 },
  { isoDate: "2007-04-16T00:00:00Z", dsun: 213.756722, hgln: 0.256878, hglt: -5.55143 },
  { isoDate: "2007-04-17T00:00:00Z", dsun: 213.826589, hgln: 0.255402, hglt: -5.469281 },
  { isoDate: "2007-04-18T00:00:00Z", dsun: 213.895976, hgln: 0.253604, hglt: -5.385616 },
  { isoDate: "2007-04-19T00:00:00Z", dsun: 213.964852, hgln: 0.251507, hglt: -5.300465 },
  { isoDate: "2007-04-20T00:00:00Z", dsun: 214.033185, hgln: 0.249137, hglt: -5.213859 },
  { isoDate: "2007-04-21T00:00:00Z", dsun: 214.100945, hgln: 0.246512, hglt: -5.125827 },
  { isoDate: "2007-04-22T00:00:00Z", dsun: 214.168105, hgln: 0.243651, hglt: -5.0364 },
  { isoDate: "2007-04-23T00:00:00Z", dsun: 214.234639, hgln: 0.240563, hglt: -4.94561 },
  { isoDate: "2007-04-24T00:00:00Z", dsun: 214.300522, hgln: 0.237255, hglt: -4.853488 },
  { isoDate: "2007-04-25T00:00:00Z", dsun: 214.365733, hgln: 0.23373, hglt: -4.760065 },
  { isoDate: "2007-04-26T00:00:00Z", dsun: 214.430248, hgln: 0.229988, hglt: -4.665372 },
  { isoDate: "2007-04-27T00:00:00Z", dsun: 214.494048, hgln: 0.226027, hglt: -4.569442 },
  { isoDate: "2007-04-28T00:00:00Z", dsun: 214.557115, hgln: 0.221841, hglt: -4.472306 },
  { isoDate: "2007-04-29T00:00:00Z", dsun: 214.619429, hgln: 0.217426, hglt: -4.373996 },
  { isoDate: "2007-04-30T00:00:00Z", dsun: 214.680974, hgln: 0.212775, hglt: -4.274545 },
  { isoDate: "2007-05-01T00:00:00Z", dsun: 214.741735, hgln: 0.207881, hglt: -4.173986 },
  { isoDate: "2007-05-02T00:00:00Z", dsun: 214.801695, hgln: 0.202736, hglt: -4.07235 },
  { isoDate: "2007-05-03T00:00:00Z", dsun: 214.860842, hgln: 0.197331, hglt: -3.96967 },
  { isoDate: "2007-05-04T00:00:00Z", dsun: 214.919163, hgln: 0.191658, hglt: -3.865979 },
  { isoDate: "2007-05-05T00:00:00Z", dsun: 214.976646, hgln: 0.185711, hglt: -3.76131 },
  { isoDate: "2007-05-06T00:00:00Z", dsun: 215.03328, hgln: 0.179481, hglt: -3.655695 },
  { isoDate: "2007-05-07T00:00:00Z", dsun: 215.089057, hgln: 0.172963, hglt: -3.549168 },
  { isoDate: "2007-05-08T00:00:00Z", dsun: 215.143964, hgln: 0.166152, hglt: -3.441761 },
  { isoDate: "2007-05-09T00:00:00Z", dsun: 215.198002, hgln: 0.159049, hglt: -3.333506 },
  { isoDate: "2007-05-10T00:00:00Z", dsun: 215.251163, hgln: 0.151653, hglt: -3.224437 },
  { isoDate: "2007-05-11T00:00:00Z", dsun: 215.303443, hgln: 0.143968, hglt: -3.114586 },
  { isoDate: "2007-05-12T00:00:00Z", dsun: 215.354841, hgln: 0.136003, hglt: -3.003986 },
  { isoDate: "2007-05-13T00:00:00Z", dsun: 215.405357, hgln: 0.127773, hglt: -2.89267 },
  { isoDate: "2007-05-14T00:00:00Z", dsun: 215.454992, hgln: 0.119295, hglt: -2.780671 },
  { isoDate: "2007-05-15T00:00:00Z", dsun: 215.503748, hgln: 0.110595, hglt: -2.66802 },
  { isoDate: "2007-05-16T00:00:00Z", dsun: 215.551628, hgln: 0.101701, hglt: -2.554751 },
  { isoDate: "2007-05-17T00:00:00Z", dsun: 215.598631, hgln: 0.092646, hglt: -2.440895 },
  { isoDate: "2007-05-18T00:00:00Z", dsun: 215.644755, hgln: 0.083465, hglt: -2.326486 },
  { isoDate: "2007-05-19T00:00:00Z", dsun: 215.689996, hgln: 0.074191, hglt: -2.211554 },
  { isoDate: "2007-05-20T00:00:00Z", dsun: 215.734348, hgln: 0.064855, hglt: -2.096131 },
  { isoDate: "2007-05-21T00:00:00Z", dsun: 215.777807, hgln: 0.055485, hglt: -1.980248 },
  { isoDate: "2007-05-22T00:00:00Z", dsun: 215.820368, hgln: 0.046104, hglt: -1.863938 },
  { isoDate: "2007-05-23T00:00:00Z", dsun: 215.862028, hgln: 0.036733, hglt: -1.74723 },
  { isoDate: "2007-05-24T00:00:00Z", dsun: 215.902782, hgln: 0.027386, hglt: -1.630156 },
  { isoDate: "2007-05-25T00:00:00Z", dsun: 215.942627, hgln: 0.018077, hglt: -1.512746 },
  { isoDate: "2007-05-26T00:00:00Z", dsun: 215.981557, hgln: 0.008818, hglt: -1.39503 },
  { isoDate: "2007-05-27T00:00:00Z", dsun: 216.019567, hgln: -0.000382, hglt: -1.27704 },
  { isoDate: "2007-05-28T00:00:00Z", dsun: 216.05665, hgln: -0.009517, hglt: -1.158803 },
  { isoDate: "2007-05-29T00:00:00Z", dsun: 216.092798, hgln: -0.018581, hglt: -1.040352 },
  { isoDate: "2007-05-30T00:00:00Z", dsun: 216.128002, hgln: -0.02757, hglt: -0.921714 },
  { isoDate: "2007-05-31T00:00:00Z", dsun: 216.162254, hgln: -0.036483, hglt: -0.802919 },
  { isoDate: "2007-06-01T00:00:00Z", dsun: 216.195539, hgln: -0.045318, hglt: -0.683997 },
  { isoDate: "2007-06-02T00:00:00Z", dsun: 216.22784, hgln: -0.054077, hglt: -0.564976 },
  { isoDate: "2007-06-03T00:00:00Z", dsun: 216.259143, hgln: -0.062762, hglt: -0.445885 },
  { isoDate: "2007-06-04T00:00:00Z", dsun: 216.289432, hgln: -0.071374, hglt: -0.326752 },
  { isoDate: "2007-06-05T00:00:00Z", dsun: 216.318687, hgln: -0.079916, hglt: -0.207607 },
  { isoDate: "2007-06-06T00:00:00Z", dsun: 216.34689, hgln: -0.088389, hglt: -0.088478 },
  { isoDate: "2007-06-07T00:00:00Z", dsun: 216.374022, hgln: -0.096794, hglt: 0.030608 },
  { isoDate: "2007-06-08T00:00:00Z", dsun: 216.400061, hgln: -0.105128, hglt: 0.149622 },
  { isoDate: "2007-06-09T00:00:00Z", dsun: 216.424988, hgln: -0.113386, hglt: 0.268536 },
  { isoDate: "2007-06-10T00:00:00Z", dsun: 216.448782, hgln: -0.121558, hglt: 0.387322 },
  { isoDate: "2007-06-11T00:00:00Z", dsun: 216.471424, hgln: -0.129632, hglt: 0.505952 },
  { isoDate: "2007-06-12T00:00:00Z", dsun: 216.492896, hgln: -0.137589, hglt: 0.624397 },
  { isoDate: "2007-06-13T00:00:00Z", dsun: 216.513181, hgln: -0.145407, hglt: 0.742633 },
  { isoDate: "2007-06-14T00:00:00Z", dsun: 216.532264, hgln: -0.153059, hglt: 0.860629 },
  { isoDate: "2007-06-15T00:00:00Z", dsun: 216.550132, hgln: -0.160517, hglt: 0.978359 },
  { isoDate: "2007-06-16T00:00:00Z", dsun: 216.566772, hgln: -0.167754, hglt: 1.095793 },
  { isoDate: "2007-06-17T00:00:00Z", dsun: 216.582173, hgln: -0.174742, hglt: 1.212905 },
  { isoDate: "2007-06-18T00:00:00Z", dsun: 216.596321, hgln: -0.181458, hglt: 1.329666 },
  { isoDate: "2007-06-19T00:00:00Z", dsun: 216.609204, hgln: -0.187884, hglt: 1.446048 },
  { isoDate: "2007-06-20T00:00:00Z", dsun: 216.620809, hgln: -0.194004, hglt: 1.562024 },
  { isoDate: "2007-06-21T00:00:00Z", dsun: 216.631122, hgln: -0.199806, hglt: 1.677565 },
  { isoDate: "2007-06-22T00:00:00Z", dsun: 216.640134, hgln: -0.205282, hglt: 1.792644 },
  { isoDate: "2007-06-23T00:00:00Z", dsun: 216.647835, hgln: -0.210426, hglt: 1.907233 },
  { isoDate: "2007-06-24T00:00:00Z", dsun: 216.654217, hgln: -0.215236, hglt: 2.021303 },
  { isoDate: "2007-06-25T00:00:00Z", dsun: 216.659277, hgln: -0.219711, hglt: 2.134828 },
  { isoDate: "2007-06-26T00:00:00Z", dsun: 216.663011, hgln: -0.223853, hglt: 2.247779 },
  { isoDate: "2007-06-27T00:00:00Z", dsun: 216.665417, hgln: -0.227665, hglt: 2.360129 },
  { isoDate: "2007-06-28T00:00:00Z", dsun: 216.666498, hgln: -0.231153, hglt: 2.471849 },
  { isoDate: "2007-06-29T00:00:00Z", dsun: 216.666254, hgln: -0.234327, hglt: 2.582912 },
  { isoDate: "2007-06-30T00:00:00Z", dsun: 216.66469, hgln: -0.237198, hglt: 2.693289 },
  { isoDate: "2007-07-01T00:00:00Z", dsun: 216.66181, hgln: -0.239777, hglt: 2.802954 },
  { isoDate: "2007-07-02T00:00:00Z", dsun: 216.657621, hgln: -0.24208, hglt: 2.911877 },
  { isoDate: "2007-07-03T00:00:00Z", dsun: 216.652129, hgln: -0.244122, hglt: 3.020032 },
  { isoDate: "2007-07-04T00:00:00Z", dsun: 216.645342, hgln: -0.245919, hglt: 3.127391 },
  { isoDate: "2007-07-05T00:00:00Z", dsun: 216.63727, hgln: -0.247485, hglt: 3.233925 },
  { isoDate: "2007-07-06T00:00:00Z", dsun: 216.627922, hgln: -0.248834, hglt: 3.339608 },
  { isoDate: "2007-07-07T00:00:00Z", dsun: 216.617309, hgln: -0.249975, hglt: 3.444412 },
  { isoDate: "2007-07-08T00:00:00Z", dsun: 216.605443, hgln: -0.250914, hglt: 3.548308 },
  { isoDate: "2007-07-09T00:00:00Z", dsun: 216.592335, hgln: -0.251653, hglt: 3.65127 },
  { isoDate: "2007-07-10T00:00:00Z", dsun: 216.578002, hgln: -0.252188, hglt: 3.753272 },
  { isoDate: "2007-07-11T00:00:00Z", dsun: 216.562455, hgln: -0.252512, hglt: 3.854284 },
  { isoDate: "2007-07-12T00:00:00Z", dsun: 216.545711, hgln: -0.252613, hglt: 3.95428 },
  { isoDate: "2007-07-13T00:00:00Z", dsun: 216.527789, hgln: -0.252475, hglt: 4.053234 },
  { isoDate: "2007-07-14T00:00:00Z", dsun: 216.508706, hgln: -0.252081, hglt: 4.151118 },
  { isoDate: "2007-07-15T00:00:00Z", dsun: 216.488485, hgln: -0.251413, hglt: 4.247905 },
  { isoDate: "2007-07-16T00:00:00Z", dsun: 216.467147, hgln: -0.250454, hglt: 4.34357 },
  { isoDate: "2007-07-17T00:00:00Z", dsun: 216.444713, hgln: -0.24919, hglt: 4.438085 },
  { isoDate: "2007-07-18T00:00:00Z", dsun: 216.421204, hgln: -0.24761, hglt: 4.531425 },
  { isoDate: "2007-07-19T00:00:00Z", dsun: 216.396644, hgln: -0.245703, hglt: 4.623563 },
  { isoDate: "2007-07-20T00:00:00Z", dsun: 216.371052, hgln: -0.243465, hglt: 4.714472 },
  { isoDate: "2007-07-21T00:00:00Z", dsun: 216.34445, hgln: -0.240892, hglt: 4.804128 },
  { isoDate: "2007-07-22T00:00:00Z", dsun: 216.31686, hgln: -0.237984, hglt: 4.892505 },
  { isoDate: "2007-07-23T00:00:00Z", dsun: 216.288305, hgln: -0.234742, hglt: 4.979576 },
  { isoDate: "2007-07-24T00:00:00Z", dsun: 216.258808, hgln: -0.23117, hglt: 5.065318 },
  { isoDate: "2007-07-25T00:00:00Z", dsun: 216.228394, hgln: -0.227275, hglt: 5.149705 },
  { isoDate: "2007-07-26T00:00:00Z", dsun: 216.197087, hgln: -0.223064, hglt: 5.232712 },
  { isoDate: "2007-07-27T00:00:00Z", dsun: 216.164914, hgln: -0.218549, hglt: 5.314315 },
  { isoDate: "2007-07-28T00:00:00Z", dsun: 216.1319, hgln: -0.213744, hglt: 5.39449 },
  { isoDate: "2007-07-29T00:00:00Z", dsun: 216.098072, hgln: -0.208667, hglt: 5.473213 },
  { isoDate: "2007-07-30T00:00:00Z", dsun: 216.063457, hgln: -0.203337, hglt: 5.55046 },
  { isoDate: "2007-07-31T00:00:00Z", dsun: 216.02808, hgln: -0.197776, hglt: 5.626207 },
  { isoDate: "2007-08-01T00:00:00Z", dsun: 215.991968, hgln: -0.19201, hglt: 5.700433 },
  { isoDate: "2007-08-02T00:00:00Z", dsun: 215.955148, hgln: -0.186062, hglt: 5.773113 },
  { isoDate: "2007-08-03T00:00:00Z", dsun: 215.917645, hgln: -0.179955, hglt: 5.844225 },
  { isoDate: "2007-08-04T00:00:00Z", dsun: 215.879484, hgln: -0.173711, hglt: 5.913748 },
  { isoDate: "2007-08-05T00:00:00Z", dsun: 215.84069, hgln: -0.167347, hglt: 5.981659 },
  { isoDate: "2007-08-06T00:00:00Z", dsun: 215.801289, hgln: -0.160875, hglt: 6.047936 },
  { isoDate: "2007-08-07T00:00:00Z", dsun: 215.7613, hgln: -0.1543, hglt: 6.112562 },
  { isoDate: "2007-08-08T00:00:00Z", dsun: 215.720758, hgln: -0.147632, hglt: 6.17551 },
  { isoDate: "2007-08-09T00:00:00Z", dsun: 215.679683, hgln: -0.140867, hglt: 6.236763 },
  { isoDate: "2007-08-10T00:00:00Z", dsun: 215.638099, hgln: -0.134, hglt: 6.2963 },
  { isoDate: "2007-08-11T00:00:00Z", dsun: 215.596031, hgln: -0.127025, hglt: 6.354103 },
  { isoDate: "2007-08-12T00:00:00Z", dsun: 215.553505, hgln: -0.119933, hglt: 6.410152 },
  { isoDate: "2007-08-13T00:00:00Z", dsun: 215.510545, hgln: -0.112715, hglt: 6.464428 },
  { isoDate: "2007-08-14T00:00:00Z", dsun: 215.467176, hgln: -0.105363, hglt: 6.516914 },
  { isoDate: "2007-08-15T00:00:00Z", dsun: 215.423421, hgln: -0.097869, hglt: 6.567591 },
  { isoDate: "2007-08-16T00:00:00Z", dsun: 215.379302, hgln: -0.090229, hglt: 6.616441 },
  { isoDate: "2007-08-17T00:00:00Z", dsun: 215.334838, hgln: -0.082439, hglt: 6.663449 },
  { isoDate: "2007-08-18T00:00:00Z", dsun: 215.290049, hgln: -0.074498, hglt: 6.708597 },
  { isoDate: "2007-08-19T00:00:00Z", dsun: 215.244955, hgln: -0.066407, hglt: 6.75187 },
  { isoDate: "2007-08-20T00:00:00Z", dsun: 215.199573, hgln: -0.05817, hglt: 6.793251 },
  { isoDate: "2007-08-21T00:00:00Z", dsun: 215.153923, hgln: -0.049792, hglt: 6.832727 },
  { isoDate: "2007-08-22T00:00:00Z", dsun: 215.108021, hgln: -0.04128, hglt: 6.870282 },
  { isoDate: "2007-08-23T00:00:00Z", dsun: 215.061886, hgln: -0.032643, hglt: 6.905904 },
  { isoDate: "2007-08-24T00:00:00Z", dsun: 215.015536, hgln: -0.023893, hglt: 6.939578 },
  { isoDate: "2007-08-25T00:00:00Z", dsun: 214.968986, hgln: -0.015046, hglt: 6.971292 },
  { isoDate: "2007-08-26T00:00:00Z", dsun: 214.922253, hgln: -0.00612, hglt: 7.001034 },
  { isoDate: "2007-08-27T00:00:00Z", dsun: 214.875352, hgln: 0.002863, hglt: 7.028791 },
  { isoDate: "2007-08-28T00:00:00Z", dsun: 214.828297, hgln: 0.011876, hglt: 7.054554 },
  { isoDate: "2007-08-29T00:00:00Z", dsun: 214.781101, hgln: 0.020892, hglt: 7.078311 },
  { isoDate: "2007-08-30T00:00:00Z", dsun: 214.733776, hgln: 0.029878, hglt: 7.100053 },
  { isoDate: "2007-08-31T00:00:00Z", dsun: 214.686337, hgln: 0.038806, hglt: 7.119769 },
  { isoDate: "2007-09-01T00:00:00Z", dsun: 214.638804, hgln: 0.047646, hglt: 7.137451 },
  { isoDate: "2007-09-02T00:00:00Z", dsun: 214.591172, hgln: 0.05637, hglt: 7.153091 },
  { isoDate: "2007-09-03T00:00:00Z", dsun: 214.54345, hgln: 0.064957, hglt: 7.166682 },
  { isoDate: "2007-09-04T00:00:00Z", dsun: 214.495645, hgln: 0.073391, hglt: 7.178217 },
  { isoDate: "2007-09-05T00:00:00Z", dsun: 214.447763, hgln: 0.08166, hglt: 7.187689 },
  { isoDate: "2007-09-06T00:00:00Z", dsun: 214.399812, hgln: 0.089758, hglt: 7.195094 },
  { isoDate: "2007-09-07T00:00:00Z", dsun: 214.351799, hgln: 0.097683, hglt: 7.200426 },
  { isoDate: "2007-09-08T00:00:00Z", dsun: 214.303729, hgln: 0.105437, hglt: 7.20368 },
  { isoDate: "2007-09-09T00:00:00Z", dsun: 214.255608, hgln: 0.113022, hglt: 7.204855 },
  { isoDate: "2007-09-10T00:00:00Z", dsun: 214.207441, hgln: 0.120443, hglt: 7.203945 },
  { isoDate: "2007-09-11T00:00:00Z", dsun: 214.159276, hgln: 0.127666, hglt: 7.20098 },
  { isoDate: "2007-09-12T00:00:00Z", dsun: 214.111029, hgln: 0.134775, hglt: 7.195899 },
  { isoDate: "2007-09-13T00:00:00Z", dsun: 214.062743, hgln: 0.141738, hglt: 7.188729 },
  { isoDate: "2007-09-14T00:00:00Z", dsun: 214.014418, hgln: 0.148561, hglt: 7.17947 },
  { isoDate: "2007-09-15T00:00:00Z", dsun: 213.966053, hgln: 0.155248, hglt: 7.168121 },
  { isoDate: "2007-09-16T00:00:00Z", dsun: 213.917647, hgln: 0.161802, hglt: 7.154685 },
  { isoDate: "2007-09-17T00:00:00Z", dsun: 213.869199, hgln: 0.168227, hglt: 7.139161 },
  { isoDate: "2007-09-18T00:00:00Z", dsun: 213.820707, hgln: 0.174521, hglt: 7.121553 },
  { isoDate: "2007-09-19T00:00:00Z", dsun: 213.772171, hgln: 0.180685, hglt: 7.101863 },
  { isoDate: "2007-09-20T00:00:00Z", dsun: 213.72359, hgln: 0.186716, hglt: 7.080095 },
  { isoDate: "2007-09-21T00:00:00Z", dsun: 213.674962, hgln: 0.192609, hglt: 7.056253 },
  { isoDate: "2007-09-22T00:00:00Z", dsun: 213.626286, hgln: 0.198358, hglt: 7.030343 },
  { isoDate: "2007-09-23T00:00:00Z", dsun: 213.577561, hgln: 0.203951, hglt: 7.002368 },
  { isoDate: "2007-09-24T00:00:00Z", dsun: 213.528783, hgln: 0.209377, hglt: 6.972336 },
  { isoDate: "2007-09-25T00:00:00Z", dsun: 213.479951, hgln: 0.214616, hglt: 6.940253 },
  { isoDate: "2007-09-26T00:00:00Z", dsun: 213.431061, hgln: 0.219646, hglt: 6.906128 },
  { isoDate: "2007-09-27T00:00:00Z", dsun: 213.38211, hgln: 0.224443, hglt: 6.869967 },
  { isoDate: "2007-09-28T00:00:00Z", dsun: 213.333094, hgln: 0.228978, hglt: 6.831779 },
  { isoDate: "2007-09-29T00:00:00Z", dsun: 213.284009, hgln: 0.233223, hglt: 6.791575 },
  { isoDate: "2007-09-30T00:00:00Z", dsun: 213.234851, hgln: 0.237152, hglt: 6.749363 },
  { isoDate: "2007-10-01T00:00:00Z", dsun: 213.185616, hgln: 0.240743, hglt: 6.705154 },
  { isoDate: "2007-10-02T00:00:00Z", dsun: 213.136301, hgln: 0.243979, hglt: 6.65896 },
  { isoDate: "2007-10-03T00:00:00Z", dsun: 213.086902, hgln: 0.246851, hglt: 6.610793 },
  { isoDate: "2007-10-04T00:00:00Z", dsun: 213.037417, hgln: 0.249352, hglt: 6.560664 },
  { isoDate: "2007-10-05T00:00:00Z", dsun: 212.987844, hgln: 0.251483, hglt: 6.508587 },
  { isoDate: "2007-10-06T00:00:00Z", dsun: 212.938183, hgln: 0.253246, hglt: 6.454576 },
  { isoDate: "2007-10-07T00:00:00Z", dsun: 212.888434, hgln: 0.254647, hglt: 6.398644 },
  { isoDate: "2007-10-08T00:00:00Z", dsun: 212.838596, hgln: 0.255694, hglt: 6.340807 },
  { isoDate: "2007-10-09T00:00:00Z", dsun: 212.788656, hgln: 0.256389, hglt: 6.281069 },
  { isoDate: "2007-10-10T00:00:00Z", dsun: 212.738639, hgln: 0.256758, hglt: 6.219468 },
  { isoDate: "2007-10-11T00:00:00Z", dsun: 212.688531, hgln: 0.256806, hglt: 6.156008 },
  { isoDate: "2007-10-12T00:00:00Z", dsun: 212.638332, hgln: 0.256545, hglt: 6.090708 },
  { isoDate: "2007-10-13T00:00:00Z", dsun: 212.588039, hgln: 0.255987, hglt: 6.023585 },
  { isoDate: "2007-10-14T00:00:00Z", dsun: 212.537654, hgln: 0.255145, hglt: 5.954657 },
  { isoDate: "2007-10-15T00:00:00Z", dsun: 212.487178, hgln: 0.254031, hglt: 5.883942 },
  { isoDate: "2007-10-16T00:00:00Z", dsun: 212.436612, hgln: 0.252655, hglt: 5.811459 },
  { isoDate: "2007-10-17T00:00:00Z", dsun: 212.385961, hgln: 0.251027, hglt: 5.737229 },
  { isoDate: "2007-10-18T00:00:00Z", dsun: 212.33523, hgln: 0.249158, hglt: 5.661272 },
  { isoDate: "2007-10-19T00:00:00Z", dsun: 212.284427, hgln: 0.247053, hglt: 5.583607 },
  { isoDate: "2007-10-20T00:00:00Z", dsun: 212.233558, hgln: 0.244718, hglt: 5.504256 },
  { isoDate: "2007-10-21T00:00:00Z", dsun: 212.182632, hgln: 0.242155, hglt: 5.423242 },
  { isoDate: "2007-10-22T00:00:00Z", dsun: 212.13166, hgln: 0.239366, hglt: 5.340584 },
  { isoDate: "2007-10-23T00:00:00Z", dsun: 212.080651, hgln: 0.236344, hglt: 5.256307 },
  { isoDate: "2007-10-24T00:00:00Z", dsun: 212.029617, hgln: 0.233081, hglt: 5.170433 },
  { isoDate: "2007-10-25T00:00:00Z", dsun: 211.978571, hgln: 0.229563, hglt: 5.082985 },
  { isoDate: "2007-10-26T00:00:00Z", dsun: 211.927524, hgln: 0.225772, hglt: 4.993987 },
  { isoDate: "2007-10-27T00:00:00Z", dsun: 211.876491, hgln: 0.221688, hglt: 4.903463 },
  { isoDate: "2007-10-28T00:00:00Z", dsun: 211.825486, hgln: 0.217289, hglt: 4.811438 },
  { isoDate: "2007-10-29T00:00:00Z", dsun: 211.774524, hgln: 0.212555, hglt: 4.717937 },
  { isoDate: "2007-10-30T00:00:00Z", dsun: 211.723623, hgln: 0.207475, hglt: 4.622984 },
  { isoDate: "2007-10-31T00:00:00Z", dsun: 211.672801, hgln: 0.202037, hglt: 4.526606 },
  { isoDate: "2007-11-01T00:00:00Z", dsun: 211.622078, hgln: 0.196241, hglt: 4.428829 },
  { isoDate: "2007-11-02T00:00:00Z", dsun: 211.571474, hgln: 0.190089, hglt: 4.329678 },
  { isoDate: "2007-11-03T00:00:00Z", dsun: 211.521015, hgln: 0.183589, hglt: 4.229181 },
  { isoDate: "2007-11-04T00:00:00Z", dsun: 211.470724, hgln: 0.176751, hglt: 4.127365 },
  { isoDate: "2007-11-05T00:00:00Z", dsun: 211.420631, hgln: 0.16959, hglt: 4.024256 },
  { isoDate: "2007-11-06T00:00:00Z", dsun: 211.370763, hgln: 0.162122, hglt: 3.919883 },
  { isoDate: "2007-11-07T00:00:00Z", dsun: 211.321152, hgln: 0.154364, hglt: 3.814273 },
  { isoDate: "2007-11-08T00:00:00Z", dsun: 211.271826, hgln: 0.146337, hglt: 3.707454 },
  { isoDate: "2007-11-09T00:00:00Z", dsun: 211.222816, hgln: 0.138062, hglt: 3.599455 },
  { isoDate: "2007-11-10T00:00:00Z", dsun: 211.17415, hgln: 0.129559, hglt: 3.490305 },
  { isoDate: "2007-11-11T00:00:00Z", dsun: 211.125852, hgln: 0.120853, hglt: 3.380034 },
  { isoDate: "2007-11-12T00:00:00Z", dsun: 211.077948, hgln: 0.111966, hglt: 3.268671 },
  { isoDate: "2007-11-13T00:00:00Z", dsun: 211.030459, hgln: 0.102926, hglt: 3.156247 },
  { isoDate: "2007-11-14T00:00:00Z", dsun: 210.983416, hgln: 0.093755, hglt: 3.042791 },
  { isoDate: "2007-11-15T00:00:00Z", dsun: 210.936841, hgln: 0.08448, hglt: 2.928335 },
  { isoDate: "2007-11-16T00:00:00Z", dsun: 210.89076, hgln: 0.075125, hglt: 2.81291 },
  { isoDate: "2007-11-17T00:00:00Z", dsun: 210.845199, hgln: 0.065713, hglt: 2.696547 },
  { isoDate: "2007-11-18T00:00:00Z", dsun: 210.800183, hgln: 0.056266, hglt: 2.579279 },
  { isoDate: "2007-11-19T00:00:00Z", dsun: 210.755736, hgln: 0.046803, hglt: 2.461137 },
  { isoDate: "2007-11-20T00:00:00Z", dsun: 210.711884, hgln: 0.037341, hglt: 2.342153 },
  { isoDate: "2007-11-21T00:00:00Z", dsun: 210.668647, hgln: 0.027889, hglt: 2.222362 },
  { isoDate: "2007-11-22T00:00:00Z", dsun: 210.626047, hgln: 0.018456, hglt: 2.101795 },
  { isoDate: "2007-11-23T00:00:00Z", dsun: 210.584103, hgln: 0.009042, hglt: 1.980488 },
  { isoDate: "2007-11-24T00:00:00Z", dsun: 210.54283, hgln: -0.000356, hglt: 1.858474 },
  { isoDate: "2007-11-25T00:00:00Z", dsun: 210.502242, hgln: -0.009744, hglt: 1.735788 },
  { isoDate: "2007-11-26T00:00:00Z", dsun: 210.462351, hgln: -0.01913, hglt: 1.612466 },
  { isoDate: "2007-11-27T00:00:00Z", dsun: 210.423165, hgln: -0.028521, hglt: 1.488543 },
  { isoDate: "2007-11-28T00:00:00Z", dsun: 210.384691, hgln: -0.037918, hglt: 1.364055 },
  { isoDate: "2007-11-29T00:00:00Z", dsun: 210.346936, hgln: -0.047319, hglt: 1.23904 },
  { isoDate: "2007-11-30T00:00:00Z", dsun: 210.309897, hgln: -0.056718, hglt: 1.113535 },
  { isoDate: "2007-12-01T00:00:00Z", dsun: 210.273576, hgln: -0.066104, hglt: 0.987578 },
  { isoDate: "2007-12-02T00:00:00Z", dsun: 210.237971, hgln: -0.075462, hglt: 0.861206 },
  { isoDate: "2007-12-03T00:00:00Z", dsun: 210.20308, hgln: -0.084774, hglt: 0.734457 },
  { isoDate: "2007-12-04T00:00:00Z", dsun: 210.168901, hgln: -0.094021, hglt: 0.607372 },
  { isoDate: "2007-12-05T00:00:00Z", dsun: 210.135429, hgln: -0.103181, hglt: 0.479989 },
  { isoDate: "2007-12-06T00:00:00Z", dsun: 210.102663, hgln: -0.112234, hglt: 0.352348 },
  { isoDate: "2007-12-07T00:00:00Z", dsun: 210.070601, hgln: -0.121154, hglt: 0.224488 },
  { isoDate: "2007-12-08T00:00:00Z", dsun: 210.03924, hgln: -0.129919, hglt: 0.096449 },
  { isoDate: "2007-12-09T00:00:00Z", dsun: 210.008581, hgln: -0.138504, hglt: -0.031728 },
  { isoDate: "2007-12-10T00:00:00Z", dsun: 209.978623, hgln: -0.146885, hglt: -0.160002 },
  { isoDate: "2007-12-11T00:00:00Z", dsun: 209.949217, hgln: -0.155046, hglt: -0.288326 },
  { isoDate: "2007-12-12T00:00:00Z", dsun: 209.920647, hgln: -0.162948, hglt: -0.416671 },
  { isoDate: "2007-12-13T00:00:00Z", dsun: 209.892775, hgln: -0.170575, hglt: -0.544989 },
  { isoDate: "2007-12-14T00:00:00Z", dsun: 209.865597, hgln: -0.177906, hglt: -0.673238 },
  { isoDate: "2007-12-15T00:00:00Z", dsun: 209.83911, hgln: -0.184922, hglt: -0.801375 },
  { isoDate: "2007-12-16T00:00:00Z", dsun: 209.81331, hgln: -0.191603, hglt: -0.929359 },
  { isoDate: "2007-12-17T00:00:00Z", dsun: 209.788195, hgln: -0.197935, hglt: -1.057147 },
  { isoDate: "2007-12-18T00:00:00Z", dsun: 209.763765, hgln: -0.203905, hglt: -1.184697 },
  { isoDate: "2007-12-19T00:00:00Z", dsun: 209.74002, hgln: -0.209504, hglt: -1.311966 },
  { isoDate: "2007-12-20T00:00:00Z", dsun: 209.716965, hgln: -0.21473, hglt: -1.438912 },
  { isoDate: "2007-12-21T00:00:00Z", dsun: 209.694605, hgln: -0.219585, hglt: -1.565493 },
  { isoDate: "2007-12-22T00:00:00Z", dsun: 209.672947, hgln: -0.224077, hglt: -1.691667 },
  { isoDate: "2007-12-23T00:00:00Z", dsun: 209.651998, hgln: -0.22822, hglt: -1.81739 },
  { isoDate: "2007-12-24T00:00:00Z", dsun: 209.631769, hgln: -0.232031, hglt: -1.942621 },
  { isoDate: "2007-12-25T00:00:00Z", dsun: 209.612269, hgln: -0.235528, hglt: -2.067317 },
  { isoDate: "2007-12-26T00:00:00Z", dsun: 209.59351, hgln: -0.238731, hglt: -2.191437 },
  { isoDate: "2007-12-27T00:00:00Z", dsun: 209.575503, hgln: -0.241653, hglt: -2.314937 },
  { isoDate: "2007-12-28T00:00:00Z", dsun: 209.558261, hgln: -0.244309, hglt: -2.437777 },
  { isoDate: "2007-12-29T00:00:00Z", dsun: 209.541796, hgln: -0.246706, hglt: -2.559913 },
  { isoDate: "2007-12-30T00:00:00Z", dsun: 209.526122, hgln: -0.24885, hglt: -2.681304 },
  { isoDate: "2007-12-31T00:00:00Z", dsun: 209.511251, hgln: -0.250742, hglt: -2.801908 },
  { isoDate: "2008-01-01T00:00:00Z", dsun: 209.497198, hgln: -0.252381, hglt: -2.921685 },
  { isoDate: "2008-01-02T00:00:00Z", dsun: 209.483979, hgln: -0.253765, hglt: -3.040592 },
  { isoDate: "2008-01-03T00:00:00Z", dsun: 209.471608, hgln: -0.254888, hglt: -3.15859 },
  { isoDate: "2008-01-04T00:00:00Z", dsun: 209.460101, hgln: -0.255744, hglt: -3.275636 },
  { isoDate: "2008-01-05T00:00:00Z", dsun: 209.449477, hgln: -0.256325, hglt: -3.391691 },
  { isoDate: "2008-01-06T00:00:00Z", dsun: 209.439753, hgln: -0.256623, hglt: -3.506714 },
  { isoDate: "2008-01-07T00:00:00Z", dsun: 209.430947, hgln: -0.256625, hglt: -3.620666 },
  { isoDate: "2008-01-08T00:00:00Z", dsun: 209.423081, hgln: -0.256318, hglt: -3.733509 },
  { isoDate: "2008-01-09T00:00:00Z", dsun: 209.416174, hgln: -0.255693, hglt: -3.8452 },
  { isoDate: "2008-01-10T00:00:00Z", dsun: 209.410248, hgln: -0.254733, hglt: -3.955701 },
  { isoDate: "2008-01-11T00:00:00Z", dsun: 209.405324, hgln: -0.253425, hglt: -4.064975 },
  { isoDate: "2008-01-12T00:00:00Z", dsun: 209.401422, hgln: -0.251756, hglt: -4.172981 },
  { isoDate: "2008-01-13T00:00:00Z", dsun: 209.39856, hgln: -0.249715, hglt: -4.279684 },
  { isoDate: "2008-01-14T00:00:00Z", dsun: 209.396756, hgln: -0.24729, hglt: -4.385045 },
  { isoDate: "2008-01-15T00:00:00Z", dsun: 209.396027, hgln: -0.244477, hglt: -4.489027 },
  { isoDate: "2008-01-16T00:00:00Z", dsun: 209.396392, hgln: -0.241271, hglt: -4.591593 },
  { isoDate: "2008-01-17T00:00:00Z", dsun: 209.397868, hgln: -0.237673, hglt: -4.692708 },
  { isoDate: "2008-01-18T00:00:00Z", dsun: 209.400473, hgln: -0.233691, hglt: -4.792337 },
  { isoDate: "2008-01-19T00:00:00Z", dsun: 209.404225, hgln: -0.229335, hglt: -4.890443 },
  { isoDate: "2008-01-20T00:00:00Z", dsun: 209.409143, hgln: -0.224623, hglt: -4.986993 },
  { isoDate: "2008-01-21T00:00:00Z", dsun: 209.415245, hgln: -0.219575, hglt: -5.081953 },
  { isoDate: "2008-01-22T00:00:00Z", dsun: 209.422549, hgln: -0.214216, hglt: -5.175288 },
  { isoDate: "2008-01-23T00:00:00Z", dsun: 209.431072, hgln: -0.208574, hglt: -5.266966 },
  { isoDate: "2008-01-24T00:00:00Z", dsun: 209.44083, hgln: -0.202673, hglt: -5.356956 },
  { isoDate: "2008-01-25T00:00:00Z", dsun: 209.45184, hgln: -0.19654, hglt: -5.445224 },
  { isoDate: "2008-01-26T00:00:00Z", dsun: 209.464115, hgln: -0.190197, hglt: -5.531741 },
  { isoDate: "2008-01-27T00:00:00Z", dsun: 209.47767, hgln: -0.183664, hglt: -5.616475 },
  { isoDate: "2008-01-28T00:00:00Z", dsun: 209.492516, hgln: -0.176956, hglt: -5.699397 },
  { isoDate: "2008-01-29T00:00:00Z", dsun: 209.508666, hgln: -0.170089, hglt: -5.780479 },
  { isoDate: "2008-01-30T00:00:00Z", dsun: 209.526129, hgln: -0.163074, hglt: -5.859691 },
  { isoDate: "2008-01-31T00:00:00Z", dsun: 209.544915, hgln: -0.155921, hglt: -5.937007 },
  { isoDate: "2008-02-01T00:00:00Z", dsun: 209.565034, hgln: -0.148637, hglt: -6.012399 },
  { isoDate: "2008-02-02T00:00:00Z", dsun: 209.586492, hgln: -0.141229, hglt: -6.085841 },
  { isoDate: "2008-02-03T00:00:00Z", dsun: 209.609296, hgln: -0.1337, hglt: -6.157309 },
  { isoDate: "2008-02-04T00:00:00Z", dsun: 209.633455, hgln: -0.126054, hglt: -6.226778 },
  { isoDate: "2008-02-05T00:00:00Z", dsun: 209.658973, hgln: -0.118289, hglt: -6.294224 },
  { isoDate: "2008-02-06T00:00:00Z", dsun: 209.685857, hgln: -0.110404, hglt: -6.359624 },
  { isoDate: "2008-02-07T00:00:00Z", dsun: 209.71411, hgln: -0.102395, hglt: -6.422957 },
  { isoDate: "2008-02-08T00:00:00Z", dsun: 209.743734, hgln: -0.094257, hglt: -6.4842 },
  { isoDate: "2008-02-09T00:00:00Z", dsun: 209.774731, hgln: -0.085982, hglt: -6.543335 },
  { isoDate: "2008-02-10T00:00:00Z", dsun: 209.807099, hgln: -0.077564, hglt: -6.600341 },
  { isoDate: "2008-02-11T00:00:00Z", dsun: 209.840834, hgln: -0.068998, hglt: -6.655199 },
  { isoDate: "2008-02-12T00:00:00Z", dsun: 209.875935, hgln: -0.06028, hglt: -6.707891 },
  { isoDate: "2008-02-13T00:00:00Z", dsun: 209.912387, hgln: -0.051409, hglt: -6.758404 },
  { isoDate: "2008-02-14T00:00:00Z", dsun: 209.950187, hgln: -0.042388, hglt: -6.80672 },
  { isoDate: "2008-02-15T00:00:00Z", dsun: 209.989326, hgln: -0.033227, hglt: -6.852824 },
  { isoDate: "2008-02-16T00:00:00Z", dsun: 210.029797, hgln: -0.023938, hglt: -6.896703 },
  { isoDate: "2008-02-17T00:00:00Z", dsun: 210.071588, hgln: -0.014541, hglt: -6.938343 },
  { isoDate: "2008-02-18T00:00:00Z", dsun: 210.114689, hgln: -0.005058, hglt: -6.977733 },
  { isoDate: "2008-02-19T00:00:00Z", dsun: 210.159087, hgln: 0.004485, hglt: -7.014861 },
  { isoDate: "2008-02-20T00:00:00Z", dsun: 210.204764, hgln: 0.014058, hglt: -7.049718 },
  { isoDate: "2008-02-21T00:00:00Z", dsun: 210.2517, hgln: 0.023632, hglt: -7.082294 },
  { isoDate: "2008-02-22T00:00:00Z", dsun: 210.299886, hgln: 0.033176, hglt: -7.112581 },
  { isoDate: "2008-02-23T00:00:00Z", dsun: 210.349301, hgln: 0.04266, hglt: -7.140572 },
  { isoDate: "2008-02-24T00:00:00Z", dsun: 210.399927, hgln: 0.052058, hglt: -7.166261 },
  { isoDate: "2008-02-25T00:00:00Z", dsun: 210.451741, hgln: 0.061345, hglt: -7.189642 },
  { isoDate: "2008-02-26T00:00:00Z", dsun: 210.504719, hgln: 0.070498, hglt: -7.210712 },
  { isoDate: "2008-02-27T00:00:00Z", dsun: 210.558836, hgln: 0.079498, hglt: -7.229466 },
  { isoDate: "2008-02-28T00:00:00Z", dsun: 210.614067, hgln: 0.088327, hglt: -7.245904 },
  { isoDate: "2008-02-29T00:00:00Z", dsun: 210.670383, hgln: 0.09697, hglt: -7.260022 },
  { isoDate: "2008-03-01T00:00:00Z", dsun: 210.727759, hgln: 0.105415, hglt: -7.271821 },
  { isoDate: "2008-03-02T00:00:00Z", dsun: 210.786165, hgln: 0.113652, hglt: -7.281301 },
  { isoDate: "2008-03-03T00:00:00Z", dsun: 210.845573, hgln: 0.121672, hglt: -7.288465 },
  { isoDate: "2008-03-04T00:00:00Z", dsun: 210.905952, hgln: 0.129471, hglt: -7.293313 },
  { isoDate: "2008-03-05T00:00:00Z", dsun: 210.967274, hgln: 0.137046, hglt: -7.295849 },
  { isoDate: "2008-03-06T00:00:00Z", dsun: 211.029505, hgln: 0.1444, hglt: -7.296077 },
  { isoDate: "2008-03-07T00:00:00Z", dsun: 211.092615, hgln: 0.151536, hglt: -7.294003 },
  { isoDate: "2008-03-08T00:00:00Z", dsun: 211.156567, hgln: 0.158463, hglt: -7.289632 },
  { isoDate: "2008-03-09T00:00:00Z", dsun: 211.221327, hgln: 0.165192, hglt: -7.282971 },
  { isoDate: "2008-03-10T00:00:00Z", dsun: 211.286856, hgln: 0.171734, hglt: -7.274028 },
  { isoDate: "2008-03-11T00:00:00Z", dsun: 211.353127, hgln: 0.178105, hglt: -7.262812 },
  { isoDate: "2008-03-12T00:00:00Z", dsun: 211.420082, hgln: 0.184308, hglt: -7.249331 },
  { isoDate: "2008-03-13T00:00:00Z", dsun: 211.487692, hgln: 0.190352, hglt: -7.233595 },
  { isoDate: "2008-03-14T00:00:00Z", dsun: 211.55592, hgln: 0.196238, hglt: -7.215617 },
  { isoDate: "2008-03-15T00:00:00Z", dsun: 211.624728, hgln: 0.201963, hglt: -7.195407 },
  { isoDate: "2008-03-16T00:00:00Z", dsun: 211.694078, hgln: 0.20752, hglt: -7.172979 },
  { isoDate: "2008-03-17T00:00:00Z", dsun: 211.763931, hgln: 0.212894, hglt: -7.148345 },
  { isoDate: "2008-03-18T00:00:00Z", dsun: 211.834248, hgln: 0.218072, hglt: -7.12152 },
  { isoDate: "2008-03-19T00:00:00Z", dsun: 211.90499, hgln: 0.223034, hglt: -7.092518 },
  { isoDate: "2008-03-20T00:00:00Z", dsun: 211.976118, hgln: 0.227761, hglt: -7.061355 },
  { isoDate: "2008-03-21T00:00:00Z", dsun: 212.047592, hgln: 0.23223, hglt: -7.028048 },
  { isoDate: "2008-03-22T00:00:00Z", dsun: 212.119371, hgln: 0.236422, hglt: -6.992613 },
  { isoDate: "2008-03-23T00:00:00Z", dsun: 212.191414, hgln: 0.240315, hglt: -6.955067 },
  { isoDate: "2008-03-24T00:00:00Z", dsun: 212.263681, hgln: 0.243892, hglt: -6.91543 },
  { isoDate: "2008-03-25T00:00:00Z", dsun: 212.336132, hgln: 0.247135, hglt: -6.87372 },
  { isoDate: "2008-03-26T00:00:00Z", dsun: 212.408725, hgln: 0.250029, hglt: -6.829957 },
  { isoDate: "2008-03-27T00:00:00Z", dsun: 212.481422, hgln: 0.252562, hglt: -6.78416 },
  { isoDate: "2008-03-28T00:00:00Z", dsun: 212.554182, hgln: 0.254722, hglt: -6.736351 },
  { isoDate: "2008-03-29T00:00:00Z", dsun: 212.626966, hgln: 0.256502, hglt: -6.686551 },
  { isoDate: "2008-03-30T00:00:00Z", dsun: 212.699736, hgln: 0.257895, hglt: -6.634781 },
  { isoDate: "2008-03-31T00:00:00Z", dsun: 212.772456, hgln: 0.258898, hglt: -6.581065 },
  { isoDate: "2008-04-01T00:00:00Z", dsun: 212.84509, hgln: 0.259509, hglt: -6.525425 },
  { isoDate: "2008-04-02T00:00:00Z", dsun: 212.917601, hgln: 0.259729, hglt: -6.467885 },
  { isoDate: "2008-04-03T00:00:00Z", dsun: 212.989956, hgln: 0.259565, hglt: -6.408468 },
  { isoDate: "2008-04-04T00:00:00Z", dsun: 213.062121, hgln: 0.259026, hglt: -6.347199 },
  { isoDate: "2008-04-05T00:00:00Z", dsun: 213.13406, hgln: 0.258125, hglt: -6.284103 },
  { isoDate: "2008-04-06T00:00:00Z", dsun: 213.20574, hgln: 0.25688, hglt: -6.219205 },
  { isoDate: "2008-04-07T00:00:00Z", dsun: 213.277124, hgln: 0.255314, hglt: -6.152532 },
  { isoDate: "2008-04-08T00:00:00Z", dsun: 213.348176, hgln: 0.253449, hglt: -6.084112 },
  { isoDate: "2008-04-09T00:00:00Z", dsun: 213.418869, hgln: 0.25131, hglt: -6.013966 },
  { isoDate: "2008-04-10T00:00:00Z", dsun: 213.489169, hgln: 0.248917, hglt: -5.942125 },
  { isoDate: "2008-04-11T00:00:00Z", dsun: 213.559046, hgln: 0.246286, hglt: -5.868615 },
  { isoDate: "2008-04-12T00:00:00Z", dsun: 213.628473, hgln: 0.243428, hglt: -5.793464 },
  { isoDate: "2008-04-13T00:00:00Z", dsun: 213.697425, hgln: 0.24035, hglt: -5.716701 },
  { isoDate: "2008-04-14T00:00:00Z", dsun: 213.765876, hgln: 0.237053, hglt: -5.638354 },
  { isoDate: "2008-04-15T00:00:00Z", dsun: 213.833802, hgln: 0.233535, hglt: -5.558451 },
  { isoDate: "2008-04-16T00:00:00Z", dsun: 213.901183, hgln: 0.229789, hglt: -5.477022 },
  { isoDate: "2008-04-17T00:00:00Z", dsun: 213.967995, hgln: 0.22581, hglt: -5.394097 },
  { isoDate: "2008-04-18T00:00:00Z", dsun: 214.03422, hgln: 0.221587, hglt: -5.309705 },
  { isoDate: "2008-04-19T00:00:00Z", dsun: 214.099837, hgln: 0.217111, hglt: -5.223875 },
  { isoDate: "2008-04-20T00:00:00Z", dsun: 214.164828, hgln: 0.212373, hglt: -5.136639 },
  { isoDate: "2008-04-21T00:00:00Z", dsun: 214.229177, hgln: 0.207361, hglt: -5.048026 },
  { isoDate: "2008-04-22T00:00:00Z", dsun: 214.292866, hgln: 0.202068, hglt: -4.958066 },
  { isoDate: "2008-04-23T00:00:00Z", dsun: 214.355881, hgln: 0.196486, hglt: -4.866792 },
  { isoDate: "2008-04-24T00:00:00Z", dsun: 214.418207, hgln: 0.190608, hglt: -4.774233 },
  { isoDate: "2008-04-25T00:00:00Z", dsun: 214.479833, hgln: 0.18443, hglt: -4.680421 },
  { isoDate: "2008-04-26T00:00:00Z", dsun: 214.540747, hgln: 0.177949, hglt: -4.585387 },
  { isoDate: "2008-04-27T00:00:00Z", dsun: 214.600938, hgln: 0.171165, hglt: -4.489162 },
  { isoDate: "2008-04-28T00:00:00Z", dsun: 214.6604, hgln: 0.164079, hglt: -4.391777 },
  { isoDate: "2008-04-29T00:00:00Z", dsun: 214.719124, hgln: 0.156695, hglt: -4.293265 },
  { isoDate: "2008-04-30T00:00:00Z", dsun: 214.777107, hgln: 0.149019, hglt: -4.193656 },
  { isoDate: "2008-05-01T00:00:00Z", dsun: 214.834346, hgln: 0.141063, hglt: -4.092983 },
  { isoDate: "2008-05-02T00:00:00Z", dsun: 214.890839, hgln: 0.132841, hglt: -3.991276 },
  { isoDate: "2008-05-03T00:00:00Z", dsun: 214.946585, hgln: 0.12437, hglt: -3.888568 },
  { isoDate: "2008-05-04T00:00:00Z", dsun: 215.001586, hgln: 0.115676, hglt: -3.78489 },
  { isoDate: "2008-05-05T00:00:00Z", dsun: 215.05584, hgln: 0.106787, hglt: -3.680274 },
  { isoDate: "2008-05-06T00:00:00Z", dsun: 215.109344, hgln: 0.097735, hglt: -3.574752 },
  { isoDate: "2008-05-07T00:00:00Z", dsun: 215.162093, hgln: 0.088556, hglt: -3.468353 },
  { isoDate: "2008-05-08T00:00:00Z", dsun: 215.214081, hgln: 0.079284, hglt: -3.361111 },
  { isoDate: "2008-05-09T00:00:00Z", dsun: 215.265302, hgln: 0.069952, hglt: -3.253056 },
  { isoDate: "2008-05-10T00:00:00Z", dsun: 215.31575, hgln: 0.060589, hglt: -3.144219 },
  { isoDate: "2008-05-11T00:00:00Z", dsun: 215.365419, hgln: 0.051218, hglt: -3.03463 },
  { isoDate: "2008-05-12T00:00:00Z", dsun: 215.414306, hgln: 0.041857, hglt: -2.92432 },
  { isoDate: "2008-05-13T00:00:00Z", dsun: 215.462402, hgln: 0.032521, hglt: -2.813323 },
  { isoDate: "2008-05-14T00:00:00Z", dsun: 215.509708, hgln: 0.02322, hglt: -2.701663 },
  { isoDate: "2008-05-15T00:00:00Z", dsun: 215.556215, hgln: 0.013962, hglt: -2.589374 },
  { isoDate: "2008-05-16T00:00:00Z", dsun: 215.601917, hgln: 0.004753, hglt: -2.476485 },
  { isoDate: "2008-05-17T00:00:00Z", dsun: 215.646804, hgln: -0.004403, hglt: -2.363026 },
  { isoDate: "2008-05-18T00:00:00Z", dsun: 215.690868, hgln: -0.013505, hglt: -2.249026 },
  { isoDate: "2008-05-19T00:00:00Z", dsun: 215.734097, hgln: -0.022549, hglt: -2.134516 },
  { isoDate: "2008-05-20T00:00:00Z", dsun: 215.77648, hgln: -0.031535, hglt: -2.019524 },
  { isoDate: "2008-05-21T00:00:00Z", dsun: 215.818007, hgln: -0.040463, hglt: -1.904079 },
  { isoDate: "2008-05-22T00:00:00Z", dsun: 215.858656, hgln: -0.049332, hglt: -1.788211 },
  { isoDate: "2008-05-23T00:00:00Z", dsun: 215.898408, hgln: -0.058142, hglt: -1.671948 },
  { isoDate: "2008-05-24T00:00:00Z", dsun: 215.937244, hgln: -0.066891, hglt: -1.55532 },
  { isoDate: "2008-05-25T00:00:00Z", dsun: 215.975144, hgln: -0.075579, hglt: -1.438353 },
  { isoDate: "2008-05-26T00:00:00Z", dsun: 216.012087, hgln: -0.084204, hglt: -1.321078 },
  { isoDate: "2008-05-27T00:00:00Z", dsun: 216.048051, hgln: -0.092761, hglt: -1.203522 },
  { isoDate: "2008-05-28T00:00:00Z", dsun: 216.083013, hgln: -0.101246, hglt: -1.085714 },
  { isoDate: "2008-05-29T00:00:00Z", dsun: 216.11695, hgln: -0.109652, hglt: -0.967681 },
  { isoDate: "2008-05-30T00:00:00Z", dsun: 216.149841, hgln: -0.117969, hglt: -0.849452 },
  { isoDate: "2008-05-31T00:00:00Z", dsun: 216.181664, hgln: -0.126183, hglt: -0.731056 },
  { isoDate: "2008-06-01T00:00:00Z", dsun: 216.212398, hgln: -0.134277, hglt: -0.612519 },
  { isoDate: "2008-06-02T00:00:00Z", dsun: 216.242025, hgln: -0.142229, hglt: -0.493871 },
  { isoDate: "2008-06-03T00:00:00Z", dsun: 216.270527, hgln: -0.150011, hglt: -0.37514 },
  { isoDate: "2008-06-04T00:00:00Z", dsun: 216.297881, hgln: -0.157596, hglt: -0.256352 },
  { isoDate: "2008-06-05T00:00:00Z", dsun: 216.324085, hgln: -0.16495, hglt: -0.137538 },
  { isoDate: "2008-06-06T00:00:00Z", dsun: 216.349117, hgln: -0.172047, hglt: -0.018726 },
  { isoDate: "2008-06-07T00:00:00Z", dsun: 216.372962, hgln: -0.17886, hglt: 0.100056 },
  { isoDate: "2008-06-08T00:00:00Z", dsun: 216.395601, hgln: -0.18537, hglt: 0.21878 },
  { isoDate: "2008-06-09T00:00:00Z", dsun: 216.417018, hgln: -0.191561, hglt: 0.337417 },
  { isoDate: "2008-06-10T00:00:00Z", dsun: 216.437199, hgln: -0.197425, hglt: 0.45594 },
  { isoDate: "2008-06-11T00:00:00Z", dsun: 216.456128, hgln: -0.202954, hglt: 0.574318 },
  { isoDate: "2008-06-12T00:00:00Z", dsun: 216.473795, hgln: -0.208146, hglt: 0.692524 },
  { isoDate: "2008-06-13T00:00:00Z", dsun: 216.490191, hgln: -0.213004, hglt: 0.810529 },
  { isoDate: "2008-06-14T00:00:00Z", dsun: 216.505307, hgln: -0.217529, hglt: 0.928305 },
  { isoDate: "2008-06-15T00:00:00Z", dsun: 216.519139, hgln: -0.221727, hglt: 1.045822 },
  { isoDate: "2008-06-16T00:00:00Z", dsun: 216.531683, hgln: -0.225605, hglt: 1.163053 },
  { isoDate: "2008-06-17T00:00:00Z", dsun: 216.542937, hgln: -0.229172, hglt: 1.279968 },
  { isoDate: "2008-06-18T00:00:00Z", dsun: 216.552899, hgln: -0.232437, hglt: 1.396538 },
  { isoDate: "2008-06-19T00:00:00Z", dsun: 216.561571, hgln: -0.235412, hglt: 1.512735 },
  { isoDate: "2008-06-20T00:00:00Z", dsun: 216.568954, hgln: -0.238107, hglt: 1.62853 },
  { isoDate: "2008-06-21T00:00:00Z", dsun: 216.57505, hgln: -0.240537, hglt: 1.743893 },
  { isoDate: "2008-06-22T00:00:00Z", dsun: 216.579865, hgln: -0.242711, hglt: 1.858797 },
  { isoDate: "2008-06-23T00:00:00Z", dsun: 216.583403, hgln: -0.244644, hglt: 1.973211 },
  { isoDate: "2008-06-24T00:00:00Z", dsun: 216.585669, hgln: -0.246346, hglt: 2.087106 },
  { isoDate: "2008-06-25T00:00:00Z", dsun: 216.58667, hgln: -0.247828, hglt: 2.200455 },
  { isoDate: "2008-06-26T00:00:00Z", dsun: 216.586415, hgln: -0.249098, hglt: 2.313228 },
  { isoDate: "2008-06-27T00:00:00Z", dsun: 216.58491, hgln: -0.250161, hglt: 2.425396 },
  { isoDate: "2008-06-28T00:00:00Z", dsun: 216.582167, hgln: -0.25102, hglt: 2.53693 },
  { isoDate: "2008-06-29T00:00:00Z", dsun: 216.578195, hgln: -0.251673, hglt: 2.647801 },
  { isoDate: "2008-06-30T00:00:00Z", dsun: 216.573008, hgln: -0.252114, hglt: 2.75798 },
  { isoDate: "2008-07-01T00:00:00Z", dsun: 216.566617, hgln: -0.252332, hglt: 2.86744 },
  { isoDate: "2008-07-02T00:00:00Z", dsun: 216.559039, hgln: -0.252309, hglt: 2.97615 },
  { isoDate: "2008-07-03T00:00:00Z", dsun: 216.55029, hgln: -0.252026, hglt: 3.084083 },
  { isoDate: "2008-07-04T00:00:00Z", dsun: 216.540387, hgln: -0.251462, hglt: 3.19121 },
  { isoDate: "2008-07-05T00:00:00Z", dsun: 216.529349, hgln: -0.250597, hglt: 3.297502 },
  { isoDate: "2008-07-06T00:00:00Z", dsun: 216.517195, hgln: -0.249414, hglt: 3.402931 },
  { isoDate: "2008-07-07T00:00:00Z", dsun: 216.503942, hgln: -0.247901, hglt: 3.507469 },
  { isoDate: "2008-07-08T00:00:00Z", dsun: 216.489613, hgln: -0.246049, hglt: 3.611086 },
  { isoDate: "2008-07-09T00:00:00Z", dsun: 216.47422, hgln: -0.243855, hglt: 3.713756 },
  { isoDate: "2008-07-10T00:00:00Z", dsun: 216.457784, hgln: -0.241319, hglt: 3.81545 },
  { isoDate: "2008-07-11T00:00:00Z", dsun: 216.440324, hgln: -0.238443, hglt: 3.91614 },
  { isoDate: "2008-07-12T00:00:00Z", dsun: 216.421861, hgln: -0.235233, hglt: 4.015798 },
  { isoDate: "2008-07-13T00:00:00Z", dsun: 216.402417, hgln: -0.231697, hglt: 4.114397 },
  { isoDate: "2008-07-14T00:00:00Z", dsun: 216.382012, hgln: -0.227846, hglt: 4.21191 },
  { isoDate: "2008-07-15T00:00:00Z", dsun: 216.36067, hgln: -0.22369, hglt: 4.308308 },
  { isoDate: "2008-07-16T00:00:00Z", dsun: 216.338415, hgln: -0.219244, hglt: 4.403565 },
  { isoDate: "2008-07-17T00:00:00Z", dsun: 216.315269, hgln: -0.214523, hglt: 4.497654 },
  { isoDate: "2008-07-18T00:00:00Z", dsun: 216.291256, hgln: -0.209544, hglt: 4.590549 },
  { isoDate: "2008-07-19T00:00:00Z", dsun: 216.266401, hgln: -0.204326, hglt: 4.682221 },
  { isoDate: "2008-07-20T00:00:00Z", dsun: 216.240727, hgln: -0.198888, hglt: 4.772646 },
  { isoDate: "2008-07-21T00:00:00Z", dsun: 216.214257, hgln: -0.193251, hglt: 4.861797 },
  { isoDate: "2008-07-22T00:00:00Z", dsun: 216.187016, hgln: -0.187434, hglt: 4.949648 },
  { isoDate: "2008-07-23T00:00:00Z", dsun: 216.159025, hgln: -0.181458, hglt: 5.036174 },
  { isoDate: "2008-07-24T00:00:00Z", dsun: 216.130309, hgln: -0.175342, hglt: 5.121348 },
  { isoDate: "2008-07-25T00:00:00Z", dsun: 216.100889, hgln: -0.1691, hglt: 5.205145 },
  { isoDate: "2008-07-26T00:00:00Z", dsun: 216.070789, hgln: -0.162748, hglt: 5.287541 },
  { isoDate: "2008-07-27T00:00:00Z", dsun: 216.04003, hgln: -0.156293, hglt: 5.368511 },
  { isoDate: "2008-07-28T00:00:00Z", dsun: 216.008635, hgln: -0.149742, hglt: 5.448031 },
  { isoDate: "2008-07-29T00:00:00Z", dsun: 215.976626, hgln: -0.143094, hglt: 5.526076 },
  { isoDate: "2008-07-30T00:00:00Z", dsun: 215.944025, hgln: -0.136345, hglt: 5.602623 },
  { isoDate: "2008-07-31T00:00:00Z", dsun: 215.910856, hgln: -0.129486, hglt: 5.677648 },
  { isoDate: "2008-08-01T00:00:00Z", dsun: 215.877142, hgln: -0.122505, hglt: 5.751128 },
  { isoDate: "2008-08-02T00:00:00Z", dsun: 215.842904, hgln: -0.11539, hglt: 5.823041 },
  { isoDate: "2008-08-03T00:00:00Z", dsun: 215.808166, hgln: -0.108127, hglt: 5.893364 },
  { isoDate: "2008-08-04T00:00:00Z", dsun: 215.772946, hgln: -0.100708, hglt: 5.962075 },
  { isoDate: "2008-08-05T00:00:00Z", dsun: 215.737264, hgln: -0.093126, hglt: 6.029153 },
  { isoDate: "2008-08-06T00:00:00Z", dsun: 215.701138, hgln: -0.085377, hglt: 6.094575 },
  { isoDate: "2008-08-07T00:00:00Z", dsun: 215.664585, hgln: -0.077463, hglt: 6.158321 },
  { isoDate: "2008-08-08T00:00:00Z", dsun: 215.627623, hgln: -0.069386, hglt: 6.22037 },
  { isoDate: "2008-08-09T00:00:00Z", dsun: 215.590268, hgln: -0.061154, hglt: 6.280703 },
  { isoDate: "2008-08-10T00:00:00Z", dsun: 215.552537, hgln: -0.052776, hglt: 6.339299 },
  { isoDate: "2008-08-11T00:00:00Z", dsun: 215.514446, hgln: -0.044263, hglt: 6.39614 },
  { isoDate: "2008-08-12T00:00:00Z", dsun: 215.47601, hgln: -0.035626, hglt: 6.451206 },
  { isoDate: "2008-08-13T00:00:00Z", dsun: 215.437247, hgln: -0.026886, hglt: 6.504479 },
  { isoDate: "2008-08-14T00:00:00Z", dsun: 215.398171, hgln: -0.018058, hglt: 6.555943 },
  { isoDate: "2008-08-15T00:00:00Z", dsun: 215.358795, hgln: -0.009163, hglt: 6.605578 },
  { isoDate: "2008-08-16T00:00:00Z", dsun: 215.319132, hgln: -0.000222, hglt: 6.653369 },
  { isoDate: "2008-08-17T00:00:00Z", dsun: 215.279196, hgln: 0.00874, hglt: 6.699299 },
  { isoDate: "2008-08-18T00:00:00Z", dsun: 215.238996, hgln: 0.017698, hglt: 6.743352 },
  { isoDate: "2008-08-19T00:00:00Z", dsun: 215.198543, hgln: 0.026624, hglt: 6.785513 },
  { isoDate: "2008-08-20T00:00:00Z", dsun: 215.157845, hgln: 0.035493, hglt: 6.825766 },
  { isoDate: "2008-08-21T00:00:00Z", dsun: 215.116912, hgln: 0.044276, hglt: 6.864098 },
  { isoDate: "2008-08-22T00:00:00Z", dsun: 215.075747, hgln: 0.052951, hglt: 6.900495 },
  { isoDate: "2008-08-23T00:00:00Z", dsun: 215.034355, hgln: 0.061497, hglt: 6.934943 },
  { isoDate: "2008-08-24T00:00:00Z", dsun: 214.992746, hgln: 0.069896, hglt: 6.96743 },
  { isoDate: "2008-08-25T00:00:00Z", dsun: 214.950926, hgln: 0.078135, hglt: 6.997943 },
  { isoDate: "2008-08-26T00:00:00Z", dsun: 214.9089, hgln: 0.086206, hglt: 7.026472 },
  { isoDate: "2008-08-27T00:00:00Z", dsun: 214.866673, hgln: 0.094107, hglt: 7.053006 },
  { isoDate: "2008-08-28T00:00:00Z", dsun: 214.824251, hgln: 0.10184, hglt: 7.077533 },
  { isoDate: "2008-08-29T00:00:00Z", dsun: 214.781638, hgln: 0.10941, hglt: 7.100045 },
  { isoDate: "2008-08-30T00:00:00Z", dsun: 214.738836, hgln: 0.116827, hglt: 7.120532 },
  { isoDate: "2008-08-31T00:00:00Z", dsun: 214.695848, hgln: 0.124098, hglt: 7.138986 },
  { isoDate: "2008-09-01T00:00:00Z", dsun: 214.652674, hgln: 0.131235, hglt: 7.155398 },
  { isoDate: "2008-09-02T00:00:00Z", dsun: 214.609314, hgln: 0.138246, hglt: 7.169762 },
  { isoDate: "2008-09-03T00:00:00Z", dsun: 214.565767, hgln: 0.145139, hglt: 7.18207 },
  { isoDate: "2008-09-04T00:00:00Z", dsun: 214.522029, hgln: 0.151916, hglt: 7.192317 },
  { isoDate: "2008-09-05T00:00:00Z", dsun: 214.4781, hgln: 0.158581, hglt: 7.200496 },
  { isoDate: "2008-09-06T00:00:00Z", dsun: 214.433977, hgln: 0.165132, hglt: 7.206604 },
  { isoDate: "2008-09-07T00:00:00Z", dsun: 214.389658, hgln: 0.171566, hglt: 7.210635 },
  { isoDate: "2008-09-08T00:00:00Z", dsun: 214.345143, hgln: 0.177879, hglt: 7.212587 },
  { isoDate: "2008-09-09T00:00:00Z", dsun: 214.300434, hgln: 0.184067, hglt: 7.212458 },
  { isoDate: "2008-09-10T00:00:00Z", dsun: 214.255518, hgln: 0.190114, hglt: 7.210242 },
  { isoDate: "2008-09-11T00:00:00Z", dsun: 214.210399, hgln: 0.196013, hglt: 7.20594 },
  { isoDate: "2008-09-12T00:00:00Z", dsun: 214.165072, hgln: 0.20175, hglt: 7.199549 },
  { isoDate: "2008-09-13T00:00:00Z", dsun: 214.119535, hgln: 0.207309, hglt: 7.19107 },
  { isoDate: "2008-09-14T00:00:00Z", dsun: 214.073783, hgln: 0.212673, hglt: 7.180503 },
  { isoDate: "2008-09-15T00:00:00Z", dsun: 214.027814, hgln: 0.21782, hglt: 7.16785 },
  { isoDate: "2008-09-16T00:00:00Z", dsun: 213.981622, hgln: 0.222727, hglt: 7.15311 },
  { isoDate: "2008-09-17T00:00:00Z", dsun: 213.935202, hgln: 0.227369, hglt: 7.136287 },
  { isoDate: "2008-09-18T00:00:00Z", dsun: 213.88855, hgln: 0.231723, hglt: 7.117383 },
  { isoDate: "2008-09-19T00:00:00Z", dsun: 213.84166, hgln: 0.235764, hglt: 7.096402 },
  { isoDate: "2008-09-20T00:00:00Z", dsun: 213.794528, hgln: 0.239472, hglt: 7.073347 },
  { isoDate: "2008-09-21T00:00:00Z", dsun: 213.747149, hgln: 0.24283, hglt: 7.048223 },
  { isoDate: "2008-09-22T00:00:00Z", dsun: 213.699521, hgln: 0.245826, hglt: 7.021036 },
  { isoDate: "2008-09-23T00:00:00Z", dsun: 213.651639, hgln: 0.248454, hglt: 6.991791 },
  { isoDate: "2008-09-24T00:00:00Z", dsun: 213.603501, hgln: 0.250711, hglt: 6.960494 },
  { isoDate: "2008-09-25T00:00:00Z", dsun: 213.555107, hgln: 0.252601, hglt: 6.927154 },
  { isoDate: "2008-09-26T00:00:00Z", dsun: 213.506454, hgln: 0.254131, hglt: 6.891777 },
  { isoDate: "2008-09-27T00:00:00Z", dsun: 213.457543, hgln: 0.255311, hglt: 6.854372 },
  { isoDate: "2008-09-28T00:00:00Z", dsun: 213.408371, hgln: 0.256155, hglt: 6.814949 },
  { isoDate: "2008-09-29T00:00:00Z", dsun: 213.358936, hgln: 0.256677, hglt: 6.773515 },
  { isoDate: "2008-09-30T00:00:00Z", dsun: 213.309237, hgln: 0.256892, hglt: 6.730082 },
  { isoDate: "2008-10-01T00:00:00Z", dsun: 213.259271, hgln: 0.256815, hglt: 6.68466 },
  { isoDate: "2008-10-02T00:00:00Z", dsun: 213.209038, hgln: 0.25646, hglt: 6.63726 },
  { isoDate: "2008-10-03T00:00:00Z", dsun: 213.158536, hgln: 0.255838, hglt: 6.587895 },
  { isoDate: "2008-10-04T00:00:00Z", dsun: 213.107767, hgln: 0.25496, hglt: 6.536576 },
  { isoDate: "2008-10-05T00:00:00Z", dsun: 213.056735, hgln: 0.253835, hglt: 6.483316 },
  { isoDate: "2008-10-06T00:00:00Z", dsun: 213.005442, hgln: 0.252469, hglt: 6.42813 },
  { isoDate: "2008-10-07T00:00:00Z", dsun: 212.953888, hgln: 0.250864, hglt: 6.371031 },
  { isoDate: "2008-10-08T00:00:00Z", dsun: 212.902091, hgln: 0.249028, hglt: 6.312033 },
  { isoDate: "2008-10-09T00:00:00Z", dsun: 212.850051, hgln: 0.24696, hglt: 6.251152 },
  { isoDate: "2008-10-10T00:00:00Z", dsun: 212.797778, hgln: 0.244659, hglt: 6.188403 },
  { isoDate: "2008-10-11T00:00:00Z", dsun: 212.74528, hgln: 0.24212, hglt: 6.123803 },
  { isoDate: "2008-10-12T00:00:00Z", dsun: 212.692567, hgln: 0.239337, hglt: 6.057368 },
  { isoDate: "2008-10-13T00:00:00Z", dsun: 212.639649, hgln: 0.236301, hglt: 5.989116 },
  { isoDate: "2008-10-14T00:00:00Z", dsun: 212.586537, hgln: 0.232997, hglt: 5.919063 },
  { isoDate: "2008-10-15T00:00:00Z", dsun: 212.533245, hgln: 0.229411, hglt: 5.847229 },
  { isoDate: "2008-10-16T00:00:00Z", dsun: 212.479785, hgln: 0.225522, hglt: 5.773631 },
  { isoDate: "2008-10-17T00:00:00Z", dsun: 212.42617, hgln: 0.221313, hglt: 5.698289 },
  { isoDate: "2008-10-18T00:00:00Z", dsun: 212.372417, hgln: 0.216766, hglt: 5.621222 },
  { isoDate: "2008-10-19T00:00:00Z", dsun: 212.318541, hgln: 0.211867, hglt: 5.542451 },
  { isoDate: "2008-10-20T00:00:00Z", dsun: 212.264561, hgln: 0.206607, hglt: 5.461995 },
  { isoDate: "2008-10-21T00:00:00Z", dsun: 212.210496, hgln: 0.200982, hglt: 5.379875 },
  { isoDate: "2008-10-22T00:00:00Z", dsun: 212.156368, hgln: 0.194995, hglt: 5.296113 },
  { isoDate: "2008-10-23T00:00:00Z", dsun: 212.102202, hgln: 0.188653, hglt: 5.210731 },
  { isoDate: "2008-10-24T00:00:00Z", dsun: 212.048022, hgln: 0.181969, hglt: 5.12375 },
  { isoDate: "2008-10-25T00:00:00Z", dsun: 211.993858, hgln: 0.174958, hglt: 5.035193 },
  { isoDate: "2008-10-26T00:00:00Z", dsun: 211.939738, hgln: 0.167638, hglt: 4.945082 },
  { isoDate: "2008-10-27T00:00:00Z", dsun: 211.885691, hgln: 0.16003, hglt: 4.853442 },
  { isoDate: "2008-10-28T00:00:00Z", dsun: 211.831747, hgln: 0.152158, hglt: 4.760294 },
  { isoDate: "2008-10-29T00:00:00Z", dsun: 211.777933, hgln: 0.144042, hglt: 4.665664 },
  { isoDate: "2008-10-30T00:00:00Z", dsun: 211.724277, hgln: 0.135708, hglt: 4.569576 },
  { isoDate: "2008-10-31T00:00:00Z", dsun: 211.670803, hgln: 0.127178, hglt: 4.472055 },
  { isoDate: "2008-11-01T00:00:00Z", dsun: 211.617539, hgln: 0.118478, hglt: 4.373127 },
  { isoDate: "2008-11-02T00:00:00Z", dsun: 211.56451, hgln: 0.109629, hglt: 4.272818 },
  { isoDate: "2008-11-03T00:00:00Z", dsun: 211.511744, hgln: 0.100655, hglt: 4.171154 },
  { isoDate: "2008-11-04T00:00:00Z", dsun: 211.45927, hgln: 0.091577, hglt: 4.068161 },
  { isoDate: "2008-11-05T00:00:00Z", dsun: 211.407116, hgln: 0.082414, hglt: 3.963867 },
  { isoDate: "2008-11-06T00:00:00Z", dsun: 211.35531, hgln: 0.073187, hglt: 3.858298 },
  { isoDate: "2008-11-07T00:00:00Z", dsun: 211.303883, hgln: 0.063913, hglt: 3.751484 },
  { isoDate: "2008-11-08T00:00:00Z", dsun: 211.25286, hgln: 0.054607, hglt: 3.643452 },
  { isoDate: "2008-11-09T00:00:00Z", dsun: 211.20227, hgln: 0.045282, hglt: 3.534231 },
  { isoDate: "2008-11-10T00:00:00Z", dsun: 211.152138, hgln: 0.035947, hglt: 3.423851 },
  { isoDate: "2008-11-11T00:00:00Z", dsun: 211.102482, hgln: 0.026607, hglt: 3.31234 },
  { isoDate: "2008-11-12T00:00:00Z", dsun: 211.053333, hgln: 0.017266, hglt: 3.19973 },
  { isoDate: "2008-11-13T00:00:00Z", dsun: 211.004706, hgln: 0.007919, hglt: 3.086051 },
  { isoDate: "2008-11-14T00:00:00Z", dsun: 210.956619, hgln: -0.001439, hglt: 2.971335 },
  { isoDate: "2008-11-15T00:00:00Z", dsun: 210.909085, hgln: -0.010815, hglt: 2.855614 },
  { isoDate: "2008-11-16T00:00:00Z", dsun: 210.862118, hgln: -0.020217, hglt: 2.73892 },
  { isoDate: "2008-11-17T00:00:00Z", dsun: 210.815725, hgln: -0.029645, hglt: 2.621287 },
  { isoDate: "2008-11-18T00:00:00Z", dsun: 210.769915, hgln: -0.0391, hglt: 2.502749 },
  { isoDate: "2008-11-19T00:00:00Z", dsun: 210.724694, hgln: -0.048572, hglt: 2.383338 },
  { isoDate: "2008-11-20T00:00:00Z", dsun: 210.680066, hgln: -0.05805, hglt: 2.26309 },
  { isoDate: "2008-11-21T00:00:00Z", dsun: 210.636033, hgln: -0.067517, hglt: 2.14204 },
  { isoDate: "2008-11-22T00:00:00Z", dsun: 210.592596, hgln: -0.076952, hglt: 2.020225 },
  { isoDate: "2008-11-23T00:00:00Z", dsun: 210.549757, hgln: -0.086334, hglt: 1.89768 },
  { isoDate: "2008-11-24T00:00:00Z", dsun: 210.507516, hgln: -0.095637, hglt: 1.774442 },
  { isoDate: "2008-11-25T00:00:00Z", dsun: 210.465876, hgln: -0.104836, hglt: 1.650549 },
  { isoDate: "2008-11-26T00:00:00Z", dsun: 210.424838, hgln: -0.113903, hglt: 1.526037 },
  { isoDate: "2008-11-27T00:00:00Z", dsun: 210.384405, hgln: -0.122813, hglt: 1.400944 },
  { isoDate: "2008-11-28T00:00:00Z", dsun: 210.34458, hgln: -0.13154, hglt: 1.275309 },
  { isoDate: "2008-11-29T00:00:00Z", dsun: 210.305365, hgln: -0.140059, hglt: 1.149172 },
  { isoDate: "2008-11-30T00:00:00Z", dsun: 210.266762, hgln: -0.148346, hglt: 1.02257 },
  { isoDate: "2008-12-01T00:00:00Z", dsun: 210.22877, hgln: -0.15638, hglt: 0.895545 },
  { isoDate: "2008-12-02T00:00:00Z", dsun: 210.191387, hgln: -0.164141, hglt: 0.768136 },
  { isoDate: "2008-12-03T00:00:00Z", dsun: 210.15461, hgln: -0.171609, hglt: 0.640385 },
  { isoDate: "2008-12-04T00:00:00Z", dsun: 210.118438, hgln: -0.178767, hglt: 0.512332 },
  { isoDate: "2008-12-05T00:00:00Z", dsun: 210.082869, hgln: -0.1856, hglt: 0.384018 },
  { isoDate: "2008-12-06T00:00:00Z", dsun: 210.047902, hgln: -0.192094, hglt: 0.255486 },
  { isoDate: "2008-12-07T00:00:00Z", dsun: 210.013541, hgln: -0.198239, hglt: 0.126775 },
  { isoDate: "2008-12-08T00:00:00Z", dsun: 209.97979, hgln: -0.204027, hglt: -0.002072 },
  { isoDate: "2008-12-09T00:00:00Z", dsun: 209.946404, hgln: -0.209467, hglt: -0.130989 },
  { isoDate: "2008-12-10T00:00:00Z", dsun: 209.913872, hgln: -0.214538, hglt: -0.259982 },
  { isoDate: "2008-12-11T00:00:00Z", dsun: 209.881972, hgln: -0.219258, hglt: -0.388985 },
  { isoDate: "2008-12-12T00:00:00Z", dsun: 209.850714, hgln: -0.223641, hglt: -0.517956 },
  { isoDate: "2008-12-13T00:00:00Z", dsun: 209.820109, hgln: -0.227705, hglt: -0.646853 },
  { isoDate: "2008-12-14T00:00:00Z", dsun: 209.790169, hgln: -0.231469, hglt: -0.775632 },
  { isoDate: "2008-12-15T00:00:00Z", dsun: 209.760908, hgln: -0.234954, hglt: -0.904252 },
  { isoDate: "2008-12-16T00:00:00Z", dsun: 209.73234, hgln: -0.238175, hglt: -1.03267 },
  { isoDate: "2008-12-17T00:00:00Z", dsun: 209.704477, hgln: -0.241145, hglt: -1.160842 },
  { isoDate: "2008-12-18T00:00:00Z", dsun: 209.677336, hgln: -0.243873, hglt: -1.288726 },
  { isoDate: "2008-12-19T00:00:00Z", dsun: 209.650931, hgln: -0.246361, hglt: -1.416279 },
  { isoDate: "2008-12-20T00:00:00Z", dsun: 209.625279, hgln: -0.248607, hglt: -1.543458 },
  { isoDate: "2008-12-21T00:00:00Z", dsun: 209.600396, hgln: -0.250607, hglt: -1.670221 },
  { isoDate: "2008-12-22T00:00:00Z", dsun: 209.576299, hgln: -0.252354, hglt: -1.796524 },
  { isoDate: "2008-12-23T00:00:00Z", dsun: 209.553007, hgln: -0.253839, hglt: -1.922325 },
  { isoDate: "2008-12-24T00:00:00Z", dsun: 209.53054, hgln: -0.255052, hglt: -2.047582 },
  { isoDate: "2008-12-25T00:00:00Z", dsun: 209.508917, hgln: -0.255982, hglt: -2.172251 },
  { isoDate: "2008-12-26T00:00:00Z", dsun: 209.48816, hgln: -0.256615, hglt: -2.296291 },
  { isoDate: "2008-12-27T00:00:00Z", dsun: 209.468292, hgln: -0.256939, hglt: -2.419659 },
  { isoDate: "2008-12-28T00:00:00Z", dsun: 209.449337, hgln: -0.256943, hglt: -2.542312 },
  { isoDate: "2008-12-29T00:00:00Z", dsun: 209.431319, hgln: -0.256613, hglt: -2.66421 },
  { isoDate: "2008-12-30T00:00:00Z", dsun: 209.414261, hgln: -0.255937, hglt: -2.78531 },
  { isoDate: "2008-12-31T00:00:00Z", dsun: 209.398188, hgln: -0.254904, hglt: -2.905569 },
  { isoDate: "2009-01-01T00:00:00Z", dsun: 209.38312, hgln: -0.253505, hglt: -3.024948 },
  { isoDate: "2009-01-02T00:00:00Z", dsun: 209.369081, hgln: -0.25173, hglt: -3.143403 },
  { isoDate: "2009-01-03T00:00:00Z", dsun: 209.356091, hgln: -0.249573, hglt: -3.260895 },
  { isoDate: "2009-01-04T00:00:00Z", dsun: 209.344171, hgln: -0.247029, hglt: -3.377381 },
  { isoDate: "2009-01-05T00:00:00Z", dsun: 209.333342, hgln: -0.244095, hglt: -3.492822 },
  { isoDate: "2009-01-06T00:00:00Z", dsun: 209.323625, hgln: -0.240772, hglt: -3.607178 },
  { isoDate: "2009-01-07T00:00:00Z", dsun: 209.315043, hgln: -0.237065, hglt: -3.720408 },
  { isoDate: "2009-01-08T00:00:00Z", dsun: 209.307618, hgln: -0.232984, hglt: -3.832474 },
  { isoDate: "2009-01-09T00:00:00Z", dsun: 209.301372, hgln: -0.228544, hglt: -3.943335 },
  { isoDate: "2009-01-10T00:00:00Z", dsun: 209.296327, hgln: -0.223769, hglt: -4.052954 },
  { isoDate: "2009-01-11T00:00:00Z", dsun: 209.292504, hgln: -0.218683, hglt: -4.161292 },
  { isoDate: "2009-01-12T00:00:00Z", dsun: 209.289925, hgln: -0.213315, hglt: -4.26831 },
  { isoDate: "2009-01-13T00:00:00Z", dsun: 209.288607, hgln: -0.207693, hglt: -4.373973 },
  { isoDate: "2009-01-14T00:00:00Z", dsun: 209.288576, hgln: -0.201847, hglt: -4.47824 },
  { isoDate: "2009-01-15T00:00:00Z", dsun: 209.289846, hgln: -0.1958, hglt: -4.581076 },
  { isoDate: "2009-01-16T00:00:00Z", dsun: 209.292436, hgln: -0.189568, hglt: -4.682445 },
  { isoDate: "2009-01-17T00:00:00Z", dsun: 209.296361, hgln: -0.183167, hglt: -4.782312 },
  { isoDate: "2009-01-18T00:00:00Z", dsun: 209.301638, hgln: -0.176607, hglt: -4.88064 },
  { isoDate: "2009-01-19T00:00:00Z", dsun: 209.30828, hgln: -0.169896, hglt: -4.977397 },
  { isoDate: "2009-01-20T00:00:00Z", dsun: 209.316301, hgln: -0.163038, hglt: -5.072547 },
  { isoDate: "2009-01-21T00:00:00Z", dsun: 209.325715, hgln: -0.156037, hglt: -5.166058 },
  { isoDate: "2009-01-22T00:00:00Z", dsun: 209.336534, hgln: -0.148893, hglt: -5.257896 },
  { isoDate: "2009-01-23T00:00:00Z", dsun: 209.34877, hgln: -0.141608, hglt: -5.34803 },
  { isoDate: "2009-01-24T00:00:00Z", dsun: 209.362433, hgln: -0.13418, hglt: -5.436428 },
  { isoDate: "2009-01-25T00:00:00Z", dsun: 209.377536, hgln: -0.126607, hglt: -5.523059 },
  { isoDate: "2009-01-26T00:00:00Z", dsun: 209.394087, hgln: -0.118886, hglt: -5.607894 },
  { isoDate: "2009-01-27T00:00:00Z", dsun: 209.412094, hgln: -0.111016, hglt: -5.690902 },
  { isoDate: "2009-01-28T00:00:00Z", dsun: 209.431565, hgln: -0.102991, hglt: -5.772056 },
  { isoDate: "2009-01-29T00:00:00Z", dsun: 209.452503, hgln: -0.094808, hglt: -5.851326 },
  { isoDate: "2009-01-30T00:00:00Z", dsun: 209.47491, hgln: -0.086465, hglt: -5.928687 },
  { isoDate: "2009-01-31T00:00:00Z", dsun: 209.498786, hgln: -0.07796, hglt: -6.004111 },
  { isoDate: "2009-02-01T00:00:00Z", dsun: 209.524129, hgln: -0.06929, hglt: -6.077574 },
  { isoDate: "2009-02-02T00:00:00Z", dsun: 209.550939, hgln: -0.060457, hglt: -6.14905 },
  { isoDate: "2009-02-03T00:00:00Z", dsun: 209.579211, hgln: -0.051466, hglt: -6.218517 },
  { isoDate: "2009-02-04T00:00:00Z", dsun: 209.608941, hgln: -0.042322, hglt: -6.28595 },
  { isoDate: "2009-02-05T00:00:00Z", dsun: 209.640124, hgln: -0.033038, hglt: -6.351329 },
  { isoDate: "2009-02-06T00:00:00Z", dsun: 209.672755, hgln: -0.023631, hglt: -6.414631 },
  { isoDate: "2009-02-07T00:00:00Z", dsun: 209.706826, hgln: -0.014123, hglt: -6.475836 },
  { isoDate: "2009-02-08T00:00:00Z", dsun: 209.742327, hgln: -0.004542, hglt: -6.534925 },
  { isoDate: "2009-02-09T00:00:00Z", dsun: 209.779249, hgln: 0.005081, hglt: -6.591879 },
  { isoDate: "2009-02-10T00:00:00Z", dsun: 209.817578, hgln: 0.014714, hglt: -6.646681 },
  { isoDate: "2009-02-11T00:00:00Z", dsun: 209.857301, hgln: 0.024319, hglt: -6.699314 },
  { isoDate: "2009-02-12T00:00:00Z", dsun: 209.898403, hgln: 0.033864, hglt: -6.749761 },
  { isoDate: "2009-02-13T00:00:00Z", dsun: 209.940864, hgln: 0.04332, hglt: -6.798009 },
  { isoDate: "2009-02-14T00:00:00Z", dsun: 209.984663, hgln: 0.052663, hglt: -6.844042 },
  { isoDate: "2009-02-15T00:00:00Z", dsun: 210.029784, hgln: 0.061871, hglt: -6.887849 },
  { isoDate: "2009-02-16T00:00:00Z", dsun: 210.076204, hgln: 0.070928, hglt: -6.929418 },
  { isoDate: "2009-02-17T00:00:00Z", dsun: 210.1239, hgln: 0.07982, hglt: -6.968737 },
  { isoDate: "2009-02-18T00:00:00Z", dsun: 210.17285, hgln: 0.088536, hglt: -7.005796 },
  { isoDate: "2009-02-19T00:00:00Z", dsun: 210.223028, hgln: 0.097068, hglt: -7.040587 },
  { isoDate: "2009-02-20T00:00:00Z", dsun: 210.274409, hgln: 0.105409, hglt: -7.073101 },
  { isoDate: "2009-02-21T00:00:00Z", dsun: 210.326968, hgln: 0.113555, hglt: -7.103331 },
  { isoDate: "2009-02-22T00:00:00Z", dsun: 210.380677, hgln: 0.121504, hglt: -7.13127 },
  { isoDate: "2009-02-23T00:00:00Z", dsun: 210.435508, hgln: 0.129255, hglt: -7.156914 },
  { isoDate: "2009-02-24T00:00:00Z", dsun: 210.491433, hgln: 0.136811, hglt: -7.180258 },
  { isoDate: "2009-02-25T00:00:00Z", dsun: 210.548419, hgln: 0.144173, hglt: -7.201298 },
  { isoDate: "2009-02-26T00:00:00Z", dsun: 210.606434, hgln: 0.151349, hglt: -7.220033 },
  { isoDate: "2009-02-27T00:00:00Z", dsun: 210.665442, hgln: 0.158344, hglt: -7.23646 },
  { isoDate: "2009-02-28T00:00:00Z", dsun: 210.725409, hgln: 0.165166, hglt: -7.250579 },
  { isoDate: "2009-03-01T00:00:00Z", dsun: 210.786298, hgln: 0.171822, hglt: -7.26239 },
  { isoDate: "2009-03-02T00:00:00Z", dsun: 210.848072, hgln: 0.17832, hglt: -7.271896 },
  { isoDate: "2009-03-03T00:00:00Z", dsun: 210.910696, hgln: 0.184662, hglt: -7.279097 },
  { isoDate: "2009-03-04T00:00:00Z", dsun: 210.974132, hgln: 0.19085, hglt: -7.283998 },
  { isoDate: "2009-03-05T00:00:00Z", dsun: 211.038344, hgln: 0.196883, hglt: -7.286602 },
  { isoDate: "2009-03-06T00:00:00Z", dsun: 211.103294, hgln: 0.202753, hglt: -7.286914 },
  { isoDate: "2009-03-07T00:00:00Z", dsun: 211.168946, hgln: 0.208449, hglt: -7.284939 },
  { isoDate: "2009-03-08T00:00:00Z", dsun: 211.235261, hgln: 0.213955, hglt: -7.280685 },
  { isoDate: "2009-03-09T00:00:00Z", dsun: 211.302201, hgln: 0.219249, hglt: -7.274158 },
  { isoDate: "2009-03-10T00:00:00Z", dsun: 211.36973, hgln: 0.22431, hglt: -7.265365 },
  { isoDate: "2009-03-11T00:00:00Z", dsun: 211.437801, hgln: 0.229109, hglt: -7.254318 },
  { isoDate: "2009-03-12T00:00:00Z", dsun: 211.506378, hgln: 0.233623, hglt: -7.241026 },
  { isoDate: "2009-03-13T00:00:00Z", dsun: 211.575421, hgln: 0.237825, hglt: -7.225499 },
  { isoDate: "2009-03-14T00:00:00Z", dsun: 211.644888, hgln: 0.241697, hglt: -7.207749 },
  { isoDate: "2009-03-15T00:00:00Z", dsun: 211.714739, hgln: 0.245218, hglt: -7.187788 },
  { isoDate: "2009-03-16T00:00:00Z", dsun: 211.784934, hgln: 0.248376, hglt: -7.165628 },
  { isoDate: "2009-03-17T00:00:00Z", dsun: 211.855433, hgln: 0.251159, hglt: -7.141283 },
  { isoDate: "2009-03-18T00:00:00Z", dsun: 211.926196, hgln: 0.253559, hglt: -7.114768 },
  { isoDate: "2009-03-19T00:00:00Z", dsun: 211.997185, hgln: 0.255573, hglt: -7.086098 },
  { isoDate: "2009-03-20T00:00:00Z", dsun: 212.068362, hgln: 0.257197, hglt: -7.055289 },
  { isoDate: "2009-03-21T00:00:00Z", dsun: 212.139689, hgln: 0.258431, hglt: -7.022356 },
  { isoDate: "2009-03-22T00:00:00Z", dsun: 212.211131, hgln: 0.259279, hglt: -6.987318 },
  { isoDate: "2009-03-23T00:00:00Z", dsun: 212.282652, hgln: 0.259745, hglt: -6.950191 },
  { isoDate: "2009-03-24T00:00:00Z", dsun: 212.354216, hgln: 0.259836, hglt: -6.910995 },
  { isoDate: "2009-03-25T00:00:00Z", dsun: 212.425788, hgln: 0.259562, hglt: -6.869747 },
  { isoDate: "2009-03-26T00:00:00Z", dsun: 212.49733, hgln: 0.258936, hglt: -6.826468 },
  { isoDate: "2009-03-27T00:00:00Z", dsun: 212.568807, hgln: 0.257975, hglt: -6.781178 },
  { isoDate: "2009-03-28T00:00:00Z", dsun: 212.640182, hgln: 0.256695, hglt: -6.733898 },
  { isoDate: "2009-03-29T00:00:00Z", dsun: 212.711421, hgln: 0.255118, hglt: -6.684648 },
  { isoDate: "2009-03-30T00:00:00Z", dsun: 212.782489, hgln: 0.253261, hglt: -6.633452 },
  { isoDate: "2009-03-31T00:00:00Z", dsun: 212.853354, hgln: 0.251143, hglt: -6.58033 },
  { isoDate: "2009-04-01T00:00:00Z", dsun: 212.923986, hgln: 0.248781, hglt: -6.525307 },
  { isoDate: "2009-04-02T00:00:00Z", dsun: 212.994357, hgln: 0.246185, hglt: -6.468406 },
  { isoDate: "2009-04-03T00:00:00Z", dsun: 213.064439, hgln: 0.243363, hglt: -6.40965 },
  { isoDate: "2009-04-04T00:00:00Z", dsun: 213.134206, hgln: 0.240318, hglt: -6.349063 },
  { isoDate: "2009-04-05T00:00:00Z", dsun: 213.203632, hgln: 0.237048, hglt: -6.286671 },
  { isoDate: "2009-04-06T00:00:00Z", dsun: 213.272694, hgln: 0.233546, hglt: -6.222499 },
  { isoDate: "2009-04-07T00:00:00Z", dsun: 213.341367, hgln: 0.229803, hglt: -6.156575 },
  { isoDate: "2009-04-08T00:00:00Z", dsun: 213.409633, hgln: 0.225807, hglt: -6.08892 },
  { isoDate: "2009-04-09T00:00:00Z", dsun: 213.477468, hgln: 0.221542, hglt: -6.019563 },
  { isoDate: "2009-04-10T00:00:00Z", dsun: 213.544852, hgln: 0.216997, hglt: -5.94853 },
  { isoDate: "2009-04-11T00:00:00Z", dsun: 213.611766, hgln: 0.212157, hglt: -5.87585 },
  { isoDate: "2009-04-12T00:00:00Z", dsun: 213.678193, hgln: 0.207012, hglt: -5.801548 },
  { isoDate: "2009-04-13T00:00:00Z", dsun: 213.744114, hgln: 0.201553, hglt: -5.725654 },
  { isoDate: "2009-04-14T00:00:00Z", dsun: 213.809514, hgln: 0.195776, hglt: -5.648195 },
  { isoDate: "2009-04-15T00:00:00Z", dsun: 213.874379, hgln: 0.189677, hglt: -5.569199 },
  { isoDate: "2009-04-16T00:00:00Z", dsun: 213.938696, hgln: 0.183258, hglt: -5.488696 },
  { isoDate: "2009-04-17T00:00:00Z", dsun: 214.002454, hgln: 0.17652, hglt: -5.406713 },
  { isoDate: "2009-04-18T00:00:00Z", dsun: 214.065645, hgln: 0.169469, hglt: -5.32328 },
  { isoDate: "2009-04-19T00:00:00Z", dsun: 214.12826, hgln: 0.162114, hglt: -5.238426 },
  { isoDate: "2009-04-20T00:00:00Z", dsun: 214.190293, hgln: 0.154465, hglt: -5.15218 },
  { isoDate: "2009-04-21T00:00:00Z", dsun: 214.251742, hgln: 0.146535, hglt: -5.064573 },
  { isoDate: "2009-04-22T00:00:00Z", dsun: 214.312604, hgln: 0.138342, hglt: -4.975633 },
  { isoDate: "2009-04-23T00:00:00Z", dsun: 214.372876, hgln: 0.129905, hglt: -4.88539 },
  { isoDate: "2009-04-24T00:00:00Z", dsun: 214.432558, hgln: 0.121248, hglt: -4.793875 },
  { isoDate: "2009-04-25T00:00:00Z", dsun: 214.491644, hgln: 0.112396, hglt: -4.701117 },
  { isoDate: "2009-04-26T00:00:00Z", dsun: 214.55013, hgln: 0.103381, hglt: -4.607146 },
  { isoDate: "2009-04-27T00:00:00Z", dsun: 214.608008, hgln: 0.094235, hglt: -4.511992 },
  { isoDate: "2009-04-28T00:00:00Z", dsun: 214.66527, hgln: 0.084989, hglt: -4.415685 },
  { isoDate: "2009-04-29T00:00:00Z", dsun: 214.72191, hgln: 0.075677, hglt: -4.318255 },
  { isoDate: "2009-04-30T00:00:00Z", dsun: 214.77792, hgln: 0.066326, hglt: -4.219732 },
  { isoDate: "2009-05-01T00:00:00Z", dsun: 214.833296, hgln: 0.056962, hglt: -4.120144 },
  { isoDate: "2009-05-02T00:00:00Z", dsun: 214.888031, hgln: 0.047603, hglt: -4.019522 },
  { isoDate: "2009-05-03T00:00:00Z", dsun: 214.94212, hgln: 0.038264, hglt: -3.917894 },
  { isoDate: "2009-05-04T00:00:00Z", dsun: 214.995558, hgln: 0.028957, hglt: -3.815292 },
  { isoDate: "2009-05-05T00:00:00Z", dsun: 215.048336, hgln: 0.019687, hglt: -3.711743 },
  { isoDate: "2009-05-06T00:00:00Z", dsun: 215.100448, hgln: 0.010457, hglt: -3.607277 },
  { isoDate: "2009-05-07T00:00:00Z", dsun: 215.151883, hgln: 0.001269, hglt: -3.501922 },
  { isoDate: "2009-05-08T00:00:00Z", dsun: 215.202631, hgln: -0.007878, hglt: -3.395709 },
  { isoDate: "2009-05-09T00:00:00Z", dsun: 215.252679, hgln: -0.016985, hglt: -3.288665 },
  { isoDate: "2009-05-10T00:00:00Z", dsun: 215.302012, hgln: -0.026053, hglt: -3.18082 },
  { isoDate: "2009-05-11T00:00:00Z", dsun: 215.350613, hgln: -0.035083, hglt: -3.072201 },
  { isoDate: "2009-05-12T00:00:00Z", dsun: 215.398461, hgln: -0.044074, hglt: -2.962839 },
  { isoDate: "2009-05-13T00:00:00Z", dsun: 215.445543, hgln: -0.053024, hglt: -2.852759 },
  { isoDate: "2009-05-14T00:00:00Z", dsun: 215.491833, hgln: -0.06193, hglt: -2.74199 },
  { isoDate: "2009-05-15T00:00:00Z", dsun: 215.53731, hgln: -0.070787, hglt: -2.630562 },
  { isoDate: "2009-05-16T00:00:00Z", dsun: 215.581952, hgln: -0.079589, hglt: -2.518501 },
  { isoDate: "2009-05-17T00:00:00Z", dsun: 215.625734, hgln: -0.088329, hglt: -2.405836 },
  { isoDate: "2009-05-18T00:00:00Z", dsun: 215.668631, hgln: -0.096997, hglt: -2.292595 },
  { isoDate: "2009-05-19T00:00:00Z", dsun: 215.71062, hgln: -0.105583, hglt: -2.178805 },
  { isoDate: "2009-05-20T00:00:00Z", dsun: 215.751677, hgln: -0.114072, hglt: -2.064495 },
  { isoDate: "2009-05-21T00:00:00Z", dsun: 215.791777, hgln: -0.12245, hglt: -1.949692 },
  { isoDate: "2009-05-22T00:00:00Z", dsun: 215.830899, hgln: -0.130698, hglt: -1.834424 },
  { isoDate: "2009-05-23T00:00:00Z", dsun: 215.869022, hgln: -0.138793, hglt: -1.71872 },
  { isoDate: "2009-05-24T00:00:00Z", dsun: 215.906125, hgln: -0.146709, hglt: -1.602606 },
  { isoDate: "2009-05-25T00:00:00Z", dsun: 215.942192, hgln: -0.154418, hglt: -1.486112 },
  { isoDate: "2009-05-26T00:00:00Z", dsun: 215.977202, hgln: -0.161892, hglt: -1.369266 },
  { isoDate: "2009-05-27T00:00:00Z", dsun: 216.011138, hgln: -0.169102, hglt: -1.252096 },
  { isoDate: "2009-05-28T00:00:00Z", dsun: 216.043979, hgln: -0.176023, hglt: -1.134631 },
  { isoDate: "2009-05-29T00:00:00Z", dsun: 216.075704, hgln: -0.182635, hglt: -1.016898 },
  { isoDate: "2009-05-30T00:00:00Z", dsun: 216.106293, hgln: -0.188921, hglt: -0.898928 },
  { isoDate: "2009-05-31T00:00:00Z", dsun: 216.135729, hgln: -0.194871, hglt: -0.780747 },
  { isoDate: "2009-06-01T00:00:00Z", dsun: 216.163996, hgln: -0.200481, hglt: -0.662385 },
  { isoDate: "2009-06-02T00:00:00Z", dsun: 216.191077, hgln: -0.205748, hglt: -0.54387 },
  { isoDate: "2009-06-03T00:00:00Z", dsun: 216.216963, hgln: -0.210678, hglt: -0.425232 },
  { isoDate: "2009-06-04T00:00:00Z", dsun: 216.241642, hgln: -0.215274, hglt: -0.306499 },
  { isoDate: "2009-06-05T00:00:00Z", dsun: 216.265107, hgln: -0.219547, hglt: -0.1877 },
  { isoDate: "2009-06-06T00:00:00Z", dsun: 216.28735, hgln: -0.223506, hglt: -0.068865 },
  { isoDate: "2009-06-07T00:00:00Z", dsun: 216.308368, hgln: -0.227161, hglt: 0.049978 },
  { isoDate: "2009-06-08T00:00:00Z", dsun: 216.328155, hgln: -0.230526, hglt: 0.168798 },
  { isoDate: "2009-06-09T00:00:00Z", dsun: 216.346692, hgln: -0.233611, hglt: 0.287569 },
  { isoDate: "2009-06-10T00:00:00Z", dsun: 216.364011, hgln: -0.236429, hglt: 0.406257 },
  { isoDate: "2009-06-11T00:00:00Z", dsun: 216.380096, hgln: -0.238992, hglt: 0.524835 },
  { isoDate: "2009-06-12T00:00:00Z", dsun: 216.394946, hgln: -0.241309, hglt: 0.643272 },
  { isoDate: "2009-06-13T00:00:00Z", dsun: 216.408564, hgln: -0.24339, hglt: 0.76154 },
  { isoDate: "2009-06-14T00:00:00Z", dsun: 216.420952, hgln: -0.245242, hglt: 0.879608 },
  { isoDate: "2009-06-15T00:00:00Z", dsun: 216.432113, hgln: -0.246874, hglt: 0.997447 },
  { isoDate: "2009-06-16T00:00:00Z", dsun: 216.442052, hgln: -0.24829, hglt: 1.115026 },
  { isoDate: "2009-06-17T00:00:00Z", dsun: 216.450775, hgln: -0.249494, hglt: 1.232317 },
  { isoDate: "2009-06-18T00:00:00Z", dsun: 216.458288, hgln: -0.250485, hglt: 1.349288 },
  { isoDate: "2009-06-19T00:00:00Z", dsun: 216.464598, hgln: -0.251262, hglt: 1.465911 },
  { isoDate: "2009-06-20T00:00:00Z", dsun: 216.469717, hgln: -0.251818, hglt: 1.582155 },
  { isoDate: "2009-06-21T00:00:00Z", dsun: 216.473653, hgln: -0.252142, hglt: 1.697991 },
  { isoDate: "2009-06-22T00:00:00Z", dsun: 216.476419, hgln: -0.252218, hglt: 1.813388 },
  { isoDate: "2009-06-23T00:00:00Z", dsun: 216.47803, hgln: -0.252028, hglt: 1.928318 },
  { isoDate: "2009-06-24T00:00:00Z", dsun: 216.4785, hgln: -0.25155, hglt: 2.042749 },
  { isoDate: "2009-06-25T00:00:00Z", dsun: 216.477845, hgln: -0.250763, hglt: 2.156652 },
  { isoDate: "2009-06-26T00:00:00Z", dsun: 216.476079, hgln: -0.24965, hglt: 2.269998 },
  { isoDate: "2009-06-27T00:00:00Z", dsun: 216.473218, hgln: -0.248197, hglt: 2.382756 },
  { isoDate: "2009-06-28T00:00:00Z", dsun: 216.469277, hgln: -0.246397, hglt: 2.494896 },
  { isoDate: "2009-06-29T00:00:00Z", dsun: 216.464272, hgln: -0.244246, hglt: 2.606389 },
  { isoDate: "2009-06-30T00:00:00Z", dsun: 216.458218, hgln: -0.241746, hglt: 2.717205 },
  { isoDate: "2009-07-01T00:00:00Z", dsun: 216.451131, hgln: -0.238902, hglt: 2.827314 },
  { isoDate: "2009-07-02T00:00:00Z", dsun: 216.443031, hgln: -0.235723, hglt: 2.936687 },
  { isoDate: "2009-07-03T00:00:00Z", dsun: 216.433936, hgln: -0.232221, hglt: 3.045295 },
  { isoDate: "2009-07-04T00:00:00Z", dsun: 216.423866, hgln: -0.228408, hglt: 3.153108 },
  { isoDate: "2009-07-05T00:00:00Z", dsun: 216.41284, hgln: -0.2243, hglt: 3.260097 },
  { isoDate: "2009-07-06T00:00:00Z", dsun: 216.400879, hgln: -0.219913, hglt: 3.366232 },
  { isoDate: "2009-07-07T00:00:00Z", dsun: 216.388004, hgln: -0.215264, hglt: 3.471485 },
  { isoDate: "2009-07-08T00:00:00Z", dsun: 216.374236, hgln: -0.210371, hglt: 3.575827 },
  { isoDate: "2009-07-09T00:00:00Z", dsun: 216.359596, hgln: -0.205251, hglt: 3.679229 },
  { isoDate: "2009-07-10T00:00:00Z", dsun: 216.344104, hgln: -0.199923, hglt: 3.781662 },
  { isoDate: "2009-07-11T00:00:00Z", dsun: 216.327783, hgln: -0.194405, hglt: 3.883097 },
  { isoDate: "2009-07-12T00:00:00Z", dsun: 216.310652, hgln: -0.188713, hglt: 3.983507 },
  { isoDate: "2009-07-13T00:00:00Z", dsun: 216.292732, hgln: -0.182864, hglt: 4.082862 },
  { isoDate: "2009-07-14T00:00:00Z", dsun: 216.274036, hgln: -0.176869, hglt: 4.181135 },
  { isoDate: "2009-07-15T00:00:00Z", dsun: 216.254598, hgln: -0.170751, hglt: 4.278298 },
  { isoDate: "2009-07-16T00:00:00Z", dsun: 216.23443, hgln: -0.164516, hglt: 4.374323 },
  { isoDate: "2009-07-17T00:00:00Z", dsun: 216.213553, hgln: -0.158174, hglt: 4.469182 },
  { isoDate: "2009-07-18T00:00:00Z", dsun: 216.191987, hgln: -0.151729, hglt: 4.56285 },
  { isoDate: "2009-07-19T00:00:00Z", dsun: 216.16975, hgln: -0.145182, hglt: 4.655297 },
  { isoDate: "2009-07-20T00:00:00Z", dsun: 216.146865, hgln: -0.13853, hglt: 4.746498 },
  { isoDate: "2009-07-21T00:00:00Z", dsun: 216.123351, hgln: -0.131764, hglt: 4.836426 },
  { isoDate: "2009-07-22T00:00:00Z", dsun: 216.099228, hgln: -0.124871, hglt: 4.925054 },
  { isoDate: "2009-07-23T00:00:00Z", dsun: 216.074518, hgln: -0.117835, hglt: 5.012357 },
  { isoDate: "2009-07-24T00:00:00Z", dsun: 216.049239, hgln: -0.110644, hglt: 5.098307 },
  { isoDate: "2009-07-25T00:00:00Z", dsun: 216.023407, hgln: -0.103284, hglt: 5.182882 },
  { isoDate: "2009-07-26T00:00:00Z", dsun: 215.99704, hgln: -0.095747, hglt: 5.266054 },
  { isoDate: "2009-07-27T00:00:00Z", dsun: 215.970154, hgln: -0.08803, hglt: 5.347799 },
  { isoDate: "2009-07-28T00:00:00Z", dsun: 215.942763, hgln: -0.080135, hglt: 5.428092 },
  { isoDate: "2009-07-29T00:00:00Z", dsun: 215.914883, hgln: -0.072067, hglt: 5.506909 },
  { isoDate: "2009-07-30T00:00:00Z", dsun: 215.886528, hgln: -0.063838, hglt: 5.584226 },
  { isoDate: "2009-07-31T00:00:00Z", dsun: 215.857715, hgln: -0.055458, hglt: 5.660019 },
  { isoDate: "2009-08-01T00:00:00Z", dsun: 215.828456, hgln: -0.046944, hglt: 5.734264 },
  { isoDate: "2009-08-02T00:00:00Z", dsun: 215.798766, hgln: -0.038313, hglt: 5.80694 },
  { isoDate: "2009-08-03T00:00:00Z", dsun: 215.768658, hgln: -0.029583, hglt: 5.878023 },
  { isoDate: "2009-08-04T00:00:00Z", dsun: 215.738146, hgln: -0.020775, hglt: 5.947492 },
  { isoDate: "2009-08-05T00:00:00Z", dsun: 215.707129, hgln: -0.011887, hglt: 6.015337 },
  { isoDate: "2009-08-06T00:00:00Z", dsun: 215.675846, hgln: -0.002987, hglt: 6.081512 },
  { isoDate: "2009-08-07T00:00:00Z", dsun: 215.64419, hgln: 0.005924, hglt: 6.146008 },
  { isoDate: "2009-08-08T00:00:00Z", dsun: 215.612171, hgln: 0.014823, hglt: 6.208805 },
  { isoDate: "2009-08-09T00:00:00Z", dsun: 215.579796, hgln: 0.023685, hglt: 6.269882 },
  { isoDate: "2009-08-10T00:00:00Z", dsun: 215.547073, hgln: 0.032488, hglt: 6.32922 },
  { isoDate: "2009-08-11T00:00:00Z", dsun: 215.514007, hgln: 0.04121, hglt: 6.386799 },
  { isoDate: "2009-08-12T00:00:00Z", dsun: 215.480605, hgln: 0.049828, hglt: 6.442602 },
  { isoDate: "2009-08-13T00:00:00Z", dsun: 215.446871, hgln: 0.058325, hglt: 6.49661 },
  { isoDate: "2009-08-14T00:00:00Z", dsun: 215.412808, hgln: 0.066684, hglt: 6.548804 },
  { isoDate: "2009-08-15T00:00:00Z", dsun: 215.378422, hgln: 0.074891, hglt: 6.599168 },
  { isoDate: "2009-08-16T00:00:00Z", dsun: 215.343714, hgln: 0.082938, hglt: 6.647686 },
  { isoDate: "2009-08-17T00:00:00Z", dsun: 215.30869, hgln: 0.09082, hglt: 6.69434 },
  { isoDate: "2009-08-18T00:00:00Z", dsun: 215.273351, hgln: 0.098539, hglt: 6.739115 },
  { isoDate: "2009-08-19T00:00:00Z", dsun: 215.237701, hgln: 0.106101, hglt: 6.781997 },
  { isoDate: "2009-08-20T00:00:00Z", dsun: 215.201741, hgln: 0.113516, hglt: 6.822969 },
  { isoDate: "2009-08-21T00:00:00Z", dsun: 215.165471, hgln: 0.120798, hglt: 6.862019 },
  { isoDate: "2009-08-22T00:00:00Z", dsun: 215.12889, hgln: 0.12796, hglt: 6.899133 },
  { isoDate: "2009-08-23T00:00:00Z", dsun: 215.091995, hgln: 0.135014, hglt: 6.934296 },
  { isoDate: "2009-08-24T00:00:00Z", dsun: 215.054782, hgln: 0.141968, hglt: 6.967498 },
  { isoDate: "2009-08-25T00:00:00Z", dsun: 215.017251, hgln: 0.148828, hglt: 6.998726 },
  { isoDate: "2009-08-26T00:00:00Z", dsun: 214.979396, hgln: 0.155592, hglt: 7.027969 },
  { isoDate: "2009-08-27T00:00:00Z", dsun: 214.941215, hgln: 0.162258, hglt: 7.055215 },
  { isoDate: "2009-08-28T00:00:00Z", dsun: 214.902706, hgln: 0.168819, hglt: 7.080455 },
  { isoDate: "2009-08-29T00:00:00Z", dsun: 214.863864, hgln: 0.175267, hglt: 7.10368 },
  { isoDate: "2009-08-30T00:00:00Z", dsun: 214.824687, hgln: 0.181588, hglt: 7.12488 },
  { isoDate: "2009-08-31T00:00:00Z", dsun: 214.785171, hgln: 0.187771, hglt: 7.144047 },
  { isoDate: "2009-09-01T00:00:00Z", dsun: 214.745311, hgln: 0.193801, hglt: 7.161172 },
  { isoDate: "2009-09-02T00:00:00Z", dsun: 214.705099, hgln: 0.199662, hglt: 7.176243 },
  { isoDate: "2009-09-03T00:00:00Z", dsun: 214.664538, hgln: 0.205334, hglt: 7.189266 },
  { isoDate: "2009-09-04T00:00:00Z", dsun: 214.623621, hgln: 0.2108, hglt: 7.200228 },
  { isoDate: "2009-09-05T00:00:00Z", dsun: 214.582339, hgln: 0.216039, hglt: 7.209123 },
  { isoDate: "2009-09-06T00:00:00Z", dsun: 214.540688, hgln: 0.221031, hglt: 7.215947 },
  { isoDate: "2009-09-07T00:00:00Z", dsun: 214.498661, hgln: 0.225756, hglt: 7.220696 },
  { isoDate: "2009-09-08T00:00:00Z", dsun: 214.456252, hgln: 0.230191, hglt: 7.223366 },
  { isoDate: "2009-09-09T00:00:00Z", dsun: 214.413455, hgln: 0.234318, hglt: 7.223954 },
  { isoDate: "2009-09-10T00:00:00Z", dsun: 214.370265, hgln: 0.238119, hglt: 7.222457 },
  { isoDate: "2009-09-11T00:00:00Z", dsun: 214.326675, hgln: 0.241578, hglt: 7.218875 },
  { isoDate: "2009-09-12T00:00:00Z", dsun: 214.28268, hgln: 0.244683, hglt: 7.213205 },
  { isoDate: "2009-09-13T00:00:00Z", dsun: 214.238277, hgln: 0.247425, hglt: 7.205447 },
  { isoDate: "2009-09-14T00:00:00Z", dsun: 214.193463, hgln: 0.2498, hglt: 7.195602 },
  { isoDate: "2009-09-15T00:00:00Z", dsun: 214.148233, hgln: 0.25181, hglt: 7.18367 },
  { isoDate: "2009-09-16T00:00:00Z", dsun: 214.102587, hgln: 0.253462, hglt: 7.169651 },
  { isoDate: "2009-09-17T00:00:00Z", dsun: 214.056522, hgln: 0.254768, hglt: 7.153549 },
  { isoDate: "2009-09-18T00:00:00Z", dsun: 214.010035, hgln: 0.255743, hglt: 7.135366 },
  { isoDate: "2009-09-19T00:00:00Z", dsun: 213.963122, hgln: 0.256405, hglt: 7.115104 },
  { isoDate: "2009-09-20T00:00:00Z", dsun: 213.91578, hgln: 0.256774, hglt: 7.092767 },
  { isoDate: "2009-09-21T00:00:00Z", dsun: 213.868005, hgln: 0.256868, hglt: 7.068359 },
  { isoDate: "2009-09-22T00:00:00Z", dsun: 213.819797, hgln: 0.256702, hglt: 7.041886 },
  { isoDate: "2009-09-23T00:00:00Z", dsun: 213.771154, hgln: 0.256288, hglt: 7.013353 },
  { isoDate: "2009-09-24T00:00:00Z", dsun: 213.722077, hgln: 0.255635, hglt: 6.982765 },
  { isoDate: "2009-09-25T00:00:00Z", dsun: 213.672567, hgln: 0.254749, hglt: 6.95013 },
  { isoDate: "2009-09-26T00:00:00Z", dsun: 213.622629, hgln: 0.253633, hglt: 6.915454 },
  { isoDate: "2009-09-27T00:00:00Z", dsun: 213.572266, hgln: 0.252286, hglt: 6.878745 },
  { isoDate: "2009-09-28T00:00:00Z", dsun: 213.521483, hgln: 0.250706, hglt: 6.840012 },
  { isoDate: "2009-09-29T00:00:00Z", dsun: 213.470286, hgln: 0.248891, hglt: 6.799264 },
  { isoDate: "2009-09-30T00:00:00Z", dsun: 213.418682, hgln: 0.246834, hglt: 6.756509 },
  { isoDate: "2009-10-01T00:00:00Z", dsun: 213.366679, hgln: 0.244528, hglt: 6.711757 },
  { isoDate: "2009-10-02T00:00:00Z", dsun: 213.314285, hgln: 0.241964, hglt: 6.66502 },
  { isoDate: "2009-10-03T00:00:00Z", dsun: 213.261509, hgln: 0.239131, hglt: 6.616308 },
  { isoDate: "2009-10-04T00:00:00Z", dsun: 213.208361, hgln: 0.236017, hglt: 6.565633 },
  { isoDate: "2009-10-05T00:00:00Z", dsun: 213.154852, hgln: 0.232607, hglt: 6.513006 },
  { isoDate: "2009-10-06T00:00:00Z", dsun: 213.100994, hgln: 0.228887, hglt: 6.458441 },
  { isoDate: "2009-10-07T00:00:00Z", dsun: 213.0468, hgln: 0.224841, hglt: 6.401951 },
  { isoDate: "2009-10-08T00:00:00Z", dsun: 212.992285, hgln: 0.220457, hglt: 6.343548 },
  { isoDate: "2009-10-09T00:00:00Z", dsun: 212.937463, hgln: 0.215721, hglt: 6.283248 },
  { isoDate: "2009-10-10T00:00:00Z", dsun: 212.882353, hgln: 0.210626, hglt: 6.221065 },
  { isoDate: "2009-10-11T00:00:00Z", dsun: 212.826973, hgln: 0.205168, hglt: 6.157014 },
  { isoDate: "2009-10-12T00:00:00Z", dsun: 212.771344, hgln: 0.199345, hglt: 6.09111 },
  { isoDate: "2009-10-13T00:00:00Z", dsun: 212.715491, hgln: 0.193164, hglt: 6.023369 },
  { isoDate: "2009-10-14T00:00:00Z", dsun: 212.659436, hgln: 0.186634, hglt: 5.953809 },
  { isoDate: "2009-10-15T00:00:00Z", dsun: 212.603209, hgln: 0.179773, hglt: 5.882445 },
  { isoDate: "2009-10-16T00:00:00Z", dsun: 212.546838, hgln: 0.172599, hglt: 5.809296 },
  { isoDate: "2009-10-17T00:00:00Z", dsun: 212.49035, hgln: 0.165136, hglt: 5.734378 },
  { isoDate: "2009-10-18T00:00:00Z", dsun: 212.433773, hgln: 0.15741, hglt: 5.657711 },
  { isoDate: "2009-10-19T00:00:00Z", dsun: 212.377133, hgln: 0.149447, hglt: 5.579313 },
  { isoDate: "2009-10-20T00:00:00Z", dsun: 212.320456, hgln: 0.141276, hglt: 5.499204 },
  { isoDate: "2009-10-21T00:00:00Z", dsun: 212.263768, hgln: 0.132919, hglt: 5.417404 },
  { isoDate: "2009-10-22T00:00:00Z", dsun: 212.207096, hgln: 0.124403, hglt: 5.333932 },
  { isoDate: "2009-10-23T00:00:00Z", dsun: 212.150469, hgln: 0.115749, hglt: 5.248811 },
  { isoDate: "2009-10-24T00:00:00Z", dsun: 212.093915, hgln: 0.106975, hglt: 5.16206 },
  { isoDate: "2009-10-25T00:00:00Z", dsun: 212.037466, hgln: 0.0981, hglt: 5.073702 },
  { isoDate: "2009-10-26T00:00:00Z", dsun: 211.981152, hgln: 0.089139, hglt: 4.983759 },
  { isoDate: "2009-10-27T00:00:00Z", dsun: 211.925003, hgln: 0.080106, hglt: 4.892254 },
  { isoDate: "2009-10-28T00:00:00Z", dsun: 211.86905, hgln: 0.071014, hglt: 4.799209 },
  { isoDate: "2009-10-29T00:00:00Z", dsun: 211.813323, hgln: 0.061873, hglt: 4.704649 },
  { isoDate: "2009-10-30T00:00:00Z", dsun: 211.75785, hgln: 0.052691, hglt: 4.608597 },
  { isoDate: "2009-10-31T00:00:00Z", dsun: 211.702659, hgln: 0.043476, hglt: 4.511078 },
  { isoDate: "2009-11-01T00:00:00Z", dsun: 211.647776, hgln: 0.03423, hglt: 4.412118 },
  { isoDate: "2009-11-02T00:00:00Z", dsun: 211.593223, hgln: 0.024955, hglt: 4.311742 },
  { isoDate: "2009-11-03T00:00:00Z", dsun: 211.539023, hgln: 0.015651, hglt: 4.209977 },
  { isoDate: "2009-11-04T00:00:00Z", dsun: 211.485195, hgln: 0.006314, hglt: 4.10685 },
  { isoDate: "2009-11-05T00:00:00Z", dsun: 211.431756, hgln: -0.00306, hglt: 4.002388 },
  { isoDate: "2009-11-06T00:00:00Z", dsun: 211.37872, hgln: -0.012472, hglt: 3.896621 },
  { isoDate: "2009-11-07T00:00:00Z", dsun: 211.326101, hgln: -0.021925, hglt: 3.789577 },
  { isoDate: "2009-11-08T00:00:00Z", dsun: 211.273909, hgln: -0.031416, hglt: 3.681285 },
  { isoDate: "2009-11-09T00:00:00Z", dsun: 211.222152, hgln: -0.040939, hglt: 3.571777 },
  { isoDate: "2009-11-10T00:00:00Z", dsun: 211.170836, hgln: -0.050483, hglt: 3.461082 },
  { isoDate: "2009-11-11T00:00:00Z", dsun: 211.119951, hgln: -0.060032, hglt: 3.349229 },
  { isoDate: "2009-11-12T00:00:00Z", dsun: 211.069531, hgln: -0.069564, hglt: 3.236254 },
  { isoDate: "2009-11-13T00:00:00Z", dsun: 211.019568, hgln: -0.079056, hglt: 3.122189 },
  { isoDate: "2009-11-14T00:00:00Z", dsun: 210.970067, hgln: -0.088481, hglt: 3.007066 },
  { isoDate: "2009-11-15T00:00:00Z", dsun: 210.921033, hgln: -0.097809, hglt: 2.890918 },
  { isoDate: "2009-11-16T00:00:00Z", dsun: 210.872472, hgln: -0.107009, hglt: 2.77378 },
  { isoDate: "2009-11-17T00:00:00Z", dsun: 210.824388, hgln: -0.116053, hglt: 2.655686 },
  { isoDate: "2009-11-18T00:00:00Z", dsun: 210.776788, hgln: -0.124911, hglt: 2.536671 },
  { isoDate: "2009-11-19T00:00:00Z", dsun: 210.729673, hgln: -0.133557, hglt: 2.416772 },
  { isoDate: "2009-11-20T00:00:00Z", dsun: 210.683046, hgln: -0.141968, hglt: 2.296024 },
  { isoDate: "2009-11-21T00:00:00Z", dsun: 210.636905, hgln: -0.150121, hglt: 2.174465 },
  { isoDate: "2009-11-22T00:00:00Z", dsun: 210.591249, hgln: -0.157999, hglt: 2.052132 },
  { isoDate: "2009-11-23T00:00:00Z", dsun: 210.546077, hgln: -0.165584, hglt: 1.929064 },
  { isoDate: "2009-11-24T00:00:00Z", dsun: 210.501388, hgln: -0.172862, hglt: 1.805298 },
  { isoDate: "2009-11-25T00:00:00Z", dsun: 210.457183, hgln: -0.17982, hglt: 1.680873 },
  { isoDate: "2009-11-26T00:00:00Z", dsun: 210.413465, hgln: -0.18645, hglt: 1.555827 },
  { isoDate: "2009-11-27T00:00:00Z", dsun: 210.37024, hgln: -0.192743, hglt: 1.4302 },
  { isoDate: "2009-11-28T00:00:00Z", dsun: 210.327512, hgln: -0.198695, hglt: 1.304032 },
  { isoDate: "2009-11-29T00:00:00Z", dsun: 210.285292, hgln: -0.204306, hglt: 1.177362 },
  { isoDate: "2009-11-30T00:00:00Z", dsun: 210.243587, hgln: -0.20958, hglt: 1.05023 },
  { isoDate: "2009-12-01T00:00:00Z", dsun: 210.20241, hgln: -0.214522, hglt: 0.922677 },
  { isoDate: "2009-12-02T00:00:00Z", dsun: 210.161778, hgln: -0.219144, hglt: 0.794768 },
  { isoDate: "2009-12-03T00:00:00Z", dsun: 210.121692, hgln: -0.223464, hglt: 0.666496 },
  { isoDate: "2009-12-04T00:00:00Z", dsun: 210.082172, hgln: -0.227496, hglt: 0.537925 },
  { isoDate: "2009-12-05T00:00:00Z", dsun: 210.043232, hgln: -0.231258, hglt: 0.409098 },
  { isoDate: "2009-12-06T00:00:00Z", dsun: 210.004887, hgln: -0.234766, hglt: 0.280055 },
  { isoDate: "2009-12-07T00:00:00Z", dsun: 209.967155, hgln: -0.238032, hglt: 0.150839 },
  { isoDate: "2009-12-08T00:00:00Z", dsun: 209.93005, hgln: -0.241064, hglt: 0.021493 },
  { isoDate: "2009-12-09T00:00:00Z", dsun: 209.893591, hgln: -0.243865, hglt: -0.107942 },
  { isoDate: "2009-12-10T00:00:00Z", dsun: 209.857796, hgln: -0.246433, hglt: -0.237424 },
  { isoDate: "2009-12-11T00:00:00Z", dsun: 209.822682, hgln: -0.248762, hglt: -0.366909 },
  { isoDate: "2009-12-12T00:00:00Z", dsun: 209.78827, hgln: -0.250843, hglt: -0.496355 },
  { isoDate: "2009-12-13T00:00:00Z", dsun: 209.754581, hgln: -0.252663, hglt: -0.62572 },
  { isoDate: "2009-12-14T00:00:00Z", dsun: 209.721638, hgln: -0.25421, hglt: -0.75496 },
  { isoDate: "2009-12-15T00:00:00Z", dsun: 209.689462, hgln: -0.255468, hglt: -0.884033 },
  { isoDate: "2009-12-16T00:00:00Z", dsun: 209.65808, hgln: -0.256421, hglt: -1.012896 },
  { isoDate: "2009-12-17T00:00:00Z", dsun: 209.627517, hgln: -0.257054, hglt: -1.141506 },
  { isoDate: "2009-12-18T00:00:00Z", dsun: 209.5978, hgln: -0.257354, hglt: -1.269819 },
  { isoDate: "2009-12-19T00:00:00Z", dsun: 209.568956, hgln: -0.257307, hglt: -1.397793 },
  { isoDate: "2009-12-20T00:00:00Z", dsun: 209.54101, hgln: -0.256903, hglt: -1.525384 },
  { isoDate: "2009-12-21T00:00:00Z", dsun: 209.513988, hgln: -0.256132, hglt: -1.652549 },
  { isoDate: "2009-12-22T00:00:00Z", dsun: 209.487915, hgln: -0.254986, hglt: -1.779246 },
  { isoDate: "2009-12-23T00:00:00Z", dsun: 209.462815, hgln: -0.25346, hglt: -1.90543 },
  { isoDate: "2009-12-24T00:00:00Z", dsun: 209.438712, hgln: -0.25155, hglt: -2.031059 },
  { isoDate: "2009-12-25T00:00:00Z", dsun: 209.41563, hgln: -0.249254, hglt: -2.15609 },
  { isoDate: "2009-12-26T00:00:00Z", dsun: 209.393595, hgln: -0.246571, hglt: -2.280481 },
  { isoDate: "2009-12-27T00:00:00Z", dsun: 209.372631, hgln: -0.243505, hglt: -2.404188 },
  { isoDate: "2009-12-28T00:00:00Z", dsun: 209.352765, hgln: -0.240063, hglt: -2.52717 },
  { isoDate: "2009-12-29T00:00:00Z", dsun: 209.334023, hgln: -0.236255, hglt: -2.649384 },
  { isoDate: "2009-12-30T00:00:00Z", dsun: 209.31643, hgln: -0.232096, hglt: -2.770789 },
  { isoDate: "2009-12-31T00:00:00Z", dsun: 209.300012, hgln: -0.227606, hglt: -2.891341 },
  { isoDate: "2010-01-01T00:00:00Z", dsun: 209.284796, hgln: -0.222812, hglt: -3.010999 },
  { isoDate: "2010-01-02T00:00:00Z", dsun: 209.270805, hgln: -0.21774, hglt: -3.129721 },
  { isoDate: "2010-01-03T00:00:00Z", dsun: 209.258064, hgln: -0.212418, hglt: -3.247467 },
  { isoDate: "2010-01-04T00:00:00Z", dsun: 209.246596, hgln: -0.206874, hglt: -3.364195 },
  { isoDate: "2010-01-05T00:00:00Z", dsun: 209.236425, hgln: -0.201131, hglt: -3.479865 },
  { isoDate: "2010-01-06T00:00:00Z", dsun: 209.22757, hgln: -0.195206, hglt: -3.594436 },
  { isoDate: "2010-01-07T00:00:00Z", dsun: 209.220052, hgln: -0.189113, hglt: -3.707867 },
  { isoDate: "2010-01-08T00:00:00Z", dsun: 209.213891, hgln: -0.18286, hglt: -3.82012 },
  { isoDate: "2010-01-09T00:00:00Z", dsun: 209.209107, hgln: -0.176453, hglt: -3.931156 },
  { isoDate: "2010-01-10T00:00:00Z", dsun: 209.205716, hgln: -0.169893, hglt: -4.040934 },
  { isoDate: "2010-01-11T00:00:00Z", dsun: 209.203737, hgln: -0.163179, hglt: -4.149417 },
  { isoDate: "2010-01-12T00:00:00Z", dsun: 209.203188, hgln: -0.156308, hglt: -4.256567 },
  { isoDate: "2010-01-13T00:00:00Z", dsun: 209.204084, hgln: -0.149278, hglt: -4.362346 },
  { isoDate: "2010-01-14T00:00:00Z", dsun: 209.206445, hgln: -0.142083, hglt: -4.46672 },
  { isoDate: "2010-01-15T00:00:00Z", dsun: 209.210281, hgln: -0.134722, hglt: -4.569647 },
  { isoDate: "2010-01-16T00:00:00Z", dsun: 209.215607, hgln: -0.127188, hglt: -4.671093 },
  { isoDate: "2010-01-17T00:00:00Z", dsun: 209.222438, hgln: -0.119479, hglt: -4.771022 },
  { isoDate: "2010-01-18T00:00:00Z", dsun: 209.230782, hgln: -0.111592, hglt: -4.869401 },
  { isoDate: "2010-01-19T00:00:00Z", dsun: 209.240648, hgln: -0.103526, hglt: -4.966194 },
  { isoDate: "2010-01-20T00:00:00Z", dsun: 209.252042, hgln: -0.095279, hglt: -5.061368 },
  { isoDate: "2010-01-21T00:00:00Z", dsun: 209.264969, hgln: -0.086853, hglt: -5.15489 },
  { isoDate: "2010-01-22T00:00:00Z", dsun: 209.279431, hgln: -0.078248, hglt: -5.246728 },
  { isoDate: "2010-01-23T00:00:00Z", dsun: 209.295431, hgln: -0.06947, hglt: -5.336849 },
  { isoDate: "2010-01-24T00:00:00Z", dsun: 209.312971, hgln: -0.060523, hglt: -5.425224 },
  { isoDate: "2010-01-25T00:00:00Z", dsun: 209.332052, hgln: -0.051417, hglt: -5.511822 },
  { isoDate: "2010-01-26T00:00:00Z", dsun: 209.352673, hgln: -0.042164, hglt: -5.596613 },
  { isoDate: "2010-01-27T00:00:00Z", dsun: 209.374833, hgln: -0.032779, hglt: -5.679569 },
  { isoDate: "2010-01-28T00:00:00Z", dsun: 209.398528, hgln: -0.023286, hglt: -5.760662 },
  { isoDate: "2010-01-29T00:00:00Z", dsun: 209.423754, hgln: -0.013711, hglt: -5.839864 },
  { isoDate: "2010-01-30T00:00:00Z", dsun: 209.450505, hgln: -0.004085, hglt: -5.917149 },
  { isoDate: "2010-01-31T00:00:00Z", dsun: 209.478772, hgln: 0.005554, hglt: -5.992492 },
  { isoDate: "2010-02-01T00:00:00Z", dsun: 209.508545, hgln: 0.015173, hglt: -6.065867 },
  { isoDate: "2010-02-02T00:00:00Z", dsun: 209.539812, hgln: 0.024735, hglt: -6.137251 },
  { isoDate: "2010-02-03T00:00:00Z", dsun: 209.572561, hgln: 0.034211, hglt: -6.206622 },
  { isoDate: "2010-02-04T00:00:00Z", dsun: 209.606776, hgln: 0.043575, hglt: -6.273956 },
  { isoDate: "2010-02-05T00:00:00Z", dsun: 209.642439, hgln: 0.052809, hglt: -6.339232 },
  { isoDate: "2010-02-06T00:00:00Z", dsun: 209.679534, hgln: 0.061895, hglt: -6.40243 },
  { isoDate: "2010-02-07T00:00:00Z", dsun: 209.718042, hgln: 0.070825, hglt: -6.463532 },
  { isoDate: "2010-02-08T00:00:00Z", dsun: 209.757943, hgln: 0.079591, hglt: -6.522517 },
  { isoDate: "2010-02-09T00:00:00Z", dsun: 209.799217, hgln: 0.088187, hglt: -6.57937 },
  { isoDate: "2010-02-10T00:00:00Z", dsun: 209.841842, hgln: 0.096612, hglt: -6.634072 },
  { isoDate: "2010-02-11T00:00:00Z", dsun: 209.885797, hgln: 0.104863, hglt: -6.686609 },
  { isoDate: "2010-02-12T00:00:00Z", dsun: 209.931057, hgln: 0.112942, hglt: -6.736964 },
  { isoDate: "2010-02-13T00:00:00Z", dsun: 209.977598, hgln: 0.120848, hglt: -6.785125 },
  { isoDate: "2010-02-14T00:00:00Z", dsun: 210.025392, hgln: 0.128585, hglt: -6.831079 },
  { isoDate: "2010-02-15T00:00:00Z", dsun: 210.074411, hgln: 0.136155, hglt: -6.874812 },
  { isoDate: "2010-02-16T00:00:00Z", dsun: 210.124623, hgln: 0.143562, hglt: -6.916315 },
  { isoDate: "2010-02-17T00:00:00Z", dsun: 210.175997, hgln: 0.150809, hglt: -6.955577 },
  { isoDate: "2010-02-18T00:00:00Z", dsun: 210.228499, hgln: 0.157901, hglt: -6.992589 },
  { isoDate: "2010-02-19T00:00:00Z", dsun: 210.282096, hgln: 0.164841, hglt: -7.027343 },
  { isoDate: "2010-02-20T00:00:00Z", dsun: 210.336756, hgln: 0.171631, hglt: -7.059832 },
  { isoDate: "2010-02-21T00:00:00Z", dsun: 210.392443, hgln: 0.178272, hglt: -7.090049 },
  { isoDate: "2010-02-22T00:00:00Z", dsun: 210.449123, hgln: 0.184764, hglt: -7.11799 },
  { isoDate: "2010-02-23T00:00:00Z", dsun: 210.506763, hgln: 0.191105, hglt: -7.143649 },
  { isoDate: "2010-02-24T00:00:00Z", dsun: 210.565326, hgln: 0.197286, hglt: -7.167023 },
  { isoDate: "2010-02-25T00:00:00Z", dsun: 210.624778, hgln: 0.203297, hglt: -7.188109 },
  { isoDate: "2010-02-26T00:00:00Z", dsun: 210.685081, hgln: 0.209122, hglt: -7.206906 },
  { isoDate: "2010-02-27T00:00:00Z", dsun: 210.746199, hgln: 0.21474, hglt: -7.223413 },
  { isoDate: "2010-02-28T00:00:00Z", dsun: 210.808095, hgln: 0.220125, hglt: -7.23763 },
  { isoDate: "2010-03-01T00:00:00Z", dsun: 210.870729, hgln: 0.225248, hglt: -7.249558 },
  { isoDate: "2010-03-02T00:00:00Z", dsun: 210.934064, hgln: 0.23008, hglt: -7.259198 },
  { isoDate: "2010-03-03T00:00:00Z", dsun: 210.998058, hgln: 0.234593, hglt: -7.266554 },
  { isoDate: "2010-03-04T00:00:00Z", dsun: 211.062675, hgln: 0.238762, hglt: -7.271628 },
  { isoDate: "2010-03-05T00:00:00Z", dsun: 211.127872, hgln: 0.242569, hglt: -7.274426 },
  { isoDate: "2010-03-06T00:00:00Z", dsun: 211.193612, hgln: 0.246001, hglt: -7.274953 },
  { isoDate: "2010-03-07T00:00:00Z", dsun: 211.259855, hgln: 0.249047, hglt: -7.273215 },
  { isoDate: "2010-03-08T00:00:00Z", dsun: 211.326563, hgln: 0.251704, hglt: -7.269219 },
  { isoDate: "2010-03-09T00:00:00Z", dsun: 211.393698, hgln: 0.253971, hglt: -7.262973 },
  { isoDate: "2010-03-10T00:00:00Z", dsun: 211.461223, hgln: 0.255848, hglt: -7.254482 },
  { isoDate: "2010-03-11T00:00:00Z", dsun: 211.529103, hgln: 0.25734, hglt: -7.243761 },
  { isoDate: "2010-03-12T00:00:00Z", dsun: 211.597301, hgln: 0.258452, hglt: -7.230818 },
  { isoDate: "2010-03-13T00:00:00Z", dsun: 211.665781, hgln: 0.259192, hglt: -7.215663 },
  { isoDate: "2010-03-14T00:00:00Z", dsun: 211.734506, hgln: 0.259568, hglt: -7.198307 },
  { isoDate: "2010-03-15T00:00:00Z", dsun: 211.803441, hgln: 0.259592, hglt: -7.178764 },
  { isoDate: "2010-03-16T00:00:00Z", dsun: 211.872547, hgln: 0.259275, hglt: -7.157046 },
  { isoDate: "2010-03-17T00:00:00Z", dsun: 211.941787, hgln: 0.258632, hglt: -7.133167 },
  { isoDate: "2010-03-18T00:00:00Z", dsun: 212.011125, hgln: 0.257677, hglt: -7.107142 },
  { isoDate: "2010-03-19T00:00:00Z", dsun: 212.080526, hgln: 0.256426, hglt: -7.078986 },
  { isoDate: "2010-03-20T00:00:00Z", dsun: 212.149956, hgln: 0.254893, hglt: -7.048714 },
  { isoDate: "2010-03-21T00:00:00Z", dsun: 212.219382, hgln: 0.253095, hglt: -7.016343 },
  { isoDate: "2010-03-22T00:00:00Z", dsun: 212.288774, hgln: 0.251042, hglt: -6.981891 },
  { isoDate: "2010-03-23T00:00:00Z", dsun: 212.358102, hgln: 0.248747, hglt: -6.945375 },
  { isoDate: "2010-03-24T00:00:00Z", dsun: 212.427338, hgln: 0.246216, hglt: -6.906813 },
  { isoDate: "2010-03-25T00:00:00Z", dsun: 212.496455, hgln: 0.243454, hglt: -6.866224 },
  { isoDate: "2010-03-26T00:00:00Z", dsun: 212.565427, hgln: 0.24046, hglt: -6.823629 },
  { isoDate: "2010-03-27T00:00:00Z", dsun: 212.634228, hgln: 0.237229, hglt: -6.779046 },
  { isoDate: "2010-03-28T00:00:00Z", dsun: 212.702834, hgln: 0.233749, hglt: -6.732497 },
  { isoDate: "2010-03-29T00:00:00Z", dsun: 212.771222, hgln: 0.230007, hglt: -6.684002 },
  { isoDate: "2010-03-30T00:00:00Z", dsun: 212.83937, hgln: 0.225984, hglt: -6.633584 },
  { isoDate: "2010-03-31T00:00:00Z", dsun: 212.907254, hgln: 0.221663, hglt: -6.581265 },
  { isoDate: "2010-04-01T00:00:00Z", dsun: 212.974855, hgln: 0.217026, hglt: -6.527067 },
  { isoDate: "2010-04-02T00:00:00Z", dsun: 213.042152, hgln: 0.212059, hglt: -6.471014 },
  { isoDate: "2010-04-03T00:00:00Z", dsun: 213.109128, hgln: 0.206754, hglt: -6.413128 },
  { isoDate: "2010-04-04T00:00:00Z", dsun: 213.175765, hgln: 0.201106, hglt: -6.353435 },
  { isoDate: "2010-04-05T00:00:00Z", dsun: 213.242048, hgln: 0.195114, hglt: -6.291959 },
  { isoDate: "2010-04-06T00:00:00Z", dsun: 213.307963, hgln: 0.188782, hglt: -6.228723 },
  { isoDate: "2010-04-07T00:00:00Z", dsun: 213.373496, hgln: 0.182116, hglt: -6.163753 },
  { isoDate: "2010-04-08T00:00:00Z", dsun: 213.438642, hgln: 0.175127, hglt: -6.097076 },
  { isoDate: "2010-04-09T00:00:00Z", dsun: 213.503391, hgln: 0.167828, hglt: -6.028717 },
  { isoDate: "2010-04-10T00:00:00Z", dsun: 213.567738, hgln: 0.160232, hglt: -5.958701 },
  { isoDate: "2010-04-11T00:00:00Z", dsun: 213.631677, hgln: 0.152357, hglt: -5.887055 },
  { isoDate: "2010-04-12T00:00:00Z", dsun: 213.695204, hgln: 0.14422, hglt: -5.813806 },
  { isoDate: "2010-04-13T00:00:00Z", dsun: 213.758316, hgln: 0.135843, hglt: -5.738981 },
  { isoDate: "2010-04-14T00:00:00Z", dsun: 213.821009, hgln: 0.127249, hglt: -5.662606 },
  { isoDate: "2010-04-15T00:00:00Z", dsun: 213.883274, hgln: 0.118463, hglt: -5.58471 },
  { isoDate: "2010-04-16T00:00:00Z", dsun: 213.945104, hgln: 0.109512, hglt: -5.505318 },
  { isoDate: "2010-04-17T00:00:00Z", dsun: 214.00649, hgln: 0.100423, hglt: -5.42446 },
  { isoDate: "2010-04-18T00:00:00Z", dsun: 214.067424, hgln: 0.091228, hglt: -5.342162 },
  { isoDate: "2010-04-19T00:00:00Z", dsun: 214.127899, hgln: 0.081954, hglt: -5.258452 },
  { isoDate: "2010-04-20T00:00:00Z", dsun: 214.187906, hgln: 0.072627, hglt: -5.173357 },
  { isoDate: "2010-04-21T00:00:00Z", dsun: 214.247441, hgln: 0.063274, hglt: -5.086905 },
  { isoDate: "2010-04-22T00:00:00Z", dsun: 214.306496, hgln: 0.053916, hglt: -4.999123 },
  { isoDate: "2010-04-23T00:00:00Z", dsun: 214.365067, hgln: 0.044568, hglt: -4.910039 },
  { isoDate: "2010-04-24T00:00:00Z", dsun: 214.423147, hgln: 0.035244, hglt: -4.819682 },
  { isoDate: "2010-04-25T00:00:00Z", dsun: 214.480727, hgln: 0.02595, hglt: -4.728077 },
  { isoDate: "2010-04-26T00:00:00Z", dsun: 214.5378, hgln: 0.016689, hglt: -4.635254 },
  { isoDate: "2010-04-27T00:00:00Z", dsun: 214.594354, hgln: 0.00746, hglt: -4.541239 },
  { isoDate: "2010-04-28T00:00:00Z", dsun: 214.650378, hgln: -0.00174, hglt: -4.446059 },
  { isoDate: "2010-04-29T00:00:00Z", dsun: 214.705859, hgln: -0.010918, hglt: -4.349743 },
  { isoDate: "2010-04-30T00:00:00Z", dsun: 214.760779, hgln: -0.020077, hglt: -4.252317 },
  { isoDate: "2010-05-01T00:00:00Z", dsun: 214.815122, hgln: -0.029219, hglt: -4.153809 },
  { isoDate: "2010-05-02T00:00:00Z", dsun: 214.868869, hgln: -0.038345, hglt: -4.054246 },
  { isoDate: "2010-05-03T00:00:00Z", dsun: 214.921998, hgln: -0.047452, hglt: -3.953654 },
  { isoDate: "2010-05-04T00:00:00Z", dsun: 214.974485, hgln: -0.056533, hglt: -3.852061 },
  { isoDate: "2010-05-05T00:00:00Z", dsun: 215.026306, hgln: -0.065581, hglt: -3.749495 },
  { isoDate: "2010-05-06T00:00:00Z", dsun: 215.077438, hgln: -0.074585, hglt: -3.645981 },
  { isoDate: "2010-05-07T00:00:00Z", dsun: 215.127853, hgln: -0.083533, hglt: -3.541546 },
  { isoDate: "2010-05-08T00:00:00Z", dsun: 215.177526, hgln: -0.092409, hglt: -3.436218 },
  { isoDate: "2010-05-09T00:00:00Z", dsun: 215.226432, hgln: -0.101199, hglt: -3.330024 },
  { isoDate: "2010-05-10T00:00:00Z", dsun: 215.274545, hgln: -0.109885, hglt: -3.222991 },
  { isoDate: "2010-05-11T00:00:00Z", dsun: 215.32184, hgln: -0.118447, hglt: -3.115144 },
  { isoDate: "2010-05-12T00:00:00Z", dsun: 215.368292, hgln: -0.126864, hglt: -3.006514 },
  { isoDate: "2010-05-13T00:00:00Z", dsun: 215.413883, hgln: -0.135115, hglt: -2.897125 },
  { isoDate: "2010-05-14T00:00:00Z", dsun: 215.458588, hgln: -0.143174, hglt: -2.787004 },
  { isoDate: "2010-05-15T00:00:00Z", dsun: 215.502386, hgln: -0.151018, hglt: -2.676181 },
  { isoDate: "2010-05-16T00:00:00Z", dsun: 215.545257, hgln: -0.158619, hglt: -2.564682 },
  { isoDate: "2010-05-17T00:00:00Z", dsun: 215.587177, hgln: -0.165954, hglt: -2.452536 },
  { isoDate: "2010-05-18T00:00:00Z", dsun: 215.628122, hgln: -0.173, hglt: -2.33977 },
  { isoDate: "2010-05-19T00:00:00Z", dsun: 215.668069, hgln: -0.179735, hglt: -2.226412 },
  { isoDate: "2010-05-20T00:00:00Z", dsun: 215.706994, hgln: -0.186145, hglt: -2.11249 },
  { isoDate: "2010-05-21T00:00:00Z", dsun: 215.744877, hgln: -0.192216, hglt: -1.998033 },
  { isoDate: "2010-05-22T00:00:00Z", dsun: 215.781698, hgln: -0.197942, hglt: -1.883068 },
  { isoDate: "2010-05-23T00:00:00Z", dsun: 215.817441, hgln: -0.20332, hglt: -1.767624 },
  { isoDate: "2010-05-24T00:00:00Z", dsun: 215.852089, hgln: -0.208354, hglt: -1.65173 },
  { isoDate: "2010-05-25T00:00:00Z", dsun: 215.885632, hgln: -0.213052, hglt: -1.535414 },
  { isoDate: "2010-05-26T00:00:00Z", dsun: 215.918056, hgln: -0.217424, hglt: -1.418706 },
  { isoDate: "2010-05-27T00:00:00Z", dsun: 215.949354, hgln: -0.221484, hglt: -1.301634 },
  { isoDate: "2010-05-28T00:00:00Z", dsun: 215.979516, hgln: -0.225247, hglt: -1.184227 },
  { isoDate: "2010-05-29T00:00:00Z", dsun: 216.008536, hgln: -0.228728, hglt: -1.066514 },
  { isoDate: "2010-05-30T00:00:00Z", dsun: 216.036407, hgln: -0.231941, hglt: -0.948527 },
  { isoDate: "2010-05-31T00:00:00Z", dsun: 216.063126, hgln: -0.234898, hglt: -0.830292 },
  { isoDate: "2010-06-01T00:00:00Z", dsun: 216.088688, hgln: -0.237612, hglt: -0.711842 },
  { isoDate: "2010-06-02T00:00:00Z", dsun: 216.11309, hgln: -0.240089, hglt: -0.593205 },
  { isoDate: "2010-06-03T00:00:00Z", dsun: 216.136332, hgln: -0.242338, hglt: -0.474411 },
  { isoDate: "2010-06-04T00:00:00Z", dsun: 216.158411, hgln: -0.244363, hglt: -0.35549 },
  { isoDate: "2010-06-05T00:00:00Z", dsun: 216.179329, hgln: -0.246166, hglt: -0.236473 },
  { isoDate: "2010-06-06T00:00:00Z", dsun: 216.199086, hgln: -0.247751, hglt: -0.117389 },
  { isoDate: "2010-06-07T00:00:00Z", dsun: 216.217686, hgln: -0.249115, hglt: 0.001731 },
  { isoDate: "2010-06-08T00:00:00Z", dsun: 216.235132, hgln: -0.250255, hglt: 0.120857 },
  { isoDate: "2010-06-09T00:00:00Z", dsun: 216.251432, hgln: -0.251168, hglt: 0.239959 },
  { isoDate: "2010-06-10T00:00:00Z", dsun: 216.266587, hgln: -0.251844, hglt: 0.359005 },
  { isoDate: "2010-06-11T00:00:00Z", dsun: 216.280608, hgln: -0.252272, hglt: 0.477967 },
  { isoDate: "2010-06-12T00:00:00Z", dsun: 216.293505, hgln: -0.252439, hglt: 0.596812 },
  { isoDate: "2010-06-13T00:00:00Z", dsun: 216.305288, hgln: -0.252328, hglt: 0.71551 },
  { isoDate: "2010-06-14T00:00:00Z", dsun: 216.31597, hgln: -0.251922, hglt: 0.834032 },
  { isoDate: "2010-06-15T00:00:00Z", dsun: 216.325562, hgln: -0.251202, hglt: 0.952345 },
  { isoDate: "2010-06-16T00:00:00Z", dsun: 216.334078, hgln: -0.250153, hglt: 1.07042 },
  { isoDate: "2010-06-17T00:00:00Z", dsun: 216.341529, hgln: -0.248761, hglt: 1.188225 },
  { isoDate: "2010-06-18T00:00:00Z", dsun: 216.347926, hgln: -0.247017, hglt: 1.30573 },
  { isoDate: "2010-06-19T00:00:00Z", dsun: 216.353283, hgln: -0.244918, hglt: 1.422904 },
  { isoDate: "2010-06-20T00:00:00Z", dsun: 216.357612, hgln: -0.242465, hglt: 1.539715 },
  { isoDate: "2010-06-21T00:00:00Z", dsun: 216.360928, hgln: -0.239662, hglt: 1.656134 },
  { isoDate: "2010-06-22T00:00:00Z", dsun: 216.363245, hgln: -0.236521, hglt: 1.77213 },
  { isoDate: "2010-06-23T00:00:00Z", dsun: 216.36458, hgln: -0.233055, hglt: 1.887671 },
  { isoDate: "2010-06-24T00:00:00Z", dsun: 216.36495, hgln: -0.22928, hglt: 2.002727 },
  { isoDate: "2010-06-25T00:00:00Z", dsun: 216.364372, hgln: -0.225214, hglt: 2.117268 },
  { isoDate: "2010-06-26T00:00:00Z", dsun: 216.362863, hgln: -0.220877, hglt: 2.231263 },
  { isoDate: "2010-06-27T00:00:00Z", dsun: 216.360442, hgln: -0.216288, hglt: 2.344681 },
  { isoDate: "2010-06-28T00:00:00Z", dsun: 216.357126, hgln: -0.211468, hglt: 2.457492 },
  { isoDate: "2010-06-29T00:00:00Z", dsun: 216.352935, hgln: -0.206434, hglt: 2.569665 },
  { isoDate: "2010-06-30T00:00:00Z", dsun: 216.347885, hgln: -0.201204, hglt: 2.68117 },
  { isoDate: "2010-07-01T00:00:00Z", dsun: 216.341995, hgln: -0.195795, hglt: 2.791977 },
  { isoDate: "2010-07-02T00:00:00Z", dsun: 216.335281, hgln: -0.190221, hglt: 2.902054 },
  { isoDate: "2010-07-03T00:00:00Z", dsun: 216.327763, hgln: -0.184496, hglt: 3.011373 },
  { isoDate: "2010-07-04T00:00:00Z", dsun: 216.319456, hgln: -0.178631, hglt: 3.119903 },
  { isoDate: "2010-07-05T00:00:00Z", dsun: 216.310379, hgln: -0.172635, hglt: 3.227613 },
  { isoDate: "2010-07-06T00:00:00Z", dsun: 216.300548, hgln: -0.166519, hglt: 3.334475 },
  { isoDate: "2010-07-07T00:00:00Z", dsun: 216.289981, hgln: -0.160286, hglt: 3.440459 },
  { isoDate: "2010-07-08T00:00:00Z", dsun: 216.278695, hgln: -0.15394, hglt: 3.545534 },
  { isoDate: "2010-07-09T00:00:00Z", dsun: 216.266707, hgln: -0.147482, hglt: 3.649673 },
  { isoDate: "2010-07-10T00:00:00Z", dsun: 216.254035, hgln: -0.140906, hglt: 3.752844 },
  { isoDate: "2010-07-11T00:00:00Z", dsun: 216.240699, hgln: -0.134205, hglt: 3.855021 },
  { isoDate: "2010-07-12T00:00:00Z", dsun: 216.226714, hgln: -0.127369, hglt: 3.956173 },
  { isoDate: "2010-07-13T00:00:00Z", dsun: 216.212099, hgln: -0.120383, hglt: 4.056271 },
  { isoDate: "2010-07-14T00:00:00Z", dsun: 216.196869, hgln: -0.113231, hglt: 4.155288 },
  { isoDate: "2010-07-15T00:00:00Z", dsun: 216.18104, hgln: -0.105908, hglt: 4.253195 },
  { isoDate: "2010-07-16T00:00:00Z", dsun: 216.164627, hgln: -0.098402, hglt: 4.349963 },
  { isoDate: "2010-07-17T00:00:00Z", dsun: 216.14764, hgln: -0.090709, hglt: 4.445566 },
  { isoDate: "2010-07-18T00:00:00Z", dsun: 216.130094, hgln: -0.08283, hglt: 4.539975 },
  { isoDate: "2010-07-19T00:00:00Z", dsun: 216.112002, hgln: -0.074771, hglt: 4.633163 },
  { isoDate: "2010-07-20T00:00:00Z", dsun: 216.093375, hgln: -0.066544, hglt: 4.725103 },
  { isoDate: "2010-07-21T00:00:00Z", dsun: 216.074227, hgln: -0.058164, hglt: 4.815767 },
  { isoDate: "2010-07-22T00:00:00Z", dsun: 216.05457, hgln: -0.049647, hglt: 4.90513 },
  { isoDate: "2010-07-23T00:00:00Z", dsun: 216.034415, hgln: -0.041014, hglt: 4.993164 },
  { isoDate: "2010-07-24T00:00:00Z", dsun: 216.013773, hgln: -0.032288, hglt: 5.079845 },
  { isoDate: "2010-07-25T00:00:00Z", dsun: 215.992656, hgln: -0.02349, hglt: 5.165146 },
  { isoDate: "2010-07-26T00:00:00Z", dsun: 215.971071, hgln: -0.014645, hglt: 5.249042 },
  { isoDate: "2010-07-27T00:00:00Z", dsun: 215.949029, hgln: -0.005776, hglt: 5.331508 },
  { isoDate: "2010-07-28T00:00:00Z", dsun: 215.926535, hgln: 0.003093, hglt: 5.412519 },
  { isoDate: "2010-07-29T00:00:00Z", dsun: 215.903598, hgln: 0.01194, hglt: 5.49205 },
  { isoDate: "2010-07-30T00:00:00Z", dsun: 215.880222, hgln: 0.020744, hglt: 5.570078 },
  { isoDate: "2010-07-31T00:00:00Z", dsun: 215.856413, hgln: 0.029483, hglt: 5.646578 },
  { isoDate: "2010-08-01T00:00:00Z", dsun: 215.832174, hgln: 0.038139, hglt: 5.721529 },
  { isoDate: "2010-08-02T00:00:00Z", dsun: 215.807508, hgln: 0.046694, hglt: 5.794905 },
  { isoDate: "2010-08-03T00:00:00Z", dsun: 215.782419, hgln: 0.055132, hglt: 5.866686 },
  { isoDate: "2010-08-04T00:00:00Z", dsun: 215.756909, hgln: 0.063441, hglt: 5.936849 },
  { isoDate: "2010-08-05T00:00:00Z", dsun: 215.730979, hgln: 0.071608, hglt: 6.005373 },
  { isoDate: "2010-08-06T00:00:00Z", dsun: 215.704669, hgln: 0.079625, hglt: 6.072238 },
  { isoDate: "2010-08-07T00:00:00Z", dsun: 215.677911, hgln: 0.087491, hglt: 6.137419 },
  { isoDate: "2010-08-08T00:00:00Z", dsun: 215.650739, hgln: 0.095205, hglt: 6.200898 },
  { isoDate: "2010-08-09T00:00:00Z", dsun: 215.623153, hgln: 0.102772, hglt: 6.262655 },
  { isoDate: "2010-08-10T00:00:00Z", dsun: 215.595152, hgln: 0.110204, hglt: 6.322671 },
  { isoDate: "2010-08-11T00:00:00Z", dsun: 215.566735, hgln: 0.117512, hglt: 6.380926 },
  { isoDate: "2010-08-12T00:00:00Z", dsun: 215.537897, hgln: 0.124712, hglt: 6.437403 },
  { isoDate: "2010-08-13T00:00:00Z", dsun: 215.508635, hgln: 0.131817, hglt: 6.492082 },
  { isoDate: "2010-08-14T00:00:00Z", dsun: 215.478944, hgln: 0.138836, hglt: 6.544947 },
  { isoDate: "2010-08-15T00:00:00Z", dsun: 215.448819, hgln: 0.145774, hglt: 6.59598 },
  { isoDate: "2010-08-16T00:00:00Z", dsun: 215.418256, hgln: 0.152631, hglt: 6.645165 },
  { isoDate: "2010-08-17T00:00:00Z", dsun: 215.38725, hgln: 0.159403, hglt: 6.692485 },
  { isoDate: "2010-08-18T00:00:00Z", dsun: 215.355797, hgln: 0.166079, hglt: 6.737926 },
  { isoDate: "2010-08-19T00:00:00Z", dsun: 215.323891, hgln: 0.172648, hglt: 6.781472 },
  { isoDate: "2010-08-20T00:00:00Z", dsun: 215.291529, hgln: 0.179094, hglt: 6.823109 },
  { isoDate: "2010-08-21T00:00:00Z", dsun: 215.258704, hgln: 0.185402, hglt: 6.862823 },
  { isoDate: "2010-08-22T00:00:00Z", dsun: 215.22541, hgln: 0.191553, hglt: 6.900601 },
  { isoDate: "2010-08-23T00:00:00Z", dsun: 215.191641, hgln: 0.197528, hglt: 6.936429 },
  { isoDate: "2010-08-24T00:00:00Z", dsun: 215.157392, hgln: 0.203306, hglt: 6.970295 },
  { isoDate: "2010-08-25T00:00:00Z", dsun: 215.122653, hgln: 0.208869, hglt: 7.002188 },
  { isoDate: "2010-08-26T00:00:00Z", dsun: 215.0873, hgln: 0.214194, hglt: 7.032107 },
  { isoDate: "2010-08-27T00:00:00Z", dsun: 215.05154, hgln: 0.219267, hglt: 7.060022 },
  { isoDate: "2010-08-28T00:00:00Z", dsun: 215.015268, hgln: 0.224067, hglt: 7.085932 },
  { isoDate: "2010-08-29T00:00:00Z", dsun: 214.978478, hgln: 0.228576, hglt: 7.109827 },
  { isoDate: "2010-08-30T00:00:00Z", dsun: 214.941161, hgln: 0.232778, hglt: 7.131698 },
  { isoDate: "2010-08-31T00:00:00Z", dsun: 214.90331, hgln: 0.236657, hglt: 7.151536 },
  { isoDate: "2010-09-01T00:00:00Z", dsun: 214.865167, hgln: 0.240193, hglt: 7.169323 },
  { isoDate: "2010-09-02T00:00:00Z", dsun: 214.826252, hgln: 0.243389, hglt: 7.185072 },
  { isoDate: "2010-09-03T00:00:00Z", dsun: 214.786785, hgln: 0.246232, hglt: 7.198768 },
  { isoDate: "2010-09-04T00:00:00Z", dsun: 214.746762, hgln: 0.248717, hglt: 7.210403 },
  { isoDate: "2010-09-05T00:00:00Z", dsun: 214.706178, hgln: 0.250845, hglt: 7.219974 },
  { isoDate: "2010-09-06T00:00:00Z", dsun: 214.66503, hgln: 0.252622, hglt: 7.227473 },
  { isoDate: "2010-09-07T00:00:00Z", dsun: 214.623313, hgln: 0.25406, hglt: 7.232898 },
  { isoDate: "2010-09-08T00:00:00Z", dsun: 214.581022, hgln: 0.255175, hglt: 7.236245 },
  { isoDate: "2010-09-09T00:00:00Z", dsun: 214.538154, hgln: 0.255986, hglt: 7.237509 },
  { isoDate: "2010-09-10T00:00:00Z", dsun: 214.494702, hgln: 0.256516, hglt: 7.236689 },
  { isoDate: "2010-09-11T00:00:00Z", dsun: 214.450663, hgln: 0.256785, hglt: 7.233783 },
  { isoDate: "2010-09-12T00:00:00Z", dsun: 214.406033, hgln: 0.25681, hglt: 7.228789 },
  { isoDate: "2010-09-13T00:00:00Z", dsun: 214.360811, hgln: 0.256603, hglt: 7.221706 },
  { isoDate: "2010-09-14T00:00:00Z", dsun: 214.314997, hgln: 0.256172, hglt: 7.212535 },
  { isoDate: "2010-09-15T00:00:00Z", dsun: 214.268592, hgln: 0.25552, hglt: 7.201274 },
  { isoDate: "2010-09-16T00:00:00Z", dsun: 214.221596, hgln: 0.254645, hglt: 7.187926 },
  { isoDate: "2010-09-17T00:00:00Z", dsun: 214.174014, hgln: 0.253544, hglt: 7.17249 },
  { isoDate: "2010-09-18T00:00:00Z", dsun: 214.125849, hgln: 0.252212, hglt: 7.154971 },
  { isoDate: "2010-09-19T00:00:00Z", dsun: 214.077105, hgln: 0.250639, hglt: 7.135369 },
  { isoDate: "2010-09-20T00:00:00Z", dsun: 214.027787, hgln: 0.248817, hglt: 7.113688 },
  { isoDate: "2010-09-21T00:00:00Z", dsun: 213.977902, hgln: 0.246735, hglt: 7.089932 },
  { isoDate: "2010-09-22T00:00:00Z", dsun: 213.927456, hgln: 0.244382, hglt: 7.064105 },
  { isoDate: "2010-09-23T00:00:00Z", dsun: 213.876457, hgln: 0.241746, hglt: 7.036211 },
  { isoDate: "2010-09-24T00:00:00Z", dsun: 213.824913, hgln: 0.238815, hglt: 7.006256 },
  { isoDate: "2010-09-25T00:00:00Z", dsun: 213.772833, hgln: 0.235576, hglt: 6.974246 },
  { isoDate: "2010-09-26T00:00:00Z", dsun: 213.720229, hgln: 0.232016, hglt: 6.940186 },
  { isoDate: "2010-09-27T00:00:00Z", dsun: 213.667112, hgln: 0.228125, hglt: 6.904085 },
  { isoDate: "2010-09-28T00:00:00Z", dsun: 213.613494, hgln: 0.223892, hglt: 6.865949 },
  { isoDate: "2010-09-29T00:00:00Z", dsun: 213.559391, hgln: 0.219308, hglt: 6.825786 },
  { isoDate: "2010-09-30T00:00:00Z", dsun: 213.504818, hgln: 0.214368, hglt: 6.783605 },
  { isoDate: "2010-10-01T00:00:00Z", dsun: 213.449794, hgln: 0.209066, hglt: 6.739415 },
  { isoDate: "2010-10-02T00:00:00Z", dsun: 213.394339, hgln: 0.203403, hglt: 6.693225 },
  { isoDate: "2010-10-03T00:00:00Z", dsun: 213.338474, hgln: 0.197382, hglt: 6.645045 },
  { isoDate: "2010-10-04T00:00:00Z", dsun: 213.282225, hgln: 0.191012, hglt: 6.594887 },
  { isoDate: "2010-10-05T00:00:00Z", dsun: 213.225617, hgln: 0.184308, hglt: 6.542759 },
  { isoDate: "2010-10-06T00:00:00Z", dsun: 213.168677, hgln: 0.177289, hglt: 6.488675 },
  { isoDate: "2010-10-07T00:00:00Z", dsun: 213.111431, hgln: 0.16998, hglt: 6.432646 },
  { isoDate: "2010-10-08T00:00:00Z", dsun: 213.053904, hgln: 0.16241, hglt: 6.374685 },
  { isoDate: "2010-10-09T00:00:00Z", dsun: 212.996123, hgln: 0.154609, hglt: 6.314804 },
  { isoDate: "2010-10-10T00:00:00Z", dsun: 212.938111, hgln: 0.146606, hglt: 6.253018 },
  { isoDate: "2010-10-11T00:00:00Z", dsun: 212.879895, hgln: 0.13843, hglt: 6.18934 },
  { isoDate: "2010-10-12T00:00:00Z", dsun: 212.821502, hgln: 0.130104, hglt: 6.123786 },
  { isoDate: "2010-10-13T00:00:00Z", dsun: 212.762962, hgln: 0.121649, hglt: 6.056371 },
  { isoDate: "2010-10-14T00:00:00Z", dsun: 212.704305, hgln: 0.11308, hglt: 5.987109 },
  { isoDate: "2010-10-15T00:00:00Z", dsun: 212.645562, hgln: 0.104411, hglt: 5.916018 },
  { isoDate: "2010-10-16T00:00:00Z", dsun: 212.586764, hgln: 0.095653, hglt: 5.843114 },
  { isoDate: "2010-10-17T00:00:00Z", dsun: 212.527944, hgln: 0.086814, hglt: 5.768413 },
  { isoDate: "2010-10-18T00:00:00Z", dsun: 212.469132, hgln: 0.077903, hglt: 5.691935 },
  { isoDate: "2010-10-19T00:00:00Z", dsun: 212.41036, hgln: 0.068924, hglt: 5.613696 },
  { isoDate: "2010-10-20T00:00:00Z", dsun: 212.351657, hgln: 0.059882, hglt: 5.533717 },
  { isoDate: "2010-10-21T00:00:00Z", dsun: 212.293051, hgln: 0.050782, hglt: 5.452016 },
  { isoDate: "2010-10-22T00:00:00Z", dsun: 212.234568, hgln: 0.041624, hglt: 5.368613 },
  { isoDate: "2010-10-23T00:00:00Z", dsun: 212.176236, hgln: 0.032412, hglt: 5.283529 },
  { isoDate: "2010-10-24T00:00:00Z", dsun: 212.118075, hgln: 0.023144, hglt: 5.196786 },
  { isoDate: "2010-10-25T00:00:00Z", dsun: 212.060108, hgln: 0.013822, hglt: 5.108406 },
  { isoDate: "2010-10-26T00:00:00Z", dsun: 212.002353, hgln: 0.004446, hglt: 5.01841 },
  { isoDate: "2010-10-27T00:00:00Z", dsun: 211.944894, hgln: -0.004946, hglt: 4.926816 },
  { isoDate: "2010-10-28T00:00:00Z", dsun: 211.887616, hgln: -0.01443, hglt: 4.833662 },
  { isoDate: "2010-10-29T00:00:00Z", dsun: 211.830594, hgln: -0.02396, hglt: 4.738965 },
  { isoDate: "2010-10-30T00:00:00Z", dsun: 211.773841, hgln: -0.033532, hglt: 4.642751 },
  { isoDate: "2010-10-31T00:00:00Z", dsun: 211.717365, hgln: -0.043134, hglt: 4.545045 },
  { isoDate: "2010-11-01T00:00:00Z", dsun: 211.661175, hgln: -0.052753, hglt: 4.445874 },
  { isoDate: "2010-11-02T00:00:00Z", dsun: 211.605278, hgln: -0.062369, hglt: 4.345265 },
  { isoDate: "2010-11-03T00:00:00Z", dsun: 211.549683, hgln: -0.071958, hglt: 4.243245 },
  { isoDate: "2010-11-04T00:00:00Z", dsun: 211.494397, hgln: -0.081493, hglt: 4.139844 },
  { isoDate: "2010-11-05T00:00:00Z", dsun: 211.439428, hgln: -0.090941, hglt: 4.03509 },
  { isoDate: "2010-11-06T00:00:00Z", dsun: 211.384784, hgln: -0.100268, hglt: 3.929011 },
  { isoDate: "2010-11-07T00:00:00Z", dsun: 211.330472, hgln: -0.109441, hglt: 3.82164 },
  { isoDate: "2010-11-08T00:00:00Z", dsun: 211.276498, hgln: -0.118426, hglt: 3.713006 },
  { isoDate: "2010-11-09T00:00:00Z", dsun: 211.222865, hgln: -0.127196, hglt: 3.603141 },
  { isoDate: "2010-11-10T00:00:00Z", dsun: 211.169574, hgln: -0.135724, hglt: 3.492078 },
  { isoDate: "2010-11-11T00:00:00Z", dsun: 211.116625, hgln: -0.143991, hglt: 3.37985 },
  { isoDate: "2010-11-12T00:00:00Z", dsun: 211.064017, hgln: -0.151978, hglt: 3.26649 },
  { isoDate: "2010-11-13T00:00:00Z", dsun: 211.011751, hgln: -0.159671, hglt: 3.152032 },
  { isoDate: "2010-11-14T00:00:00Z", dsun: 210.959829, hgln: -0.16706, hglt: 3.03651 },
  { isoDate: "2010-11-15T00:00:00Z", dsun: 210.908254, hgln: -0.174136, hglt: 2.919959 },
  { isoDate: "2010-11-16T00:00:00Z", dsun: 210.857032, hgln: -0.180893, hglt: 2.802415 },
  { isoDate: "2010-11-17T00:00:00Z", dsun: 210.80617, hgln: -0.187329, hglt: 2.683912 },
  { isoDate: "2010-11-18T00:00:00Z", dsun: 210.755676, hgln: -0.193442, hglt: 2.564487 },
  { isoDate: "2010-11-19T00:00:00Z", dsun: 210.70556, hgln: -0.199235, hglt: 2.444177 },
  { isoDate: "2010-11-20T00:00:00Z", dsun: 210.655832, hgln: -0.204711, hglt: 2.323018 },
  { isoDate: "2010-11-21T00:00:00Z", dsun: 210.606505, hgln: -0.209879, hglt: 2.201048 },
  { isoDate: "2010-11-22T00:00:00Z", dsun: 210.557592, hgln: -0.214748, hglt: 2.078305 },
  { isoDate: "2010-11-23T00:00:00Z", dsun: 210.509108, hgln: -0.219328, hglt: 1.954827 },
  { isoDate: "2010-11-24T00:00:00Z", dsun: 210.461067, hgln: -0.223634, hglt: 1.830652 },
  { isoDate: "2010-11-25T00:00:00Z", dsun: 210.413484, hgln: -0.227677, hglt: 1.70582 },
  { isoDate: "2010-11-26T00:00:00Z", dsun: 210.366377, hgln: -0.231471, hglt: 1.580369 },
  { isoDate: "2010-11-27T00:00:00Z", dsun: 210.319763, hgln: -0.235025, hglt: 1.45434 },
  { isoDate: "2010-11-28T00:00:00Z", dsun: 210.273659, hgln: -0.238348, hglt: 1.327773 },
  { isoDate: "2010-11-29T00:00:00Z", dsun: 210.228084, hgln: -0.241444, hglt: 1.200707 },
  { isoDate: "2010-11-30T00:00:00Z", dsun: 210.183058, hgln: -0.244312, hglt: 1.073183 },
  { isoDate: "2010-12-01T00:00:00Z", dsun: 210.138602, hgln: -0.246947, hglt: 0.945242 },
  { isoDate: "2010-12-02T00:00:00Z", dsun: 210.094737, hgln: -0.24934, hglt: 0.816925 },
  { isoDate: "2010-12-03T00:00:00Z", dsun: 210.051486, hgln: -0.251479, hglt: 0.688273 },
  { isoDate: "2010-12-04T00:00:00Z", dsun: 210.008873, hgln: -0.253344, hglt: 0.559327 },
  { isoDate: "2010-12-05T00:00:00Z", dsun: 209.966925, hgln: -0.254919, hglt: 0.43013 },
  { isoDate: "2010-12-06T00:00:00Z", dsun: 209.925669, hgln: -0.256183, hglt: 0.300722 },
  { isoDate: "2010-12-07T00:00:00Z", dsun: 209.885134, hgln: -0.257118, hglt: 0.171147 },
  { isoDate: "2010-12-08T00:00:00Z", dsun: 209.845335, hgln: -0.257702, hglt: 0.041445 },
  { isoDate: "2010-12-09T00:00:00Z", dsun: 209.806326, hgln: -0.257931, hglt: -0.088338 },
  { isoDate: "2010-12-10T00:00:00Z", dsun: 209.768123, hgln: -0.257788, hglt: -0.218161 },
  { isoDate: "2010-12-11T00:00:00Z", dsun: 209.730752, hgln: -0.257267, hglt: -0.347981 },
  { isoDate: "2010-12-12T00:00:00Z", dsun: 209.69424, hgln: -0.256362, hglt: -0.477755 },
  { isoDate: "2010-12-13T00:00:00Z", dsun: 209.658614, hgln: -0.255071, hglt: -0.60744 },
  { isoDate: "2010-12-14T00:00:00Z", dsun: 209.623902, hgln: -0.253393, hglt: -0.736993 },
  { isoDate: "2010-12-15T00:00:00Z", dsun: 209.590131, hgln: -0.25133, hglt: -0.866371 },
  { isoDate: "2010-12-16T00:00:00Z", dsun: 209.55733, hgln: -0.248886, hglt: -0.99553 },
  { isoDate: "2010-12-17T00:00:00Z", dsun: 209.525528, hgln: -0.246068, hglt: -1.124427 },
  { isoDate: "2010-12-18T00:00:00Z", dsun: 209.494754, hgln: -0.242885, hglt: -1.253019 },
  { isoDate: "2010-12-19T00:00:00Z", dsun: 209.465039, hgln: -0.239349, hglt: -1.381263 },
  { isoDate: "2010-12-20T00:00:00Z", dsun: 209.43641, hgln: -0.235476, hglt: -1.509114 },
  { isoDate: "2010-12-21T00:00:00Z", dsun: 209.408899, hgln: -0.231284, hglt: -1.636529 },
  { isoDate: "2010-12-22T00:00:00Z", dsun: 209.382533, hgln: -0.226796, hglt: -1.763466 },
  { isoDate: "2010-12-23T00:00:00Z", dsun: 209.357342, hgln: -0.222034, hglt: -1.88988 },
  { isoDate: "2010-12-24T00:00:00Z", dsun: 209.333353, hgln: -0.217024, hglt: -2.015729 },
  { isoDate: "2010-12-25T00:00:00Z", dsun: 209.310593, hgln: -0.21179, hglt: -2.140969 },
  { isoDate: "2010-12-26T00:00:00Z", dsun: 209.289088, hgln: -0.206352, hglt: -2.265557 },
  { isoDate: "2010-12-27T00:00:00Z", dsun: 209.268863, hgln: -0.200729, hglt: -2.389451 },
  { isoDate: "2010-12-28T00:00:00Z", dsun: 209.249944, hgln: -0.194934, hglt: -2.512606 },
  { isoDate: "2010-12-29T00:00:00Z", dsun: 209.232353, hgln: -0.188978, hglt: -2.634982 },
  { isoDate: "2010-12-30T00:00:00Z", dsun: 209.216113, hgln: -0.182864, hglt: -2.756535 },
  { isoDate: "2010-12-31T00:00:00Z", dsun: 209.201248, hgln: -0.176594, hglt: -2.877224 },
  { isoDate: "2011-01-01T00:00:00Z", dsun: 209.187778, hgln: -0.170164, hglt: -2.997006 },
  { isoDate: "2011-01-02T00:00:00Z", dsun: 209.175727, hgln: -0.163569, hglt: -3.11584 },
  { isoDate: "2011-01-03T00:00:00Z", dsun: 209.165115, hgln: -0.156802, hglt: -3.233684 },
  { isoDate: "2011-01-04T00:00:00Z", dsun: 209.155964, hgln: -0.149853, hglt: -3.350498 },
  { isoDate: "2011-01-05T00:00:00Z", dsun: 209.148292, hgln: -0.142717, hglt: -3.46624 },
  { isoDate: "2011-01-06T00:00:00Z", dsun: 209.142119, hgln: -0.135386, hglt: -3.580871 },
  { isoDate: "2011-01-07T00:00:00Z", dsun: 209.137459, hgln: -0.127855, hglt: -3.694349 },
  { isoDate: "2011-01-08T00:00:00Z", dsun: 209.134328, hgln: -0.120122, hglt: -3.806636 },
  { isoDate: "2011-01-09T00:00:00Z", dsun: 209.132738, hgln: -0.112186, hglt: -3.917691 },
  { isoDate: "2011-01-10T00:00:00Z", dsun: 209.132698, hgln: -0.104048, hglt: -4.027477 },
  { isoDate: "2011-01-11T00:00:00Z", dsun: 209.134219, hgln: -0.095712, hglt: -4.135955 },
  { isoDate: "2011-01-12T00:00:00Z", dsun: 209.137309, hgln: -0.087182, hglt: -4.243088 },
  { isoDate: "2011-01-13T00:00:00Z", dsun: 209.141975, hgln: -0.078466, hglt: -4.348838 },
  { isoDate: "2011-01-14T00:00:00Z", dsun: 209.148224, hgln: -0.069574, hglt: -4.453169 },
  { isoDate: "2011-01-15T00:00:00Z", dsun: 209.156063, hgln: -0.060516, hglt: -4.556044 },
  { isoDate: "2011-01-16T00:00:00Z", dsun: 209.165494, hgln: -0.05131, hglt: -4.657428 },
  { isoDate: "2011-01-17T00:00:00Z", dsun: 209.176522, hgln: -0.041971, hglt: -4.757285 },
  { isoDate: "2011-01-18T00:00:00Z", dsun: 209.189148, hgln: -0.032524, hglt: -4.855582 },
  { isoDate: "2011-01-19T00:00:00Z", dsun: 209.203372, hgln: -0.022994, hglt: -4.952285 },
  { isoDate: "2011-01-20T00:00:00Z", dsun: 209.219192, hgln: -0.013411, hglt: -5.04736 },
  { isoDate: "2011-01-21T00:00:00Z", dsun: 209.236604, hgln: -0.003809, hglt: -5.140776 },
  { isoDate: "2011-01-22T00:00:00Z", dsun: 209.255603, hgln: 0.00578, hglt: -5.232499 },
  { isoDate: "2011-01-23T00:00:00Z", dsun: 209.276181, hgln: 0.015322, hglt: -5.322501 },
  { isoDate: "2011-01-24T00:00:00Z", dsun: 209.298329, hgln: 0.024788, hglt: -5.410749 },
  { isoDate: "2011-01-25T00:00:00Z", dsun: 209.322037, hgln: 0.034151, hglt: -5.497215 },
  { isoDate: "2011-01-26T00:00:00Z", dsun: 209.347292, hgln: 0.043391, hglt: -5.581871 },
  { isoDate: "2011-01-27T00:00:00Z", dsun: 209.374081, hgln: 0.052494, hglt: -5.664687 },
  { isoDate: "2011-01-28T00:00:00Z", dsun: 209.40239, hgln: 0.061449, hglt: -5.745638 },
  { isoDate: "2011-01-29T00:00:00Z", dsun: 209.432202, hgln: 0.070251, hglt: -5.824697 },
  { isoDate: "2011-01-30T00:00:00Z", dsun: 209.463503, hgln: 0.078897, hglt: -5.901838 },
  { isoDate: "2011-01-31T00:00:00Z", dsun: 209.496275, hgln: 0.087387, hglt: -5.977037 },
  { isoDate: "2011-02-01T00:00:00Z", dsun: 209.530499, hgln: 0.095723, hglt: -6.05027 },
  { isoDate: "2011-02-02T00:00:00Z", dsun: 209.566155, hgln: 0.103908, hglt: -6.121514 },
  { isoDate: "2011-02-03T00:00:00Z", dsun: 209.603222, hgln: 0.111946, hglt: -6.190746 },
  { isoDate: "2011-02-04T00:00:00Z", dsun: 209.641675, hgln: 0.11984, hglt: -6.257946 },
  { isoDate: "2011-02-05T00:00:00Z", dsun: 209.681488, hgln: 0.127594, hglt: -6.323093 },
  { isoDate: "2011-02-06T00:00:00Z", dsun: 209.722634, hgln: 0.13521, hglt: -6.386168 },
  { isoDate: "2011-02-07T00:00:00Z", dsun: 209.765084, hgln: 0.142692, hglt: -6.447153 },
  { isoDate: "2011-02-08T00:00:00Z", dsun: 209.808808, hgln: 0.15004, hglt: -6.50603 },
  { isoDate: "2011-02-09T00:00:00Z", dsun: 209.853804, hgln: 0.157257, hglt: -6.562784 },
  { isoDate: "2011-02-10T00:00:00Z", dsun: 209.899988, hgln: 0.164337, hglt: -6.617396 },
  { isoDate: "2011-02-11T00:00:00Z", dsun: 209.947357, hgln: 0.171278, hglt: -6.669853 },
  { isoDate: "2011-02-12T00:00:00Z", dsun: 209.995877, hgln: 0.178078, hglt: -6.72014 },
  { isoDate: "2011-02-13T00:00:00Z", dsun: 210.045519, hgln: 0.184728, hglt: -6.768246 },
  { isoDate: "2011-02-14T00:00:00Z", dsun: 210.096248, hgln: 0.191221, hglt: -6.814158 },
  { isoDate: "2011-02-15T00:00:00Z", dsun: 210.148033, hgln: 0.197542, hglt: -6.857865 },
  { isoDate: "2011-02-16T00:00:00Z", dsun: 210.200837, hgln: 0.203676, hglt: -6.899356 },
  { isoDate: "2011-02-17T00:00:00Z", dsun: 210.254628, hgln: 0.209601, hglt: -6.938622 },
  { isoDate: "2011-02-18T00:00:00Z", dsun: 210.309368, hgln: 0.215291, hglt: -6.975655 },
  { isoDate: "2011-02-19T00:00:00Z", dsun: 210.365022, hgln: 0.220718, hglt: -7.010448 },
  { isoDate: "2011-02-20T00:00:00Z", dsun: 210.421552, hgln: 0.225852, hglt: -7.042993 },
  { isoDate: "2011-02-21T00:00:00Z", dsun: 210.478921, hgln: 0.230663, hglt: -7.073285 },
  { isoDate: "2011-02-22T00:00:00Z", dsun: 210.53709, hgln: 0.235127, hglt: -7.10132 },
  { isoDate: "2011-02-23T00:00:00Z", dsun: 210.596022, hgln: 0.239225, hglt: -7.127093 },
  { isoDate: "2011-02-24T00:00:00Z", dsun: 210.65568, hgln: 0.24294, hglt: -7.150602 },
  { isoDate: "2011-02-25T00:00:00Z", dsun: 210.716025, hgln: 0.246267, hglt: -7.171845 },
  { isoDate: "2011-02-26T00:00:00Z", dsun: 210.777021, hgln: 0.2492, hglt: -7.19082 },
  { isoDate: "2011-02-27T00:00:00Z", dsun: 210.838631, hgln: 0.251742, hglt: -7.207527 },
  { isoDate: "2011-02-28T00:00:00Z", dsun: 210.900821, hgln: 0.253895, hglt: -7.221967 },
  { isoDate: "2011-03-01T00:00:00Z", dsun: 210.963554, hgln: 0.255667, hglt: -7.234141 },
  { isoDate: "2011-03-02T00:00:00Z", dsun: 211.026795, hgln: 0.257066, hglt: -7.244051 },
  { isoDate: "2011-03-03T00:00:00Z", dsun: 211.090509, hgln: 0.258101, hglt: -7.2517 },
  { isoDate: "2011-03-04T00:00:00Z", dsun: 211.154657, hgln: 0.258784, hglt: -7.257093 },
  { isoDate: "2011-03-05T00:00:00Z", dsun: 211.219204, hgln: 0.259126, hglt: -7.260233 },
  { isoDate: "2011-03-06T00:00:00Z", dsun: 211.284112, hgln: 0.25914, hglt: -7.261127 },
  { isoDate: "2011-03-07T00:00:00Z", dsun: 211.349344, hgln: 0.25884, hglt: -7.259781 },
  { isoDate: "2011-03-08T00:00:00Z", dsun: 211.414863, hgln: 0.258237, hglt: -7.256202 },
  { isoDate: "2011-03-09T00:00:00Z", dsun: 211.480633, hgln: 0.257342, hglt: -7.250398 },
  { isoDate: "2011-03-10T00:00:00Z", dsun: 211.546627, hgln: 0.256171, hglt: -7.242379 },
  { isoDate: "2011-03-11T00:00:00Z", dsun: 211.612809, hgln: 0.254732, hglt: -7.232153 },
  { isoDate: "2011-03-12T00:00:00Z", dsun: 211.679148, hgln: 0.253035, hglt: -7.21973 },
  { isoDate: "2011-03-13T00:00:00Z", dsun: 211.745616, hgln: 0.251086, hglt: -7.205123 },
  { isoDate: "2011-03-14T00:00:00Z", dsun: 211.812183, hgln: 0.248893, hglt: -7.188342 },
  { isoDate: "2011-03-15T00:00:00Z", dsun: 211.878823, hgln: 0.246456, hglt: -7.1694 },
  { isoDate: "2011-03-16T00:00:00Z", dsun: 211.945507, hgln: 0.243775, hglt: -7.148309 },
  { isoDate: "2011-03-17T00:00:00Z", dsun: 212.012212, hgln: 0.240844, hglt: -7.125085 },
  { isoDate: "2011-03-18T00:00:00Z", dsun: 212.07891, hgln: 0.237653, hglt: -7.09974 },
  { isoDate: "2011-03-19T00:00:00Z", dsun: 212.145578, hgln: 0.234187, hglt: -7.072291 },
  { isoDate: "2011-03-20T00:00:00Z", dsun: 212.212192, hgln: 0.230426, hglt: -7.042753 },
  { isoDate: "2011-03-21T00:00:00Z", dsun: 212.27873, hgln: 0.226349, hglt: -7.011143 },
  { isoDate: "2011-03-22T00:00:00Z", dsun: 212.34517, hgln: 0.221938, hglt: -6.977477 },
  { isoDate: "2011-03-23T00:00:00Z", dsun: 212.411491, hgln: 0.217178, hglt: -6.941773 },
  { isoDate: "2011-03-24T00:00:00Z", dsun: 212.477674, hgln: 0.212057, hglt: -6.90405 },
  { isoDate: "2011-03-25T00:00:00Z", dsun: 212.543701, hgln: 0.206571, hglt: -6.864325 },
  { isoDate: "2011-03-26T00:00:00Z", dsun: 212.609557, hgln: 0.200721, hglt: -6.822619 },
  { isoDate: "2011-03-27T00:00:00Z", dsun: 212.675228, hgln: 0.194513, hglt: -6.778951 },
  { isoDate: "2011-03-28T00:00:00Z", dsun: 212.7407, hgln: 0.187958, hglt: -6.733341 },
  { isoDate: "2011-03-29T00:00:00Z", dsun: 212.805966, hgln: 0.181069, hglt: -6.68581 },
  { isoDate: "2011-03-30T00:00:00Z", dsun: 212.871017, hgln: 0.173861, hglt: -6.636379 },
  { isoDate: "2011-03-31T00:00:00Z", dsun: 212.935846, hgln: 0.166353, hglt: -6.58507 },
  { isoDate: "2011-04-01T00:00:00Z", dsun: 213.000449, hgln: 0.158562, hglt: -6.531905 },
  { isoDate: "2011-04-02T00:00:00Z", dsun: 213.064819, hgln: 0.150511, hglt: -6.476906 },
  { isoDate: "2011-04-03T00:00:00Z", dsun: 213.12895, hgln: 0.14222, hglt: -6.420096 },
  { isoDate: "2011-04-04T00:00:00Z", dsun: 213.192834, hgln: 0.133713, hglt: -6.361498 },
  { isoDate: "2011-04-05T00:00:00Z", dsun: 213.256462, hgln: 0.125014, hglt: -6.301136 },
  { isoDate: "2011-04-06T00:00:00Z", dsun: 213.319825, hgln: 0.116149, hglt: -6.239033 },
  { isoDate: "2011-04-07T00:00:00Z", dsun: 213.382913, hgln: 0.107143, hglt: -6.175213 },
  { isoDate: "2011-04-08T00:00:00Z", dsun: 213.44572, hgln: 0.098021, hglt: -6.1097 },
  { isoDate: "2011-04-09T00:00:00Z", dsun: 213.508237, hgln: 0.08881, hglt: -6.042518 },
  { isoDate: "2011-04-10T00:00:00Z", dsun: 213.570458, hgln: 0.079534, hglt: -5.973692 },
  { isoDate: "2011-04-11T00:00:00Z", dsun: 213.632378, hgln: 0.070216, hglt: -5.903246 },
  { isoDate: "2011-04-12T00:00:00Z", dsun: 213.69399, hgln: 0.060875, hglt: -5.831205 },
  { isoDate: "2011-04-13T00:00:00Z", dsun: 213.755286, hgln: 0.05153, hglt: -5.757595 },
  { isoDate: "2011-04-14T00:00:00Z", dsun: 213.816264, hgln: 0.042194, hglt: -5.682437 },
  { isoDate: "2011-04-15T00:00:00Z", dsun: 213.876916, hgln: 0.032875, hglt: -5.605759 },
  { isoDate: "2011-04-16T00:00:00Z", dsun: 213.93723, hgln: 0.023577, hglt: -5.527585 },
  { isoDate: "2011-04-17T00:00:00Z", dsun: 213.997197, hgln: 0.014297, hglt: -5.44794 },
  { isoDate: "2011-04-18T00:00:00Z", dsun: 214.056805, hgln: 0.005031, hglt: -5.366849 },
  { isoDate: "2011-04-19T00:00:00Z", dsun: 214.116038, hgln: -0.00423, hglt: -5.284337 },
  { isoDate: "2011-04-20T00:00:00Z", dsun: 214.174881, hgln: -0.013492, hglt: -5.20043 },
  { isoDate: "2011-04-21T00:00:00Z", dsun: 214.233313, hgln: -0.022761, hglt: -5.115151 },
  { isoDate: "2011-04-22T00:00:00Z", dsun: 214.291316, hgln: -0.032036, hglt: -5.028527 },
  { isoDate: "2011-04-23T00:00:00Z", dsun: 214.348867, hgln: -0.041315, hglt: -4.940582 },
  { isoDate: "2011-04-24T00:00:00Z", dsun: 214.405941, hgln: -0.050587, hglt: -4.851341 },
  { isoDate: "2011-04-25T00:00:00Z", dsun: 214.462514, hgln: -0.059842, hglt: -4.76083 },
  { isoDate: "2011-04-26T00:00:00Z", dsun: 214.518561, hgln: -0.069063, hglt: -4.669072 },
  { isoDate: "2011-04-27T00:00:00Z", dsun: 214.574054, hgln: -0.078234, hglt: -4.576094 },
  { isoDate: "2011-04-28T00:00:00Z", dsun: 214.628967, hgln: -0.087335, hglt: -4.48192 },
  { isoDate: "2011-04-29T00:00:00Z", dsun: 214.683274, hgln: -0.096346, hglt: -4.386576 },
  { isoDate: "2011-04-30T00:00:00Z", dsun: 214.736949, hgln: -0.105244, hglt: -4.290087 },
  { isoDate: "2011-05-01T00:00:00Z", dsun: 214.789967, hgln: -0.114009, hglt: -4.192479 },
  { isoDate: "2011-05-02T00:00:00Z", dsun: 214.842303, hgln: -0.122616, hglt: -4.093777 },
  { isoDate: "2011-05-03T00:00:00Z", dsun: 214.893934, hgln: -0.131043, hglt: -3.994006 },
  { isoDate: "2011-05-04T00:00:00Z", dsun: 214.944836, hgln: -0.139267, hglt: -3.893194 },
  { isoDate: "2011-05-05T00:00:00Z", dsun: 214.994985, hgln: -0.147264, hglt: -3.791365 },
  { isoDate: "2011-05-06T00:00:00Z", dsun: 215.044356, hgln: -0.155013, hglt: -3.688547 },
  { isoDate: "2011-05-07T00:00:00Z", dsun: 215.092922, hgln: -0.162491, hglt: -3.584766 },
  { isoDate: "2011-05-08T00:00:00Z", dsun: 215.140656, hgln: -0.16968, hglt: -3.480049 },
  { isoDate: "2011-05-09T00:00:00Z", dsun: 215.187532, hgln: -0.176561, hglt: -3.374422 },
  { isoDate: "2011-05-10T00:00:00Z", dsun: 215.233748, hgln: -0.183131, hglt: -3.267915 },
  { isoDate: "2011-05-11T00:00:00Z", dsun: 215.278845, hgln: -0.189352, hglt: -3.16055 },
  { isoDate: "2011-05-12T00:00:00Z", dsun: 215.323013, hgln: -0.195227, hglt: -3.052356 },
  { isoDate: "2011-05-13T00:00:00Z", dsun: 215.366232, hgln: -0.200753, hglt: -2.943362 },
  { isoDate: "2011-05-14T00:00:00Z", dsun: 215.408485, hgln: -0.20593, hglt: -2.833594 },
  { isoDate: "2011-05-15T00:00:00Z", dsun: 215.449755, hgln: -0.210767, hglt: -2.72308 },
  { isoDate: "2011-05-16T00:00:00Z", dsun: 215.490029, hgln: -0.215275, hglt: -2.611849 },
  { isoDate: "2011-05-17T00:00:00Z", dsun: 215.529295, hgln: -0.219471, hglt: -2.49993 },
  { isoDate: "2011-05-18T00:00:00Z", dsun: 215.567542, hgln: -0.223374, hglt: -2.387349 },
  { isoDate: "2011-05-19T00:00:00Z", dsun: 215.604759, hgln: -0.227001, hglt: -2.274138 },
  { isoDate: "2011-05-20T00:00:00Z", dsun: 215.640938, hgln: -0.230371, hglt: -2.160323 },
  { isoDate: "2011-05-21T00:00:00Z", dsun: 215.67607, hgln: -0.233497, hglt: -2.045934 },
  { isoDate: "2011-05-22T00:00:00Z", dsun: 215.71015, hgln: -0.236389, hglt: -1.931002 },
  { isoDate: "2011-05-23T00:00:00Z", dsun: 215.743171, hgln: -0.239056, hglt: -1.815554 },
  { isoDate: "2011-05-24T00:00:00Z", dsun: 215.775129, hgln: -0.2415, hglt: -1.699621 },
  { isoDate: "2011-05-25T00:00:00Z", dsun: 215.806019, hgln: -0.243722, hglt: -1.583232 },
  { isoDate: "2011-05-26T00:00:00Z", dsun: 215.835839, hgln: -0.245722, hglt: -1.466417 },
  { isoDate: "2011-05-27T00:00:00Z", dsun: 215.864587, hgln: -0.247497, hglt: -1.349206 },
  { isoDate: "2011-05-28T00:00:00Z", dsun: 215.892263, hgln: -0.249042, hglt: -1.23163 },
  { isoDate: "2011-05-29T00:00:00Z", dsun: 215.918869, hgln: -0.25035, hglt: -1.113719 },
  { isoDate: "2011-05-30T00:00:00Z", dsun: 215.944407, hgln: -0.251413, hglt: -0.995502 },
  { isoDate: "2011-05-31T00:00:00Z", dsun: 215.968882, hgln: -0.252221, hglt: -0.877011 },
  { isoDate: "2011-06-01T00:00:00Z", dsun: 215.992298, hgln: -0.252764, hglt: -0.758276 },
  { isoDate: "2011-06-02T00:00:00Z", dsun: 216.014664, hgln: -0.253028, hglt: -0.639328 },
  { isoDate: "2011-06-03T00:00:00Z", dsun: 216.035989, hgln: -0.253, hglt: -0.520197 },
  { isoDate: "2011-06-04T00:00:00Z", dsun: 216.05628, hgln: -0.252667, hglt: -0.400915 },
  { isoDate: "2011-06-05T00:00:00Z", dsun: 216.075548, hgln: -0.252014, hglt: -0.281513 },
  { isoDate: "2011-06-06T00:00:00Z", dsun: 216.093801, hgln: -0.251029, hglt: -0.162021 },
  { isoDate: "2011-06-07T00:00:00Z", dsun: 216.111048, hgln: -0.249701, hglt: -0.042472 },
  { isoDate: "2011-06-08T00:00:00Z", dsun: 216.127299, hgln: -0.24802, hglt: 0.077105 },
  { isoDate: "2011-06-09T00:00:00Z", dsun: 216.142562, hgln: -0.245985, hglt: 0.196676 },
  { isoDate: "2011-06-10T00:00:00Z", dsun: 216.156847, hgln: -0.243592, hglt: 0.316211 },
  { isoDate: "2011-06-11T00:00:00Z", dsun: 216.170167, hgln: -0.240846, hglt: 0.435678 },
  { isoDate: "2011-06-12T00:00:00Z", dsun: 216.182534, hgln: -0.237757, hglt: 0.555046 },
  { isoDate: "2011-06-13T00:00:00Z", dsun: 216.193961, hgln: -0.234337, hglt: 0.674284 },
  { isoDate: "2011-06-14T00:00:00Z", dsun: 216.204462, hgln: -0.230606, hglt: 0.79336 },
  { isoDate: "2011-06-15T00:00:00Z", dsun: 216.214052, hgln: -0.226584, hglt: 0.912242 },
  { isoDate: "2011-06-16T00:00:00Z", dsun: 216.222747, hgln: -0.222296, hglt: 1.0309 },
  { isoDate: "2011-06-17T00:00:00Z", dsun: 216.23056, hgln: -0.217765, hglt: 1.1493 },
  { isoDate: "2011-06-18T00:00:00Z", dsun: 216.237508, hgln: -0.213014, hglt: 1.267413 },
  { isoDate: "2011-06-19T00:00:00Z", dsun: 216.243605, hgln: -0.208061, hglt: 1.385205 },
  { isoDate: "2011-06-20T00:00:00Z", dsun: 216.248866, hgln: -0.202926, hglt: 1.502646 },
  { isoDate: "2011-06-21T00:00:00Z", dsun: 216.253307, hgln: -0.197623, hglt: 1.619704 },
  { isoDate: "2011-06-22T00:00:00Z", dsun: 216.256942, hgln: -0.192165, hglt: 1.736347 },
  { isoDate: "2011-06-23T00:00:00Z", dsun: 216.259786, hgln: -0.18656, hglt: 1.852545 },
  { isoDate: "2011-06-24T00:00:00Z", dsun: 216.261852, hgln: -0.180818, hglt: 1.968265 },
  { isoDate: "2011-06-25T00:00:00Z", dsun: 216.263157, hgln: -0.174943, hglt: 2.083475 },
  { isoDate: "2011-06-26T00:00:00Z", dsun: 216.263714, hgln: -0.168939, hglt: 2.198146 },
  { isoDate: "2011-06-27T00:00:00Z", dsun: 216.263538, hgln: -0.16281, hglt: 2.312245 },
  { isoDate: "2011-06-28T00:00:00Z", dsun: 216.262644, hgln: -0.156554, hglt: 2.425741 },
  { isoDate: "2011-06-29T00:00:00Z", dsun: 216.261048, hgln: -0.150169, hglt: 2.538604 },
  { isoDate: "2011-06-30T00:00:00Z", dsun: 216.258764, hgln: -0.143651, hglt: 2.650802 },
  { isoDate: "2011-07-01T00:00:00Z", dsun: 216.255809, hgln: -0.136994, hglt: 2.762304 },
  { isoDate: "2011-07-02T00:00:00Z", dsun: 216.252198, hgln: -0.130189, hglt: 2.87308 },
  { isoDate: "2011-07-03T00:00:00Z", dsun: 216.247945, hgln: -0.123226, hglt: 2.983099 },
  { isoDate: "2011-07-04T00:00:00Z", dsun: 216.243063, hgln: -0.116096, hglt: 3.09233 },
  { isoDate: "2011-07-05T00:00:00Z", dsun: 216.237565, hgln: -0.10879, hglt: 3.200743 },
  { isoDate: "2011-07-06T00:00:00Z", dsun: 216.231461, hgln: -0.1013, hglt: 3.308307 },
  { isoDate: "2011-07-07T00:00:00Z", dsun: 216.224761, hgln: -0.093622, hglt: 3.414992 },
  { isoDate: "2011-07-08T00:00:00Z", dsun: 216.217477, hgln: -0.085757, hglt: 3.520769 },
  { isoDate: "2011-07-09T00:00:00Z", dsun: 216.209618, hgln: -0.077708, hglt: 3.625607 },
  { isoDate: "2011-07-10T00:00:00Z", dsun: 216.201195, hgln: -0.069486, hglt: 3.729477 },
  { isoDate: "2011-07-11T00:00:00Z", dsun: 216.192217, hgln: -0.061105, hglt: 3.832349 },
  { isoDate: "2011-07-12T00:00:00Z", dsun: 216.182695, hgln: -0.052584, hglt: 3.934194 },
  { isoDate: "2011-07-13T00:00:00Z", dsun: 216.172638, hgln: -0.043945, hglt: 4.034984 },
  { isoDate: "2011-07-14T00:00:00Z", dsun: 216.162053, hgln: -0.035213, hglt: 4.13469 },
  { isoDate: "2011-07-15T00:00:00Z", dsun: 216.15095, hgln: -0.026415, hglt: 4.233282 },
  { isoDate: "2011-07-16T00:00:00Z", dsun: 216.139334, hgln: -0.017578, hglt: 4.330732 },
  { isoDate: "2011-07-17T00:00:00Z", dsun: 216.127212, hgln: -0.008728, hglt: 4.427013 },
  { isoDate: "2011-07-18T00:00:00Z", dsun: 216.114589, hgln: 0.000112, hglt: 4.522097 },
  { isoDate: "2011-07-19T00:00:00Z", dsun: 216.101468, hgln: 0.008917, hglt: 4.615956 },
  { isoDate: "2011-07-20T00:00:00Z", dsun: 216.087852, hgln: 0.017669, hglt: 4.708562 },
  { isoDate: "2011-07-21T00:00:00Z", dsun: 216.073745, hgln: 0.026348, hglt: 4.79989 },
  { isoDate: "2011-07-22T00:00:00Z", dsun: 216.059147, hgln: 0.034939, hglt: 4.889911 },
  { isoDate: "2011-07-23T00:00:00Z", dsun: 216.04406, hgln: 0.043429, hglt: 4.9786 },
  { isoDate: "2011-07-24T00:00:00Z", dsun: 216.028484, hgln: 0.051806, hglt: 5.065931 },
  { isoDate: "2011-07-25T00:00:00Z", dsun: 216.012419, hgln: 0.06006, hglt: 5.151878 },
  { isoDate: "2011-07-26T00:00:00Z", dsun: 215.995866, hgln: 0.068183, hglt: 5.236416 },
  { isoDate: "2011-07-27T00:00:00Z", dsun: 215.978824, hgln: 0.076173, hglt: 5.319519 },
  { isoDate: "2011-07-28T00:00:00Z", dsun: 215.961294, hgln: 0.084026, hglt: 5.401164 },
  { isoDate: "2011-07-29T00:00:00Z", dsun: 215.943273, hgln: 0.091744, hglt: 5.481325 },
  { isoDate: "2011-07-30T00:00:00Z", dsun: 215.92476, hgln: 0.099331, hglt: 5.559979 },
  { isoDate: "2011-07-31T00:00:00Z", dsun: 215.905752, hgln: 0.106797, hglt: 5.637103 },
  { isoDate: "2011-08-01T00:00:00Z", dsun: 215.886244, hgln: 0.114151, hglt: 5.712673 },
  { isoDate: "2011-08-02T00:00:00Z", dsun: 215.866231, hgln: 0.121405, hglt: 5.786667 },
  { isoDate: "2011-08-03T00:00:00Z", dsun: 215.845705, hgln: 0.128569, hglt: 5.859061 },
  { isoDate: "2011-08-04T00:00:00Z", dsun: 215.82466, hgln: 0.135654, hglt: 5.929835 },
  { isoDate: "2011-08-05T00:00:00Z", dsun: 215.80309, hgln: 0.142664, hglt: 5.998967 },
  { isoDate: "2011-08-06T00:00:00Z", dsun: 215.780987, hgln: 0.1496, hglt: 6.066435 },
  { isoDate: "2011-08-07T00:00:00Z", dsun: 215.758345, hgln: 0.156459, hglt: 6.13222 },
  { isoDate: "2011-08-08T00:00:00Z", dsun: 215.735158, hgln: 0.163231, hglt: 6.1963 },
  { isoDate: "2011-08-09T00:00:00Z", dsun: 215.711418, hgln: 0.169903, hglt: 6.258657 },
  { isoDate: "2011-08-10T00:00:00Z", dsun: 215.686831, hgln: 0.176492, hglt: 6.319277 },
  { isoDate: "2011-08-11T00:00:00Z", dsun: 215.66195, hgln: 0.182915, hglt: 6.378129 },
  { isoDate: "2011-08-12T00:00:00Z", dsun: 215.636493, hgln: 0.18918, hglt: 6.435202 },
  { isoDate: "2011-08-13T00:00:00Z", dsun: 215.610452, hgln: 0.195266, hglt: 6.490477 },
  { isoDate: "2011-08-14T00:00:00Z", dsun: 215.583818, hgln: 0.20115, hglt: 6.543936 },
  { isoDate: "2011-08-15T00:00:00Z", dsun: 215.556583, hgln: 0.206809, hglt: 6.595564 },
  { isoDate: "2011-08-16T00:00:00Z", dsun: 215.528736, hgln: 0.212223, hglt: 6.645344 },
  { isoDate: "2011-08-17T00:00:00Z", dsun: 215.500268, hgln: 0.217374, hglt: 6.693259 },
  { isoDate: "2011-08-18T00:00:00Z", dsun: 215.471169, hgln: 0.222242, hglt: 6.739295 },
  { isoDate: "2011-08-19T00:00:00Z", dsun: 215.441429, hgln: 0.226814, hglt: 6.783436 },
  { isoDate: "2011-08-20T00:00:00Z", dsun: 215.41104, hgln: 0.231075, hglt: 6.825669 },
  { isoDate: "2011-08-21T00:00:00Z", dsun: 215.379992, hgln: 0.235014, hglt: 6.86598 },
  { isoDate: "2011-08-22T00:00:00Z", dsun: 215.348278, hgln: 0.238621, hglt: 6.904355 },
  { isoDate: "2011-08-23T00:00:00Z", dsun: 215.315888, hgln: 0.24189, hglt: 6.940781 },
  { isoDate: "2011-08-24T00:00:00Z", dsun: 215.282818, hgln: 0.244817, hglt: 6.975247 },
  { isoDate: "2011-08-25T00:00:00Z", dsun: 215.249059, hgln: 0.247399, hglt: 7.007741 },
  { isoDate: "2011-08-26T00:00:00Z", dsun: 215.214607, hgln: 0.249639, hglt: 7.038251 },
  { isoDate: "2011-08-27T00:00:00Z", dsun: 215.179455, hgln: 0.251543, hglt: 7.066767 },
  { isoDate: "2011-08-28T00:00:00Z", dsun: 215.143597, hgln: 0.253121, hglt: 7.093278 },
  { isoDate: "2011-08-29T00:00:00Z", dsun: 215.107026, hgln: 0.254389, hglt: 7.117776 },
  { isoDate: "2011-08-30T00:00:00Z", dsun: 215.069735, hgln: 0.255364, hglt: 7.140251 },
  { isoDate: "2011-08-31T00:00:00Z", dsun: 215.031717, hgln: 0.256067, hglt: 7.160695 },
  { isoDate: "2011-09-01T00:00:00Z", dsun: 214.992967, hgln: 0.256517, hglt: 7.179099 },
  { isoDate: "2011-09-02T00:00:00Z", dsun: 214.95348, hgln: 0.256733, hglt: 7.195457 },
  { isoDate: "2011-09-03T00:00:00Z", dsun: 214.913252, hgln: 0.256725, hglt: 7.209761 },
  { isoDate: "2011-09-04T00:00:00Z", dsun: 214.872283, hgln: 0.256503, hglt: 7.222005 },
  { isoDate: "2011-09-05T00:00:00Z", dsun: 214.83057, hgln: 0.256068, hglt: 7.232184 },
  { isoDate: "2011-09-06T00:00:00Z", dsun: 214.788115, hgln: 0.255418, hglt: 7.240292 },
  { isoDate: "2011-09-07T00:00:00Z", dsun: 214.744919, hgln: 0.254548, hglt: 7.246324 },
  { isoDate: "2011-09-08T00:00:00Z", dsun: 214.700983, hgln: 0.253448, hglt: 7.250277 },
  { isoDate: "2011-09-09T00:00:00Z", dsun: 214.656309, hgln: 0.252107, hglt: 7.252146 },
  { isoDate: "2011-09-10T00:00:00Z", dsun: 214.610902, hgln: 0.250512, hglt: 7.25193 },
  { isoDate: "2011-09-11T00:00:00Z", dsun: 214.564765, hgln: 0.248649, hglt: 7.249624 },
  { isoDate: "2011-09-12T00:00:00Z", dsun: 214.517903, hgln: 0.246505, hglt: 7.245227 },
  { isoDate: "2011-09-13T00:00:00Z", dsun: 214.470322, hgln: 0.244066, hglt: 7.238739 },
  { isoDate: "2011-09-14T00:00:00Z", dsun: 214.422019, hgln: 0.241315, hglt: 7.230156 },
  { isoDate: "2011-09-15T00:00:00Z", dsun: 214.37302, hgln: 0.238247, hglt: 7.219481 },
  { isoDate: "2011-09-16T00:00:00Z", dsun: 214.323325, hgln: 0.234847, hglt: 7.206712 },
  { isoDate: "2011-09-17T00:00:00Z", dsun: 214.272943, hgln: 0.231106, hglt: 7.19185 },
  { isoDate: "2011-09-18T00:00:00Z", dsun: 214.221888, hgln: 0.227018, hglt: 7.174897 },
  { isoDate: "2011-09-19T00:00:00Z", dsun: 214.170171, hgln: 0.222575, hglt: 7.155855 },
  { isoDate: "2011-09-20T00:00:00Z", dsun: 214.117809, hgln: 0.217776, hglt: 7.134725 },
  { isoDate: "2011-09-21T00:00:00Z", dsun: 214.064817, hgln: 0.212618, hglt: 7.111511 },
  { isoDate: "2011-09-22T00:00:00Z", dsun: 214.011216, hgln: 0.207103, hglt: 7.086216 },
  { isoDate: "2011-09-23T00:00:00Z", dsun: 213.957026, hgln: 0.201236, hglt: 7.058843 },
  { isoDate: "2011-09-24T00:00:00Z", dsun: 213.90227, hgln: 0.195026, hglt: 7.029397 },
  { isoDate: "2011-09-25T00:00:00Z", dsun: 213.846973, hgln: 0.188487, hglt: 6.997882 },
  { isoDate: "2011-09-26T00:00:00Z", dsun: 213.791158, hgln: 0.181638, hglt: 6.964305 },
  { isoDate: "2011-09-27T00:00:00Z", dsun: 213.734852, hgln: 0.174503, hglt: 6.92867 },
  { isoDate: "2011-09-28T00:00:00Z", dsun: 213.678076, hgln: 0.167109, hglt: 6.890985 },
  { isoDate: "2011-09-29T00:00:00Z", dsun: 213.620856, hgln: 0.159488, hglt: 6.851256 },
  { isoDate: "2011-09-30T00:00:00Z", dsun: 213.563215, hgln: 0.151671, hglt: 6.809492 },
  { isoDate: "2011-10-01T00:00:00Z", dsun: 213.505179, hgln: 0.143685, hglt: 6.7657 },
  { isoDate: "2011-10-02T00:00:00Z", dsun: 213.446775, hgln: 0.135554, hglt: 6.719889 },
  { isoDate: "2011-10-03T00:00:00Z", dsun: 213.388033, hgln: 0.127299, hglt: 6.672068 },
  { isoDate: "2011-10-04T00:00:00Z", dsun: 213.328983, hgln: 0.118932, hglt: 6.622246 },
  { isoDate: "2011-10-05T00:00:00Z", dsun: 213.269657, hgln: 0.110465, hglt: 6.570435 },
  { isoDate: "2011-10-06T00:00:00Z", dsun: 213.210087, hgln: 0.101905, hglt: 6.516645 },
  { isoDate: "2011-10-07T00:00:00Z", dsun: 213.150305, hgln: 0.093255, hglt: 6.460887 },
  { isoDate: "2011-10-08T00:00:00Z", dsun: 213.090342, hgln: 0.084521, hglt: 6.403172 },
  { isoDate: "2011-10-09T00:00:00Z", dsun: 213.03023, hgln: 0.075702, hglt: 6.343514 },
  { isoDate: "2011-10-10T00:00:00Z", dsun: 212.969998, hgln: 0.066801, hglt: 6.281925 },
  { isoDate: "2011-10-11T00:00:00Z", dsun: 212.909676, hgln: 0.057818, hglt: 6.21842 },
  { isoDate: "2011-10-12T00:00:00Z", dsun: 212.849289, hgln: 0.048755, hglt: 6.153009 },
  { isoDate: "2011-10-13T00:00:00Z", dsun: 212.788867, hgln: 0.03961, hglt: 6.085714 },
  { isoDate: "2011-10-14T00:00:00Z", dsun: 212.728433, hgln: 0.030385, hglt: 6.016546 },
  { isoDate: "2011-10-15T00:00:00Z", dsun: 212.668009, hgln: 0.021083, hglt: 5.945523 },
  { isoDate: "2011-10-16T00:00:00Z", dsun: 212.607615, hgln: 0.011706, hglt: 5.872662 },
  { isoDate: "2011-10-17T00:00:00Z", dsun: 212.54727, hgln: 0.002258, hglt: 5.79798 },
  { isoDate: "2011-10-18T00:00:00Z", dsun: 212.486992, hgln: -0.007256, hglt: 5.721495 },
  { isoDate: "2011-10-19T00:00:00Z", dsun: 212.426794, hgln: -0.016829, hglt: 5.643227 },
  { isoDate: "2011-10-20T00:00:00Z", dsun: 212.36669, hgln: -0.026451, hglt: 5.563196 },
  { isoDate: "2011-10-21T00:00:00Z", dsun: 212.306692, hgln: -0.03611, hglt: 5.481421 },
  { isoDate: "2011-10-22T00:00:00Z", dsun: 212.246811, hgln: -0.045794, hglt: 5.397924 },
  { isoDate: "2011-10-23T00:00:00Z", dsun: 212.187058, hgln: -0.055481, hglt: 5.312726 },
  { isoDate: "2011-10-24T00:00:00Z", dsun: 212.127442, hgln: -0.06515, hglt: 5.22585 },
  { isoDate: "2011-10-25T00:00:00Z", dsun: 212.067974, hgln: -0.074773, hglt: 5.137319 },
  { isoDate: "2011-10-26T00:00:00Z", dsun: 212.008664, hgln: -0.084316, hglt: 5.047155 },
  { isoDate: "2011-10-27T00:00:00Z", dsun: 211.949522, hgln: -0.093744, hglt: 4.955384 },
  { isoDate: "2011-10-28T00:00:00Z", dsun: 211.890556, hgln: -0.103021, hglt: 4.862031 },
  { isoDate: "2011-10-29T00:00:00Z", dsun: 211.831773, hgln: -0.112111, hglt: 4.767121 },
  { isoDate: "2011-10-30T00:00:00Z", dsun: 211.773174, hgln: -0.120984, hglt: 4.670681 },
  { isoDate: "2011-10-31T00:00:00Z", dsun: 211.714762, hgln: -0.129613, hglt: 4.572738 },
  { isoDate: "2011-11-01T00:00:00Z", dsun: 211.656537, hgln: -0.137976, hglt: 4.473321 },
  { isoDate: "2011-11-02T00:00:00Z", dsun: 211.598499, hgln: -0.146059, hglt: 4.372458 },
  { isoDate: "2011-11-03T00:00:00Z", dsun: 211.540652, hgln: -0.153849, hglt: 4.270177 },
  { isoDate: "2011-11-04T00:00:00Z", dsun: 211.483, hgln: -0.161338, hglt: 4.166508 },
  { isoDate: "2011-11-05T00:00:00Z", dsun: 211.425547, hgln: -0.168523, hglt: 4.061482 },
  { isoDate: "2011-11-06T00:00:00Z", dsun: 211.3683, hgln: -0.1754, hglt: 3.955128 },
  { isoDate: "2011-11-07T00:00:00Z", dsun: 211.311269, hgln: -0.18197, hglt: 3.847478 },
  { isoDate: "2011-11-08T00:00:00Z", dsun: 211.254461, hgln: -0.188236, hglt: 3.738564 },
  { isoDate: "2011-11-09T00:00:00Z", dsun: 211.197779, hgln: -0.194195, hglt: 3.628414 },
  { isoDate: "2011-11-10T00:00:00Z", dsun: 211.141447, hgln: -0.199865, hglt: 3.517067 },
  { isoDate: "2011-11-11T00:00:00Z", dsun: 211.085373, hgln: -0.205247, hglt: 3.404554 },
  { isoDate: "2011-11-12T00:00:00Z", dsun: 211.029574, hgln: -0.21035, hglt: 3.290908 },
  { isoDate: "2011-11-13T00:00:00Z", dsun: 210.974061, hgln: -0.215182, hglt: 3.176164 },
  { isoDate: "2011-11-14T00:00:00Z", dsun: 210.918852, hgln: -0.219753, hglt: 3.060356 },
  { isoDate: "2011-11-15T00:00:00Z", dsun: 210.863963, hgln: -0.224071, hglt: 2.943519 },
  { isoDate: "2011-11-16T00:00:00Z", dsun: 210.80941, hgln: -0.228146, hglt: 2.82569 },
  { isoDate: "2011-11-17T00:00:00Z", dsun: 210.755212, hgln: -0.231985, hglt: 2.706904 },
  { isoDate: "2011-11-18T00:00:00Z", dsun: 210.701387, hgln: -0.235593, hglt: 2.587197 },
  { isoDate: "2011-11-19T00:00:00Z", dsun: 210.647954, hgln: -0.238975, hglt: 2.466607 },
  { isoDate: "2011-11-20T00:00:00Z", dsun: 210.594936, hgln: -0.24213, hglt: 2.34517 },
  { isoDate: "2011-11-21T00:00:00Z", dsun: 210.542353, hgln: -0.245054, hglt: 2.222925 },
  { isoDate: "2011-11-22T00:00:00Z", dsun: 210.490228, hgln: -0.24774, hglt: 2.09991 },
  { isoDate: "2011-11-23T00:00:00Z", dsun: 210.438587, hgln: -0.250174, hglt: 1.976162 },
  { isoDate: "2011-11-24T00:00:00Z", dsun: 210.387455, hgln: -0.252337, hglt: 1.85172 },
  { isoDate: "2011-11-25T00:00:00Z", dsun: 210.33686, hgln: -0.254207, hglt: 1.726624 },
  { isoDate: "2011-11-26T00:00:00Z", dsun: 210.286832, hgln: -0.255762, hglt: 1.600912 },
  { isoDate: "2011-11-27T00:00:00Z", dsun: 210.2374, hgln: -0.25698, hglt: 1.474626 },
  { isoDate: "2011-11-28T00:00:00Z", dsun: 210.188594, hgln: -0.257839, hglt: 1.347804 },
  { isoDate: "2011-11-29T00:00:00Z", dsun: 210.140445, hgln: -0.258326, hglt: 1.220487 },
  { isoDate: "2011-11-30T00:00:00Z", dsun: 210.092982, hgln: -0.258429, hglt: 1.092717 },
  { isoDate: "2011-12-01T00:00:00Z", dsun: 210.046231, hgln: -0.258143, hglt: 0.964534 },
  { isoDate: "2011-12-02T00:00:00Z", dsun: 210.000224, hgln: -0.257465, hglt: 0.835979 },
  { isoDate: "2011-12-03T00:00:00Z", dsun: 209.954989, hgln: -0.256396, hglt: 0.707094 },
  { isoDate: "2011-12-04T00:00:00Z", dsun: 209.910555, hgln: -0.25494, hglt: 0.577921 },
  { isoDate: "2011-12-05T00:00:00Z", dsun: 209.866955, hgln: -0.253101, hglt: 0.448502 },
  { isoDate: "2011-12-06T00:00:00Z", dsun: 209.82422, hgln: -0.250889, hglt: 0.318879 },
  { isoDate: "2011-12-07T00:00:00Z", dsun: 209.782382, hgln: -0.248313, hglt: 0.189094 },
  { isoDate: "2011-12-08T00:00:00Z", dsun: 209.741474, hgln: -0.245384, hglt: 0.05919 },
  { isoDate: "2011-12-09T00:00:00Z", dsun: 209.701528, hgln: -0.242117, hglt: -0.07079 },
  { isoDate: "2011-12-10T00:00:00Z", dsun: 209.662576, hgln: -0.238528, hglt: -0.200803 },
  { isoDate: "2011-12-11T00:00:00Z", dsun: 209.624651, hgln: -0.234634, hglt: -0.330806 },
  { isoDate: "2011-12-12T00:00:00Z", dsun: 209.587785, hgln: -0.230453, hglt: -0.460755 },
  { isoDate: "2011-12-13T00:00:00Z", dsun: 209.55201, hgln: -0.226006, hglt: -0.590608 },
  { isoDate: "2011-12-14T00:00:00Z", dsun: 209.517356, hgln: -0.221312, hglt: -0.720321 },
  { isoDate: "2011-12-15T00:00:00Z", dsun: 209.483854, hgln: -0.216391, hglt: -0.849849 },
  { isoDate: "2011-12-16T00:00:00Z", dsun: 209.451534, hgln: -0.211262, hglt: -0.979149 },
  { isoDate: "2011-12-17T00:00:00Z", dsun: 209.420423, hgln: -0.205941, hglt: -1.108179 },
  { isoDate: "2011-12-18T00:00:00Z", dsun: 209.39055, hgln: -0.200442, hglt: -1.236893 },
  { isoDate: "2011-12-19T00:00:00Z", dsun: 209.361943, hgln: -0.194775, hglt: -1.365248 },
  { isoDate: "2011-12-20T00:00:00Z", dsun: 209.334628, hgln: -0.188947, hglt: -1.493201 },
  { isoDate: "2011-12-21T00:00:00Z", dsun: 209.308632, hgln: -0.182959, hglt: -1.620707 },
  { isoDate: "2011-12-22T00:00:00Z", dsun: 209.283981, hgln: -0.176807, hglt: -1.747724 },
  { isoDate: "2011-12-23T00:00:00Z", dsun: 209.260701, hgln: -0.170484, hglt: -1.874207 },
  { isoDate: "2011-12-24T00:00:00Z", dsun: 209.238818, hgln: -0.16398, hglt: -2.000114 },
  { isoDate: "2011-12-25T00:00:00Z", dsun: 209.218356, hgln: -0.157281, hglt: -2.125401 },
  { isoDate: "2011-12-26T00:00:00Z", dsun: 209.19934, hgln: -0.150376, hglt: -2.250024 },
  { isoDate: "2011-12-27T00:00:00Z", dsun: 209.181791, hgln: -0.143254, hglt: -2.37394 },
  { isoDate: "2011-12-28T00:00:00Z", dsun: 209.16573, hgln: -0.13591, hglt: -2.497107 },
  { isoDate: "2011-12-29T00:00:00Z", dsun: 209.151173, hgln: -0.128339, hglt: -2.619481 },
  { isoDate: "2011-12-30T00:00:00Z", dsun: 209.138139, hgln: -0.120541, hglt: -2.741021 },
  { isoDate: "2011-12-31T00:00:00Z", dsun: 209.126641, hgln: -0.112521, hglt: -2.861685 },
  { isoDate: "2012-01-01T00:00:00Z", dsun: 209.116695, hgln: -0.104284, hglt: -2.98143 },
  { isoDate: "2012-01-02T00:00:00Z", dsun: 209.108313, hgln: -0.09584, hglt: -3.100216 },
  { isoDate: "2012-01-03T00:00:00Z", dsun: 209.10151, hgln: -0.0872, hglt: -3.218 },
  { isoDate: "2012-01-04T00:00:00Z", dsun: 209.096296, hgln: -0.078378, hglt: -3.334742 },
  { isoDate: "2012-01-05T00:00:00Z", dsun: 209.092681, hgln: -0.069389, hglt: -3.450402 },
  { isoDate: "2012-01-06T00:00:00Z", dsun: 209.090676, hgln: -0.060252, hglt: -3.564939 },
  { isoDate: "2012-01-07T00:00:00Z", dsun: 209.090288, hgln: -0.050987, hglt: -3.678314 },
  { isoDate: "2012-01-08T00:00:00Z", dsun: 209.091523, hgln: -0.041617, hglt: -3.790488 },
  { isoDate: "2012-01-09T00:00:00Z", dsun: 209.094385, hgln: -0.032168, hglt: -3.901421 },
  { isoDate: "2012-01-10T00:00:00Z", dsun: 209.098878, hgln: -0.022666, hglt: -4.011075 },
  { isoDate: "2012-01-11T00:00:00Z", dsun: 209.105016, hgln: -0.013138, hglt: -4.119411 },
  { isoDate: "2012-01-12T00:00:00Z", dsun: 209.112771, hgln: -0.00362, hglt: -4.226395 },
  { isoDate: "2012-01-13T00:00:00Z", dsun: 209.122153, hgln: 0.005864, hglt: -4.331989 },
  { isoDate: "2012-01-14T00:00:00Z", dsun: 209.133159, hgln: 0.015285, hglt: -4.436156 },
  { isoDate: "2012-01-15T00:00:00Z", dsun: 209.145782, hgln: 0.024619, hglt: -4.538861 },
  { isoDate: "2012-01-16T00:00:00Z", dsun: 209.160014, hgln: 0.033844, hglt: -4.64007 },
  { isoDate: "2012-01-17T00:00:00Z", dsun: 209.175846, hgln: 0.042945, hglt: -4.739747 },
  { isoDate: "2012-01-18T00:00:00Z", dsun: 209.193269, hgln: 0.05191, hglt: -4.83786 },
  { isoDate: "2012-01-19T00:00:00Z", dsun: 209.212272, hgln: 0.060731, hglt: -4.934375 },
  { isoDate: "2012-01-20T00:00:00Z", dsun: 209.232843, hgln: 0.069406, hglt: -5.029261 },
  { isoDate: "2012-01-21T00:00:00Z", dsun: 209.254969, hgln: 0.077939, hglt: -5.122486 },
  { isoDate: "2012-01-22T00:00:00Z", dsun: 209.278635, hgln: 0.086333, hglt: -5.214018 },
  { isoDate: "2012-01-23T00:00:00Z", dsun: 209.303827, hgln: 0.094595, hglt: -5.303828 },
  { isoDate: "2012-01-24T00:00:00Z", dsun: 209.330525, hgln: 0.102734, hglt: -5.391886 },
  { isoDate: "2012-01-25T00:00:00Z", dsun: 209.358708, hgln: 0.110755, hglt: -5.478164 },
  { isoDate: "2012-01-26T00:00:00Z", dsun: 209.388355, hgln: 0.118664, hglt: -5.562634 },
  { isoDate: "2012-01-27T00:00:00Z", dsun: 209.419441, hgln: 0.126464, hglt: -5.645269 },
  { isoDate: "2012-01-28T00:00:00Z", dsun: 209.451942, hgln: 0.134157, hglt: -5.726044 },
  { isoDate: "2012-01-29T00:00:00Z", dsun: 209.485833, hgln: 0.141741, hglt: -5.804933 },
  { isoDate: "2012-01-30T00:00:00Z", dsun: 209.521087, hgln: 0.149212, hglt: -5.881911 },
  { isoDate: "2012-01-31T00:00:00Z", dsun: 209.557679, hgln: 0.156566, hglt: -5.956954 },
  { isoDate: "2012-02-01T00:00:00Z", dsun: 209.595582, hgln: 0.163794, hglt: -6.030041 },
  { isoDate: "2012-02-02T00:00:00Z", dsun: 209.634767, hgln: 0.170888, hglt: -6.101149 },
  { isoDate: "2012-02-03T00:00:00Z", dsun: 209.675207, hgln: 0.177835, hglt: -6.170258 },
  { isoDate: "2012-02-04T00:00:00Z", dsun: 209.71687, hgln: 0.184624, hglt: -6.237345 },
  { isoDate: "2012-02-05T00:00:00Z", dsun: 209.759728, hgln: 0.191237, hglt: -6.302394 },
  { isoDate: "2012-02-06T00:00:00Z", dsun: 209.803749, hgln: 0.197656, hglt: -6.365384 },
  { isoDate: "2012-02-07T00:00:00Z", dsun: 209.848899, hgln: 0.203859, hglt: -6.426298 },
  { isoDate: "2012-02-08T00:00:00Z", dsun: 209.895146, hgln: 0.209823, hglt: -6.48512 },
  { isoDate: "2012-02-09T00:00:00Z", dsun: 209.942456, hgln: 0.215522, hglt: -6.541833 },
  { isoDate: "2012-02-10T00:00:00Z", dsun: 209.990793, hgln: 0.220928, hglt: -6.596424 },
  { isoDate: "2012-02-11T00:00:00Z", dsun: 210.040124, hgln: 0.226016, hglt: -6.648877 },
  { isoDate: "2012-02-12T00:00:00Z", dsun: 210.090411, hgln: 0.230762, hglt: -6.69918 },
  { isoDate: "2012-02-13T00:00:00Z", dsun: 210.141619, hgln: 0.235145, hglt: -6.747321 },
  { isoDate: "2012-02-14T00:00:00Z", dsun: 210.193713, hgln: 0.23915, hglt: -6.793288 },
  { isoDate: "2012-02-15T00:00:00Z", dsun: 210.246657, hgln: 0.242767, hglt: -6.83707 },
  { isoDate: "2012-02-16T00:00:00Z", dsun: 210.300416, hgln: 0.245991, hglt: -6.878659 },
  { isoDate: "2012-02-17T00:00:00Z", dsun: 210.354957, hgln: 0.248823, hglt: -6.918046 },
  { isoDate: "2012-02-18T00:00:00Z", dsun: 210.410244, hgln: 0.251267, hglt: -6.955222 },
  { isoDate: "2012-02-19T00:00:00Z", dsun: 210.466244, hgln: 0.253332, hglt: -6.990181 },
  { isoDate: "2012-02-20T00:00:00Z", dsun: 210.522921, hgln: 0.255028, hglt: -7.022917 },
  { isoDate: "2012-02-21T00:00:00Z", dsun: 210.580242, hgln: 0.256369, hglt: -7.053425 },
  { isoDate: "2012-02-22T00:00:00Z", dsun: 210.638169, hgln: 0.257369, hglt: -7.081699 },
  { isoDate: "2012-02-23T00:00:00Z", dsun: 210.696666, hgln: 0.258041, hglt: -7.107738 },
  { isoDate: "2012-02-24T00:00:00Z", dsun: 210.755697, hgln: 0.2584, hglt: -7.131538 },
  { isoDate: "2012-02-25T00:00:00Z", dsun: 210.815226, hgln: 0.258459, hglt: -7.153098 },
  { isoDate: "2012-02-26T00:00:00Z", dsun: 210.875218, hgln: 0.258229, hglt: -7.172418 },
  { isoDate: "2012-02-27T00:00:00Z", dsun: 210.935641, hgln: 0.25772, hglt: -7.189496 },
  { isoDate: "2012-02-28T00:00:00Z", dsun: 210.996462, hgln: 0.25694, hglt: -7.204335 },
  { isoDate: "2012-02-29T00:00:00Z", dsun: 211.05765, hgln: 0.255896, hglt: -7.216935 },
  { isoDate: "2012-03-01T00:00:00Z", dsun: 211.119175, hgln: 0.254592, hglt: -7.2273 },
  { isoDate: "2012-03-02T00:00:00Z", dsun: 211.181009, hgln: 0.253032, hglt: -7.235433 },
  { isoDate: "2012-03-03T00:00:00Z", dsun: 211.243121, hgln: 0.251216, hglt: -7.241337 },
  { isoDate: "2012-03-04T00:00:00Z", dsun: 211.305486, hgln: 0.249144, hglt: -7.245019 },
  { isoDate: "2012-03-05T00:00:00Z", dsun: 211.368076, hgln: 0.246811, hglt: -7.246483 },
  { isoDate: "2012-03-06T00:00:00Z", dsun: 211.430865, hgln: 0.244209, hglt: -7.245736 },
  { isoDate: "2012-03-07T00:00:00Z", dsun: 211.493827, hgln: 0.241328, hglt: -7.242785 },
  { isoDate: "2012-03-08T00:00:00Z", dsun: 211.556938, hgln: 0.238153, hglt: -7.237639 },
  { isoDate: "2012-03-09T00:00:00Z", dsun: 211.620174, hgln: 0.234667, hglt: -7.230306 },
  { isoDate: "2012-03-10T00:00:00Z", dsun: 211.683511, hgln: 0.230851, hglt: -7.220796 },
  { isoDate: "2012-03-11T00:00:00Z", dsun: 211.746928, hgln: 0.226687, hglt: -7.209119 },
  { isoDate: "2012-03-12T00:00:00Z", dsun: 211.810403, hgln: 0.22216, hglt: -7.195286 },
  { isoDate: "2012-03-13T00:00:00Z", dsun: 211.873918, hgln: 0.217259, hglt: -7.179308 },
  { isoDate: "2012-03-14T00:00:00Z", dsun: 211.937485, hgln: 0.211969, hglt: -7.161195 },
  { isoDate: "2012-03-15T00:00:00Z", dsun: 212.001027, hgln: 0.206308, hglt: -7.140966 },
  { isoDate: "2012-03-16T00:00:00Z", dsun: 212.06456, hgln: 0.200273, hglt: -7.118632 },
  { isoDate: "2012-03-17T00:00:00Z", dsun: 212.128072, hgln: 0.193876, hglt: -7.094207 },
  { isoDate: "2012-03-18T00:00:00Z", dsun: 212.191555, hgln: 0.187132, hglt: -7.067705 },
  { isoDate: "2012-03-19T00:00:00Z", dsun: 212.254998, hgln: 0.180058, hglt: -7.039141 },
  { isoDate: "2012-03-20T00:00:00Z", dsun: 212.318397, hgln: 0.172674, hglt: -7.008533 },
  { isoDate: "2012-03-21T00:00:00Z", dsun: 212.381743, hgln: 0.165004, hglt: -6.975896 },
  { isoDate: "2012-03-22T00:00:00Z", dsun: 212.445029, hgln: 0.157069, hglt: -6.941247 },
  { isoDate: "2012-03-23T00:00:00Z", dsun: 212.508246, hgln: 0.148894, hglt: -6.904604 },
  { isoDate: "2012-03-24T00:00:00Z", dsun: 212.571385, hgln: 0.140503, hglt: -6.865985 },
  { isoDate: "2012-03-25T00:00:00Z", dsun: 212.634435, hgln: 0.131921, hglt: -6.825407 },
  { isoDate: "2012-03-26T00:00:00Z", dsun: 212.697389, hgln: 0.123172, hglt: -6.782891 },
  { isoDate: "2012-03-27T00:00:00Z", dsun: 212.760237, hgln: 0.11428, hglt: -6.738454 },
  { isoDate: "2012-03-28T00:00:00Z", dsun: 212.822974, hgln: 0.105269, hglt: -6.692117 },
  { isoDate: "2012-03-29T00:00:00Z", dsun: 212.885592, hgln: 0.09616, hglt: -6.643898 },
  { isoDate: "2012-03-30T00:00:00Z", dsun: 212.948088, hgln: 0.086974, hglt: -6.593819 },
  { isoDate: "2012-03-31T00:00:00Z", dsun: 213.010455, hgln: 0.077731, hglt: -6.541898 },
  { isoDate: "2012-04-01T00:00:00Z", dsun: 213.07269, hgln: 0.068447, hglt: -6.488158 },
  { isoDate: "2012-04-02T00:00:00Z", dsun: 213.134787, hgln: 0.059139, hglt: -6.432617 },
  { isoDate: "2012-04-03T00:00:00Z", dsun: 213.196739, hgln: 0.049816, hglt: -6.375299 },
  { isoDate: "2012-04-04T00:00:00Z", dsun: 213.25854, hgln: 0.040489, hglt: -6.316222 },
  { isoDate: "2012-04-05T00:00:00Z", dsun: 213.32018, hgln: 0.031159, hglt: -6.25541 },
  { isoDate: "2012-04-06T00:00:00Z", dsun: 213.38165, hgln: 0.021828, hglt: -6.192882 },
  { isoDate: "2012-04-07T00:00:00Z", dsun: 213.442936, hgln: 0.012489, hglt: -6.128662 },
  { isoDate: "2012-04-08T00:00:00Z", dsun: 213.504024, hgln: 0.003136, hglt: -6.06277 },
  { isoDate: "2012-04-09T00:00:00Z", dsun: 213.564897, hgln: -0.006238, hglt: -5.995227 },
  { isoDate: "2012-04-10T00:00:00Z", dsun: 213.625538, hgln: -0.015638, hglt: -5.926057 },
  { isoDate: "2012-04-11T00:00:00Z", dsun: 213.685922, hgln: -0.025064, hglt: -5.855281 },
  { isoDate: "2012-04-12T00:00:00Z", dsun: 213.746031, hgln: -0.034513, hglt: -5.78292 },
  { isoDate: "2012-04-13T00:00:00Z", dsun: 213.805841, hgln: -0.043974, hglt: -5.708996 },
  { isoDate: "2012-04-14T00:00:00Z", dsun: 213.865325, hgln: -0.053433, hglt: -5.633532 },
  { isoDate: "2012-04-15T00:00:00Z", dsun: 213.924458, hgln: -0.06287, hglt: -5.55655 },
  { isoDate: "2012-04-16T00:00:00Z", dsun: 213.983213, hgln: -0.072267, hglt: -5.478072 },
  { isoDate: "2012-04-17T00:00:00Z", dsun: 214.041564, hgln: -0.081597, hglt: -5.39812 },
  { isoDate: "2012-04-18T00:00:00Z", dsun: 214.099485, hgln: -0.090838, hglt: -5.316718 },
  { isoDate: "2012-04-19T00:00:00Z", dsun: 214.156949, hgln: -0.099964, hglt: -5.233888 },
  { isoDate: "2012-04-20T00:00:00Z", dsun: 214.213932, hgln: -0.108949, hglt: -5.149653 },
  { isoDate: "2012-04-21T00:00:00Z", dsun: 214.270409, hgln: -0.117768, hglt: -5.064036 },
  { isoDate: "2012-04-22T00:00:00Z", dsun: 214.326355, hgln: -0.126396, hglt: -4.97706 },
  { isoDate: "2012-04-23T00:00:00Z", dsun: 214.381743, hgln: -0.134811, hglt: -4.888751 },
  { isoDate: "2012-04-24T00:00:00Z", dsun: 214.436547, hgln: -0.14299, hglt: -4.79913 },
  { isoDate: "2012-04-25T00:00:00Z", dsun: 214.490737, hgln: -0.150913, hglt: -4.708223 },
  { isoDate: "2012-04-26T00:00:00Z", dsun: 214.544284, hgln: -0.158561, hglt: -4.616055 },
  { isoDate: "2012-04-27T00:00:00Z", dsun: 214.597156, hgln: -0.165915, hglt: -4.522648 },
  { isoDate: "2012-04-28T00:00:00Z", dsun: 214.649324, hgln: -0.172961, hglt: -4.428028 },
  { isoDate: "2012-04-29T00:00:00Z", dsun: 214.700762, hgln: -0.179683, hglt: -4.33222 },
  { isoDate: "2012-04-30T00:00:00Z", dsun: 214.751444, hgln: -0.186072, hglt: -4.235249 },
  { isoDate: "2012-05-01T00:00:00Z", dsun: 214.801345, hgln: -0.192118, hglt: -4.137141 },
  { isoDate: "2012-05-02T00:00:00Z", dsun: 214.850445, hgln: -0.197818, hglt: -4.03792 },
  { isoDate: "2012-05-03T00:00:00Z", dsun: 214.898724, hgln: -0.203173, hglt: -3.937614 },
  { isoDate: "2012-05-04T00:00:00Z", dsun: 214.946164, hgln: -0.208188, hglt: -3.836249 },
  { isoDate: "2012-05-05T00:00:00Z", dsun: 214.992749, hgln: -0.212876, hglt: -3.733851 },
  { isoDate: "2012-05-06T00:00:00Z", dsun: 215.038463, hgln: -0.217253, hglt: -3.630447 },
  { isoDate: "2012-05-07T00:00:00Z", dsun: 215.083294, hgln: -0.22134, hglt: -3.526064 },
  { isoDate: "2012-05-08T00:00:00Z", dsun: 215.127228, hgln: -0.225156, hglt: -3.420729 },
  { isoDate: "2012-05-09T00:00:00Z", dsun: 215.170311, hgln: -0.22872, hglt: -3.314475 },
  { isoDate: "2012-05-10T00:00:00Z", dsun: 215.212421, hgln: -0.232049, hglt: -3.207322 },
  { isoDate: "2012-05-11T00:00:00Z", dsun: 215.253603, hgln: -0.235152, hglt: -3.099301 },
  { isoDate: "2012-05-12T00:00:00Z", dsun: 215.293847, hgln: -0.238036, hglt: -2.99044 },
  { isoDate: "2012-05-13T00:00:00Z", dsun: 215.333147, hgln: -0.240701, hglt: -2.880769 },
  { isoDate: "2012-05-14T00:00:00Z", dsun: 215.371495, hgln: -0.243145, hglt: -2.770316 },
  { isoDate: "2012-05-15T00:00:00Z", dsun: 215.408886, hgln: -0.245364, hglt: -2.65911 },
  { isoDate: "2012-05-16T00:00:00Z", dsun: 215.445317, hgln: -0.247352, hglt: -2.547181 },
  { isoDate: "2012-05-17T00:00:00Z", dsun: 215.480785, hgln: -0.249098, hglt: -2.434557 },
  { isoDate: "2012-05-18T00:00:00Z", dsun: 215.515288, hgln: -0.250594, hglt: -2.321268 },
  { isoDate: "2012-05-19T00:00:00Z", dsun: 215.548828, hgln: -0.25183, hglt: -2.207345 },
  { isoDate: "2012-05-20T00:00:00Z", dsun: 215.581407, hgln: -0.252792, hglt: -2.092817 },
  { isoDate: "2012-05-21T00:00:00Z", dsun: 215.613028, hgln: -0.25347, hglt: -1.977714 },
  { isoDate: "2012-05-22T00:00:00Z", dsun: 215.643281, hgln: -0.253823, hglt: -1.862068 },
  { isoDate: "2012-05-23T00:00:00Z", dsun: 215.672995, hgln: -0.253895, hglt: -1.745907 },
  { isoDate: "2012-05-24T00:00:00Z", dsun: 215.701769, hgln: -0.253648, hglt: -1.629263 },
  { isoDate: "2012-05-25T00:00:00Z", dsun: 215.729608, hgln: -0.253072, hglt: -1.512167 },
  { isoDate: "2012-05-26T00:00:00Z", dsun: 215.756517, hgln: -0.252158, hglt: -1.394649 },
  { isoDate: "2012-05-27T00:00:00Z", dsun: 215.782504, hgln: -0.250897, hglt: -1.276741 },
  { isoDate: "2012-05-28T00:00:00Z", dsun: 215.807573, hgln: -0.249284, hglt: -1.158474 },
  { isoDate: "2012-05-29T00:00:00Z", dsun: 215.831733, hgln: -0.247314, hglt: -1.03988 },
  { isoDate: "2012-05-30T00:00:00Z", dsun: 215.85499, hgln: -0.244988, hglt: -0.920989 },
  { isoDate: "2012-05-31T00:00:00Z", dsun: 215.877354, hgln: -0.242308, hglt: -0.801834 },
  { isoDate: "2012-06-01T00:00:00Z", dsun: 215.898835, hgln: -0.239282, hglt: -0.682446 },
  { isoDate: "2012-06-02T00:00:00Z", dsun: 215.919443, hgln: -0.235923, hglt: -0.562856 },
  { isoDate: "2012-06-03T00:00:00Z", dsun: 215.939192, hgln: -0.232251, hglt: -0.443097 },
  { isoDate: "2012-06-04T00:00:00Z", dsun: 215.958092, hgln: -0.228288, hglt: -0.3232 },
  { isoDate: "2012-06-05T00:00:00Z", dsun: 215.976155, hgln: -0.22406, hglt: -0.203198 },
  { isoDate: "2012-06-06T00:00:00Z", dsun: 215.993396, hgln: -0.219595, hglt: -0.083122 },
  { isoDate: "2012-06-07T00:00:00Z", dsun: 216.009826, hgln: -0.214918, hglt: 0.036995 },
  { isoDate: "2012-06-08T00:00:00Z", dsun: 216.025457, hgln: -0.210051, hglt: 0.157121 },
  { isoDate: "2012-06-09T00:00:00Z", dsun: 216.040302, hgln: -0.205012, hglt: 0.277225 },
  { isoDate: "2012-06-10T00:00:00Z", dsun: 216.054373, hgln: -0.199814, hglt: 0.397273 },
  { isoDate: "2012-06-11T00:00:00Z", dsun: 216.067683, hgln: -0.194468, hglt: 0.517233 },
  { isoDate: "2012-06-12T00:00:00Z", dsun: 216.080242, hgln: -0.18898, hglt: 0.637073 },
  { isoDate: "2012-06-13T00:00:00Z", dsun: 216.092659, hgln: -0.18339, hglt: 0.756769 },
  { isoDate: "2012-06-14T00:00:00Z", dsun: 216.103803, hgln: -0.177631, hglt: 0.876272 },
  { isoDate: "2012-06-15T00:00:00Z", dsun: 216.114234, hgln: -0.171736, hglt: 0.995558 },
  { isoDate: "2012-06-16T00:00:00Z", dsun: 216.123965, hgln: -0.165703, hglt: 1.114595 },
  { isoDate: "2012-06-17T00:00:00Z", dsun: 216.133009, hgln: -0.15953, hglt: 1.233351 },
  { isoDate: "2012-06-18T00:00:00Z", dsun: 216.141381, hgln: -0.153211, hglt: 1.351793 },
  { isoDate: "2012-06-19T00:00:00Z", dsun: 216.149092, hgln: -0.146744, hglt: 1.469888 },
  { isoDate: "2012-06-20T00:00:00Z", dsun: 216.156157, hgln: -0.140121, hglt: 1.587605 },
  { isoDate: "2012-06-21T00:00:00Z", dsun: 216.162587, hgln: -0.133337, hglt: 1.704912 },
  { isoDate: "2012-06-22T00:00:00Z", dsun: 216.168395, hgln: -0.126384, hglt: 1.821776 },
  { isoDate: "2012-06-23T00:00:00Z", dsun: 216.173591, hgln: -0.119258, hglt: 1.938165 },
  { isoDate: "2012-06-24T00:00:00Z", dsun: 216.178183, hgln: -0.111953, hglt: 2.054047 },
  { isoDate: "2012-06-25T00:00:00Z", dsun: 216.182181, hgln: -0.104464, hglt: 2.169391 },
  { isoDate: "2012-06-26T00:00:00Z", dsun: 216.185593, hgln: -0.096788, hglt: 2.284163 },
  { isoDate: "2012-06-27T00:00:00Z", dsun: 216.188426, hgln: -0.088927, hglt: 2.398333 },
  { isoDate: "2012-06-28T00:00:00Z", dsun: 216.190689, hgln: -0.080883, hglt: 2.511869 },
  { isoDate: "2012-06-29T00:00:00Z", dsun: 216.19239, hgln: -0.072664, hglt: 2.624739 },
  { isoDate: "2012-06-30T00:00:00Z", dsun: 216.193536, hgln: -0.064283, hglt: 2.736913 },
  { isoDate: "2012-07-01T00:00:00Z", dsun: 216.194134, hgln: -0.055758, hglt: 2.848359 },
  { isoDate: "2012-07-02T00:00:00Z", dsun: 216.194192, hgln: -0.047113, hglt: 2.959045 },
  { isoDate: "2012-07-03T00:00:00Z", dsun: 216.193716, hgln: -0.038374, hglt: 3.06894 },
  { isoDate: "2012-07-04T00:00:00Z", dsun: 216.19271, hgln: -0.029572, hglt: 3.178015 },
  { isoDate: "2012-07-05T00:00:00Z", dsun: 216.19118, hgln: -0.020738, hglt: 3.286237 },
  { isoDate: "2012-07-06T00:00:00Z", dsun: 216.189128, hgln: -0.0119, hglt: 3.393578 },
  { isoDate: "2012-07-07T00:00:00Z", dsun: 216.186557, hgln: -0.003085, hglt: 3.500005 },
  { isoDate: "2012-07-08T00:00:00Z", dsun: 216.183468, hgln: 0.005683, hglt: 3.605491 },
  { isoDate: "2012-07-09T00:00:00Z", dsun: 216.179863, hgln: 0.014387, hglt: 3.710003 },
  { isoDate: "2012-07-10T00:00:00Z", dsun: 216.17574, hgln: 0.02301, hglt: 3.813514 },
  { isoDate: "2012-07-11T00:00:00Z", dsun: 216.171112, hgln: 0.031541, hglt: 3.916001 },
  { isoDate: "2012-07-12T00:00:00Z", dsun: 216.165957, hgln: 0.03997, hglt: 4.017422 },
  { isoDate: "2012-07-13T00:00:00Z", dsun: 216.160281, hgln: 0.04829, hglt: 4.117752 },
  { isoDate: "2012-07-14T00:00:00Z", dsun: 216.154084, hgln: 0.056495, hglt: 4.216965 },
  { isoDate: "2012-07-15T00:00:00Z", dsun: 216.147364, hgln: 0.064582, hglt: 4.315031 },
  { isoDate: "2012-07-16T00:00:00Z", dsun: 216.140117, hgln: 0.07255, hglt: 4.411922 },
  { isoDate: "2012-07-17T00:00:00Z", dsun: 216.132343, hgln: 0.080399, hglt: 4.507611 },
  { isoDate: "2012-07-18T00:00:00Z", dsun: 216.124036, hgln: 0.088131, hglt: 4.602071 },
  { isoDate: "2012-07-19T00:00:00Z", dsun: 216.115193, hgln: 0.095751, hglt: 4.695273 },
  { isoDate: "2012-07-20T00:00:00Z", dsun: 216.105808, hgln: 0.103264, hglt: 4.787191 },
  { isoDate: "2012-07-21T00:00:00Z", dsun: 216.095873, hgln: 0.110677, hglt: 4.877798 },
  { isoDate: "2012-07-22T00:00:00Z", dsun: 216.085379, hgln: 0.117997, hglt: 4.967069 },
  { isoDate: "2012-07-23T00:00:00Z", dsun: 216.074318, hgln: 0.125233, hglt: 5.054977 },
  { isoDate: "2012-07-24T00:00:00Z", dsun: 216.062681, hgln: 0.132391, hglt: 5.141496 },
  { isoDate: "2012-07-25T00:00:00Z", dsun: 216.050459, hgln: 0.139475, hglt: 5.226602 },
  { isoDate: "2012-07-26T00:00:00Z", dsun: 216.037643, hgln: 0.146487, hglt: 5.31027 },
  { isoDate: "2012-07-27T00:00:00Z", dsun: 216.024224, hgln: 0.153424, hglt: 5.392474 },
  { isoDate: "2012-07-28T00:00:00Z", dsun: 216.010193, hgln: 0.16028, hglt: 5.473192 },
  { isoDate: "2012-07-29T00:00:00Z", dsun: 215.995541, hgln: 0.167043, hglt: 5.5524 },
  { isoDate: "2012-07-30T00:00:00Z", dsun: 215.980259, hgln: 0.173697, hglt: 5.630073 },
  { isoDate: "2012-07-31T00:00:00Z", dsun: 215.964337, hgln: 0.180219, hglt: 5.70619 },
  { isoDate: "2012-08-01T00:00:00Z", dsun: 215.947764, hgln: 0.186587, hglt: 5.780728 },
  { isoDate: "2012-08-02T00:00:00Z", dsun: 215.930529, hgln: 0.192774, hglt: 5.853665 },
  { isoDate: "2012-08-03T00:00:00Z", dsun: 215.912622, hgln: 0.198754, hglt: 5.924978 },
  { isoDate: "2012-08-04T00:00:00Z", dsun: 215.894029, hgln: 0.204501, hglt: 5.994648 },
  { isoDate: "2012-08-05T00:00:00Z", dsun: 215.874741, hgln: 0.209992, hglt: 6.062652 },
  { isoDate: "2012-08-06T00:00:00Z", dsun: 215.854744, hgln: 0.215208, hglt: 6.128971 },
  { isoDate: "2012-08-07T00:00:00Z", dsun: 215.834027, hgln: 0.220133, hglt: 6.193585 },
  { isoDate: "2012-08-08T00:00:00Z", dsun: 215.812578, hgln: 0.224753, hglt: 6.256474 },
  { isoDate: "2012-08-09T00:00:00Z", dsun: 215.790385, hgln: 0.229058, hglt: 6.31762 },
  { isoDate: "2012-08-10T00:00:00Z", dsun: 215.767439, hgln: 0.23304, hglt: 6.377003 },
  { isoDate: "2012-08-11T00:00:00Z", dsun: 215.743729, hgln: 0.236696, hglt: 6.434606 },
  { isoDate: "2012-08-12T00:00:00Z", dsun: 215.719245, hgln: 0.240021, hglt: 6.490411 },
  { isoDate: "2012-08-13T00:00:00Z", dsun: 215.693978, hgln: 0.243015, hglt: 6.544401 },
  { isoDate: "2012-08-14T00:00:00Z", dsun: 215.667921, hgln: 0.245681, hglt: 6.596559 },
  { isoDate: "2012-08-15T00:00:00Z", dsun: 215.64095, hgln: 0.248, hglt: 6.646824 },
  { isoDate: "2012-08-16T00:00:00Z", dsun: 215.613279, hgln: 0.250024, hglt: 6.695269 },
  { isoDate: "2012-08-17T00:00:00Z", dsun: 215.584791, hgln: 0.25174, hglt: 6.741836 },
  { isoDate: "2012-08-18T00:00:00Z", dsun: 215.555476, hgln: 0.253162, hglt: 6.786509 },
  { isoDate: "2012-08-19T00:00:00Z", dsun: 215.525324, hgln: 0.254304, hglt: 6.829274 },
  { isoDate: "2012-08-20T00:00:00Z", dsun: 215.494328, hgln: 0.255182, hglt: 6.870117 },
  { isoDate: "2012-08-21T00:00:00Z", dsun: 215.462478, hgln: 0.255812, hglt: 6.909024 },
  { isoDate: "2012-08-22T00:00:00Z", dsun: 215.429769, hgln: 0.25621, hglt: 6.945984 },
  { isoDate: "2012-08-23T00:00:00Z", dsun: 215.396195, hgln: 0.256387, hglt: 6.980983 },
  { isoDate: "2012-08-24T00:00:00Z", dsun: 215.361752, hgln: 0.256352, hglt: 7.014011 },
  { isoDate: "2012-08-25T00:00:00Z", dsun: 215.326438, hgln: 0.256109, hglt: 7.045055 },
  { isoDate: "2012-08-26T00:00:00Z", dsun: 215.29025, hgln: 0.255658, hglt: 7.074105 },
  { isoDate: "2012-08-27T00:00:00Z", dsun: 215.253187, hgln: 0.254992, hglt: 7.10115 },
  { isoDate: "2012-08-28T00:00:00Z", dsun: 215.215248, hgln: 0.254102, hglt: 7.126181 },
  { isoDate: "2012-08-29T00:00:00Z", dsun: 215.176434, hgln: 0.252974, hglt: 7.149189 },
  { isoDate: "2012-08-30T00:00:00Z", dsun: 215.136746, hgln: 0.251592, hglt: 7.170164 },
  { isoDate: "2012-08-31T00:00:00Z", dsun: 215.096186, hgln: 0.249938, hglt: 7.189099 },
  { isoDate: "2012-09-01T00:00:00Z", dsun: 215.054755, hgln: 0.247995, hglt: 7.205985 },
  { isoDate: "2012-09-02T00:00:00Z", dsun: 215.012457, hgln: 0.245746, hglt: 7.220815 },
  { isoDate: "2012-09-03T00:00:00Z", dsun: 214.969297, hgln: 0.243176, hglt: 7.233583 },
  { isoDate: "2012-09-04T00:00:00Z", dsun: 214.92528, hgln: 0.240271, hglt: 7.244282 },
  { isoDate: "2012-09-05T00:00:00Z", dsun: 214.880412, hgln: 0.237023, hglt: 7.252907 },
  { isoDate: "2012-09-06T00:00:00Z", dsun: 214.834703, hgln: 0.233423, hglt: 7.259453 },
  { isoDate: "2012-09-07T00:00:00Z", dsun: 214.78816, hgln: 0.229467, hglt: 7.263914 },
  { isoDate: "2012-09-08T00:00:00Z", dsun: 214.740797, hgln: 0.225153, hglt: 7.266287 },
  { isoDate: "2012-09-09T00:00:00Z", dsun: 214.692626, hgln: 0.220481, hglt: 7.266567 },
  { isoDate: "2012-09-10T00:00:00Z", dsun: 214.643663, hgln: 0.215454, hglt: 7.264753 },
  { isoDate: "2012-09-11T00:00:00Z", dsun: 214.593926, hgln: 0.210076, hglt: 7.26084 },
  { isoDate: "2012-09-12T00:00:00Z", dsun: 214.543433, hgln: 0.204356, hglt: 7.254826 },
  { isoDate: "2012-09-13T00:00:00Z", dsun: 214.492207, hgln: 0.198305, hglt: 7.246711 },
  { isoDate: "2012-09-14T00:00:00Z", dsun: 214.440268, hgln: 0.191937, hglt: 7.236492 },
  { isoDate: "2012-09-15T00:00:00Z", dsun: 214.387639, hgln: 0.18527, hglt: 7.224169 },
  { isoDate: "2012-09-16T00:00:00Z", dsun: 214.33434, hgln: 0.178326, hglt: 7.209742 },
  { isoDate: "2012-09-17T00:00:00Z", dsun: 214.280393, hgln: 0.17113, hglt: 7.193212 },
  { isoDate: "2012-09-18T00:00:00Z", dsun: 214.22582, hgln: 0.163709, hglt: 7.174579 },
  { isoDate: "2012-09-19T00:00:00Z", dsun: 214.170642, hgln: 0.156092, hglt: 7.153845 },
  { isoDate: "2012-09-20T00:00:00Z", dsun: 214.114885, hgln: 0.148305, hglt: 7.131012 },
  { isoDate: "2012-09-21T00:00:00Z", dsun: 214.058576, hgln: 0.140371, hglt: 7.106083 },
  { isoDate: "2012-09-22T00:00:00Z", dsun: 214.001742, hgln: 0.132311, hglt: 7.079061 },
  { isoDate: "2012-09-23T00:00:00Z", dsun: 213.944413, hgln: 0.124139, hglt: 7.049949 },
  { isoDate: "2012-09-24T00:00:00Z", dsun: 213.886619, hgln: 0.115865, hglt: 7.018753 },
  { isoDate: "2012-09-25T00:00:00Z", dsun: 213.828393, hgln: 0.107495, hglt: 6.985475 },
  { isoDate: "2012-09-26T00:00:00Z", dsun: 213.769764, hgln: 0.099032, hglt: 6.950122 },
  { isoDate: "2012-09-27T00:00:00Z", dsun: 213.710764, hgln: 0.090476, hglt: 6.9127 },
  { isoDate: "2012-09-28T00:00:00Z", dsun: 213.651424, hgln: 0.081824, hglt: 6.873215 },
  { isoDate: "2012-09-29T00:00:00Z", dsun: 213.591774, hgln: 0.073073, hglt: 6.831674 },
  { isoDate: "2012-09-30T00:00:00Z", dsun: 213.531841, hgln: 0.064222, hglt: 6.788084 },
  { isoDate: "2012-10-01T00:00:00Z", dsun: 213.471653, hgln: 0.055267, hglt: 6.742455 },
  { isoDate: "2012-10-02T00:00:00Z", dsun: 213.411237, hgln: 0.046207, hglt: 6.694794 },
  { isoDate: "2012-10-03T00:00:00Z", dsun: 213.350616, hgln: 0.037043, hglt: 6.645113 },
  { isoDate: "2012-10-04T00:00:00Z", dsun: 213.289814, hgln: 0.027778, hglt: 6.593421 },
  { isoDate: "2012-10-05T00:00:00Z", dsun: 213.228852, hgln: 0.018415, hglt: 6.539729 },
  { isoDate: "2012-10-06T00:00:00Z", dsun: 213.167748, hgln: 0.008961, hglt: 6.48405 },
  { isoDate: "2012-10-07T00:00:00Z", dsun: 213.106522, hgln: -0.000574, hglt: 6.426394 },
  { isoDate: "2012-10-08T00:00:00Z", dsun: 213.045188, hgln: -0.010181, hglt: 6.366776 },
  { isoDate: "2012-10-09T00:00:00Z", dsun: 212.983763, hgln: -0.019847, hglt: 6.30521 },
  { isoDate: "2012-10-10T00:00:00Z", dsun: 212.92226, hgln: -0.029556, hglt: 6.241709 },
  { isoDate: "2012-10-11T00:00:00Z", dsun: 212.860693, hgln: -0.039291, hglt: 6.176288 },
  { isoDate: "2012-10-12T00:00:00Z", dsun: 212.799073, hgln: -0.049032, hglt: 6.108964 },
  { isoDate: "2012-10-13T00:00:00Z", dsun: 212.737413, hgln: -0.058754, hglt: 6.039753 },
  { isoDate: "2012-10-14T00:00:00Z", dsun: 212.675726, hgln: -0.06843, hglt: 5.968671 },
  { isoDate: "2012-10-15T00:00:00Z", dsun: 212.614023, hgln: -0.078027, hglt: 5.895737 },
  { isoDate: "2012-10-16T00:00:00Z", dsun: 212.552316, hgln: -0.087513, hglt: 5.820969 },
  { isoDate: "2012-10-17T00:00:00Z", dsun: 212.490613, hgln: -0.096851, hglt: 5.744386 },
  { isoDate: "2012-10-18T00:00:00Z", dsun: 212.428919, hgln: -0.106009, hglt: 5.666009 },
  { isoDate: "2012-10-19T00:00:00Z", dsun: 212.367237, hgln: -0.114953, hglt: 5.585858 },
  { isoDate: "2012-10-20T00:00:00Z", dsun: 212.30557, hgln: -0.123657, hglt: 5.503955 },
  { isoDate: "2012-10-21T00:00:00Z", dsun: 212.243918, hgln: -0.132099, hglt: 5.420323 },
  { isoDate: "2012-10-22T00:00:00Z", dsun: 212.182284, hgln: -0.140262, hglt: 5.334984 },
  { isoDate: "2012-10-23T00:00:00Z", dsun: 212.120671, hgln: -0.148136, hglt: 5.247961 },
  { isoDate: "2012-10-24T00:00:00Z", dsun: 212.059084, hgln: -0.155714, hglt: 5.159278 },
  { isoDate: "2012-10-25T00:00:00Z", dsun: 211.99753, hgln: -0.162993, hglt: 5.06896 },
  { isoDate: "2012-10-26T00:00:00Z", dsun: 211.936017, hgln: -0.169975, hglt: 4.977032 },
  { isoDate: "2012-10-27T00:00:00Z", dsun: 211.874554, hgln: -0.176663, hglt: 4.883519 },
  { isoDate: "2012-10-28T00:00:00Z", dsun: 211.81315, hgln: -0.183062, hglt: 4.788449 },
  { isoDate: "2012-10-29T00:00:00Z", dsun: 211.751818, hgln: -0.189178, hglt: 4.691847 },
  { isoDate: "2012-10-30T00:00:00Z", dsun: 211.690568, hgln: -0.195019, hglt: 4.593742 },
  { isoDate: "2012-10-31T00:00:00Z", dsun: 211.629415, hgln: -0.200593, hglt: 4.494161 },
  { isoDate: "2012-11-01T00:00:00Z", dsun: 211.568371, hgln: -0.205909, hglt: 4.393133 },
  { isoDate: "2012-11-02T00:00:00Z", dsun: 211.507451, hgln: -0.210972, hglt: 4.290688 },
  { isoDate: "2012-11-03T00:00:00Z", dsun: 211.446672, hgln: -0.215791, hglt: 4.186856 },
  { isoDate: "2012-11-04T00:00:00Z", dsun: 211.386049, hgln: -0.220371, hglt: 4.081666 },
  { isoDate: "2012-11-05T00:00:00Z", dsun: 211.3256, hgln: -0.224717, hglt: 3.975149 },
  { isoDate: "2012-11-06T00:00:00Z", dsun: 211.265342, hgln: -0.228834, hglt: 3.867338 },
  { isoDate: "2012-11-07T00:00:00Z", dsun: 211.205296, hgln: -0.232724, hglt: 3.758263 },
  { isoDate: "2012-11-08T00:00:00Z", dsun: 211.145481, hgln: -0.236387, hglt: 3.647958 },
  { isoDate: "2012-11-09T00:00:00Z", dsun: 211.085919, hgln: -0.239822, hglt: 3.536454 },
  { isoDate: "2012-11-10T00:00:00Z", dsun: 211.026633, hgln: -0.243024, hglt: 3.423786 },
  { isoDate: "2012-11-11T00:00:00Z", dsun: 210.967646, hgln: -0.245982, hglt: 3.309987 },
  { isoDate: "2012-11-12T00:00:00Z", dsun: 210.908985, hgln: -0.248685, hglt: 3.195092 },
  { isoDate: "2012-11-13T00:00:00Z", dsun: 210.850677, hgln: -0.251114, hglt: 3.079134 },
  { isoDate: "2012-11-14T00:00:00Z", dsun: 210.793125, hgln: -0.25328, hglt: 2.962146 },
  { isoDate: "2012-11-15T00:00:00Z", dsun: 210.735629, hgln: -0.255095, hglt: 2.84417 },
  { isoDate: "2012-11-16T00:00:00Z", dsun: 210.678579, hgln: -0.256566, hglt: 2.72524 },
  { isoDate: "2012-11-17T00:00:00Z", dsun: 210.622004, hgln: -0.257673, hglt: 2.605391 },
  { isoDate: "2012-11-18T00:00:00Z", dsun: 210.565935, hgln: -0.2584, hglt: 2.484661 },
  { isoDate: "2012-11-19T00:00:00Z", dsun: 210.510403, hgln: -0.258735, hglt: 2.363087 },
  { isoDate: "2012-11-20T00:00:00Z", dsun: 210.455438, hgln: -0.258673, hglt: 2.240707 },
  { isoDate: "2012-11-21T00:00:00Z", dsun: 210.40107, hgln: -0.258214, hglt: 2.117559 },
  { isoDate: "2012-11-22T00:00:00Z", dsun: 210.347331, hgln: -0.25736, hglt: 1.993682 },
  { isoDate: "2012-11-23T00:00:00Z", dsun: 210.294254, hgln: -0.256118, hglt: 1.869115 },
  { isoDate: "2012-11-24T00:00:00Z", dsun: 210.241873, hgln: -0.254498, hglt: 1.743896 },
  { isoDate: "2012-11-25T00:00:00Z", dsun: 210.19022, hgln: -0.25251, hglt: 1.618066 },
  { isoDate: "2012-11-26T00:00:00Z", dsun: 210.139332, hgln: -0.250168, hglt: 1.491665 },
  { isoDate: "2012-11-27T00:00:00Z", dsun: 210.089244, hgln: -0.247485, hglt: 1.364732 },
  { isoDate: "2012-11-28T00:00:00Z", dsun: 210.03999, hgln: -0.244478, hglt: 1.23731 },
  { isoDate: "2012-11-29T00:00:00Z", dsun: 209.991605, hgln: -0.241162, hglt: 1.109438 },
  { isoDate: "2012-11-30T00:00:00Z", dsun: 209.944125, hgln: -0.237555, hglt: 0.981158 },
  { isoDate: "2012-12-01T00:00:00Z", dsun: 209.897584, hgln: -0.233673, hglt: 0.852512 },
  { isoDate: "2012-12-02T00:00:00Z", dsun: 209.852017, hgln: -0.229533, hglt: 0.723542 },
  { isoDate: "2012-12-03T00:00:00Z", dsun: 209.807456, hgln: -0.225153, hglt: 0.59429 },
  { isoDate: "2012-12-04T00:00:00Z", dsun: 209.763937, hgln: -0.220547, hglt: 0.464798 },
  { isoDate: "2012-12-05T00:00:00Z", dsun: 209.72149, hgln: -0.215731, hglt: 0.335109 },
  { isoDate: "2012-12-06T00:00:00Z", dsun: 209.680148, hgln: -0.210719, hglt: 0.205266 },
  { isoDate: "2012-12-07T00:00:00Z", dsun: 209.639942, hgln: -0.205522, hglt: 0.075311 },
  { isoDate: "2012-12-08T00:00:00Z", dsun: 209.600904, hgln: -0.200149, hglt: -0.054712 },
  { isoDate: "2012-12-09T00:00:00Z", dsun: 209.563063, hgln: -0.194607, hglt: -0.184759 },
  { isoDate: "2012-12-10T00:00:00Z", dsun: 209.52645, hgln: -0.188896, hglt: -0.314788 },
  { isoDate: "2012-12-11T00:00:00Z", dsun: 209.491096, hgln: -0.183015, hglt: -0.444754 },
  { isoDate: "2012-12-12T00:00:00Z", dsun: 209.457024, hgln: -0.17695, hglt: -0.574615 },
  { isoDate: "2012-12-13T00:00:00Z", dsun: 209.424275, hgln: -0.170697, hglt: -0.704325 },
  { isoDate: "2012-12-14T00:00:00Z", dsun: 209.392873, hgln: -0.164237, hglt: -0.833842 },
  { isoDate: "2012-12-15T00:00:00Z", dsun: 209.362845, hgln: -0.157556, hglt: -0.963122 },
  { isoDate: "2012-12-16T00:00:00Z", dsun: 209.334217, hgln: -0.15064, hglt: -1.092119 },
  { isoDate: "2012-12-17T00:00:00Z", dsun: 209.307012, hgln: -0.143481, hglt: -1.220791 },
  { isoDate: "2012-12-18T00:00:00Z", dsun: 209.281253, hgln: -0.136074, hglt: -1.349094 },
  { isoDate: "2012-12-19T00:00:00Z", dsun: 209.256961, hgln: -0.128421, hglt: -1.476983 },
  { isoDate: "2012-12-20T00:00:00Z", dsun: 209.234155, hgln: -0.120528, hglt: -1.604416 },
  { isoDate: "2012-12-21T00:00:00Z", dsun: 209.212855, hgln: -0.112403, hglt: -1.731347 },
  { isoDate: "2012-12-22T00:00:00Z", dsun: 209.19308, hgln: -0.104059, hglt: -1.857735 },
  { isoDate: "2012-12-23T00:00:00Z", dsun: 209.174846, hgln: -0.095512, hglt: -1.983535 },
  { isoDate: "2012-12-24T00:00:00Z", dsun: 209.15817, hgln: -0.086779, hglt: -2.108704 },
  { isoDate: "2012-12-25T00:00:00Z", dsun: 209.143069, hgln: -0.07788, hglt: -2.233199 },
  { isoDate: "2012-12-26T00:00:00Z", dsun: 209.129555, hgln: -0.068834, hglt: -2.356977 },
  { isoDate: "2012-12-27T00:00:00Z", dsun: 209.117641, hgln: -0.059664, hglt: -2.479995 },
  { isoDate: "2012-12-28T00:00:00Z", dsun: 209.107339, hgln: -0.050394, hglt: -2.602211 },
  { isoDate: "2012-12-29T00:00:00Z", dsun: 209.098656, hgln: -0.041049, hglt: -2.723583 },
  { isoDate: "2012-12-30T00:00:00Z", dsun: 209.091601, hgln: -0.031653, hglt: -2.844069 },
  { isoDate: "2012-12-31T00:00:00Z", dsun: 209.086179, hgln: -0.022233, hglt: -2.963628 },
  { isoDate: "2013-01-01T00:00:00Z", dsun: 209.082394, hgln: -0.012815, hglt: -3.082217 },
  { isoDate: "2013-01-02T00:00:00Z", dsun: 209.080247, hgln: -0.003422, hglt: -3.199797 },
  { isoDate: "2013-01-03T00:00:00Z", dsun: 209.079739, hgln: 0.005921, hglt: -3.316327 },
  { isoDate: "2013-01-04T00:00:00Z", dsun: 209.080868, hgln: 0.015192, hglt: -3.431768 },
  { isoDate: "2013-01-05T00:00:00Z", dsun: 209.083633, hgln: 0.024371, hglt: -3.546079 },
  { isoDate: "2013-01-06T00:00:00Z", dsun: 209.088028, hgln: 0.033441, hglt: -3.659221 },
  { isoDate: "2013-01-07T00:00:00Z", dsun: 209.094048, hgln: 0.04239, hglt: -3.771157 },
  { isoDate: "2013-01-08T00:00:00Z", dsun: 209.101689, hgln: 0.051209, hglt: -3.881848 },
  { isoDate: "2013-01-09T00:00:00Z", dsun: 209.111075, hgln: 0.059895, hglt: -3.991258 },
  { isoDate: "2013-01-10T00:00:00Z", dsun: 209.121957, hgln: 0.06845, hglt: -4.099346 },
  { isoDate: "2013-01-11T00:00:00Z", dsun: 209.134435, hgln: 0.076882, hglt: -4.206079 },
  { isoDate: "2013-01-12T00:00:00Z", dsun: 209.148494, hgln: 0.0852, hglt: -4.311419 },
  { isoDate: "2013-01-13T00:00:00Z", dsun: 209.164123, hgln: 0.093416, hglt: -4.415332 },
  { isoDate: "2013-01-14T00:00:00Z", dsun: 209.181302, hgln: 0.101541, hglt: -4.517784 },
  { isoDate: "2013-01-15T00:00:00Z", dsun: 209.200016, hgln: 0.109581, hglt: -4.618739 },
  { isoDate: "2013-01-16T00:00:00Z", dsun: 209.220244, hgln: 0.117541, hglt: -4.718165 },
  { isoDate: "2013-01-17T00:00:00Z", dsun: 209.241966, hgln: 0.12542, hglt: -4.81603 },
  { isoDate: "2013-01-18T00:00:00Z", dsun: 209.265162, hgln: 0.133215, hglt: -4.912301 },
  { isoDate: "2013-01-19T00:00:00Z", dsun: 209.28981, hgln: 0.140918, hglt: -5.006948 },
  { isoDate: "2013-01-20T00:00:00Z", dsun: 209.315888, hgln: 0.14852, hglt: -5.099939 },
  { isoDate: "2013-01-21T00:00:00Z", dsun: 209.343373, hgln: 0.156007, hglt: -5.191245 },
  { isoDate: "2013-01-22T00:00:00Z", dsun: 209.372241, hgln: 0.163365, hglt: -5.280837 },
  { isoDate: "2013-01-23T00:00:00Z", dsun: 209.402466, hgln: 0.17058, hglt: -5.368687 },
  { isoDate: "2013-01-24T00:00:00Z", dsun: 209.434024, hgln: 0.177633, hglt: -5.454766 },
  { isoDate: "2013-01-25T00:00:00Z", dsun: 209.466886, hgln: 0.184506, hglt: -5.539048 },
  { isoDate: "2013-01-26T00:00:00Z", dsun: 209.501023, hgln: 0.191179, hglt: -5.621508 },
  { isoDate: "2013-01-27T00:00:00Z", dsun: 209.536408, hgln: 0.19763, hglt: -5.702119 },
  { isoDate: "2013-01-28T00:00:00Z", dsun: 209.573009, hgln: 0.203836, hglt: -5.780858 },
  { isoDate: "2013-01-29T00:00:00Z", dsun: 209.610795, hgln: 0.209774, hglt: -5.857701 },
  { isoDate: "2013-01-30T00:00:00Z", dsun: 209.649735, hgln: 0.215422, hglt: -5.932625 },
  { isoDate: "2013-01-31T00:00:00Z", dsun: 209.689795, hgln: 0.220756, hglt: -6.005609 },
  { isoDate: "2013-02-01T00:00:00Z", dsun: 209.730944, hgln: 0.225757, hglt: -6.076631 },
  { isoDate: "2013-02-02T00:00:00Z", dsun: 209.773147, hgln: 0.230405, hglt: -6.145671 },
  { isoDate: "2013-02-03T00:00:00Z", dsun: 209.816373, hgln: 0.234684, hglt: -6.21271 },
  { isoDate: "2013-02-04T00:00:00Z", dsun: 209.860587, hgln: 0.238584, hglt: -6.27773 },
  { isoDate: "2013-02-05T00:00:00Z", dsun: 209.905759, hgln: 0.242096, hglt: -6.340712 },
  { isoDate: "2013-02-06T00:00:00Z", dsun: 209.951854, hgln: 0.24522, hglt: -6.40164 },
  { isoDate: "2013-02-07T00:00:00Z", dsun: 209.998843, hgln: 0.247958, hglt: -6.460498 },
  { isoDate: "2013-02-08T00:00:00Z", dsun: 210.046691, hgln: 0.250318, hglt: -6.51727 },
  { isoDate: "2013-02-09T00:00:00Z", dsun: 210.095367, hgln: 0.252315, hglt: -6.571943 },
  { isoDate: "2013-02-10T00:00:00Z", dsun: 210.144835, hgln: 0.253964, hglt: -6.624502 },
  { isoDate: "2013-02-11T00:00:00Z", dsun: 210.195062, hgln: 0.255282, hglt: -6.674937 },
  { isoDate: "2013-02-12T00:00:00Z", dsun: 210.246012, hgln: 0.256289, hglt: -6.723234 },
  { isoDate: "2013-02-13T00:00:00Z", dsun: 210.297642, hgln: 0.256996, hglt: -6.769377 },
  { isoDate: "2013-02-14T00:00:00Z", dsun: 210.349936, hgln: 0.257425, hglt: -6.813369 },
  { isoDate: "2013-02-15T00:00:00Z", dsun: 210.402852, hgln: 0.257581, hglt: -6.855194 },
  { isoDate: "2013-02-16T00:00:00Z", dsun: 210.45636, hgln: 0.257472, hglt: -6.894844 },
  { isoDate: "2013-02-17T00:00:00Z", dsun: 210.510427, hgln: 0.257101, hglt: -6.932312 },
  { isoDate: "2013-02-18T00:00:00Z", dsun: 210.565025, hgln: 0.256472, hglt: -6.967592 },
  { isoDate: "2013-02-19T00:00:00Z", dsun: 210.620125, hgln: 0.255583, hglt: -7.000678 },
  { isoDate: "2013-02-20T00:00:00Z", dsun: 210.675697, hgln: 0.254432, hglt: -7.031565 },
  { isoDate: "2013-02-21T00:00:00Z", dsun: 210.731715, hgln: 0.253015, hglt: -7.060249 },
  { isoDate: "2013-02-22T00:00:00Z", dsun: 210.788152, hgln: 0.251327, hglt: -7.086728 },
  { isoDate: "2013-02-23T00:00:00Z", dsun: 210.844979, hgln: 0.249359, hglt: -7.110999 },
  { isoDate: "2013-02-24T00:00:00Z", dsun: 210.902173, hgln: 0.247103, hglt: -7.13306 },
  { isoDate: "2013-02-25T00:00:00Z", dsun: 210.959706, hgln: 0.244546, hglt: -7.152912 },
  { isoDate: "2013-02-26T00:00:00Z", dsun: 211.017556, hgln: 0.241675, hglt: -7.170553 },
  { isoDate: "2013-02-27T00:00:00Z", dsun: 211.075696, hgln: 0.238476, hglt: -7.185987 },
  { isoDate: "2013-02-28T00:00:00Z", dsun: 211.134106, hgln: 0.234934, hglt: -7.199214 },
  { isoDate: "2013-03-01T00:00:00Z", dsun: 211.192762, hgln: 0.231034, hglt: -7.210237 },
  { isoDate: "2013-03-02T00:00:00Z", dsun: 211.251645, hgln: 0.226763, hglt: -7.219059 },
  { isoDate: "2013-03-03T00:00:00Z", dsun: 211.310733, hgln: 0.222111, hglt: -7.225686 },
  { isoDate: "2013-03-04T00:00:00Z", dsun: 211.370011, hgln: 0.217072, hglt: -7.230121 },
  { isoDate: "2013-03-05T00:00:00Z", dsun: 211.429462, hgln: 0.211644, hglt: -7.232371 },
  { isoDate: "2013-03-06T00:00:00Z", dsun: 211.489072, hgln: 0.20583, hglt: -7.232442 },
  { isoDate: "2013-03-07T00:00:00Z", dsun: 211.548829, hgln: 0.199641, hglt: -7.230341 },
  { isoDate: "2013-03-08T00:00:00Z", dsun: 211.608725, hgln: 0.19309, hglt: -7.226077 },
  { isoDate: "2013-03-09T00:00:00Z", dsun: 211.66875, hgln: 0.186196, hglt: -7.219657 },
  { isoDate: "2013-03-10T00:00:00Z", dsun: 211.728896, hgln: 0.178981, hglt: -7.211091 },
  { isoDate: "2013-03-11T00:00:00Z", dsun: 211.789157, hgln: 0.17147, hglt: -7.200389 },
  { isoDate: "2013-03-12T00:00:00Z", dsun: 211.849522, hgln: 0.163691, hglt: -7.187561 },
  { isoDate: "2013-03-13T00:00:00Z", dsun: 211.909979, hgln: 0.15567, hglt: -7.172619 },
  { isoDate: "2013-03-14T00:00:00Z", dsun: 211.970523, hgln: 0.147435, hglt: -7.155574 },
  { isoDate: "2013-03-15T00:00:00Z", dsun: 212.031143, hgln: 0.139012, hglt: -7.136439 },
  { isoDate: "2013-03-16T00:00:00Z", dsun: 212.091831, hgln: 0.130424, hglt: -7.115225 },
  { isoDate: "2013-03-17T00:00:00Z", dsun: 212.15258, hgln: 0.121693, hglt: -7.091947 },
  { isoDate: "2013-03-18T00:00:00Z", dsun: 212.213385, hgln: 0.11284, hglt: -7.066618 },
  { isoDate: "2013-03-19T00:00:00Z", dsun: 212.274242, hgln: 0.103882, hglt: -7.039252 },
  { isoDate: "2013-03-20T00:00:00Z", dsun: 212.335148, hgln: 0.094835, hglt: -7.009863 },
  { isoDate: "2013-03-21T00:00:00Z", dsun: 212.396098, hgln: 0.085715, hglt: -6.978467 },
  { isoDate: "2013-03-22T00:00:00Z", dsun: 212.457088, hgln: 0.076534, hglt: -6.945078 },
  { isoDate: "2013-03-23T00:00:00Z", dsun: 212.518114, hgln: 0.067302, hglt: -6.909713 },
  { isoDate: "2013-03-24T00:00:00Z", dsun: 212.579171, hgln: 0.05803, hglt: -6.872387 },
  { isoDate: "2013-03-25T00:00:00Z", dsun: 212.640252, hgln: 0.048722, hglt: -6.833117 },
  { isoDate: "2013-03-26T00:00:00Z", dsun: 212.701348, hgln: 0.039382, hglt: -6.791919 },
  { isoDate: "2013-03-27T00:00:00Z", dsun: 212.76245, hgln: 0.030012, hglt: -6.748809 },
  { isoDate: "2013-03-28T00:00:00Z", dsun: 212.823546, hgln: 0.020609, hglt: -6.703805 },
  { isoDate: "2013-03-29T00:00:00Z", dsun: 212.884621, hgln: 0.01117, hglt: -6.656924 },
  { isoDate: "2013-03-30T00:00:00Z", dsun: 212.945661, hgln: 0.001691, hglt: -6.608182 },
  { isoDate: "2013-03-31T00:00:00Z", dsun: 213.006646, hgln: -0.00783, hglt: -6.557598 },
  { isoDate: "2013-04-01T00:00:00Z", dsun: 213.067557, hgln: -0.017392, hglt: -6.50519 },
  { isoDate: "2013-04-02T00:00:00Z", dsun: 213.128372, hgln: -0.02699, hglt: -6.450974 },
  { isoDate: "2013-04-03T00:00:00Z", dsun: 213.189066, hgln: -0.036616, hglt: -6.394969 },
  { isoDate: "2013-04-04T00:00:00Z", dsun: 213.249617, hgln: -0.046256, hglt: -6.337193 },
  { isoDate: "2013-04-05T00:00:00Z", dsun: 213.309998, hgln: -0.055889, hglt: -6.277664 },
  { isoDate: "2013-04-06T00:00:00Z", dsun: 213.370183, hgln: -0.065495, hglt: -6.216401 },
  { isoDate: "2013-04-07T00:00:00Z", dsun: 213.430147, hgln: -0.075045, hglt: -6.153422 },
  { isoDate: "2013-04-08T00:00:00Z", dsun: 213.489864, hgln: -0.084513, hglt: -6.088747 },
  { isoDate: "2013-04-09T00:00:00Z", dsun: 213.54931, hgln: -0.093868, hglt: -6.022394 },
  { isoDate: "2013-04-10T00:00:00Z", dsun: 213.608677, hgln: -0.103087, hglt: -5.954387 },
  { isoDate: "2013-04-11T00:00:00Z", dsun: 213.667534, hgln: -0.112132, hglt: -5.884739 },
  { isoDate: "2013-04-12T00:00:00Z", dsun: 213.726042, hgln: -0.120978, hglt: -5.813474 },
  { isoDate: "2013-04-13T00:00:00Z", dsun: 213.784172, hgln: -0.129603, hglt: -5.740611 },
  { isoDate: "2013-04-14T00:00:00Z", dsun: 213.841892, hgln: -0.137983, hglt: -5.666171 },
  { isoDate: "2013-04-15T00:00:00Z", dsun: 213.899171, hgln: -0.146099, hglt: -5.590176 },
  { isoDate: "2013-04-16T00:00:00Z", dsun: 213.955977, hgln: -0.153932, hglt: -5.512646 },
  { isoDate: "2013-04-17T00:00:00Z", dsun: 214.012277, hgln: -0.161467, hglt: -5.433602 },
  { isoDate: "2013-04-18T00:00:00Z", dsun: 214.068041, hgln: -0.168691, hglt: -5.353067 },
  { isoDate: "2013-04-19T00:00:00Z", dsun: 214.123241, hgln: -0.175593, hglt: -5.271062 },
  { isoDate: "2013-04-20T00:00:00Z", dsun: 214.177851, hgln: -0.182164, hglt: -5.187609 },
  { isoDate: "2013-04-21T00:00:00Z", dsun: 214.231845, hgln: -0.1884, hglt: -5.102732 },
  { isoDate: "2013-04-22T00:00:00Z", dsun: 214.285201, hgln: -0.194298, hglt: -5.016453 },
  { isoDate: "2013-04-23T00:00:00Z", dsun: 214.337897, hgln: -0.19986, hglt: -4.928797 },
  { isoDate: "2013-04-24T00:00:00Z", dsun: 214.389914, hgln: -0.205092, hglt: -4.839786 },
  { isoDate: "2013-04-25T00:00:00Z", dsun: 214.441233, hgln: -0.210005, hglt: -4.749445 },
  { isoDate: "2013-04-26T00:00:00Z", dsun: 214.491837, hgln: -0.214614, hglt: -4.657799 },
  { isoDate: "2013-04-27T00:00:00Z", dsun: 214.54171, hgln: -0.218935, hglt: -4.564872 },
  { isoDate: "2013-04-28T00:00:00Z", dsun: 214.590837, hgln: -0.222988, hglt: -4.47069 },
  { isoDate: "2013-04-29T00:00:00Z", dsun: 214.639204, hgln: -0.226791, hglt: -4.375278 },
  { isoDate: "2013-04-30T00:00:00Z", dsun: 214.686798, hgln: -0.230358, hglt: -4.278662 },
  { isoDate: "2013-05-01T00:00:00Z", dsun: 214.733606, hgln: -0.233701, hglt: -4.180869 },
  { isoDate: "2013-05-02T00:00:00Z", dsun: 214.779619, hgln: -0.236826, hglt: -4.081924 },
  { isoDate: "2013-05-03T00:00:00Z", dsun: 214.824825, hgln: -0.239733, hglt: -3.981855 },
  { isoDate: "2013-05-04T00:00:00Z", dsun: 214.869216, hgln: -0.24242, hglt: -3.880689 },
  { isoDate: "2013-05-05T00:00:00Z", dsun: 214.912784, hgln: -0.24488, hglt: -3.778453 },
  { isoDate: "2013-05-06T00:00:00Z", dsun: 214.955524, hgln: -0.247104, hglt: -3.675174 },
  { isoDate: "2013-05-07T00:00:00Z", dsun: 214.997431, hgln: -0.249079, hglt: -3.570882 },
  { isoDate: "2013-05-08T00:00:00Z", dsun: 215.038501, hgln: -0.250793, hglt: -3.465603 },
  { isoDate: "2013-05-09T00:00:00Z", dsun: 215.078735, hgln: -0.252231, hglt: -3.359366 },
  { isoDate: "2013-05-10T00:00:00Z", dsun: 215.118133, hgln: -0.25338, hglt: -3.2522 },
  { isoDate: "2013-05-11T00:00:00Z", dsun: 215.156696, hgln: -0.254226, hglt: -3.144135 },
  { isoDate: "2013-05-12T00:00:00Z", dsun: 215.194429, hgln: -0.254758, hglt: -3.035199 },
  { isoDate: "2013-05-13T00:00:00Z", dsun: 215.231333, hgln: -0.254964, hglt: -2.925421 },
  { isoDate: "2013-05-14T00:00:00Z", dsun: 215.267413, hgln: -0.254834, hglt: -2.814832 },
  { isoDate: "2013-05-15T00:00:00Z", dsun: 215.302669, hgln: -0.25436, hglt: -2.703462 },
  { isoDate: "2013-05-16T00:00:00Z", dsun: 215.337108, hgln: -0.253538, hglt: -2.59134 },
  { isoDate: "2013-05-17T00:00:00Z", dsun: 215.370731, hgln: -0.252362, hglt: -2.478498 },
  { isoDate: "2013-05-18T00:00:00Z", dsun: 215.403543, hgln: -0.250829, hglt: -2.364965 },
  { isoDate: "2013-05-19T00:00:00Z", dsun: 215.435549, hgln: -0.248938, hglt: -2.250773 },
  { isoDate: "2013-05-20T00:00:00Z", dsun: 215.466754, hgln: -0.246691, hglt: -2.135951 },
  { isoDate: "2013-05-21T00:00:00Z", dsun: 215.497166, hgln: -0.244093, hglt: -2.020532 },
  { isoDate: "2013-05-22T00:00:00Z", dsun: 215.526793, hgln: -0.241152, hglt: -1.904547 },
  { isoDate: "2013-05-23T00:00:00Z", dsun: 215.555643, hgln: -0.237883, hglt: -1.788026 },
  { isoDate: "2013-05-24T00:00:00Z", dsun: 215.583727, hgln: -0.234302, hglt: -1.671001 },
  { isoDate: "2013-05-25T00:00:00Z", dsun: 215.611055, hgln: -0.230432, hglt: -1.553505 },
  { isoDate: "2013-05-26T00:00:00Z", dsun: 215.637635, hgln: -0.2263, hglt: -1.435568 },
  { isoDate: "2013-05-27T00:00:00Z", dsun: 215.663479, hgln: -0.221933, hglt: -1.317223 },
  { isoDate: "2013-05-28T00:00:00Z", dsun: 215.688598, hgln: -0.217356, hglt: -1.198503 },
  { isoDate: "2013-05-29T00:00:00Z", dsun: 215.713, hgln: -0.212594, hglt: -1.079438 },
  { isoDate: "2013-05-30T00:00:00Z", dsun: 215.736695, hgln: -0.207665, hglt: -0.960062 },
  { isoDate: "2013-05-31T00:00:00Z", dsun: 215.759695, hgln: -0.202582, hglt: -0.840407 },
  { isoDate: "2013-06-01T00:00:00Z", dsun: 215.782008, hgln: -0.197354, hglt: -0.720505 },
  { isoDate: "2013-06-02T00:00:00Z", dsun: 215.803645, hgln: -0.191985, hglt: -0.60039 },
  { isoDate: "2013-06-03T00:00:00Z", dsun: 215.824615, hgln: -0.186478, hglt: -0.480093 },
  { isoDate: "2013-06-04T00:00:00Z", dsun: 215.844927, hgln: -0.180829, hglt: -0.359648 },
  { isoDate: "2013-06-05T00:00:00Z", dsun: 215.864594, hgln: -0.175037, hglt: -0.239088 },
  { isoDate: "2013-06-06T00:00:00Z", dsun: 215.883625, hgln: -0.169097, hglt: -0.118444 },
  { isoDate: "2013-06-07T00:00:00Z", dsun: 215.902031, hgln: -0.163003, hglt: 0.00225 },
  { isoDate: "2013-06-08T00:00:00Z", dsun: 215.919823, hgln: -0.156749, hglt: 0.12296 },
  { isoDate: "2013-06-09T00:00:00Z", dsun: 215.937014, hgln: -0.150329, hglt: 0.243655 },
  { isoDate: "2013-06-10T00:00:00Z", dsun: 215.953613, hgln: -0.143737, hglt: 0.364301 },
  { isoDate: "2013-06-11T00:00:00Z", dsun: 215.96963, hgln: -0.136969, hglt: 0.484864 },
  { isoDate: "2013-06-12T00:00:00Z", dsun: 215.985068, hgln: -0.130016, hglt: 0.605316 },
  { isoDate: "2013-06-13T00:00:00Z", dsun: 215.999946, hgln: -0.122882, hglt: 0.725616 },
  { isoDate: "2013-06-14T00:00:00Z", dsun: 216.014264, hgln: -0.115562, hglt: 0.845735 },
  { isoDate: "2013-06-15T00:00:00Z", dsun: 216.02803, hgln: -0.108053, hglt: 0.965639 },
  { isoDate: "2013-06-16T00:00:00Z", dsun: 216.041248, hgln: -0.100357, hglt: 1.085296 },
  { isoDate: "2013-06-17T00:00:00Z", dsun: 216.053924, hgln: -0.092476, hglt: 1.204673 },
  { isoDate: "2013-06-18T00:00:00Z", dsun: 216.066065, hgln: -0.084414, hglt: 1.323736 },
  { isoDate: "2013-06-19T00:00:00Z", dsun: 216.077676, hgln: -0.07618, hglt: 1.442454 },
  { isoDate: "2013-06-20T00:00:00Z", dsun: 216.088762, hgln: -0.067786, hglt: 1.560792 },
  { isoDate: "2013-06-21T00:00:00Z", dsun: 216.099329, hgln: -0.059251, hglt: 1.678718 },
  { isoDate: "2013-06-22T00:00:00Z", dsun: 216.10938, hgln: -0.050596, hglt: 1.796201 },
  { isoDate: "2013-06-23T00:00:00Z", dsun: 216.11892, hgln: -0.04185, hglt: 1.913206 },
  { isoDate: "2013-06-24T00:00:00Z", dsun: 216.127951, hgln: -0.033044, hglt: 2.029702 },
  { isoDate: "2013-06-25T00:00:00Z", dsun: 216.136474, hgln: -0.02421, hglt: 2.145657 },
  { isoDate: "2013-06-26T00:00:00Z", dsun: 216.144492, hgln: -0.015379, hglt: 2.261037 },
  { isoDate: "2013-06-27T00:00:00Z", dsun: 216.152003, hgln: -0.006581, hglt: 2.375811 },
  { isoDate: "2013-06-28T00:00:00Z", dsun: 216.159007, hgln: 0.002163, hglt: 2.489947 },
  { isoDate: "2013-06-29T00:00:00Z", dsun: 216.165501, hgln: 0.010833, hglt: 2.603413 },
  { isoDate: "2013-06-30T00:00:00Z", dsun: 216.171484, hgln: 0.019416, hglt: 2.716178 },
  { isoDate: "2013-07-01T00:00:00Z", dsun: 216.176952, hgln: 0.027903, hglt: 2.828209 },
  { isoDate: "2013-07-02T00:00:00Z", dsun: 216.181903, hgln: 0.036287, hglt: 2.939475 },
  { isoDate: "2013-07-03T00:00:00Z", dsun: 216.186332, hgln: 0.044566, hglt: 3.049946 },
  { isoDate: "2013-07-04T00:00:00Z", dsun: 216.190236, hgln: 0.052738, hglt: 3.15959 },
  { isoDate: "2013-07-05T00:00:00Z", dsun: 216.193611, hgln: 0.060803, hglt: 3.268377 },
  { isoDate: "2013-07-06T00:00:00Z", dsun: 216.196452, hgln: 0.068762, hglt: 3.376276 },
  { isoDate: "2013-07-07T00:00:00Z", dsun: 216.198755, hgln: 0.076618, hglt: 3.483256 },
  { isoDate: "2013-07-08T00:00:00Z", dsun: 216.200513, hgln: 0.084374, hglt: 3.589289 },
  { isoDate: "2013-07-09T00:00:00Z", dsun: 216.201718, hgln: 0.092034, hglt: 3.694343 },
  { isoDate: "2013-07-10T00:00:00Z", dsun: 216.202697, hgln: 0.099589, hglt: 3.798388 },
  { isoDate: "2013-07-11T00:00:00Z", dsun: 216.202799, hgln: 0.107067, hglt: 3.901397 },
  { isoDate: "2013-07-12T00:00:00Z", dsun: 216.202318, hgln: 0.114463, hglt: 4.003339 },
  { isoDate: "2013-07-13T00:00:00Z", dsun: 216.201243, hgln: 0.121781, hglt: 4.104186 },
  { isoDate: "2013-07-14T00:00:00Z", dsun: 216.199563, hgln: 0.129023, hglt: 4.203909 },
  { isoDate: "2013-07-15T00:00:00Z", dsun: 216.197267, hgln: 0.136193, hglt: 4.30248 },
  { isoDate: "2013-07-16T00:00:00Z", dsun: 216.194344, hgln: 0.14329, hglt: 4.399872 },
  { isoDate: "2013-07-17T00:00:00Z", dsun: 216.190782, hgln: 0.150311, hglt: 4.496056 },
  { isoDate: "2013-07-18T00:00:00Z", dsun: 216.18657, hgln: 0.157251, hglt: 4.591005 },
  { isoDate: "2013-07-19T00:00:00Z", dsun: 216.181697, hgln: 0.164099, hglt: 4.684692 },
  { isoDate: "2013-07-20T00:00:00Z", dsun: 216.176151, hgln: 0.17084, hglt: 4.777091 },
  { isoDate: "2013-07-21T00:00:00Z", dsun: 216.169919, hgln: 0.177452, hglt: 4.868176 },
  { isoDate: "2013-07-22T00:00:00Z", dsun: 216.162987, hgln: 0.183911, hglt: 4.957919 },
  { isoDate: "2013-07-23T00:00:00Z", dsun: 216.155344, hgln: 0.190188, hglt: 5.046296 },
  { isoDate: "2013-07-24T00:00:00Z", dsun: 216.146975, hgln: 0.196253, hglt: 5.133281 },
  { isoDate: "2013-07-25T00:00:00Z", dsun: 216.137865, hgln: 0.202077, hglt: 5.21885 },
  { isoDate: "2013-07-26T00:00:00Z", dsun: 216.128001, hgln: 0.207637, hglt: 5.302977 },
  { isoDate: "2013-07-27T00:00:00Z", dsun: 216.117368, hgln: 0.212911, hglt: 5.385639 },
  { isoDate: "2013-07-28T00:00:00Z", dsun: 216.105951, hgln: 0.217884, hglt: 5.466812 },
  { isoDate: "2013-07-29T00:00:00Z", dsun: 216.093738, hgln: 0.222545, hglt: 5.546472 },
  { isoDate: "2013-07-30T00:00:00Z", dsun: 216.080714, hgln: 0.226889, hglt: 5.624597 },
  { isoDate: "2013-07-31T00:00:00Z", dsun: 216.066866, hgln: 0.23091, hglt: 5.701163 },
  { isoDate: "2013-08-01T00:00:00Z", dsun: 216.052183, hgln: 0.234608, hglt: 5.776149 },
  { isoDate: "2013-08-02T00:00:00Z", dsun: 216.036654, hgln: 0.237985, hglt: 5.849533 },
  { isoDate: "2013-08-03T00:00:00Z", dsun: 216.020265, hgln: 0.241043, hglt: 5.921293 },
  { isoDate: "2013-08-04T00:00:00Z", dsun: 216.003008, hgln: 0.243787, hglt: 5.991409 },
  { isoDate: "2013-08-05T00:00:00Z", dsun: 215.98487, hgln: 0.246225, hglt: 6.059858 },
  { isoDate: "2013-08-06T00:00:00Z", dsun: 215.965839, hgln: 0.248363, hglt: 6.126623 },
  { isoDate: "2013-08-07T00:00:00Z", dsun: 215.945903, hgln: 0.250212, hglt: 6.191682 },
  { isoDate: "2013-08-08T00:00:00Z", dsun: 215.92505, hgln: 0.251782, hglt: 6.255016 },
  { isoDate: "2013-08-09T00:00:00Z", dsun: 215.903268, hgln: 0.253086, hglt: 6.316607 },
  { isoDate: "2013-08-10T00:00:00Z", dsun: 215.880545, hgln: 0.254135, hglt: 6.376436 },
  { isoDate: "2013-08-11T00:00:00Z", dsun: 215.856872, hgln: 0.254942, hglt: 6.434485 },
  { isoDate: "2013-08-12T00:00:00Z", dsun: 215.832241, hgln: 0.25552, hglt: 6.490736 },
  { isoDate: "2013-08-13T00:00:00Z", dsun: 215.806642, hgln: 0.255877, hglt: 6.545173 },
  { isoDate: "2013-08-14T00:00:00Z", dsun: 215.780068, hgln: 0.25602, hglt: 6.597782 },
  { isoDate: "2013-08-15T00:00:00Z", dsun: 215.752518, hgln: 0.255957, hglt: 6.64854 },
  { isoDate: "2013-08-16T00:00:00Z", dsun: 215.723986, hgln: 0.255686, hglt: 6.697435 },
  { isoDate: "2013-08-17T00:00:00Z", dsun: 215.694467, hgln: 0.255204, hglt: 6.744451 },
  { isoDate: "2013-08-18T00:00:00Z", dsun: 215.663958, hgln: 0.254503, hglt: 6.789573 },
  { isoDate: "2013-08-19T00:00:00Z", dsun: 215.632458, hgln: 0.253567, hglt: 6.832787 },
  { isoDate: "2013-08-20T00:00:00Z", dsun: 215.599964, hgln: 0.252379, hglt: 6.87408 },
  { isoDate: "2013-08-21T00:00:00Z", dsun: 215.566476, hgln: 0.250917, hglt: 6.913436 },
  { isoDate: "2013-08-22T00:00:00Z", dsun: 215.531993, hgln: 0.24916, hglt: 6.950845 },
  { isoDate: "2013-08-23T00:00:00Z", dsun: 215.496516, hgln: 0.247087, hglt: 6.986292 },
  { isoDate: "2013-08-24T00:00:00Z", dsun: 215.460047, hgln: 0.244681, hglt: 7.019766 },
  { isoDate: "2013-08-25T00:00:00Z", dsun: 215.422588, hgln: 0.241926, hglt: 7.051255 },
  { isoDate: "2013-08-26T00:00:00Z", dsun: 215.384145, hgln: 0.238816, hglt: 7.080748 },
  { isoDate: "2013-08-27T00:00:00Z", dsun: 215.344722, hgln: 0.235343, hglt: 7.108235 },
  { isoDate: "2013-08-28T00:00:00Z", dsun: 215.304328, hgln: 0.231507, hglt: 7.133705 },
  { isoDate: "2013-08-29T00:00:00Z", dsun: 215.262972, hgln: 0.22731, hglt: 7.157148 },
  { isoDate: "2013-08-30T00:00:00Z", dsun: 215.220667, hgln: 0.222755, hglt: 7.178556 },
  { isoDate: "2013-08-31T00:00:00Z", dsun: 215.177425, hgln: 0.217849, hglt: 7.197918 },
  { isoDate: "2013-09-01T00:00:00Z", dsun: 215.133263, hgln: 0.212602, hglt: 7.215228 },
  { isoDate: "2013-09-02T00:00:00Z", dsun: 215.088197, hgln: 0.207023, hglt: 7.230476 },
  { isoDate: "2013-09-03T00:00:00Z", dsun: 215.042245, hgln: 0.201127, hglt: 7.243656 },
  { isoDate: "2013-09-04T00:00:00Z", dsun: 214.995426, hgln: 0.194928, hglt: 7.25476 },
  { isoDate: "2013-09-05T00:00:00Z", dsun: 214.947757, hgln: 0.188444, hglt: 7.263782 },
  { isoDate: "2013-09-06T00:00:00Z", dsun: 214.899257, hgln: 0.181694, hglt: 7.270717 },
  { isoDate: "2013-09-07T00:00:00Z", dsun: 214.849943, hgln: 0.1747, hglt: 7.275559 },
  { isoDate: "2013-09-08T00:00:00Z", dsun: 214.799835, hgln: 0.167485, hglt: 7.278303 },
  { isoDate: "2013-09-09T00:00:00Z", dsun: 214.748954, hgln: 0.160074, hglt: 7.278945 },
  { isoDate: "2013-09-10T00:00:00Z", dsun: 214.697324, hgln: 0.152488, hglt: 7.277482 },
  { isoDate: "2013-09-11T00:00:00Z", dsun: 214.644967, hgln: 0.14475, hglt: 7.273911 },
  { isoDate: "2013-09-12T00:00:00Z", dsun: 214.591912, hgln: 0.136879, hglt: 7.268227 },
  { isoDate: "2013-09-13T00:00:00Z", dsun: 214.538187, hgln: 0.12889, hglt: 7.260429 },
  { isoDate: "2013-09-14T00:00:00Z", dsun: 214.483821, hgln: 0.120796, hglt: 7.250515 },
  { isoDate: "2013-09-15T00:00:00Z", dsun: 214.428843, hgln: 0.112603, hglt: 7.238485 },
  { isoDate: "2013-09-16T00:00:00Z", dsun: 214.373283, hgln: 0.104314, hglt: 7.224337 },
  { isoDate: "2013-09-17T00:00:00Z", dsun: 214.31717, hgln: 0.095928, hglt: 7.208071 },
  { isoDate: "2013-09-18T00:00:00Z", dsun: 214.260535, hgln: 0.087438, hglt: 7.189688 },
  { isoDate: "2013-09-19T00:00:00Z", dsun: 214.203405, hgln: 0.078838, hglt: 7.169188 },
  { isoDate: "2013-09-20T00:00:00Z", dsun: 214.145809, hgln: 0.07012, hglt: 7.146575 },
  { isoDate: "2013-09-21T00:00:00Z", dsun: 214.087772, hgln: 0.061279, hglt: 7.121849 },
  { isoDate: "2013-09-22T00:00:00Z", dsun: 214.02932, hgln: 0.052309, hglt: 7.095015 },
  { isoDate: "2013-09-23T00:00:00Z", dsun: 213.970477, hgln: 0.043211, hglt: 7.066075 },
  { isoDate: "2013-09-24T00:00:00Z", dsun: 213.911264, hgln: 0.033986, hglt: 7.035035 },
  { isoDate: "2013-09-25T00:00:00Z", dsun: 213.851704, hgln: 0.024642, hglt: 7.001898 },
  { isoDate: "2013-09-26T00:00:00Z", dsun: 213.791816, hgln: 0.015188, hglt: 6.966671 },
  { isoDate: "2013-09-27T00:00:00Z", dsun: 213.731618, hgln: 0.005635, hglt: 6.929361 },
  { isoDate: "2013-09-28T00:00:00Z", dsun: 213.671127, hgln: -0.004001, hglt: 6.889973 },
  { isoDate: "2013-09-29T00:00:00Z", dsun: 213.610359, hgln: -0.013703, hglt: 6.848516 },
  { isoDate: "2013-09-30T00:00:00Z", dsun: 213.549331, hgln: -0.023453, hglt: 6.804997 },
  { isoDate: "2013-10-01T00:00:00Z", dsun: 213.488056, hgln: -0.033228, hglt: 6.759426 },
  { isoDate: "2013-10-02T00:00:00Z", dsun: 213.426549, hgln: -0.043005, hglt: 6.711812 },
  { isoDate: "2013-10-03T00:00:00Z", dsun: 213.364825, hgln: -0.052758, hglt: 6.662166 },
  { isoDate: "2013-10-04T00:00:00Z", dsun: 213.302896, hgln: -0.062461, hglt: 6.610498 },
  { isoDate: "2013-10-05T00:00:00Z", dsun: 213.240777, hgln: -0.072083, hglt: 6.55682 },
  { isoDate: "2013-10-06T00:00:00Z", dsun: 213.178476, hgln: -0.081593, hglt: 6.501144 },
  { isoDate: "2013-10-07T00:00:00Z", dsun: 213.116003, hgln: -0.090961, hglt: 6.443484 },
  { isoDate: "2013-10-08T00:00:00Z", dsun: 213.053361, hgln: -0.100156, hglt: 6.383853 },
  { isoDate: "2013-10-09T00:00:00Z", dsun: 212.990554, hgln: -0.109148, hglt: 6.322268 },
  { isoDate: "2013-10-10T00:00:00Z", dsun: 212.927582, hgln: -0.117911, hglt: 6.258743 },
  { isoDate: "2013-10-11T00:00:00Z", dsun: 212.864446, hgln: -0.126421, hglt: 6.193293 },
  { isoDate: "2013-10-12T00:00:00Z", dsun: 212.801151, hgln: -0.13466, hglt: 6.125937 },
  { isoDate: "2013-10-13T00:00:00Z", dsun: 212.737699, hgln: -0.142616, hglt: 6.056691 },
  { isoDate: "2013-10-14T00:00:00Z", dsun: 212.674096, hgln: -0.15028, hglt: 5.985572 },
  { isoDate: "2013-10-15T00:00:00Z", dsun: 212.610349, hgln: -0.157652, hglt: 5.912601 },
  { isoDate: "2013-10-16T00:00:00Z", dsun: 212.546465, hgln: -0.164731, hglt: 5.837794 },
  { isoDate: "2013-10-17T00:00:00Z", dsun: 212.482454, hgln: -0.171526, hglt: 5.761173 },
  { isoDate: "2013-10-18T00:00:00Z", dsun: 212.418326, hgln: -0.178044, hglt: 5.682758 },
  { isoDate: "2013-10-19T00:00:00Z", dsun: 212.35409, hgln: -0.184295, hglt: 5.60257 },
  { isoDate: "2013-10-20T00:00:00Z", dsun: 212.28976, hgln: -0.19029, hglt: 5.52063 },
  { isoDate: "2013-10-21T00:00:00Z", dsun: 212.225346, hgln: -0.196039, hglt: 5.43696 },
  { isoDate: "2013-10-22T00:00:00Z", dsun: 212.160863, hgln: -0.201551, hglt: 5.351585 },
  { isoDate: "2013-10-23T00:00:00Z", dsun: 212.096325, hgln: -0.206831, hglt: 5.264527 },
  { isoDate: "2013-10-24T00:00:00Z", dsun: 212.031746, hgln: -0.211886, hglt: 5.17581 },
  { isoDate: "2013-10-25T00:00:00Z", dsun: 211.967141, hgln: -0.216719, hglt: 5.08546 },
  { isoDate: "2013-10-26T00:00:00Z", dsun: 211.902529, hgln: -0.22133, hglt: 4.993501 },
  { isoDate: "2013-10-27T00:00:00Z", dsun: 211.837925, hgln: -0.22572, hglt: 4.899959 },
  { isoDate: "2013-10-28T00:00:00Z", dsun: 211.773351, hgln: -0.229887, hglt: 4.804861 },
  { isoDate: "2013-10-29T00:00:00Z", dsun: 211.710701, hgln: -0.233981, hglt: 4.708229 },
  { isoDate: "2013-10-30T00:00:00Z", dsun: 211.646339, hgln: -0.237697, hglt: 4.6101 },
  { isoDate: "2013-10-31T00:00:00Z", dsun: 211.582073, hgln: -0.241173, hglt: 4.510497 },
  { isoDate: "2013-11-01T00:00:00Z", dsun: 211.517927, hgln: -0.244396, hglt: 4.409449 },
  { isoDate: "2013-11-02T00:00:00Z", dsun: 211.453929, hgln: -0.247353, hglt: 4.306985 },
  { isoDate: "2013-11-03T00:00:00Z", dsun: 211.390107, hgln: -0.250027, hglt: 4.203134 },
  { isoDate: "2013-11-04T00:00:00Z", dsun: 211.326491, hgln: -0.252398, hglt: 4.097927 },
  { isoDate: "2013-11-05T00:00:00Z", dsun: 211.263112, hgln: -0.254444, hglt: 3.991395 },
  { isoDate: "2013-11-06T00:00:00Z", dsun: 211.200001, hgln: -0.256145, hglt: 3.883569 },
  { isoDate: "2013-11-07T00:00:00Z", dsun: 211.137191, hgln: -0.257482, hglt: 3.774481 },
  { isoDate: "2013-11-08T00:00:00Z", dsun: 211.074712, hgln: -0.258437, hglt: 3.664164 },
  { isoDate: "2013-11-09T00:00:00Z", dsun: 211.012594, hgln: -0.259, hglt: 3.552649 },
  { isoDate: "2013-11-10T00:00:00Z", dsun: 210.950869, hgln: -0.259164, hglt: 3.439972 },
  { isoDate: "2013-11-11T00:00:00Z", dsun: 210.889569, hgln: -0.258928, hglt: 3.326165 },
  { isoDate: "2013-11-12T00:00:00Z", dsun: 210.825251, hgln: -0.257982, hglt: 3.211258 },
  { isoDate: "2013-11-13T00:00:00Z", dsun: 210.768379, hgln: -0.257271, hglt: 3.0953 },
  { isoDate: "2013-11-14T00:00:00Z", dsun: 210.708556, hgln: -0.255869, hglt: 2.978312 },
  { isoDate: "2013-11-15T00:00:00Z", dsun: 210.649298, hgln: -0.254102, hglt: 2.860336 },
  { isoDate: "2013-11-16T00:00:00Z", dsun: 210.590639, hgln: -0.251987, hglt: 2.741407 },
  { isoDate: "2013-11-17T00:00:00Z", dsun: 210.532622, hgln: -0.249542, hglt: 2.621561 },
  { isoDate: "2013-11-18T00:00:00Z", dsun: 210.475283, hgln: -0.246783, hglt: 2.500837 },
  { isoDate: "2013-11-19T00:00:00Z", dsun: 210.418656, hgln: -0.24373, hglt: 2.379271 },
  { isoDate: "2013-11-20T00:00:00Z", dsun: 210.362776, hgln: -0.240399, hglt: 2.256902 },
  { isoDate: "2013-11-21T00:00:00Z", dsun: 210.307681, hgln: -0.236808, hglt: 2.133769 },
  { isoDate: "2013-11-22T00:00:00Z", dsun: 210.253407, hgln: -0.23297, hglt: 2.00991 },
  { isoDate: "2013-11-23T00:00:00Z", dsun: 210.199991, hgln: -0.228901, hglt: 1.885364 },
  { isoDate: "2013-11-24T00:00:00Z", dsun: 210.147466, hgln: -0.224612, hglt: 1.760172 },
  { isoDate: "2013-11-25T00:00:00Z", dsun: 210.09587, hgln: -0.220115, hglt: 1.634373 },
  { isoDate: "2013-11-26T00:00:00Z", dsun: 210.045236, hgln: -0.21542, hglt: 1.508008 },
  { isoDate: "2013-11-27T00:00:00Z", dsun: 209.995598, hgln: -0.210534, hglt: 1.381116 },
  { isoDate: "2013-11-28T00:00:00Z", dsun: 209.946992, hgln: -0.205462, hglt: 1.253741 },
  { isoDate: "2013-11-29T00:00:00Z", dsun: 209.89945, hgln: -0.200209, hglt: 1.125921 },
  { isoDate: "2013-11-30T00:00:00Z", dsun: 209.853006, hgln: -0.194773, hglt: 0.9977 },
  { isoDate: "2013-12-01T00:00:00Z", dsun: 209.807695, hgln: -0.189152, hglt: 0.86912 },
  { isoDate: "2013-12-02T00:00:00Z", dsun: 209.763549, hgln: -0.183336, hglt: 0.740221 },
  { isoDate: "2013-12-03T00:00:00Z", dsun: 209.720603, hgln: -0.177314, hglt: 0.611048 },
  { isoDate: "2013-12-04T00:00:00Z", dsun: 209.678888, hgln: -0.171073, hglt: 0.481642 },
  { isoDate: "2013-12-05T00:00:00Z", dsun: 209.638435, hgln: -0.164598, hglt: 0.352047 },
  { isoDate: "2013-12-06T00:00:00Z", dsun: 209.599275, hgln: -0.157876, hglt: 0.222306 },
  { isoDate: "2013-12-07T00:00:00Z", dsun: 209.561433, hgln: -0.150898, hglt: 0.092462 },
  { isoDate: "2013-12-08T00:00:00Z", dsun: 209.524937, hgln: -0.143658, hglt: -0.037442 },
  { isoDate: "2013-12-09T00:00:00Z", dsun: 209.489812, hgln: -0.136158, hglt: -0.167362 },
  { isoDate: "2013-12-10T00:00:00Z", dsun: 209.456081, hgln: -0.128404, hglt: -0.297254 },
  { isoDate: "2013-12-11T00:00:00Z", dsun: 209.423768, hgln: -0.120405, hglt: -0.427075 },
  { isoDate: "2013-12-12T00:00:00Z", dsun: 209.392898, hgln: -0.112178, hglt: -0.556781 },
  { isoDate: "2013-12-13T00:00:00Z", dsun: 209.363491, hgln: -0.103739, hglt: -0.686328 },
  { isoDate: "2013-12-14T00:00:00Z", dsun: 209.33557, hgln: -0.095109, hglt: -0.815672 },
  { isoDate: "2013-12-15T00:00:00Z", dsun: 209.309153, hgln: -0.08631, hglt: -0.94477 },
  { isoDate: "2013-12-16T00:00:00Z", dsun: 209.284261, hgln: -0.077365, hglt: -1.073576 },
  { isoDate: "2013-12-17T00:00:00Z", dsun: 209.260909, hgln: -0.0683, hglt: -1.202048 },
  { isoDate: "2013-12-18T00:00:00Z", dsun: 209.239114, hgln: -0.059139, hglt: -1.330142 },
  { isoDate: "2013-12-19T00:00:00Z", dsun: 209.218889, hgln: -0.049908, hglt: -1.457813 },
  { isoDate: "2013-12-20T00:00:00Z", dsun: 209.200246, hgln: -0.040629, hglt: -1.585018 },
  { isoDate: "2013-12-21T00:00:00Z", dsun: 209.183195, hgln: -0.031328, hglt: -1.711715 },
  { isoDate: "2013-12-22T00:00:00Z", dsun: 209.167744, hgln: -0.022027, hglt: -1.837858 },
  { isoDate: "2013-12-23T00:00:00Z", dsun: 209.153902, hgln: -0.012747, hglt: -1.963406 },
  { isoDate: "2013-12-24T00:00:00Z", dsun: 209.141671, hgln: -0.003509, hglt: -2.088315 },
  { isoDate: "2013-12-25T00:00:00Z", dsun: 209.131058, hgln: 0.005668, hglt: -2.212542 },
  { isoDate: "2013-12-26T00:00:00Z", dsun: 209.122063, hgln: 0.014768, hglt: -2.336047 },
  { isoDate: "2013-12-27T00:00:00Z", dsun: 209.114688, hgln: 0.023776, hglt: -2.458785 },
  { isoDate: "2013-12-28T00:00:00Z", dsun: 209.108933, hgln: 0.032681, hglt: -2.580715 },
  { isoDate: "2013-12-29T00:00:00Z", dsun: 209.104797, hgln: 0.041476, hglt: -2.701797 },
  { isoDate: "2013-12-30T00:00:00Z", dsun: 209.102279, hgln: 0.050158, hglt: -2.821987 },
  { isoDate: "2013-12-31T00:00:00Z", dsun: 209.101374, hgln: 0.058729, hglt: -2.941247 },
  { isoDate: "2014-01-01T00:00:00Z", dsun: 209.102086, hgln: 0.067198, hglt: -3.059529 },
  { isoDate: "2014-01-02T00:00:00Z", dsun: 209.104392, hgln: 0.075571, hglt: -3.176804 },
  { isoDate: "2014-01-03T00:00:00Z", dsun: 209.108289, hgln: 0.083862, hglt: -3.293027 },
  { isoDate: "2014-01-04T00:00:00Z", dsun: 209.113766, hgln: 0.092085, hglt: -3.40816 },
  { isoDate: "2014-01-05T00:00:00Z", dsun: 209.12081, hgln: 0.100246, hglt: -3.522163 },
  { isoDate: "2014-01-06T00:00:00Z", dsun: 209.129405, hgln: 0.108352, hglt: -3.634998 },
  { isoDate: "2014-01-07T00:00:00Z", dsun: 209.139545, hgln: 0.116398, hglt: -3.746627 },
  { isoDate: "2014-01-08T00:00:00Z", dsun: 209.151218, hgln: 0.12438, hglt: -3.857014 },
  { isoDate: "2014-01-09T00:00:00Z", dsun: 209.164395, hgln: 0.132288, hglt: -3.966122 },
  { isoDate: "2014-01-10T00:00:00Z", dsun: 209.179059, hgln: 0.140108, hglt: -4.073915 },
  { isoDate: "2014-01-11T00:00:00Z", dsun: 209.19519, hgln: 0.147826, hglt: -4.180357 },
  { isoDate: "2014-01-12T00:00:00Z", dsun: 209.21277, hgln: 0.155422, hglt: -4.285413 },
  { isoDate: "2014-01-13T00:00:00Z", dsun: 209.231776, hgln: 0.162878, hglt: -4.389049 },
  { isoDate: "2014-01-14T00:00:00Z", dsun: 209.252188, hgln: 0.170172, hglt: -4.491232 },
  { isoDate: "2014-01-15T00:00:00Z", dsun: 209.273981, hgln: 0.177283, hglt: -4.591928 },
  { isoDate: "2014-01-16T00:00:00Z", dsun: 209.297131, hgln: 0.184189, hglt: -4.691104 },
  { isoDate: "2014-01-17T00:00:00Z", dsun: 209.321613, hgln: 0.190868, hglt: -4.78873 },
  { isoDate: "2014-01-18T00:00:00Z", dsun: 209.3474, hgln: 0.197299, hglt: -4.884774 },
  { isoDate: "2014-01-19T00:00:00Z", dsun: 209.374465, hgln: 0.203459, hglt: -4.979207 },
  { isoDate: "2014-01-20T00:00:00Z", dsun: 209.402779, hgln: 0.209329, hglt: -5.071997 },
  { isoDate: "2014-01-21T00:00:00Z", dsun: 209.432313, hgln: 0.214889, hglt: -5.163117 },
  { isoDate: "2014-01-22T00:00:00Z", dsun: 209.463039, hgln: 0.220121, hglt: -5.252539 },
  { isoDate: "2014-01-23T00:00:00Z", dsun: 209.494926, hgln: 0.225009, hglt: -5.340234 },
  { isoDate: "2014-01-24T00:00:00Z", dsun: 209.527946, hgln: 0.22954, hglt: -5.426178 },
  { isoDate: "2014-01-25T00:00:00Z", dsun: 209.562068, hgln: 0.233703, hglt: -5.510342 },
  { isoDate: "2014-01-26T00:00:00Z", dsun: 209.597264, hgln: 0.23749, hglt: -5.592704 },
  { isoDate: "2014-01-27T00:00:00Z", dsun: 209.633505, hgln: 0.240898, hglt: -5.673237 },
  { isoDate: "2014-01-28T00:00:00Z", dsun: 209.670761, hgln: 0.243929, hglt: -5.751919 },
  { isoDate: "2014-01-29T00:00:00Z", dsun: 209.709003, hgln: 0.246592, hglt: -5.828726 },
  { isoDate: "2014-01-30T00:00:00Z", dsun: 209.7482, hgln: 0.248898, hglt: -5.903637 },
  { isoDate: "2014-01-31T00:00:00Z", dsun: 209.78832, hgln: 0.250867, hglt: -5.976631 },
  { isoDate: "2014-02-01T00:00:00Z", dsun: 209.829331, hgln: 0.252518, hglt: -6.047687 },
  { isoDate: "2014-02-02T00:00:00Z", dsun: 209.8712, hgln: 0.253872, hglt: -6.116785 },
  { isoDate: "2014-02-03T00:00:00Z", dsun: 209.913895, hgln: 0.254947, hglt: -6.183908 },
  { isoDate: "2014-02-04T00:00:00Z", dsun: 209.957385, hgln: 0.255756, hglt: -6.249037 },
  { isoDate: "2014-02-05T00:00:00Z", dsun: 210.001641, hgln: 0.256309, hglt: -6.312155 },
  { isoDate: "2014-02-06T00:00:00Z", dsun: 210.046632, hgln: 0.256609, hglt: -6.373246 },
  { isoDate: "2014-02-07T00:00:00Z", dsun: 210.092331, hgln: 0.256657, hglt: -6.432295 },
  { isoDate: "2014-02-08T00:00:00Z", dsun: 210.138709, hgln: 0.256451, hglt: -6.489287 },
  { isoDate: "2014-02-09T00:00:00Z", dsun: 210.185739, hgln: 0.255985, hglt: -6.544209 },
  { isoDate: "2014-02-10T00:00:00Z", dsun: 210.233393, hgln: 0.255251, hglt: -6.597048 },
  { isoDate: "2014-02-11T00:00:00Z", dsun: 210.281647, hgln: 0.254243, hglt: -6.64779 },
  { isoDate: "2014-02-12T00:00:00Z", dsun: 210.329902, hgln: 0.253166, hglt: -6.696439 },
  { isoDate: "2014-02-13T00:00:00Z", dsun: 210.379253, hgln: 0.251585, hglt: -6.742958 },
  { isoDate: "2014-02-14T00:00:00Z", dsun: 210.429124, hgln: 0.249697, hglt: -6.787351 },
  { isoDate: "2014-02-15T00:00:00Z", dsun: 210.479491, hgln: 0.24749, hglt: -6.829609 },
  { isoDate: "2014-02-16T00:00:00Z", dsun: 210.530331, hgln: 0.24495, hglt: -6.869725 },
  { isoDate: "2014-02-17T00:00:00Z", dsun: 210.581619, hgln: 0.242065, hglt: -6.90769 },
  { isoDate: "2014-02-18T00:00:00Z", dsun: 210.633335, hgln: 0.238822, hglt: -6.9435 },
  { isoDate: "2014-02-19T00:00:00Z", dsun: 210.685456, hgln: 0.235212, hglt: -6.977148 },
  { isoDate: "2014-02-20T00:00:00Z", dsun: 210.737962, hgln: 0.231223, hglt: -7.008631 },
  { isoDate: "2014-02-21T00:00:00Z", dsun: 210.790835, hgln: 0.226848, hglt: -7.037945 },
  { isoDate: "2014-02-22T00:00:00Z", dsun: 210.844059, hgln: 0.222082, hglt: -7.065087 },
  { isoDate: "2014-02-23T00:00:00Z", dsun: 210.897617, hgln: 0.216922, hglt: -7.090055 },
  { isoDate: "2014-02-24T00:00:00Z", dsun: 210.951497, hgln: 0.21137, hglt: -7.112847 },
  { isoDate: "2014-02-25T00:00:00Z", dsun: 211.005688, hgln: 0.205434, hglt: -7.133463 },
  { isoDate: "2014-02-26T00:00:00Z", dsun: 211.06018, hgln: 0.199125, hglt: -7.151904 },
  { isoDate: "2014-02-27T00:00:00Z", dsun: 211.114965, hgln: 0.192462, hglt: -7.16817 },
  { isoDate: "2014-02-28T00:00:00Z", dsun: 211.170036, hgln: 0.185466, hglt: -7.182263 },
  { isoDate: "2014-03-01T00:00:00Z", dsun: 211.225383, hgln: 0.178167, hglt: -7.194187 },
  { isoDate: "2014-03-02T00:00:00Z", dsun: 211.280997, hgln: 0.170594, hglt: -7.203943 },
  { isoDate: "2014-03-03T00:00:00Z", dsun: 211.336868, hgln: 0.162778, hglt: -7.211536 },
  { isoDate: "2014-03-04T00:00:00Z", dsun: 211.392986, hgln: 0.154749, hglt: -7.216971 },
  { isoDate: "2014-03-05T00:00:00Z", dsun: 211.449345, hgln: 0.146534, hglt: -7.220252 },
  { isoDate: "2014-03-06T00:00:00Z", dsun: 211.505937, hgln: 0.138156, hglt: -7.221386 },
  { isoDate: "2014-03-07T00:00:00Z", dsun: 211.562759, hgln: 0.129633, hglt: -7.220379 },
  { isoDate: "2014-03-08T00:00:00Z", dsun: 211.619807, hgln: 0.120983, hglt: -7.217238 },
  { isoDate: "2014-03-09T00:00:00Z", dsun: 211.677077, hgln: 0.112219, hglt: -7.21197 },
  { isoDate: "2014-03-10T00:00:00Z", dsun: 211.734569, hgln: 0.103352, hglt: -7.204584 },
  { isoDate: "2014-03-11T00:00:00Z", dsun: 211.79228, hgln: 0.094392, hglt: -7.195088 },
  { isoDate: "2014-03-12T00:00:00Z", dsun: 211.850204, hgln: 0.085348, hglt: -7.183492 },
  { isoDate: "2014-03-13T00:00:00Z", dsun: 211.908343, hgln: 0.076225, hglt: -7.169804 },
  { isoDate: "2014-03-14T00:00:00Z", dsun: 211.966691, hgln: 0.067029, hglt: -7.154034 },
  { isoDate: "2014-03-15T00:00:00Z", dsun: 212.025241, hgln: 0.057764, hglt: -7.136193 },
  { isoDate: "2014-03-16T00:00:00Z", dsun: 212.083987, hgln: 0.048435, hglt: -7.116292 },
  { isoDate: "2014-03-17T00:00:00Z", dsun: 212.14292, hgln: 0.039041, hglt: -7.094341 },
  { isoDate: "2014-03-18T00:00:00Z", dsun: 212.202029, hgln: 0.029585, hglt: -7.070352 },
  { isoDate: "2014-03-19T00:00:00Z", dsun: 212.261302, hgln: 0.020067, hglt: -7.044336 },
  { isoDate: "2014-03-20T00:00:00Z", dsun: 212.320723, hgln: 0.010489, hglt: -7.016306 },
  { isoDate: "2014-03-21T00:00:00Z", dsun: 212.380276, hgln: 0.00085, hglt: -6.986273 },
  { isoDate: "2014-03-22T00:00:00Z", dsun: 212.439943, hgln: -0.008844, hglt: -6.954249 },
  { isoDate: "2014-03-23T00:00:00Z", dsun: 212.499702, hgln: -0.018587, hglt: -6.920248 },
  { isoDate: "2014-03-24T00:00:00Z", dsun: 212.559532, hgln: -0.028372, hglt: -6.884283 },
  { isoDate: "2014-03-25T00:00:00Z", dsun: 212.619409, hgln: -0.038184, hglt: -6.846365 },
  { isoDate: "2014-03-26T00:00:00Z", dsun: 212.679309, hgln: -0.048005, hglt: -6.80651 },
  { isoDate: "2014-03-27T00:00:00Z", dsun: 212.739207, hgln: -0.057815, hglt: -6.764729 },
  { isoDate: "2014-03-28T00:00:00Z", dsun: 212.799079, hgln: -0.067584, hglt: -6.721038 },
  { isoDate: "2014-03-29T00:00:00Z", dsun: 212.858901, hgln: -0.077283, hglt: -6.675451 },
  { isoDate: "2014-03-30T00:00:00Z", dsun: 212.918647, hgln: -0.086878, hglt: -6.627982 },
  { isoDate: "2014-03-31T00:00:00Z", dsun: 212.978292, hgln: -0.096334, hglt: -6.578646 },
  { isoDate: "2014-04-01T00:00:00Z", dsun: 213.03781, hgln: -0.105619, hglt: -6.527459 },
  { isoDate: "2014-04-02T00:00:00Z", dsun: 213.097172, hgln: -0.114701, hglt: -6.474437 },
  { isoDate: "2014-04-03T00:00:00Z", dsun: 213.156346, hgln: -0.123553, hglt: -6.419595 },
  { isoDate: "2014-04-04T00:00:00Z", dsun: 213.2153, hgln: -0.132152, hglt: -6.362949 },
  { isoDate: "2014-04-05T00:00:00Z", dsun: 213.273998, hgln: -0.140479, hglt: -6.304517 },
  { isoDate: "2014-04-06T00:00:00Z", dsun: 213.332407, hgln: -0.148515, hglt: -6.244314 },
  { isoDate: "2014-04-07T00:00:00Z", dsun: 213.390496, hgln: -0.15625, hglt: -6.182359 },
  { isoDate: "2014-04-08T00:00:00Z", dsun: 213.448233, hgln: -0.163672, hglt: -6.118668 },
  { isoDate: "2014-04-09T00:00:00Z", dsun: 213.505653, hgln: -0.170781, hglt: -6.053263 },
  { isoDate: "2014-04-10T00:00:00Z", dsun: 213.562608, hgln: -0.177559, hglt: -5.986157 },
  { isoDate: "2014-04-11T00:00:00Z", dsun: 213.61913, hgln: -0.184011, hglt: -5.917372 },
  { isoDate: "2014-04-12T00:00:00Z", dsun: 213.675194, hgln: -0.190137, hglt: -5.846928 },
  { isoDate: "2014-04-13T00:00:00Z", dsun: 213.730778, hgln: -0.195941, hglt: -5.774843 },
  { isoDate: "2014-04-14T00:00:00Z", dsun: 213.78586, hgln: -0.201429, hglt: -5.701138 },
  { isoDate: "2014-04-15T00:00:00Z", dsun: 213.840419, hgln: -0.20661, hglt: -5.625835 },
  { isoDate: "2014-04-16T00:00:00Z", dsun: 213.894437, hgln: -0.211496, hglt: -5.548955 },
  { isoDate: "2014-04-17T00:00:00Z", dsun: 213.947895, hgln: -0.2161, hglt: -5.470518 },
  { isoDate: "2014-04-18T00:00:00Z", dsun: 214.000776, hgln: -0.220437, hglt: -5.390547 },
  { isoDate: "2014-04-19T00:00:00Z", dsun: 214.053063, hgln: -0.224522, hglt: -5.309065 },
  { isoDate: "2014-04-20T00:00:00Z", dsun: 214.104742, hgln: -0.228367, hglt: -5.226095 },
  { isoDate: "2014-04-21T00:00:00Z", dsun: 214.155797, hgln: -0.231982, hglt: -5.141659 },
  { isoDate: "2014-04-22T00:00:00Z", dsun: 214.206216, hgln: -0.235375, hglt: -5.055782 },
  { isoDate: "2014-04-23T00:00:00Z", dsun: 214.255986, hgln: -0.238548, hglt: -4.968488 },
  { isoDate: "2014-04-24T00:00:00Z", dsun: 214.305096, hgln: -0.2415, hglt: -4.879801 },
  { isoDate: "2014-04-25T00:00:00Z", dsun: 214.353537, hgln: -0.244224, hglt: -4.789745 },
  { isoDate: "2014-04-26T00:00:00Z", dsun: 214.401301, hgln: -0.246709, hglt: -4.698347 },
  { isoDate: "2014-04-27T00:00:00Z", dsun: 214.448381, hgln: -0.248941, hglt: -4.605631 },
  { isoDate: "2014-04-28T00:00:00Z", dsun: 214.494773, hgln: -0.250904, hglt: -4.511624 },
  { isoDate: "2014-04-29T00:00:00Z", dsun: 214.540474, hgln: -0.252579, hglt: -4.416352 },
  { isoDate: "2014-04-30T00:00:00Z", dsun: 214.585483, hgln: -0.253949, hglt: -4.319842 },
  { isoDate: "2014-05-01T00:00:00Z", dsun: 214.629801, hgln: -0.254998, hglt: -4.22212 },
  { isoDate: "2014-05-02T00:00:00Z", dsun: 214.673427, hgln: -0.255713, hglt: -4.123214 },
  { isoDate: "2014-05-03T00:00:00Z", dsun: 214.716362, hgln: -0.256081, hglt: -4.023152 },
  { isoDate: "2014-05-04T00:00:00Z", dsun: 214.758606, hgln: -0.256095, hglt: -3.921961 },
  { isoDate: "2014-05-05T00:00:00Z", dsun: 214.800159, hgln: -0.255748, hglt: -3.819671 },
  { isoDate: "2014-05-06T00:00:00Z", dsun: 214.841022, hgln: -0.255039, hglt: -3.716309 },
  { isoDate: "2014-05-07T00:00:00Z", dsun: 214.881196, hgln: -0.253964, hglt: -3.611904 },
  { isoDate: "2014-05-08T00:00:00Z", dsun: 214.920684, hgln: -0.252526, hglt: -3.506486 },
  { isoDate: "2014-05-09T00:00:00Z", dsun: 214.959488, hgln: -0.250727, hglt: -3.400084 },
  { isoDate: "2014-05-10T00:00:00Z", dsun: 214.997614, hgln: -0.248574, hglt: -3.292726 },
  { isoDate: "2014-05-11T00:00:00Z", dsun: 215.035067, hgln: -0.246073, hglt: -3.184445 },
  { isoDate: "2014-05-12T00:00:00Z", dsun: 215.071852, hgln: -0.243237, hglt: -3.075268 },
  { isoDate: "2014-05-13T00:00:00Z", dsun: 215.107978, hgln: -0.240079, hglt: -2.965227 },
  { isoDate: "2014-05-14T00:00:00Z", dsun: 215.143443, hgln: -0.236613, hglt: -2.854349 },
  { isoDate: "2014-05-15T00:00:00Z", dsun: 215.178272, hgln: -0.232867, hglt: -2.742672 },
  { isoDate: "2014-05-16T00:00:00Z", dsun: 215.212463, hgln: -0.228862, hglt: -2.630222 },
  { isoDate: "2014-05-17T00:00:00Z", dsun: 215.246026, hgln: -0.22462, hglt: -2.517031 },
  { isoDate: "2014-05-18T00:00:00Z", dsun: 215.278968, hgln: -0.220166, hglt: -2.403132 },
  { isoDate: "2014-05-19T00:00:00Z", dsun: 215.311298, hgln: -0.215523, hglt: -2.288554 },
  { isoDate: "2014-05-20T00:00:00Z", dsun: 215.343023, hgln: -0.210709, hglt: -2.173331 },
  { isoDate: "2014-05-21T00:00:00Z", dsun: 215.37415, hgln: -0.205738, hglt: -2.057494 },
  { isoDate: "2014-05-22T00:00:00Z", dsun: 215.404688, hgln: -0.200622, hglt: -1.941075 },
  { isoDate: "2014-05-23T00:00:00Z", dsun: 215.434644, hgln: -0.195365, hglt: -1.824107 },
  { isoDate: "2014-05-24T00:00:00Z", dsun: 215.464026, hgln: -0.189968, hglt: -1.706622 },
  { isoDate: "2014-05-25T00:00:00Z", dsun: 215.492841, hgln: -0.184429, hglt: -1.588653 },
  { isoDate: "2014-05-26T00:00:00Z", dsun: 215.521099, hgln: -0.178741, hglt: -1.470231 },
  { isoDate: "2014-05-27T00:00:00Z", dsun: 215.548808, hgln: -0.172896, hglt: -1.351391 },
  { isoDate: "2014-05-28T00:00:00Z", dsun: 215.575977, hgln: -0.166887, hglt: -1.232165 },
  { isoDate: "2014-05-29T00:00:00Z", dsun: 215.602615, hgln: -0.160704, hglt: -1.112585 },
  { isoDate: "2014-05-30T00:00:00Z", dsun: 215.628733, hgln: -0.154339, hglt: -0.992686 },
  { isoDate: "2014-05-31T00:00:00Z", dsun: 215.654336, hgln: -0.147785, hglt: -0.8725 },
  { isoDate: "2014-06-01T00:00:00Z", dsun: 215.679433, hgln: -0.141037, hglt: -0.75206 },
  { isoDate: "2014-06-02T00:00:00Z", dsun: 215.704029, hgln: -0.134092, hglt: -0.631401 },
  { isoDate: "2014-06-03T00:00:00Z", dsun: 215.728129, hgln: -0.126948, hglt: -0.510556 },
  { isoDate: "2014-06-04T00:00:00Z", dsun: 215.751737, hgln: -0.119606, hglt: -0.389558 },
  { isoDate: "2014-06-05T00:00:00Z", dsun: 215.774857, hgln: -0.112068, hglt: -0.268441 },
  { isoDate: "2014-06-06T00:00:00Z", dsun: 215.797494, hgln: -0.104337, hglt: -0.147238 },
  { isoDate: "2014-06-07T00:00:00Z", dsun: 215.819651, hgln: -0.09642, hglt: -0.025984 },
  { isoDate: "2014-06-08T00:00:00Z", dsun: 215.841332, hgln: -0.088326, hglt: 0.095289 },
  { isoDate: "2014-06-09T00:00:00Z", dsun: 215.862541, hgln: -0.080065, hglt: 0.216546 },
  { isoDate: "2014-06-10T00:00:00Z", dsun: 215.883281, hgln: -0.071653, hglt: 0.337755 },
  { isoDate: "2014-06-11T00:00:00Z", dsun: 215.903552, hgln: -0.063104, hglt: 0.458883 },
  { isoDate: "2014-06-12T00:00:00Z", dsun: 215.923362, hgln: -0.054447, hglt: 0.579892 },
  { isoDate: "2014-06-13T00:00:00Z", dsun: 215.942709, hgln: -0.045705, hglt: 0.700752 },
  { isoDate: "2014-06-14T00:00:00Z", dsun: 215.961594, hgln: -0.036908, hglt: 0.821428 },
  { isoDate: "2014-06-15T00:00:00Z", dsun: 215.980015, hgln: -0.028086, hglt: 0.941886 },
  { isoDate: "2014-06-16T00:00:00Z", dsun: 215.997972, hgln: -0.01927, hglt: 1.062094 },
  { isoDate: "2014-06-17T00:00:00Z", dsun: 216.015462, hgln: -0.010488, hglt: 1.182018 },
  { isoDate: "2014-06-18T00:00:00Z", dsun: 216.032481, hgln: -0.001763, hglt: 1.301625 },
  { isoDate: "2014-06-19T00:00:00Z", dsun: 216.049026, hgln: 0.006885, hglt: 1.42088 },
  { isoDate: "2014-06-20T00:00:00Z", dsun: 216.065092, hgln: 0.015443, hglt: 1.539752 },
  { isoDate: "2014-06-21T00:00:00Z", dsun: 216.080673, hgln: 0.023902, hglt: 1.658206 },
  { isoDate: "2014-06-22T00:00:00Z", dsun: 216.095764, hgln: 0.032257, hglt: 1.776211 },
  { isoDate: "2014-06-23T00:00:00Z", dsun: 216.110359, hgln: 0.040508, hglt: 1.893732 },
  { isoDate: "2014-06-24T00:00:00Z", dsun: 216.124451, hgln: 0.048656, hglt: 2.010738 },
  { isoDate: "2014-06-25T00:00:00Z", dsun: 216.138028, hgln: 0.056703, hglt: 2.127196 },
  { isoDate: "2014-06-26T00:00:00Z", dsun: 216.151092, hgln: 0.064656, hglt: 2.243074 },
  { isoDate: "2014-06-27T00:00:00Z", dsun: 216.163633, hgln: 0.072518, hglt: 2.358338 },
  { isoDate: "2014-06-28T00:00:00Z", dsun: 216.175642, hgln: 0.080294, hglt: 2.472958 },
  { isoDate: "2014-06-29T00:00:00Z", dsun: 216.187109, hgln: 0.08799, hglt: 2.586901 },
  { isoDate: "2014-06-30T00:00:00Z", dsun: 216.198022, hgln: 0.09561, hglt: 2.700135 },
  { isoDate: "2014-07-01T00:00:00Z", dsun: 216.208367, hgln: 0.103157, hglt: 2.812629 },
  { isoDate: "2014-07-02T00:00:00Z", dsun: 216.218132, hgln: 0.110635, hglt: 2.924351 },
  { isoDate: "2014-07-03T00:00:00Z", dsun: 216.227303, hgln: 0.118044, hglt: 3.035271 },
  { isoDate: "2014-07-04T00:00:00Z", dsun: 216.235868, hgln: 0.125385, hglt: 3.145357 },
  { isoDate: "2014-07-05T00:00:00Z", dsun: 216.243812, hgln: 0.132657, hglt: 3.254578 },
  { isoDate: "2014-07-06T00:00:00Z", dsun: 216.251122, hgln: 0.139856, hglt: 3.362906 },
  { isoDate: "2014-07-07T00:00:00Z", dsun: 216.257785, hgln: 0.146977, hglt: 3.470308 },
  { isoDate: "2014-07-08T00:00:00Z", dsun: 216.263787, hgln: 0.154012, hglt: 3.576755 },
  { isoDate: "2014-07-09T00:00:00Z", dsun: 216.269117, hgln: 0.160951, hglt: 3.682218 },
  { isoDate: "2014-07-10T00:00:00Z", dsun: 216.273754, hgln: 0.167776, hglt: 3.786668 },
  { isoDate: "2014-07-11T00:00:00Z", dsun: 216.277687, hgln: 0.174467, hglt: 3.890074 },
  { isoDate: "2014-07-12T00:00:00Z", dsun: 216.280898, hgln: 0.181001, hglt: 3.992409 },
  { isoDate: "2014-07-13T00:00:00Z", dsun: 216.283373, hgln: 0.18735, hglt: 4.093643 },
  { isoDate: "2014-07-14T00:00:00Z", dsun: 216.285095, hgln: 0.193483, hglt: 4.193749 },
  { isoDate: "2014-07-15T00:00:00Z", dsun: 216.286047, hgln: 0.199372, hglt: 4.292698 },
  { isoDate: "2014-07-16T00:00:00Z", dsun: 216.286212, hgln: 0.204991, hglt: 4.390462 },
  { isoDate: "2014-07-17T00:00:00Z", dsun: 216.285573, hgln: 0.210319, hglt: 4.487015 },
  { isoDate: "2014-07-18T00:00:00Z", dsun: 216.284113, hgln: 0.215341, hglt: 4.58233 },
  { isoDate: "2014-07-19T00:00:00Z", dsun: 216.281817, hgln: 0.220048, hglt: 4.676379 },
  { isoDate: "2014-07-20T00:00:00Z", dsun: 216.278669, hgln: 0.224433, hglt: 4.769136 },
  { isoDate: "2014-07-21T00:00:00Z", dsun: 216.274653, hgln: 0.228497, hglt: 4.860576 },
  { isoDate: "2014-07-22T00:00:00Z", dsun: 216.269755, hgln: 0.23224, hglt: 4.950672 },
  { isoDate: "2014-07-23T00:00:00Z", dsun: 216.26396, hgln: 0.235669, hglt: 5.0394 },
  { isoDate: "2014-07-24T00:00:00Z", dsun: 216.257256, hgln: 0.238788, hglt: 5.126733 },
  { isoDate: "2014-07-25T00:00:00Z", dsun: 216.249628, hgln: 0.241607, hglt: 5.212649 },
  { isoDate: "2014-07-26T00:00:00Z", dsun: 216.24106, hgln: 0.244133, hglt: 5.297121 },
  { isoDate: "2014-07-27T00:00:00Z", dsun: 216.231538, hgln: 0.246376, hglt: 5.380127 },
  { isoDate: "2014-07-28T00:00:00Z", dsun: 216.221047, hgln: 0.248347, hglt: 5.461643 },
  { isoDate: "2014-07-29T00:00:00Z", dsun: 216.209571, hgln: 0.250056, hglt: 5.541645 },
  { isoDate: "2014-07-30T00:00:00Z", dsun: 216.197097, hgln: 0.251513, hglt: 5.62011 },
  { isoDate: "2014-07-31T00:00:00Z", dsun: 216.18361, hgln: 0.252728, hglt: 5.697017 },
  { isoDate: "2014-08-01T00:00:00Z", dsun: 216.169099, hgln: 0.253711, hglt: 5.772344 },
  { isoDate: "2014-08-02T00:00:00Z", dsun: 216.153553, hgln: 0.25447, hglt: 5.846068 },
  { isoDate: "2014-08-03T00:00:00Z", dsun: 216.136961, hgln: 0.255011, hglt: 5.918168 },
  { isoDate: "2014-08-04T00:00:00Z", dsun: 216.119316, hgln: 0.255339, hglt: 5.988624 },
  { isoDate: "2014-08-05T00:00:00Z", dsun: 216.100609, hgln: 0.255458, hglt: 6.057414 },
  { isoDate: "2014-08-06T00:00:00Z", dsun: 216.080832, hgln: 0.255367, hglt: 6.12452 },
  { isoDate: "2014-08-07T00:00:00Z", dsun: 216.05998, hgln: 0.255063, hglt: 6.18992 },
  { isoDate: "2014-08-08T00:00:00Z", dsun: 216.038046, hgln: 0.254536, hglt: 6.253597 },
  { isoDate: "2014-08-09T00:00:00Z", dsun: 216.015026, hgln: 0.253774, hglt: 6.31553 },
  { isoDate: "2014-08-10T00:00:00Z", dsun: 215.990914, hgln: 0.252757, hglt: 6.375701 },
  { isoDate: "2014-08-11T00:00:00Z", dsun: 215.965708, hgln: 0.251464, hglt: 6.434093 },
  { isoDate: "2014-08-12T00:00:00Z", dsun: 215.939403, hgln: 0.249871, hglt: 6.490687 },
  { isoDate: "2014-08-13T00:00:00Z", dsun: 215.911994, hgln: 0.247954, hglt: 6.545466 },
  { isoDate: "2014-08-14T00:00:00Z", dsun: 215.883488, hgln: 0.245696, hglt: 6.598414 },
  { isoDate: "2014-08-15T00:00:00Z", dsun: 215.853882, hgln: 0.243081, hglt: 6.649514 },
  { isoDate: "2014-08-16T00:00:00Z", dsun: 215.823177, hgln: 0.2401, hglt: 6.69875 },
  { isoDate: "2014-08-17T00:00:00Z", dsun: 215.791377, hgln: 0.236749, hglt: 6.746106 },
  { isoDate: "2014-08-18T00:00:00Z", dsun: 215.758487, hgln: 0.23303, hglt: 6.791568 },
  { isoDate: "2014-08-19T00:00:00Z", dsun: 215.724515, hgln: 0.228947, hglt: 6.83512 },
  { isoDate: "2014-08-20T00:00:00Z", dsun: 215.68947, hgln: 0.224508, hglt: 6.876748 },
  { isoDate: "2014-08-21T00:00:00Z", dsun: 215.653364, hgln: 0.219723, hglt: 6.916439 },
  { isoDate: "2014-08-22T00:00:00Z", dsun: 215.61621, hgln: 0.214604, hglt: 6.954178 },
  { isoDate: "2014-08-23T00:00:00Z", dsun: 215.578022, hgln: 0.209165, hglt: 6.989952 },
  { isoDate: "2014-08-24T00:00:00Z", dsun: 215.538813, hgln: 0.20342, hglt: 7.02375 },
  { isoDate: "2014-08-25T00:00:00Z", dsun: 215.498597, hgln: 0.197385, hglt: 7.055558 },
  { isoDate: "2014-08-26T00:00:00Z", dsun: 215.45739, hgln: 0.191079, hglt: 7.085365 },
  { isoDate: "2014-08-27T00:00:00Z", dsun: 215.415204, hgln: 0.184519, hglt: 7.113161 },
  { isoDate: "2014-08-28T00:00:00Z", dsun: 215.372057, hgln: 0.177725, hglt: 7.138934 },
  { isoDate: "2014-08-29T00:00:00Z", dsun: 215.327965, hgln: 0.170718, hglt: 7.162674 },
  { isoDate: "2014-08-30T00:00:00Z", dsun: 215.282948, hgln: 0.163516, hglt: 7.184372 },
  { isoDate: "2014-08-31T00:00:00Z", dsun: 215.237026, hgln: 0.15614, hglt: 7.204018 },
  { isoDate: "2014-09-01T00:00:00Z", dsun: 215.190225, hgln: 0.148606, hglt: 7.221605 },
  { isoDate: "2014-09-02T00:00:00Z", dsun: 215.142567, hgln: 0.140934, hglt: 7.237122 },
  { isoDate: "2014-09-03T00:00:00Z", dsun: 215.094079, hgln: 0.133136, hglt: 7.250563 },
  { isoDate: "2014-09-04T00:00:00Z", dsun: 215.044789, hgln: 0.125225, hglt: 7.26192 },
  { isoDate: "2014-09-05T00:00:00Z", dsun: 214.994723, hgln: 0.117208, hglt: 7.271187 },
  { isoDate: "2014-09-06T00:00:00Z", dsun: 214.943911, hgln: 0.109088, hglt: 7.278356 },
  { isoDate: "2014-09-07T00:00:00Z", dsun: 214.892379, hgln: 0.100864, hglt: 7.283423 },
  { isoDate: "2014-09-08T00:00:00Z", dsun: 214.840156, hgln: 0.092529, hglt: 7.286382 },
  { isoDate: "2014-09-09T00:00:00Z", dsun: 214.787268, hgln: 0.084074, hglt: 7.287228 },
  { isoDate: "2014-09-10T00:00:00Z", dsun: 214.733742, hgln: 0.075486, hglt: 7.28596 },
  { isoDate: "2014-09-11T00:00:00Z", dsun: 214.679603, hgln: 0.066756, hglt: 7.28257 },
  { isoDate: "2014-09-12T00:00:00Z", dsun: 214.624874, hgln: 0.057877, hglt: 7.277057 },
  { isoDate: "2014-09-13T00:00:00Z", dsun: 214.56958, hgln: 0.048847, hglt: 7.269419 },
  { isoDate: "2014-09-14T00:00:00Z", dsun: 214.513741, hgln: 0.039668, hglt: 7.259655 },
  { isoDate: "2014-09-15T00:00:00Z", dsun: 214.457378, hgln: 0.030348, hglt: 7.247763 },
  { isoDate: "2014-09-16T00:00:00Z", dsun: 214.400511, hgln: 0.020901, hglt: 7.233744 },
  { isoDate: "2014-09-17T00:00:00Z", dsun: 214.343159, hgln: 0.011341, hglt: 7.217598 },
  { isoDate: "2014-09-18T00:00:00Z", dsun: 214.285339, hgln: 0.001688, hglt: 7.199325 },
  { isoDate: "2014-09-19T00:00:00Z", dsun: 214.227068, hgln: -0.008037, hglt: 7.178927 },
  { isoDate: "2014-09-20T00:00:00Z", dsun: 214.168364, hgln: -0.017812, hglt: 7.156406 },
  { isoDate: "2014-09-21T00:00:00Z", dsun: 214.109243, hgln: -0.027611, hglt: 7.131766 },
  { isoDate: "2014-09-22T00:00:00Z", dsun: 214.04972, hgln: -0.037409, hglt: 7.105008 },
  { isoDate: "2014-09-23T00:00:00Z", dsun: 213.989809, hgln: -0.047179, hglt: 7.076138 },
  { isoDate: "2014-09-24T00:00:00Z", dsun: 213.929522, hgln: -0.056894, hglt: 7.045161 },
  { isoDate: "2014-09-25T00:00:00Z", dsun: 213.868875, hgln: -0.066525, hglt: 7.012081 },
  { isoDate: "2014-09-26T00:00:00Z", dsun: 213.807878, hgln: -0.076046, hglt: 6.976905 },
  { isoDate: "2014-09-27T00:00:00Z", dsun: 213.746537, hgln: -0.085427, hglt: 6.939641 },
  { isoDate: "2014-09-28T00:00:00Z", dsun: 213.684855, hgln: -0.094643, hglt: 6.900297 },
  { isoDate: "2014-09-29T00:00:00Z", dsun: 213.622832, hgln: -0.103666, hglt: 6.85888 },
  { isoDate: "2014-09-30T00:00:00Z", dsun: 213.560472, hgln: -0.112472, hglt: 6.8154 },
  { isoDate: "2014-10-01T00:00:00Z", dsun: 213.497775, hgln: -0.121039, hglt: 6.769868 },
  { isoDate: "2014-10-02T00:00:00Z", dsun: 213.434743, hgln: -0.129348, hglt: 6.722293 },
  { isoDate: "2014-10-03T00:00:00Z", dsun: 213.371382, hgln: -0.137384, hglt: 6.672686 },
  { isoDate: "2014-10-04T00:00:00Z", dsun: 213.307697, hgln: -0.145137, hglt: 6.621058 },
  { isoDate: "2014-10-05T00:00:00Z", dsun: 213.243693, hgln: -0.152604, hglt: 6.567423 },
  { isoDate: "2014-10-06T00:00:00Z", dsun: 213.179379, hgln: -0.159787, hglt: 6.511793 },
  { isoDate: "2014-10-07T00:00:00Z", dsun: 213.114763, hgln: -0.166692, hglt: 6.454181 },
  { isoDate: "2014-10-08T00:00:00Z", dsun: 213.049853, hgln: -0.173331, hglt: 6.394601 },
  { isoDate: "2014-10-09T00:00:00Z", dsun: 212.984662, hgln: -0.179719, hglt: 6.333069 },
  { isoDate: "2014-10-10T00:00:00Z", dsun: 212.919198, hgln: -0.185868, hglt: 6.2696 },
  { isoDate: "2014-10-11T00:00:00Z", dsun: 212.853474, hgln: -0.191793, hglt: 6.204209 },
  { isoDate: "2014-10-12T00:00:00Z", dsun: 212.787501, hgln: -0.197503, hglt: 6.136915 },
  { isoDate: "2014-10-13T00:00:00Z", dsun: 212.721293, hgln: -0.203003, hglt: 6.067733 },
  { isoDate: "2014-10-14T00:00:00Z", dsun: 212.654863, hgln: -0.208297, hglt: 5.996682 },
  { isoDate: "2014-10-15T00:00:00Z", dsun: 212.588249, hgln: -0.213389, hglt: 5.923776 },
  { isoDate: "2014-10-16T00:00:00Z", dsun: 212.521423, hgln: -0.218269, hglt: 5.849043 },
  { isoDate: "2014-10-17T00:00:00Z", dsun: 212.454422, hgln: -0.222935, hglt: 5.772498 },
  { isoDate: "2014-10-18T00:00:00Z", dsun: 212.387266, hgln: -0.227381, hglt: 5.694163 },
  { isoDate: "2014-10-19T00:00:00Z", dsun: 212.319974, hgln: -0.231598, hglt: 5.614057 },
  { isoDate: "2014-10-20T00:00:00Z", dsun: 212.252568, hgln: -0.235576, hglt: 5.532202 },
  { isoDate: "2014-10-21T00:00:00Z", dsun: 212.185071, hgln: -0.239304, hglt: 5.44862 },
  { isoDate: "2014-10-22T00:00:00Z", dsun: 212.117507, hgln: -0.242768, hglt: 5.363334 },
  { isoDate: "2014-10-23T00:00:00Z", dsun: 212.049905, hgln: -0.245954, hglt: 5.276367 },
  { isoDate: "2014-10-24T00:00:00Z", dsun: 211.982292, hgln: -0.248844, hglt: 5.187743 },
  { isoDate: "2014-10-25T00:00:00Z", dsun: 211.914699, hgln: -0.251423, hglt: 5.097487 },
  { isoDate: "2014-10-26T00:00:00Z", dsun: 211.847158, hgln: -0.253672, hglt: 5.005623 },
  { isoDate: "2014-10-27T00:00:00Z", dsun: 211.779698, hgln: -0.255574, hglt: 4.912178 },
  { isoDate: "2014-10-28T00:00:00Z", dsun: 211.712351, hgln: -0.257114, hglt: 4.817178 },
  { isoDate: "2014-10-29T00:00:00Z", dsun: 211.645148, hgln: -0.258277, hglt: 4.72065 },
  { isoDate: "2014-10-30T00:00:00Z", dsun: 211.578119, hgln: -0.259052, hglt: 4.622621 },
  { isoDate: "2014-10-31T00:00:00Z", dsun: 211.511296, hgln: -0.259432, hglt: 4.523119 },
  { isoDate: "2014-11-01T00:00:00Z", dsun: 211.444711, hgln: -0.259412, hglt: 4.422173 },
  { isoDate: "2014-11-02T00:00:00Z", dsun: 211.378399, hgln: -0.258994, hglt: 4.319812 },
  { isoDate: "2014-11-03T00:00:00Z", dsun: 211.312393, hgln: -0.258184, hglt: 4.216065 },
  { isoDate: "2014-11-04T00:00:00Z", dsun: 211.246731, hgln: -0.256994, hglt: 4.110963 },
  { isoDate: "2014-11-05T00:00:00Z", dsun: 211.18145, hgln: -0.25544, hglt: 4.004537 },
  { isoDate: "2014-11-06T00:00:00Z", dsun: 211.116586, hgln: -0.253541, hglt: 3.896819 },
  { isoDate: "2014-11-07T00:00:00Z", dsun: 211.052177, hgln: -0.251319, hglt: 3.787839 },
  { isoDate: "2014-11-08T00:00:00Z", dsun: 210.988263, hgln: -0.248794, hglt: 3.677631 },
  { isoDate: "2014-11-09T00:00:00Z", dsun: 210.924882, hgln: -0.245989, hglt: 3.566228 },
  { isoDate: "2014-11-10T00:00:00Z", dsun: 210.86207, hgln: -0.242922, hglt: 3.453663 },
  { isoDate: "2014-11-11T00:00:00Z", dsun: 210.799867, hgln: -0.239609, hglt: 3.33997 },
  { isoDate: "2014-11-12T00:00:00Z", dsun: 210.7383, hgln: -0.236058, hglt: 3.225184 },
  { isoDate: "2014-11-13T00:00:00Z", dsun: 210.677427, hgln: -0.232292, hglt: 3.109341 },
  { isoDate: "2014-11-14T00:00:00Z", dsun: 210.617275, hgln: -0.228313, hglt: 2.992476 },
  { isoDate: "2014-11-15T00:00:00Z", dsun: 210.557881, hgln: -0.224128, hglt: 2.874624 },
  { isoDate: "2014-11-16T00:00:00Z", dsun: 210.499281, hgln: -0.219743, hglt: 2.755823 },
  { isoDate: "2014-11-17T00:00:00Z", dsun: 210.441514, hgln: -0.215161, hglt: 2.63611 },
  { isoDate: "2014-11-18T00:00:00Z", dsun: 210.384614, hgln: -0.210382, hglt: 2.515522 },
  { isoDate: "2014-11-19T00:00:00Z", dsun: 210.328618, hgln: -0.205406, hglt: 2.394096 },
  { isoDate: "2014-11-20T00:00:00Z", dsun: 210.273565, hgln: -0.200229, hglt: 2.271872 },
  { isoDate: "2014-11-21T00:00:00Z", dsun: 210.219489, hgln: -0.194848, hglt: 2.148888 },
  { isoDate: "2014-11-22T00:00:00Z", dsun: 210.166429, hgln: -0.189253, hglt: 2.025183 },
  { isoDate: "2014-11-23T00:00:00Z", dsun: 210.114419, hgln: -0.183437, hglt: 1.900797 },
  { isoDate: "2014-11-24T00:00:00Z", dsun: 210.063496, hgln: -0.177387, hglt: 1.77577 },
  { isoDate: "2014-11-25T00:00:00Z", dsun: 210.013694, hgln: -0.171093, hglt: 1.650143 },
  { isoDate: "2014-11-26T00:00:00Z", dsun: 209.965044, hgln: -0.164545, hglt: 1.523956 },
  { isoDate: "2014-11-27T00:00:00Z", dsun: 209.917578, hgln: -0.157735, hglt: 1.39725 },
  { isoDate: "2014-11-28T00:00:00Z", dsun: 209.871324, hgln: -0.150658, hglt: 1.270066 },
  { isoDate: "2014-11-29T00:00:00Z", dsun: 209.826312, hgln: -0.143314, hglt: 1.142446 },
  { isoDate: "2014-11-30T00:00:00Z", dsun: 209.782569, hgln: -0.135706, hglt: 1.014432 },
  { isoDate: "2014-12-01T00:00:00Z", dsun: 209.740124, hgln: -0.127844, hglt: 0.886066 },
  { isoDate: "2014-12-02T00:00:00Z", dsun: 209.699003, hgln: -0.119742, hglt: 0.757391 },
  { isoDate: "2014-12-03T00:00:00Z", dsun: 209.659232, hgln: -0.111419, hglt: 0.628448 },
  { isoDate: "2014-12-04T00:00:00Z", dsun: 209.620838, hgln: -0.102897, hglt: 0.499281 },
  { isoDate: "2014-12-05T00:00:00Z", dsun: 209.583844, hgln: -0.094201, hglt: 0.369933 },
  { isoDate: "2014-12-06T00:00:00Z", dsun: 209.548273, hgln: -0.085359, hglt: 0.240447 },
  { isoDate: "2014-12-07T00:00:00Z", dsun: 209.514147, hgln: -0.076399, hglt: 0.110867 },
  { isoDate: "2014-12-08T00:00:00Z", dsun: 209.481484, hgln: -0.067347, hglt: -0.018765 },
  { isoDate: "2014-12-09T00:00:00Z", dsun: 209.450304, hgln: -0.058231, hglt: -0.148404 },
  { isoDate: "2014-12-10T00:00:00Z", dsun: 209.42062, hgln: -0.049072, hglt: -0.278007 },
  { isoDate: "2014-12-11T00:00:00Z", dsun: 209.392452, hgln: -0.039897, hglt: -0.407531 },
  { isoDate: "2014-12-12T00:00:00Z", dsun: 209.365809, hgln: -0.030725, hglt: -0.536931 },
  { isoDate: "2014-12-13T00:00:00Z", dsun: 209.340705, hgln: -0.021573, hglt: -0.666164 },
  { isoDate: "2014-12-14T00:00:00Z", dsun: 209.317148, hgln: -0.012459, hglt: -0.795186 },
  { isoDate: "2014-12-15T00:00:00Z", dsun: 209.295148, hgln: -0.003396, hglt: -0.923954 },
  { isoDate: "2014-12-16T00:00:00Z", dsun: 209.274712, hgln: 0.005603, hglt: -1.052424 },
  { isoDate: "2014-12-17T00:00:00Z", dsun: 209.255846, hgln: 0.014528, hglt: -1.180553 },
  { isoDate: "2014-12-18T00:00:00Z", dsun: 209.238557, hgln: 0.023372, hglt: -1.308297 },
  { isoDate: "2014-12-19T00:00:00Z", dsun: 209.222845, hgln: 0.032129, hglt: -1.435613 },
  { isoDate: "2014-12-20T00:00:00Z", dsun: 209.208712, hgln: 0.040798, hglt: -1.562458 },
  { isoDate: "2014-12-21T00:00:00Z", dsun: 209.196165, hgln: 0.049382, hglt: -1.688789 },
  { isoDate: "2014-12-22T00:00:00Z", dsun: 209.185202, hgln: 0.057884, hglt: -1.814563 },
  { isoDate: "2014-12-23T00:00:00Z", dsun: 209.175821, hgln: 0.066315, hglt: -1.939737 },
  { isoDate: "2014-12-24T00:00:00Z", dsun: 209.168017, hgln: 0.074682, hglt: -2.064269 },
  { isoDate: "2014-12-25T00:00:00Z", dsun: 209.161782, hgln: 0.082997, hglt: -2.188117 },
  { isoDate: "2014-12-26T00:00:00Z", dsun: 209.157109, hgln: 0.091265, hglt: -2.311239 },
  { isoDate: "2014-12-27T00:00:00Z", dsun: 209.153987, hgln: 0.099493, hglt: -2.433595 },
  { isoDate: "2014-12-28T00:00:00Z", dsun: 209.152407, hgln: 0.107678, hglt: -2.555143 },
  { isoDate: "2014-12-29T00:00:00Z", dsun: 209.152357, hgln: 0.115817, hglt: -2.675842 },
  { isoDate: "2014-12-30T00:00:00Z", dsun: 209.153827, hgln: 0.123898, hglt: -2.795652 },
  { isoDate: "2014-12-31T00:00:00Z", dsun: 209.156802, hgln: 0.131907, hglt: -2.914534 },
  { isoDate: "2015-01-01T00:00:00Z", dsun: 209.161269, hgln: 0.139827, hglt: -3.032446 },
  { isoDate: "2015-01-02T00:00:00Z", dsun: 209.167213, hgln: 0.147635, hglt: -3.149351 },
  { isoDate: "2015-01-03T00:00:00Z", dsun: 209.174617, hgln: 0.155309, hglt: -3.265209 },
  { isoDate: "2015-01-04T00:00:00Z", dsun: 209.183463, hgln: 0.162825, hglt: -3.379983 },
  { isoDate: "2015-01-05T00:00:00Z", dsun: 209.193733, hgln: 0.170157, hglt: -3.493634 },
  { isoDate: "2015-01-06T00:00:00Z", dsun: 209.205405, hgln: 0.177282, hglt: -3.606125 },
  { isoDate: "2015-01-07T00:00:00Z", dsun: 209.218458, hgln: 0.184175, hglt: -3.717419 },
  { isoDate: "2015-01-08T00:00:00Z", dsun: 209.23287, hgln: 0.190815, hglt: -3.82748 },
  { isoDate: "2015-01-09T00:00:00Z", dsun: 209.248616, hgln: 0.197181, hglt: -3.936273 },
  { isoDate: "2015-01-10T00:00:00Z", dsun: 209.265673, hgln: 0.203254, hglt: -4.043762 },
  { isoDate: "2015-01-11T00:00:00Z", dsun: 209.284017, hgln: 0.209017, hglt: -4.149913 },
  { isoDate: "2015-01-12T00:00:00Z", dsun: 209.30362, hgln: 0.214456, hglt: -4.254693 },
  { isoDate: "2015-01-13T00:00:00Z", dsun: 209.324459, hgln: 0.219559, hglt: -4.358067 },
  { isoDate: "2015-01-14T00:00:00Z", dsun: 209.346525, hgln: 0.224314, hglt: -4.460001 },
  { isoDate: "2015-01-15T00:00:00Z", dsun: 209.369758, hgln: 0.228714, hglt: -4.560467 },
  { isoDate: "2015-01-16T00:00:00Z", dsun: 209.394148, hgln: 0.232754, hglt: -4.659432 },
  { isoDate: "2015-01-17T00:00:00Z", dsun: 209.419672, hgln: 0.236433, hglt: -4.756864 },
  { isoDate: "2015-01-18T00:00:00Z", dsun: 209.446302, hgln: 0.239753, hglt: -4.852734 },
  { isoDate: "2015-01-19T00:00:00Z", dsun: 209.474012, hgln: 0.242721, hglt: -4.947012 },
  { isoDate: "2015-01-20T00:00:00Z", dsun: 209.502773, hgln: 0.245349, hglt: -5.039669 },
  { isoDate: "2015-01-21T00:00:00Z", dsun: 209.532557, hgln: 0.247654, hglt: -5.130677 },
  { isoDate: "2015-01-22T00:00:00Z", dsun: 209.563333, hgln: 0.249655, hglt: -5.220009 },
  { isoDate: "2015-01-23T00:00:00Z", dsun: 209.595072, hgln: 0.251371, hglt: -5.307639 },
  { isoDate: "2015-01-24T00:00:00Z", dsun: 209.627744, hgln: 0.252819, hglt: -5.39354 },
  { isoDate: "2015-01-25T00:00:00Z", dsun: 209.661322, hgln: 0.254014, hglt: -5.477688 },
  { isoDate: "2015-01-26T00:00:00Z", dsun: 209.695778, hgln: 0.254963, hglt: -5.560059 },
  { isoDate: "2015-01-27T00:00:00Z", dsun: 209.731085, hgln: 0.25567, hglt: -5.640628 },
  { isoDate: "2015-01-28T00:00:00Z", dsun: 209.767217, hgln: 0.256133, hglt: -5.719373 },
  { isoDate: "2015-01-29T00:00:00Z", dsun: 209.804149, hgln: 0.256348, hglt: -5.796272 },
  { isoDate: "2015-01-30T00:00:00Z", dsun: 209.841854, hgln: 0.256306, hglt: -5.871303 },
  { isoDate: "2015-01-31T00:00:00Z", dsun: 209.880308, hgln: 0.255995, hglt: -5.944447 },
  { isoDate: "2015-02-01T00:00:00Z", dsun: 209.919487, hgln: 0.255404, hglt: -6.015682 },
  { isoDate: "2015-02-02T00:00:00Z", dsun: 209.959364, hgln: 0.254518, hglt: -6.084991 },
  { isoDate: "2015-02-03T00:00:00Z", dsun: 209.999917, hgln: 0.253325, hglt: -6.152356 },
  { isoDate: "2015-02-04T00:00:00Z", dsun: 210.041121, hgln: 0.251808, hglt: -6.217758 },
  { isoDate: "2015-02-05T00:00:00Z", dsun: 210.082953, hgln: 0.249956, hglt: -6.281181 },
  { isoDate: "2015-02-06T00:00:00Z", dsun: 210.125391, hgln: 0.247754, hglt: -6.34261 },
  { isoDate: "2015-02-07T00:00:00Z", dsun: 210.168412, hgln: 0.245191, hglt: -6.402031 },
  { isoDate: "2015-02-08T00:00:00Z", dsun: 210.211997, hgln: 0.242256, hglt: -6.459428 },
  { isoDate: "2015-02-09T00:00:00Z", dsun: 210.256124, hgln: 0.238941, hglt: -6.514788 },
  { isoDate: "2015-02-10T00:00:00Z", dsun: 210.300776, hgln: 0.235239, hglt: -6.5681 },
  { isoDate: "2015-02-11T00:00:00Z", dsun: 210.345935, hgln: 0.231143, hglt: -6.619352 },
  { isoDate: "2015-02-12T00:00:00Z", dsun: 210.391586, hgln: 0.226652, hglt: -6.668533 },
  { isoDate: "2015-02-13T00:00:00Z", dsun: 210.437715, hgln: 0.221765, hglt: -6.715632 },
  { isoDate: "2015-02-14T00:00:00Z", dsun: 210.484311, hgln: 0.216485, hglt: -6.760641 },
  { isoDate: "2015-02-15T00:00:00Z", dsun: 210.531363, hgln: 0.21082, hglt: -6.803552 },
  { isoDate: "2015-02-16T00:00:00Z", dsun: 210.578864, hgln: 0.204779, hglt: -6.844356 },
  { isoDate: "2015-02-17T00:00:00Z", dsun: 210.626806, hgln: 0.198381, hglt: -6.883047 },
  { isoDate: "2015-02-18T00:00:00Z", dsun: 210.67518, hgln: 0.191648, hglt: -6.919618 },
  { isoDate: "2015-02-19T00:00:00Z", dsun: 210.723979, hgln: 0.184606, hglt: -6.954066 },
  { isoDate: "2015-02-20T00:00:00Z", dsun: 210.773192, hgln: 0.177286, hglt: -6.986384 },
  { isoDate: "2015-02-21T00:00:00Z", dsun: 210.82281, hgln: 0.169721, hglt: -7.01657 },
  { isoDate: "2015-02-22T00:00:00Z", dsun: 210.872826, hgln: 0.16194, hglt: -7.04462 },
  { isoDate: "2015-02-23T00:00:00Z", dsun: 210.923234, hgln: 0.153972, hglt: -7.070532 },
  { isoDate: "2015-02-24T00:00:00Z", dsun: 210.974029, hgln: 0.145838, hglt: -7.094305 },
  { isoDate: "2015-02-25T00:00:00Z", dsun: 211.025209, hgln: 0.137556, hglt: -7.115936 },
  { isoDate: "2015-02-26T00:00:00Z", dsun: 211.076772, hgln: 0.12914, hglt: -7.135427 },
  { isoDate: "2015-02-27T00:00:00Z", dsun: 211.128718, hgln: 0.120599, hglt: -7.152777 },
  { isoDate: "2015-02-28T00:00:00Z", dsun: 211.181045, hgln: 0.111939, hglt: -7.167988 },
  { isoDate: "2015-03-01T00:00:00Z", dsun: 211.233753, hgln: 0.103168, hglt: -7.181059 },
  { isoDate: "2015-03-02T00:00:00Z", dsun: 211.286842, hgln: 0.094288, hglt: -7.191995 },
  { isoDate: "2015-03-03T00:00:00Z", dsun: 211.340309, hgln: 0.085302, hglt: -7.200797 },
  { isoDate: "2015-03-04T00:00:00Z", dsun: 211.394152, hgln: 0.076214, hglt: -7.207468 },
  { isoDate: "2015-03-05T00:00:00Z", dsun: 211.448366, hgln: 0.067024, hglt: -7.212012 },
  { isoDate: "2015-03-06T00:00:00Z", dsun: 211.502947, hgln: 0.057736, hglt: -7.214433 },
  { isoDate: "2015-03-07T00:00:00Z", dsun: 211.557886, hgln: 0.04835, hglt: -7.214735 },
  { isoDate: "2015-03-08T00:00:00Z", dsun: 211.613176, hgln: 0.038871, hglt: -7.212923 },
  { isoDate: "2015-03-09T00:00:00Z", dsun: 211.668805, hgln: 0.0293, hglt: -7.209003 },
  { isoDate: "2015-03-10T00:00:00Z", dsun: 211.72476, hgln: 0.019643, hglt: -7.202979 },
  { isoDate: "2015-03-11T00:00:00Z", dsun: 211.781023, hgln: 0.009904, hglt: -7.194858 },
  { isoDate: "2015-03-12T00:00:00Z", dsun: 211.837585, hgln: 0.000092, hglt: -7.184646 },
  { isoDate: "2015-03-13T00:00:00Z", dsun: 211.894423, hgln: -0.009786, hglt: -7.172349 },
  { isoDate: "2015-03-14T00:00:00Z", dsun: 211.951517, hgln: -0.019718, hglt: -7.157974 },
  { isoDate: "2015-03-15T00:00:00Z", dsun: 212.008846, hgln: -0.029689, hglt: -7.141528 },
  { isoDate: "2015-03-16T00:00:00Z", dsun: 212.066389, hgln: -0.039682, hglt: -7.123019 },
  { isoDate: "2015-03-17T00:00:00Z", dsun: 212.124123, hgln: -0.049675, hglt: -7.102455 },
  { isoDate: "2015-03-18T00:00:00Z", dsun: 212.182028, hgln: -0.05964, hglt: -7.079843 },
  { isoDate: "2015-03-19T00:00:00Z", dsun: 212.240077, hgln: -0.069547, hglt: -7.055194 },
  { isoDate: "2015-03-20T00:00:00Z", dsun: 212.298247, hgln: -0.079358, hglt: -7.028514 },
  { isoDate: "2015-03-21T00:00:00Z", dsun: 212.356514, hgln: -0.089037, hglt: -6.999815 },
  { isoDate: "2015-03-22T00:00:00Z", dsun: 212.414849, hgln: -0.098546, hglt: -6.969105 },
  { isoDate: "2015-03-23T00:00:00Z", dsun: 212.473223, hgln: -0.107852, hglt: -6.936396 },
  { isoDate: "2015-03-24T00:00:00Z", dsun: 212.531602, hgln: -0.116924, hglt: -6.901697 },
  { isoDate: "2015-03-25T00:00:00Z", dsun: 212.589953, hgln: -0.125738, hglt: -6.865019 },
  { isoDate: "2015-03-26T00:00:00Z", dsun: 212.64824, hgln: -0.134273, hglt: -6.826373 },
  { isoDate: "2015-03-27T00:00:00Z", dsun: 212.706431, hgln: -0.142516, hglt: -6.785772 },
  { isoDate: "2015-03-28T00:00:00Z", dsun: 212.764494, hgln: -0.150455, hglt: -6.743226 },
  { isoDate: "2015-03-29T00:00:00Z", dsun: 212.822398, hgln: -0.158084, hglt: -6.69875 },
  { isoDate: "2015-03-30T00:00:00Z", dsun: 212.880114, hgln: -0.165398, hglt: -6.652355 },
  { isoDate: "2015-03-31T00:00:00Z", dsun: 212.937614, hgln: -0.172396, hglt: -6.604056 },
  { isoDate: "2015-04-01T00:00:00Z", dsun: 212.994872, hgln: -0.179078, hglt: -6.553867 },
  { isoDate: "2015-04-02T00:00:00Z", dsun: 213.051862, hgln: -0.185449, hglt: -6.501803 },
  { isoDate: "2015-04-03T00:00:00Z", dsun: 213.108561, hgln: -0.191512, hglt: -6.447878 },
  { isoDate: "2015-04-04T00:00:00Z", dsun: 213.164944, hgln: -0.197274, hglt: -6.392109 },
  { isoDate: "2015-04-05T00:00:00Z", dsun: 213.220991, hgln: -0.202743, hglt: -6.334512 },
  { isoDate: "2015-04-06T00:00:00Z", dsun: 213.276679, hgln: -0.207929, hglt: -6.275104 },
  { isoDate: "2015-04-07T00:00:00Z", dsun: 213.331988, hgln: -0.212842, hglt: -6.213902 },
  { isoDate: "2015-04-08T00:00:00Z", dsun: 213.3869, hgln: -0.217492, hglt: -6.150924 },
  { isoDate: "2015-04-09T00:00:00Z", dsun: 213.441396, hgln: -0.22189, hglt: -6.086187 },
  { isoDate: "2015-04-10T00:00:00Z", dsun: 213.495459, hgln: -0.226045, hglt: -6.019712 },
  { isoDate: "2015-04-11T00:00:00Z", dsun: 213.549072, hgln: -0.229964, hglt: -5.951517 },
  { isoDate: "2015-04-12T00:00:00Z", dsun: 213.602221, hgln: -0.233655, hglt: -5.881623 },
  { isoDate: "2015-04-13T00:00:00Z", dsun: 213.654891, hgln: -0.23712, hglt: -5.810049 },
  { isoDate: "2015-04-14T00:00:00Z", dsun: 213.707069, hgln: -0.240358, hglt: -5.736816 },
  { isoDate: "2015-04-15T00:00:00Z", dsun: 213.758744, hgln: -0.243364, hglt: -5.661946 },
  { isoDate: "2015-04-16T00:00:00Z", dsun: 213.809907, hgln: -0.246128, hglt: -5.58546 },
  { isoDate: "2015-04-17T00:00:00Z", dsun: 213.86055, hgln: -0.248634, hglt: -5.50738 },
  { isoDate: "2015-04-18T00:00:00Z", dsun: 213.910666, hgln: -0.250864, hglt: -5.42773 },
  { isoDate: "2015-04-19T00:00:00Z", dsun: 213.960251, hgln: -0.252796, hglt: -5.346532 },
  { isoDate: "2015-04-20T00:00:00Z", dsun: 214.009303, hgln: -0.254409, hglt: -5.263809 },
  { isoDate: "2015-04-21T00:00:00Z", dsun: 214.057819, hgln: -0.255683, hglt: -5.179587 },
  { isoDate: "2015-04-22T00:00:00Z", dsun: 214.105798, hgln: -0.256601, hglt: -5.093889 },
  { isoDate: "2015-04-23T00:00:00Z", dsun: 214.153238, hgln: -0.257152, hglt: -5.006741 },
  { isoDate: "2015-04-24T00:00:00Z", dsun: 214.200135, hgln: -0.257329, hglt: -4.918167 },
  { isoDate: "2015-04-25T00:00:00Z", dsun: 214.246489, hgln: -0.257128, hglt: -4.828193 },
  { isoDate: "2015-04-26T00:00:00Z", dsun: 214.292298, hgln: -0.256548, hglt: -4.736845 },
  { isoDate: "2015-04-27T00:00:00Z", dsun: 214.337562, hgln: -0.255594, hglt: -4.64415 },
  { isoDate: "2015-04-28T00:00:00Z", dsun: 214.382281, hgln: -0.254269, hglt: -4.550135 },
  { isoDate: "2015-04-29T00:00:00Z", dsun: 214.426459, hgln: -0.252581, hglt: -4.454826 },
  { isoDate: "2015-04-30T00:00:00Z", dsun: 214.470097, hgln: -0.25054, hglt: -4.35825 },
  { isoDate: "2015-05-01T00:00:00Z", dsun: 214.513201, hgln: -0.248157, hglt: -4.260437 },
  { isoDate: "2015-05-02T00:00:00Z", dsun: 214.555775, hgln: -0.245445, hglt: -4.161413 },
  { isoDate: "2015-05-03T00:00:00Z", dsun: 214.597823, hgln: -0.242419, hglt: -4.061208 },
  { isoDate: "2015-05-04T00:00:00Z", dsun: 214.639353, hgln: -0.239097, hglt: -3.95985 },
  { isoDate: "2015-05-05T00:00:00Z", dsun: 214.680369, hgln: -0.235498, hglt: -3.857368 },
  { isoDate: "2015-05-06T00:00:00Z", dsun: 214.720878, hgln: -0.231642, hglt: -3.753791 },
  { isoDate: "2015-05-07T00:00:00Z", dsun: 214.760886, hgln: -0.227548, hglt: -3.649151 },
  { isoDate: "2015-05-08T00:00:00Z", dsun: 214.800399, hgln: -0.223238, hglt: -3.543475 },
  { isoDate: "2015-05-09T00:00:00Z", dsun: 214.839423, hgln: -0.218732, hglt: -3.436795 },
  { isoDate: "2015-05-10T00:00:00Z", dsun: 214.877963, hgln: -0.214046, hglt: -3.329141 },
  { isoDate: "2015-05-11T00:00:00Z", dsun: 214.916026, hgln: -0.209195, hglt: -3.220544 },
  { isoDate: "2015-05-12T00:00:00Z", dsun: 214.953618, hgln: -0.204192, hglt: -3.111035 },
  { isoDate: "2015-05-13T00:00:00Z", dsun: 214.990784, hgln: -0.199042, hglt: -3.000648 },
  { isoDate: "2015-05-14T00:00:00Z", dsun: 215.02745, hgln: -0.193751, hglt: -2.889408 },
  { isoDate: "2015-05-15T00:00:00Z", dsun: 215.063663, hgln: -0.188315, hglt: -2.777352 },
  { isoDate: "2015-05-16T00:00:00Z", dsun: 215.099429, hgln: -0.182726, hglt: -2.664509 },
  { isoDate: "2015-05-17T00:00:00Z", dsun: 215.134754, hgln: -0.176975, hglt: -2.550913 },
  { isoDate: "2015-05-18T00:00:00Z", dsun: 215.169647, hgln: -0.17105, hglt: -2.436595 },
  { isoDate: "2015-05-19T00:00:00Z", dsun: 215.204114, hgln: -0.164937, hglt: -2.321588 },
  { isoDate: "2015-05-20T00:00:00Z", dsun: 215.238162, hgln: -0.158625, hglt: -2.205925 },
  { isoDate: "2015-05-21T00:00:00Z", dsun: 215.271797, hgln: -0.152105, hglt: -2.089638 },
  { isoDate: "2015-05-22T00:00:00Z", dsun: 215.305022, hgln: -0.145372, hglt: -1.972761 },
  { isoDate: "2015-05-23T00:00:00Z", dsun: 215.337842, hgln: -0.138423, hglt: -1.855327 },
  { isoDate: "2015-05-24T00:00:00Z", dsun: 215.370259, hgln: -0.131258, hglt: -1.737369 },
  { isoDate: "2015-05-25T00:00:00Z", dsun: 215.402275, hgln: -0.123881, hglt: -1.61892 },
  { isoDate: "2015-05-26T00:00:00Z", dsun: 215.433892, hgln: -0.116299, hglt: -1.500014 },
  { isoDate: "2015-05-27T00:00:00Z", dsun: 215.465114, hgln: -0.108519, hglt: -1.380685 },
  { isoDate: "2015-05-28T00:00:00Z", dsun: 215.495943, hgln: -0.100552, hglt: -1.260966 },
  { isoDate: "2015-05-29T00:00:00Z", dsun: 215.52638, hgln: -0.092411, hglt: -1.140891 },
  { isoDate: "2015-05-30T00:00:00Z", dsun: 215.556428, hgln: -0.084111, hglt: -1.020494 },
  { isoDate: "2015-05-31T00:00:00Z", dsun: 215.586087, hgln: -0.07567, hglt: -0.899809 },
  { isoDate: "2015-06-01T00:00:00Z", dsun: 215.615359, hgln: -0.067106, hglt: -0.77887 },
  { isoDate: "2015-06-02T00:00:00Z", dsun: 215.644243, hgln: -0.058442, hglt: -0.657712 },
  { isoDate: "2015-06-03T00:00:00Z", dsun: 215.672737, hgln: -0.049703, hglt: -0.536367 },
  { isoDate: "2015-06-04T00:00:00Z", dsun: 215.700841, hgln: -0.040915, hglt: -0.414871 },
  { isoDate: "2015-06-05T00:00:00Z", dsun: 215.728551, hgln: -0.032105, hglt: -0.293257 },
  { isoDate: "2015-06-06T00:00:00Z", dsun: 215.755863, hgln: -0.0233, hglt: -0.17156 },
  { isoDate: "2015-06-07T00:00:00Z", dsun: 215.782773, hgln: -0.014526, hglt: -0.049814 },
  { isoDate: "2015-06-08T00:00:00Z", dsun: 215.809275, hgln: -0.005806, hglt: 0.071947 },
  { isoDate: "2015-06-09T00:00:00Z", dsun: 215.835362, hgln: 0.002841, hglt: 0.193688 },
  { isoDate: "2015-06-10T00:00:00Z", dsun: 215.861027, hgln: 0.011399, hglt: 0.315375 },
  { isoDate: "2015-06-11T00:00:00Z", dsun: 215.886264, hgln: 0.019858, hglt: 0.436975 },
  { isoDate: "2015-06-12T00:00:00Z", dsun: 215.911065, hgln: 0.028211, hglt: 0.558453 },
  { isoDate: "2015-06-13T00:00:00Z", dsun: 215.93542, hgln: 0.036459, hglt: 0.679775 },
  { isoDate: "2015-06-14T00:00:00Z", dsun: 215.959324, hgln: 0.044604, hglt: 0.800908 },
  { isoDate: "2015-06-15T00:00:00Z", dsun: 215.982766, hgln: 0.052652, hglt: 0.921818 },
  { isoDate: "2015-06-16T00:00:00Z", dsun: 216.005738, hgln: 0.060611, hglt: 1.04247 },
  { isoDate: "2015-06-17T00:00:00Z", dsun: 216.028229, hgln: 0.068491, hglt: 1.162831 },
  { isoDate: "2015-06-18T00:00:00Z", dsun: 216.050227, hgln: 0.076299, hglt: 1.282867 },
  { isoDate: "2015-06-19T00:00:00Z", dsun: 216.071718, hgln: 0.084042, hglt: 1.402546 },
  { isoDate: "2015-06-20T00:00:00Z", dsun: 216.092687, hgln: 0.091725, hglt: 1.521833 },
  { isoDate: "2015-06-21T00:00:00Z", dsun: 216.113119, hgln: 0.099352, hglt: 1.640696 },
  { isoDate: "2015-06-22T00:00:00Z", dsun: 216.133, hgln: 0.106924, hglt: 1.759101 },
  { isoDate: "2015-06-23T00:00:00Z", dsun: 216.152313, hgln: 0.114438, hglt: 1.877016 },
  { isoDate: "2015-06-24T00:00:00Z", dsun: 216.171044, hgln: 0.121892, hglt: 1.994407 },
  { isoDate: "2015-06-25T00:00:00Z", dsun: 216.189176, hgln: 0.129281, hglt: 2.111243 },
  { isoDate: "2015-06-26T00:00:00Z", dsun: 216.206695, hgln: 0.136596, hglt: 2.227491 },
  { isoDate: "2015-06-27T00:00:00Z", dsun: 216.223583, hgln: 0.143829, hglt: 2.343118 },
  { isoDate: "2015-06-28T00:00:00Z", dsun: 216.239826, hgln: 0.150968, hglt: 2.458093 },
  { isoDate: "2015-06-29T00:00:00Z", dsun: 216.255405, hgln: 0.157999, hglt: 2.572385 },
  { isoDate: "2015-06-30T00:00:00Z", dsun: 216.270304, hgln: 0.164904, hglt: 2.685961 },
  { isoDate: "2015-07-01T00:00:00Z", dsun: 216.284505, hgln: 0.171665, hglt: 2.798791 },
  { isoDate: "2015-07-02T00:00:00Z", dsun: 216.297989, hgln: 0.178257, hglt: 2.910842 },
  { isoDate: "2015-07-03T00:00:00Z", dsun: 216.310737, hgln: 0.184657, hglt: 3.022084 },
  { isoDate: "2015-07-04T00:00:00Z", dsun: 216.322731, hgln: 0.190838, hglt: 3.132486 },
  { isoDate: "2015-07-05T00:00:00Z", dsun: 216.333951, hgln: 0.196773, hglt: 3.242018 },
  { isoDate: "2015-07-06T00:00:00Z", dsun: 216.344377, hgln: 0.20244, hglt: 3.35065 },
  { isoDate: "2015-07-07T00:00:00Z", dsun: 216.35399, hgln: 0.207817, hglt: 3.458351 },
  { isoDate: "2015-07-08T00:00:00Z", dsun: 216.36277, hgln: 0.212888, hglt: 3.565093 },
  { isoDate: "2015-07-09T00:00:00Z", dsun: 216.370699, hgln: 0.217642, hglt: 3.670846 },
  { isoDate: "2015-07-10T00:00:00Z", dsun: 216.377758, hgln: 0.222073, hglt: 3.775581 },
  { isoDate: "2015-07-11T00:00:00Z", dsun: 216.38393, hgln: 0.226181, hglt: 3.879269 },
  { isoDate: "2015-07-12T00:00:00Z", dsun: 216.389197, hgln: 0.229968, hglt: 3.981881 },
  { isoDate: "2015-07-13T00:00:00Z", dsun: 216.393544, hgln: 0.233441, hglt: 4.083389 },
  { isoDate: "2015-07-14T00:00:00Z", dsun: 216.396683, hgln: 0.236652, hglt: 4.183771 },
  { isoDate: "2015-07-15T00:00:00Z", dsun: 216.399124, hgln: 0.239531, hglt: 4.282988 },
  { isoDate: "2015-07-16T00:00:00Z", dsun: 216.400591, hgln: 0.24213, hglt: 4.381019 },
  { isoDate: "2015-07-17T00:00:00Z", dsun: 216.401066, hgln: 0.244461, hglt: 4.477835 },
  { isoDate: "2015-07-18T00:00:00Z", dsun: 216.40053, hgln: 0.246536, hglt: 4.573411 },
  { isoDate: "2015-07-19T00:00:00Z", dsun: 216.398966, hgln: 0.248366, hglt: 4.667719 },
  { isoDate: "2015-07-20T00:00:00Z", dsun: 216.396358, hgln: 0.249961, hglt: 4.760735 },
  { isoDate: "2015-07-21T00:00:00Z", dsun: 216.392689, hgln: 0.251328, hglt: 4.852431 },
  { isoDate: "2015-07-22T00:00:00Z", dsun: 216.387946, hgln: 0.252475, hglt: 4.942783 },
  { isoDate: "2015-07-23T00:00:00Z", dsun: 216.382115, hgln: 0.253405, hglt: 5.031766 },
  { isoDate: "2015-07-24T00:00:00Z", dsun: 216.375183, hgln: 0.254123, hglt: 5.119354 },
  { isoDate: "2015-07-25T00:00:00Z", dsun: 216.367139, hgln: 0.25463, hglt: 5.205524 },
  { isoDate: "2015-07-26T00:00:00Z", dsun: 216.357972, hgln: 0.254924, hglt: 5.290251 },
  { isoDate: "2015-07-27T00:00:00Z", dsun: 216.347672, hgln: 0.255001, hglt: 5.373511 },
  { isoDate: "2015-07-28T00:00:00Z", dsun: 216.336229, hgln: 0.254857, hglt: 5.455281 },
  { isoDate: "2015-07-29T00:00:00Z", dsun: 216.323635, hgln: 0.254481, hglt: 5.535538 },
  { isoDate: "2015-07-30T00:00:00Z", dsun: 216.309881, hgln: 0.25386, hglt: 5.614259 },
  { isoDate: "2015-07-31T00:00:00Z", dsun: 216.29496, hgln: 0.252977, hglt: 5.691421 },
  { isoDate: "2015-08-01T00:00:00Z", dsun: 216.278866, hgln: 0.251812, hglt: 5.767003 },
  { isoDate: "2015-08-02T00:00:00Z", dsun: 216.261591, hgln: 0.250344, hglt: 5.840983 },
  { isoDate: "2015-08-03T00:00:00Z", dsun: 216.243132, hgln: 0.248549, hglt: 5.913339 },
  { isoDate: "2015-08-04T00:00:00Z", dsun: 216.223484, hgln: 0.246411, hglt: 5.984051 },
  { isoDate: "2015-08-05T00:00:00Z", dsun: 216.202645, hgln: 0.243914, hglt: 6.053098 },
  { isoDate: "2015-08-06T00:00:00Z", dsun: 216.180614, hgln: 0.241047, hglt: 6.12046 },
  { isoDate: "2015-08-07T00:00:00Z", dsun: 216.157392, hgln: 0.237807, hglt: 6.186116 },
  { isoDate: "2015-08-08T00:00:00Z", dsun: 216.132982, hgln: 0.234195, hglt: 6.250048 },
  { isoDate: "2015-08-09T00:00:00Z", dsun: 216.107389, hgln: 0.230218, hglt: 6.312237 },
  { isoDate: "2015-08-10T00:00:00Z", dsun: 216.08062, hgln: 0.225883, hglt: 6.372663 },
  { isoDate: "2015-08-11T00:00:00Z", dsun: 216.052679, hgln: 0.221203, hglt: 6.431308 },
  { isoDate: "2015-08-12T00:00:00Z", dsun: 216.023584, hgln: 0.216196, hglt: 6.488154 },
  { isoDate: "2015-08-13T00:00:00Z", dsun: 215.993341, hgln: 0.210876, hglt: 6.543183 },
  { isoDate: "2015-08-14T00:00:00Z", dsun: 215.961959, hgln: 0.205261, hglt: 6.596379 },
  { isoDate: "2015-08-15T00:00:00Z", dsun: 215.92945, hgln: 0.199369, hglt: 6.647724 },
  { isoDate: "2015-08-16T00:00:00Z", dsun: 215.895824, hgln: 0.193219, hglt: 6.697202 },
  { isoDate: "2015-08-17T00:00:00Z", dsun: 215.861092, hgln: 0.18683, hglt: 6.744797 },
  { isoDate: "2015-08-18T00:00:00Z", dsun: 215.825269, hgln: 0.18022, hglt: 6.790494 },
  { isoDate: "2015-08-19T00:00:00Z", dsun: 215.788369, hgln: 0.173407, hglt: 6.834278 },
  { isoDate: "2015-08-20T00:00:00Z", dsun: 215.75041, hgln: 0.166406, hglt: 6.876134 },
  { isoDate: "2015-08-21T00:00:00Z", dsun: 215.711413, hgln: 0.159236, hglt: 6.916049 },
  { isoDate: "2015-08-22T00:00:00Z", dsun: 215.671398, hgln: 0.151908, hglt: 6.954007 },
  { isoDate: "2015-08-23T00:00:00Z", dsun: 215.630387, hgln: 0.144438, hglt: 6.989996 },
  { isoDate: "2015-08-24T00:00:00Z", dsun: 215.588406, hgln: 0.136835, hglt: 7.024004 },
  { isoDate: "2015-08-25T00:00:00Z", dsun: 215.545479, hgln: 0.129109, hglt: 7.056016 },
  { isoDate: "2015-08-26T00:00:00Z", dsun: 215.501631, hgln: 0.121265, hglt: 7.086022 },
  { isoDate: "2015-08-27T00:00:00Z", dsun: 215.456888, hgln: 0.113307, hglt: 7.114011 },
  { isoDate: "2015-08-28T00:00:00Z", dsun: 215.411276, hgln: 0.105231, hglt: 7.13997 },
  { isoDate: "2015-08-29T00:00:00Z", dsun: 215.36482, hgln: 0.097032, hglt: 7.16389 },
  { isoDate: "2015-08-30T00:00:00Z", dsun: 215.317546, hgln: 0.0887, hglt: 7.18576 },
  { isoDate: "2015-08-31T00:00:00Z", dsun: 215.269478, hgln: 0.080224, hglt: 7.205572 },
  { isoDate: "2015-09-01T00:00:00Z", dsun: 215.22064, hgln: 0.071593, hglt: 7.223316 },
  { isoDate: "2015-09-02T00:00:00Z", dsun: 215.171053, hgln: 0.062799, hglt: 7.238984 },
  { isoDate: "2015-09-03T00:00:00Z", dsun: 215.120741, hgln: 0.053838, hglt: 7.252569 },
  { isoDate: "2015-09-04T00:00:00Z", dsun: 215.069723, hgln: 0.044712, hglt: 7.264064 },
  { isoDate: "2015-09-05T00:00:00Z", dsun: 215.01802, hgln: 0.03543, hglt: 7.273462 },
  { isoDate: "2015-09-06T00:00:00Z", dsun: 214.96565, hgln: 0.026006, hglt: 7.280757 },
  { isoDate: "2015-09-07T00:00:00Z", dsun: 214.912634, hgln: 0.016458, hglt: 7.285944 },
  { isoDate: "2015-09-08T00:00:00Z", dsun: 214.858982, hgln: 0.006807, hglt: 7.289008 },
  { isoDate: "2015-09-09T00:00:00Z", dsun: 214.804724, hgln: -0.002922, hglt: 7.289964 },
  { isoDate: "2015-09-10T00:00:00Z", dsun: 214.749873, hgln: -0.012705, hglt: 7.288799 },
  { isoDate: "2015-09-11T00:00:00Z", dsun: 214.694445, hgln: -0.022512, hglt: 7.28551 },
  { isoDate: "2015-09-12T00:00:00Z", dsun: 214.63846, hgln: -0.032316, hglt: 7.280095 },
  { isoDate: "2015-09-13T00:00:00Z", dsun: 214.581937, hgln: -0.04209, hglt: 7.272552 },
  { isoDate: "2015-09-14T00:00:00Z", dsun: 214.524883, hgln: -0.051805, hglt: 7.262879 },
  { isoDate: "2015-09-15T00:00:00Z", dsun: 214.467309, hgln: -0.061433, hglt: 7.251077 },
  { isoDate: "2015-09-16T00:00:00Z", dsun: 214.409219, hgln: -0.07095, hglt: 7.237147 },
  { isoDate: "2015-09-17T00:00:00Z", dsun: 214.350619, hgln: -0.080329, hglt: 7.221088 },
  { isoDate: "2015-09-18T00:00:00Z", dsun: 214.29151, hgln: -0.089545, hglt: 7.202903 },
  { isoDate: "2015-09-19T00:00:00Z", dsun: 214.231893, hgln: -0.098574, hglt: 7.182595 },
  { isoDate: "2015-09-20T00:00:00Z", dsun: 214.171769, hgln: -0.107396, hglt: 7.160166 },
  { isoDate: "2015-09-21T00:00:00Z", dsun: 214.111141, hgln: -0.115989, hglt: 7.135621 },
  { isoDate: "2015-09-22T00:00:00Z", dsun: 214.050011, hgln: -0.124337, hglt: 7.108962 },
  { isoDate: "2015-09-23T00:00:00Z", dsun: 213.988384, hgln: -0.132427, hglt: 7.080195 },
  { isoDate: "2015-09-24T00:00:00Z", dsun: 213.926264, hgln: -0.140247, hglt: 7.049325 },
  { isoDate: "2015-09-25T00:00:00Z", dsun: 213.863658, hgln: -0.147794, hglt: 7.016358 },
  { isoDate: "2015-09-26T00:00:00Z", dsun: 213.800573, hgln: -0.155069, hglt: 6.9813 },
  { isoDate: "2015-09-27T00:00:00Z", dsun: 213.737016, hgln: -0.162078, hglt: 6.94416 },
  { isoDate: "2015-09-28T00:00:00Z", dsun: 213.672995, hgln: -0.168834, hglt: 6.904944 },
  { isoDate: "2015-09-29T00:00:00Z", dsun: 213.608519, hgln: -0.175352, hglt: 6.86366 },
  { isoDate: "2015-09-30T00:00:00Z", dsun: 213.543598, hgln: -0.181649, hglt: 6.820319 },
  { isoDate: "2015-10-01T00:00:00Z", dsun: 213.478242, hgln: -0.187738, hglt: 6.77493 },
  { isoDate: "2015-10-02T00:00:00Z", dsun: 213.412462, hgln: -0.19363, hglt: 6.727502 },
  { isoDate: "2015-10-03T00:00:00Z", dsun: 213.346269, hgln: -0.199332, hglt: 6.678048 },
  { isoDate: "2015-10-04T00:00:00Z", dsun: 213.279675, hgln: -0.204845, hglt: 6.626578 },
  { isoDate: "2015-10-05T00:00:00Z", dsun: 213.212694, hgln: -0.210165, hglt: 6.573104 },
  { isoDate: "2015-10-06T00:00:00Z", dsun: 213.145341, hgln: -0.215288, hglt: 6.51764 },
  { isoDate: "2015-10-07T00:00:00Z", dsun: 213.077631, hgln: -0.220204, hglt: 6.460199 },
  { isoDate: "2015-10-08T00:00:00Z", dsun: 213.009583, hgln: -0.224901, hglt: 6.400794 },
  { isoDate: "2015-10-09T00:00:00Z", dsun: 212.941214, hgln: -0.229369, hglt: 6.33944 },
  { isoDate: "2015-10-10T00:00:00Z", dsun: 212.872547, hgln: -0.233591, hglt: 6.276152 },
  { isoDate: "2015-10-11T00:00:00Z", dsun: 212.803605, hgln: -0.237555, hglt: 6.210946 },
  { isoDate: "2015-10-12T00:00:00Z", dsun: 212.734412, hgln: -0.241244, hglt: 6.143839 },
  { isoDate: "2015-10-13T00:00:00Z", dsun: 212.665086, hgln: -0.24465, hglt: 6.07485 },
  { isoDate: "2015-10-14T00:00:00Z", dsun: 212.595478, hgln: -0.247743, hglt: 6.003991 },
  { isoDate: "2015-10-15T00:00:00Z", dsun: 212.525705, hgln: -0.250515, hglt: 5.931282 },
  { isoDate: "2015-10-16T00:00:00Z", dsun: 212.455796, hgln: -0.252951, hglt: 5.856743 },
  { isoDate: "2015-10-17T00:00:00Z", dsun: 212.385781, hgln: -0.255038, hglt: 5.780394 },
  { isoDate: "2015-10-18T00:00:00Z", dsun: 212.31569, hgln: -0.256761, hglt: 5.702255 },
  { isoDate: "2015-10-19T00:00:00Z", dsun: 212.245552, hgln: -0.258111, hglt: 5.622346 },
  { isoDate: "2015-10-20T00:00:00Z", dsun: 212.175398, hgln: -0.259078, hglt: 5.540689 },
  { isoDate: "2015-10-21T00:00:00Z", dsun: 212.10526, hgln: -0.259654, hglt: 5.457306 },
  { isoDate: "2015-10-22T00:00:00Z", dsun: 212.035169, hgln: -0.259836, hglt: 5.372219 },
  { isoDate: "2015-10-23T00:00:00Z", dsun: 211.96516, hgln: -0.259624, hglt: 5.285451 },
  { isoDate: "2015-10-24T00:00:00Z", dsun: 211.895268, hgln: -0.259023, hglt: 5.197027 },
  { isoDate: "2015-10-25T00:00:00Z", dsun: 211.825529, hgln: -0.258044, hglt: 5.10697 },
  { isoDate: "2015-10-26T00:00:00Z", dsun: 211.755981, hgln: -0.256702, hglt: 5.015307 },
  { isoDate: "2015-10-27T00:00:00Z", dsun: 211.686661, hgln: -0.255018, hglt: 4.922061 },
  { isoDate: "2015-10-28T00:00:00Z", dsun: 211.617609, hgln: -0.253018, hglt: 4.827261 },
  { isoDate: "2015-10-29T00:00:00Z", dsun: 211.548862, hgln: -0.250726, hglt: 4.730932 },
  { isoDate: "2015-10-30T00:00:00Z", dsun: 211.48046, hgln: -0.248165, hglt: 4.633103 },
  { isoDate: "2015-10-31T00:00:00Z", dsun: 211.412442, hgln: -0.245357, hglt: 4.533801 },
  { isoDate: "2015-11-01T00:00:00Z", dsun: 211.344845, hgln: -0.242318, hglt: 4.433055 },
  { isoDate: "2015-11-02T00:00:00Z", dsun: 211.27771, hgln: -0.239058, hglt: 4.330895 },
  { isoDate: "2015-11-03T00:00:00Z", dsun: 211.211073, hgln: -0.235588, hglt: 4.227351 },
  { isoDate: "2015-11-04T00:00:00Z", dsun: 211.144973, hgln: -0.231911, hglt: 4.122452 },
  { isoDate: "2015-11-05T00:00:00Z", dsun: 211.07945, hgln: -0.228029, hglt: 4.016231 },
  { isoDate: "2015-11-06T00:00:00Z", dsun: 211.01454, hgln: -0.223944, hglt: 3.908718 },
  { isoDate: "2015-11-07T00:00:00Z", dsun: 210.950282, hgln: -0.219653, hglt: 3.799946 },
  { isoDate: "2015-11-08T00:00:00Z", dsun: 210.886716, hgln: -0.215153, hglt: 3.689948 },
  { isoDate: "2015-11-09T00:00:00Z", dsun: 210.823879, hgln: -0.21044, hglt: 3.578758 },
  { isoDate: "2015-11-10T00:00:00Z", dsun: 210.761805, hgln: -0.205506, hglt: 3.466407 },
  { isoDate: "2015-11-11T00:00:00Z", dsun: 210.700545, hgln: -0.20035, hglt: 3.352932 },
  { isoDate: "2015-11-12T00:00:00Z", dsun: 210.640132, hgln: -0.194963, hglt: 3.238368 },
  { isoDate: "2015-11-13T00:00:00Z", dsun: 210.580604, hgln: -0.189336, hglt: 3.12275 },
  { isoDate: "2015-11-14T00:00:00Z", dsun: 210.522, hgln: -0.183462, hglt: 3.006114 },
  { isoDate: "2015-11-15T00:00:00Z", dsun: 210.464354, hgln: -0.177333, hglt: 2.888497 },
  { isoDate: "2015-11-16T00:00:00Z", dsun: 210.407701, hgln: -0.170943, hglt: 2.769934 },
  { isoDate: "2015-11-17T00:00:00Z", dsun: 210.352074, hgln: -0.164287, hglt: 2.650465 },
  { isoDate: "2015-11-18T00:00:00Z", dsun: 210.297505, hgln: -0.157361, hglt: 2.530126 },
  { isoDate: "2015-11-19T00:00:00Z", dsun: 210.244025, hgln: -0.150165, hglt: 2.408956 },
  { isoDate: "2015-11-20T00:00:00Z", dsun: 210.191667, hgln: -0.142701, hglt: 2.286993 },
  { isoDate: "2015-11-21T00:00:00Z", dsun: 210.140461, hgln: -0.134978, hglt: 2.164277 },
  { isoDate: "2015-11-22T00:00:00Z", dsun: 210.090437, hgln: -0.127006, hglt: 2.040846 },
  { isoDate: "2015-11-23T00:00:00Z", dsun: 210.041624, hgln: -0.118804, hglt: 1.916741 },
  { isoDate: "2015-11-24T00:00:00Z", dsun: 209.994053, hgln: -0.110395, hglt: 1.792002 },
  { isoDate: "2015-11-25T00:00:00Z", dsun: 209.947749, hgln: -0.101807, hglt: 1.66667 },
  { isoDate: "2015-11-26T00:00:00Z", dsun: 209.90274, hgln: -0.093071, hglt: 1.540785 },
  { isoDate: "2015-11-27T00:00:00Z", dsun: 209.859051, hgln: -0.084218, hglt: 1.414389 },
  { isoDate: "2015-11-28T00:00:00Z", dsun: 209.816705, hgln: -0.075278, hglt: 1.287523 },
  { isoDate: "2015-11-29T00:00:00Z", dsun: 209.775722, hgln: -0.066282, hglt: 1.160229 },
  { isoDate: "2015-11-30T00:00:00Z", dsun: 209.736125, hgln: -0.057252, hglt: 1.032549 },
  { isoDate: "2015-12-01T00:00:00Z", dsun: 209.69793, hgln: -0.048211, hglt: 0.904525 },
  { isoDate: "2015-12-02T00:00:00Z", dsun: 209.661156, hgln: -0.039175, hglt: 0.776199 },
  { isoDate: "2015-12-03T00:00:00Z", dsun: 209.625817, hgln: -0.03016, hglt: 0.647615 },
  { isoDate: "2015-12-04T00:00:00Z", dsun: 209.591929, hgln: -0.021177, hglt: 0.518814 },
  { isoDate: "2015-12-05T00:00:00Z", dsun: 209.559504, hgln: -0.012236, hglt: 0.38984 },
  { isoDate: "2015-12-06T00:00:00Z", dsun: 209.528555, hgln: -0.003344, hglt: 0.260735 },
  { isoDate: "2015-12-07T00:00:00Z", dsun: 209.499093, hgln: 0.005493, hglt: 0.131543 },
  { isoDate: "2015-12-08T00:00:00Z", dsun: 209.471128, hgln: 0.014271, hglt: 0.002307 },
  { isoDate: "2015-12-09T00:00:00Z", dsun: 209.444669, hgln: 0.022988, hglt: -0.12693 },
  { isoDate: "2015-12-10T00:00:00Z", dsun: 209.419724, hgln: 0.031645, hglt: -0.256124 },
  { isoDate: "2015-12-11T00:00:00Z", dsun: 209.396304, hgln: 0.040242, hglt: -0.385233 },
  { isoDate: "2015-12-12T00:00:00Z", dsun: 209.374418, hgln: 0.048784, hglt: -0.514212 },
  { isoDate: "2015-12-13T00:00:00Z", dsun: 209.354057, hgln: 0.057274, hglt: -0.643018 },
  { isoDate: "2015-12-14T00:00:00Z", dsun: 209.33522, hgln: 0.065719, hglt: -0.771609 },
  { isoDate: "2015-12-15T00:00:00Z", dsun: 209.317903, hgln: 0.074124, hglt: -0.899941 },
  { isoDate: "2015-12-16T00:00:00Z", dsun: 209.302104, hgln: 0.082494, hglt: -1.027971 },
  { isoDate: "2015-12-17T00:00:00Z", dsun: 209.287818, hgln: 0.090831, hglt: -1.155657 },
  { isoDate: "2015-12-18T00:00:00Z", dsun: 209.275038, hgln: 0.099136, hglt: -1.282956 },
  { isoDate: "2015-12-19T00:00:00Z", dsun: 209.263758, hgln: 0.107404, hglt: -1.409825 },
  { isoDate: "2015-12-20T00:00:00Z", dsun: 209.253972, hgln: 0.115627, hglt: -1.536223 },
  { isoDate: "2015-12-21T00:00:00Z", dsun: 209.245671, hgln: 0.123792, hglt: -1.662106 },
  { isoDate: "2015-12-22T00:00:00Z", dsun: 209.238845, hgln: 0.131881, hglt: -1.787432 },
  { isoDate: "2015-12-23T00:00:00Z", dsun: 209.233483, hgln: 0.139871, hglt: -1.912161 },
  { isoDate: "2015-12-24T00:00:00Z", dsun: 209.229574, hgln: 0.147736, hglt: -2.036251 },
  { isoDate: "2015-12-25T00:00:00Z", dsun: 209.227102, hgln: 0.155448, hglt: -2.159659 },
  { isoDate: "2015-12-26T00:00:00Z", dsun: 209.226055, hgln: 0.162978, hglt: -2.282347 },
  { isoDate: "2015-12-27T00:00:00Z", dsun: 209.226414, hgln: 0.170297, hglt: -2.404272 },
  { isoDate: "2015-12-28T00:00:00Z", dsun: 209.228163, hgln: 0.177379, hglt: -2.525395 },
  { isoDate: "2015-12-29T00:00:00Z", dsun: 209.231282, hgln: 0.184201, hglt: -2.645676 },
  { isoDate: "2015-12-30T00:00:00Z", dsun: 209.235753, hgln: 0.190744, hglt: -2.765076 },
  { isoDate: "2015-12-31T00:00:00Z", dsun: 209.241556, hgln: 0.196989, hglt: -2.883556 },
  { isoDate: "2016-01-01T00:00:00Z", dsun: 209.248669, hgln: 0.202925, hglt: -3.001076 },
  { isoDate: "2016-01-02T00:00:00Z", dsun: 209.257071, hgln: 0.208538, hglt: -3.1176 },
  { isoDate: "2016-01-03T00:00:00Z", dsun: 209.266742, hgln: 0.213822, hglt: -3.233089 },
  { isoDate: "2016-01-04T00:00:00Z", dsun: 209.27766, hgln: 0.218771, hglt: -3.347505 },
  { isoDate: "2016-01-05T00:00:00Z", dsun: 209.289803, hgln: 0.223379, hglt: -3.460813 },
  { isoDate: "2016-01-06T00:00:00Z", dsun: 209.30315, hgln: 0.227646, hglt: -3.572976 },
  { isoDate: "2016-01-07T00:00:00Z", dsun: 209.317679, hgln: 0.231574, hglt: -3.683958 },
  { isoDate: "2016-01-08T00:00:00Z", dsun: 209.333367, hgln: 0.235165, hglt: -3.793723 },
  { isoDate: "2016-01-09T00:00:00Z", dsun: 209.350189, hgln: 0.238428, hglt: -3.902237 },
  { isoDate: "2016-01-10T00:00:00Z", dsun: 209.36812, hgln: 0.241372, hglt: -4.009465 },
  { isoDate: "2016-01-11T00:00:00Z", dsun: 209.387134, hgln: 0.24401, hglt: -4.115375 },
  { isoDate: "2016-01-12T00:00:00Z", dsun: 209.407315, hgln: 0.246342, hglt: -4.219927 },
  { isoDate: "2016-01-13T00:00:00Z", dsun: 209.428419, hgln: 0.248413, hglt: -4.3231 },
  { isoDate: "2016-01-14T00:00:00Z", dsun: 209.450527, hgln: 0.250223, hglt: -4.424857 },
  { isoDate: "2016-01-15T00:00:00Z", dsun: 209.473613, hgln: 0.251783, hglt: -4.525167 },
  { isoDate: "2016-01-16T00:00:00Z", dsun: 209.497654, hgln: 0.253104, hglt: -4.624 },
  { isoDate: "2016-01-17T00:00:00Z", dsun: 209.522624, hgln: 0.254188, hglt: -4.721325 },
  { isoDate: "2016-01-18T00:00:00Z", dsun: 209.548502, hgln: 0.255036, hglt: -4.817113 },
  { isoDate: "2016-01-19T00:00:00Z", dsun: 209.575263, hgln: 0.255642, hglt: -4.911336 },
  { isoDate: "2016-01-20T00:00:00Z", dsun: 209.602885, hgln: 0.255996, hglt: -5.003966 },
  { isoDate: "2016-01-21T00:00:00Z", dsun: 209.631345, hgln: 0.256086, hglt: -5.094975 },
  { isoDate: "2016-01-22T00:00:00Z", dsun: 209.66062, hgln: 0.255896, hglt: -5.184337 },
  { isoDate: "2016-01-23T00:00:00Z", dsun: 209.690688, hgln: 0.255407, hglt: -5.272027 },
  { isoDate: "2016-01-24T00:00:00Z", dsun: 209.721526, hgln: 0.254601, hglt: -5.358018 },
  { isoDate: "2016-01-25T00:00:00Z", dsun: 209.753112, hgln: 0.253461, hglt: -5.442286 },
  { isoDate: "2016-01-26T00:00:00Z", dsun: 209.785426, hgln: 0.251969, hglt: -5.524809 },
  { isoDate: "2016-01-27T00:00:00Z", dsun: 209.818446, hgln: 0.250112, hglt: -5.605563 },
  { isoDate: "2016-01-28T00:00:00Z", dsun: 209.852153, hgln: 0.247878, hglt: -5.684526 },
  { isoDate: "2016-01-29T00:00:00Z", dsun: 209.886527, hgln: 0.245257, hglt: -5.761676 },
  { isoDate: "2016-01-30T00:00:00Z", dsun: 209.92155, hgln: 0.242242, hglt: -5.836993 },
  { isoDate: "2016-01-31T00:00:00Z", dsun: 209.957207, hgln: 0.23883, hglt: -5.910457 },
  { isoDate: "2016-02-01T00:00:00Z", dsun: 209.993482, hgln: 0.235019, hglt: -5.982049 },
  { isoDate: "2016-02-02T00:00:00Z", dsun: 210.03036, hgln: 0.230809, hglt: -6.051751 },
  { isoDate: "2016-02-03T00:00:00Z", dsun: 210.067829, hgln: 0.226203, hglt: -6.119545 },
  { isoDate: "2016-02-04T00:00:00Z", dsun: 210.105881, hgln: 0.221208, hglt: -6.185413 },
  { isoDate: "2016-02-05T00:00:00Z", dsun: 210.14451, hgln: 0.215832, hglt: -6.249341 },
  { isoDate: "2016-02-06T00:00:00Z", dsun: 210.183707, hgln: 0.210088, hglt: -6.311313 },
  { isoDate: "2016-02-07T00:00:00Z", dsun: 210.223465, hgln: 0.203992, hglt: -6.371313 },
  { isoDate: "2016-02-08T00:00:00Z", dsun: 210.263776, hgln: 0.197564, hglt: -6.429329 },
  { isoDate: "2016-02-09T00:00:00Z", dsun: 210.304632, hgln: 0.190829, hglt: -6.485348 },
  { isoDate: "2016-02-10T00:00:00Z", dsun: 210.346025, hgln: 0.183815, hglt: -6.539357 },
  { isoDate: "2016-02-11T00:00:00Z", dsun: 210.387947, hgln: 0.176549, hglt: -6.591345 },
  { isoDate: "2016-02-12T00:00:00Z", dsun: 210.430391, hgln: 0.169062, hglt: -6.641301 },
  { isoDate: "2016-02-13T00:00:00Z", dsun: 210.473354, hgln: 0.161378, hglt: -6.689216 },
  { isoDate: "2016-02-14T00:00:00Z", dsun: 210.516833, hgln: 0.15352, hglt: -6.735079 },
  { isoDate: "2016-02-15T00:00:00Z", dsun: 210.560828, hgln: 0.145506, hglt: -6.778882 },
  { isoDate: "2016-02-16T00:00:00Z", dsun: 210.60534, hgln: 0.137348, hglt: -6.820618 },
  { isoDate: "2016-02-17T00:00:00Z", dsun: 210.650369, hgln: 0.129055, hglt: -6.860279 },
  { isoDate: "2016-02-18T00:00:00Z", dsun: 210.695918, hgln: 0.120631, hglt: -6.897859 },
  { isoDate: "2016-02-19T00:00:00Z", dsun: 210.741987, hgln: 0.112078, hglt: -6.933351 },
  { isoDate: "2016-02-20T00:00:00Z", dsun: 210.788577, hgln: 0.103398, hglt: -6.96675 },
  { isoDate: "2016-02-21T00:00:00Z", dsun: 210.83569, hgln: 0.094588, hglt: -6.998051 },
  { isoDate: "2016-02-22T00:00:00Z", dsun: 210.883323, hgln: 0.085648, hglt: -7.027251 },
  { isoDate: "2016-02-23T00:00:00Z", dsun: 210.931476, hgln: 0.076575, hglt: -7.054345 },
  { isoDate: "2016-02-24T00:00:00Z", dsun: 210.980144, hgln: 0.067371, hglt: -7.07933 },
  { isoDate: "2016-02-25T00:00:00Z", dsun: 211.029324, hgln: 0.058036, hglt: -7.102204 },
  { isoDate: "2016-02-26T00:00:00Z", dsun: 211.079008, hgln: 0.048573, hglt: -7.122964 },
  { isoDate: "2016-02-27T00:00:00Z", dsun: 211.129189, hgln: 0.038986, hglt: -7.141609 },
  { isoDate: "2016-02-28T00:00:00Z", dsun: 211.179855, hgln: 0.029283, hglt: -7.158136 },
  { isoDate: "2016-02-29T00:00:00Z", dsun: 211.230995, hgln: 0.019472, hglt: -7.172545 },
  { isoDate: "2016-03-01T00:00:00Z", dsun: 211.282595, hgln: 0.009565, hglt: -7.184836 },
  { isoDate: "2016-03-02T00:00:00Z", dsun: 211.33464, hgln: -0.000426, hglt: -7.195007 },
  { isoDate: "2016-03-03T00:00:00Z", dsun: 211.387113, hgln: -0.010487, hglt: -7.203059 },
  { isoDate: "2016-03-04T00:00:00Z", dsun: 211.439996, hgln: -0.020598, hglt: -7.208993 },
  { isoDate: "2016-03-05T00:00:00Z", dsun: 211.493271, hgln: -0.03074, hglt: -7.212809 },
  { isoDate: "2016-03-06T00:00:00Z", dsun: 211.546917, hgln: -0.040888, hglt: -7.214509 },
  { isoDate: "2016-03-07T00:00:00Z", dsun: 211.600914, hgln: -0.051016, hglt: -7.214094 },
  { isoDate: "2016-03-08T00:00:00Z", dsun: 211.655743, hgln: -0.061165, hglt: -7.211574 },
  { isoDate: "2016-03-09T00:00:00Z", dsun: 211.710409, hgln: -0.071155, hglt: -7.206938 },
  { isoDate: "2016-03-10T00:00:00Z", dsun: 211.765362, hgln: -0.08102, hglt: -7.200195 },
  { isoDate: "2016-03-11T00:00:00Z", dsun: 211.820574, hgln: -0.090723, hglt: -7.191349 },
  { isoDate: "2016-03-12T00:00:00Z", dsun: 211.876015, hgln: -0.100229, hglt: -7.180403 },
  { isoDate: "2016-03-13T00:00:00Z", dsun: 211.931652, hgln: -0.109505, hglt: -7.167361 },
  { isoDate: "2016-03-14T00:00:00Z", dsun: 211.98745, hgln: -0.118525, hglt: -7.152227 },
  { isoDate: "2016-03-15T00:00:00Z", dsun: 212.043376, hgln: -0.127269, hglt: -7.135008 },
  { isoDate: "2016-03-16T00:00:00Z", dsun: 212.099397, hgln: -0.135722, hglt: -7.115707 },
  { isoDate: "2016-03-17T00:00:00Z", dsun: 212.155483, hgln: -0.143873, hglt: -7.094332 },
  { isoDate: "2016-03-18T00:00:00Z", dsun: 212.211603, hgln: -0.151718, hglt: -7.070889 },
  { isoDate: "2016-03-19T00:00:00Z", dsun: 212.267728, hgln: -0.159255, hglt: -7.045384 },
  { isoDate: "2016-03-20T00:00:00Z", dsun: 212.32383, hgln: -0.166484, hglt: -7.017826 },
  { isoDate: "2016-03-21T00:00:00Z", dsun: 212.379883, hgln: -0.173409, hglt: -6.988224 },
  { isoDate: "2016-03-22T00:00:00Z", dsun: 212.43586, hgln: -0.180036, hglt: -6.956585 },
  { isoDate: "2016-03-23T00:00:00Z", dsun: 212.491736, hgln: -0.18637, hglt: -6.92292 },
  { isoDate: "2016-03-24T00:00:00Z", dsun: 212.547488, hgln: -0.19242, hglt: -6.887239 },
  { isoDate: "2016-03-25T00:00:00Z", dsun: 212.603091, hgln: -0.198193, hglt: -6.849551 },
  { isoDate: "2016-03-26T00:00:00Z", dsun: 212.658524, hgln: -0.203697, hglt: -6.809869 },
  { isoDate: "2016-03-27T00:00:00Z", dsun: 212.713765, hgln: -0.20894, hglt: -6.768205 },
  { isoDate: "2016-03-28T00:00:00Z", dsun: 212.768792, hgln: -0.213929, hglt: -6.724571 },
  { isoDate: "2016-03-29T00:00:00Z", dsun: 212.823587, hgln: -0.218672, hglt: -6.678979 },
  { isoDate: "2016-03-30T00:00:00Z", dsun: 212.87813, hgln: -0.223173, hglt: -6.631444 },
  { isoDate: "2016-03-31T00:00:00Z", dsun: 212.932404, hgln: -0.227438, hglt: -6.58198 },
  { isoDate: "2016-04-01T00:00:00Z", dsun: 212.986392, hgln: -0.231468, hglt: -6.530601 },
  { isoDate: "2016-04-02T00:00:00Z", dsun: 213.040078, hgln: -0.235265, hglt: -6.477324 },
  { isoDate: "2016-04-03T00:00:00Z", dsun: 213.093449, hgln: -0.238826, hglt: -6.422163 },
  { isoDate: "2016-04-04T00:00:00Z", dsun: 213.146492, hgln: -0.242145, hglt: -6.365136 },
  { isoDate: "2016-04-05T00:00:00Z", dsun: 213.199196, hgln: -0.245213, hglt: -6.30626 },
  { isoDate: "2016-04-06T00:00:00Z", dsun: 213.251553, hgln: -0.248013, hglt: -6.245552 },
  { isoDate: "2016-04-07T00:00:00Z", dsun: 213.303555, hgln: -0.250527, hglt: -6.183031 },
  { isoDate: "2016-04-08T00:00:00Z", dsun: 213.355196, hgln: -0.252732, hglt: -6.118716 },
  { isoDate: "2016-04-09T00:00:00Z", dsun: 213.406473, hgln: -0.254604, hglt: -6.052626 },
  { isoDate: "2016-04-10T00:00:00Z", dsun: 213.457383, hgln: -0.256123, hglt: -5.98478 },
  { isoDate: "2016-04-11T00:00:00Z", dsun: 213.50792, hgln: -0.257269, hglt: -5.9152 },
  { isoDate: "2016-04-12T00:00:00Z", dsun: 213.558087, hgln: -0.258032, hglt: -5.843903 },
  { isoDate: "2016-04-13T00:00:00Z", dsun: 213.607869, hgln: -0.258403, hglt: -5.770917 },
  { isoDate: "2016-04-14T00:00:00Z", dsun: 213.657268, hgln: -0.258379, hglt: -5.696261 },
  { isoDate: "2016-04-15T00:00:00Z", dsun: 213.706282, hgln: -0.257964, hglt: -5.619957 },
  { isoDate: "2016-04-16T00:00:00Z", dsun: 213.754908, hgln: -0.257164, hglt: -5.542029 },
  { isoDate: "2016-04-17T00:00:00Z", dsun: 213.803148, hgln: -0.255986, hglt: -5.462499 },
  { isoDate: "2016-04-18T00:00:00Z", dsun: 213.851003, hgln: -0.254442, hglt: -5.381391 },
  { isoDate: "2016-04-19T00:00:00Z", dsun: 213.898474, hgln: -0.252544, hglt: -5.29873 },
  { isoDate: "2016-04-20T00:00:00Z", dsun: 213.945564, hgln: -0.250307, hglt: -5.21454 },
  { isoDate: "2016-04-21T00:00:00Z", dsun: 213.992277, hgln: -0.247747, hglt: -5.128847 },
  { isoDate: "2016-04-22T00:00:00Z", dsun: 214.038617, hgln: -0.24488, hglt: -5.041676 },
  { isoDate: "2016-04-23T00:00:00Z", dsun: 214.084588, hgln: -0.241723, hglt: -4.953054 },
  { isoDate: "2016-04-24T00:00:00Z", dsun: 214.130193, hgln: -0.238295, hglt: -4.863006 },
  { isoDate: "2016-04-25T00:00:00Z", dsun: 214.175439, hgln: -0.234613, hglt: -4.77156 },
  { isoDate: "2016-04-26T00:00:00Z", dsun: 214.220329, hgln: -0.230695, hglt: -4.678742 },
  { isoDate: "2016-04-27T00:00:00Z", dsun: 214.264867, hgln: -0.226558, hglt: -4.584581 },
  { isoDate: "2016-04-28T00:00:00Z", dsun: 214.309058, hgln: -0.222219, hglt: -4.489104 },
  { isoDate: "2016-04-29T00:00:00Z", dsun: 214.352907, hgln: -0.217692, hglt: -4.39234 },
  { isoDate: "2016-04-30T00:00:00Z", dsun: 214.396416, hgln: -0.212991, hglt: -4.294318 },
  { isoDate: "2016-05-01T00:00:00Z", dsun: 214.439591, hgln: -0.208127, hglt: -4.195065 },
  { isoDate: "2016-05-02T00:00:00Z", dsun: 214.482436, hgln: -0.203107, hglt: -4.094613 },
  { isoDate: "2016-05-03T00:00:00Z", dsun: 214.524955, hgln: -0.197934, hglt: -3.99299 },
  { isoDate: "2016-05-04T00:00:00Z", dsun: 214.567153, hgln: -0.192608, hglt: -3.890227 },
  { isoDate: "2016-05-05T00:00:00Z", dsun: 214.609035, hgln: -0.187122, hglt: -3.786353 },
  { isoDate: "2016-05-06T00:00:00Z", dsun: 214.650606, hgln: -0.181464, hglt: -3.681399 },
  { isoDate: "2016-05-07T00:00:00Z", dsun: 214.691873, hgln: -0.175621, hglt: -3.575397 },
  { isoDate: "2016-05-08T00:00:00Z", dsun: 214.73284, hgln: -0.169577, hglt: -3.468376 },
  { isoDate: "2016-05-09T00:00:00Z", dsun: 214.773514, hgln: -0.163317, hglt: -3.36037 },
  { isoDate: "2016-05-10T00:00:00Z", dsun: 214.813887, hgln: -0.156826, hglt: -3.251405 },
  { isoDate: "2016-05-11T00:00:00Z", dsun: 214.85398, hgln: -0.150106, hglt: -3.141521 },
  { isoDate: "2016-05-12T00:00:00Z", dsun: 214.893785, hgln: -0.143151, hglt: -3.030746 },
  { isoDate: "2016-05-13T00:00:00Z", dsun: 214.933304, hgln: -0.135964, hglt: -2.919113 },
  { isoDate: "2016-05-14T00:00:00Z", dsun: 214.972538, hgln: -0.128551, hglt: -2.806654 },
  { isoDate: "2016-05-15T00:00:00Z", dsun: 215.011487, hgln: -0.120923, hglt: -2.693402 },
  { isoDate: "2016-05-16T00:00:00Z", dsun: 215.050154, hgln: -0.113093, hglt: -2.579391 },
  { isoDate: "2016-05-17T00:00:00Z", dsun: 215.088537, hgln: -0.105074, hglt: -2.464652 },
  { isoDate: "2016-05-18T00:00:00Z", dsun: 215.126639, hgln: -0.096884, hglt: -2.34922 },
  { isoDate: "2016-05-19T00:00:00Z", dsun: 215.164459, hgln: -0.088541, hglt: -2.233128 },
  { isoDate: "2016-05-20T00:00:00Z", dsun: 215.201995, hgln: -0.080065, hglt: -2.116409 },
  { isoDate: "2016-05-21T00:00:00Z", dsun: 215.239248, hgln: -0.071478, hglt: -1.999097 },
  { isoDate: "2016-05-22T00:00:00Z", dsun: 215.276215, hgln: -0.062802, hglt: -1.881227 },
  { isoDate: "2016-05-23T00:00:00Z", dsun: 215.312892, hgln: -0.054059, hglt: -1.762832 },
  { isoDate: "2016-05-24T00:00:00Z", dsun: 215.349275, hgln: -0.045275, hglt: -1.643946 },
  { isoDate: "2016-05-25T00:00:00Z", dsun: 215.38536, hgln: -0.036472, hglt: -1.524604 },
  { isoDate: "2016-05-26T00:00:00Z", dsun: 215.42114, hgln: -0.027674, hglt: -1.404839 },
  { isoDate: "2016-05-27T00:00:00Z", dsun: 215.456609, hgln: -0.018905, hglt: -1.284688 },
  { isoDate: "2016-05-28T00:00:00Z", dsun: 215.491759, hgln: -0.010184, hglt: -1.164182 },
  { isoDate: "2016-05-29T00:00:00Z", dsun: 215.526581, hgln: -0.00153, hglt: -1.043359 },
  { isoDate: "2016-05-30T00:00:00Z", dsun: 215.561066, hgln: 0.00704, hglt: -0.922251 },
  { isoDate: "2016-05-31T00:00:00Z", dsun: 215.595205, hgln: 0.015515, hglt: -0.800893 },
  { isoDate: "2016-06-01T00:00:00Z", dsun: 215.628988, hgln: 0.023887, hglt: -0.679321 },
  { isoDate: "2016-06-02T00:00:00Z", dsun: 215.662406, hgln: 0.032155, hglt: -0.557568 },
  { isoDate: "2016-06-03T00:00:00Z", dsun: 215.695447, hgln: 0.040321, hglt: -0.435669 },
  { isoDate: "2016-06-04T00:00:00Z", dsun: 215.728101, hgln: 0.048393, hglt: -0.313659 },
  { isoDate: "2016-06-05T00:00:00Z", dsun: 215.760356, hgln: 0.056382, hglt: -0.191572 },
  { isoDate: "2016-06-06T00:00:00Z", dsun: 215.792199, hgln: 0.064301, hglt: -0.069443 },
  { isoDate: "2016-06-07T00:00:00Z", dsun: 215.823615, hgln: 0.072161, hglt: 0.052693 },
  { isoDate: "2016-06-08T00:00:00Z", dsun: 215.854588, hgln: 0.079971, hglt: 0.174803 },
  { isoDate: "2016-06-09T00:00:00Z", dsun: 215.885101, hgln: 0.087739, hglt: 0.296851 },
  { isoDate: "2016-06-10T00:00:00Z", dsun: 215.915136, hgln: 0.095467, hglt: 0.418803 },
  { isoDate: "2016-06-11T00:00:00Z", dsun: 215.944677, hgln: 0.103153, hglt: 0.540626 },
  { isoDate: "2016-06-12T00:00:00Z", dsun: 215.973705, hgln: 0.110792, hglt: 0.662285 },
  { isoDate: "2016-06-13T00:00:00Z", dsun: 216.002204, hgln: 0.118379, hglt: 0.783746 },
  { isoDate: "2016-06-14T00:00:00Z", dsun: 216.030156, hgln: 0.125902, hglt: 0.904976 },
  { isoDate: "2016-06-15T00:00:00Z", dsun: 216.057542, hgln: 0.13335, hglt: 1.025939 },
  { isoDate: "2016-06-16T00:00:00Z", dsun: 216.084345, hgln: 0.140709, hglt: 1.146604 },
  { isoDate: "2016-06-17T00:00:00Z", dsun: 216.110544, hgln: 0.147964, hglt: 1.266936 },
  { isoDate: "2016-06-18T00:00:00Z", dsun: 216.136122, hgln: 0.155099, hglt: 1.386902 },
  { isoDate: "2016-06-19T00:00:00Z", dsun: 216.161056, hgln: 0.162094, hglt: 1.506468 },
  { isoDate: "2016-06-20T00:00:00Z", dsun: 216.185328, hgln: 0.16893, hglt: 1.625602 },
  { isoDate: "2016-06-21T00:00:00Z", dsun: 216.208915, hgln: 0.175587, hglt: 1.744271 },
  { isoDate: "2016-06-22T00:00:00Z", dsun: 216.231797, hgln: 0.182041, hglt: 1.862441 },
  { isoDate: "2016-06-23T00:00:00Z", dsun: 216.253951, hgln: 0.18827, hglt: 1.980081 },
  { isoDate: "2016-06-24T00:00:00Z", dsun: 216.275354, hgln: 0.194254, hglt: 2.097159 },
  { isoDate: "2016-06-25T00:00:00Z", dsun: 216.295986, hgln: 0.19997, hglt: 2.213641 },
  { isoDate: "2016-06-26T00:00:00Z", dsun: 216.315824, hgln: 0.2054, hglt: 2.329496 },
  { isoDate: "2016-06-27T00:00:00Z", dsun: 216.334846, hgln: 0.210529, hglt: 2.444693 },
  { isoDate: "2016-06-28T00:00:00Z", dsun: 216.353029, hgln: 0.215344, hglt: 2.559199 },
  { isoDate: "2016-06-29T00:00:00Z", dsun: 216.370354, hgln: 0.219837, hglt: 2.672984 },
  { isoDate: "2016-06-30T00:00:00Z", dsun: 216.386801, hgln: 0.224006, hglt: 2.786017 },
  { isoDate: "2016-07-01T00:00:00Z", dsun: 216.402348, hgln: 0.227854, hglt: 2.898266 },
  { isoDate: "2016-07-02T00:00:00Z", dsun: 216.416977, hgln: 0.231387, hglt: 3.009701 },
  { isoDate: "2016-07-03T00:00:00Z", dsun: 216.430667, hgln: 0.234618, hglt: 3.120293 },
  { isoDate: "2016-07-04T00:00:00Z", dsun: 216.443398, hgln: 0.237562, hglt: 3.23001 },
  { isoDate: "2016-07-05T00:00:00Z", dsun: 216.45515, hgln: 0.240234, hglt: 3.338822 },
  { isoDate: "2016-07-06T00:00:00Z", dsun: 216.465899, hgln: 0.242651, hglt: 3.446701 },
  { isoDate: "2016-07-07T00:00:00Z", dsun: 216.475627, hgln: 0.244828, hglt: 3.553617 },
  { isoDate: "2016-07-08T00:00:00Z", dsun: 216.484312, hgln: 0.246777, hglt: 3.65954 },
  { isoDate: "2016-07-09T00:00:00Z", dsun: 216.491936, hgln: 0.248508, hglt: 3.764443 },
  { isoDate: "2016-07-10T00:00:00Z", dsun: 216.498481, hgln: 0.250027, hglt: 3.868296 },
  { isoDate: "2016-07-11T00:00:00Z", dsun: 216.50393, hgln: 0.251336, hglt: 3.971071 },
  { isoDate: "2016-07-12T00:00:00Z", dsun: 216.508308, hgln: 0.252433, hglt: 4.072742 },
  { isoDate: "2016-07-13T00:00:00Z", dsun: 216.511519, hgln: 0.253326, hglt: 4.173278 },
  { isoDate: "2016-07-14T00:00:00Z", dsun: 216.513589, hgln: 0.254006, hglt: 4.272654 },
  { isoDate: "2016-07-15T00:00:00Z", dsun: 216.514503, hgln: 0.254468, hglt: 4.370841 },
  { isoDate: "2016-07-16T00:00:00Z", dsun: 216.514248, hgln: 0.254706, hglt: 4.467814 },
  { isoDate: "2016-07-17T00:00:00Z", dsun: 216.512813, hgln: 0.254709, hglt: 4.563546 },
  { isoDate: "2016-07-18T00:00:00Z", dsun: 216.510185, hgln: 0.254468, hglt: 4.658009 },
  { isoDate: "2016-07-19T00:00:00Z", dsun: 216.506352, hgln: 0.253969, hglt: 4.75118 },
  { isoDate: "2016-07-20T00:00:00Z", dsun: 216.501305, hgln: 0.253196, hglt: 4.843031 },
  { isoDate: "2016-07-21T00:00:00Z", dsun: 216.495033, hgln: 0.252132, hglt: 4.933537 },
  { isoDate: "2016-07-22T00:00:00Z", dsun: 216.487528, hgln: 0.25076, hglt: 5.022673 },
  { isoDate: "2016-07-23T00:00:00Z", dsun: 216.478782, hgln: 0.249062, hglt: 5.110416 },
  { isoDate: "2016-07-24T00:00:00Z", dsun: 216.468788, hgln: 0.247022, hglt: 5.196739 },
  { isoDate: "2016-07-25T00:00:00Z", dsun: 216.45754, hgln: 0.244627, hglt: 5.28162 },
  { isoDate: "2016-07-26T00:00:00Z", dsun: 216.445036, hgln: 0.241865, hglt: 5.365034 },
  { isoDate: "2016-07-27T00:00:00Z", dsun: 216.431273, hgln: 0.238731, hglt: 5.446957 },
  { isoDate: "2016-07-28T00:00:00Z", dsun: 216.416251, hgln: 0.235226, hglt: 5.527368 },
  { isoDate: "2016-07-29T00:00:00Z", dsun: 216.399973, hgln: 0.231352, hglt: 5.606242 },
  { isoDate: "2016-07-30T00:00:00Z", dsun: 216.382443, hgln: 0.227119, hglt: 5.683558 },
  { isoDate: "2016-07-31T00:00:00Z", dsun: 216.363665, hgln: 0.222542, hglt: 5.759293 },
  { isoDate: "2016-08-01T00:00:00Z", dsun: 216.343646, hgln: 0.217637, hglt: 5.833424 },
  { isoDate: "2016-08-02T00:00:00Z", dsun: 216.322393, hgln: 0.212424, hglt: 5.905931 },
  { isoDate: "2016-08-03T00:00:00Z", dsun: 216.29991, hgln: 0.206925, hglt: 5.976793 },
  { isoDate: "2016-08-04T00:00:00Z", dsun: 216.276206, hgln: 0.201162, hglt: 6.045988 },
  { isoDate: "2016-08-05T00:00:00Z", dsun: 216.251288, hgln: 0.195155, hglt: 6.113496 },
  { isoDate: "2016-08-06T00:00:00Z", dsun: 216.225164, hgln: 0.188924, hglt: 6.179298 },
  { isoDate: "2016-08-07T00:00:00Z", dsun: 216.197847, hgln: 0.182487, hglt: 6.243373 },
  { isoDate: "2016-08-08T00:00:00Z", dsun: 216.16935, hgln: 0.17586, hglt: 6.305703 },
  { isoDate: "2016-08-09T00:00:00Z", dsun: 216.139688, hgln: 0.169056, hglt: 6.366268 },
  { isoDate: "2016-08-10T00:00:00Z", dsun: 216.10888, hgln: 0.162088, hglt: 6.42505 },
  { isoDate: "2016-08-11T00:00:00Z", dsun: 216.076944, hgln: 0.154965, hglt: 6.482031 },
  { isoDate: "2016-08-12T00:00:00Z", dsun: 216.043899, hgln: 0.147695, hglt: 6.537193 },
  { isoDate: "2016-08-13T00:00:00Z", dsun: 216.009769, hgln: 0.140286, hglt: 6.590518 },
  { isoDate: "2016-08-14T00:00:00Z", dsun: 215.974574, hgln: 0.132742, hglt: 6.64199 },
  { isoDate: "2016-08-15T00:00:00Z", dsun: 215.938338, hgln: 0.125065, hglt: 6.691592 },
  { isoDate: "2016-08-16T00:00:00Z", dsun: 215.901084, hgln: 0.117255, hglt: 6.739308 },
  { isoDate: "2016-08-17T00:00:00Z", dsun: 215.862834, hgln: 0.109311, hglt: 6.785122 },
  { isoDate: "2016-08-18T00:00:00Z", dsun: 215.823612, hgln: 0.101226, hglt: 6.829019 },
  { isoDate: "2016-08-19T00:00:00Z", dsun: 215.78344, hgln: 0.092994, hglt: 6.870984 },
  { isoDate: "2016-08-20T00:00:00Z", dsun: 215.742341, hgln: 0.084606, hglt: 6.911004 },
  { isoDate: "2016-08-21T00:00:00Z", dsun: 215.700337, hgln: 0.076054, hglt: 6.949064 },
  { isoDate: "2016-08-22T00:00:00Z", dsun: 215.657447, hgln: 0.067332, hglt: 6.985152 },
  { isoDate: "2016-08-23T00:00:00Z", dsun: 215.613692, hgln: 0.058435, hglt: 7.019255 },
  { isoDate: "2016-08-24T00:00:00Z", dsun: 215.569091, hgln: 0.049366, hglt: 7.051361 },
  { isoDate: "2016-08-25T00:00:00Z", dsun: 215.523664, hgln: 0.040131, hglt: 7.081458 },
  { isoDate: "2016-08-26T00:00:00Z", dsun: 215.477429, hgln: 0.030743, hglt: 7.109535 },
  { isoDate: "2016-08-27T00:00:00Z", dsun: 215.430402, hgln: 0.021219, hglt: 7.135582 },
  { isoDate: "2016-08-28T00:00:00Z", dsun: 215.382603, hgln: 0.01158, hglt: 7.159589 },
  { isoDate: "2016-08-29T00:00:00Z", dsun: 215.334049, hgln: 0.001854, hglt: 7.181546 },
  { isoDate: "2016-08-30T00:00:00Z", dsun: 215.284758, hgln: -0.007931, hglt: 7.201444 },
  { isoDate: "2016-08-31T00:00:00Z", dsun: 215.234747, hgln: -0.017745, hglt: 7.219275 },
  { isoDate: "2016-09-01T00:00:00Z", dsun: 215.184032, hgln: -0.027556, hglt: 7.235032 },
  { isoDate: "2016-09-02T00:00:00Z", dsun: 215.132626, hgln: -0.037335, hglt: 7.248706 },
  { isoDate: "2016-09-03T00:00:00Z", dsun: 215.08054, hgln: -0.047052, hglt: 7.260291 },
  { isoDate: "2016-09-04T00:00:00Z", dsun: 215.027781, hgln: -0.05668, hglt: 7.269783 },
  { isoDate: "2016-09-05T00:00:00Z", dsun: 214.974352, hgln: -0.066191, hglt: 7.277175 },
  { isoDate: "2016-09-06T00:00:00Z", dsun: 214.920255, hgln: -0.075561, hglt: 7.282463 },
  { isoDate: "2016-09-07T00:00:00Z", dsun: 214.865492, hgln: -0.084767, hglt: 7.285643 },
  { isoDate: "2016-09-08T00:00:00Z", dsun: 214.810063, hgln: -0.093789, hglt: 7.286712 },
  { isoDate: "2016-09-09T00:00:00Z", dsun: 214.75397, hgln: -0.102608, hglt: 7.285667 },
  { isoDate: "2016-09-10T00:00:00Z", dsun: 214.697215, hgln: -0.111208, hglt: 7.282505 },
  { isoDate: "2016-09-11T00:00:00Z", dsun: 214.639802, hgln: -0.119574, hglt: 7.277225 },
  { isoDate: "2016-09-12T00:00:00Z", dsun: 214.581734, hgln: -0.127697, hglt: 7.269825 },
  { isoDate: "2016-09-13T00:00:00Z", dsun: 214.523007, hgln: -0.135569, hglt: 7.260303 },
  { isoDate: "2016-09-14T00:00:00Z", dsun: 214.463643, hgln: -0.143186, hglt: 7.248662 },
  { isoDate: "2016-09-15T00:00:00Z", dsun: 214.403638, hgln: -0.150551, hglt: 7.2349 },
  { isoDate: "2016-09-16T00:00:00Z", dsun: 214.343001, hgln: -0.157669, hglt: 7.21902 },
  { isoDate: "2016-09-17T00:00:00Z", dsun: 214.281736, hgln: -0.164549, hglt: 7.201022 },
  { isoDate: "2016-09-18T00:00:00Z", dsun: 214.219851, hgln: -0.171206, hglt: 7.180909 },
  { isoDate: "2016-09-19T00:00:00Z", dsun: 214.157354, hgln: -0.177653, hglt: 7.158684 },
  { isoDate: "2016-09-20T00:00:00Z", dsun: 214.094253, hgln: -0.183904, hglt: 7.134349 },
  { isoDate: "2016-09-21T00:00:00Z", dsun: 214.030558, hgln: -0.18997, hglt: 7.10791 },
  { isoDate: "2016-09-22T00:00:00Z", dsun: 213.966277, hgln: -0.195857, hglt: 7.07937 },
  { isoDate: "2016-09-23T00:00:00Z", dsun: 213.901422, hgln: -0.201566, hglt: 7.048734 },
  { isoDate: "2016-09-24T00:00:00Z", dsun: 213.836004, hgln: -0.207095, hglt: 7.016009 },
  { isoDate: "2016-09-25T00:00:00Z", dsun: 213.770037, hgln: -0.212436, hglt: 6.981201 },
  { isoDate: "2016-09-26T00:00:00Z", dsun: 213.703534, hgln: -0.217578, hglt: 6.944316 },
  { isoDate: "2016-09-27T00:00:00Z", dsun: 213.636512, hgln: -0.222506, hglt: 6.905362 },
  { isoDate: "2016-09-28T00:00:00Z", dsun: 213.56899, hgln: -0.227205, hglt: 6.864346 },
  { isoDate: "2016-09-29T00:00:00Z", dsun: 213.500987, hgln: -0.231656, hglt: 6.821278 },
  { isoDate: "2016-09-30T00:00:00Z", dsun: 213.432526, hgln: -0.235843, hglt: 6.776167 },
  { isoDate: "2016-10-01T00:00:00Z", dsun: 213.363631, hgln: -0.239748, hglt: 6.729022 },
  { isoDate: "2016-10-02T00:00:00Z", dsun: 213.294329, hgln: -0.243351, hglt: 6.679854 },
  { isoDate: "2016-10-03T00:00:00Z", dsun: 213.224645, hgln: -0.246639, hglt: 6.628674 },
  { isoDate: "2016-10-04T00:00:00Z", dsun: 213.154609, hgln: -0.249595, hglt: 6.575493 },
  { isoDate: "2016-10-05T00:00:00Z", dsun: 213.084248, hgln: -0.252206, hglt: 6.520325 },
  { isoDate: "2016-10-06T00:00:00Z", dsun: 213.01359, hgln: -0.254462, hglt: 6.463182 },
  { isoDate: "2016-10-07T00:00:00Z", dsun: 212.942662, hgln: -0.256352, hglt: 6.404078 },
  { isoDate: "2016-10-08T00:00:00Z", dsun: 212.871492, hgln: -0.257867, hglt: 6.343026 },
  { isoDate: "2016-10-09T00:00:00Z", dsun: 212.800112, hgln: -0.259002, hglt: 6.280042 },
  { isoDate: "2016-10-10T00:00:00Z", dsun: 212.72855, hgln: -0.259751, hglt: 6.215142 },
  { isoDate: "2016-10-11T00:00:00Z", dsun: 212.656843, hgln: -0.260113, hglt: 6.148346 },
  { isoDate: "2016-10-12T00:00:00Z", dsun: 212.585019, hgln: -0.26009, hglt: 6.079661 },
  { isoDate: "2016-10-13T00:00:00Z", dsun: 212.513116, hgln: -0.259688, hglt: 6.009109 },
  { isoDate: "2016-10-14T00:00:00Z", dsun: 212.441169, hgln: -0.258916, hglt: 5.936708 },
  { isoDate: "2016-10-15T00:00:00Z", dsun: 212.369216, hgln: -0.25779, hglt: 5.862477 },
  { isoDate: "2016-10-16T00:00:00Z", dsun: 212.297294, hgln: -0.256331, hglt: 5.786436 },
  { isoDate: "2016-10-17T00:00:00Z", dsun: 212.225442, hgln: -0.254563, hglt: 5.708604 },
  { isoDate: "2016-10-18T00:00:00Z", dsun: 212.153699, hgln: -0.25251, hglt: 5.629002 },
  { isoDate: "2016-10-19T00:00:00Z", dsun: 212.082102, hgln: -0.250198, hglt: 5.547652 },
  { isoDate: "2016-10-20T00:00:00Z", dsun: 212.010692, hgln: -0.247647, hglt: 5.464575 },
  { isoDate: "2016-10-21T00:00:00Z", dsun: 211.939506, hgln: -0.244874, hglt: 5.379794 },
  { isoDate: "2016-10-22T00:00:00Z", dsun: 211.868584, hgln: -0.241889, hglt: 5.293333 },
  { isoDate: "2016-10-23T00:00:00Z", dsun: 211.797964, hgln: -0.2387, hglt: 5.205216 },
  { isoDate: "2016-10-24T00:00:00Z", dsun: 211.727685, hgln: -0.235309, hglt: 5.115466 },
  { isoDate: "2016-10-25T00:00:00Z", dsun: 211.657786, hgln: -0.231714, hglt: 5.024109 },
  { isoDate: "2016-10-26T00:00:00Z", dsun: 211.588307, hgln: -0.227912, hglt: 4.931171 },
  { isoDate: "2016-10-27T00:00:00Z", dsun: 211.519287, hgln: -0.223899, hglt: 4.836678 },
  { isoDate: "2016-10-28T00:00:00Z", dsun: 211.450766, hgln: -0.219666, hglt: 4.740658 },
  { isoDate: "2016-10-29T00:00:00Z", dsun: 211.382784, hgln: -0.215207, hglt: 4.643139 },
  { isoDate: "2016-10-30T00:00:00Z", dsun: 211.315382, hgln: -0.210513, hglt: 4.544148 },
  { isoDate: "2016-10-31T00:00:00Z", dsun: 211.248601, hgln: -0.205578, hglt: 4.443715 },
  { isoDate: "2016-11-01T00:00:00Z", dsun: 211.182482, hgln: -0.200392, hglt: 4.341869 },
  { isoDate: "2016-11-02T00:00:00Z", dsun: 211.117064, hgln: -0.19495, hglt: 4.238642 },
  { isoDate: "2016-11-03T00:00:00Z", dsun: 211.052385, hgln: -0.189246, hglt: 4.134063 },
  { isoDate: "2016-11-04T00:00:00Z", dsun: 210.988482, hgln: -0.183274, hglt: 4.028164 },
  { isoDate: "2016-11-05T00:00:00Z", dsun: 210.925392, hgln: -0.17703, hglt: 3.920977 },
  { isoDate: "2016-11-06T00:00:00Z", dsun: 210.863148, hgln: -0.170513, hglt: 3.812534 },
  { isoDate: "2016-11-07T00:00:00Z", dsun: 210.801786, hgln: -0.163722, hglt: 3.70287 },
  { isoDate: "2016-11-08T00:00:00Z", dsun: 210.741331, hgln: -0.156652, hglt: 3.592012 },
  { isoDate: "2016-11-09T00:00:00Z", dsun: 210.681834, hgln: -0.149319, hglt: 3.480004 },
  { isoDate: "2016-11-10T00:00:00Z", dsun: 210.623319, hgln: -0.141725, hglt: 3.366875 },
  { isoDate: "2016-11-11T00:00:00Z", dsun: 210.565821, hgln: -0.133881, hglt: 3.252662 },
  { isoDate: "2016-11-12T00:00:00Z", dsun: 210.509371, hgln: -0.125805, hglt: 3.1374 },
  { isoDate: "2016-11-13T00:00:00Z", dsun: 210.454001, hgln: -0.117519, hglt: 3.021126 },
  { isoDate: "2016-11-14T00:00:00Z", dsun: 210.399742, hgln: -0.109053, hglt: 2.903875 },
  { isoDate: "2016-11-15T00:00:00Z", dsun: 210.346622, hgln: -0.100436, hglt: 2.785686 },
  { isoDate: "2016-11-16T00:00:00Z", dsun: 210.294669, hgln: -0.091705, hglt: 2.666595 },
  { isoDate: "2016-11-17T00:00:00Z", dsun: 210.243911, hgln: -0.08289, hglt: 2.546642 },
  { isoDate: "2016-11-18T00:00:00Z", dsun: 210.194372, hgln: -0.074024, hglt: 2.425864 },
  { isoDate: "2016-11-19T00:00:00Z", dsun: 210.146075, hgln: -0.065129, hglt: 2.3043 },
  { isoDate: "2016-11-20T00:00:00Z", dsun: 210.099042, hgln: -0.056226, hglt: 2.18199 },
  { isoDate: "2016-11-21T00:00:00Z", dsun: 210.053294, hgln: -0.047331, hglt: 2.058973 },
  { isoDate: "2016-11-22T00:00:00Z", dsun: 210.00885, hgln: -0.038454, hglt: 1.935288 },
  { isoDate: "2016-11-23T00:00:00Z", dsun: 209.965729, hgln: -0.029604, hglt: 1.810977 },
  { isoDate: "2016-11-24T00:00:00Z", dsun: 209.923948, hgln: -0.020785, hglt: 1.686079 },
  { isoDate: "2016-11-25T00:00:00Z", dsun: 209.883523, hgln: -0.012002, hglt: 1.560636 },
  { isoDate: "2016-11-26T00:00:00Z", dsun: 209.844471, hgln: -0.003255, hglt: 1.434689 },
  { isoDate: "2016-11-27T00:00:00Z", dsun: 209.806806, hgln: 0.005454, hglt: 1.308278 },
  { isoDate: "2016-11-28T00:00:00Z", dsun: 209.77054, hgln: 0.014126, hglt: 1.181446 },
  { isoDate: "2016-11-29T00:00:00Z", dsun: 209.735686, hgln: 0.022763, hglt: 1.054235 },
  { isoDate: "2016-11-30T00:00:00Z", dsun: 209.702254, hgln: 0.031365, hglt: 0.926686 },
  { isoDate: "2016-12-01T00:00:00Z", dsun: 209.67025, hgln: 0.039935, hglt: 0.798842 },
  { isoDate: "2016-12-02T00:00:00Z", dsun: 209.639679, hgln: 0.048477, hglt: 0.670745 },
  { isoDate: "2016-12-03T00:00:00Z", dsun: 209.610544, hgln: 0.056991, hglt: 0.542437 },
  { isoDate: "2016-12-04T00:00:00Z", dsun: 209.582847, hgln: 0.06548, hglt: 0.413961 },
  { isoDate: "2016-12-05T00:00:00Z", dsun: 209.556588, hgln: 0.073947, hglt: 0.285359 },
  { isoDate: "2016-12-06T00:00:00Z", dsun: 209.531767, hgln: 0.082389, hglt: 0.156673 },
  { isoDate: "2016-12-07T00:00:00Z", dsun: 209.508384, hgln: 0.090807, hglt: 0.027947 },
  { isoDate: "2016-12-08T00:00:00Z", dsun: 209.486435, hgln: 0.099195, hglt: -0.100777 },
  { isoDate: "2016-12-09T00:00:00Z", dsun: 209.465918, hgln: 0.107545, hglt: -0.229457 },
  { isoDate: "2016-12-10T00:00:00Z", dsun: 209.44683, hgln: 0.115846, hglt: -0.358049 },
  { isoDate: "2016-12-11T00:00:00Z", dsun: 209.429164, hgln: 0.12408, hglt: -0.486512 },
  { isoDate: "2016-12-12T00:00:00Z", dsun: 209.412915, hgln: 0.132225, hglt: -0.614802 },
  { isoDate: "2016-12-13T00:00:00Z", dsun: 209.398074, hgln: 0.140259, hglt: -0.742867 },
  { isoDate: "2016-12-14T00:00:00Z", dsun: 209.384631, hgln: 0.148139, hglt: -0.870685 },
  { isoDate: "2016-12-15T00:00:00Z", dsun: 209.372575, hgln: 0.155838, hglt: -0.998202 },
  { isoDate: "2016-12-16T00:00:00Z", dsun: 209.361895, hgln: 0.163325, hglt: -1.125378 },
  { isoDate: "2016-12-17T00:00:00Z", dsun: 209.352576, hgln: 0.170569, hglt: -1.252171 },
  { isoDate: "2016-12-18T00:00:00Z", dsun: 209.344605, hgln: 0.177548, hglt: -1.378539 },
  { isoDate: "2016-12-19T00:00:00Z", dsun: 209.337966, hgln: 0.184242, hglt: -1.504441 },
  { isoDate: "2016-12-20T00:00:00Z", dsun: 209.332642, hgln: 0.190636, hglt: -1.629834 },
  { isoDate: "2016-12-21T00:00:00Z", dsun: 209.328618, hgln: 0.19672, hglt: -1.754679 },
  { isoDate: "2016-12-22T00:00:00Z", dsun: 209.325877, hgln: 0.202488, hglt: -1.878935 },
  { isoDate: "2016-12-23T00:00:00Z", dsun: 209.324402, hgln: 0.207933, hglt: -2.00256 },
  { isoDate: "2016-12-24T00:00:00Z", dsun: 209.324175, hgln: 0.213056, hglt: -2.125516 },
  { isoDate: "2016-12-25T00:00:00Z", dsun: 209.32518, hgln: 0.217856, hglt: -2.247761 },
  { isoDate: "2016-12-26T00:00:00Z", dsun: 209.327399, hgln: 0.222334, hglt: -2.369257 },
  { isoDate: "2016-12-27T00:00:00Z", dsun: 209.330812, hgln: 0.226495, hglt: -2.489963 },
  { isoDate: "2016-12-28T00:00:00Z", dsun: 209.3354, hgln: 0.230343, hglt: -2.609841 },
  { isoDate: "2016-12-29T00:00:00Z", dsun: 209.341141, hgln: 0.233886, hglt: -2.728853 },
  { isoDate: "2016-12-30T00:00:00Z", dsun: 209.348013, hgln: 0.237132, hglt: -2.846961 },
  { isoDate: "2016-12-31T00:00:00Z", dsun: 209.355992, hgln: 0.240091, hglt: -2.964126 },
  { isoDate: "2017-01-01T00:00:00Z", dsun: 209.365055, hgln: 0.242775, hglt: -3.080314 },
  { isoDate: "2017-01-02T00:00:00Z", dsun: 209.375179, hgln: 0.245193, hglt: -3.195484 },
  { isoDate: "2017-01-03T00:00:00Z", dsun: 209.386342, hgln: 0.247358, hglt: -3.309602 },
  { isoDate: "2017-01-04T00:00:00Z", dsun: 209.398522, hgln: 0.249277, hglt: -3.422632 },
  { isoDate: "2017-01-05T00:00:00Z", dsun: 209.411698, hgln: 0.250958, hglt: -3.534539 },
  { isoDate: "2017-01-06T00:00:00Z", dsun: 209.425849, hgln: 0.252405, hglt: -3.645288 },
  { isoDate: "2017-01-07T00:00:00Z", dsun: 209.440954, hgln: 0.253619, hglt: -3.754845 },
  { isoDate: "2017-01-08T00:00:00Z", dsun: 209.456994, hgln: 0.254596, hglt: -3.863175 },
  { isoDate: "2017-01-09T00:00:00Z", dsun: 209.473947, hgln: 0.255328, hglt: -3.970246 },
  { isoDate: "2017-01-10T00:00:00Z", dsun: 209.491775, hgln: 0.255788, hglt: -4.07605 },
  { isoDate: "2017-01-11T00:00:00Z", dsun: 209.510493, hgln: 0.255982, hglt: -4.180504 },
  { isoDate: "2017-01-12T00:00:00Z", dsun: 209.530065, hgln: 0.255876, hglt: -4.283603 },
  { isoDate: "2017-01-13T00:00:00Z", dsun: 209.55047, hgln: 0.255448, hglt: -4.385314 },
  { isoDate: "2017-01-14T00:00:00Z", dsun: 209.571688, hgln: 0.254676, hglt: -4.485609 },
  { isoDate: "2017-01-15T00:00:00Z", dsun: 209.5937, hgln: 0.253539, hglt: -4.584456 },
  { isoDate: "2017-01-16T00:00:00Z", dsun: 209.616488, hgln: 0.252021, hglt: -4.681827 },
  { isoDate: "2017-01-17T00:00:00Z", dsun: 209.640033, hgln: 0.25011, hglt: -4.777694 },
  { isoDate: "2017-01-18T00:00:00Z", dsun: 209.66432, hgln: 0.247798, hglt: -4.872029 },
  { isoDate: "2017-01-19T00:00:00Z", dsun: 209.689333, hgln: 0.245081, hglt: -4.964804 },
  { isoDate: "2017-01-20T00:00:00Z", dsun: 209.715058, hgln: 0.24196, hglt: -5.055994 },
  { isoDate: "2017-01-21T00:00:00Z", dsun: 209.741482, hgln: 0.238435, hglt: -5.145572 },
  { isoDate: "2017-01-22T00:00:00Z", dsun: 209.768596, hgln: 0.234511, hglt: -5.233513 },
  { isoDate: "2017-01-23T00:00:00Z", dsun: 209.796389, hgln: 0.230195, hglt: -5.319793 },
  { isoDate: "2017-01-24T00:00:00Z", dsun: 209.824856, hgln: 0.225497, hglt: -5.404388 },
  { isoDate: "2017-01-25T00:00:00Z", dsun: 209.853988, hgln: 0.220427, hglt: -5.487275 },
  { isoDate: "2017-01-26T00:00:00Z", dsun: 209.883779, hgln: 0.214998, hglt: -5.568431 },
  { isoDate: "2017-01-27T00:00:00Z", dsun: 209.914222, hgln: 0.209228, hglt: -5.647836 },
  { isoDate: "2017-01-28T00:00:00Z", dsun: 209.94531, hgln: 0.203134, hglt: -5.725468 },
  { isoDate: "2017-01-29T00:00:00Z", dsun: 209.977034, hgln: 0.196738, hglt: -5.801307 },
  { isoDate: "2017-01-30T00:00:00Z", dsun: 210.009388, hgln: 0.190062, hglt: -5.875333 },
  { isoDate: "2017-01-31T00:00:00Z", dsun: 210.042366, hgln: 0.183132, hglt: -5.947528 },
  { isoDate: "2017-02-01T00:00:00Z", dsun: 210.075964, hgln: 0.17597, hglt: -6.017874 },
  { isoDate: "2017-02-02T00:00:00Z", dsun: 210.110181, hgln: 0.168601, hglt: -6.086354 },
  { isoDate: "2017-02-03T00:00:00Z", dsun: 210.145017, hgln: 0.161044, hglt: -6.152951 },
  { isoDate: "2017-02-04T00:00:00Z", dsun: 210.180473, hgln: 0.153318, hglt: -6.217648 },
  { isoDate: "2017-02-05T00:00:00Z", dsun: 210.216553, hgln: 0.145435, hglt: -6.280431 },
  { isoDate: "2017-02-06T00:00:00Z", dsun: 210.253258, hgln: 0.137407, hglt: -6.341285 },
  { isoDate: "2017-02-07T00:00:00Z", dsun: 210.290594, hgln: 0.129236, hglt: -6.400195 },
  { isoDate: "2017-02-08T00:00:00Z", dsun: 210.328564, hgln: 0.120925, hglt: -6.45715 },
  { isoDate: "2017-02-09T00:00:00Z", dsun: 210.367171, hgln: 0.112469, hglt: -6.512135 },
  { isoDate: "2017-02-10T00:00:00Z", dsun: 210.406419, hgln: 0.103864, hglt: -6.565139 },
  { isoDate: "2017-02-11T00:00:00Z", dsun: 210.446308, hgln: 0.095104, hglt: -6.61615 },
  { isoDate: "2017-02-12T00:00:00Z", dsun: 210.486839, hgln: 0.086183, hglt: -6.665157 },
  { isoDate: "2017-02-13T00:00:00Z", dsun: 210.528013, hgln: 0.077096, hglt: -6.71215 },
  { isoDate: "2017-02-14T00:00:00Z", dsun: 210.569824, hgln: 0.067843, hglt: -6.757119 },
  { isoDate: "2017-02-15T00:00:00Z", dsun: 210.612273, hgln: 0.058427, hglt: -6.800053 },
  { isoDate: "2017-02-16T00:00:00Z", dsun: 210.655353, hgln: 0.048853, hglt: -6.840944 },
  { isoDate: "2017-02-17T00:00:00Z", dsun: 210.699057, hgln: 0.039131, hglt: -6.879784 },
  { isoDate: "2017-02-18T00:00:00Z", dsun: 210.743377, hgln: 0.029273, hglt: -6.916563 },
  { isoDate: "2017-02-19T00:00:00Z", dsun: 210.788302, hgln: 0.019294, hglt: -6.951275 },
  { isoDate: "2017-02-20T00:00:00Z", dsun: 210.833823, hgln: 0.009212, hglt: -6.983912 },
  { isoDate: "2017-02-21T00:00:00Z", dsun: 210.879925, hgln: -0.000954, hglt: -7.014466 },
  { isoDate: "2017-02-22T00:00:00Z", dsun: 210.926596, hgln: -0.011181, hglt: -7.042932 },
  { isoDate: "2017-02-23T00:00:00Z", dsun: 210.973821, hgln: -0.021446, hglt: -7.069303 },
  { isoDate: "2017-02-24T00:00:00Z", dsun: 211.021587, hgln: -0.031721, hglt: -7.093573 },
  { isoDate: "2017-02-25T00:00:00Z", dsun: 211.069883, hgln: -0.041978, hglt: -7.115738 },
  { isoDate: "2017-02-26T00:00:00Z", dsun: 211.118684, hgln: -0.052185, hglt: -7.135792 },
  { isoDate: "2017-02-27T00:00:00Z", dsun: 211.167972, hgln: -0.062309, hglt: -7.153731 },
  { isoDate: "2017-02-28T00:00:00Z", dsun: 211.217723, hgln: -0.072317, hglt: -7.16955 },
  { isoDate: "2017-03-01T00:00:00Z", dsun: 211.26791, hgln: -0.082173, hglt: -7.183247 },
  { isoDate: "2017-03-02T00:00:00Z", dsun: 211.318504, hgln: -0.091845, hglt: -7.194818 },
  { isoDate: "2017-03-03T00:00:00Z", dsun: 211.369472, hgln: -0.101302, hglt: -7.20426 },
  { isoDate: "2017-03-04T00:00:00Z", dsun: 211.420782, hgln: -0.110515, hglt: -7.21157 },
  { isoDate: "2017-03-05T00:00:00Z", dsun: 211.4724, hgln: -0.119463, hglt: -7.216746 },
  { isoDate: "2017-03-06T00:00:00Z", dsun: 211.524297, hgln: -0.128128, hglt: -7.219787 },
  { isoDate: "2017-03-07T00:00:00Z", dsun: 211.576441, hgln: -0.136499, hglt: -7.220691 },
  { isoDate: "2017-03-08T00:00:00Z", dsun: 211.628804, hgln: -0.144568, hglt: -7.21946 },
  { isoDate: "2017-03-09T00:00:00Z", dsun: 211.681357, hgln: -0.152334, hglt: -7.216093 },
  { isoDate: "2017-03-10T00:00:00Z", dsun: 211.734073, hgln: -0.159801, hglt: -7.210591 },
  { isoDate: "2017-03-11T00:00:00Z", dsun: 211.786925, hgln: -0.166974, hglt: -7.202955 },
  { isoDate: "2017-03-12T00:00:00Z", dsun: 211.839887, hgln: -0.173862, hglt: -7.193188 },
  { isoDate: "2017-03-13T00:00:00Z", dsun: 211.892934, hgln: -0.180474, hglt: -7.181293 },
  { isoDate: "2017-03-14T00:00:00Z", dsun: 211.946037, hgln: -0.186818, hglt: -7.167274 },
  { isoDate: "2017-03-15T00:00:00Z", dsun: 211.999179, hgln: -0.192905, hglt: -7.151134 },
  { isoDate: "2017-03-16T00:00:00Z", dsun: 212.052334, hgln: -0.198742, hglt: -7.132877 },
  { isoDate: "2017-03-17T00:00:00Z", dsun: 212.105478, hgln: -0.204335, hglt: -7.11251 },
  { isoDate: "2017-03-18T00:00:00Z", dsun: 212.158591, hgln: -0.209688, hglt: -7.090038 },
  { isoDate: "2017-03-19T00:00:00Z", dsun: 212.211651, hgln: -0.214804, hglt: -7.065469 },
  { isoDate: "2017-03-20T00:00:00Z", dsun: 212.264639, hgln: -0.219684, hglt: -7.038809 },
  { isoDate: "2017-03-21T00:00:00Z", dsun: 212.317535, hgln: -0.224329, hglt: -7.010066 },
  { isoDate: "2017-03-22T00:00:00Z", dsun: 212.370323, hgln: -0.228737, hglt: -6.97925 },
  { isoDate: "2017-03-23T00:00:00Z", dsun: 212.422987, hgln: -0.232903, hglt: -6.94637 },
  { isoDate: "2017-03-24T00:00:00Z", dsun: 212.475511, hgln: -0.236822, hglt: -6.911435 },
  { isoDate: "2017-03-25T00:00:00Z", dsun: 212.527882, hgln: -0.240486, hglt: -6.874457 },
  { isoDate: "2017-03-26T00:00:00Z", dsun: 212.580091, hgln: -0.243881, hglt: -6.835448 },
  { isoDate: "2017-03-27T00:00:00Z", dsun: 212.632126, hgln: -0.246993, hglt: -6.794418 },
  { isoDate: "2017-03-28T00:00:00Z", dsun: 212.683982, hgln: -0.249804, hglt: -6.751381 },
  { isoDate: "2017-03-29T00:00:00Z", dsun: 212.735651, hgln: -0.252292, hglt: -6.70635 },
  { isoDate: "2017-03-30T00:00:00Z", dsun: 212.78713, hgln: -0.254437, hglt: -6.65934 },
  { isoDate: "2017-03-31T00:00:00Z", dsun: 212.838413, hgln: -0.256219, hglt: -6.610365 },
  { isoDate: "2017-04-01T00:00:00Z", dsun: 212.889496, hgln: -0.257619, hglt: -6.55944 },
  { isoDate: "2017-04-02T00:00:00Z", dsun: 212.940373, hgln: -0.258624, hglt: -6.506581 },
  { isoDate: "2017-04-03T00:00:00Z", dsun: 212.991039, hgln: -0.259227, hglt: -6.451806 },
  { isoDate: "2017-04-04T00:00:00Z", dsun: 213.041491, hgln: -0.259424, hglt: -6.39513 },
  { isoDate: "2017-04-05T00:00:00Z", dsun: 213.091724, hgln: -0.259218, hglt: -6.336571 },
  { isoDate: "2017-04-06T00:00:00Z", dsun: 213.141738, hgln: -0.258614, hglt: -6.276149 },
  { isoDate: "2017-04-07T00:00:00Z", dsun: 213.191533, hgln: -0.257624, hglt: -6.213881 },
  { isoDate: "2017-04-08T00:00:00Z", dsun: 213.241107, hgln: -0.256262, hglt: -6.149787 },
  { isoDate: "2017-04-09T00:00:00Z", dsun: 213.290465, hgln: -0.254542, hglt: -6.083888 },
  { isoDate: "2017-04-10T00:00:00Z", dsun: 213.339606, hgln: -0.252484, hglt: -6.016203 },
  { isoDate: "2017-04-11T00:00:00Z", dsun: 213.388524, hgln: -0.250099, hglt: -5.946754 },
  { isoDate: "2017-04-12T00:00:00Z", dsun: 213.437242, hgln: -0.247417, hglt: -5.875562 },
  { isoDate: "2017-04-13T00:00:00Z", dsun: 213.485754, hgln: -0.244452, hglt: -5.80265 },
  { isoDate: "2017-04-14T00:00:00Z", dsun: 213.534063, hgln: -0.241222, hglt: -5.72804 },
  { isoDate: "2017-04-15T00:00:00Z", dsun: 213.582173, hgln: -0.237743, hglt: -5.651756 },
  { isoDate: "2017-04-16T00:00:00Z", dsun: 213.630086, hgln: -0.234031, hglt: -5.57382 },
  { isoDate: "2017-04-17T00:00:00Z", dsun: 213.677807, hgln: -0.230101, hglt: -5.494258 },
  { isoDate: "2017-04-18T00:00:00Z", dsun: 213.725338, hgln: -0.225966, hglt: -5.413094 },
  { isoDate: "2017-04-19T00:00:00Z", dsun: 213.772683, hgln: -0.221637, hglt: -5.330353 },
  { isoDate: "2017-04-20T00:00:00Z", dsun: 213.819845, hgln: -0.217124, hglt: -5.246061 },
  { isoDate: "2017-04-21T00:00:00Z", dsun: 213.866827, hgln: -0.212434, hglt: -5.160243 },
  { isoDate: "2017-04-22T00:00:00Z", dsun: 213.913632, hgln: -0.207574, hglt: -5.072927 },
  { isoDate: "2017-04-23T00:00:00Z", dsun: 213.960264, hgln: -0.202544, hglt: -4.984138 },
  { isoDate: "2017-04-24T00:00:00Z", dsun: 214.006728, hgln: -0.197343, hglt: -4.893905 },
  { isoDate: "2017-04-25T00:00:00Z", dsun: 214.053027, hgln: -0.191965, hglt: -4.802254 },
  { isoDate: "2017-04-26T00:00:00Z", dsun: 214.099166, hgln: -0.1864, hglt: -4.709215 },
  { isoDate: "2017-04-27T00:00:00Z", dsun: 214.14515, hgln: -0.180635, hglt: -4.614815 },
  { isoDate: "2017-04-28T00:00:00Z", dsun: 214.190983, hgln: -0.174653, hglt: -4.519083 },
  { isoDate: "2017-04-29T00:00:00Z", dsun: 214.236668, hgln: -0.168442, hglt: -4.422049 },
  { isoDate: "2017-04-30T00:00:00Z", dsun: 214.282208, hgln: -0.16199, hglt: -4.323742 },
  { isoDate: "2017-05-01T00:00:00Z", dsun: 214.327602, hgln: -0.155289, hglt: -4.224192 },
  { isoDate: "2017-05-02T00:00:00Z", dsun: 214.372852, hgln: -0.148338, hglt: -4.123429 },
  { isoDate: "2017-05-03T00:00:00Z", dsun: 214.417957, hgln: -0.14114, hglt: -4.021484 },
  { isoDate: "2017-05-04T00:00:00Z", dsun: 214.462917, hgln: -0.133703, hglt: -3.918388 },
  { isoDate: "2017-05-05T00:00:00Z", dsun: 214.507732, hgln: -0.12604, hglt: -3.814172 },
  { isoDate: "2017-05-06T00:00:00Z", dsun: 214.552403, hgln: -0.118167, hglt: -3.708868 },
  { isoDate: "2017-05-07T00:00:00Z", dsun: 214.596928, hgln: -0.110101, hglt: -3.602507 },
  { isoDate: "2017-05-08T00:00:00Z", dsun: 214.641306, hgln: -0.101863, hglt: -3.495121 },
  { isoDate: "2017-05-09T00:00:00Z", dsun: 214.685537, hgln: -0.093476, hglt: -3.386742 },
  { isoDate: "2017-05-10T00:00:00Z", dsun: 214.729618, hgln: -0.084961, hglt: -3.277404 },
  { isoDate: "2017-05-11T00:00:00Z", dsun: 214.773546, hgln: -0.076343, hglt: -3.167138 },
  { isoDate: "2017-05-12T00:00:00Z", dsun: 214.817317, hgln: -0.067643, hglt: -3.055978 },
  { isoDate: "2017-05-13T00:00:00Z", dsun: 214.860927, hgln: -0.058887, hglt: -2.943957 },
  { isoDate: "2017-05-14T00:00:00Z", dsun: 214.904368, hgln: -0.050096, hglt: -2.831109 },
  { isoDate: "2017-05-15T00:00:00Z", dsun: 214.947636, hgln: -0.041293, hglt: -2.717466 },
  { isoDate: "2017-05-16T00:00:00Z", dsun: 214.990721, hgln: -0.032498, hglt: -2.603063 },
  { isoDate: "2017-05-17T00:00:00Z", dsun: 215.033615, hgln: -0.023731, hglt: -2.487934 },
  { isoDate: "2017-05-18T00:00:00Z", dsun: 215.076309, hgln: -0.015009, hglt: -2.372112 },
  { isoDate: "2017-05-19T00:00:00Z", dsun: 215.118792, hgln: -0.00635, hglt: -2.255632 },
  { isoDate: "2017-05-20T00:00:00Z", dsun: 215.161054, hgln: 0.002234, hglt: -2.138529 },
  { isoDate: "2017-05-21T00:00:00Z", dsun: 215.203083, hgln: 0.010732, hglt: -2.020836 },
  { isoDate: "2017-05-22T00:00:00Z", dsun: 215.244868, hgln: 0.019136, hglt: -1.902588 },
  { isoDate: "2017-05-23T00:00:00Z", dsun: 215.286397, hgln: 0.027444, hglt: -1.78382 },
  { isoDate: "2017-05-24T00:00:00Z", dsun: 215.327658, hgln: 0.035658, hglt: -1.664566 },
  { isoDate: "2017-05-25T00:00:00Z", dsun: 215.368639, hgln: 0.043785, hglt: -1.544862 },
  { isoDate: "2017-05-26T00:00:00Z", dsun: 215.409328, hgln: 0.051838, hglt: -1.424741 },
  { isoDate: "2017-05-27T00:00:00Z", dsun: 215.449707, hgln: 0.059828, hglt: -1.304239 },
  { isoDate: "2017-05-28T00:00:00Z", dsun: 215.489757, hgln: 0.06777, hglt: -1.183391 },
  { isoDate: "2017-05-29T00:00:00Z", dsun: 215.529461, hgln: 0.075675, hglt: -1.062231 },
  { isoDate: "2017-05-30T00:00:00Z", dsun: 215.568799, hgln: 0.083551, hglt: -0.940795 },
  { isoDate: "2017-05-31T00:00:00Z", dsun: 215.607753, hgln: 0.091398, hglt: -0.819118 },
  { isoDate: "2017-06-01T00:00:00Z", dsun: 215.646303, hgln: 0.099215, hglt: -0.697233 },
  { isoDate: "2017-06-02T00:00:00Z", dsun: 215.68443, hgln: 0.106995, hglt: -0.575177 },
  { isoDate: "2017-06-03T00:00:00Z", dsun: 215.722113, hgln: 0.114727, hglt: -0.452984 },
  { isoDate: "2017-06-04T00:00:00Z", dsun: 215.759333, hgln: 0.122397, hglt: -0.330688 },
  { isoDate: "2017-06-05T00:00:00Z", dsun: 215.79607, hgln: 0.129989, hglt: -0.208325 },
  { isoDate: "2017-06-06T00:00:00Z", dsun: 215.832302, hgln: 0.137488, hglt: -0.085928 },
  { isoDate: "2017-06-07T00:00:00Z", dsun: 215.868008, hgln: 0.144873, hglt: 0.036467 },
  { isoDate: "2017-06-08T00:00:00Z", dsun: 215.903166, hgln: 0.152126, hglt: 0.158826 },
  { isoDate: "2017-06-09T00:00:00Z", dsun: 215.937752, hgln: 0.159227, hglt: 0.281114 },
  { isoDate: "2017-06-10T00:00:00Z", dsun: 215.971744, hgln: 0.166155, hglt: 0.403298 },
  { isoDate: "2017-06-11T00:00:00Z", dsun: 216.005118, hgln: 0.172889, hglt: 0.525343 },
  { isoDate: "2017-06-12T00:00:00Z", dsun: 216.037849, hgln: 0.17941, hglt: 0.647215 },
  { isoDate: "2017-06-13T00:00:00Z", dsun: 216.06992, hgln: 0.185703, hglt: 0.768882 },
  { isoDate: "2017-06-14T00:00:00Z", dsun: 216.101292, hgln: 0.19174, hglt: 0.890307 },
  { isoDate: "2017-06-15T00:00:00Z", dsun: 216.131947, hgln: 0.197507, hglt: 1.011458 },
  { isoDate: "2017-06-16T00:00:00Z", dsun: 216.161859, hgln: 0.20299, hglt: 1.132301 },
  { isoDate: "2017-06-17T00:00:00Z", dsun: 216.191005, hgln: 0.208174, hglt: 1.252803 },
  { isoDate: "2017-06-18T00:00:00Z", dsun: 216.219361, hgln: 0.213049, hglt: 1.37293 },
  { isoDate: "2017-06-19T00:00:00Z", dsun: 216.246903, hgln: 0.217607, hglt: 1.492651 },
  { isoDate: "2017-06-20T00:00:00Z", dsun: 216.273607, hgln: 0.221844, hglt: 1.611933 },
  { isoDate: "2017-06-21T00:00:00Z", dsun: 216.299451, hgln: 0.225763, hglt: 1.730741 },
  { isoDate: "2017-06-22T00:00:00Z", dsun: 216.324412, hgln: 0.229369, hglt: 1.849045 },
  { isoDate: "2017-06-23T00:00:00Z", dsun: 216.348468, hgln: 0.232676, hglt: 1.966812 },
  { isoDate: "2017-06-24T00:00:00Z", dsun: 216.371595, hgln: 0.2357, hglt: 2.084009 },
  { isoDate: "2017-06-25T00:00:00Z", dsun: 216.393767, hgln: 0.238459, hglt: 2.200606 },
  { isoDate: "2017-06-26T00:00:00Z", dsun: 216.414962, hgln: 0.240974, hglt: 2.31657 },
  { isoDate: "2017-06-27T00:00:00Z", dsun: 216.435155, hgln: 0.243261, hglt: 2.431869 },
  { isoDate: "2017-06-28T00:00:00Z", dsun: 216.454324, hgln: 0.245335, hglt: 2.546474 },
  { isoDate: "2017-06-29T00:00:00Z", dsun: 216.472447, hgln: 0.247205, hglt: 2.660352 },
  { isoDate: "2017-06-30T00:00:00Z", dsun: 216.489504, hgln: 0.248874, hglt: 2.773474 },
  { isoDate: "2017-07-01T00:00:00Z", dsun: 216.505475, hgln: 0.250345, hglt: 2.885808 },
  { isoDate: "2017-07-02T00:00:00Z", dsun: 216.520341, hgln: 0.251612, hglt: 2.997324 },
  { isoDate: "2017-07-03T00:00:00Z", dsun: 216.534084, hgln: 0.252673, hglt: 3.107993 },
  { isoDate: "2017-07-04T00:00:00Z", dsun: 216.546686, hgln: 0.253519, hglt: 3.217784 },
  { isoDate: "2017-07-05T00:00:00Z", dsun: 216.55813, hgln: 0.254141, hglt: 3.326668 },
  { isoDate: "2017-07-06T00:00:00Z", dsun: 216.5684, hgln: 0.254529, hglt: 3.434616 },
  { isoDate: "2017-07-07T00:00:00Z", dsun: 216.577481, hgln: 0.25467, hglt: 3.541597 },
  { isoDate: "2017-07-08T00:00:00Z", dsun: 216.585356, hgln: 0.254553, hglt: 3.647584 },
  { isoDate: "2017-07-09T00:00:00Z", dsun: 216.592011, hgln: 0.254164, hglt: 3.752549 },
  { isoDate: "2017-07-10T00:00:00Z", dsun: 216.597433, hgln: 0.253489, hglt: 3.856461 },
  { isoDate: "2017-07-11T00:00:00Z", dsun: 216.601609, hgln: 0.252513, hglt: 3.959294 },
  { isoDate: "2017-07-12T00:00:00Z", dsun: 216.604526, hgln: 0.251221, hglt: 4.06102 },
  { isoDate: "2017-07-13T00:00:00Z", dsun: 216.606174, hgln: 0.249601, hglt: 4.161611 },
  { isoDate: "2017-07-14T00:00:00Z", dsun: 216.606542, hgln: 0.247639, hglt: 4.26104 },
  { isoDate: "2017-07-15T00:00:00Z", dsun: 216.605623, hgln: 0.245325, hglt: 4.359279 },
  { isoDate: "2017-07-16T00:00:00Z", dsun: 216.60341, hgln: 0.242649, hglt: 4.456303 },
  { isoDate: "2017-07-17T00:00:00Z", dsun: 216.599897, hgln: 0.239607, hglt: 4.552084 },
  { isoDate: "2017-07-18T00:00:00Z", dsun: 216.595083, hgln: 0.236195, hglt: 4.646597 },
  { isoDate: "2017-07-19T00:00:00Z", dsun: 216.588965, hgln: 0.232418, hglt: 4.739815 },
  { isoDate: "2017-07-20T00:00:00Z", dsun: 216.581546, hgln: 0.228283, hglt: 4.831712 },
  { isoDate: "2017-07-21T00:00:00Z", dsun: 216.572826, hgln: 0.223802, hglt: 4.922264 },
  { isoDate: "2017-07-22T00:00:00Z", dsun: 216.562807, hgln: 0.218995, hglt: 5.011445 },
  { isoDate: "2017-07-23T00:00:00Z", dsun: 216.551494, hgln: 0.213884, hglt: 5.09923 },
  { isoDate: "2017-07-24T00:00:00Z", dsun: 216.538887, hgln: 0.208494, hglt: 5.185595 },
  { isoDate: "2017-07-25T00:00:00Z", dsun: 216.524991, hgln: 0.20285, hglt: 5.270516 },
  { isoDate: "2017-07-26T00:00:00Z", dsun: 216.509809, hgln: 0.196976, hglt: 5.353968 },
  { isoDate: "2017-07-27T00:00:00Z", dsun: 216.49335, hgln: 0.190894, hglt: 5.435929 },
  { isoDate: "2017-07-28T00:00:00Z", dsun: 216.475621, hgln: 0.184621, hglt: 5.516375 },
  { isoDate: "2017-07-29T00:00:00Z", dsun: 216.456635, hgln: 0.17817, hglt: 5.595283 },
  { isoDate: "2017-07-30T00:00:00Z", dsun: 216.436403, hgln: 0.171553, hglt: 5.672631 },
  { isoDate: "2017-07-31T00:00:00Z", dsun: 216.414941, hgln: 0.164776, hglt: 5.748397 },
  { isoDate: "2017-08-01T00:00:00Z", dsun: 216.392265, hgln: 0.157846, hglt: 5.822558 },
  { isoDate: "2017-08-02T00:00:00Z", dsun: 216.368392, hgln: 0.150765, hglt: 5.895093 },
  { isoDate: "2017-08-03T00:00:00Z", dsun: 216.343341, hgln: 0.143537, hglt: 5.96598 },
  { isoDate: "2017-08-04T00:00:00Z", dsun: 216.317131, hgln: 0.13616, hglt: 6.0352 },
  { isoDate: "2017-08-05T00:00:00Z", dsun: 216.289781, hgln: 0.128635, hglt: 6.102731 },
  { isoDate: "2017-08-06T00:00:00Z", dsun: 216.261312, hgln: 0.12096, hglt: 6.168553 },
  { isoDate: "2017-08-07T00:00:00Z", dsun: 216.231744, hgln: 0.113131, hglt: 6.232647 },
  { isoDate: "2017-08-08T00:00:00Z", dsun: 216.201088, hgln: 0.105144, hglt: 6.294993 },
  { isoDate: "2017-08-09T00:00:00Z", dsun: 216.169381, hgln: 0.096995, hglt: 6.355574 },
  { isoDate: "2017-08-10T00:00:00Z", dsun: 216.136635, hgln: 0.088677, hglt: 6.414369 },
  { isoDate: "2017-08-11T00:00:00Z", dsun: 216.102868, hgln: 0.080187, hglt: 6.471362 },
  { isoDate: "2017-08-12T00:00:00Z", dsun: 216.0681, hgln: 0.071522, hglt: 6.526535 },
  { isoDate: "2017-08-13T00:00:00Z", dsun: 216.032349, hgln: 0.06268, hglt: 6.579872 },
  { isoDate: "2017-08-14T00:00:00Z", dsun: 215.995631, hgln: 0.053662, hglt: 6.631355 },
  { isoDate: "2017-08-15T00:00:00Z", dsun: 215.957966, hgln: 0.044476, hglt: 6.680969 },
  { isoDate: "2017-08-16T00:00:00Z", dsun: 215.919369, hgln: 0.03513, hglt: 6.728699 },
  { isoDate: "2017-08-17T00:00:00Z", dsun: 215.879857, hgln: 0.02564, hglt: 6.774528 },
  { isoDate: "2017-08-18T00:00:00Z", dsun: 215.839447, hgln: 0.016026, hglt: 6.818444 },
  { isoDate: "2017-08-19T00:00:00Z", dsun: 215.798156, hgln: 0.006315, hglt: 6.860431 },
  { isoDate: "2017-08-20T00:00:00Z", dsun: 215.756, hgln: -0.003463, hglt: 6.900476 },
  { isoDate: "2017-08-21T00:00:00Z", dsun: 215.712997, hgln: -0.013276, hglt: 6.938565 },
  { isoDate: "2017-08-22T00:00:00Z", dsun: 215.669159, hgln: -0.023088, hglt: 6.974687 },
  { isoDate: "2017-08-23T00:00:00Z", dsun: 215.624496, hgln: -0.032866, hglt: 7.008829 },
  { isoDate: "2017-08-24T00:00:00Z", dsun: 215.579016, hgln: -0.042578, hglt: 7.04098 },
  { isoDate: "2017-08-25T00:00:00Z", dsun: 215.532724, hgln: -0.052194, hglt: 7.07113 },
  { isoDate: "2017-08-26T00:00:00Z", dsun: 215.485621, hgln: -0.061686, hglt: 7.099267 },
  { isoDate: "2017-08-27T00:00:00Z", dsun: 215.437709, hgln: -0.071032, hglt: 7.125383 },
  { isoDate: "2017-08-28T00:00:00Z", dsun: 215.388988, hgln: -0.080211, hglt: 7.149468 },
  { isoDate: "2017-08-29T00:00:00Z", dsun: 215.33946, hgln: -0.089206, hglt: 7.171513 },
  { isoDate: "2017-08-30T00:00:00Z", dsun: 215.289125, hgln: -0.098003, hglt: 7.19151 },
  { isoDate: "2017-08-31T00:00:00Z", dsun: 215.237987, hgln: -0.106588, hglt: 7.209452 },
  { isoDate: "2017-09-01T00:00:00Z", dsun: 215.186047, hgln: -0.114954, hglt: 7.225331 },
  { isoDate: "2017-09-02T00:00:00Z", dsun: 215.133308, hgln: -0.123092, hglt: 7.23914 },
  { isoDate: "2017-09-03T00:00:00Z", dsun: 215.079773, hgln: -0.130999, hglt: 7.250874 },
  { isoDate: "2017-09-04T00:00:00Z", dsun: 215.025447, hgln: -0.138674, hglt: 7.260526 },
  { isoDate: "2017-09-05T00:00:00Z", dsun: 214.970332, hgln: -0.146119, hglt: 7.268091 },
  { isoDate: "2017-09-06T00:00:00Z", dsun: 214.914434, hgln: -0.153338, hglt: 7.273565 },
  { isoDate: "2017-09-07T00:00:00Z", dsun: 214.857756, hgln: -0.160339, hglt: 7.276943 },
  { isoDate: "2017-09-08T00:00:00Z", dsun: 214.800305, hgln: -0.167131, hglt: 7.278221 },
  { isoDate: "2017-09-09T00:00:00Z", dsun: 214.742084, hgln: -0.173724, hglt: 7.277398 },
  { isoDate: "2017-09-10T00:00:00Z", dsun: 214.683102, hgln: -0.180129, hglt: 7.27447 },
  { isoDate: "2017-09-11T00:00:00Z", dsun: 214.623364, hgln: -0.186353, hglt: 7.269434 },
  { isoDate: "2017-09-12T00:00:00Z", dsun: 214.562878, hgln: -0.192404, hglt: 7.262291 },
  { isoDate: "2017-09-13T00:00:00Z", dsun: 214.501652, hgln: -0.198283, hglt: 7.253038 },
  { isoDate: "2017-09-14T00:00:00Z", dsun: 214.439697, hgln: -0.203989, hglt: 7.241674 },
  { isoDate: "2017-09-15T00:00:00Z", dsun: 214.377024, hgln: -0.209516, hglt: 7.228201 },
  { isoDate: "2017-09-16T00:00:00Z", dsun: 214.313645, hgln: -0.214852, hglt: 7.212619 },
  { isoDate: "2017-09-17T00:00:00Z", dsun: 214.249574, hgln: -0.219983, hglt: 7.194929 },
  { isoDate: "2017-09-18T00:00:00Z", dsun: 214.184829, hgln: -0.224889, hglt: 7.175131 },
  { isoDate: "2017-09-19T00:00:00Z", dsun: 214.119427, hgln: -0.22955, hglt: 7.15323 },
  { isoDate: "2017-09-20T00:00:00Z", dsun: 214.053391, hgln: -0.233944, hglt: 7.129227 },
  { isoDate: "2017-09-21T00:00:00Z", dsun: 213.986742, hgln: -0.238048, hglt: 7.103126 },
  { isoDate: "2017-09-22T00:00:00Z", dsun: 213.919506, hgln: -0.241843, hglt: 7.074931 },
  { isoDate: "2017-09-23T00:00:00Z", dsun: 213.851707, hgln: -0.245311, hglt: 7.044647 },
  { isoDate: "2017-09-24T00:00:00Z", dsun: 213.78337, hgln: -0.248436, hglt: 7.012278 },
  { isoDate: "2017-09-25T00:00:00Z", dsun: 213.71452, hgln: -0.251208, hglt: 6.977831 },
  { isoDate: "2017-09-26T00:00:00Z", dsun: 213.645182, hgln: -0.253615, hglt: 6.941312 },
  { isoDate: "2017-09-27T00:00:00Z", dsun: 213.575383, hgln: -0.255651, hglt: 6.902727 },
  { isoDate: "2017-09-28T00:00:00Z", dsun: 213.505149, hgln: -0.257311, hglt: 6.862085 },
  { isoDate: "2017-09-29T00:00:00Z", dsun: 213.434509, hgln: -0.258592, hglt: 6.819393 },
  { isoDate: "2017-09-30T00:00:00Z", dsun: 213.363493, hgln: -0.259494, hglt: 6.77466 },
  { isoDate: "2017-10-01T00:00:00Z", dsun: 213.292133, hgln: -0.260018, hglt: 6.727897 },
  { isoDate: "2017-10-02T00:00:00Z", dsun: 213.220462, hgln: -0.26017, hglt: 6.679112 },
  { isoDate: "2017-10-03T00:00:00Z", dsun: 213.148514, hgln: -0.259957, hglt: 6.628316 },
  { isoDate: "2017-10-04T00:00:00Z", dsun: 213.076325, hgln: -0.25939, hglt: 6.575522 },
  { isoDate: "2017-10-05T00:00:00Z", dsun: 213.00393, hgln: -0.258485, hglt: 6.520741 },
  { isoDate: "2017-10-06T00:00:00Z", dsun: 212.931367, hgln: -0.257258, hglt: 6.463985 },
  { isoDate: "2017-10-07T00:00:00Z", dsun: 212.858674, hgln: -0.255732, hglt: 6.405269 },
  { isoDate: "2017-10-08T00:00:00Z", dsun: 212.785887, hgln: -0.253927, hglt: 6.344605 },
  { isoDate: "2017-10-09T00:00:00Z", dsun: 212.713046, hgln: -0.251866, hglt: 6.28201 },
  { isoDate: "2017-10-10T00:00:00Z", dsun: 212.640157, hgln: -0.249564, hglt: 6.217495 },
  { isoDate: "2017-10-11T00:00:00Z", dsun: 212.567319, hgln: -0.247046, hglt: 6.151082 },
  { isoDate: "2017-10-12T00:00:00Z", dsun: 212.494542, hgln: -0.244319, hglt: 6.082785 },
  { isoDate: "2017-10-13T00:00:00Z", dsun: 212.421863, hgln: -0.241391, hglt: 6.012622 },
  { isoDate: "2017-10-14T00:00:00Z", dsun: 212.349321, hgln: -0.238263, hglt: 5.94061 },
  { isoDate: "2017-10-15T00:00:00Z", dsun: 212.276956, hgln: -0.234936, hglt: 5.866769 },
  { isoDate: "2017-10-16T00:00:00Z", dsun: 212.204807, hgln: -0.231401, hglt: 5.791117 },
  { isoDate: "2017-10-17T00:00:00Z", dsun: 212.132914, hgln: -0.227652, hglt: 5.713675 },
  { isoDate: "2017-10-18T00:00:00Z", dsun: 212.061317, hgln: -0.223678, hglt: 5.634463 },
  { isoDate: "2017-10-19T00:00:00Z", dsun: 211.990057, hgln: -0.219467, hglt: 5.553503 },
  { isoDate: "2017-10-20T00:00:00Z", dsun: 211.919178, hgln: -0.215009, hglt: 5.470817 },
  { isoDate: "2017-10-21T00:00:00Z", dsun: 211.848719, hgln: -0.210292, hglt: 5.386429 },
  { isoDate: "2017-10-22T00:00:00Z", dsun: 211.778722, hgln: -0.205308, hglt: 5.300362 },
  { isoDate: "2017-10-23T00:00:00Z", dsun: 211.709226, hgln: -0.200049, hglt: 5.21264 },
  { isoDate: "2017-10-24T00:00:00Z", dsun: 211.640271, hgln: -0.194511, hglt: 5.123287 },
  { isoDate: "2017-10-25T00:00:00Z", dsun: 211.571898, hgln: -0.18869, hglt: 5.03233 },
  { isoDate: "2017-10-26T00:00:00Z", dsun: 211.504139, hgln: -0.182585, hglt: 4.939793 },
  { isoDate: "2017-10-27T00:00:00Z", dsun: 211.437031, hgln: -0.176196, hglt: 4.845705 },
  { isoDate: "2017-10-28T00:00:00Z", dsun: 211.37061, hgln: -0.169526, hglt: 4.750092 },
  { isoDate: "2017-10-29T00:00:00Z", dsun: 211.304911, hgln: -0.16258, hglt: 4.652983 },
  { isoDate: "2017-10-30T00:00:00Z", dsun: 211.239971, hgln: -0.155366, hglt: 4.554406 },
  { isoDate: "2017-10-31T00:00:00Z", dsun: 211.175825, hgln: -0.147894, hglt: 4.45439 },
  { isoDate: "2017-11-01T00:00:00Z", dsun: 211.112509, hgln: -0.140177, hglt: 4.352965 },
  { isoDate: "2017-11-02T00:00:00Z", dsun: 211.050056, hgln: -0.132234, hglt: 4.250162 },
  { isoDate: "2017-11-03T00:00:00Z", dsun: 210.988501, hgln: -0.124087, hglt: 4.146012 },
  { isoDate: "2017-11-04T00:00:00Z", dsun: 210.927877, hgln: -0.115762, hglt: 4.040547 },
  { isoDate: "2017-11-05T00:00:00Z", dsun: 210.868213, hgln: -0.10729, hglt: 3.933798 },
  { isoDate: "2017-11-06T00:00:00Z", dsun: 210.809542, hgln: -0.098703, hglt: 3.8258 },
  { isoDate: "2017-11-07T00:00:00Z", dsun: 210.751891, hgln: -0.090032, hglt: 3.716585 },
  { isoDate: "2017-11-08T00:00:00Z", dsun: 210.695289, hgln: -0.081306, hglt: 3.606186 },
  { isoDate: "2017-11-09T00:00:00Z", dsun: 210.639761, hgln: -0.072551, hglt: 3.494639 },
  { isoDate: "2017-11-10T00:00:00Z", dsun: 210.585332, hgln: -0.063787, hglt: 3.381979 },
  { isoDate: "2017-11-11T00:00:00Z", dsun: 210.532025, hgln: -0.055028, hglt: 3.26824 },
  { isoDate: "2017-11-12T00:00:00Z", dsun: 210.479864, hgln: -0.046284, hglt: 3.153459 },
  { isoDate: "2017-11-13T00:00:00Z", dsun: 210.428872, hgln: -0.03756, hglt: 3.037672 },
  { isoDate: "2017-11-14T00:00:00Z", dsun: 210.379065, hgln: -0.028862, hglt: 2.920915 },
  { isoDate: "2017-11-15T00:00:00Z", dsun: 210.330467, hgln: -0.020187, hglt: 2.803226 },
  { isoDate: "2017-11-16T00:00:00Z", dsun: 210.283095, hgln: -0.011535, hglt: 2.684643 },
  { isoDate: "2017-11-17T00:00:00Z", dsun: 210.236969, hgln: -0.002902, hglt: 2.565204 },
  { isoDate: "2017-11-18T00:00:00Z", dsun: 210.192105, hgln: 0.005714, hglt: 2.444947 },
  { isoDate: "2017-11-19T00:00:00Z", dsun: 210.148517, hgln: 0.014317, hglt: 2.323911 },
  { isoDate: "2017-11-20T00:00:00Z", dsun: 210.106218, hgln: 0.02291, hglt: 2.202135 },
  { isoDate: "2017-11-21T00:00:00Z", dsun: 210.065219, hgln: 0.031495, hglt: 2.079659 },
  { isoDate: "2017-11-22T00:00:00Z", dsun: 210.025526, hgln: 0.040074, hglt: 1.956522 },
  { isoDate: "2017-11-23T00:00:00Z", dsun: 209.987149, hgln: 0.048647, hglt: 1.832764 },
  { isoDate: "2017-11-24T00:00:00Z", dsun: 209.950091, hgln: 0.057214, hglt: 1.708425 },
  { isoDate: "2017-11-25T00:00:00Z", dsun: 209.914358, hgln: 0.065772, hglt: 1.583546 },
  { isoDate: "2017-11-26T00:00:00Z", dsun: 209.879955, hgln: 0.074319, hglt: 1.458168 },
  { isoDate: "2017-11-27T00:00:00Z", dsun: 209.846884, hgln: 0.082848, hglt: 1.33233 },
  { isoDate: "2017-11-28T00:00:00Z", dsun: 209.815147, hgln: 0.091354, hglt: 1.206076 },
  { isoDate: "2017-11-29T00:00:00Z", dsun: 209.784746, hgln: 0.099825, hglt: 1.079445 },
  { isoDate: "2017-11-30T00:00:00Z", dsun: 209.75568, hgln: 0.108249, hglt: 0.952479 },
  { isoDate: "2017-12-01T00:00:00Z", dsun: 209.727948, hgln: 0.116607, hglt: 0.82522 },
  { isoDate: "2017-12-02T00:00:00Z", dsun: 209.701548, hgln: 0.124877, hglt: 0.697709 },
  { isoDate: "2017-12-03T00:00:00Z", dsun: 209.676475, hgln: 0.133032, hglt: 0.569989 },
  { isoDate: "2017-12-04T00:00:00Z", dsun: 209.652724, hgln: 0.14104, hglt: 0.442101 },
  { isoDate: "2017-12-05T00:00:00Z", dsun: 209.630288, hgln: 0.148869, hglt: 0.314086 },
  { isoDate: "2017-12-06T00:00:00Z", dsun: 209.609157, hgln: 0.156486, hglt: 0.185988 },
  { isoDate: "2017-12-07T00:00:00Z", dsun: 209.589323, hgln: 0.163862, hglt: 0.057847 },
  { isoDate: "2017-12-08T00:00:00Z", dsun: 209.570776, hgln: 0.170971, hglt: -0.070294 },
  { isoDate: "2017-12-09T00:00:00Z", dsun: 209.553503, hgln: 0.177794, hglt: -0.198394 },
  { isoDate: "2017-12-10T00:00:00Z", dsun: 209.537494, hgln: 0.184319, hglt: -0.326411 },
  { isoDate: "2017-12-11T00:00:00Z", dsun: 209.522735, hgln: 0.190536, hglt: -0.454303 },
  { isoDate: "2017-12-12T00:00:00Z", dsun: 209.509216, hgln: 0.196442, hglt: -0.582026 },
  { isoDate: "2017-12-13T00:00:00Z", dsun: 209.496921, hgln: 0.202034, hglt: -0.709544 },
  { isoDate: "2017-12-14T00:00:00Z", dsun: 209.485839, hgln: 0.207315, hglt: -0.836812 },
  { isoDate: "2017-12-15T00:00:00Z", dsun: 209.475957, hgln: 0.212287, hglt: -0.963789 },
  { isoDate: "2017-12-16T00:00:00Z", dsun: 209.467258, hgln: 0.216957, hglt: -1.090434 },
  { isoDate: "2017-12-17T00:00:00Z", dsun: 209.459729, hgln: 0.221328, hglt: -1.216706 },
  { isoDate: "2017-12-18T00:00:00Z", dsun: 209.453351, hgln: 0.225409, hglt: -1.342564 },
  { isoDate: "2017-12-19T00:00:00Z", dsun: 209.448105, hgln: 0.229208, hglt: -1.467967 },
  { isoDate: "2017-12-20T00:00:00Z", dsun: 209.443974, hgln: 0.232731, hglt: -1.592876 },
  { isoDate: "2017-12-21T00:00:00Z", dsun: 209.440937, hgln: 0.235989, hglt: -1.717251 },
  { isoDate: "2017-12-22T00:00:00Z", dsun: 209.438974, hgln: 0.238988, hglt: -1.841051 },
  { isoDate: "2017-12-23T00:00:00Z", dsun: 209.438067, hgln: 0.241737, hglt: -1.964237 },
  { isoDate: "2017-12-24T00:00:00Z", dsun: 209.438198, hgln: 0.244242, hglt: -2.086771 },
  { isoDate: "2017-12-25T00:00:00Z", dsun: 209.439347, hgln: 0.246509, hglt: -2.208612 },
  { isoDate: "2017-12-26T00:00:00Z", dsun: 209.441498, hgln: 0.248542, hglt: -2.329724 },
  { isoDate: "2017-12-27T00:00:00Z", dsun: 209.444632, hgln: 0.250343, hglt: -2.450067 },
  { isoDate: "2017-12-28T00:00:00Z", dsun: 209.448733, hgln: 0.251911, hglt: -2.569603 },
  { isoDate: "2017-12-29T00:00:00Z", dsun: 209.453782, hgln: 0.253242, hglt: -2.688296 },
  { isoDate: "2017-12-30T00:00:00Z", dsun: 209.459762, hgln: 0.254327, hglt: -2.806108 },
  { isoDate: "2017-12-31T00:00:00Z", dsun: 209.466656, hgln: 0.255153, hglt: -2.923001 },
  { isoDate: "2018-01-01T00:00:00Z", dsun: 209.474446, hgln: 0.2557, hglt: -3.038941 },
  { isoDate: "2018-01-02T00:00:00Z", dsun: 209.483115, hgln: 0.255946, hglt: -3.153891 },
  { isoDate: "2018-01-03T00:00:00Z", dsun: 209.492645, hgln: 0.255866, hglt: -3.267816 },
  { isoDate: "2018-01-04T00:00:00Z", dsun: 209.50302, hgln: 0.255433, hglt: -3.38068 },
  { isoDate: "2018-01-05T00:00:00Z", dsun: 209.514221, hgln: 0.254627, hglt: -3.49245 },
  { isoDate: "2018-01-06T00:00:00Z", dsun: 209.526235, hgln: 0.253428, hglt: -3.603092 },
  { isoDate: "2018-01-07T00:00:00Z", dsun: 209.539044, hgln: 0.251826, hglt: -3.712572 },
  { isoDate: "2018-01-08T00:00:00Z", dsun: 209.552636, hgln: 0.249812, hglt: -3.820858 },
  { isoDate: "2018-01-09T00:00:00Z", dsun: 209.566999, hgln: 0.247387, hglt: -3.927916 },
  { isoDate: "2018-01-10T00:00:00Z", dsun: 209.582119, hgln: 0.244552, hglt: -4.033715 },
  { isoDate: "2018-01-11T00:00:00Z", dsun: 209.59799, hgln: 0.241312, hglt: -4.138225 },
  { isoDate: "2018-01-12T00:00:00Z", dsun: 209.614602, hgln: 0.237676, hglt: -4.241413 },
  { isoDate: "2018-01-13T00:00:00Z", dsun: 209.631949, hgln: 0.233653, hglt: -4.343251 },
  { isoDate: "2018-01-14T00:00:00Z", dsun: 209.650025, hgln: 0.229255, hglt: -4.443708 },
  { isoDate: "2018-01-15T00:00:00Z", dsun: 209.668825, hgln: 0.224496, hglt: -4.542755 },
  { isoDate: "2018-01-16T00:00:00Z", dsun: 209.688341, hgln: 0.21939, hglt: -4.640365 },
  { isoDate: "2018-01-17T00:00:00Z", dsun: 209.708567, hgln: 0.213953, hglt: -4.736509 },
  { isoDate: "2018-01-18T00:00:00Z", dsun: 209.729497, hgln: 0.208202, hglt: -4.83116 },
  { isoDate: "2018-01-19T00:00:00Z", dsun: 209.751125, hgln: 0.202157, hglt: -4.924292 },
  { isoDate: "2018-01-20T00:00:00Z", dsun: 209.773444, hgln: 0.195838, hglt: -5.01588 },
  { isoDate: "2018-01-21T00:00:00Z", dsun: 209.796454, hgln: 0.189263, hglt: -5.105897 },
  { isoDate: "2018-01-22T00:00:00Z", dsun: 209.820153, hgln: 0.182454, hglt: -5.19432 },
  { isoDate: "2018-01-23T00:00:00Z", dsun: 209.844542, hgln: 0.175428, hglt: -5.281124 },
  { isoDate: "2018-01-24T00:00:00Z", dsun: 209.869623, hgln: 0.168203, hglt: -5.366287 },
  { isoDate: "2018-01-25T00:00:00Z", dsun: 209.895401, hgln: 0.160796, hglt: -5.449785 },
  { isoDate: "2018-01-26T00:00:00Z", dsun: 209.921882, hgln: 0.153218, hglt: -5.531596 },
  { isoDate: "2018-01-27T00:00:00Z", dsun: 209.949069, hgln: 0.14548, hglt: -5.6117 },
  { isoDate: "2018-01-28T00:00:00Z", dsun: 209.976971, hgln: 0.137586, hglt: -5.690075 },
  { isoDate: "2018-01-29T00:00:00Z", dsun: 210.005593, hgln: 0.129538, hglt: -5.7667 },
  { isoDate: "2018-01-30T00:00:00Z", dsun: 210.03494, hgln: 0.121331, hglt: -5.841558 },
  { isoDate: "2018-01-31T00:00:00Z", dsun: 210.065019, hgln: 0.112956, hglt: -5.914627 },
  { isoDate: "2018-02-01T00:00:00Z", dsun: 210.095834, hgln: 0.104403, hglt: -5.98589 },
  { isoDate: "2018-02-02T00:00:00Z", dsun: 210.127388, hgln: 0.095663, hglt: -6.055329 },
  { isoDate: "2018-02-03T00:00:00Z", dsun: 210.159684, hgln: 0.086726, hglt: -6.122926 },
  { isoDate: "2018-02-04T00:00:00Z", dsun: 210.192722, hgln: 0.077591, hglt: -6.188665 },
  { isoDate: "2018-02-05T00:00:00Z", dsun: 210.226503, hgln: 0.068258, hglt: -6.252528 },
  { isoDate: "2018-02-06T00:00:00Z", dsun: 210.261025, hgln: 0.058735, hglt: -6.3145 },
  { isoDate: "2018-02-07T00:00:00Z", dsun: 210.296286, hgln: 0.049034, hglt: -6.374564 },
  { isoDate: "2018-02-08T00:00:00Z", dsun: 210.332281, hgln: 0.039171, hglt: -6.432706 },
  { isoDate: "2018-02-09T00:00:00Z", dsun: 210.369005, hgln: 0.029164, hglt: -6.488911 },
  { isoDate: "2018-02-10T00:00:00Z", dsun: 210.406452, hgln: 0.019037, hglt: -6.543164 },
  { isoDate: "2018-02-11T00:00:00Z", dsun: 210.444615, hgln: 0.008813, hglt: -6.595452 },
  { isoDate: "2018-02-12T00:00:00Z", dsun: 210.483486, hgln: -0.001483, hglt: -6.64576 },
  { isoDate: "2018-02-13T00:00:00Z", dsun: 210.523045, hgln: -0.011822, hglt: -6.694078 },
  { isoDate: "2018-02-14T00:00:00Z", dsun: 210.563303, hgln: -0.022175, hglt: -6.74039 },
  { isoDate: "2018-02-15T00:00:00Z", dsun: 210.60424, hgln: -0.032514, hglt: -6.784684 },
  { isoDate: "2018-02-16T00:00:00Z", dsun: 210.645841, hgln: -0.042806, hglt: -6.826948 },
  { isoDate: "2018-02-17T00:00:00Z", dsun: 210.688088, hgln: -0.053022, hglt: -6.867172 },
  { isoDate: "2018-02-18T00:00:00Z", dsun: 210.730957, hgln: -0.06313, hglt: -6.905344 },
  { isoDate: "2018-02-19T00:00:00Z", dsun: 210.774424, hgln: -0.073099, hglt: -6.941452 },
  { isoDate: "2018-02-20T00:00:00Z", dsun: 210.81846, hgln: -0.0829, hglt: -6.975487 },
  { isoDate: "2018-02-21T00:00:00Z", dsun: 210.863034, hgln: -0.092502, hglt: -7.007437 },
  { isoDate: "2018-02-22T00:00:00Z", dsun: 210.908117, hgln: -0.101879, hglt: -7.037293 },
  { isoDate: "2018-02-23T00:00:00Z", dsun: 210.953678, hgln: -0.111008, hglt: -7.065046 },
  { isoDate: "2018-02-24T00:00:00Z", dsun: 210.999688, hgln: -0.119869, hglt: -7.090687 },
  { isoDate: "2018-02-25T00:00:00Z", dsun: 211.046119, hgln: -0.128447, hglt: -7.114207 },
  { isoDate: "2018-02-26T00:00:00Z", dsun: 211.092944, hgln: -0.136735, hglt: -7.1356 },
  { isoDate: "2018-02-27T00:00:00Z", dsun: 211.140136, hgln: -0.144728, hglt: -7.154858 },
  { isoDate: "2018-02-28T00:00:00Z", dsun: 211.187667, hgln: -0.15243, hglt: -7.171975 },
  { isoDate: "2018-03-01T00:00:00Z", dsun: 211.235513, hgln: -0.159849, hglt: -7.186946 },
  { isoDate: "2018-03-02T00:00:00Z", dsun: 211.283647, hgln: -0.166997, hglt: -7.199764 },
  { isoDate: "2018-03-03T00:00:00Z", dsun: 211.332045, hgln: -0.173885, hglt: -7.210426 },
  { isoDate: "2018-03-04T00:00:00Z", dsun: 211.380682, hgln: -0.180527, hglt: -7.218928 },
  { isoDate: "2018-03-05T00:00:00Z", dsun: 211.429534, hgln: -0.186932, hglt: -7.225267 },
  { isoDate: "2018-03-06T00:00:00Z", dsun: 211.478577, hgln: -0.193109, hglt: -7.22944 },
  { isoDate: "2018-03-07T00:00:00Z", dsun: 211.527789, hgln: -0.199061, hglt: -7.231445 },
  { isoDate: "2018-03-08T00:00:00Z", dsun: 211.577146, hgln: -0.20479, hglt: -7.231281 },
  { isoDate: "2018-03-09T00:00:00Z", dsun: 211.626628, hgln: -0.210295, hglt: -7.228948 },
  { isoDate: "2018-03-10T00:00:00Z", dsun: 211.676215, hgln: -0.215571, hglt: -7.224446 },
  { isoDate: "2018-03-11T00:00:00Z", dsun: 211.725888, hgln: -0.220614, hglt: -7.217776 },
  { isoDate: "2018-03-12T00:00:00Z", dsun: 211.775629, hgln: -0.225416, hglt: -7.20894 },
  { isoDate: "2018-03-13T00:00:00Z", dsun: 211.825422, hgln: -0.229969, hglt: -7.197942 },
  { isoDate: "2018-03-14T00:00:00Z", dsun: 211.875253, hgln: -0.234262, hglt: -7.184782 },
  { isoDate: "2018-03-15T00:00:00Z", dsun: 211.925107, hgln: -0.238283, hglt: -7.169466 },
  { isoDate: "2018-03-16T00:00:00Z", dsun: 211.974976, hgln: -0.242018, hglt: -7.151997 },
  { isoDate: "2018-03-17T00:00:00Z", dsun: 212.02485, hgln: -0.245452, hglt: -7.132383 },
  { isoDate: "2018-03-18T00:00:00Z", dsun: 212.074721, hgln: -0.248567, hglt: -7.110628 },
  { isoDate: "2018-03-19T00:00:00Z", dsun: 212.124584, hgln: -0.251348, hglt: -7.086741 },
  { isoDate: "2018-03-20T00:00:00Z", dsun: 212.174432, hgln: -0.253774, hglt: -7.060727 },
  { isoDate: "2018-03-21T00:00:00Z", dsun: 212.224262, hgln: -0.255831, hglt: -7.032597 },
  { isoDate: "2018-03-22T00:00:00Z", dsun: 212.274066, hgln: -0.257502, hglt: -7.002359 },
  { isoDate: "2018-03-23T00:00:00Z", dsun: 212.323839, hgln: -0.258775, hglt: -6.970022 },
  { isoDate: "2018-03-24T00:00:00Z", dsun: 212.373575, hgln: -0.259641, hglt: -6.935597 },
  { isoDate: "2018-03-25T00:00:00Z", dsun: 212.423269, hgln: -0.260096, hglt: -6.899096 },
  { isoDate: "2018-03-26T00:00:00Z", dsun: 212.472919, hgln: -0.260138, hglt: -6.860529 },
  { isoDate: "2018-03-27T00:00:00Z", dsun: 212.522522, hgln: -0.259773, hglt: -6.81991 },
  { isoDate: "2018-03-28T00:00:00Z", dsun: 212.572077, hgln: -0.259011, hglt: -6.777251 },
  { isoDate: "2018-03-29T00:00:00Z", dsun: 212.621585, hgln: -0.257868, hglt: -6.732568 },
  { isoDate: "2018-03-30T00:00:00Z", dsun: 212.671048, hgln: -0.256361, hglt: -6.685873 },
  { isoDate: "2018-03-31T00:00:00Z", dsun: 212.720466, hgln: -0.254512, hglt: -6.637182 },
  { isoDate: "2018-04-01T00:00:00Z", dsun: 212.769842, hgln: -0.252344, hglt: -6.586512 },
  { isoDate: "2018-04-02T00:00:00Z", dsun: 212.819179, hgln: -0.249879, hglt: -6.533878 },
  { isoDate: "2018-04-03T00:00:00Z", dsun: 212.868479, hgln: -0.247137, hglt: -6.479298 },
  { isoDate: "2018-04-04T00:00:00Z", dsun: 212.917746, hgln: -0.244138, hglt: -6.422789 },
  { isoDate: "2018-04-05T00:00:00Z", dsun: 212.966982, hgln: -0.240897, hglt: -6.36437 },
  { isoDate: "2018-04-06T00:00:00Z", dsun: 213.016189, hgln: -0.237428, hglt: -6.30406 },
  { isoDate: "2018-04-07T00:00:00Z", dsun: 213.06537, hgln: -0.233742, hglt: -6.241878 },
  { isoDate: "2018-04-08T00:00:00Z", dsun: 213.114528, hgln: -0.229849, hglt: -6.177845 },
  { isoDate: "2018-04-09T00:00:00Z", dsun: 213.163666, hgln: -0.225756, hglt: -6.11198 },
  { isoDate: "2018-04-10T00:00:00Z", dsun: 213.212772, hgln: -0.221462, hglt: -6.044304 },
  { isoDate: "2018-04-11T00:00:00Z", dsun: 213.261876, hgln: -0.216982, hglt: -5.974842 },
  { isoDate: "2018-04-12T00:00:00Z", dsun: 213.310968, hgln: -0.212313, hglt: -5.903615 },
  { isoDate: "2018-04-13T00:00:00Z", dsun: 213.360051, hgln: -0.207452, hglt: -5.830646 },
  { isoDate: "2018-04-14T00:00:00Z", dsun: 213.409129, hgln: -0.202396, hglt: -5.755958 },
  { isoDate: "2018-04-15T00:00:00Z", dsun: 213.458206, hgln: -0.19714, hglt: -5.679574 },
  { isoDate: "2018-04-16T00:00:00Z", dsun: 213.507286, hgln: -0.191674, hglt: -5.60152 },
  { isoDate: "2018-04-17T00:00:00Z", dsun: 213.556373, hgln: -0.185989, hglt: -5.521821 },
  { isoDate: "2018-04-18T00:00:00Z", dsun: 213.605471, hgln: -0.180073, hglt: -5.440502 },
  { isoDate: "2018-04-19T00:00:00Z", dsun: 213.654582, hgln: -0.173916, hglt: -5.357588 },
  { isoDate: "2018-04-20T00:00:00Z", dsun: 213.703706, hgln: -0.167507, hglt: -5.273107 },
  { isoDate: "2018-04-21T00:00:00Z", dsun: 213.752844, hgln: -0.16084, hglt: -5.187085 },
  { isoDate: "2018-04-22T00:00:00Z", dsun: 213.801995, hgln: -0.153913, hglt: -5.09955 },
  { isoDate: "2018-04-23T00:00:00Z", dsun: 213.851158, hgln: -0.146728, hglt: -5.010529 },
  { isoDate: "2018-04-24T00:00:00Z", dsun: 213.900332, hgln: -0.139292, hglt: -4.920051 },
  { isoDate: "2018-04-25T00:00:00Z", dsun: 213.949516, hgln: -0.131618, hglt: -4.828144 },
  { isoDate: "2018-04-26T00:00:00Z", dsun: 213.99871, hgln: -0.123722, hglt: -4.734837 },
  { isoDate: "2018-04-27T00:00:00Z", dsun: 214.047912, hgln: -0.115624, hglt: -4.64016 },
  { isoDate: "2018-04-28T00:00:00Z", dsun: 214.09712, hgln: -0.107349, hglt: -4.544142 },
  { isoDate: "2018-04-29T00:00:00Z", dsun: 214.146332, hgln: -0.098923, hglt: -4.446814 },
  { isoDate: "2018-04-30T00:00:00Z", dsun: 214.195544, hgln: -0.090371, hglt: -4.348206 },
  { isoDate: "2018-05-01T00:00:00Z", dsun: 214.244753, hgln: -0.081721, hglt: -4.248348 },
  { isoDate: "2018-05-02T00:00:00Z", dsun: 214.293952, hgln: -0.072998, hglt: -4.147272 },
  { isoDate: "2018-05-03T00:00:00Z", dsun: 214.343137, hgln: -0.064227, hglt: -4.04501 },
  { isoDate: "2018-05-04T00:00:00Z", dsun: 214.392299, hgln: -0.055429, hglt: -3.941593 },
  { isoDate: "2018-05-05T00:00:00Z", dsun: 214.44143, hgln: -0.046627, hglt: -3.837053 },
  { isoDate: "2018-05-06T00:00:00Z", dsun: 214.490521, hgln: -0.037837, hglt: -3.731423 },
  { isoDate: "2018-05-07T00:00:00Z", dsun: 214.539562, hgln: -0.029077, hglt: -3.624735 },
  { isoDate: "2018-05-08T00:00:00Z", dsun: 214.588541, hgln: -0.020358, hglt: -3.517021 },
  { isoDate: "2018-05-09T00:00:00Z", dsun: 214.637449, hgln: -0.011698, hglt: -3.408317 },
  { isoDate: "2018-05-10T00:00:00Z", dsun: 214.686273, hgln: -0.003104, hglt: -3.298653 },
  { isoDate: "2018-05-11T00:00:00Z", dsun: 214.735, hgln: 0.005416, hglt: -3.188065 },
  { isoDate: "2018-05-12T00:00:00Z", dsun: 214.783618, hgln: 0.013858, hglt: -3.076585 },
  { isoDate: "2018-05-13T00:00:00Z", dsun: 214.832113, hgln: 0.022219, hglt: -2.964247 },
  { isoDate: "2018-05-14T00:00:00Z", dsun: 214.880472, hgln: 0.030503, hglt: -2.851086 },
  { isoDate: "2018-05-15T00:00:00Z", dsun: 214.92868, hgln: 0.038714, hglt: -2.737136 },
  { isoDate: "2018-05-16T00:00:00Z", dsun: 214.976722, hgln: 0.046862, hglt: -2.62243 },
  { isoDate: "2018-05-17T00:00:00Z", dsun: 215.024579, hgln: 0.054957, hglt: -2.507004 },
  { isoDate: "2018-05-18T00:00:00Z", dsun: 215.072232, hgln: 0.063011, hglt: -2.390893 },
  { isoDate: "2018-05-19T00:00:00Z", dsun: 215.119661, hgln: 0.071035, hglt: -2.27413 },
  { isoDate: "2018-05-20T00:00:00Z", dsun: 215.166845, hgln: 0.079034, hglt: -2.15675 },
  { isoDate: "2018-05-21T00:00:00Z", dsun: 215.213762, hgln: 0.087013, hglt: -2.038789 },
  { isoDate: "2018-05-22T00:00:00Z", dsun: 215.260391, hgln: 0.094968, hglt: -1.920281 },
  { isoDate: "2018-05-23T00:00:00Z", dsun: 215.306712, hgln: 0.102892, hglt: -1.801262 },
  { isoDate: "2018-05-24T00:00:00Z", dsun: 215.352701, hgln: 0.110774, hglt: -1.681765 },
  { isoDate: "2018-05-25T00:00:00Z", dsun: 215.398338, hgln: 0.118598, hglt: -1.561827 },
  { isoDate: "2018-05-26T00:00:00Z", dsun: 215.4436, hgln: 0.126345, hglt: -1.441482 },
  { isoDate: "2018-05-27T00:00:00Z", dsun: 215.488462, hgln: 0.133996, hglt: -1.320766 },
  { isoDate: "2018-05-28T00:00:00Z", dsun: 215.532902, hgln: 0.141528, hglt: -1.199713 },
  { isoDate: "2018-05-29T00:00:00Z", dsun: 215.576895, hgln: 0.148919, hglt: -1.078358 },
  { isoDate: "2018-05-30T00:00:00Z", dsun: 215.620414, hgln: 0.156145, hglt: -0.956736 },
  { isoDate: "2018-05-31T00:00:00Z", dsun: 215.663436, hgln: 0.163185, hglt: -0.834883 },
  { isoDate: "2018-06-01T00:00:00Z", dsun: 215.705931, hgln: 0.170018, hglt: -0.712833 },
  { isoDate: "2018-06-02T00:00:00Z", dsun: 215.747875, hgln: 0.176624, hglt: -0.590621 },
  { isoDate: "2018-06-03T00:00:00Z", dsun: 215.78924, hgln: 0.182985, hglt: -0.468281 },
  { isoDate: "2018-06-04T00:00:00Z", dsun: 215.829998, hgln: 0.189086, hglt: -0.34585 },
  { isoDate: "2018-06-05T00:00:00Z", dsun: 215.870122, hgln: 0.194911, hglt: -0.22336 },
  { isoDate: "2018-06-06T00:00:00Z", dsun: 215.909584, hgln: 0.20045, hglt: -0.100847 },
  { isoDate: "2018-06-07T00:00:00Z", dsun: 215.948359, hgln: 0.205691, hglt: 0.021655 },
  { isoDate: "2018-06-08T00:00:00Z", dsun: 215.986419, hgln: 0.210626, hglt: 0.144112 },
  { isoDate: "2018-06-09T00:00:00Z", dsun: 216.023739, hgln: 0.215251, hglt: 0.26649 },
  { isoDate: "2018-06-10T00:00:00Z", dsun: 216.060292, hgln: 0.219565, hglt: 0.388754 },
  { isoDate: "2018-06-11T00:00:00Z", dsun: 216.096053, hgln: 0.223568, hglt: 0.51087 },
  { isoDate: "2018-06-12T00:00:00Z", dsun: 216.130997, hgln: 0.227269, hglt: 0.632806 },
  { isoDate: "2018-06-13T00:00:00Z", dsun: 216.165098, hgln: 0.230677, hglt: 0.754527 },
  { isoDate: "2018-06-14T00:00:00Z", dsun: 216.198327, hgln: 0.233809, hglt: 0.875999 },
  { isoDate: "2018-06-15T00:00:00Z", dsun: 216.230659, hgln: 0.236684, hglt: 0.99719 },
  { isoDate: "2018-06-16T00:00:00Z", dsun: 216.262065, hgln: 0.239319, hglt: 1.118065 },
  { isoDate: "2018-06-17T00:00:00Z", dsun: 216.292521, hgln: 0.241734, hglt: 1.238593 },
  { isoDate: "2018-06-18T00:00:00Z", dsun: 216.321999, hgln: 0.243943, hglt: 1.35874 },
  { isoDate: "2018-06-19T00:00:00Z", dsun: 216.350476, hgln: 0.245955, hglt: 1.478474 },
  { isoDate: "2018-06-20T00:00:00Z", dsun: 216.377928, hgln: 0.247774, hglt: 1.597762 },
  { isoDate: "2018-06-21T00:00:00Z", dsun: 216.404334, hgln: 0.2494, hglt: 1.716572 },
  { isoDate: "2018-06-22T00:00:00Z", dsun: 216.429669, hgln: 0.250828, hglt: 1.834872 },
  { isoDate: "2018-06-23T00:00:00Z", dsun: 216.453915, hgln: 0.25205, hglt: 1.952629 },
  { isoDate: "2018-06-24T00:00:00Z", dsun: 216.47705, hgln: 0.253057, hglt: 2.069813 },
  { isoDate: "2018-06-25T00:00:00Z", dsun: 216.499053, hgln: 0.253835, hglt: 2.186391 },
  { isoDate: "2018-06-26T00:00:00Z", dsun: 216.519905, hgln: 0.25437, hglt: 2.302332 },
  { isoDate: "2018-06-27T00:00:00Z", dsun: 216.539587, hgln: 0.254649, hglt: 2.417606 },
  { isoDate: "2018-06-28T00:00:00Z", dsun: 216.558081, hgln: 0.254658, hglt: 2.53218 },
  { isoDate: "2018-06-29T00:00:00Z", dsun: 216.575368, hgln: 0.25438, hglt: 2.646025 },
  { isoDate: "2018-06-30T00:00:00Z", dsun: 216.591431, hgln: 0.253803, hglt: 2.75911 },
  { isoDate: "2018-07-01T00:00:00Z", dsun: 216.606254, hgln: 0.252913, hglt: 2.871404 },
  { isoDate: "2018-07-02T00:00:00Z", dsun: 216.619822, hgln: 0.251698, hglt: 2.982878 },
  { isoDate: "2018-07-03T00:00:00Z", dsun: 216.63212, hgln: 0.250147, hglt: 3.093501 },
  { isoDate: "2018-07-04T00:00:00Z", dsun: 216.643137, hgln: 0.24825, hglt: 3.203245 },
  { isoDate: "2018-07-05T00:00:00Z", dsun: 216.65286, hgln: 0.246001, hglt: 3.312079 },
  { isoDate: "2018-07-06T00:00:00Z", dsun: 216.661281, hgln: 0.243393, hglt: 3.419975 },
  { isoDate: "2018-07-07T00:00:00Z", dsun: 216.668391, hgln: 0.240423, hglt: 3.526903 },
  { isoDate: "2018-07-08T00:00:00Z", dsun: 216.674184, hgln: 0.23709, hglt: 3.632835 },
  { isoDate: "2018-07-09T00:00:00Z", dsun: 216.678656, hgln: 0.233399, hglt: 3.737742 },
  { isoDate: "2018-07-10T00:00:00Z", dsun: 216.681801, hgln: 0.229355, hglt: 3.841597 },
  { isoDate: "2018-07-11T00:00:00Z", dsun: 216.683625, hgln: 0.224974, hglt: 3.94437 },
  { isoDate: "2018-07-12T00:00:00Z", dsun: 216.684121, hgln: 0.220271, hglt: 4.046034 },
  { isoDate: "2018-07-13T00:00:00Z", dsun: 216.68329, hgln: 0.21527, hglt: 4.146562 },
  { isoDate: "2018-07-14T00:00:00Z", dsun: 216.68113, hgln: 0.209996, hglt: 4.245925 },
  { isoDate: "2018-07-15T00:00:00Z", dsun: 216.677641, hgln: 0.204477, hglt: 4.344099 },
  { isoDate: "2018-07-16T00:00:00Z", dsun: 216.672824, hgln: 0.198738, hglt: 4.441055 },
  { isoDate: "2018-07-17T00:00:00Z", dsun: 216.666684, hgln: 0.192802, hglt: 4.536767 },
  { isoDate: "2018-07-18T00:00:00Z", dsun: 216.659226, hgln: 0.186685, hglt: 4.631209 },
  { isoDate: "2018-07-19T00:00:00Z", dsun: 216.650459, hgln: 0.180401, hglt: 4.724356 },
  { isoDate: "2018-07-20T00:00:00Z", dsun: 216.640393, hgln: 0.173957, hglt: 4.816182 },
  { isoDate: "2018-07-21T00:00:00Z", dsun: 216.62904, hgln: 0.167359, hglt: 4.906661 },
  { isoDate: "2018-07-22T00:00:00Z", dsun: 216.616413, hgln: 0.160607, hglt: 4.995768 },
  { isoDate: "2018-07-23T00:00:00Z", dsun: 216.602526, hgln: 0.153702, hglt: 5.083478 },
  { isoDate: "2018-07-24T00:00:00Z", dsun: 216.587395, hgln: 0.146641, hglt: 5.169768 },
  { isoDate: "2018-07-25T00:00:00Z", dsun: 216.571034, hgln: 0.139422, hglt: 5.254613 },
  { isoDate: "2018-07-26T00:00:00Z", dsun: 216.553461, hgln: 0.132041, hglt: 5.337988 },
  { isoDate: "2018-07-27T00:00:00Z", dsun: 216.534692, hgln: 0.124493, hglt: 5.419872 },
  { isoDate: "2018-07-28T00:00:00Z", dsun: 216.514744, hgln: 0.116775, hglt: 5.500241 },
  { isoDate: "2018-07-29T00:00:00Z", dsun: 216.493634, hgln: 0.108882, hglt: 5.579072 },
  { isoDate: "2018-07-30T00:00:00Z", dsun: 216.47138, hgln: 0.10081, hglt: 5.656342 },
  { isoDate: "2018-07-31T00:00:00Z", dsun: 216.447998, hgln: 0.092558, hglt: 5.732031 },
  { isoDate: "2018-08-01T00:00:00Z", dsun: 216.423504, hgln: 0.084123, hglt: 5.806117 },
  { isoDate: "2018-08-02T00:00:00Z", dsun: 216.397916, hgln: 0.075505, hglt: 5.878578 },
  { isoDate: "2018-08-03T00:00:00Z", dsun: 216.371249, hgln: 0.066706, hglt: 5.949394 },
  { isoDate: "2018-08-04T00:00:00Z", dsun: 216.343519, hgln: 0.05773, hglt: 6.018545 },
  { isoDate: "2018-08-05T00:00:00Z", dsun: 216.314742, hgln: 0.048584, hglt: 6.086012 },
  { isoDate: "2018-08-06T00:00:00Z", dsun: 216.284932, hgln: 0.039278, hglt: 6.151774 },
  { isoDate: "2018-08-07T00:00:00Z", dsun: 216.254102, hgln: 0.029825, hglt: 6.215813 },
  { isoDate: "2018-08-08T00:00:00Z", dsun: 216.222273, hgln: 0.020246, hglt: 6.27811 },
  { isoDate: "2018-08-09T00:00:00Z", dsun: 216.189458, hgln: 0.010566, hglt: 6.338648 },
  { isoDate: "2018-08-10T00:00:00Z", dsun: 216.155673, hgln: 0.000813, hglt: 6.397408 },
  { isoDate: "2018-08-11T00:00:00Z", dsun: 216.12093, hgln: -0.008978, hglt: 6.454374 },
  { isoDate: "2018-08-12T00:00:00Z", dsun: 216.085243, hgln: -0.01877, hglt: 6.509528 },
  { isoDate: "2018-08-13T00:00:00Z", dsun: 216.048618, hgln: -0.028527, hglt: 6.562855 },
  { isoDate: "2018-08-14T00:00:00Z", dsun: 216.011062, hgln: -0.038214, hglt: 6.614339 },
  { isoDate: "2018-08-15T00:00:00Z", dsun: 215.972574, hgln: -0.047799, hglt: 6.663965 },
  { isoDate: "2018-08-16T00:00:00Z", dsun: 215.933157, hgln: -0.057254, hglt: 6.711718 },
  { isoDate: "2018-08-17T00:00:00Z", dsun: 215.892808, hgln: -0.066559, hglt: 6.757585 },
  { isoDate: "2018-08-18T00:00:00Z", dsun: 215.85153, hgln: -0.075694, hglt: 6.80155 },
  { isoDate: "2018-08-19T00:00:00Z", dsun: 215.809322, hgln: -0.084646, hglt: 6.843602 },
  { isoDate: "2018-08-20T00:00:00Z", dsun: 215.766184, hgln: -0.093404, hglt: 6.883727 },
  { isoDate: "2018-08-21T00:00:00Z", dsun: 215.72212, hgln: -0.101961, hglt: 6.921912 },
  { isoDate: "2018-08-22T00:00:00Z", dsun: 215.677128, hgln: -0.11031, hglt: 6.958146 },
  { isoDate: "2018-08-23T00:00:00Z", dsun: 215.631211, hgln: -0.11845, hglt: 6.992416 },
  { isoDate: "2018-08-24T00:00:00Z", dsun: 215.584371, hgln: -0.126378, hglt: 7.024712 },
  { isoDate: "2018-08-25T00:00:00Z", dsun: 215.536609, hgln: -0.134095, hglt: 7.055024 },
  { isoDate: "2018-08-26T00:00:00Z", dsun: 215.487926, hgln: -0.141605, hglt: 7.08334 },
  { isoDate: "2018-08-27T00:00:00Z", dsun: 215.438325, hgln: -0.148911, hglt: 7.109651 },
  { isoDate: "2018-08-28T00:00:00Z", dsun: 215.387807, hgln: -0.156018, hglt: 7.133947 },
  { isoDate: "2018-08-29T00:00:00Z", dsun: 215.336376, hgln: -0.162933, hglt: 7.15622 },
  { isoDate: "2018-08-30T00:00:00Z", dsun: 215.284035, hgln: -0.169662, hglt: 7.176462 },
  { isoDate: "2018-08-31T00:00:00Z", dsun: 215.230787, hgln: -0.17621, hglt: 7.194663 },
  { isoDate: "2018-09-01T00:00:00Z", dsun: 215.176636, hgln: -0.182584, hglt: 7.210818 },
  { isoDate: "2018-09-02T00:00:00Z", dsun: 215.121588, hgln: -0.188788, hglt: 7.224917 },
  { isoDate: "2018-09-03T00:00:00Z", dsun: 215.065649, hgln: -0.194822, hglt: 7.236956 },
  { isoDate: "2018-09-04T00:00:00Z", dsun: 215.008826, hgln: -0.200687, hglt: 7.246927 },
  { isoDate: "2018-09-05T00:00:00Z", dsun: 214.951128, hgln: -0.206377, hglt: 7.254826 },
  { isoDate: "2018-09-06T00:00:00Z", dsun: 214.892566, hgln: -0.211883, hglt: 7.260646 },
  { isoDate: "2018-09-07T00:00:00Z", dsun: 214.833152, hgln: -0.217189, hglt: 7.264383 },
  { isoDate: "2018-09-08T00:00:00Z", dsun: 214.7729, hgln: -0.222277, hglt: 7.266032 },
  { isoDate: "2018-09-09T00:00:00Z", dsun: 214.711827, hgln: -0.227123, hglt: 7.265591 },
  { isoDate: "2018-09-10T00:00:00Z", dsun: 214.649953, hgln: -0.231701, hglt: 7.263056 },
  { isoDate: "2018-09-11T00:00:00Z", dsun: 214.587291, hgln: -0.235982, hglt: 7.258419 },
  { isoDate: "2018-09-12T00:00:00Z", dsun: 214.523876, hgln: -0.239949, hglt: 7.251687 },
  { isoDate: "2018-09-13T00:00:00Z", dsun: 214.459724, hgln: -0.243579, hglt: 7.242855 },
  { isoDate: "2018-09-14T00:00:00Z", dsun: 214.394856, hgln: -0.246856, hglt: 7.23192 },
  { isoDate: "2018-09-15T00:00:00Z", dsun: 214.329295, hgln: -0.249768, hglt: 7.218883 },
  { isoDate: "2018-09-16T00:00:00Z", dsun: 214.263062, hgln: -0.25231, hglt: 7.203744 },
  { isoDate: "2018-09-17T00:00:00Z", dsun: 214.196184, hgln: -0.254476, hglt: 7.186503 },
  { isoDate: "2018-09-18T00:00:00Z", dsun: 214.128684, hgln: -0.256265, hglt: 7.16716 },
  { isoDate: "2018-09-19T00:00:00Z", dsun: 214.060591, hgln: -0.25768, hglt: 7.145719 },
  { isoDate: "2018-09-20T00:00:00Z", dsun: 213.991933, hgln: -0.258722, hglt: 7.12218 },
  { isoDate: "2018-09-21T00:00:00Z", dsun: 213.92274, hgln: -0.2594, hglt: 7.096548 },
  { isoDate: "2018-09-22T00:00:00Z", dsun: 213.853046, hgln: -0.259719, hglt: 7.068825 },
  { isoDate: "2018-09-23T00:00:00Z", dsun: 213.782881, hgln: -0.25969, hglt: 7.039015 },
  { isoDate: "2018-09-24T00:00:00Z", dsun: 213.712281, hgln: -0.259326, hglt: 7.007124 },
  { isoDate: "2018-09-25T00:00:00Z", dsun: 213.641281, hgln: -0.25864, hglt: 6.973156 },
  { isoDate: "2018-09-26T00:00:00Z", dsun: 213.569914, hgln: -0.257648, hglt: 6.937118 },
  { isoDate: "2018-09-27T00:00:00Z", dsun: 213.498219, hgln: -0.256367, hglt: 6.899016 },
  { isoDate: "2018-09-28T00:00:00Z", dsun: 213.426231, hgln: -0.254815, hglt: 6.858858 },
  { isoDate: "2018-09-29T00:00:00Z", dsun: 213.353986, hgln: -0.253009, hglt: 6.816652 },
  { isoDate: "2018-09-30T00:00:00Z", dsun: 213.281522, hgln: -0.250966, hglt: 6.772405 },
  { isoDate: "2018-10-01T00:00:00Z", dsun: 213.208877, hgln: -0.248701, hglt: 6.726128 },
  { isoDate: "2018-10-02T00:00:00Z", dsun: 213.136075, hgln: -0.24622, hglt: 6.677829 },
  { isoDate: "2018-10-03T00:00:00Z", dsun: 213.063178, hgln: -0.243542, hglt: 6.627521 },
  { isoDate: "2018-10-04T00:00:00Z", dsun: 212.990214, hgln: -0.240665, hglt: 6.575215 },
  { isoDate: "2018-10-05T00:00:00Z", dsun: 212.917221, hgln: -0.237591, hglt: 6.520922 },
  { isoDate: "2018-10-06T00:00:00Z", dsun: 212.844237, hgln: -0.234311, hglt: 6.464654 },
  { isoDate: "2018-10-07T00:00:00Z", dsun: 212.771302, hgln: -0.230818, hglt: 6.406426 },
  { isoDate: "2018-10-08T00:00:00Z", dsun: 212.698455, hgln: -0.227096, hglt: 6.346251 },
  { isoDate: "2018-10-09T00:00:00Z", dsun: 212.625739, hgln: -0.223132, hglt: 6.284144 },
  { isoDate: "2018-10-10T00:00:00Z", dsun: 212.553193, hgln: -0.218908, hglt: 6.220121 },
  { isoDate: "2018-10-11T00:00:00Z", dsun: 212.48086, hgln: -0.21441, hglt: 6.154197 },
  { isoDate: "2018-10-12T00:00:00Z", dsun: 212.40878, hgln: -0.209628, hglt: 6.08639 },
  { isoDate: "2018-10-13T00:00:00Z", dsun: 212.336992, hgln: -0.204552, hglt: 6.016717 },
  { isoDate: "2018-10-14T00:00:00Z", dsun: 212.265534, hgln: -0.199178, hglt: 5.945197 },
  { isoDate: "2018-10-15T00:00:00Z", dsun: 212.194444, hgln: -0.193506, hglt: 5.871848 },
  { isoDate: "2018-10-16T00:00:00Z", dsun: 212.123759, hgln: -0.187536, hglt: 5.796689 },
  { isoDate: "2018-10-17T00:00:00Z", dsun: 212.053517, hgln: -0.181272, hglt: 5.719742 },
  { isoDate: "2018-10-18T00:00:00Z", dsun: 211.983755, hgln: -0.174722, hglt: 5.641027 },
  { isoDate: "2018-10-19T00:00:00Z", dsun: 211.91451, hgln: -0.167895, hglt: 5.560565 },
  { isoDate: "2018-10-20T00:00:00Z", dsun: 211.845819, hgln: -0.160802, hglt: 5.478379 },
  { isoDate: "2018-10-21T00:00:00Z", dsun: 211.777719, hgln: -0.153457, hglt: 5.394493 },
  { isoDate: "2018-10-22T00:00:00Z", dsun: 211.710247, hgln: -0.145877, hglt: 5.30893 },
  { isoDate: "2018-10-23T00:00:00Z", dsun: 211.643439, hgln: -0.13808, hglt: 5.221714 },
  { isoDate: "2018-10-24T00:00:00Z", dsun: 211.577329, hgln: -0.130089, hglt: 5.132871 },
  { isoDate: "2018-10-25T00:00:00Z", dsun: 211.511951, hgln: -0.121929, hglt: 5.042426 },
  { isoDate: "2018-10-26T00:00:00Z", dsun: 211.447339, hgln: -0.113625, hglt: 4.950406 },
  { isoDate: "2018-10-27T00:00:00Z", dsun: 211.383524, hgln: -0.105205, hglt: 4.856837 },
  { isoDate: "2018-10-28T00:00:00Z", dsun: 211.320537, hgln: -0.096697, hglt: 4.761748 },
  { isoDate: "2018-10-29T00:00:00Z", dsun: 211.258408, hgln: -0.088128, hglt: 4.665167 },
  { isoDate: "2018-10-30T00:00:00Z", dsun: 211.197164, hgln: -0.079521, hglt: 4.567122 },
  { isoDate: "2018-10-31T00:00:00Z", dsun: 211.136833, hgln: -0.070896, hglt: 4.467643 },
  { isoDate: "2018-11-01T00:00:00Z", dsun: 211.07744, hgln: -0.062271, hglt: 4.366761 },
  { isoDate: "2018-11-02T00:00:00Z", dsun: 211.019011, hgln: -0.053656, hglt: 4.264505 },
  { isoDate: "2018-11-03T00:00:00Z", dsun: 210.96157, hgln: -0.045057, hglt: 4.160907 },
  { isoDate: "2018-11-04T00:00:00Z", dsun: 210.90514, hgln: -0.036477, hglt: 4.056 },
  { isoDate: "2018-11-05T00:00:00Z", dsun: 210.849745, hgln: -0.027914, hglt: 3.949815 },
  { isoDate: "2018-11-06T00:00:00Z", dsun: 210.795406, hgln: -0.01936, hglt: 3.842385 },
  { isoDate: "2018-11-07T00:00:00Z", dsun: 210.742146, hgln: -0.010813, hglt: 3.733744 },
  { isoDate: "2018-11-08T00:00:00Z", dsun: 210.689983, hgln: -0.002265, hglt: 3.623926 },
  { isoDate: "2018-11-09T00:00:00Z", dsun: 210.638935, hgln: 0.006292, hglt: 3.512965 },
  { isoDate: "2018-11-10T00:00:00Z", dsun: 210.589016, hgln: 0.014863, hglt: 3.400896 },
  { isoDate: "2018-11-11T00:00:00Z", dsun: 210.540241, hgln: 0.023451, hglt: 3.287754 },
  { isoDate: "2018-11-12T00:00:00Z", dsun: 210.49262, hgln: 0.032058, hglt: 3.173576 },
  { isoDate: "2018-11-13T00:00:00Z", dsun: 210.446163, hgln: 0.040682, hglt: 3.058397 },
  { isoDate: "2018-11-14T00:00:00Z", dsun: 210.400882, hgln: 0.04932, hglt: 2.942254 },
  { isoDate: "2018-11-15T00:00:00Z", dsun: 210.356784, hgln: 0.057967, hglt: 2.825184 },
  { isoDate: "2018-11-16T00:00:00Z", dsun: 210.313878, hgln: 0.066614, hglt: 2.707224 },
  { isoDate: "2018-11-17T00:00:00Z", dsun: 210.272171, hgln: 0.075252, hglt: 2.588412 },
  { isoDate: "2018-11-18T00:00:00Z", dsun: 210.231668, hgln: 0.08387, hglt: 2.468785 },
  { isoDate: "2018-11-19T00:00:00Z", dsun: 210.192375, hgln: 0.092453, hglt: 2.348384 },
  { isoDate: "2018-11-20T00:00:00Z", dsun: 210.154295, hgln: 0.100986, hglt: 2.227245 },
  { isoDate: "2018-11-21T00:00:00Z", dsun: 210.117431, hgln: 0.109448, hglt: 2.105408 },
  { isoDate: "2018-11-22T00:00:00Z", dsun: 210.081783, hgln: 0.117816, hglt: 1.982913 },
  { isoDate: "2018-11-23T00:00:00Z", dsun: 210.047351, hgln: 0.126065, hglt: 1.859799 },
  { isoDate: "2018-11-24T00:00:00Z", dsun: 210.014133, hgln: 0.134166, hglt: 1.736105 },
  { isoDate: "2018-11-25T00:00:00Z", dsun: 209.982125, hgln: 0.142089, hglt: 1.611873 },
  { isoDate: "2018-11-26T00:00:00Z", dsun: 209.951324, hgln: 0.149806, hglt: 1.48714 },
  { isoDate: "2018-11-27T00:00:00Z", dsun: 209.921722, hgln: 0.157287, hglt: 1.361949 },
  { isoDate: "2018-11-28T00:00:00Z", dsun: 209.893315, hgln: 0.164509, hglt: 1.236339 },
  { isoDate: "2018-11-29T00:00:00Z", dsun: 209.866093, hgln: 0.171452, hglt: 1.110351 },
  { isoDate: "2018-11-30T00:00:00Z", dsun: 209.84005, hgln: 0.178101, hglt: 0.984026 },
  { isoDate: "2018-12-01T00:00:00Z", dsun: 209.815177, hgln: 0.184447, hglt: 0.857403 },
  { isoDate: "2018-12-02T00:00:00Z", dsun: 209.791465, hgln: 0.190486, hglt: 0.730526 },
  { isoDate: "2018-12-03T00:00:00Z", dsun: 209.768906, hgln: 0.196218, hglt: 0.603433 },
  { isoDate: "2018-12-04T00:00:00Z", dsun: 209.747491, hgln: 0.201646, hglt: 0.476167 },
  { isoDate: "2018-12-05T00:00:00Z", dsun: 209.727209, hgln: 0.206778, hglt: 0.348768 },
  { isoDate: "2018-12-06T00:00:00Z", dsun: 209.708049, hgln: 0.21162, hglt: 0.221278 },
  { isoDate: "2018-12-07T00:00:00Z", dsun: 209.689999, hgln: 0.216183, hglt: 0.093737 },
  { isoDate: "2018-12-08T00:00:00Z", dsun: 209.673044, hgln: 0.220475, hglt: -0.033813 },
  { isoDate: "2018-12-09T00:00:00Z", dsun: 209.657169, hgln: 0.224508, hglt: -0.161332 },
  { isoDate: "2018-12-10T00:00:00Z", dsun: 209.642358, hgln: 0.228288, hglt: -0.288778 },
  { isoDate: "2018-12-11T00:00:00Z", dsun: 209.628597, hgln: 0.231825, hglt: -0.416109 },
  { isoDate: "2018-12-12T00:00:00Z", dsun: 209.615869, hgln: 0.235124, hglt: -0.543289 },
  { isoDate: "2018-12-13T00:00:00Z", dsun: 209.604159, hgln: 0.23819, hglt: -0.670274 },
  { isoDate: "2018-12-14T00:00:00Z", dsun: 209.593453, hgln: 0.241027, hglt: -0.797025 },
  { isoDate: "2018-12-15T00:00:00Z", dsun: 209.583737, hgln: 0.243635, hglt: -0.923501 },
  { isoDate: "2018-12-16T00:00:00Z", dsun: 209.574995, hgln: 0.246015, hglt: -1.049662 },
  { isoDate: "2018-12-17T00:00:00Z", dsun: 209.567215, hgln: 0.248164, hglt: -1.175468 },
  { isoDate: "2018-12-18T00:00:00Z", dsun: 209.56038, hgln: 0.250078, hglt: -1.300879 },
  { isoDate: "2018-12-19T00:00:00Z", dsun: 209.554477, hgln: 0.251751, hglt: -1.425855 },
  { isoDate: "2018-12-20T00:00:00Z", dsun: 209.549492, hgln: 0.25317, hglt: -1.550358 },
  { isoDate: "2018-12-21T00:00:00Z", dsun: 209.54541, hgln: 0.254323, hglt: -1.674348 },
  { isoDate: "2018-12-22T00:00:00Z", dsun: 209.542215, hgln: 0.255191, hglt: -1.797786 },
  { isoDate: "2018-12-23T00:00:00Z", dsun: 209.539894, hgln: 0.255751, hglt: -1.920634 },
  { isoDate: "2018-12-24T00:00:00Z", dsun: 209.538431, hgln: 0.255981, hglt: -2.042853 },
  { isoDate: "2018-12-25T00:00:00Z", dsun: 209.537813, hgln: 0.255856, hglt: -2.164406 },
  { isoDate: "2018-12-26T00:00:00Z", dsun: 209.538025, hgln: 0.255354, hglt: -2.285255 },
  { isoDate: "2018-12-27T00:00:00Z", dsun: 209.539055, hgln: 0.254458, hglt: -2.405363 },
  { isoDate: "2018-12-28T00:00:00Z", dsun: 209.54089, hgln: 0.253153, hglt: -2.524694 },
  { isoDate: "2018-12-29T00:00:00Z", dsun: 209.543518, hgln: 0.251435, hglt: -2.643209 },
  { isoDate: "2018-12-30T00:00:00Z", dsun: 209.546931, hgln: 0.2493, hglt: -2.760874 },
  { isoDate: "2018-12-31T00:00:00Z", dsun: 209.551119, hgln: 0.246753, hglt: -2.877653 },
  { isoDate: "2019-01-01T00:00:00Z", dsun: 209.556077, hgln: 0.2438, hglt: -2.99351 },
  { isoDate: "2019-01-02T00:00:00Z", dsun: 209.561799, hgln: 0.240452, hglt: -3.108411 },
  { isoDate: "2019-01-03T00:00:00Z", dsun: 209.568281, hgln: 0.236721, hglt: -3.22232 },
  { isoDate: "2019-01-04T00:00:00Z", dsun: 209.575517, hgln: 0.232622, hglt: -3.335204 },
  { isoDate: "2019-01-05T00:00:00Z", dsun: 209.583502, hgln: 0.228171, hglt: -3.44703 },
  { isoDate: "2019-01-06T00:00:00Z", dsun: 209.592232, hgln: 0.223383, hglt: -3.557764 },
  { isoDate: "2019-01-07T00:00:00Z", dsun: 209.601697, hgln: 0.218277, hglt: -3.667374 },
  { isoDate: "2019-01-08T00:00:00Z", dsun: 209.611898, hgln: 0.21287, hglt: -3.775827 },
  { isoDate: "2019-01-09T00:00:00Z", dsun: 209.62283, hgln: 0.207181, hglt: -3.883093 },
  { isoDate: "2019-01-10T00:00:00Z", dsun: 209.634491, hgln: 0.201225, hglt: -3.98914 },
  { isoDate: "2019-01-11T00:00:00Z", dsun: 209.646881, hgln: 0.195021, hglt: -4.093938 },
  { isoDate: "2019-01-12T00:00:00Z", dsun: 209.660002, hgln: 0.188583, hglt: -4.197457 },
  { isoDate: "2019-01-13T00:00:00Z", dsun: 209.673859, hgln: 0.181926, hglt: -4.299667 },
  { isoDate: "2019-01-14T00:00:00Z", dsun: 209.688457, hgln: 0.175063, hglt: -4.40054 },
  { isoDate: "2019-01-15T00:00:00Z", dsun: 209.703802, hgln: 0.168006, hglt: -4.500048 },
  { isoDate: "2019-01-16T00:00:00Z", dsun: 209.719902, hgln: 0.160764, hglt: -4.598161 },
  { isoDate: "2019-01-17T00:00:00Z", dsun: 209.736765, hgln: 0.153345, hglt: -4.694854 },
  { isoDate: "2019-01-18T00:00:00Z", dsun: 209.754399, hgln: 0.145752, hglt: -4.7901 },
  { isoDate: "2019-01-19T00:00:00Z", dsun: 209.772814, hgln: 0.137984, hglt: -4.883871 },
  { isoDate: "2019-01-20T00:00:00Z", dsun: 209.792018, hgln: 0.130037, hglt: -4.976143 },
  { isoDate: "2019-01-21T00:00:00Z", dsun: 209.812019, hgln: 0.121902, hglt: -5.066891 },
  { isoDate: "2019-01-22T00:00:00Z", dsun: 209.832824, hgln: 0.113567, hglt: -5.156089 },
  { isoDate: "2019-01-23T00:00:00Z", dsun: 209.85444, hgln: 0.105021, hglt: -5.243714 },
  { isoDate: "2019-01-24T00:00:00Z", dsun: 209.876872, hgln: 0.096255, hglt: -5.329741 },
  { isoDate: "2019-01-25T00:00:00Z", dsun: 209.900126, hgln: 0.087262, hglt: -5.414147 },
  { isoDate: "2019-01-26T00:00:00Z", dsun: 209.924204, hgln: 0.078046, hglt: -5.49691 },
  { isoDate: "2019-01-27T00:00:00Z", dsun: 209.949109, hgln: 0.068612, hglt: -5.578007 },
  { isoDate: "2019-01-28T00:00:00Z", dsun: 209.974844, hgln: 0.058974, hglt: -5.657415 },
  { isoDate: "2019-01-29T00:00:00Z", dsun: 210.001409, hgln: 0.04915, hglt: -5.735114 },
  { isoDate: "2019-01-30T00:00:00Z", dsun: 210.028803, hgln: 0.039162, hglt: -5.811081 },
  { isoDate: "2019-01-31T00:00:00Z", dsun: 210.057028, hgln: 0.029035, hglt: -5.885296 },
  { isoDate: "2019-02-01T00:00:00Z", dsun: 210.086081, hgln: 0.018796, hglt: -5.957739 },
  { isoDate: "2019-02-02T00:00:00Z", dsun: 210.115961, hgln: 0.008475, hglt: -6.028389 },
  { isoDate: "2019-02-03T00:00:00Z", dsun: 210.146665, hgln: -0.001898, hglt: -6.097226 },
  { isoDate: "2019-02-04T00:00:00Z", dsun: 210.178184, hgln: -0.012292, hglt: -6.164232 },
  { isoDate: "2019-02-05T00:00:00Z", dsun: 210.210516, hgln: -0.022678, hglt: -6.229386 },
  { isoDate: "2019-02-06T00:00:00Z", dsun: 210.243647, hgln: -0.033025, hglt: -6.292671 },
  { isoDate: "2019-02-07T00:00:00Z", dsun: 210.277559, hgln: -0.043303, hglt: -6.354068 },
  { isoDate: "2019-02-08T00:00:00Z", dsun: 210.312231, hgln: -0.053481, hglt: -6.413558 },
  { isoDate: "2019-02-09T00:00:00Z", dsun: 210.34764, hgln: -0.063532, hglt: -6.471122 },
  { isoDate: "2019-02-10T00:00:00Z", dsun: 210.383759, hgln: -0.073427, hglt: -6.526745 },
  { isoDate: "2019-02-11T00:00:00Z", dsun: 210.420562, hgln: -0.083139, hglt: -6.580406 },
  { isoDate: "2019-02-12T00:00:00Z", dsun: 210.458023, hgln: -0.092645, hglt: -6.632091 },
  { isoDate: "2019-02-13T00:00:00Z", dsun: 210.496115, hgln: -0.101921, hglt: -6.681783 },
  { isoDate: "2019-02-14T00:00:00Z", dsun: 210.534812, hgln: -0.110948, hglt: -6.729465 },
  { isoDate: "2019-02-15T00:00:00Z", dsun: 210.57409, hgln: -0.119712, hglt: -6.775122 },
  { isoDate: "2019-02-16T00:00:00Z", dsun: 210.613921, hgln: -0.128203, hglt: -6.818739 },
  { isoDate: "2019-02-17T00:00:00Z", dsun: 210.654282, hgln: -0.136416, hglt: -6.860303 },
  { isoDate: "2019-02-18T00:00:00Z", dsun: 210.695148, hgln: -0.144354, hglt: -6.899798 },
  { isoDate: "2019-02-19T00:00:00Z", dsun: 210.736493, hgln: -0.152024, hglt: -6.937213 },
  { isoDate: "2019-02-20T00:00:00Z", dsun: 210.778294, hgln: -0.159438, hglt: -6.972534 },
  { isoDate: "2019-02-21T00:00:00Z", dsun: 210.820525, hgln: -0.166612, hglt: -7.00575 },
  { isoDate: "2019-02-22T00:00:00Z", dsun: 210.863164, hgln: -0.173559, hglt: -7.036848 },
  { isoDate: "2019-02-23T00:00:00Z", dsun: 210.906185, hgln: -0.180291, hglt: -7.065819 },
  { isoDate: "2019-02-24T00:00:00Z", dsun: 210.949566, hgln: -0.186815, hglt: -7.092651 },
  { isoDate: "2019-02-25T00:00:00Z", dsun: 210.993283, hgln: -0.193133, hglt: -7.117336 },
  { isoDate: "2019-02-26T00:00:00Z", dsun: 211.037315, hgln: -0.199245, hglt: -7.139865 },
  { isoDate: "2019-02-27T00:00:00Z", dsun: 211.08164, hgln: -0.205144, hglt: -7.16023 },
  { isoDate: "2019-02-28T00:00:00Z", dsun: 211.126237, hgln: -0.210822, hglt: -7.178423 },
  { isoDate: "2019-03-01T00:00:00Z", dsun: 211.171089, hgln: -0.21627, hglt: -7.194438 },
  { isoDate: "2019-03-02T00:00:00Z", dsun: 211.216178, hgln: -0.221474, hglt: -7.208269 },
  { isoDate: "2019-03-03T00:00:00Z", dsun: 211.261488, hgln: -0.226422, hglt: -7.219912 },
  { isoDate: "2019-03-04T00:00:00Z", dsun: 211.307006, hgln: -0.231099, hglt: -7.229361 },
  { isoDate: "2019-03-05T00:00:00Z", dsun: 211.352719, hgln: -0.23549, hglt: -7.236613 },
  { isoDate: "2019-03-06T00:00:00Z", dsun: 211.398617, hgln: -0.23958, hglt: -7.241667 },
  { isoDate: "2019-03-07T00:00:00Z", dsun: 211.444693, hgln: -0.243351, hglt: -7.244519 },
  { isoDate: "2019-03-08T00:00:00Z", dsun: 211.490939, hgln: -0.246789, hglt: -7.245169 },
  { isoDate: "2019-03-09T00:00:00Z", dsun: 211.53735, hgln: -0.249878, hglt: -7.243616 },
  { isoDate: "2019-03-10T00:00:00Z", dsun: 211.583918, hgln: -0.252603, hglt: -7.23986 },
  { isoDate: "2019-03-11T00:00:00Z", dsun: 211.630638, hgln: -0.254951, hglt: -7.233904 },
  { isoDate: "2019-03-12T00:00:00Z", dsun: 211.677503, hgln: -0.256909, hglt: -7.225748 },
  { isoDate: "2019-03-13T00:00:00Z", dsun: 211.724507, hgln: -0.258467, hglt: -7.215395 },
  { isoDate: "2019-03-14T00:00:00Z", dsun: 211.771644, hgln: -0.259617, hglt: -7.202848 },
  { isoDate: "2019-03-15T00:00:00Z", dsun: 211.818908, hgln: -0.260353, hglt: -7.188113 },
  { isoDate: "2019-03-16T00:00:00Z", dsun: 211.866299, hgln: -0.260675, hglt: -7.171193 },
  { isoDate: "2019-03-17T00:00:00Z", dsun: 211.913812, hgln: -0.260584, hglt: -7.152095 },
  { isoDate: "2019-03-18T00:00:00Z", dsun: 211.961448, hgln: -0.26009, hglt: -7.130825 },
  { isoDate: "2019-03-19T00:00:00Z", dsun: 212.009207, hgln: -0.259208, hglt: -7.10739 },
  { isoDate: "2019-03-20T00:00:00Z", dsun: 212.057091, hgln: -0.257957, hglt: -7.081798 },
  { isoDate: "2019-03-21T00:00:00Z", dsun: 212.105101, hgln: -0.256361, hglt: -7.054058 },
  { isoDate: "2019-03-22T00:00:00Z", dsun: 212.153239, hgln: -0.254447, hglt: -7.02418 },
  { isoDate: "2019-03-23T00:00:00Z", dsun: 212.201508, hgln: -0.252239, hglt: -6.992173 },
  { isoDate: "2019-03-24T00:00:00Z", dsun: 212.249911, hgln: -0.249762, hglt: -6.958049 },
  { isoDate: "2019-03-25T00:00:00Z", dsun: 212.29845, hgln: -0.247034, hglt: -6.921819 },
  { isoDate: "2019-03-26T00:00:00Z", dsun: 212.347127, hgln: -0.244072, hglt: -6.883496 },
  { isoDate: "2019-03-27T00:00:00Z", dsun: 212.395945, hgln: -0.240885, hglt: -6.843092 },
  { isoDate: "2019-03-28T00:00:00Z", dsun: 212.444906, hgln: -0.237482, hglt: -6.800622 },
  { isoDate: "2019-03-29T00:00:00Z", dsun: 212.494012, hgln: -0.233869, hglt: -6.7561 },
  { isoDate: "2019-03-30T00:00:00Z", dsun: 212.543267, hgln: -0.230046, hglt: -6.709541 },
  { isoDate: "2019-03-31T00:00:00Z", dsun: 212.592672, hgln: -0.226016, hglt: -6.660961 },
  { isoDate: "2019-04-01T00:00:00Z", dsun: 212.642231, hgln: -0.221777, hglt: -6.610376 },
  { isoDate: "2019-04-02T00:00:00Z", dsun: 212.691947, hgln: -0.217326, hglt: -6.557804 },
  { isoDate: "2019-04-03T00:00:00Z", dsun: 212.741822, hgln: -0.21266, hglt: -6.503262 },
  { isoDate: "2019-04-04T00:00:00Z", dsun: 212.791863, hgln: -0.207774, hglt: -6.446769 },
  { isoDate: "2019-04-05T00:00:00Z", dsun: 212.842071, hgln: -0.202662, hglt: -6.388344 },
  { isoDate: "2019-04-06T00:00:00Z", dsun: 212.892453, hgln: -0.197316, hglt: -6.328006 },
  { isoDate: "2019-04-07T00:00:00Z", dsun: 212.94301, hgln: -0.191729, hglt: -6.265777 },
  { isoDate: "2019-04-08T00:00:00Z", dsun: 212.993747, hgln: -0.185893, hglt: -6.201676 },
  { isoDate: "2019-04-09T00:00:00Z", dsun: 213.044672, hgln: -0.1798, hglt: -6.135726 },
  { isoDate: "2019-04-10T00:00:00Z", dsun: 213.09577, hgln: -0.173447, hglt: -6.067948 },
  { isoDate: "2019-04-11T00:00:00Z", dsun: 213.147049, hgln: -0.166829, hglt: -5.998365 },
  { isoDate: "2019-04-12T00:00:00Z", dsun: 213.198506, hgln: -0.159944, hglt: -5.927001 },
  { isoDate: "2019-04-13T00:00:00Z", dsun: 213.250141, hgln: -0.152793, hglt: -5.853879 },
  { isoDate: "2019-04-14T00:00:00Z", dsun: 213.301952, hgln: -0.145382, hglt: -5.779024 },
  { isoDate: "2019-04-15T00:00:00Z", dsun: 213.353939, hgln: -0.137721, hglt: -5.70246 },
  { isoDate: "2019-04-16T00:00:00Z", dsun: 213.406099, hgln: -0.129826, hglt: -5.624213 },
  { isoDate: "2019-04-17T00:00:00Z", dsun: 213.458431, hgln: -0.121718, hglt: -5.544309 },
  { isoDate: "2019-04-18T00:00:00Z", dsun: 213.510933, hgln: -0.113424, hglt: -5.462774 },
  { isoDate: "2019-04-19T00:00:00Z", dsun: 213.563601, hgln: -0.104973, hglt: -5.379635 },
  { isoDate: "2019-04-20T00:00:00Z", dsun: 213.616431, hgln: -0.096396, hglt: -5.29492 },
  { isoDate: "2019-04-21T00:00:00Z", dsun: 213.669419, hgln: -0.087724, hglt: -5.208656 },
  { isoDate: "2019-04-22T00:00:00Z", dsun: 213.722558, hgln: -0.078985, hglt: -5.120871 },
  { isoDate: "2019-04-23T00:00:00Z", dsun: 213.77584, hgln: -0.070206, hglt: -5.031595 },
  { isoDate: "2019-04-24T00:00:00Z", dsun: 213.829259, hgln: -0.06141, hglt: -4.940856 },
  { isoDate: "2019-04-25T00:00:00Z", dsun: 213.882803, hgln: -0.052614, hglt: -4.848684 },
  { isoDate: "2019-04-26T00:00:00Z", dsun: 213.936465, hgln: -0.043835, hglt: -4.755109 },
  { isoDate: "2019-04-27T00:00:00Z", dsun: 213.990231, hgln: -0.035086, hglt: -4.660161 },
  { isoDate: "2019-04-28T00:00:00Z", dsun: 214.044091, hgln: -0.026376, hglt: -4.563871 },
  { isoDate: "2019-04-29T00:00:00Z", dsun: 214.098032, hgln: -0.017714, hglt: -4.46627 },
  { isoDate: "2019-04-30T00:00:00Z", dsun: 214.152043, hgln: -0.009106, hglt: -4.367389 },
  { isoDate: "2019-05-01T00:00:00Z", dsun: 214.206109, hgln: -0.000556, hglt: -4.267259 },
  { isoDate: "2019-05-02T00:00:00Z", dsun: 214.260217, hgln: 0.007934, hglt: -4.165914 },
  { isoDate: "2019-05-03T00:00:00Z", dsun: 214.314353, hgln: 0.016365, hglt: -4.063384 },
  { isoDate: "2019-05-04T00:00:00Z", dsun: 214.368503, hgln: 0.024737, hglt: -3.959702 },
  { isoDate: "2019-05-05T00:00:00Z", dsun: 214.422649, hgln: 0.033056, hglt: -3.854902 },
  { isoDate: "2019-05-06T00:00:00Z", dsun: 214.476774, hgln: 0.041328, hglt: -3.749015 },
  { isoDate: "2019-05-07T00:00:00Z", dsun: 214.530859, hgln: 0.049557, hglt: -3.642076 },
  { isoDate: "2019-05-08T00:00:00Z", dsun: 214.58488, hgln: 0.057752, hglt: -3.534118 },
  { isoDate: "2019-05-09T00:00:00Z", dsun: 214.638812, hgln: 0.06592, hglt: -3.425175 },
  { isoDate: "2019-05-10T00:00:00Z", dsun: 214.692638, hgln: 0.074065, hglt: -3.31528 },
  { isoDate: "2019-05-11T00:00:00Z", dsun: 214.746336, hgln: 0.08219, hglt: -3.204468 },
  { isoDate: "2019-05-12T00:00:00Z", dsun: 214.799881, hgln: 0.090294, hglt: -3.092773 },
  { isoDate: "2019-05-13T00:00:00Z", dsun: 214.853253, hgln: 0.098372, hglt: -2.980228 },
  { isoDate: "2019-05-14T00:00:00Z", dsun: 214.906426, hgln: 0.106412, hglt: -2.866869 },
  { isoDate: "2019-05-15T00:00:00Z", dsun: 214.959377, hgln: 0.1144, hglt: -2.75273 },
  { isoDate: "2019-05-16T00:00:00Z", dsun: 215.012082, hgln: 0.122315, hglt: -2.637846 },
  { isoDate: "2019-05-17T00:00:00Z", dsun: 215.064515, hgln: 0.130135, hglt: -2.522251 },
  { isoDate: "2019-05-18T00:00:00Z", dsun: 215.11665, hgln: 0.137834, hglt: -2.40598 },
  { isoDate: "2019-05-19T00:00:00Z", dsun: 215.16846, hgln: 0.145384, hglt: -2.289068 },
  { isoDate: "2019-05-20T00:00:00Z", dsun: 215.219917, hgln: 0.152759, hglt: -2.171551 },
  { isoDate: "2019-05-21T00:00:00Z", dsun: 215.270995, hgln: 0.159935, hglt: -2.053462 },
  { isoDate: "2019-05-22T00:00:00Z", dsun: 215.321663, hgln: 0.166889, hglt: -1.934838 },
  { isoDate: "2019-05-23T00:00:00Z", dsun: 215.371892, hgln: 0.1736, hglt: -1.815712 },
  { isoDate: "2019-05-24T00:00:00Z", dsun: 215.421654, hgln: 0.180054, hglt: -1.696121 },
  { isoDate: "2019-05-25T00:00:00Z", dsun: 215.470918, hgln: 0.186236, hglt: -1.5761 },
  { isoDate: "2019-05-26T00:00:00Z", dsun: 215.519655, hgln: 0.192134, hglt: -1.455682 },
  { isoDate: "2019-05-27T00:00:00Z", dsun: 215.567836, hgln: 0.197742, hglt: -1.334904 },
  { isoDate: "2019-05-28T00:00:00Z", dsun: 215.615432, hgln: 0.203053, hglt: -1.213799 },
  { isoDate: "2019-05-29T00:00:00Z", dsun: 215.662414, hgln: 0.208064, hglt: -1.092404 },
  { isoDate: "2019-05-30T00:00:00Z", dsun: 215.708755, hgln: 0.212772, hglt: -0.970752 },
  { isoDate: "2019-05-31T00:00:00Z", dsun: 215.754425, hgln: 0.21718, hglt: -0.848879 },
  { isoDate: "2019-06-01T00:00:00Z", dsun: 215.799397, hgln: 0.22129, hglt: -0.726819 },
  { isoDate: "2019-06-02T00:00:00Z", dsun: 215.843642, hgln: 0.225111, hglt: -0.604608 },
  { isoDate: "2019-06-03T00:00:00Z", dsun: 215.887131, hgln: 0.228653, hglt: -0.482278 },
  { isoDate: "2019-06-04T00:00:00Z", dsun: 215.929833, hgln: 0.231927, hglt: -0.359866 },
  { isoDate: "2019-06-05T00:00:00Z", dsun: 215.971719, hgln: 0.23495, hglt: -0.237405 },
  { isoDate: "2019-06-06T00:00:00Z", dsun: 216.012758, hgln: 0.237736, hglt: -0.11493 },
  { isoDate: "2019-06-07T00:00:00Z", dsun: 216.052922, hgln: 0.240303, hglt: 0.007526 },
  { isoDate: "2019-06-08T00:00:00Z", dsun: 216.092183, hgln: 0.242662, hglt: 0.129928 },
  { isoDate: "2019-06-09T00:00:00Z", dsun: 216.130512, hgln: 0.244824, hglt: 0.252242 },
  { isoDate: "2019-06-10T00:00:00Z", dsun: 216.167885, hgln: 0.246795, hglt: 0.374435 },
  { isoDate: "2019-06-11T00:00:00Z", dsun: 216.204282, hgln: 0.248574, hglt: 0.496474 },
  { isoDate: "2019-06-12T00:00:00Z", dsun: 216.239666, hgln: 0.25016, hglt: 0.618324 },
  { isoDate: "2019-06-13T00:00:00Z", dsun: 216.274018, hgln: 0.251542, hglt: 0.739952 },
  { isoDate: "2019-06-14T00:00:00Z", dsun: 216.307316, hgln: 0.252709, hglt: 0.861326 },
  { isoDate: "2019-06-15T00:00:00Z", dsun: 216.339535, hgln: 0.253645, hglt: 0.982411 },
  { isoDate: "2019-06-16T00:00:00Z", dsun: 216.370653, hgln: 0.254334, hglt: 1.103176 },
  { isoDate: "2019-06-17T00:00:00Z", dsun: 216.400648, hgln: 0.254758, hglt: 1.223587 },
  { isoDate: "2019-06-18T00:00:00Z", dsun: 216.429497, hgln: 0.2549, hglt: 1.343612 },
  { isoDate: "2019-06-19T00:00:00Z", dsun: 216.45718, hgln: 0.254741, hglt: 1.463219 },
  { isoDate: "2019-06-20T00:00:00Z", dsun: 216.483676, hgln: 0.254268, hglt: 1.582375 },
  { isoDate: "2019-06-21T00:00:00Z", dsun: 216.508966, hgln: 0.253468, hglt: 1.701048 },
  { isoDate: "2019-06-22T00:00:00Z", dsun: 216.533032, hgln: 0.252329, hglt: 1.819207 },
  { isoDate: "2019-06-23T00:00:00Z", dsun: 216.555856, hgln: 0.250844, hglt: 1.93682 },
  { isoDate: "2019-06-24T00:00:00Z", dsun: 216.577423, hgln: 0.249007, hglt: 2.053855 },
  { isoDate: "2019-06-25T00:00:00Z", dsun: 216.597719, hgln: 0.246812, hglt: 2.17028 },
  { isoDate: "2019-06-26T00:00:00Z", dsun: 216.616732, hgln: 0.24426, hglt: 2.286066 },
  { isoDate: "2019-06-27T00:00:00Z", dsun: 216.634451, hgln: 0.24135, hglt: 2.401181 },
  { isoDate: "2019-06-28T00:00:00Z", dsun: 216.650867, hgln: 0.238085, hglt: 2.515594 },
  { isoDate: "2019-06-29T00:00:00Z", dsun: 216.665973, hgln: 0.234472, hglt: 2.629275 },
  { isoDate: "2019-06-30T00:00:00Z", dsun: 216.679763, hgln: 0.230519, hglt: 2.742193 },
  { isoDate: "2019-07-01T00:00:00Z", dsun: 216.692231, hgln: 0.226239, hglt: 2.854317 },
  { isoDate: "2019-07-02T00:00:00Z", dsun: 216.703372, hgln: 0.221649, hglt: 2.965619 },
  { isoDate: "2019-07-03T00:00:00Z", dsun: 216.71318, hgln: 0.21677, hglt: 3.076068 },
  { isoDate: "2019-07-04T00:00:00Z", dsun: 216.721652, hgln: 0.211625, hglt: 3.185634 },
  { isoDate: "2019-07-05T00:00:00Z", dsun: 216.728782, hgln: 0.20624, hglt: 3.294289 },
  { isoDate: "2019-07-06T00:00:00Z", dsun: 216.734571, hgln: 0.200637, hglt: 3.402004 },
  { isoDate: "2019-07-07T00:00:00Z", dsun: 216.739019, hgln: 0.19484, hglt: 3.508749 },
  { isoDate: "2019-07-08T00:00:00Z", dsun: 216.742129, hgln: 0.188867, hglt: 3.614497 },
  { isoDate: "2019-07-09T00:00:00Z", dsun: 216.743904, hgln: 0.18273, hglt: 3.719218 },
  { isoDate: "2019-07-10T00:00:00Z", dsun: 216.744355, hgln: 0.176438, hglt: 3.822886 },
  { isoDate: "2019-07-11T00:00:00Z", dsun: 216.743489, hgln: 0.169995, hglt: 3.925471 },
  { isoDate: "2019-07-12T00:00:00Z", dsun: 216.741316, hgln: 0.163401, hglt: 4.026947 },
  { isoDate: "2019-07-13T00:00:00Z", dsun: 216.737846, hgln: 0.156653, hglt: 4.127286 },
  { isoDate: "2019-07-14T00:00:00Z", dsun: 216.733092, hgln: 0.149745, hglt: 4.22646 },
  { isoDate: "2019-07-15T00:00:00Z", dsun: 216.727066, hgln: 0.142671, hglt: 4.324444 },
  { isoDate: "2019-07-16T00:00:00Z", dsun: 216.71978, hgln: 0.135424, hglt: 4.42121 },
  { isoDate: "2019-07-17T00:00:00Z", dsun: 216.711249, hgln: 0.127996, hglt: 4.516733 },
  { isoDate: "2019-07-18T00:00:00Z", dsun: 216.701487, hgln: 0.120383, hglt: 4.610987 },
  { isoDate: "2019-07-19T00:00:00Z", dsun: 216.690506, hgln: 0.112578, hglt: 4.703945 },
  { isoDate: "2019-07-20T00:00:00Z", dsun: 216.678321, hgln: 0.104578, hglt: 4.795584 },
  { isoDate: "2019-07-21T00:00:00Z", dsun: 216.664946, hgln: 0.096383, hglt: 4.885878 },
  { isoDate: "2019-07-22T00:00:00Z", dsun: 216.650396, hgln: 0.087992, hglt: 4.974803 },
  { isoDate: "2019-07-23T00:00:00Z", dsun: 216.634683, hgln: 0.079408, hglt: 5.062335 },
  { isoDate: "2019-07-24T00:00:00Z", dsun: 216.617822, hgln: 0.070636, hglt: 5.14845 },
  { isoDate: "2019-07-25T00:00:00Z", dsun: 216.599826, hgln: 0.061684, hglt: 5.233126 },
  { isoDate: "2019-07-26T00:00:00Z", dsun: 216.58071, hgln: 0.052562, hglt: 5.316338 },
  { isoDate: "2019-07-27T00:00:00Z", dsun: 216.560487, hgln: 0.043281, hglt: 5.398065 },
  { isoDate: "2019-07-28T00:00:00Z", dsun: 216.53917, hgln: 0.033858, hglt: 5.478284 },
  { isoDate: "2019-07-29T00:00:00Z", dsun: 216.516775, hgln: 0.024314, hglt: 5.556973 },
  { isoDate: "2019-07-30T00:00:00Z", dsun: 216.493315, hgln: 0.014671, hglt: 5.634111 },
  { isoDate: "2019-07-31T00:00:00Z", dsun: 216.468804, hgln: 0.00496, hglt: 5.709678 },
  { isoDate: "2019-08-01T00:00:00Z", dsun: 216.443254, hgln: -0.004789, hglt: 5.783652 },
  { isoDate: "2019-08-02T00:00:00Z", dsun: 216.416673, hgln: -0.014539, hglt: 5.856013 },
  { isoDate: "2019-08-03T00:00:00Z", dsun: 216.389066, hgln: -0.024255, hglt: 5.926741 },
  { isoDate: "2019-08-04T00:00:00Z", dsun: 216.360436, hgln: -0.0339, hglt: 5.995818 },
  { isoDate: "2019-08-05T00:00:00Z", dsun: 216.330781, hgln: -0.043443, hglt: 6.063225 },
  { isoDate: "2019-08-06T00:00:00Z", dsun: 216.300098, hgln: -0.052855, hglt: 6.128942 },
  { isoDate: "2019-08-07T00:00:00Z", dsun: 216.268391, hgln: -0.062116, hglt: 6.192953 },
  { isoDate: "2019-08-08T00:00:00Z", dsun: 216.235659, hgln: -0.071207, hglt: 6.25524 },
  { isoDate: "2019-08-09T00:00:00Z", dsun: 216.201902, hgln: -0.080117, hglt: 6.315785 },
  { isoDate: "2019-08-10T00:00:00Z", dsun: 216.167117, hgln: -0.088837, hglt: 6.374572 },
  { isoDate: "2019-08-11T00:00:00Z", dsun: 216.131304, hgln: -0.097363, hglt: 6.431583 },
  { isoDate: "2019-08-12T00:00:00Z", dsun: 216.094461, hgln: -0.105692, hglt: 6.486804 },
  { isoDate: "2019-08-13T00:00:00Z", dsun: 216.056588, hgln: -0.113826, hglt: 6.540217 },
  { isoDate: "2019-08-14T00:00:00Z", dsun: 216.017685, hgln: -0.121766, hglt: 6.591808 },
  { isoDate: "2019-08-15T00:00:00Z", dsun: 215.977751, hgln: -0.129515, hglt: 6.641562 },
  { isoDate: "2019-08-16T00:00:00Z", dsun: 215.936785, hgln: -0.137076, hglt: 6.689464 },
  { isoDate: "2019-08-17T00:00:00Z", dsun: 215.894787, hgln: -0.144455, hglt: 6.735499 },
  { isoDate: "2019-08-18T00:00:00Z", dsun: 215.851756, hgln: -0.151655, hglt: 6.779654 },
  { isoDate: "2019-08-19T00:00:00Z", dsun: 215.807692, hgln: -0.15868, hglt: 6.821915 },
  { isoDate: "2019-08-20T00:00:00Z", dsun: 215.762596, hgln: -0.165535, hglt: 6.862269 },
  { isoDate: "2019-08-21T00:00:00Z", dsun: 215.716467, hgln: -0.172222, hglt: 6.900704 },
  { isoDate: "2019-08-22T00:00:00Z", dsun: 215.669309, hgln: -0.178743, hglt: 6.937206 },
  { isoDate: "2019-08-23T00:00:00Z", dsun: 215.621123, hgln: -0.185099, hglt: 6.971765 },
  { isoDate: "2019-08-24T00:00:00Z", dsun: 215.571913, hgln: -0.191289, hglt: 7.004367 },
  { isoDate: "2019-08-25T00:00:00Z", dsun: 215.521683, hgln: -0.19731, hglt: 7.035003 },
  { isoDate: "2019-08-26T00:00:00Z", dsun: 215.470441, hgln: -0.203155, hglt: 7.063661 },
  { isoDate: "2019-08-27T00:00:00Z", dsun: 215.418193, hgln: -0.208815, hglt: 7.09033 },
  { isoDate: "2019-08-28T00:00:00Z", dsun: 215.364951, hgln: -0.214276, hglt: 7.115001 },
  { isoDate: "2019-08-29T00:00:00Z", dsun: 215.310726, hgln: -0.219518, hglt: 7.137664 },
  { isoDate: "2019-08-30T00:00:00Z", dsun: 215.255533, hgln: -0.224517, hglt: 7.158309 },
  { isoDate: "2019-08-31T00:00:00Z", dsun: 215.199389, hgln: -0.229248, hglt: 7.176929 },
  { isoDate: "2019-09-01T00:00:00Z", dsun: 215.142311, hgln: -0.233682, hglt: 7.193515 },
  { isoDate: "2019-09-02T00:00:00Z", dsun: 215.084318, hgln: -0.237794, hglt: 7.208058 },
  { isoDate: "2019-09-03T00:00:00Z", dsun: 215.02543, hgln: -0.241564, hglt: 7.220552 },
  { isoDate: "2019-09-04T00:00:00Z", dsun: 214.965665, hgln: -0.244973, hglt: 7.23099 },
  { isoDate: "2019-09-05T00:00:00Z", dsun: 214.905041, hgln: -0.248013, hglt: 7.239364 },
  { isoDate: "2019-09-06T00:00:00Z", dsun: 214.843581, hgln: -0.250676, hglt: 7.24567 },
  { isoDate: "2019-09-07T00:00:00Z", dsun: 214.781305, hgln: -0.252961, hglt: 7.249901 },
  { isoDate: "2019-09-08T00:00:00Z", dsun: 214.718237, hgln: -0.254871, hglt: 7.252053 },
  { isoDate: "2019-09-09T00:00:00Z", dsun: 214.654403, hgln: -0.25641, hglt: 7.252122 },
  { isoDate: "2019-09-10T00:00:00Z", dsun: 214.589836, hgln: -0.257586, hglt: 7.250099 },
  { isoDate: "2019-09-11T00:00:00Z", dsun: 214.524553, hgln: -0.258407, hglt: 7.245988 },
  { isoDate: "2019-09-12T00:00:00Z", dsun: 214.458591, hgln: -0.258884, hglt: 7.239785 },
  { isoDate: "2019-09-13T00:00:00Z", dsun: 214.391979, hgln: -0.259029, hglt: 7.231485 },
  { isoDate: "2019-09-14T00:00:00Z", dsun: 214.324751, hgln: -0.258856, hglt: 7.221088 },
  { isoDate: "2019-09-15T00:00:00Z", dsun: 214.256938, hgln: -0.258378, hglt: 7.208592 },
  { isoDate: "2019-09-16T00:00:00Z", dsun: 214.188575, hgln: -0.25761, hglt: 7.193997 },
  { isoDate: "2019-09-17T00:00:00Z", dsun: 214.119695, hgln: -0.256566, hglt: 7.177303 },
  { isoDate: "2019-09-18T00:00:00Z", dsun: 214.050333, hgln: -0.255261, hglt: 7.158511 },
  { isoDate: "2019-09-19T00:00:00Z", dsun: 213.980524, hgln: -0.253708, hglt: 7.137622 },
  { isoDate: "2019-09-20T00:00:00Z", dsun: 213.910304, hgln: -0.25192, hglt: 7.114638 },
  { isoDate: "2019-09-21T00:00:00Z", dsun: 213.839707, hgln: -0.24991, hglt: 7.089562 },
  { isoDate: "2019-09-22T00:00:00Z", dsun: 213.768771, hgln: -0.247686, hglt: 7.062396 },
  { isoDate: "2019-09-23T00:00:00Z", dsun: 213.697531, hgln: -0.245257, hglt: 7.033145 },
  { isoDate: "2019-09-24T00:00:00Z", dsun: 213.626024, hgln: -0.242627, hglt: 7.001814 },
  { isoDate: "2019-09-25T00:00:00Z", dsun: 213.554288, hgln: -0.239794, hglt: 6.968406 },
  { isoDate: "2019-09-26T00:00:00Z", dsun: 213.482362, hgln: -0.236755, hglt: 6.932929 },
  { isoDate: "2019-09-27T00:00:00Z", dsun: 213.410282, hgln: -0.2335, hglt: 6.895388 },
  { isoDate: "2019-09-28T00:00:00Z", dsun: 213.338091, hgln: -0.230012, hglt: 6.855792 },
  { isoDate: "2019-09-29T00:00:00Z", dsun: 213.265827, hgln: -0.226275, hglt: 6.814147 },
  { isoDate: "2019-09-30T00:00:00Z", dsun: 213.193532, hgln: -0.222269, hglt: 6.770463 },
  { isoDate: "2019-10-01T00:00:00Z", dsun: 213.121246, hgln: -0.217976, hglt: 6.724749 },
  { isoDate: "2019-10-02T00:00:00Z", dsun: 213.049008, hgln: -0.213382, hglt: 6.677014 },
  { isoDate: "2019-10-03T00:00:00Z", dsun: 212.976857, hgln: -0.208479, hglt: 6.62727 },
  { isoDate: "2019-10-04T00:00:00Z", dsun: 212.90483, hgln: -0.203262, hglt: 6.575528 },
  { isoDate: "2019-10-05T00:00:00Z", dsun: 212.832965, hgln: -0.197732, hglt: 6.521799 },
  { isoDate: "2019-10-06T00:00:00Z", dsun: 212.761299, hgln: -0.191892, hglt: 6.466096 },
  { isoDate: "2019-10-07T00:00:00Z", dsun: 212.689871, hgln: -0.185752, hglt: 6.408434 },
  { isoDate: "2019-10-08T00:00:00Z", dsun: 212.618712, hgln: -0.179319, hglt: 6.348825 },
  { isoDate: "2019-10-09T00:00:00Z", dsun: 212.547871, hgln: -0.172611, hglt: 6.287286 },
  { isoDate: "2019-10-10T00:00:00Z", dsun: 212.477381, hgln: -0.165642, hglt: 6.223831 },
  { isoDate: "2019-10-11T00:00:00Z", dsun: 212.407279, hgln: -0.158428, hglt: 6.158477 },
  { isoDate: "2019-10-12T00:00:00Z", dsun: 212.337603, hgln: -0.150988, hglt: 6.091242 },
  { isoDate: "2019-10-13T00:00:00Z", dsun: 212.268387, hgln: -0.143342, hglt: 6.022142 },
  { isoDate: "2019-10-14T00:00:00Z", dsun: 212.199669, hgln: -0.135512, hglt: 5.951197 },
  { isoDate: "2019-10-15T00:00:00Z", dsun: 212.131482, hgln: -0.127521, hglt: 5.878425 },
  { isoDate: "2019-10-16T00:00:00Z", dsun: 212.063862, hgln: -0.119392, hglt: 5.803846 },
  { isoDate: "2019-10-17T00:00:00Z", dsun: 211.996839, hgln: -0.111148, hglt: 5.727481 },
  { isoDate: "2019-10-18T00:00:00Z", dsun: 211.930447, hgln: -0.102814, hglt: 5.649352 },
  { isoDate: "2019-10-19T00:00:00Z", dsun: 211.864715, hgln: -0.094412, hglt: 5.56948 },
  { isoDate: "2019-10-20T00:00:00Z", dsun: 211.799673, hgln: -0.085963, hglt: 5.487887 },
  { isoDate: "2019-10-21T00:00:00Z", dsun: 211.73535, hgln: -0.077486, hglt: 5.404597 },
  { isoDate: "2019-10-22T00:00:00Z", dsun: 211.671773, hgln: -0.068998, hglt: 5.319635 },
  { isoDate: "2019-10-23T00:00:00Z", dsun: 211.608969, hgln: -0.060511, hglt: 5.233024 },
  { isoDate: "2019-10-24T00:00:00Z", dsun: 211.546963, hgln: -0.052033, hglt: 5.144789 },
  { isoDate: "2019-10-25T00:00:00Z", dsun: 211.485783, hgln: -0.043567, hglt: 5.054958 },
  { isoDate: "2019-10-26T00:00:00Z", dsun: 211.425451, hgln: -0.035111, hglt: 4.963555 },
  { isoDate: "2019-10-27T00:00:00Z", dsun: 211.365994, hgln: -0.026657, hglt: 4.87061 },
  { isoDate: "2019-10-28T00:00:00Z", dsun: 211.307433, hgln: -0.018195, hglt: 4.776148 },
  { isoDate: "2019-10-29T00:00:00Z", dsun: 211.249789, hgln: -0.009714, hglt: 4.6802 },
  { isoDate: "2019-10-30T00:00:00Z", dsun: 211.193083, hgln: -0.001204, hglt: 4.582794 },
  { isoDate: "2019-10-31T00:00:00Z", dsun: 211.137331, hgln: 0.007344, hglt: 4.483959 },
  { isoDate: "2019-11-01T00:00:00Z", dsun: 211.082549, hgln: 0.015935, hglt: 4.383726 },
  { isoDate: "2019-11-02T00:00:00Z", dsun: 211.02875, hgln: 0.024568, hglt: 4.282125 },
  { isoDate: "2019-11-03T00:00:00Z", dsun: 210.975949, hgln: 0.033241, hglt: 4.179187 },
  { isoDate: "2019-11-04T00:00:00Z", dsun: 210.924158, hgln: 0.041947, hglt: 4.074944 },
  { isoDate: "2019-11-05T00:00:00Z", dsun: 210.873389, hgln: 0.050676, hglt: 3.969428 },
  { isoDate: "2019-11-06T00:00:00Z", dsun: 210.823652, hgln: 0.059417, hglt: 3.862673 },
  { isoDate: "2019-11-07T00:00:00Z", dsun: 210.774958, hgln: 0.068154, hglt: 3.754711 },
  { isoDate: "2019-11-08T00:00:00Z", dsun: 210.727315, hgln: 0.076872, hglt: 3.645576 },
  { isoDate: "2019-11-09T00:00:00Z", dsun: 210.680733, hgln: 0.085554, hglt: 3.535302 },
  { isoDate: "2019-11-10T00:00:00Z", dsun: 210.635217, hgln: 0.094179, hglt: 3.423924 },
  { isoDate: "2019-11-11T00:00:00Z", dsun: 210.590773, hgln: 0.102726, hglt: 3.311478 },
  { isoDate: "2019-11-12T00:00:00Z", dsun: 210.547404, hgln: 0.111172, hglt: 3.197998 },
  { isoDate: "2019-11-13T00:00:00Z", dsun: 210.505113, hgln: 0.119493, hglt: 3.08352 },
  { isoDate: "2019-11-14T00:00:00Z", dsun: 210.463901, hgln: 0.127663, hglt: 2.96808 },
  { isoDate: "2019-11-15T00:00:00Z", dsun: 210.423767, hgln: 0.135657, hglt: 2.851716 },
  { isoDate: "2019-11-16T00:00:00Z", dsun: 210.38471, hgln: 0.143448, hglt: 2.734463 },
  { isoDate: "2019-11-17T00:00:00Z", dsun: 210.346728, hgln: 0.151013, hglt: 2.61636 },
  { isoDate: "2019-11-18T00:00:00Z", dsun: 210.309817, hgln: 0.158329, hglt: 2.497443 },
  { isoDate: "2019-11-19T00:00:00Z", dsun: 210.273972, hgln: 0.165377, hglt: 2.377751 },
  { isoDate: "2019-11-20T00:00:00Z", dsun: 210.239191, hgln: 0.172141, hglt: 2.257321 },
  { isoDate: "2019-11-21T00:00:00Z", dsun: 210.205466, hgln: 0.178611, hglt: 2.136192 },
  { isoDate: "2019-11-22T00:00:00Z", dsun: 210.172794, hgln: 0.184779, hglt: 2.014402 },
  { isoDate: "2019-11-23T00:00:00Z", dsun: 210.141169, hgln: 0.190646, hglt: 1.89199 },
  { isoDate: "2019-11-24T00:00:00Z", dsun: 210.110585, hgln: 0.196215, hglt: 1.768996 },
  { isoDate: "2019-11-25T00:00:00Z", dsun: 210.081034, hgln: 0.201495, hglt: 1.645458 },
  { isoDate: "2019-11-26T00:00:00Z", dsun: 210.052509, hgln: 0.206498, hglt: 1.521416 },
  { isoDate: "2019-11-27T00:00:00Z", dsun: 210.025, hgln: 0.211237, hglt: 1.396909 },
  { isoDate: "2019-11-28T00:00:00Z", dsun: 209.998495, hgln: 0.215726, hglt: 1.271978 },
  { isoDate: "2019-11-29T00:00:00Z", dsun: 209.972982, hgln: 0.219977, hglt: 1.146661 },
  { isoDate: "2019-11-30T00:00:00Z", dsun: 209.94845, hgln: 0.224, hglt: 1.020999 },
  { isoDate: "2019-12-01T00:00:00Z", dsun: 209.924886, hgln: 0.227802, hglt: 0.89503 },
  { isoDate: "2019-12-02T00:00:00Z", dsun: 209.902279, hgln: 0.231389, hglt: 0.768797 },
  { isoDate: "2019-12-03T00:00:00Z", dsun: 209.880617, hgln: 0.23476, hglt: 0.642337 },
  { isoDate: "2019-12-04T00:00:00Z", dsun: 209.85989, hgln: 0.237916, hglt: 0.515692 },
  { isoDate: "2019-12-05T00:00:00Z", dsun: 209.840086, hgln: 0.240853, hglt: 0.388901 },
  { isoDate: "2019-12-06T00:00:00Z", dsun: 209.821194, hgln: 0.243566, hglt: 0.262005 },
  { isoDate: "2019-12-07T00:00:00Z", dsun: 209.803202, hgln: 0.246049, hglt: 0.135043 },
  { isoDate: "2019-12-08T00:00:00Z", dsun: 209.786099, hgln: 0.248291, hglt: 0.008056 },
  { isoDate: "2019-12-09T00:00:00Z", dsun: 209.769874, hgln: 0.250284, hglt: -0.118917 },
  { isoDate: "2019-12-10T00:00:00Z", dsun: 209.754519, hgln: 0.252014, hglt: -0.245834 },
  { isoDate: "2019-12-11T00:00:00Z", dsun: 209.740013, hgln: 0.253465, hglt: -0.372658 },
  { isoDate: "2019-12-12T00:00:00Z", dsun: 209.726349, hgln: 0.254619, hglt: -0.499347 },
  { isoDate: "2019-12-13T00:00:00Z", dsun: 209.713515, hgln: 0.255459, hglt: -0.625863 },
  { isoDate: "2019-12-14T00:00:00Z", dsun: 209.701498, hgln: 0.255964, hglt: -0.752165 },
  { isoDate: "2019-12-15T00:00:00Z", dsun: 209.690287, hgln: 0.256114, hglt: -0.878215 },
  { isoDate: "2019-12-16T00:00:00Z", dsun: 209.679872, hgln: 0.255889, hglt: -1.003974 },
  { isoDate: "2019-12-17T00:00:00Z", dsun: 209.670241, hgln: 0.255274, hglt: -1.129403 },
  { isoDate: "2019-12-18T00:00:00Z", dsun: 209.661385, hgln: 0.254255, hglt: -1.254462 },
  { isoDate: "2019-12-19T00:00:00Z", dsun: 209.653295, hgln: 0.252824, hglt: -1.379114 },
  { isoDate: "2019-12-20T00:00:00Z", dsun: 209.645966, hgln: 0.250978, hglt: -1.50332 },
  { isoDate: "2019-12-21T00:00:00Z", dsun: 209.639391, hgln: 0.248718, hglt: -1.627042 },
  { isoDate: "2019-12-22T00:00:00Z", dsun: 209.633565, hgln: 0.246051, hglt: -1.750242 },
  { isoDate: "2019-12-23T00:00:00Z", dsun: 209.628487, hgln: 0.242988, hglt: -1.872883 },
  { isoDate: "2019-12-24T00:00:00Z", dsun: 209.624152, hgln: 0.239543, hglt: -1.994927 },
  { isoDate: "2019-12-25T00:00:00Z", dsun: 209.620557, hgln: 0.235735, hglt: -2.116338 },
  { isoDate: "2019-12-26T00:00:00Z", dsun: 209.6177, hgln: 0.231583, hglt: -2.237079 },
  { isoDate: "2019-12-27T00:00:00Z", dsun: 209.615575, hgln: 0.227107, hglt: -2.357114 },
  { isoDate: "2019-12-28T00:00:00Z", dsun: 209.61418, hgln: 0.222326, hglt: -2.476407 },
  { isoDate: "2019-12-29T00:00:00Z", dsun: 209.613512, hgln: 0.21726, hglt: -2.594922 },
  { isoDate: "2019-12-30T00:00:00Z", dsun: 209.61357, hgln: 0.211925, hglt: -2.712624 },
  { isoDate: "2019-12-31T00:00:00Z", dsun: 209.614358, hgln: 0.206338, hglt: -2.82948 },
  { isoDate: "2020-01-01T00:00:00Z", dsun: 209.615878, hgln: 0.200511, hglt: -2.945453 },
  { isoDate: "2020-01-02T00:00:00Z", dsun: 209.618135, hgln: 0.194455, hglt: -3.060511 },
  { isoDate: "2020-01-03T00:00:00Z", dsun: 209.621136, hgln: 0.18818, hglt: -3.174619 },
  { isoDate: "2020-01-04T00:00:00Z", dsun: 209.62489, hgln: 0.181695, hglt: -3.287745 },
  { isoDate: "2020-01-05T00:00:00Z", dsun: 209.629405, hgln: 0.175006, hglt: -3.399856 },
  { isoDate: "2020-01-06T00:00:00Z", dsun: 209.634691, hgln: 0.168117, hglt: -3.51092 },
  { isoDate: "2020-01-07T00:00:00Z", dsun: 209.640759, hgln: 0.161032, hglt: -3.620904 },
  { isoDate: "2020-01-08T00:00:00Z", dsun: 209.647619, hgln: 0.153749, hglt: -3.729778 },
  { isoDate: "2020-01-09T00:00:00Z", dsun: 209.655283, hgln: 0.146268, hglt: -3.837511 },
  { isoDate: "2020-01-10T00:00:00Z", dsun: 209.663762, hgln: 0.138582, hglt: -3.944071 },
  { isoDate: "2020-01-11T00:00:00Z", dsun: 209.673066, hgln: 0.130685, hglt: -4.049429 },
  { isoDate: "2020-01-12T00:00:00Z", dsun: 209.683206, hgln: 0.122567, hglt: -4.153554 },
  { isoDate: "2020-01-13T00:00:00Z", dsun: 209.694192, hgln: 0.114218, hglt: -4.256419 },
  { isoDate: "2020-01-14T00:00:00Z", dsun: 209.70603, hgln: 0.105632, hglt: -4.357993 },
  { isoDate: "2020-01-15T00:00:00Z", dsun: 209.718733, hgln: 0.096803, hglt: -4.458248 },
  { isoDate: "2020-01-16T00:00:00Z", dsun: 209.732307, hgln: 0.08773, hglt: -4.557155 },
  { isoDate: "2020-01-17T00:00:00Z", dsun: 209.746759, hgln: 0.078421, hglt: -4.654687 },
  { isoDate: "2020-01-18T00:00:00Z", dsun: 209.762096, hgln: 0.068887, hglt: -4.750817 },
  { isoDate: "2020-01-19T00:00:00Z", dsun: 209.778323, hgln: 0.059145, hglt: -4.845516 },
  { isoDate: "2020-01-20T00:00:00Z", dsun: 209.795447, hgln: 0.049218, hglt: -4.938759 },
  { isoDate: "2020-01-21T00:00:00Z", dsun: 209.813472, hgln: 0.039131, hglt: -5.030518 },
  { isoDate: "2020-01-22T00:00:00Z", dsun: 209.832404, hgln: 0.028915, hglt: -5.120768 },
  { isoDate: "2020-01-23T00:00:00Z", dsun: 209.852246, hgln: 0.018602, hglt: -5.209482 },
  { isoDate: "2020-01-24T00:00:00Z", dsun: 209.873001, hgln: 0.008224, hglt: -5.296635 },
  { isoDate: "2020-01-25T00:00:00Z", dsun: 209.894666, hgln: -0.002185, hglt: -5.382202 },
  { isoDate: "2020-01-26T00:00:00Z", dsun: 209.917237, hgln: -0.012593, hglt: -5.466157 },
  { isoDate: "2020-01-27T00:00:00Z", dsun: 209.940702, hgln: -0.022968, hglt: -5.548476 },
  { isoDate: "2020-01-28T00:00:00Z", dsun: 209.965048, hgln: -0.033281, hglt: -5.629133 },
  { isoDate: "2020-01-29T00:00:00Z", dsun: 209.990256, hgln: -0.0435, hglt: -5.708103 },
  { isoDate: "2020-01-30T00:00:00Z", dsun: 210.016306, hgln: -0.0536, hglt: -5.785363 },
  { isoDate: "2020-01-31T00:00:00Z", dsun: 210.043177, hgln: -0.063554, hglt: -5.860887 },
  { isoDate: "2020-02-01T00:00:00Z", dsun: 210.07085, hgln: -0.073339, hglt: -5.934652 },
  { isoDate: "2020-02-02T00:00:00Z", dsun: 210.099301, hgln: -0.082932, hglt: -6.006635 },
  { isoDate: "2020-02-03T00:00:00Z", dsun: 210.128511, hgln: -0.092315, hglt: -6.076812 },
  { isoDate: "2020-02-04T00:00:00Z", dsun: 210.158457, hgln: -0.101472, hglt: -6.145161 },
  { isoDate: "2020-02-05T00:00:00Z", dsun: 210.189117, hgln: -0.110389, hglt: -6.21166 },
  { isoDate: "2020-02-06T00:00:00Z", dsun: 210.220468, hgln: -0.119058, hglt: -6.276287 },
  { isoDate: "2020-02-07T00:00:00Z", dsun: 210.252489, hgln: -0.127476, hglt: -6.33902 },
  { isoDate: "2020-02-08T00:00:00Z", dsun: 210.285156, hgln: -0.135642, hglt: -6.399838 },
  { isoDate: "2020-02-09T00:00:00Z", dsun: 210.318448, hgln: -0.143564, hglt: -6.458722 },
  { isoDate: "2020-02-10T00:00:00Z", dsun: 210.35234, hgln: -0.151251, hglt: -6.515651 },
  { isoDate: "2020-02-11T00:00:00Z", dsun: 210.386817, hgln: -0.158718, hglt: -6.570608 },
  { isoDate: "2020-02-12T00:00:00Z", dsun: 210.421843, hgln: -0.165974, hglt: -6.623569 },
  { isoDate: "2020-02-13T00:00:00Z", dsun: 210.4574, hgln: -0.17303, hglt: -6.67452 },
  { isoDate: "2020-02-14T00:00:00Z", dsun: 210.493467, hgln: -0.179894, hglt: -6.723441 },
  { isoDate: "2020-02-15T00:00:00Z", dsun: 210.530021, hgln: -0.186568, hglt: -6.770316 },
  { isoDate: "2020-02-16T00:00:00Z", dsun: 210.567041, hgln: -0.193049, hglt: -6.815128 },
  { isoDate: "2020-02-17T00:00:00Z", dsun: 210.604505, hgln: -0.199329, hglt: -6.857862 },
  { isoDate: "2020-02-18T00:00:00Z", dsun: 210.642396, hgln: -0.205398, hglt: -6.898503 },
  { isoDate: "2020-02-19T00:00:00Z", dsun: 210.680695, hgln: -0.211241, hglt: -6.937036 },
  { isoDate: "2020-02-20T00:00:00Z", dsun: 210.719385, hgln: -0.216843, hglt: -6.973448 },
  { isoDate: "2020-02-21T00:00:00Z", dsun: 210.758452, hgln: -0.222185, hglt: -7.007726 },
  { isoDate: "2020-02-22T00:00:00Z", dsun: 210.797884, hgln: -0.22725, hglt: -7.039858 },
  { isoDate: "2020-02-23T00:00:00Z", dsun: 210.83767, hgln: -0.232018, hglt: -7.069833 },
  { isoDate: "2020-02-24T00:00:00Z", dsun: 210.877801, hgln: -0.236473, hglt: -7.097641 },
  { isoDate: "2020-02-25T00:00:00Z", dsun: 210.918271, hgln: -0.240596, hglt: -7.123271 },
  { isoDate: "2020-02-26T00:00:00Z", dsun: 210.959072, hgln: -0.244371, hglt: -7.146714 },
  { isoDate: "2020-02-27T00:00:00Z", dsun: 211.000198, hgln: -0.247784, hglt: -7.167963 },
  { isoDate: "2020-02-28T00:00:00Z", dsun: 211.041643, hgln: -0.250822, hglt: -7.18701 },
  { isoDate: "2020-02-29T00:00:00Z", dsun: 211.083399, hgln: -0.253473, hglt: -7.203847 },
  { isoDate: "2020-03-01T00:00:00Z", dsun: 211.125459, hgln: -0.255729, hglt: -7.21847 },
  { isoDate: "2020-03-02T00:00:00Z", dsun: 211.167818, hgln: -0.257582, hglt: -7.230874 },
  { isoDate: "2020-03-03T00:00:00Z", dsun: 211.210471, hgln: -0.259025, hglt: -7.241053 },
  { isoDate: "2020-03-04T00:00:00Z", dsun: 211.253413, hgln: -0.260055, hglt: -7.249004 },
  { isoDate: "2020-03-05T00:00:00Z", dsun: 211.296642, hgln: -0.260672, hglt: -7.254725 },
  { isoDate: "2020-03-06T00:00:00Z", dsun: 211.340158, hgln: -0.26088, hglt: -7.258214 },
  { isoDate: "2020-03-07T00:00:00Z", dsun: 211.38396, hgln: -0.260687, hglt: -7.259469 },
  { isoDate: "2020-03-08T00:00:00Z", dsun: 211.42805, hgln: -0.260108, hglt: -7.258492 },
  { isoDate: "2020-03-09T00:00:00Z", dsun: 211.472429, hgln: -0.25916, hglt: -7.255281 },
  { isoDate: "2020-03-10T00:00:00Z", dsun: 211.517096, hgln: -0.257866, hglt: -7.24984 },
  { isoDate: "2020-03-11T00:00:00Z", dsun: 211.562062, hgln: -0.256257, hglt: -7.242169 },
  { isoDate: "2020-03-12T00:00:00Z", dsun: 211.607325, hgln: -0.254356, hglt: -7.232272 },
  { isoDate: "2020-03-13T00:00:00Z", dsun: 211.652888, hgln: -0.252189, hglt: -7.220152 },
  { isoDate: "2020-03-14T00:00:00Z", dsun: 211.698755, hgln: -0.249773, hglt: -7.205814 },
  { isoDate: "2020-03-15T00:00:00Z", dsun: 211.744927, hgln: -0.247126, hglt: -7.189264 },
  { isoDate: "2020-03-16T00:00:00Z", dsun: 211.791408, hgln: -0.244255, hglt: -7.170507 },
  { isoDate: "2020-03-17T00:00:00Z", dsun: 211.838201, hgln: -0.241167, hglt: -7.149551 },
  { isoDate: "2020-03-18T00:00:00Z", dsun: 211.885307, hgln: -0.237862, hglt: -7.126403 },
  { isoDate: "2020-03-19T00:00:00Z", dsun: 211.932731, hgln: -0.23434, hglt: -7.101071 },
  { isoDate: "2020-03-20T00:00:00Z", dsun: 211.980476, hgln: -0.230598, hglt: -7.073566 },
  { isoDate: "2020-03-21T00:00:00Z", dsun: 212.028544, hgln: -0.22663, hglt: -7.043897 },
  { isoDate: "2020-03-22T00:00:00Z", dsun: 212.076939, hgln: -0.22243, hglt: -7.012074 },
  { isoDate: "2020-03-23T00:00:00Z", dsun: 212.125668, hgln: -0.217993, hglt: -6.97811 },
  { isoDate: "2020-03-24T00:00:00Z", dsun: 212.174733, hgln: -0.21331, hglt: -6.942015 },
  { isoDate: "2020-03-25T00:00:00Z", dsun: 212.224141, hgln: -0.208376, hglt: -6.903805 },
  { isoDate: "2020-03-26T00:00:00Z", dsun: 212.273894, hgln: -0.203183, hglt: -6.863491 },
  { isoDate: "2020-03-27T00:00:00Z", dsun: 212.323997, hgln: -0.197726, hglt: -6.82109 },
  { isoDate: "2020-03-28T00:00:00Z", dsun: 212.374453, hgln: -0.191999, hglt: -6.776614 },
  { isoDate: "2020-03-29T00:00:00Z", dsun: 212.425261, hgln: -0.186, hglt: -6.730082 },
  { isoDate: "2020-03-30T00:00:00Z", dsun: 212.476422, hgln: -0.179725, hglt: -6.681509 },
  { isoDate: "2020-03-31T00:00:00Z", dsun: 212.527936, hgln: -0.173173, hglt: -6.630912 },
  { isoDate: "2020-04-01T00:00:00Z", dsun: 212.579801, hgln: -0.166344, hglt: -6.57831 },
  { isoDate: "2020-04-02T00:00:00Z", dsun: 212.632016, hgln: -0.159243, hglt: -6.523721 },
  { isoDate: "2020-04-03T00:00:00Z", dsun: 212.684581, hgln: -0.151874, hglt: -6.467164 },
  { isoDate: "2020-04-04T00:00:00Z", dsun: 212.737495, hgln: -0.144249, hglt: -6.40866 },
  { isoDate: "2020-04-05T00:00:00Z", dsun: 212.790755, hgln: -0.136383, hglt: -6.348228 },
  { isoDate: "2020-04-06T00:00:00Z", dsun: 212.844361, hgln: -0.128296, hglt: -6.285891 },
  { isoDate: "2020-04-07T00:00:00Z", dsun: 212.898309, hgln: -0.120015, hglt: -6.221669 },
  { isoDate: "2020-04-08T00:00:00Z", dsun: 212.952596, hgln: -0.111572, hglt: -6.155586 },
  { isoDate: "2020-04-09T00:00:00Z", dsun: 213.007218, hgln: -0.102999, hglt: -6.087664 },
  { isoDate: "2020-04-10T00:00:00Z", dsun: 213.062169, hgln: -0.094332, hglt: -6.017927 },
  { isoDate: "2020-04-11T00:00:00Z", dsun: 213.117444, hgln: -0.0856, hglt: -5.946399 },
  { isoDate: "2020-04-12T00:00:00Z", dsun: 213.173033, hgln: -0.076833, hglt: -5.873105 },
  { isoDate: "2020-04-13T00:00:00Z", dsun: 213.22893, hgln: -0.068052, hglt: -5.79807 },
  { isoDate: "2020-04-14T00:00:00Z", dsun: 213.285122, hgln: -0.059274, hglt: -5.721321 },
  { isoDate: "2020-04-15T00:00:00Z", dsun: 213.341598, hgln: -0.05051, hglt: -5.642881 },
  { isoDate: "2020-04-16T00:00:00Z", dsun: 213.398351, hgln: -0.041776, hglt: -5.562781 },
  { isoDate: "2020-04-17T00:00:00Z", dsun: 213.455368, hgln: -0.033074, hglt: -5.481046 },
  { isoDate: "2020-04-18T00:00:00Z", dsun: 213.512634, hgln: -0.024409, hglt: -5.397705 },
  { isoDate: "2020-04-19T00:00:00Z", dsun: 213.570138, hgln: -0.015783, hglt: -5.312785 },
  { isoDate: "2020-04-20T00:00:00Z", dsun: 213.627866, hgln: -0.007198, hglt: -5.226316 },
  { isoDate: "2020-04-21T00:00:00Z", dsun: 213.685803, hgln: 0.001347, hglt: -5.138326 },
  { isoDate: "2020-04-22T00:00:00Z", dsun: 213.743936, hgln: 0.009854, hglt: -5.048845 },
  { isoDate: "2020-04-23T00:00:00Z", dsun: 213.802248, hgln: 0.018323, hglt: -4.957902 },
  { isoDate: "2020-04-24T00:00:00Z", dsun: 213.86072, hgln: 0.026759, hglt: -4.865529 },
  { isoDate: "2020-04-25T00:00:00Z", dsun: 213.919334, hgln: 0.035164, hglt: -4.771756 },
  { isoDate: "2020-04-26T00:00:00Z", dsun: 213.978069, hgln: 0.043542, hglt: -4.676613 },
  { isoDate: "2020-04-27T00:00:00Z", dsun: 214.036901, hgln: 0.051896, hglt: -4.580133 },
  { isoDate: "2020-04-28T00:00:00Z", dsun: 214.095808, hgln: 0.060229, hglt: -4.482347 },
  { isoDate: "2020-04-29T00:00:00Z", dsun: 214.154765, hgln: 0.068544, hglt: -4.383287 },
  { isoDate: "2020-04-30T00:00:00Z", dsun: 214.213745, hgln: 0.076839, hglt: -4.282985 },
  { isoDate: "2020-05-01T00:00:00Z", dsun: 214.272729, hgln: 0.085114, hglt: -4.181474 },
  { isoDate: "2020-05-02T00:00:00Z", dsun: 214.331692, hgln: 0.093363, hglt: -4.078786 },
  { isoDate: "2020-05-03T00:00:00Z", dsun: 214.390608, hgln: 0.101576, hglt: -3.974955 },
  { isoDate: "2020-05-04T00:00:00Z", dsun: 214.449453, hgln: 0.109738, hglt: -3.870014 },
  { isoDate: "2020-05-05T00:00:00Z", dsun: 214.508201, hgln: 0.117832, hglt: -3.763997 },
  { isoDate: "2020-05-06T00:00:00Z", dsun: 214.566823, hgln: 0.125831, hglt: -3.656936 },
  { isoDate: "2020-05-07T00:00:00Z", dsun: 214.625292, hgln: 0.133708, hglt: -3.548866 },
  { isoDate: "2020-05-08T00:00:00Z", dsun: 214.68358, hgln: 0.141431, hglt: -3.43982 },
  { isoDate: "2020-05-09T00:00:00Z", dsun: 214.741656, hgln: 0.148971, hglt: -3.329834 },
  { isoDate: "2020-05-10T00:00:00Z", dsun: 214.799491, hgln: 0.156298, hglt: -3.218941 },
  { isoDate: "2020-05-11T00:00:00Z", dsun: 214.857053, hgln: 0.163389, hglt: -3.107175 },
  { isoDate: "2020-05-12T00:00:00Z", dsun: 214.914312, hgln: 0.170224, hglt: -2.994571 },
  { isoDate: "2020-05-13T00:00:00Z", dsun: 214.971236, hgln: 0.176788, hglt: -2.881165 },
  { isoDate: "2020-05-14T00:00:00Z", dsun: 215.027794, hgln: 0.183069, hglt: -2.766989 },
  { isoDate: "2020-05-15T00:00:00Z", dsun: 215.083954, hgln: 0.189061, hglt: -2.652079 },
  { isoDate: "2020-05-16T00:00:00Z", dsun: 215.139684, hgln: 0.194759, hglt: -2.536471 },
  { isoDate: "2020-05-17T00:00:00Z", dsun: 215.194955, hgln: 0.200162, hglt: -2.420197 },
  { isoDate: "2020-05-18T00:00:00Z", dsun: 215.249735, hgln: 0.20527, hglt: -2.303295 },
  { isoDate: "2020-05-19T00:00:00Z", dsun: 215.303995, hgln: 0.210085, hglt: -2.185797 },
  { isoDate: "2020-05-20T00:00:00Z", dsun: 215.357703, hgln: 0.21461, hglt: -2.06774 },
  { isoDate: "2020-05-21T00:00:00Z", dsun: 215.410828, hgln: 0.218852, hglt: -1.949158 },
  { isoDate: "2020-05-22T00:00:00Z", dsun: 215.46334, hgln: 0.222817, hglt: -1.830087 },
  { isoDate: "2020-05-23T00:00:00Z", dsun: 215.515204, hgln: 0.226516, hglt: -1.71056 },
  { isoDate: "2020-05-24T00:00:00Z", dsun: 215.566388, hgln: 0.229958, hglt: -1.590613 },
  { isoDate: "2020-05-25T00:00:00Z", dsun: 215.616859, hgln: 0.233155, hglt: -1.47028 },
  { isoDate: "2020-05-26T00:00:00Z", dsun: 215.666586, hgln: 0.23612, hglt: -1.349597 },
  { isoDate: "2020-05-27T00:00:00Z", dsun: 215.715535, hgln: 0.238863, hglt: -1.228598 },
  { isoDate: "2020-05-28T00:00:00Z", dsun: 215.763677, hgln: 0.241396, hglt: -1.107318 },
  { isoDate: "2020-05-29T00:00:00Z", dsun: 215.810983, hgln: 0.243728, hglt: -0.985791 },
  { isoDate: "2020-05-30T00:00:00Z", dsun: 215.857422, hgln: 0.245863, hglt: -0.864052 },
  { isoDate: "2020-05-31T00:00:00Z", dsun: 215.902968, hgln: 0.247805, hglt: -0.742134 },
  { isoDate: "2020-06-01T00:00:00Z", dsun: 215.947592, hgln: 0.249551, hglt: -0.620074 },
  { isoDate: "2020-06-02T00:00:00Z", dsun: 215.991267, hgln: 0.251095, hglt: -0.497903 },
  { isoDate: "2020-06-03T00:00:00Z", dsun: 216.033967, hgln: 0.252424, hglt: -0.375658 },
  { isoDate: "2020-06-04T00:00:00Z", dsun: 216.075665, hgln: 0.253522, hglt: -0.253372 },
  { isoDate: "2020-06-05T00:00:00Z", dsun: 216.116337, hgln: 0.254369, hglt: -0.131079 },
  { isoDate: "2020-06-06T00:00:00Z", dsun: 216.155955, hgln: 0.254943, hglt: -0.008812 },
  { isoDate: "2020-06-07T00:00:00Z", dsun: 216.194496, hgln: 0.255222, hglt: 0.113394 },
  { isoDate: "2020-06-08T00:00:00Z", dsun: 216.231935, hgln: 0.255188, hglt: 0.235506 },
  { isoDate: "2020-06-09T00:00:00Z", dsun: 216.268249, hgln: 0.254822, hglt: 0.357491 },
  { isoDate: "2020-06-10T00:00:00Z", dsun: 216.303414, hgln: 0.254114, hglt: 0.479315 },
  { isoDate: "2020-06-11T00:00:00Z", dsun: 216.337413, hgln: 0.253052, hglt: 0.600945 },
  { isoDate: "2020-06-12T00:00:00Z", dsun: 216.370224, hgln: 0.251631, hglt: 0.722348 },
  { isoDate: "2020-06-13T00:00:00Z", dsun: 216.401829, hgln: 0.249848, hglt: 0.843491 },
  { isoDate: "2020-06-14T00:00:00Z", dsun: 216.432212, hgln: 0.247706, hglt: 0.964342 },
  { isoDate: "2020-06-15T00:00:00Z", dsun: 216.461357, hgln: 0.245205, hglt: 1.084867 },
  { isoDate: "2020-06-16T00:00:00Z", dsun: 216.489252, hgln: 0.24235, hglt: 1.205034 },
  { isoDate: "2020-06-17T00:00:00Z", dsun: 216.515885, hgln: 0.23915, hglt: 1.324812 },
  { isoDate: "2020-06-18T00:00:00Z", dsun: 216.541246, hgln: 0.235612, hglt: 1.444166 },
  { isoDate: "2020-06-19T00:00:00Z", dsun: 216.565325, hgln: 0.231747, hglt: 1.563067 },
  { isoDate: "2020-06-20T00:00:00Z", dsun: 216.588112, hgln: 0.22757, hglt: 1.681481 },
  { isoDate: "2020-06-21T00:00:00Z", dsun: 216.609598, hgln: 0.223096, hglt: 1.799376 },
  { isoDate: "2020-06-22T00:00:00Z", dsun: 216.629774, hgln: 0.218344, hglt: 1.916723 },
  { isoDate: "2020-06-23T00:00:00Z", dsun: 216.648632, hgln: 0.213333, hglt: 2.033488 },
  { isoDate: "2020-06-24T00:00:00Z", dsun: 216.666165, hgln: 0.208084, hglt: 2.149642 },
  { isoDate: "2020-06-25T00:00:00Z", dsun: 216.68237, hgln: 0.202619, hglt: 2.265153 },
  { isoDate: "2020-06-26T00:00:00Z", dsun: 216.697245, hgln: 0.196958, hglt: 2.37999 },
  { isoDate: "2020-06-27T00:00:00Z", dsun: 216.710789, hgln: 0.191117, hglt: 2.494124 },
  { isoDate: "2020-06-28T00:00:00Z", dsun: 216.723005, hgln: 0.18511, hglt: 2.607524 },
  { isoDate: "2020-06-29T00:00:00Z", dsun: 216.733898, hgln: 0.178949, hglt: 2.720159 },
  { isoDate: "2020-06-30T00:00:00Z", dsun: 216.743472, hgln: 0.172637, hglt: 2.832 },
  { isoDate: "2020-07-01T00:00:00Z", dsun: 216.751734, hgln: 0.166177, hglt: 2.943017 },
  { isoDate: "2020-07-02T00:00:00Z", dsun: 216.758693, hgln: 0.159563, hglt: 3.05318 },
  { isoDate: "2020-07-03T00:00:00Z", dsun: 216.764356, hgln: 0.152789, hglt: 3.162461 },
  { isoDate: "2020-07-04T00:00:00Z", dsun: 216.768734, hgln: 0.145845, hglt: 3.27083 },
  { isoDate: "2020-07-05T00:00:00Z", dsun: 216.771837, hgln: 0.13872, hglt: 3.378259 },
  { isoDate: "2020-07-06T00:00:00Z", dsun: 216.773673, hgln: 0.131403, hglt: 3.484719 },
  { isoDate: "2020-07-07T00:00:00Z", dsun: 216.774255, hgln: 0.123884, hglt: 3.590182 },
  { isoDate: "2020-07-08T00:00:00Z", dsun: 216.773593, hgln: 0.116157, hglt: 3.69462 },
  { isoDate: "2020-07-09T00:00:00Z", dsun: 216.771698, hgln: 0.108218, hglt: 3.798006 },
  { isoDate: "2020-07-10T00:00:00Z", dsun: 216.768581, hgln: 0.100067, hglt: 3.900313 },
  { isoDate: "2020-07-11T00:00:00Z", dsun: 216.764255, hgln: 0.091706, hglt: 4.001514 },
  { isoDate: "2020-07-12T00:00:00Z", dsun: 216.75873, hgln: 0.083141, hglt: 4.101581 },
  { isoDate: "2020-07-13T00:00:00Z", dsun: 216.752018, hgln: 0.074382, hglt: 4.200491 },
  { isoDate: "2020-07-14T00:00:00Z", dsun: 216.744129, hgln: 0.065439, hglt: 4.298213 },
  { isoDate: "2020-07-15T00:00:00Z", dsun: 216.73508, hgln: 0.056326, hglt: 4.394727 },
  { isoDate: "2020-07-16T00:00:00Z", dsun: 216.724883, hgln: 0.04706, hglt: 4.490006 },
  { isoDate: "2020-07-17T00:00:00Z", dsun: 216.713549, hgln: 0.03766, hglt: 4.584024 },
  { isoDate: "2020-07-18T00:00:00Z", dsun: 216.701092, hgln: 0.028147, hglt: 4.676756 },
  { isoDate: "2020-07-19T00:00:00Z", dsun: 216.687525, hgln: 0.018546, hglt: 4.76818 },
  { isoDate: "2020-07-20T00:00:00Z", dsun: 216.672859, hgln: 0.008885, hglt: 4.858271 },
  { isoDate: "2020-07-21T00:00:00Z", dsun: 216.657103, hgln: -0.000807, hglt: 4.947005 },
  { isoDate: "2020-07-22T00:00:00Z", dsun: 216.640262, hgln: -0.010499, hglt: 5.03436 },
  { isoDate: "2020-07-23T00:00:00Z", dsun: 216.622338, hgln: -0.020157, hglt: 5.120312 },
  { isoDate: "2020-07-24T00:00:00Z", dsun: 216.603331, hgln: -0.029749, hglt: 5.204841 },
  { isoDate: "2020-07-25T00:00:00Z", dsun: 216.583241, hgln: -0.039244, hglt: 5.287923 },
  { isoDate: "2020-07-26T00:00:00Z", dsun: 216.562065, hgln: -0.048615, hglt: 5.369539 },
  { isoDate: "2020-07-27T00:00:00Z", dsun: 216.539798, hgln: -0.057838, hglt: 5.449665 },
  { isoDate: "2020-07-28T00:00:00Z", dsun: 216.516438, hgln: -0.066895, hglt: 5.528282 },
  { isoDate: "2020-07-29T00:00:00Z", dsun: 216.491983, hgln: -0.075772, hglt: 5.60537 },
  { isoDate: "2020-07-30T00:00:00Z", dsun: 216.466429, hgln: -0.084462, hglt: 5.680907 },
  { isoDate: "2020-07-31T00:00:00Z", dsun: 216.439776, hgln: -0.092962, hglt: 5.754874 },
  { isoDate: "2020-08-01T00:00:00Z", dsun: 216.412021, hgln: -0.101273, hglt: 5.827252 },
  { isoDate: "2020-08-02T00:00:00Z", dsun: 216.383161, hgln: -0.109396, hglt: 5.898021 },
  { isoDate: "2020-08-03T00:00:00Z", dsun: 216.353194, hgln: -0.117339, hglt: 5.967162 },
  { isoDate: "2020-08-04T00:00:00Z", dsun: 216.322115, hgln: -0.125108, hglt: 6.034657 },
  { isoDate: "2020-08-05T00:00:00Z", dsun: 216.289923, hgln: -0.132709, hglt: 6.100487 },
  { isoDate: "2020-08-06T00:00:00Z", dsun: 216.256613, hgln: -0.140147, hglt: 6.164634 },
  { isoDate: "2020-08-07T00:00:00Z", dsun: 216.222183, hgln: -0.147427, hglt: 6.227081 },
  { isoDate: "2020-08-08T00:00:00Z", dsun: 216.186628, hgln: -0.154552, hglt: 6.287809 },
  { isoDate: "2020-08-09T00:00:00Z", dsun: 216.149948, hgln: -0.161524, hglt: 6.346803 },
  { isoDate: "2020-08-10T00:00:00Z", dsun: 216.112139, hgln: -0.168342, hglt: 6.404045 },
  { isoDate: "2020-08-11T00:00:00Z", dsun: 216.0732, hgln: -0.175004, hglt: 6.459518 },
  { isoDate: "2020-08-12T00:00:00Z", dsun: 216.033159, hgln: -0.18151, hglt: 6.513204 },
  { isoDate: "2020-08-13T00:00:00Z", dsun: 215.991962, hgln: -0.187851, hglt: 6.565092 },
  { isoDate: "2020-08-14T00:00:00Z", dsun: 215.949638, hgln: -0.19402, hglt: 6.615163 },
  { isoDate: "2020-08-15T00:00:00Z", dsun: 215.906192, hgln: -0.20001, hglt: 6.663403 },
  { isoDate: "2020-08-16T00:00:00Z", dsun: 215.861627, hgln: -0.205807, hglt: 6.709796 },
  { isoDate: "2020-08-17T00:00:00Z", dsun: 215.815953, hgln: -0.211397, hglt: 6.754328 },
  { isoDate: "2020-08-18T00:00:00Z", dsun: 215.769179, hgln: -0.21676, hglt: 6.796984 },
  { isoDate: "2020-08-19T00:00:00Z", dsun: 215.721317, hgln: -0.221875, hglt: 6.837751 },
  { isoDate: "2020-08-20T00:00:00Z", dsun: 215.672381, hgln: -0.226717, hglt: 6.876616 },
  { isoDate: "2020-08-21T00:00:00Z", dsun: 215.622386, hgln: -0.231263, hglt: 6.913563 },
  { isoDate: "2020-08-22T00:00:00Z", dsun: 215.571348, hgln: -0.235487, hglt: 6.948582 },
  { isoDate: "2020-08-23T00:00:00Z", dsun: 215.519283, hgln: -0.239368, hglt: 6.981659 },
  { isoDate: "2020-08-24T00:00:00Z", dsun: 215.466205, hgln: -0.242891, hglt: 7.012783 },
  { isoDate: "2020-08-25T00:00:00Z", dsun: 215.412131, hgln: -0.246043, hglt: 7.041941 },
  { isoDate: "2020-08-26T00:00:00Z", dsun: 215.357079, hgln: -0.248818, hglt: 7.069122 },
  { isoDate: "2020-08-27T00:00:00Z", dsun: 215.301068, hgln: -0.251214, hglt: 7.094315 },
  { isoDate: "2020-08-28T00:00:00Z", dsun: 215.244121, hgln: -0.253235, hglt: 7.117511 },
  { isoDate: "2020-08-29T00:00:00Z", dsun: 215.186259, hgln: -0.254887, hglt: 7.138699 },
  { isoDate: "2020-08-30T00:00:00Z", dsun: 215.127509, hgln: -0.256181, hglt: 7.157869 },
  { isoDate: "2020-08-31T00:00:00Z", dsun: 215.067896, hgln: -0.257128, hglt: 7.175013 },
  { isoDate: "2020-09-01T00:00:00Z", dsun: 215.007448, hgln: -0.257744, hglt: 7.190122 },
  { isoDate: "2020-09-02T00:00:00Z", dsun: 214.946193, hgln: -0.258042, hglt: 7.203188 },
  { isoDate: "2020-09-03T00:00:00Z", dsun: 214.884162, hgln: -0.258037, hglt: 7.214204 },
  { isoDate: "2020-09-04T00:00:00Z", dsun: 214.821385, hgln: -0.257745, hglt: 7.223162 },
  { isoDate: "2020-09-05T00:00:00Z", dsun: 214.757892, hgln: -0.257179, hglt: 7.230056 },
  { isoDate: "2020-09-06T00:00:00Z", dsun: 214.693716, hgln: -0.256353, hglt: 7.23488 },
  { isoDate: "2020-09-07T00:00:00Z", dsun: 214.628889, hgln: -0.255279, hglt: 7.237629 },
  { isoDate: "2020-09-08T00:00:00Z", dsun: 214.563442, hgln: -0.253967, hglt: 7.238297 },
  { isoDate: "2020-09-09T00:00:00Z", dsun: 214.497406, hgln: -0.252425, hglt: 7.236882 },
  { isoDate: "2020-09-10T00:00:00Z", dsun: 214.430822, hgln: -0.250665, hglt: 7.233377 },
  { isoDate: "2020-09-11T00:00:00Z", dsun: 214.36372, hgln: -0.248691, hglt: 7.227781 },
  { isoDate: "2020-09-12T00:00:00Z", dsun: 214.296134, hgln: -0.246507, hglt: 7.22009 },
  { isoDate: "2020-09-13T00:00:00Z", dsun: 214.228099, hgln: -0.244115, hglt: 7.210304 },
  { isoDate: "2020-09-14T00:00:00Z", dsun: 214.159653, hgln: -0.241513, hglt: 7.19842 },
  { isoDate: "2020-09-15T00:00:00Z", dsun: 214.09083, hgln: -0.238694, hglt: 7.184437 },
  { isoDate: "2020-09-16T00:00:00Z", dsun: 214.021669, hgln: -0.235649, hglt: 7.168357 },
  { isoDate: "2020-09-17T00:00:00Z", dsun: 213.952208, hgln: -0.232363, hglt: 7.150179 },
  { isoDate: "2020-09-18T00:00:00Z", dsun: 213.882486, hgln: -0.22882, hglt: 7.129904 },
  { isoDate: "2020-09-19T00:00:00Z", dsun: 213.812543, hgln: -0.225, hglt: 7.107535 },
  { isoDate: "2020-09-20T00:00:00Z", dsun: 213.742416, hgln: -0.220885, hglt: 7.083073 },
  { isoDate: "2020-09-21T00:00:00Z", dsun: 213.672144, hgln: -0.216461, hglt: 7.056523 },
  { isoDate: "2020-09-22T00:00:00Z", dsun: 213.601764, hgln: -0.211718, hglt: 7.027888 },
  { isoDate: "2020-09-23T00:00:00Z", dsun: 213.531312, hgln: -0.20665, hglt: 6.997172 },
  { isoDate: "2020-09-24T00:00:00Z", dsun: 213.460826, hgln: -0.20126, hglt: 6.96438 },
  { isoDate: "2020-09-25T00:00:00Z", dsun: 213.390341, hgln: -0.195552, hglt: 6.929519 },
  { isoDate: "2020-09-26T00:00:00Z", dsun: 213.319896, hgln: -0.189537, hglt: 6.892594 },
  { isoDate: "2020-09-27T00:00:00Z", dsun: 213.249527, hgln: -0.183229, hglt: 6.853614 },
  { isoDate: "2020-09-28T00:00:00Z", dsun: 213.179272, hgln: -0.176643, hglt: 6.812586 },
  { isoDate: "2020-09-29T00:00:00Z", dsun: 213.109169, hgln: -0.169798, hglt: 6.769519 },
  { isoDate: "2020-09-30T00:00:00Z", dsun: 213.039254, hgln: -0.162713, hglt: 6.724422 },
  { isoDate: "2020-10-01T00:00:00Z", dsun: 212.969564, hgln: -0.15541, hglt: 6.677306 },
  { isoDate: "2020-10-02T00:00:00Z", dsun: 212.900136, hgln: -0.147911, hglt: 6.628181 },
  { isoDate: "2020-10-03T00:00:00Z", dsun: 212.831004, hgln: -0.140238, hglt: 6.577059 },
  { isoDate: "2020-10-04T00:00:00Z", dsun: 212.762203, hgln: -0.132412, hglt: 6.523952 },
  { isoDate: "2020-10-05T00:00:00Z", dsun: 212.693768, hgln: -0.124456, hglt: 6.468873 },
  { isoDate: "2020-10-06T00:00:00Z", dsun: 212.62573, hgln: -0.11639, hglt: 6.411836 },
  { isoDate: "2020-10-07T00:00:00Z", dsun: 212.558122, hgln: -0.108236, hglt: 6.352855 },
  { isoDate: "2020-10-08T00:00:00Z", dsun: 212.490974, hgln: -0.100011, hglt: 6.291946 },
  { isoDate: "2020-10-09T00:00:00Z", dsun: 212.424316, hgln: -0.091734, hglt: 6.229124 },
  { isoDate: "2020-10-10T00:00:00Z", dsun: 212.358178, hgln: -0.08342, hglt: 6.164405 },
  { isoDate: "2020-10-11T00:00:00Z", dsun: 212.292588, hgln: -0.075084, hglt: 6.097808 },
  { isoDate: "2020-10-12T00:00:00Z", dsun: 212.227573, hgln: -0.066737, hglt: 6.02935 },
  { isoDate: "2020-10-13T00:00:00Z", dsun: 212.163161, hgln: -0.058385, hglt: 5.959049 },
  { isoDate: "2020-10-14T00:00:00Z", dsun: 212.099375, hgln: -0.050029, hglt: 5.886927 },
  { isoDate: "2020-10-15T00:00:00Z", dsun: 212.036247, hgln: -0.041671, hglt: 5.813001 },
  { isoDate: "2020-10-16T00:00:00Z", dsun: 211.9738, hgln: -0.033303, hglt: 5.737292 },
  { isoDate: "2020-10-17T00:00:00Z", dsun: 211.912058, hgln: -0.024913, hglt: 5.659824 },
  { isoDate: "2020-10-18T00:00:00Z", dsun: 211.851044, hgln: -0.016489, hglt: 5.580617 },
  { isoDate: "2020-10-19T00:00:00Z", dsun: 211.790777, hgln: -0.008017, hglt: 5.499694 },
  { isoDate: "2020-10-20T00:00:00Z", dsun: 211.731277, hgln: 0.000512, hglt: 5.417079 },
  { isoDate: "2020-10-21T00:00:00Z", dsun: 211.672559, hgln: 0.009103, hglt: 5.332796 },
  { isoDate: "2020-10-22T00:00:00Z", dsun: 211.614641, hgln: 0.017758, hglt: 5.24687 },
  { isoDate: "2020-10-23T00:00:00Z", dsun: 211.557537, hgln: 0.026471, hglt: 5.159325 },
  { isoDate: "2020-10-24T00:00:00Z", dsun: 211.501263, hgln: 0.035232, hglt: 5.070188 },
  { isoDate: "2020-10-25T00:00:00Z", dsun: 211.445831, hgln: 0.04403, hglt: 4.979486 },
  { isoDate: "2020-10-26T00:00:00Z", dsun: 211.391256, hgln: 0.052849, hglt: 4.887244 },
  { isoDate: "2020-10-27T00:00:00Z", dsun: 211.33755, hgln: 0.061669, hglt: 4.793492 },
  { isoDate: "2020-10-28T00:00:00Z", dsun: 211.284725, hgln: 0.070472, hglt: 4.698258 },
  { isoDate: "2020-10-29T00:00:00Z", dsun: 211.23279, hgln: 0.079237, hglt: 4.60157 },
  { isoDate: "2020-10-30T00:00:00Z", dsun: 211.181754, hgln: 0.08794, hglt: 4.503459 },
  { isoDate: "2020-10-31T00:00:00Z", dsun: 211.131625, hgln: 0.096559, hglt: 4.403953 },
  { isoDate: "2020-11-01T00:00:00Z", dsun: 211.082408, hgln: 0.10507, hglt: 4.303083 },
  { isoDate: "2020-11-02T00:00:00Z", dsun: 211.034108, hgln: 0.11345, hglt: 4.200881 },
  { isoDate: "2020-11-03T00:00:00Z", dsun: 210.986728, hgln: 0.121675, hglt: 4.097378 },
  { isoDate: "2020-11-04T00:00:00Z", dsun: 210.940271, hgln: 0.129722, hglt: 3.992606 },
  { isoDate: "2020-11-05T00:00:00Z", dsun: 210.894737, hgln: 0.137569, hglt: 3.886597 },
  { isoDate: "2020-11-06T00:00:00Z", dsun: 210.850126, hgln: 0.145196, hglt: 3.779384 },
  { isoDate: "2020-11-07T00:00:00Z", dsun: 210.806438, hgln: 0.152582, hglt: 3.671001 },
  { isoDate: "2020-11-08T00:00:00Z", dsun: 210.763672, hgln: 0.15971, hglt: 3.56148 },
  { isoDate: "2020-11-09T00:00:00Z", dsun: 210.721825, hgln: 0.166567, hglt: 3.450857 },
  { isoDate: "2020-11-10T00:00:00Z", dsun: 210.680896, hgln: 0.173141, hglt: 3.339165 },
  { isoDate: "2020-11-11T00:00:00Z", dsun: 210.640886, hgln: 0.179426, hglt: 3.226441 },
  { isoDate: "2020-11-12T00:00:00Z", dsun: 210.601784, hgln: 0.185418, hglt: 3.112718 },
  { isoDate: "2020-11-13T00:00:00Z", dsun: 210.563591, hgln: 0.191123, hglt: 2.998032 },
  { isoDate: "2020-11-14T00:00:00Z", dsun: 210.526303, hgln: 0.196548, hglt: 2.88242 },
  { isoDate: "2020-11-15T00:00:00Z", dsun: 210.489914, hgln: 0.201708, hglt: 2.765918 },
  { isoDate: "2020-11-16T00:00:00Z", dsun: 210.454415, hgln: 0.206618, hglt: 2.648562 },
  { isoDate: "2020-11-17T00:00:00Z", dsun: 210.419799, hgln: 0.211297, hglt: 2.530389 },
  { isoDate: "2020-11-18T00:00:00Z", dsun: 210.386057, hgln: 0.215758, hglt: 2.411437 },
  { isoDate: "2020-11-19T00:00:00Z", dsun: 210.35318, hgln: 0.220013, hglt: 2.291741 },
  { isoDate: "2020-11-20T00:00:00Z", dsun: 210.321158, hgln: 0.224068, hglt: 2.171341 },
  { isoDate: "2020-11-21T00:00:00Z", dsun: 210.289983, hgln: 0.227927, hglt: 2.050273 },
  { isoDate: "2020-11-22T00:00:00Z", dsun: 210.259648, hgln: 0.231587, hglt: 1.928576 },
  { isoDate: "2020-11-23T00:00:00Z", dsun: 210.230143, hgln: 0.235043, hglt: 1.806287 },
  { isoDate: "2020-11-24T00:00:00Z", dsun: 210.201461, hgln: 0.238287, hglt: 1.683445 },
  { isoDate: "2020-11-25T00:00:00Z", dsun: 210.173592, hgln: 0.24131, hglt: 1.560089 },
  { isoDate: "2020-11-26T00:00:00Z", dsun: 210.146529, hgln: 0.244101, hglt: 1.436256 },
  { isoDate: "2020-11-27T00:00:00Z", dsun: 210.120261, hgln: 0.246647, hglt: 1.311987 },
  { isoDate: "2020-11-28T00:00:00Z", dsun: 210.09478, hgln: 0.248934, hglt: 1.187319 },
  { isoDate: "2020-11-29T00:00:00Z", dsun: 210.070077, hgln: 0.250947, hglt: 1.062292 },
  { isoDate: "2020-11-30T00:00:00Z", dsun: 210.046142, hgln: 0.25267, hglt: 0.936944 },
  { isoDate: "2020-12-01T00:00:00Z", dsun: 210.022964, hgln: 0.254087, hglt: 0.811314 },
  { isoDate: "2020-12-02T00:00:00Z", dsun: 210.000536, hgln: 0.25518, hglt: 0.685442 },
  { isoDate: "2020-12-03T00:00:00Z", dsun: 209.978847, hgln: 0.255933, hglt: 0.559366 },
  { isoDate: "2020-12-04T00:00:00Z", dsun: 209.957888, hgln: 0.256329, hglt: 0.433126 },
  { isoDate: "2020-12-05T00:00:00Z", dsun: 209.937651, hgln: 0.256353, hglt: 0.306761 },
  { isoDate: "2020-12-06T00:00:00Z", dsun: 209.918127, hgln: 0.255991, hglt: 0.180308 },
  { isoDate: "2020-12-07T00:00:00Z", dsun: 209.899311, hgln: 0.255232, hglt: 0.053809 },
  { isoDate: "2020-12-08T00:00:00Z", dsun: 209.881196, hgln: 0.254067, hglt: -0.0727 },
  { isoDate: "2020-12-09T00:00:00Z", dsun: 209.863777, hgln: 0.252493, hglt: -0.199178 },
  { isoDate: "2020-12-10T00:00:00Z", dsun: 209.847052, hgln: 0.250509, hglt: -0.325588 },
  { isoDate: "2020-12-11T00:00:00Z", dsun: 209.831019, hgln: 0.248119, hglt: -0.45189 },
  { isoDate: "2020-12-12T00:00:00Z", dsun: 209.815676, hgln: 0.245334, hglt: -0.578046 },
  { isoDate: "2020-12-13T00:00:00Z", dsun: 209.801022, hgln: 0.24217, hglt: -0.704017 },
  { isoDate: "2020-12-14T00:00:00Z", dsun: 209.787056, hgln: 0.238647, hglt: -0.829766 },
  { isoDate: "2020-12-15T00:00:00Z", dsun: 209.773774, hgln: 0.234787, hglt: -0.955254 },
  { isoDate: "2020-12-16T00:00:00Z", dsun: 209.761176, hgln: 0.230615, hglt: -1.080444 },
  { isoDate: "2020-12-17T00:00:00Z", dsun: 209.74926, hgln: 0.226153, hglt: -1.205298 },
  { isoDate: "2020-12-18T00:00:00Z", dsun: 209.738027, hgln: 0.221422, hglt: -1.329778 },
  { isoDate: "2020-12-19T00:00:00Z", dsun: 209.727479, hgln: 0.216439, hglt: -1.453848 },
  { isoDate: "2020-12-20T00:00:00Z", dsun: 209.717622, hgln: 0.211216, hglt: -1.57747 },
  { isoDate: "2020-12-21T00:00:00Z", dsun: 209.70846, hgln: 0.205763, hglt: -1.700609 },
  { isoDate: "2020-12-22T00:00:00Z", dsun: 209.700003, hgln: 0.200088, hglt: -1.823226 },
  { isoDate: "2020-12-23T00:00:00Z", dsun: 209.692259, hgln: 0.194194, hglt: -1.945287 },
  { isoDate: "2020-12-24T00:00:00Z", dsun: 209.685239, hgln: 0.188085, hglt: -2.066755 },
  { isoDate: "2020-12-25T00:00:00Z", dsun: 209.678954, hgln: 0.181761, hglt: -2.187595 },
  { isoDate: "2020-12-26T00:00:00Z", dsun: 209.673416, hgln: 0.175222, hglt: -2.307772 },
  { isoDate: "2020-12-27T00:00:00Z", dsun: 209.668637, hgln: 0.168467, hglt: -2.427251 },
  { isoDate: "2020-12-28T00:00:00Z", dsun: 209.664631, hgln: 0.161492, hglt: -2.545996 },
  { isoDate: "2020-12-29T00:00:00Z", dsun: 209.66141, hgln: 0.154293, hglt: -2.663974 },
  { isoDate: "2020-12-30T00:00:00Z", dsun: 209.658989, hgln: 0.146865, hglt: -2.781151 },
  { isoDate: "2020-12-31T00:00:00Z", dsun: 209.657381, hgln: 0.139202, hglt: -2.897493 },
  { isoDate: "2021-01-01T00:00:00Z", dsun: 209.656598, hgln: 0.131298, hglt: -3.012966 },
  { isoDate: "2021-01-02T00:00:00Z", dsun: 209.656655, hgln: 0.123146, hglt: -3.127537 },
  { isoDate: "2021-01-03T00:00:00Z", dsun: 209.657563, hgln: 0.114743, hglt: -3.241174 },
  { isoDate: "2021-01-04T00:00:00Z", dsun: 209.659334, hgln: 0.106084, hglt: -3.353844 },
  { isoDate: "2021-01-05T00:00:00Z", dsun: 209.661982, hgln: 0.097172, hglt: -3.465514 },
  { isoDate: "2021-01-06T00:00:00Z", dsun: 209.665517, hgln: 0.088011, hglt: -3.576153 },
  { isoDate: "2021-01-07T00:00:00Z", dsun: 209.669951, hgln: 0.078611, hglt: -3.685728 },
  { isoDate: "2021-01-08T00:00:00Z", dsun: 209.675294, hgln: 0.068985, hglt: -3.794209 },
  { isoDate: "2021-01-09T00:00:00Z", dsun: 209.681558, hgln: 0.059155, hglt: -3.901563 },
  { isoDate: "2021-01-10T00:00:00Z", dsun: 209.688753, hgln: 0.049145, hglt: -4.007761 },
  { isoDate: "2021-01-11T00:00:00Z", dsun: 209.69689, hgln: 0.038987, hglt: -4.11277 },
  { isoDate: "2021-01-12T00:00:00Z", dsun: 209.705978, hgln: 0.028715, hglt: -4.216562 },
  { isoDate: "2021-01-13T00:00:00Z", dsun: 209.716008, hgln: 0.018364, hglt: -4.319105 },
  { isoDate: "2021-01-14T00:00:00Z", dsun: 209.727011, hgln: 0.007972, hglt: -4.420369 },
  { isoDate: "2021-01-15T00:00:00Z", dsun: 209.73897, hgln: -0.002425, hglt: -4.520323 },
  { isoDate: "2021-01-16T00:00:00Z", dsun: 209.751878, hgln: -0.012795, hglt: -4.618938 },
  { isoDate: "2021-01-17T00:00:00Z", dsun: 209.765724, hgln: -0.023104, hglt: -4.716183 },
  { isoDate: "2021-01-18T00:00:00Z", dsun: 209.780497, hgln: -0.033325, hglt: -4.812028 },
  { isoDate: "2021-01-19T00:00:00Z", dsun: 209.796181, hgln: -0.043432, hglt: -4.906444 },
  { isoDate: "2021-01-20T00:00:00Z", dsun: 209.812765, hgln: -0.053401, hglt: -4.999401 },
  { isoDate: "2021-01-21T00:00:00Z", dsun: 209.830235, hgln: -0.063214, hglt: -5.090869 },
  { isoDate: "2021-01-22T00:00:00Z", dsun: 209.848572, hgln: -0.072852, hglt: -5.18082 },
  { isoDate: "2021-01-23T00:00:00Z", dsun: 209.867759, hgln: -0.0823, hglt: -5.269224 },
  { isoDate: "2021-01-24T00:00:00Z", dsun: 209.887781, hgln: -0.091547, hglt: -5.356054 },
  { isoDate: "2021-01-25T00:00:00Z", dsun: 209.908618, hgln: -0.100583, hglt: -5.441281 },
  { isoDate: "2021-01-26T00:00:00Z", dsun: 209.930252, hgln: -0.109401, hglt: -5.524877 },
  { isoDate: "2021-01-27T00:00:00Z", dsun: 209.952664, hgln: -0.117999, hglt: -5.606814 },
  { isoDate: "2021-01-28T00:00:00Z", dsun: 209.975833, hgln: -0.126377, hglt: -5.687066 },
  { isoDate: "2021-01-29T00:00:00Z", dsun: 209.999741, hgln: -0.134539, hglt: -5.765605 },
  { isoDate: "2021-01-30T00:00:00Z", dsun: 210.024365, hgln: -0.14249, hglt: -5.842404 },
  { isoDate: "2021-01-31T00:00:00Z", dsun: 210.049685, hgln: -0.150238, hglt: -5.917439 },
  { isoDate: "2021-02-01T00:00:00Z", dsun: 210.075679, hgln: -0.157792, hglt: -5.990681 },
  { isoDate: "2021-02-02T00:00:00Z", dsun: 210.102326, hgln: -0.165158, hglt: -6.062108 },
  { isoDate: "2021-02-03T00:00:00Z", dsun: 210.129606, hgln: -0.172342, hglt: -6.131693 },
  { isoDate: "2021-02-04T00:00:00Z", dsun: 210.157497, hgln: -0.179346, hglt: -6.199413 },
  { isoDate: "2021-02-05T00:00:00Z", dsun: 210.185979, hgln: -0.186167, hglt: -6.265245 },
  { isoDate: "2021-02-06T00:00:00Z", dsun: 210.215033, hgln: -0.192799, hglt: -6.329164 },
  { isoDate: "2021-02-07T00:00:00Z", dsun: 210.244642, hgln: -0.199231, hglt: -6.391149 },
  { isoDate: "2021-02-08T00:00:00Z", dsun: 210.274789, hgln: -0.205446, hglt: -6.451177 },
  { isoDate: "2021-02-09T00:00:00Z", dsun: 210.305459, hgln: -0.211427, hglt: -6.509229 },
  { isoDate: "2021-02-10T00:00:00Z", dsun: 210.336639, hgln: -0.217152, hglt: -6.565285 },
  { isoDate: "2021-02-11T00:00:00Z", dsun: 210.368319, hgln: -0.222597, hglt: -6.619322 },
  { isoDate: "2021-02-12T00:00:00Z", dsun: 210.40049, hgln: -0.227741, hglt: -6.671323 },
  { isoDate: "2021-02-13T00:00:00Z", dsun: 210.433145, hgln: -0.232561, hglt: -6.72127 },
  { isoDate: "2021-02-14T00:00:00Z", dsun: 210.466277, hgln: -0.237038, hglt: -6.769145 },
  { isoDate: "2021-02-15T00:00:00Z", dsun: 210.49988, hgln: -0.241154, hglt: -6.814931 },
  { isoDate: "2021-02-16T00:00:00Z", dsun: 210.533948, hgln: -0.244895, hglt: -6.858613 },
  { isoDate: "2021-02-17T00:00:00Z", dsun: 210.568473, hgln: -0.248249, hglt: -6.900174 },
  { isoDate: "2021-02-18T00:00:00Z", dsun: 210.603449, hgln: -0.251208, hglt: -6.9396 },
  { isoDate: "2021-02-19T00:00:00Z", dsun: 210.63887, hgln: -0.253764, hglt: -6.976878 },
  { isoDate: "2021-02-20T00:00:00Z", dsun: 210.67473, hgln: -0.255914, hglt: -7.011993 },
  { isoDate: "2021-02-21T00:00:00Z", dsun: 210.711027, hgln: -0.257654, hglt: -7.044934 },
  { isoDate: "2021-02-22T00:00:00Z", dsun: 210.747759, hgln: -0.258985, hglt: -7.07569 },
  { isoDate: "2021-02-23T00:00:00Z", dsun: 210.784924, hgln: -0.25991, hglt: -7.104249 },
  { isoDate: "2021-02-24T00:00:00Z", dsun: 210.822524, hgln: -0.260436, hglt: -7.130602 },
  { isoDate: "2021-02-25T00:00:00Z", dsun: 210.860559, hgln: -0.260571, hglt: -7.15474 },
  { isoDate: "2021-02-26T00:00:00Z", dsun: 210.899033, hgln: -0.260329, hglt: -7.176655 },
  { isoDate: "2021-02-27T00:00:00Z", dsun: 210.937949, hgln: -0.259728, hglt: -7.196339 },
  { isoDate: "2021-02-28T00:00:00Z", dsun: 210.977309, hgln: -0.258789, hglt: -7.213786 },
  { isoDate: "2021-03-01T00:00:00Z", dsun: 211.017118, hgln: -0.257533, hglt: -7.22899 },
  { isoDate: "2021-03-02T00:00:00Z", dsun: 211.05738, hgln: -0.255985, hglt: -7.241947 },
  { isoDate: "2021-03-03T00:00:00Z", dsun: 211.098098, hgln: -0.254167, hglt: -7.252651 },
  { isoDate: "2021-03-04T00:00:00Z", dsun: 211.139277, hgln: -0.252098, hglt: -7.2611 },
  { isoDate: "2021-03-05T00:00:00Z", dsun: 211.180919, hgln: -0.249792, hglt: -7.267292 },
  { isoDate: "2021-03-06T00:00:00Z", dsun: 211.22303, hgln: -0.247261, hglt: -7.271224 },
  { isoDate: "2021-03-07T00:00:00Z", dsun: 211.265613, hgln: -0.244508, hglt: -7.272896 },
  { isoDate: "2021-03-08T00:00:00Z", dsun: 211.308671, hgln: -0.241534, hglt: -7.272308 },
  { isoDate: "2021-03-09T00:00:00Z", dsun: 211.352208, hgln: -0.238337, hglt: -7.269462 },
  { isoDate: "2021-03-10T00:00:00Z", dsun: 211.396229, hgln: -0.234908, hglt: -7.264359 },
  { isoDate: "2021-03-11T00:00:00Z", dsun: 211.44074, hgln: -0.231243, hglt: -7.257 },
  { isoDate: "2021-03-12T00:00:00Z", dsun: 211.485745, hgln: -0.22733, hglt: -7.24739 },
  { isoDate: "2021-03-13T00:00:00Z", dsun: 211.531251, hgln: -0.223159, hglt: -7.235533 },
  { isoDate: "2021-03-14T00:00:00Z", dsun: 211.577263, hgln: -0.21872, hglt: -7.221434 },
  { isoDate: "2021-03-15T00:00:00Z", dsun: 211.623788, hgln: -0.214004, hglt: -7.205098 },
  { isoDate: "2021-03-16T00:00:00Z", dsun: 211.670829, hgln: -0.209004, hglt: -7.186533 },
  { isoDate: "2021-03-17T00:00:00Z", dsun: 211.718391, hgln: -0.203714, hglt: -7.165746 },
  { isoDate: "2021-03-18T00:00:00Z", dsun: 211.766476, hgln: -0.198132, hglt: -7.142745 },
  { isoDate: "2021-03-19T00:00:00Z", dsun: 211.815085, hgln: -0.192256, hglt: -7.117539 },
  { isoDate: "2021-03-20T00:00:00Z", dsun: 211.864219, hgln: -0.186085, hglt: -7.090138 },
  { isoDate: "2021-03-21T00:00:00Z", dsun: 211.913879, hgln: -0.179622, hglt: -7.060553 },
  { isoDate: "2021-03-22T00:00:00Z", dsun: 211.964064, hgln: -0.172872, hglt: -7.028795 },
  { isoDate: "2021-03-23T00:00:00Z", dsun: 212.014776, hgln: -0.16584, hglt: -6.994876 },
  { isoDate: "2021-03-24T00:00:00Z", dsun: 212.066013, hgln: -0.158537, hglt: -6.95881 },
  { isoDate: "2021-03-25T00:00:00Z", dsun: 212.117775, hgln: -0.150975, hglt: -6.92061 },
  { isoDate: "2021-03-26T00:00:00Z", dsun: 212.170063, hgln: -0.14317, hglt: -6.880291 },
  { isoDate: "2021-03-27T00:00:00Z", dsun: 212.222874, hgln: -0.135144, hglt: -6.837867 },
  { isoDate: "2021-03-28T00:00:00Z", dsun: 212.276208, hgln: -0.126922, hglt: -6.793356 },
  { isoDate: "2021-03-29T00:00:00Z", dsun: 212.33006, hgln: -0.118535, hglt: -6.746773 },
  { isoDate: "2021-03-30T00:00:00Z", dsun: 212.384427, hgln: -0.110014, hglt: -6.698136 },
  { isoDate: "2021-03-31T00:00:00Z", dsun: 212.439303, hgln: -0.101394, hglt: -6.647464 },
  { isoDate: "2021-04-01T00:00:00Z", dsun: 212.494684, hgln: -0.092705, hglt: -6.594774 },
  { isoDate: "2021-04-02T00:00:00Z", dsun: 212.55056, hgln: -0.083975, hglt: -6.540087 },
  { isoDate: "2021-04-03T00:00:00Z", dsun: 212.606925, hgln: -0.075228, hglt: -6.483423 },
  { isoDate: "2021-04-04T00:00:00Z", dsun: 212.663767, hgln: -0.066481, hglt: -6.424802 },
  { isoDate: "2021-04-05T00:00:00Z", dsun: 212.721076, hgln: -0.057747, hglt: -6.364247 },
  { isoDate: "2021-04-06T00:00:00Z", dsun: 212.778842, hgln: -0.049033, hglt: -6.301779 },
  { isoDate: "2021-04-07T00:00:00Z", dsun: 212.837053, hgln: -0.040344, hglt: -6.23742 },
  { isoDate: "2021-04-08T00:00:00Z", dsun: 212.895696, hgln: -0.031681, hglt: -6.171196 },
  { isoDate: "2021-04-09T00:00:00Z", dsun: 212.954759, hgln: -0.023044, hglt: -6.103128 },
  { isoDate: "2021-04-10T00:00:00Z", dsun: 213.014229, hgln: -0.014431, hglt: -6.033243 },
  { isoDate: "2021-04-11T00:00:00Z", dsun: 213.074091, hgln: -0.005838, hglt: -5.961564 },
  { isoDate: "2021-04-12T00:00:00Z", dsun: 213.13433, hgln: 0.002736, hglt: -5.888118 },
  { isoDate: "2021-04-13T00:00:00Z", dsun: 213.19493, hgln: 0.011295, hglt: -5.81293 },
  { isoDate: "2021-04-14T00:00:00Z", dsun: 213.255868, hgln: 0.019845, hglt: -5.736027 },
  { isoDate: "2021-04-15T00:00:00Z", dsun: 213.317129, hgln: 0.028382, hglt: -5.657438 },
  { isoDate: "2021-04-16T00:00:00Z", dsun: 213.378688, hgln: 0.03691, hglt: -5.577188 },
  { isoDate: "2021-04-17T00:00:00Z", dsun: 213.440524, hgln: 0.045431, hglt: -5.495308 },
  { isoDate: "2021-04-18T00:00:00Z", dsun: 213.502613, hgln: 0.053943, hglt: -5.411824 },
  { isoDate: "2021-04-19T00:00:00Z", dsun: 213.56493, hgln: 0.062444, hglt: -5.326766 },
  { isoDate: "2021-04-20T00:00:00Z", dsun: 213.62745, hgln: 0.070931, hglt: -5.240164 },
  { isoDate: "2021-04-21T00:00:00Z", dsun: 213.69015, hgln: 0.0794, hglt: -5.152047 },
  { isoDate: "2021-04-22T00:00:00Z", dsun: 213.753004, hgln: 0.087841, hglt: -5.062446 },
  { isoDate: "2021-04-23T00:00:00Z", dsun: 213.815985, hgln: 0.096244, hglt: -4.97139 },
  { isoDate: "2021-04-24T00:00:00Z", dsun: 213.879068, hgln: 0.104594, hglt: -4.878912 },
  { isoDate: "2021-04-25T00:00:00Z", dsun: 213.942224, hgln: 0.11287, hglt: -4.785041 },
  { isoDate: "2021-04-26T00:00:00Z", dsun: 214.005426, hgln: 0.12105, hglt: -4.689811 },
  { isoDate: "2021-04-27T00:00:00Z", dsun: 214.068644, hgln: 0.129103, hglt: -4.593252 },
  { isoDate: "2021-04-28T00:00:00Z", dsun: 214.131848, hgln: 0.136998, hglt: -4.495396 },
  { isoDate: "2021-04-29T00:00:00Z", dsun: 214.195006, hgln: 0.144704, hglt: -4.396277 },
  { isoDate: "2021-04-30T00:00:00Z", dsun: 214.258087, hgln: 0.152191, hglt: -4.295926 },
  { isoDate: "2021-05-01T00:00:00Z", dsun: 214.321059, hgln: 0.159432, hglt: -4.194377 },
  { isoDate: "2021-05-02T00:00:00Z", dsun: 214.383888, hgln: 0.166408, hglt: -4.091663 },
  { isoDate: "2021-05-03T00:00:00Z", dsun: 214.446541, hgln: 0.173103, hglt: -3.987817 },
  { isoDate: "2021-05-04T00:00:00Z", dsun: 214.508986, hgln: 0.179509, hglt: -3.882872 },
  { isoDate: "2021-05-05T00:00:00Z", dsun: 214.571189, hgln: 0.185621, hglt: -3.776862 },
  { isoDate: "2021-05-06T00:00:00Z", dsun: 214.633118, hgln: 0.191436, hglt: -3.669821 },
  { isoDate: "2021-05-07T00:00:00Z", dsun: 214.69474, hgln: 0.196958, hglt: -3.561783 },
  { isoDate: "2021-05-08T00:00:00Z", dsun: 214.756024, hgln: 0.202188, hglt: -3.452782 },
  { isoDate: "2021-05-09T00:00:00Z", dsun: 214.816936, hgln: 0.207133, hglt: -3.342851 },
  { isoDate: "2021-05-10T00:00:00Z", dsun: 214.877443, hgln: 0.211798, hglt: -3.232026 },
  { isoDate: "2021-05-11T00:00:00Z", dsun: 214.937511, hgln: 0.216191, hglt: -3.120341 },
  { isoDate: "2021-05-12T00:00:00Z", dsun: 214.997108, hgln: 0.220321, hglt: -3.007828 },
  { isoDate: "2021-05-13T00:00:00Z", dsun: 215.056194, hgln: 0.224196, hglt: -2.894525 },
  { isoDate: "2021-05-14T00:00:00Z", dsun: 215.114736, hgln: 0.227826, hglt: -2.780465 },
  { isoDate: "2021-05-15T00:00:00Z", dsun: 215.172699, hgln: 0.231219, hglt: -2.665684 },
  { isoDate: "2021-05-16T00:00:00Z", dsun: 215.230048, hgln: 0.234386, hglt: -2.550214 },
  { isoDate: "2021-05-17T00:00:00Z", dsun: 215.28675, hgln: 0.237335, hglt: -2.434092 },
  { isoDate: "2021-05-18T00:00:00Z", dsun: 215.342771, hgln: 0.240071, hglt: -2.317352 },
  { isoDate: "2021-05-19T00:00:00Z", dsun: 215.398081, hgln: 0.242603, hglt: -2.200028 },
  { isoDate: "2021-05-20T00:00:00Z", dsun: 215.452647, hgln: 0.244933, hglt: -2.082155 },
  { isoDate: "2021-05-21T00:00:00Z", dsun: 215.506439, hgln: 0.247063, hglt: -1.963767 },
  { isoDate: "2021-05-22T00:00:00Z", dsun: 215.559427, hgln: 0.24899, hglt: -1.8449 },
  { isoDate: "2021-05-23T00:00:00Z", dsun: 215.611579, hgln: 0.250708, hglt: -1.725589 },
  { isoDate: "2021-05-24T00:00:00Z", dsun: 215.662868, hgln: 0.252207, hglt: -1.605866 },
  { isoDate: "2021-05-25T00:00:00Z", dsun: 215.713264, hgln: 0.253469, hglt: -1.485768 },
  { isoDate: "2021-05-26T00:00:00Z", dsun: 215.762738, hgln: 0.254474, hglt: -1.365329 },
  { isoDate: "2021-05-27T00:00:00Z", dsun: 215.811262, hgln: 0.255198, hglt: -1.244582 },
  { isoDate: "2021-05-28T00:00:00Z", dsun: 215.858808, hgln: 0.255617, hglt: -1.123563 },
  { isoDate: "2021-05-29T00:00:00Z", dsun: 215.905348, hgln: 0.255709, hglt: -1.002306 },
  { isoDate: "2021-05-30T00:00:00Z", dsun: 215.950857, hgln: 0.255455, hglt: -0.880844 },
  { isoDate: "2021-05-31T00:00:00Z", dsun: 215.995309, hgln: 0.254842, hglt: -0.759213 },
  { isoDate: "2021-06-01T00:00:00Z", dsun: 216.038681, hgln: 0.253863, hglt: -0.637445 },
  { isoDate: "2021-06-02T00:00:00Z", dsun: 216.080949, hgln: 0.252513, hglt: -0.515576 },
  { isoDate: "2021-06-03T00:00:00Z", dsun: 216.122094, hgln: 0.250793, hglt: -0.393638 },
  { isoDate: "2021-06-04T00:00:00Z", dsun: 216.162096, hgln: 0.248709, hglt: -0.271667 },
  { isoDate: "2021-06-05T00:00:00Z", dsun: 216.200937, hgln: 0.246266, hglt: -0.149694 },
  { isoDate: "2021-06-06T00:00:00Z", dsun: 216.238602, hgln: 0.243473, hglt: -0.027755 },
  { isoDate: "2021-06-07T00:00:00Z", dsun: 216.275076, hgln: 0.24034, hglt: 0.094118 },
  { isoDate: "2021-06-08T00:00:00Z", dsun: 216.310344, hgln: 0.23688, hglt: 0.215891 },
  { isoDate: "2021-06-09T00:00:00Z", dsun: 216.34439, hgln: 0.233104, hglt: 0.33753 },
  { isoDate: "2021-06-10T00:00:00Z", dsun: 216.377205, hgln: 0.229028, hglt: 0.459004 },
  { isoDate: "2021-06-11T00:00:00Z", dsun: 216.408772, hgln: 0.224668, hglt: 0.580278 },
  { isoDate: "2021-06-12T00:00:00Z", dsun: 216.439079, hgln: 0.22004, hglt: 0.70132 },
  { isoDate: "2021-06-13T00:00:00Z", dsun: 216.468116, hgln: 0.215162, hglt: 0.822097 },
  { isoDate: "2021-06-14T00:00:00Z", dsun: 216.495872, hgln: 0.210052, hglt: 0.942578 },
  { isoDate: "2021-06-15T00:00:00Z", dsun: 216.522341, hgln: 0.204727, hglt: 1.062729 },
  { isoDate: "2021-06-16T00:00:00Z", dsun: 216.547518, hgln: 0.199203, hglt: 1.182518 },
  { isoDate: "2021-06-17T00:00:00Z", dsun: 216.571401, hgln: 0.193495, hglt: 1.301914 },
  { isoDate: "2021-06-18T00:00:00Z", dsun: 216.593989, hgln: 0.187618, hglt: 1.420884 },
  { isoDate: "2021-06-19T00:00:00Z", dsun: 216.615281, hgln: 0.18158, hglt: 1.539398 },
  { isoDate: "2021-06-20T00:00:00Z", dsun: 216.635282, hgln: 0.175388, hglt: 1.657422 },
  { isoDate: "2021-06-21T00:00:00Z", dsun: 216.653994, hgln: 0.169045, hglt: 1.774926 },
  { isoDate: "2021-06-22T00:00:00Z", dsun: 216.671421, hgln: 0.162547, hglt: 1.891879 },
  { isoDate: "2021-06-23T00:00:00Z", dsun: 216.68757, hgln: 0.155887, hglt: 2.00825 },
  { isoDate: "2021-06-24T00:00:00Z", dsun: 216.702446, hgln: 0.149053, hglt: 2.124008 },
  { isoDate: "2021-06-25T00:00:00Z", dsun: 216.716055, hgln: 0.142031, hglt: 2.239122 },
  { isoDate: "2021-06-26T00:00:00Z", dsun: 216.728406, hgln: 0.134806, hglt: 2.353563 },
  { isoDate: "2021-06-27T00:00:00Z", dsun: 216.739504, hgln: 0.127366, hglt: 2.4673 },
  { isoDate: "2021-06-28T00:00:00Z", dsun: 216.749359, hgln: 0.119701, hglt: 2.580304 },
  { isoDate: "2021-06-29T00:00:00Z", dsun: 216.757978, hgln: 0.111807, hglt: 2.692546 },
  { isoDate: "2021-06-30T00:00:00Z", dsun: 216.765369, hgln: 0.103685, hglt: 2.803995 },
  { isoDate: "2021-07-01T00:00:00Z", dsun: 216.771543, hgln: 0.095341, hglt: 2.914625 },
  { isoDate: "2021-07-02T00:00:00Z", dsun: 216.776508, hgln: 0.086783, hglt: 3.024405 },
  { isoDate: "2021-07-03T00:00:00Z", dsun: 216.780274, hgln: 0.078024, hglt: 3.133308 },
  { isoDate: "2021-07-04T00:00:00Z", dsun: 216.782852, hgln: 0.069079, hglt: 3.241305 },
  { isoDate: "2021-07-05T00:00:00Z", dsun: 216.784253, hgln: 0.059968, hglt: 3.34837 },
  { isoDate: "2021-07-06T00:00:00Z", dsun: 216.78449, hgln: 0.050709, hglt: 3.454475 },
  { isoDate: "2021-07-07T00:00:00Z", dsun: 216.783573, hgln: 0.041324, hglt: 3.559592 },
  { isoDate: "2021-07-08T00:00:00Z", dsun: 216.781515, hgln: 0.031838, hglt: 3.663695 },
  { isoDate: "2021-07-09T00:00:00Z", dsun: 216.778325, hgln: 0.022274, hglt: 3.766758 },
  { isoDate: "2021-07-10T00:00:00Z", dsun: 216.774014, hgln: 0.01266, hglt: 3.868754 },
  { isoDate: "2021-07-11T00:00:00Z", dsun: 216.768585, hgln: 0.003022, hglt: 3.969657 },
  { isoDate: "2021-07-12T00:00:00Z", dsun: 216.76204, hgln: -0.006611, hglt: 4.069443 },
  { isoDate: "2021-07-13T00:00:00Z", dsun: 216.754378, hgln: -0.01621, hglt: 4.168087 },
  { isoDate: "2021-07-14T00:00:00Z", dsun: 216.745591, hgln: -0.025747, hglt: 4.265563 },
  { isoDate: "2021-07-15T00:00:00Z", dsun: 216.735685, hgln: -0.035193, hglt: 4.361848 },
  { isoDate: "2021-07-16T00:00:00Z", dsun: 216.724649, hgln: -0.044523, hglt: 4.456919 },
  { isoDate: "2021-07-17T00:00:00Z", dsun: 216.712481, hgln: -0.053713, hglt: 4.55075 },
  { isoDate: "2021-07-18T00:00:00Z", dsun: 216.699177, hgln: -0.062744, hglt: 4.643319 },
  { isoDate: "2021-07-19T00:00:00Z", dsun: 216.684732, hgln: -0.071602, hglt: 4.734603 },
  { isoDate: "2021-07-20T00:00:00Z", dsun: 216.669142, hgln: -0.080275, hglt: 4.824579 },
  { isoDate: "2021-07-21T00:00:00Z", dsun: 216.652404, hgln: -0.088761, hglt: 4.913224 },
  { isoDate: "2021-07-22T00:00:00Z", dsun: 216.634513, hgln: -0.097061, hglt: 5.000516 },
  { isoDate: "2021-07-23T00:00:00Z", dsun: 216.615463, hgln: -0.105181, hglt: 5.086433 },
  { isoDate: "2021-07-24T00:00:00Z", dsun: 216.595251, hgln: -0.11313, hglt: 5.170953 },
  { isoDate: "2021-07-25T00:00:00Z", dsun: 216.57387, hgln: -0.120919, hglt: 5.254054 },
  { isoDate: "2021-07-26T00:00:00Z", dsun: 216.551313, hgln: -0.128557, hglt: 5.335716 },
  { isoDate: "2021-07-27T00:00:00Z", dsun: 216.527576, hgln: -0.136053, hglt: 5.415916 },
  { isoDate: "2021-07-28T00:00:00Z", dsun: 216.502652, hgln: -0.143411, hglt: 5.494635 },
  { isoDate: "2021-07-29T00:00:00Z", dsun: 216.476534, hgln: -0.150634, hglt: 5.571851 },
  { isoDate: "2021-07-30T00:00:00Z", dsun: 216.449218, hgln: -0.15772, hglt: 5.647544 },
  { isoDate: "2021-07-31T00:00:00Z", dsun: 216.420697, hgln: -0.164665, hglt: 5.721693 },
  { isoDate: "2021-08-01T00:00:00Z", dsun: 216.390969, hgln: -0.171465, hglt: 5.79428 },
  { isoDate: "2021-08-02T00:00:00Z", dsun: 216.360029, hgln: -0.178111, hglt: 5.865284 },
  { isoDate: "2021-08-03T00:00:00Z", dsun: 216.327876, hgln: -0.184593, hglt: 5.934686 },
  { isoDate: "2021-08-04T00:00:00Z", dsun: 216.29451, hgln: -0.1909, hglt: 6.002466 },
  { isoDate: "2021-08-05T00:00:00Z", dsun: 216.259932, hgln: -0.197019, hglt: 6.068606 },
  { isoDate: "2021-08-06T00:00:00Z", dsun: 216.224145, hgln: -0.202935, hglt: 6.133087 },
  { isoDate: "2021-08-07T00:00:00Z", dsun: 216.187156, hgln: -0.208632, hglt: 6.195889 },
  { isoDate: "2021-08-08T00:00:00Z", dsun: 216.148971, hgln: -0.214091, hglt: 6.256996 },
  { isoDate: "2021-08-09T00:00:00Z", dsun: 216.109601, hgln: -0.219292, hglt: 6.316389 },
  { isoDate: "2021-08-10T00:00:00Z", dsun: 216.069056, hgln: -0.224215, hglt: 6.37405 },
  { isoDate: "2021-08-11T00:00:00Z", dsun: 216.027351, hgln: -0.228839, hglt: 6.429959 },
  { isoDate: "2021-08-12T00:00:00Z", dsun: 215.984493, hgln: -0.233145, hglt: 6.484105 },
  { isoDate: "2021-08-13T00:00:00Z", dsun: 215.940497, hgln: -0.237114, hglt: 6.536467 },
  { isoDate: "2021-08-14T00:00:00Z", dsun: 215.895375, hgln: -0.240729, hglt: 6.58703 },
  { isoDate: "2021-08-15T00:00:00Z", dsun: 215.849141, hgln: -0.24398, hglt: 6.635777 },
  { isoDate: "2021-08-16T00:00:00Z", dsun: 215.801809, hgln: -0.246857, hglt: 6.682692 },
  { isoDate: "2021-08-17T00:00:00Z", dsun: 215.753395, hgln: -0.249358, hglt: 6.72776 },
  { isoDate: "2021-08-18T00:00:00Z", dsun: 215.70392, hgln: -0.251483, hglt: 6.770965 },
  { isoDate: "2021-08-19T00:00:00Z", dsun: 215.653403, hgln: -0.253241, hglt: 6.812293 },
  { isoDate: "2021-08-20T00:00:00Z", dsun: 215.601867, hgln: -0.254642, hglt: 6.851729 },
  { isoDate: "2021-08-21T00:00:00Z", dsun: 215.549336, hgln: -0.255702, hglt: 6.889259 },
  { isoDate: "2021-08-22T00:00:00Z", dsun: 215.495834, hgln: -0.256438, hglt: 6.92487 },
  { isoDate: "2021-08-23T00:00:00Z", dsun: 215.441388, hgln: -0.256869, hglt: 6.958549 },
  { isoDate: "2021-08-24T00:00:00Z", dsun: 215.386025, hgln: -0.257013, hglt: 6.990282 },
  { isoDate: "2021-08-25T00:00:00Z", dsun: 215.329773, hgln: -0.256887, hglt: 7.020058 },
  { isoDate: "2021-08-26T00:00:00Z", dsun: 215.272659, hgln: -0.256506, hglt: 7.047864 },
  { isoDate: "2021-08-27T00:00:00Z", dsun: 215.214714, hgln: -0.255882, hglt: 7.073689 },
  { isoDate: "2021-08-28T00:00:00Z", dsun: 215.155965, hgln: -0.255025, hglt: 7.097521 },
  { isoDate: "2021-08-29T00:00:00Z", dsun: 215.096444, hgln: -0.253943, hglt: 7.119351 },
  { isoDate: "2021-08-30T00:00:00Z", dsun: 215.036182, hgln: -0.252641, hglt: 7.139169 },
  { isoDate: "2021-08-31T00:00:00Z", dsun: 214.975208, hgln: -0.251122, hglt: 7.156964 },
  { isoDate: "2021-09-01T00:00:00Z", dsun: 214.913556, hgln: -0.249388, hglt: 7.172728 },
  { isoDate: "2021-09-02T00:00:00Z", dsun: 214.851257, hgln: -0.247438, hglt: 7.186452 },
  { isoDate: "2021-09-03T00:00:00Z", dsun: 214.788345, hgln: -0.24527, hglt: 7.198128 },
  { isoDate: "2021-09-04T00:00:00Z", dsun: 214.724853, hgln: -0.24288, hglt: 7.207749 },
  { isoDate: "2021-09-05T00:00:00Z", dsun: 214.660818, hgln: -0.240259, hglt: 7.215309 },
  { isoDate: "2021-09-06T00:00:00Z", dsun: 214.596274, hgln: -0.237397, hglt: 7.2208 },
  { isoDate: "2021-09-07T00:00:00Z", dsun: 214.531259, hgln: -0.234281, hglt: 7.224217 },
  { isoDate: "2021-09-08T00:00:00Z", dsun: 214.46581, hgln: -0.230899, hglt: 7.225555 },
  { isoDate: "2021-09-09T00:00:00Z", dsun: 214.399964, hgln: -0.227233, hglt: 7.22481 },
  { isoDate: "2021-09-10T00:00:00Z", dsun: 214.333757, hgln: -0.223269, hglt: 7.221978 },
  { isoDate: "2021-09-11T00:00:00Z", dsun: 214.267227, hgln: -0.218994, hglt: 7.217054 },
  { isoDate: "2021-09-12T00:00:00Z", dsun: 214.200408, hgln: -0.214398, hglt: 7.210037 },
  { isoDate: "2021-09-13T00:00:00Z", dsun: 214.133334, hgln: -0.209476, hglt: 7.200924 },
  { isoDate: "2021-09-14T00:00:00Z", dsun: 214.066043, hgln: -0.204227, hglt: 7.189715 },
  { isoDate: "2021-09-15T00:00:00Z", dsun: 213.998561, hgln: -0.198651, hglt: 7.176405 },
  { isoDate: "2021-09-16T00:00:00Z", dsun: 213.930939, hgln: -0.192767, hglt: 7.160999 },
  { isoDate: "2021-09-17T00:00:00Z", dsun: 213.863207, hgln: -0.186583, hglt: 7.143495 },
  { isoDate: "2021-09-18T00:00:00Z", dsun: 213.7954, hgln: -0.180118, hglt: 7.123895 },
  { isoDate: "2021-09-19T00:00:00Z", dsun: 213.727556, hgln: -0.173394, hglt: 7.102201 },
  { isoDate: "2021-09-20T00:00:00Z", dsun: 213.65971, hgln: -0.166433, hglt: 7.078414 },
  { isoDate: "2021-09-21T00:00:00Z", dsun: 213.591899, hgln: -0.159261, hglt: 7.052539 },
  { isoDate: "2021-09-22T00:00:00Z", dsun: 213.524157, hgln: -0.151901, hglt: 7.02458 },
  { isoDate: "2021-09-23T00:00:00Z", dsun: 213.456519, hgln: -0.144377, hglt: 6.99454 },
  { isoDate: "2021-09-24T00:00:00Z", dsun: 213.389019, hgln: -0.136711, hglt: 6.962425 },
  { isoDate: "2021-09-25T00:00:00Z", dsun: 213.321689, hgln: -0.128925, hglt: 6.928242 },
  { isoDate: "2021-09-26T00:00:00Z", dsun: 213.254562, hgln: -0.121038, hglt: 6.891997 },
  { isoDate: "2021-09-27T00:00:00Z", dsun: 213.18767, hgln: -0.113066, hglt: 6.853697 },
  { isoDate: "2021-09-28T00:00:00Z", dsun: 213.121042, hgln: -0.105025, hglt: 6.813351 },
  { isoDate: "2021-09-29T00:00:00Z", dsun: 213.054709, hgln: -0.096929, hglt: 6.770967 },
  { isoDate: "2021-09-30T00:00:00Z", dsun: 212.988699, hgln: -0.088789, hglt: 6.726555 },
  { isoDate: "2021-10-01T00:00:00Z", dsun: 212.923041, hgln: -0.080615, hglt: 6.680125 },
  { isoDate: "2021-10-02T00:00:00Z", dsun: 212.857764, hgln: -0.072414, hglt: 6.631689 },
  { isoDate: "2021-10-03T00:00:00Z", dsun: 212.792894, hgln: -0.06419, hglt: 6.581258 },
  { isoDate: "2021-10-04T00:00:00Z", dsun: 212.72846, hgln: -0.055944, hglt: 6.528845 },
  { isoDate: "2021-10-05T00:00:00Z", dsun: 212.664488, hgln: -0.047675, hglt: 6.474463 },
  { isoDate: "2021-10-06T00:00:00Z", dsun: 212.601003, hgln: -0.039377, hglt: 6.418125 },
  { isoDate: "2021-10-07T00:00:00Z", dsun: 212.538031, hgln: -0.031039, hglt: 6.359848 },
  { isoDate: "2021-10-08T00:00:00Z", dsun: 212.475594, hgln: -0.022653, hglt: 6.299645 },
  { isoDate: "2021-10-09T00:00:00Z", dsun: 212.413712, hgln: -0.014207, hglt: 6.237533 },
  { isoDate: "2021-10-10T00:00:00Z", dsun: 212.352406, hgln: -0.005692, hglt: 6.173529 },
  { isoDate: "2021-10-11T00:00:00Z", dsun: 212.291692, hgln: 0.002898, hglt: 6.107649 },
  { isoDate: "2021-10-12T00:00:00Z", dsun: 212.231588, hgln: 0.011564, hglt: 6.039913 },
  { isoDate: "2021-10-13T00:00:00Z", dsun: 212.17211, hgln: 0.020304, hglt: 5.970339 },
  { isoDate: "2021-10-14T00:00:00Z", dsun: 212.113276, hgln: 0.029104, hglt: 5.898946 },
  { isoDate: "2021-10-15T00:00:00Z", dsun: 212.055102, hgln: 0.037952, hglt: 5.825755 },
  { isoDate: "2021-10-16T00:00:00Z", dsun: 211.997604, hgln: 0.046831, hglt: 5.750786 },
  { isoDate: "2021-10-17T00:00:00Z", dsun: 211.940792, hgln: 0.055719, hglt: 5.67406 },
  { isoDate: "2021-10-18T00:00:00Z", dsun: 211.884679, hgln: 0.064595, hglt: 5.595601 },
  { isoDate: "2021-10-19T00:00:00Z", dsun: 211.829277, hgln: 0.073432, hglt: 5.51543 },
  { isoDate: "2021-10-20T00:00:00Z", dsun: 211.774596, hgln: 0.082206, hglt: 5.433572 },
  { isoDate: "2021-10-21T00:00:00Z", dsun: 211.720643, hgln: 0.090891, hglt: 5.35005 },
  { isoDate: "2021-10-22T00:00:00Z", dsun: 211.667428, hgln: 0.099462, hglt: 5.264888 },
  { isoDate: "2021-10-23T00:00:00Z", dsun: 211.614955, hgln: 0.107895, hglt: 5.178113 },
  { isoDate: "2021-10-24T00:00:00Z", dsun: 211.563229, hgln: 0.116167, hglt: 5.089749 },
  { isoDate: "2021-10-25T00:00:00Z", dsun: 211.512255, hgln: 0.124257, hglt: 4.999824 },
  { isoDate: "2021-10-26T00:00:00Z", dsun: 211.462036, hgln: 0.132146, hglt: 4.908364 },
  { isoDate: "2021-10-27T00:00:00Z", dsun: 211.412572, hgln: 0.139815, hglt: 4.815396 },
  { isoDate: "2021-10-28T00:00:00Z", dsun: 211.363867, hgln: 0.14725, hglt: 4.720949 },
  { isoDate: "2021-10-29T00:00:00Z", dsun: 211.31592, hgln: 0.154435, hglt: 4.625051 },
  { isoDate: "2021-10-30T00:00:00Z", dsun: 211.268732, hgln: 0.161361, hglt: 4.527731 },
  { isoDate: "2021-10-31T00:00:00Z", dsun: 211.222304, hgln: 0.168019, hglt: 4.429019 },
  { isoDate: "2021-11-01T00:00:00Z", dsun: 211.176634, hgln: 0.174403, hglt: 4.328945 },
  { isoDate: "2021-11-02T00:00:00Z", dsun: 211.131723, hgln: 0.180512, hglt: 4.227539 },
  { isoDate: "2021-11-03T00:00:00Z", dsun: 211.08757, hgln: 0.18635, hglt: 4.124833 },
  { isoDate: "2021-11-04T00:00:00Z", dsun: 211.044171, hgln: 0.191926, hglt: 4.020857 },
  { isoDate: "2021-11-05T00:00:00Z", dsun: 211.001522, hgln: 0.19725, hglt: 3.915645 },
  { isoDate: "2021-11-06T00:00:00Z", dsun: 210.959618, hgln: 0.20234, hglt: 3.809228 },
  { isoDate: "2021-11-07T00:00:00Z", dsun: 210.918452, hgln: 0.207212, hglt: 3.701639 },
  { isoDate: "2021-11-08T00:00:00Z", dsun: 210.878018, hgln: 0.211882, hglt: 3.592911 },
  { isoDate: "2021-11-09T00:00:00Z", dsun: 210.838309, hgln: 0.21636, hglt: 3.483077 },
  { isoDate: "2021-11-10T00:00:00Z", dsun: 210.799321, hgln: 0.220653, hglt: 3.372172 },
  { isoDate: "2021-11-11T00:00:00Z", dsun: 210.761043, hgln: 0.224764, hglt: 3.260229 },
  { isoDate: "2021-11-12T00:00:00Z", dsun: 210.723472, hgln: 0.228689, hglt: 3.147282 },
  { isoDate: "2021-11-13T00:00:00Z", dsun: 210.686602, hgln: 0.232419, hglt: 3.033367 },
  { isoDate: "2021-11-14T00:00:00Z", dsun: 210.650426, hgln: 0.235946, hglt: 2.918519 },
  { isoDate: "2021-11-15T00:00:00Z", dsun: 210.614938, hgln: 0.239256, hglt: 2.802773 },
  { isoDate: "2021-11-16T00:00:00Z", dsun: 210.580132, hgln: 0.242332, hglt: 2.686165 },
  { isoDate: "2021-11-17T00:00:00Z", dsun: 210.546, hgln: 0.245161, hglt: 2.568731 },
  { isoDate: "2021-11-18T00:00:00Z", dsun: 210.512535, hgln: 0.247723, hglt: 2.450507 },
  { isoDate: "2021-11-19T00:00:00Z", dsun: 210.47973, hgln: 0.250003, hglt: 2.33153 },
  { isoDate: "2021-11-20T00:00:00Z", dsun: 210.447577, hgln: 0.251983, hglt: 2.211836 },
  { isoDate: "2021-11-21T00:00:00Z", dsun: 210.416069, hgln: 0.253645, hglt: 2.091461 },
  { isoDate: "2021-11-22T00:00:00Z", dsun: 210.385198, hgln: 0.254975, hglt: 1.970444 },
  { isoDate: "2021-11-23T00:00:00Z", dsun: 210.354956, hgln: 0.255957, hglt: 1.848821 },
  { isoDate: "2021-11-24T00:00:00Z", dsun: 210.325338, hgln: 0.256578, hglt: 1.726629 },
  { isoDate: "2021-11-25T00:00:00Z", dsun: 210.296337, hgln: 0.256825, hglt: 1.603906 },
  { isoDate: "2021-11-26T00:00:00Z", dsun: 210.267947, hgln: 0.256687, hglt: 1.48069 },
  { isoDate: "2021-11-27T00:00:00Z", dsun: 210.240164, hgln: 0.256158, hglt: 1.357017 },
  { isoDate: "2021-11-28T00:00:00Z", dsun: 210.212984, hgln: 0.25523, hglt: 1.232926 },
  { isoDate: "2021-11-29T00:00:00Z", dsun: 210.186406, hgln: 0.253901, hglt: 1.108454 },
  { isoDate: "2021-11-30T00:00:00Z", dsun: 210.160428, hgln: 0.25217, hglt: 0.98364 },
  { isoDate: "2021-12-01T00:00:00Z", dsun: 210.13505, hgln: 0.250042, hglt: 0.858521 },
  { isoDate: "2021-12-02T00:00:00Z", dsun: 210.110273, hgln: 0.247528, hglt: 0.733135 },
  { isoDate: "2021-12-03T00:00:00Z", dsun: 210.086097, hgln: 0.244641, hglt: 0.60752 },
  { isoDate: "2021-12-04T00:00:00Z", dsun: 210.062521, hgln: 0.241402, hglt: 0.481715 },
  { isoDate: "2021-12-05T00:00:00Z", dsun: 210.039544, hgln: 0.237836, hglt: 0.355757 },
  { isoDate: "2021-12-06T00:00:00Z", dsun: 210.017167, hgln: 0.233968, hglt: 0.229683 },
  { isoDate: "2021-12-07T00:00:00Z", dsun: 209.995391, hgln: 0.229822, hglt: 0.103532 },
  { isoDate: "2021-12-08T00:00:00Z", dsun: 209.974218, hgln: 0.225419, hglt: -0.022658 },
  { isoDate: "2021-12-09T00:00:00Z", dsun: 209.953654, hgln: 0.220777, hglt: -0.148851 },
  { isoDate: "2021-12-10T00:00:00Z", dsun: 209.933706, hgln: 0.215907, hglt: -0.275008 },
  { isoDate: "2021-12-11T00:00:00Z", dsun: 209.914381, hgln: 0.210814, hglt: -0.401093 },
  { isoDate: "2021-12-12T00:00:00Z", dsun: 209.895691, hgln: 0.205503, hglt: -0.527067 },
  { isoDate: "2021-12-13T00:00:00Z", dsun: 209.877646, hgln: 0.199974, hglt: -0.652894 },
  { isoDate: "2021-12-14T00:00:00Z", dsun: 209.860258, hgln: 0.194224, hglt: -0.778536 },
  { isoDate: "2021-12-15T00:00:00Z", dsun: 209.843535, hgln: 0.188248, hglt: -0.903957 },
  { isoDate: "2021-12-16T00:00:00Z", dsun: 209.827501, hgln: 0.182046, hglt: -1.02912 },
  { isoDate: "2021-12-17T00:00:00Z", dsun: 209.812166, hgln: 0.175609, hglt: -1.153989 },
  { isoDate: "2021-12-18T00:00:00Z", dsun: 209.797544, hgln: 0.168933, hglt: -1.278527 },
  { isoDate: "2021-12-19T00:00:00Z", dsun: 209.783651, hgln: 0.162011, hglt: -1.402697 },
  { isoDate: "2021-12-20T00:00:00Z", dsun: 209.770501, hgln: 0.154838, hglt: -1.526465 },
  { isoDate: "2021-12-21T00:00:00Z", dsun: 209.758112, hgln: 0.147408, hglt: -1.649794 },
  { isoDate: "2021-12-22T00:00:00Z", dsun: 209.746498, hgln: 0.139716, hglt: -1.772648 },
  { isoDate: "2021-12-23T00:00:00Z", dsun: 209.735675, hgln: 0.131759, hglt: -1.894993 },
  { isoDate: "2021-12-24T00:00:00Z", dsun: 209.725661, hgln: 0.123536, hglt: -2.016792 },
  { isoDate: "2021-12-25T00:00:00Z", dsun: 209.716469, hgln: 0.115047, hglt: -2.138011 },
  { isoDate: "2021-12-26T00:00:00Z", dsun: 209.708117, hgln: 0.106295, hglt: -2.258614 },
  { isoDate: "2021-12-27T00:00:00Z", dsun: 209.70062, hgln: 0.097284, hglt: -2.378568 },
  { isoDate: "2021-12-28T00:00:00Z", dsun: 209.693993, hgln: 0.088025, hglt: -2.497836 },
  { isoDate: "2021-12-29T00:00:00Z", dsun: 209.688254, hgln: 0.07853, hglt: -2.616386 },
  { isoDate: "2021-12-30T00:00:00Z", dsun: 209.683417, hgln: 0.068819, hglt: -2.734182 },
  { isoDate: "2021-12-31T00:00:00Z", dsun: 209.679499, hgln: 0.058914, hglt: -2.851191 },
  { isoDate: "2022-01-01T00:00:00Z", dsun: 209.676516, hgln: 0.048847, hglt: -2.967379 },
  { isoDate: "2022-01-02T00:00:00Z", dsun: 209.67448, hgln: 0.03865, hglt: -3.082711 },
  { isoDate: "2022-01-03T00:00:00Z", dsun: 209.673402, hgln: 0.028364, hglt: -3.197154 },
  { isoDate: "2022-01-04T00:00:00Z", dsun: 209.673288, hgln: 0.018026, hglt: -3.310674 },
  { isoDate: "2022-01-05T00:00:00Z", dsun: 209.674135, hgln: 0.007678, hglt: -3.423238 },
  { isoDate: "2022-01-06T00:00:00Z", dsun: 209.675946, hgln: -0.002647, hglt: -3.53481 },
  { isoDate: "2022-01-07T00:00:00Z", dsun: 209.678714, hgln: -0.012915, hglt: -3.645356 },
  { isoDate: "2022-01-08T00:00:00Z", dsun: 209.682432, hgln: -0.023099, hglt: -3.754844 },
  { isoDate: "2022-01-09T00:00:00Z", dsun: 209.687094, hgln: -0.033176, hglt: -3.863238 },
  { isoDate: "2022-01-10T00:00:00Z", dsun: 209.692691, hgln: -0.043126, hglt: -3.970506 },
  { isoDate: "2022-01-11T00:00:00Z", dsun: 209.699215, hgln: -0.052932, hglt: -4.076614 },
  { isoDate: "2022-01-12T00:00:00Z", dsun: 209.706655, hgln: -0.062583, hglt: -4.181529 },
  { isoDate: "2022-01-13T00:00:00Z", dsun: 209.715002, hgln: -0.072067, hglt: -4.285216 },
  { isoDate: "2022-01-14T00:00:00Z", dsun: 209.724241, hgln: -0.081375, hglt: -4.387644 },
  { isoDate: "2022-01-15T00:00:00Z", dsun: 209.734362, hgln: -0.090502, hglt: -4.488779 },
  { isoDate: "2022-01-16T00:00:00Z", dsun: 209.745347, hgln: -0.099443, hglt: -4.588587 },
  { isoDate: "2022-01-17T00:00:00Z", dsun: 209.757183, hgln: -0.108197, hglt: -4.687037 },
  { isoDate: "2022-01-18T00:00:00Z", dsun: 209.769851, hgln: -0.116762, hglt: -4.784096 },
  { isoDate: "2022-01-19T00:00:00Z", dsun: 209.783336, hgln: -0.12514, hglt: -4.879731 },
  { isoDate: "2022-01-20T00:00:00Z", dsun: 209.797618, hgln: -0.133334, hglt: -4.97391 },
  { isoDate: "2022-01-21T00:00:00Z", dsun: 209.81268, hgln: -0.141347, hglt: -5.066601 },
  { isoDate: "2022-01-22T00:00:00Z", dsun: 209.828502, hgln: -0.149182, hglt: -5.157774 },
  { isoDate: "2022-01-23T00:00:00Z", dsun: 209.845066, hgln: -0.156842, hglt: -5.247395 },
  { isoDate: "2022-01-24T00:00:00Z", dsun: 209.862353, hgln: -0.16433, hglt: -5.335436 },
  { isoDate: "2022-01-25T00:00:00Z", dsun: 209.880344, hgln: -0.171645, hglt: -5.421865 },
  { isoDate: "2022-01-26T00:00:00Z", dsun: 209.899021, hgln: -0.178785, hglt: -5.506652 },
  { isoDate: "2022-01-27T00:00:00Z", dsun: 209.918367, hgln: -0.185743, hglt: -5.589768 },
  { isoDate: "2022-01-28T00:00:00Z", dsun: 209.938367, hgln: -0.19251, hglt: -5.671184 },
  { isoDate: "2022-01-29T00:00:00Z", dsun: 209.959006, hgln: -0.199071, hglt: -5.750872 },
  { isoDate: "2022-01-30T00:00:00Z", dsun: 209.980271, hgln: -0.205406, hglt: -5.828803 },
  { isoDate: "2022-01-31T00:00:00Z", dsun: 210.002152, hgln: -0.21149, hglt: -5.904951 },
  { isoDate: "2022-02-01T00:00:00Z", dsun: 210.024641, hgln: -0.217297, hglt: -5.979289 },
  { isoDate: "2022-02-02T00:00:00Z", dsun: 210.04773, hgln: -0.222799, hglt: -6.05179 },
  { isoDate: "2022-02-03T00:00:00Z", dsun: 210.071414, hgln: -0.227971, hglt: -6.12243 },
  { isoDate: "2022-02-04T00:00:00Z", dsun: 210.095688, hgln: -0.232788, hglt: -6.191184 },
  { isoDate: "2022-02-05T00:00:00Z", dsun: 210.120546, hgln: -0.237233, hglt: -6.258026 },
  { isoDate: "2022-02-06T00:00:00Z", dsun: 210.145981, hgln: -0.241291, hglt: -6.322934 },
  { isoDate: "2022-02-07T00:00:00Z", dsun: 210.171989, hgln: -0.244952, hglt: -6.385884 },
  { isoDate: "2022-02-08T00:00:00Z", dsun: 210.198562, hgln: -0.248211, hglt: -6.446854 },
  { isoDate: "2022-02-09T00:00:00Z", dsun: 210.225676, hgln: -0.251063, hglt: -6.505826 },
  { isoDate: "2022-02-10T00:00:00Z", dsun: 210.25337, hgln: -0.253507, hglt: -6.562773 },
  { isoDate: "2022-02-11T00:00:00Z", dsun: 210.281624, hgln: -0.255546, hglt: -6.617677 },
  { isoDate: "2022-02-12T00:00:00Z", dsun: 210.310436, hgln: -0.257184, hglt: -6.67052 },
  { isoDate: "2022-02-13T00:00:00Z", dsun: 210.339808, hgln: -0.258426, hglt: -6.721283 },
  { isoDate: "2022-02-14T00:00:00Z", dsun: 210.369745, hgln: -0.259281, hglt: -6.769948 },
  { isoDate: "2022-02-15T00:00:00Z", dsun: 210.400248, hgln: -0.259761, hglt: -6.816498 },
  { isoDate: "2022-02-16T00:00:00Z", dsun: 210.431322, hgln: -0.259878, hglt: -6.860917 },
  { isoDate: "2022-02-17T00:00:00Z", dsun: 210.462972, hgln: -0.259648, hglt: -6.90319 },
  { isoDate: "2022-02-18T00:00:00Z", dsun: 210.495204, hgln: -0.259089, hglt: -6.943302 },
  { isoDate: "2022-02-19T00:00:00Z", dsun: 210.528022, hgln: -0.258217, hglt: -6.981238 },
  { isoDate: "2022-02-20T00:00:00Z", dsun: 210.561432, hgln: -0.257053, hglt: -7.016987 },
  { isoDate: "2022-02-21T00:00:00Z", dsun: 210.59544, hgln: -0.255613, hglt: -7.050535 },
  { isoDate: "2022-02-22T00:00:00Z", dsun: 210.630051, hgln: -0.253915, hglt: -7.081871 },
  { isoDate: "2022-02-23T00:00:00Z", dsun: 210.66527, hgln: -0.251973, hglt: -7.110985 },
  { isoDate: "2022-02-24T00:00:00Z", dsun: 210.701104, hgln: -0.249796, hglt: -7.137867 },
  { isoDate: "2022-02-25T00:00:00Z", dsun: 210.737557, hgln: -0.247393, hglt: -7.162508 },
  { isoDate: "2022-02-26T00:00:00Z", dsun: 210.774636, hgln: -0.244764, hglt: -7.1849 },
  { isoDate: "2022-02-27T00:00:00Z", dsun: 210.812346, hgln: -0.241907, hglt: -7.205036 },
  { isoDate: "2022-02-28T00:00:00Z", dsun: 210.850694, hgln: -0.238814, hglt: -7.222909 },
  { isoDate: "2022-03-01T00:00:00Z", dsun: 210.889686, hgln: -0.235475, hglt: -7.238514 },
  { isoDate: "2022-03-02T00:00:00Z", dsun: 210.929329, hgln: -0.231874, hglt: -7.251846 },
  { isoDate: "2022-03-03T00:00:00Z", dsun: 210.969633, hgln: -0.227998, hglt: -7.262902 },
  { isoDate: "2022-03-04T00:00:00Z", dsun: 211.010604, hgln: -0.223833, hglt: -7.271678 },
  { isoDate: "2022-03-05T00:00:00Z", dsun: 211.052249, hgln: -0.219366, hglt: -7.278172 },
  { isoDate: "2022-03-06T00:00:00Z", dsun: 211.094574, hgln: -0.214588, hglt: -7.282384 },
  { isoDate: "2022-03-07T00:00:00Z", dsun: 211.137584, hgln: -0.209495, hglt: -7.284312 },
  { isoDate: "2022-03-08T00:00:00Z", dsun: 211.181283, hgln: -0.204084, hglt: -7.283957 },
  { isoDate: "2022-03-09T00:00:00Z", dsun: 211.225662, hgln: -0.198351, hglt: -7.281321 },
  { isoDate: "2022-03-10T00:00:00Z", dsun: 211.270745, hgln: -0.19231, hglt: -7.276406 },
  { isoDate: "2022-03-11T00:00:00Z", dsun: 211.316523, hgln: -0.185962, hglt: -7.269214 },
  { isoDate: "2022-03-12T00:00:00Z", dsun: 211.363, hgln: -0.179315, hglt: -7.259751 },
  { isoDate: "2022-03-13T00:00:00Z", dsun: 211.410176, hgln: -0.17238, hglt: -7.24802 },
  { isoDate: "2022-03-14T00:00:00Z", dsun: 211.458054, hgln: -0.165171, hglt: -7.234028 },
  { isoDate: "2022-03-15T00:00:00Z", dsun: 211.506635, hgln: -0.157703, hglt: -7.21778 },
  { isoDate: "2022-03-16T00:00:00Z", dsun: 211.555921, hgln: -0.149996, hglt: -7.199285 },
  { isoDate: "2022-03-17T00:00:00Z", dsun: 211.605911, hgln: -0.14207, hglt: -7.17855 },
  { isoDate: "2022-03-18T00:00:00Z", dsun: 211.656603, hgln: -0.133951, hglt: -7.155584 },
  { isoDate: "2022-03-19T00:00:00Z", dsun: 211.707998, hgln: -0.125666, hglt: -7.130397 },
  { isoDate: "2022-03-20T00:00:00Z", dsun: 211.760091, hgln: -0.117243, hglt: -7.103 },
  { isoDate: "2022-03-21T00:00:00Z", dsun: 211.812879, hgln: -0.108712, hglt: -7.073405 },
  { isoDate: "2022-03-22T00:00:00Z", dsun: 211.866356, hgln: -0.100101, hglt: -7.041622 },
  { isoDate: "2022-03-23T00:00:00Z", dsun: 211.920516, hgln: -0.091438, hglt: -7.007667 },
  { isoDate: "2022-03-24T00:00:00Z", dsun: 211.975351, hgln: -0.082745, hglt: -6.971552 },
  { isoDate: "2022-03-25T00:00:00Z", dsun: 212.030853, hgln: -0.074041, hglt: -6.933292 },
  { isoDate: "2022-03-26T00:00:00Z", dsun: 212.087011, hgln: -0.065341, hglt: -6.892902 },
  { isoDate: "2022-03-27T00:00:00Z", dsun: 212.143817, hgln: -0.056653, hglt: -6.8504 },
  { isoDate: "2022-03-28T00:00:00Z", dsun: 212.201257, hgln: -0.047983, hglt: -6.805801 },
  { isoDate: "2022-03-29T00:00:00Z", dsun: 212.259323, hgln: -0.03933, hglt: -6.759123 },
  { isoDate: "2022-03-30T00:00:00Z", dsun: 212.318, hgln: -0.030692, hglt: -6.710385 },
  { isoDate: "2022-03-31T00:00:00Z", dsun: 212.377278, hgln: -0.022063, hglt: -6.659606 },
  { isoDate: "2022-04-01T00:00:00Z", dsun: 212.437141, hgln: -0.013437, hglt: -6.606805 },
  { isoDate: "2022-04-02T00:00:00Z", dsun: 212.497575, hgln: -0.004808, hglt: -6.552003 },
  { isoDate: "2022-04-03T00:00:00Z", dsun: 212.558563, hgln: 0.003829, hglt: -6.495221 },
  { isoDate: "2022-04-04T00:00:00Z", dsun: 212.620084, hgln: 0.012479, hglt: -6.436481 },
  { isoDate: "2022-04-05T00:00:00Z", dsun: 212.682119, hgln: 0.021142, hglt: -6.375805 },
  { isoDate: "2022-04-06T00:00:00Z", dsun: 212.744644, hgln: 0.029822, hglt: -6.313216 },
  { isoDate: "2022-04-07T00:00:00Z", dsun: 212.807636, hgln: 0.038511, hglt: -6.248738 },
  { isoDate: "2022-04-08T00:00:00Z", dsun: 212.871065, hgln: 0.047208, hglt: -6.182396 },
  { isoDate: "2022-04-09T00:00:00Z", dsun: 212.934916, hgln: 0.055906, hglt: -6.114214 },
  { isoDate: "2022-04-10T00:00:00Z", dsun: 212.999165, hgln: 0.064598, hglt: -6.044217 },
  { isoDate: "2022-04-11T00:00:00Z", dsun: 213.063786, hgln: 0.073274, hglt: -5.972431 },
  { isoDate: "2022-04-12T00:00:00Z", dsun: 213.128755, hgln: 0.081921, hglt: -5.898883 },
  { isoDate: "2022-04-13T00:00:00Z", dsun: 213.194044, hgln: 0.090525, hglt: -5.823599 },
  { isoDate: "2022-04-14T00:00:00Z", dsun: 213.259626, hgln: 0.099067, hglt: -5.746608 },
  { isoDate: "2022-04-15T00:00:00Z", dsun: 213.325474, hgln: 0.107528, hglt: -5.667936 },
  { isoDate: "2022-04-16T00:00:00Z", dsun: 213.391557, hgln: 0.115883, hglt: -5.587612 },
  { isoDate: "2022-04-17T00:00:00Z", dsun: 213.457846, hgln: 0.124106, hglt: -5.505665 },
  { isoDate: "2022-04-18T00:00:00Z", dsun: 213.524308, hgln: 0.132166, hglt: -5.422124 },
  { isoDate: "2022-04-19T00:00:00Z", dsun: 213.590913, hgln: 0.140035, hglt: -5.337019 },
  { isoDate: "2022-04-20T00:00:00Z", dsun: 213.657626, hgln: 0.147683, hglt: -5.250379 },
  { isoDate: "2022-04-21T00:00:00Z", dsun: 213.724414, hgln: 0.155086, hglt: -5.162235 },
  { isoDate: "2022-04-22T00:00:00Z", dsun: 213.791244, hgln: 0.162221, hglt: -5.072617 },
  { isoDate: "2022-04-23T00:00:00Z", dsun: 213.85808, hgln: 0.169074, hglt: -4.981556 },
  { isoDate: "2022-04-24T00:00:00Z", dsun: 213.924888, hgln: 0.175634, hglt: -4.889084 },
  { isoDate: "2022-04-25T00:00:00Z", dsun: 213.991634, hgln: 0.181896, hglt: -4.795233 },
  { isoDate: "2022-04-26T00:00:00Z", dsun: 214.058285, hgln: 0.187859, hglt: -4.700033 },
  { isoDate: "2022-04-27T00:00:00Z", dsun: 214.124805, hgln: 0.193526, hglt: -4.603517 },
  { isoDate: "2022-04-28T00:00:00Z", dsun: 214.191161, hgln: 0.198903, hglt: -4.505717 },
  { isoDate: "2022-04-29T00:00:00Z", dsun: 214.257319, hgln: 0.203999, hglt: -4.406667 },
  { isoDate: "2022-04-30T00:00:00Z", dsun: 214.323242, hgln: 0.208823, hglt: -4.306398 },
  { isoDate: "2022-05-01T00:00:00Z", dsun: 214.388894, hgln: 0.213385, hglt: -4.204943 },
  { isoDate: "2022-05-02T00:00:00Z", dsun: 214.454239, hgln: 0.217695, hglt: -4.102337 },
  { isoDate: "2022-05-03T00:00:00Z", dsun: 214.519237, hgln: 0.221764, hglt: -3.998611 },
  { isoDate: "2022-05-04T00:00:00Z", dsun: 214.583853, hgln: 0.2256, hglt: -3.8938 },
  { isoDate: "2022-05-05T00:00:00Z", dsun: 214.648049, hgln: 0.229212, hglt: -3.787938 },
  { isoDate: "2022-05-06T00:00:00Z", dsun: 214.711789, hgln: 0.232605, hglt: -3.681057 },
  { isoDate: "2022-05-07T00:00:00Z", dsun: 214.775038, hgln: 0.235786, hglt: -3.573192 },
  { isoDate: "2022-05-08T00:00:00Z", dsun: 214.83776, hgln: 0.238757, hglt: -3.464377 },
  { isoDate: "2022-05-09T00:00:00Z", dsun: 214.899922, hgln: 0.241521, hglt: -3.354645 },
  { isoDate: "2022-05-10T00:00:00Z", dsun: 214.96149, hgln: 0.244078, hglt: -3.244032 },
  { isoDate: "2022-05-11T00:00:00Z", dsun: 215.022656, hgln: 0.24639, hglt: -3.132572 },
  { isoDate: "2022-05-12T00:00:00Z", dsun: 215.082945, hgln: 0.248522, hglt: -3.020298 },
  { isoDate: "2022-05-13T00:00:00Z", dsun: 215.142541, hgln: 0.250431, hglt: -2.907243 },
  { isoDate: "2022-05-14T00:00:00Z", dsun: 215.201412, hgln: 0.252107, hglt: -2.793444 },
  { isoDate: "2022-05-15T00:00:00Z", dsun: 215.259526, hgln: 0.253534, hglt: -2.678935 },
  { isoDate: "2022-05-16T00:00:00Z", dsun: 215.316851, hgln: 0.254691, hglt: -2.563749 },
  { isoDate: "2022-05-17T00:00:00Z", dsun: 215.373356, hgln: 0.255558, hglt: -2.447922 },
  { isoDate: "2022-05-18T00:00:00Z", dsun: 215.429011, hgln: 0.25611, hglt: -2.331488 },
  { isoDate: "2022-05-19T00:00:00Z", dsun: 215.483784, hgln: 0.256326, hglt: -2.21448 },
  { isoDate: "2022-05-20T00:00:00Z", dsun: 215.537648, hgln: 0.256187, hglt: -2.096935 },
  { isoDate: "2022-05-21T00:00:00Z", dsun: 215.590574, hgln: 0.255679, hglt: -1.978885 },
  { isoDate: "2022-05-22T00:00:00Z", dsun: 215.642536, hgln: 0.254794, hglt: -1.860366 },
  { isoDate: "2022-05-23T00:00:00Z", dsun: 215.693508, hgln: 0.253529, hglt: -1.741412 },
  { isoDate: "2022-05-24T00:00:00Z", dsun: 215.743466, hgln: 0.251886, hglt: -1.622056 },
  { isoDate: "2022-05-25T00:00:00Z", dsun: 215.79239, hgln: 0.249872, hglt: -1.502334 },
  { isoDate: "2022-05-26T00:00:00Z", dsun: 215.840259, hgln: 0.247497, hglt: -1.38228 },
  { isoDate: "2022-05-27T00:00:00Z", dsun: 215.887052, hgln: 0.244771, hglt: -1.261927 },
  { isoDate: "2022-05-28T00:00:00Z", dsun: 215.932753, hgln: 0.241708, hglt: -1.141311 },
  { isoDate: "2022-05-29T00:00:00Z", dsun: 215.977342, hgln: 0.238323, hglt: -1.020464 },
  { isoDate: "2022-05-30T00:00:00Z", dsun: 216.020801, hgln: 0.234633, hglt: -0.899422 },
  { isoDate: "2022-05-31T00:00:00Z", dsun: 216.063112, hgln: 0.230653, hglt: -0.778217 },
  { isoDate: "2022-06-01T00:00:00Z", dsun: 216.104259, hgln: 0.226399, hglt: -0.656884 },
  { isoDate: "2022-06-02T00:00:00Z", dsun: 216.144225, hgln: 0.221889, hglt: -0.535457 },
  { isoDate: "2022-06-03T00:00:00Z", dsun: 216.182996, hgln: 0.217139, hglt: -0.413969 },
  { isoDate: "2022-06-04T00:00:00Z", dsun: 216.220561, hgln: 0.212164, hglt: -0.292452 },
  { isoDate: "2022-06-05T00:00:00Z", dsun: 216.256911, hgln: 0.206978, hglt: -0.170942 },
  { isoDate: "2022-06-06T00:00:00Z", dsun: 216.292038, hgln: 0.201594, hglt: -0.049471 },
  { isoDate: "2022-06-07T00:00:00Z", dsun: 216.325936, hgln: 0.196025, hglt: 0.071929 },
  { isoDate: "2022-06-08T00:00:00Z", dsun: 216.358601, hgln: 0.190281, hglt: 0.193223 },
  { isoDate: "2022-06-09T00:00:00Z", dsun: 216.390032, hgln: 0.184368, hglt: 0.314379 },
  { isoDate: "2022-06-10T00:00:00Z", dsun: 216.420226, hgln: 0.178293, hglt: 0.435364 },
  { isoDate: "2022-06-11T00:00:00Z", dsun: 216.449185, hgln: 0.172057, hglt: 0.556146 },
  { isoDate: "2022-06-12T00:00:00Z", dsun: 216.476908, hgln: 0.165658, hglt: 0.676692 },
  { isoDate: "2022-06-13T00:00:00Z", dsun: 216.503399, hgln: 0.159088, hglt: 0.79697 },
  { isoDate: "2022-06-14T00:00:00Z", dsun: 216.52866, hgln: 0.152335, hglt: 0.916948 },
  { isoDate: "2022-06-15T00:00:00Z", dsun: 216.552689, hgln: 0.145385, hglt: 1.036594 },
  { isoDate: "2022-06-16T00:00:00Z", dsun: 216.5755, hgln: 0.138224, hglt: 1.155877 },
  { isoDate: "2022-06-17T00:00:00Z", dsun: 216.597091, hgln: 0.130837, hglt: 1.274765 },
  { isoDate: "2022-06-18T00:00:00Z", dsun: 216.617468, hgln: 0.123213, hglt: 1.393226 },
  { isoDate: "2022-06-19T00:00:00Z", dsun: 216.636636, hgln: 0.115348, hglt: 1.511231 },
  { isoDate: "2022-06-20T00:00:00Z", dsun: 216.654601, hgln: 0.107243, hglt: 1.628749 },
  { isoDate: "2022-06-21T00:00:00Z", dsun: 216.671369, hgln: 0.098904, hglt: 1.745749 },
  { isoDate: "2022-06-22T00:00:00Z", dsun: 216.686948, hgln: 0.090343, hglt: 1.8622 },
  { isoDate: "2022-06-23T00:00:00Z", dsun: 216.701346, hgln: 0.081576, hglt: 1.978074 },
  { isoDate: "2022-06-24T00:00:00Z", dsun: 216.714571, hgln: 0.072622, hglt: 2.093341 },
  { isoDate: "2022-06-25T00:00:00Z", dsun: 216.726634, hgln: 0.063502, hglt: 2.207971 },
  { isoDate: "2022-06-26T00:00:00Z", dsun: 216.737545, hgln: 0.054238, hglt: 2.321935 },
  { isoDate: "2022-06-27T00:00:00Z", dsun: 216.747314, hgln: 0.044856, hglt: 2.435205 },
  { isoDate: "2022-06-28T00:00:00Z", dsun: 216.75595, hgln: 0.035381, hglt: 2.547751 },
  { isoDate: "2022-06-29T00:00:00Z", dsun: 216.763461, hgln: 0.025838, hglt: 2.659547 },
  { isoDate: "2022-06-30T00:00:00Z", dsun: 216.76985, hgln: 0.016254, hglt: 2.770563 },
  { isoDate: "2022-07-01T00:00:00Z", dsun: 216.775117, hgln: 0.006654, hglt: 2.880773 },
  { isoDate: "2022-07-02T00:00:00Z", dsun: 216.779262, hgln: -0.002934, hglt: 2.99015 },
  { isoDate: "2022-07-03T00:00:00Z", dsun: 216.782279, hgln: -0.012485, hglt: 3.098666 },
  { isoDate: "2022-07-04T00:00:00Z", dsun: 216.784163, hgln: -0.021972, hglt: 3.206296 },
  { isoDate: "2022-07-05T00:00:00Z", dsun: 216.784909, hgln: -0.031372, hglt: 3.313012 },
  { isoDate: "2022-07-06T00:00:00Z", dsun: 216.784511, hgln: -0.04066, hglt: 3.418791 },
  { isoDate: "2022-07-07T00:00:00Z", dsun: 216.782965, hgln: -0.049815, hglt: 3.523604 },
  { isoDate: "2022-07-08T00:00:00Z", dsun: 216.780263, hgln: -0.05882, hglt: 3.627428 },
  { isoDate: "2022-07-09T00:00:00Z", dsun: 216.776401, hgln: -0.06766, hglt: 3.730238 },
  { isoDate: "2022-07-10T00:00:00Z", dsun: 216.771371, hgln: -0.076324, hglt: 3.832007 },
  { isoDate: "2022-07-11T00:00:00Z", dsun: 216.765169, hgln: -0.084808, hglt: 3.932712 },
  { isoDate: "2022-07-12T00:00:00Z", dsun: 216.757786, hgln: -0.093112, hglt: 4.032328 },
  { isoDate: "2022-07-13T00:00:00Z", dsun: 216.749215, hgln: -0.101244, hglt: 4.130831 },
  { isoDate: "2022-07-14T00:00:00Z", dsun: 216.739449, hgln: -0.109213, hglt: 4.228196 },
  { isoDate: "2022-07-15T00:00:00Z", dsun: 216.728479, hgln: -0.117034, hglt: 4.324401 },
  { isoDate: "2022-07-16T00:00:00Z", dsun: 216.716296, hgln: -0.124718, hglt: 4.41942 },
  { isoDate: "2022-07-17T00:00:00Z", dsun: 216.702891, hgln: -0.132276, hglt: 4.513231 },
  { isoDate: "2022-07-18T00:00:00Z", dsun: 216.688255, hgln: -0.139712, hglt: 4.60581 },
  { isoDate: "2022-07-19T00:00:00Z", dsun: 216.672377, hgln: -0.147029, hglt: 4.697134 },
  { isoDate: "2022-07-20T00:00:00Z", dsun: 216.65525, hgln: -0.154223, hglt: 4.78718 },
  { isoDate: "2022-07-21T00:00:00Z", dsun: 216.636865, hgln: -0.161287, hglt: 4.875925 },
  { isoDate: "2022-07-22T00:00:00Z", dsun: 216.617216, hgln: -0.168212, hglt: 4.963347 },
  { isoDate: "2022-07-23T00:00:00Z", dsun: 216.596296, hgln: -0.174986, hglt: 5.049423 },
  { isoDate: "2022-07-24T00:00:00Z", dsun: 216.5741, hgln: -0.181594, hglt: 5.134131 },
  { isoDate: "2022-07-25T00:00:00Z", dsun: 216.550626, hgln: -0.188021, hglt: 5.217449 },
  { isoDate: "2022-07-26T00:00:00Z", dsun: 216.525873, hgln: -0.194252, hglt: 5.299354 },
  { isoDate: "2022-07-27T00:00:00Z", dsun: 216.499842, hgln: -0.200269, hglt: 5.379826 },
  { isoDate: "2022-07-28T00:00:00Z", dsun: 216.472536, hgln: -0.206055, hglt: 5.458841 },
  { isoDate: "2022-07-29T00:00:00Z", dsun: 216.443962, hgln: -0.211591, hglt: 5.53638 },
  { isoDate: "2022-07-30T00:00:00Z", dsun: 216.414124, hgln: -0.216859, hglt: 5.61242 },
  { isoDate: "2022-07-31T00:00:00Z", dsun: 216.383032, hgln: -0.221842, hglt: 5.686941 },
  { isoDate: "2022-08-01T00:00:00Z", dsun: 216.350693, hgln: -0.226522, hglt: 5.759921 },
  { isoDate: "2022-08-02T00:00:00Z", dsun: 216.317116, hgln: -0.230884, hglt: 5.831341 },
  { isoDate: "2022-08-03T00:00:00Z", dsun: 216.282307, hgln: -0.234912, hglt: 5.901179 },
  { isoDate: "2022-08-04T00:00:00Z", dsun: 216.246277, hgln: -0.238593, hglt: 5.969415 },
  { isoDate: "2022-08-05T00:00:00Z", dsun: 216.209036, hgln: -0.241916, hglt: 6.036029 },
  { isoDate: "2022-08-06T00:00:00Z", dsun: 216.170595, hgln: -0.244873, hglt: 6.101002 },
  { isoDate: "2022-08-07T00:00:00Z", dsun: 216.130969, hgln: -0.247459, hglt: 6.164313 },
  { isoDate: "2022-08-08T00:00:00Z", dsun: 216.090174, hgln: -0.249675, hglt: 6.225945 },
  { isoDate: "2022-08-09T00:00:00Z", dsun: 216.048226, hgln: -0.251526, hglt: 6.285878 },
  { isoDate: "2022-08-10T00:00:00Z", dsun: 216.005131, hgln: -0.253024, hglt: 6.344091 },
  { isoDate: "2022-08-11T00:00:00Z", dsun: 215.960941, hgln: -0.254185, hglt: 6.400571 },
  { isoDate: "2022-08-12T00:00:00Z", dsun: 215.915661, hgln: -0.255029, hglt: 6.455297 },
  { isoDate: "2022-08-13T00:00:00Z", dsun: 215.869316, hgln: -0.25558, hglt: 6.508252 },
  { isoDate: "2022-08-14T00:00:00Z", dsun: 215.821929, hgln: -0.255858, hglt: 6.559418 },
  { isoDate: "2022-08-15T00:00:00Z", dsun: 215.773526, hgln: -0.255882, hglt: 6.608778 },
  { isoDate: "2022-08-16T00:00:00Z", dsun: 215.724132, hgln: -0.255669, hglt: 6.656317 },
  { isoDate: "2022-08-17T00:00:00Z", dsun: 215.673773, hgln: -0.25523, hglt: 6.702017 },
  { isoDate: "2022-08-18T00:00:00Z", dsun: 215.622475, hgln: -0.254573, hglt: 6.745862 },
  { isoDate: "2022-08-19T00:00:00Z", dsun: 215.570266, hgln: -0.253701, hglt: 6.787838 },
  { isoDate: "2022-08-20T00:00:00Z", dsun: 215.517175, hgln: -0.252616, hglt: 6.827929 },
  { isoDate: "2022-08-21T00:00:00Z", dsun: 215.463229, hgln: -0.251316, hglt: 6.866121 },
  { isoDate: "2022-08-22T00:00:00Z", dsun: 215.408458, hgln: -0.249799, hglt: 6.902399 },
  { isoDate: "2022-08-23T00:00:00Z", dsun: 215.352893, hgln: -0.24806, hglt: 6.93675 },
  { isoDate: "2022-08-24T00:00:00Z", dsun: 215.296564, hgln: -0.246092, hglt: 6.96916 },
  { isoDate: "2022-08-25T00:00:00Z", dsun: 215.239504, hgln: -0.243888, hglt: 6.999616 },
  { isoDate: "2022-08-26T00:00:00Z", dsun: 215.181745, hgln: -0.241439, hglt: 7.028107 },
  { isoDate: "2022-08-27T00:00:00Z", dsun: 215.123324, hgln: -0.238734, hglt: 7.05462 },
  { isoDate: "2022-08-28T00:00:00Z", dsun: 215.064273, hgln: -0.235763, hglt: 7.079145 },
  { isoDate: "2022-08-29T00:00:00Z", dsun: 215.004629, hgln: -0.232514, hglt: 7.101669 },
  { isoDate: "2022-08-30T00:00:00Z", dsun: 214.944425, hgln: -0.228975, hglt: 7.122183 },
  { isoDate: "2022-08-31T00:00:00Z", dsun: 214.883698, hgln: -0.225135, hglt: 7.140678 },
  { isoDate: "2022-09-01T00:00:00Z", dsun: 214.822479, hgln: -0.220985, hglt: 7.157143 },
  { isoDate: "2022-09-02T00:00:00Z", dsun: 214.760804, hgln: -0.216517, hglt: 7.17157 },
  { isoDate: "2022-09-03T00:00:00Z", dsun: 214.698704, hgln: -0.211726, hglt: 7.18395 },
  { isoDate: "2022-09-04T00:00:00Z", dsun: 214.636213, hgln: -0.206608, hglt: 7.194276 },
  { isoDate: "2022-09-05T00:00:00Z", dsun: 214.573366, hgln: -0.201168, hglt: 7.202542 },
  { isoDate: "2022-09-06T00:00:00Z", dsun: 214.510197, hgln: -0.195412, hglt: 7.208739 },
  { isoDate: "2022-09-07T00:00:00Z", dsun: 214.44674, hgln: -0.189353, hglt: 7.212864 },
  { isoDate: "2022-09-08T00:00:00Z", dsun: 214.383032, hgln: -0.18301, hglt: 7.21491 },
  { isoDate: "2022-09-09T00:00:00Z", dsun: 214.319106, hgln: -0.176405, hglt: 7.214873 },
  { isoDate: "2022-09-10T00:00:00Z", dsun: 214.254998, hgln: -0.169566, hglt: 7.212749 },
  { isoDate: "2022-09-11T00:00:00Z", dsun: 214.190743, hgln: -0.162519, hglt: 7.208534 },
  { isoDate: "2022-09-12T00:00:00Z", dsun: 214.126374, hgln: -0.155294, hglt: 7.202227 },
  { isoDate: "2022-09-13T00:00:00Z", dsun: 214.061924, hgln: -0.147916, hglt: 7.193824 },
  { isoDate: "2022-09-14T00:00:00Z", dsun: 213.99742, hgln: -0.140405, hglt: 7.183323 },
  { isoDate: "2022-09-15T00:00:00Z", dsun: 213.932906, hgln: -0.132789, hglt: 7.170726 },
  { isoDate: "2022-09-16T00:00:00Z", dsun: 213.868408, hgln: -0.125081, hglt: 7.156032 },
  { isoDate: "2022-09-17T00:00:00Z", dsun: 213.803955, hgln: -0.117294, hglt: 7.139241 },
  { isoDate: "2022-09-18T00:00:00Z", dsun: 213.739578, hgln: -0.109439, hglt: 7.120355 },
  { isoDate: "2022-09-19T00:00:00Z", dsun: 213.675306, hgln: -0.101526, hglt: 7.099375 },
  { isoDate: "2022-09-20T00:00:00Z", dsun: 213.611167, hgln: -0.09356, hglt: 7.076304 },
  { isoDate: "2022-09-21T00:00:00Z", dsun: 213.54719, hgln: -0.085548, hglt: 7.051146 },
  { isoDate: "2022-09-22T00:00:00Z", dsun: 213.483404, hgln: -0.077492, hglt: 7.023904 },
  { isoDate: "2022-09-23T00:00:00Z", dsun: 213.419835, hgln: -0.069393, hglt: 6.994584 },
  { isoDate: "2022-09-24T00:00:00Z", dsun: 213.356512, hgln: -0.06125, hglt: 6.963191 },
  { isoDate: "2022-09-25T00:00:00Z", dsun: 213.293461, hgln: -0.053062, hglt: 6.929732 },
  { isoDate: "2022-09-26T00:00:00Z", dsun: 213.230709, hgln: -0.044823, hglt: 6.894212 },
  { isoDate: "2022-09-27T00:00:00Z", dsun: 213.168278, hgln: -0.036527, hglt: 6.856641 },
  { isoDate: "2022-09-28T00:00:00Z", dsun: 213.106192, hgln: -0.028169, hglt: 6.817026 },
  { isoDate: "2022-09-29T00:00:00Z", dsun: 213.044472, hgln: -0.01974, hglt: 6.775376 },
  { isoDate: "2022-09-30T00:00:00Z", dsun: 212.983136, hgln: -0.011235, hglt: 6.731701 },
  { isoDate: "2022-10-01T00:00:00Z", dsun: 212.922203, hgln: -0.00265, hglt: 6.686012 },
  { isoDate: "2022-10-02T00:00:00Z", dsun: 212.861691, hgln: 0.006017, hglt: 6.638319 },
  { isoDate: "2022-10-03T00:00:00Z", dsun: 212.801617, hgln: 0.014763, hglt: 6.588634 },
  { isoDate: "2022-10-04T00:00:00Z", dsun: 212.741998, hgln: 0.02358, hglt: 6.536971 },
  { isoDate: "2022-10-05T00:00:00Z", dsun: 212.682855, hgln: 0.032456, hglt: 6.483342 },
  { isoDate: "2022-10-06T00:00:00Z", dsun: 212.624208, hgln: 0.041374, hglt: 6.427762 },
  { isoDate: "2022-10-07T00:00:00Z", dsun: 212.566061, hgln: 0.050312, hglt: 6.370245 },
  { isoDate: "2022-10-08T00:00:00Z", dsun: 212.508429, hgln: 0.059244, hglt: 6.310806 },
  { isoDate: "2022-10-09T00:00:00Z", dsun: 212.451323, hgln: 0.068142, hglt: 6.249463 },
  { isoDate: "2022-10-10T00:00:00Z", dsun: 212.394755, hgln: 0.076976, hglt: 6.186231 },
  { isoDate: "2022-10-11T00:00:00Z", dsun: 212.338733, hgln: 0.085716, hglt: 6.121128 },
  { isoDate: "2022-10-12T00:00:00Z", dsun: 212.283267, hgln: 0.094337, hglt: 6.054172 },
  { isoDate: "2022-10-13T00:00:00Z", dsun: 212.228362, hgln: 0.102811, hglt: 5.985381 },
  { isoDate: "2022-10-14T00:00:00Z", dsun: 212.174026, hgln: 0.111117, hglt: 5.914777 },
  { isoDate: "2022-10-15T00:00:00Z", dsun: 212.120264, hgln: 0.119234, hglt: 5.842377 },
  { isoDate: "2022-10-16T00:00:00Z", dsun: 212.067079, hgln: 0.127147, hglt: 5.768203 },
  { isoDate: "2022-10-17T00:00:00Z", dsun: 212.014475, hgln: 0.134841, hglt: 5.692277 },
  { isoDate: "2022-10-18T00:00:00Z", dsun: 211.962455, hgln: 0.142304, hglt: 5.61462 },
  { isoDate: "2022-10-19T00:00:00Z", dsun: 211.911022, hgln: 0.149528, hglt: 5.535255 },
  { isoDate: "2022-10-20T00:00:00Z", dsun: 211.860178, hgln: 0.156504, hglt: 5.454205 },
  { isoDate: "2022-10-21T00:00:00Z", dsun: 211.809926, hgln: 0.163229, hglt: 5.371494 },
  { isoDate: "2022-10-22T00:00:00Z", dsun: 211.760266, hgln: 0.169699, hglt: 5.287145 },
  { isoDate: "2022-10-23T00:00:00Z", dsun: 211.7112, hgln: 0.175917, hglt: 5.201185 },
  { isoDate: "2022-10-24T00:00:00Z", dsun: 211.662727, hgln: 0.181886, hglt: 5.113639 },
  { isoDate: "2022-10-25T00:00:00Z", dsun: 211.614847, hgln: 0.187613, hglt: 5.024532 },
  { isoDate: "2022-10-26T00:00:00Z", dsun: 211.56756, hgln: 0.193109, hglt: 4.933894 },
  { isoDate: "2022-10-27T00:00:00Z", dsun: 211.520854, hgln: 0.198383, hglt: 4.841747 },
  { isoDate: "2022-10-28T00:00:00Z", dsun: 211.474728, hgln: 0.20345, hglt: 4.74812 },
  { isoDate: "2022-10-29T00:00:00Z", dsun: 211.429177, hgln: 0.208323, hglt: 4.653042 },
  { isoDate: "2022-10-30T00:00:00Z", dsun: 211.384196, hgln: 0.213011, hglt: 4.556541 },
  { isoDate: "2022-10-31T00:00:00Z", dsun: 211.339781, hgln: 0.217522, hglt: 4.458647 },
  { isoDate: "2022-11-01T00:00:00Z", dsun: 211.295929, hgln: 0.221858, hglt: 4.359388 },
  { isoDate: "2022-11-02T00:00:00Z", dsun: 211.252633, hgln: 0.226017, hglt: 4.258794 },
  { isoDate: "2022-11-03T00:00:00Z", dsun: 211.209891, hgln: 0.229992, hglt: 4.156896 },
  { isoDate: "2022-11-04T00:00:00Z", dsun: 211.167698, hgln: 0.233771, hglt: 4.053726 },
  { isoDate: "2022-11-05T00:00:00Z", dsun: 211.126048, hgln: 0.237339, hglt: 3.949313 },
  { isoDate: "2022-11-06T00:00:00Z", dsun: 211.084938, hgln: 0.240679, hglt: 3.84369 },
  { isoDate: "2022-11-07T00:00:00Z", dsun: 211.044361, hgln: 0.24377, hglt: 3.736888 },
  { isoDate: "2022-11-08T00:00:00Z", dsun: 211.004313, hgln: 0.246591, hglt: 3.62894 },
  { isoDate: "2022-11-09T00:00:00Z", dsun: 210.964785, hgln: 0.249121, hglt: 3.519878 },
  { isoDate: "2022-11-10T00:00:00Z", dsun: 210.925775, hgln: 0.251342, hglt: 3.409736 },
  { isoDate: "2022-11-11T00:00:00Z", dsun: 210.887276, hgln: 0.253236, hglt: 3.298547 },
  { isoDate: "2022-11-12T00:00:00Z", dsun: 210.849281, hgln: 0.254785, hglt: 3.186344 },
  { isoDate: "2022-11-13T00:00:00Z", dsun: 210.811786, hgln: 0.255977, hglt: 3.073162 },
  { isoDate: "2022-11-14T00:00:00Z", dsun: 210.774785, hgln: 0.2568, hglt: 2.959034 },
  { isoDate: "2022-11-15T00:00:00Z", dsun: 210.738274, hgln: 0.257245, hglt: 2.843994 },
  { isoDate: "2022-11-16T00:00:00Z", dsun: 210.702251, hgln: 0.257305, hglt: 2.728079 },
  { isoDate: "2022-11-17T00:00:00Z", dsun: 210.666712, hgln: 0.256976, hglt: 2.611322 },
  { isoDate: "2022-11-18T00:00:00Z", dsun: 210.631657, hgln: 0.256256, hglt: 2.493759 },
  { isoDate: "2022-11-19T00:00:00Z", dsun: 210.597085, hgln: 0.255144, hglt: 2.375425 },
  { isoDate: "2022-11-20T00:00:00Z", dsun: 210.562998, hgln: 0.253644, hglt: 2.256356 },
  { isoDate: "2022-11-21T00:00:00Z", dsun: 210.529397, hgln: 0.251762, hglt: 2.136588 },
  { isoDate: "2022-11-22T00:00:00Z", dsun: 210.496283, hgln: 0.249507, hglt: 2.016156 },
  { isoDate: "2022-11-23T00:00:00Z", dsun: 210.463657, hgln: 0.246895, hglt: 1.895097 },
  { isoDate: "2022-11-24T00:00:00Z", dsun: 210.43152, hgln: 0.243944, hglt: 1.773446 },
  { isoDate: "2022-11-25T00:00:00Z", dsun: 210.399872, hgln: 0.240675, hglt: 1.651241 },
  { isoDate: "2022-11-26T00:00:00Z", dsun: 210.368716, hgln: 0.237111, hglt: 1.528517 },
  { isoDate: "2022-11-27T00:00:00Z", dsun: 210.338053, hgln: 0.233277, hglt: 1.405311 },
  { isoDate: "2022-11-28T00:00:00Z", dsun: 210.307889, hgln: 0.229191, hglt: 1.281659 },
  { isoDate: "2022-11-29T00:00:00Z", dsun: 210.278231, hgln: 0.224872, hglt: 1.157599 },
  { isoDate: "2022-11-30T00:00:00Z", dsun: 210.249088, hgln: 0.220328, hglt: 1.033166 },
  { isoDate: "2022-12-01T00:00:00Z", dsun: 210.22047, hgln: 0.215569, hglt: 0.908397 },
  { isoDate: "2022-12-02T00:00:00Z", dsun: 210.192389, hgln: 0.210594, hglt: 0.783329 },
  { isoDate: "2022-12-03T00:00:00Z", dsun: 210.164857, hgln: 0.205402, hglt: 0.658 },
  { isoDate: "2022-12-04T00:00:00Z", dsun: 210.137887, hgln: 0.199988, hglt: 0.532444 },
  { isoDate: "2022-12-05T00:00:00Z", dsun: 210.111496, hgln: 0.194345, hglt: 0.4067 },
  { isoDate: "2022-12-06T00:00:00Z", dsun: 210.085697, hgln: 0.188464, hglt: 0.280803 },
  { isoDate: "2022-12-07T00:00:00Z", dsun: 210.060507, hgln: 0.182336, hglt: 0.154791 },
  { isoDate: "2022-12-08T00:00:00Z", dsun: 210.035942, hgln: 0.175951, hglt: 0.0287 },
  { isoDate: "2022-12-09T00:00:00Z", dsun: 210.01202, hgln: 0.169302, hglt: -0.097434 },
  { isoDate: "2022-12-10T00:00:00Z", dsun: 209.988756, hgln: 0.162381, hglt: -0.223574 },
  { isoDate: "2022-12-11T00:00:00Z", dsun: 209.966171, hgln: 0.155182, hglt: -0.349684 },
  { isoDate: "2022-12-12T00:00:00Z", dsun: 209.944281, hgln: 0.147702, hglt: -0.475727 },
  { isoDate: "2022-12-13T00:00:00Z", dsun: 209.923105, hgln: 0.139939, hglt: -0.601667 },
  { isoDate: "2022-12-14T00:00:00Z", dsun: 209.902658, hgln: 0.131893, hglt: -0.727469 },
  { isoDate: "2022-12-15T00:00:00Z", dsun: 209.882968, hgln: 0.123568, hglt: -0.853094 },
  { isoDate: "2022-12-16T00:00:00Z", dsun: 209.864049, hgln: 0.114969, hglt: -0.978509 },
  { isoDate: "2022-12-17T00:00:00Z", dsun: 209.845924, hgln: 0.106105, hglt: -1.103676 },
  { isoDate: "2022-12-18T00:00:00Z", dsun: 209.828611, hgln: 0.096987, hglt: -1.22856 },
  { isoDate: "2022-12-19T00:00:00Z", dsun: 209.812131, hgln: 0.08763, hglt: -1.353125 },
  { isoDate: "2022-12-20T00:00:00Z", dsun: 209.796506, hgln: 0.078052, hglt: -1.477335 },
  { isoDate: "2022-12-21T00:00:00Z", dsun: 209.781757, hgln: 0.068277, hglt: -1.601154 },
  { isoDate: "2022-12-22T00:00:00Z", dsun: 209.767903, hgln: 0.058333, hglt: -1.724547 },
  { isoDate: "2022-12-23T00:00:00Z", dsun: 209.754962, hgln: 0.048254, hglt: -1.847477 },
  { isoDate: "2022-12-24T00:00:00Z", dsun: 209.742948, hgln: 0.038076, hglt: -1.96991 },
  { isoDate: "2022-12-25T00:00:00Z", dsun: 209.73187, hgln: 0.027839, hglt: -2.091808 },
  { isoDate: "2022-12-26T00:00:00Z", dsun: 209.721732, hgln: 0.017581, hglt: -2.213135 },
  { isoDate: "2022-12-27T00:00:00Z", dsun: 209.712538, hgln: 0.007339, hglt: -2.333856 },
  { isoDate: "2022-12-28T00:00:00Z", dsun: 209.704288, hgln: -0.002855, hglt: -2.453933 },
  { isoDate: "2022-12-29T00:00:00Z", dsun: 209.696981, hgln: -0.012973, hglt: -2.57333 },
  { isoDate: "2022-12-30T00:00:00Z", dsun: 209.690618, hgln: -0.022993, hglt: -2.692011 },
  { isoDate: "2022-12-31T00:00:00Z", dsun: 209.685196, hgln: -0.032898, hglt: -2.80994 },
  { isoDate: "2023-01-01T00:00:00Z", dsun: 209.680709, hgln: -0.042675, hglt: -2.92708 },
  { isoDate: "2023-01-02T00:00:00Z", dsun: 209.677156, hgln: -0.052314, hglt: -3.043394 },
  { isoDate: "2023-01-03T00:00:00Z", dsun: 209.674532, hgln: -0.061807, hglt: -3.158847 },
  { isoDate: "2023-01-04T00:00:00Z", dsun: 209.672828, hgln: -0.07115, hglt: -3.273402 },
  { isoDate: "2023-01-05T00:00:00Z", dsun: 209.672037, hgln: -0.080339, hglt: -3.387022 },
  { isoDate: "2023-01-06T00:00:00Z", dsun: 209.672147, hgln: -0.089372, hglt: -3.49967 },
  { isoDate: "2023-01-07T00:00:00Z", dsun: 209.673146, hgln: -0.098249, hglt: -3.611311 },
  { isoDate: "2023-01-08T00:00:00Z", dsun: 209.675022, hgln: -0.106968, hglt: -3.721907 },
  { isoDate: "2023-01-09T00:00:00Z", dsun: 209.67776, hgln: -0.115531, hglt: -3.831423 },
  { isoDate: "2023-01-10T00:00:00Z", dsun: 209.681345, hgln: -0.123938, hglt: -3.939822 },
  { isoDate: "2023-01-11T00:00:00Z", dsun: 209.68576, hgln: -0.132189, hglt: -4.047069 },
  { isoDate: "2023-01-12T00:00:00Z", dsun: 209.690991, hgln: -0.140282, hglt: -4.153125 },
  { isoDate: "2023-01-13T00:00:00Z", dsun: 209.69702, hgln: -0.148218, hglt: -4.257956 },
  { isoDate: "2023-01-14T00:00:00Z", dsun: 209.703831, hgln: -0.155994, hglt: -4.361527 },
  { isoDate: "2023-01-15T00:00:00Z", dsun: 209.711407, hgln: -0.163607, hglt: -4.463801 },
  { isoDate: "2023-01-16T00:00:00Z", dsun: 209.719733, hgln: -0.171051, hglt: -4.564743 },
  { isoDate: "2023-01-17T00:00:00Z", dsun: 209.728795, hgln: -0.178319, hglt: -4.66432 },
  { isoDate: "2023-01-18T00:00:00Z", dsun: 209.738577, hgln: -0.1854, hglt: -4.762497 },
  { isoDate: "2023-01-19T00:00:00Z", dsun: 209.74907, hgln: -0.192279, hglt: -4.859239 },
  { isoDate: "2023-01-20T00:00:00Z", dsun: 209.760261, hgln: -0.198934, hglt: -4.954514 },
  { isoDate: "2023-01-21T00:00:00Z", dsun: 209.772143, hgln: -0.205342, hglt: -5.048288 },
  { isoDate: "2023-01-22T00:00:00Z", dsun: 209.78471, hgln: -0.211473, hglt: -5.140529 },
  { isoDate: "2023-01-23T00:00:00Z", dsun: 209.797957, hgln: -0.217299, hglt: -5.231204 },
  { isoDate: "2023-01-24T00:00:00Z", dsun: 209.81188, hgln: -0.222791, hglt: -5.320282 },
  { isoDate: "2023-01-25T00:00:00Z", dsun: 209.826474, hgln: -0.227922, hglt: -5.407732 },
  { isoDate: "2023-01-26T00:00:00Z", dsun: 209.841735, hgln: -0.232675, hglt: -5.493523 },
  { isoDate: "2023-01-27T00:00:00Z", dsun: 209.857656, hgln: -0.237034, hglt: -5.577623 },
  { isoDate: "2023-01-28T00:00:00Z", dsun: 209.874235, hgln: -0.240991, hglt: -5.660005 },
  { isoDate: "2023-01-29T00:00:00Z", dsun: 209.891466, hgln: -0.24454, hglt: -5.740638 },
  { isoDate: "2023-01-30T00:00:00Z", dsun: 209.909349, hgln: -0.247683, hglt: -5.819494 },
  { isoDate: "2023-01-31T00:00:00Z", dsun: 209.927883, hgln: -0.250421, hglt: -5.896546 },
  { isoDate: "2023-02-01T00:00:00Z", dsun: 209.94707, hgln: -0.252759, hglt: -5.971766 },
  { isoDate: "2023-02-02T00:00:00Z", dsun: 209.966912, hgln: -0.254705, hglt: -6.045128 },
  { isoDate: "2023-02-03T00:00:00Z", dsun: 209.987413, hgln: -0.256268, hglt: -6.116606 },
  { isoDate: "2023-02-04T00:00:00Z", dsun: 210.008579, hgln: -0.257459, hglt: -6.186174 },
  { isoDate: "2023-02-05T00:00:00Z", dsun: 210.030415, hgln: -0.258289, hglt: -6.253809 },
  { isoDate: "2023-02-06T00:00:00Z", dsun: 210.052926, hgln: -0.258772, hglt: -6.319486 },
  { isoDate: "2023-02-07T00:00:00Z", dsun: 210.076121, hgln: -0.258922, hglt: -6.383183 },
  { isoDate: "2023-02-08T00:00:00Z", dsun: 210.100005, hgln: -0.258752, hglt: -6.444876 },
  { isoDate: "2023-02-09T00:00:00Z", dsun: 210.124587, hgln: -0.258279, hglt: -6.504545 },
  { isoDate: "2023-02-10T00:00:00Z", dsun: 210.149873, hgln: -0.257517, hglt: -6.562169 },
  { isoDate: "2023-02-11T00:00:00Z", dsun: 210.175872, hgln: -0.256478, hglt: -6.617726 },
  { isoDate: "2023-02-12T00:00:00Z", dsun: 210.202589, hgln: -0.255178, hglt: -6.671198 },
  { isoDate: "2023-02-13T00:00:00Z", dsun: 210.230034, hgln: -0.253625, hglt: -6.722567 },
  { isoDate: "2023-02-14T00:00:00Z", dsun: 210.258214, hgln: -0.251831, hglt: -6.771813 },
  { isoDate: "2023-02-15T00:00:00Z", dsun: 210.287137, hgln: -0.2498, hglt: -6.818922 },
  { isoDate: "2023-02-16T00:00:00Z", dsun: 210.316809, hgln: -0.247535, hglt: -6.863875 },
  { isoDate: "2023-02-17T00:00:00Z", dsun: 210.347239, hgln: -0.245035, hglt: -6.906658 },
  { isoDate: "2023-02-18T00:00:00Z", dsun: 210.378436, hgln: -0.242291, hglt: -6.947256 },
  { isoDate: "2023-02-19T00:00:00Z", dsun: 210.410408, hgln: -0.239291, hglt: -6.985655 },
  { isoDate: "2023-02-20T00:00:00Z", dsun: 210.443167, hgln: -0.236018, hglt: -7.021843 },
  { isoDate: "2023-02-21T00:00:00Z", dsun: 210.476721, hgln: -0.232454, hglt: -7.055808 },
  { isoDate: "2023-02-22T00:00:00Z", dsun: 210.511081, hgln: -0.228582, hglt: -7.087537 },
  { isoDate: "2023-02-23T00:00:00Z", dsun: 210.546255, hgln: -0.224385, hglt: -7.117021 },
  { isoDate: "2023-02-24T00:00:00Z", dsun: 210.582251, hgln: -0.219854, hglt: -7.144251 },
  { isoDate: "2023-02-25T00:00:00Z", dsun: 210.619075, hgln: -0.214983, hglt: -7.169217 },
  { isoDate: "2023-02-26T00:00:00Z", dsun: 210.656731, hgln: -0.209771, hglt: -7.191911 },
  { isoDate: "2023-02-27T00:00:00Z", dsun: 210.695227, hgln: -0.204221, hglt: -7.212328 },
  { isoDate: "2023-02-28T00:00:00Z", dsun: 210.734565, hgln: -0.198342, hglt: -7.23046 },
  { isoDate: "2023-03-01T00:00:00Z", dsun: 210.774752, hgln: -0.192142, hglt: -7.246304 },
  { isoDate: "2023-03-02T00:00:00Z", dsun: 210.815791, hgln: -0.185635, hglt: -7.259853 },
  { isoDate: "2023-03-03T00:00:00Z", dsun: 210.857687, hgln: -0.178836, hglt: -7.271107 },
  { isoDate: "2023-03-04T00:00:00Z", dsun: 210.900444, hgln: -0.17176, hglt: -7.28006 },
  { isoDate: "2023-03-05T00:00:00Z", dsun: 210.944066, hgln: -0.164427, hglt: -7.286714 },
  { isoDate: "2023-03-06T00:00:00Z", dsun: 210.988556, hgln: -0.156858, hglt: -7.291065 },
  { isoDate: "2023-03-07T00:00:00Z", dsun: 211.033915, hgln: -0.149073, hglt: -7.293116 },
  { isoDate: "2023-03-08T00:00:00Z", dsun: 211.080144, hgln: -0.141098, hglt: -7.292866 },
  { isoDate: "2023-03-09T00:00:00Z", dsun: 211.127244, hgln: -0.132956, hglt: -7.290318 },
  { isoDate: "2023-03-10T00:00:00Z", dsun: 211.175213, hgln: -0.124673, hglt: -7.285475 },
  { isoDate: "2023-03-11T00:00:00Z", dsun: 211.224049, hgln: -0.116274, hglt: -7.27834 },
  { isoDate: "2023-03-12T00:00:00Z", dsun: 211.273748, hgln: -0.107784, hglt: -7.268919 },
  { isoDate: "2023-03-13T00:00:00Z", dsun: 211.324306, hgln: -0.099227, hglt: -7.257216 },
  { isoDate: "2023-03-14T00:00:00Z", dsun: 211.375715, hgln: -0.090624, hglt: -7.243239 },
  { isoDate: "2023-03-15T00:00:00Z", dsun: 211.427967, hgln: -0.081991, hglt: -7.226993 },
  { isoDate: "2023-03-16T00:00:00Z", dsun: 211.481058, hgln: -0.07335, hglt: -7.20849 },
  { isoDate: "2023-03-17T00:00:00Z", dsun: 211.534978, hgln: -0.064709, hglt: -7.187736 },
  { isoDate: "2023-03-18T00:00:00Z", dsun: 211.589716, hgln: -0.056074, hglt: -7.164741 },
  { isoDate: "2023-03-19T00:00:00Z", dsun: 211.645263, hgln: -0.047445, hglt: -7.139517 },
  { isoDate: "2023-03-20T00:00:00Z", dsun: 211.701609, hgln: -0.038819, hglt: -7.112076 },
  { isoDate: "2023-03-21T00:00:00Z", dsun: 211.758741, hgln: -0.030188, hglt: -7.082428 },
  { isoDate: "2023-03-22T00:00:00Z", dsun: 211.816648, hgln: -0.021542, hglt: -7.050588 },
  { isoDate: "2023-03-23T00:00:00Z", dsun: 211.875314, hgln: -0.012872, hglt: -7.01657 },
  { isoDate: "2023-03-24T00:00:00Z", dsun: 211.934722, hgln: -0.004168, hglt: -6.980388 },
  { isoDate: "2023-03-25T00:00:00Z", dsun: 211.994854, hgln: 0.004575, hglt: -6.942058 },
  { isoDate: "2023-03-26T00:00:00Z", dsun: 212.05569, hgln: 0.013359, hglt: -6.901596 },
  { isoDate: "2023-03-27T00:00:00Z", dsun: 212.117209, hgln: 0.022181, hglt: -6.859019 },
  { isoDate: "2023-03-28T00:00:00Z", dsun: 212.179389, hgln: 0.031037, hglt: -6.814346 },
  { isoDate: "2023-03-29T00:00:00Z", dsun: 212.242209, hgln: 0.039917, hglt: -6.767595 },
  { isoDate: "2023-03-30T00:00:00Z", dsun: 212.305647, hgln: 0.048813, hglt: -6.718785 },
  { isoDate: "2023-03-31T00:00:00Z", dsun: 212.369679, hgln: 0.057709, hglt: -6.667935 },
  { isoDate: "2023-04-01T00:00:00Z", dsun: 212.434278, hgln: 0.066592, hglt: -6.615068 },
  { isoDate: "2023-04-02T00:00:00Z", dsun: 212.499421, hgln: 0.075444, hglt: -6.560203 },
  { isoDate: "2023-04-03T00:00:00Z", dsun: 212.565081, hgln: 0.084248, hglt: -6.503364 },
  { isoDate: "2023-04-04T00:00:00Z", dsun: 212.63123, hgln: 0.092982, hglt: -6.444572 },
  { isoDate: "2023-04-05T00:00:00Z", dsun: 212.697841, hgln: 0.101626, hglt: -6.38385 },
  { isoDate: "2023-04-06T00:00:00Z", dsun: 212.764883, hgln: 0.110153, hglt: -6.321222 },
  { isoDate: "2023-04-07T00:00:00Z", dsun: 212.832326, hgln: 0.118541, hglt: -6.256714 },
  { isoDate: "2023-04-08T00:00:00Z", dsun: 212.900138, hgln: 0.126762, hglt: -6.190349 },
  { isoDate: "2023-04-09T00:00:00Z", dsun: 212.968286, hgln: 0.134791, hglt: -6.122152 },
  { isoDate: "2023-04-10T00:00:00Z", dsun: 213.036737, hgln: 0.142603, hglt: -6.052151 },
  { isoDate: "2023-04-11T00:00:00Z", dsun: 213.105457, hgln: 0.150173, hglt: -5.980371 },
  { isoDate: "2023-04-12T00:00:00Z", dsun: 213.174411, hgln: 0.157482, hglt: -5.906838 },
  { isoDate: "2023-04-13T00:00:00Z", dsun: 213.243564, hgln: 0.164512, hglt: -5.831583 },
  { isoDate: "2023-04-14T00:00:00Z", dsun: 213.31288, hgln: 0.171252, hglt: -5.75463 },
  { isoDate: "2023-04-15T00:00:00Z", dsun: 213.382324, hgln: 0.177692, hglt: -5.67601 },
  { isoDate: "2023-04-16T00:00:00Z", dsun: 213.451863, hgln: 0.183832, hglt: -5.59575 },
  { isoDate: "2023-04-17T00:00:00Z", dsun: 213.52146, hgln: 0.189673, hglt: -5.51388 },
  { isoDate: "2023-04-18T00:00:00Z", dsun: 213.59108, hgln: 0.195225, hglt: -5.430429 },
  { isoDate: "2023-04-19T00:00:00Z", dsun: 213.660687, hgln: 0.200496, hglt: -5.345426 },
  { isoDate: "2023-04-20T00:00:00Z", dsun: 213.730244, hgln: 0.205501, hglt: -5.258902 },
  { isoDate: "2023-04-21T00:00:00Z", dsun: 213.799713, hgln: 0.210254, hglt: -5.170888 },
  { isoDate: "2023-04-22T00:00:00Z", dsun: 213.869055, hgln: 0.214767, hglt: -5.081414 },
  { isoDate: "2023-04-23T00:00:00Z", dsun: 213.93823, hgln: 0.219053, hglt: -4.990512 },
  { isoDate: "2023-04-24T00:00:00Z", dsun: 214.0072, hgln: 0.223121, hglt: -4.898212 },
  { isoDate: "2023-04-25T00:00:00Z", dsun: 214.075927, hgln: 0.226977, hglt: -4.804546 },
  { isoDate: "2023-04-26T00:00:00Z", dsun: 214.144374, hgln: 0.230626, hglt: -4.709546 },
  { isoDate: "2023-04-27T00:00:00Z", dsun: 214.212503, hgln: 0.234068, hglt: -4.613245 },
  { isoDate: "2023-04-28T00:00:00Z", dsun: 214.280279, hgln: 0.237304, hglt: -4.515674 },
  { isoDate: "2023-04-29T00:00:00Z", dsun: 214.347665, hgln: 0.24033, hglt: -4.416866 },
  { isoDate: "2023-04-30T00:00:00Z", dsun: 214.414625, hgln: 0.243143, hglt: -4.316853 },
  { isoDate: "2023-05-01T00:00:00Z", dsun: 214.481124, hgln: 0.245735, hglt: -4.21567 },
  { isoDate: "2023-05-02T00:00:00Z", dsun: 214.547127, hgln: 0.248099, hglt: -4.113347 },
  { isoDate: "2023-05-03T00:00:00Z", dsun: 214.612599, hgln: 0.250223, hglt: -4.00992 },
  { isoDate: "2023-05-04T00:00:00Z", dsun: 214.677506, hgln: 0.252095, hglt: -3.90542 },
  { isoDate: "2023-05-05T00:00:00Z", dsun: 214.741811, hgln: 0.2537, hglt: -3.799882 },
  { isoDate: "2023-05-06T00:00:00Z", dsun: 214.805483, hgln: 0.255019, hglt: -3.693339 },
  { isoDate: "2023-05-07T00:00:00Z", dsun: 214.868485, hgln: 0.256035, hglt: -3.585824 },
  { isoDate: "2023-05-08T00:00:00Z", dsun: 214.930787, hgln: 0.256727, hglt: -3.477373 },
  { isoDate: "2023-05-09T00:00:00Z", dsun: 214.992354, hgln: 0.257077, hglt: -3.368017 },
  { isoDate: "2023-05-10T00:00:00Z", dsun: 215.053153, hgln: 0.257067, hglt: -3.257792 },
  { isoDate: "2023-05-11T00:00:00Z", dsun: 215.113159, hgln: 0.256685, hglt: -3.146731 },
  { isoDate: "2023-05-12T00:00:00Z", dsun: 215.172339, hgln: 0.255921, hglt: -3.034868 },
  { isoDate: "2023-05-13T00:00:00Z", dsun: 215.230667, hgln: 0.254771, hglt: -2.922237 },
  { isoDate: "2023-05-14T00:00:00Z", dsun: 215.288115, hgln: 0.253236, hglt: -2.808872 },
  { isoDate: "2023-05-15T00:00:00Z", dsun: 215.34466, hgln: 0.251322, hglt: -2.694808 },
  { isoDate: "2023-05-16T00:00:00Z", dsun: 215.400279, hgln: 0.249038, hglt: -2.580079 },
  { isoDate: "2023-05-17T00:00:00Z", dsun: 215.454948, hgln: 0.246399, hglt: -2.464718 },
  { isoDate: "2023-05-18T00:00:00Z", dsun: 215.508646, hgln: 0.243422, hglt: -2.348761 },
  { isoDate: "2023-05-19T00:00:00Z", dsun: 215.561352, hgln: 0.240125, hglt: -2.232241 },
  { isoDate: "2023-05-20T00:00:00Z", dsun: 215.613042, hgln: 0.236529, hglt: -2.115192 },
  { isoDate: "2023-05-21T00:00:00Z", dsun: 215.663696, hgln: 0.232651, hglt: -1.997649 },
  { isoDate: "2023-05-22T00:00:00Z", dsun: 215.713294, hgln: 0.228511, hglt: -1.879646 },
  { isoDate: "2023-05-23T00:00:00Z", dsun: 215.761819, hgln: 0.224127, hglt: -1.761216 },
  { isoDate: "2023-05-24T00:00:00Z", dsun: 215.809253, hgln: 0.219513, hglt: -1.642394 },
  { isoDate: "2023-05-25T00:00:00Z", dsun: 215.855582, hgln: 0.214682, hglt: -1.523213 },
  { isoDate: "2023-05-26T00:00:00Z", dsun: 215.900796, hgln: 0.209647, hglt: -1.403708 },
  { isoDate: "2023-05-27T00:00:00Z", dsun: 215.944884, hgln: 0.204416, hglt: -1.283912 },
  { isoDate: "2023-05-28T00:00:00Z", dsun: 215.987837, hgln: 0.198998, hglt: -1.16386 },
  { isoDate: "2023-05-29T00:00:00Z", dsun: 216.029649, hgln: 0.193398, hglt: -1.043584 },
  { isoDate: "2023-05-30T00:00:00Z", dsun: 216.070314, hgln: 0.187621, hglt: -0.923119 },
  { isoDate: "2023-05-31T00:00:00Z", dsun: 216.109828, hgln: 0.181668, hglt: -0.802497 },
  { isoDate: "2023-06-01T00:00:00Z", dsun: 216.148187, hgln: 0.175538, hglt: -0.681753 },
  { isoDate: "2023-06-02T00:00:00Z", dsun: 216.18539, hgln: 0.169228, hglt: -0.56092 },
  { isoDate: "2023-06-03T00:00:00Z", dsun: 216.221435, hgln: 0.162731, hglt: -0.44003 },
  { isoDate: "2023-06-04T00:00:00Z", dsun: 216.256322, hgln: 0.156037, hglt: -0.319117 },
  { isoDate: "2023-06-05T00:00:00Z", dsun: 216.290051, hgln: 0.149134, hglt: -0.198213 },
  { isoDate: "2023-06-06T00:00:00Z", dsun: 216.322621, hgln: 0.142011, hglt: -0.077352 },
  { isoDate: "2023-06-07T00:00:00Z", dsun: 216.354036, hgln: 0.134654, hglt: 0.043436 },
  { isoDate: "2023-06-08T00:00:00Z", dsun: 216.384296, hgln: 0.127055, hglt: 0.164116 },
  { isoDate: "2023-06-09T00:00:00Z", dsun: 216.413404, hgln: 0.119209, hglt: 0.284658 },
  { isoDate: "2023-06-10T00:00:00Z", dsun: 216.441364, hgln: 0.111115, hglt: 0.40503 },
  { isoDate: "2023-06-11T00:00:00Z", dsun: 216.468179, hgln: 0.10278, hglt: 0.5252 },
  { isoDate: "2023-06-12T00:00:00Z", dsun: 216.493854, hgln: 0.094216, hglt: 0.645137 },
  { isoDate: "2023-06-13T00:00:00Z", dsun: 216.518397, hgln: 0.085438, hglt: 0.76481 },
  { isoDate: "2023-06-14T00:00:00Z", dsun: 216.541813, hgln: 0.076467, hglt: 0.884187 },
  { isoDate: "2023-06-15T00:00:00Z", dsun: 216.564112, hgln: 0.067327, hglt: 1.003239 },
  { isoDate: "2023-06-16T00:00:00Z", dsun: 216.585301, hgln: 0.058045, hglt: 1.121935 },
  { isoDate: "2023-06-17T00:00:00Z", dsun: 216.605388, hgln: 0.048646, hglt: 1.240245 },
  { isoDate: "2023-06-18T00:00:00Z", dsun: 216.624379, hgln: 0.03916, hglt: 1.358139 },
  { isoDate: "2023-06-19T00:00:00Z", dsun: 216.642277, hgln: 0.029615, hglt: 1.475588 },
  { isoDate: "2023-06-20T00:00:00Z", dsun: 216.659081, hgln: 0.020037, hglt: 1.592562 },
  { isoDate: "2023-06-21T00:00:00Z", dsun: 216.674788, hgln: 0.010453, hglt: 1.709033 },
  { isoDate: "2023-06-22T00:00:00Z", dsun: 216.689393, hgln: 0.000889, hglt: 1.824972 },
  { isoDate: "2023-06-23T00:00:00Z", dsun: 216.70289, hgln: -0.008629, hglt: 1.940351 },
  { isoDate: "2023-06-24T00:00:00Z", dsun: 216.715273, hgln: -0.01808, hglt: 2.055142 },
  { isoDate: "2023-06-25T00:00:00Z", dsun: 216.726535, hgln: -0.02744, hglt: 2.169318 },
  { isoDate: "2023-06-26T00:00:00Z", dsun: 216.736671, hgln: -0.036691, hglt: 2.282851 },
  { isoDate: "2023-06-27T00:00:00Z", dsun: 216.745672, hgln: -0.045815, hglt: 2.395714 },
  { isoDate: "2023-06-28T00:00:00Z", dsun: 216.753532, hgln: -0.054797, hglt: 2.50788 },
  { isoDate: "2023-06-29T00:00:00Z", dsun: 216.760243, hgln: -0.063625, hglt: 2.619322 },
  { isoDate: "2023-06-30T00:00:00Z", dsun: 216.765797, hgln: -0.07229, hglt: 2.730014 },
  { isoDate: "2023-07-01T00:00:00Z", dsun: 216.770185, hgln: -0.080788, hglt: 2.83993 },
  { isoDate: "2023-07-02T00:00:00Z", dsun: 216.773398, hgln: -0.089121, hglt: 2.949042 },
  { isoDate: "2023-07-03T00:00:00Z", dsun: 216.775426, hgln: -0.097292, hglt: 3.057326 },
  { isoDate: "2023-07-04T00:00:00Z", dsun: 216.776258, hgln: -0.105313, hglt: 3.164756 },
  { isoDate: "2023-07-05T00:00:00Z", dsun: 216.775881, hgln: -0.113193, hglt: 3.271305 },
  { isoDate: "2023-07-06T00:00:00Z", dsun: 216.774285, hgln: -0.120946, hglt: 3.376948 },
  { isoDate: "2023-07-07T00:00:00Z", dsun: 216.771457, hgln: -0.12858, hglt: 3.481661 },
  { isoDate: "2023-07-08T00:00:00Z", dsun: 216.767385, hgln: -0.136103, hglt: 3.585417 },
  { isoDate: "2023-07-09T00:00:00Z", dsun: 216.762055, hgln: -0.143515, hglt: 3.688191 },
  { isoDate: "2023-07-10T00:00:00Z", dsun: 216.755457, hgln: -0.150813, hglt: 3.789959 },
  { isoDate: "2023-07-11T00:00:00Z", dsun: 216.747579, hgln: -0.157989, hglt: 3.890696 },
  { isoDate: "2023-07-12T00:00:00Z", dsun: 216.73841, hgln: -0.165031, hglt: 3.990377 },
  { isoDate: "2023-07-13T00:00:00Z", dsun: 216.727942, hgln: -0.171924, hglt: 4.088977 },
  { isoDate: "2023-07-14T00:00:00Z", dsun: 216.716167, hgln: -0.178652, hglt: 4.186472 },
  { isoDate: "2023-07-15T00:00:00Z", dsun: 216.70308, hgln: -0.185196, hglt: 4.282838 },
  { isoDate: "2023-07-16T00:00:00Z", dsun: 216.688678, hgln: -0.191536, hglt: 4.37805 },
  { isoDate: "2023-07-17T00:00:00Z", dsun: 216.672959, hgln: -0.197652, hglt: 4.472083 },
  { isoDate: "2023-07-18T00:00:00Z", dsun: 216.655925, hgln: -0.203527, hglt: 4.564915 },
  { isoDate: "2023-07-19T00:00:00Z", dsun: 216.637576, hgln: -0.20914, hglt: 4.65652 },
  { isoDate: "2023-07-20T00:00:00Z", dsun: 216.617917, hgln: -0.214474, hglt: 4.746876 },
  { isoDate: "2023-07-21T00:00:00Z", dsun: 216.59695, hgln: -0.219514, hglt: 4.835958 },
  { isoDate: "2023-07-22T00:00:00Z", dsun: 216.57468, hgln: -0.224244, hglt: 4.923742 },
  { isoDate: "2023-07-23T00:00:00Z", dsun: 216.55111, hgln: -0.228651, hglt: 5.010206 },
  { isoDate: "2023-07-24T00:00:00Z", dsun: 216.526243, hgln: -0.232724, hglt: 5.095325 },
  { isoDate: "2023-07-25T00:00:00Z", dsun: 216.500085, hgln: -0.236452, hglt: 5.179077 },
  { isoDate: "2023-07-26T00:00:00Z", dsun: 216.472644, hgln: -0.239827, hglt: 5.261438 },
  { isoDate: "2023-07-27T00:00:00Z", dsun: 216.443929, hgln: -0.242844, hglt: 5.342386 },
  { isoDate: "2023-07-28T00:00:00Z", dsun: 216.41395, hgln: -0.245499, hglt: 5.421899 },
  { isoDate: "2023-07-29T00:00:00Z", dsun: 216.382721, hgln: -0.247794, hglt: 5.499953 },
  { isoDate: "2023-07-30T00:00:00Z", dsun: 216.350257, hgln: -0.249733, hglt: 5.576526 },
  { isoDate: "2023-07-31T00:00:00Z", dsun: 216.316574, hgln: -0.251329, hglt: 5.651597 }
];
