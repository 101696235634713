import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

import { InputNumberParameterProps } from "../Interfaces";
configure({ enforceActions: "always" });

// Number input field both for NumericParameter and (twice) for RangeParameter

@observer
class InputNumberParameter extends React.Component<InputNumberParameterProps> {
  render() {
    const excludedClassName = this.props.criterion.excluded ? "criterion-input-excluded" : "";
    const className = `${this.props.criterion.type} ${this.props.criterion.afterPrefetchStatus} ${excludedClassName}`;
    const value = this.props.value ?? "";
    return (
      <input
        type="number"
        placeholder={this.props.placeholder}
        value={value}
        className={className}
        onChange={this.props.onValueChange}
        onBlur={this.props.onBlur}
      />
    );
  }
}
export default InputNumberParameter;
