import { observer } from "mobx-react";
import React from "react";
import resultsStore from "../../Stores/ResultsStore";
import searchStore from "../../Stores/SearchStore";
import Button from "../../Utilities/Button";
import ResultsButtonMenu from "./ResultsButtonMenu";

@observer
export default class TopCommandBar extends React.Component {
  Header = () => {
    const className = searchStore.leftPanelCollapsed ? "top-bar top-bar-collapsed" : "top-bar";
    const toggleSelectorsVisibility = {
      id: "middle-button",
      imageClassName: " results-icon",
      path: searchStore.selectorsBlockIsExpanded ? "icons/leftPanelMin.png" : "icons/leftPanelMax.png",
      name: "Show / hide data selectors",
      onClick: () => {
        searchStore.toggleSelectorsVisibility();
      }
    };

    const undoButton = {
      id: "undo-button",
      imageClassName: " results-icon",
      path: "icons/undo.png",
      name: "Undo last change",
      onClick: resultsStore.undoFileChange
    };

    const redoButton = {
      id: "undo-button",
      imageClassName: " results-icon",
      path: "icons/redo.png",
      name: "Redo last change",
      onClick: resultsStore.redoFileChange
    };

    return (
      <header id="top-bar" className={className}>
        <div className="left-section top-bar">
          <img loading="lazy" fetchpriority="low" className="uio-logo" src="icons/UiO.png" alt="UiO-logo" />
          <div>
            <span className="header-text">
              <div className="header-word">Oslo </div>
              <div className="header-word">Solar</div>
              <div className="header-word tweak-left">Virtual</div>
              <div className="header-word">Observatory</div>
              {/* <div className="caveat">[WIP: Use Chrome or Opera]</div> */}
            </span>
          </div>
        </div>
        <div className="base-flexbox-row">
          <Button {...undoButton} />
          <Button {...redoButton} />
          <Button {...toggleSelectorsVisibility} />
        </div>
      </header>
    );
  };

  render() {
    return (
      <div className="top-bar">
        {this.Header()}
        <ResultsButtonMenu />
      </div>
    );
  }
}
