import React, { CSSProperties } from "react";
const Info = ({ currentDateMsec }) => {
  if (isNaN(currentDateMsec)) {
    return null;
  }
  const currentDate = new Date(currentDateMsec);
  const timeZoneOffset = currentDate.getTimezoneOffset() * 60 * 1000;
  const updatedDate = new Date(currentDateMsec - timeZoneOffset);
  const isoDate = updatedDate.toISOString().replace("T", " ").replace("Z", "");
  const style = {
    position: "absolute",
    top: "4px",
    left: "30px",
    color: "white",
    backgroundColor: "transparent",
    zIndex: 10,
    fontFamily: "Monospace",
    fontWeight: "bold",
    fontSize: "12px"
  } as CSSProperties;
  return <div style={style}>{isoDate}</div>;
};
export default Info;
