import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

configure({ enforceActions: "always" });

interface IProps {
  dateTime: any;
  dragOverClass: string;
  onClick: (any) => void;
  file: any;
}

@observer
class DateTimeCell extends React.Component<IProps> {
  // Observation period/dates are very special cases:
  render() {
    return (
      <td className={"resultCell" + this.props.dragOverClass} onClick={this.props.onClick}>
        <div className={" dateTimeType"}>
          <div className={"dateTimeStartDate"}>{this.props.dateTime.startDate} </div>
          <div className={"dateTimeStartEndTime"}>
            {this.props.dateTime.startTime}&mdash;{this.props.dateTime.endTime}&nbsp;
            {this.props.dateTime.daysOffset}
          </div>
          <div className={"dateTimeDuration"}>{this.props.dateTime.duration}</div>
        </div>
      </td>
    );
  }
}
export default DateTimeCell;
