export const STEIN = false;
//! https://threejs.org/docs/#api/en/core/Raycaster.ray
//! It seems the raycaster's .params.line.threshold = 1 (in world units!)
//! so we can't scale the universe down in numbers - the picking cone would
//! become way too large!
export const universeScaleFactor = 1; //! 1000_000 / 695_500_000; //! MYSTERY!!
// 1 AU = 149,597,870.7 km
// 1 RSUN = 696,340
export const RSUN = (695_500_000 / 1000_000) * universeScaleFactor;
export const DSUN_EARTH = 215 * RSUN;
export const DEGREES_TO_RADIANS = Math.PI / 180;
export const ARCSEC_TO_RADIANS = DEGREES_TO_RADIANS / 3600;
export const RADIANS_TO_DEGREES = 1 / DEGREES_TO_RADIANS;
export const DAYS_TO_MILLISECONDS = 86400000;
export const MILLISECONDS_TO_DAYS = 1 / DAYS_TO_MILLISECONDS;

export const BACKGROUND_PIXELSIZE = RSUN / 100;
export const OBSERVATION_PIXELSIZE = RSUN / 300;
export const LAYER_HEIGHT = (RSUN / 5000) * 1;
export const BORDER_NUM_LAYERS = 70;
export const BORDER_TOP_HEIGHT = BORDER_NUM_LAYERS * LAYER_HEIGHT;
export const UNALLOCATED_LAYER = -1;

export const GLOBE_RENDER_ORDER = 100;
export const GLOBE_CLEAR_DEPTH = false;

// Magic from https://stackoverflow.com/questions/13309289/three-js-geometry-on-top-of-another
// Logic: all images, background, globe etc drawn first,
// then depth info (including corona images) is cleared before a totally
// transparent GLOBE2 is drawn, setting the depth info to represent the globe only
// Then the borders are painted onto the scene, with parts that are behind
// the GLOBE2 not showing.
export const BACKGROUND_RENDER_ORDER = 10000;
export const BACKGROUND_CLEAR_DEPTH = false;

export const GLOBE2_RENDER_ORDER = 10001;
export const GLOBE2_CLEAR_DEPTH = true;

export const BORDER_RENDER_ORDER = 10002;
export const BORDER_CLEAR_DEPTH = false;

export const BORDERCOLOR1 = 0x10ff10;
export const BORDERCOLOR1_HOVERED = 0xffff00; // Bright yellow

const nearLimit = 20 * RSUN; // Why does this need to be so big????
const farLimit = DSUN_EARTH * 1.5 + RSUN;

export const omega0 = 360.0 / 25.05; // One rotation per 25 days => degrees per day
export const omegaPolar = 360.0 / 34.3; // One rotation per 34.3 days => degrees per day
export const deltaOmega = omega0 - omegaPolar;

export const threeCanvasDefaultProps = {
  physCameraX: 0,
  physCameraY: 0,
  physCameraZ: 0,
  physFovWidth: 3.5 * RSUN,

  nearLimit: nearLimit, // Relative to camera position towards world center
  farLimit: farLimit,

  width: 1012,
  height: 1012,

  axisLength: 1.5 * RSUN,
  axisLabelFont: "fonts/helvetiker_bold.typeface.json",
  axisLabelFontSize: (0.3 * RSUN) / 6.955,
  axisLabelFontHeight: (0.05 * RSUN) / 6.995,
  axisLabelOffset: 0.03 * RSUN,

  axisArrowHeadLength: (RSUN / 6.955) * 0.6,
  axisArrowHeadWidth: (0.3 * RSUN) / 6.955,
  axisColor: 0xffff00, //0xe5ff00,

  globeRadius: (1 - 0.005) * RSUN,
  globeWidthSegments: 40,
  globeHeightSegments: 40,
  globeColor: 0x303030,
  globeWireframeColor: 0x454545,

  containerDivId: "ThreeSunContainerDiv",
  className: "ThreeCanvas"
};

export const orbitControlProps = {
  minDistance: nearLimit + 1.05 * RSUN,
  maxDistance: 1.5 * DSUN_EARTH,
  zoomSpeed: 0.3,
  enablePan: true
};

export const quasiFitsAndGeometryParameters = {
  NAME: "",
  HGLN_OBS: 0,
  HGLT_OBS: 0,
  CROTA: 0,
  hglt_obs: 0,
  hgln_obs: 0,
  naxis1: 0,
  naxis2: 0,
  crpix1: 0,
  crpix2: 0,
  crval1: 0,
  crval2: 0,
  cdelt1: 0,
  cdelt2: 0,
  b: 0, // Effective projection sphere radius (incl. layering)
  c: 0, // DSUN_OBS
  coronaPlaneZCoord: 0,
  dateBeg: 0,
  PCx_x: 0,
  PCx_y: 0,
  PCy_x: 0,
  PCy_y: 0
};
