import * as THREE from "three";

import { MILLISECONDS_TO_DAYS, RSUN, UNALLOCATED_LAYER } from "../../utilities/three-constants";
import File from "../../../../classes/File";
import CommonQuasiFitsAndGeometryParameters from "./CommonQuasiFitsAndGeometryUtilities";
import { Border } from "./Border";
import { ThreeImage } from "./ThreeImage";
import layerManager from "../../LayerManager";
import { myEncodeURI, MyLogger } from "../../../../Utilities/Utilities";

// An observation has the following attributes:
//  NAME  XCEN  YCEN  FOVX  FOVY  CROTA  DSUN_OBS  HGLN_OBS  HGLT_OBS, "DATE-BEG" URL

export default class ThreeObservation {
  private disposables = [] as any[];
  name;
  q: CommonQuasiFitsAndGeometryParameters;
  private scene;
  private displayDateMsec;
  private textureLoader = new THREE.TextureLoader();
  private uvTexture: THREE.Texture | undefined;
  private textureCache = new Map() as Map<string, THREE.Texture>;

  public props;
  public file: File | null;
  public border: Border;
  public corona: ThreeImage;
  public surface: ThreeImage;
  public deltaTimeDays = 0;

  // Storing current state of the observation
  selectionImageUrl = "";

  // TODO: Double check that dispose() frees up resources.
  // TODO: Prevent Surface/Corona from being differentially rotated when not visible
  // TODO: Control: make callbacks (in general)
  // TODO: Control: Switch between fast/slow scrolling

  private constructor(props, scene: THREE.Scene, file: File | null) {
    this.props = props; // CommonQuasiFitsAndGeometryParameters will need this when changing layer
    this.name = props.NAME;
    this.scene = scene;
    this.file = file;

    this.q = new CommonQuasiFitsAndGeometryParameters({ observation: this, ...props }, UNALLOCATED_LAYER);
    this.displayDateMsec = this.q.dateBegMsec;

    const startTime = Date.now();
    this.border = new Border(this.q, this.name + " border", this.scene, this);
    const time1 = Date.now() - startTime;
    const time2 = Date.now() - startTime - time1;
    this.disposables.push(this.border);

    const { naxis1, naxis2 } = this.q;
    console.log(`ThreeObs: ${this.name}, ${naxis1}x${naxis2}: ${time1}/${time2} ms, ${this.q.layer}`);
  }

  public setObservationDisplayDateMsec = displayDateMsec => {
    this.displayDateMsec = displayDateMsec;
    this.deltaTimeDays = (this.displayDateMsec - this.q.dateBegMsec) * MILLISECONDS_TO_DAYS;
    this.border?.applyDeltaTimeDays(this.deltaTimeDays);
    this.surface?.applyDeltaTimeDays(this.deltaTimeDays);
    this.corona?.applyDeltaTimeDays(this.deltaTimeDays);
  };

  public setLayer = newLayer => {
    if (newLayer === this.q.lastLayer) {
      this.q.layer = newLayer;
      return;
    }
    const oldB = this.q.b;
    this.q.calculateProps(newLayer);

    this.corona?.setImageLayer(newLayer, 0);
    this.surface?.setImageLayer(newLayer, oldB);
    // if (!this.props.isBackground) {
    // const arr = this.surface.mesh.geometry.attributes.position.array;
    // const r2 = arr[0] * arr[0] + arr[1] * arr[1] + arr[2] * arr[2];
    // const r = Math.sqrt(r2);
    // const rRSUN = r / RSUN;
    // const oldBRSUN = oldB / RSUN;
    // const currentBRSUN = this.q.b / RSUN;
    // MyLogger.red(`${this.name} setLayer: ${oldBRSUN} -> ${currentBRSUN} (${rRSUN}) (layer ${newLayer})`);
    // }
  };

  public setBorderLayer = newLayer => {
    this.border.setBorderLayer(newLayer);
  };

  private setImageVisibility = (imageVisibilityStatus: boolean, theOne: ThreeImage, theOther: ThreeImage) => {
    if (imageVisibilityStatus && this.q.layer === -1) {
      this.setLayer(layerManager.allocateLayer(this));
    }
    theOne.setImageVisibility(imageVisibilityStatus);
    const noImageMeshIsVisible = !(theOne.mesh?.visible || theOther.mesh?.visible);
    if (noImageMeshIsVisible && this.q.layer !== UNALLOCATED_LAYER) {
      layerManager.freeLayer(this);
      this.q.layer = UNALLOCATED_LAYER;
    }
  };

  private lastCoronaVisibilityStatus = false;
  private lastSurfaceVisibilityStatus = false;

  public setCoronaVisibility = (coronaVisibilityStatus: boolean) => {
    this.setImageVisibility(coronaVisibilityStatus, this.corona, this.surface);
  };
  public setSurfaceVisibility = (surfaceVisibilityStatus: boolean) => {
    this.setImageVisibility(surfaceVisibilityStatus, this.surface, this.corona);
  };
  public setBorderVisibility = (borderVisibilityStatus: boolean) => {
    this.border.setBorderVisibility(borderVisibilityStatus);
  };

  flashBorder = () => {
    this.border.flash();
  };

  public fadeAway = () => {
    this.lastCoronaVisibilityStatus = this.corona.mesh?.visible;
    this.lastSurfaceVisibilityStatus = this.surface.mesh?.visible;
    this.setSurfaceVisibility(false);
    this.setCoronaVisibility(false);
    this.setBorderVisibility(false);
  };

  public fadeBack = () => {
    this.setSurfaceVisibility(this.lastSurfaceVisibilityStatus);
    this.setCoronaVisibility(this.lastCoronaVisibilityStatus);
    this.setBorderVisibility(true);
  };

  public setBorderHovered = (hovered: boolean) => this.border.setBorderHovered(hovered);

  private setDisplayImage = (imageUrl: string) => {
    if (this.textureCache.has(imageUrl)) {
      this.uvTexture = this.textureCache.get(imageUrl);
    } else {
      this.uvTexture = this.textureLoader.load(imageUrl);
      this.textureCache.set(imageUrl, this.uvTexture);
    }
    if (this.uvTexture) {
      this.corona.setUvTexture(this.uvTexture);
      this.surface.setUvTexture(this.uvTexture);
    }
  };

  public showImages = imageUrl => {
    this.setDisplayImage(imageUrl);
    this.setCoronaVisibility(true);
    this.setSurfaceVisibility(true);
  };

  public hideImages = () => {
    this.setCoronaVisibility(false);
    this.setSurfaceVisibility(false);
  };

  dispose = () => this.disposables.forEach(d => d.dispose());

  uvTexturePromise: Promise<THREE.Texture> | null = null;

  private createEmptyImageObjects = async () => {
    if (this.uvTexturePromise) {
      MyLogger.bigBlue("createEmptyImageObjects already in progress, returning", this.name);
      return;
    }
    const imageUrl = this.file?.selectionImageUrl || this.props.backgroundImageUrl;

    // Only prefetch texture for backgrounds?
    if (this.props.isBackground) {
      this.uvTexturePromise = this.textureLoader.loadAsync(imageUrl);
      this.uvTexture = await this.uvTexturePromise; // uvTexturePromise; // Prefetch
      this.uvTexturePromise = null;
    }

    const commonProps = {
      q: this.q,
      scene: this.scene,
      uvTexture: this.uvTexture,
      threeObservationReference: this
    };
    this.surface = new ThreeImage({ ...commonProps, type: "surface", name: this.name + " surface" });
    this.corona = new ThreeImage({ ...commonProps, type: "corona", name: this.name + " corona" });
    this.disposables.push(this.corona);
    this.disposables.push(this.surface);
  };

  currentCenterOfGravityVertex = () => {
    return this.q.currentCenterOfGravityVertex(this.deltaTimeDays);
  };

  // You don't alway want to await, but here's the idea:
  // observation = await observationFactory(...)
  static observationFactory = async (props, scene, file: File | null) => {
    let fetchedProps;
    if (props.jsonUrl) {
      const response = await fetch(myEncodeURI(props.jsonUrl));
      const result = await response;
      fetchedProps = await result.json();
      fetchedProps.DSUN_OBS *= RSUN; // JSON file specifies DSUN_OBS in units of RSUN
      fetchedProps.DSUN_OBS *= 1.001; // Why!!
    }
    const patchedObservationProps = { ...props, ...fetchedProps };
    const observation = new ThreeObservation(patchedObservationProps, scene, file);
    await observation.createEmptyImageObjects();
    return observation;
  };
}
