import "raf/polyfill";
import { action, reaction, configure, computed, observable, makeObservable, toJS } from "mobx";

import File from "../classes/File";
import { THUMBWIDTH_INITIAL_VALUE, TRUE_DBSERVER } from "./../constants";
import searchStore from "./SearchStore";
import appStore from "./AppStore";
import {
  createCSSSelector,
  macKeys,
  MyLogger,
  parseDate,
  removeCSSSelector,
  elementYcenter,
  urlAsCssClassName
} from "../Utilities/Utilities";
import threeStore from "./ThreeStore";
import layerManager from "../components/ThreeSpan/LayerManager";

configure({ enforceActions: "always" });

const defaultResultsTableColumns = [
  { name: "timespan", type: "date", description: "Obs. period", fullDescription: "Observation period" },
  { name: "INSTRUME", type: "text", description: "Instr.", fullDescription: "Instrument" },
  { name: "fov", type: "nowrap", description: "FOV", fullDescription: "Field of view" },
  { name: "EXPTIME", type: "number", description: "Exp.time", fullDescription: "Exposure time" },
  { name: "OBSTITLE", type: "text", description: "Obs.title.", fullDescription: "Observation title" },
  { name: "OBS_DEC", type: "text", description: "Obs.descr.", fullDescription: "Observation description" },
  { name: "E__SLIT_ID", type: "text", description: "Slit ID", fullDescription: "Slit ID" },
  { name: "images", type: "images", description: "Images", fullDescription: "Images" }
];

export class ResultsStore {
  constructor() {
    makeObservable(this);
    defaultResultsTableColumns.forEach(column => {
      this.availableResultsColumns[column.name] = column;
    });
    this.initializeResultsTableColumns(defaultResultsTableColumns); // Must be in @action
    this.setGlobalHandlers();
  }

  setGlobalHandlers = () => {
    if (appStore) {
      appStore.globalKeyDownHandlers.push({ id: "resultsStore", function: this.globalKeyHandler });
      appStore.globalKeyUpHandlers.push({ id: "resultsStore", function: this.globalKeyHandler });
      appStore.globalOnMouseMoveHandlers.push({ id: "resultsStore", function: this.globalMouseMoveHandler });
      appStore.globalOnScrollHandlers.push({ id: "resultsStore", function: this.globalScrollHandler });
    } else {
      setTimeout(() => this.setGlobalHandlers(), 100);
    }
  };

  public resultsComponentForForcedUpdate;
  public divTriggeringOverlay;
  public overlayBoxFile: File;
  public resultsTableColumns = observable.array();
  public availableResultsColumns = {};
  public currentResultsRowDiv;
  public currentResultsRowFile;
  public contextMenuFile;
  public lastSelectedFile;
  public penultimateSelectedFile;
  public selectionStartFile;
  public cmdKeyTemporarilyShowImageIsActive = false;
  public mousePointerIsMovingTowardsImagesOverlay = true;

  private currentFOVBackground;

  private fileChangesHistory = new Map();
  private maxHistoryKey = 0;
  private currentHistoryKey = 0;
  private currentMousePosition = { x: 0, y: 0 };

  @observable
  files = new Map() as Map<string, File>;

  @observable
  iconImagesOverlayIsActive = false;

  @observable
  fullSizeImageUrl = "";

  @observable
  fullSizeImageOverlayIsActive = false;

  @observable
  finishedLoading = false;

  @observable
  dragoverColumn = "";

  @observable
  draggedColumn = "";

  @observable
  thumbWidth = THUMBWIDTH_INITIAL_VALUE;

  @observable
  statisticsFromRequests = {};

  @observable
  helpOverlayIsActive = false;

  @observable
  manageColumnsOverlayIsActive;

  @observable
  settingsOverlayIsActive = false;

  @observable
  showSelected: boolean = true;

  @observable
  showUnmarked: boolean = true;

  @observable
  showHidden: boolean = false;

  @observable
  showCart: boolean = false;

  @observable
  showTrash: boolean = false;

  @observable
  sortOrder = "DESC";

  @observable
  sortColumn = "timespan";

  @observable
  iconImagesOverlayGeometry;

  @observable
  contextMenuEvent: MouseEvent;

  @observable
  baseFileForCanvas;

  @observable
  timeSpanForCanvas = 1000 * 60 * 60 * 1;

  @observable
  timeCenterPinpointIsActive = false;

  @observable
  selectionInProgress = false;

  @computed
  get computeFilesList() {
    const columnType = this.resultsTableColumns.find(column => column.name === this.sortColumn)?.type;
    return Array.from(this.files.values()).sort((fileA, fileB) =>
      fileA.compareWithFile(fileB, this.sortOrder, this.sortColumn, columnType)
    ) as File[];
  }

  @computed
  get computeValidResults() {
    const maxIndex = this.computeHighestIndexOfValidResults;
    return this.computeFilesList.filter((file, index) => index <= maxIndex);
  }

  fileShouldBeVisible = (file: File, { ignoreSelectionStatus } = { ignoreSelectionStatus: false }) => {
    const effectiveSelection = ignoreSelectionStatus ? false : file.selected;
    if (this.showSelected && effectiveSelection) {
      return true;
    }
    if (this.showHidden && file.hidden) {
      return true;
    }
    if (this.showCart && file.inCart) {
      return true;
    }
    if (this.showTrash && file.inTrash) {
      return true;
    }
    const unmarked = !file.inCart && !file.inTrash && !file.hidden && !effectiveSelection;
    if (this.showUnmarked && unmarked) {
      return true;
    }
    return false;
  };

  @computed
  get computeFilteredData() {
    const filteredData = this.computeValidResults.filter(
      file => this.fileShouldBeVisible(file) && file.hasActiveSelector()
    );
    return filteredData;
  }

  // TODO: Somehow we should have a reaction that clears the
  // selected status of files that go out of view due to a show/hide button,
  // but this is quite difficult to formulate. Forget it for now, please!
  // -----------------------------------------------------------
  // @action removeSelectionStatusForInvisibleFiles = () => {
  //   this.computeValidResults.forEach(file => {
  //     if (!this.fileShouldBeVisible(file, { ignoreSelectionStatus: true })) {
  //       file.removeFileStatus("selected");
  //     }
  //   });
  // };

  @computed
  get computeNumberOfSelectedFiles() {
    return this.computeFilteredData.filter(file => file.selected).length;
  }

  @computed
  get computeResultsForThree() {
    const filteredData = this.computeFilteredData;
    if (filteredData.length === 0) {
      return [];
    }
    if (!this.baseFileForCanvas) {
      this.setBaseFileForCanvas(filteredData[0]);
      threeStore.three?.setBackground(filteredData[0].fovAndImagesData.fovBackgroundImageUrl);
      //! This gives the red borders!
      //resultsStore.changeBacgroundCssClassName(filteredData[0].fovAndImages.fovBackgroundUrl);
      const dateMsec = Date.parse(filteredData[0].columnValuesByName["DATE-BEG"]);
      threeStore.storeNextAnimationFrameDisplayDateMsec(dateMsec);
    }
    return filteredData.filter(file => this.fileInsideTimeSpanForCanvas(file));
  }

  @computed
  get computeCumulativeGroups() {
    let cumulativeGroups = 0;
    for (const selectorId in this.statisticsFromRequests) {
      if (this.statisticsFromRequests[selectorId]) {
        cumulativeGroups += this.statisticsFromRequests[selectorId].groups_matching;
      }
    }
    return cumulativeGroups;
  }

  // Accessing proxies for arrays directly doesn't actually give an array, due to the MobX magic
  @computed
  get computeResultsTableColumnsAsArray() {
    return [...this.resultsTableColumns];
  }

  @computed
  get computeResultsSpanSpinnerIsActive() {
    return searchStore.computeSearchIsInProgress;
  }

  @computed
  get computeSelected() {
    return this.computeValidResults.filter(file => file.selected);
  }

  @computed
  get computeUnselected() {
    return this.computeFilesList.filter(file => !file.selected);
  }

  @computed
  get computeAllApplicableFilesForCurrentMousePosition() {
    if (appStore.mouseIsOver("canvas") && this.computeResultsForThree.length > 0) {
      return this.computeResultsForThree;
    }
    const globalCriteriaIsHovered = searchStore.computeGlobalCriteria.mouseIsOverSelector;
    const mouseIsOverResultsList = this.computeValidResults.some(file => file.fileIsHovered);
    if (mouseIsOverResultsList || globalCriteriaIsHovered) {
      return this.computeValidResults;
    }
    const selectorFiles = this.computeValidResults.filter(file => file.selectorIsHovered);
    if (selectorFiles.length > 0) {
      return selectorFiles;
    }
    return [];
  }

  @computed
  get computeSelectedFilesForCurrentMousePosition() {
    return this.computeAllApplicableFilesForCurrentMousePosition.filter(file => file.selected);
  }

  @computed
  get computeHoveredFilesForCurrentMouseOverPosition() {
    if (appStore.mouseIsOver("canvas") && this.computeResultsForThree.length > 0) {
      return this.computeHoveredObservations;
    }
    const globalCriteriaIsHovered = searchStore.computeGlobalCriteria.mouseIsOverSelector;
    if (globalCriteriaIsHovered) {
      return this.computeValidResults;
    }
    const hoveredFileInResultsList = this.computeValidResults.filter(file => file.fileIsHovered);
    if (hoveredFileInResultsList.length > 0) {
      return hoveredFileInResultsList;
    }
    const selectorFiles = this.computeValidResults.filter(file => file.selectorIsHovered);
    return selectorFiles;
  }

  @computed
  get computeHighestIndexOfValidResults() {
    let maxIndex = this.computeFilesList.length - 1;
    if (maxIndex < 0) {
      return 0;
    }
    const setMaxIndex = index => (maxIndex = index);
    const selectorsToCheck = new Map();
    searchStore.selectors
      .filter(selector => !selector.allDataIsReceived())
      .forEach(selector => selectorsToCheck.set(selector.id, true));
    for (let index = this.computeFilesList.length - 1; index >= 0; index--) {
      const file = this.computeFilesList[index];
      file.selectors.forEach(selector => {
        if (selectorsToCheck.has(selector.id)) {
          selectorsToCheck.delete(selector.id);
          setMaxIndex(index);
        }
      });
    }
    return maxIndex;
  }

  @computed
  get computePinpointFileIsVisible() {
    if (!this.timeCenterPinpointIsActive) {
      return false;
    }
    const isVisible = this.computeFilteredData.some(file => file === this.baseFileForCanvas);
    return isVisible;
  }

  @computed
  get computeHoveredObservations() {
    const hovered = this.computeFilteredData.filter(file => file.isHovered);
    return hovered;
  }

  @action
  public addStatistics = (id, statistics) => (this.statisticsFromRequests[id] = statistics);

  @action
  public setDragOverColumn = dragOverColumn => (this.dragoverColumn = dragOverColumn);

  @action
  public setDraggedColumn = draggedColumn => (this.draggedColumn = draggedColumn);

  @action
  public toggleShowSelected = () => {
    this.showSelected = !this.showSelected;
    searchStore.syncToUrlAndLocalStorage();
  };

  @action
  public toggleShowUnmarked = () => {
    this.showUnmarked = !this.showUnmarked;
    searchStore.syncToUrlAndLocalStorage();
  };

  @action
  public toggleShowHidden = () => {
    this.showHidden = !this.showHidden;
    searchStore.syncToUrlAndLocalStorage();
  };

  @action
  public toggleShowCart = () => {
    this.showCart = !this.showCart;
    searchStore.syncToUrlAndLocalStorage();
  };

  @action
  public toggleShowTrash = () => {
    this.showTrash = !this.showTrash;
    searchStore.syncToUrlAndLocalStorage();
    if (!this.showTrash) {
      this.computeFilesList.forEach(file => {
        if (file.inTrash) {
          file.removeFileStatus("selected");
        }
      });
    }
  };

  @action
  public showIconImagesOverlay = () => {
    this.iconImagesOverlayIsActive = true;
  };

  @action
  public hideIconImagesOverlay = () => {
    this.iconImagesOverlayIsActive = false;
  };

  @action
  public setFullSizeImage = image => (this.fullSizeImageUrl = image);

  @action
  public showFullSizeImageOverlay = () => {
    this.fullSizeImageOverlayIsActive = true;
    appStore.activeOverlays.set("fullSizeImageOverlay", this.hideFullSizeImageOverlay);
  };

  @action
  public hideFullSizeImageOverlay = () => {
    this.fullSizeImageOverlayIsActive = false;
    appStore.activeOverlays.delete("fullSizeImageOverlay");
  };

  @action
  public setThumbWidth = width => {
    if (this.thumbWidth !== width) {
      this.thumbWidth = width;
    }
  };

  @action
  public cancelDragging = () => (this.dragoverColumn = this.draggedColumn = "");

  @action
  public dropOnColumn(toColumn) {
    const draggedColumn = this.draggedColumn;
    this.cancelDragging();
    if (draggedColumn === toColumn || toColumn === "" || draggedColumn === "") {
      return;
    }
    const fromIndex = this.resultsTableColumns.findIndex(header => header.name === draggedColumn);
    const headerElementToBeMoved = this.resultsTableColumns[fromIndex];
    this.resultsTableColumns.splice(fromIndex, 1);
    const toIndex = this.resultsTableColumns.findIndex(header => header.name === toColumn);
    this.resultsTableColumns.splice(toIndex, 0, headerElementToBeMoved);
  }

  @action
  public async handleNewFile(newFile: File, selector) {
    const oldFile = this.files.get(newFile.id);
    if (oldFile) {
      // File exists from other selector, tell it that this selector found it also.
      oldFile.addSelector(selector);
      // Patch in any new values if columns have been added
      for (const column in newFile.columnValuesByName) {
        oldFile.columnValuesByName[column] = newFile.columnValuesByName[column];
      }
      return;
    }
    newFile.addSelector(selector);
    this.files.set(newFile.id, newFile);
    if (threeStore.three) {
      threeStore.three.preloadBackgroundImage(newFile.fovAndImagesData.fovBackgroundImageUrl);
    }
  }

  @action removeFile = Id => {
    this.files.delete(Id);
  };

  @action
  public removeSelectorFromResults = selector => {
    this.files.forEach(file => {
      file.removeSelector(selector);
    });
  };

  @action removeStatusFromFiles = (files: File[], status: string) => {
    this.removeUnusedHistoryElements();
    this.currentHistoryKey = ++this.maxHistoryKey;
    files.forEach(file => {
      file.removeFileStatus(status);
    });
  };

  @action
  public setStatusForFiles = (files: File[], status: string) => {
    this.removeUnusedHistoryElements();
    this.currentHistoryKey = ++this.maxHistoryKey;
    files.forEach(file => {
      file.setFileStatus(status);
    });
  };

  @action
  public addToSelection = (files: File[]) => this.setStatusForFiles(files, "selected");

  @action
  public removeStatusesFromFiles = (files: File[], markings: string[]) => {
    markings.forEach(mark => this.removeStatusFromFiles(files, mark));
  };

  @action
  public removeFromSelection = (files: File[]) => this.removeStatusesFromFiles(files, ["selected"]);

  @action
  private initializeResultsTableColumns = defaultResultsTableColumns =>
    this.resultsTableColumns.replace(defaultResultsTableColumns);

  @action
  public removeColumn = columnToRemoveName => {
    const index = this.resultsTableColumns.findIndex(column => column.name === columnToRemoveName);
    if (index >= 0) {
      this.resultsTableColumns.splice(index, 1);
    }
  };

  @action
  public addColumn = columnToAdd => {
    const indexOfImages = this.resultsTableColumns.findIndex(column => column.name === "images");
    if (indexOfImages === -1) {
      this.resultsTableColumns.push(columnToAdd);
    }
    this.resultsTableColumns.splice(indexOfImages, 0, columnToAdd);
  };

  @action
  public showHelpOverlay = () => {
    this.helpOverlayIsActive = true;
    appStore.activeOverlays.set("helpOverlay", this.hideHelpOverlay);
  };

  @action
  public hideHelpOverlay = () => {
    this.helpOverlayIsActive = false;
    appStore.activeOverlays.delete("helpOverlay");
  };

  @action
  public showSettingsOverlay = () => {
    this.settingsOverlayIsActive = true;
    appStore.activeOverlays.set("settingsOverlay", this.hideSettingsOverlay);
  };

  @action
  public hideSettingsOverlay = () => {
    this.settingsOverlayIsActive = false;
    appStore.activeOverlays.delete("settingsOverlay");
  };

  @action
  public showManageColumnsOverlay = () => {
    this.manageColumnsOverlayIsActive = true;
    appStore.activeOverlays.set("manageColumnsOverlay", this.hideManageColumnsOverlay);
  };

  @action
  public hideManageColumnsOverlay = () => {
    this.manageColumnsOverlayIsActive = false;
    appStore.activeOverlays.delete("manageColumnsOverlay");
  };

  @action
  public toggleSortOrder = () => {
    this.sortOrder = this.sortOrder === "ASC" ? "DESC" : "ASC";
    searchStore.generalSearch();
  };

  @action setSortOrder = order => {
    if (this.sortOrder === order) {
      return;
    }
    this.sortOrder = order;
    searchStore.generalSearch();
  };

  @action setSortColumn = column => {
    this.sortColumn = column;
    searchStore.generalSearch();
  };

  public removeFromSelectionByIndices = indices =>
    this.removeFromSelection(indices.map(index => this.computeFilesList[index]));

  public setDivTriggeringOverlay = sourceDiv => (this.divTriggeringOverlay = sourceDiv);

  public initializeOverlayBoxData = (file: File) => {
    this.overlayBoxFile = file;
    const overlayDataImages = [...file.fovAndImagesData.images];
    if (overlayDataImages.length > 0) {
      this.captureOverlayDataImageSize(overlayDataImages[0].url);
    }
  };

  @action
  setIconImagesOverlayGeometry = geometry => (this.iconImagesOverlayGeometry = geometry);

  @action
  setContextMenuData = (event, file) => {
    this.contextMenuEvent = event;
    this.contextMenuFile = file;
  };

  @action
  setContextMenuFile = file => {
    this.contextMenuFile = file;
  };

  public initializeFullSizeImageData = image => {
    this.setFullSizeImage(image);
  };

  public imagePromise = image => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = () => {
        MyLogger.red("Image failed to load:", image);
        resolve({ width: 0, height: 0 });
      };
    });
  };

  @action
  setBaseFileForCanvas = file => {
    this.baseFileForCanvas = file;
  };

  @action
  setTimeSpanForCanvas = timeSpan => (this.timeSpanForCanvas = timeSpan);

  @action
  setTimeCenterPinpointIsActive = status => {
    this.timeCenterPinpointIsActive = status;
  };

  @action
  toggleAllFilesSelectionStatus = () => {
    layerManager.freeze();
    const allSelected = this.computeFilteredData.every(file => file.selected);
    if (allSelected) {
      this.removeFromSelection(this.computeFilteredData);
    } else {
      this.addToSelection(this.computeFilteredData);
    }
    layerManager.unfreeze();
  };

  @action
  setSelectionInProgress = (selectionInProgress: boolean) => {
    this.selectionInProgress = selectionInProgress;
  };

  @action
  clearHoveredStatus = () => {
    this.computeValidResults.forEach(file => {
      file.fileIsHovered = false;
    });
  };

  public toggleStatusForFiles = (files: File[], status) => {
    if (this.allFilesHaveGivenStatus(files, status)) {
      this.removeStatusFromFiles(files, status);
    } else {
      this.setStatusForFiles(files, status);
    }
  };

  public downloadFiles = () => {
    const onlyCartIsVisible =
      this.showCart && !this.showSelected && !this.showUnmarked && !this.showHidden && !this.showTrash;
    if (!onlyCartIsVisible) {
      this.showSelected && this.toggleShowSelected();
      this.showUnmarked && this.toggleShowUnmarked();
      this.showHidden && this.toggleShowHidden();
      this.showTrash && this.toggleShowTrash();
      !this.showCart && this.toggleShowCart();
      return;
    }
    const files = this.computeValidResults.filter(file => file.inCart);
    if (files.length === 0) {
      return;
    }
    const selectedFileNames = files.map(file => file.id).join(",");
    window.open(`${TRUE_DBSERVER}/search/filehandling?SQ_IUMODE1=${selectedFileNames};G=IUMODE1`);
  };

  public addCriteriaDefinitionsToAvailableResultsColumns = criteriaToAdd => {
    for (const criterionName in criteriaToAdd) {
      this.availableResultsColumns[criterionName] = criteriaToAdd[criterionName];
    }
  };

  @action
  public setResultsTableColumnsFromUrl = () => {
    const showColumnsMatch = /[?&;](s=[^&;]+)/.exec(searchStore.originalUrl);
    if (showColumnsMatch) {
      const columnNames = showColumnsMatch[1].split("=")[1].split(",");
      const columns = columnNames.map(columnName => this.availableResultsColumns[columnName]);
      this.resultsTableColumns.replace(columns);
    }
  };

  @action
  public setSortingParametersFromUrl = () => {
    const sortingParametersMatch = /[?&;](o=[^&;]+)/.exec(searchStore.originalUrl);
    if (sortingParametersMatch) {
      const sortingParameters = sortingParametersMatch[1].split("=")[1].split(",");
      const sortColumn = sortingParameters[0];
      const sortOrder = sortingParameters[1];
      this.sortColumn = sortColumn;
      this.sortOrder = sortOrder;
    }
  };

  @action
  public setVisibilityControlsFromUrl = () => {
    const visibilityParametersMatch = /[?&;](v=[^&;]+)/.exec(searchStore.originalUrl);

    if (visibilityParametersMatch) {
      const visibilities = visibilityParametersMatch[1].split("=")[1].split(",");
      if (visibilities.includes("selected")) {
        this.showSelected = true;
      }
      if (visibilities.includes("unmarked")) {
        this.showUnmarked = true;
      }
      if (visibilities.includes("hidden")) {
        this.showHidden = true;
      }
      if (visibilities.includes("trash")) {
        this.showTrash = true;
      }
      if (visibilities.includes("cart")) {
        this.showCart = true;
      }
    }
  };

  public removeStatistics = id => {
    if (this.statisticsFromRequests[id]) {
      this.statisticsFromRequests[id] = undefined;
    }
  };

  public purgeOutdatedFiles = () => {
    setInterval(() => {
      this.files.forEach(file => {
        const fileAge = Date.now() - file.lastAccessDate;
        if (!file.isAssociated() && fileAge > 10000) {
          this.removeFile(file.id);
        }
      });
    }, 1000);
  };

  public selectorsWithIncompleteDataOnNewPage = selectorsWithDataToReceive => {
    const selectorsToReturn = selectorsWithDataToReceive.filter(
      selector =>
        this.highestIndexOfSelectorInResults(selector) <
        this.computeFilteredData.length + searchStore.numberOfResultsInRequest
    );
    if (selectorsToReturn.length === 0) {
      return null;
    }
    return selectorsToReturn;
  };

  public changeBacgroundCssClassName = fovBackgroundUrl => {
    if (!fovBackgroundUrl) {
      return;
    }
    const className = urlAsCssClassName(fovBackgroundUrl);
    if (this.currentFOVBackground) {
      removeCSSSelector(this.currentFOVBackground);
    }
    createCSSSelector(`.${className}`, `border-color: red`);
    this.currentFOVBackground = "." + className;
  };

  public scrollToFile = file => {
    const actionCellId = "action-cell-" + file.id;
    const fileElement = document.getElementById(actionCellId);
    if (fileElement) {
      fileElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  };

  public flashFile = file => {
    file.flashMeInResults = true;
    setTimeout(() => {
      file.flashMeInResults = false;
    }, 1500);
  };

  public resetProtoSelection = () => {
    this.computeFilteredData.forEach(file => (file.protoSelected = false));
  };

  public setProtoSelection = file => {
    if (!this.selectionStartFile) {
      return;
    }
    const indexOfFile = this.computeFilteredData.findIndex(result => result.id === file.id);
    const indexOfStartFile = this.computeFilteredData.findIndex(result => result.id === this.selectionStartFile.id);
    const lowerIndex = Math.min(indexOfFile, indexOfStartFile);
    const higherIndex = Math.max(indexOfFile, indexOfStartFile);
    this.computeFilteredData.forEach((file, index) => {
      if (index >= lowerIndex && index <= higherIndex) {
        file.protoSelected = true;
      } else {
        file.protoSelected = false;
      }
    });
  };

  private currentFilesToAddOrRemove = () => {
    const newFilesToSync = this.computeResultsForThree;
    if (newFilesToSync.length === 0) {
      MyLogger.red("No files in list to sync to three, verify that this is expected");
    }
    const newFilesToSyncMap = new Map<File, boolean>(newFilesToSync.map(file => [file, true]));
    const commonFilesMap = new Map();
    const filesToRemove = [] as File[];
    threeStore.three.onCanvasObservations.forEach(observation => {
      if (newFilesToSyncMap.has(observation.file as File)) {
        commonFilesMap.set(observation.file, true);
      }
    });
    threeStore.three.onCanvasObservations.forEach(observation => {
      if (!commonFilesMap.has(observation.file)) {
        filesToRemove.push(observation.file as File);
      }
    });
    const filesToAdd = newFilesToSync.filter(file => !commonFilesMap.has(file));
    return { filesToAdd, filesToRemove };
  };

  syncFilesToThreeInProgress = false;
  public syncFilesToThree = ({ calledBack } = { calledBack: false }) => {
    if (!threeStore.three || this.syncFilesToThreeInProgress) {
      return;
    }

    this.syncFilesToThreeInProgress = true;
    let { filesToAdd, filesToRemove } = this.currentFilesToAddOrRemove();
    if (filesToAdd.length === 0 && filesToRemove.length === 0) {
      this.syncFilesToThreeInProgress = false;
      // This is here b/c of race conditions and the fact that the reaction
      // triggering updateObservationsBorderHoveredStatus() is based
      // on resultsStore.computeHoveredObservations, which is *not*
      // necessarily in sync with onCanvasObservations. The correct way
      // would be to make onCanvasObservations an observable in threeStore,
      // and have the reaction be based on that! Later...
      threeStore.three.updateCanvasVisuals();
      return;
    }
    if (filesToRemove.length > 0) {
      layerManager.freeze();
      filesToRemove.forEach(file => threeStore.three.removeObservationFromCanvasByFile(file));
      layerManager.unfreeze();
    }
    filesToAdd.forEach(file => threeStore.three.addObservationToCanvasByFile(file));
    this.syncFilesToThreeInProgress = false;

    // If there are still some files to change, it may because something changed
    // OR we're just waiting for observations to be built and added!
    const { filesToAdd: newFilesToAdd, filesToRemove: newFilesToRemove } = this.currentFilesToAddOrRemove();
    if (newFilesToAdd.length > 0 || newFilesToRemove.length > 0) {
      setTimeout(() => this.syncFilesToThree({ calledBack: true }), 10);
    } else {
      threeStore.three.updateCanvasVisuals();
    }
  };

  public addFileToHistory = (fileStateBefore, fileStateAfter) => {
    if (!this.fileChangesHistory.get(this.maxHistoryKey)) {
      this.fileChangesHistory.set(this.maxHistoryKey, []);
    }
    const currentHistorySet = this.fileChangesHistory.get(this.maxHistoryKey);
    const dataToAdd = {
      before: fileStateBefore,
      after: fileStateAfter
    };
    currentHistorySet.push(dataToAdd);
    this.fileChangesHistory.set(this.maxHistoryKey, currentHistorySet);
  };

  public undoFileChange = () => {
    if (this.currentHistoryKey < 1) {
      return;
    }
    const currentHistorySet = this.fileChangesHistory.get(this.currentHistoryKey);
    currentHistorySet?.forEach(fileChange => {
      const file = this.files.get(fileChange.before.id);
      if (file) {
        file.selected = fileChange.before.selected;
        file.inCart = fileChange.before.inCart;
        file.inTrash = fileChange.before.inTrash;
        file.hidden = fileChange.before.hidden;
      }
    });
    this.currentHistoryKey--;
  };

  public redoFileChange = () => {
    // We do ++1 below, so must add 1 in comparison below
    if (this.currentHistoryKey + 1 >= this.maxHistoryKey) {
      return;
    }
    this.currentHistoryKey++;
    const currentHistorySet = this.fileChangesHistory.get(this.currentHistoryKey);
    currentHistorySet.forEach(fileChange => {
      const file = this.files.get(fileChange.after.id);
      if (file) {
        file.selected = fileChange.after.selected;
        file.inCart = fileChange.after.inCart;
        file.inTrash = fileChange.after.inTrash;
        file.hidden = fileChange.after.hidden;
      }
    });
  };

  public invertSelection = (files?: File[]) => {
    const filesToInvert = files || this.computeFilteredData;
    filesToInvert.forEach(file => {
      file.selected = !file.selected;
    });
  };

  cancelTempCmdKeyActions = () => {
    this.hideTempImageInCanvas(this.currentResultsRowFile);
    this.computeResultsForThree.forEach(file => this.hideTempImageInCanvas(file));
  };

  public hideTempImageInCanvas = (file: File) => {
    if (!threeStore.three || !file) {
      return;
    }
    if (file.selected || threeStore.altKeyCmdTemporarilyShowImageIsActive) {
      threeStore.three.showImageByFile(file, file.selectionImageUrl);
    } else {
      threeStore.three.hideImageByFile(file);
    }
  };

  public changeSorting = (sortColumn: string) => {
    console.log("Changing sort", sortColumn, this.sortColumn, this.sortOrder);
    if (this.sortColumn === sortColumn) {
      this.setSortOrder(this.sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      this.setSortColumn(sortColumn);
    }
  };

  private captureOverlayDataImageSize = async imageToCapture => {
    const image = (await this.imagePromise(imageToCapture)) as HTMLImageElement;
    this.setThumbWidth(image.width);
  };

  private highestIndexOfSelectorInResults = inspectedSelector => {
    if (!inspectedSelector) return;
    let highestIndex;
    // TODO: Could be simplified with .findLastIndex or something
    for (let index = this.computeFilesList.length - 1; index >= 0; index--) {
      const file = this.computeFilesList[index];
      if (file.selectors.has(inspectedSelector.id)) {
        highestIndex = index;
        break;
      }
    }
    return highestIndex;
  };

  public handleEscapePressed = () => {
    // We use if statements to prevent unnecessary re-rendering
    // from unnecessary assignments
    if (this.fullSizeImageOverlayIsActive) {
      this.hideFullSizeImageOverlay();
      return;
    }
    if (this.iconImagesOverlayIsActive) {
      this.hideIconImagesOverlay();
    }
  };

  globalKeyHandler = ev => {
    const { directionalFullCleanedCode } = macKeys(ev);
    if (directionalFullCleanedCode === "Escape.down") {
      this.handleEscapePressed();
    }
  };

  public allFilesHaveGivenStatus = (files: File[], status: string) => {
    if (files.length === 0) return false;
    return files.every(file => file.getFileStatus(status));
  };

  public toggleSelected = (files: File[]) => {
    const numberOfSelected = files.filter(file => file.selected).length;
    const allSelected = numberOfSelected === files.length;
    if (allSelected)
      files.forEach(file => {
        file.selected = false;
      });
    else
      files.forEach(file => {
        file.selected = true;
      });
  };

  public removeUnusedHistoryElements = () => {
    if (this.currentHistoryKey !== this.maxHistoryKey) {
      for (let i = this.maxHistoryKey; i > this.currentHistoryKey; i--) {
        this.fileChangesHistory.delete(i);
      }
    }
    this.maxHistoryKey = this.currentHistoryKey;
  };

  public handlePinUnpinTime = file => {
    if (this.timeCenterPinpointIsActive && this.baseFileForCanvas === file) {
      this.setTimeCenterPinpointIsActive(false);
      return;
    }
    this.setBaseFileForCanvas(file);
    this.setTimeCenterPinpointIsActive(true);
  };

  public altCmdKeyTemporarilyShowImages = () => {
    this.computeResultsForThree.forEach(file => {
      if (!file.selected) {
        threeStore.three.showImageByFile(file, file.selectionImageUrl);
      }
    });
  };

  public altCmdKeyTemporarilyHideImages = () => {
    this.computeResultsForThree.forEach(file => {
      if (!file.selected) {
        threeStore.three.hideImageByFile(file);
      }
    });
  };

  public fileInsideTimeSpanForCanvas = file => {
    if (file.mouseIsOverFile) {
      return true;
    }
    const currentFileDateObsEpoch = new Date(parseDate(file.columnValuesByName.DATE_OBS));
    const baseFileDateObsEpoch = new Date(parseDate(this.baseFileForCanvas.columnValuesByName.DATE_OBS));
    return Math.abs(baseFileDateObsEpoch.getTime() - currentFileDateObsEpoch.getTime()) < this.timeSpanForCanvas;
  };

  public queryStringTermForResultsTableColumns = () => {
    const resultsTableColumnsAsArray = this.computeResultsTableColumnsAsArray.map(column => toJS(column));
    const resultsTableColumnNames = resultsTableColumnsAsArray.map(column => column.name);
    const listOfTableColumns = resultsTableColumnNames.join(",");
    return `s=${listOfTableColumns}`;
  };

  public queryStringTermForVisibilityControls = () => {
    let visibilityControls: string[] = [];
    visibilityControls.push(this.showSelected ? "selected" : "");
    visibilityControls.push(this.showUnmarked ? "unmarked" : "");
    visibilityControls.push(this.showHidden ? "hidden" : "");
    visibilityControls.push(this.showCart ? "cart" : "");
    visibilityControls.push(this.showTrash ? "trash" : "");
    visibilityControls = visibilityControls.filter(control => control !== "");
    return `v=${visibilityControls.join(",")}`;
  };

  public queryStringTermForSorting = () => {
    return `o=${this.sortColumn},${this.sortOrder}`;
  };

  resultsTableRowHeight = 64; // pixels; defined in result_list.css as results-table-row-height!!

  interpolateTime = (time1: number, time2: number, fraction: number) => {
    return time1 + (time2 - time1) * fraction;
  };

  handleFirstOrLastRow = (file: File, fractionalPosition) => {
    const time1 = file.timeCenter();
    const time2 = file.timeCenter() + Math.sign(fractionalPosition) * file.timespan() * 0.5;
    const mouseTime = this.interpolateTime(time1, time2, fractionalPosition / 2);
    threeStore.storeNextAnimationFrameDisplayDateMsec(mouseTime);
    return null;
  };

  // TODO: This action should probably be triggered by mousemove + scroll events, giving
  // TODO: ev.clientX, ev.clientY positions. We should then calculate which file based on
  // TODO: magical constants such as resultsTableRowHeight.
  handleOnMouseMoveOverResultRow = (file: File, ev: MouseEvent) => {
    if (this.sortColumn !== "timespan" || this.timeCenterPinpointIsActive) {
      return;
    }
    const filesShown = this.computeFilteredData;
    const index = filesShown.findIndex(result => result.id === file.id);

    // Positive when below center/further down
    const deltaY = ev.clientY - elementYcenter(ev.target);
    const fractionalPosition = deltaY / this.resultsTableRowHeight;
    const isFirstRow = index === 0;
    const isLastRow = index === filesShown.length - 1;
    const interpolateWithinFile = (isFirstRow && fractionalPosition < 0) || (isLastRow && fractionalPosition > 0);
    if (interpolateWithinFile) {
      return this.handleFirstOrLastRow(file, fractionalPosition);
    }

    // Now we know that we are interpolating towards some other file - find it
    const nextIndex = index + Math.sign(fractionalPosition);
    const nextFile = filesShown[nextIndex];
    const thisTime = file.timeCenter();
    const nextTime = nextFile.timeCenter();
    const mouseTime = this.interpolateTime(thisTime, nextTime, Math.abs(fractionalPosition));
    threeStore.storeNextAnimationFrameDisplayDateMsec(mouseTime);
  };

  private previousMousePosition = { x: 0, y: 0 };
  private mouseInDirectionCounter = 0;
  private mouseOutDirectionCounter = 0;

  private mousePointerIsInNewPosition = (ev: any) => {
    const { x, y } = this.previousMousePosition;
    const { clientX, clientY } = ev;
    const [deltaX, deltaY] = [clientX - x, clientY - y];
    return Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2)) > 10;
  };

  // Mouse movement processing for determining whether new image overlay should be triggered

  private isMousePointerMovingTowardsOverlay = (ev, overlayGeometry) => {
    const mouseMoveVector = {
      deltaX: this.previousMousePosition.x - ev.clientX,
      deltaY: this.previousMousePosition.y - ev.clientY
    };
    const mouseMoveAngle = Math.atan2(mouseMoveVector.deltaY, mouseMoveVector.deltaX);
    const overlayTopCornerVector = {
      deltaX: this.previousMousePosition.x - overlayGeometry.right,
      deltaY: this.previousMousePosition.y - overlayGeometry.top
    };
    const overlayTopCornerAngle = Math.atan2(overlayTopCornerVector.deltaY, overlayTopCornerVector.deltaX);
    const overlayBottomCornerVector = {
      deltaX: this.previousMousePosition.x - overlayGeometry.right,
      deltaY: this.previousMousePosition.y - overlayGeometry.bottom
    };
    const overlayBottomCornerAngle = Math.atan2(overlayBottomCornerVector.deltaY, overlayBottomCornerVector.deltaX);
    return mouseMoveAngle < overlayTopCornerAngle && mouseMoveAngle > overlayBottomCornerAngle;
  };

  handleOnMouseMoveInResults = ev => {
    if (!this.mousePointerIsInNewPosition(ev)) {
      return;
    }
    const iconImagesOverlay = document.getElementById("iconImagesOverlay");
    if (!iconImagesOverlay) {
      this.mousePointerIsMovingTowardsImagesOverlay = false;
      return;
    }
    const overlayGeometry = iconImagesOverlay.getBoundingClientRect();
    if (this.isMousePointerMovingTowardsOverlay(ev, overlayGeometry)) {
      this.mouseInDirectionCounter++;
      this.mouseOutDirectionCounter = 0;
      this.mousePointerIsMovingTowardsImagesOverlay = true;
      MyLogger.blue("Good direction !!!!!", this.mousePointerIsMovingTowardsImagesOverlay);
    } else {
      this.mouseOutDirectionCounter++;
      this.mouseInDirectionCounter = 0;
      this.mousePointerIsMovingTowardsImagesOverlay = false;
      MyLogger.red("Wrong direction !!!!!", this.mousePointerIsMovingTowardsImagesOverlay);
    }
    this.previousMousePosition = { x: ev.clientX, y: ev.clientY };
  };

  globalMouseMoveHandler = ev => {
    this.currentMousePosition = { x: ev.clientX, y: ev.clientY };
    this.mousePositionChangeHandler();
  };

  globalScrollHandler = ev => {
    this.mousePositionChangeHandler();
  };

  mousePositionChangeHandler = () => {
    const hoveredElement = document.elementFromPoint(this.currentMousePosition.x, this.currentMousePosition.y);
    const iconImagesOverlayDiv = document.getElementById("icon-images-overlay");
    const fullSizeImageOverlayDiv = document.getElementById("fullSizeImageOverlay");
    const greenDivs = [this.currentResultsRowDiv, iconImagesOverlayDiv, fullSizeImageOverlayDiv];
    const mouseIsOverGreenDiv = greenDivs.some(div => div && div.contains(hoveredElement));
    console.log("mouseIsOverGreenDiv: ", mouseIsOverGreenDiv ? "GREEN" : "NOT GREEN");

    const resultsDiv = document.getElementById("m-results-cell-div");
    const mousePointerIsOverResults = resultsDiv && resultsDiv.contains(hoveredElement);

    if (mousePointerIsOverResults) {
      let hoveredFile;
      this.computeFilteredData.forEach(file => {
        const divId = file.id.replaceAll(".", "");
        const rowDivElement = document.getElementById(divId);
        if (rowDivElement && rowDivElement.contains(hoveredElement)) {
          hoveredFile = file;
          if (this.currentResultsRowDiv !== rowDivElement) {
            // set context data for newly hovered file
          }
        }
      });
      if (hoveredFile) {
        this.computeFilteredData.forEach(file => {
          file.fileIsHovered = file.id === hoveredFile.id;
        });
      }
    }

    const FOVndImagesHeaderDivGeometry = document.getElementById("header:images")?.getBoundingClientRect();
    const mousePointerIsOverFOVAndImagesInResults =
      FOVndImagesHeaderDivGeometry &&
      this.currentMousePosition.x < FOVndImagesHeaderDivGeometry.right &&
      this.currentMousePosition.x > FOVndImagesHeaderDivGeometry.left;
    if (mousePointerIsOverFOVAndImagesInResults) {
      console.log("mousePointerIsOverFOVAndImagesInResults");
    }
  };
}

const resultsStore = new ResultsStore();

// TODO: Somehow we should have a reaction that clears the
// selected status of files that go out of view due to a show/hide button,
// but this is quite difficult to formulate. Forget it for now, please!
// -----------------------------------------------------------
// reaction(
//     () =>
//       resultsStore.showUnmarked.toString() +
//     resultsStore.showHidden.toString() +
//     resultsStore.showCart.toString() +
//     resultsStore.showTrash.toString(),
//   () => {
//     resultsStore.removeSelectionStatusForInvisibleFiles();
//   }
// );

reaction(
  () => resultsStore.computeResultsForThree,
  filesForThree => {
    // Don't sync to three if a context menu is active
    // NOTE: syncFilesToThree must be called when context menu disappears,
    // this happens in context menu's handleOnClick function *after*
    // the menu item callback has been called.
    if (!appStore.contextMenuIsActive) {
      resultsStore.syncFilesToThree();
    }
  }
);

reaction(
  () => resultsStore.computePinpointFileIsVisible,
  isVisible => {
    if (!isVisible) {
      resultsStore.setTimeCenterPinpointIsActive(null);
    }
  }
);

reaction(
  () => resultsStore.computeHoveredObservations,
  hoveredObservations => threeStore.three.updateCanvasVisuals()
);

reaction(
  () => resultsStore.computeResultsTableColumnsAsArray,
  columnDefinitions => searchStore.syncToUrlAndLocalStorage()
);
export default resultsStore;
