import "raf/polyfill";

import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import DateInputParameter from "./DateInputParameter";
import { DateRangeParameterProps } from "../Interfaces";
import {
  dateObjectToString,
  isValidAbsoluteDate,
  isValidRelativeDate,
  stringToISODate
} from "../../Utilities/Utilities";
import searchStore from "../../Stores/SearchStore";

configure({ enforceActions: "always" });

@observer
class GlobalSelectorDateRangeParameter extends React.Component<DateRangeParameterProps> {
  id = uuidv4();
  handleOnBlur = ev => {
    searchStore.setPopDownCriterionOverlayIsActive(false);
    this.props.onBlur(ev);
  };

  handleOnDoubleClick = ev => {
    this.props.criterion.toggleExclusion();
  };

  handleMouseEnter = ev => {
    searchStore.popDownCriterionHookElement = document.getElementById(this.id);
    searchStore.setPopDownCriterionOverlayIsActive(true);
  };

  handleMouseLeave = ev => {
    searchStore.setPopDownCriterionOverlayIsActive(false);
  };

  handleDeltaTimeClick = (ev, value, deltaDays, setter) => {
    ev.target.blur();
    if (isValidRelativeDate(value)) {
      const values = value.match(/[0-9]+([.][0-9]*)?/);
      const valueAsNumber = Number(values[0]);
      const changedValueAsNumber = valueAsNumber + Number(deltaDays);
      const changedValue = changedValueAsNumber < 0 ? "0" : changedValueAsNumber.toString();
      ev.target.value = value.replace(values[0], changedValue);
      setter(ev);
      return;
    }
    if (isValidAbsoluteDate(value)) {
      const date = stringToISODate(value);
      if (Math.abs(deltaDays) >= 30) {
        date.setMonth(date.getMonth() + deltaDays / 30);
      } else {
        date.setDate(date.getDate() + deltaDays);
      }
      const changedDateAsStr = dateObjectToString(date);
      ev.target.value = changedDateAsStr;
      setter(ev);
    }
  };

  minDateInputSpan = () => {
    return (
      <span className="minValue">
        <DateInputParameter
          criterion={this.props.criterion}
          onValueChange={this.props.criterion.handleMinValueChange}
          onBlur={this.handleOnBlur}
          value={this.props.criterion.minValue}
          canHandleRelativeDate={false}
          inputFieldId={"Min"}
          minSelectableDate={null}
        />
      </span>
    );
  };

  maxDateInputSpan = minSelectableDate => {
    return (
      <span className="maxValue">
        <DateInputParameter
          criterion={this.props.criterion}
          onValueChange={this.props.criterion.handleMaxValueChange}
          onBlur={this.handleOnBlur}
          value={this.props.criterion.maxValue}
          canHandleRelativeDate={true}
          inputFieldId={"Max"}
          minSelectableDate={minSelectableDate}
        />
      </span>
    );
  };

  deltaTimeButton = (value, deltaDays, text, setter) => (
    <button
      onClick={ev => this.handleDeltaTimeClick(ev, value, deltaDays, setter)}
      className="click-button"
      style={{ fontSize: "11px" }}
    >
      {text}
    </button>
  );

  render() {
    const { criterion } = this.props;
    const minSelectableDate = isValidAbsoluteDate(criterion.minValue) ? new Date(criterion.minValue) : null;
    const excludedClassName = criterion.excluded ? "criterion-name-excluded" : "";
    return (
      <div id={this.id} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <div onDoubleClick={this.handleOnDoubleClick}>
          <span className={`description ${excludedClassName}`}>{criterion.description}</span>
        </div>
        <div className="criterion" style={{ minWidth: "300px" }}>
          <b>FROM:</b> <span style={{ width: "5px" }}></span>
          {this.deltaTimeButton(criterion.minValue, -30, "-1mo", criterion.handleMinValueChange)}
          {this.deltaTimeButton(criterion.minValue, -7, "-1w", criterion.handleMinValueChange)}
          {this.deltaTimeButton(criterion.minValue, -1, "-1d", criterion.handleMinValueChange)}
          &nbsp;
          {this.minDateInputSpan()}
          &nbsp;
          {this.deltaTimeButton(criterion.minValue, 1, "+1d", criterion.handleMinValueChange)}
          {this.deltaTimeButton(criterion.minValue, 7, "+1w", criterion.handleMinValueChange)}
          {this.deltaTimeButton(criterion.minValue, 30, "+1mo", criterion.handleMinValueChange)}
        </div>
        <div className="criterion">
          <b>TO:</b> <span style={{ width: "93px" }}></span>
          {this.deltaTimeButton(criterion.maxValue, -1, "-1", criterion.handleMaxValueChange)}
          &nbsp;
          {this.maxDateInputSpan(minSelectableDate)}
          &nbsp;
          {this.deltaTimeButton(criterion.maxValue, 1, "+1", criterion.handleMaxValueChange)}
          <span style={{ width: "75px" }}></span>
        </div>
      </div>
    );
  }
}

export default GlobalSelectorDateRangeParameter;
