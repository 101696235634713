/* eslint-disable no-useless-escape */
import "web-streams-polyfill"; // Required for Fireshit
import fetchStream from "fetch-readablestream"; // Required for Fireshit
import ndjsonStream from "can-ndjson-stream"; // Main thingy.
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { BOGUS_ENUM_REGEXP, DBSERVER } from "../constants";
import { generateUUID } from "three/src/math/MathUtils";

export const shutdownEvent = event => {
  event.preventDefault();
  event.stopPropagation();
};

export const roundTo = (n, digits) => {
  const multiplicator = Math.pow(10, digits);
  return Math.round(multiplicator * n) / multiplicator;
};

export const constrain = (val, min, max) => {
  if (min < max) {
    return Math.min(Math.max(val, min), max);
  }
  return (min + max) / 2;
};

export const ensureArray = x => (Array.isArray(x) ? x : [x]);
export const defined = x => typeof x !== "undefined";

const ndjsonReadAndRepeat = async (reader, callback, callbackWhenDone) => {
  const { value, done } = await reader.read();
  if (done) {
    return callbackWhenDone();
  }
  callback(value);
  ndjsonReadAndRepeat(reader, callback, callbackWhenDone);
};

export const ndjsonWithCallback = async (url, callback, callbackWhenDone) => {
  callbackWhenDone = callbackWhenDone ?? (() => console.log("ndjsonWithCallback DONE"));
  const response = await fetchStream(url);
  const stream = ndjsonStream(response.body);
  const reader = stream.getReader();
  ndjsonReadAndRepeat(reader, callback, callbackWhenDone);
};

export const macKeys = event => {
  if (!event.id) {
    event.id = generateUUID();
  }
  const isOnMac = navigator.userAgentData.platform.match(/^Mac/i) ? true : false;
  let cmdKey, ctrlKey;
  if (isOnMac) {
    cmdKey = event.metaKey;
    ctrlKey = event.ctrlKey;
  } else {
    cmdKey = event.ctrlKey;
    ctrlKey = event.metaKey;
  }
  const shiftKey = event.shiftKey;
  const altKey = event.altKey;

  let prefix = "";
  prefix += ctrlKey ? "Ctrl-" : "";
  prefix += cmdKey ? "Cmd-" : "";
  prefix += shiftKey ? "Shift-" : "";
  prefix += altKey ? "Alt-" : "";

  prefix = event.key?.match(/Shift|Meta|Control|Alt/) ? "" : prefix;

  let cleanedCode = event.type;
  cleanedCode = event.key ? event.key.replace(/^Key|^Digit|Left$|Right$/, "") : cleanedCode;

  const fullCleanedCode = prefix + cleanedCode + ".";
  const direction = event.type.replace(/^key/, "");
  const directionalFullCleanedCode = fullCleanedCode + direction;

  const status = {
    prefix,
    fullCleanedCode,
    direction,
    directionalFullCleanedCode,
    cmdKey,
    shiftKey,
    altKey,
    ctrlKey,

    noMacKeys: !cmdKey && !shiftKey && !altKey && !ctrlKey,

    onlyCtrlKey: ctrlKey && !cmdKey && !shiftKey && !altKey,
    onlyAltKey: altKey && !cmdKey && !shiftKey && !ctrlKey,
    onlyCmdKey: cmdKey && !shiftKey && !altKey && !ctrlKey,
    onlyShiftKey: shiftKey && !cmdKey && !altKey && !ctrlKey,

    ctrlAltKey: ctrlKey && altKey && !cmdKey && !shiftKey,
    ctrlShiftKey: ctrlKey && shiftKey && !cmdKey && !altKey,
    ctrlCmdKey: ctrlKey && cmdKey && !shiftKey && !altKey,

    cmdAltKey: altKey && cmdKey && !shiftKey && !ctrlKey,
    shiftAltKey: altKey && shiftKey && !cmdKey && !ctrlKey,

    cmdShiftKey: cmdKey && shiftKey && !altKey && !ctrlKey,

    cmdShiftAltKey: altKey && shiftKey && cmdKey && !ctrlKey
  };
  return status;
};

export const charCode = ev => (ev.which ? ev.which : ev.keyCode);

export const numbersZeroToNMinusOne = n => Array.from(Array(n).keys()); // Seriously, javascript??

export class MyLogger {
  static normal(...args) {
    console.log(...args);
    return null;
  }

  static red(first, ...args) {
    console.log("%c" + first, "color: red;", ...args);
    return null;
  }
  static bigRed(first, ...args) {
    console.log("%c" + first, "color:red; font-size: large");
    return null;
  }
  static green(first, ...args) {
    console.log("%c" + first, "color:green");
    return null;
  }
  static bigGreen(first, ...args) {
    console.log("%c" + first, "color:green; font-size: large");
    return null;
  }
  static blue(first, ...args) {
    console.log("%c" + first, "color:blue");
    return null;
  }
  static bigBlue(first, ...args) {
    console.log("%c" + first, "color:blue; font-size: large");
    return;
  }
}

// Replace "?..." part if any, replace trailing "/"" if any:
export const baseUrl = () => document.location.href.replace(/\?.*/, "").replace(/\/$/, "");
export const fullUrl = path => (/http/.exec(path) ? path : baseUrl() + "/" + path);

export const baseUrlForRealBackend = () => {
  return `${DBSERVER}/search/svo`;
};

export const dateObjectToString = date => {
  const dateISO = date.toISOString().replace("T", " ").substr(0, 16);
  return dateISO;
};

export const stringToISODate = date => {
  const datePart = date.substr(0, 10);
  const timePart = date.substr(11, 5).length === 5 ? date.substr(11, 5) : "00:00";
  return new Date(datePart + "T" + timePart + ":00.000Z");
};

export const isValidRelativeDate = date => {
  const relativeDatePattern = /^[+-][0-9]+([.][0-9]+)?([ywdhms]{1}|(mo){1})$/;
  return relativeDatePattern.test(date);
};

export const isValidAbsoluteDate = inputValue => {
  const year = "[0-9]{4}";
  const month_01_09 = "0[1-9]";
  const month_10_12 = "1[012]";
  const month = `(${month_01_09}|${month_10_12})`;
  const day_01_09 = "0[1-9]";
  const day_10_29 = "[12][0-9]";
  const day_30_31 = "3[01]";
  const day = `(${day_01_09}|${day_10_29}|${day_30_31})`;
  const hour_00_09 = "0[0-9]";
  const hour_10_19 = "1[0-9]";
  const hour_20_23 = "2[0-3]";
  const hour = `(${hour_00_09}|${hour_10_19}|${hour_20_23})`;
  const minute_00_09 = "0[0-9]";
  const minute_10_59 = "[1-5][0-9]";
  const minute = `(${minute_00_09}|${minute_10_59})`;
  const time = `${hour}:${minute}`;
  const absoluteDatePattern = new RegExp(`${year}-${month}-${day}( ${time})?$`);
  return absoluteDatePattern.test(inputValue);
};

export const isValidDate = inputValue => isValidAbsoluteDate(inputValue) || isValidRelativeDate(inputValue);

export const yyyyMmDdPathFromString = dateAsString => {
  const dateObsAsDate = new Date(parseDate(dateAsString));
  const yearAsFourDigits = dateObsAsDate.getFullYear();
  const monthAsTwoDigits = (dateObsAsDate.getMonth() + 1).toString().padStart(2, "0");
  const dayAsTwoDigits = dateObsAsDate.getDate().toString().padStart(2, "0");
  return `${yearAsFourDigits}/${monthAsTwoDigits}/${dayAsTwoDigits}`;
};

export const currentTimeAsString = () => {
  return Date.now().toString().substr(-5, 2) + ":" + Date.now().toString().substr(-3);
};

export const infoPopup = (mode, info) => {
  switch (mode) {
    case `info`:
      toast.success(info);
      break;
    case `warning`:
      toast.warning(info);
      break;
    case `error`:
      toast.error(info);
      break;
    default:
      toast();
  }
};
export const toSyntheticCriterionName = name => {
  switch (name) {
    case "EPOCH_START":
    case "EPOCH_END":
    case "EPOCH": {
      return "DATE_OBS";
    }
    default:
      return name;
  }
};

export const addEscapeChars = line => {
  const fields = line.split('"value" : ');
  if (fields.length !== 2) {
    return line;
  }
  const secondField = fields[1].substring(1, fields[1].length - 3);
  const secondFieldPatched = secondField.replaceAll(`"`, `\\"`);
  const transformedLine = `${fields[0]} "value" : "${secondFieldPatched}"}`;
  return transformedLine;
};

export const clone = obj => {
  var copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }
};

export const parseDate = date => {
  const patchedDate = date.trim().replace(" ", "T");
  return Date.parse(patchedDate);
};

export const replaceAll = (str, find, replace) => str.split(find).join(replace);

const translateType = (name, baseType, subType) => {
  if (name.match(BOGUS_ENUM_REGEXP)) {
    return "enum";
  }
  if (baseType.includes("char") || baseType.includes("text")) {
    return "enum";
  }
  if (baseType.includes("enum")) {
    return "enum";
  }
  if (baseType.includes("datetime")) {
    return "date-range";
  }
  if (
    baseType.includes("int") ||
    baseType.includes("decimal") ||
    baseType.includes("float") ||
    baseType.includes("double")
  ) {
    return "range";
  }
  if (baseType.includes("polygon")) {
    return "polygon";
  }
  if (baseType.includes("string")) {
    return "string";
  }
  if (subType === "0") {
    return "range";
  }
  return "no-type";
};

const translateDisplayType = (name, type) => {
  if (type.includes("int") || type.includes("decimal") || type.includes("float") || type.includes("double")) {
    return "number";
  }
  return "text";
};

const instrumentsListForInstrumentSpecificCriteria = name => {
  if (/^E__/.test(name)) {
    return ["EIS"];
  }
  if (/^IR__/.test(name)) {
    return ["IRIS/SJI", "IRIS/SPEC"];
  }
  if (/^X__/.test(name)) {
    return ["XRT"];
  }
  if (/^SX__/.test(name)) {
    return ["SOT/NB", "SOT/WB", "SOT/SP", "XRT"];
  }
  if (/^S__/.test(name)) {
    return ["SOT/NB", "SOT/WB", "SOT/SP"];
  }
  if (/^SS__/.test(name)) {
    return ["SOT/SP"];
  }
  if (/^SF__/.test(name)) {
    return ["SOT/FG"];
  }
  return [];
};

export const loadAndTransformFKFile = async criteriaDefs => {
  const locationTypes = {};
  const urlToFetchData = `${baseUrl()}/json-assets/criteria/criteriaFK.json`;
  const dataAsText = await fetch(myEncodeURI(urlToFetchData))
    .then(response => response.text())
    .catch(error => {
      console.error(error);
    });
  const patchedDataAsText = dataAsText ? dataAsText : "";
  const regexp = /\[\n +"[A-Z_0-9]+",\n +"-?\d",?\n( +".+",\n)?( +\[\n.+\n +\],\n)?( +".+"\n)? +]/g;
  const matching = patchedDataAsText.match(regexp);
  const instrumentTypes = [];
  matching.forEach(line => {
    const rawObject = line.split("\n");
    if (rawObject.length < 4) {
      return;
    }
    const name = replaceAll(replaceAll(rawObject[1], '"', ""), ",", "").trim();
    const baseType = rawObject[3].includes('"') ? replaceAll(replaceAll(rawObject[3], '"', ""), ",", "") : "";
    const subType = rawObject[2].includes("0") ? "0" : "1";
    const type = translateType(name, baseType, subType);
    const displayType = translateDisplayType(name, baseType);
    const descriptionIndex = rawObject.length - 2;
    const descriptionLines = rawObject[descriptionIndex].split(":");
    if (!locationTypes[descriptionLines[0]]) {
      locationTypes[descriptionLines[0]] = descriptionLines[0];
    }
    let description = descriptionLines.length > 3 ? descriptionLines[3].split("/")[0] : "";
    if (name.includes("__") && !instrumentTypes.some(type => type === name.substr(0, 3))) {
      instrumentTypes.push(name.substr(0, 3));
    }

    const fullDescription = description;
    description = description.length > 8 ? name : description;

    criteriaDefs[name] = {
      name,
      type,
      displayType,
      subType,
      description,
      fullDescription,
      instruments: instrumentsListForInstrumentSpecificCriteria(name),
      group: descriptionLines[0].replace('"', "").replace("O_", "").trim()
    };
  });
  return criteriaDefs;
};

export const relativeDateToEpoch = date => {
  const charsPattern = new RegExp("[smhdwy]", "g");
  if (!charsPattern.exec(date)) {
    return 0;
  }
  const indexOfFirstChar = charsPattern.lastIndex - 1;
  const numberAsString = date.substr(0, indexOfFirstChar);
  const quantity = Number(numberAsString);
  const typeOfPeriod = date.substr(indexOfFirstChar);
  console.log("Number from real date", date, indexOfFirstChar, numberAsString, quantity, typeOfPeriod);
  const periodMultipliersInSeconds = {
    s: 1,
    m: 60,
    h: 60 * 60,
    d: 24 * 60 * 60,
    w: 7 * 24 * 60 * 60,
    mo: 30 * 24 * 60 * 60,
    y: 365 * 24 * 60 * 60
  };
  const periodMultiplierInMilliSeconds = periodMultipliersInSeconds[typeOfPeriod] * 1000;
  return quantity * periodMultiplierInMilliSeconds;
};

export const crashToGetStackDump = () => {
  const nothing = null;
  const crashing = nothing.something.nonExistent;
  return crashing;
};

export const translateRelativeToAbsoluteDate = (startDate, relativeDate) => {
  const minValueAsEpoch = new Date(startDate).getTime();
  const offsetAsEpoch = this.relativeDateToEpoch(relativeDate);
  const maxValueAsEpoch = minValueAsEpoch + offsetAsEpoch;
  const maxValueAsString = dateObjectToString(new Date(maxValueAsEpoch)).toString();
  return maxValueAsString;
};

export const isIncluded = (element, filter) => {
  const filterRegex = new RegExp(filter, "i");
  const exp1 = element.name ? element.name : "";
  const exp2 = element.description ? element.description : "";
  return exp1.match(filterRegex) !== null || exp2.match(filterRegex) !== null;
};

export const glob2Regex = glob => {
  const glob2Regex = glob.replace(".", ".").replace("+", "+").replace("*", ".*");
  return new RegExp(glob2Regex, "i");
};

export const isPathAbsolute = path => {
  return /^(?:\/|[a-z]+:\/\/)/.test(path);
};

export const timeSpan = (fileStart, fileEnd) => {
  if (!fileStart || !fileEnd) {
    return 0;
  }
  const epochStart = new Date(parseDate(fileStart.columnValuesByName.DATE_OBS));
  const epochEnd = new Date(parseDate(fileEnd.columnValuesByName.DATE_END));
  return epochStart - epochEnd;
};

export const createCSSSelector = (selector, style) => {
  if (!document.styleSheets) {
    return;
  }

  if (document.getElementsByTagName("head").length === 0) {
    return;
  }

  let styleSheet;
  let styleSheetElement;
  let mediaType;
  if (document.styleSheets.length > 0) {
    for (let i = 0; i < document.styleSheets.length; i++) {
      if (document.styleSheets[i].disabled) {
        continue;
      }
      var media = document.styleSheets[i].media;
      mediaType = typeof media;

      if (mediaType === "string") {
        if (media === "" || media.indexOf("screen") !== -1) {
          styleSheet = document.styleSheets[i];
        }
      } else if (mediaType === "object") {
        if (media.mediaText === "" || media.mediaText.indexOf("screen") !== -1) {
          styleSheet = document.styleSheets[i];
        }
      }
      if (typeof styleSheet != "undefined") {
        break;
      }
    }
  }
  if (typeof styleSheet == "undefined") {
    styleSheetElement = document.createElement("style");
    styleSheetElement.type = "text/css";
    document.getElementsByTagName("head")[0].appendChild(styleSheetElement);
    for (let i = 0; i < document.styleSheets.length; i++) {
      if (document.styleSheets[i].disabled) {
        continue;
      }
      styleSheet = document.styleSheets[i];
    }
    const media = styleSheet.media;
    mediaType = typeof media;
  }
  styleSheet.insertRule(selector + "{" + style + "}", styleSheet.cssRules.length);
};

export const removeCSSSelector = selector => {
  if (!document.styleSheets) {
    return;
  }
  if (document.getElementsByTagName("head").length === 0) {
    return;
  }
  let styleSheet;
  let mediaType;
  if (document.styleSheets.length > 0) {
    for (let i = 0; i < document.styleSheets.length; i++) {
      if (document.styleSheets[i].disabled) {
        continue;
      }
      var media = document.styleSheets[i].media;
      mediaType = typeof media;

      if (mediaType === "string") {
        if (media === "" || media.indexOf("screen") !== -1) {
          styleSheet = document.styleSheets[i];
        }
      } else if (mediaType === "object") {
        if (media.mediaText === "" || media.mediaText.indexOf("screen") !== -1) {
          styleSheet = document.styleSheets[i];
        }
      }
      if (typeof styleSheet != "undefined") {
        break;
      }
    }
  }
  if (typeof styleSheet !== "undefined") {
    styleSheet.deleteRule(styleSheet.cssRules.length - 1);
  }
};

export const urlAsCssClassName = url => url.replace(/[\/.-]/g, ``).replaceAll(":", "");

export const hashCode = str => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

//
export const formatNumber = (value, fractionalDigitsAtOnePointSomething, maxFractionalSignificantDigits) => {
  const number = parseFloat(value);
  const sign = number < 0 ? "-" : "";
  const absNumber = Math.abs(number);
  const log10 = Math.log10(absNumber);
  const props = {};
  if (log10 > 0) {
    // n > 1
    props["maximumFractionDigits"] = Math.max(0, fractionalDigitsAtOnePointSomething - Math.floor(log10) - 1);
  } else {
    props["maximumSignificantDigits"] = maxFractionalSignificantDigits;
  }
  const formatter = new Intl.NumberFormat("en-US", props);
  return `${sign}${formatter.format(absNumber)}`;
};

const nonSortableColumns = new Set(["images", "fov"]);
export const isColumnSortable = columnName => {
  if (nonSortableColumns.has(columnName)) {
    return false;
  }
  return true;
};

export const convertSyntheticToRealColumnName = synetheticColumnName => {
  switch (synetheticColumnName) {
    case "timespan":
      return "DATE_OBS";
    default:
      return synetheticColumnName;
  }
};

export const isChromeOrOpera = () => {
  const userAgent = navigator.userAgent;
  const isOpera = userAgent.match(/opr\//i);
  const isChrome = userAgent.match(/chrome|chromium|crios/i);
  return isChrome || isOpera;
};

export const labeledUuidV4 = label => label + "---" + uuidv4();

export const myFunctionName = () => {
  try {
    throw new Error();
  } catch (e) {
    console.log(e.stack);
    // matches this function, the caller and the parent
    const allMatches = e.stack.match(/(\w+)@|at (\w+) \(/g);
    // match parent function name
    const parentMatches = allMatches[2].match(/(\w+)@|at (\w+) \(/);
    // return only name
    return parentMatches[1] || parentMatches[2];
  }
};

export const myEncodeURI = uri => {
  // The problem is that the standard encodeURI does not replace + signs with %2b,
  // meaning it is interpreted as a space in the backend.
  // This is a workaround.
  const intermediate1 = uri.replace("+", "``");
  const intermediate2 = encodeURI(intermediate1);
  const final = intermediate2.replace("%60%60", "%2b");
  return final;
};

export const fetchFieldsFromQueryString = queryString => queryString.replace(/.*[?&;]s=([^&;]*)/, "$1")?.split(",");

export const queryStringWithoutFetchFields = queryString => queryString.replace(/[?&;]s=[^&;]*/, "");

export const elementYcenter = element => {
  const rect = element.getBoundingClientRect();
  return rect.y + rect.height / 2;
};
