export const ephemerisSolo = [
  { isoDate: "2020-02-11T00:00:00Z", dsun: 212.246862, hgln: -0.1575, hglt: -6.575586 },
  { isoDate: "2020-02-12T00:00:00Z", dsun: 212.364543, hgln: -0.340387, hglt: -6.59176 },
  { isoDate: "2020-02-13T00:00:00Z", dsun: 212.460773, hgln: -0.522268, hglt: -6.606861 },
  { isoDate: "2020-02-14T00:00:00Z", dsun: 212.53611, hgln: -0.704017, hglt: -6.620685 },
  { isoDate: "2020-02-15T00:00:00Z", dsun: 212.590731, hgln: -0.885765, hglt: -6.633172 },
  { isoDate: "2020-02-16T00:00:00Z", dsun: 212.624723, hgln: -1.067473, hglt: -6.644297 },
  { isoDate: "2020-02-17T00:00:00Z", dsun: 212.638137, hgln: -1.249041, hglt: -6.654049 },
  { isoDate: "2020-02-18T00:00:00Z", dsun: 212.631004, hgln: -1.430332, hglt: -6.662422 },
  { isoDate: "2020-02-19T00:00:00Z", dsun: 212.603343, hgln: -1.611191, hglt: -6.669409 },
  { isoDate: "2020-02-20T00:00:00Z", dsun: 212.55517, hgln: -1.791453, hglt: -6.675005 },
  { isoDate: "2020-02-21T00:00:00Z", dsun: 212.486494, hgln: -1.970947, hglt: -6.679206 },
  { isoDate: "2020-02-22T00:00:00Z", dsun: 212.397343, hgln: -2.149498, hglt: -6.682002 },
  { isoDate: "2020-02-23T00:00:00Z", dsun: 212.287713, hgln: -2.326924, hglt: -6.683391 },
  { isoDate: "2020-02-24T00:00:00Z", dsun: 212.157604, hgln: -2.50304, hglt: -6.68337 },
  { isoDate: "2020-02-25T00:00:00Z", dsun: 212.007024, hgln: -2.677663, hglt: -6.681932 },
  { isoDate: "2020-02-26T00:00:00Z", dsun: 211.835977, hgln: -2.850607, hglt: -6.679071 },
  { isoDate: "2020-02-27T00:00:00Z", dsun: 211.644471, hgln: -3.021685, hglt: -6.674778 },
  { isoDate: "2020-02-28T00:00:00Z", dsun: 211.432512, hgln: -3.19071, hglt: -6.669046 },
  { isoDate: "2020-02-29T00:00:00Z", dsun: 211.200106, hgln: -3.357496, hglt: -6.661867 },
  { isoDate: "2020-03-01T00:00:00Z", dsun: 210.94726, hgln: -3.521854, hglt: -6.653229 },
  { isoDate: "2020-03-02T00:00:00Z", dsun: 210.673981, hgln: -3.683598, hglt: -6.643123 },
  { isoDate: "2020-03-03T00:00:00Z", dsun: 210.380278, hgln: -3.842539, hglt: -6.631538 },
  { isoDate: "2020-03-04T00:00:00Z", dsun: 210.066158, hgln: -3.998491, hglt: -6.618461 },
  { isoDate: "2020-03-05T00:00:00Z", dsun: 209.731631, hgln: -4.151267, hglt: -6.603879 },
  { isoDate: "2020-03-06T00:00:00Z", dsun: 209.376707, hgln: -4.300683, hglt: -6.587779 },
  { isoDate: "2020-03-07T00:00:00Z", dsun: 209.001397, hgln: -4.446555, hglt: -6.570145 },
  { isoDate: "2020-03-08T00:00:00Z", dsun: 208.605711, hgln: -4.588704, hglt: -6.550962 },
  { isoDate: "2020-03-09T00:00:00Z", dsun: 208.189664, hgln: -4.726951, hglt: -6.530214 },
  { isoDate: "2020-03-10T00:00:00Z", dsun: 207.753269, hgln: -4.861121, hglt: -6.507881 },
  { isoDate: "2020-03-11T00:00:00Z", dsun: 207.296542, hgln: -4.991035, hglt: -6.483947 },
  { isoDate: "2020-03-12T00:00:00Z", dsun: 206.819499, hgln: -5.116514, hglt: -6.458391 },
  { isoDate: "2020-03-13T00:00:00Z", dsun: 206.322158, hgln: -5.237371, hglt: -6.431192 },
  { isoDate: "2020-03-14T00:00:00Z", dsun: 205.80454, hgln: -5.353413, hglt: -6.40233 },
  { isoDate: "2020-03-15T00:00:00Z", dsun: 205.266667, hgln: -5.464434, hglt: -6.37178 },
  { isoDate: "2020-03-16T00:00:00Z", dsun: 204.708562, hgln: -5.570224, hglt: -6.33952 },
  { isoDate: "2020-03-17T00:00:00Z", dsun: 204.130252, hgln: -5.67056, hglt: -6.305524 },
  { isoDate: "2020-03-18T00:00:00Z", dsun: 203.531764, hgln: -5.765214, hglt: -6.269765 },
  { isoDate: "2020-03-19T00:00:00Z", dsun: 202.913128, hgln: -5.853948, hglt: -6.232218 },
  { isoDate: "2020-03-20T00:00:00Z", dsun: 202.27438, hgln: -5.93652, hglt: -6.192852 },
  { isoDate: "2020-03-21T00:00:00Z", dsun: 201.615554, hgln: -6.012679, hglt: -6.151638 },
  { isoDate: "2020-03-22T00:00:00Z", dsun: 200.93669, hgln: -6.082167, hglt: -6.108546 },
  { isoDate: "2020-03-23T00:00:00Z", dsun: 200.23783, hgln: -6.144723, hglt: -6.063542 },
  { isoDate: "2020-03-24T00:00:00Z", dsun: 199.519022, hgln: -6.200076, hglt: -6.016593 },
  { isoDate: "2020-03-25T00:00:00Z", dsun: 198.780315, hgln: -6.247951, hglt: -5.967664 },
  { isoDate: "2020-03-26T00:00:00Z", dsun: 198.021763, hgln: -6.288066, hglt: -5.916719 },
  { isoDate: "2020-03-27T00:00:00Z", dsun: 197.243425, hgln: -6.320133, hglt: -5.863719 },
  { isoDate: "2020-03-28T00:00:00Z", dsun: 196.445365, hgln: -6.343857, hglt: -5.808625 },
  { isoDate: "2020-03-29T00:00:00Z", dsun: 195.627651, hgln: -6.358937, hglt: -5.751396 },
  { isoDate: "2020-03-30T00:00:00Z", dsun: 194.790357, hgln: -6.365065, hglt: -5.69199 },
  { isoDate: "2020-03-31T00:00:00Z", dsun: 193.933563, hgln: -6.361929, hglt: -5.630362 },
  { isoDate: "2020-04-01T00:00:00Z", dsun: 193.057355, hgln: -6.349207, hglt: -5.566469 },
  { isoDate: "2020-04-02T00:00:00Z", dsun: 192.161826, hgln: -6.326574, hglt: -5.500261 },
  { isoDate: "2020-04-03T00:00:00Z", dsun: 191.247076, hgln: -6.293697, hglt: -5.431691 },
  { isoDate: "2020-04-04T00:00:00Z", dsun: 190.313212, hgln: -6.250237, hglt: -5.360708 },
  { isoDate: "2020-04-05T00:00:00Z", dsun: 189.36035, hgln: -6.195853, hglt: -5.287259 },
  { isoDate: "2020-04-06T00:00:00Z", dsun: 188.388616, hgln: -6.130195, hglt: -5.211292 },
  { isoDate: "2020-04-07T00:00:00Z", dsun: 187.398143, hgln: -6.052911, hglt: -5.13275 },
  { isoDate: "2020-04-08T00:00:00Z", dsun: 186.389076, hgln: -5.96364, hglt: -5.051577 },
  { isoDate: "2020-04-09T00:00:00Z", dsun: 185.361569, hgln: -5.862013, hglt: -4.967712 },
  { isoDate: "2020-04-10T00:00:00Z", dsun: 184.315788, hgln: -5.747648, hglt: -4.881095 },
  { isoDate: "2020-04-11T00:00:00Z", dsun: 183.251913, hgln: -5.620148, hglt: -4.791665 },
  { isoDate: "2020-04-12T00:00:00Z", dsun: 182.170135, hgln: -5.479099, hglt: -4.699356 },
  { isoDate: "2020-04-13T00:00:00Z", dsun: 181.070659, hgln: -5.324068, hglt: -4.604102 },
  { isoDate: "2020-04-14T00:00:00Z", dsun: 179.953706, hgln: -5.154601, hglt: -4.505836 },
  { isoDate: "2020-04-15T00:00:00Z", dsun: 178.819512, hgln: -4.970227, hglt: -4.404488 },
  { isoDate: "2020-04-16T00:00:00Z", dsun: 177.668331, hgln: -4.770455, hglt: -4.299988 },
  { isoDate: "2020-04-17T00:00:00Z", dsun: 176.500434, hgln: -4.554773, hglt: -4.192264 },
  { isoDate: "2020-04-18T00:00:00Z", dsun: 175.316113, hgln: -4.322653, hglt: -4.081241 },
  { isoDate: "2020-04-19T00:00:00Z", dsun: 174.115678, hgln: -4.073546, hglt: -3.966844 },
  { isoDate: "2020-04-20T00:00:00Z", dsun: 172.899463, hgln: -3.806884, hglt: -3.848998 },
  { isoDate: "2020-04-21T00:00:00Z", dsun: 171.667825, hgln: -3.522077, hglt: -3.727626 },
  { isoDate: "2020-04-22T00:00:00Z", dsun: 170.421146, hgln: -3.218518, hglt: -3.602649 },
  { isoDate: "2020-04-23T00:00:00Z", dsun: 169.159834, hgln: -2.895576, hglt: -3.473989 },
  { isoDate: "2020-04-24T00:00:00Z", dsun: 167.884326, hgln: -2.552601, hglt: -3.341567 },
  { isoDate: "2020-04-25T00:00:00Z", dsun: 166.595089, hgln: -2.188919, hglt: -3.205306 },
  { isoDate: "2020-04-26T00:00:00Z", dsun: 165.292622, hgln: -1.803836, hglt: -3.065126 },
  { isoDate: "2020-04-27T00:00:00Z", dsun: 163.977458, hgln: -1.396637, hglt: -2.920952 },
  { isoDate: "2020-04-28T00:00:00Z", dsun: 162.650167, hgln: -0.966583, hglt: -2.772706 },
  { isoDate: "2020-04-29T00:00:00Z", dsun: 161.311357, hgln: -0.512913, hglt: -2.620314 },
  { isoDate: "2020-04-30T00:00:00Z", dsun: 159.961677, hgln: -0.034847, hglt: -2.463707 },
  { isoDate: "2020-05-01T00:00:00Z", dsun: 158.60182, hgln: 0.468418, hglt: -2.302814 },
  { isoDate: "2020-05-02T00:00:00Z", dsun: 157.232523, hgln: 0.997705, hglt: -2.137572 },
  { isoDate: "2020-05-03T00:00:00Z", dsun: 155.854575, hgln: 1.553857, hglt: -1.967923 },
  { isoDate: "2020-05-04T00:00:00Z", dsun: 154.468814, hgln: 2.137734, hglt: -1.793814 },
  { isoDate: "2020-05-05T00:00:00Z", dsun: 153.076136, hgln: 2.750216, hglt: -1.615197 },
  { isoDate: "2020-05-06T00:00:00Z", dsun: 151.677491, hgln: 3.392199, hglt: -1.432037 },
  { isoDate: "2020-05-07T00:00:00Z", dsun: 150.273893, hgln: 4.064594, hglt: -1.244306 },
  { isoDate: "2020-05-08T00:00:00Z", dsun: 148.866417, hgln: 4.768335, hglt: -1.051988 },
  { isoDate: "2020-05-09T00:00:00Z", dsun: 147.456209, hgln: 5.504369, hglt: -0.855081 },
  { isoDate: "2020-05-10T00:00:00Z", dsun: 146.044482, hgln: 6.273667, hglt: -0.653597 },
  { isoDate: "2020-05-11T00:00:00Z", dsun: 144.632527, hgln: 7.077215, hglt: -0.447566 },
  { isoDate: "2020-05-12T00:00:00Z", dsun: 143.221708, hgln: 7.916014, hglt: -0.237039 },
  { isoDate: "2020-05-13T00:00:00Z", dsun: 141.813473, hgln: 8.791079, hglt: -0.022087 },
  { isoDate: "2020-05-14T00:00:00Z", dsun: 140.409352, hgln: 9.703431, hglt: 0.197192 },
  { isoDate: "2020-05-15T00:00:00Z", dsun: 139.010963, hgln: 10.654095, hglt: 0.420673 },
  { isoDate: "2020-05-16T00:00:00Z", dsun: 137.62001, hgln: 11.64409, hglt: 0.648201 },
  { isoDate: "2020-05-17T00:00:00Z", dsun: 136.238292, hgln: 12.674423, hglt: 0.879586 },
  { isoDate: "2020-05-18T00:00:00Z", dsun: 134.8677, hgln: 13.746082, hglt: 1.1146 },
  { isoDate: "2020-05-19T00:00:00Z", dsun: 133.51022, hgln: 14.860027, hglt: 1.352973 },
  { isoDate: "2020-05-20T00:00:00Z", dsun: 132.167934, hgln: 16.01718, hglt: 1.594392 },
  { isoDate: "2020-05-21T00:00:00Z", dsun: 130.843019, hgln: 17.218411, hglt: 1.838495 },
  { isoDate: "2020-05-22T00:00:00Z", dsun: 129.537749, hgln: 18.464532, hglt: 2.084869 },
  { isoDate: "2020-05-23T00:00:00Z", dsun: 128.25449, hgln: 19.756276, hglt: 2.333043 },
  { isoDate: "2020-05-24T00:00:00Z", dsun: 126.995697, hgln: 21.094288, hglt: 2.582489 },
  { isoDate: "2020-05-25T00:00:00Z", dsun: 125.763913, hgln: 22.479105, hglt: 2.832621 },
  { isoDate: "2020-05-26T00:00:00Z", dsun: 124.561759, hgln: 23.91114, hglt: 3.082785 },
  { isoDate: "2020-05-27T00:00:00Z", dsun: 123.391933, hgln: 25.390662, hglt: 3.332265 },
  { isoDate: "2020-05-28T00:00:00Z", dsun: 122.257192, hgln: 26.917776, hglt: 3.58028 },
  { isoDate: "2020-05-29T00:00:00Z", dsun: 121.160347, hgln: 28.492405, hglt: 3.825984 },
  { isoDate: "2020-05-30T00:00:00Z", dsun: 120.104252, hgln: 30.114268, hglt: 4.068467 },
  { isoDate: "2020-05-31T00:00:00Z", dsun: 119.091781, hgln: 31.782857, hglt: 4.306761 },
  { isoDate: "2020-06-01T00:00:00Z", dsun: 118.125819, hgln: 33.497422, hglt: 4.539843 },
  { isoDate: "2020-06-02T00:00:00Z", dsun: 117.209245, hgln: 35.256949, hglt: 4.766642 },
  { isoDate: "2020-06-03T00:00:00Z", dsun: 116.344887, hgln: 37.060144, hglt: 4.986047 },
  { isoDate: "2020-06-04T00:00:00Z", dsun: 115.535536, hgln: 38.90542, hglt: 5.196917 },
  { isoDate: "2020-06-05T00:00:00Z", dsun: 114.783897, hgln: 40.790893, hglt: 5.398097 },
  { isoDate: "2020-06-06T00:00:00Z", dsun: 114.092573, hgln: 42.714367, hglt: 5.588428 },
  { isoDate: "2020-06-07T00:00:00Z", dsun: 113.464034, hgln: 44.673342, hglt: 5.766763 },
  { isoDate: "2020-06-08T00:00:00Z", dsun: 112.900593, hgln: 46.665018, hglt: 5.931987 },
  { isoDate: "2020-06-09T00:00:00Z", dsun: 112.404379, hgln: 48.686301, hglt: 6.083031 },
  { isoDate: "2020-06-10T00:00:00Z", dsun: 111.977313, hgln: 50.733823, hglt: 6.218894 },
  { isoDate: "2020-06-11T00:00:00Z", dsun: 111.621082, hgln: 52.803964, hglt: 6.338659 },
  { isoDate: "2020-06-12T00:00:00Z", dsun: 111.337119, hgln: 54.892875, hglt: 6.44151 },
  { isoDate: "2020-06-13T00:00:00Z", dsun: 111.126581, hgln: 56.996514, hglt: 6.52675 },
  { isoDate: "2020-06-14T00:00:00Z", dsun: 110.990338, hgln: 59.110682, hglt: 6.593814 },
  { isoDate: "2020-06-15T00:00:00Z", dsun: 110.928956, hgln: 61.231067, hglt: 6.642279 },
  { isoDate: "2020-06-16T00:00:00Z", dsun: 110.942699, hgln: 63.353283, hglt: 6.671871 },
  { isoDate: "2020-06-17T00:00:00Z", dsun: 111.031501, hgln: 65.472934, hglt: 6.682482 },
  { isoDate: "2020-06-18T00:00:00Z", dsun: 111.19499, hgln: 67.585649, hglt: 6.674156 },
  { isoDate: "2020-06-19T00:00:00Z", dsun: 111.432488, hgln: 69.687131, hglt: 6.647096 },
  { isoDate: "2020-06-20T00:00:00Z", dsun: 111.74302, hgln: 71.773206, hglt: 6.601654 },
  { isoDate: "2020-06-21T00:00:00Z", dsun: 112.125325, hgln: 73.839862, hglt: 6.538324 },
  { isoDate: "2020-06-22T00:00:00Z", dsun: 112.577873, hgln: 75.883291, hglt: 6.457729 },
  { isoDate: "2020-06-23T00:00:00Z", dsun: 113.09889, hgln: 77.899919, hglt: 6.360609 },
  { isoDate: "2020-06-24T00:00:00Z", dsun: 113.686376, hgln: 79.886434, hglt: 6.247805 },
  { isoDate: "2020-06-25T00:00:00Z", dsun: 114.338132, hgln: 81.839801, hglt: 6.12024 },
  { isoDate: "2020-06-26T00:00:00Z", dsun: 115.051783, hgln: 83.757284, hglt: 5.978902 },
  { isoDate: "2020-06-27T00:00:00Z", dsun: 115.824808, hgln: 85.636448, hglt: 5.824828 },
  { isoDate: "2020-06-28T00:00:00Z", dsun: 116.654565, hgln: 87.475162, hglt: 5.659087 },
  { isoDate: "2020-06-29T00:00:00Z", dsun: 117.538315, hgln: 89.271596, hglt: 5.482762 },
  { isoDate: "2020-06-30T00:00:00Z", dsun: 118.473251, hgln: 91.024217, hglt: 5.296937 },
  { isoDate: "2020-07-01T00:00:00Z", dsun: 119.456517, hgln: 92.731773, hglt: 5.102683 },
  { isoDate: "2020-07-02T00:00:00Z", dsun: 120.485235, hgln: 94.393282, hglt: 4.90105 },
  { isoDate: "2020-07-03T00:00:00Z", dsun: 121.556522, hgln: 96.008018, hglt: 4.693049 },
  { isoDate: "2020-07-04T00:00:00Z", dsun: 122.667508, hgln: 97.57549, hglt: 4.479654 },
  { isoDate: "2020-07-05T00:00:00Z", dsun: 123.815355, hgln: 99.095426, hglt: 4.26179 },
  { isoDate: "2020-07-06T00:00:00Z", dsun: 124.997269, hgln: 100.567756, hglt: 4.040328 },
  { isoDate: "2020-07-07T00:00:00Z", dsun: 126.210513, hgln: 101.992591, hglt: 3.816086 },
  { isoDate: "2020-07-08T00:00:00Z", dsun: 127.452419, hgln: 103.370205, hglt: 3.589823 },
  { isoDate: "2020-07-09T00:00:00Z", dsun: 128.720391, hgln: 104.701014, hglt: 3.362241 },
  { isoDate: "2020-07-10T00:00:00Z", dsun: 130.011919, hgln: 105.985564, hglt: 3.133984 },
  { isoDate: "2020-07-11T00:00:00Z", dsun: 131.32458, hgln: 107.224506, hglt: 2.905638 },
  { isoDate: "2020-07-12T00:00:00Z", dsun: 132.65604, hgln: 108.418587, hglt: 2.677735 },
  { isoDate: "2020-07-13T00:00:00Z", dsun: 134.004063, hgln: 109.568628, hglt: 2.450756 },
  { isoDate: "2020-07-14T00:00:00Z", dsun: 135.366504, hgln: 110.675518, hglt: 2.225129 },
  { isoDate: "2020-07-15T00:00:00Z", dsun: 136.74132, hgln: 111.740192, hglt: 2.001235 },
  { isoDate: "2020-07-16T00:00:00Z", dsun: 138.126558, hgln: 112.763631, hglt: 1.779413 },
  { isoDate: "2020-07-17T00:00:00Z", dsun: 139.520359, hgln: 113.746846, hglt: 1.559956 },
  { isoDate: "2020-07-18T00:00:00Z", dsun: 140.920961, hgln: 114.690868, hglt: 1.343124 },
  { isoDate: "2020-07-19T00:00:00Z", dsun: 142.32669, hgln: 115.596746, hglt: 1.129138 },
  { isoDate: "2020-07-20T00:00:00Z", dsun: 143.735961, hgln: 116.465535, hglt: 0.918189 },
  { isoDate: "2020-07-21T00:00:00Z", dsun: 145.147274, hgln: 117.298296, hglt: 0.710436 },
  { isoDate: "2020-07-22T00:00:00Z", dsun: 146.559214, hgln: 118.096084, hglt: 0.506012 },
  { isoDate: "2020-07-23T00:00:00Z", dsun: 147.970442, hgln: 118.859947, hglt: 0.305029 },
  { isoDate: "2020-07-24T00:00:00Z", dsun: 149.379697, hgln: 119.590921, hglt: 0.107572 },
  { isoDate: "2020-07-25T00:00:00Z", dsun: 150.785791, hgln: 120.290024, hglt: -0.086289 },
  { isoDate: "2020-07-26T00:00:00Z", dsun: 152.187603, hgln: 120.958252, hglt: -0.276504 },
  { isoDate: "2020-07-27T00:00:00Z", dsun: 153.584082, hgln: 121.596577, hglt: -0.463038 },
  { isoDate: "2020-07-28T00:00:00Z", dsun: 154.974237, hgln: 122.205947, hglt: -0.64587 },
  { isoDate: "2020-07-29T00:00:00Z", dsun: 156.357138, hgln: 122.78728, hglt: -0.82499 },
  { isoDate: "2020-07-30T00:00:00Z", dsun: 157.731911, hgln: 123.341467, hglt: -1.0004 },
  { isoDate: "2020-07-31T00:00:00Z", dsun: 159.097736, hgln: 123.869373, hglt: -1.172111 },
  { isoDate: "2020-08-01T00:00:00Z", dsun: 160.453845, hgln: 124.371832, hglt: -1.340142 },
  { isoDate: "2020-08-02T00:00:00Z", dsun: 161.799514, hgln: 124.849653, hglt: -1.504518 },
  { isoDate: "2020-08-03T00:00:00Z", dsun: 163.134068, hgln: 125.30362, hglt: -1.665272 },
  { isoDate: "2020-08-04T00:00:00Z", dsun: 164.456873, hgln: 125.734492, hglt: -1.822441 },
  { isoDate: "2020-08-05T00:00:00Z", dsun: 165.767336, hgln: 126.143003, hglt: -1.976066 },
  { isoDate: "2020-08-06T00:00:00Z", dsun: 167.064899, hgln: 126.529867, hglt: -2.126193 },
  { isoDate: "2020-08-07T00:00:00Z", dsun: 168.349044, hgln: 126.895775, hglt: -2.27287 },
  { isoDate: "2020-08-08T00:00:00Z", dsun: 169.619281, hgln: 127.241398, hglt: -2.416148 },
  { isoDate: "2020-08-09T00:00:00Z", dsun: 170.875157, hgln: 127.567387, hglt: -2.556079 },
  { isoDate: "2020-08-10T00:00:00Z", dsun: 172.116244, hgln: 127.874372, hglt: -2.692717 },
  { isoDate: "2020-08-11T00:00:00Z", dsun: 173.342144, hgln: 128.162966, hglt: -2.826119 },
  { isoDate: "2020-08-12T00:00:00Z", dsun: 174.552484, hgln: 128.433762, hglt: -2.956339 },
  { isoDate: "2020-08-13T00:00:00Z", dsun: 175.746916, hgln: 128.687337, hglt: -3.083434 },
  { isoDate: "2020-08-14T00:00:00Z", dsun: 176.925116, hgln: 128.924249, hglt: -3.207462 },
  { isoDate: "2020-08-15T00:00:00Z", dsun: 178.086778, hgln: 129.145043, hglt: -3.328478 },
  { isoDate: "2020-08-16T00:00:00Z", dsun: 179.231619, hgln: 129.350248, hglt: -3.44654 },
  { isoDate: "2020-08-17T00:00:00Z", dsun: 180.359376, hgln: 129.540379, hglt: -3.561703 },
  { isoDate: "2020-08-18T00:00:00Z", dsun: 181.4698, hgln: 129.715938, hglt: -3.674023 },
  { isoDate: "2020-08-19T00:00:00Z", dsun: 182.562661, hgln: 129.877416, hglt: -3.783555 },
  { isoDate: "2020-08-20T00:00:00Z", dsun: 183.637744, hgln: 130.02529, hglt: -3.890352 },
  { isoDate: "2020-08-21T00:00:00Z", dsun: 184.694849, hgln: 130.160026, hglt: -3.994469 },
  { isoDate: "2020-08-22T00:00:00Z", dsun: 185.733789, hgln: 130.282074, hglt: -4.095958 },
  { isoDate: "2020-08-23T00:00:00Z", dsun: 186.75439, hgln: 130.391867, hglt: -4.194869 },
  { isoDate: "2020-08-24T00:00:00Z", dsun: 187.756491, hgln: 130.489823, hglt: -4.291253 },
  { isoDate: "2020-08-25T00:00:00Z", dsun: 188.739939, hgln: 130.576342, hglt: -4.385158 },
  { isoDate: "2020-08-26T00:00:00Z", dsun: 189.704596, hgln: 130.651806, hglt: -4.476633 },
  { isoDate: "2020-08-27T00:00:00Z", dsun: 190.65033, hgln: 130.716582, hglt: -4.565724 },
  { isoDate: "2020-08-28T00:00:00Z", dsun: 191.577021, hgln: 130.77102, hglt: -4.652478 },
  { isoDate: "2020-08-29T00:00:00Z", dsun: 192.484555, hgln: 130.815458, hglt: -4.736937 },
  { isoDate: "2020-08-30T00:00:00Z", dsun: 193.372827, hgln: 130.850219, hglt: -4.819145 },
  { isoDate: "2020-08-31T00:00:00Z", dsun: 194.241741, hgln: 130.875616, hglt: -4.899143 },
  { isoDate: "2020-09-01T00:00:00Z", dsun: 195.091207, hgln: 130.891952, hglt: -4.976974 },
  { isoDate: "2020-09-02T00:00:00Z", dsun: 195.92114, hgln: 130.899518, hglt: -5.052674 },
  { isoDate: "2020-09-03T00:00:00Z", dsun: 196.731464, hgln: 130.898599, hglt: -5.126284 },
  { isoDate: "2020-09-04T00:00:00Z", dsun: 197.522106, hgln: 130.889471, hglt: -5.197839 },
  { isoDate: "2020-09-05T00:00:00Z", dsun: 198.293, hgln: 130.872406, hglt: -5.267375 },
  { isoDate: "2020-09-06T00:00:00Z", dsun: 199.044084, hgln: 130.847667, hglt: -5.334928 },
  { isoDate: "2020-09-07T00:00:00Z", dsun: 199.775302, hgln: 130.815512, hglt: -5.400529 },
  { isoDate: "2020-09-08T00:00:00Z", dsun: 200.486602, hgln: 130.776195, hglt: -5.464212 },
  { isoDate: "2020-09-09T00:00:00Z", dsun: 201.177934, hgln: 130.729964, hglt: -5.526007 },
  { isoDate: "2020-09-10T00:00:00Z", dsun: 201.849254, hgln: 130.677064, hglt: -5.585945 },
  { isoDate: "2020-09-11T00:00:00Z", dsun: 202.500522, hgln: 130.617733, hglt: -5.644054 },
  { isoDate: "2020-09-12T00:00:00Z", dsun: 203.131698, hgln: 130.55221, hglt: -5.700361 },
  { isoDate: "2020-09-13T00:00:00Z", dsun: 203.742748, hgln: 130.480729, hglt: -5.754895 },
  { isoDate: "2020-09-14T00:00:00Z", dsun: 204.33364, hgln: 130.403522, hglt: -5.807679 },
  { isoDate: "2020-09-15T00:00:00Z", dsun: 204.904346, hgln: 130.320823, hglt: -5.858738 },
  { isoDate: "2020-09-16T00:00:00Z", dsun: 205.454836, hgln: 130.232862, hglt: -5.908097 },
  { isoDate: "2020-09-17T00:00:00Z", dsun: 205.985088, hgln: 130.139873, hglt: -5.955778 },
  { isoDate: "2020-09-18T00:00:00Z", dsun: 206.495079, hgln: 130.042086, hglt: -6.001801 },
  { isoDate: "2020-09-19T00:00:00Z", dsun: 206.984787, hgln: 129.939733, hglt: -6.046188 },
  { isoDate: "2020-09-20T00:00:00Z", dsun: 207.454194, hgln: 129.833037, hglt: -6.088959 },
  { isoDate: "2020-09-21T00:00:00Z", dsun: 207.903283, hgln: 129.722218, hglt: -6.130131 },
  { isoDate: "2020-09-22T00:00:00Z", dsun: 208.332037, hgln: 129.607488, hglt: -6.169723 },
  { isoDate: "2020-09-23T00:00:00Z", dsun: 208.740444, hgln: 129.489048, hglt: -6.207752 },
  { isoDate: "2020-09-24T00:00:00Z", dsun: 209.128489, hgln: 129.367094, hglt: -6.244232 },
  { isoDate: "2020-09-25T00:00:00Z", dsun: 209.496162, hgln: 129.241813, hglt: -6.279181 },
  { isoDate: "2020-09-26T00:00:00Z", dsun: 209.843451, hgln: 129.113384, hglt: -6.31261 },
  { isoDate: "2020-09-27T00:00:00Z", dsun: 210.170346, hgln: 128.981982, hglt: -6.344534 },
  { isoDate: "2020-09-28T00:00:00Z", dsun: 210.476841, hgln: 128.847776, hglt: -6.374966 },
  { isoDate: "2020-09-29T00:00:00Z", dsun: 210.762926, hgln: 128.710933, hglt: -6.403917 },
  { isoDate: "2020-09-30T00:00:00Z", dsun: 211.028595, hgln: 128.571614, hglt: -6.431398 },
  { isoDate: "2020-10-01T00:00:00Z", dsun: 211.273841, hgln: 128.429979, hglt: -6.457418 },
  { isoDate: "2020-10-02T00:00:00Z", dsun: 211.49866, hgln: 128.286186, hglt: -6.481987 },
  { isoDate: "2020-10-03T00:00:00Z", dsun: 211.703046, hgln: 128.140391, hglt: -6.505113 },
  { isoDate: "2020-10-04T00:00:00Z", dsun: 211.886995, hgln: 127.992748, hglt: -6.526803 },
  { isoDate: "2020-10-05T00:00:00Z", dsun: 212.050503, hgln: 127.843411, hglt: -6.547066 },
  { isoDate: "2020-10-06T00:00:00Z", dsun: 212.193567, hgln: 127.692534, hglt: -6.565905 },
  { isoDate: "2020-10-07T00:00:00Z", dsun: 212.316184, hgln: 127.540271, hglt: -6.583327 },
  { isoDate: "2020-10-08T00:00:00Z", dsun: 212.418352, hgln: 127.386774, hglt: -6.599336 },
  { isoDate: "2020-10-09T00:00:00Z", dsun: 212.500069, hgln: 127.232199, hglt: -6.613936 },
  { isoDate: "2020-10-10T00:00:00Z", dsun: 212.561334, hgln: 127.076701, hglt: -6.627129 },
  { isoDate: "2020-10-11T00:00:00Z", dsun: 212.602145, hgln: 126.920438, hglt: -6.638917 },
  { isoDate: "2020-10-12T00:00:00Z", dsun: 212.622502, hgln: 126.76357, hglt: -6.649303 },
  { isoDate: "2020-10-13T00:00:00Z", dsun: 212.622404, hgln: 126.606261, hglt: -6.658285 },
  { isoDate: "2020-10-14T00:00:00Z", dsun: 212.601852, hgln: 126.448677, hglt: -6.665865 },
  { isoDate: "2020-10-15T00:00:00Z", dsun: 212.560845, hgln: 126.290993, hglt: -6.672041 },
  { isoDate: "2020-10-16T00:00:00Z", dsun: 212.499384, hgln: 126.133386, hglt: -6.676811 },
  { isoDate: "2020-10-17T00:00:00Z", dsun: 212.417471, hgln: 125.976038, hglt: -6.680173 },
  { isoDate: "2020-10-18T00:00:00Z", dsun: 212.315106, hgln: 125.819136, hglt: -6.682124 },
  { isoDate: "2020-10-19T00:00:00Z", dsun: 212.192293, hgln: 125.662863, hglt: -6.682658 },
  { isoDate: "2020-10-20T00:00:00Z", dsun: 212.049032, hgln: 125.507402, hglt: -6.681772 },
  { isoDate: "2020-10-21T00:00:00Z", dsun: 211.885327, hgln: 125.352934, hglt: -6.679459 },
  { isoDate: "2020-10-22T00:00:00Z", dsun: 211.70118, hgln: 125.199632, hglt: -6.675714 },
  { isoDate: "2020-10-23T00:00:00Z", dsun: 211.496596, hgln: 125.047667, hglt: -6.670528 },
  { isoDate: "2020-10-24T00:00:00Z", dsun: 211.271579, hgln: 124.897207, hglt: -6.663894 },
  { isoDate: "2020-10-25T00:00:00Z", dsun: 211.026133, hgln: 124.748415, hglt: -6.655802 },
  { isoDate: "2020-10-26T00:00:00Z", dsun: 210.760265, hgln: 124.601456, hglt: -6.646242 },
  { isoDate: "2020-10-27T00:00:00Z", dsun: 210.473979, hgln: 124.456491, hglt: -6.635204 },
  { isoDate: "2020-10-28T00:00:00Z", dsun: 210.167283, hgln: 124.313683, hglt: -6.622677 },
  { isoDate: "2020-10-29T00:00:00Z", dsun: 209.840185, hgln: 124.173195, hglt: -6.608647 },
  { isoDate: "2020-10-30T00:00:00Z", dsun: 209.492693, hgln: 124.03519, hglt: -6.593101 },
  { isoDate: "2020-10-31T00:00:00Z", dsun: 209.124817, hgln: 123.899833, hglt: -6.576025 },
  { isoDate: "2020-11-01T00:00:00Z", dsun: 208.736566, hgln: 123.767291, hglt: -6.557404 },
  { isoDate: "2020-11-02T00:00:00Z", dsun: 208.327954, hgln: 123.637733, hglt: -6.537222 },
  { isoDate: "2020-11-03T00:00:00Z", dsun: 207.898992, hgln: 123.511331, hglt: -6.515461 },
  { isoDate: "2020-11-04T00:00:00Z", dsun: 207.449689, hgln: 123.388259, hglt: -6.492102 },
  { isoDate: "2020-11-05T00:00:00Z", dsun: 206.980067, hgln: 123.268695, hglt: -6.467128 },
  { isoDate: "2020-11-06T00:00:00Z", dsun: 206.490142, hgln: 123.152822, hglt: -6.440518 },
  { isoDate: "2020-11-07T00:00:00Z", dsun: 205.979934, hgln: 123.040827, hglt: -6.41225 },
  { isoDate: "2020-11-08T00:00:00Z", dsun: 205.449463, hgln: 122.932903, hglt: -6.382302 },
  { isoDate: "2020-11-09T00:00:00Z", dsun: 204.898751, hgln: 122.829249, hglt: -6.350651 },
  { isoDate: "2020-11-10T00:00:00Z", dsun: 204.327823, hgln: 122.730072, hglt: -6.317272 },
  { isoDate: "2020-11-11T00:00:00Z", dsun: 203.736707, hgln: 122.635586, hglt: -6.282139 },
  { isoDate: "2020-11-12T00:00:00Z", dsun: 203.125431, hgln: 122.546015, hglt: -6.245226 },
  { isoDate: "2020-11-13T00:00:00Z", dsun: 202.494027, hgln: 122.461593, hglt: -6.206505 },
  { isoDate: "2020-11-14T00:00:00Z", dsun: 201.84253, hgln: 122.382564, hglt: -6.165945 },
  { isoDate: "2020-11-15T00:00:00Z", dsun: 201.170979, hgln: 122.309182, hglt: -6.123517 },
  { isoDate: "2020-11-16T00:00:00Z", dsun: 200.479414, hgln: 122.241711, hglt: -6.079188 },
  { isoDate: "2020-11-17T00:00:00Z", dsun: 199.767879, hgln: 122.180416, hglt: -6.032926 },
  { isoDate: "2020-11-18T00:00:00Z", dsun: 199.036423, hgln: 122.125571, hglt: -5.984696 },
  { isoDate: "2020-11-19T00:00:00Z", dsun: 198.285099, hgln: 122.077449, hglt: -5.934462 },
  { isoDate: "2020-11-20T00:00:00Z", dsun: 197.513963, hgln: 122.036325, hglt: -5.882186 },
  { isoDate: "2020-11-21T00:00:00Z", dsun: 196.723076, hgln: 122.002479, hglt: -5.827829 },
  { isoDate: "2020-11-22T00:00:00Z", dsun: 195.912506, hgln: 121.976191, hglt: -5.771353 },
  { isoDate: "2020-11-23T00:00:00Z", dsun: 195.082323, hgln: 121.957747, hglt: -5.712713 },
  { isoDate: "2020-11-24T00:00:00Z", dsun: 194.232605, hgln: 121.947437, hglt: -5.651868 },
  { isoDate: "2020-11-25T00:00:00Z", dsun: 193.363436, hgln: 121.94556, hglt: -5.588772 },
  { isoDate: "2020-11-26T00:00:00Z", dsun: 192.474905, hgln: 121.952418, hglt: -5.523379 },
  { isoDate: "2020-11-27T00:00:00Z", dsun: 191.567109, hgln: 121.968325, hglt: -5.45564 },
  { isoDate: "2020-11-28T00:00:00Z", dsun: 190.640154, hgln: 121.993599, hglt: -5.385506 },
  { isoDate: "2020-11-29T00:00:00Z", dsun: 189.694151, hgln: 122.02857, hglt: -5.312925 },
  { isoDate: "2020-11-30T00:00:00Z", dsun: 188.729222, hgln: 122.073575, hglt: -5.237844 },
  { isoDate: "2020-12-01T00:00:00Z", dsun: 187.745497, hgln: 122.128962, hglt: -5.160208 },
  { isoDate: "2020-12-02T00:00:00Z", dsun: 186.743116, hgln: 122.195091, hglt: -5.07996 },
  { isoDate: "2020-12-03T00:00:00Z", dsun: 185.722229, hgln: 122.272332, hglt: -4.997042 },
  { isoDate: "2020-12-04T00:00:00Z", dsun: 184.682999, hgln: 122.361068, hglt: -4.911394 },
  { isoDate: "2020-12-05T00:00:00Z", dsun: 183.625599, hgln: 122.461696, hglt: -4.822953 },
  { isoDate: "2020-12-06T00:00:00Z", dsun: 182.550214, hgln: 122.574629, hglt: -4.731657 },
  { isoDate: "2020-12-07T00:00:00Z", dsun: 181.457045, hgln: 122.700294, hglt: -4.637439 },
  { isoDate: "2020-12-08T00:00:00Z", dsun: 180.346307, hgln: 122.839138, hglt: -4.540232 },
  { isoDate: "2020-12-09T00:00:00Z", dsun: 179.218228, hgln: 122.991624, hglt: -4.439969 },
  { isoDate: "2020-12-10T00:00:00Z", dsun: 178.073056, hgln: 123.158238, hglt: -4.336578 },
  { isoDate: "2020-12-11T00:00:00Z", dsun: 176.911053, hgln: 123.339486, hglt: -4.229987 },
  { isoDate: "2020-12-12T00:00:00Z", dsun: 175.732502, hgln: 123.535896, hglt: -4.120124 },
  { isoDate: "2020-12-13T00:00:00Z", dsun: 174.537706, hgln: 123.74802, hglt: -4.006913 },
  { isoDate: "2020-12-14T00:00:00Z", dsun: 173.326988, hgln: 123.976433, hglt: -3.890279 },
  { isoDate: "2020-12-15T00:00:00Z", dsun: 172.100693, hgln: 124.221731, hglt: -3.770146 },
  { isoDate: "2020-12-16T00:00:00Z", dsun: 170.859191, hgln: 124.484529, hglt: -3.646434 },
  { isoDate: "2020-12-17T00:00:00Z", dsun: 169.602877, hgln: 124.765463, hglt: -3.519066 },
  { isoDate: "2020-12-18T00:00:00Z", dsun: 168.332173, hgln: 125.065184, hglt: -3.387962 },
  { isoDate: "2020-12-19T00:00:00Z", dsun: 167.047526, hgln: 125.384364, hglt: -3.253045 },
  { isoDate: "2020-12-20T00:00:00Z", dsun: 165.749415, hgln: 125.723689, hglt: -3.114234 },
  { isoDate: "2020-12-21T00:00:00Z", dsun: 164.438347, hgln: 126.083868, hglt: -2.971453 },
  { isoDate: "2020-12-22T00:00:00Z", dsun: 163.114858, hgln: 126.465626, hglt: -2.824621 },
  { isoDate: "2020-12-23T00:00:00Z", dsun: 161.779509, hgln: 126.869713, hglt: -2.673665 },
  { isoDate: "2020-12-24T00:00:00Z", dsun: 160.432876, hgln: 127.296895, hglt: -2.518507 },
  { isoDate: "2020-12-25T00:00:00Z", dsun: 159.07552, hgln: 127.747964, hglt: -2.359071 },
  { isoDate: "2020-12-26T00:00:00Z", dsun: 157.70787, hgln: 128.223725, hglt: -2.195277 },
  { isoDate: "2020-12-27T00:00:00Z", dsun: 156.32938, hgln: 128.724971, hglt: -2.026995 },
  { isoDate: "2020-12-28T00:00:00Z", dsun: 154.921129, hgln: 129.241555, hglt: -1.930321 },
  { isoDate: "2020-12-29T00:00:00Z", dsun: 153.530339, hgln: 129.775483, hglt: -1.916131 },
  { isoDate: "2020-12-30T00:00:00Z", dsun: 152.13061, hgln: 130.337602, hglt: -1.900137 },
  { isoDate: "2020-12-31T00:00:00Z", dsun: 150.721339, hgln: 130.928815, hglt: -1.882337 },
  { isoDate: "2021-01-01T00:00:00Z", dsun: 149.30314, hgln: 131.550121, hglt: -1.862667 },
  { isoDate: "2021-01-02T00:00:00Z", dsun: 147.876881, hgln: 132.202562, hglt: -1.841049 },
  { isoDate: "2021-01-03T00:00:00Z", dsun: 146.443577, hgln: 132.887196, hglt: -1.8174 },
  { isoDate: "2021-01-04T00:00:00Z", dsun: 145.004301, hgln: 133.60514, hglt: -1.791635 },
  { isoDate: "2021-01-05T00:00:00Z", dsun: 143.560246, hgln: 134.357519, hglt: -1.763663 },
  { isoDate: "2021-01-06T00:00:00Z", dsun: 142.112686, hgln: 135.145485, hglt: -1.733392 },
  { isoDate: "2021-01-07T00:00:00Z", dsun: 140.662986, hgln: 135.970214, hglt: -1.700728 },
  { isoDate: "2021-01-08T00:00:00Z", dsun: 139.212603, hgln: 136.832904, hglt: -1.665573 },
  { isoDate: "2021-01-09T00:00:00Z", dsun: 137.763091, hgln: 137.734764, hglt: -1.627832 },
  { isoDate: "2021-01-10T00:00:00Z", dsun: 136.316105, hgln: 138.677018, hglt: -1.587408 },
  { isoDate: "2021-01-11T00:00:00Z", dsun: 134.8734, hgln: 139.660892, hglt: -1.544203 },
  { isoDate: "2021-01-12T00:00:00Z", dsun: 133.436845, hgln: 140.687609, hglt: -1.498123 },
  { isoDate: "2021-01-13T00:00:00Z", dsun: 132.008414, hgln: 141.758376, hglt: -1.449077 },
  { isoDate: "2021-01-14T00:00:00Z", dsun: 130.5902, hgln: 142.874377, hglt: -1.396975 },
  { isoDate: "2021-01-15T00:00:00Z", dsun: 129.184411, hgln: 144.036757, hglt: -1.341735 },
  { isoDate: "2021-01-16T00:00:00Z", dsun: 127.793373, hgln: 145.246611, hglt: -1.283282 },
  { isoDate: "2021-01-17T00:00:00Z", dsun: 126.419531, hgln: 146.504967, hglt: -1.221548 },
  { isoDate: "2021-01-18T00:00:00Z", dsun: 125.065453, hgln: 147.812771, hglt: -1.156479 },
  { isoDate: "2021-01-19T00:00:00Z", dsun: 123.733822, hgln: 149.170874, hglt: -1.088031 },
  { isoDate: "2021-01-20T00:00:00Z", dsun: 122.427436, hgln: 150.580006, hglt: -1.016178 },
  { isoDate: "2021-01-21T00:00:00Z", dsun: 121.149206, hgln: 152.040765, hglt: -0.940911 },
  { isoDate: "2021-01-22T00:00:00Z", dsun: 119.902145, hgln: 153.553587, hglt: -0.862244 },
  { isoDate: "2021-01-23T00:00:00Z", dsun: 118.689363, hgln: 155.118733, hglt: -0.780214 },
  { isoDate: "2021-01-24T00:00:00Z", dsun: 117.514054, hgln: 156.736255, hglt: -0.694883 },
  { isoDate: "2021-01-25T00:00:00Z", dsun: 116.379482, hgln: 158.405984, hglt: -0.606346 },
  { isoDate: "2021-01-26T00:00:00Z", dsun: 115.288965, hgln: 160.127492, hglt: -0.514729 },
  { isoDate: "2021-01-27T00:00:00Z", dsun: 114.245858, hgln: 161.900082, hglt: -0.420191 },
  { isoDate: "2021-01-28T00:00:00Z", dsun: 113.253525, hgln: 163.722755, hglt: -0.322933 },
  { isoDate: "2021-01-29T00:00:00Z", dsun: 112.315321, hgln: 165.594195, hglt: -0.223189 },
  { isoDate: "2021-01-30T00:00:00Z", dsun: 111.434559, hgln: 167.51275, hglt: -0.121237 },
  { isoDate: "2021-01-31T00:00:00Z", dsun: 110.614481, hgln: 169.476421, hglt: -0.017392 },
  { isoDate: "2021-02-01T00:00:00Z", dsun: 109.858228, hgln: 171.482852, hglt: 0.08799 },
  { isoDate: "2021-02-02T00:00:00Z", dsun: 109.168801, hgln: 173.529327, hglt: 0.194517 },
  { isoDate: "2021-02-03T00:00:00Z", dsun: 108.549032, hgln: 175.612777, hglt: 0.301762 },
  { isoDate: "2021-02-04T00:00:00Z", dsun: 108.001542, hgln: 177.729785, hglt: 0.409266 },
  { isoDate: "2021-02-05T00:00:00Z", dsun: 107.528712, hgln: 179.876611, hglt: 0.516548 },
  { isoDate: "2021-02-06T00:00:00Z", dsun: 107.132649, hgln: -177.950792, hglt: 0.623104 },
  { isoDate: "2021-02-07T00:00:00Z", dsun: 106.815152, hgln: -175.756738, hglt: 0.728421 },
  { isoDate: "2021-02-08T00:00:00Z", dsun: 106.577689, hgln: -173.545775, hglt: 0.831979 },
  { isoDate: "2021-02-09T00:00:00Z", dsun: 106.421371, hgln: -171.322638, hglt: 0.933264 },
  { isoDate: "2021-02-10T00:00:00Z", dsun: 106.346937, hgln: -169.092197, hglt: 1.031774 },
  { isoDate: "2021-02-11T00:00:00Z", dsun: 106.35474, hgln: -166.859407, hglt: 1.127027 },
  { isoDate: "2021-02-12T00:00:00Z", dsun: 106.444744, hgln: -164.629248, hglt: 1.218572 },
  { isoDate: "2021-02-13T00:00:00Z", dsun: 106.61652, hgln: -162.406671, hglt: 1.305993 },
  { isoDate: "2021-02-14T00:00:00Z", dsun: 106.869259, hgln: -160.196537, hglt: 1.388918 },
  { isoDate: "2021-02-15T00:00:00Z", dsun: 107.201778, hgln: -158.00357, hglt: 1.467023 },
  { isoDate: "2021-02-16T00:00:00Z", dsun: 107.612545, hgln: -155.832297, hglt: 1.540034 },
  { isoDate: "2021-02-17T00:00:00Z", dsun: 108.099699, hgln: -153.687011, hglt: 1.607734 },
  { isoDate: "2021-02-18T00:00:00Z", dsun: 108.661081, hgln: -151.571729, hglt: 1.669959 },
  { isoDate: "2021-02-19T00:00:00Z", dsun: 109.294259, hgln: -149.490159, hglt: 1.726602 },
  { isoDate: "2021-02-20T00:00:00Z", dsun: 109.99657, hgln: -147.445676, hglt: 1.777608 },
  { isoDate: "2021-02-21T00:00:00Z", dsun: 110.765148, hgln: -145.441307, hglt: 1.822971 },
  { isoDate: "2021-02-22T00:00:00Z", dsun: 111.59696, hgln: -143.479722, hglt: 1.862734 },
  { isoDate: "2021-02-23T00:00:00Z", dsun: 112.488845, hgln: -141.563233, hglt: 1.896981 },
  { isoDate: "2021-02-24T00:00:00Z", dsun: 113.437545, hgln: -139.693792, hglt: 1.925834 },
  { isoDate: "2021-02-25T00:00:00Z", dsun: 114.439735, hgln: -137.873013, hglt: 1.949448 },
  { isoDate: "2021-02-26T00:00:00Z", dsun: 115.492058, hgln: -136.102174, hglt: 1.968002 },
  { isoDate: "2021-02-27T00:00:00Z", dsun: 116.591148, hgln: -134.382243, hglt: 1.981701 },
  { isoDate: "2021-02-28T00:00:00Z", dsun: 117.733656, hgln: -132.713897, hglt: 1.990764 },
  { isoDate: "2021-03-01T00:00:00Z", dsun: 118.916272, hgln: -131.09754, hglt: 1.995424 },
  { isoDate: "2021-03-02T00:00:00Z", dsun: 120.135743, hgln: -129.533328, hglt: 1.995921 },
  { isoDate: "2021-03-03T00:00:00Z", dsun: 121.388892, hgln: -128.021192, hglt: 1.992502 },
  { isoDate: "2021-03-04T00:00:00Z", dsun: 122.672624, hgln: -126.560857, hglt: 1.985412 },
  { isoDate: "2021-03-05T00:00:00Z", dsun: 123.983946, hgln: -125.151869, hglt: 1.974897 },
  { isoDate: "2021-03-06T00:00:00Z", dsun: 125.319967, hgln: -123.793614, hglt: 1.961197 },
  { isoDate: "2021-03-07T00:00:00Z", dsun: 126.677909, hgln: -122.48534, hglt: 1.944548 },
  { isoDate: "2021-03-08T00:00:00Z", dsun: 128.055111, hgln: -121.226178, hglt: 1.925177 },
  { isoDate: "2021-03-09T00:00:00Z", dsun: 129.449026, hgln: -120.01516, hglt: 1.903313 },
  { isoDate: "2021-03-10T00:00:00Z", dsun: 130.857232, hgln: -118.851241, hglt: 1.879143 },
  { isoDate: "2021-03-11T00:00:00Z", dsun: 132.277424, hgln: -117.733309, hglt: 1.852879 },
  { isoDate: "2021-03-12T00:00:00Z", dsun: 133.707414, hgln: -116.660203, hglt: 1.824708 },
  { isoDate: "2021-03-13T00:00:00Z", dsun: 135.145131, hgln: -115.630726, hglt: 1.794808 },
  { isoDate: "2021-03-14T00:00:00Z", dsun: 136.588617, hgln: -114.643658, hglt: 1.763346 },
  { isoDate: "2021-03-15T00:00:00Z", dsun: 138.036025, hgln: -113.697762, hglt: 1.730477 },
  { isoDate: "2021-03-16T00:00:00Z", dsun: 139.485614, hgln: -112.791796, hglt: 1.696349 },
  { isoDate: "2021-03-17T00:00:00Z", dsun: 140.935744, hgln: -111.924522, hglt: 1.661095 },
  { isoDate: "2021-03-18T00:00:00Z", dsun: 142.384875, hgln: -111.094706, hglt: 1.624843 },
  { isoDate: "2021-03-19T00:00:00Z", dsun: 143.831561, hgln: -110.301131, hglt: 1.587709 },
  { isoDate: "2021-03-20T00:00:00Z", dsun: 145.274442, hgln: -109.542596, hglt: 1.549801 },
  { isoDate: "2021-03-21T00:00:00Z", dsun: 146.712248, hgln: -108.817921, hglt: 1.511219 },
  { isoDate: "2021-03-22T00:00:00Z", dsun: 148.143786, hgln: -108.12595, hglt: 1.472053 },
  { isoDate: "2021-03-23T00:00:00Z", dsun: 149.567927, hgln: -107.465561, hglt: 1.432388 },
  { isoDate: "2021-03-24T00:00:00Z", dsun: 150.983655, hgln: -106.835642, hglt: 1.392301 },
  { isoDate: "2021-03-25T00:00:00Z", dsun: 152.389979, hgln: -106.23513, hglt: 1.351864 },
  { isoDate: "2021-03-26T00:00:00Z", dsun: 153.78599, hgln: -105.66299, hglt: 1.31114 },
  { isoDate: "2021-03-27T00:00:00Z", dsun: 155.170835, hgln: -105.118217, hglt: 1.27019 },
  { isoDate: "2021-03-28T00:00:00Z", dsun: 156.543722, hgln: -104.599844, hglt: 1.229067 },
  { isoDate: "2021-03-29T00:00:00Z", dsun: 157.903907, hgln: -104.106936, hglt: 1.18782 },
  { isoDate: "2021-03-30T00:00:00Z", dsun: 159.2507, hgln: -103.63859, hglt: 1.146493 },
  { isoDate: "2021-03-31T00:00:00Z", dsun: 160.583458, hgln: -103.193933, hglt: 1.10513 },
  { isoDate: "2021-04-01T00:00:00Z", dsun: 161.901579, hgln: -102.772121, hglt: 1.063765 },
  { isoDate: "2021-04-02T00:00:00Z", dsun: 163.204505, hgln: -102.372334, hglt: 1.022433 },
  { isoDate: "2021-04-03T00:00:00Z", dsun: 164.491715, hgln: -101.993775, hglt: 0.981163 },
  { isoDate: "2021-04-04T00:00:00Z", dsun: 165.762723, hgln: -101.635671, hglt: 0.939982 },
  { isoDate: "2021-04-05T00:00:00Z", dsun: 167.017079, hgln: -101.297268, hglt: 0.898915 },
  { isoDate: "2021-04-06T00:00:00Z", dsun: 168.254363, hgln: -100.977835, hglt: 0.857984 },
  { isoDate: "2021-04-07T00:00:00Z", dsun: 169.474184, hgln: -100.676665, hglt: 0.81721 },
  { isoDate: "2021-04-08T00:00:00Z", dsun: 170.676179, hgln: -100.393068, hglt: 0.776608 },
  { isoDate: "2021-04-09T00:00:00Z", dsun: 171.860011, hgln: -100.126381, hglt: 0.736197 },
  { isoDate: "2021-04-10T00:00:00Z", dsun: 173.025367, hgln: -99.875957, hglt: 0.695989 },
  { isoDate: "2021-04-11T00:00:00Z", dsun: 174.171955, hgln: -99.641176, hglt: 0.655997 },
  { isoDate: "2021-04-12T00:00:00Z", dsun: 175.299505, hgln: -99.421434, hglt: 0.616232 },
  { isoDate: "2021-04-13T00:00:00Z", dsun: 176.407765, hgln: -99.21615, hglt: 0.576705 },
  { isoDate: "2021-04-14T00:00:00Z", dsun: 177.496505, hgln: -99.024764, hglt: 0.537423 },
  { isoDate: "2021-04-15T00:00:00Z", dsun: 178.565506, hgln: -98.846734, hglt: 0.498394 },
  { isoDate: "2021-04-16T00:00:00Z", dsun: 179.614571, hgln: -98.681538, hglt: 0.459625 },
  { isoDate: "2021-04-17T00:00:00Z", dsun: 180.643512, hgln: -98.528673, hglt: 0.421121 },
  { isoDate: "2021-04-18T00:00:00Z", dsun: 181.652159, hgln: -98.387652, hglt: 0.382886 },
  { isoDate: "2021-04-19T00:00:00Z", dsun: 182.640352, hgln: -98.25801, hglt: 0.344926 },
  { isoDate: "2021-04-20T00:00:00Z", dsun: 183.607945, hgln: -98.139295, hglt: 0.307244 },
  { isoDate: "2021-04-21T00:00:00Z", dsun: 184.554801, hgln: -98.031075, hglt: 0.269841 },
  { isoDate: "2021-04-22T00:00:00Z", dsun: 185.480795, hgln: -97.932932, hglt: 0.232721 },
  { isoDate: "2021-04-23T00:00:00Z", dsun: 186.38581, hgln: -97.844468, hglt: 0.195885 },
  { isoDate: "2021-04-24T00:00:00Z", dsun: 187.26974, hgln: -97.7653, hglt: 0.159334 },
  { isoDate: "2021-04-25T00:00:00Z", dsun: 188.132486, hgln: -97.695064, hglt: 0.123069 },
  { isoDate: "2021-04-26T00:00:00Z", dsun: 188.973957, hgln: -97.63341, hglt: 0.087091 },
  { isoDate: "2021-04-27T00:00:00Z", dsun: 189.794068, hgln: -97.580006, hglt: 0.0514 },
  { isoDate: "2021-04-28T00:00:00Z", dsun: 190.592742, hgln: -97.534535, hglt: 0.015996 },
  { isoDate: "2021-04-29T00:00:00Z", dsun: 191.369909, hgln: -97.496688, hglt: -0.019122 },
  { isoDate: "2021-04-30T00:00:00Z", dsun: 192.125503, hgln: -97.466167, hglt: -0.053954 },
  { isoDate: "2021-05-01T00:00:00Z", dsun: 192.859464, hgln: -97.442678, hglt: -0.088501 },
  { isoDate: "2021-05-02T00:00:00Z", dsun: 193.571737, hgln: -97.425933, hglt: -0.122764 },
  { isoDate: "2021-05-03T00:00:00Z", dsun: 194.262272, hgln: -97.415644, hglt: -0.156743 },
  { isoDate: "2021-05-04T00:00:00Z", dsun: 194.931023, hgln: -97.411528, hglt: -0.19044 },
  { isoDate: "2021-05-05T00:00:00Z", dsun: 195.577947, hgln: -97.413308, hglt: -0.223857 },
  { isoDate: "2021-05-06T00:00:00Z", dsun: 196.203006, hgln: -97.420706, hglt: -0.256994 },
  { isoDate: "2021-05-07T00:00:00Z", dsun: 196.806164, hgln: -97.433454, hglt: -0.289854 },
  { isoDate: "2021-05-08T00:00:00Z", dsun: 197.387391, hgln: -97.451285, hglt: -0.322437 },
  { isoDate: "2021-05-09T00:00:00Z", dsun: 197.946656, hgln: -97.473938, hglt: -0.354745 },
  { isoDate: "2021-05-10T00:00:00Z", dsun: 198.483932, hgln: -97.501157, hglt: -0.38678 },
  { isoDate: "2021-05-11T00:00:00Z", dsun: 198.999197, hgln: -97.532693, hglt: -0.418544 },
  { isoDate: "2021-05-12T00:00:00Z", dsun: 199.492427, hgln: -97.568298, hglt: -0.450037 },
  { isoDate: "2021-05-13T00:00:00Z", dsun: 199.963604, hgln: -97.607732, hglt: -0.481262 },
  { isoDate: "2021-05-14T00:00:00Z", dsun: 200.41271, hgln: -97.650759, hglt: -0.51222 },
  { isoDate: "2021-05-15T00:00:00Z", dsun: 200.839727, hgln: -97.697147, hglt: -0.542912 },
  { isoDate: "2021-05-16T00:00:00Z", dsun: 201.244642, hgln: -97.746672, hglt: -0.573341 },
  { isoDate: "2021-05-17T00:00:00Z", dsun: 201.627442, hgln: -97.799112, hglt: -0.603508 },
  { isoDate: "2021-05-18T00:00:00Z", dsun: 201.988115, hgln: -97.854251, hglt: -0.633413 },
  { isoDate: "2021-05-19T00:00:00Z", dsun: 202.326651, hgln: -97.91188, hglt: -0.663059 },
  { isoDate: "2021-05-20T00:00:00Z", dsun: 202.64304, hgln: -97.971792, hglt: -0.692446 },
  { isoDate: "2021-05-21T00:00:00Z", dsun: 202.937273, hgln: -98.03379, hglt: -0.721577 },
  { isoDate: "2021-05-22T00:00:00Z", dsun: 203.209345, hgln: -98.097679, hglt: -0.750451 },
  { isoDate: "2021-05-23T00:00:00Z", dsun: 203.459247, hgln: -98.163275, hglt: -0.779071 },
  { isoDate: "2021-05-24T00:00:00Z", dsun: 203.686975, hgln: -98.2304, hglt: -0.807436 },
  { isoDate: "2021-05-25T00:00:00Z", dsun: 203.892523, hgln: -98.298883, hglt: -0.835549 },
  { isoDate: "2021-05-26T00:00:00Z", dsun: 204.075887, hgln: -98.36856, hglt: -0.863409 },
  { isoDate: "2021-05-27T00:00:00Z", dsun: 204.237063, hgln: -98.439273, hglt: -0.891018 },
  { isoDate: "2021-05-28T00:00:00Z", dsun: 204.376048, hgln: -98.510866, hglt: -0.918375 },
  { isoDate: "2021-05-29T00:00:00Z", dsun: 204.49284, hgln: -98.583181, hglt: -0.945482 },
  { isoDate: "2021-05-30T00:00:00Z", dsun: 204.587435, hgln: -98.656061, hglt: -0.972338 },
  { isoDate: "2021-05-31T00:00:00Z", dsun: 204.659833, hgln: -98.729341, hglt: -0.998945 },
  { isoDate: "2021-06-01T00:00:00Z", dsun: 204.710032, hgln: -98.802856, hglt: -1.025301 },
  { isoDate: "2021-06-02T00:00:00Z", dsun: 204.737974, hgln: -98.876445, hglt: -1.051518 },
  { isoDate: "2021-06-03T00:00:00Z", dsun: 204.743694, hgln: -98.949927, hglt: -1.077525 },
  { isoDate: "2021-06-04T00:00:00Z", dsun: 204.727213, hgln: -99.02312, hglt: -1.103281 },
  { isoDate: "2021-06-05T00:00:00Z", dsun: 204.68853, hgln: -99.095845, hglt: -1.128786 },
  { isoDate: "2021-06-06T00:00:00Z", dsun: 204.627646, hgln: -99.167921, hglt: -1.154038 },
  { isoDate: "2021-06-07T00:00:00Z", dsun: 204.544562, hgln: -99.239163, hglt: -1.179037 },
  { isoDate: "2021-06-08T00:00:00Z", dsun: 204.43928, hgln: -99.309386, hglt: -1.203782 },
  { isoDate: "2021-06-09T00:00:00Z", dsun: 204.311802, hgln: -99.378402, hglt: -1.228272 },
  { isoDate: "2021-06-10T00:00:00Z", dsun: 204.16213, hgln: -99.446024, hglt: -1.252504 },
  { isoDate: "2021-06-11T00:00:00Z", dsun: 203.990266, hgln: -99.512058, hglt: -1.276477 },
  { isoDate: "2021-06-12T00:00:00Z", dsun: 203.796215, hgln: -99.576312, hglt: -1.30019 },
  { isoDate: "2021-06-13T00:00:00Z", dsun: 203.57998, hgln: -99.638591, hglt: -1.32364 },
  { isoDate: "2021-06-14T00:00:00Z", dsun: 203.341566, hgln: -99.698699, hglt: -1.346825 },
  { isoDate: "2021-06-15T00:00:00Z", dsun: 203.080977, hgln: -99.756437, hglt: -1.369742 },
  { isoDate: "2021-06-16T00:00:00Z", dsun: 202.798221, hgln: -99.811609, hglt: -1.392389 },
  { isoDate: "2021-06-17T00:00:00Z", dsun: 202.493304, hgln: -99.864013, hglt: -1.414762 },
  { isoDate: "2021-06-18T00:00:00Z", dsun: 202.166233, hgln: -99.913451, hglt: -1.436858 },
  { isoDate: "2021-06-19T00:00:00Z", dsun: 201.817017, hgln: -99.959723, hglt: -1.458674 },
  { isoDate: "2021-06-20T00:00:00Z", dsun: 201.445667, hgln: -100.00263, hglt: -1.480204 },
  { isoDate: "2021-06-21T00:00:00Z", dsun: 201.052193, hgln: -100.041976, hglt: -1.501446 },
  { isoDate: "2021-06-22T00:00:00Z", dsun: 200.636607, hgln: -100.077565, hglt: -1.522395 },
  { isoDate: "2021-06-23T00:00:00Z", dsun: 200.198923, hgln: -100.109203, hglt: -1.543045 },
  { isoDate: "2021-06-24T00:00:00Z", dsun: 199.739155, hgln: -100.136697, hglt: -1.563392 },
  { isoDate: "2021-06-25T00:00:00Z", dsun: 199.257322, hgln: -100.159852, hglt: -1.583429 },
  { isoDate: "2021-06-26T00:00:00Z", dsun: 198.753442, hgln: -100.17847, hglt: -1.603152 },
  { isoDate: "2021-06-27T00:00:00Z", dsun: 198.227534, hgln: -100.192346, hglt: -1.622552 },
  { isoDate: "2021-06-28T00:00:00Z", dsun: 197.679623, hgln: -100.201266, hglt: -1.641624 },
  { isoDate: "2021-06-29T00:00:00Z", dsun: 197.109733, hgln: -100.205009, hglt: -1.660361 },
  { isoDate: "2021-06-30T00:00:00Z", dsun: 196.517891, hgln: -100.203342, hglt: -1.678754 },
  { isoDate: "2021-07-01T00:00:00Z", dsun: 195.904128, hgln: -100.196021, hglt: -1.696795 },
  { isoDate: "2021-07-02T00:00:00Z", dsun: 195.268478, hgln: -100.182795, hglt: -1.714475 },
  { isoDate: "2021-07-03T00:00:00Z", dsun: 194.610977, hgln: -100.163401, hglt: -1.731786 },
  { isoDate: "2021-07-04T00:00:00Z", dsun: 193.931665, hgln: -100.137568, hglt: -1.748718 },
  { isoDate: "2021-07-05T00:00:00Z", dsun: 193.230586, hgln: -100.105014, hglt: -1.765259 },
  { isoDate: "2021-07-06T00:00:00Z", dsun: 192.507788, hgln: -100.065448, hglt: -1.781399 },
  { isoDate: "2021-07-07T00:00:00Z", dsun: 191.763323, hgln: -100.01857, hglt: -1.797127 },
  { isoDate: "2021-07-08T00:00:00Z", dsun: 190.997249, hgln: -99.964069, hglt: -1.81243 },
  { isoDate: "2021-07-09T00:00:00Z", dsun: 190.209627, hgln: -99.901623, hglt: -1.827294 },
  { isoDate: "2021-07-10T00:00:00Z", dsun: 189.400525, hgln: -99.830901, hglt: -1.841707 },
  { isoDate: "2021-07-11T00:00:00Z", dsun: 188.570018, hgln: -99.751558, hglt: -1.855653 },
  { isoDate: "2021-07-12T00:00:00Z", dsun: 187.718184, hgln: -99.66324, hglt: -1.869117 },
  { isoDate: "2021-07-13T00:00:00Z", dsun: 186.845112, hgln: -99.565581, hglt: -1.882082 },
  { isoDate: "2021-07-14T00:00:00Z", dsun: 185.950895, hgln: -99.458205, hglt: -1.894531 },
  { isoDate: "2021-07-15T00:00:00Z", dsun: 185.035636, hgln: -99.340723, hglt: -1.906446 },
  { isoDate: "2021-07-16T00:00:00Z", dsun: 184.099445, hgln: -99.212738, hglt: -1.917808 },
  { isoDate: "2021-07-17T00:00:00Z", dsun: 183.142443, hgln: -99.073841, hglt: -1.928596 },
  { isoDate: "2021-07-18T00:00:00Z", dsun: 182.164759, hgln: -98.923614, hglt: -1.93879 },
  { isoDate: "2021-07-19T00:00:00Z", dsun: 181.166535, hgln: -98.761627, hglt: -1.948365 },
  { isoDate: "2021-07-20T00:00:00Z", dsun: 180.147922, hgln: -98.587443, hglt: -1.957298 },
  { isoDate: "2021-07-21T00:00:00Z", dsun: 179.109083, hgln: -98.400613, hglt: -1.965565 },
  { isoDate: "2021-07-22T00:00:00Z", dsun: 178.050197, hgln: -98.200676, hglt: -1.973138 },
  { isoDate: "2021-07-23T00:00:00Z", dsun: 176.971454, hgln: -97.987159, hglt: -1.979989 },
  { isoDate: "2021-07-24T00:00:00Z", dsun: 175.873061, hgln: -97.759574, hglt: -1.986088 },
  { isoDate: "2021-07-25T00:00:00Z", dsun: 174.755239, hgln: -97.517415, hglt: -1.991405 },
  { isoDate: "2021-07-26T00:00:00Z", dsun: 173.618228, hgln: -97.260157, hglt: -1.995907 },
  { isoDate: "2021-07-27T00:00:00Z", dsun: 172.462286, hgln: -96.98725, hglt: -1.999558 },
  { isoDate: "2021-07-28T00:00:00Z", dsun: 171.28769, hgln: -96.698123, hglt: -2.002322 },
  { isoDate: "2021-07-29T00:00:00Z", dsun: 170.094738, hgln: -96.392177, hglt: -2.00416 },
  { isoDate: "2021-07-30T00:00:00Z", dsun: 168.88375, hgln: -96.068791, hglt: -2.005033 },
  { isoDate: "2021-07-31T00:00:00Z", dsun: 167.65507, hgln: -95.727316, hglt: -2.004897 },
  { isoDate: "2021-08-01T00:00:00Z", dsun: 166.409066, hgln: -95.367074, hglt: -2.003708 },
  { isoDate: "2021-08-02T00:00:00Z", dsun: 165.146131, hgln: -94.987365, hglt: -2.001419 },
  { isoDate: "2021-08-03T00:00:00Z", dsun: 163.866683, hgln: -94.587458, hglt: -1.997981 },
  { isoDate: "2021-08-04T00:00:00Z", dsun: 162.571166, hgln: -94.166596, hglt: -1.993344 },
  { isoDate: "2021-08-05T00:00:00Z", dsun: 161.260043, hgln: -93.723992, hglt: -1.987453 },
  { isoDate: "2021-08-06T00:00:00Z", dsun: 159.933781, hgln: -93.258832, hglt: -1.980255 },
  { isoDate: "2021-08-07T00:00:00Z", dsun: 158.592807, hgln: -92.770274, hglt: -1.971698 },
  { isoDate: "2021-08-08T00:00:00Z", dsun: 157.237302, hgln: -92.257458, hglt: -1.961744 },
  { isoDate: "2021-08-09T00:00:00Z", dsun: 155.864698, hgln: -91.719605, hglt: -1.950505 },
  { isoDate: "2021-08-10T00:00:00Z", dsun: 154.457199, hgln: -91.030336, hglt: -1.916922 },
  { isoDate: "2021-08-11T00:00:00Z", dsun: 153.083099, hgln: -90.281354, hglt: -1.87462 },
  { isoDate: "2021-08-12T00:00:00Z", dsun: 151.723878, hgln: -89.502173, hglt: -1.829788 },
  { isoDate: "2021-08-13T00:00:00Z", dsun: 150.380264, hgln: -88.692257, hglt: -1.782394 },
  { isoDate: "2021-08-14T00:00:00Z", dsun: 149.053609, hgln: -87.851124, hglt: -1.732399 },
  { isoDate: "2021-08-15T00:00:00Z", dsun: 147.74546, hgln: -86.978336, hglt: -1.679767 },
  { isoDate: "2021-08-16T00:00:00Z", dsun: 146.457437, hgln: -86.073521, hglt: -1.624423 },
  { isoDate: "2021-08-17T00:00:00Z", dsun: 145.19125, hgln: -85.136356, hglt: -1.566327 },
  { isoDate: "2021-08-18T00:00:00Z", dsun: 143.948671, hgln: -84.166581, hglt: -1.505443 },
  { isoDate: "2021-08-19T00:00:00Z", dsun: 142.731526, hgln: -83.164009, hglt: -1.441745 },
  { isoDate: "2021-08-20T00:00:00Z", dsun: 141.541693, hgln: -82.12853, hglt: -1.375215 },
  { isoDate: "2021-08-21T00:00:00Z", dsun: 140.381092, hgln: -81.060118, hglt: -1.305848 },
  { isoDate: "2021-08-22T00:00:00Z", dsun: 139.251683, hgln: -79.958838, hglt: -1.233649 },
  { isoDate: "2021-08-23T00:00:00Z", dsun: 138.155455, hgln: -78.824853, hglt: -1.158638 },
  { isoDate: "2021-08-24T00:00:00Z", dsun: 137.094423, hgln: -77.658433, hglt: -1.080848 },
  { isoDate: "2021-08-25T00:00:00Z", dsun: 136.070618, hgln: -76.459954, hglt: -1.000329 },
  { isoDate: "2021-08-26T00:00:00Z", dsun: 135.086079, hgln: -75.229912, hglt: -0.917148 },
  { isoDate: "2021-08-27T00:00:00Z", dsun: 134.142843, hgln: -73.968926, hglt: -0.831389 },
  { isoDate: "2021-08-28T00:00:00Z", dsun: 133.242935, hgln: -72.677747, hglt: -0.743155 },
  { isoDate: "2021-08-29T00:00:00Z", dsun: 132.388354, hgln: -71.357259, hglt: -0.652569 },
  { isoDate: "2021-08-30T00:00:00Z", dsun: 131.581065, hgln: -70.008488, hglt: -0.559774 },
  { isoDate: "2021-08-31T00:00:00Z", dsun: 130.822985, hgln: -68.6326, hglt: -0.464933 },
  { isoDate: "2021-09-01T00:00:00Z", dsun: 130.115969, hgln: -67.23091, hglt: -0.368229 },
  { isoDate: "2021-09-02T00:00:00Z", dsun: 129.461795, hgln: -65.804874, hglt: -0.269866 },
  { isoDate: "2021-09-03T00:00:00Z", dsun: 128.862154, hgln: -64.356094, hglt: -0.170064 },
  { isoDate: "2021-09-04T00:00:00Z", dsun: 128.318633, hgln: -62.886309, hglt: -0.069065 },
  { isoDate: "2021-09-05T00:00:00Z", dsun: 127.832705, hgln: -61.397391, hglt: 0.032874 },
  { isoDate: "2021-09-06T00:00:00Z", dsun: 127.405711, hgln: -59.89134, hglt: 0.135483 },
  { isoDate: "2021-09-07T00:00:00Z", dsun: 127.038852, hgln: -58.370268, hglt: 0.238475 },
  { isoDate: "2021-09-08T00:00:00Z", dsun: 126.733175, hgln: -56.836397, hglt: 0.341556 },
  { isoDate: "2021-09-09T00:00:00Z", dsun: 126.489566, hgln: -55.292038, hglt: 0.444423 },
  { isoDate: "2021-09-10T00:00:00Z", dsun: 126.308738, hgln: -53.739583, hglt: 0.54677 },
  { isoDate: "2021-09-11T00:00:00Z", dsun: 126.191223, hgln: -52.181488, hglt: 0.648287 },
  { isoDate: "2021-09-12T00:00:00Z", dsun: 126.137369, hgln: -50.620256, hglt: 0.748668 },
  { isoDate: "2021-09-13T00:00:00Z", dsun: 126.147339, hgln: -49.058424, hglt: 0.847609 },
  { isoDate: "2021-09-14T00:00:00Z", dsun: 126.2211, hgln: -47.498537, hglt: 0.944818 },
  { isoDate: "2021-09-15T00:00:00Z", dsun: 126.358435, hgln: -45.943138, hglt: 1.04001 },
  { isoDate: "2021-09-16T00:00:00Z", dsun: 126.558935, hgln: -44.394743, hglt: 1.132919 },
  { isoDate: "2021-09-17T00:00:00Z", dsun: 126.82201, hgln: -42.855824, hglt: 1.22329 },
  { isoDate: "2021-09-18T00:00:00Z", dsun: 127.146893, hgln: -41.32879, hglt: 1.310893 },
  { isoDate: "2021-09-19T00:00:00Z", dsun: 127.532646, hgln: -39.815972, hglt: 1.395514 },
  { isoDate: "2021-09-20T00:00:00Z", dsun: 127.978173, hgln: -38.319606, hglt: 1.476964 },
  { isoDate: "2021-09-21T00:00:00Z", dsun: 128.482226, hgln: -36.841817, hglt: 1.555077 },
  { isoDate: "2021-09-22T00:00:00Z", dsun: 129.043421, hgln: -35.384611, hglt: 1.629711 },
  { isoDate: "2021-09-23T00:00:00Z", dsun: 129.660249, hgln: -33.949862, hglt: 1.70075 },
  { isoDate: "2021-09-24T00:00:00Z", dsun: 130.331088, hgln: -32.539303, hglt: 1.768101 },
  { isoDate: "2021-09-25T00:00:00Z", dsun: 131.054217, hgln: -31.154525, hglt: 1.831695 },
  { isoDate: "2021-09-26T00:00:00Z", dsun: 131.827831, hgln: -29.796969, hglt: 1.891487 },
  { isoDate: "2021-09-27T00:00:00Z", dsun: 132.650054, hgln: -28.467927, hglt: 1.947454 },
  { isoDate: "2021-09-28T00:00:00Z", dsun: 133.518952, hgln: -27.16854, hglt: 1.999594 },
  { isoDate: "2021-09-29T00:00:00Z", dsun: 134.432548, hgln: -25.899805, hglt: 2.047923 },
  { isoDate: "2021-09-30T00:00:00Z", dsun: 135.388829, hgln: -24.66257, hglt: 2.092478 },
  { isoDate: "2021-10-01T00:00:00Z", dsun: 136.385766, hgln: -23.457548, hglt: 2.133308 },
  { isoDate: "2021-10-02T00:00:00Z", dsun: 137.421318, hgln: -22.285312, hglt: 2.17048 },
  { isoDate: "2021-10-03T00:00:00Z", dsun: 138.493446, hgln: -21.14631, hglt: 2.204071 },
  { isoDate: "2021-10-04T00:00:00Z", dsun: 139.600119, hgln: -20.040867, hglt: 2.23417 },
  { isoDate: "2021-10-05T00:00:00Z", dsun: 140.739326, hgln: -18.969189, hglt: 2.260875 },
  { isoDate: "2021-10-06T00:00:00Z", dsun: 141.909082, hgln: -17.931378, hglt: 2.284291 },
  { isoDate: "2021-10-07T00:00:00Z", dsun: 143.107435, hgln: -16.927437, hglt: 2.304531 },
  { isoDate: "2021-10-08T00:00:00Z", dsun: 144.332468, hgln: -15.957275, hglt: 2.321711 },
  { isoDate: "2021-10-09T00:00:00Z", dsun: 145.582311, hgln: -15.020725, hglt: 2.335949 },
  { isoDate: "2021-10-10T00:00:00Z", dsun: 146.855138, hgln: -14.117546, hglt: 2.347368 },
  { isoDate: "2021-10-11T00:00:00Z", dsun: 148.149175, hgln: -13.247437, hglt: 2.35609 },
  { isoDate: "2021-10-12T00:00:00Z", dsun: 149.4627, hgln: -12.410044, hglt: 2.362239 },
  { isoDate: "2021-10-13T00:00:00Z", dsun: 150.794047, hgln: -11.604967, hglt: 2.365937 },
  { isoDate: "2021-10-14T00:00:00Z", dsun: 152.141606, hgln: -10.831765, hglt: 2.367304 },
  { isoDate: "2021-10-15T00:00:00Z", dsun: 153.503825, hgln: -10.089966, hglt: 2.366459 },
  { isoDate: "2021-10-16T00:00:00Z", dsun: 154.879209, hgln: -9.379067, hglt: 2.36352 },
  { isoDate: "2021-10-17T00:00:00Z", dsun: 156.26632, hgln: -8.69854, hglt: 2.358599 },
  { isoDate: "2021-10-18T00:00:00Z", dsun: 157.663781, hgln: -8.047838, hglt: 2.351807 },
  { isoDate: "2021-10-19T00:00:00Z", dsun: 159.07027, hgln: -7.426394, hglt: 2.34325 },
  { isoDate: "2021-10-20T00:00:00Z", dsun: 160.484521, hgln: -6.833631, hglt: 2.333032 },
  { isoDate: "2021-10-21T00:00:00Z", dsun: 161.905325, hgln: -6.26896, hglt: 2.321252 },
  { isoDate: "2021-10-22T00:00:00Z", dsun: 163.331526, hgln: -5.731781, hglt: 2.308004 },
  { isoDate: "2021-10-23T00:00:00Z", dsun: 164.762021, hgln: -5.221493, hglt: 2.293381 },
  { isoDate: "2021-10-24T00:00:00Z", dsun: 166.195759, hgln: -4.73749, hglt: 2.277468 },
  { isoDate: "2021-10-25T00:00:00Z", dsun: 167.631737, hgln: -4.279163, hglt: 2.26035 },
  { isoDate: "2021-10-26T00:00:00Z", dsun: 169.069003, hgln: -3.845905, hglt: 2.242106 },
  { isoDate: "2021-10-27T00:00:00Z", dsun: 170.506648, hgln: -3.437114, hglt: 2.222811 },
  { isoDate: "2021-10-28T00:00:00Z", dsun: 171.943811, hgln: -3.052187, hglt: 2.202536 },
  { isoDate: "2021-10-29T00:00:00Z", dsun: 173.379671, hgln: -2.690529, hglt: 2.181349 },
  { isoDate: "2021-10-30T00:00:00Z", dsun: 174.813452, hgln: -2.35155, hglt: 2.159315 },
  { isoDate: "2021-10-31T00:00:00Z", dsun: 176.244414, hgln: -2.034667, hglt: 2.136494 },
  { isoDate: "2021-11-01T00:00:00Z", dsun: 177.671856, hgln: -1.739303, hglt: 2.112944 },
  { isoDate: "2021-11-02T00:00:00Z", dsun: 179.095116, hgln: -1.464888, hglt: 2.088719 },
  { isoDate: "2021-11-03T00:00:00Z", dsun: 180.513563, hgln: -1.210857, hglt: 2.06387 },
  { isoDate: "2021-11-04T00:00:00Z", dsun: 181.926602, hgln: -0.976654, hglt: 2.038445 },
  { isoDate: "2021-11-05T00:00:00Z", dsun: 183.333667, hgln: -0.761729, hglt: 2.01249 },
  { isoDate: "2021-11-06T00:00:00Z", dsun: 184.734225, hgln: -0.565542, hglt: 1.986048 },
  { isoDate: "2021-11-07T00:00:00Z", dsun: 186.12777, hgln: -0.387564, hglt: 1.959158 },
  { isoDate: "2021-11-08T00:00:00Z", dsun: 187.513823, hgln: -0.227281, hglt: 1.931859 },
  { isoDate: "2021-11-09T00:00:00Z", dsun: 188.891932, hgln: -0.084194, hglt: 1.904186 },
  { isoDate: "2021-11-10T00:00:00Z", dsun: 190.26167, hgln: 0.042177, hglt: 1.876172 },
  { isoDate: "2021-11-11T00:00:00Z", dsun: 191.622634, hgln: 0.152296, hglt: 1.847849 },
  { isoDate: "2021-11-12T00:00:00Z", dsun: 192.974442, hgln: 0.246608, hglt: 1.819247 },
  { isoDate: "2021-11-13T00:00:00Z", dsun: 194.316734, hgln: 0.325543, hglt: 1.790392 },
  { isoDate: "2021-11-14T00:00:00Z", dsun: 195.649173, hgln: 0.389515, hglt: 1.761311 },
  { isoDate: "2021-11-15T00:00:00Z", dsun: 196.971438, hgln: 0.438925, hglt: 1.732029 },
  { isoDate: "2021-11-16T00:00:00Z", dsun: 198.283229, hgln: 0.474159, hglt: 1.702567 },
  { isoDate: "2021-11-17T00:00:00Z", dsun: 199.584263, hgln: 0.495591, hglt: 1.672948 },
  { isoDate: "2021-11-18T00:00:00Z", dsun: 200.874278, hgln: 0.503582, hglt: 1.643192 },
  { isoDate: "2021-11-19T00:00:00Z", dsun: 202.153027, hgln: 0.498483, hglt: 1.613318 },
  { isoDate: "2021-11-20T00:00:00Z", dsun: 203.420282, hgln: 0.480631, hglt: 1.583345 },
  { isoDate: "2021-11-21T00:00:00Z", dsun: 204.675836, hgln: 0.450356, hglt: 1.553289 },
  { isoDate: "2021-11-22T00:00:00Z", dsun: 205.919508, hgln: 0.407975, hglt: 1.52317 },
  { isoDate: "2021-11-23T00:00:00Z", dsun: 207.151147, hgln: 0.353795, hglt: 1.493005 },
  { isoDate: "2021-11-24T00:00:00Z", dsun: 208.370656, hgln: 0.28811, hglt: 1.462814 },
  { isoDate: "2021-11-25T00:00:00Z", dsun: 209.578064, hgln: 0.211197, hglt: 1.432626 },
  { isoDate: "2021-11-26T00:00:00Z", dsun: 210.773798, hgln: 0.123287, hglt: 1.4025 },
  { isoDate: "2021-11-27T00:00:00Z", dsun: 211.962129, hgln: 0.024247, hglt: 1.372797 },
  { isoDate: "2021-11-28T00:00:00Z", dsun: 212.710214, hgln: -0.246146, hglt: 1.32862 },
  { isoDate: "2021-11-29T00:00:00Z", dsun: 213.295953, hgln: -0.550886, hglt: 1.278157 },
  { isoDate: "2021-11-30T00:00:00Z", dsun: 213.847979, hgln: -0.859306, hglt: 1.227669 },
  { isoDate: "2021-12-01T00:00:00Z", dsun: 214.36697, hgln: -1.171415, hglt: 1.177214 },
  { isoDate: "2021-12-02T00:00:00Z", dsun: 214.853179, hgln: -1.487037, hglt: 1.126803 },
  { isoDate: "2021-12-03T00:00:00Z", dsun: 215.306753, hgln: -1.805958, hglt: 1.076437 },
  { isoDate: "2021-12-04T00:00:00Z", dsun: 215.727814, hgln: -2.127934, hglt: 1.026146 },
  { isoDate: "2021-12-05T00:00:00Z", dsun: 216.116447, hgln: -2.452738, hglt: 0.975905 },
  { isoDate: "2021-12-06T00:00:00Z", dsun: 216.472722, hgln: -2.780139, hglt: 0.925695 },
  { isoDate: "2021-12-07T00:00:00Z", dsun: 216.796704, hgln: -3.109905, hglt: 0.875509 },
  { isoDate: "2021-12-08T00:00:00Z", dsun: 217.088452, hgln: -3.44181, hglt: 0.825341 },
  { isoDate: "2021-12-09T00:00:00Z", dsun: 217.348017, hgln: -3.775633, hglt: 0.775183 },
  { isoDate: "2021-12-10T00:00:00Z", dsun: 217.575443, hgln: -4.111164, hglt: 0.725028 },
  { isoDate: "2021-12-11T00:00:00Z", dsun: 217.770769, hgln: -4.448196, hglt: 0.674869 },
  { isoDate: "2021-12-12T00:00:00Z", dsun: 217.934029, hgln: -4.78653, hglt: 0.624699 },
  { isoDate: "2021-12-13T00:00:00Z", dsun: 218.06525, hgln: -5.12597, hglt: 0.57451 },
  { isoDate: "2021-12-14T00:00:00Z", dsun: 218.164453, hgln: -5.466326, hglt: 0.524295 },
  { isoDate: "2021-12-15T00:00:00Z", dsun: 218.231657, hgln: -5.807408, hglt: 0.474045 },
  { isoDate: "2021-12-16T00:00:00Z", dsun: 218.266873, hgln: -6.149031, hglt: 0.423754 },
  { isoDate: "2021-12-17T00:00:00Z", dsun: 218.270106, hgln: -6.491008, hglt: 0.373413 },
  { isoDate: "2021-12-18T00:00:00Z", dsun: 218.24136, hgln: -6.833156, hglt: 0.323015 },
  { isoDate: "2021-12-19T00:00:00Z", dsun: 218.180628, hgln: -7.175292, hglt: 0.272552 },
  { isoDate: "2021-12-20T00:00:00Z", dsun: 218.087904, hgln: -7.517231, hglt: 0.222016 },
  { isoDate: "2021-12-21T00:00:00Z", dsun: 217.963173, hgln: -7.858789, hglt: 0.171398 },
  { isoDate: "2021-12-22T00:00:00Z", dsun: 217.806415, hgln: -8.19978, hglt: 0.120691 },
  { isoDate: "2021-12-23T00:00:00Z", dsun: 217.617608, hgln: -8.540018, hglt: 0.069886 },
  { isoDate: "2021-12-24T00:00:00Z", dsun: 217.39672, hgln: -8.87931, hglt: 0.018976 },
  { isoDate: "2021-12-25T00:00:00Z", dsun: 217.14372, hgln: -9.217465, hglt: -0.032049 },
  { isoDate: "2021-12-26T00:00:00Z", dsun: 216.858566, hgln: -9.554283, hglt: -0.083196 },
  { isoDate: "2021-12-27T00:00:00Z", dsun: 216.541215, hgln: -9.889563, hglt: -0.134474 },
  { isoDate: "2021-12-28T00:00:00Z", dsun: 216.191617, hgln: -10.223095, hglt: -0.185892 },
  { isoDate: "2021-12-29T00:00:00Z", dsun: 215.809717, hgln: -10.554665, hglt: -0.237459 },
  { isoDate: "2021-12-30T00:00:00Z", dsun: 215.395457, hgln: -10.884047, hglt: -0.289182 },
  { isoDate: "2021-12-31T00:00:00Z", dsun: 214.948771, hgln: -11.21101, hglt: -0.341072 },
  { isoDate: "2022-01-01T00:00:00Z", dsun: 214.469589, hgln: -11.535309, hglt: -0.393136 },
  { isoDate: "2022-01-02T00:00:00Z", dsun: 213.957836, hgln: -11.85669, hglt: -0.445385 },
  { isoDate: "2022-01-03T00:00:00Z", dsun: 213.413432, hgln: -12.174893, hglt: -0.497827 },
  { isoDate: "2022-01-04T00:00:00Z", dsun: 212.836292, hgln: -12.489647, hglt: -0.550471 },
  { isoDate: "2022-01-05T00:00:00Z", dsun: 212.226324, hgln: -12.800677, hglt: -0.603327 },
  { isoDate: "2022-01-06T00:00:00Z", dsun: 211.583434, hgln: -13.107707, hglt: -0.656405 },
  { isoDate: "2022-01-07T00:00:00Z", dsun: 210.907519, hgln: -13.410455, hglt: -0.709713 },
  { isoDate: "2022-01-08T00:00:00Z", dsun: 210.198474, hgln: -13.708639, hglt: -0.763262 },
  { isoDate: "2022-01-09T00:00:00Z", dsun: 209.456188, hgln: -14.001969, hglt: -0.817062 },
  { isoDate: "2022-01-10T00:00:00Z", dsun: 208.680542, hgln: -14.290154, hglt: -0.871121 },
  { isoDate: "2022-01-11T00:00:00Z", dsun: 207.871416, hgln: -14.572895, hglt: -0.925451 },
  { isoDate: "2022-01-12T00:00:00Z", dsun: 207.028683, hgln: -14.849885, hglt: -0.980061 },
  { isoDate: "2022-01-13T00:00:00Z", dsun: 206.152209, hgln: -15.120808, hglt: -1.034962 },
  { isoDate: "2022-01-14T00:00:00Z", dsun: 205.241857, hgln: -15.385337, hglt: -1.090164 },
  { isoDate: "2022-01-15T00:00:00Z", dsun: 204.297485, hgln: -15.643138, hglt: -1.145676 },
  { isoDate: "2022-01-16T00:00:00Z", dsun: 203.318944, hgln: -15.89386, hglt: -1.201511 },
  { isoDate: "2022-01-17T00:00:00Z", dsun: 202.306082, hgln: -16.137142, hglt: -1.257677 },
  { isoDate: "2022-01-18T00:00:00Z", dsun: 201.25874, hgln: -16.372609, hglt: -1.314185 },
  { isoDate: "2022-01-19T00:00:00Z", dsun: 200.176756, hgln: -16.59987, hglt: -1.371047 },
  { isoDate: "2022-01-20T00:00:00Z", dsun: 199.059962, hgln: -16.818517, hglt: -1.428272 },
  { isoDate: "2022-01-21T00:00:00Z", dsun: 197.908186, hgln: -17.028122, hglt: -1.485871 },
  { isoDate: "2022-01-22T00:00:00Z", dsun: 196.721251, hgln: -17.22824, hglt: -1.543855 },
  { isoDate: "2022-01-23T00:00:00Z", dsun: 195.498974, hgln: -17.418402, hglt: -1.602234 },
  { isoDate: "2022-01-24T00:00:00Z", dsun: 194.241172, hgln: -17.598113, hglt: -1.661017 },
  { isoDate: "2022-01-25T00:00:00Z", dsun: 192.947655, hgln: -17.766852, hglt: -1.720216 },
  { isoDate: "2022-01-26T00:00:00Z", dsun: 191.618228, hgln: -17.92407, hglt: -1.77984 },
  { isoDate: "2022-01-27T00:00:00Z", dsun: 190.252696, hgln: -18.069185, hglt: -1.839899 },
  { isoDate: "2022-01-28T00:00:00Z", dsun: 188.850859, hgln: -18.201579, hglt: -1.900401 },
  { isoDate: "2022-01-29T00:00:00Z", dsun: 187.412516, hgln: -18.320597, hglt: -1.961356 },
  { isoDate: "2022-01-30T00:00:00Z", dsun: 185.937462, hgln: -18.425544, hglt: -2.022772 },
  { isoDate: "2022-01-31T00:00:00Z", dsun: 184.425493, hgln: -18.515682, hglt: -2.084656 },
  { isoDate: "2022-02-01T00:00:00Z", dsun: 182.876401, hgln: -18.590231, hglt: -2.147015 },
  { isoDate: "2022-02-02T00:00:00Z", dsun: 181.289982, hgln: -18.648363, hglt: -2.209856 },
  { isoDate: "2022-02-03T00:00:00Z", dsun: 179.66603, hgln: -18.689204, hglt: -2.273182 },
  { isoDate: "2022-02-04T00:00:00Z", dsun: 178.004343, hgln: -18.711834, hglt: -2.336998 },
  { isoDate: "2022-02-05T00:00:00Z", dsun: 176.30472, hgln: -18.715279, hglt: -2.401304 },
  { isoDate: "2022-02-06T00:00:00Z", dsun: 174.566968, hgln: -18.69851, hglt: -2.466102 },
  { isoDate: "2022-02-07T00:00:00Z", dsun: 172.790896, hgln: -18.660437, hglt: -2.53139 },
  { isoDate: "2022-02-08T00:00:00Z", dsun: 170.976325, hgln: -18.599903, hglt: -2.597162 },
  { isoDate: "2022-02-09T00:00:00Z", dsun: 169.123085, hgln: -18.515678, hglt: -2.663411 },
  { isoDate: "2022-02-10T00:00:00Z", dsun: 167.231016, hgln: -18.406452, hglt: -2.730128 },
  { isoDate: "2022-02-11T00:00:00Z", dsun: 165.299978, hgln: -18.270825, hglt: -2.797296 },
  { isoDate: "2022-02-12T00:00:00Z", dsun: 163.329847, hgln: -18.107303, hglt: -2.864896 },
  { isoDate: "2022-02-13T00:00:00Z", dsun: 161.320522, hgln: -17.914285, hglt: -2.932904 },
  { isoDate: "2022-02-14T00:00:00Z", dsun: 159.271928, hgln: -17.690055, hglt: -3.00129 },
  { isoDate: "2022-02-15T00:00:00Z", dsun: 157.184022, hgln: -17.432772, hglt: -3.070013 },
  { isoDate: "2022-02-16T00:00:00Z", dsun: 155.0568, hgln: -17.140453, hglt: -3.139028 },
  { isoDate: "2022-02-17T00:00:00Z", dsun: 152.8903, hgln: -16.810967, hglt: -3.208277 },
  { isoDate: "2022-02-18T00:00:00Z", dsun: 150.684613, hgln: -16.442014, hglt: -3.277693 },
  { isoDate: "2022-02-19T00:00:00Z", dsun: 148.439891, hgln: -16.031109, hglt: -3.347193 },
  { isoDate: "2022-02-20T00:00:00Z", dsun: 146.156355, hgln: -15.575565, hglt: -3.416679 },
  { isoDate: "2022-02-21T00:00:00Z", dsun: 143.834311, hgln: -15.072468, hglt: -3.486034 },
  { isoDate: "2022-02-22T00:00:00Z", dsun: 141.47416, hgln: -14.518658, hglt: -3.555121 },
  { isoDate: "2022-02-23T00:00:00Z", dsun: 139.076416, hgln: -13.910699, hglt: -3.623776 },
  { isoDate: "2022-02-24T00:00:00Z", dsun: 136.641726, hgln: -13.244854, hglt: -3.691804 },
  { isoDate: "2022-02-25T00:00:00Z", dsun: 134.170888, hgln: -12.517049, hglt: -3.758978 },
  { isoDate: "2022-02-26T00:00:00Z", dsun: 131.664879, hgln: -11.722846, hglt: -3.825028 },
  { isoDate: "2022-02-27T00:00:00Z", dsun: 129.124886, hgln: -10.8574, hglt: -3.889636 },
  { isoDate: "2022-02-28T00:00:00Z", dsun: 126.552335, hgln: -9.915422, hglt: -3.952428 },
  { isoDate: "2022-03-01T00:00:00Z", dsun: 123.94894, hgln: -8.891134, hglt: -4.012957 },
  { isoDate: "2022-03-02T00:00:00Z", dsun: 121.316738, hgln: -7.778227, hglt: -4.070716 },
  { isoDate: "2022-03-03T00:00:00Z", dsun: 118.658154, hgln: -6.569814, hglt: -4.12509 },
  { isoDate: "2022-03-04T00:00:00Z", dsun: 115.976062, hgln: -5.258376, hglt: -4.175361 },
  { isoDate: "2022-03-05T00:00:00Z", dsun: 113.273854, hgln: -3.835719, hglt: -4.220688 },
  { isoDate: "2022-03-06T00:00:00Z", dsun: 110.555531, hgln: -2.292922, hglt: -4.260083 },
  { isoDate: "2022-03-07T00:00:00Z", dsun: 107.825798, hgln: -0.620297, hglt: -4.292393 },
  { isoDate: "2022-03-08T00:00:00Z", dsun: 105.090179, hgln: 1.192644, hglt: -4.316267 },
  { isoDate: "2022-03-09T00:00:00Z", dsun: 102.355142, hgln: 3.157205, hglt: -4.330136 },
  { isoDate: "2022-03-10T00:00:00Z", dsun: 99.628246, hgln: 5.285491, hglt: -4.33218 },
  { isoDate: "2022-03-11T00:00:00Z", dsun: 96.918296, hgln: 7.59036, hglt: -4.320304 },
  { isoDate: "2022-03-12T00:00:00Z", dsun: 94.235515, hgln: 10.085308, hglt: -4.292114 },
  { isoDate: "2022-03-13T00:00:00Z", dsun: 91.591728, hgln: 12.784277, hglt: -4.244903 },
  { isoDate: "2022-03-14T00:00:00Z", dsun: 89.000533, hgln: 15.701363, hglt: -4.175653 },
  { isoDate: "2022-03-15T00:00:00Z", dsun: 86.477469, hgln: 18.850372, hglt: -4.081062 },
  { isoDate: "2022-03-16T00:00:00Z", dsun: 84.040144, hgln: 22.244209, hglt: -3.957606 },
  { isoDate: "2022-03-17T00:00:00Z", dsun: 81.708299, hgln: 25.894069, hglt: -3.801659 },
  { isoDate: "2022-03-18T00:00:00Z", dsun: 79.503771, hgln: 29.808389, hglt: -3.609672 },
  { isoDate: "2022-03-19T00:00:00Z", dsun: 77.450309, hgln: 33.991596, hglt: -3.378439 },
  { isoDate: "2022-03-20T00:00:00Z", dsun: 75.573192, hgln: 38.442677, hglt: -3.10544 },
  { isoDate: "2022-03-21T00:00:00Z", dsun: 73.898605, hgln: 43.153675, hglt: -2.78926 },
  { isoDate: "2022-03-22T00:00:00Z", dsun: 72.452741, hgln: 48.108314, hglt: -2.430042 },
  { isoDate: "2022-03-23T00:00:00Z", dsun: 71.260643, hgln: 53.280971, hglt: -2.029905 },
  { isoDate: "2022-03-24T00:00:00Z", dsun: 70.344842, hgln: 58.636303, hglt: -1.593236 },
  { isoDate: "2022-03-25T00:00:00Z", dsun: 69.723907, hgln: 64.129779, hglt: -1.126756 },
  { isoDate: "2022-03-26T00:00:00Z", dsun: 69.4111, hgln: 69.709273, hglt: -0.639283 },
  { isoDate: "2022-03-27T00:00:00Z", dsun: 69.413313, hgln: 75.317653, hglt: -0.141165 },
  { isoDate: "2022-03-28T00:00:00Z", dsun: 69.730498, hgln: 80.896097, hglt: 0.356537 },
  { isoDate: "2022-03-29T00:00:00Z", dsun: 70.355666, hgln: 86.387665, hglt: 0.84299 },
  { isoDate: "2022-03-30T00:00:00Z", dsun: 71.275473, hgln: 91.740581, hglt: 1.308475 },
  { isoDate: "2022-03-31T00:00:00Z", dsun: 72.471284, hgln: 96.910773, hglt: 1.745049 },
  { isoDate: "2022-04-01T00:00:00Z", dsun: 73.920524, hgln: 101.8634, hglt: 2.146927 },
  { isoDate: "2022-04-02T00:00:00Z", dsun: 75.598123, hgln: 106.573331, hglt: 2.510535 },
  { isoDate: "2022-04-03T00:00:00Z", dsun: 77.477881, hgln: 111.024723, hglt: 2.834332 },
  { isoDate: "2022-04-04T00:00:00Z", dsun: 79.53362, hgln: 115.209979, hglt: 3.118463 },
  { isoDate: "2022-04-05T00:00:00Z", dsun: 81.74008, hgln: 119.128351, hglt: 3.364356 },
  { isoDate: "2022-04-06T00:00:00Z", dsun: 84.073537, hgln: 122.78445, hglt: 3.574318 },
  { isoDate: "2022-04-07T00:00:00Z", dsun: 86.512182, hgln: 126.186823, hglt: 3.751184 },
  { isoDate: "2022-04-08T00:00:00Z", dsun: 89.036303, hgln: 129.346713, hglt: 3.898043 },
  { isoDate: "2022-04-09T00:00:00Z", dsun: 91.628323, hgln: 132.277027, hglt: 4.018026 },
  { isoDate: "2022-04-10T00:00:00Z", dsun: 94.272732, hgln: 134.991542, hglt: 4.114167 },
  { isoDate: "2022-04-11T00:00:00Z", dsun: 96.955956, hgln: 137.504297, hglt: 4.189317 },
  { isoDate: "2022-04-12T00:00:00Z", dsun: 99.666196, hgln: 139.829167, hglt: 4.24609 },
  { isoDate: "2022-04-13T00:00:00Z", dsun: 102.393249, hgln: 141.979569, hglt: 4.286849 },
  { isoDate: "2022-04-14T00:00:00Z", dsun: 105.128329, hgln: 143.968273, hglt: 4.313699 },
  { isoDate: "2022-04-15T00:00:00Z", dsun: 107.863893, hgln: 145.807297, hglt: 4.328502 },
  { isoDate: "2022-04-16T00:00:00Z", dsun: 110.593488, hgln: 147.507851, hglt: 4.332889 },
  { isoDate: "2022-04-17T00:00:00Z", dsun: 113.311602, hgln: 149.080329, hglt: 4.328288 },
  { isoDate: "2022-04-18T00:00:00Z", dsun: 116.01354, hgln: 150.534322, hglt: 4.315939 },
  { isoDate: "2022-04-19T00:00:00Z", dsun: 118.695312, hgln: 151.878655, hglt: 4.296921 },
  { isoDate: "2022-04-20T00:00:00Z", dsun: 121.353532, hgln: 153.121431, hglt: 4.272165 },
  { isoDate: "2022-04-21T00:00:00Z", dsun: 123.985333, hgln: 154.270076, hglt: 4.24248 },
  { isoDate: "2022-04-22T00:00:00Z", dsun: 126.588299, hgln: 155.3314, hglt: 4.208564 },
  { isoDate: "2022-04-23T00:00:00Z", dsun: 129.160392, hgln: 156.311638, hglt: 4.171022 },
  { isoDate: "2022-04-24T00:00:00Z", dsun: 131.699908, hgln: 157.216508, hglt: 4.130375 },
  { isoDate: "2022-04-25T00:00:00Z", dsun: 134.20542, hgln: 158.051247, hglt: 4.087076 },
  { isoDate: "2022-04-26T00:00:00Z", dsun: 136.675747, hgln: 158.820662, hglt: 4.041515 },
  { isoDate: "2022-04-27T00:00:00Z", dsun: 139.109915, hgln: 159.529165, hglt: 3.994031 },
  { isoDate: "2022-04-28T00:00:00Z", dsun: 141.507126, hgln: 160.180808, hglt: 3.944917 },
  { isoDate: "2022-04-29T00:00:00Z", dsun: 143.866738, hgln: 160.779318, hglt: 3.894425 },
  { isoDate: "2022-04-30T00:00:00Z", dsun: 146.188236, hgln: 161.328125, hglt: 3.842777 },
  { isoDate: "2022-05-01T00:00:00Z", dsun: 148.471222, hgln: 161.830387, hglt: 3.790163 },
  { isoDate: "2022-05-02T00:00:00Z", dsun: 150.715391, hgln: 162.289018, hglt: 3.736747 },
  { isoDate: "2022-05-03T00:00:00Z", dsun: 152.920523, hgln: 162.706707, hglt: 3.682674 },
  { isoDate: "2022-05-04T00:00:00Z", dsun: 155.086467, hgln: 163.085939, hglt: 3.628068 },
  { isoDate: "2022-05-05T00:00:00Z", dsun: 157.213133, hgln: 163.429013, hglt: 3.573036 },
  { isoDate: "2022-05-06T00:00:00Z", dsun: 159.300483, hgln: 163.738057, hglt: 3.517673 },
  { isoDate: "2022-05-07T00:00:00Z", dsun: 161.348522, hgln: 164.015044, hglt: 3.462058 },
  { isoDate: "2022-05-08T00:00:00Z", dsun: 163.357295, hgln: 164.261807, hglt: 3.406262 },
  { isoDate: "2022-05-09T00:00:00Z", dsun: 165.326875, hgln: 164.480046, hglt: 3.350346 },
  { isoDate: "2022-05-10T00:00:00Z", dsun: 167.257365, hgln: 164.671345, hglt: 3.294362 },
  { isoDate: "2022-05-11T00:00:00Z", dsun: 169.148883, hgln: 164.837176, hglt: 3.238354 },
  { isoDate: "2022-05-12T00:00:00Z", dsun: 171.001575, hgln: 164.978912, hglt: 3.182363 },
  { isoDate: "2022-05-13T00:00:00Z", dsun: 172.8156, hgln: 165.09783, hglt: 3.12642 },
  { isoDate: "2022-05-14T00:00:00Z", dsun: 174.59113, hgln: 165.195122, hglt: 3.070554 },
  { isoDate: "2022-05-15T00:00:00Z", dsun: 176.328345, hgln: 165.271899, hglt: 3.014789 },
  { isoDate: "2022-05-16T00:00:00Z", dsun: 178.027433, hgln: 165.329197, hglt: 2.959145 },
  { isoDate: "2022-05-17T00:00:00Z", dsun: 179.688591, hgln: 165.367986, hglt: 2.903639 },
  { isoDate: "2022-05-18T00:00:00Z", dsun: 181.312017, hgln: 165.389172, hglt: 2.848286 },
  { isoDate: "2022-05-19T00:00:00Z", dsun: 182.897914, hgln: 165.39361, hglt: 2.793096 },
  { isoDate: "2022-05-20T00:00:00Z", dsun: 184.446489, hgln: 165.382106, hglt: 2.73808 },
  { isoDate: "2022-05-21T00:00:00Z", dsun: 185.957946, hgln: 165.355422, hglt: 2.683243 },
  { isoDate: "2022-05-22T00:00:00Z", dsun: 187.432492, hgln: 165.314284, hglt: 2.628592 },
  { isoDate: "2022-05-23T00:00:00Z", dsun: 188.870332, hgln: 165.25938, hglt: 2.574131 },
  { isoDate: "2022-05-24T00:00:00Z", dsun: 190.27167, hgln: 165.191369, hglt: 2.519862 },
  { isoDate: "2022-05-25T00:00:00Z", dsun: 191.636709, hgln: 165.110875, hglt: 2.465787 },
  { isoDate: "2022-05-26T00:00:00Z", dsun: 192.965646, hgln: 165.018495, hglt: 2.411907 },
  { isoDate: "2022-05-27T00:00:00Z", dsun: 194.25868, hgln: 164.914798, hglt: 2.35822 },
  { isoDate: "2022-05-28T00:00:00Z", dsun: 195.516003, hgln: 164.800326, hglt: 2.304725 },
  { isoDate: "2022-05-29T00:00:00Z", dsun: 196.737805, hgln: 164.675597, hglt: 2.25142 },
  { isoDate: "2022-05-30T00:00:00Z", dsun: 197.924271, hgln: 164.541103, hglt: 2.198303 },
  { isoDate: "2022-05-31T00:00:00Z", dsun: 199.075584, hgln: 164.397317, hglt: 2.14537 },
  { isoDate: "2022-06-01T00:00:00Z", dsun: 200.191918, hgln: 164.244687, hglt: 2.092618 },
  { isoDate: "2022-06-02T00:00:00Z", dsun: 201.273449, hgln: 164.083643, hglt: 2.040041 },
  { isoDate: "2022-06-03T00:00:00Z", dsun: 202.320342, hgln: 163.914596, hglt: 1.987636 },
  { isoDate: "2022-06-04T00:00:00Z", dsun: 203.332761, hgln: 163.737936, hglt: 1.935398 },
  { isoDate: "2022-06-05T00:00:00Z", dsun: 204.310864, hgln: 163.554038, hglt: 1.883321 },
  { isoDate: "2022-06-06T00:00:00Z", dsun: 205.254804, hgln: 163.363261, hglt: 1.8314 },
  { isoDate: "2022-06-07T00:00:00Z", dsun: 206.164729, hgln: 163.165947, hglt: 1.779629 },
  { isoDate: "2022-06-08T00:00:00Z", dsun: 207.040782, hgln: 162.962422, hglt: 1.728002 },
  { isoDate: "2022-06-09T00:00:00Z", dsun: 207.8831, hgln: 162.753, hglt: 1.676513 },
  { isoDate: "2022-06-10T00:00:00Z", dsun: 208.691816, hgln: 162.537979, hglt: 1.625155 },
  { isoDate: "2022-06-11T00:00:00Z", dsun: 209.467057, hgln: 162.317642, hglt: 1.573923 },
  { isoDate: "2022-06-12T00:00:00Z", dsun: 210.208946, hgln: 162.092259, hglt: 1.522809 },
  { isoDate: "2022-06-13T00:00:00Z", dsun: 210.917599, hgln: 161.862084, hglt: 1.471807 },
  { isoDate: "2022-06-14T00:00:00Z", dsun: 211.593129, hgln: 161.627359, hglt: 1.42091 },
  { isoDate: "2022-06-15T00:00:00Z", dsun: 212.23564, hgln: 161.388315, hglt: 1.370111 },
  { isoDate: "2022-06-16T00:00:00Z", dsun: 212.845235, hgln: 161.145172, hglt: 1.319403 },
  { isoDate: "2022-06-17T00:00:00Z", dsun: 213.422009, hgln: 160.898145, hglt: 1.26878 },
  { isoDate: "2022-06-18T00:00:00Z", dsun: 213.966054, hgln: 160.647447, hglt: 1.218234 },
  { isoDate: "2022-06-19T00:00:00Z", dsun: 214.477454, hgln: 160.393287, hglt: 1.167758 },
  { isoDate: "2022-06-20T00:00:00Z", dsun: 214.956291, hgln: 160.135878, hglt: 1.117345 },
  { isoDate: "2022-06-21T00:00:00Z", dsun: 215.40264, hgln: 159.875428, hglt: 1.066987 },
  { isoDate: "2022-06-22T00:00:00Z", dsun: 215.81657, hgln: 159.612149, hglt: 1.016678 },
  { isoDate: "2022-06-23T00:00:00Z", dsun: 216.198147, hgln: 159.346251, hglt: 0.96641 },
  { isoDate: "2022-06-24T00:00:00Z", dsun: 216.54743, hgln: 159.077941, hglt: 0.916175 },
  { isoDate: "2022-06-25T00:00:00Z", dsun: 216.864475, hgln: 158.807428, hglt: 0.865966 },
  { isoDate: "2022-06-26T00:00:00Z", dsun: 217.149331, hgln: 158.534918, hglt: 0.815776 },
  { isoDate: "2022-06-27T00:00:00Z", dsun: 217.402043, hgln: 158.260614, hglt: 0.765598 },
  { isoDate: "2022-06-28T00:00:00Z", dsun: 217.62265, hgln: 157.984719, hglt: 0.715422 },
  { isoDate: "2022-06-29T00:00:00Z", dsun: 217.811187, hgln: 157.707436, hglt: 0.665243 },
  { isoDate: "2022-06-30T00:00:00Z", dsun: 217.967683, hgln: 157.428963, hglt: 0.615053 },
  { isoDate: "2022-07-01T00:00:00Z", dsun: 218.092164, hgln: 157.1495, hglt: 0.564843 },
  { isoDate: "2022-07-02T00:00:00Z", dsun: 218.184647, hgln: 156.869244, hglt: 0.514606 },
  { isoDate: "2022-07-03T00:00:00Z", dsun: 218.245148, hgln: 156.588389, hglt: 0.464335 },
  { isoDate: "2022-07-04T00:00:00Z", dsun: 218.273677, hgln: 156.307131, hglt: 0.414021 },
  { isoDate: "2022-07-05T00:00:00Z", dsun: 218.270237, hgln: 156.025663, hglt: 0.363656 },
  { isoDate: "2022-07-06T00:00:00Z", dsun: 218.234829, hgln: 155.744175, hglt: 0.313233 },
  { isoDate: "2022-07-07T00:00:00Z", dsun: 218.167446, hgln: 155.462857, hglt: 0.262744 },
  { isoDate: "2022-07-08T00:00:00Z", dsun: 218.068078, hgln: 155.181899, hglt: 0.212181 },
  { isoDate: "2022-07-09T00:00:00Z", dsun: 217.936709, hgln: 154.901485, hglt: 0.161535 },
  { isoDate: "2022-07-10T00:00:00Z", dsun: 217.773319, hgln: 154.621797, hglt: 0.110799 },
  { isoDate: "2022-07-11T00:00:00Z", dsun: 217.577883, hgln: 154.343017, hglt: 0.059963 },
  { isoDate: "2022-07-12T00:00:00Z", dsun: 217.350366, hgln: 154.065324, hglt: 0.009027 },
  { isoDate: "2022-07-13T00:00:00Z", dsun: 217.09074, hgln: 153.788882, hglt: -0.042032 },
  { isoDate: "2022-07-14T00:00:00Z", dsun: 216.798959, hgln: 153.513868, hglt: -0.093214 },
  { isoDate: "2022-07-15T00:00:00Z", dsun: 216.47498, hgln: 153.240455, hglt: -0.144529 },
  { isoDate: "2022-07-16T00:00:00Z", dsun: 216.11875, hgln: 152.968822, hglt: -0.195985 },
  { isoDate: "2022-07-17T00:00:00Z", dsun: 215.730214, hgln: 152.699152, hglt: -0.247591 },
  { isoDate: "2022-07-18T00:00:00Z", dsun: 215.309311, hgln: 152.431637, hglt: -0.299355 },
  { isoDate: "2022-07-19T00:00:00Z", dsun: 214.855974, hgln: 152.16648, hglt: -0.351287 },
  { isoDate: "2022-07-20T00:00:00Z", dsun: 214.370133, hgln: 151.903893, hglt: -0.403396 },
  { isoDate: "2022-07-21T00:00:00Z", dsun: 213.851711, hgln: 151.644095, hglt: -0.455691 },
  { isoDate: "2022-07-22T00:00:00Z", dsun: 213.300627, hgln: 151.387316, hglt: -0.50818 },
  { isoDate: "2022-07-23T00:00:00Z", dsun: 212.716793, hgln: 151.133793, hglt: -0.560874 },
  { isoDate: "2022-07-24T00:00:00Z", dsun: 212.100119, hgln: 150.883774, hglt: -0.613781 },
  { isoDate: "2022-07-25T00:00:00Z", dsun: 211.450506, hgln: 150.637513, hglt: -0.666911 },
  { isoDate: "2022-07-26T00:00:00Z", dsun: 210.767852, hgln: 150.395274, hglt: -0.720275 },
  { isoDate: "2022-07-27T00:00:00Z", dsun: 210.052051, hgln: 150.157329, hglt: -0.77388 },
  { isoDate: "2022-07-28T00:00:00Z", dsun: 209.302988, hgln: 149.923962, hglt: -0.827738 },
  { isoDate: "2022-07-29T00:00:00Z", dsun: 208.520546, hgln: 149.695464, hglt: -0.881858 },
  { isoDate: "2022-07-30T00:00:00Z", dsun: 207.704602, hgln: 149.472138, hglt: -0.93625 },
  { isoDate: "2022-07-31T00:00:00Z", dsun: 206.855027, hgln: 149.254297, hglt: -0.990924 },
  { isoDate: "2022-08-01T00:00:00Z", dsun: 205.971689, hgln: 149.042266, hglt: -1.045891 },
  { isoDate: "2022-08-02T00:00:00Z", dsun: 205.054446, hgln: 148.836381, hglt: -1.101161 },
  { isoDate: "2022-08-03T00:00:00Z", dsun: 204.103156, hgln: 148.636988, hglt: -1.156743 },
  { isoDate: "2022-08-04T00:00:00Z", dsun: 203.11767, hgln: 148.444448, hglt: -1.21265 },
  { isoDate: "2022-08-05T00:00:00Z", dsun: 202.097832, hgln: 148.259133, hglt: -1.26889 },
  { isoDate: "2022-08-06T00:00:00Z", dsun: 201.043485, hgln: 148.081425, hglt: -1.325475 },
  { isoDate: "2022-08-07T00:00:00Z", dsun: 199.954464, hgln: 147.911723, hglt: -1.382414 },
  { isoDate: "2022-08-08T00:00:00Z", dsun: 198.8306, hgln: 147.750438, hglt: -1.43972 },
  { isoDate: "2022-08-09T00:00:00Z", dsun: 197.67172, hgln: 147.597993, hglt: -1.497401 },
  { isoDate: "2022-08-10T00:00:00Z", dsun: 196.477645, hgln: 147.454827, hglt: -1.555469 },
  { isoDate: "2022-08-11T00:00:00Z", dsun: 195.248193, hgln: 147.321397, hglt: -1.613934 },
  { isoDate: "2022-08-12T00:00:00Z", dsun: 193.983178, hgln: 147.198177, hglt: -1.672806 },
  { isoDate: "2022-08-13T00:00:00Z", dsun: 192.682408, hgln: 147.085668, hglt: -1.732095 },
  { isoDate: "2022-08-14T00:00:00Z", dsun: 191.34569, hgln: 146.984394, hglt: -1.791811 },
  { isoDate: "2022-08-15T00:00:00Z", dsun: 189.972826, hgln: 146.894914, hglt: -1.851963 },
  { isoDate: "2022-08-16T00:00:00Z", dsun: 188.563615, hgln: 146.817821, hglt: -1.912561 },
  { isoDate: "2022-08-17T00:00:00Z", dsun: 187.117856, hgln: 146.753743, hglt: -1.973614 },
  { isoDate: "2022-08-18T00:00:00Z", dsun: 185.635342, hgln: 146.703351, hglt: -2.035129 },
  { isoDate: "2022-08-19T00:00:00Z", dsun: 184.115869, hgln: 146.667356, hglt: -2.097113 },
  { isoDate: "2022-08-20T00:00:00Z", dsun: 182.559229, hgln: 146.646513, hglt: -2.159574 },
  { isoDate: "2022-08-21T00:00:00Z", dsun: 180.965215, hgln: 146.641627, hglt: -2.222517 },
  { isoDate: "2022-08-22T00:00:00Z", dsun: 179.333622, hgln: 146.653549, hglt: -2.285947 },
  { isoDate: "2022-08-23T00:00:00Z", dsun: 177.664247, hgln: 146.683186, hglt: -2.349867 },
  { isoDate: "2022-08-24T00:00:00Z", dsun: 175.95689, hgln: 146.731502, hglt: -2.414278 },
  { isoDate: "2022-08-25T00:00:00Z", dsun: 174.211355, hgln: 146.799525, hglt: -2.479181 },
  { isoDate: "2022-08-26T00:00:00Z", dsun: 172.427451, hgln: 146.888348, hglt: -2.544573 },
  { isoDate: "2022-08-27T00:00:00Z", dsun: 170.604997, hgln: 146.99914, hglt: -2.610448 },
  { isoDate: "2022-08-28T00:00:00Z", dsun: 168.74382, hgln: 147.133144, hglt: -2.676799 },
  { isoDate: "2022-08-29T00:00:00Z", dsun: 166.843759, hgln: 147.291694, hglt: -2.743614 },
  { isoDate: "2022-08-30T00:00:00Z", dsun: 164.904666, hgln: 147.476211, hglt: -2.810878 },
  { isoDate: "2022-08-31T00:00:00Z", dsun: 162.926404, hgln: 147.688218, hglt: -2.87857 },
  { isoDate: "2022-09-01T00:00:00Z", dsun: 160.908848, hgln: 147.929345, hglt: -2.946665 },
  { isoDate: "2022-09-02T00:00:00Z", dsun: 158.851863, hgln: 148.201333, hglt: -3.015129 },
  { isoDate: "2022-09-03T00:00:00Z", dsun: 156.755212, hgln: 148.506035, hglt: -3.083926 },
  { isoDate: "2022-09-04T00:00:00Z", dsun: 154.616447, hgln: 148.84513, hglt: -3.153038 },
  { isoDate: "2022-09-05T00:00:00Z", dsun: 152.514552, hgln: 149.155926, hglt: -3.312565 },
  { isoDate: "2022-09-06T00:00:00Z", dsun: 150.380801, hgln: 149.500238, hglt: -3.480383 },
  { isoDate: "2022-09-07T00:00:00Z", dsun: 148.20065, hgln: 149.883131, hglt: -3.651136 },
  { isoDate: "2022-09-08T00:00:00Z", dsun: 145.973745, hgln: 150.307216, hglt: -3.824887 },
  { isoDate: "2022-09-09T00:00:00Z", dsun: 143.699948, hgln: 150.775363, hglt: -4.001683 },
  { isoDate: "2022-09-10T00:00:00Z", dsun: 141.379217, hgln: 151.290709, hglt: -4.181551 },
  { isoDate: "2022-09-11T00:00:00Z", dsun: 139.011601, hgln: 151.856683, hglt: -4.364502 },
  { isoDate: "2022-09-12T00:00:00Z", dsun: 136.597248, hgln: 152.477043, hglt: -4.550523 },
  { isoDate: "2022-09-13T00:00:00Z", dsun: 134.136415, hgln: 153.155913, hglt: -4.739571 },
  { isoDate: "2022-09-14T00:00:00Z", dsun: 131.629493, hgln: 153.897829, hglt: -4.931566 },
  { isoDate: "2022-09-15T00:00:00Z", dsun: 129.077031, hgln: 154.707784, hglt: -5.12638 },
  { isoDate: "2022-09-16T00:00:00Z", dsun: 126.479764, hgln: 155.591287, hglt: -5.323828 },
  { isoDate: "2022-09-17T00:00:00Z", dsun: 123.838652, hgln: 156.55442, hglt: -5.523649 },
  { isoDate: "2022-09-18T00:00:00Z", dsun: 121.154922, hgln: 157.60391, hglt: -5.725493 },
  { isoDate: "2022-09-19T00:00:00Z", dsun: 118.430121, hgln: 158.747205, hglt: -5.928891 },
  { isoDate: "2022-09-20T00:00:00Z", dsun: 115.666176, hgln: 159.992555, hglt: -6.133235 },
  { isoDate: "2022-09-21T00:00:00Z", dsun: 112.865472, hgln: 161.349113, hglt: -6.337735 },
  { isoDate: "2022-09-22T00:00:00Z", dsun: 110.030941, hgln: 162.827028, hglt: -6.541382 },
  { isoDate: "2022-09-23T00:00:00Z", dsun: 107.166165, hgln: 164.437561, hglt: -6.74289 },
  { isoDate: "2022-09-24T00:00:00Z", dsun: 104.275509, hgln: 166.193188, hglt: -6.940633 },
  { isoDate: "2022-09-25T00:00:00Z", dsun: 101.364269, hgln: 168.107716, hglt: -7.132563 },
  { isoDate: "2022-09-26T00:00:00Z", dsun: 98.438852, hgln: 170.196373, hglt: -7.316117 },
  { isoDate: "2022-09-27T00:00:00Z", dsun: 95.506991, hgln: 172.475887, hglt: -7.488099 },
  { isoDate: "2022-09-28T00:00:00Z", dsun: 92.577984, hgln: 174.964508, hglt: -7.644548 },
  { isoDate: "2022-09-29T00:00:00Z", dsun: 89.662984, hgln: 177.681967, hglt: -7.780589 },
  { isoDate: "2022-09-30T00:00:00Z", dsun: 86.77531, hgln: -179.350695, hglt: -7.89027 },
  { isoDate: "2022-10-01T00:00:00Z", dsun: 83.930791, hgln: -176.111461, hglt: -7.966406 },
  { isoDate: "2022-10-02T00:00:00Z", dsun: 81.148124, hgln: -172.577923, hglt: -8.000448 },
  { isoDate: "2022-10-03T00:00:00Z", dsun: 78.449209, hgln: -168.728205, hglt: -7.982415 },
  { isoDate: "2022-10-04T00:00:00Z", dsun: 75.859417, hgln: -164.542313, hglt: -7.900962 },
  { isoDate: "2022-10-05T00:00:00Z", dsun: 73.407726, hgln: -160.004008, hglt: -7.743654 },
  { isoDate: "2022-10-06T00:00:00Z", dsun: 71.126605, hgln: -155.103218, hglt: -7.497554 },
  { isoDate: "2022-10-07T00:00:00Z", dsun: 69.051545, hgln: -149.838938, hglt: -7.150227 },
  { isoDate: "2022-10-08T00:00:00Z", dsun: 67.22008, hgln: -144.222359, hglt: -6.691189 },
  { isoDate: "2022-10-09T00:00:00Z", dsun: 65.670223, hgln: -138.279772, hglt: -6.113774 },
  { isoDate: "2022-10-10T00:00:00Z", dsun: 64.438271, hgln: -132.054548, hglt: -5.417142 },
  { isoDate: "2022-10-11T00:00:00Z", dsun: 63.556132, hgln: -125.607421, hglt: -4.608004 },
  { isoDate: "2022-10-12T00:00:00Z", dsun: 63.048497, hgln: -119.014429, hglt: -3.701469 },
  { isoDate: "2022-10-13T00:00:00Z", dsun: 62.93032, hgln: -112.362378, hglt: -2.720524 },
  { isoDate: "2022-10-14T00:00:00Z", dsun: 63.205164, hgln: -105.742347, hglt: -1.693981 },
  { isoDate: "2022-10-15T00:00:00Z", dsun: 63.86479, hgln: -99.242403, hglt: -0.653257 },
  { isoDate: "2022-10-16T00:00:00Z", dsun: 64.890097, hgln: -92.940966, hglt: 0.371236 },
  { isoDate: "2022-10-17T00:00:00Z", dsun: 66.25318, hgln: -86.90201, hglt: 1.353201 },
  { isoDate: "2022-10-18T00:00:00Z", dsun: 67.920036, hgln: -81.172676, hglt: 2.272437 },
  { isoDate: "2022-10-19T00:00:00Z", dsun: 69.853384, hgln: -75.783215, hglt: 3.115517 },
  { isoDate: "2022-10-20T00:00:00Z", dsun: 72.015173, hgln: -70.748661, hglt: 3.875364 },
  { isoDate: "2022-10-21T00:00:00Z", dsun: 74.368533, hgln: -66.071509, hglt: 4.550167 },
  { isoDate: "2022-10-22T00:00:00Z", dsun: 76.879093, hgln: -61.74471, hglt: 5.142027 },
  { isoDate: "2022-10-23T00:00:00Z", dsun: 79.515745, hgln: -57.754512, hglt: 5.655653 },
  { isoDate: "2022-10-24T00:00:00Z", dsun: 82.250955, hgln: -54.082877, hglt: 6.097266 },
  { isoDate: "2022-10-25T00:00:00Z", dsun: 85.060764, hgln: -50.709405, hglt: 6.473769 },
  { isoDate: "2022-10-26T00:00:00Z", dsun: 87.924589, hgln: -47.612742, hglt: 6.792172 },
  { isoDate: "2022-10-27T00:00:00Z", dsun: 90.824921, hgln: -44.771584, hglt: 7.05922 },
  { isoDate: "2022-10-28T00:00:00Z", dsun: 93.746976, hgln: -42.165327, hglt: 7.281181 },
  { isoDate: "2022-10-29T00:00:00Z", dsun: 96.678335, hgln: -39.774471, hglt: 7.463748 },
  { isoDate: "2022-10-30T00:00:00Z", dsun: 99.608631, hgln: -37.580838, hglt: 7.61199 },
  { isoDate: "2022-10-31T00:00:00Z", dsun: 102.529219, hgln: -35.567658, hglt: 7.730394 },
  { isoDate: "2022-11-01T00:00:00Z", dsun: 105.432922, hgln: -33.719582, hglt: 7.82288 },
  { isoDate: "2022-11-02T00:00:00Z", dsun: 108.313801, hgln: -32.022632, hglt: 7.892864 },
  { isoDate: "2022-11-03T00:00:00Z", dsun: 111.166951, hgln: -30.464126, hglt: 7.943307 },
  { isoDate: "2022-11-04T00:00:00Z", dsun: 113.988336, hgln: -29.032581, hglt: 7.976771 },
  { isoDate: "2022-11-05T00:00:00Z", dsun: 116.774647, hgln: -27.717615, hglt: 7.995469 },
  { isoDate: "2022-11-06T00:00:00Z", dsun: 119.523183, hgln: -26.509846, hglt: 8.001315 },
  { isoDate: "2022-11-07T00:00:00Z", dsun: 122.23175, hgln: -25.400794, hglt: 7.995961 },
  { isoDate: "2022-11-08T00:00:00Z", dsun: 124.89858, hgln: -24.382791, hglt: 7.980835 },
  { isoDate: "2022-11-09T00:00:00Z", dsun: 127.52226, hgln: -23.448896, hglt: 7.957172 },
  { isoDate: "2022-11-10T00:00:00Z", dsun: 130.101674, hgln: -22.592821, hglt: 7.926043 },
  { isoDate: "2022-11-11T00:00:00Z", dsun: 132.635954, hgln: -21.808858, hglt: 7.888374 },
  { isoDate: "2022-11-12T00:00:00Z", dsun: 135.124442, hgln: -21.091823, hglt: 7.844973 },
  { isoDate: "2022-11-13T00:00:00Z", dsun: 137.566652, hgln: -20.436994, hglt: 7.796538 },
  { isoDate: "2022-11-14T00:00:00Z", dsun: 139.96224, hgln: -19.840068, hglt: 7.743681 },
  { isoDate: "2022-11-15T00:00:00Z", dsun: 142.310995, hgln: -19.297119, hglt: 7.686934 },
  { isoDate: "2022-11-16T00:00:00Z", dsun: 144.612796, hgln: -18.804549, hglt: 7.626759 },
  { isoDate: "2022-11-17T00:00:00Z", dsun: 146.867608, hgln: -18.359066, hglt: 7.563564 },
  { isoDate: "2022-11-18T00:00:00Z", dsun: 149.075469, hgln: -17.957647, hglt: 7.497704 },
  { isoDate: "2022-11-19T00:00:00Z", dsun: 151.236473, hgln: -17.59751, hglt: 7.42949 },
  { isoDate: "2022-11-20T00:00:00Z", dsun: 153.35076, hgln: -17.276092, hglt: 7.359197 },
  { isoDate: "2022-11-21T00:00:00Z", dsun: 155.418512, hgln: -16.991029, hglt: 7.287064 },
  { isoDate: "2022-11-22T00:00:00Z", dsun: 157.439941, hgln: -16.740129, hglt: 7.213303 },
  { isoDate: "2022-11-23T00:00:00Z", dsun: 159.415283, hgln: -16.521364, hglt: 7.138099 },
  { isoDate: "2022-11-24T00:00:00Z", dsun: 161.344796, hgln: -16.332848, hglt: 7.061616 },
  { isoDate: "2022-11-25T00:00:00Z", dsun: 163.228752, hgln: -16.172827, hglt: 6.983999 },
  { isoDate: "2022-11-26T00:00:00Z", dsun: 165.067438, hgln: -16.03967, hglt: 6.905373 },
  { isoDate: "2022-11-27T00:00:00Z", dsun: 166.861145, hgln: -15.931857, hglt: 6.825851 },
  { isoDate: "2022-11-28T00:00:00Z", dsun: 168.610173, hgln: -15.847973, hglt: 6.745532 },
  { isoDate: "2022-11-29T00:00:00Z", dsun: 170.314824, hgln: -15.7867, hglt: 6.664502 },
  { isoDate: "2022-11-30T00:00:00Z", dsun: 171.975402, hgln: -15.746811, hglt: 6.582837 },
  { isoDate: "2022-12-01T00:00:00Z", dsun: 173.592211, hgln: -15.727161, hglt: 6.500605 },
  { isoDate: "2022-12-02T00:00:00Z", dsun: 175.165552, hgln: -15.726681, hglt: 6.417865 },
  { isoDate: "2022-12-03T00:00:00Z", dsun: 176.695727, hgln: -15.744373, hglt: 6.334667 },
  { isoDate: "2022-12-04T00:00:00Z", dsun: 178.183029, hgln: -15.7793, hglt: 6.251058 },
  { isoDate: "2022-12-05T00:00:00Z", dsun: 179.62775, hgln: -15.830586, hglt: 6.167076 },
  { isoDate: "2022-12-06T00:00:00Z", dsun: 181.030176, hgln: -15.897404, hglt: 6.082756 },
  { isoDate: "2022-12-07T00:00:00Z", dsun: 182.390587, hgln: -15.978978, hglt: 5.998127 },
  { isoDate: "2022-12-08T00:00:00Z", dsun: 183.709255, hgln: -16.074575, hglt: 5.913214 },
  { isoDate: "2022-12-09T00:00:00Z", dsun: 184.986449, hgln: -16.183501, hglt: 5.828039 },
  { isoDate: "2022-12-10T00:00:00Z", dsun: 186.222459, hgln: -16.305112, hglt: 5.742663 },
  { isoDate: "2022-12-11T00:00:00Z", dsun: 187.417601, hgln: -16.438812, hglt: 5.65719 },
  { isoDate: "2022-12-12T00:00:00Z", dsun: 188.572017, hgln: -16.583965, hglt: 5.5715 },
  { isoDate: "2022-12-13T00:00:00Z", dsun: 189.685944, hgln: -16.740006, hglt: 5.485601 },
  { isoDate: "2022-12-14T00:00:00Z", dsun: 190.75961, hgln: -16.906394, hglt: 5.399503 },
  { isoDate: "2022-12-15T00:00:00Z", dsun: 191.793236, hgln: -17.082612, hglt: 5.313208 },
  { isoDate: "2022-12-16T00:00:00Z", dsun: 192.787036, hgln: -17.268163, hglt: 5.226723 },
  { isoDate: "2022-12-17T00:00:00Z", dsun: 193.741213, hgln: -17.462572, hglt: 5.14005 },
  { isoDate: "2022-12-18T00:00:00Z", dsun: 194.655965, hgln: -17.665379, hglt: 5.053189 },
  { isoDate: "2022-12-19T00:00:00Z", dsun: 195.53148, hgln: -17.876141, hglt: 4.966139 },
  { isoDate: "2022-12-20T00:00:00Z", dsun: 196.367938, hgln: -18.094429, hglt: 4.878899 },
  { isoDate: "2022-12-21T00:00:00Z", dsun: 197.16551, hgln: -18.319824, hglt: 4.791465 },
  { isoDate: "2022-12-22T00:00:00Z", dsun: 197.924361, hgln: -18.551917, hglt: 4.703832 },
  { isoDate: "2022-12-23T00:00:00Z", dsun: 198.644647, hgln: -18.790309, hglt: 4.615995 },
  { isoDate: "2022-12-24T00:00:00Z", dsun: 199.326514, hgln: -19.03461, hglt: 4.527948 },
  { isoDate: "2022-12-25T00:00:00Z", dsun: 199.970103, hgln: -19.284439, hglt: 4.439683 },
  { isoDate: "2022-12-26T00:00:00Z", dsun: 200.575545, hgln: -19.539425, hglt: 4.351193 },
  { isoDate: "2022-12-27T00:00:00Z", dsun: 201.142963, hgln: -19.799213, hglt: 4.262467 },
  { isoDate: "2022-12-28T00:00:00Z", dsun: 201.672474, hgln: -20.06346, hglt: 4.173497 },
  { isoDate: "2022-12-29T00:00:00Z", dsun: 202.164185, hgln: -20.331836, hglt: 4.084272 },
  { isoDate: "2022-12-30T00:00:00Z", dsun: 202.618197, hgln: -20.604025, hglt: 3.994781 },
  { isoDate: "2022-12-31T00:00:00Z", dsun: 203.034601, hgln: -20.879721, hglt: 3.905012 },
  { isoDate: "2023-01-01T00:00:00Z", dsun: 203.413483, hgln: -21.158629, hglt: 3.814953 },
  { isoDate: "2023-01-02T00:00:00Z", dsun: 203.754918, hgln: -21.440463, hglt: 3.72459 },
  { isoDate: "2023-01-03T00:00:00Z", dsun: 204.058977, hgln: -21.724943, hglt: 3.63391 },
  { isoDate: "2023-01-04T00:00:00Z", dsun: 204.32572, hgln: -22.011795, hglt: 3.542898 },
  { isoDate: "2023-01-05T00:00:00Z", dsun: 204.555202, hgln: -22.30075, hglt: 3.45154 },
  { isoDate: "2023-01-06T00:00:00Z", dsun: 204.747469, hgln: -22.591544, hglt: 3.35982 },
  { isoDate: "2023-01-07T00:00:00Z", dsun: 204.90256, hgln: -22.883915, hglt: 3.267723 },
  { isoDate: "2023-01-08T00:00:00Z", dsun: 205.020506, hgln: -23.177602, hglt: 3.175231 },
  { isoDate: "2023-01-09T00:00:00Z", dsun: 205.101331, hgln: -23.472349, hglt: 3.082327 },
  { isoDate: "2023-01-10T00:00:00Z", dsun: 205.145052, hgln: -23.767898, hglt: 2.988994 },
  { isoDate: "2023-01-11T00:00:00Z", dsun: 205.151677, hgln: -24.06399, hglt: 2.895213 },
  { isoDate: "2023-01-12T00:00:00Z", dsun: 205.121207, hgln: -24.360366, hglt: 2.800966 },
  { isoDate: "2023-01-13T00:00:00Z", dsun: 205.053637, hgln: -24.656768, hglt: 2.706232 },
  { isoDate: "2023-01-14T00:00:00Z", dsun: 204.948953, hgln: -24.95293, hglt: 2.610991 },
  { isoDate: "2023-01-15T00:00:00Z", dsun: 204.807134, hgln: -25.248589, hglt: 2.515224 },
  { isoDate: "2023-01-16T00:00:00Z", dsun: 204.62815, hgln: -25.543473, hglt: 2.418907 },
  { isoDate: "2023-01-17T00:00:00Z", dsun: 204.411969, hgln: -25.837307, hglt: 2.322022 },
  { isoDate: "2023-01-18T00:00:00Z", dsun: 204.158546, hgln: -26.129809, hglt: 2.224543 },
  { isoDate: "2023-01-19T00:00:00Z", dsun: 203.867827, hgln: -26.420688, hglt: 2.126447 },
  { isoDate: "2023-01-20T00:00:00Z", dsun: 203.539755, hgln: -26.709646, hglt: 2.027709 },
  { isoDate: "2023-01-21T00:00:00Z", dsun: 203.174263, hgln: -26.996371, hglt: 1.928305 },
  { isoDate: "2023-01-22T00:00:00Z", dsun: 202.771277, hgln: -27.280548, hglt: 1.828209 },
  { isoDate: "2023-01-23T00:00:00Z", dsun: 202.330714, hgln: -27.561848, hglt: 1.727394 },
  { isoDate: "2023-01-24T00:00:00Z", dsun: 201.852487, hgln: -27.839941, hglt: 1.625832 },
  { isoDate: "2023-01-25T00:00:00Z", dsun: 201.336496, hgln: -28.114491, hglt: 1.523496 },
  { isoDate: "2023-01-26T00:00:00Z", dsun: 200.782638, hgln: -28.385157, hglt: 1.420356 },
  { isoDate: "2023-01-27T00:00:00Z", dsun: 200.1908, hgln: -28.6516, hglt: 1.316382 },
  { isoDate: "2023-01-28T00:00:00Z", dsun: 199.56086, hgln: -28.913473, hglt: 1.211543 },
  { isoDate: "2023-01-29T00:00:00Z", dsun: 198.89269, hgln: -29.170425, hglt: 1.105806 },
  { isoDate: "2023-01-30T00:00:00Z", dsun: 198.186154, hgln: -29.422099, hglt: 0.999139 },
  { isoDate: "2023-01-31T00:00:00Z", dsun: 197.441106, hgln: -29.668128, hglt: 0.891507 },
  { isoDate: "2023-02-01T00:00:00Z", dsun: 196.657394, hgln: -29.908136, hglt: 0.782875 },
  { isoDate: "2023-02-02T00:00:00Z", dsun: 195.834857, hgln: -30.141735, hglt: 0.673206 },
  { isoDate: "2023-02-03T00:00:00Z", dsun: 194.973325, hgln: -30.368523, hglt: 0.562461 },
  { isoDate: "2023-02-04T00:00:00Z", dsun: 194.072623, hgln: -30.588085, hglt: 0.450603 },
  { isoDate: "2023-02-05T00:00:00Z", dsun: 193.132564, hgln: -30.799989, hglt: 0.337589 },
  { isoDate: "2023-02-06T00:00:00Z", dsun: 192.152954, hgln: -31.003788, hglt: 0.223379 },
  { isoDate: "2023-02-07T00:00:00Z", dsun: 191.133593, hgln: -31.199013, hglt: 0.107929 },
  { isoDate: "2023-02-08T00:00:00Z", dsun: 190.07427, hgln: -31.385175, hglt: -0.008806 },
  { isoDate: "2023-02-09T00:00:00Z", dsun: 188.974767, hgln: -31.561765, hglt: -0.126874 },
  { isoDate: "2023-02-10T00:00:00Z", dsun: 187.834858, hgln: -31.728245, hglt: -0.24632 },
  { isoDate: "2023-02-11T00:00:00Z", dsun: 186.65431, hgln: -31.884051, hglt: -0.367197 },
  { isoDate: "2023-02-12T00:00:00Z", dsun: 185.43288, hgln: -32.028591, hglt: -0.489554 },
  { isoDate: "2023-02-13T00:00:00Z", dsun: 184.170319, hgln: -32.16124, hglt: -0.613446 },
  { isoDate: "2023-02-14T00:00:00Z", dsun: 182.86637, hgln: -32.281334, hglt: -0.738927 },
  { isoDate: "2023-02-15T00:00:00Z", dsun: 181.520769, hgln: -32.388175, hglt: -0.866055 },
  { isoDate: "2023-02-16T00:00:00Z", dsun: 180.133245, hgln: -32.481018, hglt: -0.994888 },
  { isoDate: "2023-02-17T00:00:00Z", dsun: 178.703519, hgln: -32.559074, hglt: -1.125488 },
  { isoDate: "2023-02-18T00:00:00Z", dsun: 177.231309, hgln: -32.621502, hglt: -1.257916 },
  { isoDate: "2023-02-19T00:00:00Z", dsun: 175.716324, hgln: -32.667405, hglt: -1.392237 },
  { isoDate: "2023-02-20T00:00:00Z", dsun: 174.158271, hgln: -32.695831, hglt: -1.528519 },
  { isoDate: "2023-02-21T00:00:00Z", dsun: 172.556851, hgln: -32.705763, hglt: -1.66683 },
  { isoDate: "2023-02-22T00:00:00Z", dsun: 170.911762, hgln: -32.696125, hglt: -1.807239 },
  { isoDate: "2023-02-23T00:00:00Z", dsun: 169.222702, hgln: -32.66577, hglt: -1.949819 },
  { isoDate: "2023-02-24T00:00:00Z", dsun: 167.489366, hgln: -32.613482, hglt: -2.094644 },
  { isoDate: "2023-02-25T00:00:00Z", dsun: 165.711451, hgln: -32.537965, hglt: -2.241788 },
  { isoDate: "2023-02-26T00:00:00Z", dsun: 163.888656, hgln: -32.43784, hglt: -2.391328 },
  { isoDate: "2023-02-27T00:00:00Z", dsun: 162.020686, hgln: -32.31163, hglt: -2.54334 },
  { isoDate: "2023-02-28T00:00:00Z", dsun: 160.107252, hgln: -32.157754, hglt: -2.697901 },
  { isoDate: "2023-03-01T00:00:00Z", dsun: 158.148077, hgln: -31.974514, hglt: -2.855087 },
  { isoDate: "2023-03-02T00:00:00Z", dsun: 156.142897, hgln: -31.760082, hglt: -3.014975 },
  { isoDate: "2023-03-03T00:00:00Z", dsun: 154.091468, hgln: -31.512487, hglt: -3.177638 },
  { isoDate: "2023-03-04T00:00:00Z", dsun: 151.993566, hgln: -31.229599, hglt: -3.343146 },
  { isoDate: "2023-03-05T00:00:00Z", dsun: 149.849001, hgln: -30.909113, hglt: -3.511565 },
  { isoDate: "2023-03-06T00:00:00Z", dsun: 147.657614, hgln: -30.548529, hglt: -3.682955 },
  { isoDate: "2023-03-07T00:00:00Z", dsun: 145.419295, hgln: -30.14513, hglt: -3.857366 },
  { isoDate: "2023-03-08T00:00:00Z", dsun: 143.133984, hgln: -29.695957, hglt: -4.034838 },
  { isoDate: "2023-03-09T00:00:00Z", dsun: 140.801689, hgln: -29.197786, hglt: -4.215395 },
  { isoDate: "2023-03-10T00:00:00Z", dsun: 138.422495, hgln: -28.647091, hglt: -4.399042 },
  { isoDate: "2023-03-11T00:00:00Z", dsun: 135.996583, hgln: -28.040011, hglt: -4.585759 },
  { isoDate: "2023-03-12T00:00:00Z", dsun: 133.524247, hgln: -27.372313, hglt: -4.775497 },
  { isoDate: "2023-03-13T00:00:00Z", dsun: 131.005917, hgln: -26.639341, hglt: -4.968164 },
  { isoDate: "2023-03-14T00:00:00Z", dsun: 128.442186, hgln: -25.835972, hglt: -5.163623 },
  { isoDate: "2023-03-15T00:00:00Z", dsun: 125.833845, hgln: -24.956553, hglt: -5.361671 },
  { isoDate: "2023-03-16T00:00:00Z", dsun: 123.181915, hgln: -23.994843, hglt: -5.56203 },
  { isoDate: "2023-03-17T00:00:00Z", dsun: 120.487696, hgln: -22.943935, hglt: -5.764324 },
  { isoDate: "2023-03-18T00:00:00Z", dsun: 117.752825, hgln: -21.79618, hglt: -5.968055 },
  { isoDate: "2023-03-19T00:00:00Z", dsun: 114.979334, hgln: -20.543099, hglt: -6.172576 },
  { isoDate: "2023-03-20T00:00:00Z", dsun: 112.169733, hgln: -19.175286, hglt: -6.37705 },
  { isoDate: "2023-03-21T00:00:00Z", dsun: 109.327104, hgln: -17.682308, hglt: -6.580409 },
  { isoDate: "2023-03-22T00:00:00Z", dsun: 106.45521, hgln: -16.052597, hglt: -6.781292 },
  { isoDate: "2023-03-23T00:00:00Z", dsun: 103.55863, hgln: -14.273337, hglt: -6.977983 },
  { isoDate: "2023-03-24T00:00:00Z", dsun: 100.642915, hgln: -12.330367, hglt: -7.168322 },
  { isoDate: "2023-03-25T00:00:00Z", dsun: 97.714778, hgln: -10.208083, hglt: -7.349605 },
  { isoDate: "2023-03-26T00:00:00Z", dsun: 94.782314, hgln: -7.889376, hglt: -7.518466 },
  { isoDate: "2023-03-27T00:00:00Z", dsun: 91.855253, hgln: -5.355621, hglt: -7.670742 },
  { isoDate: "2023-03-28T00:00:00Z", dsun: 88.94525, hgln: -2.586743, hglt: -7.801313 },
  { isoDate: "2023-03-29T00:00:00Z", dsun: 86.066212, hgln: 0.43858, hglt: -7.903945 },
  { isoDate: "2023-03-30T00:00:00Z", dsun: 83.234648, hgln: 3.742529, hglt: -7.971134 },
  { isoDate: "2023-03-31T00:00:00Z", dsun: 80.470016, hgln: 7.347487, hglt: -7.993987 },
  { isoDate: "2023-04-01T00:00:00Z", dsun: 77.795057, hgln: 11.275019, hglt: -7.962179 },
  { isoDate: "2023-04-02T00:00:00Z", dsun: 75.236036, hgln: 15.544401, hglt: -7.864065 },
  { isoDate: "2023-04-03T00:00:00Z", dsun: 72.822823, hgln: 20.170615, hglt: -7.687015 },
  { isoDate: "2023-04-04T00:00:00Z", dsun: 70.588706, hgln: 25.161797, hglt: -7.418104 },
  { isoDate: "2023-04-05T00:00:00Z", dsun: 68.569807, hgln: 30.516252, hglt: -7.04521 },
  { isoDate: "2023-04-06T00:00:00Z", dsun: 66.803968, hgln: 36.219318, hglt: -6.558592 },
  { isoDate: "2023-04-07T00:00:00Z", dsun: 65.329021, hgln: 42.240618, hglt: -5.952813 },
  { isoDate: "2023-04-08T00:00:00Z", dsun: 64.180461, hgln: 48.532417, hglt: -5.228729 },
  { isoDate: "2023-04-09T00:00:00Z", dsun: 63.388692, hgln: 55.029876, hglt: -4.39506 },
  { isoDate: "2023-04-10T00:00:00Z", dsun: 62.97622, hgln: 61.653725, hglt: -3.468937 },
  { isoDate: "2023-04-11T00:00:00Z", dsun: 62.955303, hgln: 68.315377, hglt: -2.475003 },
  { isoDate: "2023-04-12T00:00:00Z", dsun: 63.326574, hgln: 74.923748, hglt: -1.443022 },
  { isoDate: "2023-04-13T00:00:00Z", dsun: 64.078964, hgln: 81.392526, hglt: -0.404481 },
  { isoDate: "2023-04-14T00:00:00Z", dsun: 65.190957, hgln: 87.646451, hglt: 0.610979 },
  { isoDate: "2023-04-15T00:00:00Z", dsun: 66.632857, hgln: 93.625554, hglt: 1.578432 },
  { isoDate: "2023-04-16T00:00:00Z", dsun: 68.369575, hgln: 99.286931, hglt: 2.479332 },
  { isoDate: "2023-04-17T00:00:00Z", dsun: 70.363408, hgln: 104.60431, hglt: 3.301884 },
  { isoDate: "2023-04-18T00:00:00Z", dsun: 72.576421, hgln: 109.566047, hglt: 4.040421 },
  { isoDate: "2023-04-19T00:00:00Z", dsun: 74.972246, hgln: 114.172314, hglt: 4.694216 },
  { isoDate: "2023-04-20T00:00:00Z", dsun: 77.517259, hgln: 118.432098, hglt: 5.266117 },
  { isoDate: "2023-04-21T00:00:00Z", dsun: 80.181212, hgln: 122.360444, hglt: 5.761288 },
  { isoDate: "2023-04-22T00:00:00Z", dsun: 82.937457, hgln: 125.976153, hglt: 6.186175 },
  { isoDate: "2023-04-23T00:00:00Z", dsun: 85.762889, hgln: 129.29999, hglt: 6.547744 },
  { isoDate: "2023-04-24T00:00:00Z", dsun: 88.637714, hgln: 132.353376, hglt: 6.852956 },
  { isoDate: "2023-04-25T00:00:00Z", dsun: 91.545129, hgln: 135.157491, hglt: 7.108447 },
  { isoDate: "2023-04-26T00:00:00Z", dsun: 94.470967, hgln: 137.732686, hglt: 7.320341 },
  { isoDate: "2023-04-27T00:00:00Z", dsun: 97.403349, hgln: 140.098135, hglt: 7.494171 },
  { isoDate: "2023-04-28T00:00:00Z", dsun: 100.332355, hgln: 142.271659, hglt: 7.634862 },
  { isoDate: "2023-04-29T00:00:00Z", dsun: 103.249731, hgln: 144.269661, hglt: 7.746746 },
  { isoDate: "2023-04-30T00:00:00Z", dsun: 106.148627, hgln: 146.107131, hglt: 7.833614 },
  { isoDate: "2023-05-01T00:00:00Z", dsun: 109.023377, hgln: 147.797707, hglt: 7.898759 },
  { isoDate: "2023-05-02T00:00:00Z", dsun: 111.869309, hgln: 149.353756, hglt: 7.945037 },
  { isoDate: "2023-05-03T00:00:00Z", dsun: 114.682578, hgln: 150.786471, hglt: 7.974917 },
  { isoDate: "2023-05-04T00:00:00Z", dsun: 117.460038, hgln: 152.105968, hglt: 7.990533 },
  { isoDate: "2023-05-05T00:00:00Z", dsun: 120.199122, hgln: 153.321389, hglt: 7.993729 },
  { isoDate: "2023-05-06T00:00:00Z", dsun: 122.89775, hgln: 154.440996, hglt: 7.986097 },
  { isoDate: "2023-05-07T00:00:00Z", dsun: 125.554248, hgln: 155.472259, hglt: 7.969014 },
  { isoDate: "2023-05-08T00:00:00Z", dsun: 128.167282, hgln: 156.421937, hglt: 7.943672 },
  { isoDate: "2023-05-09T00:00:00Z", dsun: 130.735804, hgln: 157.296156, hglt: 7.911103 },
  { isoDate: "2023-05-10T00:00:00Z", dsun: 133.259001, hgln: 158.100475, hglt: 7.872203 },
  { isoDate: "2023-05-11T00:00:00Z", dsun: 135.736262, hgln: 158.839949, hglt: 7.827749 },
  { isoDate: "2023-05-12T00:00:00Z", dsun: 138.167138, hgln: 159.519179, hglt: 7.778418 },
  { isoDate: "2023-05-13T00:00:00Z", dsun: 140.551324, hgln: 160.142363, hglt: 7.724799 },
  { isoDate: "2023-05-14T00:00:00Z", dsun: 142.888627, hgln: 160.71334, hglt: 7.667406 },
  { isoDate: "2023-05-15T00:00:00Z", dsun: 145.178953, hgln: 161.235625, hglt: 7.60669 },
  { isoDate: "2023-05-16T00:00:00Z", dsun: 147.422286, hgln: 161.712441, hglt: 7.543041 },
  { isoDate: "2023-05-17T00:00:00Z", dsun: 149.61868, hgln: 162.146751, hglt: 7.476805 },
  { isoDate: "2023-05-18T00:00:00Z", dsun: 151.768242, hgln: 162.54128, hglt: 7.408283 },
  { isoDate: "2023-05-19T00:00:00Z", dsun: 153.871122, hgln: 162.898541, hglt: 7.337741 },
  { isoDate: "2023-05-20T00:00:00Z", dsun: 155.927512, hgln: 163.22085, hglt: 7.26541 },
  { isoDate: "2023-05-21T00:00:00Z", dsun: 157.937628, hgln: 163.510349, hglt: 7.191496 },
  { isoDate: "2023-05-22T00:00:00Z", dsun: 159.901716, hgln: 163.769018, hglt: 7.116178 },
  { isoDate: "2023-05-23T00:00:00Z", dsun: 161.820035, hgln: 163.998688, hglt: 7.039616 },
  { isoDate: "2023-05-24T00:00:00Z", dsun: 163.692863, hgln: 164.201059, hglt: 6.961948 },
  { isoDate: "2023-05-25T00:00:00Z", dsun: 165.520487, hgln: 164.377708, hglt: 6.883299 },
  { isoDate: "2023-05-26T00:00:00Z", dsun: 167.303202, hgln: 164.530102, hglt: 6.803776 },
  { isoDate: "2023-05-27T00:00:00Z", dsun: 169.041308, hgln: 164.659603, hglt: 6.723475 },
  { isoDate: "2023-05-28T00:00:00Z", dsun: 170.735109, hgln: 164.767484, hglt: 6.64248 },
  { isoDate: "2023-05-29T00:00:00Z", dsun: 172.384907, hgln: 164.854931, hglt: 6.560866 },
  { isoDate: "2023-05-30T00:00:00Z", dsun: 173.991008, hgln: 164.92305, hglt: 6.478697 },
  { isoDate: "2023-05-31T00:00:00Z", dsun: 175.553712, hgln: 164.972877, hglt: 6.396031 },
  { isoDate: "2023-06-01T00:00:00Z", dsun: 177.073318, hgln: 165.00538, hglt: 6.312916 },
  { isoDate: "2023-06-02T00:00:00Z", dsun: 178.550122, hgln: 165.021465, hglt: 6.229398 },
  { isoDate: "2023-06-03T00:00:00Z", dsun: 179.984412, hgln: 165.021979, hglt: 6.145514 },
  { isoDate: "2023-06-04T00:00:00Z", dsun: 181.376474, hgln: 165.007719, hglt: 6.061297 },
  { isoDate: "2023-06-05T00:00:00Z", dsun: 182.726586, hgln: 164.979429, hglt: 5.976776 },
  { isoDate: "2023-06-06T00:00:00Z", dsun: 184.035019, hgln: 164.937813, hglt: 5.891975 },
  { isoDate: "2023-06-07T00:00:00Z", dsun: 185.302039, hgln: 164.883533, hglt: 5.806914 },
  { isoDate: "2023-06-08T00:00:00Z", dsun: 186.527904, hgln: 164.817219, hglt: 5.721612 },
  { isoDate: "2023-06-09T00:00:00Z", dsun: 187.712863, hgln: 164.739469, hglt: 5.636082 },
  { isoDate: "2023-06-10T00:00:00Z", dsun: 188.857159, hgln: 164.650857, hglt: 5.550338 },
  { isoDate: "2023-06-11T00:00:00Z", dsun: 189.961028, hgln: 164.551931, hglt: 5.464387 },
  { isoDate: "2023-06-12T00:00:00Z", dsun: 191.024696, hgln: 164.44322, hglt: 5.378238 },
  { isoDate: "2023-06-13T00:00:00Z", dsun: 192.048381, hgln: 164.325232, hglt: 5.291895 },
  { isoDate: "2023-06-14T00:00:00Z", dsun: 193.032295, hgln: 164.198454, hglt: 5.205363 },
  { isoDate: "2023-06-15T00:00:00Z", dsun: 193.97664, hgln: 164.063356, hglt: 5.118642 },
  { isoDate: "2023-06-16T00:00:00Z", dsun: 194.88161, hgln: 163.920393, hglt: 5.031734 },
  { isoDate: "2023-06-17T00:00:00Z", dsun: 195.747392, hgln: 163.769999, hglt: 4.944636 },
  { isoDate: "2023-06-18T00:00:00Z", dsun: 196.574164, hgln: 163.612595, hglt: 4.857348 },
  { isoDate: "2023-06-19T00:00:00Z", dsun: 197.362095, hgln: 163.448584, hglt: 4.769864 },
  { isoDate: "2023-06-20T00:00:00Z", dsun: 198.111348, hgln: 163.278355, hglt: 4.682181 },
  { isoDate: "2023-06-21T00:00:00Z", dsun: 198.822076, hgln: 163.102282, hglt: 4.594293 },
  { isoDate: "2023-06-22T00:00:00Z", dsun: 199.494425, hgln: 162.920726, hglt: 4.506193 },
  { isoDate: "2023-06-23T00:00:00Z", dsun: 200.128531, hgln: 162.734034, hglt: 4.417873 },
  { isoDate: "2023-06-24T00:00:00Z", dsun: 200.724525, hgln: 162.542541, hglt: 4.329325 },
  { isoDate: "2023-06-25T00:00:00Z", dsun: 201.282529, hgln: 162.346572, hglt: 4.240541 },
  { isoDate: "2023-06-26T00:00:00Z", dsun: 201.802655, hgln: 162.14644, hglt: 4.151509 },
  { isoDate: "2023-06-27T00:00:00Z", dsun: 202.28501, hgln: 161.942447, hglt: 4.06222 },
  { isoDate: "2023-06-28T00:00:00Z", dsun: 202.729692, hgln: 161.734889, hglt: 3.972662 },
  { isoDate: "2023-06-29T00:00:00Z", dsun: 203.136792, hgln: 161.524048, hglt: 3.882824 },
  { isoDate: "2023-06-30T00:00:00Z", dsun: 203.506391, hgln: 161.310199, hglt: 3.792691 },
  { isoDate: "2023-07-01T00:00:00Z", dsun: 203.838565, hgln: 161.09361, hglt: 3.702252 },
  { isoDate: "2023-07-02T00:00:00Z", dsun: 204.13338, hgln: 160.874535, hglt: 3.611493 },
  { isoDate: "2023-07-03T00:00:00Z", dsun: 204.390898, hgln: 160.653223, hglt: 3.520398 },
  { isoDate: "2023-07-04T00:00:00Z", dsun: 204.611169, hgln: 160.429916, hglt: 3.428954 },
  { isoDate: "2023-07-05T00:00:00Z", dsun: 204.794238, hgln: 160.204849, hglt: 3.337144 },
  { isoDate: "2023-07-06T00:00:00Z", dsun: 204.940141, hgln: 159.978256, hglt: 3.244952 },
  { isoDate: "2023-07-07T00:00:00Z", dsun: 205.04891, hgln: 159.750371, hglt: 3.152361 },
  { isoDate: "2023-07-08T00:00:00Z", dsun: 205.120565, hgln: 159.521432, hglt: 3.059355 },
  { isoDate: "2023-07-09T00:00:00Z", dsun: 205.15512, hgln: 159.291679, hglt: 2.965915 },
  { isoDate: "2023-07-10T00:00:00Z", dsun: 205.152583, hgln: 159.061357, hglt: 2.872023 },
  { isoDate: "2023-07-11T00:00:00Z", dsun: 205.112954, hgln: 158.830718, hglt: 2.777659 },
  { isoDate: "2023-07-12T00:00:00Z", dsun: 205.036223, hgln: 158.600015, hglt: 2.682804 },
  { isoDate: "2023-07-13T00:00:00Z", dsun: 204.922376, hgln: 158.369509, hglt: 2.587438 },
  { isoDate: "2023-07-14T00:00:00Z", dsun: 204.77139, hgln: 158.139463, hglt: 2.491539 },
  { isoDate: "2023-07-15T00:00:00Z", dsun: 204.583234, hgln: 157.910143, hglt: 2.395086 },
  { isoDate: "2023-07-16T00:00:00Z", dsun: 204.35787, hgln: 157.68182, hglt: 2.298057 },
  { isoDate: "2023-07-17T00:00:00Z", dsun: 204.095253, hgln: 157.454769, hglt: 2.200429 },
  { isoDate: "2023-07-18T00:00:00Z", dsun: 203.795329, hgln: 157.229267, hglt: 2.102177 },
  { isoDate: "2023-07-19T00:00:00Z", dsun: 203.458038, hgln: 157.005596, hglt: 2.003279 },
  { isoDate: "2023-07-20T00:00:00Z", dsun: 203.083312, hgln: 156.784042, hglt: 1.903707 },
  { isoDate: "2023-07-21T00:00:00Z", dsun: 202.671073, hgln: 156.564895, hglt: 1.803437 },
  { isoDate: "2023-07-22T00:00:00Z", dsun: 202.22124, hgln: 156.348451, hglt: 1.702441 },
  { isoDate: "2023-07-23T00:00:00Z", dsun: 201.733719, hgln: 156.13501, hglt: 1.600692 },
  { isoDate: "2023-07-24T00:00:00Z", dsun: 201.208413, hgln: 155.924877, hglt: 1.498162 },
  { isoDate: "2023-07-25T00:00:00Z", dsun: 200.645214, hgln: 155.718364, hglt: 1.39482 },
  { isoDate: "2023-07-26T00:00:00Z", dsun: 200.044007, hgln: 155.515791, hglt: 1.290637 },
  { isoDate: "2023-07-27T00:00:00Z", dsun: 199.404669, hgln: 155.317482, hglt: 1.185581 },
  { isoDate: "2023-07-28T00:00:00Z", dsun: 198.72707, hgln: 155.12377, hglt: 1.07962 },
  { isoDate: "2023-07-29T00:00:00Z", dsun: 198.011072, hgln: 154.934996, hglt: 0.97272 },
  { isoDate: "2023-07-30T00:00:00Z", dsun: 197.256526, hgln: 154.751505, hglt: 0.864846 },
  { isoDate: "2023-07-31T00:00:00Z", dsun: 196.463279, hgln: 154.573653, hglt: 0.755964 },
  { isoDate: "2023-08-01T00:00:00Z", dsun: 195.631167, hgln: 154.401804, hglt: 0.646035 },
  { isoDate: "2023-08-02T00:00:00Z", dsun: 194.76002, hgln: 154.236332, hglt: 0.535022 },
  { isoDate: "2023-08-03T00:00:00Z", dsun: 193.849659, hgln: 154.077625, hglt: 0.422884 },
  { isoDate: "2023-08-04T00:00:00Z", dsun: 192.899895, hgln: 153.926089, hglt: 0.309582 },
  { isoDate: "2023-08-05T00:00:00Z", dsun: 191.910533, hgln: 153.78215, hglt: 0.195073 },
  { isoDate: "2023-08-06T00:00:00Z", dsun: 190.881369, hgln: 153.64626, hglt: 0.079313 },
  { isoDate: "2023-08-07T00:00:00Z", dsun: 189.812192, hgln: 153.518895, hglt: -0.037743 },
  { isoDate: "2023-08-08T00:00:00Z", dsun: 188.702782, hgln: 153.400564, hglt: -0.156143 },
  { isoDate: "2023-08-09T00:00:00Z", dsun: 187.55291, hgln: 153.2918, hglt: -0.275934 },
  { isoDate: "2023-08-10T00:00:00Z", dsun: 186.36234, hgln: 153.193171, hglt: -0.397168 },
  { isoDate: "2023-08-11T00:00:00Z", dsun: 185.130829, hgln: 153.105275, hglt: -0.519895 },
  { isoDate: "2023-08-12T00:00:00Z", dsun: 183.858126, hgln: 153.028745, hglt: -0.644169 },
  { isoDate: "2023-08-13T00:00:00Z", dsun: 182.543971, hgln: 152.964249, hglt: -0.770047 },
  { isoDate: "2023-08-14T00:00:00Z", dsun: 181.188099, hgln: 152.912492, hglt: -0.897585 },
  { isoDate: "2023-08-15T00:00:00Z", dsun: 179.790236, hgln: 152.874222, hglt: -1.026843 },
  { isoDate: "2023-08-16T00:00:00Z", dsun: 178.350103, hgln: 152.850229, hglt: -1.157882 },
  { isoDate: "2023-08-17T00:00:00Z", dsun: 176.867416, hgln: 152.84135, hglt: -1.290766 },
  { isoDate: "2023-08-18T00:00:00Z", dsun: 175.341883, hgln: 152.848475, hglt: -1.425559 },
  { isoDate: "2023-08-19T00:00:00Z", dsun: 173.773208, hgln: 152.872546, hglt: -1.562329 },
  { isoDate: "2023-08-20T00:00:00Z", dsun: 172.161094, hgln: 152.914566, hglt: -1.701145 },
  { isoDate: "2023-08-21T00:00:00Z", dsun: 170.505236, hgln: 152.975603, hglt: -1.842076 },
  { isoDate: "2023-08-22T00:00:00Z", dsun: 168.805333, hgln: 153.056793, hglt: -1.985197 },
  { isoDate: "2023-08-23T00:00:00Z", dsun: 167.061079, hgln: 153.15935, hglt: -2.13058 },
  { isoDate: "2023-08-24T00:00:00Z", dsun: 165.272171, hgln: 153.284571, hglt: -2.2783 },
  { isoDate: "2023-08-25T00:00:00Z", dsun: 163.43831, hgln: 153.433842, hglt: -2.428435 },
  { isoDate: "2023-08-26T00:00:00Z", dsun: 161.559202, hgln: 153.608649, hglt: -2.581061 },
  { isoDate: "2023-08-27T00:00:00Z", dsun: 159.634561, hgln: 153.810587, hglt: -2.736254 },
  { isoDate: "2023-08-28T00:00:00Z", dsun: 157.664111, hgln: 154.041366, hglt: -2.894092 },
  { isoDate: "2023-08-29T00:00:00Z", dsun: 155.647594, hgln: 154.302827, hglt: -3.054649 },
  { isoDate: "2023-08-30T00:00:00Z", dsun: 153.584769, hgln: 154.596958, hglt: -3.217998 },
  { isoDate: "2023-08-31T00:00:00Z", dsun: 151.47542, hgln: 154.925903, hglt: -3.384209 },
  { isoDate: "2023-09-01T00:00:00Z", dsun: 149.319364, hgln: 155.291988, hglt: -3.553347 },
  { isoDate: "2023-09-02T00:00:00Z", dsun: 147.116454, hgln: 155.697741, hglt: -3.725468 },
  { isoDate: "2023-09-03T00:00:00Z", dsun: 144.866588, hgln: 156.145916, hglt: -3.900621 },
  { isoDate: "2023-09-04T00:00:00Z", dsun: 142.569725, hgln: 156.639518, hglt: -4.078842 },
  { isoDate: "2023-09-05T00:00:00Z", dsun: 140.225887, hgln: 157.181838, hglt: -4.260152 },
  { isoDate: "2023-09-06T00:00:00Z", dsun: 137.835183, hgln: 157.776477, hglt: -4.444549 },
  { isoDate: "2023-09-07T00:00:00Z", dsun: 135.397818, hgln: 158.427385, hglt: -4.632008 },
  { isoDate: "2023-09-08T00:00:00Z", dsun: 132.914117, hgln: 159.1389, hglt: -4.822469 },
  { isoDate: "2023-09-09T00:00:00Z", dsun: 130.384548, hgln: 159.915795, hglt: -5.015831 },
  { isoDate: "2023-09-10T00:00:00Z", dsun: 127.809749, hgln: 160.763326, hglt: -5.211941 },
  { isoDate: "2023-09-11T00:00:00Z", dsun: 125.19056, hgln: 161.687288, hglt: -5.410581 },
  { isoDate: "2023-09-12T00:00:00Z", dsun: 122.528069, hgln: 162.694086, hglt: -5.611449 },
  { isoDate: "2023-09-13T00:00:00Z", dsun: 119.823651, hgln: 163.790801, hglt: -5.814143 },
  { isoDate: "2023-09-14T00:00:00Z", dsun: 117.079033, hgln: 164.985278, hglt: -6.018131 },
  { isoDate: "2023-09-15T00:00:00Z", dsun: 114.296355, hgln: 166.286207, hglt: -6.222724 },
  { isoDate: "2023-09-16T00:00:00Z", dsun: 111.478258, hgln: 167.703228, hglt: -6.427033 },
  { isoDate: "2023-09-17T00:00:00Z", dsun: 108.627978, hgln: 169.24703, hglt: -6.629924 },
  { isoDate: "2023-09-18T00:00:00Z", dsun: 105.749464, hgln: 170.929467, hglt: -6.829957 },
  { isoDate: "2023-09-19T00:00:00Z", dsun: 102.847517, hgln: 172.763659, hglt: -7.025314 },
  { isoDate: "2023-09-20T00:00:00Z", dsun: 99.927953, hgln: 174.764106, hglt: -7.213713 },
  { isoDate: "2023-09-21T00:00:00Z", dsun: 96.997802, hgln: 176.946768, hglt: -7.392301 },
  { isoDate: "2023-09-22T00:00:00Z", dsun: 94.065531, hgln: 179.329121, hglt: -7.557529 },
  { isoDate: "2023-09-23T00:00:00Z", dsun: 91.141313, hgln: -178.069844, hglt: -7.705014 },
  { isoDate: "2023-09-24T00:00:00Z", dsun: 88.237325, hgln: -175.229724, hglt: -7.82938 },
  { isoDate: "2023-09-25T00:00:00Z", dsun: 85.368075, hgln: -172.128942, hglt: -7.924096 },
  { isoDate: "2023-09-26T00:00:00Z", dsun: 82.550761, hgln: -168.745198, hglt: -7.981325 },
  { isoDate: "2023-09-27T00:00:00Z", dsun: 79.805618, hgln: -165.056201, hglt: -7.991824 },
  { isoDate: "2023-09-28T00:00:00Z", dsun: 77.156233, hgln: -161.040796, hglt: -7.944925 },
  { isoDate: "2023-09-29T00:00:00Z", dsun: 74.629756, hgln: -156.680557, hglt: -7.828698 },
  { isoDate: "2023-09-30T00:00:00Z", dsun: 72.256925, hgln: -151.961928, hglt: -7.630361 },
  { isoDate: "2023-10-01T00:00:00Z", dsun: 70.0718, hgln: -146.878893, hglt: -7.337061 },
  { isoDate: "2023-10-02T00:00:00Z", dsun: 68.111055, hgln: -141.436033, hglt: -6.937095 },
  { isoDate: "2023-10-03T00:00:00Z", dsun: 66.41272, hgln: -135.651628, hglt: -6.421585 },
  { isoDate: "2023-10-04T00:00:00Z", dsun: 65.014301, hgln: -129.560204, hglt: -5.786452 },
  { isoDate: "2023-10-05T00:00:00Z", dsun: 63.950317, hgln: -123.213785, hglt: -5.034361 },
  { isoDate: "2023-10-06T00:00:00Z", dsun: 63.249502, hgln: -116.681081, hglt: -4.17609 },
  { isoDate: "2023-10-07T00:00:00Z", dsun: 62.932047, hgln: -110.044195, hglt: -3.230754 },
  { isoDate: "2023-10-08T00:00:00Z", dsun: 63.007451, hgln: -103.392997, hglt: -2.224529 },
  { isoDate: "2023-10-09T00:00:00Z", dsun: 63.473438, hgln: -96.818063, hglt: -1.187943 },
  { isoDate: "2023-10-10T00:00:00Z", dsun: 64.316219, hgln: -90.403517, hglt: -0.152354 },
  { isoDate: "2023-10-11T00:00:00Z", dsun: 65.512031, hgln: -84.221175, hglt: 0.853519 },
  { isoDate: "2023-10-12T00:00:00Z", dsun: 67.029588, hgln: -78.3269, hglt: 1.806205 },
  { isoDate: "2023-10-13T00:00:00Z", dsun: 68.832906, hgln: -72.75941, hglt: 2.688819 },
  { isoDate: "2023-10-14T00:00:00Z", dsun: 70.884017, hgln: -67.541161, hglt: 3.491152 },
  { isoDate: "2023-10-15T00:00:00Z", dsun: 73.145221, hgln: -62.680623, hglt: 4.208871 },
  { isoDate: "2023-10-16T00:00:00Z", dsun: 75.580732, hgln: -58.175185, hglt: 4.84225 },
  { isoDate: "2023-10-17T00:00:00Z", dsun: 78.157702, hgln: -54.014129, hglt: 5.394818 },
  { isoDate: "2023-10-18T00:00:00Z", dsun: 80.846756, hgln: -50.181289, hglt: 5.87214 },
  { isoDate: "2023-10-19T00:00:00Z", dsun: 83.622123, hgln: -46.657226, hglt: 6.280852 },
  { isoDate: "2023-10-20T00:00:00Z", dsun: 86.461532, hgln: -43.420901, hglt: 6.62796 },
  { isoDate: "2023-10-21T00:00:00Z", dsun: 89.345952, hgln: -40.450868, hglt: 6.920372 },
  { isoDate: "2023-10-22T00:00:00Z", dsun: 92.259257, hgln: -37.726097, hglt: 7.164611 },
  { isoDate: "2023-10-23T00:00:00Z", dsun: 95.187872, hgln: -35.22649, hglt: 7.366664 },
  { isoDate: "2023-10-24T00:00:00Z", dsun: 98.120425, hgln: -32.933185, hglt: 7.531915 },
  { isoDate: "2023-10-25T00:00:00Z", dsun: 101.04743, hgln: -30.828701, hglt: 7.66514 },
  { isoDate: "2023-10-26T00:00:00Z", dsun: 103.961001, hgln: -28.896983, hglt: 7.770536 },
  { isoDate: "2023-10-27T00:00:00Z", dsun: 106.854596, hgln: -27.12338, hglt: 7.851767 },
  { isoDate: "2023-10-28T00:00:00Z", dsun: 109.722809, hgln: -25.494575, hglt: 7.912013 },
  { isoDate: "2023-10-29T00:00:00Z", dsun: 112.561186, hgln: -23.998501, hglt: 7.954032 },
  { isoDate: "2023-10-30T00:00:00Z", dsun: 115.366063, hgln: -22.624239, hglt: 7.980207 },
  { isoDate: "2023-10-31T00:00:00Z", dsun: 118.134448, hgln: -21.361916, hglt: 7.992595 },
  { isoDate: "2023-11-01T00:00:00Z", dsun: 120.8639, hgln: -20.202606, hglt: 7.992976 },
  { isoDate: "2023-11-02T00:00:00Z", dsun: 123.552446, hgln: -19.138234, hglt: 7.982887 },
  { isoDate: "2023-11-03T00:00:00Z", dsun: 126.198503, hgln: -18.161491, hglt: 7.963656 },
  { isoDate: "2023-11-04T00:00:00Z", dsun: 128.800812, hgln: -17.265755, hglt: 7.936433 },
  { isoDate: "2023-11-05T00:00:00Z", dsun: 131.358384, hgln: -16.445017, hglt: 7.902216 },
  { isoDate: "2023-11-06T00:00:00Z", dsun: 133.870461, hgln: -15.693822, hglt: 7.861869 },
  { isoDate: "2023-11-07T00:00:00Z", dsun: 136.336475, hgln: -15.007204, hglt: 7.816144 },
  { isoDate: "2023-11-08T00:00:00Z", dsun: 138.756015, hgln: -14.38064, hglt: 7.765694 },
  { isoDate: "2023-11-09T00:00:00Z", dsun: 141.128805, hgln: -13.810001, hglt: 7.711091 },
  { isoDate: "2023-11-10T00:00:00Z", dsun: 143.454678, hgln: -13.291514, hglt: 7.652829 },
  { isoDate: "2023-11-11T00:00:00Z", dsun: 145.733562, hgln: -12.821721, hglt: 7.591346 },
  { isoDate: "2023-11-12T00:00:00Z", dsun: 147.96546, hgln: -12.397451, hglt: 7.52702 },
  { isoDate: "2023-11-13T00:00:00Z", dsun: 150.150437, hgln: -12.015787, hglt: 7.460185 },
  { isoDate: "2023-11-14T00:00:00Z", dsun: 152.288615, hgln: -11.674046, hglt: 7.391132 },
  { isoDate: "2023-11-15T00:00:00Z", dsun: 154.380154, hgln: -11.369752, hglt: 7.320119 },
  { isoDate: "2023-11-16T00:00:00Z", dsun: 156.425252, hgln: -11.100618, hglt: 7.247371 },
  { isoDate: "2023-11-17T00:00:00Z", dsun: 158.424135, hgln: -10.86453, hglt: 7.173087 },
  { isoDate: "2023-11-18T00:00:00Z", dsun: 160.377051, hgln: -10.659531, hglt: 7.09744 },
  { isoDate: "2023-11-19T00:00:00Z", dsun: 162.284266, hgln: -10.483809, hglt: 7.020584 },
  { isoDate: "2023-11-20T00:00:00Z", dsun: 164.146059, hgln: -10.335685, hglt: 6.942655 },
  { isoDate: "2023-11-21T00:00:00Z", dsun: 165.96272, hgln: -10.213597, hglt: 6.863773 },
  { isoDate: "2023-11-22T00:00:00Z", dsun: 167.734546, hgln: -10.1161, hglt: 6.784042 },
  { isoDate: "2023-11-23T00:00:00Z", dsun: 169.461839, hgln: -10.041847, hglt: 6.703555 },
  { isoDate: "2023-11-24T00:00:00Z", dsun: 171.144901, hgln: -9.989587, hglt: 6.622393 },
  { isoDate: "2023-11-25T00:00:00Z", dsun: 172.784037, hgln: -9.958153, hglt: 6.540628 },
  { isoDate: "2023-11-26T00:00:00Z", dsun: 174.379551, hgln: -9.946457, hglt: 6.458323 },
  { isoDate: "2023-11-27T00:00:00Z", dsun: 175.931744, hgln: -9.95348, hglt: 6.375534 },
  { isoDate: "2023-11-28T00:00:00Z", dsun: 177.440914, hgln: -9.978267, hglt: 6.292308 },
  { isoDate: "2023-11-29T00:00:00Z", dsun: 178.907354, hgln: -10.019918, hglt: 6.208689 },
  { isoDate: "2023-11-30T00:00:00Z", dsun: 180.331353, hgln: -10.077587, hglt: 6.124712 },
  { isoDate: "2023-12-01T00:00:00Z", dsun: 181.713195, hgln: -10.150473, hglt: 6.040409 },
  { isoDate: "2023-12-02T00:00:00Z", dsun: 183.053156, hgln: -10.237816, hglt: 5.955808 },
  { isoDate: "2023-12-03T00:00:00Z", dsun: 184.351507, hgln: -10.338899, hglt: 5.870933 },
  { isoDate: "2023-12-04T00:00:00Z", dsun: 185.608511, hgln: -10.453037, hglt: 5.785802 },
  { isoDate: "2023-12-05T00:00:00Z", dsun: 186.824423, hgln: -10.57958, hglt: 5.700434 },
  { isoDate: "2023-12-06T00:00:00Z", dsun: 187.999493, hgln: -10.717907, hglt: 5.614842 },
  { isoDate: "2023-12-07T00:00:00Z", dsun: 189.133961, hgln: -10.867429, hglt: 5.529036 },
  { isoDate: "2023-12-08T00:00:00Z", dsun: 190.228059, hgln: -11.027578, hglt: 5.443027 },
  { isoDate: "2023-12-09T00:00:00Z", dsun: 191.282014, hgln: -11.197815, hglt: 5.35682 },
  { isoDate: "2023-12-10T00:00:00Z", dsun: 192.296042, hgln: -11.377617, hglt: 5.270421 },
  { isoDate: "2023-12-11T00:00:00Z", dsun: 193.27035, hgln: -11.566485, hglt: 5.183833 },
  { isoDate: "2023-12-12T00:00:00Z", dsun: 194.205141, hgln: -11.763935, hglt: 5.097056 },
  { isoDate: "2023-12-13T00:00:00Z", dsun: 195.100606, hgln: -11.9695, hglt: 5.010092 },
  { isoDate: "2023-12-14T00:00:00Z", dsun: 195.956931, hgln: -12.182729, hglt: 4.922937 },
  { isoDate: "2023-12-15T00:00:00Z", dsun: 196.774289, hgln: -12.403186, hglt: 4.835591 },
  { isoDate: "2023-12-16T00:00:00Z", dsun: 197.552851, hgln: -12.630449, hglt: 4.748049 },
  { isoDate: "2023-12-17T00:00:00Z", dsun: 198.292774, hgln: -12.864115, hglt: 4.660305 },
  { isoDate: "2023-12-18T00:00:00Z", dsun: 198.994212, hgln: -13.103794, hglt: 4.572355 },
  { isoDate: "2023-12-19T00:00:00Z", dsun: 199.657308, hgln: -13.349116, hglt: 4.48419 },
  { isoDate: "2023-12-20T00:00:00Z", dsun: 200.282197, hgln: -13.599723, hglt: 4.395805 },
  { isoDate: "2023-12-21T00:00:00Z", dsun: 200.869006, hgln: -13.855276, hglt: 4.307189 },
  { isoDate: "2023-12-22T00:00:00Z", dsun: 201.417856, hgln: -14.115447, hglt: 4.218334 },
  { isoDate: "2023-12-23T00:00:00Z", dsun: 201.928859, hgln: -14.379923, hglt: 4.129229 },
  { isoDate: "2023-12-24T00:00:00Z", dsun: 202.402118, hgln: -14.648402, hglt: 4.039864 },
  { isoDate: "2023-12-25T00:00:00Z", dsun: 202.83773, hgln: -14.920589, hglt: 3.950227 },
  { isoDate: "2023-12-26T00:00:00Z", dsun: 203.235783, hgln: -15.196201, hglt: 3.860307 },
  { isoDate: "2023-12-27T00:00:00Z", dsun: 203.596358, hgln: -15.474959, hglt: 3.770089 },
  { isoDate: "2023-12-28T00:00:00Z", dsun: 203.919528, hgln: -15.756589, hglt: 3.679562 },
  { isoDate: "2023-12-29T00:00:00Z", dsun: 204.205358, hgln: -16.040821, hglt: 3.588711 },
  { isoDate: "2023-12-30T00:00:00Z", dsun: 204.453906, hgln: -16.327389, hglt: 3.497521 },
  { isoDate: "2023-12-31T00:00:00Z", dsun: 204.665222, hgln: -16.616025, hglt: 3.405977 },
  { isoDate: "2024-01-01T00:00:00Z", dsun: 204.83935, hgln: -16.906466, hglt: 3.314064 },
  { isoDate: "2024-01-02T00:00:00Z", dsun: 204.976324, hgln: -17.198447, hglt: 3.221765 },
  { isoDate: "2024-01-03T00:00:00Z", dsun: 205.076172, hgln: -17.491704, hglt: 3.129064 }
];
