import "raf/polyfill";
import React from "react";

const FOVCell = ({ pointingAndSize, dragOverClass, onClick }) => {
  if (!pointingAndSize) {
    return <td />;
  }
  const [pointing, size] = pointingAndSize.split("<br>");
  return (
    <td className={"resultCell" + dragOverClass} onClick={onClick}>
      {pointing} <br />
      {size}
    </td>
  );
};

export default FOVCell;
