import React, { Component } from "react";
import Modal from "react-overlays/Modal";
import appStore from "../../Stores/AppStore";
import resultsStore from "../../Stores/ResultsStore";
import { baseUrl, labeledUuidV4 } from "../../Utilities/Utilities";

export default class SettingsOverlay extends Component {
  id = labeledUuidV4("settings-overlay");

  componentDidMount() {
    appStore.globalOnMouseDownHandlers.push({ id: this.id, function: this.handleGlobalMouseOrKeyDown });
    appStore.globalKeyDownHandlers.push({ id: this.id, function: this.handleGlobalMouseOrKeyDown });
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
  }

  handleGlobalMouseOrKeyDown = ev => resultsStore.hideSettingsOverlay();
  handleKeyDown = ev => resultsStore.hideSettingsOverlay();
  handleHideOverlay = ev => resultsStore.hideSettingsOverlay();

  renderBackdrop = props => <div className="backdrop" {...props} />;

  render() {
    return (
      <Modal
        className="centered-overlay help-overlay"
        show
        renderBackdrop={this.renderBackdrop}
        aria-labelledby="modal-label"
        onKeyDown={this.handleKeyDown}
      >
        <div>
          <div style={{ textAlign: "right" }}>
            <img
              className="click-button close-icon"
              src={baseUrl() + "/icons/close.png"}
              alt="X"
              onClick={this.handleHideOverlay}
            />
          </div>
          <h1>Settings</h1>
          <p>List of settings</p>
        </div>
      </Modal>
    );
  }
}
