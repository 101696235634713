import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { EnumParameterProps } from "../Interfaces";
import searchStore from "../../Stores/SearchStore";
configure({ enforceActions: "always" });

@observer
class EnumParameter extends React.Component<EnumParameterProps> {
  id = uuidv4();

  handleOnBlur = ev => {
    searchStore.setPopDownCriterionOverlayIsActive(false);
    this.props.onBlur(ev);
  };

  handleOnFocus = ev => {
    searchStore.setCriterionForOverlay(this.props.criterion);
    searchStore.onBlurActionForEnumOverlay = this.props.onBlur;
    searchStore.sourceElementForEnumOverlay = document.getElementById(this.id);
    searchStore.showOptionsListOverlay();
  };

  handleKeyDown = ev => {
    if (ev.key === "Escape") {
      searchStore.hideOptionsListOverlay();
    }
  };

  handleOnDoubleClick = ev => {
    this.props.criterion.toggleExclusion();
  };

  handleMouseEnter = ev => {
    searchStore.popDownCriterionHookElement = document.getElementById(this.id);
    searchStore.setPopDownCriterionOverlayIsActive(true);
  };

  handleMouseLeave = ev => {
    searchStore.setPopDownCriterionOverlayIsActive(false);
  };

  render() {
    const placeholder = this.props.criterion.values.join(",");
    const excludedClassName = this.props.criterion.excluded ? "criterion-name-excluded" : "";
    const inputExcludedClassName = this.props.criterion.excluded ? "criterion-input-excluded" : "";
    const className = `${this.props.criterion.type} ${this.props.criterion.afterPrefetchStatus} ${inputExcludedClassName}`;
    const value = "";
    return (
      <div
        id={this.id}
        className="numericParameter parameter"
        style={{ width: `${this.props.criterion.width()}px` }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <span className="description">
          <span className={`description ${excludedClassName}`} onDoubleClick={this.handleOnDoubleClick}>
            {" "}
            {this.props.criterion.name}{" "}
          </span>
          <span className="value">
            <input
              type="text"
              placeholder={placeholder}
              className={className}
              value={value}
              onChange={e => {}}
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleOnBlur}
              onFocus={this.handleOnFocus}
              size={20 - this.props.criterion.name.length}
            />
          </span>
        </span>
      </div>
    );
  }
}

export default EnumParameter;
