// Reads a file from the given URL line by line ('\n'-delimited) and
// passes it to the given callBackForEachLine with the new text line as the
// only argument.
//
// When the end of the content has been reached, the callBackForEnd routine
// is called, with no arguments.
//
// When some error occurs, the callBackForError is called with the error
// object as the only argument.

import { MyLogger, myEncodeURI } from "./Utilities";

class LinesFetcher {
  url = "";
  fullResultString = "";
  abortSignal;

  constructor(url, callBackForEachLine, callBackForEnd, callBackForError, abortSignal) {
    this.url = url;
    this.callBackForEachLine = callBackForEachLine;
    this.callBackForEnd = callBackForEnd;
    this.callBackForError = callBackForError;
    this.abortSignal = abortSignal;
  }

  async execute() {
    try {
      const fetchResponse = await fetch(myEncodeURI(this.url), { signal: this.abortSignal });
      const readableStream = fetchResponse.body;
      const reader = readableStream.getReader();

      let readResult;
      while (!readResult?.done) {
        readResult = await reader.read();
        if (readResult.done) {
          if (this.fullResultString !== "") {
            this.callBackForEachLine(this.fullResultString);
          }
          this.callBackForEnd();
          break;
        }
        this.handleNewChunk(readResult.value);
      }
    } catch (error) {
      MyLogger.red("LineFetcher.execute(): Fetching aborted or failed:", error.message);
      this.callBackForError(error);
      throw error;
    }
  }

  handleNewChunk = newChunk => {
    const newChunkString = this.convertArrayFromIntToChar(newChunk);
    this.fullResultString += newChunkString;
    const splitResult = this.fullResultString.split("\n");
    const numberOfSplits = splitResult.length;
    splitResult.forEach((line, index) => {
      if (index < numberOfSplits - 1) {
        this.callBackForEachLine(line);
      }
    });
    this.fullResultString = splitResult[numberOfSplits - 1];
  };

  convertArrayFromIntToChar = inputIntArray => {
    if (inputIntArray.length <= 65000) {
      return String.fromCharCode.apply(null, inputIntArray);
    } else {
      return (
        String.fromCharCode.apply(null, inputIntArray.slice(0, 65000)) +
        this.convertArrayFromIntToChar(inputIntArray.slice(65000, inputIntArray.length))
      );
    }
  };
}

export default LinesFetcher;
