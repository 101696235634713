import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

import searchStore from "../Stores/SearchStore";
import Button from "./Button";
import resultsStore from "../Stores/ResultsStore";
import { convertSyntheticToRealColumnName } from "./Utilities";
configure({ enforceActions: "always" });

const mainActionButtonsWidth = "100px";
const bigActionButtonsHeight = "39px";

interface IProps {
  label?: string;
}

interface SortProps {
  columnName: string;
}

@observer
export class HelpLabeledButton extends React.Component {
  render() {
    return <Button {...help} label="Help" />;
  }
}

@observer
export class HelpButton extends React.Component {
  render() {
    return <Button {...help} />;
  }
}

@observer
export class ManageColumnsLabeledButton extends React.Component {
  render() {
    return <Button {...manageColumns} label="Edit columns" />;
  }
}

@observer
export class ManageColumnsButton extends React.Component {
  render() {
    return <Button {...manageColumns} />;
  }
}

@observer
export class DownloadLabeledButton extends React.Component {
  render() {
    return <Button {...download} label="Download" />;
  }
}

@observer
export class DownloadButton extends React.Component {
  render() {
    return <Button {...download} />;
  }
}

@observer
export class SettingsButton extends React.Component<IProps> {
  render() {
    const settings = {
      id: "settings-icon",
      imageClassName: "results-icon active",
      path: "icons/settings.png",
      name: "Settings",
      onClick: resultsStore.showSettingsOverlay
    };
    return <Button {...settings} />;
  }
}

@observer
export class SortOrderButton extends React.Component<IProps> {
  render() {
    const sortOrder = {
      id: "sort-order-icon",
      imageClassName: "results-icon ",
      path: resultsStore.sortOrder === "ASC" ? "icons/sort-asc.png" : "icons/sort-desc.png",
      name: resultsStore.sortOrder === "DESC" ? "Sort low -> high" : "Sort high -> low",
      label: this.props.label,
      onClick: resultsStore.toggleSortOrder
    };
    return <Button {...sortOrder} />;
  }
}

@observer
export class SortButton extends React.Component<SortProps> {
  render() {
    const currentColumnIsActive =
      convertSyntheticToRealColumnName(resultsStore.sortColumn) ===
      convertSyntheticToRealColumnName(this.props.columnName);

    const sortOrder = {
      id: "sort-order-icon",
      imageClassName: "results-icon " + (currentColumnIsActive ? "active" : "inactive"),
      path: resultsStore.sortOrder === "ASC" ? "icons/sort-asc.png" : "icons/sort-desc.png",
      name: resultsStore.sortOrder === "DESC" ? "Sort low -> high" : "Sort high -> low",
      height: "12px",
      onClick: () => resultsStore.changeSorting(this.props.columnName)
    };
    return <Button {...sortOrder} />;
  }
}

@observer
export class HelpPlainButton extends React.Component {
  render() {
    const buttonText = "Help";
    const collapseAll = {
      id: "collapse-all-button",
      className: "click-button",
      name: buttonText,
      onClick: help.onClick,
      background: "white",
      maxWidth: mainActionButtonsWidth,
      width: mainActionButtonsWidth
    };
    return <Button {...collapseAll} />;
  }
}

@observer
export class AddSelectorButton extends React.Component {
  render() {
    const addSelector = {
      id: "add-selector-button",
      className: "click-button ",
      name: "Add Data",
      onClick: searchStore.showAddSelectorOverlay,
      height: bigActionButtonsHeight,
      background: "yellow",
      fontSize: "28px"
    };
    return <Button {...addSelector} />;
  }
}

@observer
export class SearchButton extends React.Component {
  render() {
    const flashingClass = searchStore.computeSomeSearchIsNeeded ? " search-is-needed" : "";
    const searchButton = {
      id: "search-button",
      className: "click-button search-button" + flashingClass,
      name: `Search`,
      onClick: searchStore.generalSearch,
      height: bigActionButtonsHeight,
      fontSize: "28px"
    };
    return <Button {...searchButton} />;
  }
}

@observer
export class CollapseAllButton extends React.Component {
  render() {
    const buttonText = searchStore.allSelectorsAreMinimised ? "Expand all" : "Collapse all data selectors";
    const collapseAll = {
      id: "collapse-all-button",
      className: "click-button",
      name: buttonText,
      onClick: searchStore.toggleAllSelectorsMinimisedStatus,
      background: "white"
    };
    return <Button {...collapseAll} />;
  }
}

@observer
export class ResetAllButton extends React.Component {
  render() {
    const resetAll = {
      id: "reset-all-button",
      className: "click-button",
      name: "Reset",
      onClick: searchStore.resetAll,
      background: "white"
    };
    return <Button {...resetAll} />;
  }
}

@observer
export class SelectedButton extends React.Component<IProps> {
  render() {
    const selected = {
      id: "show-selected-icon",
      imageClassName: "results-icon " + (resultsStore.showSelected ? "active" : "inactive"),
      path: "icons/show-selected.png",
      name: !resultsStore.showSelected ? "Show selected" : "Hide selected",
      label: this.props.label,
      onClick: resultsStore.toggleShowSelected
    };
    return <Button {...selected} />;
  }
}

@observer
export class UnmarkedButton extends React.Component<IProps> {
  render() {
    const unselected = {
      id: "show-unmarked-icon",
      imageClassName: "results-icon " + (resultsStore.showUnmarked ? "active" : "inactive"),
      path: "icons/show-unmarked.png",
      name: !resultsStore.showUnmarked ? "Show unmarked" : "Hide unmarked",
      label: this.props.label,
      onClick: resultsStore.toggleShowUnmarked
    };
    return <Button {...unselected} />;
  }
}

@observer
export class CartButton extends React.Component<IProps> {
  render() {
    const cart = {
      id: "show-cart-icon",
      imageClassName: "results-icon " + (resultsStore.showCart ? "active" : "inactive"),
      path: "icons/cart.png",
      name: !resultsStore.showCart ? "Show cart" : "Hide cart",
      label: this.props.label,
      onClick: resultsStore.toggleShowCart
    };
    return <Button {...cart} />;
  }
}

@observer
export class HiddenButton extends React.Component<IProps> {
  render() {
    const hidden = {
      id: "show-hidden-icon",
      imageClassName: "results-icon " + (resultsStore.showHidden ? "active" : "inactive"),
      path: "icons/hidden.png",
      name: !resultsStore.showHidden ? "Show hidden" : "Hide hidden",
      label: this.props.label,
      onClick: resultsStore.toggleShowHidden
    };

    return <Button {...hidden} />;
  }
}

@observer
export class TrashButton extends React.Component<IProps> {
  render() {
    const trash = {
      id: "show-trash-icon",
      imageClassName: "results-icon " + (resultsStore.showTrash ? "active" : "inactive"),
      path: "icons/trash.png",
      name: !resultsStore.showTrash ? "Show trash" : "Hide trash",
      label: this.props.label,
      onClick: resultsStore.toggleShowTrash
    };

    return <Button {...trash} />;
  }
}

@observer
export class CollapseLeftPanButton extends React.Component<IProps> {
  render() {
    const collapse = {
      id: "collapse-all-icon",
      imageClassName: " results-icon no-borders",
      path: searchStore.leftPanelCollapsed ? "icons/doubleArrowRight.png" : "icons/doubleArrowLeft.png",
      name: searchStore.leftPanelCollapsed ? "Show solar image and selectors" : "Hide solar image and selectors",
      label: this.props.label,
      onClick: searchStore.toggleLeftPanel
    };

    return <Button {...collapse} />;
  }
}

@observer
export class InvertSelectionButton extends React.Component {
  render() {
    const invert = {
      id: "invert-selection-icon",
      imageClassName: " results-icon no-borders",
      path: "icons/invert-selection.png",
      name: "Expand/invert selection",
      onClick: () => resultsStore.invertSelection(),
      height: "15px"
    };

    return <Button {...invert} />;
  }
}
const help = {
  id: "help-icon",
  imageClassName: "results-icon active",
  path: "icons/help-icon.png",
  name: "Help icon",
  onClick: resultsStore.showHelpOverlay
};

const manageColumns = {
  id: "manage-columns-icon",
  imageClassName: "results-icon ",
  path: "icons/columns.png",
  name: "Add/remove columns",
  onClick: resultsStore.showManageColumnsOverlay
};

const download = {
  id: "download-icon",
  imageClassName: "results-icon ",
  path: "icons/download.png",
  name: "Download cart",
  onClick: () => resultsStore.downloadFiles()
};
