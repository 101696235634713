import "raf/polyfill";
import { action, configure, makeObservable, observable, toJS } from "mobx";

import { MyLogger, isValidDate, myEncodeURI } from "../Utilities/Utilities";
import { BOGUS_ENUM_REGEXP, FILESERVER, INVALID, ORTHOGONAL_INVALID, VALID } from "../constants";
import searchStore from "../Stores/SearchStore";
import Selector from "./Selector";

configure({ enforceActions: "always" });

export type PrefetchStatus = "prefetchEmpty" | "prefetchOK" | "prefetchMalformed" | "prefetchOrthogonal" | "";

export enum CriteriaTypes {
  numeric = "numeric",
  range = "range",
  dateRange = "date-range",
  date = "date",
  enum = "enum",
  string = "string"
}

export class Criterion {
  constructor(parentSelector: Selector, { name, type, description, isMandatory = false, group = "" }) {
    this.parentSelector = parentSelector;
    [this.name, this.type, this.description] = [name, type, description];
    [this.isMandatory, this.group] = [isMandatory, group];
    this.query = () => {
      throw new Error("Querystring not defined for abstract criterion!");
    };
    this.composedName = this.name + "_" + this.parentSelector.datasetName;
  }

  name;
  parentSelector: Selector;
  composedName;
  type;
  description;
  isMandatory;
  group;
  popDownIsOver = false;

  isReady = true;

  @observable
  afterPrefetchStatus: PrefetchStatus;

  @observable
  excluded = false;

  @action
  toggleExclusion = () => {
    this.excluded = !this.excluded;
  };

  @action
  setAfterPrefetchStatus = status => {
    this.afterPrefetchStatus = status;
  };

  setValue: (value: any) => void;
  query: () => any;
  queryPartToBackend: () => any;
  handleValueChange: (ev: any) => void;
  handleMinValueChange: (ev: any) => void;
  handleMaxValueChange: (ev: any) => void;
  checkValidity: () => number;
  hasValue: () => boolean;
  clearValues: () => void;
  replaceCriterionValues: (any) => any;
  importValues: (Criterion) => void;
  clone: (parentSelector: Selector) => Criterion;

  static patchedCriteriondDef = criterionInfo => {
    const patchedCriterionDef = { ...criterionInfo };
    const criterionDef = searchStore.criteriaDefinitions[criterionInfo.name];
    for (let prop in criterionDef) {
      patchedCriterionDef[prop] = criterionDef[prop];
    }
    return patchedCriterionDef;
  };

  height = () => {
    if (searchStore.criteriaTypeDefinitions[this.type]) {
      return searchStore.criteriaTypeDefinitions[this.type].height;
    }
    return null;
  };

  width = () => {
    if (searchStore.criteriaTypeDefinitions[this.type]) {
      return searchStore.criteriaTypeDefinitions[this.type].width;
    }
    return null;
  };
}

export class NumericCriterion extends Criterion {
  constructor(parentSelector: Selector, { name, description, value, isMandatory = false, group = "" }) {
    super(parentSelector, { name, type: CriteriaTypes.numeric, description, isMandatory, group });
    makeObservable(this);
    this.setValue(value);
  }

  @observable
  value: any;

  @action
  setValue = value => {
    this.value = value;
  };

  importValues = criterion => {
    this.setValue(criterion.value);
  };

  query = () => {
    if (!this.value) {
      return "";
    }
    const name = this.excluded ? `${this.name}excluded` : this.name;
    return `${name}=${this.value}`;
  };

  queryPartToBackend = () => {
    if (!this.value || this.excluded) {
      return "";
    }
    return `${this.name}=${this.value}`;
  };

  handleNumericValueChange = ev => this.setValue(ev.target.value);

  checkValidity = () => {
    if (this.value === "") {
      return VALID;
    }
    if (!Number.isNaN(this.value)) {
      return VALID;
    }
    return INVALID;
  };

  replaceCriterionValues = newValue => (this.value = newValue);

  hasValue = () => {
    if (this.value) return true;
    return false;
  };

  @action
  clone = (parentSelector: Selector) => {
    const newObject = new NumericCriterion(parentSelector, {
      name: this.name,
      description: "",
      value: null
    });
    let clone = Object.assign(newObject, this);
    return clone;
  };

  clearValues = () => {
    this.setValue("");
  };
}

export class RangeCriterion extends Criterion {
  constructor(parentSelector: Selector, { name, description, minValue, maxValue, isMandatory = false, group = "" }) {
    super(parentSelector, { name, type: CriteriaTypes.range, description, isMandatory, group });
    makeObservable(this);
    this.setValues(minValue, maxValue);
  }
  @observable
  minValue: any;
  @observable
  maxValue: any;

  @action
  setValues = (minValue, maxValue) => {
    if (minValue !== undefined) {
      this.minValue = minValue;
    }
    if (maxValue !== undefined) {
      this.maxValue = maxValue;
    }
  };

  importValues = criterion => {
    this.setValues(criterion.minValue, criterion.maxValue);
  };

  handleMinValueChange = ev => {
    this.setValues(ev.target.value, undefined);
  };
  handleMaxValueChange = ev => {
    this.setValues(undefined, ev.target.value);
  };

  queryValues = () => {
    const minCriterion = !this.minValue ? "" : `>${this.minValue}`;
    const maxCriterion = !this.maxValue ? "" : `<${this.maxValue}`;
    const separator = minCriterion !== "" && maxCriterion !== "" ? "+" : "";
    return `${minCriterion}${separator}${maxCriterion}`;
  };

  query = () => {
    if (!this.minValue && !this.maxValue) {
      return "";
    }
    const name = this.excluded ? `${this.name}excluded` : this.name;
    return `${name}=${this.queryValues()}`;
  };

  queryPartToBackend = () => {
    const noValues = !this.minValue && !this.maxValue;
    if (noValues || this.excluded) {
      return "";
    }
    return `${this.name}=${this.queryValues()}`;
  };

  checkValidity = () => {
    if (this.minValue && Number.isNaN(this.minValue)) {
      return INVALID;
    }
    if (this.maxValue && Number.isNaN(this.maxValue)) {
      return INVALID;
    }
    if (Number(this.minValue) > Number(this.maxValue)) {
      return ORTHOGONAL_INVALID;
    }
    return VALID;
  };

  replaceCriterionValues = newValues => {
    if (newValues.includes(">")) {
      const lengthToSlice = newValues.includes("+") ? newValues.indexOf("+") : undefined;
      this.minValue = newValues.slice(newValues.indexOf(">") + 1, lengthToSlice);
    }
    if (newValues.includes("<")) {
      this.maxValue = newValues.slice(newValues.indexOf("<") + 1);
    }
  };

  hasValue = () => {
    if (this.minValue || this.maxValue) return true;
    return false;
  };

  @action
  clone = (parentSelector: Selector) => {
    const newObject = new RangeCriterion(parentSelector, {
      name: this.name,
      description: this.description,
      minValue: null,
      maxValue: null
    });
    let clone = Object.assign(newObject, this);
    return clone;
  };

  clearValues = () => {
    this.setValues("", "");
  };
}

export class DateRangeCriterion extends Criterion {
  constructor(
    parentSelector: Selector,
    { name, description, minValue = "", maxValue = "", isMandatory = false, group = "" }
  ) {
    super(parentSelector, { name, type: CriteriaTypes.dateRange, description, isMandatory, group });
    makeObservable(this);
    this.setValues(minValue, maxValue);
    this.popDownIsOver = true;
  }
  @observable
  minValue: any;
  @observable
  maxValue: any;

  @action
  setValues = (minValue, maxValue) => {
    if (minValue !== undefined) {
      this.minValue = minValue;
    }
    if (maxValue !== undefined) {
      this.maxValue = maxValue;
    }
  };

  importValues = criterion => {
    this.setValues(criterion.minValue, criterion.maxValue);
  };

  handleMinValueChange = ev => {
    this.setValues(ev.target.value, undefined);
  };

  handleMaxValueChange = ev => {
    this.setValues(undefined, ev.target.value);
  };

  query = () => {
    if (!this.minValue && !this.maxValue) {
      return "";
    }
    const nameWithExclusion = this.excluded ? `${this.name}excluded` : this.name;
    const minCriterion = this.minValue ? `>${this.minValue}` : "";
    const maxCriterion = this.maxValue ? `<${this.maxValue}` : "";

    const separator = minCriterion !== "" && maxCriterion !== "" ? "+" : "";
    return `${nameWithExclusion}=${minCriterion}${separator}${maxCriterion}`;
  };

  dateObsQueryPartToBackend = () => {
    const minCriterionAsEpochStart = this.minValue === "" ? "" : `EPOCH_START=${this.minValue}`;
    const maxCriterionAsEpochEnd = this.maxValue === "" ? "" : `EPOCH_END=${this.maxValue}`;
    const semicolon = minCriterionAsEpochStart !== "" && maxCriterionAsEpochEnd !== "" ? ";" : "";
    const dateRangeQueryPartToBackend = `${minCriterionAsEpochStart}${semicolon}${maxCriterionAsEpochEnd}`;
    return dateRangeQueryPartToBackend;
  };

  queryPartToBackend = () => {
    const noValues = !this.minValue && !this.maxValue;
    if (noValues || this.excluded) {
      return "";
    }
    if (this.name === "DATE_OBS") {
      return this.dateObsQueryPartToBackend();
    }
    const minCriterion = this.minValue ? `>${this.minValue}` : "";
    const maxCriterion = this.maxValue ? `<${this.maxValue}` : "";
    const minMaxSeparator = minCriterion !== "" && maxCriterion !== "" ? "+" : "";
    return `${this.name}=${minCriterion}${minMaxSeparator}${maxCriterion}`;
  };

  replaceCriterionValues = newValues => {
    if (newValues.includes(">")) {
      const lengthToSlice = newValues.includes("+") ? newValues.indexOf("+") : undefined;
      this.minValue = newValues.slice(newValues.indexOf(">") + 1, lengthToSlice);
    }
    if (newValues.includes("<")) {
      this.maxValue = newValues.slice(newValues.indexOf("<") + 1);
    }
  };

  hasValue = () => {
    if (this.minValue || this.maxValue) return true;
    return false;
  };

  @action
  clone = parentSelector => {
    const newObject = new DateRangeCriterion(parentSelector, {
      name: this.name,
      description: this.description,
      minValue: this.minValue,
      maxValue: this.maxValue,
      isMandatory: this.isMandatory,
      group: this.group
    });
    let clone = Object.assign(newObject, this);
    return clone;
  };

  checkValidity = () => {
    if (this.minValue !== "" && !isValidDate(this.minValue)) {
      return INVALID;
    }
    if (this.maxValue !== "" && !isValidDate(this.maxValue)) {
      return INVALID;
    }
    return VALID;
  };

  clearValues = () => {
    this.setValues("", "");
  };
}

export class DateCriterion extends Criterion {
  constructor(parentSelector: Selector, { name, description, value = "", isMandatory = false, group = "" }) {
    super(parentSelector, { name, type: CriteriaTypes.date, description, isMandatory, group });
    makeObservable(this);
    this.setValue(value);
    this.popDownIsOver = true;
  }
  @observable
  value: any;

  @action
  setValue = value => {
    if (value !== undefined) {
      this.value = value;
    }
  };

  importValues = criterion => {
    this.setValue(criterion.value);
  };

  handleValueChange = ev => {
    this.setValue(ev.target.value);
  };

  query = () => {
    if (!this.value) {
      return "";
    }
    const name = this.excluded ? `${this.name}excluded` : this.name;
    return `${name}=${this.value}`;
  };

  queryPartToBackend = () => {
    if (!this.value || this.excluded) {
      return "";
    }
    const criterion = this.value ? `${this.value}` : "";
    return `${this.name}=${criterion}`;
  };

  replaceCriterionValues = newValue => {
    this.value = newValue;
  };

  hasValue = () => {
    return this.value;
  };

  @action
  clone = (parentSelector: Selector) => {
    const newObject = new DateCriterion(parentSelector, {
      name: this.name,
      description: this.description,
      value: this.value,
      isMandatory: this.isMandatory,
      group: this.group
    });
    let clone = Object.assign(newObject, this);
    return clone;
  };

  checkValidity = () => {
    if (this.value !== "" && !isValidDate(this.value)) {
      return INVALID;
    }
    return VALID;
  };

  clearValues = () => {
    this.setValue("");
  };
}

export class EnumCriterion extends Criterion {
  constructor(parentSelector: Selector, { name, description, values, isMandatory = false, group = "" }) {
    super(parentSelector, { name, type: CriteriaTypes.enum, description, isMandatory, group });
    makeObservable(this);
    this.setValues(values);
    this.isReady = false;
    this.loadEnumValues();
  }
  ids: any[] = [];

  values = observable.array();

  @action
  setValues = values => {
    this.values.replace(values);
  };

  importValues = criterion => {
    this.setValues(criterion.values);
  };

  @action
  addValue = value => {
    if (!this.values.some(currentValue => currentValue === value)) {
      this.values.push(value);
    }
  };

  @action
  removeValue = value => {
    const updatedValues = this.values.filter(currentValue => currentValue !== value);
    this.values.replace(updatedValues);
  };

  @action
  resetValues = ev => {
    this.values.clear();
  };

  handleValueChange = ev => {};

  query = () => {
    const queryParts: any[] = [];
    const name = this.excluded ? `${this.name}excluded` : this.name;
    this.values.forEach(value => queryParts.push(this.value2id(value)));
    const ids = queryParts.join(",");
    if (queryParts.length === 0) {
      return "";
    }
    return `${name}=${ids}`;
  };

  value2id = value => {
    const element = searchStore.enumCriteriaValues[this.composedName].find(element => element.value === value);
    if (!element) {
      return undefined;
    }
    return element.id;
  };

  id2value = id => {
    console.log(
      "Id2Value",
      id,
      toJS(searchStore.enumCriteriaValues),
      searchStore.enumCriteriaValues[this.composedName]
    );
    const element = searchStore.enumCriteriaValues[this.composedName].find(element => element.id.toString() === id);
    if (!element) {
      return undefined;
    }
    return element.value;
  };

  queryPartToBackend = () => {
    if (this.excluded) {
      return "";
    }
    const enumSuffix = this.name.match(BOGUS_ENUM_REGEXP) ? "" : "_ID_";
    if (this.values.length > 0) {
      const ids = this.values.map(value => this.value2id(value)).join(",");
      return `${this.name}${enumSuffix}=${ids}`;
    }
    return "";
  };

  replaceCriterionValues = newValue => {
    if (!this.isReady) {
      setTimeout(() => this.replaceCriterionValues(newValue), 100);
      return;
    }
    const values = newValue
      .split(",")
      .map(value => value.trim())
      .filter(value => value !== "");
    for (let i = 0; i < values.length; i++) {
      const textualValue = this.id2value(values[i]);
      this.addValue(textualValue);
    }
  };

  hasValue = () => {
    if (this.values.length > 0) {
      return true;
    }
    return false;
  };

  @action
  clone = (parentSelector: Selector) => {
    const newObject = new EnumCriterion(parentSelector, {
      name: this.name,
      description: this.description,
      isMandatory: this.isMandatory,
      values: this.values,
      group: this.group
    });
    let clone = Object.assign(newObject, this);
    return clone;
  };

  loadEnumValues = async () => {
    if (searchStore.enumCriteriaValues[this.composedName]) {
      return;
    }
    const url = `${FILESERVER}/vol/svo-data/enums/${this.name}/${this.parentSelector.datasetName}/distinct_values.json`;
    const request = fetch(myEncodeURI(url));
    searchStore.addEnumCriteriaValues(this.composedName, request);
    const fetchResponse = await request;
    try {
      const response = await fetchResponse.json();
      searchStore.addEnumCriteriaValues(this.composedName, response);
    } catch (e) {
      MyLogger.red("Some error parsing JSON or empty file", e);
    }
    this.isReady = true;
  };

  checkValidity = () => {
    return VALID;
  };

  @action
  clearValues = () => {
    this.values.clear();
  };
}

export class StringCriterion extends Criterion {
  constructor(parentSelector: Selector, { name, description, value, isMandatory = false, group = "" }) {
    super(parentSelector, { name, type: CriteriaTypes.string, description, isMandatory, group });
    makeObservable(this);
    this.setValue(value);
  }

  @observable
  value: any;

  @action
  setValue = value => {
    this.value = value;
  };

  importValues = criterion => {
    this.setValue(criterion.value);
  };

  query = () => {
    if (!this.value) {
      return "";
    }
    const name = this.excluded ? `${this.name}excluded` : this.name;
    return `${name}=${this.value}`;
  };

  queryPartToBackend = () => {
    if (!this.value || this.excluded) {
      return "";
    }
    return `${this.name}=${this.value}`;
  };

  handleNumericValueChange = ev => this.setValue(ev.target.value);

  checkValidity = () => {
    if (this.value === "") {
      return VALID;
    }
    if (!Number.isNaN(this.value)) {
      return VALID;
    }
    return INVALID;
  };

  replaceCriterionValues = newValue => (this.value = newValue);

  hasValue = () => {
    if (this.value) return true;
    return false;
  };

  @action
  clone = (parentSelector: Selector) => {
    const newObject = new NumericCriterion(parentSelector, { name: "", description: "", value: null });
    let clone = Object.assign(newObject, this);
    return clone;
  };

  clearValues = () => {
    this.setValue("");
  };
}

export class CriterionFactory {
  static createCriterionFromDefinition = (parentSelector: Selector, criterionDefinition) => {
    switch (criterionDefinition.type) {
      case "numeric":
        return new NumericCriterion(parentSelector, criterionDefinition);
      case "enum":
        return new EnumCriterion(parentSelector, criterionDefinition);
      case "range":
        return new RangeCriterion(parentSelector, criterionDefinition);
      case "date-range":
        return new DateRangeCriterion(parentSelector, criterionDefinition);
      case "date":
        return new DateCriterion(parentSelector, criterionDefinition);
      case "string":
        return new StringCriterion(parentSelector, criterionDefinition);
      default:
        throw new Error("Unknown criterion type", criterionDefinition.type);
    }
  };
}
