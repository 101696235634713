import * as THREE from "three";
import { vectorLength } from "../../utilities/conversions";
import {
  ARCSEC_TO_RADIANS,
  RSUN,
  LAYER_HEIGHT,
  DEGREES_TO_RADIANS,
  UNALLOCATED_LAYER
} from "../../utilities/three-constants";
import { XYZ_to_HGLN_HGLT_R } from "../../utilities/conversions";
import layerManager from "../../LayerManager";
const { cos, sin, sqrt, round } = Math;

// ONE instance is shared between border, corona and surface. The main reason for sharing this is so
// Image vertices0/uvs0/indices only need to be computed once. There is some asymmetry between Image
// and Border objects (which don't need vertices0/uvs0/indices) but there are reasons :)
export default class CommonQuasiFitsAndGeometryParameters {
  private hgln_obs;
  private hglt_obs;
  pixelSize;
  layer: number;
  lastLayer: number;
  naxis1;
  naxis2;
  crpix1;
  crpix2;
  crval1;
  crval2;
  cdelt1;
  cdelt2;
  dateBegMsec;
  c;
  b;
  imageScaleFactor;
  coronaPlaneZCoord;
  private PCx_x;
  private PCy_y;
  private PCy_x;
  private PCx_y;
  observerPositionMatrix;
  private _uvs0;
  private _indices;
  private _vertices0;
  private props;

  constructor(props, layer: number) {
    this.props = props;
    this.calculateProps(layer);
  }

  calculateProps(layer: number) {
    this.pixelSize = this.props.pixelSize;
    this.layer = layer;
    this.lastLayer = layer;
    this.hgln_obs = this.props.HGLN_OBS * DEGREES_TO_RADIANS;
    this.hglt_obs = this.props.HGLT_OBS * DEGREES_TO_RADIANS;
    this.naxis1 = round((this.props.FOVX * ARCSEC_TO_RADIANS * this.props.DSUN_OBS) / this.pixelSize);
    this.naxis2 = round((this.props.FOVY * ARCSEC_TO_RADIANS * this.props.DSUN_OBS) / this.pixelSize);
    this.naxis1 = this.naxis1 || 1;
    this.naxis2 = this.naxis2 || 1;
    this.crpix2 = this.naxis2 / 2.0 + 0.5;
    this.crpix1 = this.naxis1 / 2.0 + 0.5;
    this.crval1 = this.props.XCEN * ARCSEC_TO_RADIANS;
    this.crval2 = this.props.YCEN * ARCSEC_TO_RADIANS;
    this.cdelt1 = (this.props.FOVX / this.naxis1) * ARCSEC_TO_RADIANS;
    this.cdelt2 = (this.props.FOVY / this.naxis2) * ARCSEC_TO_RADIANS;
    this.dateBegMsec = Date.parse(this.props["DATE-BEG"]);
    this.c = this.props.DSUN_OBS;
    this.b = RSUN + layer * LAYER_HEIGHT;
    this.imageScaleFactor = this.b / RSUN;
    this.coronaPlaneZCoord = (RSUN / 10000) * layer;
    const crota = this.props.CROTA * DEGREES_TO_RADIANS;
    this.PCx_x = cos(crota);
    this.PCy_y = cos(crota);
    this.PCy_x = sin(crota);
    this.PCx_y = -sin(crota);
    const matrixX = new THREE.Matrix4().makeRotationX(-this.hglt_obs);
    const matrixY = new THREE.Matrix4().makeRotationY(this.hgln_obs);
    this.observerPositionMatrix = new THREE.Matrix4().premultiply(matrixX).premultiply(matrixY);
  }

  get computeImageVertices0() {
    this._vertices0 || this.createImageVertices0uvs0();
    if (!this._vertices0) {
      this.createImageVertices0uvs0();
    }
    return this._vertices0;
  }

  get computeImageUvs0() {
    this._uvs0 || this.createImageVertices0uvs0();
    return this._uvs0;
  }

  get indices() {
    this._indices || (this._indices = this.generateIndices(this.naxis1, this.naxis2));
    return this._indices;
  }

  // Project points to the plane z=coronaPlaneZCoord
  private outsideSunImagePointToXYZ = ([hpln, hplt]) => {
    const { c } = this;
    const x = c * sin(hpln);
    const y = c * sin(hplt);
    const z = this.coronaPlaneZCoord;
    return [x, y, z];
  };

  private hplnHpltToXYZ = ([hpln, hplt], scaleFactor) => {
    // Fig. 1
    const thetaR = sqrt(hpln * hpln + hplt * hplt);
    // Fig. 2
    const B = thetaR;
    const { c } = this;
    const bb = 2 * c * cos(B);
    const cc = c * c - RSUN * RSUN;
    const sqrtCore = bb * bb - 4 * cc;
    if (sqrtCore < 0) {
      const outsideSunSolution = this.outsideSunImagePointToXYZ([hpln, hplt]);
      return outsideSunSolution;
    }
    const a = (+bb - sqrt(sqrtCore)) / 2;
    const R = a * sin(B);
    const d = sqrt(a * a - R * R);
    const z = c - d;
    // Fig. 1 again, sort of:
    const x = d * sin(hpln);
    const y = d * sin(hplt);
    return [x * scaleFactor, y * scaleFactor, z * scaleFactor];
  };

  pixelCoordinatesToHplnHplt(px, py) {
    const { crpix1, crpix2, PCx_x, PCy_x, PCx_y, PCy_y, crval1, crval2, cdelt1, cdelt2 } = this;
    const ptx = px - crpix1;
    const pty = py - crpix2;
    const qx = PCx_x * ptx + PCx_y * pty;
    const qy = PCy_y * pty + PCy_x * ptx;
    const hpln = crval1 + qx * cdelt1;
    const hplt = crval2 + qy * cdelt2;
    return [hpln, hplt];
  }

  // NOTE: ix/iy are *indices* going from 0 to naxis1+1/naxis2+1 when calculating image
  // and border *vertices*. Vertex(0,0) should be at the lower left corner of the lower
  // left corner, which in FITS-lingo is pixel coord (px,py) = (0.5, 0.5)
  private physVertexAndUvFromPix0Coord = (ix, iy) => {
    const [hpln, hplt] = this.pixelCoordinatesToHplnHplt(ix + 0.5, iy + 0.5);
    const vertex = this.hplnHpltToXYZ([hpln, hplt], this.imageScaleFactor);
    const uvLeft = ix / this.naxis1;
    const uvLower = iy / this.naxis2;
    const uv = [uvLeft, uvLower];
    return { vertex, uv };
  };

  // TODO: Detection of inside/outside Sun should really be done here,
  // TODO: so we can prevent further costly operations if the corona
  // TODO: is entirely inside the Sun
  private createImageVertices0uvs0 = () => {
    if (this.layer === UNALLOCATED_LAYER) {
      this.layer = layerManager.allocateLayer(this.props.observation);
      this.calculateProps(this.layer);
    }
    const { naxis1, naxis2 } = this;
    const numVertices = (naxis1 + 1) * (naxis2 + 1);
    const vertices0 = new Float32Array(Array(3 * numVertices).fill(0));
    const uvs0 = new Float32Array(Array(2 * numVertices).fill(0));
    let vertexOffset = 0;
    let uvOffset = 0;
    for (let iy = 0; iy < naxis2 + 1; iy++) {
      for (let ix = 0; ix < naxis1 + 1; ix++) {
        // TODO: Speed up by sending the vertex0/uvs0 and offsets
        // TODO: so results can be stored directly into the arrays?
        const point = this.physVertexAndUvFromPix0Coord(ix, iy);
        vertices0.set(point.vertex, vertexOffset);
        uvs0.set(point.uv, uvOffset);
        vertexOffset += 3;
        uvOffset += 2;
      }
    }
    this._vertices0 = vertices0;
    this._uvs0 = uvs0;
  };

  centerOfGravityVertex0 = () => {
    const { naxis1, naxis2 } = this;
    const { vertex } = this.physVertexAndUvFromPix0Coord(naxis1 / 2, naxis2 / 2);
    return vertex;
  };

  public surfacePointsFromAtoB = (hplnhpltA, hplnhpltB, pixelSize) => {
    const delta = [hplnhpltB[0] - hplnhpltA[0], hplnhpltB[1] - hplnhpltA[1], 0];
    const length = vectorLength(delta) * this.c;
    const numSteps = Math.ceil(length / pixelSize);
    const steps = [delta[0] / numSteps, delta[1] / numSteps];
    const hplnhpltPosition = hplnhpltA.slice();
    const points = [] as THREE.Vector3[];
    for (let i = 0; i < numSteps + 1; i++) {
      const vertex = this.hplnHpltToXYZ(hplnhpltPosition, 1.0);
      points.push(new THREE.Vector3().fromArray(vertex));
      hplnhpltPosition[0] += steps[0];
      hplnhpltPosition[1] += steps[1];
      hplnhpltPosition[2] += steps[2];
    }
    return points;
  };

  private indicesForPixel = (ix, iy, verticesPerRow) => {
    const LLix = verticesPerRow * iy + ix;
    const LRix = verticesPerRow * iy + ix + 1;
    const URix = verticesPerRow * (iy + 1) + (ix + 1);
    const ULix = verticesPerRow * (iy + 1) + ix;
    const lowerTriangleIx = [LLix, LRix, URix];
    const upperTriangleIx = [URix, ULix, LLix];
    return [...lowerTriangleIx, ...upperTriangleIx];
  };

  generateIndices = (naxis1, naxis2) => {
    // Index calculation - no math here
    const numPixels = naxis1 * naxis2;
    const indicesArraySize = 6 * numPixels;
    const indices = Array(indicesArraySize).fill(0) as number[]; // Note indices is NOT Float32Array!
    const verticesPerRow = naxis1 + 1;
    let indexOffset = 0;
    for (let iy = 0; iy < naxis2; iy++) {
      for (let ix = 0; ix < naxis1; ix++) {
        const singlePixelIndices = this.indicesForPixel(ix, iy, verticesPerRow); // [ 6 elements ]
        for (let i = 0; i < 6; i++) {
          indices[indexOffset++] = singlePixelIndices[i];
        }
      }
    }
    return indices;
  };

  public applyObserverPositionToVertices = vertices => {
    const numVertices = vertices.length;
    const pointVector = new THREE.Vector3();
    for (let i = 0; i < numVertices; i += 3) {
      pointVector.fromArray(vertices, i);
      pointVector.applyMatrix4(this.observerPositionMatrix);
      pointVector.toArray(vertices, i);
    }
  };

  public differentialRotationDegreesPerDay = ([x, y, z]) => {
    const [, HGLT] = XYZ_to_HGLN_HGLT_R(x, y, z);
    const sinHGLT = sin(HGLT * DEGREES_TO_RADIANS);
    const A = 14.713; // deg per day
    const B = -2.396; // deg per day
    const C = -1.787; // deg per day
    const siderealRotation = A + B * sinHGLT * sinHGLT + C * sinHGLT * sinHGLT * sinHGLT * sinHGLT;
    const earthRotation = 360 / 365.26;
    const synodicRotation = siderealRotation - earthRotation; // Our coord. system corotates w/Earth
    return synodicRotation;
  };

  applyDifferentialRotationToPoint = (initialPositions, positions, rotationSpeedRadians, offset, deltaTimeDays) => {
    const omega = rotationSpeedRadians[offset / 3];
    const rotation = omega * deltaTimeDays;
    const sinBeta = sin(rotation);
    const cosBeta = cos(rotation);
    const x = initialPositions[offset];
    const z = initialPositions[offset + 2]; // Just pretending it's called y!
    const newX = cosBeta * x + sinBeta * z;
    const newZ = cosBeta * z - sinBeta * x;
    positions[offset] = newX;
    positions[offset + 2] = newZ;
  };

  currentCenterOfGravityVertex = currentDeltaTimeDays => {
    const centerOfGravityVertex0 = this.centerOfGravityVertex0();
    this.applyObserverPositionToVertices(centerOfGravityVertex0);
    const rotationSpeed = this.differentialRotationSpeedsRadians(centerOfGravityVertex0);
    const currentCenterOfGravityVertex = centerOfGravityVertex0.slice();
    this.applyDifferentialRotationToPoint(
      centerOfGravityVertex0,
      currentCenterOfGravityVertex,
      [rotationSpeed],
      0,
      currentDeltaTimeDays
    );
    if (currentCenterOfGravityVertex.some(isNaN)) {
      console.error("NaN encountered in currentCenterOfGravityVertex");
    }
    return currentCenterOfGravityVertex;
  };

  differentialRotationSpeedsRadians = initialPositions => {
    const rotationSpeedRadians = new Float32Array(initialPositions.length / 3);
    for (let i = 0; i < initialPositions.length; i += 3) {
      const x = initialPositions[i];
      const y = initialPositions[i + 1];
      const z = initialPositions[i + 2];
      rotationSpeedRadians[i / 3] = this.differentialRotationDegreesPerDay([x, y, z]) * DEGREES_TO_RADIANS;
    }
    return rotationSpeedRadians;
  };
}
