import "raf/polyfill";
import { action, configure, observable, toJS, computed, makeObservable, IObservableArray } from "mobx";

import resultsStore from "./ResultsStore";
import { MyLogger, baseUrl, infoPopup, loadAndTransformFKFile, macKeys, myEncodeURI } from "../Utilities/Utilities";

import Selector from "../classes/Selector";
import ResponseLineHandler from "../classes/ResponseLineHandler";
import LineFetcher from "../Utilities/LineFetcher";
import myLocalStorage from "./MyLocalStorage";
import appStore from "./AppStore";
import { CriterionFactory } from "../classes/Criterion";
configure({ enforceActions: "always" });

class SearchStore {
  originalUrl;
  nextSelectorId = 0;
  criteriaDefinitions = {};
  criteriaTypeDefinitions = {};
  lastEditedInputField = "";
  sourceElementForEnumOverlay;
  onBlurActionForEnumOverlay;
  //! TODO: clean out those that are not strictly necessary
  //! TODO: clean out unnecessary //! comments, leave only important ones
  baseQueryFields = [
    "FILE",
    "INSTRUME",
    "DATE_OBS",
    "DATE_END",
    "XCEN",
    "YCEN",
    "XCEN_CORR",
    "YCEN_CORR",
    "XCEN_DELTA", // For checking truthfullness of XCEN_CORR
    "FOVX",
    "FOVY",
    "THUMBS",
    "THUMB_LABELS",
    "THUMB_HINTS",
    "STATUS", // Needed for image/file path calculation (L0/L1/L2/quicklook)
    "HOURPATH"
  ];
  numberOfResultsInRequest = 100;
  popDownCriterionOverlay;
  popDownCriterionHookElement: any = null;
  datePickerOverlayHookElement: any = null;
  dataForDatePickerOverlay: any = null;
  searchSectionWidth = 800;
  selectorsDivHeight;

  constructor() {
    this.originalUrl = decodeURI(window.location.href);
    makeObservable(this);
    setTimeout(() => this.setGlobalKeyHandler(), 100);
  }

  setGlobalKeyHandler = () => {
    if (appStore) {
      appStore.globalKeyDownHandlers.push({ id: "searchStore", function: this.globalKeyHandler });
      appStore.globalKeyUpHandlers.push({ id: "searchStore", function: this.globalKeyHandler });
    } else {
      setTimeout(() => this.setGlobalKeyHandler(), 100);
    }
  };

  globalKeyHandler = event => {
    const { directionalFullCleanedCode } = macKeys(event);
    if (directionalFullCleanedCode === "Enter.down") {
      this.generalSearch();
    }
    if (directionalFullCleanedCode === "Cmd-Enter.down") {
      this.generalSearch();
      this.toggleSelectorsVisibility();
    }
  };

  @observable
  criterionForOverlay;

  @observable
  selectorForOverlay: Selector | null = null;

  @observable
  enumCriteriaValues = {};

  @observable
  selectors = observable.array() as IObservableArray<Selector>;

  @observable
  addSelectorOverlayIsActive = false;

  @observable
  optionsListOverlayIsActive = false;

  @observable
  editSelectorOverlayIsActive = false;

  @observable
  allSelectorsAreMinimised = false;

  @observable
  popDownCriterionOverlayIsActive = false;

  @observable
  selectorsBlockIsExpanded = true;

  @observable
  leftPanelCollapsed = false;

  @observable
  datePickerOverlayIsActive = false;

  @computed
  get computeNormalSelectors() {
    return this.selectors.filter(selector => selector.type === "normalSelector");
  }

  @computed
  get computeSomeSearchIsNeeded() {
    return this.computeSomeNormalSelectorIsEdited || this.computeGlobalCriteria?.computeCriteriaIsEdited;
  }

  @computed
  get computeSomeNormalSelectorIsEdited() {
    return this.computeNormalSelectors.some(selector => selector.computeCriteriaIsEdited);
  }

  @computed
  get computeGlobalCriteria() {
    return this.selectors.find(selector => selector.type === "globalCriteria")!;
  }

  @computed
  get computeSearchForAllIsNeeded() {
    return this.computeGlobalCriteria.computeCriteriaIsEdited || !this.computeSomeNormalSelectorIsEdited;
  }

  @computed
  get computeSelectorsForSearch() {
    return this.computeNormalSelectors.filter(
      selector => selector.computeCriteriaIsEdited || this.computeSearchForAllIsNeeded
    );
  }

  @computed
  get computeSearchIsInProgress() {
    return this.selectors.some(selector => selector.searchInProgress);
  }

  @action
  showEditSelectorOverlay = () => {
    this.editSelectorOverlayIsActive = true;
    appStore.activeOverlays.set("editSelectorOverlay", this.hideAddSelectorOverlay);
  };

  @action
  hideEditSelectorOverlay = () => {
    this.editSelectorOverlayIsActive = false;
    appStore.activeOverlays.delete("editSelectorOverlay");
  };

  @action
  setCriterionForOverlay = criterion => (this.criterionForOverlay = criterion);

  @action
  clearCriterionForOverlay = () => (this.criterionForOverlay = null);

  @action
  setSelectorForOverlay = selector => (this.selectorForOverlay = selector);

  @action
  clearSelectorForOverlay = () => (this.criterionForOverlay = null);

  @action
  addSelectorWhenReady = (selector: Selector) => {
    if (!selector.isReady()) {
      setTimeout(() => this.addSelectorWhenReady(selector), 100);
      return;
    }
    this.selectors.push(selector);
  };

  @action asyncAddAndSearchSelectorWhenEnumsAreReady = (selector: Selector) => {
    if (!selector.isReady()) {
      console.log("addAndSearchSelectorWhenReady (not ready)", selector);
      setTimeout(() => this.asyncAddAndSearchSelectorWhenEnumsAreReady(selector), 100);
      return;
    }
    console.log("addAndSearchSelectorWhenReady (ready)", selector);
    this.addSelectorWhenReady(selector);
    selector.issuePrefetchIfEdited();
    this.issueSingleSelectorSearch(selector, 0);
  };

  @action
  deleteSelector = selector => {
    const compactedSelectors = this.selectors.filter(currentSelector => currentSelector !== selector);
    this.selectors.replace(compactedSelectors);
    resultsStore.removeStatistics(selector);
    resultsStore.removeSelectorFromResults(selector);
    this.syncToUrlAndLocalStorage();
  };

  @action
  replaceSelectors = newSelectors => {
    this.selectors.replace(newSelectors);
  };

  @action
  handleNewSelectorSelection = (selectors, newSelectorAsCopyOfExisting) => {
    if (!selectors) {
      return;
    }
    for (const selector of selectors) {
      this.addNewSelectorByType(selector, newSelectorAsCopyOfExisting);
    }
    this.addSelectorOverlayIsActive = false;
  };

  @action
  showAddSelectorOverlay = () => {
    this.addSelectorOverlayIsActive = true;
    appStore.activeOverlays.set("addSelectorOverlay", this.hideAddSelectorOverlay);
  };

  @action
  hideAddSelectorOverlay = () => {
    this.addSelectorOverlayIsActive = false;
    appStore.activeOverlays.delete("addSelectorOverlay");
  };

  @action
  showOptionsListOverlay = () => {
    this.optionsListOverlayIsActive = true;
    appStore.activeOverlays.set("optionsListOverlay", this.hideOptionsListOverlay);
  };

  @action
  hideOptionsListOverlay = () => {
    this.optionsListOverlayIsActive = false;
    appStore.activeOverlays.delete("optionsListOverlay");
  };

  @action
  deactivateAllSelectorSpinners = () => {
    this.selectors.forEach(selector => selector.searchEnded());
  };

  @action
  addEnumCriteriaValues = (composedName, values) => (this.enumCriteriaValues[composedName] = values);

  @action
  modifySelector = (selector: Selector, modifyWith) => {
    if (!modifyWith) {
      return selector;
    }
    if (modifyWith["minimised"]) {
      selector.toggleMinimisedStatus();
    }
    for (const criterionName in modifyWith) {
      const criterionExists = selector.criteria.some(criterion => criterion.name === criterionName);
      if (!criterionExists) {
        const criterionDefinition = this.criteriaDefinitions[criterionName];
        const newCriterion = CriterionFactory.createCriterionFromDefinition(selector, criterionDefinition);
        selector.addCriterion(newCriterion);
      }
      const modifyData = modifyWith[criterionName];
      console.log("criterionName", criterionName, "modifyData", modifyData);
      this.replaceCriterionValuesInSelector(selector, criterionName, modifyData.value);
    }
    return selector;
  };

  @action toggleAllSelectorsMinimisedStatus = ev => {
    this.allSelectorsAreMinimised = !this.allSelectorsAreMinimised;
    this.selectors.forEach(selector => {
      if (selector.type === "globalCriteria") {
        return;
      }
      if (this.allSelectorsAreMinimised) {
        selector.makeMinimised();
      } else {
        selector.makeExpanded();
      }
    });
  };

  @action expandAllSelectors = () => {
    this.allSelectorsAreMinimised = false;
    this.selectors.forEach(selector => {
      if (selector.type === "globalCriteria") {
        return;
      }
      selector.makeExpanded();
    });
  };

  @action
  setPopDownCriterionOverlayIsActive = (status: boolean) => {
    this.popDownCriterionOverlayIsActive = status;
  };

  @action toggleSelectorsVisibility = () => {
    if (this.selectorsBlockIsExpanded) {
      this.selectorsBlockIsExpanded = false;
      this.setSelectorsDivHeight(0);
    } else {
      this.selectorsBlockIsExpanded = true;
      this.setSelectorsDivHeight(null);
      this.expandAllSelectors();
    }
  };

  @action toggleLeftPanel = () => {
    this.leftPanelCollapsed = !this.leftPanelCollapsed;
    if (this.leftPanelCollapsed) {
      this.setSearchSectionWidth(0);
    } else {
      this.setSearchSectionWidth(this.searchSectionWidth);
    }
  };

  @action
  showDatePickerOverlay = () => {
    this.datePickerOverlayIsActive = true;
    appStore.activeOverlays.set("datePickerOverlay", this.hideDatePickerOverlay);
  };

  @action
  hideDatePickerOverlay = () => {
    this.datePickerOverlayIsActive = false;
    appStore.activeOverlays.delete("datePickerOverlay");
  };

  handleGlobalEscPressed = () => {
    this.hideEditSelectorOverlay();
    this.hideOptionsListOverlay();
  };

  nonSelectorUrlParameters = ["s", "v", "o"];

  selectorContentsForAllSelectorsFromUrl = (url: string) => {
    let selectorsDefinitions: any[] = [];
    const queryString = url.split("?");
    if (queryString.length < 2 || queryString[1].length === 0) {
      return [];
    }
    const queryParts = queryString[1].split(/[?;&]/).filter(term => term.length > 0);
    const handleQueryPart = part => {
      const [key, value] = part.split("=");
      if (!this.nonSelectorUrlParameters.includes(key)) {
        let match = key.match(/([^[]+)\[(\d+)\]$/);
        match = match ? match : [null, key, 0];
        const [, name, index] = match;
        selectorsDefinitions[index] = selectorsDefinitions[index] ?? {};
        selectorsDefinitions[index][name] = { value, excluded: false };
      }
    };
    queryParts.forEach(handleQueryPart);
    return selectorsDefinitions;
  };

  replaceCriterionValuesInSelector = (selector: Selector, criterionNameFromUrlOrStorage, newValues) => {
    const realCriterionName = criterionNameFromUrlOrStorage.replace("excluded", "");
    selector.criteria.forEach(criterion => {
      if (criterion.name === realCriterionName) {
        criterion.excluded = criterionNameFromUrlOrStorage.includes("excluded");
        criterion.replaceCriterionValues(newValues);
      }
    });
  };

  fetchSelectorDefinition = async jsonFileName => {
    const urlToFetchSelector = `${baseUrl()}/json-assets/selectors/${jsonFileName}.json`;
    return fetch(myEncodeURI(urlToFetchSelector))
      .then(response => response.json())
      .catch(error => {
        console.error(error);
      });
  };

  syncToUrlAndLocalStorage = () => {
    const numberedSelectorStates = [] as any[];
    this.selectors.forEach((selector, ix) => {
      const selectorState = selector.selectorStateStringForUrl();
      const numberedSelectorState = selectorState.replace(/=/g, `[${ix}]=`); // => "PARAM[ix]"
      numberedSelectorStates.push(numberedSelectorState);
    });
    let url = baseUrl() + "?" + numberedSelectorStates.join(";");
    url += ";" + resultsStore.queryStringTermForResultsTableColumns();
    url += ";" + resultsStore.queryStringTermForSorting();
    url += ";" + resultsStore.queryStringTermForVisibilityControls();
    window.history.pushState("Object", "Title", url);
    MyLogger.blue("New application URL: " + url);
    myLocalStorage.setUrl(url);
  };

  issueSingleSelectorSearch = async (selector: Selector, startIndex) => {
    if (!selector.isValid() || !this.computeGlobalCriteria?.isValid()) {
      return;
    }
    selector.lastSearchCriteria = selector.cloneCriteria();
    this.computeGlobalCriteria.lastSearchCriteria = this.computeGlobalCriteria.cloneCriteria();
    this.syncToUrlAndLocalStorage();
    selector.setPreviousQueryString();
    const request = selector.requestForSelector(startIndex);
    const resultHandler = new ResponseLineHandler(selector);
    selector.createNewAbortSignal();
    const lineFetcher = new LineFetcher(
      request,
      resultHandler.handleNewLine,
      resultHandler.handleEndOfResults,
      resultHandler.handleError,
      selector.abortController.signal
    );
    selector.startIndexOfCurrentQuery = startIndex;
    selector.searchStarted();
    await lineFetcher.execute();
  };

  asyncFetchAndInitSelector = async selectorType => {
    const selectorDef = await this.fetchSelectorDefinition(selectorType);
    const selector = Selector.createFromDefinition(selectorDef);
    return selector;
  };

  asyncFetchInitAddAndSearchSelector = async (selectorType, useDefaultValues, modifyWith) => {
    const selector = await this.asyncFetchAndInitSelector(selectorType);
    if (!useDefaultValues) {
      selector.clearCriteria();
    }
    this.modifySelector(selector, modifyWith);
    this.asyncAddAndSearchSelectorWhenEnumsAreReady(selector);
  };

  issueAllInitialSearches = async (listOfSelectorTypes, useDefaultValues, modifyWithForAllSelectors) => {
    const globalSelector = await this.asyncFetchAndInitSelector("GLOBAL");
    if (!useDefaultValues) {
      globalSelector.clearCriteria();
    }
    const globalModifyWith = modifyWithForAllSelectors ? modifyWithForAllSelectors[0] : null;
    if (globalModifyWith) {
      this.modifySelector(globalSelector, globalModifyWith);
    }
    this.addSelectorWhenReady(globalSelector);
    globalSelector.setPreviousQueryString();
    for (let i = 1; i < listOfSelectorTypes.length; i++) {
      const selectorType = listOfSelectorTypes[i];
      const modifyWith = modifyWithForAllSelectors ? modifyWithForAllSelectors[i] : null;
      const selector = await this.asyncFetchAndInitSelector(selectorType);
      if (!useDefaultValues) {
        selector.clearCriteria();
      }
      this.modifySelector(selector, modifyWith);
      this.asyncAddAndSearchSelectorWhenEnumsAreReady(selector);
    }
  };

  modifyWithDataFromUrl = () => {
    const selectorContentsForAllSelectorsFromUrl = this.selectorContentsForAllSelectorsFromUrl(this.originalUrl);
    const listOfSelectors = selectorContentsForAllSelectorsFromUrl.map((selector, index) => {
      const name = index === 0 ? "GLOBAL" : selector.INSTRUME.value;
      delete selector.INSTRUME;
      return name;
    });
    return { listOfSelectors, modifyWithForAllSelectors: selectorContentsForAllSelectorsFromUrl };
  };

  loadCriteriaTypeDefinitions = async fileName => {
    const urlToFetchData = `${baseUrl()}/json-assets/criteria/${fileName}`;
    const DataDefAsArray = await fetch(myEncodeURI(urlToFetchData))
      .then(response => response.json())
      .catch(error => {
        console.error(error);
      });
    DataDefAsArray.forEach(definition => (this.criteriaTypeDefinitions[definition.name] = definition));
  };

  loadExternalCriteriaDefinitions = async () => {
    const promises: any[] = [];
    promises.push(this.loadCriteriaTypeDefinitions("criteriaTypes.json"));
    promises.push(loadAndTransformFKFile(this.criteriaDefinitions)); //! TODO: move from Utils into SearchStore
    await Promise.all(promises);
  };

  initializeSearchStore = async () => {
    await this.loadExternalCriteriaDefinitions();
    resultsStore.addCriteriaDefinitionsToAvailableResultsColumns(this.criteriaDefinitions);
    resultsStore.setSortingParametersFromUrl();
    resultsStore.setVisibilityControlsFromUrl();
    const { listOfSelectors, modifyWithForAllSelectors } = this.modifyWithDataFromUrl();
    if (listOfSelectors.length > 0) {
      // We have stuff in URL, use without default values, with modifications
      this.issueAllInitialSearches(listOfSelectors, false, modifyWithForAllSelectors);
      resultsStore.setResultsTableColumnsFromUrl(); //! Must be done last, or infinite reload loop... (why??)
      return;
    }
    const lastUrl = myLocalStorage.getLastUrl();
    if (lastUrl) {
      myLocalStorage.setUrl("");
      window.location.href = lastUrl; //! Try *once* with that URL
      return;
    }
    //! Last resort, use default selector list, use default values, no modifyWith
    this.issueAllInitialSearches(appStore.defaultSelectorList(), true, null);
  };

  resetAll = () => {
    localStorage.clear();
    window.location.href = baseUrl();
  };

  lastModifiedSelector = selectorType => {
    const sameTypeSelectors = this.selectors.filter(selector => selector.datasetName === selectorType);
    if (sameTypeSelectors.length === 0) {
      return null;
    }
    let lastModifiedSelector: any = null;
    sameTypeSelectors.forEach(selector => {
      if (!lastModifiedSelector) {
        lastModifiedSelector = selector;
        return;
      }
      if (selector.lastEditTime > lastModifiedSelector.lastEditTime) {
        lastModifiedSelector = selector;
      }
    });
    return lastModifiedSelector;
  };

  addNewSelectorByType = (selectorType, newSelectorAsCopyOfExisting) => {
    const lastModifiedSelector = this.lastModifiedSelector(selectorType);
    console.log("Last modified selector of type:", selectorType, lastModifiedSelector);
    if (newSelectorAsCopyOfExisting && lastModifiedSelector) {
      const newSelector = lastModifiedSelector.clone();
      console.log("Original selector and copy:", toJS(lastModifiedSelector), toJS(newSelector));
      this.selectors.push(newSelector);
      return;
    }
    // Use default values, no modifyWith
    this.asyncFetchInitAddAndSearchSelector(selectorType, true, null);
  };

  cancelSearch = selector => {
    if (selector.searchInProgress) {
      selector.abortController!.abort();
    }
  };

  // If globalCriteria has been edited - search for *all* (the way it's currently done)
  // If no normalSelector has been edited - search for *all*
  // else: search *only* those normalSelectors that have been edited

  generalSearch = () => {
    if (!this.computeGlobalCriteria) {
      return;
    }
    this.checkSelectorsValidation();
    this.computeGlobalCriteria.setPreviousQueryString();
    appStore.deactivateAllOverlays();
    if (this.computeSearchForAllIsNeeded) {
      this.removeAllSelectorsFromResults();
    }
    this.computeSelectorsForSearch.forEach(selector => {
      this.cancelSearch(selector);
      selector.issuePrefetchIfEdited();
      selector.resetIndices(); // WIP: Working on this
      resultsStore.removeSelectorFromResults(selector);
      this.issueSingleSelectorSearch(selector, 0);
    });
  };

  indexOfSelectorWithinSameNameSelectors = inputSelector => {
    let indexOfSelector = 0;
    const selectorsWithGivenName = this.selectors.filter(selector => selector.datasetName === inputSelector.name);
    selectorsWithGivenName.forEach((selector, index) => {
      if (selector === inputSelector) {
        indexOfSelector = index + 1;
      }
    });
    return indexOfSelector;
  };

  checkSelectorsValidation = () => {
    this.selectors.forEach(selector => {
      if (!selector.isValid()) {
        infoPopup("error", ` Invalid criteria in ${selector.datasetName} selector`);
      }
    });
  };

  public removeSelectorFromRresults = selector => {
    if (selector.type === "normalSelector") {
      resultsStore.removeSelectorFromResults(selector);
      selector.resetIndices();
    }
  };

  public setSearchSectionWidth = width => {
    const searchDiv = document.getElementById("search-section");
    searchDiv!.style.width = `${width}px`;
    const commandBarDiv = document.getElementById("top-bar");
    commandBarDiv!.style.width = `${width}px`;
  };

  public setSelectorsDivHeight = height => {
    const selectorsElement = document.getElementById("selectors");
    if (!selectorsElement) {
      return;
    }
    selectorsElement.style.height = height !== null ? `${height}px` : "auto";
  };

  private removeAllSelectorsFromResults = () => {
    this.selectors.forEach(selector => {
      this.removeSelectorFromRresults(selector);
    });
  };

  fetchMoreDataFromSelectors = async selectors => {
    const promises = selectors.map(selector =>
      this.issueSingleSelectorSearch(selector, selector.endIndexOfFetchedData)
    );
    await Promise.all(promises);
  };
}

const searchStore = new SearchStore();

export default searchStore;
