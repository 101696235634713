import "raf/polyfill";
import React, { CSSProperties } from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

import searchStore from "../../Stores/SearchStore";
import CriteriaGroupBox from "./CriteriaGroupBox";
import resultsStore from "../../Stores/ResultsStore";
import { SEARCHSPANWIDTH } from "../../constants";
import { PulseLoader } from "react-spinners";
import appStore from "../../Stores/AppStore";
import Button from "../../Utilities/Button";
import { labeledUuidV4, shutdownEvent } from "../../Utilities/Utilities";
import Selector from "../../classes/Selector";
configure({ enforceActions: "always" });

@observer
class SelectorBox extends React.Component<{ selector: Selector }> {
  id = labeledUuidV4("selector-box");
  globalSelector = this.props.selector.type === "globalCriteria";
  contextMenuLabels = {
    moveTo: "Move to Earth/SolO (C)",
    centre: this.globalSelector ? "Move to Earht/SolO (cycle) (C)" : "Centre view on observer (C)",
    pinTime: "",
    saveSelected: this.globalSelector
      ? "Save all selected files to cart (Cmd-S)"
      : "Save selector's selected to cart (Cmd-S)",
    altSaveSelected: this.globalSelector
      ? "Unsave all selected files (Alt-Cmd-S)"
      : "Unsave selector's selected (Alt-Cmd-S)",
    hideSelected: this.globalSelector ? "Hide all selected files (Cmd-H)" : "Hide this selector's selected (Cmd-H)",
    altHideSelected: this.globalSelector
      ? "Unhide selected files (Alt-Cmd-H)"
      : "Unhide this selector's selected (Alt-Cmd-H)",
    trashSelected: this.globalSelector
      ? "Delete all selected files (to trash) (Cmd-X)"
      : "Delete this selector's selected files (to trash) (Cmd-X)",
    altTrashSelected: this.globalSelector
      ? "Restore all selected files (Alt-Cmd-X)"
      : "Restore this selector's selected (Alt-Cmd-X)",
    downloadSelected: this.globalSelector
      ? "Download all selected files (Cmd-D)"
      : "Download this selector's selected files (Cmd-D)",
    selectAll: this.globalSelector ? "Select/unselect all (Cmd-A)" : "Select/unselect selector's files (Cmd-A)"
  };

  componentDidMount() {
    appStore.globalKeyUpHandlers.push({ id: this.id, function: this.handleGlobalKeyUp });
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
  }

  handleGlobalKeyUp = ev => {
    const myDiv = document.getElementById(this.id);
    const isMyChildElement = myDiv?.contains(ev.target);
    if (isMyChildElement && ev.key === "Escape") {
      this.props.selector.revertCriteriaToLastSearch();
    }
    if (ev.key === "Escape") {
      resultsStore.hideManageColumnsOverlay();
    }
  };

  handleSelectorDelete = () => {
    const { selector } = this.props;
    searchStore.cancelSearch(selector);
    resultsStore.removeSelectorFromResults(selector);
    searchStore.deleteSelector(selector);
  };

  handleToggleMinimiseStatus = () => this.props.selector.toggleMinimisedStatus();

  handleToggleActiveStatus = ev => {
    this.props.selector.toggleActiveStatus();
  };

  handleEditSelector = ev => {
    searchStore.setSelectorForOverlay(this.props.selector);
    searchStore.showEditSelectorOverlay();
  };

  selectorButtonText = () => {
    const selector = this.props.selector;
    const selectorNameCounter = searchStore.indexOfSelectorWithinSameNameSelectors(selector);
    if (selector.type === "globalCriteria") {
      return selector.description;
    }

    return selector.description + " #" + selectorNameCounter;
  };

  spinnerWhenActive = () => {
    if (this.props.selector.type === "globalCriteria") {
      return;
    }
    if (this.props.selector.countPrefetchInProgress || this.props.selector.statusPrefetchInProgress) {
      const analyzingColor = "red"; // "rgb(248, 194, 215)";
      const color = this.props.selector.countPrefetchInProgress ? "lightgray" : analyzingColor;
      const css = { position: "relative", top: "6px" } as CSSProperties;
      return <PulseLoader size={5} cssOverride={css} color={color} speedMultiplier={1} />;
    }
  };

  matchingTexts = () => {
    let groupsString = this.props.selector.numberOfGroupsMatching.toString();
    let filesString = this.props.selector.numberOfFilesMatching.toString();
    let countsClass = this.props.selector.numberOfFilesMatching ? "some-files-match" : "no-files-match";
    if (this.props.selector.countPrefetchInProgress) {
      groupsString = "?";
      filesString = "?";
      countsClass = "prefetch-in-progress";
    }
    const matchingGroupsText = <span className={countsClass}>{groupsString} groups</span>;
    const matchingFilesText = <span className={countsClass}>{filesString} files</span>;

    return { matchingGroupsText, matchingFilesText };
  };

  renderStatistics = () => {
    if (this.props.selector.type === "globalCriteria") {
      return "";
    }
    if (this.props.selector.countPrefetchInProgress) {
      return <div className="prefetch-in-progress">(Counting....)</div>;
    }
    const { matchingGroupsText, matchingFilesText } = this.matchingTexts();
    return (
      <div>
        Matching: {matchingGroupsText} with {matchingFilesText}
      </div>
    );
  };

  criteriaByGroup = () => {
    const criteriaByGroupAsMap = {};
    this.props.selector.criteria.forEach(criterion => {
      const groupName = criterion.group !== "" ? criterion.group : "General";
      if (criteriaByGroupAsMap[groupName] === undefined) {
        let group = { criteria: [criterion], name: groupName };
        criteriaByGroupAsMap[groupName] = group;
        return;
      }
      criteriaByGroupAsMap[groupName].criteria.push(criterion);
    });
    const criteriaByGroup: any[] = [];
    for (let groupName in criteriaByGroupAsMap) {
      criteriaByGroup.push(criteriaByGroupAsMap[groupName]);
    }
    return criteriaByGroup;
  };

  calculateGroupsSize = criteriaGroups => {
    criteriaGroups.forEach((group, index) => {
      let groupHeight = 0,
        groupMaxWidth = 0;
      group.criteria.forEach(criterion => {
        groupHeight += criterion.height();
        groupMaxWidth = groupMaxWidth >= criterion.width() ? groupMaxWidth : criterion.width();
      });
      criteriaGroups[index].height = groupHeight;
      criteriaGroups[index].width = groupMaxWidth;
    });
  };

  columnHeight = column => column.reduce((acc, group) => acc + group.height, 0);

  columnWidth = column => Math.max(column.map(group => group.width));

  sectionWidth = section => section.reduce((acc, column) => acc + this.columnWidth(column), 0);

  maxColumnHeight = section =>
    section.reduce((maxHeight, column) => {
      const columnHeight = this.columnHeight(column);
      if (columnHeight > maxHeight) {
        return columnHeight;
      }
      return maxHeight;
    }, 0);

  tryToInsertIntoSection = (section, group) => {
    const canInsertGroupIntoNewColumn = this.sectionWidth(section) + group.width < SEARCHSPANWIDTH;
    if (canInsertGroupIntoNewColumn) {
      section.push([group]);
      return true;
    }
    const maxColumnsHeight = this.maxColumnHeight(section);
    let inserted = false;
    section.forEach((column, columnIdx) => {
      const columnHeight = this.columnHeight(column);
      const hasEnoughVerticalSpace = maxColumnsHeight - columnHeight >= group.height;
      const hasEnoughHorizontalSpace = column.width >= group.width;
      if (hasEnoughVerticalSpace && hasEnoughHorizontalSpace && !inserted) {
        section[columnIdx].push(group);
        inserted = true;
        return;
      }
    });
    return inserted;
  };

  insertIntoSelectorLayout = (sections, group) => {
    let inserted = false;
    sections.forEach(section => {
      if (!inserted) {
        inserted = this.tryToInsertIntoSection(section, group);
      }
    });
    if (!inserted) sections.push([[group]]);
  };

  criteriaGroupsArrangement = criteriaGroups => {
    const sections: any[] = [];
    criteriaGroups.forEach(group => {
      sections.push([[group]]);
    });
    return sections;
  };

  renderHeader = () => {
    const checkbox = {
      id: "checkbox-icon",
      path: this.props.selector.active ? "icons/checked.png" : "icons/unchecked.png",
      name: "Turn on / off",
      onClick: this.handleToggleActiveStatus,
      height: "20px"
    };
    const edit = {
      id: "edit-icon",
      className: " click-button",
      path: "",
      name: "EDIT",
      hoverText: "Edit selector criteria",
      onClick: this.handleEditSelector
    };
    const deleteButton = {
      id: "close-column-icon",
      path: "icons/close-column.png",
      name: "Remove selector",
      onClick: this.handleSelectorDelete,
      height: "10px"
    };
    const collapse = {
      id: "collapse-all-icon",
      imageClassName: " results-icon no-borders",
      path: this.props.selector.isMinimised ? "icons/doubleArrowBlackDown.png" : "icons/doubleArrowBlackUp.png",
      name: "Collapse / expand data selector",
      onClick: this.props.selector.toggleMinimisedStatus,
      height: "16px"
    };

    return (
      <div className="selectorbox-header">
        <div className="selectorbox-header-actions">
          {this.selectorButtonText()}
          {this.spinnerWhenActive()}
        </div>
        <div className="selectorbox-header-actions">
          <Button {...edit} />
          <Button {...checkbox} />
          <Button {...collapse} />
          {this.props.selector.type !== "globalCriteria" ? (
            <>
              <div style={{ height: "18px" }}>
                <Button {...deleteButton} />
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  renderCriteriaGroup = group => (
    <CriteriaGroupBox
      key={group.name}
      group={group}
      selector={this.props.selector}
      onBlur={this.props.selector.handlePrefetchOnBlur}
    />
  );

  renderColumn = (column, idx) => (
    <div className="selectorbox-column" key={idx}>
      {column.map(group => this.renderCriteriaGroup(group))}
    </div>
  );

  renderSection = (section, idx) => (
    <div className="selectorbox-section" key={idx}>
      {section.map((column, idx) => this.renderColumn(column, idx))}
    </div>
  );

  handleMouseEnter = ev => {
    this.props.selector.setMouseOverSelector(true);
  };

  handleMouseLeave = ev => {
    this.props.selector.setMouseOverSelector(false);
  };

  handleContextMenu = ev => {
    shutdownEvent(ev);
    appStore.setContextMenuData(ev.clientX, ev.clientY, this.selectorFiles(), this.selectorHoveredFiles());
    appStore.contextMenuLabels = this.contextMenuLabels;
    appStore.showContextMenu();
  };

  selectorFiles = () => {
    if (this.props.selector.type === "globalCriteria") {
      return resultsStore.computeValidResults;
    }
    return resultsStore.computeValidResults.filter(file => file.selectors.has(this.props.selector.id));
  };

  selectorHoveredFiles = () => {
    if (this.props.selector.type === "globalCriteria") {
      return resultsStore.computeValidResults;
    }
    return this.selectorFiles().filter(file => file.selectors.get(this.props.selector.id)!.mouseIsOverSelector);
  };

  render() {
    const { selector } = this.props;
    const classNameWhenMinimised =
      this.props.selector.type === "globalCriteria" ? "minimised-global-selector-body" : "minimised-selector-body";
    const minimisedClass = selector.isMinimised ? classNameWhenMinimised : "";

    const mouseOverResultsClass = selector.mouseIsOverResults ? "mouse-over-results" : "";
    const mouseOverSelectorClass = selector.mouseIsOverSelector ? "mouse-over-selector" : "";
    const mouseOverClasses = `${mouseOverResultsClass} ${mouseOverSelectorClass}`;
    const unsynchronisedClass = selector.computeCriteriaIsEdited ? "unsynchronised-results" : "";
    const selectorIsEdited = selector.computeCriteriaIsEdited ? " selector-is-edited" : "";
    const selectorBoxSpecificClasses = `selector-name-${selector.datasetName} selector-type-${selector.type}`;
    const style = { maxHeight: "fit-content", overflow: "visible" };
    const criteriaGroups = this.criteriaByGroup();
    this.calculateGroupsSize(criteriaGroups);
    const criteriaGroupsArrangement = this.criteriaGroupsArrangement(criteriaGroups);
    return (
      <div
        id={this.id}
        style={style}
        className={` selectorbox ${selectorBoxSpecificClasses} ${unsynchronisedClass} ${mouseOverClasses} ${selectorIsEdited}`}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onContextMenu={this.handleContextMenu}
      >
        {this.renderHeader()}
        {this.renderStatistics()}
        <div className={minimisedClass}>{criteriaGroupsArrangement.map(this.renderSection)}</div>
      </div>
    );
  }
}
export default SelectorBox;
