import React from "react";
import { observer } from "mobx-react";
import { FadeLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";

import { macKeys, shutdownEvent } from "../../Utilities/Utilities";
import resultsStore from "../../Stores/ResultsStore";

import ResultsHeader from "./ResultsHeader";
import ResultRow from "./ResultRow";
import HelpOverlay from "./HelpOverlay";
import appStore from "../../Stores/AppStore";
import IconImagesOverlay from "./IconImagesOverlay";
import searchStore from "../../Stores/SearchStore";
import SettingsOverlay from "./SettingsOverlay";
import BrowserWarning from "./BrowserWarning";

@observer
class ResultsDiv extends React.Component<{}> {
  id = uuidv4();
  private previousMousePosition = { x: 0, y: 0 };
  private mouseInDirectionCounter = 0;
  private mouseOutDirectionCounter = 0;

  componentDidMount() {
    resultsStore.resultsComponentForForcedUpdate = this;
    const intersectionObserver = new IntersectionObserver(this.handleIntersection, {
      root: document.querySelector("#m-results-cell"),
      rootMargin: "0px",
      threshold: 0.5
    });
    const resultsLastRow = document.querySelector("#results-last-row");
    if (resultsLastRow) {
      intersectionObserver.observe(resultsLastRow);
    }
  }

  handleResultClicked = (event, file) => {
    shutdownEvent(event);
    const { shiftKey } = macKeys(event);
    if (!shiftKey) {
      return;
    }
    //resultsStore.addToSelection([file]);
    if (resultsStore.penultimateSelectedFile && resultsStore.lastSelectedFile) {
      const indexOfPenultimate = resultsStore.computeFilteredData.findIndex(
        result => result.id === resultsStore.penultimateSelectedFile.id
      );
      const indexOfLast = resultsStore.computeFilteredData.findIndex(
        result => result.id === resultsStore.lastSelectedFile.id
      );
      const lowerIndex = Math.min(indexOfPenultimate, indexOfLast);
      const upperIndex = Math.max(indexOfPenultimate, indexOfLast);
      const filesToSelect = resultsStore.computeFilteredData.slice(lowerIndex, upperIndex + 1);
      resultsStore.addToSelection(filesToSelect);
    }
  };

  handleIntersection = entries => {
    entries.forEach(entry => {
      if (
        entry.target.id === "results-last-row" &&
        entry.isIntersecting &&
        !searchStore.computeSearchIsInProgress &&
        resultsStore.computeFilteredData.length > 0
      ) {
        const selectorsWhichHaveDataToReceive = searchStore.selectors.filter(selector => !selector.allDataIsReceived());
        const selectorsWithIncompleteDataOnNewPage = resultsStore.selectorsWithIncompleteDataOnNewPage(
          selectorsWhichHaveDataToReceive
        );
        if (selectorsWithIncompleteDataOnNewPage) {
          searchStore.fetchMoreDataFromSelectors(selectorsWithIncompleteDataOnNewPage);
        }
      }
    });
  };

  renderResultLine = file => {
    return (
      <ResultRow
        key={file.id}
        file={file}
        displayStandardActions={true}
        resultsTableColumns={resultsStore.resultsTableColumns}
        onClick={this.handleResultClicked}
      />
    );
  };

  renderHeader = () => (
    <thead>
      <ResultsHeader resultsTableColumns={resultsStore.computeResultsTableColumnsAsArray} />
    </thead>
  );

  renderOverlays = () => (
    <tr>
      <td>{resultsStore.iconImagesOverlayIsActive ? <IconImagesOverlay /> : null}</td>
    </tr>
  );

  bigSpinnerIfActive = () => {
    const resultsDiv = document.getElementById("m-results-cell-div");
    if (!resultsStore.computeResultsSpanSpinnerIsActive || !resultsDiv) {
      return;
    }
    const { width } = resultsDiv.getBoundingClientRect();
    const spinnerHeight = Math.min(Math.max((width - 50) / 10, 20), 80); // Math.min((width - 50) / 2, height);
    const emptyList = resultsStore.files.size === 0;
    const fadeLoaderProps = {
      height: spinnerHeight,
      width: spinnerHeight,
      radius: spinnerHeight / 2,
      margin: spinnerHeight * 1.2,
      color: emptyList ? "#FF000020" : "#80808040"
    };
    const top = -spinnerHeight + 100; // FadeLoader is bigger than itself!
    const left = spinnerHeight + 50;
    return (
      <div id="results-spinner" style={{ position: "absolute", left: `${left}px`, top: `${top}px`, zIndex: 100 }}>
        <FadeLoader {...fadeLoaderProps} speedMultiplier={1.6} />
      </div>
    );
  };

  handleOnMouseEnterResults = ev => appStore.mouseEnters("results");

  handleOnMouseLeaveResults = ev => {
    appStore.mouseLeaves("results");
    resultsStore.setSelectionInProgress(false);
    resultsStore.resetProtoSelection();
  };

  private mousePointerIsInNewPosition = (ev: any) => {
    const { x, y } = this.previousMousePosition;
    const { clientX, clientY } = ev;
    return Math.abs(x - clientX) > 10 || Math.abs(y - clientY) > 10;
  };

  private mousePointerIsMovingInOverlayDirection = (ev, overlayGeometry) => {
    const mouseMoveVector = {
      deltaX: this.previousMousePosition.x - ev.clientX,
      deltaY: this.previousMousePosition.y - ev.clientY
    };
    const mouseMoveAngle = Math.atan2(mouseMoveVector.deltaY, mouseMoveVector.deltaX);
    const overlayTopCornerVector = {
      deltaX: this.previousMousePosition.x - overlayGeometry.right,
      deltaY: this.previousMousePosition.y - overlayGeometry.top
    };
    const overlayTopCornerAngle = Math.atan2(overlayTopCornerVector.deltaY, overlayTopCornerVector.deltaX);
    const overlayBottomCornerVector = {
      deltaX: this.previousMousePosition.x - overlayGeometry.right,
      deltaY: this.previousMousePosition.y - overlayGeometry.bottom
    };
    const overlayBottomCornerAngle = Math.atan2(overlayBottomCornerVector.deltaY, overlayBottomCornerVector.deltaX);
    // console.log(
    //   "mouseMoveVector",
    //   mouseMoveVector,
    //   mouseMoveAngle,
    //   overlayTopCornerVector,
    //   overlayTopCornerAngle,
    //   overlayBottomCornerVector,
    //   overlayBottomCornerAngle
    // );
    return mouseMoveAngle < overlayTopCornerAngle && mouseMoveAngle > overlayBottomCornerAngle;
  };

  handleOnMouseMoveInResults = ev => resultsStore.handleOnMouseMoveInResults(ev);

  render() {
    const className = resultsStore.selectionInProgress ? "noSelect" : "";
    return (
      <div style={{ position: "relative" }}>
        {this.bigSpinnerIfActive()}
        <div id="m-results-cell-div" className={className}>
          <table id="resultsTable">
            {this.renderHeader()}
            <tbody
              onMouseEnter={this.handleOnMouseEnterResults}
              onMouseLeave={this.handleOnMouseLeaveResults}
              onMouseMove={this.handleOnMouseMoveInResults}
            >
              {this.renderOverlays()}
              {resultsStore.computeFilteredData.map(this.renderResultLine)}
              <tr id="results-last-row"></tr>
            </tbody>
          </table>
        </div>
        {resultsStore.helpOverlayIsActive && <HelpOverlay />}
        {appStore.browserWarningIsActive && <BrowserWarning />}
        {resultsStore.settingsOverlayIsActive && <SettingsOverlay />}
      </div>
    );
  }
}

export default ResultsDiv;
