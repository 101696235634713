import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

import SelectorBox from "./SelectorBox";
import searchStore from "../../Stores/SearchStore";
import AddSelectorOverlay from "./AddSelectorOverlay";
import OptionsListOverlay from "./OptionsListOverlay";
import EditSelectorOverlay from "./EditSelectorOverlay";
import Selector from "../../classes/Selector";
import Three from "../ThreeSpan/Three";
import { AddSelectorButton, CollapseAllButton, ResetAllButton, SearchButton } from "../../Utilities/Buttons";
import PopDownOverlay from "./PopDownOverlay";
import DatePicker from "./DatePicker";
configure({ enforceActions: "always" });

@observer
class SearchSpan extends React.Component<{}> {
  componentDidMount() {
    searchStore.initializeSearchStore();
  }

  renderSelectorBox = selector => <SelectorBox key={selector.id} selector={selector} />;

  renderSectionHeading = (heading, addButton) => (
    <div className="search-section-heading">
      <div className="search-section-title">{heading}</div>
      <div className="search-section-add-new">{addButton}</div>
    </div>
  );

  allSelectorsSorted = () =>
    searchStore.selectors.slice().sort((a: Selector, b: Selector) => {
      if (b.type === "globalCriteria") {
        return 1;
      }
      if (a.datasetName === b.datasetName) return 0;
      if (a.datasetName >= b.datasetName) return 1;
      return -1;
    });

  renderApplicationCommandBlock = () => {
    return (
      <div className="search-section-command-column">
        <AddSelectorButton />
        <SearchButton />
        <CollapseAllButton />
        <ResetAllButton />
        {searchStore.addSelectorOverlayIsActive && (
          <AddSelectorOverlay onAddSelectorOverlayExit={searchStore.handleNewSelectorSelection} />
        )}
      </div>
    );
  };

  render() {
    const sortedSelectors = this.allSelectorsSorted();
    const globalSelector = sortedSelectors.length ? sortedSelectors.shift() : null;
    const style = { maxHeight: "fit-content", overflow: "visible" };
    return (
      <div id="search-section" className="search-section">
        <div id="selectors" className="selectors">
          {searchStore.optionsListOverlayIsActive && <OptionsListOverlay />}
          {searchStore.editSelectorOverlayIsActive && <EditSelectorOverlay />}
          {searchStore.popDownCriterionOverlayIsActive && <PopDownOverlay />}
          {searchStore.datePickerOverlayIsActive && <DatePicker />}
          <div className="command-blocks">
            <div className="base-flexbox-row search-section-global-block">
              {globalSelector ? this.renderSelectorBox(globalSelector) : null}
            </div>
            {this.renderApplicationCommandBlock()}
          </div>
          <div style={style} className="selectors-column">
            {sortedSelectors.map(this.renderSelectorBox)}
          </div>
        </div>
        <Three />
      </div>
    );
  }
}
export default SearchSpan;
