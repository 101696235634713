//import 'core-js/es6/map'; // https://reactjs.org/docs/javascript-environment-requirements.html
//import 'core-js/es6/set';

import "raf/polyfill";

import React, { Fragment } from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import InputNumberParameter from "./InputNumberParameter";
import { RangeParameterProps } from "../Interfaces";
import searchStore from "../../Stores/SearchStore";
configure({ enforceActions: "always" });

@observer
class RangeParameter extends React.Component<RangeParameterProps> {
  id = uuidv4();
  handleOnBlur = ev => {
    searchStore.setPopDownCriterionOverlayIsActive(false);
    this.props.onBlur(ev);
  };

  handleOnDoubleClick = ev => this.props.criterion.toggleExclusion();

  handleMouseEnter = ev => {
    searchStore.popDownCriterionHookElement = document.getElementById(this.id);
    searchStore.setPopDownCriterionOverlayIsActive(true);
  };

  handleMouseLeave = ev => searchStore.setPopDownCriterionOverlayIsActive(false);

  numberParameterSpan = (value, placeholder, onValueChange, spanClass) => {
    const { criterion } = this.props;
    const { excluded } = criterion;
    return (
      <span className={spanClass}>
        <InputNumberParameter
          criterion={criterion}
          onValueChange={onValueChange}
          onBlur={this.handleOnBlur}
          value={value}
          placeholder={placeholder}
          excluded={excluded}
        />
      </span>
    );
  };

  renderCoreLikeIRIS = props => (
    <Fragment>
      {this.numberParameterSpan(props.minValue, props.placeholderMin, props.handleMinValueChange, "minValue")}
      <span className={`description ${props.excludedClassName}`} onDoubleClick={this.handleOnDoubleClick}>
        {" " + this.props.criterion.name + " "}
      </span>
      {this.numberParameterSpan(props.maxValue, props.placeholderMax, props.handleMaxValueChange, "maxValue")}
    </Fragment>
  );

  renderCoreLikeRange = props => (
    <Fragment>
      <span className={`description ${props.excludedClassName} nonselectable`} onDoubleClick={this.handleOnDoubleClick}>
        {this.props.criterion.name + ": "}
      </span>
      {this.numberParameterSpan(props.minValue, props.placeholderMin, props.handleMinValueChange, "minValue")}
      <span className="ellipsis-for-range-criterion">{" ... "}</span>
      {this.numberParameterSpan(props.maxValue, props.placeholderMax, props.handleMaxValueChange, "maxValue")}
    </Fragment>
  );

  rangeStyle = false;

  render() {
    const { criterion } = this.props;
    const { name, excluded, minValue, maxValue, handleMinValueChange, handleMaxValueChange } = criterion;
    const globalCriterion = searchStore.computeGlobalCriteria.criterion(name) || { excluded: true };
    const globalCriterionIsActive = globalCriterion ? !globalCriterion.excluded : false;
    const placeholderMin = globalCriterionIsActive ? globalCriterion.minValue : "";
    const placeholderMax = globalCriterionIsActive ? globalCriterion.maxValue : "";

    const excludedClassName = excluded ? "criterion-name-excluded" : "";

    const renderProps = {
      minValue,
      maxValue,
      placeholderMin,
      placeholderMax,
      excludedClassName,
      handleMinValueChange,
      handleMaxValueChange
    };

    const core = this.rangeStyle ? this.renderCoreLikeRange(renderProps) : this.renderCoreLikeIRIS(renderProps);

    return (
      <div id={this.id} className="criterion">
        <span onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
          {core}
        </span>
      </div>
    );
  }
}

export default RangeParameter;
