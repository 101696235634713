import { RSUN } from "../components/ThreeSpan/utilities/three-constants";
import { ephemerisEarth } from "../ephemeris-earth";
import { ephemerisSoho } from "../ephemeris-soho";
import { ephemerisSolo } from "../ephemeris-solo";

class Ephemeris {
  earthInstruments = ["EARTH", "TRACE", "XRT", "EIS", "SOT/NB", "SOT/NB", "SOT/SP", "IRIS/SJI", "IRIS/SPEC"];
  sohoInstruments = ["SOHO", "EIT", "LASCO", "MDI", "SUMER", "UVCS", "CDS"];
  soloInstruments = ["SOLO", "SPICE", "EUI", "EPD", "MAG", "PHI", "RPW", "STIX", "SWA"];

  private doLookup = (isoDate: string, ephemeris) => {
    const ix = ephemeris.findIndex(x => x.isoDate > isoDate);
    const previous = ephemeris[ix - 1];
    const next = ephemeris[ix];
    const previousDate = new Date(previous.isoDate);
    const nextDate = new Date(next.isoDate);
    const span = nextDate.getTime() - previousDate.getTime();
    const fraction = (new Date(isoDate).getTime() - previousDate.getTime()) / span;
    const hglnInterpolated = previous.hgln + fraction * (next.hgln - previous.hgln);
    const hgltInterpolated = previous.hglt + fraction * (next.hglt - previous.hglt);
    const dsunInterpolated = previous.dsun + fraction * (next.dsun - previous.dsun);
    const position = {
      dsun: dsunInterpolated * RSUN,
      hgln: hglnInterpolated,
      hglt: hgltInterpolated
    };
    return position;
  };

  lookup = (isoDate: string, instrument: string) => {
    if (this.earthInstruments.includes(instrument)) {
      return this.doLookup(isoDate, ephemerisEarth);
    }
    if (this.sohoInstruments.includes(instrument)) {
      return this.doLookup(isoDate, ephemerisSoho);
    }
    if (this.soloInstruments.includes(instrument)) {
      return this.doLookup(isoDate, ephemerisSolo);
    }
    throw new Error(`Instrument ${instrument} not found`);
  };
}
const ephemeris = new Ephemeris();
export default ephemeris;
