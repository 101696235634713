import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

import TransferListComponent from "./TransferListComponent";
import resultsStore from "../../Stores/ResultsStore";
import appStore from "../../Stores/AppStore";
import { v4 as uuidv4 } from "uuid";

configure({ enforceActions: "always" });

@observer
class ManageColumnsOverlay extends React.Component {
  id = uuidv4();
  mousePointerWasOverOverlay = false;

  handleAddColumn = columnDef => resultsStore.addColumn(columnDef);

  handleRemoveColumn = columnToRemove => resultsStore.removeColumn(columnToRemove.name);

  handleDragOver = (e, headerName) => {
    if (resultsStore.dragoverColumn !== headerName) {
      resultsStore.setDragOverColumn(headerName);
    }
    e.preventDefault();
  };

  handleDragEnd = (e, headerName) => {
    if (e.dataTransfer.dropEffect === "none") {
      resultsStore.cancelDragging();
    }
  };

  handleDrop = headerName => resultsStore.dropOnColumn(headerName);

  handleGlobalMouseOver = ev => {
    const myDiv = document.getElementById(this.id);
    if (!myDiv) {
      return;
    }
    const isChildElement = myDiv.contains(ev.target);
    if (!isChildElement && this.mousePointerWasOverOverlay) {
      resultsStore.hideManageColumnsOverlay();
    }
    if (!this.mousePointerWasOverOverlay && isChildElement) {
      this.mousePointerWasOverOverlay = true;
    }
  };

  columnIsDisplayed = columnName =>
    resultsStore.resultsTableColumns.some(columnDefinition => columnDefinition.name === columnName);

  availableColumns = () => {
    const columnDefinitions: any[] = [];
    for (let criterionName in resultsStore.availableResultsColumns) {
      const criterionDef = resultsStore.availableResultsColumns[criterionName];
      if (!this.columnIsDisplayed(criterionDef.name) && criterionDef.type !== "no-type" && criterionDef.name !== "L") {
        columnDefinitions.push(criterionDef);
      }
    }
    return columnDefinitions;
  };

  componentDidMount() {
    appStore.globalOnMouseOverHandlers.push({ id: this.id, function: this.handleGlobalMouseOver });
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
  }

  render() {
    const props = {
      leftColumnElements: () => resultsStore.resultsTableColumns,
      rightColumnElements: this.availableColumns,
      handleAddElement: this.handleAddColumn,
      handleRemoveElement: this.handleRemoveColumn,
      hideComponent: resultsStore.hideManageColumnsOverlay,
      draggable: true,
      handleDragStart: resultsStore.setDraggedColumn,
      handleDragOver: this.handleDragOver,
      handleDragEnd: this.handleDragEnd,
      handleDrop: this.handleDrop,
      overlayCSSClassName: "manage-columns-overlay",
      id: this.id
    };

    return <TransferListComponent {...props} />;
  }
}
export default ManageColumnsOverlay;
