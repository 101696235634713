import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

import NumericParameter from "./NumericParameter";
import RangeParameter from "./RangeParameter";
import EnumParameter from "./EnumParameter";
import DateRangeParameter from "./DateRangeParameter";
import { CriteriaGroupBoxProps } from "../Interfaces";
import DateParameter from "./DateParameter";
import StringParameter from "./StringParameter";
import GlobalSelectorDateRangeParameter from "./GlobalSelectorDateRangeParameter";
configure({ enforceActions: "always" });

@observer
class CriteriaGroupBox extends React.Component<CriteriaGroupBoxProps> {
  renderCriterion = criterion => {
    const key = criterion.name; // Don't need id since *key* only needs to be unique within outer element!
    const commonProps = {
      key: key,
      criterion: criterion,
      onBlur: this.props.onBlur
    };
    switch (criterion.type) {
      case "numeric":
        return <NumericParameter {...commonProps} />;
      case "range":
        return <RangeParameter {...commonProps} />;
      case "date-range":
        if (this.props.selector.type === "globalCriteria") {
          return <GlobalSelectorDateRangeParameter {...commonProps} />;
        }
        return <DateRangeParameter {...commonProps} />;
      case "date":
        return <DateParameter {...commonProps} />;
      case "enum":
        return <EnumParameter {...commonProps} />;
      case "string":
        return <StringParameter {...commonProps} />;
      default:
        return <div key={key}>{"?? unknown criterion type " + criterion.type + " ??"}</div>;
    }
  };

  render() {
    const group = this.props.group;
    let spacer;
    if (group.criteria.length % 2 === 1) {
      spacer = <div className="criterion" />;
    }
    return (
      <div className={`criteria-groupbox`}>
        <div className="groupbox-top-spacer" />
        <div className="groupbox-body">
          {group.criteria.map(this.renderCriterion)}
          {spacer}
        </div>
        <div className="groupbox-bottom-spacer" />
      </div>
    );
  }
}

export default CriteriaGroupBox;
