import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import InputNumberParameter from "./InputNumberParameter";
import { NumericParameterProps } from "../Interfaces";
import searchStore from "../../Stores/SearchStore";
configure({ enforceActions: "always" });

@observer
class NumericParameter extends React.Component<NumericParameterProps> {
  id = uuidv4();
  handleOnBlur = ev => {
    searchStore.setPopDownCriterionOverlayIsActive(false);
    this.props.onBlur(ev);
  };

  handleOnDoubleClick = ev => {
    this.props.criterion.toggleExclusion();
  };

  handleMouseEnter = ev => {
    searchStore.popDownCriterionHookElement = document.getElementById(this.id);
    searchStore.setPopDownCriterionOverlayIsActive(true);
  };

  handleMouseLeave = ev => {
    searchStore.setPopDownCriterionOverlayIsActive(false);
  };

  render() {
    const excludedClassName = this.props.criterion.excluded ? "criterion-name-excluded" : "";
    return (
      <div
        id={this.id}
        className="numericParameter parameter"
        style={{ width: `${this.props.criterion.width()}px` }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <span className={`description ${excludedClassName}`} onDoubleClick={this.handleOnDoubleClick}>
          {this.props.criterion.name} &nbsp;
        </span>
        <span className="value">
          <InputNumberParameter
            criterion={this.props.criterion}
            onValueChange={this.props.criterion.handleNumericValueChange}
            onBlur={this.handleOnBlur}
            value={this.props.criterion.value}
            placeholder=""
            excluded={this.props.criterion.excluded}
          />
        </span>
      </div>
    );
  }
}

export default NumericParameter;
