import "raf/polyfill";
import { action, computed, configure, IObservableArray, makeObservable, observable } from "mobx";

import { Criterion, CriterionFactory, PrefetchStatus } from "./Criterion";
import searchStore from "../Stores/SearchStore";
import {
  baseUrlForRealBackend,
  fetchFieldsFromQueryString,
  myEncodeURI,
  queryStringWithoutFetchFields,
  toSyntheticCriterionName
} from "../Utilities/Utilities";
import { INVALID } from "../constants";
import resultsStore from "../Stores/ResultsStore";
import appStore from "../Stores/AppStore";
configure({ enforceActions: "always" });

export type SelectorType = "globalCriteria" | "normalSelector";

export default class Selector {
  datasetName: string;
  type: SelectorType;
  description;
  mandatory;
  id: number;
  abortController;
  prefetchAbortController;
  startIndexOfCurrentQuery = 0;
  endIndexOfFetchedData = 0;
  lastEditTime = Date.now();
  lastSearchCriteria: Criterion[] = [];

  @observable
  criteria = observable.array() as IObservableArray<Criterion>;

  @observable
  numberOfGroupsMatching = 0;

  @observable
  numberOfFilesMatching = 0;

  @observable
  searchInProgress = false;

  @observable
  countPrefetchInProgress = true; // Will start immediately - prevent flickering "0 files/0 groups" text

  @observable
  statusPrefetchInProgress = false;

  @observable
  previousQuery = "";

  @observable
  previousPrefetchQueryString = "";

  @observable
  isMinimised = false;

  @observable
  active = true;

  @observable
  mouseIsOverSelector = false;

  @observable
  mouseIsOverResults = false;

  constructor(name, type, description, mandatory, isMinimised = false) {
    makeObservable(this);
    [this.datasetName, this.type, this.description, this.mandatory] = [name, type, description, mandatory];
    if (isMinimised) {
      this.toggleMinimisedStatus();
    }
    this.id = searchStore.nextSelectorId++;
  }

  @action setPreviousQueryString = () => (this.previousQuery = this.requestForSelector(0));

  @action setPreviousPrefetchQueryString = queryString => (this.previousPrefetchQueryString = queryString);

  @computed
  get computeCriteriaIsEdited() {
    if (this.previousQuery === "") {
      return false;
    }
    const currentQuery = this.requestForSelector(0);
    const previousFetchFields = fetchFieldsFromQueryString(this.previousQuery);
    const currentFetchFields = fetchFieldsFromQueryString(currentQuery);
    if (currentFetchFields.some(currentField => previousFetchFields.indexOf(currentField) === -1)) {
      return true;
    }
    const previousQueryCriteria = queryStringWithoutFetchFields(this.previousQuery);
    const currentQueryCriteria = queryStringWithoutFetchFields(currentQuery);
    return previousQueryCriteria !== currentQueryCriteria;
  }

  @computed
  get computeCriteriaIsEditedAfterLastPrefetch() {
    const baseQueryString = queryStringWithoutFetchFields(this.requestForSelector(0));
    const changed = baseQueryString !== queryStringWithoutFetchFields(this.previousPrefetchQueryString);
    return changed;
  }

  @action toggleMinimisedStatus = () => (this.isMinimised = !this.isMinimised);
  @action makeMinimised = () => (this.isMinimised = true);
  @action makeExpanded = () => (this.isMinimised = false);

  @action searchStarted = () => (this.searchInProgress = true);
  @action searchEnded = () => (this.searchInProgress = false);

  @action prefetchStateChange = (startOrStop: "start" | "stop", prefetchType: "counts_only" | "analysis") => {
    const state = startOrStop === "start";
    if (prefetchType === "counts_only") {
      this.countPrefetchInProgress = state;
    } else {
      this.statusPrefetchInProgress = state;
    }
  };

  @action addCriterion = criterion => this.criteria.push(criterion);

  @action clearCriteria = () => this.criteria.forEach(criterion => criterion.clearValues());

  @action
  removeCriterion = name => {
    const indexOfRemovedCriterion = this.criteria.findIndex(criterion => criterion.name === name);
    if (indexOfRemovedCriterion !== -1) {
      return;
    }
    this.criteria.splice(indexOfRemovedCriterion, 1);
  };

  @action
  updateStatistics = statistics => {
    this.numberOfGroupsMatching = statistics.numberOfGroupsMatching;
    this.numberOfFilesMatching = statistics.numberOfFilesMatching;
  };

  @action toggleActiveStatus = () => (this.active = !this.active);

  @action replaceCriteria = criteria => this.criteria.replace(criteria);

  isReady = () => this.criteria.every(criterion => criterion.isReady);

  // Nomenclature:
  //
  // QueryPart: "PARAM=5"
  // QueryParts: [QueryPart, QueryPart]
  // QueryPartsArrays: [[QueryPartArray], [QueryPartArray]]
  // QueryString: QueryPart;QueryPart;...

  selectorStateStringForUrl = () => {
    let allStateParts: string[] = [];
    if (this.type === "normalSelector") {
      allStateParts.push(`INSTRUME=${this.datasetName}`);
    }
    if (this.isMinimised) {
      allStateParts.push("minimised=1");
    }
    this.criteria.forEach(criterion => allStateParts.push(criterion.query()));
    const nonEmptyQueryParts = allStateParts.flat().filter(query => query !== "");
    return nonEmptyQueryParts.join(";");
  };

  static createFromDefinition = selectorDefinition => {
    const { datasetName, type, description, mandatory, isMinimised } = selectorDefinition;
    const selector = new Selector(datasetName, type, description, mandatory, isMinimised);
    selectorDefinition.criteria.forEach(criterionInfo => {
      const criterionDef = Criterion.patchedCriteriondDef(criterionInfo);
      const criterion = CriterionFactory.createCriterionFromDefinition(selector, criterionDef);
      selector.addCriterion(criterion);
    });
    return selector;
  };

  updateAmountOfReceivedData = statistics => {
    const endIndexOfCurrentQuery = this.startIndexOfCurrentQuery + statistics.groups_shown;
    this.endIndexOfFetchedData = Math.max(endIndexOfCurrentQuery, this.endIndexOfFetchedData);
  };

  @action resetIndices = () => {
    this.startIndexOfCurrentQuery = 0;
    this.endIndexOfFetchedData = 0;
  };

  @action
  resetStatistics = () => {
    this.updateStatistics({ numberOfGroupsMatching: 0, numberOfFilesMatching: 0 });
  };

  @action
  setMouseOverSelector = (isOver: boolean) => {
    if (isOver) {
      appStore.mouseEnters(this.type);
    } else {
      appStore.mouseLeaves(this.type);
    }
    this.mouseIsOverSelector = isOver;
  };

  @action
  setMouseOverResults = (status: boolean) => (this.mouseIsOverResults = status);

  allDataIsReceived = () => {
    if (this.endIndexOfFetchedData >= this.numberOfGroupsMatching - 1) {
      return true;
    }
    return false;
  };

  createNewAbortSignal = () => {
    this.abortController = new AbortController();
  };

  checkForStatistics = response => {
    const matchingStrings = response.match(/OSDC_result: [0-9]+ groups w\/[0-9]+ matching/);
    if (!matchingStrings) {
      console.error("checkForStatistics: No matchingStrings!");
      this.searchEnded();
      return;
    }

    const numbersFromMatchingString = matchingStrings[0].match(/[0-9]+/g);
    const numberOfGroupsMatching = parseInt(numbersFromMatchingString[0]);
    const numberOfFilesMatching = parseInt(numbersFromMatchingString[1]);
    this.updateStatistics({ numberOfGroupsMatching, numberOfFilesMatching });
  };

  prefetchQuery = async url => {
    this.prefetchAbortController?.abort();
    this.prefetchAbortController = new AbortController();
    this.resetStatistics();
    let responseText = "";
    try {
      const fetchResponse = await fetch(myEncodeURI(url), { signal: this.prefetchAbortController.signal });
      responseText = await fetchResponse.text();
    } catch (error) {
      console.error("prefetch caught error: ", error);
      return;
    }
    return responseText;
  };

  applyPrefetchStatusToCriterionIfValid = (criterion, criterionName, afterPrefetchStatus) => {
    const applyToAllCriteriaWithValue = criterionName === "" && criterion.hasValue();
    const applyToAllCriteria = criterionName === "" && afterPrefetchStatus === "";
    let applyToThis = applyToAllCriteriaWithValue || applyToAllCriteria;
    applyToThis = applyToThis || criterionName === criterion.name;
    if (applyToThis) criterion.setAfterPrefetchStatus(afterPrefetchStatus);
  };

  updateCriterionPrefetchStatus = (criterionName, afterPrefetchStatus: PrefetchStatus) => {
    this.criteria.forEach(criterion => {
      this.applyPrefetchStatusToCriterionIfValid(criterion, criterionName, afterPrefetchStatus);
    });
    searchStore.computeGlobalCriteria.criteria.forEach(criterion => {
      this.applyPrefetchStatusToCriterionIfValid(criterion, criterionName, afterPrefetchStatus);
    });
  };

  clearAllCriteriaPrefetchStatus = () => {
    this.updateCriterionPrefetchStatus("", "");
  };

  malformedCiterion = response => {
    const splitResponse = response.trim().split("\n");
    const allOk = splitResponse.some(line => line.match(/^OSDC_crash:$/));
    if (allOk) {
      return null;
    }
    const crashStrings = response.match(/OSDC_crash: ([\w-]+)/g);
    const crashedCriterion = crashStrings[crashStrings.length - 1].replace("OSDC_crash: ", "");
    return toSyntheticCriterionName(crashedCriterion);
  };

  orthogonalAndEmptyCriteria = response => {
    const orthogonalCriteriaStringMatchArray = response.match(/OSDC_yel: ((\w+),)*(\w+)/);
    const orthogonalCriteriaString = orthogonalCriteriaStringMatchArray
      ? orthogonalCriteriaStringMatchArray[0].replace("OSDC_yel:", "").replace("OSDC_red:", "")
      : null;
    const orthogonalCriteria = orthogonalCriteriaString
      ?.split(",")
      .map(value => toSyntheticCriterionName(value.trim()));
    const emptyCriteriaStringMatcharray = response.match(/OSDC_red: ((\w+),)*(\w+)/);
    const emptyCriteriaString = emptyCriteriaStringMatcharray
      ? emptyCriteriaStringMatcharray[0].replace("OSDC_red:", "")
      : null;
    const emptyCriteria = emptyCriteriaString?.split(",").map(value => toSyntheticCriterionName(value.trim()));
    return [orthogonalCriteria, emptyCriteria];
  };

  updateCriteriaPrefetchStatus = response => {
    this.clearAllCriteriaPrefetchStatus();
    const malformedCriterion = this.malformedCiterion(response);
    if (malformedCriterion) {
      this.updateCriterionPrefetchStatus(malformedCriterion, "prefetchMalformed");
      return;
    }
    const [orthogonalCriteria, emptyCriteria] = this.orthogonalAndEmptyCriteria(response);
    orthogonalCriteria?.forEach(criterionName =>
      this.updateCriterionPrefetchStatus(criterionName, "prefetchOrthogonal")
    );
    emptyCriteria?.forEach(criterionName => this.updateCriterionPrefetchStatus(criterionName, "prefetchEmpty"));
    if (orthogonalCriteria || emptyCriteria) {
      return;
    }
    this.updateCriterionPrefetchStatus("", "prefetchOK");
  };

  prefetch = async (prefetchType: "counts_only" | "analysis") => {
    const baseQueryString = this.requestForSelector(0);
    const prefetchBaseQueryString = baseQueryString.replace("svo/search", "get14");
    this.prefetchStateChange("start", prefetchType);
    const response = await this.prefetchQuery(prefetchBaseQueryString + ";prefetch_type=" + prefetchType);
    this.prefetchStateChange("stop", prefetchType);
    this.setPreviousPrefetchQueryString(baseQueryString);
    return response;
  };

  issuePrefetchIfEdited = async () => {
    const wrongType = this.type === "globalCriteria";
    const notNeeded = !this.computeCriteriaIsEditedAfterLastPrefetch;
    if (wrongType || notNeeded) {
      return;
    }

    this.clearAllCriteriaPrefetchStatus();
    const response = await this.prefetch("counts_only");
    if (!response) {
      return;
    }
    this.checkForStatistics(response);

    if (this.numberOfFilesMatching === 0) {
      const statusResponse = await this.prefetch("analysis");
      if (statusResponse) {
        this.updateCriteriaPrefetchStatus(statusResponse);
      }
    }
  };

  handlePrefetchOnBlur = () => {
    this.lastEditTime = Date.now();
    if (this.type === "globalCriteria") {
      searchStore.selectors.forEach(selector => selector.issuePrefetchIfEdited());
    } else {
      this.issuePrefetchIfEdited();
    }
  };

  criterion = criterionName => {
    let foundCriterion;
    this.criteria.forEach(criterion => {
      if (criterion.name === criterionName) {
        foundCriterion = criterion;
      }
    });
    return foundCriterion;
  };

  clone = () => {
    const clonedSelector = new Selector("", "", "", false);
    Object.assign(clonedSelector, this);
    clonedSelector.replaceCriteria(this.criteria.map(criterion => criterion.clone(clonedSelector)));
    clonedSelector.abortController = null;
    clonedSelector.endIndexOfFetchedData = 0;
    clonedSelector.lastEditTime = Date.now();
    clonedSelector.prefetchAbortController = null;
    clonedSelector.startIndexOfCurrentQuery = 0;
    clonedSelector.id = searchStore.nextSelectorId++;
    console.log("Objects: original and copy:", this, clonedSelector);
    return clonedSelector;
  };

  isValid = () => {
    return !this.criteria.some(criterion => criterion.checkValidity() === INVALID);
  };

  removeFromCriteria = (criteria, removedCriterion) => {
    const index = criteria.findIndex(criterion => criterion.name === removedCriterion.name);
    if (index === -1) {
      return;
    }
    criteria.splice(index, 1);
  };

  replaceOrAddInCriteria = (criteria, newCriterion) => {
    const index = criteria.findIndex(criterion => criterion.name === newCriterion.name);
    if (index === -1) {
      criteria.push(newCriterion);
      return;
    }
    if (!criteria[index].hasValue()) {
      criteria.splice(index, 1, newCriterion);
    }
  };

  fetchFieldsQueryPart = () => {
    let fieldsToAskFor = [...searchStore.baseQueryFields]; // Must clone array since we modify it!
    if (this.datasetName === "SOT/SP") {
      fieldsToAskFor.push("SS__NSLITPOS");
      fieldsToAskFor.push("SS__SLITINDX");
      fieldsToAskFor.push("SS__SCN_STEP");
      fieldsToAskFor.push("SS__SCN_SUM");
    }
    resultsStore.resultsTableColumns.forEach(column => fieldsToAskFor.push(column.name));
    fieldsToAskFor = [...new Set(fieldsToAskFor)];
    fieldsToAskFor = fieldsToAskFor.filter(field => field.match(/^[A-Z]/)); // Remove synthetic fields (lowercase)

    // Sorting fields makes request insensitive to column order => cache works more efficiently
    fieldsToAskFor = fieldsToAskFor.sort((a, b) => (a > b ? 1 : -1));
    const fetchFieldsQueryPart = "s=," + fieldsToAskFor.join(",");
    return fetchFieldsQueryPart;
  };

  effectiveCriteria = () => {
    const globalEffectiveCriteria = searchStore.computeGlobalCriteria.criteria.filter(criterion => !criterion.excluded);
    const selectorEffectiveCriteria = this.criteria.filter(criterion => !criterion.excluded);
    const effectiveCriteria = [...globalEffectiveCriteria, ...selectorEffectiveCriteria];
    return effectiveCriteria;
  };

  requestForSelector = startIndex => {
    const dataSetQueryPart = `INSTRUME=${this.datasetName}`;

    const criteriaQueryParts = this.effectiveCriteria().map(criterion => criterion.queryPartToBackend());
    const queriesFromSearchCriteria = criteriaQueryParts.filter(part => part !== "").join(";");

    const fetchFieldsQueryPart = this.fetchFieldsQueryPart();

    const sortOrder = resultsStore.sortOrder === "DESC" ? "O=DATE_OBS;o=D" : "O=DATE_OBS;o=A";
    // MyLogger.normal("request sortOrder is not using resultsStore.sortColumn!!!", sortOrder);
    const startIndexCmd = `result_offset=${startIndex}`;
    const SOT_SP_Optional = this.datasetName === "SOT/SP" ? `;SS__L1LEAD=y` : "";
    let request = "";
    request += `${baseUrlForRealBackend()}/search?${dataSetQueryPart}`;
    request += `;L=${searchStore.numberOfResultsInRequest}`;
    request += `;${queriesFromSearchCriteria}`;
    request += `;${startIndexCmd}`;
    request += `;${sortOrder}`;
    request += `;G=IUMODE1`;
    request += `;Gx=IUMODE1`;
    request += `;th=y`;
    request += `;${fetchFieldsQueryPart}`;
    request += `${SOT_SP_Optional}`;
    return request;
  };

  @action
  revertCriteriaToLastSearch = () => {
    this.criteria.forEach(criterion => {
      const indexOfLastSavedCriterion = this.lastSearchCriteria.findIndex(
        lastSearchedCriterion => lastSearchedCriterion.name === criterion.name
      );
      if (indexOfLastSavedCriterion >= 0) {
        const lastSavedCriterion = this.lastSearchCriteria[indexOfLastSavedCriterion];
        criterion.importValues(lastSavedCriterion);
      }
    });
    return;
  };

  cloneCriteria = () => {
    return this.criteria.map(criterion => criterion.clone(this));
  };
}
