import React, { Component } from "react";
import * as THREE from "three";

import { MyOrbitControls } from "../MyOrbitControls";
import Axes from "./Axes";
import {
  GLOBE2_CLEAR_DEPTH,
  GLOBE2_RENDER_ORDER,
  GLOBE_CLEAR_DEPTH,
  GLOBE_RENDER_ORDER,
  orbitControlProps,
  threeCanvasDefaultProps
} from "../../utilities/three-constants";
import RaycasterPickHelper from "./RaycasterPickHelper";
import resultsStore from "../../../../Stores/ResultsStore";
import threeStore from "../../../../Stores/ThreeStore";
import File from "../../../../classes/File";
import { MyLogger, macKeys, shutdownEvent } from "../../../../Utilities/Utilities";
import appStore from "../../../../Stores/AppStore";

export interface ThreeCanvasProps {
  physCameraX?: number;
  physCameraY?: number;
  physCameraZ?: number;
  physFovWidth?: number;
  physCameraDist?: number;

  nearLimit?: number;
  farLimit?: number;
  width?: number;
  height?: number;

  axisLength?: number;
  axisLabelFontSize?: number;
  axisLabelFont?: string;
  axisLabelFontHeight?: number;
  axisLabelOffset?: number;

  axisArrowHeadLength?: number;
  axisArrowHeadWidth?: number;
  axisColor?: number | string;

  globeRadius?: number;
  globeWidthSegments?: number;
  globeHeightSegments?: number;
  globeColor?: number | string;
  globeWireframeColor?: number | string;

  containerDivId?: string;
  className?: string;
  containerDivStyle?: React.CSSProperties; // Style to be applied to container div

  handleOnClick?: (ev, intersecElements) => void;
  handleOnMouseMove?: (intersecElements) => void;
}

class ThreeCanvas extends Component<ThreeCanvasProps, {}> {
  static defaultProps = threeCanvasDefaultProps;
  id = "three-canvas";

  // THREE-related properties
  camera: THREE.PerspectiveCamera;
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  orbitControls: MyOrbitControls;
  containerDivElement;
  renderedContentElement;
  infoDiv;
  normalizedPickPosition = { x: 0, y: 0 } as { x: number | null; y: number | null };

  clickEvent: MouseEvent;

  mouseMovePicker: RaycasterPickHelper;
  onClickPicker: RaycasterPickHelper;

  // Rectangle selection related properties
  rectangleSelectionStartPosition;
  rectangleSelectionEndPosition;
  rectangleSelectionOverlayElement;
  rectangleSelectionOverlayStartPoint = { x: 0, y: 0 };
  rectangleSelectionInProgress = false;

  pointerIsDownWithoutShift = false; // Used to detect orbit control rotation state

  constructor(props) {
    super(props);
    this.clearPickPosition();
    this.mouseMovePicker = new RaycasterPickHelper(this.props.handleOnMouseMove, { alwaysCallback: false });
    this.onClickPicker = new RaycasterPickHelper(this.handleClickPickHelper, { alwaysCallback: true });
  }

  // TODO: Something like this should be in place to move viewpoint to Earth/Solar Orbiter
  public reset = () => this.orbitControls.reset();

  private makeRenderer = () => {
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(this.props.width!, this.props.height!);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    const seeThroughColor = 0x000000;
    const opacity = 1;
    this.renderer.setClearColor(seeThroughColor, opacity);
    this.renderer.sortObjects = true;
    this.renderedContentElement = this.renderer.domElement;
    this.containerDivElement = document.getElementById(this.props.containerDivId!)!;
    this.containerDivElement.appendChild(this.renderedContentElement);
    this.renderedContentElement.addEventListener("click", this.handleClick);
    this.renderedContentElement.addEventListener("pointermove", this.handlePointerMove);
    this.renderedContentElement.addEventListener("mouseout", this.handleMouseOut);
    this.renderedContentElement.addEventListener("mouseleave", this.clearPickPosition);
    this.renderedContentElement.addEventListener("pointerdown", this.handlePointerDown);
    this.renderedContentElement.addEventListener("pointerup", this.handlePointerUp);
  };

  private makeCamera = () => {
    // The linter does not understand how React defaultProps work
    // So to avoid using "!" all the time, we need to do this:
    const effectiveProps = { ...threeCanvasDefaultProps, ...this.props };

    const { physCameraX, physCameraY, physCameraZ, physFovWidth, nearLimit, farLimit } = effectiveProps;

    const physCameraDist = Math.sqrt(physCameraX * physCameraX + physCameraY * physCameraY + physCameraZ * physCameraZ);
    const sinFovWidth = physFovWidth / physCameraDist;
    const fovWidthInDegrees = (Math.asin(sinFovWidth) * 180) / Math.PI;

    const frustumAspectRatio = effectiveProps.width / effectiveProps.height;

    this.camera = new THREE.PerspectiveCamera(fovWidthInDegrees, frustumAspectRatio, nearLimit, farLimit);
    this.camera.position.x = physCameraX;
    this.camera.position.y = physCameraY;
    this.camera.position.z = physCameraZ;
    this.camera.lookAt(0, 0, 0);
  };

  private makeScene = () => {
    this.scene = new THREE.Scene();
  };

  private makeOrbitControls = () =>
    (this.orbitControls = new MyOrbitControls(this.camera, this.renderedContentElement, orbitControlProps));

  private addGlobe = ({ opacity, renderOrder, clearDepth }) => {
    const { globeRadius, globeHeightSegments, globeWidthSegments } = this.props;
    const { globeColor, globeWireframeColor } = this.props;
    const sphereGeometry = new THREE.SphereGeometry(globeRadius, globeWidthSegments, globeHeightSegments);
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: globeColor, transparent: true, opacity });
    const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphereMesh.name = clearDepth ? "clear" : "Globe sphere";
    sphereMesh.renderOrder = renderOrder;
    sphereMesh.onBeforeRender = renderer => (clearDepth ? renderer.clearDepth() : null);
    this.scene.add(sphereMesh);
    if (!clearDepth) {
      const sphereWireframeGeometry = new THREE.WireframeGeometry(sphereGeometry);
      const lineMaterial = new THREE.LineBasicMaterial({ color: globeWireframeColor });
      const lineSegments = new THREE.LineSegments(sphereWireframeGeometry, lineMaterial);
      lineSegments.name = "Globe wireframe";
      this.scene.add(lineSegments);
    }
  };

  // Picking is done every render(), using the position recorded here
  private setPickPositionOnMouseEvent = event => {
    const { normalizedX, normalizedY } = this.normalizeMousePosition(event);
    this.normalizedPickPosition.x = normalizedX;
    this.normalizedPickPosition.y = normalizedY; // note we flip Y
  };

  private handleClick = event => {
    this.clickEvent = event;
    this.setPickPositionOnMouseEvent(event);
    this.onClickPicker.pick(this.normalizedPickPosition, this.scene, this.camera);
  };

  handleClickPickHelper = (intersection: THREE.Intersection) => {
    if (this.props.handleOnClick) this.props.handleOnClick(this.clickEvent, intersection);
  };

  private handlePointerDown = event => {
    if (event.shiftKey) {
      this.rectangleSelectionStartPosition = this.normalizeMousePosition(event);
      this.initializeRectangleOverlay(event);
      this.rectangleSelectionInProgress = true;
    } else {
      this.pointerIsDownWithoutShift = true;
    }
  };

  private handlePointerMove = event => {
    this.setPickPositionOnMouseEvent(event);
    shutdownEvent(event);
    if (!this.rectangleSelectionInProgress) {
      return;
    }
    this.updateRectangleOverlay(event);
    this.rectangleSelectionEndPosition = this.normalizeMousePosition(event);
    this.updateProtoSelectedObservations();
  };

  private handlePointerUp = event => {
    shutdownEvent(event);
    this.pointerIsDownWithoutShift = false;
    if (!this.rectangleSelectionInProgress) {
      return;
    }
    this.rectangleSelectionEndPosition = this.normalizeMousePosition(event);
    this.removeRectangleOverlay();
    this.updateProtoSelectedObservations();
    const onCanvasObservations = [...threeStore.three.onCanvasObservations.values()];
    const protoSelectedFiles = onCanvasObservations
      .filter(observation => observation.file!.protoSelected)
      .map(observation => observation.file);
    const { onlyShiftKey, cmdShiftKey, shiftAltKey, cmdShiftAltKey } = macKeys(event);
    if (onlyShiftKey) {
      resultsStore.addToSelection(protoSelectedFiles as File[]);
    }
    if (cmdShiftKey) {
      resultsStore.removeFromSelection(protoSelectedFiles as File[]);
    }
    if (shiftAltKey) {
      protoSelectedFiles.forEach(file => file?.setFileStatus("hidden"));
    }
    if (cmdShiftAltKey) {
      protoSelectedFiles.forEach(file => file?.setFileStatus("trash"));
    }
    protoSelectedFiles.forEach(file => (file!.protoSelected = false));
    this.rectangleSelectionInProgress = false;
  };

  private clearPickPosition = () => {
    this.normalizedPickPosition.x = null;
    this.normalizedPickPosition.y = null;
  };

  private clearProtoselecions = () => {
    const onCanvasObservations = [...threeStore.three.onCanvasObservations.values()];
    onCanvasObservations.forEach(observation => (observation.file!.protoSelected = false));
  };

  private cancelRectangleSelection = () => {
    this.rectangleSelectionInProgress = false;
    this.clearPickPosition();
    this.clearProtoselecions();
    this.removeRectangleOverlay();
  };

  private handleMouseOut = event => {
    this.pointerIsDownWithoutShift = false;
    this.cancelRectangleSelection();
  };

  private handleKeyUp = event => {
    if (event.key === "Shift" && event.shiftKey === false) {
      this.cancelRectangleSelection();
    }
  };

  animateTimerCount = 0;

  private animate = () => {
    if (!this.pointerIsDownWithoutShift) {
      this.mouseMovePicker.pick(this.normalizedPickPosition, this.scene, this.camera);
    }
    if (threeStore.displayDateMsecHasChanged) {
      threeStore.commitNextAnimationFrameDisplayDateMsec();
    }
    if (this.animateTimerCount === 0) {
      console.time("animate");
    }
    this.renderer.render(this.scene, this.camera);
    if (this.animateTimerCount++ === 10000) {
      MyLogger.blue("Time passed for 10000 frames:");
      console.timeEnd("animate");
      this.animateTimerCount = 0;
    }
    setTimeout(() => requestAnimationFrame(this.animate), 1000 / 60);
  };

  private normalizeMousePosition = event => {
    const rect = this.renderedContentElement.getBoundingClientRect();
    const canvasX = event.clientX - rect.left;
    const canvasY = event.clientY - rect.top;
    const normalizedX = (canvasX / this.renderedContentElement.clientWidth) * 2 - 1;
    const normalizedY = (canvasY / this.renderedContentElement.clientHeight) * -2 + 1; // note we flip Y
    return { normalizedX, normalizedY };
  };

  private pointInsideSelection = (point, rectangleStart, rectangleEnd) => {
    const minX = Math.min(rectangleStart.normalizedX, rectangleEnd.normalizedX);
    const maxX = Math.max(rectangleStart.normalizedX, rectangleEnd.normalizedX);
    const minY = Math.min(rectangleStart.normalizedY, rectangleEnd.normalizedY);
    const maxY = Math.max(rectangleStart.normalizedY, rectangleEnd.normalizedY);
    return point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY;
  };

  private vectorToScreenNormalizedPosition(inputVector, camera) {
    inputVector.project(camera);
    return {
      x: inputVector.x,
      y: inputVector.y
    };
  }

  private updateProtoSelectedObservations = () => {
    if (!this.rectangleSelectionInProgress) {
      return;
    }
    const visibleObservationsMap = threeStore.three.onCanvasObservations;
    visibleObservationsMap.forEach(observation => {
      const centerOfGravityVertex = observation.currentCenterOfGravityVertex();
      const centerOfGravity = new THREE.Vector3(
        centerOfGravityVertex[0],
        centerOfGravityVertex[1],
        centerOfGravityVertex[2]
      );
      const cameraToCenterOfGravityDistance = this.camera.position.distanceTo(centerOfGravity);
      const cameraToSunCenterDistance = this.camera.position.distanceTo(new THREE.Vector3(0, 0, 0));
      const centerOfGravityIsVisible = cameraToCenterOfGravityDistance < cameraToSunCenterDistance;
      const observationTo2D = this.vectorToScreenNormalizedPosition(centerOfGravity, this.camera);
      const isInside = this.pointInsideSelection(
        observationTo2D,
        this.rectangleSelectionStartPosition,
        this.rectangleSelectionEndPosition
      );
      observation.file!.protoSelected = isInside && centerOfGravityIsVisible;
    });
  };

  private createRectangleOverlay = () => {
    this.rectangleSelectionOverlayElement = document.createElement("div");
    this.rectangleSelectionOverlayElement.classList.add("selectBox");
    this.rectangleSelectionOverlayElement.style.pointerEvents = "none";
  };

  private initializeRectangleOverlay = event => {
    this.rectangleSelectionOverlayElement.style.display = "none";
    this.renderer.domElement.parentElement!.appendChild(this.rectangleSelectionOverlayElement);
    this.rectangleSelectionOverlayElement.style.left = event.clientX + "px";
    this.rectangleSelectionOverlayElement.style.top = event.clientY + "px";
    this.rectangleSelectionOverlayElement.style.width = "0px";
    this.rectangleSelectionOverlayElement.style.height = "0px";
    this.rectangleSelectionOverlayStartPoint.x = event.clientX;
    this.rectangleSelectionOverlayStartPoint.y = event.clientY;
  };

  private updateRectangleOverlay = event => {
    this.rectangleSelectionOverlayElement.style.display = "block";
    const overlayBottomRightX = Math.max(this.rectangleSelectionOverlayStartPoint.x, event.clientX);
    const overlayBottomRightY = Math.max(this.rectangleSelectionOverlayStartPoint.y, event.clientY);
    const overlayTopLeftX = Math.min(this.rectangleSelectionOverlayStartPoint.x, event.clientX);
    const overlayTopLeftY = Math.min(this.rectangleSelectionOverlayStartPoint.y, event.clientY);
    this.rectangleSelectionOverlayElement.style.left = overlayTopLeftX + "px";
    this.rectangleSelectionOverlayElement.style.top = overlayTopLeftY + "px";
    this.rectangleSelectionOverlayElement.style.width = overlayBottomRightX - overlayTopLeftX + "px";
    this.rectangleSelectionOverlayElement.style.height = overlayBottomRightY - overlayTopLeftY + "px";
  };

  private removeRectangleOverlay = () => {
    if (this.rectangleSelectionOverlayElement.parentElement) {
      this.rectangleSelectionOverlayElement.parentElement.removeChild(this.rectangleSelectionOverlayElement);
    }
  };

  mountCounter = 0;
  componentDidMount() {
    if (this.mountCounter++ === 0) {
      this.makeCamera();
      this.makeRenderer();
      this.makeOrbitControls();
      this.makeScene();
      this.addGlobe({ renderOrder: GLOBE_RENDER_ORDER, opacity: 0.9, clearDepth: GLOBE_CLEAR_DEPTH });
      this.addGlobe({ renderOrder: GLOBE2_RENDER_ORDER, opacity: 0.000001, clearDepth: GLOBE2_CLEAR_DEPTH });
      this.scene.add(new Axes(this.props).group);
      this.animate();
      this.createRectangleOverlay();
      appStore.globalKeyUpHandlers.push({ id: this.id, function: this.handleKeyUp });
    }
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
  }

  render() {
    const { className } = this.props;
    const containerDivId = "ThreeSunContainerDiv";
    return (
      <div id={containerDivId} className={className}>
        <div id="guiDiv"></div>
      </div>
    );
  }
}

export default ThreeCanvas;
