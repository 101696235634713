import React, { CSSProperties } from "react";
import { action, configure, makeObservable } from "mobx";
import { observer } from "mobx-react";

import ThreeCanvas, { ThreeCanvasProps } from "./components/ThreeCanvas/ThreeCanvas";
import ThreeObservation from "./components/ThreeObservation/ThreeObservation";
import { RSUN, DSUN_EARTH, DAYS_TO_MILLISECONDS } from "./utilities/three-constants";
import { OBSERVATION_PIXELSIZE, BACKGROUND_PIXELSIZE } from "./utilities/three-constants";
import { formatIsoDateToZuluTime } from "./utilities/conversions";
import layerManager from "./LayerManager";

import Controls from "./components/Controls/Controls";
import Info from "./components/Info/Info";
import File from "../../classes/File";
import resultsStore from "../../Stores/ResultsStore";
import threeStore from "../../Stores/ThreeStore";
import { MyLogger, macKeys, shutdownEvent } from "../../Utilities/Utilities";
import { DEG2RAD } from "three/src/math/MathUtils";
import appStore from "../../Stores/AppStore";
import searchStore from "../../Stores/SearchStore";
import ephemeris from "../../classes/Ephemeris";

configure({ enforceActions: "always" });

const threeCanvasProps: ThreeCanvasProps = {
  physCameraX: 0.0 * DSUN_EARTH,
  physCameraY: 0.0 * DSUN_EARTH,
  physCameraZ: 0.9 * DSUN_EARTH,
  physFovWidth: 3 * RSUN,
  width: 800,
  height: 800
};

const contextMenuLabels = {
  centre: "Centre view on observer (C)",
  pinTime: "Pin/unpin time (1)",
  saveSelected: "Save selected files to cart (Cmd-S)",
  altSaveSelected: "Unsave selected files (Alt-Cmd-S)",
  hideSelected: "Hide(remove) selected files (Cmd-H/R)",
  altHideSelected: "Unhide selected files (Alt-Cmd-H/R)",
  trashSelected: "Delete selected files (Cmd-D / Cmd-X)",
  altTrashSelected: "Restore selected files (Alt-Cmd-D / Alt-Cmd-X)",
  selectAll: "Select/unselect all (Cmd-A)",
  downloadSelected: "Download(get) selected (Cmd-G)"
};

const contextMenuLabelsNoHovered = {
  saveHovered: "",
  altSaveHovered: "",
  hideHovered: "",
  altHideHovered: "",
  trashHovered: "",
  altTrashHovered: ""
};

@observer
class Three extends React.Component {
  public onCanvasObservations = new Map() as Map<File, ThreeObservation>;
  public observationsInBuildPhase = new Set() as Set<File>;

  public threeCanvas: ThreeCanvas;

  private id = "three";
  private backgroundCache = new Map();
  private backgroundsInBuildPhase = new Set();
  private cachedObservations = new Map() as Map<File, ThreeObservation>;
  private testObservations = new Map();
  private currentBackgroundUrl = "";

  // resizing private props
  private canvasAspectRatio = 1; //width / height
  private canvasWidth;
  private canvasHeight;
  private currentCanvasWidth;
  private currentCanvasHeight;
  private resizerMouseX;
  private resizerMouseY;
  private resizingIsActive = false;
  private currrentSelectorsDivHeight;

  currentBackgroundObservation: ThreeObservation;
  lastIntersectedObservationsSet = new Set<ThreeObservation>();

  constructor(props) {
    super(props);
    this.generateCornerControlsMenuDeclarations();
    makeObservable(this);
  }

  private controlsMenuSetInstrumentVisibility = (instrument, status) => {
    MyLogger.blue("setInstrumentVisibility", instrument, status);
  };

  private controlsMenuSetTimeShift = (timeShiftDays: number) => {
    const timeShiftMsec = timeShiftDays * 24 * 60 * 60 * 1000;
    threeStore.storeNextAnimationFrameDisplayDateMsec(threeStore.timeShiftReferenceDateMsec + timeShiftMsec, {
      keepReferenceTime: true
    });
  };

  private controlsMenuSetTimeSpanForCanvas = (timeSpanHours: number) => {
    resultsStore.setTimeSpanForCanvas(1000 * 60 * 60 * timeSpanHours);
  };

  availableViewPoints = ["EARTH", "SOLO"];
  currentViewPoint = "EARTH";

  private setViewpoint = viewpoint => {
    this.currentViewPoint = viewpoint;
    this.threeCanvas.orbitControls.reset();
    const date = new Date(threeStore.effectiveDisplayDateMsec).toISOString();
    const { hgln, hglt } = ephemeris.lookup(date, viewpoint);
    const phi = (90 - hglt) * DEG2RAD;
    const theta = hgln * DEG2RAD;
    this.threeCanvas.orbitControls.setFromRPhiTheta(0.9 * DSUN_EARTH, phi, theta);
  };

  private controlsMenuSetBackground = background => {
    console.log("useBackground: " + background);
  };

  upperLeftControlsMenuDeclaration;
  upperRightControlsMenuDeclaration;
  lowerLeftControlsMenuDeclaration;
  backgroundControlsMenuDeclaration;
  generateCornerControlsMenuDeclarations = () => {
    this.upperLeftControlsMenuDeclaration = {
      // 0: { textOpen: "Testing controls", textClose: "Close testing controls", open: true },
      0: { textOpen: " ", textClose: " ", open: true },
      1: { closeOnTop: false, left: "0px", top: "20px", width: 330 },
      // "Time shift": { value: 0, minMaxStep: [-26, 26, 0.1], callback: this.controlsMenuSetTimeShift },
      "Display time span [±h]": {
        value: resultsStore.timeSpanForCanvas / 3600000,
        minMaxStep: [0, 100, 1],
        callback: this.controlsMenuSetTimeSpanForCanvas
      }
    };

    this.upperRightControlsMenuDeclaration = {
      0: { textOpen: " ", textClose: " ", open: true },
      1: { closeOnTop: false, right: "0px", top: "0px", width: 140 },
      "View from Earth": { callback: () => this.setViewpoint("EARTH") },
      "View from Solar Orbiter": { callback: () => this.setViewpoint("SOLO") }
      // Visibility: [
      //   {
      //     Corona: [
      //       {
      //         EIS: { value: true, callback: v => this.controlsMenuSetInstrumentVisibility("EIS.corona", v) },
      //         XRT: { value: true, callback: v => this.controlsMenuSetInstrumentVisibility("XRT.corona", v) },
      //         "SOT/SP": { value: true, callback: v => this.controlsMenuSetInstrumentVisibility("SOT/SP.corona", v) }
      //       }
      //     ],
      //     Surface: [
      //       {
      //         EIS: { value: true, callback: v => this.controlsMenuSetInstrumentVisibility("EIS.corona", v) },
      //         XRT: { value: true, callback: v => this.controlsMenuSetInstrumentVisibility("XRT.corona", v) },
      //         "SOT/SP": { value: true, callback: v => this.controlsMenuSetInstrumentVisibility("SOT/SP.corona", v) }
      //       }
      //     ]
      //   }
      // ]
    };

    this.lowerLeftControlsMenuDeclaration = {
      0: { textOpen: "LOWER LEFT CONTROLS", textClose: "Close lower left controls", open: false },
      1: { closeOnTop: true, left: "0px", bottom: "0px", width: 200 },
      "Start Playing": { callback: () => this.playTime() },
      "Stop Playing": { callback: () => clearTimeout(this.playTimeOut) }
    };

    this.backgroundControlsMenuDeclaration = {
      0: { textOpen: "Backgrounds", textClose: "Close backgrounds menu", open: false },
      1: { closeOnTop: true, right: "0px", bottom: "0px", width: 200 },
      "AIA 193 (EIT 195)": { value: false, callback: () => this.controlsMenuSetBackground("AIA") }
    };
  };

  private threeObservationPropsFromFile = (file: File) => ({
    "DATE-BEG": file.columnValuesByName["DATE-BEG"],
    FOVX: parseFloat(file.columnValuesByName.fovx_best),
    FOVY: parseFloat(file.columnValuesByName.fovy_best),
    XCEN: parseFloat(file.columnValuesByName.xcen_best),
    YCEN: parseFloat(file.columnValuesByName.ycen_best),
    CROTA: parseFloat(file.columnValuesByName["CROTA"]),
    DSUN_OBS: parseFloat(file.columnValuesByName["DSUN_OBS"]),
    HGLN_OBS: parseFloat(file.columnValuesByName["HGLN_OBS"]),
    HGLT_OBS: parseFloat(file.columnValuesByName["HGLT_OBS"]),
    NAME: file.id,
    URL: file.fovAndImagesData.onHoverImageUrl
  });

  private async buildObservation(file: File) {
    const obsPropsFromFile = this.threeObservationPropsFromFile(file);
    const observationPromise = ThreeObservation.observationFactory(
      { ...obsPropsFromFile, pixelSize: OBSERVATION_PIXELSIZE },
      this.threeCanvas.scene,
      file
    );
    const observation = (await observationPromise) as ThreeObservation;
    return observation;
  }

  public supplyObservation = async (file: File): Promise<ThreeObservation | "Already on stage" | "In progress"> => {
    if (this.onCanvasObservations.has(file)) {
      return "Already on stage";
    }
    if (this.observationsInBuildPhase.has(file)) {
      return "In progress";
    }
    if (this.cachedObservations.has(file)) {
      return this.cachedObservations.get(file)!;
    }
    this.observationsInBuildPhase.add(file);
    const observation = await this.buildObservation(file);
    this.cachedObservations.set(file, observation);
    this.observationsInBuildPhase.delete(file);
    return observation;
  };

  public updateCanvasVisuals = () => {
    const temporarilyShowAllImages = appStore.cmdKey && appStore.altKey;
    const temporarilyShowHoveredImage = appStore.cmdKey;
    this.onCanvasObservations.forEach(observation => {
      let showImage = observation.file!.selected;
      showImage ||= temporarilyShowAllImages;
      showImage ||=
        temporarilyShowHoveredImage && (observation.file!.fileIsHovered || observation.file!.selectorIsHovered);
      if (showImage) {
        observation.showImages(observation.file!.selectionImageUrl);
      } else {
        observation.hideImages();
      }
      observation.setBorderHovered(observation.file!.isHovered);
    });
  };

  public addObservationToCanvasByFile = async (file: File) => {
    const observation = await this.supplyObservation(file);
    if (observation === "Already on stage" || observation === "In progress") {
      return;
    }
    if (this.onCanvasObservations.size === 0) {
      const obsPropsFromFile = this.threeObservationPropsFromFile(file);
      const fileDateMsec = new Date(obsPropsFromFile["DATE-BEG"]).getTime();
      threeStore.storeNextAnimationFrameDisplayDateMsec(fileDateMsec);
    } else {
      observation.setObservationDisplayDateMsec(threeStore.effectiveDisplayDateMsec);
    }
    observation.setBorderVisibility(true);
    observation.setCoronaVisibility(false);
    observation.setSurfaceVisibility(false);
    this.onCanvasObservations.set(file, observation);
    this.updateCanvasVisuals();
  };

  // TODO: removeObservationFromScene
  public removeObservationFromCanvasByFile = (file: File) => {
    const observation = this.onCanvasObservations.get(file);
    if (observation) {
      observation.setBorderVisibility(false);
      observation.setCoronaVisibility(false);
      observation.setSurfaceVisibility(false);
      this.onCanvasObservations.delete(file);
    }
  };

  public showImageByFile = (file: File, imageUrl: string) => {
    const observation = this.onCanvasObservations.get(file);
    if (observation) {
      observation.showImages(imageUrl);
    }
  };

  public hideImageByFile = (file: File) => {
    const observation = this.onCanvasObservations.get(file);
    if (observation) {
      observation.hideImages();
    }
  };

  private handleOnContextMenu = ev => {
    shutdownEvent(ev);
    appStore.setContextMenuData(
      ev.clientX,
      ev.clientY,
      resultsStore.computeResultsForThree,
      resultsStore.computeHoveredObservations
    );
    const noHoveredObservations = resultsStore.computeHoveredObservations.length === 0;
    appStore.contextMenuLabels = noHoveredObservations
      ? { ...contextMenuLabels, ...contextMenuLabelsNoHovered }
      : contextMenuLabels;
    appStore.showContextMenu();
  };

  private switchToExistingBackground = (newBackgroundUrl: string) => {
    if (this.currentBackgroundObservation) {
      this.currentBackgroundObservation.setBorderVisibility(false);
      this.currentBackgroundObservation.setSurfaceVisibility(false);
      this.currentBackgroundObservation.setCoronaVisibility(false);
    }
    // TODO: current corona/surface visibility status should be stored somewhere
    // this.currentBackgroundObservation.setCoronaVisibility(true);
    // this.currentBackgroundObservation.setSurfaceVisibility(true);
    const newBackgroundObservation = this.backgroundCache.get(newBackgroundUrl);
    this.displayBackground(newBackgroundObservation, newBackgroundUrl);
    this.currentBackgroundUrl = newBackgroundUrl;
  };

  public setBackground = async fovBackgroundUrl => {
    if (!fovBackgroundUrl || this.currentBackgroundUrl === fovBackgroundUrl) {
      return;
    }
    this.currentBackgroundUrl = fovBackgroundUrl;
    const [, year, month, day] = /(\d\d\d\d)\/(\d\d)\/(\d\d)/.exec(fovBackgroundUrl)!;
    const dateFromPath = `${year}-${month}-${day}T12:00:00Z`;
    if (this.backgroundCache.has(fovBackgroundUrl)) {
      this.switchToExistingBackground(fovBackgroundUrl);
      threeStore.storeNextAnimationFrameDisplayDateMsec(new Date(dateFromPath).getTime());
      return;
    }
    this.createBackgroundAndDisplayIfNeeded(fovBackgroundUrl);
  };

  public preloadBackgroundImage = (fovBackgroundUrl: string) => {
    this.createBackgroundAndDisplayIfNeeded(fovBackgroundUrl);
  };

  public createBackgroundAndDisplayIfNeeded = async (fovBackgroundUrl: string) => {
    if (this.backgroundCache.has(fovBackgroundUrl) || this.backgroundsInBuildPhase.has(fovBackgroundUrl)) {
      return;
    }
    this.backgroundsInBuildPhase.add(fovBackgroundUrl);
    const [, year, month, day] = /(\d\d\d\d)\/(\d\d)\/(\d\d)/.exec(fovBackgroundUrl)!;
    const dateFromPath = `${year}-${month}-${day}T12:00:00Z`;
    const jsonUrl = fovBackgroundUrl.replace(/th-/, "info-").replace(/-[0-9]*\.png$/, ".json");
    const props = {
      "DATE-BEG": dateFromPath,
      HGLT_OBS: 0,
      HGLN_OBS: 0.0,
      jsonUrl: jsonUrl,
      pixelSize: BACKGROUND_PIXELSIZE,
      backgroundImageUrl: fovBackgroundUrl,
      isBackground: true,
      NAME: `Background (${year}/${month}/${day})`
    };
    const backgroundObservationPromise = ThreeObservation.observationFactory(props, this.threeCanvas.scene, null);
    const backgroundObservation = (await backgroundObservationPromise) as ThreeObservation;
    this.backgroundsInBuildPhase.delete(fovBackgroundUrl);
    this.backgroundCache.set(fovBackgroundUrl, backgroundObservation);
    if (this.currentBackgroundUrl === fovBackgroundUrl) {
      this.displayBackground(backgroundObservation, fovBackgroundUrl);
    }
    return backgroundObservation;
  };

  public displayBackground = (backgroundObservation, fovBackgroundUrl) => {
    const [, year, month, day] = /(\d\d\d\d)\/(\d\d)\/(\d\d)/.exec(fovBackgroundUrl)!;
    const dateFromPath = `${year}-${month}-${day}T12:00:00Z`;
    backgroundObservation.setCoronaVisibility(true);
    backgroundObservation.setSurfaceVisibility(true);
    backgroundObservation.setBorderVisibility(false);
    threeStore.storeNextAnimationFrameDisplayDateMsec(new Date(dateFromPath).getTime());
    if (this.currentBackgroundObservation) {
      this.currentBackgroundObservation.setBorderVisibility(false);
      this.currentBackgroundObservation.setSurfaceVisibility(false);
      this.currentBackgroundObservation.setCoronaVisibility(false);
    }
    this.currentBackgroundObservation = backgroundObservation;
  };

  private effectiveIntersectedObservations = (intersectedThings: any[]) => {
    const visibleThings = intersectedThings.filter(thing => thing.object.visible);
    // Leave only things in *front* of globe, from a list of things in increasing distance order
    const globeIndex = visibleThings.findIndex(thing => thing.object.name?.includes("Globe"));
    let outsideGlobeThings = globeIndex === -1 ? visibleThings : visibleThings.slice(0, globeIndex);
    const intersectedBorderThings = outsideGlobeThings.filter(thing => thing.object.name?.includes("border"));
    if (intersectedBorderThings.length > 0) {
      outsideGlobeThings = intersectedBorderThings;
    }
    const threeObservationThings = outsideGlobeThings.filter(thing => thing.object.userData.threeObservation);
    const threeObservations = threeObservationThings.map(thing => thing.object.userData.threeObservation);
    const uniqueThreeObservations = [...new Set(threeObservations)] as ThreeObservation[];
    const observations = uniqueThreeObservations.filter(observation => observation.file);
    return observations;
  };

  private setDisplayDate = isoDate => {
    const isoDateZuluTime = formatIsoDateToZuluTime(isoDate);
    const zuluTimeMsec = Date.parse(isoDateZuluTime);
    threeStore.storeNextAnimationFrameDisplayDateMsec(zuluTimeMsec);
    threeStore.commitNextAnimationFrameDisplayDateMsec();
  };

  private toggleHoveredObservationImageVisibilityWhenCmdPressed = observation => {
    if (!resultsStore.cmdKeyTemporarilyShowImageIsActive || observation.file!.selected) {
      return;
    }
    if (observation.file!.mouseIsOverFile) {
      this.showImageByFile(observation.file, observation.file.selectionImageUrl);
    } else {
      this.hideImageByFile(observation.file);
    }
  };

  private handleOnMouseMove = intersectedThings => {
    const intersectedObservationsSet = new Set(this.effectiveIntersectedObservations(intersectedThings));
    const potentiallyUpdatedObservations = [...intersectedObservationsSet, ...this.lastIntersectedObservationsSet];
    potentiallyUpdatedObservations.forEach(observation => {
      const mouseIsOverFile = intersectedObservationsSet.has(observation);
      // Avoid unnecessary assignments to avoid unnecessary reactions/recomputations
      if (observation.file!.fileIsHovered !== mouseIsOverFile && !appStore.contextMenuIsActive) {
        observation.file!.fileIsHovered = mouseIsOverFile;
        this.toggleHoveredObservationImageVisibilityWhenCmdPressed(observation);
      }
    });
    this.lastIntersectedObservationsSet = intersectedObservationsSet;
  };

  private handleOnClick = (ev, intersectThings) => {
    const intersectedObservations = this.effectiveIntersectedObservations(intersectThings);
    const intersectedObservationsWithFiles = intersectedObservations.filter(observation => observation.file);
    if (intersectedObservationsWithFiles.length === 0) {
      return;
    }
    const intersectedFiles = intersectedObservationsWithFiles.map(observation => observation.file!);
    const topFile = intersectedObservationsWithFiles[0].file!;
    if (!topFile) {
      return;
    }
    console.log("ThreeObservation clicked:,", topFile.id);
    const { onlyCmdKey, onlyShiftKey, noMacKeys } = macKeys(ev);
    if (onlyCmdKey) {
      intersectedFiles.forEach(file => {
        if (file.selected) {
          resultsStore.removeFromSelection([file]);
        } else {
          resultsStore.addToSelection([file]);
        }
      });
      return;
    }
    if (noMacKeys) {
      intersectedFiles.forEach(file => {
        if (file.selected) {
          layerManager.pullToFrontByFile(file);
        } else {
          resultsStore.addToSelection([file]);
        }
      });
      resultsStore.scrollToFile(topFile);
      resultsStore.flashFile(topFile);
      return;
    }
    if (onlyShiftKey) {
      intersectedFiles.forEach(file => {
        layerManager.pushToBackByFile(topFile);
      });
    }
  };

  updateAllDisplayDates = () => {
    const allObservations = [
      ...this.testObservations.values(),
      ...this.onCanvasObservations.values()
    ] as ThreeObservation[];
    allObservations.forEach(observation =>
      observation.setObservationDisplayDateMsec(threeStore.effectiveDisplayDateMsec)
    );
    this.currentBackgroundObservation?.setObservationDisplayDateMsec(threeStore.effectiveDisplayDateMsec);
  };

  private handleResizeOnMouseDown = ev => {
    this.resizingIsActive = true;
    this.resizerMouseX = ev.clientX;
    this.resizerMouseY = ev.clientY;
    const canvasElement = document.getElementById("ThreeSunContainerDiv");
    const selectorsDivelement = document.getElementById("selectors");
    if (!canvasElement || !selectorsDivelement) {
      return;
    }
    const canvasStyles = window.getComputedStyle(canvasElement);
    this.canvasWidth = this.currentCanvasWidth = parseInt(canvasStyles.width, 10);
    this.canvasHeight = this.currentCanvasHeight = parseInt(canvasStyles.height, 10);
    const selectorsDivStyles = window.getComputedStyle(selectorsDivelement);
    searchStore.selectorsDivHeight = this.currrentSelectorsDivHeight = parseInt(selectorsDivStyles.height, 10);
    appStore.globalOnMouseUpHandlers.push({ id: this.id, function: this.handleResizeOnMouseUp });
    appStore.globalOnMouseMoveHandlers.push({ id: this.id, function: this.handleResizeOnMouseMove });
    appStore.globalOnMouseLeaveHandlers.push({ id: this.id, function: this.handleResizeOnMouseLeave });
    appStore.disableSelection();
  };

  private handleResizeOnMouseLeave = ev => this.handleResizeOnMouseUp(ev);

  private handleResizeOnMouseUp = ev => {
    this.resizingIsActive = false;
    appStore.removeGlobalHandlers(this.id);
    appStore.enableSelection();
    this.canvasHeight = this.currentCanvasHeight;
    this.canvasWidth = this.currentCanvasWidth;
    searchStore.searchSectionWidth = this.currentCanvasWidth;
    searchStore.selectorsDivHeight = this.currrentSelectorsDivHeight;
  };

  private handleResizeOnMouseMove = ev => {
    if (!this.resizingIsActive) {
      return;
    }
    const dx = ev.clientX - this.resizerMouseX;
    const dy = ev.clientY - this.resizerMouseY;

    const newWidth = this.canvasWidth + dx;
    if (newWidth > 460 && dx !== 0) {
      this.currentCanvasWidth = newWidth;
      this.currentCanvasHeight = newWidth * this.canvasAspectRatio;
      this.resizeCanvas(this.currentCanvasWidth, this.currentCanvasHeight);
      searchStore.setSearchSectionWidth(this.currentCanvasWidth);
    }
    const newHeight = searchStore.selectorsDivHeight + dy;
    this.currrentSelectorsDivHeight = newHeight;
    searchStore.setSelectorsDivHeight(newHeight);
  };

  private handleMouseEnter = () => appStore.mouseEnters("canvas");

  private handleMouseLeave = () => appStore.mouseLeaves("canvas");

  private resizeCanvas = (width, height) => {
    const canvasParentElement = document.getElementById("three-div");
    const canvasElement = document.getElementById("ThreeSunContainerDiv");
    if (!canvasParentElement || !canvasElement) {
      return;
    }
    canvasElement.style.width = `${width}px`;
    canvasParentElement.style.width = `${width}px`;
    canvasElement.style.height = `${height}px`;
    canvasParentElement.style.height = `${height}}px`;
    this.threeCanvas.renderer.setSize(this.currentCanvasWidth, this.currentCanvasHeight);
  };

  @action
  public toggleInstrumentVisibility = instrument => {
    threeStore.instrumentsVisibilityStatus.set(instrument, !threeStore.instrumentsVisibilityStatus.get(instrument));
    if (threeStore.instrumentsVisibilityStatus.get(instrument)) {
      this.showCorona(instrument);
    } else {
      this.hideCorona(instrument);
    }
  };

  @action
  setInstrumentsVisibilityStatus = (instrument, status) => {
    threeStore.instrumentsVisibilityStatus.set(instrument, status);
  };

  timeShift = (1 / 24) * 2; // 1 hour
  playTimeOut;
  playTime = () => {
    const { effectiveDisplayDateMsec: displayDateMsec } = threeStore;
    const newDisplayDateMsec = displayDateMsec + this.timeShift * DAYS_TO_MILLISECONDS;
    threeStore.storeNextAnimationFrameDisplayDateMsec(newDisplayDateMsec);
    threeStore.commitNextAnimationFrameDisplayDateMsec();
    this.playTimeOut = setTimeout(this.playTime, 1);
  };
  mountCount = 0;
  componentDidMount() {
    const asyncMountActions = async () => {};
    if (this.mountCount++ === 0) {
      threeStore.three = this; // resultsStore needs to talk to us
      setTimeout(asyncMountActions, 2000);
    }
  }

  // Stubs
  public jumpToViewPort = viewPort => {};
  public showCorona = instrument => {};
  public hideCorona = instrument => {};

  public flashObservationByFile = file => {
    const observation = this.onCanvasObservations.get(file);
    if (!observation) {
      return;
    }
    observation.flashBorder();
  };

  public centreViewOnObserverByFile = (file: File | null) => {
    console.log("centreViewOnObserverByFile", file);
    if (!file) {
      const viewPointIndex = this.availableViewPoints.indexOf(this.currentViewPoint);
      const nextViewPointIndex = (viewPointIndex + 1) % this.availableViewPoints.length;
      this.setViewpoint(this.availableViewPoints[nextViewPointIndex]);
      return;
    }
    const instrument = file.columnValuesByName.INSTRUME;
    if (instrument.match(/IRIS|EIS|XRT|SOT/)) {
      this.setViewpoint("EARTH");
    }
    if (instrument.match(/SPICE/)) {
      this.setViewpoint("SOLO");
    }
  };

  render() {
    const appStyle = { position: "relative" } as CSSProperties;
    const patchedThreeCanvasProps = {
      handleOnClick: this.handleOnClick,
      handleOnMouseMove: this.handleOnMouseMove,
      ...threeCanvasProps
    };
    const controllers = [
      this.upperLeftControlsMenuDeclaration,
      this.upperRightControlsMenuDeclaration,
      this.lowerLeftControlsMenuDeclaration,
      this.backgroundControlsMenuDeclaration
    ];
    return (
      <div
        id="three-div"
        className="canvas-resizeable"
        style={appStyle}
        onContextMenu={this.handleOnContextMenu}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="canvas-resizer canvas-resizer-r" onMouseDown={this.handleResizeOnMouseDown}></div>
        <div className="canvas-resizer canvas-resizer-t" onMouseDown={this.handleResizeOnMouseDown}></div>
        <Controls controllers={controllers} />
        <Info currentDateMsec={threeStore.effectiveDisplayDateMsec} />
        <ThreeCanvas ref={threeCanvas => (this.threeCanvas = threeCanvas!)} {...patchedThreeCanvasProps} />
        {/* <ThreeCanvasTest ref={threeCanvas => (this.threeCanvas = threeCanvas!)} {...patchedThreeCanvasProps} /> */}
      </div>
    );
  }

  // ****************************************************************************
}

export default Three;
