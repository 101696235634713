// CANVAS constants
export const BOXID_SELECTION_SHIELD = -100;
export const BOXID_LIMB = -99;
export const BOXID_SELECTION_RECTANGLE = -98;

export const CANVAS_WIDTH_IN_ARCSEC = 2000;
export const CANVAS_SIZE = 250;
export const BOUNDINGBOX_SIZE = 10 * CANVAS_SIZE;
export const VIEWPORT_SIZE = CANVAS_SIZE;

export const ZINDEX_RECT = 2;
export const ZINDEX_IMAGE = 1;
export const ZINDEX_LIMB = 0;

// RESULTSPAN constants
export const THUMBWIDTH_INITIAL_VALUE = -1;
export const FULLSIZE_INITIAL_VALUE = -1;

// SEARCHSPAN constants
export const INVALID = 0;
export const VALID = 1;
export const ORTHOGONAL_INVALID = 2;
export const SEARCHSPANWIDTH = 360;

export const BOGUS_ENUM_REGEXP = /^(IR__OBSID[1-9]?|IR__OBS_DESC|IR__DATA_SRC|IR__BLD_VERS|X__READPORT|STATUS)$/;

const USE_PROXY = true;
let USE_LOCAL_PROXY = true;

if (window.location.hostname !== "localhost") {
  USE_LOCAL_PROXY = false;
}

export const TRUE_DBSERVER = "http://astro-sdc-db.uio.no";
export const TRUE_FILESERVER = "http://astro-sdc-fs.uio.no";

export const DBSERVER_PROXY = USE_LOCAL_PROXY ? "http://localhost:8081" : "https://astro-sdc-db-proxy.utenforuio.no";
export const FILESERVER_PROXY = USE_LOCAL_PROXY ? "http://localhost:8082" : "https://astro-sdc-fs-proxy.utenforuio.no";

export const DBSERVER = USE_PROXY ? DBSERVER_PROXY : TRUE_DBSERVER;
export const FILESERVER = USE_PROXY ? FILESERVER_PROXY : TRUE_FILESERVER;
