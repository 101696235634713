import React from "react";
import { GUI } from "dat.gui";

export default class Controls extends React.Component<{ controllers }> {
  createAndHookGuiDiv = (guiObject: GUI, divName, left = "", right = "", top = "", bottom = "") => {
    const guiDiv = document.getElementById(divName)!;
    guiDiv.style.position = "absolute";
    guiDiv.style.left = left ?? ""; //! Nice use case for ?? operator!
    guiDiv.style.right = right ?? "";
    guiDiv.style.top = top ?? "";
    guiDiv.style.bottom = bottom ?? "";
    guiDiv.appendChild(guiObject.domElement);
  };

  leftMargin = 10;
  private buildSlider = (parent: GUI, name, data, depth) => {
    const [min, max, step] = data.minMaxStep;
    const c = parent.add(data, "value", min, max).step(step).name(name).onChange(data.callback);
    c.domElement.id = "Slider " + name + "-" + depth;
    c.domElement.previousElementSibling.style.marginLeft = depth * this.leftMargin + "px";
  };

  private buildCheckbox = (parent: GUI, name, data, depth) => {
    const c = parent.add(data, "value").name(name).onChange(data.callback);
    c.domElement.id = "Checkbox " + name + "-" + depth;
    c.domElement.previousElementSibling.style.marginLeft = depth * this.leftMargin + "px";
  };

  private buildSelection = (parent: GUI, name, data, depth) => {
    const c = parent.add(data, "value", data.sel).name(name).onChange(data.callback);
    c.domElement.id = "Selection " + name + "-" + depth;
    c.domElement.previousElementSibling.style.marginLeft = depth * this.leftMargin + "px";
  };

  private buildButton = (parent: GUI, name, data, depth) => {
    const c = parent.add(data, "callback").name(name);
    c.domElement.id = "Button " + name + "-" + depth;
    c.domElement.previousElementSibling.style.marginLeft = depth * this.leftMargin + 2 + "px";
  };

  private buildFolder = (parent: GUI, name, data, depth) => {
    const folder = parent.addFolder(name);
    folder.domElement.id = "Folder: " + name + "-" + depth;
    folder.domElement.style.marginLeft = depth * this.leftMargin + "px"; //CHECK
    if (data.open) {
      folder.open();
    }
    this.buildMenu(folder, data[0], depth + 1);
  };

  private buildMenu(gui, data, depth) {
    for (const name in data) {
      if (Array.isArray(data[name])) {
        this.buildFolder(gui, name, data[name], depth);
        continue;
      }
      if (data[name].minMaxStep) {
        this.buildSlider(gui, name, data[name], depth);
        continue;
      }
      if (typeof data[name].value === "boolean") {
        this.buildCheckbox(gui, name, data[name], depth);
        continue;
      }
      if (data[name].sel) {
        this.buildSelection(gui, name, data[name], depth);
        continue;
      }
      if (data[name].callback) {
        this.buildButton(gui, name, data[name], depth);
        continue;
      }
    }
  }

  private buildDatGui = guiData => {
    const allProps = { ...guiData[0], ...guiData[1] };
    const { divName, textOpen, textClose, closeOnTop, width, left, right, top, bottom } = allProps;
    const gui = new GUI({ autoPlace: false, width, closeOnTop });
    this.createAndHookGuiDiv(gui, divName, left, right, top, bottom);
    gui.textOpen = textOpen;
    gui.textClose = textClose;
    this.buildMenu(gui, guiData, 0);
    gui.open();
    gui.close();
    if (guiData[0].open) {
      gui.open();
    }
  };

  controls;
  mountCount = 0;
  componentDidMount() {
    if (this.mountCount++ === 0) {
      this.controls.forEach(controller => this.buildDatGui(controller));
    }
  }

  render() {
    this.controls = this.props.controllers.slice(); // Shallow copy is ok
    this.controls.forEach((control, index) => (control[0].divName = `gui${index}DivNew`));
    const controlDivs = this.controls.map((control, index) => <div key={index} id={control[0].divName}></div>);
    return <div id="controlDiv">{controlDivs}</div>;
  }
}
