import { action, configure, makeObservable, observable } from "mobx";
import { FILESERVER } from "../constants";
import resultsStore from "../Stores/ResultsStore";
import threeStore from "../Stores/ThreeStore";
import {
  convertSyntheticToRealColumnName,
  formatNumber,
  parseDate,
  yyyyMmDdPathFromString
} from "../Utilities/Utilities";
import ephemeris from "./Ephemeris";
import Selector from "./Selector";
configure({ enforceActions: "always" });

class File {
  lineAsObject;
  id;
  type;
  columnValuesByName;
  minMaxValuesByName;

  fovAndImagesData;
  lastAccessDate: number = Date.now();

  // For ui changes and deletions...
  // list of products that it is related to
  @observable selectors = new Map<number, Selector>();

  @observable private _selectionImageUrl = "";
  @observable private _mouseIsOverFile = false; // for hover effects
  @observable private _selected = false; // for selection effects
  @observable private _hidden = false; // Only on-canvas/off-canvas
  @observable private _inCart = false; // Only on-canvas/off-canvas
  @observable private _inTrash = false; // Only on-canvas/off-canvas
  @observable private _protoSelected = false; // hover effects
  @observable private _flashMeInResults = false;

  constructor(lineAsObject, callback) {
    makeObservable(this);
    this.lineAsObject = lineAsObject;
    this.getColumnValuesFromLineAsObject();
    this.type = this.columnValuesByName.INSTRUME;
    this.id = this.columnValuesByName.FILE.replace(".", "_");
    this.patchSyntheticColumnValuesIntoColumnValuesByName();
    this.makeFovAndImagesData();
    if (callback) {
      callback(this);
    }
  }

  @action
  addSelector = (selector: Selector) => {
    if (!this.selectors.has(selector.id)) {
      this.selectors.set(selector.id, selector);
    }
  };

  @action
  removeSelector = selectorToRemove => {
    this.selectors.delete(selectorToRemove.id);
  };

  fileState = () => {
    return {
      id: this.id,
      selected: this.selected,
      inCart: this.inCart,
      inTrash: this.inTrash,
      hidden: this.hidden
    };
  };

  // NOTE: If a file status is changed such that it's selection status
  // is the only thing that keeps it on the list, then the selection status
  // should be cleared. This makes it disappear from the list, which is
  // self-consistent. Or? No: has a strange feel to it if unmarked is not
  // shown and state is (SELECTED + CART), when removing CART it should not
  // disappear (thus selected should not be cleared)
  @action
  private modifyStatus = (statusName, modifier) => {
    const stateBefore = this.fileState();
    switch (statusName) {
      case "selected":
        this.selected = modifier(this.selected);
        if (this.selected) {
          this.inTrash = false;
        }
        break;
      case "cart":
        this.inCart = modifier(this.inCart);
        if (this.inCart) {
          this.inTrash = false;
        }
        break;
      case "trash":
        this.inTrash = modifier(this.inTrash);
        if (this.inTrash) {
          this.inCart = false;
          this.hidden = false;
          this.selected = false;
        }
        break;
      case "hidden":
        this.hidden = modifier(this.hidden);
        if (this.hidden) {
          this.inTrash = false;
        }
        break;
      default:
    }
    const stateAfter = this.fileState();
    resultsStore.addFileToHistory(stateBefore, stateAfter);
  };

  public setFileStatus = statusName => this.modifyStatus(statusName, () => true);

  public toggleFileStatus = statusName => this.modifyStatus(statusName, state => !state);

  public removeFileStatus = statusName => this.modifyStatus(statusName, () => false);

  public getFileStatus = statusName => {
    switch (statusName) {
      case "cart":
        return this.inCart;
      case "trash":
        return this.inTrash;
      case "hidden":
        return this.hidden;
      case "selected":
        return this.selected;
      default:
        return false;
    }
  };

  get someSelectorIsEdited() {
    const selectors = Array.from(this.selectors.values()) as Selector[];
    return selectors.some(selector => selector.computeCriteriaIsEdited);
  }

  parseMinValuesFromLineAsObject = line => {
    if (!line) {
      return;
    }
    const values = line.split("||||").map(value => value.trim());
    return values;
  };

  getColumnValuesFromLineAsObject = () => {
    const minMaxValuesByName = {};
    const maxValueByName = {};
    for (const fieldName in this.lineAsObject) {
      const value = Object();
      value.minValues = this.parseMinValuesFromLineAsObject(this.lineAsObject[fieldName].min);
      value.maxValue = this.lineAsObject[fieldName].max;
      minMaxValuesByName[fieldName] = value;
      maxValueByName[fieldName] = value.maxValue;
    }
    this.columnValuesByName = maxValueByName;
    this.minMaxValuesByName = minMaxValuesByName;
  };

  instrumentPath = () => {
    const instrumentToPath = {
      "IRIS/SJI": "iris",
      "IRIS/SPEC": "iris",
      XRT: "xrt",
      EIS: "eis",
      "SOT/NB": "sot",
      "SOT/WB": "sot",
      "SOT/SP": "sot"
    };
    return instrumentToPath[this.columnValuesByName.INSTRUME];
  };

  statusPath = () => {
    const statusToPath = {
      "Level 0": "level0",
      "Level 2": "level2",
      Quicklook: "level0"
    };
    const statusPath = statusToPath[this.columnValuesByName.STATUS];
    return statusPath;
  };

  imageUrlPrefix = (mode = "") => {
    const iconBaseUrl = `${FILESERVER}/vol/icons/`;
    const SOTPath =
      this.columnValuesByName.FILE.substr(2, 1) === "2"
        ? this.columnValuesByName.FILE.substr(0, 2)
        : this.columnValuesByName.FILE.substr(0, 4);
    const instrumentPath = this.instrumentPath();
    const statusPath = this.statusPath();
    const datePath = yyyyMmDdPathFromString(this.columnValuesByName.DATE_OBS);
    const presentationMode = mode.includes("SOT") ? SOTPath : "";
    const fileNamePrefix = mode === "SOT/SP" ? "sotsp_1d_" : "";
    const hourPath = this.columnValuesByName.HOURPATH;
    const fileName = this.columnValuesByName.FILE;
    return `${iconBaseUrl}${instrumentPath}/${statusPath}/${datePath}/${presentationMode}/${hourPath}/${fileName}/${fileNamePrefix}${fileName}`;
  };

  descriptionsAndUrlEndingsForBoxDisplay = thumbs => {
    const urlSufixes: any[] = [];
    thumbs.forEach(thumb => {
      const imagesInBox: any[] = [];
      imagesInBox.push({ urlEnding: `_${thumb.hint}_int_thumb.png`, description: thumb.description });
      imagesInBox.push({ urlEnding: `_${thumb.hint}_vel_thumb.png`, description: thumb.description });
      const imageIsAbsorbtion = thumb.hint.match(/([0-9]){1}_([0-9]){1}_([a-z])+([0-9])+/);
      if (!imageIsAbsorbtion) {
        imagesInBox.push({ urlEnding: `_${thumb.hint}_wid_thumb.png`, description: thumb.description });
      }
      urlSufixes.push(imagesInBox);
    });
    return urlSufixes;
  };

  imageUrlPrefixForInstrum = instrumentType => {
    switch (instrumentType) {
      case `SOT/NB`:
      case `SOT/WB`:
        return this.imageUrlPrefix("SOT");
      case `SOT/SP`:
        return this.imageUrlPrefix("SOT/SP");
      case `IRIS/SPEC`:
      default:
        return this.imageUrlPrefix();
    }
  };

  calculateImageUrlsForHint = () => {
    const sortedThumbs = this.sortedThumbs();
    const imageObjects: any[] = [];
    sortedThumbs.forEach(thumb => {
      const prefix = thumb.hint.split("_")[0];
      const prefixExists = imageObjects.find(sufix => sufix.url === `_${prefix}_thumb.png`);
      if (!prefixExists) {
        imageObjects.push({
          url: `${this.imageUrlPrefix(this.columnValuesByName.INSTRUME)}_${prefix}_thumb.png`,
          description: ""
        });
      }
      imageObjects.push({
        url: `${this.imageUrlPrefix(this.columnValuesByName.INSTRUME)}_${thumb.hint}_int_thumb.png`,
        description: thumb.description
      });
      imageObjects.push({
        url: `${this.imageUrlPrefix(this.columnValuesByName.INSTRUME)}_${thumb.hint}_vel_thumb.png`,
        description: thumb.description
      });
      const imageIsAbsorbtion = thumb.hint.match(/([0-9]){1}_([0-9]){1}_([a-z])+([0-9])+/);
      if (!imageIsAbsorbtion) {
        imageObjects.push({
          url: `${this.imageUrlPrefix(this.columnValuesByName.INSTRUME)}_${thumb.hint}_wid_thumb.png`,
          description: thumb.description
        });
      }
    });
    return imageObjects;
  };

  calculateImageUrlsForThumbNum = () => {
    const imageObjects: any[] = [];
    switch (this.columnValuesByName.INSTRUME) {
      case `IRIS/SPEC`:
        imageObjects.push({ url: `${this.imageUrlPrefix()}_0_thumb.png`, description: "" });
        break;
      case `SOT/NB`:
      case `SOT/WB`:
        for (let i = 0; i < this.columnValuesByName.THUMBS; i++) {
          imageObjects[i] = { url: `${this.imageUrlPrefix("SOT")}_${i}_thumb.png`, description: "" };
        }
        break;
      case `SOT/SP`:
        for (let i = 0; i < this.columnValuesByName.THUMBS; i++) {
          imageObjects[i] = { url: `${this.imageUrlPrefix("SOT/SP")}_${i}_thumb.png`, description: "" };
        }
        break;
      default:
        for (let i = 0; i < this.columnValuesByName.THUMBS; i++) {
          imageObjects[i] = { url: `${this.imageUrlPrefix()}_${i}_thumb.png`, description: "" };
        }
    }
    return imageObjects;
  };

  sortedThumbs = () => {
    const thumbHints = this.columnValuesByName.THUMB_HINTS.split("|").filter(hint => hint !== "");
    const thumbLabels = this.columnValuesByName.THUMB_LABELS.split("|");
    const separatorIndex = thumbLabels.indexOf("");
    const thumbHintsDescriptions = thumbLabels.slice(separatorIndex + 1);
    const thumbs = thumbHints.map((hint, index) => {
      const description = thumbHintsDescriptions[index] ? thumbHintsDescriptions[index] : "";
      return { hint: hint, description: description };
    });
    return thumbs.sort((a, b) => a.hint.localeCompare(b.hint));
  };

  boxImagesDataIfValidHints = () => {
    let imageUrls = [] as any[];
    const sortedThumbs = this.sortedThumbs();
    const thumbHintsArePresent = sortedThumbs.length > 0 ? true : false;
    const descriptionsAndUrlEndingsForBoxDisplay = this.descriptionsAndUrlEndingsForBoxDisplay(sortedThumbs);
    const hintsAreValid = sortedThumbs.every(element => element.hint.match(/^([0-9])+_([0-9])+/));
    if (thumbHintsArePresent && hintsAreValid) {
      descriptionsAndUrlEndingsForBoxDisplay.forEach(descriptionsAndUrlEndings => {
        const urlsInBox: any = [];
        descriptionsAndUrlEndings.forEach(descriptionAndUrlEnding => {
          urlsInBox.push({
            url: `${this.imageUrlPrefix(this.columnValuesByName.INSTRUME)}${descriptionAndUrlEnding.urlEnding}`,
            description: descriptionAndUrlEnding.description
          });
        });
        imageUrls.push(urlsInBox);
      });
      return imageUrls;
    }
    return;
  };

  imageObjects = () => {
    const sortedThumbs = this.sortedThumbs();
    const thumbHintsArePresent = sortedThumbs.length > 0 ? true : false;
    const hintsAreValid = this.sortedThumbs().every(element => element.hint.match(/^([0-9])+_([0-9])+/));
    if (thumbHintsArePresent && hintsAreValid) {
      return this.calculateImageUrlsForHint();
    }
    if (this.columnValuesByName.THUMBS > 0) {
      return this.calculateImageUrlsForThumbNum();
    }
    return [];
  };

  fovImageUrl = () => {
    let imageUrl = "";
    switch (this.columnValuesByName.INSTRUME) {
      case `SOT/NB`:
      case `SOT/WB`:
      case `SOT/SP`:
        for (let i = 0; i < this.columnValuesByName.THUMBS; i++) {
          imageUrl = `${this.imageUrlPrefix("SOT")}_fov_thumb.png`;
        }
        break;
      default:
        imageUrl = `${this.imageUrlPrefix()}_fov_thumb.png`;
    }
    return imageUrl;
  };

  fovBackgroundUrl = size => {
    const datePath = yyyyMmDdPathFromString(this.columnValuesByName.DATE_OBS);
    return `${FILESERVER}/vol/svo-synop/${datePath}/th-earth-193-H12-${size}.png`;
  };

  onHoverImageUrl = imageUrls => {
    if (imageUrls.length === 0) {
      return;
    }
    return imageUrls[0].url;
  };

  makeFovAndImagesData = () => {
    const imageObjects = this.imageObjects();
    const onHoverImageUrl = this.onHoverImageUrl(imageObjects);
    this.selectionImageUrl = onHoverImageUrl;
    this.fovAndImagesData = {
      fovImage: this.fovImageUrl(),
      fovBackgroundIconUrl: this.fovBackgroundUrl(64),
      fovBackgroundImageUrl: this.fovBackgroundUrl(1024),
      images: imageObjects,
      onHoverImageUrl: onHoverImageUrl,
      boxImagesData: this.boxImagesDataIfValidHints()
    };
  };

  wholeDaysDifference = () => {
    const dayStartMillisec = parseDate(this.columnValuesByName.DATE_OBS.substr(0, 10));
    const dayEndMillisec = parseDate(this.columnValuesByName.DATE_END.substr(0, 10));
    const difference = dayEndMillisec - dayStartMillisec;
    return Math.floor(difference / 1000 / 60 / 60 / 24);
  };

  calculateDurationString = () => {
    const duration = new Date(
      parseDate(this.columnValuesByName.DATE_END) - parseDate(this.columnValuesByName.DATE_OBS)
    );
    const hoursAsString = duration.getUTCHours() !== 0 ? `${duration.getUTCHours().toString()}h` : "";
    const minutesAsString =
      duration.getUTCHours() === 0 && duration.getUTCMinutes() === 0 ? "" : `${duration.getUTCMinutes().toString()}m`;
    const secondsAsString = duration.getUTCSeconds().toString();
    return ` ${hoursAsString} ${minutesAsString} ${secondsAsString}s`;
  };

  calculateTimespanStrings = () => {
    const dateStart = new Date(parseDate(this.columnValuesByName.DATE_OBS));
    const dateEnd = new Date(parseDate(this.columnValuesByName.DATE_END));
    const daysDifference = this.wholeDaysDifference();
    const daysOffset = daysDifference === 0 ? "" : `+${daysDifference}d`;
    const startMonth = (dateStart.getMonth() + 1).toString().padStart(2, "0");
    const startDay = dateStart.getDate().toString().padStart(2, "0");
    const startHour = dateStart.getHours().toString().padStart(2, "0");
    const startMinutes = dateStart.getMinutes().toString().padStart(2, "0");
    const endHours = dateEnd.getHours().toString().padStart(2, "0");
    const endMinutes = dateEnd.getMinutes().toString().padStart(2, "0");
    const startDate = `${dateStart.getFullYear()}-${startMonth}-${startDay} `;
    const startEndTime = `${startHour}:${startMinutes}-${endHours}:${endMinutes}`;
    const startTime = `${startHour}:${startMinutes}`;
    const endTime = `${endHours}:${endMinutes}`;
    const duration = this.calculateDurationString();
    return {
      startDate,
      startEndTime,
      daysOffset,
      duration,
      startTime,
      endTime
    };
  };

  calculateFovString = () => {
    const formatter = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      maximumSignificantDigits: 3
    });
    const xcenFormated = formatter.format(this.columnValuesByName.xcen_best);
    const ycenFormated = formatter.format(this.columnValuesByName.ycen_best);
    const fovxFormated = formatter.format(this.columnValuesByName.fovx_best);
    const fovyFormated = formatter.format(this.columnValuesByName.fovy_best);
    const pointing = `(${xcenFormated}",\xa0${ycenFormated}")`;
    const size = `[${fovxFormated}"\xa0x\xa0${fovyFormated}"]`;
    return `${pointing}<br>${size}`;
  };

  // xc(i): XCEN for slit index i
  // j: slit index for current file
  // xcj: XCEN for current file
  //
  // xc(i) = x0 + step * sum * i * plateX
  // x0 = xcj - step * sum * j * plateX
  //
  // xc(i) = (xcj - step * sum * j * plateX) + Step * Sum * i * plateX
  //       = xcj + (i - j) * step * sum * plateX
  //

  findSOTSPBestParameters = () => {
    const step = parseFloat(this.columnValuesByName.SS__SCN_STEP);
    const sum = parseFloat(this.columnValuesByName.SS__SCN_SUM);
    const j = parseFloat(this.columnValuesByName.SS__SLITINDX);
    const xcj = parseFloat(this.columnValuesByName.XCEN);
    const nslitpos = parseFloat(this.columnValuesByName.SS__NSLITPOS);
    const maxJ = nslitpos / sum;
    const plateX = 0.1486; // XSCALE (arcsec/pixel, https://iopscience.iop.org/article/10.3847/1538-4365/aca539)

    const centeri = nslitpos / 2;
    const xcen_best = xcj + (centeri - j) * step * sum * plateX;
    const xLeft = xcj + (0 - j) * step * sum * plateX;
    const xRight = xcj + (maxJ - 1 - j) * step * sum * plateX;
    const fovx_best = xRight - xLeft;

    return { fovx_best, xcen_best };
  };

  findBestPointing = () => {
    if (this.columnValuesByName.THUMBS === 0) {
      this.columnValuesByName.XCEN_CORR = this.columnValuesByName.XCEN;
      this.columnValuesByName.YCEN_CORR = this.columnValuesByName.YCEN;
    }
    let xcen_best = this.columnValuesByName.XCEN_CORR;
    let ycen_best = this.columnValuesByName.YCEN_CORR;
    const [initial_xcen_best, initial_ycen_best] = [xcen_best, ycen_best];
    if (
      parseFloat(this.columnValuesByName.XCEN_DELTA) === 0.0 &&
      parseFloat(this.columnValuesByName.XCEN_CORR) === 0.0 &&
      Math.abs(parseFloat(this.columnValuesByName.XCEN)) < 5000.0 &&
      Math.abs(parseFloat(this.columnValuesByName.YCEN)) < 5000.0
    ) {
      xcen_best = this.columnValuesByName.XCEN;
      ycen_best = this.columnValuesByName.YCEN;
    }
    if (xcen_best !== initial_xcen_best || ycen_best !== initial_ycen_best) {
      // MyLogger.red(`Fixed pointing (${initial_xcen_best},${initial_ycen_best}) => (${xcen_best}, ${ycen_best})`);
    }
    return [xcen_best, ycen_best];
  };

  patchBestFov = () => {
    const [xcen_best, ycen_best] = this.findBestPointing();
    this.columnValuesByName["xcen_best"] = xcen_best;
    this.columnValuesByName["ycen_best"] = ycen_best;

    this.columnValuesByName.fovx_best = this.columnValuesByName.FOVX;
    this.columnValuesByName.fovy_best = this.columnValuesByName.FOVY;

    if (this.columnValuesByName.INSTRUME === `SOT/SP`) {
      const { fovx_best, xcen_best } = this.findSOTSPBestParameters();
      this.columnValuesByName.fovx_best = fovx_best;
      this.columnValuesByName.xcen_best = xcen_best;
    }
  };

  patchSyntheticColumnValuesIntoColumnValuesByName = () => {
    this.columnValuesByName["DATE-BEG"] = this.columnValuesByName.DATE_OBS;
    this.columnValuesByName["DATE-END"] = this.columnValuesByName.DATE_END;
    this.columnValuesByName["OBSDESC"] = this.columnValuesByName.OBS_DEC;
    this.columnValuesByName["XPOSURE"] = formatNumber(this.columnValuesByName.EXPTIME, 3, 3);

    const position = ephemeris.lookup(this.columnValuesByName.DATE_OBS, this.columnValuesByName.INSTRUME);
    this.columnValuesByName["HGLN_OBS"] = position.hgln;
    this.columnValuesByName["HGLT_OBS"] = position.hglt;
    this.columnValuesByName["DSUN_OBS"] = position.dsun;
    this.columnValuesByName["CROTA"] = 0;

    this.patchBestFov();

    this.columnValuesByName["fov"] = this.calculateFovString();
    this.columnValuesByName["timespan"] = this.calculateTimespanStrings();

    const fakeIrisAsSolarOrbiter = false;
    if (fakeIrisAsSolarOrbiter) {
      const instrument = this.columnValuesByName["INSTRUME"];
      if (instrument === "IRIS/SPEC" || instrument === "IRIS/SJI") {
        this.columnValuesByName["HGLN_OBS"] = 0;
        this.columnValuesByName["HGLT_OBS"] = 0;
      }
    }
  };

  // WARNING: When using Date.parse(), Safari requires *EXACT* adherence to
  // ISO 8601, i.e. yyyy-mm-ddThh:mm:ss[.mmm]+hh:mm. Don't drop a single thing!!
  // However, we *know* that the DATE-BEG strings can be sorted in lexical order.
  // So, we just do that!

  compareWithFile = (fileToCompareWith, sortOrder, sortColumn, columnType) => {
    const columnName = convertSyntheticToRealColumnName(sortColumn);
    switch (columnType) {
      case "number":
        return sortOrder === "ASC"
          ? this.columnValuesByName[sortColumn] - fileToCompareWith.columnValuesByName[sortColumn]
          : fileToCompareWith.columnValuesByName[sortColumn] - this.columnValuesByName[sortColumn];
      case "date":
      case "text":
        return sortOrder === "ASC"
          ? this.columnValuesByName[columnName].localeCompare(fileToCompareWith.columnValuesByName[columnName])
          : fileToCompareWith.columnValuesByName[columnName].localeCompare(this.columnValuesByName[columnName]);
      default:
        return 0;
    }
  };

  clearSelectors = () => {
    this.selectors = new Map();
  };

  isAssociated = () => this._selected || this.inCart || this.inTrash || this.hidden || this.selectors.size > 0;

  hasActiveSelector = () => {
    let hasActiveSelector = false;
    this.selectors.forEach(selector => {
      if (selector.active) {
        hasActiveSelector = true;
      }
    });
    return hasActiveSelector;
  };

  timeCenter = () => {
    const dateStart = parseDate(this.columnValuesByName["DATE-BEG"]);
    const dateEnd = parseDate(this.columnValuesByName["DATE_END"]);
    return dateStart + (dateEnd - dateStart) / 2;
  };

  timespan = () => {
    const dateStart = parseDate(this.columnValuesByName["DATE-BEG"]);
    const dateEnd = parseDate(this.columnValuesByName["DATE_END"]);
    return dateEnd - dateStart;
  };

  @action private setSelected = value => (this._selected = value);
  set selected(value) {
    this.setSelected(value);
    if (value) {
      threeStore.three.showImageByFile(this, this._selectionImageUrl);
      resultsStore.penultimateSelectedFile = resultsStore.lastSelectedFile;
      resultsStore.lastSelectedFile = this;
      return;
    }
    threeStore.three.hideImageByFile(this);
    if (resultsStore.lastSelectedFile && resultsStore.lastSelectedFile.id === this.id) {
      resultsStore.lastSelectedFile = resultsStore.penultimateSelectedFile;
      resultsStore.penultimateSelectedFile = null;
    }
  }
  get selected() {
    return this._selected;
  }

  get selectorIsHovered() {
    return Array.from(this.selectors.values()).some(selector => selector.mouseIsOverSelector);
  }

  get isAtRisk() {
    return (
      this.selectors.size > 0 && Array.from(this.selectors.values()).every(selector => selector.computeCriteriaIsEdited)
    );
  }

  @action private setSelectionImageUrl = value => (this._selectionImageUrl = value);
  set selectionImageUrl(value) {
    this.setSelectionImageUrl(value);
  }
  get selectionImageUrl() {
    return this._selectionImageUrl;
  }

  @action private setFileIsHovered = value => (this._mouseIsOverFile = value);
  set fileIsHovered(value) {
    this.setFileIsHovered(value);
    threeStore.three.updateCanvasVisuals();
  }
  get fileIsHovered() {
    return this._mouseIsOverFile;
  }

  get isHovered() {
    return this.fileIsHovered || this.selectorIsHovered || this.protoSelected;
  }

  @action private setHidden = value => (this._hidden = value);
  set hidden(value) {
    this.setHidden(value);
  }
  get hidden() {
    return this._hidden;
  }

  @action private setInCart = value => (this._inCart = value);
  set inCart(value) {
    this.setInCart(value);
  }
  get inCart() {
    return this._inCart;
  }

  @action private setInTrash = value => (this._inTrash = value);
  set inTrash(value) {
    this.setInTrash(value);
  }
  get inTrash() {
    return this._inTrash;
  }

  @action private setProtoSelected = value => (this._protoSelected = value);
  set protoSelected(value) {
    this.setProtoSelected(value);
  }
  get protoSelected() {
    return this._protoSelected;
  }

  @action private setFlashing = value => (this._flashMeInResults = value);
  set flashMeInResults(value) {
    this.setFlashing(value);
  }
  get flashMeInResults() {
    return this._flashMeInResults;
  }
}

export default File;
