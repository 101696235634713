import React, { CSSProperties, Component } from "react";
import { labeledUuidV4 } from "../../Utilities/Utilities";
import appStore from "../../Stores/AppStore";
import { observer } from "mobx-react";

@observer
class ShortcutsHelpOverlay extends Component {
  id = labeledUuidV4("shortcuts-help-overlay");

  handleOnClick = ev => appStore.toggleShowShortcutsHelp();

  content = () => {
    return (
      <table style={{ border: "100px" } as CSSProperties}>
        <tbody>
          <tr>
            <td>ESC</td>
            <td>Hide this help</td>
          </tr>
          <tr>
            <td>CTRL + A</td>
            <td>Select all</td>
          </tr>
        </tbody>
      </table>
    );
  };

  render() {
    const className = appStore.mouseIsOver("canvas") ? "shortcutsHelp-right" : "shortcutsHelp-left";
    return (
      <div className={className} onClick={this.handleOnClick} style={{ zIndex: 1000, background: "white" }}>
        <div className="innerShortcutsHelp" style={{ padding: "2px" }}>
          <span>SHORTCUTS HELP {appStore.showShortcutsHelp ? " (click to hide)" : " (click to show)"}</span>
          {appStore.showShortcutsHelp ? this.content() : null}
        </div>
      </div>
    );
  }
}

export default ShortcutsHelpOverlay;
