import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

import FovAndImagesCell from "./FovAndImagesCell";
import DateTimeCell from "./DateTimeCell";
import FOVCell from "./FOVCell";
import Button from "../../Utilities/Button";

import { ResultRowProps } from "../Interfaces";
import resultsStore from "../../Stores/ResultsStore";
import threeStore from "../../Stores/ThreeStore";

import { DBSERVER } from "../../constants";
import File from "../../classes/File";
import { macKeys, shutdownEvent } from "../../Utilities/Utilities";
import appStore from "../../Stores/AppStore";
import searchStore from "../../Stores/SearchStore";

configure({ enforceActions: "always" });

const contextMenuLabels = {
  centre: "Centre view on observer (C)",
  pinTime: "Pin/unpin time to hovered file (1)",
  saveSelected: "Save selected files to cart (Cmd-S)",
  altSaveSelected: "Unsave selected files (Alt-Cmd-S)",
  hideSelected: "Hide selected files (Cmd-H)",
  altHideSelected: "Unhide selected files (Alt-Cmd-H)",
  trashSelected: "Delete selected files (Cmd-D)",
  altTrashSelected: "Restore selected files (Alt-Cmd-D)"
};

@observer
class ResultRow extends React.Component<ResultRowProps> {
  defaultImageUrl;
  timer;
  contentFitsInsideCell;

  componentDidMount() {
    this.contentFitsInsideCell = true;
  }

  handleClick = ev => {
    const { file, onClick } = this.props;
    const { onlyCmdKey, cmdKey, shiftKey, altKey, ctrlKey } = macKeys(ev);
    if (onlyCmdKey) {
      resultsStore.toggleStatusForFiles([file], "selected");
      if (file.selected) {
        threeStore.three.flashObservationByFile(file);
      } else {
        // This hides the image *despite* the Cmd key being down
        // Anything else is confusing...
        threeStore.three.hideImageByFile(file);
      }
      return;
    }
    if (!(cmdKey || altKey || shiftKey || ctrlKey)) {
      resultsStore.addToSelection([file]);
      threeStore.three.flashObservationByFile(file);
    }
    onClick(ev, file);
  };

  handleDoubleClick = ev => {
    threeStore.three.centreViewOnObserverByFile(this.props.file);
  };

  openInNewWindowAndShutdownEvent = (ev, url) => {
    window.open(url, "_blank");
    shutdownEvent(ev);
  };

  handleOnMouseEnter = ev => {
    console.log("Mouse enters result row");
    if (appStore.contextMenuIsActive) {
      return;
    }
    const { file } = this.props;
    const newResultsRow = document.getElementById(this.props.file.id);
    resultsStore.contextMenuFile = this.props.file;
    if (resultsStore.currentResultsRowDiv !== newResultsRow) {
      resultsStore.currentResultsRowDiv = newResultsRow;
      resultsStore.currentResultsRowFile = file;
      // resultsStore.hideIconImagesOverlay();
      // resultsStore.hideFullSizeImageOverlay();
      // resultsStore.setThumbWidth(THUMBWIDTH_INITIAL_VALUE);
      if (!resultsStore.timeCenterPinpointIsActive) {
        threeStore.three?.setBackground(file.fovAndImagesData.fovBackgroundImageUrl);
        //! This is what gives the red borders of the FOV icons:
        // resultsStore.changeBacgroundCssClassName(file.fovAndImages.fovBackgroundUrl);
      }
    }

    if (resultsStore.cmdKeyTemporarilyShowImageIsActive) {
      threeStore.three.showImageByFile(file, file.selectionImageUrl);
    }
    file.selectors.forEach(selector => selector.setMouseOverResults(true));
    if (!appStore.contextMenuIsActive) {
      file.fileIsHovered = true;
    }
    if (!resultsStore.timeCenterPinpointIsActive) {
      resultsStore.setBaseFileForCanvas(file);
    }
    if (resultsStore.selectionInProgress) {
      resultsStore.setProtoSelection(file);
    }
  };

  handleOnMouseLeave = ev => {
    // console.log("Mouse leaves result row");
    const { file } = this.props;
    file.selectors.forEach(selector => selector.setMouseOverResults(false));
    if (!appStore.contextMenuIsActive) {
      file.fileIsHovered = false;
    }
    resultsStore.hideTempImageInCanvas(file);
    resultsStore.currentResultsRowFile = null;
  };

  getAngle = (x1, y1, x2, y2) => {
    var distY = Math.abs(y2 - y1); //opposite
    var distX = Math.abs(x2 - x1); //adjacent
    var dist = Math.sqrt(distY * distY + distX * distX); //hypotenuse,
    //don't know if there is a built in JS function to do the square of a number
    var val = distY / dist;
    var aSine = Math.asin(val);
    return aSine * (180 / Math.PI); //return angle in degrees
  };

  handleOnMouseMove = ev => resultsStore.handleOnMouseMoveOverResultRow(this.props.file, ev);

  handleOnContextMenu = ev => {
    shutdownEvent(ev);
    appStore.setContextMenuData(ev.clientX, ev.clientY, resultsStore.computeFilteredData, [this.props.file]);
    appStore.contextMenuLabels = contextMenuLabels;
    appStore.showContextMenu();
  };

  handleOnMouseDown = ev => {
    const { cmdKey, shiftKey, altKey, ctrlKey } = macKeys(ev);
    const onlyShiftKey = shiftKey && !(altKey || cmdKey || ctrlKey);
    if (onlyShiftKey) {
      resultsStore.selectionStartFile = this.props.file;
      resultsStore.setSelectionInProgress(true);
      this.props.file.protoSelected = true;
    }
  };

  handleOnMouseUp = ev => {
    const { cmdKey, shiftKey, altKey, ctrlKey } = macKeys(ev);
    const onlyShiftKey = shiftKey && !(altKey || cmdKey || ctrlKey);
    if (onlyShiftKey) {
      const selectionEndFile = this.props.file;
      const indexOfStartFile = resultsStore.computeFilteredData.findIndex(
        result => result.id === resultsStore.selectionStartFile.id
      );
      const indexOfEndFile = resultsStore.computeFilteredData.findIndex(result => result.id === selectionEndFile.id);
      const lowerIndex = Math.min(indexOfStartFile, indexOfEndFile);
      const upperIndex = Math.max(indexOfStartFile, indexOfEndFile);
      const filesToSelect = resultsStore.computeFilteredData.slice(lowerIndex, upperIndex + 1);
      resultsStore.addToSelection(filesToSelect);
      filesToSelect.forEach(file => (file.protoSelected = false));
      resultsStore.setSelectionInProgress(false);
    }
  };

  toggleFileStatus = (ev, statusName) => {
    shutdownEvent(ev);
    const file = this.props.file;
    resultsStore.toggleStatusForFiles([file], statusName);
    //resultsStore.removeFromSelection([file]);
  };

  toggleBindTimeSpanStatus = ev => {
    shutdownEvent(ev);
    const file = this.props.file;
    const timeCenterBoundToFile =
      resultsStore.baseFileForCanvas.id === file.id && resultsStore.timeCenterPinpointIsActive;
    if (resultsStore.timeCenterPinpointIsActive && timeCenterBoundToFile) {
      resultsStore.setTimeCenterPinpointIsActive(false);
      return;
    }
    resultsStore.setTimeCenterPinpointIsActive(true);
    resultsStore.setBaseFileForCanvas(file);
  };

  renderAllActionButtons = () => {
    const showFieldsQueryPart =
      "s=," +
      resultsStore.resultsTableColumns
        .map(col => col.name)
        .filter(colName => colName.match("[A-Z]"))
        .join(",");
    const file = this.props.file;
    const timeCenterBoundToFile =
      resultsStore.baseFileForCanvas.id === file.id && resultsStore.timeCenterPinpointIsActive;
    const checkbox = {
      id: "checkbox-icon",
      path: file.selected ? "icons/checked.png" : "icons/unchecked.png",
      name: "Select / deselect",
      onClick: ev => this.toggleFileStatus(ev, "selected"),
      height: "20px"
    };
    const header = {
      id: "actions-header-button",
      path: `${DBSERVER}/search/img/text.gif`,
      name: "Show fits header",
      onClick: ev =>
        this.openInNewWindowAndShutdownEvent(ev, `${DBSERVER}/search/header/${file.columnValuesByName.FILE}`)
    };
    const download = {
      id: "actions-download-button",
      path: `${DBSERVER}/search/img/download.gif`,
      name: "Quick download - single fits file",
      onClick: ev =>
        this.openInNewWindowAndShutdownEvent(ev, `${DBSERVER}/search/file/${file.columnValuesByName.FILE}.fits`)
    };

    const expand = {
      id: "actions-expand-button",
      path: `${DBSERVER}/search/img/expand.gif`,
      name: "Expand group",
      onClick: ev =>
        this.openInNewWindowAndShutdownEvent(
          ev,
          `${DBSERVER}/search/result?O=DATE_OBS;o=D;L=1000;P=10;xSQ_IUMODE1=${file.columnValuesByName.FILE};${showFieldsQueryPart}`
        )
    };
    const hidden = {
      id: "show-hidden-icon",
      imageClassName: "results-icon " + (file.hidden ? "active" : "inactive"),
      path: "icons/hidden.png",
      name: "Hide/unhide file",
      onClick: ev => this.toggleFileStatus(ev, "hidden"),
      height: "15px"
    };
    const cart = {
      id: "show-cart-icon",
      imageClassName: "results-icon " + (file.inCart ? "active" : "inactive"),
      path: "icons/cart.png",
      name: "Add/remove from cart",
      onClick: ev => this.toggleFileStatus(ev, "cart"),
      height: "15px"
    };
    const trash = {
      id: "show-trash-icon",
      imageClassName: "results-icon " + (file.inTrash ? "active" : "inactive"),
      path: "icons/trash.png",
      name: "Add/remove from trash",
      onClick: ev => this.toggleFileStatus(ev, "trash"),
      height: "15px"
    };
    const pin = {
      id: "pin-icon",
      imageClassName: "results-icon " + (timeCenterBoundToFile ? "active" : "inactive"),
      path: timeCenterBoundToFile ? "icons/pinpoint-selected.png" : "icons/pinpoint.png",
      name: timeCenterBoundToFile ? "Unbind time center" : "Bind time center",
      onClick: this.toggleBindTimeSpanStatus,
      height: "15px"
    };
    const actionCellId = "action-cell-" + file.id;
    const numberOfFiles = file.minMaxValuesByName["N files"].minValues[0];
    const numberOfFilesElement = numberOfFiles ? (
      <div style={{ position: "absolute", left: "5px", top: "-1px" }}>
        <b style={{ color: "rgb(6, 184, 0)" }}> {numberOfFiles}</b>
      </div>
    ) : null;
    const fileInCanvas = resultsStore.computeResultsForThree.indexOf(this.props.file);
    const className = fileInCanvas === -1 ? "action-cell fileNotInCanvas" : "action-cell fileInCanvas";
    return (
      <div id={actionCellId} style={{ height: "64px" }} className={className}>
        <div onClick={shutdownEvent}>
          <Button {...checkbox} />
          <Button {...pin} />
          {numberOfFilesElement}
        </div>
        <div>
          <div className="action-cell">
            <Button {...header} /> &nbsp;
            <Button {...download} /> &nbsp;
            <Button {...expand} />
          </div>
          <div style={{ paddingTop: "5px" }} className="action-cell">
            <Button {...cart} />
            <Button {...hidden} />
            <Button {...trash} />
          </div>
        </div>
      </div>
    );
  };

  renderFileActionsAndNumberOfFilesCell = () => {
    const sticky: React.CSSProperties = { position: "sticky", left: 0 };
    return (
      <td className="resultCell" style={sticky} onClick={this.handleClick}>
        {this.renderAllActionButtons()}
      </td>
    );
  };

  handleToggleSelection = ev => {
    this.props.file.selected = !this.props.file.selected;
    shutdownEvent(ev);
  };

  renderBlankColumn = () => <td className="empty-status-column"></td>;

  showHoverText = (id, value) => {
    const hoverTextIsInverted = true;
    appStore.showHoverText(value, id, hoverTextIsInverted);
  };

  hideHoverText = () => {
    appStore.hideHoverText();
  };

  handleOnMouseEnterRegularCell = (ev, id, value) => {
    const cellElement = document.getElementById(id);
    if (!cellElement) {
      return;
    }
    const cellWidth = cellElement.clientWidth;
    const textWidth = cellElement.scrollWidth;
    const cellHeight = cellElement.clientHeight;
    const textHeight = cellElement.scrollHeight;
    this.contentFitsInsideCell = textWidth > cellWidth || textHeight > cellHeight;
    if (this.contentFitsInsideCell) {
      this.timer = setTimeout(() => this.showHoverText(id, value), 500);
    }
  };

  handleOnMouseLeaveRegularCell = () => {
    if (this.contentFitsInsideCell) {
      clearTimeout(this.timer);
      this.hideHoverText();
    }
  };

  regularCell = (name, type, value, dragOverClass) => {
    value = value || "";
    const minWidth = Math.max(Math.floor(value.length / 3) * 7, 50);
    const minWidthFinal = Math.min(minWidth, 100);
    const minWidthText = type === "text" ? `${minWidthFinal}px` : "";
    const id = this.props.file.id + name;
    return (
      <td
        id={id}
        key={name}
        className={"resultCell columnType_" + type + dragOverClass}
        style={{ minWidth: minWidthText, maxWidth: "100px", overflow: "hidden", maxHeight: "60px" }}
        onClick={this.handleClick}
        onMouseEnter={ev => this.handleOnMouseEnterRegularCell(ev, id, value)}
        onMouseLeave={this.handleOnMouseLeaveRegularCell}
      >
        <div style={{ maxHeight: "60px" }}>{value}</div>
      </td>
    );
  };

  renderAnyCell = displayColumnDefinition => {
    const name = displayColumnDefinition.name;
    const columnValue = this.props.file.columnValuesByName[name];
    const dragOverClass = name === resultsStore.dragoverColumn ? " dragover" : "";
    const cellProps = { key: name, file: this.props.file, dragOverClass, onClick: this.handleClick };
    switch (displayColumnDefinition.type) {
      case "images":
        return <FovAndImagesCell {...cellProps} />;
      case "date":
        return <DateTimeCell {...cellProps} dateTime={columnValue} />;
      case "nowrap":
        return <FOVCell pointingAndSize={columnValue} {...cellProps} />;
      default:
        return this.regularCell(name, displayColumnDefinition.type, columnValue, dragOverClass);
    }
  };

  highlightClasses = (file: File) => {
    let highlightClasses = " ";
    const listIsOutdated = searchStore.selectors.some(selector => selector.computeCriteriaIsEdited);
    const globalCriteriaIsHovered = searchStore.computeGlobalCriteria.mouseIsOverSelector;
    const timeCenterBoundToFile =
      resultsStore.baseFileForCanvas.id === file.id && resultsStore.timeCenterPinpointIsActive;
    highlightClasses += listIsOutdated ? " marked-as-outdated-list" : "";
    highlightClasses += globalCriteriaIsHovered ? " marked-as-global-hovered" : "";
    highlightClasses += file.someSelectorIsEdited ? " marked-as-edited" : "";
    highlightClasses += file.selectorIsHovered ? " marked-as-hovered" : "";
    highlightClasses += file.isAtRisk ? " marked-as-at-risk" : "";
    highlightClasses += file.fileIsHovered ? " marked-as-file-hovered" : "";
    highlightClasses += file.protoSelected ? " protoSelected" : "";
    highlightClasses += file.selected ? " selected" : "";
    highlightClasses += file.flashMeInResults ? " flashing" : "";
    highlightClasses += timeCenterBoundToFile ? " marked-as-time-center" : "";
    return highlightClasses;
  };

  render() {
    const { file, resultsTableColumns } = this.props;
    let className = "resultRow fileType_" + file.type;
    const highlightClasses = this.highlightClasses(file);
    const classNames = className + " " + highlightClasses;
    return (
      <tr
        id={this.props.file.id}
        className={classNames}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        onContextMenu={this.handleOnContextMenu}
        onMouseDown={this.handleOnMouseDown}
        onMouseUp={this.handleOnMouseUp}
        onMouseMove={this.handleOnMouseMove}
        onDoubleClick={this.handleDoubleClick}
      >
        {this.renderFileActionsAndNumberOfFilesCell()}
        {resultsTableColumns.map(displayColumnDefinition => this.renderAnyCell(displayColumnDefinition))}
      </tr>
    );
  }
}
export default ResultRow;
