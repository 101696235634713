import React from "react";
import { THUMBWIDTH_INITIAL_VALUE } from "../../constants";
import { ThumbImageInIconImagesOverlayProps } from "../Interfaces";
import resultsStore from "../../Stores/ResultsStore";
import threeStore from "../../Stores/ThreeStore";
import { macKeys, shutdownEvent } from "../../Utilities/Utilities";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";
import appStore from "../../Stores/AppStore";

@observer
class ThumbImageInIconImagesOverlay extends React.Component<ThumbImageInIconImagesOverlayProps> {
  id = uuidv4();
  timer;

  handleMouseEnterThumb = (ev, image) => {
    this.timer = setTimeout(() => this.showHoverText(), 100);
    threeStore.three.showImageByFile(resultsStore.overlayBoxFile, image);
    if (ev.shiftKey) {
      resultsStore.initializeFullSizeImageData(this.props.imageUrl.replace("_thumb.png", ".jpg"));
    }
  };

  handleMouseLeaveThumb = event => {
    clearTimeout(this.timer);
    this.hideHoverText();
    threeStore.three.hideImageByFile(resultsStore.overlayBoxFile);
    if (resultsStore.overlayBoxFile.selected) {
      threeStore.three.showImageByFile(resultsStore.overlayBoxFile, resultsStore.overlayBoxFile.selectionImageUrl);
    }
  };

  showHoverText = () => {
    if (this.props.imageTitle !== "") {
      appStore.showHoverText(this.props.imageTitle, this.id);
    }
  };

  hideHoverText = () => {
    appStore.hideHoverText();
  };

  handleOnError = (ev, id) => {
    shutdownEvent(ev);
    const image = document.getElementById(id);
    if (image) {
      image.style.display = "none";
    }
  };

  handleOnClick = event => {
    const file = this.props.file;
    const { onlyCmdKey, onlyShiftKey } = macKeys(event);
    if (onlyCmdKey) {
      file.selected = !file.selected;
      return;
    }
    if (onlyShiftKey) {
      file.selectionImageUrl = this.props.imageUrl;
      if (file.selected) {
        threeStore.three.showImageByFile(file, file.selectionImageUrl);
      }
    }
    this.props.onClick(event);
  };

  compareUrlWithCurrentFullSizeImage = () => {
    const fullSizeImage = this.props.imageUrl.replace("_thumb.png", ".jpg");
    return fullSizeImage === resultsStore.fullSizeImageUrl;
  };

  render() {
    const { imageUrl, imageTitle } = this.props;
    const className = this.compareUrlWithCurrentFullSizeImage() ? "previewImage previewImageShown" : "previewImage";
    return resultsStore.thumbWidth === THUMBWIDTH_INITIAL_VALUE ? null : (
      <img
        id={this.id}
        key={imageUrl}
        src={imageUrl}
        alt={imageTitle}
        className={className}
        onClick={this.handleOnClick}
        fetchpriority="low"
        loading="lazy"
        onMouseEnter={event => this.handleMouseEnterThumb(event, imageUrl)}
        onMouseLeave={this.handleMouseLeaveThumb}
        onError={ev => this.handleOnError(ev, this.id)}
      />
    );
  }
}
export default ThumbImageInIconImagesOverlay;
