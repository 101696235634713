import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { DateInputParameterProps } from "../Interfaces";
import searchStore from "../../Stores/SearchStore";
import { dateObjectToString, isValidAbsoluteDate, isValidRelativeDate } from "../../Utilities/Utilities";
configure({ enforceActions: "always" });

// Single date input field used both for single date and range

@observer
class DateInputParameter extends React.Component<DateInputParameterProps> {
  inputFieldFullName = uuidv4();

  validitySuffix = inputText => {
    if (inputText === "") {
      return "";
    }
    if (isValidAbsoluteDate(inputText)) {
      return "-valid";
    }
    if (!this.props.canHandleRelativeDate) {
      return "-invalid";
    }
    if (isValidRelativeDate(inputText)) {
      return "-valid";
    }
    return "-invalid";
  };

  handleValueChange = ev => {
    const validationPattern = /^[-+: 0-9ywdhmso]+$/;
    const inputValueIsValid = ev.target.value.match(validationPattern);
    if (inputValueIsValid || ev.target.value === "") {
      this.props.onValueChange(ev);
    }
  };

  handleOnFocus = () => {
    searchStore.datePickerOverlayHookElement = document.getElementById(this.inputFieldFullName);
    const datePickerStartDate = this.dateObjectFromInput();
    searchStore.dataForDatePickerOverlay = {
      minSelectableDate: this.props.minSelectableDate,
      startDate: datePickerStartDate,
      onClickDay: this.handleOnClickDay
    };
    searchStore.showDatePickerOverlay();
  };

  handleOnBlur = ev => {
    const datePickerOverlayelement = document.getElementById("date-picker-overlay");
    if (!datePickerOverlayelement) {
      return;
    }
    if (!datePickerOverlayelement.contains(ev.relatedTarget)) {
      searchStore.hideDatePickerOverlay();
    }
  };

  handleOnClickDay = (value, ev) => {
    const time = value.getTime();
    const timeZoneOffset = value.getTimezoneOffset() * 60 * 1000;
    const updatedValue = new Date(time - timeZoneOffset);
    ev.target.value = dateObjectToString(updatedValue);
    if (this.props.inputFieldId === "Max") {
      ev.target.value = ev.target.value.substring(0, 11) + "23:59";
    }
    this.handleValueChange(ev);
    searchStore.hideDatePickerOverlay();
  };

  dateObjectFromInput = () => {
    if (isValidAbsoluteDate(this.props.value)) {
      return new Date(this.props.value);
    }
    return null;
  };

  handleChangeDate = (ev, offset) => {
    if (isValidRelativeDate(this.props.value)) {
      const values = this.props.value.match(/[0-9]+([.][0-9]*)?/);
      const valueAsNumber = Number(values[0]);
      const changedValueAsNumber = valueAsNumber + Number(offset);
      const changedValue = changedValueAsNumber < 0 ? "0" : changedValueAsNumber.toString();
      ev.target.value = this.props.value.replace(values[0], changedValue);
      this.handleValueChange(ev);
      return;
    }
    if (isValidAbsoluteDate(this.props.value)) {
      const date = new Date(this.props.value);
      const changedDate = new Date(date.setDate(date.getDate() + Number(offset)));
      const changedDateAsStr = dateObjectToString(changedDate);
      ev.target.value = changedDateAsStr;
      this.handleValueChange(ev);
      return;
    }
  };

  render() {
    let className = this.props.criterion.type;
    className += this.validitySuffix(this.props.value);
    className += " " + this.props.criterion.afterPrefetchStatus;
    const excludedClassName = this.props.criterion.excluded ? "criterion-input-excluded" : "";
    className += " " + excludedClassName;

    const placeholder = this.props.inputFieldId === "Min" ? "- infinity" : "infinity";
    return (
      <div id={this.inputFieldFullName} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur}>
        <input
          type="text"
          placeholder={placeholder}
          value={this.props.value}
          className={className}
          onClick={this.handleOnFocus}
          onChange={this.handleValueChange}
          onBlur={this.props.onBlur}
          size={18}
        ></input>
        {/* {shouldHaveDatePicker ? (
          <div className={this.props.className}>
            {shortcutButtons}
            <DatePicker
              startDate={datePickerStartDate}
              minSelectableDate={this.props.minSelectableDate}
              onClickDay={this.handleOnClickDay}
              className=""
            />
          </div>
        ) : null} */}
      </div>
    );
  }
}
export default DateInputParameter;
