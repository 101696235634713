import { observable, action, makeObservable, configure } from "mobx";
import Three from "../components/ThreeSpan/Three";

configure({ enforceActions: "always" });

class ThreeCapsule {
  //! NOTE: Public attributes of Three should really be stored
  //! directly in ThreeCapsule (which should then be called
  //! ThreeStore).
  //! However, this would require a lot of refactoring, so
  //! for now, we just store a reference to Three in ThreeCapsule.
  three: Three;

  private nextAnimationFrameDisplayDateMsec = Date.now();
  public displayDateMsecHasChanged = false;
  public timeShiftReferenceDateMsec = Date.now();

  @observable effectiveDisplayDateMsec = Date.now();

  constructor() {
    makeObservable(this);
  }

  @action setEffectiveDisplayDateMsec = displayDateMsec => (this.effectiveDisplayDateMsec = displayDateMsec);

  public commitNextAnimationFrameDisplayDateMsec = () => {
    this.setEffectiveDisplayDateMsec(this.nextAnimationFrameDisplayDateMsec);
    this.displayDateMsecHasChanged = false;
    this.three.updateAllDisplayDates();
  };

  public storeNextAnimationFrameDisplayDateMsec = (
    displayDateMsec: number,
    { keepReferenceTime } = { keepReferenceTime: false }
  ) => {
    if (this.nextAnimationFrameDisplayDateMsec !== displayDateMsec) {
      this.nextAnimationFrameDisplayDateMsec = displayDateMsec;
      this.displayDateMsecHasChanged = true;
      if (!keepReferenceTime) {
        this.timeShiftReferenceDateMsec = threeStore.effectiveDisplayDateMsec;
      }
    }
  };

  instrumentsVisibilityStatus = observable.map();
  altKeyCmdTemporarilyShowImageIsActive = false;
}

const threeStore = new ThreeCapsule();
export default threeStore;
