import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";

import File from "../../classes/File";
import resultsStore from "../../Stores/ResultsStore";
import { labeledUuidV4, shutdownEvent, urlAsCssClassName } from "../../Utilities/Utilities";
import threeStore from "../../Stores/ThreeStore";
import appStore from "../../Stores/AppStore";

configure({ enforceActions: "always" });

interface IProps {
  file: File;
  dragOverClass: string;
  onClick: (any) => void;
}

@observer
class FovAndImagesCell extends React.Component<IProps> {
  id = labeledUuidV4("fov-and-images-cell");

  handleMouseEnter = ev => {
    console.log(
      "Entering fov and images cell",
      resultsStore.iconImagesOverlayIsActive,
      resultsStore.mousePointerIsMovingTowardsImagesOverlay,
      appStore.contextMenuIsActive
    );
    // Because the mouseEnter may arrive before the mouse move triggers, we
    // need to "manually" update the mouse movement direction
    resultsStore.handleOnMouseMoveOverResultRow(this.props.file, ev);
    if (appStore.contextMenuIsActive || resultsStore.mousePointerIsMovingTowardsImagesOverlay) {
      return;
    }
    if (resultsStore.divTriggeringOverlay !== ev.currentTarget) {
      resultsStore.setDivTriggeringOverlay(ev.currentTarget);
    }
    if (resultsStore.overlayBoxFile !== this.props.file) {
      resultsStore.initializeOverlayBoxData(this.props.file);
    }
    resultsStore.showIconImagesOverlay();
    threeStore.three.showImageByFile(this.props.file, this.props.file.selectionImageUrl);
  };

  handleMouseLeave = ev => {
    if (appStore.contextMenuIsActive) {
      return;
    }
    resultsStore.hideTempImageInCanvas(this.props.file);
  };

  handleOnError = (ev, id) => {
    shutdownEvent(ev);
    const image = document.getElementById(id);
    if (image) {
      image.style.display = "none";
    }
  };

  renderFovImage = (imageUrl, fovBackgroundIconUrl) => {
    const className = `fov-thumbnail ${urlAsCssClassName(fovBackgroundIconUrl)}`;
    const hasImages = this.props.file.fovAndImagesData.images.length > 0;
    return hasImages ? (
      <span style={{ borderColor: "white" }}>
        <img
          src={imageUrl}
          alt={""}
          // className={className}
          style={{ background: `url("${fovBackgroundIconUrl}") right 1px bottom 0px` }}
          loading="lazy"
          fetchpriority="low"
        />
      </span>
    ) : (
      <img loading="lazy" fetchpriority="low" src={fovBackgroundIconUrl} alt={""} className={className} />
    );
  };

  renderImage = imageObj => {
    const id = labeledUuidV4("image");
    return (
      <span key={id}>
        <img
          id={id}
          key={imageObj.url}
          src={imageObj.url}
          alt={imageObj.description}
          title={imageObj.description}
          className="smallthumbnail"
          onError={ev => this.handleOnError(ev, id)}
          loading="lazy"
          fetchpriority="low"
        />
      </span>
    );
  };

  renderNoData = () => {
    return (
      <div style={{ padding: "10px" }}>
        <h4>NO IMAGE DATA</h4>
      </div>
    );
  };

  render() {
    const { images, fovImage, fovBackgroundIconUrl } = this.props.file.fovAndImagesData;
    const hasImages = images.length > 0;
    const listOfImageElements = hasImages
      ? images.slice(0, 3).map(image => this.renderImage(image))
      : this.renderNoData();
    const fovImageElement = this.renderFovImage(fovImage, fovBackgroundIconUrl);
    const tdProps = {
      id: this.id,
      className: "resultCell fovAndImages " + this.props.dragOverClass,
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave
    };
    return (
      <td {...tdProps} onClick={this.props.onClick}>
        <span className="imagesSpan">
          {fovImageElement}
          {listOfImageElements}
        </span>
      </td>
    );
  }
}
export default FovAndImagesCell;
