import { MyLogger } from "../../../Utilities/Utilities";

const { cos, sin, sqrt, atan2 } = Math;
export const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180;
export const radiansToDegrees = (radians: number) => (radians * 180) / Math.PI;

// Coordinate system conversions --------------------------------------------------------
// https://en.wikipedia.org/wiki/Spherical_coordinate_system#Cartesian_coordinates @ 2022/08/31
// NOTE transposition of coordinate names!
export const XYZ_to_HGLN_HGLT_R = (xWorld, yWorld, zWorld) => {
  const xFig = zWorld;
  const yFig = xWorld;
  const zFig = yWorld;
  const theta = atan2(sqrt(xFig * xFig + yFig * yFig), zFig);
  const phi = atan2(yFig, xFig);
  const HGLN = radiansToDegrees(phi);
  const HGLT = 90 - radiansToDegrees(theta);
  return [HGLN, HGLT, sqrt(xFig * xFig + yFig * yFig + zFig * zFig)];
};

export const HGLN_HGLT_R_to_XYZ = (HGLN, HGLT, R) => {
  const phi = degreesToRadians(HGLN);
  const theta = degreesToRadians(90 - HGLT);
  const xFig = R * cos(phi) * sin(theta);
  const yFig = R * sin(phi) * sin(theta);
  const zFig = R * cos(theta);
  const xWorld = yFig;
  const yWorld = zFig;
  const zWorld = xFig;
  return [xWorld, yWorld, zWorld];
};

export const logArrayToConsole = (prefix, arr, stride) => {
  for (let i = 0; i < arr.length; i += stride) {
    let s = prefix + `[${i / stride}] `;
    for (let j = 0; j < stride; j++) {
      s += Number(arr[i + j]).toPrecision(3) + " ";
    }
    MyLogger.normal(s);
  }
};

export const table = (names, values) => {
  const arr = [] as any[];
  names.forEach((name, index) => (arr[name] = values[index]));
  console.table(arr);
};

export const vectorLength = ([x, y, z]: any[]) => Math.sqrt(x * x + y * y + z * z);

export const formatIsoDateToZuluTime = (date: string) => {
  const Tdate = date.replace(" ", "T");
  if (Tdate.endsWith("Z")) {
    return Tdate;
  }
  if (!Tdate.endsWith(".000")) {
    return Tdate + ".000Z";
  }
  console.warn("formatIsoDateToZuluTime is confused: ", Tdate);
  return Tdate + "Z";
};
