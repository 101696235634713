import "raf/polyfill";
import React, { CSSProperties } from "react";
import { action, configure, makeObservable } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import appStore from "../Stores/AppStore";
import { fullUrl } from "./Utilities";

configure({ enforceActions: "always" });

interface IProps {
  name?: string;
  hoverText?: string;
  className?: string;
  imageClassName?: string;
  path?: string;
  label?: string;
  onClick: (any) => void;
  height?: string;
  background?: string;
  maxWidth?: string;
  position?: string;
  width?: string;
  fontSize?: string;
}

@observer
class Button extends React.Component<IProps> {
  id;
  timer;
  buttonHeight = 0;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.id = uuidv4();
  }

  componentDidMount() {
    this.buttonHeight = document.getElementById(this.id)!.clientHeight;
  }

  @action
  showHoverText = () => {
    const text = this.props.name ? this.props.name : this.props.hoverText;
    appStore.showHoverText(text, this.id);
  };

  @action
  hideHoverText = () => {
    appStore.hideHoverText();
  };

  handleMouseEnter = () => {
    this.timer = setTimeout(() => this.showHoverText(), 300);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timer);
    this.hideHoverText();
  };

  render() {
    const { className = "", imageClassName = "", path = "", name = "", onClick, height = "", label = "" } = this.props;
    const { background = null, position, width, fontSize = null } = this.props;
    // The following is weird, but using this.props.position as a string doesn't work:
    const fullUrlFromPath = fullUrl(path);
    const buttonImage = path ? (
      <div className="labeled-button">
        <img
          style={{ height: `${height}`, cursor: "pointer" }}
          className={imageClassName}
          src={fullUrlFromPath}
          alt={name}
        />
        <div className="button-label" onClick={onClick}>
          {label}
        </div>
      </div>
    ) : (
      <div>{name}</div>
    );
    const style = {
      position,
      background,
      width,
      height,
      fontSize
    } as CSSProperties;
    return (
      <div
        id={this.id}
        className={className}
        style={style}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={onClick}
      >
        {buttonImage}
      </div>
    );
  }
}
export default Button;
