import * as THREE from "three";
import { Font } from "../../../../../node_modules/three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "../../../../../node_modules/three/examples/jsm/geometries/TextGeometry";
import { myEncodeURI } from "../../../../Utilities/Utilities";

export default class Axes {
  group;
  props;
  constructor(props) {
    this.group = new THREE.Group();
    this.group.name = "Axes";
    this.props = props;
    const { axisLength } = this.props;
    this.addAxis(axisLength, 0, 0, "+X");
    this.addAxis(0, axisLength, 0, "+Y");
    this.addAxis(0, 0, axisLength, "+Z");
  }

  addLabel = async (x, y, z, label) => {
    const myFont = await getFont(this.props.axisLabelFont);
    const geometry = new TextGeometry(label, {
      font: myFont,
      size: this.props.axisLabelFontSize,
      height: this.props.axisLabelFontHeight
    });
    const material = new THREE.MeshBasicMaterial({ color: this.props.axisColor });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.x = x;
    mesh.position.y = y;
    mesh.position.z = z;
    this.group.add(mesh);
  };

  addAxis = (x, y, z, label) => {
    const origin = new THREE.Vector3(-x, -y, -z);
    const direction = new THREE.Vector3(x, y, z);
    direction.normalize();
    const length = 2 * Math.sqrt(x * x + y * y + z * z);
    const color = this.props.axisColor;
    const { axisArrowHeadLength, axisArrowHeadWidth } = this.props;
    const arrow = new THREE.ArrowHelper(direction, origin, length, color, axisArrowHeadLength, axisArrowHeadWidth);
    this.group.add(arrow);
    const { axisLabelOffset } = this.props;
    this.addLabel(x + axisLabelOffset, y + axisLabelOffset, z + axisLabelOffset, label);
  };
}

const getFont = async fontPath => {
  const response = await fetch(myEncodeURI(fontPath));
  const fontDef = await response.json();
  const font = new Font(fontDef);
  return font;
};
