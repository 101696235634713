import React, { Component } from "react";
import Modal from "react-overlays/Modal";

import appStore from "../../Stores/AppStore";
import searchStore from "../../Stores/SearchStore";

interface IProps {
  onAddSelectorOverlayExit: any;
}
export default class AddSelectorOverlay extends Component<IProps> {
  selectorsInOverlay: any[] = [];
  newSelectorAsCopyOfExisting: boolean = false;

  constructor(props) {
    super(props);
    this.selectorsInOverlay = appStore.selectorTypes().map(selectorName => ({ name: selectorName, isChecked: false }));
  }

  renderBackdrop = props => <div className="backdrop" {...props} />;

  handleAddAndReturnSelectors = () => {
    const selectedSelectorNames = this.selectorsInOverlay
      .filter(selector => selector.isChecked)
      .map(selector => selector.name);
    this.props.onAddSelectorOverlayExit(selectedSelectorNames, this.newSelectorAsCopyOfExisting);
  };

  cancel = () => {
    this.selectorsInOverlay.forEach(selector => (selector.isChecked = false));
    this.handleAddAndReturnSelectors();
  };

  toogleCheckedStatusForNewSelector = selectorName => {
    let selectedSelector = this.selectorsInOverlay.find(obj => {
      return obj.name === selectorName;
    });
    if (selectedSelector) {
      selectedSelector.isChecked = !selectedSelector.isChecked;
    }
  };

  toggleDuplicateSelector = ev => {
    this.newSelectorAsCopyOfExisting = !this.newSelectorAsCopyOfExisting;
  };

  render() {
    return (
      <Modal
        className="centered-overlay"
        show
        onHide={this.props.onAddSelectorOverlayExit}
        renderBackdrop={this.renderBackdrop}
        aria-labelledby="modal-label"
        onEscapeKeyDown={ev => searchStore.hideAddSelectorOverlay()}
      >
        <div>
          <div>
            <input type="button" value="Cancel" className="click-button right-button" onClick={this.cancel} />
            <input
              type="button"
              value="Add Selected"
              className="click-button right-button"
              onClick={this.handleAddAndReturnSelectors}
            />
            <h4 id="modal-label">Add Selectors </h4>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "70px" }}>
            <div>
              {this.selectorsInOverlay.map(selectorInOverlay => (
                <div key={selectorInOverlay.name}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={() => {
                        this.toogleCheckedStatusForNewSelector(selectorInOverlay.name);
                      }}
                    />
                    {selectorInOverlay.name}
                  </label>
                </div>
              ))}
            </div>
            <div></div>
          </div>
        </div>
      </Modal>
    );
  }
}
