import { MyLogger } from "../Utilities/Utilities";

const sidebarStatusKey = "sidebarStatus";
const urlKey = "url";

export class LocalStorage {
  lastSelectorIndex = 0;
  // GENERAL set/get ----------------------------------------------------------------

  getAsObject = key => {
    const stringOrNull = localStorage.getItem(key);
    return stringOrNull ? JSON.parse(stringOrNull) : null;
  };

  setAsJSON = (key, value) => localStorage.setItem(key, JSON.stringify(value));
  // Sidebar status ---------------------------------------------------------
  getSidebarStatus = () => this.getAsObject(sidebarStatusKey);
  storeSidebarStatus = status => this.setAsJSON(sidebarStatusKey, status);

  setUrl = (url: string) => localStorage.setItem(urlKey, url);
  getLastUrl = () => localStorage.getItem(urlKey);

  dumpContents = () => {
    MyLogger.bigGreen("localStorage contents at this stage:");
    const localStorageKeyIndices = Array.from(Array(localStorage.length).keys());
    const localStorageKeys = localStorageKeyIndices.map(keyIndex => localStorage.key(keyIndex)!);
    localStorageKeys.forEach(key => MyLogger.green(`${key}=>${localStorage.getItem(key)}`));
  };
}

const myLocalStorage = new LocalStorage();
export default myLocalStorage;
