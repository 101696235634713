export const ephemerisEarth = [
  { isoDate: "2006-01-01T00:00:00Z", dsun: 211.452680, hgln: 0.000000, hglt: -3.010154 },
  { isoDate: "2006-01-02T00:00:00Z", dsun: 211.450021, hgln: 0.000000, hglt: -3.126889 },
  { isoDate: "2006-01-03T00:00:00Z", dsun: 211.448154, hgln: 0.000000, hglt: -3.242652 },
  { isoDate: "2006-01-04T00:00:00Z", dsun: 211.447123, hgln: 0.000000, hglt: -3.357400 },
  { isoDate: "2006-01-05T00:00:00Z", dsun: 211.446989, hgln: -0.000000, hglt: -3.471094 },
  { isoDate: "2006-01-06T00:00:00Z", dsun: 211.447823, hgln: -0.000000, hglt: -3.583693 },
  { isoDate: "2006-01-07T00:00:00Z", dsun: 211.449697, hgln: -0.000000, hglt: -3.695157 },
  { isoDate: "2006-01-08T00:00:00Z", dsun: 211.452681, hgln: -0.000000, hglt: -3.805450 },
  { isoDate: "2006-01-09T00:00:00Z", dsun: 211.456838, hgln: -0.000000, hglt: -3.914534 },
  { isoDate: "2006-01-10T00:00:00Z", dsun: 211.462223, hgln: -0.000000, hglt: -4.022373 },
  { isoDate: "2006-01-11T00:00:00Z", dsun: 211.468881, hgln: 0.000000, hglt: -4.128932 },
  { isoDate: "2006-01-12T00:00:00Z", dsun: 211.476847, hgln: -0.000000, hglt: -4.234178 },
  { isoDate: "2006-01-13T00:00:00Z", dsun: 211.486143, hgln: -0.000000, hglt: -4.338076 },
  { isoDate: "2006-01-14T00:00:00Z", dsun: 211.496782, hgln: -0.000000, hglt: -4.440596 },
  { isoDate: "2006-01-15T00:00:00Z", dsun: 211.508767, hgln: 0.000000, hglt: -4.541705 },
  { isoDate: "2006-01-16T00:00:00Z", dsun: 211.522088, hgln: 0.000000, hglt: -4.641373 },
  { isoDate: "2006-01-17T00:00:00Z", dsun: 211.536729, hgln: 0.000000, hglt: -4.739570 },
  { isoDate: "2006-01-18T00:00:00Z", dsun: 211.552665, hgln: 0.000000, hglt: -4.836266 },
  { isoDate: "2006-01-19T00:00:00Z", dsun: 211.569863, hgln: 0.000000, hglt: -4.931432 },
  { isoDate: "2006-01-20T00:00:00Z", dsun: 211.588284, hgln: 0.000000, hglt: -5.025040 },
  { isoDate: "2006-01-21T00:00:00Z", dsun: 211.607884, hgln: 0.000000, hglt: -5.117061 },
  { isoDate: "2006-01-22T00:00:00Z", dsun: 211.628611, hgln: -0.000000, hglt: -5.207468 },
  { isoDate: "2006-01-23T00:00:00Z", dsun: 211.650411, hgln: 0.000000, hglt: -5.296233 },
  { isoDate: "2006-01-24T00:00:00Z", dsun: 211.673221, hgln: -0.000000, hglt: -5.383331 },
  { isoDate: "2006-01-25T00:00:00Z", dsun: 211.696973, hgln: -0.000000, hglt: -5.468733 },
  { isoDate: "2006-01-26T00:00:00Z", dsun: 211.721598, hgln: -0.000000, hglt: -5.552415 },
  { isoDate: "2006-01-27T00:00:00Z", dsun: 211.747025, hgln: 0.000000, hglt: -5.634349 },
  { isoDate: "2006-01-28T00:00:00Z", dsun: 211.773188, hgln: 0.000000, hglt: -5.714509 },
  { isoDate: "2006-01-29T00:00:00Z", dsun: 211.800031, hgln: 0.000000, hglt: -5.792867 },
  { isoDate: "2006-01-30T00:00:00Z", dsun: 211.827515, hgln: 0.000000, hglt: -5.869398 },
  { isoDate: "2006-01-31T00:00:00Z", dsun: 211.855627, hgln: -0.000000, hglt: -5.944075 },
  { isoDate: "2006-02-01T00:00:00Z", dsun: 211.884377, hgln: 0.000000, hglt: -6.016869 },
  { isoDate: "2006-02-02T00:00:00Z", dsun: 211.913798, hgln: -0.000000, hglt: -6.087756 },
  { isoDate: "2006-02-03T00:00:00Z", dsun: 211.943938, hgln: 0.000000, hglt: -6.156709 },
  { isoDate: "2006-02-04T00:00:00Z", dsun: 211.974858, hgln: 0.000000, hglt: -6.223706 },
  { isoDate: "2006-02-05T00:00:00Z", dsun: 212.006618, hgln: -0.000000, hglt: -6.288724 },
  { isoDate: "2006-02-06T00:00:00Z", dsun: 212.039276, hgln: 0.000000, hglt: -6.351742 },
  { isoDate: "2006-02-07T00:00:00Z", dsun: 212.072884, hgln: -0.000000, hglt: -6.412741 },
  { isoDate: "2006-02-08T00:00:00Z", dsun: 212.107488, hgln: -0.000000, hglt: -6.471701 },
  { isoDate: "2006-02-09T00:00:00Z", dsun: 212.143124, hgln: 0.000000, hglt: -6.528607 },
  { isoDate: "2006-02-10T00:00:00Z", dsun: 212.179820, hgln: -0.000000, hglt: -6.583442 },
  { isoDate: "2006-02-11T00:00:00Z", dsun: 212.217594, hgln: -0.000000, hglt: -6.636191 },
  { isoDate: "2006-02-12T00:00:00Z", dsun: 212.256456, hgln: 0.000000, hglt: -6.686842 },
  { isoDate: "2006-02-13T00:00:00Z", dsun: 212.296409, hgln: -0.000000, hglt: -6.735380 },
  { isoDate: "2006-02-14T00:00:00Z", dsun: 212.337444, hgln: -0.000000, hglt: -6.781794 },
  { isoDate: "2006-02-15T00:00:00Z", dsun: 212.379546, hgln: -0.000000, hglt: -6.826073 },
  { isoDate: "2006-02-16T00:00:00Z", dsun: 212.422690, hgln: 0.000000, hglt: -6.868206 },
  { isoDate: "2006-02-17T00:00:00Z", dsun: 212.466843, hgln: 0.000000, hglt: -6.908185 },
  { isoDate: "2006-02-18T00:00:00Z", dsun: 212.511966, hgln: 0.000000, hglt: -6.946001 },
  { isoDate: "2006-02-19T00:00:00Z", dsun: 212.558008, hgln: -0.000000, hglt: -6.981645 },
  { isoDate: "2006-02-20T00:00:00Z", dsun: 212.604916, hgln: -0.000000, hglt: -7.015110 },
  { isoDate: "2006-02-21T00:00:00Z", dsun: 212.652624, hgln: -0.000000, hglt: -7.046389 },
  { isoDate: "2006-02-22T00:00:00Z", dsun: 212.701062, hgln: -0.000000, hglt: -7.075475 },
  { isoDate: "2006-02-23T00:00:00Z", dsun: 212.750153, hgln: 0.000000, hglt: -7.102363 },
  { isoDate: "2006-02-24T00:00:00Z", dsun: 212.799813, hgln: -0.000000, hglt: -7.127047 },
  { isoDate: "2006-02-25T00:00:00Z", dsun: 212.849957, hgln: -0.000000, hglt: -7.149522 },
  { isoDate: "2006-02-26T00:00:00Z", dsun: 212.900505, hgln: -0.000000, hglt: -7.169783 },
  { isoDate: "2006-02-27T00:00:00Z", dsun: 212.951385, hgln: -0.000000, hglt: -7.187823 },
  { isoDate: "2006-02-28T00:00:00Z", dsun: 213.002545, hgln: 0.000000, hglt: -7.203639 },
  { isoDate: "2006-03-01T00:00:00Z", dsun: 213.053955, hgln: 0.000000, hglt: -7.217225 },
  { isoDate: "2006-03-02T00:00:00Z", dsun: 213.105612, hgln: -0.000000, hglt: -7.228577 },
  { isoDate: "2006-03-03T00:00:00Z", dsun: 213.157537, hgln: -0.000000, hglt: -7.237694 },
  { isoDate: "2006-03-04T00:00:00Z", dsun: 213.209766, hgln: -0.000000, hglt: -7.244571 },
  { isoDate: "2006-03-05T00:00:00Z", dsun: 213.262347, hgln: -0.000000, hglt: -7.249210 },
  { isoDate: "2006-03-06T00:00:00Z", dsun: 213.315332, hgln: 0.000000, hglt: -7.251611 },
  { isoDate: "2006-03-07T00:00:00Z", dsun: 213.368769, hgln: 0.000000, hglt: -7.251776 },
  { isoDate: "2006-03-08T00:00:00Z", dsun: 213.422703, hgln: -0.000000, hglt: -7.249709 },
  { isoDate: "2006-03-09T00:00:00Z", dsun: 213.477172, hgln: -0.000000, hglt: -7.245413 },
  { isoDate: "2006-03-10T00:00:00Z", dsun: 213.532209, hgln: 0.000000, hglt: -7.238896 },
  { isoDate: "2006-03-11T00:00:00Z", dsun: 213.587839, hgln: 0.000000, hglt: -7.230162 },
  { isoDate: "2006-03-12T00:00:00Z", dsun: 213.644081, hgln: 0.000000, hglt: -7.219220 },
  { isoDate: "2006-03-13T00:00:00Z", dsun: 213.700945, hgln: -0.000000, hglt: -7.206078 },
  { isoDate: "2006-03-14T00:00:00Z", dsun: 213.758437, hgln: 0.000000, hglt: -7.190745 },
  { isoDate: "2006-03-15T00:00:00Z", dsun: 213.816553, hgln: 0.000000, hglt: -7.173230 },
  { isoDate: "2006-03-16T00:00:00Z", dsun: 213.875282, hgln: -0.000000, hglt: -7.153545 },
  { isoDate: "2006-03-17T00:00:00Z", dsun: 213.934602, hgln: -0.000000, hglt: -7.131701 },
  { isoDate: "2006-03-18T00:00:00Z", dsun: 213.994486, hgln: -0.000000, hglt: -7.107708 },
  { isoDate: "2006-03-19T00:00:00Z", dsun: 214.054893, hgln: 0.000000, hglt: -7.081580 },
  { isoDate: "2006-03-20T00:00:00Z", dsun: 214.115776, hgln: 0.000000, hglt: -7.053330 },
  { isoDate: "2006-03-21T00:00:00Z", dsun: 214.177077, hgln: 0.000000, hglt: -7.022970 },
  { isoDate: "2006-03-22T00:00:00Z", dsun: 214.238728, hgln: 0.000000, hglt: -6.990514 },
  { isoDate: "2006-03-23T00:00:00Z", dsun: 214.300653, hgln: -0.000000, hglt: -6.955977 },
  { isoDate: "2006-03-24T00:00:00Z", dsun: 214.362769, hgln: -0.000000, hglt: -6.919371 },
  { isoDate: "2006-03-25T00:00:00Z", dsun: 214.424985, hgln: 0.000000, hglt: -6.880713 },
  { isoDate: "2006-03-26T00:00:00Z", dsun: 214.487210, hgln: 0.000000, hglt: -6.840016 },
  { isoDate: "2006-03-27T00:00:00Z", dsun: 214.549357, hgln: -0.000000, hglt: -6.797295 },
  { isoDate: "2006-03-28T00:00:00Z", dsun: 214.611348, hgln: -0.000000, hglt: -6.752566 },
  { isoDate: "2006-03-29T00:00:00Z", dsun: 214.673124, hgln: 0.000000, hglt: -6.705845 },
  { isoDate: "2006-03-30T00:00:00Z", dsun: 214.734647, hgln: 0.000000, hglt: -6.657147 },
  { isoDate: "2006-03-31T00:00:00Z", dsun: 214.795905, hgln: 0.000000, hglt: -6.606491 },
  { isoDate: "2006-04-01T00:00:00Z", dsun: 214.856908, hgln: -0.000000, hglt: -6.553893 },
  { isoDate: "2006-04-02T00:00:00Z", dsun: 214.917685, hgln: 0.000000, hglt: -6.499373 },
  { isoDate: "2006-04-03T00:00:00Z", dsun: 214.978273, hgln: -0.000000, hglt: -6.442951 },
  { isoDate: "2006-04-04T00:00:00Z", dsun: 215.038715, hgln: -0.000000, hglt: -6.384647 },
  { isoDate: "2006-04-05T00:00:00Z", dsun: 215.099054, hgln: 0.000000, hglt: -6.324483 },
  { isoDate: "2006-04-06T00:00:00Z", dsun: 215.159331, hgln: 0.000000, hglt: -6.262481 },
  { isoDate: "2006-04-07T00:00:00Z", dsun: 215.219584, hgln: -0.000000, hglt: -6.198664 },
  { isoDate: "2006-04-08T00:00:00Z", dsun: 215.279844, hgln: 0.000000, hglt: -6.133056 },
  { isoDate: "2006-04-09T00:00:00Z", dsun: 215.340139, hgln: 0.000000, hglt: -6.065681 },
  { isoDate: "2006-04-10T00:00:00Z", dsun: 215.400493, hgln: 0.000000, hglt: -5.996562 },
  { isoDate: "2006-04-11T00:00:00Z", dsun: 215.460923, hgln: 0.000000, hglt: -5.925725 },
  { isoDate: "2006-04-12T00:00:00Z", dsun: 215.521442, hgln: 0.000000, hglt: -5.853194 },
  { isoDate: "2006-04-13T00:00:00Z", dsun: 215.582054, hgln: 0.000000, hglt: -5.778996 },
  { isoDate: "2006-04-14T00:00:00Z", dsun: 215.642759, hgln: 0.000000, hglt: -5.703156 },
  { isoDate: "2006-04-15T00:00:00Z", dsun: 215.703544, hgln: -0.000000, hglt: -5.625700 },
  { isoDate: "2006-04-16T00:00:00Z", dsun: 215.764387, hgln: 0.000000, hglt: -5.546654 },
  { isoDate: "2006-04-17T00:00:00Z", dsun: 215.825257, hgln: -0.000000, hglt: -5.466044 },
  { isoDate: "2006-04-18T00:00:00Z", dsun: 215.886107, hgln: 0.000000, hglt: -5.383897 },
  { isoDate: "2006-04-19T00:00:00Z", dsun: 215.946883, hgln: 0.000000, hglt: -5.300238 },
  { isoDate: "2006-04-20T00:00:00Z", dsun: 216.007515, hgln: -0.000000, hglt: -5.215094 },
  { isoDate: "2006-04-21T00:00:00Z", dsun: 216.067928, hgln: -0.000000, hglt: -5.128491 },
  { isoDate: "2006-04-22T00:00:00Z", dsun: 216.128035, hgln: -0.000000, hglt: -5.040456 },
  { isoDate: "2006-04-23T00:00:00Z", dsun: 216.187746, hgln: -0.000000, hglt: -4.951016 },
  { isoDate: "2006-04-24T00:00:00Z", dsun: 216.246969, hgln: 0.000000, hglt: -4.860196 },
  { isoDate: "2006-04-25T00:00:00Z", dsun: 216.305618, hgln: -0.000000, hglt: -4.768025 },
  { isoDate: "2006-04-26T00:00:00Z", dsun: 216.363618, hgln: -0.000000, hglt: -4.674529 },
  { isoDate: "2006-04-27T00:00:00Z", dsun: 216.420910, hgln: -0.000000, hglt: -4.579738 },
  { isoDate: "2006-04-28T00:00:00Z", dsun: 216.477457, hgln: 0.000000, hglt: -4.483680 },
  { isoDate: "2006-04-29T00:00:00Z", dsun: 216.533242, hgln: -0.000000, hglt: -4.386384 },
  { isoDate: "2006-04-30T00:00:00Z", dsun: 216.588271, hgln: 0.000000, hglt: -4.287882 },
  { isoDate: "2006-05-01T00:00:00Z", dsun: 216.642562, hgln: 0.000000, hglt: -4.188203 },
  { isoDate: "2006-05-02T00:00:00Z", dsun: 216.696147, hgln: -0.000000, hglt: -4.087380 },
  { isoDate: "2006-05-03T00:00:00Z", dsun: 216.749064, hgln: 0.000000, hglt: -3.985444 },
  { isoDate: "2006-05-04T00:00:00Z", dsun: 216.801353, hgln: 0.000000, hglt: -3.882426 },
  { isoDate: "2006-05-05T00:00:00Z", dsun: 216.853052, hgln: 0.000000, hglt: -3.778360 },
  { isoDate: "2006-05-06T00:00:00Z", dsun: 216.904202, hgln: -0.000000, hglt: -3.673276 },
  { isoDate: "2006-05-07T00:00:00Z", dsun: 216.954837, hgln: -0.000000, hglt: -3.567208 },
  { isoDate: "2006-05-08T00:00:00Z", dsun: 217.004992, hgln: -0.000000, hglt: -3.460188 },
  { isoDate: "2006-05-09T00:00:00Z", dsun: 217.054698, hgln: 0.000000, hglt: -3.352247 },
  { isoDate: "2006-05-10T00:00:00Z", dsun: 217.103985, hgln: 0.000000, hglt: -3.243419 },
  { isoDate: "2006-05-11T00:00:00Z", dsun: 217.152875, hgln: 0.000000, hglt: -3.133735 },
  { isoDate: "2006-05-12T00:00:00Z", dsun: 217.201389, hgln: 0.000000, hglt: -3.023228 },
  { isoDate: "2006-05-13T00:00:00Z", dsun: 217.249536, hgln: 0.000000, hglt: -2.911929 },
  { isoDate: "2006-05-14T00:00:00Z", dsun: 217.297320, hgln: 0.000000, hglt: -2.799870 },
  { isoDate: "2006-05-15T00:00:00Z", dsun: 217.344730, hgln: -0.000000, hglt: -2.687082 },
  { isoDate: "2006-05-16T00:00:00Z", dsun: 217.391742, hgln: 0.000000, hglt: -2.573596 },
  { isoDate: "2006-05-17T00:00:00Z", dsun: 217.438320, hgln: 0.000000, hglt: -2.459443 },
  { isoDate: "2006-05-18T00:00:00Z", dsun: 217.484410, hgln: -0.000000, hglt: -2.344653 },
  { isoDate: "2006-05-19T00:00:00Z", dsun: 217.529948, hgln: -0.000000, hglt: -2.229256 },
  { isoDate: "2006-05-20T00:00:00Z", dsun: 217.574857, hgln: 0.000000, hglt: -2.113282 },
  { isoDate: "2006-05-21T00:00:00Z", dsun: 217.619053, hgln: -0.000000, hglt: -1.996761 },
  { isoDate: "2006-05-22T00:00:00Z", dsun: 217.662451, hgln: 0.000000, hglt: -1.879724 },
  { isoDate: "2006-05-23T00:00:00Z", dsun: 217.704964, hgln: 0.000000, hglt: -1.762200 },
  { isoDate: "2006-05-24T00:00:00Z", dsun: 217.746513, hgln: 0.000000, hglt: -1.644223 },
  { isoDate: "2006-05-25T00:00:00Z", dsun: 217.787032, hgln: -0.000000, hglt: -1.525823 },
  { isoDate: "2006-05-26T00:00:00Z", dsun: 217.826467, hgln: -0.000000, hglt: -1.407033 },
  { isoDate: "2006-05-27T00:00:00Z", dsun: 217.864785, hgln: -0.000000, hglt: -1.287886 },
  { isoDate: "2006-05-28T00:00:00Z", dsun: 217.901970, hgln: -0.000000, hglt: -1.168416 },
  { isoDate: "2006-05-29T00:00:00Z", dsun: 217.938024, hgln: 0.000000, hglt: -1.048657 },
  { isoDate: "2006-05-30T00:00:00Z", dsun: 217.972963, hgln: 0.000000, hglt: -0.928644 },
  { isoDate: "2006-05-31T00:00:00Z", dsun: 218.006814, hgln: -0.000000, hglt: -0.808411 },
  { isoDate: "2006-06-01T00:00:00Z", dsun: 218.039610, hgln: 0.000000, hglt: -0.687993 },
  { isoDate: "2006-06-02T00:00:00Z", dsun: 218.071389, hgln: 0.000000, hglt: -0.567424 },
  { isoDate: "2006-06-03T00:00:00Z", dsun: 218.102192, hgln: -0.000000, hglt: -0.446738 },
  { isoDate: "2006-06-04T00:00:00Z", dsun: 218.132061, hgln: -0.000000, hglt: -0.325970 },
  { isoDate: "2006-06-05T00:00:00Z", dsun: 218.161037, hgln: 0.000000, hglt: -0.205153 },
  { isoDate: "2006-06-06T00:00:00Z", dsun: 218.189162, hgln: 0.000000, hglt: -0.084321 },
  { isoDate: "2006-06-07T00:00:00Z", dsun: 218.216479, hgln: -0.000000, hglt: 0.036493 },
  { isoDate: "2006-06-08T00:00:00Z", dsun: 218.243029, hgln: -0.000000, hglt: 0.157255 },
  { isoDate: "2006-06-09T00:00:00Z", dsun: 218.268850, hgln: 0.000000, hglt: 0.277933 },
  { isoDate: "2006-06-10T00:00:00Z", dsun: 218.293978, hgln: 0.000000, hglt: 0.398496 },
  { isoDate: "2006-06-11T00:00:00Z", dsun: 218.318441, hgln: -0.000000, hglt: 0.518911 },
  { isoDate: "2006-06-12T00:00:00Z", dsun: 218.342257, hgln: 0.000000, hglt: 0.639147 },
  { isoDate: "2006-06-13T00:00:00Z", dsun: 218.365430, hgln: -0.000000, hglt: 0.759176 },
  { isoDate: "2006-06-14T00:00:00Z", dsun: 218.387949, hgln: 0.000000, hglt: 0.878966 },
  { isoDate: "2006-06-15T00:00:00Z", dsun: 218.409785, hgln: -0.000000, hglt: 0.998490 },
  { isoDate: "2006-06-16T00:00:00Z", dsun: 218.430890, hgln: -0.000000, hglt: 1.117719 },
  { isoDate: "2006-06-17T00:00:00Z", dsun: 218.451204, hgln: 0.000000, hglt: 1.236624 },
  { isoDate: "2006-06-18T00:00:00Z", dsun: 218.470654, hgln: -0.000000, hglt: 1.355177 },
  { isoDate: "2006-06-19T00:00:00Z", dsun: 218.489160, hgln: -0.000000, hglt: 1.473350 },
  { isoDate: "2006-06-20T00:00:00Z", dsun: 218.506644, hgln: 0.000000, hglt: 1.591114 },
  { isoDate: "2006-06-21T00:00:00Z", dsun: 218.523029, hgln: -0.000000, hglt: 1.708438 },
  { isoDate: "2006-06-22T00:00:00Z", dsun: 218.538247, hgln: 0.000000, hglt: 1.825293 },
  { isoDate: "2006-06-23T00:00:00Z", dsun: 218.552239, hgln: 0.000000, hglt: 1.941648 },
  { isoDate: "2006-06-24T00:00:00Z", dsun: 218.564965, hgln: 0.000000, hglt: 2.057471 },
  { isoDate: "2006-06-25T00:00:00Z", dsun: 218.576394, hgln: -0.000000, hglt: 2.172730 },
  { isoDate: "2006-06-26T00:00:00Z", dsun: 218.586515, hgln: -0.000000, hglt: 2.287393 },
  { isoDate: "2006-06-27T00:00:00Z", dsun: 218.595329, hgln: 0.000000, hglt: 2.401428 },
  { isoDate: "2006-06-28T00:00:00Z", dsun: 218.602850, hgln: -0.000000, hglt: 2.514802 },
  { isoDate: "2006-06-29T00:00:00Z", dsun: 218.609102, hgln: 0.000000, hglt: 2.627483 },
  { isoDate: "2006-06-30T00:00:00Z", dsun: 218.614117, hgln: -0.000000, hglt: 2.739438 },
  { isoDate: "2006-07-01T00:00:00Z", dsun: 218.617931, hgln: 0.000000, hglt: 2.850635 },
  { isoDate: "2006-07-02T00:00:00Z", dsun: 218.620588, hgln: 0.000000, hglt: 2.961044 },
  { isoDate: "2006-07-03T00:00:00Z", dsun: 218.622133, hgln: -0.000000, hglt: 3.070632 },
  { isoDate: "2006-07-04T00:00:00Z", dsun: 218.622613, hgln: 0.000000, hglt: 3.179369 },
  { isoDate: "2006-07-05T00:00:00Z", dsun: 218.622080, hgln: 0.000000, hglt: 3.287225 },
  { isoDate: "2006-07-06T00:00:00Z", dsun: 218.620586, hgln: 0.000000, hglt: 3.394169 },
  { isoDate: "2006-07-07T00:00:00Z", dsun: 218.618185, hgln: -0.000000, hglt: 3.500172 },
  { isoDate: "2006-07-08T00:00:00Z", dsun: 218.614933, hgln: 0.000000, hglt: 3.605206 },
  { isoDate: "2006-07-09T00:00:00Z", dsun: 218.610882, hgln: 0.000000, hglt: 3.709242 },
  { isoDate: "2006-07-10T00:00:00Z", dsun: 218.606077, hgln: -0.000000, hglt: 3.812254 },
  { isoDate: "2006-07-11T00:00:00Z", dsun: 218.600555, hgln: -0.000000, hglt: 3.914215 },
  { isoDate: "2006-07-12T00:00:00Z", dsun: 218.594336, hgln: -0.000000, hglt: 4.015100 },
  { isoDate: "2006-07-13T00:00:00Z", dsun: 218.587421, hgln: 0.000000, hglt: 4.114886 },
  { isoDate: "2006-07-14T00:00:00Z", dsun: 218.579790, hgln: -0.000000, hglt: 4.213548 },
  { isoDate: "2006-07-15T00:00:00Z", dsun: 218.571403, hgln: -0.000000, hglt: 4.311063 },
  { isoDate: "2006-07-16T00:00:00Z", dsun: 218.562202, hgln: -0.000000, hglt: 4.407409 },
  { isoDate: "2006-07-17T00:00:00Z", dsun: 218.552122, hgln: -0.000000, hglt: 4.502562 },
  { isoDate: "2006-07-18T00:00:00Z", dsun: 218.541090, hgln: 0.000000, hglt: 4.596497 },
  { isoDate: "2006-07-19T00:00:00Z", dsun: 218.529035, hgln: -0.000000, hglt: 4.689192 },
  { isoDate: "2006-07-20T00:00:00Z", dsun: 218.515892, hgln: -0.000000, hglt: 4.780621 },
  { isoDate: "2006-07-21T00:00:00Z", dsun: 218.501603, hgln: -0.000000, hglt: 4.870758 },
  { isoDate: "2006-07-22T00:00:00Z", dsun: 218.486123, hgln: 0.000000, hglt: 4.959578 },
  { isoDate: "2006-07-23T00:00:00Z", dsun: 218.469417, hgln: 0.000000, hglt: 5.047056 },
  { isoDate: "2006-07-24T00:00:00Z", dsun: 218.451463, hgln: 0.000000, hglt: 5.133164 },
  { isoDate: "2006-07-25T00:00:00Z", dsun: 218.432251, hgln: -0.000000, hglt: 5.217876 },
  { isoDate: "2006-07-26T00:00:00Z", dsun: 218.411784, hgln: -0.000000, hglt: 5.301167 },
  { isoDate: "2006-07-27T00:00:00Z", dsun: 218.390074, hgln: -0.000000, hglt: 5.383010 },
  { isoDate: "2006-07-28T00:00:00Z", dsun: 218.367145, hgln: 0.000000, hglt: 5.463379 },
  { isoDate: "2006-07-29T00:00:00Z", dsun: 218.343027, hgln: 0.000000, hglt: 5.542250 },
  { isoDate: "2006-07-30T00:00:00Z", dsun: 218.317757, hgln: -0.000000, hglt: 5.619597 },
  { isoDate: "2006-07-31T00:00:00Z", dsun: 218.291380, hgln: 0.000000, hglt: 5.695396 },
  { isoDate: "2006-08-01T00:00:00Z", dsun: 218.263944, hgln: 0.000000, hglt: 5.769624 },
  { isoDate: "2006-08-02T00:00:00Z", dsun: 218.235502, hgln: -0.000000, hglt: 5.842256 },
  { isoDate: "2006-08-03T00:00:00Z", dsun: 218.206114, hgln: -0.000000, hglt: 5.913270 },
  { isoDate: "2006-08-04T00:00:00Z", dsun: 218.175842, hgln: 0.000000, hglt: 5.982644 },
  { isoDate: "2006-08-05T00:00:00Z", dsun: 218.144752, hgln: 0.000000, hglt: 6.050357 },
  { isoDate: "2006-08-06T00:00:00Z", dsun: 218.112915, hgln: 0.000000, hglt: 6.116389 },
  { isoDate: "2006-08-07T00:00:00Z", dsun: 218.080398, hgln: -0.000000, hglt: 6.180720 },
  { isoDate: "2006-08-08T00:00:00Z", dsun: 218.047265, hgln: 0.000000, hglt: 6.243331 },
  { isoDate: "2006-08-09T00:00:00Z", dsun: 218.013569, hgln: -0.000000, hglt: 6.304207 },
  { isoDate: "2006-08-10T00:00:00Z", dsun: 217.979346, hgln: 0.000000, hglt: 6.363330 },
  { isoDate: "2006-08-11T00:00:00Z", dsun: 217.944608, hgln: -0.000000, hglt: 6.420685 },
  { isoDate: "2006-08-12T00:00:00Z", dsun: 217.909346, hgln: -0.000000, hglt: 6.476259 },
  { isoDate: "2006-08-13T00:00:00Z", dsun: 217.873526, hgln: 0.000000, hglt: 6.530037 },
  { isoDate: "2006-08-14T00:00:00Z", dsun: 217.837098, hgln: -0.000000, hglt: 6.582005 },
  { isoDate: "2006-08-15T00:00:00Z", dsun: 217.800001, hgln: 0.000000, hglt: 6.632149 },
  { isoDate: "2006-08-16T00:00:00Z", dsun: 217.762173, hgln: -0.000000, hglt: 6.680453 },
  { isoDate: "2006-08-17T00:00:00Z", dsun: 217.723552, hgln: 0.000000, hglt: 6.726903 },
  { isoDate: "2006-08-18T00:00:00Z", dsun: 217.684083, hgln: -0.000000, hglt: 6.771484 },
  { isoDate: "2006-08-19T00:00:00Z", dsun: 217.643718, hgln: 0.000000, hglt: 6.814181 },
  { isoDate: "2006-08-20T00:00:00Z", dsun: 217.602421, hgln: -0.000000, hglt: 6.854977 },
  { isoDate: "2006-08-21T00:00:00Z", dsun: 217.560164, hgln: -0.000000, hglt: 6.893858 },
  { isoDate: "2006-08-22T00:00:00Z", dsun: 217.516929, hgln: -0.000000, hglt: 6.930808 },
  { isoDate: "2006-08-23T00:00:00Z", dsun: 217.472709, hgln: -0.000000, hglt: 6.965812 },
  { isoDate: "2006-08-24T00:00:00Z", dsun: 217.427506, hgln: -0.000000, hglt: 6.998857 },
  { isoDate: "2006-08-25T00:00:00Z", dsun: 217.381333, hgln: -0.000000, hglt: 7.029928 },
  { isoDate: "2006-08-26T00:00:00Z", dsun: 217.334211, hgln: 0.000000, hglt: 7.059012 },
  { isoDate: "2006-08-27T00:00:00Z", dsun: 217.286168, hgln: -0.000000, hglt: 7.086096 },
  { isoDate: "2006-08-28T00:00:00Z", dsun: 217.237243, hgln: -0.000000, hglt: 7.111168 },
  { isoDate: "2006-08-29T00:00:00Z", dsun: 217.187478, hgln: 0.000000, hglt: 7.134216 },
  { isoDate: "2006-08-30T00:00:00Z", dsun: 217.136926, hgln: -0.000000, hglt: 7.155229 },
  { isoDate: "2006-08-31T00:00:00Z", dsun: 217.085645, hgln: -0.000000, hglt: 7.174198 },
  { isoDate: "2006-09-01T00:00:00Z", dsun: 217.033698, hgln: -0.000000, hglt: 7.191114 },
  { isoDate: "2006-09-02T00:00:00Z", dsun: 216.981156, hgln: 0.000000, hglt: 7.205968 },
  { isoDate: "2006-09-03T00:00:00Z", dsun: 216.928097, hgln: 0.000000, hglt: 7.218752 },
  { isoDate: "2006-09-04T00:00:00Z", dsun: 216.874601, hgln: 0.000000, hglt: 7.229461 },
  { isoDate: "2006-09-05T00:00:00Z", dsun: 216.820750, hgln: -0.000000, hglt: 7.238088 },
  { isoDate: "2006-09-06T00:00:00Z", dsun: 216.766621, hgln: -0.000000, hglt: 7.244631 },
  { isoDate: "2006-09-07T00:00:00Z", dsun: 216.712281, hgln: 0.000000, hglt: 7.249086 },
  { isoDate: "2006-09-08T00:00:00Z", dsun: 216.657779, hgln: 0.000000, hglt: 7.251451 },
  { isoDate: "2006-09-09T00:00:00Z", dsun: 216.603138, hgln: 0.000000, hglt: 7.251725 },
  { isoDate: "2006-09-10T00:00:00Z", dsun: 216.548357, hgln: 0.000000, hglt: 7.249907 },
  { isoDate: "2006-09-11T00:00:00Z", dsun: 216.493410, hgln: 0.000000, hglt: 7.245996 },
  { isoDate: "2006-09-12T00:00:00Z", dsun: 216.438257, hgln: 0.000000, hglt: 7.239992 },
  { isoDate: "2006-09-13T00:00:00Z", dsun: 216.382843, hgln: 0.000000, hglt: 7.231895 },
  { isoDate: "2006-09-14T00:00:00Z", dsun: 216.327116, hgln: 0.000000, hglt: 7.221704 },
  { isoDate: "2006-09-15T00:00:00Z", dsun: 216.271021, hgln: -0.000000, hglt: 7.209419 },
  { isoDate: "2006-09-16T00:00:00Z", dsun: 216.214513, hgln: 0.000000, hglt: 7.195039 },
  { isoDate: "2006-09-17T00:00:00Z", dsun: 216.157551, hgln: 0.000000, hglt: 7.178565 },
  { isoDate: "2006-09-18T00:00:00Z", dsun: 216.100103, hgln: -0.000000, hglt: 7.159996 },
  { isoDate: "2006-09-19T00:00:00Z", dsun: 216.042146, hgln: 0.000000, hglt: 7.139333 },
  { isoDate: "2006-09-20T00:00:00Z", dsun: 215.983662, hgln: -0.000000, hglt: 7.116579 },
  { isoDate: "2006-09-21T00:00:00Z", dsun: 215.924646, hgln: 0.000000, hglt: 7.091735 },
  { isoDate: "2006-09-22T00:00:00Z", dsun: 215.865096, hgln: -0.000000, hglt: 7.064804 },
  { isoDate: "2006-09-23T00:00:00Z", dsun: 215.805022, hgln: -0.000000, hglt: 7.035788 },
  { isoDate: "2006-09-24T00:00:00Z", dsun: 215.744441, hgln: 0.000000, hglt: 7.004693 },
  { isoDate: "2006-09-25T00:00:00Z", dsun: 215.683379, hgln: -0.000000, hglt: 6.971523 },
  { isoDate: "2006-09-26T00:00:00Z", dsun: 215.621870, hgln: -0.000000, hglt: 6.936284 },
  { isoDate: "2006-09-27T00:00:00Z", dsun: 215.559957, hgln: -0.000000, hglt: 6.898983 },
  { isoDate: "2006-09-28T00:00:00Z", dsun: 215.497692, hgln: 0.000000, hglt: 6.859626 },
  { isoDate: "2006-09-29T00:00:00Z", dsun: 215.435133, hgln: 0.000000, hglt: 6.818223 },
  { isoDate: "2006-09-30T00:00:00Z", dsun: 215.372348, hgln: -0.000000, hglt: 6.774782 },
  { isoDate: "2006-10-01T00:00:00Z", dsun: 215.309414, hgln: -0.000000, hglt: 6.729314 },
  { isoDate: "2006-10-02T00:00:00Z", dsun: 215.246413, hgln: 0.000000, hglt: 6.681830 },
  { isoDate: "2006-10-03T00:00:00Z", dsun: 215.183434, hgln: 0.000000, hglt: 6.632343 },
  { isoDate: "2006-10-04T00:00:00Z", dsun: 215.120565, hgln: 0.000000, hglt: 6.580864 },
  { isoDate: "2006-10-05T00:00:00Z", dsun: 215.057893, hgln: 0.000000, hglt: 6.527410 },
  { isoDate: "2006-10-06T00:00:00Z", dsun: 214.995492, hgln: -0.000000, hglt: 6.471993 },
  { isoDate: "2006-10-07T00:00:00Z", dsun: 214.933419, hgln: 0.000000, hglt: 6.414629 },
  { isoDate: "2006-10-08T00:00:00Z", dsun: 214.871706, hgln: -0.000000, hglt: 6.355335 },
  { isoDate: "2006-10-09T00:00:00Z", dsun: 214.810358, hgln: -0.000000, hglt: 6.294125 },
  { isoDate: "2006-10-10T00:00:00Z", dsun: 214.749360, hgln: 0.000000, hglt: 6.231015 },
  { isoDate: "2006-10-11T00:00:00Z", dsun: 214.688678, hgln: 0.000000, hglt: 6.166022 },
  { isoDate: "2006-10-12T00:00:00Z", dsun: 214.628268, hgln: 0.000000, hglt: 6.099162 },
  { isoDate: "2006-10-13T00:00:00Z", dsun: 214.568082, hgln: 0.000000, hglt: 6.030451 },
  { isoDate: "2006-10-14T00:00:00Z", dsun: 214.508074, hgln: 0.000000, hglt: 5.959907 },
  { isoDate: "2006-10-15T00:00:00Z", dsun: 214.448204, hgln: 0.000000, hglt: 5.887546 },
  { isoDate: "2006-10-16T00:00:00Z", dsun: 214.388433, hgln: -0.000000, hglt: 5.813386 },
  { isoDate: "2006-10-17T00:00:00Z", dsun: 214.328731, hgln: 0.000000, hglt: 5.737447 },
  { isoDate: "2006-10-18T00:00:00Z", dsun: 214.269073, hgln: -0.000000, hglt: 5.659748 },
  { isoDate: "2006-10-19T00:00:00Z", dsun: 214.209439, hgln: -0.000000, hglt: 5.580308 },
  { isoDate: "2006-10-20T00:00:00Z", dsun: 214.149818, hgln: 0.000000, hglt: 5.499148 },
  { isoDate: "2006-10-21T00:00:00Z", dsun: 214.090203, hgln: -0.000000, hglt: 5.416290 },
  { isoDate: "2006-10-22T00:00:00Z", dsun: 214.030596, hgln: -0.000000, hglt: 5.331755 },
  { isoDate: "2006-10-23T00:00:00Z", dsun: 213.971005, hgln: 0.000000, hglt: 5.245568 },
  { isoDate: "2006-10-24T00:00:00Z", dsun: 213.911449, hgln: 0.000000, hglt: 5.157752 },
  { isoDate: "2006-10-25T00:00:00Z", dsun: 213.851955, hgln: -0.000000, hglt: 5.068331 },
  { isoDate: "2006-10-26T00:00:00Z", dsun: 213.792561, hgln: 0.000000, hglt: 4.977332 },
  { isoDate: "2006-10-27T00:00:00Z", dsun: 213.733314, hgln: 0.000000, hglt: 4.884781 },
  { isoDate: "2006-10-28T00:00:00Z", dsun: 213.674272, hgln: -0.000000, hglt: 4.790704 },
  { isoDate: "2006-10-29T00:00:00Z", dsun: 213.615503, hgln: 0.000000, hglt: 4.695132 },
  { isoDate: "2006-10-30T00:00:00Z", dsun: 213.557082, hgln: -0.000000, hglt: 4.598092 },
  { isoDate: "2006-10-31T00:00:00Z", dsun: 213.499095, hgln: -0.000000, hglt: 4.499614 },
  { isoDate: "2006-11-01T00:00:00Z", dsun: 213.441630, hgln: -0.000000, hglt: 4.399728 },
  { isoDate: "2006-11-02T00:00:00Z", dsun: 213.384779, hgln: 0.000000, hglt: 4.298466 },
  { isoDate: "2006-11-03T00:00:00Z", dsun: 213.328627, hgln: 0.000000, hglt: 4.195858 },
  { isoDate: "2006-11-04T00:00:00Z", dsun: 213.273251, hgln: 0.000000, hglt: 4.091935 },
  { isoDate: "2006-11-05T00:00:00Z", dsun: 213.218708, hgln: 0.000000, hglt: 3.986729 },
  { isoDate: "2006-11-06T00:00:00Z", dsun: 213.165033, hgln: -0.000000, hglt: 3.880269 },
  { isoDate: "2006-11-07T00:00:00Z", dsun: 213.112239, hgln: 0.000000, hglt: 3.772586 },
  { isoDate: "2006-11-08T00:00:00Z", dsun: 213.060315, hgln: -0.000000, hglt: 3.663711 },
  { isoDate: "2006-11-09T00:00:00Z", dsun: 213.009236, hgln: 0.000000, hglt: 3.553674 },
  { isoDate: "2006-11-10T00:00:00Z", dsun: 212.958964, hgln: -0.000000, hglt: 3.442506 },
  { isoDate: "2006-11-11T00:00:00Z", dsun: 212.909459, hgln: 0.000000, hglt: 3.330239 },
  { isoDate: "2006-11-12T00:00:00Z", dsun: 212.860676, hgln: -0.000000, hglt: 3.216904 },
  { isoDate: "2006-11-13T00:00:00Z", dsun: 212.812576, hgln: 0.000000, hglt: 3.102533 },
  { isoDate: "2006-11-14T00:00:00Z", dsun: 212.765119, hgln: -0.000000, hglt: 2.987159 },
  { isoDate: "2006-11-15T00:00:00Z", dsun: 212.718272, hgln: -0.000000, hglt: 2.870815 },
  { isoDate: "2006-11-16T00:00:00Z", dsun: 212.672003, hgln: 0.000000, hglt: 2.753537 },
  { isoDate: "2006-11-17T00:00:00Z", dsun: 212.626286, hgln: -0.000000, hglt: 2.635358 },
  { isoDate: "2006-11-18T00:00:00Z", dsun: 212.581095, hgln: -0.000000, hglt: 2.516313 },
  { isoDate: "2006-11-19T00:00:00Z", dsun: 212.536415, hgln: -0.000000, hglt: 2.396440 },
  { isoDate: "2006-11-20T00:00:00Z", dsun: 212.492232, hgln: 0.000000, hglt: 2.275774 },
  { isoDate: "2006-11-21T00:00:00Z", dsun: 212.448542, hgln: -0.000000, hglt: 2.154353 },
  { isoDate: "2006-11-22T00:00:00Z", dsun: 212.405350, hgln: 0.000000, hglt: 2.032214 },
  { isoDate: "2006-11-23T00:00:00Z", dsun: 212.362669, hgln: 0.000000, hglt: 1.909398 },
  { isoDate: "2006-11-24T00:00:00Z", dsun: 212.320529, hgln: 0.000000, hglt: 1.785944 },
  { isoDate: "2006-11-25T00:00:00Z", dsun: 212.278966, hgln: -0.000000, hglt: 1.661892 },
  { isoDate: "2006-11-26T00:00:00Z", dsun: 212.238034, hgln: -0.000000, hglt: 1.537282 },
  { isoDate: "2006-11-27T00:00:00Z", dsun: 212.197795, hgln: -0.000000, hglt: 1.412157 },
  { isoDate: "2006-11-28T00:00:00Z", dsun: 212.158322, hgln: -0.000000, hglt: 1.286558 },
  { isoDate: "2006-11-29T00:00:00Z", dsun: 212.119696, hgln: 0.000000, hglt: 1.160527 },
  { isoDate: "2006-11-30T00:00:00Z", dsun: 212.082003, hgln: 0.000000, hglt: 1.034105 },
  { isoDate: "2006-12-01T00:00:00Z", dsun: 212.045326, hgln: 0.000000, hglt: 0.907334 },
  { isoDate: "2006-12-02T00:00:00Z", dsun: 212.009746, hgln: 0.000000, hglt: 0.780254 },
  { isoDate: "2006-12-03T00:00:00Z", dsun: 211.975331, hgln: -0.000000, hglt: 0.652906 },
  { isoDate: "2006-12-04T00:00:00Z", dsun: 211.942135, hgln: -0.000000, hglt: 0.525330 },
  { isoDate: "2006-12-05T00:00:00Z", dsun: 211.910190, hgln: 0.000000, hglt: 0.397564 },
  { isoDate: "2006-12-06T00:00:00Z", dsun: 211.879511, hgln: -0.000000, hglt: 0.269646 },
  { isoDate: "2006-12-07T00:00:00Z", dsun: 211.850091, hgln: -0.000000, hglt: 0.141615 },
  { isoDate: "2006-12-08T00:00:00Z", dsun: 211.821909, hgln: -0.000000, hglt: 0.013508 },
  { isoDate: "2006-12-09T00:00:00Z", dsun: 211.794932, hgln: -0.000000, hglt: -0.114637 },
  { isoDate: "2006-12-10T00:00:00Z", dsun: 211.769122, hgln: -0.000000, hglt: -0.242782 },
  { isoDate: "2006-12-11T00:00:00Z", dsun: 211.744437, hgln: 0.000000, hglt: -0.370888 },
  { isoDate: "2006-12-12T00:00:00Z", dsun: 211.720833, hgln: -0.000000, hglt: -0.498916 },
  { isoDate: "2006-12-13T00:00:00Z", dsun: 211.698266, hgln: -0.000000, hglt: -0.626828 },
  { isoDate: "2006-12-14T00:00:00Z", dsun: 211.676695, hgln: 0.000000, hglt: -0.754583 },
  { isoDate: "2006-12-15T00:00:00Z", dsun: 211.656078, hgln: -0.000000, hglt: -0.882143 },
  { isoDate: "2006-12-16T00:00:00Z", dsun: 211.636376, hgln: 0.000000, hglt: -1.009466 },
  { isoDate: "2006-12-17T00:00:00Z", dsun: 211.617550, hgln: 0.000000, hglt: -1.136513 },
  { isoDate: "2006-12-18T00:00:00Z", dsun: 211.599565, hgln: 0.000000, hglt: -1.263243 },
  { isoDate: "2006-12-19T00:00:00Z", dsun: 211.582390, hgln: 0.000000, hglt: -1.389613 },
  { isoDate: "2006-12-20T00:00:00Z", dsun: 211.566002, hgln: 0.000000, hglt: -1.515583 },
  { isoDate: "2006-12-21T00:00:00Z", dsun: 211.550384, hgln: 0.000000, hglt: -1.641110 },
  { isoDate: "2006-12-22T00:00:00Z", dsun: 211.535534, hgln: 0.000000, hglt: -1.766151 },
  { isoDate: "2006-12-23T00:00:00Z", dsun: 211.521464, hgln: 0.000000, hglt: -1.890663 },
  { isoDate: "2006-12-24T00:00:00Z", dsun: 211.508199, hgln: -0.000000, hglt: -2.014602 },
  { isoDate: "2006-12-25T00:00:00Z", dsun: 211.495783, hgln: -0.000000, hglt: -2.137924 },
  { isoDate: "2006-12-26T00:00:00Z", dsun: 211.484270, hgln: 0.000000, hglt: -2.260587 },
  { isoDate: "2006-12-27T00:00:00Z", dsun: 211.473726, hgln: -0.000000, hglt: -2.382547 },
  { isoDate: "2006-12-28T00:00:00Z", dsun: 211.464227, hgln: 0.000000, hglt: -2.503762 },
  { isoDate: "2006-12-29T00:00:00Z", dsun: 211.455847, hgln: 0.000000, hglt: -2.624190 },
  { isoDate: "2006-12-30T00:00:00Z", dsun: 211.448661, hgln: -0.000000, hglt: -2.743791 },
  { isoDate: "2006-12-31T00:00:00Z", dsun: 211.442736, hgln: -0.000000, hglt: -2.862526 },
  { isoDate: "2007-01-01T00:00:00Z", dsun: 211.438132, hgln: -0.000000, hglt: -2.980356 },
  { isoDate: "2007-01-02T00:00:00Z", dsun: 211.434890, hgln: 0.000000, hglt: -3.097245 },
  { isoDate: "2007-01-03T00:00:00Z", dsun: 211.433039, hgln: -0.000000, hglt: -3.213157 },
  { isoDate: "2007-01-04T00:00:00Z", dsun: 211.432589, hgln: -0.000000, hglt: -3.328056 },
  { isoDate: "2007-01-05T00:00:00Z", dsun: 211.433534, hgln: 0.000000, hglt: -3.441909 },
  { isoDate: "2007-01-06T00:00:00Z", dsun: 211.435855, hgln: -0.000000, hglt: -3.554680 },
  { isoDate: "2007-01-07T00:00:00Z", dsun: 211.439523, hgln: 0.000000, hglt: -3.666337 },
  { isoDate: "2007-01-08T00:00:00Z", dsun: 211.444499, hgln: -0.000000, hglt: -3.776846 },
  { isoDate: "2007-01-09T00:00:00Z", dsun: 211.450739, hgln: -0.000000, hglt: -3.886173 },
  { isoDate: "2007-01-10T00:00:00Z", dsun: 211.458198, hgln: 0.000000, hglt: -3.994284 },
  { isoDate: "2007-01-11T00:00:00Z", dsun: 211.466825, hgln: 0.000000, hglt: -4.101148 },
  { isoDate: "2007-01-12T00:00:00Z", dsun: 211.476569, hgln: 0.000000, hglt: -4.206729 },
  { isoDate: "2007-01-13T00:00:00Z", dsun: 211.487379, hgln: -0.000000, hglt: -4.310995 },
  { isoDate: "2007-01-14T00:00:00Z", dsun: 211.499200, hgln: -0.000000, hglt: -4.413913 },
  { isoDate: "2007-01-15T00:00:00Z", dsun: 211.511977, hgln: -0.000000, hglt: -4.515450 },
  { isoDate: "2007-01-16T00:00:00Z", dsun: 211.525657, hgln: 0.000000, hglt: -4.615572 },
  { isoDate: "2007-01-17T00:00:00Z", dsun: 211.540189, hgln: -0.000000, hglt: -4.714247 },
  { isoDate: "2007-01-18T00:00:00Z", dsun: 211.555524, hgln: 0.000000, hglt: -4.811441 },
  { isoDate: "2007-01-19T00:00:00Z", dsun: 211.571627, hgln: 0.000000, hglt: -4.907121 },
  { isoDate: "2007-01-20T00:00:00Z", dsun: 211.588473, hgln: 0.000000, hglt: -5.001252 },
  { isoDate: "2007-01-21T00:00:00Z", dsun: 211.606055, hgln: -0.000000, hglt: -5.093802 },
  { isoDate: "2007-01-22T00:00:00Z", dsun: 211.624385, hgln: -0.000000, hglt: -5.184736 },
  { isoDate: "2007-01-23T00:00:00Z", dsun: 211.643494, hgln: 0.000000, hglt: -5.274023 },
  { isoDate: "2007-01-24T00:00:00Z", dsun: 211.663430, hgln: 0.000000, hglt: -5.361629 },
  { isoDate: "2007-01-25T00:00:00Z", dsun: 211.684250, hgln: 0.000000, hglt: -5.447524 },
  { isoDate: "2007-01-26T00:00:00Z", dsun: 211.706021, hgln: 0.000000, hglt: -5.531679 },
  { isoDate: "2007-01-27T00:00:00Z", dsun: 211.728807, hgln: -0.000000, hglt: -5.614066 },
  { isoDate: "2007-01-28T00:00:00Z", dsun: 211.752673, hgln: -0.000000, hglt: -5.694658 },
  { isoDate: "2007-01-29T00:00:00Z", dsun: 211.777673, hgln: -0.000000, hglt: -5.773431 },
  { isoDate: "2007-01-30T00:00:00Z", dsun: 211.803853, hgln: 0.000000, hglt: -5.850360 },
  { isoDate: "2007-01-31T00:00:00Z", dsun: 211.831246, hgln: -0.000000, hglt: -5.925423 },
  { isoDate: "2007-02-01T00:00:00Z", dsun: 211.859873, hgln: 0.000000, hglt: -5.998600 },
  { isoDate: "2007-02-02T00:00:00Z", dsun: 211.889740, hgln: -0.000000, hglt: -6.069869 },
  { isoDate: "2007-02-03T00:00:00Z", dsun: 211.920841, hgln: 0.000000, hglt: -6.139212 },
  { isoDate: "2007-02-04T00:00:00Z", dsun: 211.953158, hgln: -0.000000, hglt: -6.206611 },
  { isoDate: "2007-02-05T00:00:00Z", dsun: 211.986663, hgln: -0.000000, hglt: -6.272046 },
  { isoDate: "2007-02-06T00:00:00Z", dsun: 212.021318, hgln: -0.000000, hglt: -6.335501 },
  { isoDate: "2007-02-07T00:00:00Z", dsun: 212.057081, hgln: -0.000000, hglt: -6.396958 },
  { isoDate: "2007-02-08T00:00:00Z", dsun: 212.093901, hgln: 0.000000, hglt: -6.456401 },
  { isoDate: "2007-02-09T00:00:00Z", dsun: 212.131726, hgln: -0.000000, hglt: -6.513813 },
  { isoDate: "2007-02-10T00:00:00Z", dsun: 212.170497, hgln: 0.000000, hglt: -6.569179 },
  { isoDate: "2007-02-11T00:00:00Z", dsun: 212.210154, hgln: 0.000000, hglt: -6.622483 },
  { isoDate: "2007-02-12T00:00:00Z", dsun: 212.250631, hgln: 0.000000, hglt: -6.673711 },
  { isoDate: "2007-02-13T00:00:00Z", dsun: 212.291861, hgln: -0.000000, hglt: -6.722847 },
  { isoDate: "2007-02-14T00:00:00Z", dsun: 212.333773, hgln: -0.000000, hglt: -6.769877 },
  { isoDate: "2007-02-15T00:00:00Z", dsun: 212.376297, hgln: -0.000000, hglt: -6.814788 },
  { isoDate: "2007-02-16T00:00:00Z", dsun: 212.419366, hgln: -0.000000, hglt: -6.857564 },
  { isoDate: "2007-02-17T00:00:00Z", dsun: 212.462923, hgln: -0.000000, hglt: -6.898193 },
  { isoDate: "2007-02-18T00:00:00Z", dsun: 212.506924, hgln: 0.000000, hglt: -6.936660 },
  { isoDate: "2007-02-19T00:00:00Z", dsun: 212.551345, hgln: 0.000000, hglt: -6.972953 },
  { isoDate: "2007-02-20T00:00:00Z", dsun: 212.596184, hgln: -0.000000, hglt: -7.007059 },
  { isoDate: "2007-02-21T00:00:00Z", dsun: 212.641464, hgln: 0.000000, hglt: -7.038966 },
  { isoDate: "2007-02-22T00:00:00Z", dsun: 212.687223, hgln: -0.000000, hglt: -7.068665 },
  { isoDate: "2007-02-23T00:00:00Z", dsun: 212.733513, hgln: 0.000000, hglt: -7.096146 },
  { isoDate: "2007-02-24T00:00:00Z", dsun: 212.780393, hgln: -0.000000, hglt: -7.121403 },
  { isoDate: "2007-02-25T00:00:00Z", dsun: 212.827919, hgln: -0.000000, hglt: -7.144431 },
  { isoDate: "2007-02-26T00:00:00Z", dsun: 212.876147, hgln: -0.000000, hglt: -7.165225 },
  { isoDate: "2007-02-27T00:00:00Z", dsun: 212.925121, hgln: -0.000000, hglt: -7.183783 },
  { isoDate: "2007-02-28T00:00:00Z", dsun: 212.974878, hgln: -0.000000, hglt: -7.200102 },
  { isoDate: "2007-03-01T00:00:00Z", dsun: 213.025446, hgln: -0.000000, hglt: -7.214184 },
  { isoDate: "2007-03-02T00:00:00Z", dsun: 213.076841, hgln: -0.000000, hglt: -7.226028 },
  { isoDate: "2007-03-03T00:00:00Z", dsun: 213.129066, hgln: -0.000000, hglt: -7.235636 },
  { isoDate: "2007-03-04T00:00:00Z", dsun: 213.182118, hgln: 0.000000, hglt: -7.243009 },
  { isoDate: "2007-03-05T00:00:00Z", dsun: 213.235982, hgln: 0.000000, hglt: -7.248151 },
  { isoDate: "2007-03-06T00:00:00Z", dsun: 213.290631, hgln: -0.000000, hglt: -7.251065 },
  { isoDate: "2007-03-07T00:00:00Z", dsun: 213.346034, hgln: 0.000000, hglt: -7.251755 },
  { isoDate: "2007-03-08T00:00:00Z", dsun: 213.402149, hgln: -0.000000, hglt: -7.250225 },
  { isoDate: "2007-03-09T00:00:00Z", dsun: 213.458929, hgln: 0.000000, hglt: -7.246481 },
  { isoDate: "2007-03-10T00:00:00Z", dsun: 213.516320, hgln: -0.000000, hglt: -7.240527 },
  { isoDate: "2007-03-11T00:00:00Z", dsun: 213.574263, hgln: -0.000000, hglt: -7.232369 },
  { isoDate: "2007-03-12T00:00:00Z", dsun: 213.632691, hgln: 0.000000, hglt: -7.222014 },
  { isoDate: "2007-03-13T00:00:00Z", dsun: 213.691535, hgln: -0.000000, hglt: -7.209469 },
  { isoDate: "2007-03-14T00:00:00Z", dsun: 213.750718, hgln: 0.000000, hglt: -7.194739 },
  { isoDate: "2007-03-15T00:00:00Z", dsun: 213.810159, hgln: 0.000000, hglt: -7.177833 },
  { isoDate: "2007-03-16T00:00:00Z", dsun: 213.869776, hgln: 0.000000, hglt: -7.158758 },
  { isoDate: "2007-03-17T00:00:00Z", dsun: 213.929489, hgln: -0.000000, hglt: -7.137522 },
  { isoDate: "2007-03-18T00:00:00Z", dsun: 213.989225, hgln: 0.000000, hglt: -7.114133 },
  { isoDate: "2007-03-19T00:00:00Z", dsun: 214.048927, hgln: 0.000000, hglt: -7.088600 },
  { isoDate: "2007-03-20T00:00:00Z", dsun: 214.108559, hgln: -0.000000, hglt: -7.060934 },
  { isoDate: "2007-03-21T00:00:00Z", dsun: 214.168111, hgln: -0.000000, hglt: -7.031143 },
  { isoDate: "2007-03-22T00:00:00Z", dsun: 214.227595, hgln: -0.000000, hglt: -6.999241 },
  { isoDate: "2007-03-23T00:00:00Z", dsun: 214.287047, hgln: 0.000000, hglt: -6.965239 },
  { isoDate: "2007-03-24T00:00:00Z", dsun: 214.346513, hgln: -0.000000, hglt: -6.929153 },
  { isoDate: "2007-03-25T00:00:00Z", dsun: 214.406047, hgln: -0.000000, hglt: -6.890997 },
  { isoDate: "2007-03-26T00:00:00Z", dsun: 214.465701, hgln: 0.000000, hglt: -6.850788 },
  { isoDate: "2007-03-27T00:00:00Z", dsun: 214.525523, hgln: -0.000000, hglt: -6.808544 },
  { isoDate: "2007-03-28T00:00:00Z", dsun: 214.585555, hgln: -0.000000, hglt: -6.764282 },
  { isoDate: "2007-03-29T00:00:00Z", dsun: 214.645832, hgln: 0.000000, hglt: -6.718022 },
  { isoDate: "2007-03-30T00:00:00Z", dsun: 214.706378, hgln: -0.000000, hglt: -6.669782 },
  { isoDate: "2007-03-31T00:00:00Z", dsun: 214.767210, hgln: 0.000000, hglt: -6.619582 },
  { isoDate: "2007-04-01T00:00:00Z", dsun: 214.828337, hgln: 0.000000, hglt: -6.567443 },
  { isoDate: "2007-04-02T00:00:00Z", dsun: 214.889759, hgln: 0.000000, hglt: -6.513385 },
  { isoDate: "2007-04-03T00:00:00Z", dsun: 214.951466, hgln: 0.000000, hglt: -6.457428 },
  { isoDate: "2007-04-04T00:00:00Z", dsun: 215.013442, hgln: 0.000000, hglt: -6.399595 },
  { isoDate: "2007-04-05T00:00:00Z", dsun: 215.075661, hgln: 0.000000, hglt: -6.339906 },
  { isoDate: "2007-04-06T00:00:00Z", dsun: 215.138089, hgln: 0.000000, hglt: -6.278384 },
  { isoDate: "2007-04-07T00:00:00Z", dsun: 215.200685, hgln: 0.000000, hglt: -6.215049 },
  { isoDate: "2007-04-08T00:00:00Z", dsun: 215.263399, hgln: 0.000000, hglt: -6.149924 },
  { isoDate: "2007-04-09T00:00:00Z", dsun: 215.326175, hgln: 0.000000, hglt: -6.083031 },
  { isoDate: "2007-04-10T00:00:00Z", dsun: 215.388947, hgln: 0.000000, hglt: -6.014392 },
  { isoDate: "2007-04-11T00:00:00Z", dsun: 215.451644, hgln: 0.000000, hglt: -5.944029 },
  { isoDate: "2007-04-12T00:00:00Z", dsun: 215.514187, hgln: 0.000000, hglt: -5.871966 },
  { isoDate: "2007-04-13T00:00:00Z", dsun: 215.576492, hgln: -0.000000, hglt: -5.798226 },
  { isoDate: "2007-04-14T00:00:00Z", dsun: 215.638471, hgln: -0.000000, hglt: -5.722830 },
  { isoDate: "2007-04-15T00:00:00Z", dsun: 215.700038, hgln: -0.000000, hglt: -5.645803 },
  { isoDate: "2007-04-16T00:00:00Z", dsun: 215.761114, hgln: 0.000000, hglt: -5.567169 },
  { isoDate: "2007-04-17T00:00:00Z", dsun: 215.821637, hgln: 0.000000, hglt: -5.486953 },
  { isoDate: "2007-04-18T00:00:00Z", dsun: 215.881564, hgln: -0.000000, hglt: -5.405181 },
  { isoDate: "2007-04-19T00:00:00Z", dsun: 215.940881, hgln: -0.000000, hglt: -5.321879 },
  { isoDate: "2007-04-20T00:00:00Z", dsun: 215.999598, hgln: 0.000000, hglt: -5.237075 },
  { isoDate: "2007-04-21T00:00:00Z", dsun: 216.057744, hgln: 0.000000, hglt: -5.150799 },
  { isoDate: "2007-04-22T00:00:00Z", dsun: 216.115363, hgln: 0.000000, hglt: -5.063078 },
  { isoDate: "2007-04-23T00:00:00Z", dsun: 216.172505, hgln: -0.000000, hglt: -4.973943 },
  { isoDate: "2007-04-24T00:00:00Z", dsun: 216.229222, hgln: 0.000000, hglt: -4.883425 },
  { isoDate: "2007-04-25T00:00:00Z", dsun: 216.285559, hgln: -0.000000, hglt: -4.791553 },
  { isoDate: "2007-04-26T00:00:00Z", dsun: 216.341560, hgln: -0.000000, hglt: -4.698357 },
  { isoDate: "2007-04-27T00:00:00Z", dsun: 216.397260, hgln: 0.000000, hglt: -4.603868 },
  { isoDate: "2007-04-28T00:00:00Z", dsun: 216.452689, hgln: -0.000000, hglt: -4.508118 },
  { isoDate: "2007-04-29T00:00:00Z", dsun: 216.507869, hgln: -0.000000, hglt: -4.411134 },
  { isoDate: "2007-04-30T00:00:00Z", dsun: 216.562817, hgln: -0.000000, hglt: -4.312950 },
  { isoDate: "2007-05-01T00:00:00Z", dsun: 216.617542, hgln: 0.000000, hglt: -4.213593 },
  { isoDate: "2007-05-02T00:00:00Z", dsun: 216.672046, hgln: 0.000000, hglt: -4.113096 },
  { isoDate: "2007-05-03T00:00:00Z", dsun: 216.726324, hgln: -0.000000, hglt: -4.011487 },
  { isoDate: "2007-05-04T00:00:00Z", dsun: 216.780361, hgln: 0.000000, hglt: -3.908797 },
  { isoDate: "2007-05-05T00:00:00Z", dsun: 216.834134, hgln: -0.000000, hglt: -3.805055 },
  { isoDate: "2007-05-06T00:00:00Z", dsun: 216.887612, hgln: 0.000000, hglt: -3.700291 },
  { isoDate: "2007-05-07T00:00:00Z", dsun: 216.940753, hgln: -0.000000, hglt: -3.594534 },
  { isoDate: "2007-05-08T00:00:00Z", dsun: 216.993506, hgln: -0.000000, hglt: -3.487813 },
  { isoDate: "2007-05-09T00:00:00Z", dsun: 217.045811, hgln: -0.000000, hglt: -3.380159 },
  { isoDate: "2007-05-10T00:00:00Z", dsun: 217.097599, hgln: 0.000000, hglt: -3.271598 },
  { isoDate: "2007-05-11T00:00:00Z", dsun: 217.148791, hgln: -0.000000, hglt: -3.162162 },
  { isoDate: "2007-05-12T00:00:00Z", dsun: 217.199306, hgln: 0.000000, hglt: -3.051880 },
  { isoDate: "2007-05-13T00:00:00Z", dsun: 217.249055, hgln: -0.000000, hglt: -2.940780 },
  { isoDate: "2007-05-14T00:00:00Z", dsun: 217.297953, hgln: 0.000000, hglt: -2.828895 },
  { isoDate: "2007-05-15T00:00:00Z", dsun: 217.345924, hgln: -0.000000, hglt: -2.716253 },
  { isoDate: "2007-05-16T00:00:00Z", dsun: 217.392906, hgln: 0.000000, hglt: -2.602889 },
  { isoDate: "2007-05-17T00:00:00Z", dsun: 217.438859, hgln: 0.000000, hglt: -2.488835 },
  { isoDate: "2007-05-18T00:00:00Z", dsun: 217.483766, hgln: 0.000000, hglt: -2.374125 },
  { isoDate: "2007-05-19T00:00:00Z", dsun: 217.527637, hgln: 0.000000, hglt: -2.258793 },
  { isoDate: "2007-05-20T00:00:00Z", dsun: 217.570499, hgln: 0.000000, hglt: -2.142876 },
  { isoDate: "2007-05-21T00:00:00Z", dsun: 217.612393, hgln: -0.000000, hglt: -2.026408 },
  { isoDate: "2007-05-22T00:00:00Z", dsun: 217.653366, hgln: 0.000000, hglt: -1.909423 },
  { isoDate: "2007-05-23T00:00:00Z", dsun: 217.693470, hgln: -0.000000, hglt: -1.791958 },
  { isoDate: "2007-05-24T00:00:00Z", dsun: 217.732751, hgln: -0.000000, hglt: -1.674046 },
  { isoDate: "2007-05-25T00:00:00Z", dsun: 217.771258, hgln: -0.000000, hglt: -1.555722 },
  { isoDate: "2007-05-26T00:00:00Z", dsun: 217.809029, hgln: -0.000000, hglt: -1.437019 },
  { isoDate: "2007-05-27T00:00:00Z", dsun: 217.846103, hgln: -0.000000, hglt: -1.317971 },
  { isoDate: "2007-05-28T00:00:00Z", dsun: 217.882512, hgln: -0.000000, hglt: -1.198611 },
  { isoDate: "2007-05-29T00:00:00Z", dsun: 217.918284, hgln: 0.000000, hglt: -1.078972 },
  { isoDate: "2007-05-30T00:00:00Z", dsun: 217.953441, hgln: -0.000000, hglt: -0.959085 },
  { isoDate: "2007-05-31T00:00:00Z", dsun: 217.988000, hgln: -0.000000, hglt: -0.838982 },
  { isoDate: "2007-06-01T00:00:00Z", dsun: 218.021969, hgln: 0.000000, hglt: -0.718696 },
  { isoDate: "2007-06-02T00:00:00Z", dsun: 218.055349, hgln: -0.000000, hglt: -0.598257 },
  { isoDate: "2007-06-03T00:00:00Z", dsun: 218.088130, hgln: -0.000000, hglt: -0.477695 },
  { isoDate: "2007-06-04T00:00:00Z", dsun: 218.120293, hgln: -0.000000, hglt: -0.357040 },
  { isoDate: "2007-06-05T00:00:00Z", dsun: 218.151806, hgln: 0.000000, hglt: -0.236322 },
  { isoDate: "2007-06-06T00:00:00Z", dsun: 218.182626, hgln: -0.000000, hglt: -0.115571 },
  { isoDate: "2007-06-07T00:00:00Z", dsun: 218.212698, hgln: -0.000000, hglt: 0.005185 },
  { isoDate: "2007-06-08T00:00:00Z", dsun: 218.241957, hgln: 0.000000, hglt: 0.125916 },
  { isoDate: "2007-06-09T00:00:00Z", dsun: 218.270329, hgln: 0.000000, hglt: 0.246593 },
  { isoDate: "2007-06-10T00:00:00Z", dsun: 218.297734, hgln: 0.000000, hglt: 0.367186 },
  { isoDate: "2007-06-11T00:00:00Z", dsun: 218.324091, hgln: -0.000000, hglt: 0.487665 },
  { isoDate: "2007-06-12T00:00:00Z", dsun: 218.349319, hgln: 0.000000, hglt: 0.607998 },
  { isoDate: "2007-06-13T00:00:00Z", dsun: 218.373350, hgln: 0.000000, hglt: 0.728154 },
  { isoDate: "2007-06-14T00:00:00Z", dsun: 218.396128, hgln: 0.000000, hglt: 0.848100 },
  { isoDate: "2007-06-15T00:00:00Z", dsun: 218.417619, hgln: -0.000000, hglt: 0.967802 },
  { isoDate: "2007-06-16T00:00:00Z", dsun: 218.437811, hgln: 0.000000, hglt: 1.087224 },
  { isoDate: "2007-06-17T00:00:00Z", dsun: 218.456711, hgln: -0.000000, hglt: 1.206333 },
  { isoDate: "2007-06-18T00:00:00Z", dsun: 218.474345, hgln: -0.000000, hglt: 1.325094 },
  { isoDate: "2007-06-19T00:00:00Z", dsun: 218.490752, hgln: 0.000000, hglt: 1.443471 },
  { isoDate: "2007-06-20T00:00:00Z", dsun: 218.505978, hgln: -0.000000, hglt: 1.561431 },
  { isoDate: "2007-06-21T00:00:00Z", dsun: 218.520073, hgln: -0.000000, hglt: 1.678939 },
  { isoDate: "2007-06-22T00:00:00Z", dsun: 218.533088, hgln: -0.000000, hglt: 1.795964 },
  { isoDate: "2007-06-23T00:00:00Z", dsun: 218.545073, hgln: 0.000000, hglt: 1.912472 },
  { isoDate: "2007-06-24T00:00:00Z", dsun: 218.556077, hgln: -0.000000, hglt: 2.028432 },
  { isoDate: "2007-06-25T00:00:00Z", dsun: 218.566146, hgln: 0.000000, hglt: 2.143812 },
  { isoDate: "2007-06-26T00:00:00Z", dsun: 218.575323, hgln: 0.000000, hglt: 2.258582 },
  { isoDate: "2007-06-27T00:00:00Z", dsun: 218.583650, hgln: 0.000000, hglt: 2.372711 },
  { isoDate: "2007-06-28T00:00:00Z", dsun: 218.591164, hgln: 0.000000, hglt: 2.486170 },
  { isoDate: "2007-06-29T00:00:00Z", dsun: 218.597898, hgln: 0.000000, hglt: 2.598930 },
  { isoDate: "2007-06-30T00:00:00Z", dsun: 218.603877, hgln: -0.000000, hglt: 2.710963 },
  { isoDate: "2007-07-01T00:00:00Z", dsun: 218.609119, hgln: 0.000000, hglt: 2.822241 },
  { isoDate: "2007-07-02T00:00:00Z", dsun: 218.613630, hgln: -0.000000, hglt: 2.932738 },
  { isoDate: "2007-07-03T00:00:00Z", dsun: 218.617403, hgln: -0.000000, hglt: 3.042427 },
  { isoDate: "2007-07-04T00:00:00Z", dsun: 218.620417, hgln: 0.000000, hglt: 3.151283 },
  { isoDate: "2007-07-05T00:00:00Z", dsun: 218.622636, hgln: 0.000000, hglt: 3.259279 },
  { isoDate: "2007-07-06T00:00:00Z", dsun: 218.624011, hgln: 0.000000, hglt: 3.366391 },
  { isoDate: "2007-07-07T00:00:00Z", dsun: 218.624481, hgln: 0.000000, hglt: 3.472594 },
  { isoDate: "2007-07-08T00:00:00Z", dsun: 218.623977, hgln: 0.000000, hglt: 3.577860 },
  { isoDate: "2007-07-09T00:00:00Z", dsun: 218.622423, hgln: 0.000000, hglt: 3.682164 },
  { isoDate: "2007-07-10T00:00:00Z", dsun: 218.619747, hgln: 0.000000, hglt: 3.785480 },
  { isoDate: "2007-07-11T00:00:00Z", dsun: 218.615877, hgln: 0.000000, hglt: 3.887778 },
  { isoDate: "2007-07-12T00:00:00Z", dsun: 218.610755, hgln: 0.000000, hglt: 3.989030 },
  { isoDate: "2007-07-13T00:00:00Z", dsun: 218.604336, hgln: 0.000000, hglt: 4.089206 },
  { isoDate: "2007-07-14T00:00:00Z", dsun: 218.596593, hgln: -0.000000, hglt: 4.188278 },
  { isoDate: "2007-07-15T00:00:00Z", dsun: 218.587517, hgln: 0.000000, hglt: 4.286215 },
  { isoDate: "2007-07-16T00:00:00Z", dsun: 218.577117, hgln: 0.000000, hglt: 4.382986 },
  { isoDate: "2007-07-17T00:00:00Z", dsun: 218.565418, hgln: -0.000000, hglt: 4.478562 },
  { isoDate: "2007-07-18T00:00:00Z", dsun: 218.552457, hgln: 0.000000, hglt: 4.572913 },
  { isoDate: "2007-07-19T00:00:00Z", dsun: 218.538277, hgln: 0.000000, hglt: 4.666011 },
  { isoDate: "2007-07-20T00:00:00Z", dsun: 218.522928, hgln: 0.000000, hglt: 4.757827 },
  { isoDate: "2007-07-21T00:00:00Z", dsun: 218.506464, hgln: 0.000000, hglt: 4.848336 },
  { isoDate: "2007-07-22T00:00:00Z", dsun: 218.488939, hgln: 0.000000, hglt: 4.937509 },
  { isoDate: "2007-07-23T00:00:00Z", dsun: 218.470408, hgln: 0.000000, hglt: 5.025321 },
  { isoDate: "2007-07-24T00:00:00Z", dsun: 218.450925, hgln: -0.000000, hglt: 5.111747 },
  { isoDate: "2007-07-25T00:00:00Z", dsun: 218.430546, hgln: -0.000000, hglt: 5.196763 },
  { isoDate: "2007-07-26T00:00:00Z", dsun: 218.409326, hgln: -0.000000, hglt: 5.280344 },
  { isoDate: "2007-07-27T00:00:00Z", dsun: 218.387317, hgln: 0.000000, hglt: 5.362469 },
  { isoDate: "2007-07-28T00:00:00Z", dsun: 218.364570, hgln: -0.000000, hglt: 5.443115 },
  { isoDate: "2007-07-29T00:00:00Z", dsun: 218.341127, hgln: 0.000000, hglt: 5.522260 },
  { isoDate: "2007-07-30T00:00:00Z", dsun: 218.317024, hgln: -0.000000, hglt: 5.599885 },
  { isoDate: "2007-07-31T00:00:00Z", dsun: 218.292282, hgln: -0.000000, hglt: 5.675970 },
  { isoDate: "2007-08-01T00:00:00Z", dsun: 218.266909, hgln: 0.000000, hglt: 5.750496 },
  { isoDate: "2007-08-02T00:00:00Z", dsun: 218.240892, hgln: 0.000000, hglt: 5.823445 },
  { isoDate: "2007-08-03T00:00:00Z", dsun: 218.214204, hgln: -0.000000, hglt: 5.894799 },
  { isoDate: "2007-08-04T00:00:00Z", dsun: 218.186799, hgln: -0.000000, hglt: 5.964540 },
  { isoDate: "2007-08-05T00:00:00Z", dsun: 218.158620, hgln: -0.000000, hglt: 6.032650 },
  { isoDate: "2007-08-06T00:00:00Z", dsun: 218.129603, hgln: 0.000000, hglt: 6.099109 },
  { isoDate: "2007-08-07T00:00:00Z", dsun: 218.099681, hgln: -0.000000, hglt: 6.163899 },
  { isoDate: "2007-08-08T00:00:00Z", dsun: 218.068787, hgln: 0.000000, hglt: 6.227000 },
  { isoDate: "2007-08-09T00:00:00Z", dsun: 218.036863, hgln: -0.000000, hglt: 6.288391 },
  { isoDate: "2007-08-10T00:00:00Z", dsun: 218.003861, hgln: 0.000000, hglt: 6.348052 },
  { isoDate: "2007-08-11T00:00:00Z", dsun: 217.969746, hgln: -0.000000, hglt: 6.405963 },
  { isoDate: "2007-08-12T00:00:00Z", dsun: 217.934499, hgln: -0.000000, hglt: 6.462103 },
  { isoDate: "2007-08-13T00:00:00Z", dsun: 217.898114, hgln: -0.000000, hglt: 6.516451 },
  { isoDate: "2007-08-14T00:00:00Z", dsun: 217.860602, hgln: -0.000000, hglt: 6.568987 },
  { isoDate: "2007-08-15T00:00:00Z", dsun: 217.821986, hgln: -0.000000, hglt: 6.619692 },
  { isoDate: "2007-08-16T00:00:00Z", dsun: 217.782300, hgln: -0.000000, hglt: 6.668547 },
  { isoDate: "2007-08-17T00:00:00Z", dsun: 217.741587, hgln: 0.000000, hglt: 6.715534 },
  { isoDate: "2007-08-18T00:00:00Z", dsun: 217.699894, hgln: -0.000000, hglt: 6.760637 },
  { isoDate: "2007-08-19T00:00:00Z", dsun: 217.657276, hgln: 0.000000, hglt: 6.803838 },
  { isoDate: "2007-08-20T00:00:00Z", dsun: 217.613790, hgln: -0.000000, hglt: 6.845123 },
  { isoDate: "2007-08-21T00:00:00Z", dsun: 217.569495, hgln: -0.000000, hglt: 6.884476 },
  { isoDate: "2007-08-22T00:00:00Z", dsun: 217.524454, hgln: -0.000000, hglt: 6.921885 },
  { isoDate: "2007-08-23T00:00:00Z", dsun: 217.478732, hgln: -0.000000, hglt: 6.957336 },
  { isoDate: "2007-08-24T00:00:00Z", dsun: 217.432395, hgln: 0.000000, hglt: 6.990817 },
  { isoDate: "2007-08-25T00:00:00Z", dsun: 217.385510, hgln: -0.000000, hglt: 7.022317 },
  { isoDate: "2007-08-26T00:00:00Z", dsun: 217.338143, hgln: 0.000000, hglt: 7.051826 },
  { isoDate: "2007-08-27T00:00:00Z", dsun: 217.290355, hgln: 0.000000, hglt: 7.079336 },
  { isoDate: "2007-08-28T00:00:00Z", dsun: 217.242198, hgln: 0.000000, hglt: 7.104837 },
  { isoDate: "2007-08-29T00:00:00Z", dsun: 217.193709, hgln: 0.000000, hglt: 7.128323 },
  { isoDate: "2007-08-30T00:00:00Z", dsun: 217.144907, hgln: 0.000000, hglt: 7.149786 },
  { isoDate: "2007-08-31T00:00:00Z", dsun: 217.095791, hgln: 0.000000, hglt: 7.169221 },
  { isoDate: "2007-09-01T00:00:00Z", dsun: 217.046337, hgln: -0.000000, hglt: 7.186621 },
  { isoDate: "2007-09-02T00:00:00Z", dsun: 216.996506, hgln: -0.000000, hglt: 7.201980 },
  { isoDate: "2007-09-03T00:00:00Z", dsun: 216.946244, hgln: 0.000000, hglt: 7.215291 },
  { isoDate: "2007-09-04T00:00:00Z", dsun: 216.895490, hgln: -0.000000, hglt: 7.226549 },
  { isoDate: "2007-09-05T00:00:00Z", dsun: 216.844186, hgln: -0.000000, hglt: 7.235745 },
  { isoDate: "2007-09-06T00:00:00Z", dsun: 216.792274, hgln: -0.000000, hglt: 7.242874 },
  { isoDate: "2007-09-07T00:00:00Z", dsun: 216.739707, hgln: -0.000000, hglt: 7.247928 },
  { isoDate: "2007-09-08T00:00:00Z", dsun: 216.686444, hgln: -0.000000, hglt: 7.250901 },
  { isoDate: "2007-09-09T00:00:00Z", dsun: 216.632461, hgln: 0.000000, hglt: 7.251788 },
  { isoDate: "2007-09-10T00:00:00Z", dsun: 216.577741, hgln: 0.000000, hglt: 7.250581 },
  { isoDate: "2007-09-11T00:00:00Z", dsun: 216.522282, hgln: 0.000000, hglt: 7.247278 },
  { isoDate: "2007-09-12T00:00:00Z", dsun: 216.466095, hgln: 0.000000, hglt: 7.241873 },
  { isoDate: "2007-09-13T00:00:00Z", dsun: 216.409199, hgln: -0.000000, hglt: 7.234363 },
  { isoDate: "2007-09-14T00:00:00Z", dsun: 216.351625, hgln: -0.000000, hglt: 7.224746 },
  { isoDate: "2007-09-15T00:00:00Z", dsun: 216.293411, hgln: -0.000000, hglt: 7.213021 },
  { isoDate: "2007-09-16T00:00:00Z", dsun: 216.234604, hgln: 0.000000, hglt: 7.199187 },
  { isoDate: "2007-09-17T00:00:00Z", dsun: 216.175255, hgln: -0.000000, hglt: 7.183244 },
  { isoDate: "2007-09-18T00:00:00Z", dsun: 216.115421, hgln: 0.000000, hglt: 7.165194 },
  { isoDate: "2007-09-19T00:00:00Z", dsun: 216.055165, hgln: -0.000000, hglt: 7.145039 },
  { isoDate: "2007-09-20T00:00:00Z", dsun: 215.994551, hgln: 0.000000, hglt: 7.122782 },
  { isoDate: "2007-09-21T00:00:00Z", dsun: 215.933652, hgln: 0.000000, hglt: 7.098427 },
  { isoDate: "2007-09-22T00:00:00Z", dsun: 215.872542, hgln: -0.000000, hglt: 7.071980 },
  { isoDate: "2007-09-23T00:00:00Z", dsun: 215.811299, hgln: -0.000000, hglt: 7.043447 },
  { isoDate: "2007-09-24T00:00:00Z", dsun: 215.750000, hgln: 0.000000, hglt: 7.012834 },
  { isoDate: "2007-09-25T00:00:00Z", dsun: 215.688722, hgln: -0.000000, hglt: 6.980148 },
  { isoDate: "2007-09-26T00:00:00Z", dsun: 215.627529, hgln: -0.000000, hglt: 6.945400 },
  { isoDate: "2007-09-27T00:00:00Z", dsun: 215.566474, hgln: -0.000000, hglt: 6.908596 },
  { isoDate: "2007-09-28T00:00:00Z", dsun: 215.505584, hgln: 0.000000, hglt: 6.869748 },
  { isoDate: "2007-09-29T00:00:00Z", dsun: 215.444867, hgln: -0.000000, hglt: 6.828863 },
  { isoDate: "2007-09-30T00:00:00Z", dsun: 215.384303, hgln: -0.000000, hglt: 6.785952 },
  { isoDate: "2007-10-01T00:00:00Z", dsun: 215.323857, hgln: 0.000000, hglt: 6.741024 },
  { isoDate: "2007-10-02T00:00:00Z", dsun: 215.263477, hgln: -0.000000, hglt: 6.694088 },
  { isoDate: "2007-10-03T00:00:00Z", dsun: 215.203109, hgln: 0.000000, hglt: 6.645154 },
  { isoDate: "2007-10-04T00:00:00Z", dsun: 215.142699, hgln: 0.000000, hglt: 6.594232 },
  { isoDate: "2007-10-05T00:00:00Z", dsun: 215.082196, hgln: -0.000000, hglt: 6.541333 },
  { isoDate: "2007-10-06T00:00:00Z", dsun: 215.021559, hgln: 0.000000, hglt: 6.486466 },
  { isoDate: "2007-10-07T00:00:00Z", dsun: 214.960754, hgln: 0.000000, hglt: 6.429645 },
  { isoDate: "2007-10-08T00:00:00Z", dsun: 214.899756, hgln: -0.000000, hglt: 6.370882 },
  { isoDate: "2007-10-09T00:00:00Z", dsun: 214.838554, hgln: -0.000000, hglt: 6.310190 },
  { isoDate: "2007-10-10T00:00:00Z", dsun: 214.777141, hgln: -0.000000, hglt: 6.247583 },
  { isoDate: "2007-10-11T00:00:00Z", dsun: 214.715524, hgln: 0.000000, hglt: 6.183077 },
  { isoDate: "2007-10-12T00:00:00Z", dsun: 214.653718, hgln: -0.000000, hglt: 6.116688 },
  { isoDate: "2007-10-13T00:00:00Z", dsun: 214.591745, hgln: 0.000000, hglt: 6.048433 },
  { isoDate: "2007-10-14T00:00:00Z", dsun: 214.529638, hgln: 0.000000, hglt: 5.978329 },
  { isoDate: "2007-10-15T00:00:00Z", dsun: 214.467437, hgln: -0.000000, hglt: 5.906397 },
  { isoDate: "2007-10-16T00:00:00Z", dsun: 214.405186, hgln: -0.000000, hglt: 5.832655 },
  { isoDate: "2007-10-17T00:00:00Z", dsun: 214.342941, hgln: 0.000000, hglt: 5.757125 },
  { isoDate: "2007-10-18T00:00:00Z", dsun: 214.280760, hgln: 0.000000, hglt: 5.679827 },
  { isoDate: "2007-10-19T00:00:00Z", dsun: 214.218709, hgln: -0.000000, hglt: 5.600785 },
  { isoDate: "2007-10-20T00:00:00Z", dsun: 214.156859, hgln: 0.000000, hglt: 5.520022 },
  { isoDate: "2007-10-21T00:00:00Z", dsun: 214.095290, hgln: 0.000000, hglt: 5.437562 },
  { isoDate: "2007-10-22T00:00:00Z", dsun: 214.034084, hgln: -0.000000, hglt: 5.353429 },
  { isoDate: "2007-10-23T00:00:00Z", dsun: 213.973326, hgln: 0.000000, hglt: 5.267648 },
  { isoDate: "2007-10-24T00:00:00Z", dsun: 213.913100, hgln: 0.000000, hglt: 5.180246 },
  { isoDate: "2007-10-25T00:00:00Z", dsun: 213.853479, hgln: -0.000000, hglt: 5.091247 },
  { isoDate: "2007-10-26T00:00:00Z", dsun: 213.794524, hgln: 0.000000, hglt: 5.000677 },
  { isoDate: "2007-10-27T00:00:00Z", dsun: 213.736270, hgln: -0.000000, hglt: 4.908563 },
  { isoDate: "2007-10-28T00:00:00Z", dsun: 213.678730, hgln: 0.000000, hglt: 4.814928 },
  { isoDate: "2007-10-29T00:00:00Z", dsun: 213.621888, hgln: -0.000000, hglt: 4.719797 },
  { isoDate: "2007-10-30T00:00:00Z", dsun: 213.565711, hgln: 0.000000, hglt: 4.623197 },
  { isoDate: "2007-10-31T00:00:00Z", dsun: 213.510152, hgln: 0.000000, hglt: 4.525151 },
  { isoDate: "2007-11-01T00:00:00Z", dsun: 213.455158, hgln: -0.000000, hglt: 4.425685 },
  { isoDate: "2007-11-02T00:00:00Z", dsun: 213.400678, hgln: -0.000000, hglt: 4.324827 },
  { isoDate: "2007-11-03T00:00:00Z", dsun: 213.346664, hgln: -0.000000, hglt: 4.222604 },
  { isoDate: "2007-11-04T00:00:00Z", dsun: 213.293074, hgln: -0.000000, hglt: 4.119042 },
  { isoDate: "2007-11-05T00:00:00Z", dsun: 213.239875, hgln: -0.000000, hglt: 4.014173 },
  { isoDate: "2007-11-06T00:00:00Z", dsun: 213.187041, hgln: 0.000000, hglt: 3.908024 },
  { isoDate: "2007-11-07T00:00:00Z", dsun: 213.134553, hgln: 0.000000, hglt: 3.800628 },
  { isoDate: "2007-11-08T00:00:00Z", dsun: 213.082398, hgln: -0.000000, hglt: 3.692015 },
  { isoDate: "2007-11-09T00:00:00Z", dsun: 213.030575, hgln: -0.000000, hglt: 3.582218 },
  { isoDate: "2007-11-10T00:00:00Z", dsun: 212.979086, hgln: 0.000000, hglt: 3.471270 },
  { isoDate: "2007-11-11T00:00:00Z", dsun: 212.927944, hgln: 0.000000, hglt: 3.359206 },
  { isoDate: "2007-11-12T00:00:00Z", dsun: 212.877169, hgln: -0.000000, hglt: 3.246059 },
  { isoDate: "2007-11-13T00:00:00Z", dsun: 212.826790, hgln: -0.000000, hglt: 3.131865 },
  { isoDate: "2007-11-14T00:00:00Z", dsun: 212.776843, hgln: 0.000000, hglt: 3.016662 },
  { isoDate: "2007-11-15T00:00:00Z", dsun: 212.727373, hgln: 0.000000, hglt: 2.900485 },
  { isoDate: "2007-11-16T00:00:00Z", dsun: 212.678432, hgln: -0.000000, hglt: 2.783372 },
  { isoDate: "2007-11-17T00:00:00Z", dsun: 212.630080, hgln: -0.000000, hglt: 2.665361 },
  { isoDate: "2007-11-18T00:00:00Z", dsun: 212.582386, hgln: 0.000000, hglt: 2.546490 },
  { isoDate: "2007-11-19T00:00:00Z", dsun: 212.535427, hgln: -0.000000, hglt: 2.426799 },
  { isoDate: "2007-11-20T00:00:00Z", dsun: 212.489282, hgln: -0.000000, hglt: 2.306326 },
  { isoDate: "2007-11-21T00:00:00Z", dsun: 212.444039, hgln: -0.000000, hglt: 2.185109 },
  { isoDate: "2007-11-22T00:00:00Z", dsun: 212.399780, hgln: 0.000000, hglt: 2.063188 },
  { isoDate: "2007-11-23T00:00:00Z", dsun: 212.356582, hgln: 0.000000, hglt: 1.940600 },
  { isoDate: "2007-11-24T00:00:00Z", dsun: 212.314506, hgln: -0.000000, hglt: 1.817382 },
  { isoDate: "2007-11-25T00:00:00Z", dsun: 212.273589, hgln: -0.000000, hglt: 1.693571 },
  { isoDate: "2007-11-26T00:00:00Z", dsun: 212.233847, hgln: -0.000000, hglt: 1.569201 },
  { isoDate: "2007-11-27T00:00:00Z", dsun: 212.195265, hgln: 0.000000, hglt: 1.444308 },
  { isoDate: "2007-11-28T00:00:00Z", dsun: 212.157814, hgln: -0.000000, hglt: 1.318927 },
  { isoDate: "2007-11-29T00:00:00Z", dsun: 212.121447, hgln: 0.000000, hglt: 1.193093 },
  { isoDate: "2007-11-30T00:00:00Z", dsun: 212.086113, hgln: 0.000000, hglt: 1.066843 },
  { isoDate: "2007-12-01T00:00:00Z", dsun: 212.051759, hgln: -0.000000, hglt: 0.940214 },
  { isoDate: "2007-12-02T00:00:00Z", dsun: 212.018335, hgln: 0.000000, hglt: 0.813243 },
  { isoDate: "2007-12-03T00:00:00Z", dsun: 211.985796, hgln: 0.000000, hglt: 0.685969 },
  { isoDate: "2007-12-04T00:00:00Z", dsun: 211.954101, hgln: 0.000000, hglt: 0.558431 },
  { isoDate: "2007-12-05T00:00:00Z", dsun: 211.923215, hgln: -0.000000, hglt: 0.430668 },
  { isoDate: "2007-12-06T00:00:00Z", dsun: 211.893109, hgln: 0.000000, hglt: 0.302721 },
  { isoDate: "2007-12-07T00:00:00Z", dsun: 211.863758, hgln: 0.000000, hglt: 0.174632 },
  { isoDate: "2007-12-08T00:00:00Z", dsun: 211.835144, hgln: 0.000000, hglt: 0.046441 },
  { isoDate: "2007-12-09T00:00:00Z", dsun: 211.807256, hgln: 0.000000, hglt: -0.081810 },
  { isoDate: "2007-12-10T00:00:00Z", dsun: 211.780089, hgln: -0.000000, hglt: -0.210078 },
  { isoDate: "2007-12-11T00:00:00Z", dsun: 211.753646, hgln: 0.000000, hglt: -0.338320 },
  { isoDate: "2007-12-12T00:00:00Z", dsun: 211.727939, hgln: 0.000000, hglt: -0.466492 },
  { isoDate: "2007-12-13T00:00:00Z", dsun: 211.702991, hgln: -0.000000, hglt: -0.594551 },
  { isoDate: "2007-12-14T00:00:00Z", dsun: 211.678833, hgln: 0.000000, hglt: -0.722453 },
  { isoDate: "2007-12-15T00:00:00Z", dsun: 211.655507, hgln: 0.000000, hglt: -0.850153 },
  { isoDate: "2007-12-16T00:00:00Z", dsun: 211.633063, hgln: 0.000000, hglt: -0.977608 },
  { isoDate: "2007-12-17T00:00:00Z", dsun: 211.611565, hgln: 0.000000, hglt: -1.104773 },
  { isoDate: "2007-12-18T00:00:00Z", dsun: 211.591079, hgln: 0.000000, hglt: -1.231605 },
  { isoDate: "2007-12-19T00:00:00Z", dsun: 211.571683, hgln: 0.000000, hglt: -1.358061 },
  { isoDate: "2007-12-20T00:00:00Z", dsun: 211.553455, hgln: 0.000000, hglt: -1.484099 },
  { isoDate: "2007-12-21T00:00:00Z", dsun: 211.536472, hgln: -0.000000, hglt: -1.609677 },
  { isoDate: "2007-12-22T00:00:00Z", dsun: 211.520805, hgln: -0.000000, hglt: -1.734756 },
  { isoDate: "2007-12-23T00:00:00Z", dsun: 211.506509, hgln: 0.000000, hglt: -1.859297 },
  { isoDate: "2007-12-24T00:00:00Z", dsun: 211.493619, hgln: 0.000000, hglt: -1.983263 },
  { isoDate: "2007-12-25T00:00:00Z", dsun: 211.482146, hgln: 0.000000, hglt: -2.106617 },
  { isoDate: "2007-12-26T00:00:00Z", dsun: 211.472079, hgln: 0.000000, hglt: -2.229325 },
  { isoDate: "2007-12-27T00:00:00Z", dsun: 211.463389, hgln: -0.000000, hglt: -2.351349 },
  { isoDate: "2007-12-28T00:00:00Z", dsun: 211.456029, hgln: -0.000000, hglt: -2.472655 },
  { isoDate: "2007-12-29T00:00:00Z", dsun: 211.449949, hgln: -0.000000, hglt: -2.593207 },
  { isoDate: "2007-12-30T00:00:00Z", dsun: 211.445093, hgln: 0.000000, hglt: -2.712966 },
  { isoDate: "2007-12-31T00:00:00Z", dsun: 211.441406, hgln: -0.000000, hglt: -2.831898 },
  { isoDate: "2008-01-01T00:00:00Z", dsun: 211.438834, hgln: 0.000000, hglt: -2.949963 },
  { isoDate: "2008-01-02T00:00:00Z", dsun: 211.437328, hgln: 0.000000, hglt: -3.067124 },
  { isoDate: "2008-01-03T00:00:00Z", dsun: 211.436840, hgln: -0.000000, hglt: -3.183343 },
  { isoDate: "2008-01-04T00:00:00Z", dsun: 211.437326, hgln: -0.000000, hglt: -3.298583 },
  { isoDate: "2008-01-05T00:00:00Z", dsun: 211.438745, hgln: 0.000000, hglt: -3.412804 },
  { isoDate: "2008-01-06T00:00:00Z", dsun: 211.441062, hgln: -0.000000, hglt: -3.525968 },
  { isoDate: "2008-01-07T00:00:00Z", dsun: 211.444244, hgln: -0.000000, hglt: -3.638037 },
  { isoDate: "2008-01-08T00:00:00Z", dsun: 211.448267, hgln: 0.000000, hglt: -3.748972 },
  { isoDate: "2008-01-09T00:00:00Z", dsun: 211.453113, hgln: 0.000000, hglt: -3.858734 },
  { isoDate: "2008-01-10T00:00:00Z", dsun: 211.458776, hgln: 0.000000, hglt: -3.967285 },
  { isoDate: "2008-01-11T00:00:00Z", dsun: 211.465259, hgln: -0.000000, hglt: -4.074586 },
  { isoDate: "2008-01-12T00:00:00Z", dsun: 211.472579, hgln: -0.000000, hglt: -4.180598 },
  { isoDate: "2008-01-13T00:00:00Z", dsun: 211.480765, hgln: 0.000000, hglt: -4.285284 },
  { isoDate: "2008-01-14T00:00:00Z", dsun: 211.489858, hgln: -0.000000, hglt: -4.388606 },
  { isoDate: "2008-01-15T00:00:00Z", dsun: 211.499911, hgln: -0.000000, hglt: -4.490529 },
  { isoDate: "2008-01-16T00:00:00Z", dsun: 211.510985, hgln: 0.000000, hglt: -4.591016 },
  { isoDate: "2008-01-17T00:00:00Z", dsun: 211.523148, hgln: -0.000000, hglt: -4.690035 },
  { isoDate: "2008-01-18T00:00:00Z", dsun: 211.536470, hgln: -0.000000, hglt: -4.787552 },
  { isoDate: "2008-01-19T00:00:00Z", dsun: 211.551016, hgln: 0.000000, hglt: -4.883537 },
  { isoDate: "2008-01-20T00:00:00Z", dsun: 211.566847, hgln: 0.000000, hglt: -4.977960 },
  { isoDate: "2008-01-21T00:00:00Z", dsun: 211.584008, hgln: -0.000000, hglt: -5.070794 },
  { isoDate: "2008-01-22T00:00:00Z", dsun: 211.602526, hgln: 0.000000, hglt: -5.162011 },
  { isoDate: "2008-01-23T00:00:00Z", dsun: 211.622409, hgln: -0.000000, hglt: -5.251588 },
  { isoDate: "2008-01-24T00:00:00Z", dsun: 211.643645, hgln: 0.000000, hglt: -5.339498 },
  { isoDate: "2008-01-25T00:00:00Z", dsun: 211.666204, hgln: -0.000000, hglt: -5.425717 },
  { isoDate: "2008-01-26T00:00:00Z", dsun: 211.690045, hgln: -0.000000, hglt: -5.510222 },
  { isoDate: "2008-01-27T00:00:00Z", dsun: 211.715115, hgln: -0.000000, hglt: -5.592988 },
  { isoDate: "2008-01-28T00:00:00Z", dsun: 211.741357, hgln: -0.000000, hglt: -5.673990 },
  { isoDate: "2008-01-29T00:00:00Z", dsun: 211.768713, hgln: 0.000000, hglt: -5.753206 },
  { isoDate: "2008-01-30T00:00:00Z", dsun: 211.797122, hgln: -0.000000, hglt: -5.830609 },
  { isoDate: "2008-01-31T00:00:00Z", dsun: 211.826525, hgln: 0.000000, hglt: -5.906177 },
  { isoDate: "2008-02-01T00:00:00Z", dsun: 211.856863, hgln: -0.000000, hglt: -5.979886 },
  { isoDate: "2008-02-02T00:00:00Z", dsun: 211.888078, hgln: 0.000000, hglt: -6.051713 },
  { isoDate: "2008-02-03T00:00:00Z", dsun: 211.920114, hgln: -0.000000, hglt: -6.121634 },
  { isoDate: "2008-02-04T00:00:00Z", dsun: 211.952916, hgln: -0.000000, hglt: -6.189626 },
  { isoDate: "2008-02-05T00:00:00Z", dsun: 211.986432, hgln: 0.000000, hglt: -6.255667 },
  { isoDate: "2008-02-06T00:00:00Z", dsun: 212.020618, hgln: 0.000000, hglt: -6.319734 },
  { isoDate: "2008-02-07T00:00:00Z", dsun: 212.055435, hgln: -0.000000, hglt: -6.381806 },
  { isoDate: "2008-02-08T00:00:00Z", dsun: 212.090855, hgln: 0.000000, hglt: -6.441861 },
  { isoDate: "2008-02-09T00:00:00Z", dsun: 212.126866, hgln: -0.000000, hglt: -6.499877 },
  { isoDate: "2008-02-10T00:00:00Z", dsun: 212.163469, hgln: 0.000000, hglt: -6.555836 },
  { isoDate: "2008-02-11T00:00:00Z", dsun: 212.200683, hgln: 0.000000, hglt: -6.609716 },
  { isoDate: "2008-02-12T00:00:00Z", dsun: 212.238540, hgln: -0.000000, hglt: -6.661501 },
  { isoDate: "2008-02-13T00:00:00Z", dsun: 212.277086, hgln: -0.000000, hglt: -6.711174 },
  { isoDate: "2008-02-14T00:00:00Z", dsun: 212.316378, hgln: 0.000000, hglt: -6.758720 },
  { isoDate: "2008-02-15T00:00:00Z", dsun: 212.356476, hgln: -0.000000, hglt: -6.804125 },
  { isoDate: "2008-02-16T00:00:00Z", dsun: 212.397439, hgln: -0.000000, hglt: -6.847378 },
  { isoDate: "2008-02-17T00:00:00Z", dsun: 212.439326, hgln: -0.000000, hglt: -6.888468 },
  { isoDate: "2008-02-18T00:00:00Z", dsun: 212.482183, hgln: -0.000000, hglt: -6.927387 },
  { isoDate: "2008-02-19T00:00:00Z", dsun: 212.526047, hgln: -0.000000, hglt: -6.964126 },
  { isoDate: "2008-02-20T00:00:00Z", dsun: 212.570938, hgln: -0.000000, hglt: -6.998680 },
  { isoDate: "2008-02-21T00:00:00Z", dsun: 212.616860, hgln: -0.000000, hglt: -7.031042 },
  { isoDate: "2008-02-22T00:00:00Z", dsun: 212.663802, hgln: 0.000000, hglt: -7.061208 },
  { isoDate: "2008-02-23T00:00:00Z", dsun: 212.711736, hgln: -0.000000, hglt: -7.089173 },
  { isoDate: "2008-02-24T00:00:00Z", dsun: 212.760624, hgln: 0.000000, hglt: -7.114933 },
  { isoDate: "2008-02-25T00:00:00Z", dsun: 212.810417, hgln: -0.000000, hglt: -7.138483 },
  { isoDate: "2008-02-26T00:00:00Z", dsun: 212.861062, hgln: -0.000000, hglt: -7.159820 },
  { isoDate: "2008-02-27T00:00:00Z", dsun: 212.912498, hgln: 0.000000, hglt: -7.178941 },
  { isoDate: "2008-02-28T00:00:00Z", dsun: 212.964665, hgln: 0.000000, hglt: -7.195843 },
  { isoDate: "2008-02-29T00:00:00Z", dsun: 213.017497, hgln: -0.000000, hglt: -7.210523 },
  { isoDate: "2008-03-01T00:00:00Z", dsun: 213.070929, hgln: 0.000000, hglt: -7.222979 },
  { isoDate: "2008-03-02T00:00:00Z", dsun: 213.124895, hgln: -0.000000, hglt: -7.233209 },
  { isoDate: "2008-03-03T00:00:00Z", dsun: 213.179325, hgln: -0.000000, hglt: -7.241212 },
  { isoDate: "2008-03-04T00:00:00Z", dsun: 213.234152, hgln: -0.000000, hglt: -7.246987 },
  { isoDate: "2008-03-05T00:00:00Z", dsun: 213.289308, hgln: -0.000000, hglt: -7.250534 },
  { isoDate: "2008-03-06T00:00:00Z", dsun: 213.344731, hgln: -0.000000, hglt: -7.251854 },
  { isoDate: "2008-03-07T00:00:00Z", dsun: 213.400364, hgln: 0.000000, hglt: -7.250946 },
  { isoDate: "2008-03-08T00:00:00Z", dsun: 213.456164, hgln: 0.000000, hglt: -7.247812 },
  { isoDate: "2008-03-09T00:00:00Z", dsun: 213.512102, hgln: 0.000000, hglt: -7.242455 },
  { isoDate: "2008-03-10T00:00:00Z", dsun: 213.568169, hgln: -0.000000, hglt: -7.234877 },
  { isoDate: "2008-03-11T00:00:00Z", dsun: 213.624376, hgln: -0.000000, hglt: -7.225085 },
  { isoDate: "2008-03-12T00:00:00Z", dsun: 213.680752, hgln: 0.000000, hglt: -7.213083 },
  { isoDate: "2008-03-13T00:00:00Z", dsun: 213.737340, hgln: 0.000000, hglt: -7.198879 },
  { isoDate: "2008-03-14T00:00:00Z", dsun: 213.794194, hgln: -0.000000, hglt: -7.182482 },
  { isoDate: "2008-03-15T00:00:00Z", dsun: 213.851371, hgln: 0.000000, hglt: -7.163902 },
  { isoDate: "2008-03-16T00:00:00Z", dsun: 213.908924, hgln: 0.000000, hglt: -7.143150 },
  { isoDate: "2008-03-17T00:00:00Z", dsun: 213.966905, hgln: 0.000000, hglt: -7.120238 },
  { isoDate: "2008-03-18T00:00:00Z", dsun: 214.025353, hgln: -0.000000, hglt: -7.095180 },
  { isoDate: "2008-03-19T00:00:00Z", dsun: 214.084298, hgln: 0.000000, hglt: -7.067989 },
  { isoDate: "2008-03-20T00:00:00Z", dsun: 214.143759, hgln: 0.000000, hglt: -7.038679 },
  { isoDate: "2008-03-21T00:00:00Z", dsun: 214.203738, hgln: -0.000000, hglt: -7.007265 },
  { isoDate: "2008-03-22T00:00:00Z", dsun: 214.264227, hgln: -0.000000, hglt: -6.973761 },
  { isoDate: "2008-03-23T00:00:00Z", dsun: 214.325206, hgln: 0.000000, hglt: -6.938182 },
  { isoDate: "2008-03-24T00:00:00Z", dsun: 214.386642, hgln: -0.000000, hglt: -6.900545 },
  { isoDate: "2008-03-25T00:00:00Z", dsun: 214.448495, hgln: 0.000000, hglt: -6.860864 },
  { isoDate: "2008-03-26T00:00:00Z", dsun: 214.510718, hgln: -0.000000, hglt: -6.819155 },
  { isoDate: "2008-03-27T00:00:00Z", dsun: 214.573256, hgln: 0.000000, hglt: -6.775435 },
  { isoDate: "2008-03-28T00:00:00Z", dsun: 214.636052, hgln: 0.000000, hglt: -6.729719 },
  { isoDate: "2008-03-29T00:00:00Z", dsun: 214.699041, hgln: 0.000000, hglt: -6.682024 },
  { isoDate: "2008-03-30T00:00:00Z", dsun: 214.762159, hgln: 0.000000, hglt: -6.632367 },
  { isoDate: "2008-03-31T00:00:00Z", dsun: 214.825335, hgln: 0.000000, hglt: -6.580766 },
  { isoDate: "2008-04-01T00:00:00Z", dsun: 214.888495, hgln: -0.000000, hglt: -6.527237 },
  { isoDate: "2008-04-02T00:00:00Z", dsun: 214.951565, hgln: -0.000000, hglt: -6.471800 },
  { isoDate: "2008-04-03T00:00:00Z", dsun: 215.014467, hgln: -0.000000, hglt: -6.414472 },
  { isoDate: "2008-04-04T00:00:00Z", dsun: 215.077127, hgln: -0.000000, hglt: -6.355273 },
  { isoDate: "2008-04-05T00:00:00Z", dsun: 215.139477, hgln: -0.000000, hglt: -6.294222 },
  { isoDate: "2008-04-06T00:00:00Z", dsun: 215.201461, hgln: 0.000000, hglt: -6.231340 },
  { isoDate: "2008-04-07T00:00:00Z", dsun: 215.263041, hgln: -0.000000, hglt: -6.166649 },
  { isoDate: "2008-04-08T00:00:00Z", dsun: 215.324202, hgln: 0.000000, hglt: -6.100171 },
  { isoDate: "2008-04-09T00:00:00Z", dsun: 215.384952, hgln: -0.000000, hglt: -6.031930 },
  { isoDate: "2008-04-10T00:00:00Z", dsun: 215.445318, hgln: 0.000000, hglt: -5.961952 },
  { isoDate: "2008-04-11T00:00:00Z", dsun: 215.505347, hgln: -0.000000, hglt: -5.890263 },
  { isoDate: "2008-04-12T00:00:00Z", dsun: 215.565091, hgln: 0.000000, hglt: -5.816888 },
  { isoDate: "2008-04-13T00:00:00Z", dsun: 215.624607, hgln: 0.000000, hglt: -5.741856 },
  { isoDate: "2008-04-14T00:00:00Z", dsun: 215.683948, hgln: -0.000000, hglt: -5.665193 },
  { isoDate: "2008-04-15T00:00:00Z", dsun: 215.743162, hgln: -0.000000, hglt: -5.586927 },
  { isoDate: "2008-04-16T00:00:00Z", dsun: 215.802287, hgln: 0.000000, hglt: -5.507086 },
  { isoDate: "2008-04-17T00:00:00Z", dsun: 215.861354, hgln: 0.000000, hglt: -5.425696 },
  { isoDate: "2008-04-18T00:00:00Z", dsun: 215.920382, hgln: 0.000000, hglt: -5.342787 },
  { isoDate: "2008-04-19T00:00:00Z", dsun: 215.979379, hgln: 0.000000, hglt: -5.258385 },
  { isoDate: "2008-04-20T00:00:00Z", dsun: 216.038344, hgln: -0.000000, hglt: -5.172517 },
  { isoDate: "2008-04-21T00:00:00Z", dsun: 216.097265, hgln: 0.000000, hglt: -5.085211 },
  { isoDate: "2008-04-22T00:00:00Z", dsun: 216.156121, hgln: -0.000000, hglt: -4.996494 },
  { isoDate: "2008-04-23T00:00:00Z", dsun: 216.214885, hgln: 0.000000, hglt: -4.906392 },
  { isoDate: "2008-04-24T00:00:00Z", dsun: 216.273517, hgln: 0.000000, hglt: -4.814933 },
  { isoDate: "2008-04-25T00:00:00Z", dsun: 216.331975, hgln: 0.000000, hglt: -4.722145 },
  { isoDate: "2008-04-26T00:00:00Z", dsun: 216.390207, hgln: 0.000000, hglt: -4.628053 },
  { isoDate: "2008-04-27T00:00:00Z", dsun: 216.448159, hgln: -0.000000, hglt: -4.532686 },
  { isoDate: "2008-04-28T00:00:00Z", dsun: 216.505768, hgln: 0.000000, hglt: -4.436071 },
  { isoDate: "2008-04-29T00:00:00Z", dsun: 216.562967, hgln: -0.000000, hglt: -4.338235 },
  { isoDate: "2008-04-30T00:00:00Z", dsun: 216.619683, hgln: -0.000000, hglt: -4.239206 },
  { isoDate: "2008-05-01T00:00:00Z", dsun: 216.675839, hgln: -0.000000, hglt: -4.139011 },
  { isoDate: "2008-05-02T00:00:00Z", dsun: 216.731354, hgln: 0.000000, hglt: -4.037680 },
  { isoDate: "2008-05-03T00:00:00Z", dsun: 216.786148, hgln: 0.000000, hglt: -3.935242 },
  { isoDate: "2008-05-04T00:00:00Z", dsun: 216.840149, hgln: -0.000000, hglt: -3.831726 },
  { isoDate: "2008-05-05T00:00:00Z", dsun: 216.893295, hgln: -0.000000, hglt: -3.727163 },
  { isoDate: "2008-05-06T00:00:00Z", dsun: 216.945543, hgln: 0.000000, hglt: -3.621586 },
  { isoDate: "2008-05-07T00:00:00Z", dsun: 216.996877, hgln: -0.000000, hglt: -3.515027 },
  { isoDate: "2008-05-08T00:00:00Z", dsun: 217.047305, hgln: -0.000000, hglt: -3.407521 },
  { isoDate: "2008-05-09T00:00:00Z", dsun: 217.096857, hgln: -0.000000, hglt: -3.299103 },
  { isoDate: "2008-05-10T00:00:00Z", dsun: 217.145581, hgln: 0.000000, hglt: -3.189808 },
  { isoDate: "2008-05-11T00:00:00Z", dsun: 217.193532, hgln: -0.000000, hglt: -3.079669 },
  { isoDate: "2008-05-12T00:00:00Z", dsun: 217.240769, hgln: 0.000000, hglt: -2.968723 },
  { isoDate: "2008-05-13T00:00:00Z", dsun: 217.287346, hgln: -0.000000, hglt: -2.857002 },
  { isoDate: "2008-05-14T00:00:00Z", dsun: 217.333313, hgln: 0.000000, hglt: -2.744541 },
  { isoDate: "2008-05-15T00:00:00Z", dsun: 217.378713, hgln: -0.000000, hglt: -2.631372 },
  { isoDate: "2008-05-16T00:00:00Z", dsun: 217.423580, hgln: 0.000000, hglt: -2.517528 },
  { isoDate: "2008-05-17T00:00:00Z", dsun: 217.467939, hgln: 0.000000, hglt: -2.403042 },
  { isoDate: "2008-05-18T00:00:00Z", dsun: 217.511808, hgln: 0.000000, hglt: -2.287944 },
  { isoDate: "2008-05-19T00:00:00Z", dsun: 217.555198, hgln: 0.000000, hglt: -2.172266 },
  { isoDate: "2008-05-20T00:00:00Z", dsun: 217.598107, hgln: -0.000000, hglt: -2.056039 },
  { isoDate: "2008-05-21T00:00:00Z", dsun: 217.640531, hgln: 0.000000, hglt: -1.939294 },
  { isoDate: "2008-05-22T00:00:00Z", dsun: 217.682452, hgln: 0.000000, hglt: -1.822061 },
  { isoDate: "2008-05-23T00:00:00Z", dsun: 217.723848, hgln: -0.000000, hglt: -1.704370 },
  { isoDate: "2008-05-24T00:00:00Z", dsun: 217.764687, hgln: -0.000000, hglt: -1.586251 },
  { isoDate: "2008-05-25T00:00:00Z", dsun: 217.804929, hgln: -0.000000, hglt: -1.467735 },
  { isoDate: "2008-05-26T00:00:00Z", dsun: 217.844530, hgln: -0.000000, hglt: -1.348850 },
  { isoDate: "2008-05-27T00:00:00Z", dsun: 217.883433, hgln: 0.000000, hglt: -1.229627 },
  { isoDate: "2008-05-28T00:00:00Z", dsun: 217.921577, hgln: 0.000000, hglt: -1.110095 },
  { isoDate: "2008-05-29T00:00:00Z", dsun: 217.958894, hgln: -0.000000, hglt: -0.990284 },
  { isoDate: "2008-05-30T00:00:00Z", dsun: 217.995308, hgln: 0.000000, hglt: -0.870225 },
  { isoDate: "2008-05-31T00:00:00Z", dsun: 218.030740, hgln: -0.000000, hglt: -0.749949 },
  { isoDate: "2008-06-01T00:00:00Z", dsun: 218.065110, hgln: -0.000000, hglt: -0.629487 },
  { isoDate: "2008-06-02T00:00:00Z", dsun: 218.098346, hgln: -0.000000, hglt: -0.508872 },
  { isoDate: "2008-06-03T00:00:00Z", dsun: 218.130386, hgln: 0.000000, hglt: -0.388138 },
  { isoDate: "2008-06-04T00:00:00Z", dsun: 218.161190, hgln: 0.000000, hglt: -0.267320 },
  { isoDate: "2008-06-05T00:00:00Z", dsun: 218.190742, hgln: -0.000000, hglt: -0.146454 },
  { isoDate: "2008-06-06T00:00:00Z", dsun: 218.219052, hgln: -0.000000, hglt: -0.025577 },
  { isoDate: "2008-06-07T00:00:00Z", dsun: 218.246154, hgln: 0.000000, hglt: 0.095275 },
  { isoDate: "2008-06-08T00:00:00Z", dsun: 218.272097, hgln: -0.000000, hglt: 0.216065 },
  { isoDate: "2008-06-09T00:00:00Z", dsun: 218.296939, hgln: -0.000000, hglt: 0.336759 },
  { isoDate: "2008-06-10T00:00:00Z", dsun: 218.320741, hgln: 0.000000, hglt: 0.457320 },
  { isoDate: "2008-06-11T00:00:00Z", dsun: 218.343561, hgln: -0.000000, hglt: 0.577717 },
  { isoDate: "2008-06-12T00:00:00Z", dsun: 218.365453, hgln: -0.000000, hglt: 0.697914 },
  { isoDate: "2008-06-13T00:00:00Z", dsun: 218.386466, hgln: -0.000000, hglt: 0.817881 },
  { isoDate: "2008-06-14T00:00:00Z", dsun: 218.406641, hgln: 0.000000, hglt: 0.937586 },
  { isoDate: "2008-06-15T00:00:00Z", dsun: 218.426016, hgln: 0.000000, hglt: 1.056997 },
  { isoDate: "2008-06-16T00:00:00Z", dsun: 218.444618, hgln: 0.000000, hglt: 1.176084 },
  { isoDate: "2008-06-17T00:00:00Z", dsun: 218.462471, hgln: -0.000000, hglt: 1.294818 },
  { isoDate: "2008-06-18T00:00:00Z", dsun: 218.479591, hgln: 0.000000, hglt: 1.413168 },
  { isoDate: "2008-06-19T00:00:00Z", dsun: 218.495985, hgln: 0.000000, hglt: 1.531107 },
  { isoDate: "2008-06-20T00:00:00Z", dsun: 218.511653, hgln: -0.000000, hglt: 1.648605 },
  { isoDate: "2008-06-21T00:00:00Z", dsun: 218.526588, hgln: 0.000000, hglt: 1.765634 },
  { isoDate: "2008-06-22T00:00:00Z", dsun: 218.540770, hgln: 0.000000, hglt: 1.882168 },
  { isoDate: "2008-06-23T00:00:00Z", dsun: 218.554174, hgln: -0.000000, hglt: 1.998177 },
  { isoDate: "2008-06-24T00:00:00Z", dsun: 218.566762, hgln: 0.000000, hglt: 2.113635 },
  { isoDate: "2008-06-25T00:00:00Z", dsun: 218.578488, hgln: 0.000000, hglt: 2.228514 },
  { isoDate: "2008-06-26T00:00:00Z", dsun: 218.589298, hgln: 0.000000, hglt: 2.342787 },
  { isoDate: "2008-06-27T00:00:00Z", dsun: 218.599126, hgln: 0.000000, hglt: 2.456425 },
  { isoDate: "2008-06-28T00:00:00Z", dsun: 218.607903, hgln: -0.000000, hglt: 2.569401 },
  { isoDate: "2008-06-29T00:00:00Z", dsun: 218.615554, hgln: 0.000000, hglt: 2.681685 },
  { isoDate: "2008-06-30T00:00:00Z", dsun: 218.622002, hgln: -0.000000, hglt: 2.793249 },
  { isoDate: "2008-07-01T00:00:00Z", dsun: 218.627180, hgln: 0.000000, hglt: 2.904059 },
  { isoDate: "2008-07-02T00:00:00Z", dsun: 218.631032, hgln: 0.000000, hglt: 3.014086 },
  { isoDate: "2008-07-03T00:00:00Z", dsun: 218.633522, hgln: -0.000000, hglt: 3.123295 },
  { isoDate: "2008-07-04T00:00:00Z", dsun: 218.634640, hgln: 0.000000, hglt: 3.231654 },
  { isoDate: "2008-07-05T00:00:00Z", dsun: 218.634399, hgln: -0.000000, hglt: 3.339129 },
  { isoDate: "2008-07-06T00:00:00Z", dsun: 218.632833, hgln: 0.000000, hglt: 3.445686 },
  { isoDate: "2008-07-07T00:00:00Z", dsun: 218.629992, hgln: 0.000000, hglt: 3.551295 },
  { isoDate: "2008-07-08T00:00:00Z", dsun: 218.625935, hgln: -0.000000, hglt: 3.655922 },
  { isoDate: "2008-07-09T00:00:00Z", dsun: 218.620724, hgln: 0.000000, hglt: 3.759539 },
  { isoDate: "2008-07-10T00:00:00Z", dsun: 218.614422, hgln: 0.000000, hglt: 3.862116 },
  { isoDate: "2008-07-11T00:00:00Z", dsun: 218.607087, hgln: 0.000000, hglt: 3.963624 },
  { isoDate: "2008-07-12T00:00:00Z", dsun: 218.598776, hgln: 0.000000, hglt: 4.064036 },
  { isoDate: "2008-07-13T00:00:00Z", dsun: 218.589539, hgln: 0.000000, hglt: 4.163325 },
  { isoDate: "2008-07-14T00:00:00Z", dsun: 218.579424, hgln: 0.000000, hglt: 4.261465 },
  { isoDate: "2008-07-15T00:00:00Z", dsun: 218.568473, hgln: -0.000000, hglt: 4.358432 },
  { isoDate: "2008-07-16T00:00:00Z", dsun: 218.556723, hgln: -0.000000, hglt: 4.454199 },
  { isoDate: "2008-07-17T00:00:00Z", dsun: 218.544205, hgln: -0.000000, hglt: 4.548744 },
  { isoDate: "2008-07-18T00:00:00Z", dsun: 218.530944, hgln: 0.000000, hglt: 4.642042 },
  { isoDate: "2008-07-19T00:00:00Z", dsun: 218.516957, hgln: 0.000000, hglt: 4.734070 },
  { isoDate: "2008-07-20T00:00:00Z", dsun: 218.502248, hgln: 0.000000, hglt: 4.824807 },
  { isoDate: "2008-07-21T00:00:00Z", dsun: 218.486816, hgln: 0.000000, hglt: 4.914230 },
  { isoDate: "2008-07-22T00:00:00Z", dsun: 218.470645, hgln: -0.000000, hglt: 5.002317 },
  { isoDate: "2008-07-23T00:00:00Z", dsun: 218.453707, hgln: 0.000000, hglt: 5.089046 },
  { isoDate: "2008-07-24T00:00:00Z", dsun: 218.435963, hgln: -0.000000, hglt: 5.174396 },
  { isoDate: "2008-07-25T00:00:00Z", dsun: 218.417365, hgln: 0.000000, hglt: 5.258346 },
  { isoDate: "2008-07-26T00:00:00Z", dsun: 218.397852, hgln: -0.000000, hglt: 5.340872 },
  { isoDate: "2008-07-27T00:00:00Z", dsun: 218.377360, hgln: 0.000000, hglt: 5.421951 },
  { isoDate: "2008-07-28T00:00:00Z", dsun: 218.355817, hgln: 0.000000, hglt: 5.501562 },
  { isoDate: "2008-07-29T00:00:00Z", dsun: 218.333158, hgln: 0.000000, hglt: 5.579679 },
  { isoDate: "2008-07-30T00:00:00Z", dsun: 218.309320, hgln: -0.000000, hglt: 5.656278 },
  { isoDate: "2008-07-31T00:00:00Z", dsun: 218.284258, hgln: -0.000000, hglt: 5.731333 },
  { isoDate: "2008-08-01T00:00:00Z", dsun: 218.257944, hgln: 0.000000, hglt: 5.804820 },
  { isoDate: "2008-08-02T00:00:00Z", dsun: 218.230371, hgln: 0.000000, hglt: 5.876712 },
  { isoDate: "2008-08-03T00:00:00Z", dsun: 218.201555, hgln: 0.000000, hglt: 5.946984 },
  { isoDate: "2008-08-04T00:00:00Z", dsun: 218.171530, hgln: -0.000000, hglt: 6.015613 },
  { isoDate: "2008-08-05T00:00:00Z", dsun: 218.140345, hgln: 0.000000, hglt: 6.082575 },
  { isoDate: "2008-08-06T00:00:00Z", dsun: 218.108058, hgln: 0.000000, hglt: 6.147847 },
  { isoDate: "2008-08-07T00:00:00Z", dsun: 218.074730, hgln: 0.000000, hglt: 6.211409 },
  { isoDate: "2008-08-08T00:00:00Z", dsun: 218.040425, hgln: -0.000000, hglt: 6.273241 },
  { isoDate: "2008-08-09T00:00:00Z", dsun: 218.005208, hgln: 0.000000, hglt: 6.333324 },
  { isoDate: "2008-08-10T00:00:00Z", dsun: 217.969140, hgln: 0.000000, hglt: 6.391638 },
  { isoDate: "2008-08-11T00:00:00Z", dsun: 217.932281, hgln: 0.000000, hglt: 6.448168 },
  { isoDate: "2008-08-12T00:00:00Z", dsun: 217.894688, hgln: -0.000000, hglt: 6.502897 },
  { isoDate: "2008-08-13T00:00:00Z", dsun: 217.856416, hgln: 0.000000, hglt: 6.555808 },
  { isoDate: "2008-08-14T00:00:00Z", dsun: 217.817518, hgln: -0.000000, hglt: 6.606886 },
  { isoDate: "2008-08-15T00:00:00Z", dsun: 217.778040, hgln: 0.000000, hglt: 6.656118 },
  { isoDate: "2008-08-16T00:00:00Z", dsun: 217.738022, hgln: -0.000000, hglt: 6.703490 },
  { isoDate: "2008-08-17T00:00:00Z", dsun: 217.697498, hgln: -0.000000, hglt: 6.748988 },
  { isoDate: "2008-08-18T00:00:00Z", dsun: 217.656489, hgln: 0.000000, hglt: 6.792601 },
  { isoDate: "2008-08-19T00:00:00Z", dsun: 217.615006, hgln: 0.000000, hglt: 6.834316 },
  { isoDate: "2008-08-20T00:00:00Z", dsun: 217.573043, hgln: 0.000000, hglt: 6.874121 },
  { isoDate: "2008-08-21T00:00:00Z", dsun: 217.530580, hgln: -0.000000, hglt: 6.912006 },
  { isoDate: "2008-08-22T00:00:00Z", dsun: 217.487586, hgln: -0.000000, hglt: 6.947958 },
  { isoDate: "2008-08-23T00:00:00Z", dsun: 217.444013, hgln: -0.000000, hglt: 6.981965 },
  { isoDate: "2008-08-24T00:00:00Z", dsun: 217.399807, hgln: -0.000000, hglt: 7.014015 },
  { isoDate: "2008-08-25T00:00:00Z", dsun: 217.354906, hgln: -0.000000, hglt: 7.044097 },
  { isoDate: "2008-08-26T00:00:00Z", dsun: 217.309247, hgln: 0.000000, hglt: 7.072197 },
  { isoDate: "2008-08-27T00:00:00Z", dsun: 217.262772, hgln: -0.000000, hglt: 7.098302 },
  { isoDate: "2008-08-28T00:00:00Z", dsun: 217.215429, hgln: 0.000000, hglt: 7.122401 },
  { isoDate: "2008-08-29T00:00:00Z", dsun: 217.167182, hgln: 0.000000, hglt: 7.144480 },
  { isoDate: "2008-08-30T00:00:00Z", dsun: 217.118009, hgln: -0.000000, hglt: 7.164528 },
  { isoDate: "2008-08-31T00:00:00Z", dsun: 217.067909, hgln: 0.000000, hglt: 7.182532 },
  { isoDate: "2008-09-01T00:00:00Z", dsun: 217.016898, hgln: 0.000000, hglt: 7.198483 },
  { isoDate: "2008-09-02T00:00:00Z", dsun: 216.965007, hgln: 0.000000, hglt: 7.212371 },
  { isoDate: "2008-09-03T00:00:00Z", dsun: 216.912282, hgln: 0.000000, hglt: 7.224187 },
  { isoDate: "2008-09-04T00:00:00Z", dsun: 216.858776, hgln: -0.000000, hglt: 7.233925 },
  { isoDate: "2008-09-05T00:00:00Z", dsun: 216.804548, hgln: 0.000000, hglt: 7.241578 },
  { isoDate: "2008-09-06T00:00:00Z", dsun: 216.749663, hgln: 0.000000, hglt: 7.247141 },
  { isoDate: "2008-09-07T00:00:00Z", dsun: 216.694185, hgln: 0.000000, hglt: 7.250609 },
  { isoDate: "2008-09-08T00:00:00Z", dsun: 216.638179, hgln: 0.000000, hglt: 7.251980 },
  { isoDate: "2008-09-09T00:00:00Z", dsun: 216.581712, hgln: -0.000000, hglt: 7.251251 },
  { isoDate: "2008-09-10T00:00:00Z", dsun: 216.524849, hgln: -0.000000, hglt: 7.248421 },
  { isoDate: "2008-09-11T00:00:00Z", dsun: 216.467656, hgln: -0.000000, hglt: 7.243489 },
  { isoDate: "2008-09-12T00:00:00Z", dsun: 216.410197, hgln: -0.000000, hglt: 7.236454 },
  { isoDate: "2008-09-13T00:00:00Z", dsun: 216.352533, hgln: -0.000000, hglt: 7.227318 },
  { isoDate: "2008-09-14T00:00:00Z", dsun: 216.294721, hgln: -0.000000, hglt: 7.216082 },
  { isoDate: "2008-09-15T00:00:00Z", dsun: 216.236809, hgln: -0.000000, hglt: 7.202748 },
  { isoDate: "2008-09-16T00:00:00Z", dsun: 216.178832, hgln: 0.000000, hglt: 7.187318 },
  { isoDate: "2008-09-17T00:00:00Z", dsun: 216.120814, hgln: 0.000000, hglt: 7.169795 },
  { isoDate: "2008-09-18T00:00:00Z", dsun: 216.062758, hgln: 0.000000, hglt: 7.150182 },
  { isoDate: "2008-09-19T00:00:00Z", dsun: 216.004649, hgln: 0.000000, hglt: 7.128483 },
  { isoDate: "2008-09-20T00:00:00Z", dsun: 215.946459, hgln: 0.000000, hglt: 7.104699 },
  { isoDate: "2008-09-21T00:00:00Z", dsun: 215.888143, hgln: 0.000000, hglt: 7.078833 },
  { isoDate: "2008-09-22T00:00:00Z", dsun: 215.829647, hgln: -0.000000, hglt: 7.050889 },
  { isoDate: "2008-09-23T00:00:00Z", dsun: 215.770914, hgln: -0.000000, hglt: 7.020870 },
  { isoDate: "2008-09-24T00:00:00Z", dsun: 215.711888, hgln: -0.000000, hglt: 6.988780 },
  { isoDate: "2008-09-25T00:00:00Z", dsun: 215.652517, hgln: 0.000000, hglt: 6.954621 },
  { isoDate: "2008-09-26T00:00:00Z", dsun: 215.592759, hgln: -0.000000, hglt: 6.918399 },
  { isoDate: "2008-09-27T00:00:00Z", dsun: 215.532582, hgln: 0.000000, hglt: 6.880120 },
  { isoDate: "2008-09-28T00:00:00Z", dsun: 215.471972, hgln: 0.000000, hglt: 6.839790 },
  { isoDate: "2008-09-29T00:00:00Z", dsun: 215.410928, hgln: -0.000000, hglt: 6.797416 },
  { isoDate: "2008-09-30T00:00:00Z", dsun: 215.349461, hgln: 0.000000, hglt: 6.753008 },
  { isoDate: "2008-10-01T00:00:00Z", dsun: 215.287601, hgln: 0.000000, hglt: 6.706575 },
  { isoDate: "2008-10-02T00:00:00Z", dsun: 215.225383, hgln: 0.000000, hglt: 6.658127 },
  { isoDate: "2008-10-03T00:00:00Z", dsun: 215.162855, hgln: -0.000000, hglt: 6.607678 },
  { isoDate: "2008-10-04T00:00:00Z", dsun: 215.100072, hgln: -0.000000, hglt: 6.555239 },
  { isoDate: "2008-10-05T00:00:00Z", dsun: 215.037090, hgln: 0.000000, hglt: 6.500826 },
  { isoDate: "2008-10-06T00:00:00Z", dsun: 214.973973, hgln: 0.000000, hglt: 6.444451 },
  { isoDate: "2008-10-07T00:00:00Z", dsun: 214.910786, hgln: 0.000000, hglt: 6.386132 },
  { isoDate: "2008-10-08T00:00:00Z", dsun: 214.847595, hgln: -0.000000, hglt: 6.325883 },
  { isoDate: "2008-10-09T00:00:00Z", dsun: 214.784471, hgln: 0.000000, hglt: 6.263723 },
  { isoDate: "2008-10-10T00:00:00Z", dsun: 214.721486, hgln: -0.000000, hglt: 6.199669 },
  { isoDate: "2008-10-11T00:00:00Z", dsun: 214.658711, hgln: -0.000000, hglt: 6.133739 },
  { isoDate: "2008-10-12T00:00:00Z", dsun: 214.596220, hgln: 0.000000, hglt: 6.065951 },
  { isoDate: "2008-10-13T00:00:00Z", dsun: 214.534082, hgln: -0.000000, hglt: 5.996326 },
  { isoDate: "2008-10-14T00:00:00Z", dsun: 214.472356, hgln: 0.000000, hglt: 5.924882 },
  { isoDate: "2008-10-15T00:00:00Z", dsun: 214.411091, hgln: -0.000000, hglt: 5.851639 },
  { isoDate: "2008-10-16T00:00:00Z", dsun: 214.350321, hgln: 0.000000, hglt: 5.776616 },
  { isoDate: "2008-10-17T00:00:00Z", dsun: 214.290053, hgln: 0.000000, hglt: 5.699832 },
  { isoDate: "2008-10-18T00:00:00Z", dsun: 214.230279, hgln: 0.000000, hglt: 5.621307 },
  { isoDate: "2008-10-19T00:00:00Z", dsun: 214.170968, hgln: -0.000000, hglt: 5.541058 },
  { isoDate: "2008-10-20T00:00:00Z", dsun: 214.112075, hgln: 0.000000, hglt: 5.459106 },
  { isoDate: "2008-10-21T00:00:00Z", dsun: 214.053547, hgln: -0.000000, hglt: 5.375471 },
  { isoDate: "2008-10-22T00:00:00Z", dsun: 213.995328, hgln: -0.000000, hglt: 5.290172 },
  { isoDate: "2008-10-23T00:00:00Z", dsun: 213.937363, hgln: 0.000000, hglt: 5.203231 },
  { isoDate: "2008-10-24T00:00:00Z", dsun: 213.879606, hgln: 0.000000, hglt: 5.114671 },
  { isoDate: "2008-10-25T00:00:00Z", dsun: 213.822018, hgln: 0.000000, hglt: 5.024514 },
  { isoDate: "2008-10-26T00:00:00Z", dsun: 213.764571, hgln: 0.000000, hglt: 4.932786 },
  { isoDate: "2008-10-27T00:00:00Z", dsun: 213.707247, hgln: -0.000000, hglt: 4.839512 },
  { isoDate: "2008-10-28T00:00:00Z", dsun: 213.650043, hgln: -0.000000, hglt: 4.744718 },
  { isoDate: "2008-10-29T00:00:00Z", dsun: 213.592965, hgln: -0.000000, hglt: 4.648433 },
  { isoDate: "2008-10-30T00:00:00Z", dsun: 213.536029, hgln: -0.000000, hglt: 4.550685 },
  { isoDate: "2008-10-31T00:00:00Z", dsun: 213.479264, hgln: -0.000000, hglt: 4.451504 },
  { isoDate: "2008-11-01T00:00:00Z", dsun: 213.422706, hgln: -0.000000, hglt: 4.350920 },
  { isoDate: "2008-11-02T00:00:00Z", dsun: 213.366397, hgln: -0.000000, hglt: 4.248964 },
  { isoDate: "2008-11-03T00:00:00Z", dsun: 213.310386, hgln: 0.000000, hglt: 4.145669 },
  { isoDate: "2008-11-04T00:00:00Z", dsun: 213.254728, hgln: -0.000000, hglt: 4.041066 },
  { isoDate: "2008-11-05T00:00:00Z", dsun: 213.199482, hgln: -0.000000, hglt: 3.935189 },
  { isoDate: "2008-11-06T00:00:00Z", dsun: 213.144711, hgln: 0.000000, hglt: 3.828071 },
  { isoDate: "2008-11-07T00:00:00Z", dsun: 213.090484, hgln: -0.000000, hglt: 3.719746 },
  { isoDate: "2008-11-08T00:00:00Z", dsun: 213.036872, hgln: -0.000000, hglt: 3.610249 },
  { isoDate: "2008-11-09T00:00:00Z", dsun: 212.983951, hgln: -0.000000, hglt: 3.499613 },
  { isoDate: "2008-11-10T00:00:00Z", dsun: 212.931798, hgln: 0.000000, hglt: 3.387872 },
  { isoDate: "2008-11-11T00:00:00Z", dsun: 212.880488, hgln: -0.000000, hglt: 3.275062 },
  { isoDate: "2008-11-12T00:00:00Z", dsun: 212.830091, hgln: -0.000000, hglt: 3.161216 },
  { isoDate: "2008-11-13T00:00:00Z", dsun: 212.780664, hgln: 0.000000, hglt: 3.046367 },
  { isoDate: "2008-11-14T00:00:00Z", dsun: 212.732243, hgln: 0.000000, hglt: 2.930547 },
  { isoDate: "2008-11-15T00:00:00Z", dsun: 212.684844, hgln: 0.000000, hglt: 2.813789 },
  { isoDate: "2008-11-16T00:00:00Z", dsun: 212.638455, hgln: 0.000000, hglt: 2.696123 },
  { isoDate: "2008-11-17T00:00:00Z", dsun: 212.593044, hgln: 0.000000, hglt: 2.577582 },
  { isoDate: "2008-11-18T00:00:00Z", dsun: 212.548564, hgln: 0.000000, hglt: 2.458198 },
  { isoDate: "2008-11-19T00:00:00Z", dsun: 212.504957, hgln: -0.000000, hglt: 2.338004 },
  { isoDate: "2008-11-20T00:00:00Z", dsun: 212.462167, hgln: -0.000000, hglt: 2.217033 },
  { isoDate: "2008-11-21T00:00:00Z", dsun: 212.420137, hgln: -0.000000, hglt: 2.095321 },
  { isoDate: "2008-11-22T00:00:00Z", dsun: 212.378818, hgln: -0.000000, hglt: 1.972903 },
  { isoDate: "2008-11-23T00:00:00Z", dsun: 212.338169, hgln: -0.000000, hglt: 1.849818 },
  { isoDate: "2008-11-24T00:00:00Z", dsun: 212.298158, hgln: 0.000000, hglt: 1.726103 },
  { isoDate: "2008-11-25T00:00:00Z", dsun: 212.258761, hgln: 0.000000, hglt: 1.601797 },
  { isoDate: "2008-11-26T00:00:00Z", dsun: 212.219963, hgln: 0.000000, hglt: 1.476940 },
  { isoDate: "2008-11-27T00:00:00Z", dsun: 212.181760, hgln: -0.000000, hglt: 1.351573 },
  { isoDate: "2008-11-28T00:00:00Z", dsun: 212.144154, hgln: 0.000000, hglt: 1.225736 },
  { isoDate: "2008-11-29T00:00:00Z", dsun: 212.107159, hgln: -0.000000, hglt: 1.099472 },
  { isoDate: "2008-11-30T00:00:00Z", dsun: 212.070794, hgln: 0.000000, hglt: 0.972822 },
  { isoDate: "2008-12-01T00:00:00Z", dsun: 212.035088, hgln: 0.000000, hglt: 0.845830 },
  { isoDate: "2008-12-02T00:00:00Z", dsun: 212.000075, hgln: 0.000000, hglt: 0.718537 },
  { isoDate: "2008-12-03T00:00:00Z", dsun: 211.965797, hgln: 0.000000, hglt: 0.590987 },
  { isoDate: "2008-12-04T00:00:00Z", dsun: 211.932302, hgln: 0.000000, hglt: 0.463223 },
  { isoDate: "2008-12-05T00:00:00Z", dsun: 211.899644, hgln: -0.000000, hglt: 0.335288 },
  { isoDate: "2008-12-06T00:00:00Z", dsun: 211.867883, hgln: 0.000000, hglt: 0.207224 },
  { isoDate: "2008-12-07T00:00:00Z", dsun: 211.837086, hgln: -0.000000, hglt: 0.079076 },
  { isoDate: "2008-12-08T00:00:00Z", dsun: 211.807325, hgln: -0.000000, hglt: -0.049116 },
  { isoDate: "2008-12-09T00:00:00Z", dsun: 211.778676, hgln: 0.000000, hglt: -0.177308 },
  { isoDate: "2008-12-10T00:00:00Z", dsun: 211.751214, hgln: -0.000000, hglt: -0.305461 },
  { isoDate: "2008-12-11T00:00:00Z", dsun: 211.725011, hgln: 0.000000, hglt: -0.433534 },
  { isoDate: "2008-12-12T00:00:00Z", dsun: 211.700124, hgln: -0.000000, hglt: -0.561489 },
  { isoDate: "2008-12-13T00:00:00Z", dsun: 211.676593, hgln: 0.000000, hglt: -0.689289 },
  { isoDate: "2008-12-14T00:00:00Z", dsun: 211.654432, hgln: -0.000000, hglt: -0.816896 },
  { isoDate: "2008-12-15T00:00:00Z", dsun: 211.633628, hgln: -0.000000, hglt: -0.944276 },
  { isoDate: "2008-12-16T00:00:00Z", dsun: 211.614146, hgln: 0.000000, hglt: -1.071393 },
  { isoDate: "2008-12-17T00:00:00Z", dsun: 211.595935, hgln: -0.000000, hglt: -1.198209 },
  { isoDate: "2008-12-18T00:00:00Z", dsun: 211.578932, hgln: 0.000000, hglt: -1.324687 },
  { isoDate: "2008-12-19T00:00:00Z", dsun: 211.563075, hgln: -0.000000, hglt: -1.450790 },
  { isoDate: "2008-12-20T00:00:00Z", dsun: 211.548303, hgln: -0.000000, hglt: -1.576478 },
  { isoDate: "2008-12-21T00:00:00Z", dsun: 211.534559, hgln: -0.000000, hglt: -1.701710 },
  { isoDate: "2008-12-22T00:00:00Z", dsun: 211.521795, hgln: -0.000000, hglt: -1.826446 },
  { isoDate: "2008-12-23T00:00:00Z", dsun: 211.509968, hgln: 0.000000, hglt: -1.950646 },
  { isoDate: "2008-12-24T00:00:00Z", dsun: 211.499042, hgln: 0.000000, hglt: -2.074267 },
  { isoDate: "2008-12-25T00:00:00Z", dsun: 211.488989, hgln: -0.000000, hglt: -2.197267 },
  { isoDate: "2008-12-26T00:00:00Z", dsun: 211.479788, hgln: 0.000000, hglt: -2.319605 },
  { isoDate: "2008-12-27T00:00:00Z", dsun: 211.471424, hgln: -0.000000, hglt: -2.441237 },
  { isoDate: "2008-12-28T00:00:00Z", dsun: 211.463891, hgln: -0.000000, hglt: -2.562123 },
  { isoDate: "2008-12-29T00:00:00Z", dsun: 211.457190, hgln: 0.000000, hglt: -2.682219 },
  { isoDate: "2008-12-30T00:00:00Z", dsun: 211.451331, hgln: 0.000000, hglt: -2.801484 },
  { isoDate: "2008-12-31T00:00:00Z", dsun: 211.446331, hgln: -0.000000, hglt: -2.919874 },
  { isoDate: "2009-01-01T00:00:00Z", dsun: 211.442216, hgln: 0.000000, hglt: -3.037351 },
  { isoDate: "2009-01-02T00:00:00Z", dsun: 211.439020, hgln: -0.000000, hglt: -3.153871 },
  { isoDate: "2009-01-03T00:00:00Z", dsun: 211.436784, hgln: 0.000000, hglt: -3.269393 },
  { isoDate: "2009-01-04T00:00:00Z", dsun: 211.435558, hgln: 0.000000, hglt: -3.383878 },
  { isoDate: "2009-01-05T00:00:00Z", dsun: 211.435400, hgln: 0.000000, hglt: -3.497287 },
  { isoDate: "2009-01-06T00:00:00Z", dsun: 211.436374, hgln: 0.000000, hglt: -3.609581 },
  { isoDate: "2009-01-07T00:00:00Z", dsun: 211.438550, hgln: -0.000000, hglt: -3.720725 },
  { isoDate: "2009-01-08T00:00:00Z", dsun: 211.441998, hgln: -0.000000, hglt: -3.830682 },
  { isoDate: "2009-01-09T00:00:00Z", dsun: 211.446784, hgln: -0.000000, hglt: -3.939418 },
  { isoDate: "2009-01-10T00:00:00Z", dsun: 211.452962, hgln: 0.000000, hglt: -4.046902 },
  { isoDate: "2009-01-11T00:00:00Z", dsun: 211.460569, hgln: -0.000000, hglt: -4.153103 },
  { isoDate: "2009-01-12T00:00:00Z", dsun: 211.469615, hgln: -0.000000, hglt: -4.257991 },
  { isoDate: "2009-01-13T00:00:00Z", dsun: 211.480086, hgln: -0.000000, hglt: -4.361537 },
  { isoDate: "2009-01-14T00:00:00Z", dsun: 211.491945, hgln: -0.000000, hglt: -4.463711 },
  { isoDate: "2009-01-15T00:00:00Z", dsun: 211.505136, hgln: 0.000000, hglt: -4.564485 },
  { isoDate: "2009-01-16T00:00:00Z", dsun: 211.519596, hgln: 0.000000, hglt: -4.663829 },
  { isoDate: "2009-01-17T00:00:00Z", dsun: 211.535255, hgln: 0.000000, hglt: -4.761711 },
  { isoDate: "2009-01-18T00:00:00Z", dsun: 211.552047, hgln: 0.000000, hglt: -4.858102 },
  { isoDate: "2009-01-19T00:00:00Z", dsun: 211.569907, hgln: 0.000000, hglt: -4.952970 },
  { isoDate: "2009-01-20T00:00:00Z", dsun: 211.588775, hgln: 0.000000, hglt: -5.046284 },
  { isoDate: "2009-01-21T00:00:00Z", dsun: 211.608597, hgln: 0.000000, hglt: -5.138012 },
  { isoDate: "2009-01-22T00:00:00Z", dsun: 211.629323, hgln: 0.000000, hglt: -5.228124 },
  { isoDate: "2009-01-23T00:00:00Z", dsun: 211.650909, hgln: -0.000000, hglt: -5.316589 },
  { isoDate: "2009-01-24T00:00:00Z", dsun: 211.673316, hgln: -0.000000, hglt: -5.403376 },
  { isoDate: "2009-01-25T00:00:00Z", dsun: 211.696510, hgln: -0.000000, hglt: -5.488455 },
  { isoDate: "2009-01-26T00:00:00Z", dsun: 211.720467, hgln: -0.000000, hglt: -5.571797 },
  { isoDate: "2009-01-27T00:00:00Z", dsun: 211.745167, hgln: 0.000000, hglt: -5.653371 },
  { isoDate: "2009-01-28T00:00:00Z", dsun: 211.770600, hgln: -0.000000, hglt: -5.733150 },
  { isoDate: "2009-01-29T00:00:00Z", dsun: 211.796765, hgln: -0.000000, hglt: -5.811106 },
  { isoDate: "2009-01-30T00:00:00Z", dsun: 211.823673, hgln: 0.000000, hglt: -5.887211 },
  { isoDate: "2009-01-31T00:00:00Z", dsun: 211.851343, hgln: 0.000000, hglt: -5.961439 },
  { isoDate: "2009-02-01T00:00:00Z", dsun: 211.879806, hgln: 0.000000, hglt: -6.033766 },
  { isoDate: "2009-02-02T00:00:00Z", dsun: 211.909100, hgln: 0.000000, hglt: -6.104166 },
  { isoDate: "2009-02-03T00:00:00Z", dsun: 211.939276, hgln: 0.000000, hglt: -6.172619 },
  { isoDate: "2009-02-04T00:00:00Z", dsun: 211.970391, hgln: 0.000000, hglt: -6.239102 },
  { isoDate: "2009-02-05T00:00:00Z", dsun: 212.002507, hgln: -0.000000, hglt: -6.303597 },
  { isoDate: "2009-02-06T00:00:00Z", dsun: 212.035685, hgln: -0.000000, hglt: -6.366085 },
  { isoDate: "2009-02-07T00:00:00Z", dsun: 212.069985, hgln: 0.000000, hglt: -6.426551 },
  { isoDate: "2009-02-08T00:00:00Z", dsun: 212.105453, hgln: 0.000000, hglt: -6.484979 },
  { isoDate: "2009-02-09T00:00:00Z", dsun: 212.142120, hgln: 0.000000, hglt: -6.541356 },
  { isoDate: "2009-02-10T00:00:00Z", dsun: 212.179993, hgln: -0.000000, hglt: -6.595670 },
  { isoDate: "2009-02-11T00:00:00Z", dsun: 212.219057, hgln: 0.000000, hglt: -6.647908 },
  { isoDate: "2009-02-12T00:00:00Z", dsun: 212.259274, hgln: -0.000000, hglt: -6.698058 },
  { isoDate: "2009-02-13T00:00:00Z", dsun: 212.300592, hgln: 0.000000, hglt: -6.746108 },
  { isoDate: "2009-02-14T00:00:00Z", dsun: 212.342947, hgln: 0.000000, hglt: -6.792047 },
  { isoDate: "2009-02-15T00:00:00Z", dsun: 212.386268, hgln: 0.000000, hglt: -6.835862 },
  { isoDate: "2009-02-16T00:00:00Z", dsun: 212.430487, hgln: -0.000000, hglt: -6.877541 },
  { isoDate: "2009-02-17T00:00:00Z", dsun: 212.475532, hgln: -0.000000, hglt: -6.917071 },
  { isoDate: "2009-02-18T00:00:00Z", dsun: 212.521336, hgln: -0.000000, hglt: -6.954443 },
  { isoDate: "2009-02-19T00:00:00Z", dsun: 212.567835, hgln: -0.000000, hglt: -6.989644 },
  { isoDate: "2009-02-20T00:00:00Z", dsun: 212.614968, hgln: 0.000000, hglt: -7.022664 },
  { isoDate: "2009-02-21T00:00:00Z", dsun: 212.662676, hgln: -0.000000, hglt: -7.053493 },
  { isoDate: "2009-02-22T00:00:00Z", dsun: 212.710905, hgln: -0.000000, hglt: -7.082122 },
  { isoDate: "2009-02-23T00:00:00Z", dsun: 212.759606, hgln: 0.000000, hglt: -7.108542 },
  { isoDate: "2009-02-24T00:00:00Z", dsun: 212.808735, hgln: 0.000000, hglt: -7.132745 },
  { isoDate: "2009-02-25T00:00:00Z", dsun: 212.858254, hgln: 0.000000, hglt: -7.154723 },
  { isoDate: "2009-02-26T00:00:00Z", dsun: 212.908137, hgln: 0.000000, hglt: -7.174472 },
  { isoDate: "2009-02-27T00:00:00Z", dsun: 212.958368, hgln: -0.000000, hglt: -7.191985 },
  { isoDate: "2009-02-28T00:00:00Z", dsun: 213.008942, hgln: -0.000000, hglt: -7.207258 },
  { isoDate: "2009-03-01T00:00:00Z", dsun: 213.059870, hgln: -0.000000, hglt: -7.220289 },
  { isoDate: "2009-03-02T00:00:00Z", dsun: 213.111173, hgln: -0.000000, hglt: -7.231076 },
  { isoDate: "2009-03-03T00:00:00Z", dsun: 213.162887, hgln: -0.000000, hglt: -7.239618 },
  { isoDate: "2009-03-04T00:00:00Z", dsun: 213.215057, hgln: 0.000000, hglt: -7.245917 },
  { isoDate: "2009-03-05T00:00:00Z", dsun: 213.267735, hgln: -0.000000, hglt: -7.249975 },
  { isoDate: "2009-03-06T00:00:00Z", dsun: 213.320978, hgln: -0.000000, hglt: -7.251797 },
  { isoDate: "2009-03-07T00:00:00Z", dsun: 213.374843, hgln: 0.000000, hglt: -7.251388 },
  { isoDate: "2009-03-08T00:00:00Z", dsun: 213.429381, hgln: -0.000000, hglt: -7.248753 },
  { isoDate: "2009-03-09T00:00:00Z", dsun: 213.484632, hgln: 0.000000, hglt: -7.243900 },
  { isoDate: "2009-03-10T00:00:00Z", dsun: 213.540621, hgln: 0.000000, hglt: -7.236837 },
  { isoDate: "2009-03-11T00:00:00Z", dsun: 213.597354, hgln: 0.000000, hglt: -7.227572 },
  { isoDate: "2009-03-12T00:00:00Z", dsun: 213.654817, hgln: -0.000000, hglt: -7.216112 },
  { isoDate: "2009-03-13T00:00:00Z", dsun: 213.712978, hgln: -0.000000, hglt: -7.202467 },
  { isoDate: "2009-03-14T00:00:00Z", dsun: 213.771790, hgln: 0.000000, hglt: -7.186643 },
  { isoDate: "2009-03-15T00:00:00Z", dsun: 213.831195, hgln: 0.000000, hglt: -7.168651 },
  { isoDate: "2009-03-16T00:00:00Z", dsun: 213.891130, hgln: -0.000000, hglt: -7.148498 },
  { isoDate: "2009-03-17T00:00:00Z", dsun: 213.951526, hgln: 0.000000, hglt: -7.126194 },
  { isoDate: "2009-03-18T00:00:00Z", dsun: 214.012313, hgln: -0.000000, hglt: -7.101748 },
  { isoDate: "2009-03-19T00:00:00Z", dsun: 214.073423, hgln: 0.000000, hglt: -7.075169 },
  { isoDate: "2009-03-20T00:00:00Z", dsun: 214.134788, hgln: -0.000000, hglt: -7.046468 },
  { isoDate: "2009-03-21T00:00:00Z", dsun: 214.196338, hgln: 0.000000, hglt: -7.015656 },
  { isoDate: "2009-03-22T00:00:00Z", dsun: 214.258008, hgln: 0.000000, hglt: -6.982744 },
  { isoDate: "2009-03-23T00:00:00Z", dsun: 214.319734, hgln: 0.000000, hglt: -6.947744 },
  { isoDate: "2009-03-24T00:00:00Z", dsun: 214.381452, hgln: 0.000000, hglt: -6.910670 },
  { isoDate: "2009-03-25T00:00:00Z", dsun: 214.443106, hgln: -0.000000, hglt: -6.871535 },
  { isoDate: "2009-03-26T00:00:00Z", dsun: 214.504646, hgln: -0.000000, hglt: -6.830354 },
  { isoDate: "2009-03-27T00:00:00Z", dsun: 214.566030, hgln: -0.000000, hglt: -6.787141 },
  { isoDate: "2009-03-28T00:00:00Z", dsun: 214.627231, hgln: 0.000000, hglt: -6.741913 },
  { isoDate: "2009-03-29T00:00:00Z", dsun: 214.688238, hgln: 0.000000, hglt: -6.694689 },
  { isoDate: "2009-03-30T00:00:00Z", dsun: 214.749053, hgln: 0.000000, hglt: -6.645486 },
  { isoDate: "2009-03-31T00:00:00Z", dsun: 214.809698, hgln: 0.000000, hglt: -6.594326 },
  { isoDate: "2009-04-01T00:00:00Z", dsun: 214.870207, hgln: 0.000000, hglt: -6.541228 },
  { isoDate: "2009-04-02T00:00:00Z", dsun: 214.930628, hgln: 0.000000, hglt: -6.486215 },
  { isoDate: "2009-04-03T00:00:00Z", dsun: 214.991013, hgln: -0.000000, hglt: -6.429309 },
  { isoDate: "2009-04-04T00:00:00Z", dsun: 215.051419, hgln: 0.000000, hglt: -6.370535 },
  { isoDate: "2009-04-05T00:00:00Z", dsun: 215.111901, hgln: 0.000000, hglt: -6.309915 },
  { isoDate: "2009-04-06T00:00:00Z", dsun: 215.172506, hgln: -0.000000, hglt: -6.247473 },
  { isoDate: "2009-04-07T00:00:00Z", dsun: 215.233270, hgln: -0.000000, hglt: -6.183233 },
  { isoDate: "2009-04-08T00:00:00Z", dsun: 215.294217, hgln: 0.000000, hglt: -6.117218 },
  { isoDate: "2009-04-09T00:00:00Z", dsun: 215.355355, hgln: 0.000000, hglt: -6.049453 },
  { isoDate: "2009-04-10T00:00:00Z", dsun: 215.416675, hgln: 0.000000, hglt: -5.979960 },
  { isoDate: "2009-04-11T00:00:00Z", dsun: 215.478154, hgln: 0.000000, hglt: -5.908762 },
  { isoDate: "2009-04-12T00:00:00Z", dsun: 215.539756, hgln: 0.000000, hglt: -5.835883 },
  { isoDate: "2009-04-13T00:00:00Z", dsun: 215.601435, hgln: -0.000000, hglt: -5.761345 },
  { isoDate: "2009-04-14T00:00:00Z", dsun: 215.663137, hgln: -0.000000, hglt: -5.685171 },
  { isoDate: "2009-04-15T00:00:00Z", dsun: 215.724804, hgln: -0.000000, hglt: -5.607386 },
  { isoDate: "2009-04-16T00:00:00Z", dsun: 215.786372, hgln: 0.000000, hglt: -5.528013 },
  { isoDate: "2009-04-17T00:00:00Z", dsun: 215.847778, hgln: 0.000000, hglt: -5.447075 },
  { isoDate: "2009-04-18T00:00:00Z", dsun: 215.908953, hgln: 0.000000, hglt: -5.364599 },
  { isoDate: "2009-04-19T00:00:00Z", dsun: 215.969832, hgln: 0.000000, hglt: -5.280607 },
  { isoDate: "2009-04-20T00:00:00Z", dsun: 216.030345, hgln: -0.000000, hglt: -5.195126 },
  { isoDate: "2009-04-21T00:00:00Z", dsun: 216.090422, hgln: 0.000000, hglt: -5.108182 },
  { isoDate: "2009-04-22T00:00:00Z", dsun: 216.149996, hgln: 0.000000, hglt: -5.019801 },
  { isoDate: "2009-04-23T00:00:00Z", dsun: 216.209000, hgln: 0.000000, hglt: -4.930011 },
  { isoDate: "2009-04-24T00:00:00Z", dsun: 216.267374, hgln: 0.000000, hglt: -4.838839 },
  { isoDate: "2009-04-25T00:00:00Z", dsun: 216.325070, hgln: 0.000000, hglt: -4.746314 },
  { isoDate: "2009-04-26T00:00:00Z", dsun: 216.382052, hgln: -0.000000, hglt: -4.652467 },
  { isoDate: "2009-04-27T00:00:00Z", dsun: 216.438304, hgln: -0.000000, hglt: -4.557330 },
  { isoDate: "2009-04-28T00:00:00Z", dsun: 216.493831, hgln: 0.000000, hglt: -4.460933 },
  { isoDate: "2009-04-29T00:00:00Z", dsun: 216.548654, hgln: 0.000000, hglt: -4.363311 },
  { isoDate: "2009-04-30T00:00:00Z", dsun: 216.602815, hgln: 0.000000, hglt: -4.264496 },
  { isoDate: "2009-05-01T00:00:00Z", dsun: 216.656366, hgln: 0.000000, hglt: -4.164521 },
  { isoDate: "2009-05-02T00:00:00Z", dsun: 216.709365, hgln: 0.000000, hglt: -4.063421 },
  { isoDate: "2009-05-03T00:00:00Z", dsun: 216.761871, hgln: 0.000000, hglt: -3.961228 },
  { isoDate: "2009-05-04T00:00:00Z", dsun: 216.813939, hgln: 0.000000, hglt: -3.857974 },
  { isoDate: "2009-05-05T00:00:00Z", dsun: 216.865617, hgln: -0.000000, hglt: -3.753691 },
  { isoDate: "2009-05-06T00:00:00Z", dsun: 216.916943, hgln: -0.000000, hglt: -3.648410 },
  { isoDate: "2009-05-07T00:00:00Z", dsun: 216.967942, hgln: -0.000000, hglt: -3.542163 },
  { isoDate: "2009-05-08T00:00:00Z", dsun: 217.018629, hgln: 0.000000, hglt: -3.434979 },
  { isoDate: "2009-05-09T00:00:00Z", dsun: 217.069004, hgln: 0.000000, hglt: -3.326887 },
  { isoDate: "2009-05-10T00:00:00Z", dsun: 217.119054, hgln: 0.000000, hglt: -3.217917 },
  { isoDate: "2009-05-11T00:00:00Z", dsun: 217.168758, hgln: 0.000000, hglt: -3.108099 },
  { isoDate: "2009-05-12T00:00:00Z", dsun: 217.218084, hgln: 0.000000, hglt: -2.997460 },
  { isoDate: "2009-05-13T00:00:00Z", dsun: 217.266991, hgln: 0.000000, hglt: -2.886031 },
  { isoDate: "2009-05-14T00:00:00Z", dsun: 217.315434, hgln: 0.000000, hglt: -2.773841 },
  { isoDate: "2009-05-15T00:00:00Z", dsun: 217.363360, hgln: 0.000000, hglt: -2.660918 },
  { isoDate: "2009-05-16T00:00:00Z", dsun: 217.410715, hgln: 0.000000, hglt: -2.547293 },
  { isoDate: "2009-05-17T00:00:00Z", dsun: 217.457439, hgln: -0.000000, hglt: -2.432994 },
  { isoDate: "2009-05-18T00:00:00Z", dsun: 217.503469, hgln: -0.000000, hglt: -2.318053 },
  { isoDate: "2009-05-19T00:00:00Z", dsun: 217.548738, hgln: 0.000000, hglt: -2.202500 },
  { isoDate: "2009-05-20T00:00:00Z", dsun: 217.593178, hgln: -0.000000, hglt: -2.086365 },
  { isoDate: "2009-05-21T00:00:00Z", dsun: 217.636717, hgln: 0.000000, hglt: -1.969680 },
  { isoDate: "2009-05-22T00:00:00Z", dsun: 217.679285, hgln: 0.000000, hglt: -1.852477 },
  { isoDate: "2009-05-23T00:00:00Z", dsun: 217.720819, hgln: -0.000000, hglt: -1.734790 },
  { isoDate: "2009-05-24T00:00:00Z", dsun: 217.761264, hgln: -0.000000, hglt: -1.616652 },
  { isoDate: "2009-05-25T00:00:00Z", dsun: 217.800582, hgln: -0.000000, hglt: -1.498100 },
  { isoDate: "2009-05-26T00:00:00Z", dsun: 217.838756, hgln: -0.000000, hglt: -1.379169 },
  { isoDate: "2009-05-27T00:00:00Z", dsun: 217.875793, hgln: 0.000000, hglt: -1.259896 },
  { isoDate: "2009-05-28T00:00:00Z", dsun: 217.911723, hgln: 0.000000, hglt: -1.140319 },
  { isoDate: "2009-05-29T00:00:00Z", dsun: 217.946592, hgln: -0.000000, hglt: -1.020475 },
  { isoDate: "2009-05-30T00:00:00Z", dsun: 217.980460, hgln: -0.000000, hglt: -0.900399 },
  { isoDate: "2009-05-31T00:00:00Z", dsun: 218.013393, hgln: 0.000000, hglt: -0.780127 },
  { isoDate: "2009-06-01T00:00:00Z", dsun: 218.045452, hgln: -0.000000, hglt: -0.659693 },
  { isoDate: "2009-06-02T00:00:00Z", dsun: 218.076699, hgln: -0.000000, hglt: -0.539131 },
  { isoDate: "2009-06-03T00:00:00Z", dsun: 218.107183, hgln: -0.000000, hglt: -0.418473 },
  { isoDate: "2009-06-04T00:00:00Z", dsun: 218.136947, hgln: -0.000000, hglt: -0.297750 },
  { isoDate: "2009-06-05T00:00:00Z", dsun: 218.166024, hgln: 0.000000, hglt: -0.176994 },
  { isoDate: "2009-06-06T00:00:00Z", dsun: 218.194436, hgln: -0.000000, hglt: -0.056234 },
  { isoDate: "2009-06-07T00:00:00Z", dsun: 218.222194, hgln: -0.000000, hglt: 0.064500 },
  { isoDate: "2009-06-08T00:00:00Z", dsun: 218.249301, hgln: 0.000000, hglt: 0.185177 },
  { isoDate: "2009-06-09T00:00:00Z", dsun: 218.275749, hgln: 0.000000, hglt: 0.305770 },
  { isoDate: "2009-06-10T00:00:00Z", dsun: 218.301521, hgln: 0.000000, hglt: 0.426249 },
  { isoDate: "2009-06-11T00:00:00Z", dsun: 218.326592, hgln: -0.000000, hglt: 0.546586 },
  { isoDate: "2009-06-12T00:00:00Z", dsun: 218.350932, hgln: -0.000000, hglt: 0.666751 },
  { isoDate: "2009-06-13T00:00:00Z", dsun: 218.374502, hgln: -0.000000, hglt: 0.786716 },
  { isoDate: "2009-06-14T00:00:00Z", dsun: 218.397258, hgln: -0.000000, hglt: 0.906452 },
  { isoDate: "2009-06-15T00:00:00Z", dsun: 218.419150, hgln: -0.000000, hglt: 1.025929 },
  { isoDate: "2009-06-16T00:00:00Z", dsun: 218.440123, hgln: 0.000000, hglt: 1.145118 },
  { isoDate: "2009-06-17T00:00:00Z", dsun: 218.460115, hgln: 0.000000, hglt: 1.263990 },
  { isoDate: "2009-06-18T00:00:00Z", dsun: 218.479060, hgln: -0.000000, hglt: 1.382513 },
  { isoDate: "2009-06-19T00:00:00Z", dsun: 218.496890, hgln: 0.000000, hglt: 1.500658 },
  { isoDate: "2009-06-20T00:00:00Z", dsun: 218.513534, hgln: 0.000000, hglt: 1.618391 },
  { isoDate: "2009-06-21T00:00:00Z", dsun: 218.528926, hgln: 0.000000, hglt: 1.735682 },
  { isoDate: "2009-06-22T00:00:00Z", dsun: 218.543012, hgln: -0.000000, hglt: 1.852495 },
  { isoDate: "2009-06-23T00:00:00Z", dsun: 218.555754, hgln: -0.000000, hglt: 1.968796 },
  { isoDate: "2009-06-24T00:00:00Z", dsun: 218.567137, hgln: -0.000000, hglt: 2.084549 },
  { isoDate: "2009-06-25T00:00:00Z", dsun: 218.577174, hgln: 0.000000, hglt: 2.199720 },
  { isoDate: "2009-06-26T00:00:00Z", dsun: 218.585899, hgln: -0.000000, hglt: 2.314271 },
  { isoDate: "2009-06-27T00:00:00Z", dsun: 218.593368, hgln: 0.000000, hglt: 2.428170 },
  { isoDate: "2009-06-28T00:00:00Z", dsun: 218.599646, hgln: -0.000000, hglt: 2.541383 },
  { isoDate: "2009-06-29T00:00:00Z", dsun: 218.604802, hgln: 0.000000, hglt: 2.653877 },
  { isoDate: "2009-06-30T00:00:00Z", dsun: 218.608907, hgln: -0.000000, hglt: 2.765623 },
  { isoDate: "2009-07-01T00:00:00Z", dsun: 218.612024, hgln: 0.000000, hglt: 2.876590 },
  { isoDate: "2009-07-02T00:00:00Z", dsun: 218.614210, hgln: -0.000000, hglt: 2.986750 },
  { isoDate: "2009-07-03T00:00:00Z", dsun: 218.615515, hgln: -0.000000, hglt: 3.096074 },
  { isoDate: "2009-07-04T00:00:00Z", dsun: 218.615980, hgln: -0.000000, hglt: 3.204537 },
  { isoDate: "2009-07-05T00:00:00Z", dsun: 218.615637, hgln: 0.000000, hglt: 3.312112 },
  { isoDate: "2009-07-06T00:00:00Z", dsun: 218.614511, hgln: 0.000000, hglt: 3.418771 },
  { isoDate: "2009-07-07T00:00:00Z", dsun: 218.612619, hgln: 0.000000, hglt: 3.524491 },
  { isoDate: "2009-07-08T00:00:00Z", dsun: 218.609968, hgln: 0.000000, hglt: 3.629245 },
  { isoDate: "2009-07-09T00:00:00Z", dsun: 218.606557, hgln: 0.000000, hglt: 3.733008 },
  { isoDate: "2009-07-10T00:00:00Z", dsun: 218.602377, hgln: 0.000000, hglt: 3.835756 },
  { isoDate: "2009-07-11T00:00:00Z", dsun: 218.597413, hgln: 0.000000, hglt: 3.937464 },
  { isoDate: "2009-07-12T00:00:00Z", dsun: 218.591640, hgln: 0.000000, hglt: 4.038107 },
  { isoDate: "2009-07-13T00:00:00Z", dsun: 218.585024, hgln: 0.000000, hglt: 4.137660 },
  { isoDate: "2009-07-14T00:00:00Z", dsun: 218.577527, hgln: -0.000000, hglt: 4.236098 },
  { isoDate: "2009-07-15T00:00:00Z", dsun: 218.569101, hgln: -0.000000, hglt: 4.333396 },
  { isoDate: "2009-07-16T00:00:00Z", dsun: 218.559691, hgln: -0.000000, hglt: 4.429528 },
  { isoDate: "2009-07-17T00:00:00Z", dsun: 218.549237, hgln: 0.000000, hglt: 4.524469 },
  { isoDate: "2009-07-18T00:00:00Z", dsun: 218.537672, hgln: 0.000000, hglt: 4.618192 },
  { isoDate: "2009-07-19T00:00:00Z", dsun: 218.524929, hgln: 0.000000, hglt: 4.710670 },
  { isoDate: "2009-07-20T00:00:00Z", dsun: 218.510945, hgln: -0.000000, hglt: 4.801875 },
  { isoDate: "2009-07-21T00:00:00Z", dsun: 218.495667, hgln: 0.000000, hglt: 4.891777 },
  { isoDate: "2009-07-22T00:00:00Z", dsun: 218.479061, hgln: -0.000000, hglt: 4.980348 },
  { isoDate: "2009-07-23T00:00:00Z", dsun: 218.461117, hgln: 0.000000, hglt: 5.067558 },
  { isoDate: "2009-07-24T00:00:00Z", dsun: 218.441851, hgln: 0.000000, hglt: 5.153378 },
  { isoDate: "2009-07-25T00:00:00Z", dsun: 218.421303, hgln: 0.000000, hglt: 5.237779 },
  { isoDate: "2009-07-26T00:00:00Z", dsun: 218.399532, hgln: 0.000000, hglt: 5.320735 },
  { isoDate: "2009-07-27T00:00:00Z", dsun: 218.376607, hgln: -0.000000, hglt: 5.402220 },
  { isoDate: "2009-07-28T00:00:00Z", dsun: 218.352601, hgln: 0.000000, hglt: 5.482210 },
  { isoDate: "2009-07-29T00:00:00Z", dsun: 218.327588, hgln: 0.000000, hglt: 5.560681 },
  { isoDate: "2009-07-30T00:00:00Z", dsun: 218.301636, hgln: -0.000000, hglt: 5.637613 },
  { isoDate: "2009-07-31T00:00:00Z", dsun: 218.274809, hgln: 0.000000, hglt: 5.712983 },
  { isoDate: "2009-08-01T00:00:00Z", dsun: 218.247164, hgln: -0.000000, hglt: 5.786773 },
  { isoDate: "2009-08-02T00:00:00Z", dsun: 218.218752, hgln: 0.000000, hglt: 5.858961 },
  { isoDate: "2009-08-03T00:00:00Z", dsun: 218.189616, hgln: 0.000000, hglt: 5.929530 },
  { isoDate: "2009-08-04T00:00:00Z", dsun: 218.159795, hgln: 0.000000, hglt: 5.998460 },
  { isoDate: "2009-08-05T00:00:00Z", dsun: 218.129320, hgln: 0.000000, hglt: 6.065735 },
  { isoDate: "2009-08-06T00:00:00Z", dsun: 218.098213, hgln: 0.000000, hglt: 6.131336 },
  { isoDate: "2009-08-07T00:00:00Z", dsun: 218.066489, hgln: 0.000000, hglt: 6.195246 },
  { isoDate: "2009-08-08T00:00:00Z", dsun: 218.034155, hgln: -0.000000, hglt: 6.257448 },
  { isoDate: "2009-08-09T00:00:00Z", dsun: 218.001209, hgln: 0.000000, hglt: 6.317926 },
  { isoDate: "2009-08-10T00:00:00Z", dsun: 217.967638, hgln: 0.000000, hglt: 6.376663 },
  { isoDate: "2009-08-11T00:00:00Z", dsun: 217.933420, hgln: -0.000000, hglt: 6.433641 },
  { isoDate: "2009-08-12T00:00:00Z", dsun: 217.898526, hgln: -0.000000, hglt: 6.488846 },
  { isoDate: "2009-08-13T00:00:00Z", dsun: 217.862915, hgln: 0.000000, hglt: 6.542259 },
  { isoDate: "2009-08-14T00:00:00Z", dsun: 217.826538, hgln: 0.000000, hglt: 6.593864 },
  { isoDate: "2009-08-15T00:00:00Z", dsun: 217.789339, hgln: -0.000000, hglt: 6.643643 },
  { isoDate: "2009-08-16T00:00:00Z", dsun: 217.751256, hgln: 0.000000, hglt: 6.691580 },
  { isoDate: "2009-08-17T00:00:00Z", dsun: 217.712226, hgln: 0.000000, hglt: 6.737656 },
  { isoDate: "2009-08-18T00:00:00Z", dsun: 217.672191, hgln: 0.000000, hglt: 6.781853 },
  { isoDate: "2009-08-19T00:00:00Z", dsun: 217.631104, hgln: -0.000000, hglt: 6.824153 },
  { isoDate: "2009-08-20T00:00:00Z", dsun: 217.588935, hgln: -0.000000, hglt: 6.864538 },
  { isoDate: "2009-08-21T00:00:00Z", dsun: 217.545679, hgln: -0.000000, hglt: 6.902990 },
  { isoDate: "2009-08-22T00:00:00Z", dsun: 217.501354, hgln: -0.000000, hglt: 6.939494 },
  { isoDate: "2009-08-23T00:00:00Z", dsun: 217.456001, hgln: -0.000000, hglt: 6.974033 },
  { isoDate: "2009-08-24T00:00:00Z", dsun: 217.409679, hgln: -0.000000, hglt: 7.006595 },
  { isoDate: "2009-08-25T00:00:00Z", dsun: 217.362457, hgln: 0.000000, hglt: 7.037167 },
  { isoDate: "2009-08-26T00:00:00Z", dsun: 217.314407, hgln: 0.000000, hglt: 7.065737 },
  { isoDate: "2009-08-27T00:00:00Z", dsun: 217.265606, hgln: -0.000000, hglt: 7.092297 },
  { isoDate: "2009-08-28T00:00:00Z", dsun: 217.216123, hgln: 0.000000, hglt: 7.116836 },
  { isoDate: "2009-08-29T00:00:00Z", dsun: 217.166029, hgln: -0.000000, hglt: 7.139346 },
  { isoDate: "2009-08-30T00:00:00Z", dsun: 217.115388, hgln: -0.000000, hglt: 7.159821 },
  { isoDate: "2009-08-31T00:00:00Z", dsun: 217.064258, hgln: 0.000000, hglt: 7.178253 },
  { isoDate: "2009-09-01T00:00:00Z", dsun: 217.012695, hgln: -0.000000, hglt: 7.194636 },
  { isoDate: "2009-09-02T00:00:00Z", dsun: 216.960750, hgln: 0.000000, hglt: 7.208964 },
  { isoDate: "2009-09-03T00:00:00Z", dsun: 216.908466, hgln: -0.000000, hglt: 7.221233 },
  { isoDate: "2009-09-04T00:00:00Z", dsun: 216.855881, hgln: -0.000000, hglt: 7.231438 },
  { isoDate: "2009-09-05T00:00:00Z", dsun: 216.803026, hgln: 0.000000, hglt: 7.239574 },
  { isoDate: "2009-09-06T00:00:00Z", dsun: 216.749920, hgln: -0.000000, hglt: 7.245638 },
  { isoDate: "2009-09-07T00:00:00Z", dsun: 216.696574, hgln: 0.000000, hglt: 7.249625 },
  { isoDate: "2009-09-08T00:00:00Z", dsun: 216.642987, hgln: -0.000000, hglt: 7.251533 },
  { isoDate: "2009-09-09T00:00:00Z", dsun: 216.589146, hgln: 0.000000, hglt: 7.251358 },
  { isoDate: "2009-09-10T00:00:00Z", dsun: 216.535028, hgln: 0.000000, hglt: 7.249095 },
  { isoDate: "2009-09-11T00:00:00Z", dsun: 216.480596, hgln: 0.000000, hglt: 7.244743 },
  { isoDate: "2009-09-12T00:00:00Z", dsun: 216.425805, hgln: 0.000000, hglt: 7.238298 },
  { isoDate: "2009-09-13T00:00:00Z", dsun: 216.370601, hgln: 0.000000, hglt: 7.229756 },
  { isoDate: "2009-09-14T00:00:00Z", dsun: 216.314928, hgln: -0.000000, hglt: 7.219115 },
  { isoDate: "2009-09-15T00:00:00Z", dsun: 216.258727, hgln: -0.000000, hglt: 7.206372 },
  { isoDate: "2009-09-16T00:00:00Z", dsun: 216.201946, hgln: 0.000000, hglt: 7.191524 },
  { isoDate: "2009-09-17T00:00:00Z", dsun: 216.144543, hgln: 0.000000, hglt: 7.174570 },
  { isoDate: "2009-09-18T00:00:00Z", dsun: 216.086494, hgln: -0.000000, hglt: 7.155510 },
  { isoDate: "2009-09-19T00:00:00Z", dsun: 216.027795, hgln: 0.000000, hglt: 7.134344 },
  { isoDate: "2009-09-20T00:00:00Z", dsun: 215.968465, hgln: -0.000000, hglt: 7.111075 },
  { isoDate: "2009-09-21T00:00:00Z", dsun: 215.908541, hgln: -0.000000, hglt: 7.085706 },
  { isoDate: "2009-09-22T00:00:00Z", dsun: 215.848076, hgln: -0.000000, hglt: 7.058242 },
  { isoDate: "2009-09-23T00:00:00Z", dsun: 215.787135, hgln: -0.000000, hglt: 7.028689 },
  { isoDate: "2009-09-24T00:00:00Z", dsun: 215.725786, hgln: -0.000000, hglt: 6.997053 },
  { isoDate: "2009-09-25T00:00:00Z", dsun: 215.664102, hgln: 0.000000, hglt: 6.963343 },
  { isoDate: "2009-09-26T00:00:00Z", dsun: 215.602155, hgln: -0.000000, hglt: 6.927568 },
  { isoDate: "2009-09-27T00:00:00Z", dsun: 215.540014, hgln: 0.000000, hglt: 6.889736 },
  { isoDate: "2009-09-28T00:00:00Z", dsun: 215.477748, hgln: 0.000000, hglt: 6.849858 },
  { isoDate: "2009-09-29T00:00:00Z", dsun: 215.415424, hgln: -0.000000, hglt: 6.807944 },
  { isoDate: "2009-09-30T00:00:00Z", dsun: 215.353104, hgln: 0.000000, hglt: 6.764004 },
  { isoDate: "2009-10-01T00:00:00Z", dsun: 215.290849, hgln: 0.000000, hglt: 6.718052 },
  { isoDate: "2009-10-02T00:00:00Z", dsun: 215.228716, hgln: -0.000000, hglt: 6.670098 },
  { isoDate: "2009-10-03T00:00:00Z", dsun: 215.166754, hgln: -0.000000, hglt: 6.620155 },
  { isoDate: "2009-10-04T00:00:00Z", dsun: 215.105007, hgln: -0.000000, hglt: 6.568235 },
  { isoDate: "2009-10-05T00:00:00Z", dsun: 215.043507, hgln: 0.000000, hglt: 6.514351 },
  { isoDate: "2009-10-06T00:00:00Z", dsun: 214.982277, hgln: 0.000000, hglt: 6.458515 },
  { isoDate: "2009-10-07T00:00:00Z", dsun: 214.921324, hgln: -0.000000, hglt: 6.400742 },
  { isoDate: "2009-10-08T00:00:00Z", dsun: 214.860639, hgln: -0.000000, hglt: 6.341042 },
  { isoDate: "2009-10-09T00:00:00Z", dsun: 214.800202, hgln: -0.000000, hglt: 6.279430 },
  { isoDate: "2009-10-10T00:00:00Z", dsun: 214.739978, hgln: 0.000000, hglt: 6.215918 },
  { isoDate: "2009-10-11T00:00:00Z", dsun: 214.679920, hgln: 0.000000, hglt: 6.150520 },
  { isoDate: "2009-10-12T00:00:00Z", dsun: 214.619978, hgln: 0.000000, hglt: 6.083251 },
  { isoDate: "2009-10-13T00:00:00Z", dsun: 214.560093, hgln: -0.000000, hglt: 6.014124 },
  { isoDate: "2009-10-14T00:00:00Z", dsun: 214.500213, hgln: -0.000000, hglt: 5.943156 },
  { isoDate: "2009-10-15T00:00:00Z", dsun: 214.440288, hgln: -0.000000, hglt: 5.870364 },
  { isoDate: "2009-10-16T00:00:00Z", dsun: 214.380281, hgln: -0.000000, hglt: 5.795766 },
  { isoDate: "2009-10-17T00:00:00Z", dsun: 214.320171, hgln: 0.000000, hglt: 5.719382 },
  { isoDate: "2009-10-18T00:00:00Z", dsun: 214.259952, hgln: 0.000000, hglt: 5.641233 },
  { isoDate: "2009-10-19T00:00:00Z", dsun: 214.199638, hgln: 0.000000, hglt: 5.561342 },
  { isoDate: "2009-10-20T00:00:00Z", dsun: 214.139258, hgln: -0.000000, hglt: 5.479731 },
  { isoDate: "2009-10-21T00:00:00Z", dsun: 214.078855, hgln: 0.000000, hglt: 5.396426 },
  { isoDate: "2009-10-22T00:00:00Z", dsun: 214.018484, hgln: 0.000000, hglt: 5.311452 },
  { isoDate: "2009-10-23T00:00:00Z", dsun: 213.958203, hgln: -0.000000, hglt: 5.224835 },
  { isoDate: "2009-10-24T00:00:00Z", dsun: 213.898079, hgln: -0.000000, hglt: 5.136602 },
  { isoDate: "2009-10-25T00:00:00Z", dsun: 213.838176, hgln: 0.000000, hglt: 5.046780 },
  { isoDate: "2009-10-26T00:00:00Z", dsun: 213.778562, hgln: 0.000000, hglt: 4.955396 },
  { isoDate: "2009-10-27T00:00:00Z", dsun: 213.719306, hgln: -0.000000, hglt: 4.862479 },
  { isoDate: "2009-10-28T00:00:00Z", dsun: 213.660473, hgln: 0.000000, hglt: 4.768055 },
  { isoDate: "2009-10-29T00:00:00Z", dsun: 213.602133, hgln: -0.000000, hglt: 4.672155 },
  { isoDate: "2009-10-30T00:00:00Z", dsun: 213.544350, hgln: 0.000000, hglt: 4.574806 },
  { isoDate: "2009-10-31T00:00:00Z", dsun: 213.487190, hgln: -0.000000, hglt: 4.476037 },
  { isoDate: "2009-11-01T00:00:00Z", dsun: 213.430712, hgln: -0.000000, hglt: 4.375876 },
  { isoDate: "2009-11-02T00:00:00Z", dsun: 213.374970, hgln: -0.000000, hglt: 4.274353 },
  { isoDate: "2009-11-03T00:00:00Z", dsun: 213.320007, hgln: 0.000000, hglt: 4.171495 },
  { isoDate: "2009-11-04T00:00:00Z", dsun: 213.265851, hgln: 0.000000, hglt: 4.067330 },
  { isoDate: "2009-11-05T00:00:00Z", dsun: 213.212515, hgln: -0.000000, hglt: 3.961887 },
  { isoDate: "2009-11-06T00:00:00Z", dsun: 213.159993, hgln: 0.000000, hglt: 3.855191 },
  { isoDate: "2009-11-07T00:00:00Z", dsun: 213.108262, hgln: 0.000000, hglt: 3.747271 },
  { isoDate: "2009-11-08T00:00:00Z", dsun: 213.057283, hgln: 0.000000, hglt: 3.638156 },
  { isoDate: "2009-11-09T00:00:00Z", dsun: 213.007005, hgln: 0.000000, hglt: 3.527873 },
  { isoDate: "2009-11-10T00:00:00Z", dsun: 212.957372, hgln: 0.000000, hglt: 3.416453 },
  { isoDate: "2009-11-11T00:00:00Z", dsun: 212.908327, hgln: 0.000000, hglt: 3.303927 },
  { isoDate: "2009-11-12T00:00:00Z", dsun: 212.859814, hgln: 0.000000, hglt: 3.190326 },
  { isoDate: "2009-11-13T00:00:00Z", dsun: 212.811785, hgln: -0.000000, hglt: 3.075685 },
  { isoDate: "2009-11-14T00:00:00Z", dsun: 212.764203, hgln: -0.000000, hglt: 2.960038 },
  { isoDate: "2009-11-15T00:00:00Z", dsun: 212.717043, hgln: -0.000000, hglt: 2.843422 },
  { isoDate: "2009-11-16T00:00:00Z", dsun: 212.670295, hgln: -0.000000, hglt: 2.725874 },
  { isoDate: "2009-11-17T00:00:00Z", dsun: 212.623961, hgln: 0.000000, hglt: 2.607431 },
  { isoDate: "2009-11-18T00:00:00Z", dsun: 212.578059, hgln: 0.000000, hglt: 2.488135 },
  { isoDate: "2009-11-19T00:00:00Z", dsun: 212.532617, hgln: 0.000000, hglt: 2.368023 },
  { isoDate: "2009-11-20T00:00:00Z", dsun: 212.487673, hgln: 0.000000, hglt: 2.247135 },
  { isoDate: "2009-11-21T00:00:00Z", dsun: 212.443274, hgln: -0.000000, hglt: 2.125513 },
  { isoDate: "2009-11-22T00:00:00Z", dsun: 212.399470, hgln: -0.000000, hglt: 2.003196 },
  { isoDate: "2009-11-23T00:00:00Z", dsun: 212.356317, hgln: 0.000000, hglt: 1.880225 },
  { isoDate: "2009-11-24T00:00:00Z", dsun: 212.313873, hgln: -0.000000, hglt: 1.756640 },
  { isoDate: "2009-11-25T00:00:00Z", dsun: 212.272198, hgln: -0.000000, hglt: 1.632482 },
  { isoDate: "2009-11-26T00:00:00Z", dsun: 212.231355, hgln: -0.000000, hglt: 1.507790 },
  { isoDate: "2009-11-27T00:00:00Z", dsun: 212.191410, hgln: -0.000000, hglt: 1.382605 },
  { isoDate: "2009-11-28T00:00:00Z", dsun: 212.152428, hgln: -0.000000, hglt: 1.256966 },
  { isoDate: "2009-11-29T00:00:00Z", dsun: 212.114477, hgln: 0.000000, hglt: 1.130913 },
  { isoDate: "2009-11-30T00:00:00Z", dsun: 212.077622, hgln: 0.000000, hglt: 1.004484 },
  { isoDate: "2009-12-01T00:00:00Z", dsun: 212.041920, hgln: 0.000000, hglt: 0.877718 },
  { isoDate: "2009-12-02T00:00:00Z", dsun: 212.007423, hgln: -0.000000, hglt: 0.750652 },
  { isoDate: "2009-12-03T00:00:00Z", dsun: 211.974163, hgln: 0.000000, hglt: 0.623322 },
  { isoDate: "2009-12-04T00:00:00Z", dsun: 211.942153, hgln: -0.000000, hglt: 0.495763 },
  { isoDate: "2009-12-05T00:00:00Z", dsun: 211.911387, hgln: -0.000000, hglt: 0.368010 },
  { isoDate: "2009-12-06T00:00:00Z", dsun: 211.881833, hgln: 0.000000, hglt: 0.240101 },
  { isoDate: "2009-12-07T00:00:00Z", dsun: 211.853447, hgln: -0.000000, hglt: 0.112070 },
  { isoDate: "2009-12-08T00:00:00Z", dsun: 211.826168, hgln: -0.000000, hglt: -0.016046 },
  { isoDate: "2009-12-09T00:00:00Z", dsun: 211.799934, hgln: -0.000000, hglt: -0.144208 },
  { isoDate: "2009-12-10T00:00:00Z", dsun: 211.774680, hgln: -0.000000, hglt: -0.272376 },
  { isoDate: "2009-12-11T00:00:00Z", dsun: 211.750345, hgln: -0.000000, hglt: -0.400511 },
  { isoDate: "2009-12-12T00:00:00Z", dsun: 211.726879, hgln: 0.000000, hglt: -0.528571 },
  { isoDate: "2009-12-13T00:00:00Z", dsun: 211.704237, hgln: 0.000000, hglt: -0.656513 },
  { isoDate: "2009-12-14T00:00:00Z", dsun: 211.682387, hgln: -0.000000, hglt: -0.784294 },
  { isoDate: "2009-12-15T00:00:00Z", dsun: 211.661307, hgln: 0.000000, hglt: -0.911870 },
  { isoDate: "2009-12-16T00:00:00Z", dsun: 211.640987, hgln: 0.000000, hglt: -1.039197 },
  { isoDate: "2009-12-17T00:00:00Z", dsun: 211.621427, hgln: -0.000000, hglt: -1.166231 },
  { isoDate: "2009-12-18T00:00:00Z", dsun: 211.602638, hgln: -0.000000, hglt: -1.292927 },
  { isoDate: "2009-12-19T00:00:00Z", dsun: 211.584639, hgln: -0.000000, hglt: -1.419241 },
  { isoDate: "2009-12-20T00:00:00Z", dsun: 211.567457, hgln: -0.000000, hglt: -1.545129 },
  { isoDate: "2009-12-21T00:00:00Z", dsun: 211.551126, hgln: 0.000000, hglt: -1.670548 },
  { isoDate: "2009-12-22T00:00:00Z", dsun: 211.535685, hgln: 0.000000, hglt: -1.795454 },
  { isoDate: "2009-12-23T00:00:00Z", dsun: 211.521178, hgln: -0.000000, hglt: -1.919804 },
  { isoDate: "2009-12-24T00:00:00Z", dsun: 211.507653, hgln: 0.000000, hglt: -2.043557 },
  { isoDate: "2009-12-25T00:00:00Z", dsun: 211.495164, hgln: 0.000000, hglt: -2.166670 },
  { isoDate: "2009-12-26T00:00:00Z", dsun: 211.483767, hgln: 0.000000, hglt: -2.289103 },
  { isoDate: "2009-12-27T00:00:00Z", dsun: 211.473524, hgln: -0.000000, hglt: -2.410816 },
  { isoDate: "2009-12-28T00:00:00Z", dsun: 211.464498, hgln: -0.000000, hglt: -2.531769 },
  { isoDate: "2009-12-29T00:00:00Z", dsun: 211.456754, hgln: -0.000000, hglt: -2.651925 },
  { isoDate: "2009-12-30T00:00:00Z", dsun: 211.450351, hgln: -0.000000, hglt: -2.771247 },
  { isoDate: "2009-12-31T00:00:00Z", dsun: 211.445342, hgln: 0.000000, hglt: -2.889699 },
  { isoDate: "2010-01-01T00:00:00Z", dsun: 211.441760, hgln: -0.000000, hglt: -3.007248 },
  { isoDate: "2010-01-02T00:00:00Z", dsun: 211.439619, hgln: 0.000000, hglt: -3.123860 },
  { isoDate: "2010-01-03T00:00:00Z", dsun: 211.438906, hgln: -0.000000, hglt: -3.239503 },
  { isoDate: "2010-01-04T00:00:00Z", dsun: 211.439586, hgln: -0.000000, hglt: -3.354144 },
  { isoDate: "2010-01-05T00:00:00Z", dsun: 211.441601, hgln: 0.000000, hglt: -3.467749 },
  { isoDate: "2010-01-06T00:00:00Z", dsun: 211.444886, hgln: -0.000000, hglt: -3.580284 },
  { isoDate: "2010-01-07T00:00:00Z", dsun: 211.449366, hgln: 0.000000, hglt: -3.691715 },
  { isoDate: "2010-01-08T00:00:00Z", dsun: 211.454970, hgln: -0.000000, hglt: -3.802004 },
  { isoDate: "2010-01-09T00:00:00Z", dsun: 211.461629, hgln: 0.000000, hglt: -3.911115 },
  { isoDate: "2010-01-10T00:00:00Z", dsun: 211.469283, hgln: 0.000000, hglt: -4.019012 },
  { isoDate: "2010-01-11T00:00:00Z", dsun: 211.477879, hgln: -0.000000, hglt: -4.125657 },
  { isoDate: "2010-01-12T00:00:00Z", dsun: 211.487374, hgln: -0.000000, hglt: -4.231012 },
  { isoDate: "2010-01-13T00:00:00Z", dsun: 211.497731, hgln: -0.000000, hglt: -4.335041 },
  { isoDate: "2010-01-14T00:00:00Z", dsun: 211.508925, hgln: -0.000000, hglt: -4.437707 },
  { isoDate: "2010-01-15T00:00:00Z", dsun: 211.520938, hgln: 0.000000, hglt: -4.538973 },
  { isoDate: "2010-01-16T00:00:00Z", dsun: 211.533761, hgln: 0.000000, hglt: -4.638804 },
  { isoDate: "2010-01-17T00:00:00Z", dsun: 211.547393, hgln: 0.000000, hglt: -4.737163 },
  { isoDate: "2010-01-18T00:00:00Z", dsun: 211.561842, hgln: 0.000000, hglt: -4.834017 },
  { isoDate: "2010-01-19T00:00:00Z", dsun: 211.577121, hgln: -0.000000, hglt: -4.929331 },
  { isoDate: "2010-01-20T00:00:00Z", dsun: 211.593254, hgln: 0.000000, hglt: -5.023073 },
  { isoDate: "2010-01-21T00:00:00Z", dsun: 211.610267, hgln: 0.000000, hglt: -5.115211 },
  { isoDate: "2010-01-22T00:00:00Z", dsun: 211.628195, hgln: 0.000000, hglt: -5.205714 },
  { isoDate: "2010-01-23T00:00:00Z", dsun: 211.647079, hgln: 0.000000, hglt: -5.294552 },
  { isoDate: "2010-01-24T00:00:00Z", dsun: 211.666966, hgln: -0.000000, hglt: -5.381696 },
  { isoDate: "2010-01-25T00:00:00Z", dsun: 211.687909, hgln: -0.000000, hglt: -5.467119 },
  { isoDate: "2010-01-26T00:00:00Z", dsun: 211.709966, hgln: -0.000000, hglt: -5.550795 },
  { isoDate: "2010-01-27T00:00:00Z", dsun: 211.733197, hgln: -0.000000, hglt: -5.632699 },
  { isoDate: "2010-01-28T00:00:00Z", dsun: 211.757661, hgln: 0.000000, hglt: -5.712809 },
  { isoDate: "2010-01-29T00:00:00Z", dsun: 211.783407, hgln: -0.000000, hglt: -5.791102 },
  { isoDate: "2010-01-30T00:00:00Z", dsun: 211.810469, hgln: 0.000000, hglt: -5.867558 },
  { isoDate: "2010-01-31T00:00:00Z", dsun: 211.838858, hgln: -0.000000, hglt: -5.942159 },
  { isoDate: "2010-02-01T00:00:00Z", dsun: 211.868559, hgln: 0.000000, hglt: -6.014884 },
  { isoDate: "2010-02-02T00:00:00Z", dsun: 211.899531, hgln: -0.000000, hglt: -6.085716 },
  { isoDate: "2010-02-03T00:00:00Z", dsun: 211.931715, hgln: 0.000000, hglt: -6.154634 },
  { isoDate: "2010-02-04T00:00:00Z", dsun: 211.965035, hgln: 0.000000, hglt: -6.221619 },
  { isoDate: "2010-02-05T00:00:00Z", dsun: 211.999413, hgln: 0.000000, hglt: -6.286649 },
  { isoDate: "2010-02-06T00:00:00Z", dsun: 212.034770, hgln: -0.000000, hglt: -6.349706 },
  { isoDate: "2010-02-07T00:00:00Z", dsun: 212.071031, hgln: -0.000000, hglt: -6.410767 },
  { isoDate: "2010-02-08T00:00:00Z", dsun: 212.108126, hgln: 0.000000, hglt: -6.469814 },
  { isoDate: "2010-02-09T00:00:00Z", dsun: 212.145994, hgln: 0.000000, hglt: -6.526825 },
  { isoDate: "2010-02-10T00:00:00Z", dsun: 212.184578, hgln: 0.000000, hglt: -6.581783 },
  { isoDate: "2010-02-11T00:00:00Z", dsun: 212.223830, hgln: 0.000000, hglt: -6.634668 },
  { isoDate: "2010-02-12T00:00:00Z", dsun: 212.263709, hgln: -0.000000, hglt: -6.685462 },
  { isoDate: "2010-02-13T00:00:00Z", dsun: 212.304181, hgln: 0.000000, hglt: -6.734149 },
  { isoDate: "2010-02-14T00:00:00Z", dsun: 212.345218, hgln: 0.000000, hglt: -6.780712 },
  { isoDate: "2010-02-15T00:00:00Z", dsun: 212.386801, hgln: 0.000000, hglt: -6.825137 },
  { isoDate: "2010-02-16T00:00:00Z", dsun: 212.428920, hgln: -0.000000, hglt: -6.867409 },
  { isoDate: "2010-02-17T00:00:00Z", dsun: 212.471573, hgln: -0.000000, hglt: -6.907516 },
  { isoDate: "2010-02-18T00:00:00Z", dsun: 212.514765, hgln: 0.000000, hglt: -6.945445 },
  { isoDate: "2010-02-19T00:00:00Z", dsun: 212.558510, hgln: 0.000000, hglt: -6.981186 },
  { isoDate: "2010-02-20T00:00:00Z", dsun: 212.602831, hgln: -0.000000, hglt: -7.014730 },
  { isoDate: "2010-02-21T00:00:00Z", dsun: 212.647759, hgln: 0.000000, hglt: -7.046069 },
  { isoDate: "2010-02-22T00:00:00Z", dsun: 212.693334, hgln: 0.000000, hglt: -7.075196 },
  { isoDate: "2010-02-23T00:00:00Z", dsun: 212.739602, hgln: 0.000000, hglt: -7.102105 },
  { isoDate: "2010-02-24T00:00:00Z", dsun: 212.786616, hgln: 0.000000, hglt: -7.126794 },
  { isoDate: "2010-02-25T00:00:00Z", dsun: 212.834433, hgln: -0.000000, hglt: -7.149258 },
  { isoDate: "2010-02-26T00:00:00Z", dsun: 212.883109, hgln: -0.000000, hglt: -7.169498 },
  { isoDate: "2010-02-27T00:00:00Z", dsun: 212.932689, hgln: -0.000000, hglt: -7.187512 },
  { isoDate: "2010-02-28T00:00:00Z", dsun: 212.983207, hgln: 0.000000, hglt: -7.203301 },
  { isoDate: "2010-03-01T00:00:00Z", dsun: 213.034672, hgln: 0.000000, hglt: -7.216865 },
  { isoDate: "2010-03-02T00:00:00Z", dsun: 213.087071, hgln: 0.000000, hglt: -7.228206 },
  { isoDate: "2010-03-03T00:00:00Z", dsun: 213.140363, hgln: -0.000000, hglt: -7.237324 },
  { isoDate: "2010-03-04T00:00:00Z", dsun: 213.194489, hgln: -0.000000, hglt: -7.244220 },
  { isoDate: "2010-03-05T00:00:00Z", dsun: 213.249377, hgln: 0.000000, hglt: -7.248894 },
  { isoDate: "2010-03-06T00:00:00Z", dsun: 213.304947, hgln: -0.000000, hglt: -7.251347 },
  { isoDate: "2010-03-07T00:00:00Z", dsun: 213.361119, hgln: 0.000000, hglt: -7.251580 },
  { isoDate: "2010-03-08T00:00:00Z", dsun: 213.417815, hgln: -0.000000, hglt: -7.249593 },
  { isoDate: "2010-03-09T00:00:00Z", dsun: 213.474961, hgln: -0.000000, hglt: -7.245390 },
  { isoDate: "2010-03-10T00:00:00Z", dsun: 213.532488, hgln: -0.000000, hglt: -7.238973 },
  { isoDate: "2010-03-11T00:00:00Z", dsun: 213.590332, hgln: 0.000000, hglt: -7.230344 },
  { isoDate: "2010-03-12T00:00:00Z", dsun: 213.648435, hgln: 0.000000, hglt: -7.219510 },
  { isoDate: "2010-03-13T00:00:00Z", dsun: 213.706743, hgln: -0.000000, hglt: -7.206474 },
  { isoDate: "2010-03-14T00:00:00Z", dsun: 213.765210, hgln: -0.000000, hglt: -7.191244 },
  { isoDate: "2010-03-15T00:00:00Z", dsun: 213.823795, hgln: -0.000000, hglt: -7.173826 },
  { isoDate: "2010-03-16T00:00:00Z", dsun: 213.882465, hgln: -0.000000, hglt: -7.154228 },
  { isoDate: "2010-03-17T00:00:00Z", dsun: 213.941194, hgln: -0.000000, hglt: -7.132461 },
  { isoDate: "2010-03-18T00:00:00Z", dsun: 213.999968, hgln: -0.000000, hglt: -7.108533 },
  { isoDate: "2010-03-19T00:00:00Z", dsun: 214.058781, hgln: -0.000000, hglt: -7.082457 },
  { isoDate: "2010-03-20T00:00:00Z", dsun: 214.117638, hgln: 0.000000, hglt: -7.054245 },
  { isoDate: "2010-03-21T00:00:00Z", dsun: 214.176555, hgln: 0.000000, hglt: -7.023912 },
  { isoDate: "2010-03-22T00:00:00Z", dsun: 214.235560, hgln: -0.000000, hglt: -6.991471 },
  { isoDate: "2010-03-23T00:00:00Z", dsun: 214.294689, hgln: 0.000000, hglt: -6.956940 },
  { isoDate: "2010-03-24T00:00:00Z", dsun: 214.353988, hgln: -0.000000, hglt: -6.920334 },
  { isoDate: "2010-03-25T00:00:00Z", dsun: 214.413510, hgln: 0.000000, hglt: -6.881672 },
  { isoDate: "2010-03-26T00:00:00Z", dsun: 214.473309, hgln: 0.000000, hglt: -6.840971 },
  { isoDate: "2010-03-27T00:00:00Z", dsun: 214.533439, hgln: 0.000000, hglt: -6.798251 },
  { isoDate: "2010-03-28T00:00:00Z", dsun: 214.593947, hgln: 0.000000, hglt: -6.753529 },
  { isoDate: "2010-03-29T00:00:00Z", dsun: 214.654864, hgln: 0.000000, hglt: -6.706826 },
  { isoDate: "2010-03-30T00:00:00Z", dsun: 214.716202, hgln: 0.000000, hglt: -6.658159 },
  { isoDate: "2010-03-31T00:00:00Z", dsun: 214.777949, hgln: 0.000000, hglt: -6.607546 },
  { isoDate: "2010-04-01T00:00:00Z", dsun: 214.840073, hgln: 0.000000, hglt: -6.555005 },
  { isoDate: "2010-04-02T00:00:00Z", dsun: 214.902522, hgln: 0.000000, hglt: -6.500554 },
  { isoDate: "2010-04-03T00:00:00Z", dsun: 214.965232, hgln: 0.000000, hglt: -6.444211 },
  { isoDate: "2010-04-04T00:00:00Z", dsun: 215.028131, hgln: -0.000000, hglt: -6.385993 },
  { isoDate: "2010-04-05T00:00:00Z", dsun: 215.091144, hgln: -0.000000, hglt: -6.325919 },
  { isoDate: "2010-04-06T00:00:00Z", dsun: 215.154196, hgln: -0.000000, hglt: -6.264009 },
  { isoDate: "2010-04-07T00:00:00Z", dsun: 215.217215, hgln: 0.000000, hglt: -6.200283 },
  { isoDate: "2010-04-08T00:00:00Z", dsun: 215.280129, hgln: -0.000000, hglt: -6.134760 },
  { isoDate: "2010-04-09T00:00:00Z", dsun: 215.342872, hgln: -0.000000, hglt: -6.067463 },
  { isoDate: "2010-04-10T00:00:00Z", dsun: 215.405382, hgln: 0.000000, hglt: -5.998412 },
  { isoDate: "2010-04-11T00:00:00Z", dsun: 215.467597, hgln: -0.000000, hglt: -5.927632 },
  { isoDate: "2010-04-12T00:00:00Z", dsun: 215.529462, hgln: 0.000000, hglt: -5.855146 },
  { isoDate: "2010-04-13T00:00:00Z", dsun: 215.590928, hgln: 0.000000, hglt: -5.780977 },
  { isoDate: "2010-04-14T00:00:00Z", dsun: 215.651951, hgln: 0.000000, hglt: -5.705152 },
  { isoDate: "2010-04-15T00:00:00Z", dsun: 215.712496, hgln: 0.000000, hglt: -5.627697 },
  { isoDate: "2010-04-16T00:00:00Z", dsun: 215.772541, hgln: 0.000000, hglt: -5.548638 },
  { isoDate: "2010-04-17T00:00:00Z", dsun: 215.832073, hgln: -0.000000, hglt: -5.468004 },
  { isoDate: "2010-04-18T00:00:00Z", dsun: 215.891094, hgln: 0.000000, hglt: -5.385823 },
  { isoDate: "2010-04-19T00:00:00Z", dsun: 215.949620, hgln: 0.000000, hglt: -5.302126 },
  { isoDate: "2010-04-20T00:00:00Z", dsun: 216.007681, hgln: 0.000000, hglt: -5.216943 },
  { isoDate: "2010-04-21T00:00:00Z", dsun: 216.065316, hgln: 0.000000, hglt: -5.130304 },
  { isoDate: "2010-04-22T00:00:00Z", dsun: 216.122575, hgln: 0.000000, hglt: -5.042240 },
  { isoDate: "2010-04-23T00:00:00Z", dsun: 216.179516, hgln: 0.000000, hglt: -4.952782 },
  { isoDate: "2010-04-24T00:00:00Z", dsun: 216.236196, hgln: 0.000000, hglt: -4.861961 },
  { isoDate: "2010-04-25T00:00:00Z", dsun: 216.292670, hgln: 0.000000, hglt: -4.769805 },
  { isoDate: "2010-04-26T00:00:00Z", dsun: 216.348985, hgln: 0.000000, hglt: -4.676345 },
  { isoDate: "2010-04-27T00:00:00Z", dsun: 216.405173, hgln: 0.000000, hglt: -4.581608 },
  { isoDate: "2010-04-28T00:00:00Z", dsun: 216.461249, hgln: 0.000000, hglt: -4.485624 },
  { isoDate: "2010-04-29T00:00:00Z", dsun: 216.517210, hgln: 0.000000, hglt: -4.388417 },
  { isoDate: "2010-04-30T00:00:00Z", dsun: 216.573033, hgln: 0.000000, hglt: -4.290016 },
  { isoDate: "2010-05-01T00:00:00Z", dsun: 216.628680, hgln: 0.000000, hglt: -4.190448 },
  { isoDate: "2010-05-02T00:00:00Z", dsun: 216.684099, hgln: 0.000000, hglt: -4.089738 },
  { isoDate: "2010-05-03T00:00:00Z", dsun: 216.739231, hgln: 0.000000, hglt: -3.987914 },
  { isoDate: "2010-05-04T00:00:00Z", dsun: 216.794013, hgln: 0.000000, hglt: -3.885003 },
  { isoDate: "2010-05-05T00:00:00Z", dsun: 216.848379, hgln: -0.000000, hglt: -3.781035 },
  { isoDate: "2010-05-06T00:00:00Z", dsun: 216.902263, hgln: -0.000000, hglt: -3.676037 },
  { isoDate: "2010-05-07T00:00:00Z", dsun: 216.955598, hgln: -0.000000, hglt: -3.570039 },
  { isoDate: "2010-05-08T00:00:00Z", dsun: 217.008320, hgln: 0.000000, hglt: -3.463071 },
  { isoDate: "2010-05-09T00:00:00Z", dsun: 217.060365, hgln: -0.000000, hglt: -3.355162 },
  { isoDate: "2010-05-10T00:00:00Z", dsun: 217.111672, hgln: 0.000000, hglt: -3.246345 },
  { isoDate: "2010-05-11T00:00:00Z", dsun: 217.162180, hgln: -0.000000, hglt: -3.136650 },
  { isoDate: "2010-05-12T00:00:00Z", dsun: 217.211834, hgln: 0.000000, hglt: -3.026110 },
  { isoDate: "2010-05-13T00:00:00Z", dsun: 217.260585, hgln: 0.000000, hglt: -2.914759 },
  { isoDate: "2010-05-14T00:00:00Z", dsun: 217.308392, hgln: 0.000000, hglt: -2.802629 },
  { isoDate: "2010-05-15T00:00:00Z", dsun: 217.355228, hgln: 0.000000, hglt: -2.689757 },
  { isoDate: "2010-05-16T00:00:00Z", dsun: 217.401077, hgln: 0.000000, hglt: -2.576177 },
  { isoDate: "2010-05-17T00:00:00Z", dsun: 217.445945, hgln: -0.000000, hglt: -2.461926 },
  { isoDate: "2010-05-18T00:00:00Z", dsun: 217.489850, hgln: 0.000000, hglt: -2.347040 },
  { isoDate: "2010-05-19T00:00:00Z", dsun: 217.532828, hgln: -0.000000, hglt: -2.231555 },
  { isoDate: "2010-05-20T00:00:00Z", dsun: 217.574930, hgln: 0.000000, hglt: -2.115508 },
  { isoDate: "2010-05-21T00:00:00Z", dsun: 217.616214, hgln: -0.000000, hglt: -1.998933 },
  { isoDate: "2010-05-22T00:00:00Z", dsun: 217.656743, hgln: 0.000000, hglt: -1.881865 },
  { isoDate: "2010-05-23T00:00:00Z", dsun: 217.696580, hgln: 0.000000, hglt: -1.764338 },
  { isoDate: "2010-05-24T00:00:00Z", dsun: 217.735783, hgln: -0.000000, hglt: -1.646384 },
  { isoDate: "2010-05-25T00:00:00Z", dsun: 217.774400, hgln: -0.000000, hglt: -1.528034 },
  { isoDate: "2010-05-26T00:00:00Z", dsun: 217.812470, hgln: -0.000000, hglt: -1.409318 },
  { isoDate: "2010-05-27T00:00:00Z", dsun: 217.850011, hgln: 0.000000, hglt: -1.290267 },
  { isoDate: "2010-05-28T00:00:00Z", dsun: 217.887030, hgln: -0.000000, hglt: -1.170909 },
  { isoDate: "2010-05-29T00:00:00Z", dsun: 217.923517, hgln: 0.000000, hglt: -1.051272 },
  { isoDate: "2010-05-30T00:00:00Z", dsun: 217.959447, hgln: -0.000000, hglt: -0.931386 },
  { isoDate: "2010-05-31T00:00:00Z", dsun: 217.994787, hgln: -0.000000, hglt: -0.811280 },
  { isoDate: "2010-06-01T00:00:00Z", dsun: 218.029493, hgln: 0.000000, hglt: -0.690982 },
  { isoDate: "2010-06-02T00:00:00Z", dsun: 218.063517, hgln: 0.000000, hglt: -0.570521 },
  { isoDate: "2010-06-03T00:00:00Z", dsun: 218.096805, hgln: -0.000000, hglt: -0.449928 },
  { isoDate: "2010-06-04T00:00:00Z", dsun: 218.129302, hgln: -0.000000, hglt: -0.329233 },
  { isoDate: "2010-06-05T00:00:00Z", dsun: 218.160949, hgln: -0.000000, hglt: -0.208467 },
  { isoDate: "2010-06-06T00:00:00Z", dsun: 218.191690, hgln: 0.000000, hglt: -0.087660 },
  { isoDate: "2010-06-07T00:00:00Z", dsun: 218.221463, hgln: 0.000000, hglt: 0.033155 },
  { isoDate: "2010-06-08T00:00:00Z", dsun: 218.250208, hgln: -0.000000, hglt: 0.153946 },
  { isoDate: "2010-06-09T00:00:00Z", dsun: 218.277864, hgln: 0.000000, hglt: 0.274682 },
  { isoDate: "2010-06-10T00:00:00Z", dsun: 218.304375, hgln: 0.000000, hglt: 0.395327 },
  { isoDate: "2010-06-11T00:00:00Z", dsun: 218.329686, hgln: -0.000000, hglt: 0.515849 },
  { isoDate: "2010-06-12T00:00:00Z", dsun: 218.353755, hgln: 0.000000, hglt: 0.636211 },
  { isoDate: "2010-06-13T00:00:00Z", dsun: 218.376550, hgln: -0.000000, hglt: 0.756379 },
  { isoDate: "2010-06-14T00:00:00Z", dsun: 218.398059, hgln: 0.000000, hglt: 0.876315 },
  { isoDate: "2010-06-15T00:00:00Z", dsun: 218.418288, hgln: -0.000000, hglt: 0.995984 },
  { isoDate: "2010-06-16T00:00:00Z", dsun: 218.437267, hgln: 0.000000, hglt: 1.115349 },
  { isoDate: "2010-06-17T00:00:00Z", dsun: 218.455040, hgln: -0.000000, hglt: 1.234375 },
  { isoDate: "2010-06-18T00:00:00Z", dsun: 218.471668, hgln: -0.000000, hglt: 1.353027 },
  { isoDate: "2010-06-19T00:00:00Z", dsun: 218.487220, hgln: 0.000000, hglt: 1.471271 },
  { isoDate: "2010-06-20T00:00:00Z", dsun: 218.501766, hgln: -0.000000, hglt: 1.589076 },
  { isoDate: "2010-06-21T00:00:00Z", dsun: 218.515375, hgln: 0.000000, hglt: 1.706411 },
  { isoDate: "2010-06-22T00:00:00Z", dsun: 218.528109, hgln: 0.000000, hglt: 1.823246 },
  { isoDate: "2010-06-23T00:00:00Z", dsun: 218.540022, hgln: -0.000000, hglt: 1.939553 },
  { isoDate: "2010-06-24T00:00:00Z", dsun: 218.551155, hgln: -0.000000, hglt: 2.055303 },
  { isoDate: "2010-06-25T00:00:00Z", dsun: 218.561536, hgln: 0.000000, hglt: 2.170470 },
  { isoDate: "2010-06-26T00:00:00Z", dsun: 218.571182, hgln: 0.000000, hglt: 2.285027 },
  { isoDate: "2010-06-27T00:00:00Z", dsun: 218.580096, hgln: 0.000000, hglt: 2.398947 },
  { isoDate: "2010-06-28T00:00:00Z", dsun: 218.588271, hgln: -0.000000, hglt: 2.512205 },
  { isoDate: "2010-06-29T00:00:00Z", dsun: 218.595689, hgln: 0.000000, hglt: 2.624773 },
  { isoDate: "2010-06-30T00:00:00Z", dsun: 218.602322, hgln: 0.000000, hglt: 2.736625 },
  { isoDate: "2010-07-01T00:00:00Z", dsun: 218.608140, hgln: 0.000000, hglt: 2.847733 },
  { isoDate: "2010-07-02T00:00:00Z", dsun: 218.613103, hgln: 0.000000, hglt: 2.958071 },
  { isoDate: "2010-07-03T00:00:00Z", dsun: 218.617168, hgln: 0.000000, hglt: 3.067611 },
  { isoDate: "2010-07-04T00:00:00Z", dsun: 218.620289, hgln: 0.000000, hglt: 3.176325 },
  { isoDate: "2010-07-05T00:00:00Z", dsun: 218.622416, hgln: -0.000000, hglt: 3.284185 },
  { isoDate: "2010-07-06T00:00:00Z", dsun: 218.623495, hgln: 0.000000, hglt: 3.391162 },
  { isoDate: "2010-07-07T00:00:00Z", dsun: 218.623469, hgln: 0.000000, hglt: 3.497227 },
  { isoDate: "2010-07-08T00:00:00Z", dsun: 218.622279, hgln: 0.000000, hglt: 3.602351 },
  { isoDate: "2010-07-09T00:00:00Z", dsun: 218.619869, hgln: 0.000000, hglt: 3.706504 },
  { isoDate: "2010-07-10T00:00:00Z", dsun: 218.616182, hgln: 0.000000, hglt: 3.809655 },
  { isoDate: "2010-07-11T00:00:00Z", dsun: 218.611174, hgln: -0.000000, hglt: 3.911772 },
  { isoDate: "2010-07-12T00:00:00Z", dsun: 218.604814, hgln: -0.000000, hglt: 4.012823 },
  { isoDate: "2010-07-13T00:00:00Z", dsun: 218.597090, hgln: -0.000000, hglt: 4.112777 },
  { isoDate: "2010-07-14T00:00:00Z", dsun: 218.588015, hgln: -0.000000, hglt: 4.211601 },
  { isoDate: "2010-07-15T00:00:00Z", dsun: 218.577626, hgln: -0.000000, hglt: 4.309266 },
  { isoDate: "2010-07-16T00:00:00Z", dsun: 218.565979, hgln: -0.000000, hglt: 4.405740 },
  { isoDate: "2010-07-17T00:00:00Z", dsun: 218.553142, hgln: -0.000000, hglt: 4.500996 },
  { isoDate: "2010-07-18T00:00:00Z", dsun: 218.539194, hgln: 0.000000, hglt: 4.595006 },
  { isoDate: "2010-07-19T00:00:00Z", dsun: 218.524211, hgln: 0.000000, hglt: 4.687745 },
  { isoDate: "2010-07-20T00:00:00Z", dsun: 218.508268, hgln: -0.000000, hglt: 4.779190 },
  { isoDate: "2010-07-21T00:00:00Z", dsun: 218.491432, hgln: -0.000000, hglt: 4.869315 },
  { isoDate: "2010-07-22T00:00:00Z", dsun: 218.473761, hgln: 0.000000, hglt: 4.958100 },
  { isoDate: "2010-07-23T00:00:00Z", dsun: 218.455303, hgln: 0.000000, hglt: 5.045523 },
  { isoDate: "2010-07-24T00:00:00Z", dsun: 218.436096, hgln: 0.000000, hglt: 5.131561 },
  { isoDate: "2010-07-25T00:00:00Z", dsun: 218.416168, hgln: -0.000000, hglt: 5.216193 },
  { isoDate: "2010-07-26T00:00:00Z", dsun: 218.395536, hgln: -0.000000, hglt: 5.299400 },
  { isoDate: "2010-07-27T00:00:00Z", dsun: 218.374207, hgln: -0.000000, hglt: 5.381160 },
  { isoDate: "2010-07-28T00:00:00Z", dsun: 218.352180, hgln: 0.000000, hglt: 5.461452 },
  { isoDate: "2010-07-29T00:00:00Z", dsun: 218.329446, hgln: -0.000000, hglt: 5.540256 },
  { isoDate: "2010-07-30T00:00:00Z", dsun: 218.305987, hgln: 0.000000, hglt: 5.617551 },
  { isoDate: "2010-07-31T00:00:00Z", dsun: 218.281780, hgln: 0.000000, hglt: 5.693316 },
  { isoDate: "2010-08-01T00:00:00Z", dsun: 218.256796, hgln: -0.000000, hglt: 5.767530 },
  { isoDate: "2010-08-02T00:00:00Z", dsun: 218.230998, hgln: 0.000000, hglt: 5.840172 },
  { isoDate: "2010-08-03T00:00:00Z", dsun: 218.204346, hgln: 0.000000, hglt: 5.911220 },
  { isoDate: "2010-08-04T00:00:00Z", dsun: 218.176793, hgln: 0.000000, hglt: 5.980653 },
  { isoDate: "2010-08-05T00:00:00Z", dsun: 218.148288, hgln: 0.000000, hglt: 6.048450 },
  { isoDate: "2010-08-06T00:00:00Z", dsun: 218.118775, hgln: 0.000000, hglt: 6.114587 },
  { isoDate: "2010-08-07T00:00:00Z", dsun: 218.088197, hgln: -0.000000, hglt: 6.179044 },
  { isoDate: "2010-08-08T00:00:00Z", dsun: 218.056500, hgln: 0.000000, hglt: 6.241797 },
  { isoDate: "2010-08-09T00:00:00Z", dsun: 218.023638, hgln: 0.000000, hglt: 6.302824 },
  { isoDate: "2010-08-10T00:00:00Z", dsun: 217.989580, hgln: 0.000000, hglt: 6.362102 },
  { isoDate: "2010-08-11T00:00:00Z", dsun: 217.954318, hgln: -0.000000, hglt: 6.419609 },
  { isoDate: "2010-08-12T00:00:00Z", dsun: 217.917870, hgln: 0.000000, hglt: 6.475323 },
  { isoDate: "2010-08-13T00:00:00Z", dsun: 217.880276, hgln: -0.000000, hglt: 6.529226 },
  { isoDate: "2010-08-14T00:00:00Z", dsun: 217.841600, hgln: 0.000000, hglt: 6.581297 },
  { isoDate: "2010-08-15T00:00:00Z", dsun: 217.801918, hgln: -0.000000, hglt: 6.631521 },
  { isoDate: "2010-08-16T00:00:00Z", dsun: 217.761311, hgln: -0.000000, hglt: 6.679882 },
  { isoDate: "2010-08-17T00:00:00Z", dsun: 217.719863, hgln: 0.000000, hglt: 6.726366 },
  { isoDate: "2010-08-18T00:00:00Z", dsun: 217.677652, hgln: -0.000000, hglt: 6.770958 },
  { isoDate: "2010-08-19T00:00:00Z", dsun: 217.634748, hgln: -0.000000, hglt: 6.813648 },
  { isoDate: "2010-08-20T00:00:00Z", dsun: 217.591215, hgln: 0.000000, hglt: 6.854422 },
  { isoDate: "2010-08-21T00:00:00Z", dsun: 217.547110, hgln: -0.000000, hglt: 6.893270 },
  { isoDate: "2010-08-22T00:00:00Z", dsun: 217.502479, hgln: -0.000000, hglt: 6.930179 },
  { isoDate: "2010-08-23T00:00:00Z", dsun: 217.457361, hgln: -0.000000, hglt: 6.965141 },
  { isoDate: "2010-08-24T00:00:00Z", dsun: 217.411787, hgln: 0.000000, hglt: 6.998143 },
  { isoDate: "2010-08-25T00:00:00Z", dsun: 217.365778, hgln: 0.000000, hglt: 7.029177 },
  { isoDate: "2010-08-26T00:00:00Z", dsun: 217.319349, hgln: 0.000000, hglt: 7.058232 },
  { isoDate: "2010-08-27T00:00:00Z", dsun: 217.272504, hgln: 0.000000, hglt: 7.085297 },
  { isoDate: "2010-08-28T00:00:00Z", dsun: 217.225242, hgln: 0.000000, hglt: 7.110365 },
  { isoDate: "2010-08-29T00:00:00Z", dsun: 217.177552, hgln: -0.000000, hglt: 7.133423 },
  { isoDate: "2010-08-30T00:00:00Z", dsun: 217.129416, hgln: -0.000000, hglt: 7.154464 },
  { isoDate: "2010-08-31T00:00:00Z", dsun: 217.080808, hgln: -0.000000, hglt: 7.173478 },
  { isoDate: "2010-09-01T00:00:00Z", dsun: 217.031697, hgln: 0.000000, hglt: 7.190455 },
  { isoDate: "2010-09-02T00:00:00Z", dsun: 216.982041, hgln: -0.000000, hglt: 7.205386 },
  { isoDate: "2010-09-03T00:00:00Z", dsun: 216.931793, hgln: 0.000000, hglt: 7.218262 },
  { isoDate: "2010-09-04T00:00:00Z", dsun: 216.880900, hgln: -0.000000, hglt: 7.229073 },
  { isoDate: "2010-09-05T00:00:00Z", dsun: 216.829308, hgln: -0.000000, hglt: 7.237812 },
  { isoDate: "2010-09-06T00:00:00Z", dsun: 216.776962, hgln: -0.000000, hglt: 7.244469 },
  { isoDate: "2010-09-07T00:00:00Z", dsun: 216.723816, hgln: 0.000000, hglt: 7.249038 },
  { isoDate: "2010-09-08T00:00:00Z", dsun: 216.669843, hgln: 0.000000, hglt: 7.251510 },
  { isoDate: "2010-09-09T00:00:00Z", dsun: 216.615033, hgln: 0.000000, hglt: 7.251880 },
  { isoDate: "2010-09-10T00:00:00Z", dsun: 216.559407, hgln: 0.000000, hglt: 7.250145 },
  { isoDate: "2010-09-11T00:00:00Z", dsun: 216.503006, hgln: -0.000000, hglt: 7.246301 },
  { isoDate: "2010-09-12T00:00:00Z", dsun: 216.445896, hgln: -0.000000, hglt: 7.240347 },
  { isoDate: "2010-09-13T00:00:00Z", dsun: 216.388153, hgln: 0.000000, hglt: 7.232283 },
  { isoDate: "2010-09-14T00:00:00Z", dsun: 216.329860, hgln: 0.000000, hglt: 7.222110 },
  { isoDate: "2010-09-15T00:00:00Z", dsun: 216.271100, hgln: -0.000000, hglt: 7.209830 },
  { isoDate: "2010-09-16T00:00:00Z", dsun: 216.211952, hgln: -0.000000, hglt: 7.195447 },
  { isoDate: "2010-09-17T00:00:00Z", dsun: 216.152492, hgln: 0.000000, hglt: 7.178962 },
  { isoDate: "2010-09-18T00:00:00Z", dsun: 216.092788, hgln: 0.000000, hglt: 7.160380 },
  { isoDate: "2010-09-19T00:00:00Z", dsun: 216.032901, hgln: -0.000000, hglt: 7.139705 },
  { isoDate: "2010-09-20T00:00:00Z", dsun: 215.972886, hgln: -0.000000, hglt: 7.116941 },
  { isoDate: "2010-09-21T00:00:00Z", dsun: 215.912794, hgln: -0.000000, hglt: 7.092092 },
  { isoDate: "2010-09-22T00:00:00Z", dsun: 215.852666, hgln: 0.000000, hglt: 7.065165 },
  { isoDate: "2010-09-23T00:00:00Z", dsun: 215.792536, hgln: 0.000000, hglt: 7.036163 },
  { isoDate: "2010-09-24T00:00:00Z", dsun: 215.732431, hgln: 0.000000, hglt: 7.005094 },
  { isoDate: "2010-09-25T00:00:00Z", dsun: 215.672370, hgln: -0.000000, hglt: 6.971961 },
  { isoDate: "2010-09-26T00:00:00Z", dsun: 215.612362, hgln: 0.000000, hglt: 6.936772 },
  { isoDate: "2010-09-27T00:00:00Z", dsun: 215.552407, hgln: -0.000000, hglt: 6.899533 },
  { isoDate: "2010-09-28T00:00:00Z", dsun: 215.492497, hgln: 0.000000, hglt: 6.860249 },
  { isoDate: "2010-09-29T00:00:00Z", dsun: 215.432612, hgln: 0.000000, hglt: 6.818927 },
  { isoDate: "2010-09-30T00:00:00Z", dsun: 215.372724, hgln: 0.000000, hglt: 6.775575 },
  { isoDate: "2010-10-01T00:00:00Z", dsun: 215.312796, hgln: 0.000000, hglt: 6.730199 },
  { isoDate: "2010-10-02T00:00:00Z", dsun: 215.252782, hgln: -0.000000, hglt: 6.682808 },
  { isoDate: "2010-10-03T00:00:00Z", dsun: 215.192631, hgln: 0.000000, hglt: 6.633410 },
  { isoDate: "2010-10-04T00:00:00Z", dsun: 215.132288, hgln: 0.000000, hglt: 6.582013 },
  { isoDate: "2010-10-05T00:00:00Z", dsun: 215.071699, hgln: 0.000000, hglt: 6.528629 },
  { isoDate: "2010-10-06T00:00:00Z", dsun: 215.010819, hgln: 0.000000, hglt: 6.473269 },
  { isoDate: "2010-10-07T00:00:00Z", dsun: 214.949619, hgln: 0.000000, hglt: 6.415946 },
  { isoDate: "2010-10-08T00:00:00Z", dsun: 214.888091, hgln: 0.000000, hglt: 6.356675 },
  { isoDate: "2010-10-09T00:00:00Z", dsun: 214.826250, hgln: 0.000000, hglt: 6.295473 },
  { isoDate: "2010-10-10T00:00:00Z", dsun: 214.764134, hgln: 0.000000, hglt: 6.232357 },
  { isoDate: "2010-10-11T00:00:00Z", dsun: 214.701804, hgln: 0.000000, hglt: 6.167347 },
  { isoDate: "2010-10-12T00:00:00Z", dsun: 214.639328, hgln: -0.000000, hglt: 6.100462 },
  { isoDate: "2010-10-13T00:00:00Z", dsun: 214.576785, hgln: 0.000000, hglt: 6.031723 },
  { isoDate: "2010-10-14T00:00:00Z", dsun: 214.514254, hgln: 0.000000, hglt: 5.961149 },
  { isoDate: "2010-10-15T00:00:00Z", dsun: 214.451812, hgln: 0.000000, hglt: 5.888763 },
  { isoDate: "2010-10-16T00:00:00Z", dsun: 214.389533, hgln: -0.000000, hglt: 5.814586 },
  { isoDate: "2010-10-17T00:00:00Z", dsun: 214.327487, hgln: 0.000000, hglt: 5.738639 },
  { isoDate: "2010-10-18T00:00:00Z", dsun: 214.265739, hgln: 0.000000, hglt: 5.660943 },
  { isoDate: "2010-10-19T00:00:00Z", dsun: 214.204351, hgln: -0.000000, hglt: 5.581521 },
  { isoDate: "2010-10-20T00:00:00Z", dsun: 214.143377, hgln: 0.000000, hglt: 5.500394 },
  { isoDate: "2010-10-21T00:00:00Z", dsun: 214.082870, hgln: 0.000000, hglt: 5.417586 },
  { isoDate: "2010-10-22T00:00:00Z", dsun: 214.022873, hgln: -0.000000, hglt: 5.333117 },
  { isoDate: "2010-10-23T00:00:00Z", dsun: 213.963423, hgln: -0.000000, hglt: 5.247010 },
  { isoDate: "2010-10-24T00:00:00Z", dsun: 213.904549, hgln: 0.000000, hglt: 5.159289 },
  { isoDate: "2010-10-25T00:00:00Z", dsun: 213.846268, hgln: 0.000000, hglt: 5.069974 },
  { isoDate: "2010-10-26T00:00:00Z", dsun: 213.788588, hgln: -0.000000, hglt: 4.979089 },
  { isoDate: "2010-10-27T00:00:00Z", dsun: 213.731503, hgln: -0.000000, hglt: 4.886656 },
  { isoDate: "2010-10-28T00:00:00Z", dsun: 213.674997, hgln: -0.000000, hglt: 4.792699 },
  { isoDate: "2010-10-29T00:00:00Z", dsun: 213.619042, hgln: 0.000000, hglt: 4.697240 },
  { isoDate: "2010-10-30T00:00:00Z", dsun: 213.563597, hgln: 0.000000, hglt: 4.600304 },
  { isoDate: "2010-10-31T00:00:00Z", dsun: 213.508616, hgln: 0.000000, hglt: 4.501916 },
  { isoDate: "2010-11-01T00:00:00Z", dsun: 213.454043, hgln: 0.000000, hglt: 4.402102 },
  { isoDate: "2010-11-02T00:00:00Z", dsun: 213.399822, hgln: -0.000000, hglt: 4.300889 },
  { isoDate: "2010-11-03T00:00:00Z", dsun: 213.345898, hgln: 0.000000, hglt: 4.198305 },
  { isoDate: "2010-11-04T00:00:00Z", dsun: 213.292226, hgln: 0.000000, hglt: 4.094381 },
  { isoDate: "2010-11-05T00:00:00Z", dsun: 213.238773, hgln: 0.000000, hglt: 3.989149 },
  { isoDate: "2010-11-06T00:00:00Z", dsun: 213.185527, hgln: -0.000000, hglt: 3.882643 },
  { isoDate: "2010-11-07T00:00:00Z", dsun: 213.132495, hgln: 0.000000, hglt: 3.774897 },
  { isoDate: "2010-11-08T00:00:00Z", dsun: 213.079708, hgln: 0.000000, hglt: 3.665947 },
  { isoDate: "2010-11-09T00:00:00Z", dsun: 213.027210, hgln: 0.000000, hglt: 3.555830 },
  { isoDate: "2010-11-10T00:00:00Z", dsun: 212.975060, hgln: -0.000000, hglt: 3.444583 },
  { isoDate: "2010-11-11T00:00:00Z", dsun: 212.923323, hgln: -0.000000, hglt: 3.332241 },
  { isoDate: "2010-11-12T00:00:00Z", dsun: 212.872068, hgln: 0.000000, hglt: 3.218841 },
  { isoDate: "2010-11-13T00:00:00Z", dsun: 212.821365, hgln: -0.000000, hglt: 3.104420 },
  { isoDate: "2010-11-14T00:00:00Z", dsun: 212.771282, hgln: 0.000000, hglt: 2.989013 },
  { isoDate: "2010-11-15T00:00:00Z", dsun: 212.721885, hgln: 0.000000, hglt: 2.872658 },
  { isoDate: "2010-11-16T00:00:00Z", dsun: 212.673240, hgln: -0.000000, hglt: 2.755389 },
  { isoDate: "2010-11-17T00:00:00Z", dsun: 212.625407, hgln: -0.000000, hglt: 2.637243 },
  { isoDate: "2010-11-18T00:00:00Z", dsun: 212.578447, hgln: -0.000000, hglt: 2.518255 },
  { isoDate: "2010-11-19T00:00:00Z", dsun: 212.532413, hgln: -0.000000, hglt: 2.398461 },
  { isoDate: "2010-11-20T00:00:00Z", dsun: 212.487356, hgln: 0.000000, hglt: 2.277895 },
  { isoDate: "2010-11-21T00:00:00Z", dsun: 212.443321, hgln: -0.000000, hglt: 2.156593 },
  { isoDate: "2010-11-22T00:00:00Z", dsun: 212.400341, hgln: 0.000000, hglt: 2.034588 },
  { isoDate: "2010-11-23T00:00:00Z", dsun: 212.358440, hgln: -0.000000, hglt: 1.911916 },
  { isoDate: "2010-11-24T00:00:00Z", dsun: 212.317628, hgln: -0.000000, hglt: 1.788610 },
  { isoDate: "2010-11-25T00:00:00Z", dsun: 212.277900, hgln: 0.000000, hglt: 1.664703 },
  { isoDate: "2010-11-26T00:00:00Z", dsun: 212.239235, hgln: 0.000000, hglt: 1.540231 },
  { isoDate: "2010-11-27T00:00:00Z", dsun: 212.201598, hgln: -0.000000, hglt: 1.415228 },
  { isoDate: "2010-11-28T00:00:00Z", dsun: 212.164944, hgln: -0.000000, hglt: 1.289729 },
  { isoDate: "2010-11-29T00:00:00Z", dsun: 212.129216, hgln: -0.000000, hglt: 1.163771 },
  { isoDate: "2010-11-30T00:00:00Z", dsun: 212.094353, hgln: -0.000000, hglt: 1.037392 },
  { isoDate: "2010-12-01T00:00:00Z", dsun: 212.060292, hgln: 0.000000, hglt: 0.910631 },
  { isoDate: "2010-12-02T00:00:00Z", dsun: 212.026976, hgln: -0.000000, hglt: 0.783529 },
  { isoDate: "2010-12-03T00:00:00Z", dsun: 211.994353, hgln: 0.000000, hglt: 0.656127 },
  { isoDate: "2010-12-04T00:00:00Z", dsun: 211.962388, hgln: -0.000000, hglt: 0.528468 },
  { isoDate: "2010-12-05T00:00:00Z", dsun: 211.931059, hgln: -0.000000, hglt: 0.400597 },
  { isoDate: "2010-12-06T00:00:00Z", dsun: 211.900362, hgln: 0.000000, hglt: 0.272558 },
  { isoDate: "2010-12-07T00:00:00Z", dsun: 211.870311, hgln: -0.000000, hglt: 0.144397 },
  { isoDate: "2010-12-08T00:00:00Z", dsun: 211.840934, hgln: 0.000000, hglt: 0.016158 },
  { isoDate: "2010-12-09T00:00:00Z", dsun: 211.812269, hgln: -0.000000, hglt: -0.112114 },
  { isoDate: "2010-12-10T00:00:00Z", dsun: 211.784365, hgln: -0.000000, hglt: -0.240375 },
  { isoDate: "2010-12-11T00:00:00Z", dsun: 211.757274, hgln: 0.000000, hglt: -0.368581 },
  { isoDate: "2010-12-12T00:00:00Z", dsun: 211.731051, hgln: 0.000000, hglt: -0.496690 },
  { isoDate: "2010-12-13T00:00:00Z", dsun: 211.705754, hgln: 0.000000, hglt: -0.624659 },
  { isoDate: "2010-12-14T00:00:00Z", dsun: 211.681440, hgln: 0.000000, hglt: -0.752446 },
  { isoDate: "2010-12-15T00:00:00Z", dsun: 211.658165, hgln: -0.000000, hglt: -0.880009 },
  { isoDate: "2010-12-16T00:00:00Z", dsun: 211.635986, hgln: 0.000000, hglt: -1.007308 },
  { isoDate: "2010-12-17T00:00:00Z", dsun: 211.614961, hgln: 0.000000, hglt: -1.134303 },
  { isoDate: "2010-12-18T00:00:00Z", dsun: 211.595143, hgln: 0.000000, hglt: -1.260955 },
  { isoDate: "2010-12-19T00:00:00Z", dsun: 211.576586, hgln: 0.000000, hglt: -1.387224 },
  { isoDate: "2010-12-20T00:00:00Z", dsun: 211.559336, hgln: -0.000000, hglt: -1.513074 },
  { isoDate: "2010-12-21T00:00:00Z", dsun: 211.543430, hgln: 0.000000, hglt: -1.638466 },
  { isoDate: "2010-12-22T00:00:00Z", dsun: 211.528895, hgln: 0.000000, hglt: -1.763364 },
  { isoDate: "2010-12-23T00:00:00Z", dsun: 211.515740, hgln: 0.000000, hglt: -1.887733 },
  { isoDate: "2010-12-24T00:00:00Z", dsun: 211.503955, hgln: -0.000000, hglt: -2.011538 },
  { isoDate: "2010-12-25T00:00:00Z", dsun: 211.493513, hgln: 0.000000, hglt: -2.134741 },
  { isoDate: "2010-12-26T00:00:00Z", dsun: 211.484370, hgln: 0.000000, hglt: -2.257307 },
  { isoDate: "2010-12-27T00:00:00Z", dsun: 211.476466, hgln: 0.000000, hglt: -2.379200 },
  { isoDate: "2010-12-28T00:00:00Z", dsun: 211.469734, hgln: 0.000000, hglt: -2.500379 },
  { isoDate: "2010-12-29T00:00:00Z", dsun: 211.464103, hgln: -0.000000, hglt: -2.620808 },
  { isoDate: "2010-12-30T00:00:00Z", dsun: 211.459503, hgln: -0.000000, hglt: -2.740445 },
  { isoDate: "2010-12-31T00:00:00Z", dsun: 211.455868, hgln: 0.000000, hglt: -2.859249 },
  { isoDate: "2011-01-01T00:00:00Z", dsun: 211.453141, hgln: -0.000000, hglt: -2.977180 },
  { isoDate: "2011-01-02T00:00:00Z", dsun: 211.451277, hgln: -0.000000, hglt: -3.094195 },
  { isoDate: "2011-01-03T00:00:00Z", dsun: 211.450244, hgln: -0.000000, hglt: -3.210251 },
  { isoDate: "2011-01-04T00:00:00Z", dsun: 211.450024, hgln: 0.000000, hglt: -3.325309 },
  { isoDate: "2011-01-05T00:00:00Z", dsun: 211.450612, hgln: 0.000000, hglt: -3.439325 },
  { isoDate: "2011-01-06T00:00:00Z", dsun: 211.452016, hgln: -0.000000, hglt: -3.552259 },
  { isoDate: "2011-01-07T00:00:00Z", dsun: 211.454253, hgln: 0.000000, hglt: -3.664071 },
  { isoDate: "2011-01-08T00:00:00Z", dsun: 211.457351, hgln: -0.000000, hglt: -3.774723 },
  { isoDate: "2011-01-09T00:00:00Z", dsun: 211.461341, hgln: -0.000000, hglt: -3.884175 },
  { isoDate: "2011-01-10T00:00:00Z", dsun: 211.466261, hgln: 0.000000, hglt: -3.992391 },
  { isoDate: "2011-01-11T00:00:00Z", dsun: 211.472152, hgln: 0.000000, hglt: -4.099334 },
  { isoDate: "2011-01-12T00:00:00Z", dsun: 211.479058, hgln: -0.000000, hglt: -4.204969 },
  { isoDate: "2011-01-13T00:00:00Z", dsun: 211.487024, hgln: -0.000000, hglt: -4.309262 },
  { isoDate: "2011-01-14T00:00:00Z", dsun: 211.496098, hgln: 0.000000, hglt: -4.412181 },
  { isoDate: "2011-01-15T00:00:00Z", dsun: 211.506332, hgln: 0.000000, hglt: -4.513691 },
  { isoDate: "2011-01-16T00:00:00Z", dsun: 211.517775, hgln: 0.000000, hglt: -4.613763 },
  { isoDate: "2011-01-17T00:00:00Z", dsun: 211.530480, hgln: 0.000000, hglt: -4.712366 },
  { isoDate: "2011-01-18T00:00:00Z", dsun: 211.544493, hgln: -0.000000, hglt: -4.809472 },
  { isoDate: "2011-01-19T00:00:00Z", dsun: 211.559854, hgln: -0.000000, hglt: -4.905053 },
  { isoDate: "2011-01-20T00:00:00Z", dsun: 211.576591, hgln: -0.000000, hglt: -4.999082 },
  { isoDate: "2011-01-21T00:00:00Z", dsun: 211.594711, hgln: -0.000000, hglt: -5.091534 },
  { isoDate: "2011-01-22T00:00:00Z", dsun: 211.614202, hgln: 0.000000, hglt: -5.182382 },
  { isoDate: "2011-01-23T00:00:00Z", dsun: 211.635028, hgln: -0.000000, hglt: -5.271601 },
  { isoDate: "2011-01-24T00:00:00Z", dsun: 211.657133, hgln: -0.000000, hglt: -5.359165 },
  { isoDate: "2011-01-25T00:00:00Z", dsun: 211.680449, hgln: 0.000000, hglt: -5.445046 },
  { isoDate: "2011-01-26T00:00:00Z", dsun: 211.704895, hgln: 0.000000, hglt: -5.529218 },
  { isoDate: "2011-01-27T00:00:00Z", dsun: 211.730391, hgln: -0.000000, hglt: -5.611653 },
  { isoDate: "2011-01-28T00:00:00Z", dsun: 211.756858, hgln: -0.000000, hglt: -5.692322 },
  { isoDate: "2011-01-29T00:00:00Z", dsun: 211.784222, hgln: -0.000000, hglt: -5.771198 },
  { isoDate: "2011-01-30T00:00:00Z", dsun: 211.812420, hgln: 0.000000, hglt: -5.848252 },
  { isoDate: "2011-01-31T00:00:00Z", dsun: 211.841397, hgln: 0.000000, hglt: -5.923457 },
  { isoDate: "2011-02-01T00:00:00Z", dsun: 211.871110, hgln: 0.000000, hglt: -5.996788 },
  { isoDate: "2011-02-02T00:00:00Z", dsun: 211.901525, hgln: -0.000000, hglt: -6.068217 },
  { isoDate: "2011-02-03T00:00:00Z", dsun: 211.932621, hgln: 0.000000, hglt: -6.137720 },
  { isoDate: "2011-02-04T00:00:00Z", dsun: 211.964386, hgln: 0.000000, hglt: -6.205273 },
  { isoDate: "2011-02-05T00:00:00Z", dsun: 211.996819, hgln: -0.000000, hglt: -6.270854 },
  { isoDate: "2011-02-06T00:00:00Z", dsun: 212.029924, hgln: -0.000000, hglt: -6.334441 },
  { isoDate: "2011-02-07T00:00:00Z", dsun: 212.063716, hgln: 0.000000, hglt: -6.396014 },
  { isoDate: "2011-02-08T00:00:00Z", dsun: 212.098213, hgln: 0.000000, hglt: -6.455553 },
  { isoDate: "2011-02-09T00:00:00Z", dsun: 212.133441, hgln: -0.000000, hglt: -6.513041 },
  { isoDate: "2011-02-10T00:00:00Z", dsun: 212.169429, hgln: -0.000000, hglt: -6.568461 },
  { isoDate: "2011-02-11T00:00:00Z", dsun: 212.206211, hgln: -0.000000, hglt: -6.621798 },
  { isoDate: "2011-02-12T00:00:00Z", dsun: 212.243827, hgln: 0.000000, hglt: -6.673038 },
  { isoDate: "2011-02-13T00:00:00Z", dsun: 212.282319, hgln: 0.000000, hglt: -6.722167 },
  { isoDate: "2011-02-14T00:00:00Z", dsun: 212.321736, hgln: 0.000000, hglt: -6.769174 },
  { isoDate: "2011-02-15T00:00:00Z", dsun: 212.362125, hgln: -0.000000, hglt: -6.814048 },
  { isoDate: "2011-02-16T00:00:00Z", dsun: 212.403535, hgln: -0.000000, hglt: -6.856780 },
  { isoDate: "2011-02-17T00:00:00Z", dsun: 212.446008, hgln: -0.000000, hglt: -6.897361 },
  { isoDate: "2011-02-18T00:00:00Z", dsun: 212.489573, hgln: -0.000000, hglt: -6.935783 },
  { isoDate: "2011-02-19T00:00:00Z", dsun: 212.534240, hgln: 0.000000, hglt: -6.972040 },
  { isoDate: "2011-02-20T00:00:00Z", dsun: 212.579995, hgln: 0.000000, hglt: -7.006125 },
  { isoDate: "2011-02-21T00:00:00Z", dsun: 212.626798, hgln: -0.000000, hglt: -7.038030 },
  { isoDate: "2011-02-22T00:00:00Z", dsun: 212.674589, hgln: 0.000000, hglt: -7.067747 },
  { isoDate: "2011-02-23T00:00:00Z", dsun: 212.723289, hgln: 0.000000, hglt: -7.095269 },
  { isoDate: "2011-02-24T00:00:00Z", dsun: 212.772814, hgln: 0.000000, hglt: -7.120588 },
  { isoDate: "2011-02-25T00:00:00Z", dsun: 212.823075, hgln: 0.000000, hglt: -7.143696 },
  { isoDate: "2011-02-26T00:00:00Z", dsun: 212.873990, hgln: 0.000000, hglt: -7.164588 },
  { isoDate: "2011-02-27T00:00:00Z", dsun: 212.925478, hgln: -0.000000, hglt: -7.183255 },
  { isoDate: "2011-02-28T00:00:00Z", dsun: 212.977471, hgln: -0.000000, hglt: -7.199693 },
  { isoDate: "2011-03-01T00:00:00Z", dsun: 213.029906, hgln: -0.000000, hglt: -7.213897 },
  { isoDate: "2011-03-02T00:00:00Z", dsun: 213.082730, hgln: -0.000000, hglt: -7.225863 },
  { isoDate: "2011-03-03T00:00:00Z", dsun: 213.135898, hgln: -0.000000, hglt: -7.235590 },
  { isoDate: "2011-03-04T00:00:00Z", dsun: 213.189376, hgln: -0.000000, hglt: -7.243076 },
  { isoDate: "2011-03-05T00:00:00Z", dsun: 213.243137, hgln: -0.000000, hglt: -7.248320 },
  { isoDate: "2011-03-06T00:00:00Z", dsun: 213.297162, hgln: 0.000000, hglt: -7.251324 },
  { isoDate: "2011-03-07T00:00:00Z", dsun: 213.351441, hgln: 0.000000, hglt: -7.252090 },
  { isoDate: "2011-03-08T00:00:00Z", dsun: 213.405973, hgln: 0.000000, hglt: -7.250622 },
  { isoDate: "2011-03-09T00:00:00Z", dsun: 213.460762, hgln: 0.000000, hglt: -7.246923 },
  { isoDate: "2011-03-10T00:00:00Z", dsun: 213.515821, hgln: -0.000000, hglt: -7.241001 },
  { isoDate: "2011-03-11T00:00:00Z", dsun: 213.571169, hgln: -0.000000, hglt: -7.232861 },
  { isoDate: "2011-03-12T00:00:00Z", dsun: 213.626832, hgln: -0.000000, hglt: -7.222511 },
  { isoDate: "2011-03-13T00:00:00Z", dsun: 213.682844, hgln: 0.000000, hglt: -7.209961 },
  { isoDate: "2011-03-14T00:00:00Z", dsun: 213.739244, hgln: 0.000000, hglt: -7.195220 },
  { isoDate: "2011-03-15T00:00:00Z", dsun: 213.796078, hgln: -0.000000, hglt: -7.178299 },
  { isoDate: "2011-03-16T00:00:00Z", dsun: 213.853394, hgln: 0.000000, hglt: -7.159209 },
  { isoDate: "2011-03-17T00:00:00Z", dsun: 213.911244, hgln: -0.000000, hglt: -7.137962 },
  { isoDate: "2011-03-18T00:00:00Z", dsun: 213.969672, hgln: 0.000000, hglt: -7.114572 },
  { isoDate: "2011-03-19T00:00:00Z", dsun: 214.028713, hgln: 0.000000, hglt: -7.089050 },
  { isoDate: "2011-03-20T00:00:00Z", dsun: 214.088378, hgln: -0.000000, hglt: -7.061408 },
  { isoDate: "2011-03-21T00:00:00Z", dsun: 214.148658, hgln: 0.000000, hglt: -7.031659 },
  { isoDate: "2011-03-22T00:00:00Z", dsun: 214.209515, hgln: -0.000000, hglt: -6.999813 },
  { isoDate: "2011-03-23T00:00:00Z", dsun: 214.270889, hgln: 0.000000, hglt: -6.965883 },
  { isoDate: "2011-03-24T00:00:00Z", dsun: 214.332704, hgln: -0.000000, hglt: -6.929880 },
  { isoDate: "2011-03-25T00:00:00Z", dsun: 214.394875, hgln: -0.000000, hglt: -6.891815 },
  { isoDate: "2011-03-26T00:00:00Z", dsun: 214.457313, hgln: 0.000000, hglt: -6.851703 },
  { isoDate: "2011-03-27T00:00:00Z", dsun: 214.519936, hgln: 0.000000, hglt: -6.809555 },
  { isoDate: "2011-03-28T00:00:00Z", dsun: 214.582663, hgln: -0.000000, hglt: -6.765387 },
  { isoDate: "2011-03-29T00:00:00Z", dsun: 214.645422, hgln: 0.000000, hglt: -6.719214 },
  { isoDate: "2011-03-30T00:00:00Z", dsun: 214.708146, hgln: 0.000000, hglt: -6.671053 },
  { isoDate: "2011-03-31T00:00:00Z", dsun: 214.770778, hgln: -0.000000, hglt: -6.620920 },
  { isoDate: "2011-04-01T00:00:00Z", dsun: 214.833265, hgln: -0.000000, hglt: -6.568835 },
  { isoDate: "2011-04-02T00:00:00Z", dsun: 214.895564, hgln: 0.000000, hglt: -6.514816 },
  { isoDate: "2011-04-03T00:00:00Z", dsun: 214.957638, hgln: -0.000000, hglt: -6.458884 },
  { isoDate: "2011-04-04T00:00:00Z", dsun: 215.019457, hgln: 0.000000, hglt: -6.401061 },
  { isoDate: "2011-04-05T00:00:00Z", dsun: 215.081002, hgln: 0.000000, hglt: -6.341368 },
  { isoDate: "2011-04-06T00:00:00Z", dsun: 215.142260, hgln: 0.000000, hglt: -6.279828 },
  { isoDate: "2011-04-07T00:00:00Z", dsun: 215.203228, hgln: 0.000000, hglt: -6.216466 },
  { isoDate: "2011-04-08T00:00:00Z", dsun: 215.263911, hgln: -0.000000, hglt: -6.151305 },
  { isoDate: "2011-04-09T00:00:00Z", dsun: 215.324324, hgln: -0.000000, hglt: -6.084372 },
  { isoDate: "2011-04-10T00:00:00Z", dsun: 215.384491, hgln: 0.000000, hglt: -6.015693 },
  { isoDate: "2011-04-11T00:00:00Z", dsun: 215.444445, hgln: -0.000000, hglt: -5.945293 },
  { isoDate: "2011-04-12T00:00:00Z", dsun: 215.504225, hgln: -0.000000, hglt: -5.873200 },
  { isoDate: "2011-04-13T00:00:00Z", dsun: 215.563881, hgln: 0.000000, hglt: -5.799440 },
  { isoDate: "2011-04-14T00:00:00Z", dsun: 215.623467, hgln: 0.000000, hglt: -5.724042 },
  { isoDate: "2011-04-15T00:00:00Z", dsun: 215.683037, hgln: 0.000000, hglt: -5.647031 },
  { isoDate: "2011-04-16T00:00:00Z", dsun: 215.742641, hgln: 0.000000, hglt: -5.568433 },
  { isoDate: "2011-04-17T00:00:00Z", dsun: 215.802317, hgln: 0.000000, hglt: -5.488274 },
  { isoDate: "2011-04-18T00:00:00Z", dsun: 215.862085, hgln: -0.000000, hglt: -5.406579 },
  { isoDate: "2011-04-19T00:00:00Z", dsun: 215.921939, hgln: -0.000000, hglt: -5.323371 },
  { isoDate: "2011-04-20T00:00:00Z", dsun: 215.981851, hgln: 0.000000, hglt: -5.238674 },
  { isoDate: "2011-04-21T00:00:00Z", dsun: 216.041767, hgln: 0.000000, hglt: -5.152510 },
  { isoDate: "2011-04-22T00:00:00Z", dsun: 216.101621, hgln: 0.000000, hglt: -5.064904 },
  { isoDate: "2011-04-23T00:00:00Z", dsun: 216.161334, hgln: -0.000000, hglt: -4.975880 },
  { isoDate: "2011-04-24T00:00:00Z", dsun: 216.220828, hgln: 0.000000, hglt: -4.885464 },
  { isoDate: "2011-04-25T00:00:00Z", dsun: 216.280022, hgln: -0.000000, hglt: -4.793681 },
  { isoDate: "2011-04-26T00:00:00Z", dsun: 216.338842, hgln: -0.000000, hglt: -4.700559 },
  { isoDate: "2011-04-27T00:00:00Z", dsun: 216.397216, hgln: 0.000000, hglt: -4.606126 },
  { isoDate: "2011-04-28T00:00:00Z", dsun: 216.455080, hgln: -0.000000, hglt: -4.510410 },
  { isoDate: "2011-04-29T00:00:00Z", dsun: 216.512372, hgln: -0.000000, hglt: -4.413441 },
  { isoDate: "2011-04-30T00:00:00Z", dsun: 216.569039, hgln: -0.000000, hglt: -4.315249 },
  { isoDate: "2011-05-01T00:00:00Z", dsun: 216.625033, hgln: 0.000000, hglt: -4.215865 },
  { isoDate: "2011-05-02T00:00:00Z", dsun: 216.680309, hgln: -0.000000, hglt: -4.115320 },
  { isoDate: "2011-05-03T00:00:00Z", dsun: 216.734835, hgln: 0.000000, hglt: -4.013647 },
  { isoDate: "2011-05-04T00:00:00Z", dsun: 216.788583, hgln: -0.000000, hglt: -3.910879 },
  { isoDate: "2011-05-05T00:00:00Z", dsun: 216.841535, hgln: 0.000000, hglt: -3.807048 },
  { isoDate: "2011-05-06T00:00:00Z", dsun: 216.893686, hgln: -0.000000, hglt: -3.702190 },
  { isoDate: "2011-05-07T00:00:00Z", dsun: 216.945038, hgln: 0.000000, hglt: -3.596338 },
  { isoDate: "2011-05-08T00:00:00Z", dsun: 216.995607, hgln: 0.000000, hglt: -3.489527 },
  { isoDate: "2011-05-09T00:00:00Z", dsun: 217.045421, hgln: 0.000000, hglt: -3.381792 },
  { isoDate: "2011-05-10T00:00:00Z", dsun: 217.094517, hgln: -0.000000, hglt: -3.273167 },
  { isoDate: "2011-05-11T00:00:00Z", dsun: 217.142942, hgln: 0.000000, hglt: -3.163686 },
  { isoDate: "2011-05-12T00:00:00Z", dsun: 217.190752, hgln: -0.000000, hglt: -3.053384 },
  { isoDate: "2011-05-13T00:00:00Z", dsun: 217.238009, hgln: -0.000000, hglt: -2.942293 },
  { isoDate: "2011-05-14T00:00:00Z", dsun: 217.284771, hgln: 0.000000, hglt: -2.830445 },
  { isoDate: "2011-05-15T00:00:00Z", dsun: 217.331096, hgln: -0.000000, hglt: -2.717871 },
  { isoDate: "2011-05-16T00:00:00Z", dsun: 217.377025, hgln: 0.000000, hglt: -2.604601 },
  { isoDate: "2011-05-17T00:00:00Z", dsun: 217.422585, hgln: -0.000000, hglt: -2.490662 },
  { isoDate: "2011-05-18T00:00:00Z", dsun: 217.467779, hgln: -0.000000, hglt: -2.376083 },
  { isoDate: "2011-05-19T00:00:00Z", dsun: 217.512587, hgln: 0.000000, hglt: -2.260890 },
  { isoDate: "2011-05-20T00:00:00Z", dsun: 217.556971, hgln: -0.000000, hglt: -2.145113 },
  { isoDate: "2011-05-21T00:00:00Z", dsun: 217.600877, hgln: 0.000000, hglt: -2.028778 },
  { isoDate: "2011-05-22T00:00:00Z", dsun: 217.644241, hgln: 0.000000, hglt: -1.911915 },
  { isoDate: "2011-05-23T00:00:00Z", dsun: 217.686996, hgln: -0.000000, hglt: -1.794552 },
  { isoDate: "2011-05-24T00:00:00Z", dsun: 217.729074, hgln: -0.000000, hglt: -1.676722 },
  { isoDate: "2011-05-25T00:00:00Z", dsun: 217.770407, hgln: -0.000000, hglt: -1.558455 },
  { isoDate: "2011-05-26T00:00:00Z", dsun: 217.810930, hgln: 0.000000, hglt: -1.439783 },
  { isoDate: "2011-05-27T00:00:00Z", dsun: 217.850582, hgln: 0.000000, hglt: -1.320738 },
  { isoDate: "2011-05-28T00:00:00Z", dsun: 217.889306, hgln: 0.000000, hglt: -1.201353 },
  { isoDate: "2011-05-29T00:00:00Z", dsun: 217.927047, hgln: -0.000000, hglt: -1.081663 },
  { isoDate: "2011-05-30T00:00:00Z", dsun: 217.963756, hgln: 0.000000, hglt: -0.961701 },
  { isoDate: "2011-05-31T00:00:00Z", dsun: 217.999387, hgln: -0.000000, hglt: -0.841501 },
  { isoDate: "2011-06-01T00:00:00Z", dsun: 218.033902, hgln: -0.000000, hglt: -0.721100 },
  { isoDate: "2011-06-02T00:00:00Z", dsun: 218.067272, hgln: -0.000000, hglt: -0.600532 },
  { isoDate: "2011-06-03T00:00:00Z", dsun: 218.099477, hgln: -0.000000, hglt: -0.479834 },
  { isoDate: "2011-06-04T00:00:00Z", dsun: 218.130510, hgln: -0.000000, hglt: -0.359041 },
  { isoDate: "2011-06-05T00:00:00Z", dsun: 218.160377, hgln: 0.000000, hglt: -0.238191 },
  { isoDate: "2011-06-06T00:00:00Z", dsun: 218.189098, hgln: 0.000000, hglt: -0.117319 },
  { isoDate: "2011-06-07T00:00:00Z", dsun: 218.216709, hgln: 0.000000, hglt: 0.003538 },
  { isoDate: "2011-06-08T00:00:00Z", dsun: 218.243255, hgln: -0.000000, hglt: 0.124347 },
  { isoDate: "2011-06-09T00:00:00Z", dsun: 218.268795, hgln: 0.000000, hglt: 0.245072 },
  { isoDate: "2011-06-10T00:00:00Z", dsun: 218.293394, hgln: 0.000000, hglt: 0.365681 },
  { isoDate: "2011-06-11T00:00:00Z", dsun: 218.317121, hgln: 0.000000, hglt: 0.486141 },
  { isoDate: "2011-06-12T00:00:00Z", dsun: 218.340044, hgln: 0.000000, hglt: 0.606422 },
  { isoDate: "2011-06-13T00:00:00Z", dsun: 218.362221, hgln: -0.000000, hglt: 0.726495 },
  { isoDate: "2011-06-14T00:00:00Z", dsun: 218.383702, hgln: -0.000000, hglt: 0.846331 },
  { isoDate: "2011-06-15T00:00:00Z", dsun: 218.404517, hgln: -0.000000, hglt: 0.965904 },
  { isoDate: "2011-06-16T00:00:00Z", dsun: 218.424680, hgln: -0.000000, hglt: 1.085186 },
  { isoDate: "2011-06-17T00:00:00Z", dsun: 218.444183, hgln: 0.000000, hglt: 1.204150 },
  { isoDate: "2011-06-18T00:00:00Z", dsun: 218.463003, hgln: 0.000000, hglt: 1.322771 },
  { isoDate: "2011-06-19T00:00:00Z", dsun: 218.481103, hgln: -0.000000, hglt: 1.441019 },
  { isoDate: "2011-06-20T00:00:00Z", dsun: 218.498436, hgln: -0.000000, hglt: 1.558867 },
  { isoDate: "2011-06-21T00:00:00Z", dsun: 218.514952, hgln: 0.000000, hglt: 1.676286 },
  { isoDate: "2011-06-22T00:00:00Z", dsun: 218.530595, hgln: -0.000000, hglt: 1.793247 },
  { isoDate: "2011-06-23T00:00:00Z", dsun: 218.545309, hgln: 0.000000, hglt: 1.909720 },
  { isoDate: "2011-06-24T00:00:00Z", dsun: 218.559040, hgln: 0.000000, hglt: 2.025673 },
  { isoDate: "2011-06-25T00:00:00Z", dsun: 218.571734, hgln: 0.000000, hglt: 2.141076 },
  { isoDate: "2011-06-26T00:00:00Z", dsun: 218.583336, hgln: 0.000000, hglt: 2.255899 },
  { isoDate: "2011-06-27T00:00:00Z", dsun: 218.593796, hgln: 0.000000, hglt: 2.370108 },
  { isoDate: "2011-06-28T00:00:00Z", dsun: 218.603064, hgln: -0.000000, hglt: 2.483672 },
  { isoDate: "2011-06-29T00:00:00Z", dsun: 218.611094, hgln: -0.000000, hglt: 2.596558 },
  { isoDate: "2011-06-30T00:00:00Z", dsun: 218.617848, hgln: 0.000000, hglt: 2.708734 },
  { isoDate: "2011-07-01T00:00:00Z", dsun: 218.623292, hgln: -0.000000, hglt: 2.820165 },
  { isoDate: "2011-07-02T00:00:00Z", dsun: 218.627407, hgln: 0.000000, hglt: 2.930820 },
  { isoDate: "2011-07-03T00:00:00Z", dsun: 218.630186, hgln: 0.000000, hglt: 3.040665 },
  { isoDate: "2011-07-04T00:00:00Z", dsun: 218.631640, hgln: 0.000000, hglt: 3.149665 },
  { isoDate: "2011-07-05T00:00:00Z", dsun: 218.631796, hgln: -0.000000, hglt: 3.257790 },
  { isoDate: "2011-07-06T00:00:00Z", dsun: 218.630698, hgln: 0.000000, hglt: 3.365007 },
  { isoDate: "2011-07-07T00:00:00Z", dsun: 218.628407, hgln: 0.000000, hglt: 3.471285 },
  { isoDate: "2011-07-08T00:00:00Z", dsun: 218.624989, hgln: 0.000000, hglt: 3.576596 },
  { isoDate: "2011-07-09T00:00:00Z", dsun: 218.620522, hgln: -0.000000, hglt: 3.680912 },
  { isoDate: "2011-07-10T00:00:00Z", dsun: 218.615081, hgln: 0.000000, hglt: 3.784206 },
  { isoDate: "2011-07-11T00:00:00Z", dsun: 218.608739, hgln: 0.000000, hglt: 3.886452 },
  { isoDate: "2011-07-12T00:00:00Z", dsun: 218.601561, hgln: 0.000000, hglt: 3.987627 },
  { isoDate: "2011-07-13T00:00:00Z", dsun: 218.593598, hgln: 0.000000, hglt: 4.087706 },
  { isoDate: "2011-07-14T00:00:00Z", dsun: 218.584890, hgln: 0.000000, hglt: 4.186667 },
  { isoDate: "2011-07-15T00:00:00Z", dsun: 218.575457, hgln: -0.000000, hglt: 4.284487 },
  { isoDate: "2011-07-16T00:00:00Z", dsun: 218.565307, hgln: -0.000000, hglt: 4.381142 },
  { isoDate: "2011-07-17T00:00:00Z", dsun: 218.554432, hgln: -0.000000, hglt: 4.476610 },
  { isoDate: "2011-07-18T00:00:00Z", dsun: 218.542814, hgln: 0.000000, hglt: 4.570867 },
  { isoDate: "2011-07-19T00:00:00Z", dsun: 218.530423, hgln: 0.000000, hglt: 4.663889 },
  { isoDate: "2011-07-20T00:00:00Z", dsun: 218.517224, hgln: 0.000000, hglt: 4.755652 },
  { isoDate: "2011-07-21T00:00:00Z", dsun: 218.503180, hgln: 0.000000, hglt: 4.846131 },
  { isoDate: "2011-07-22T00:00:00Z", dsun: 218.488247, hgln: -0.000000, hglt: 4.935302 },
  { isoDate: "2011-07-23T00:00:00Z", dsun: 218.472382, hgln: 0.000000, hglt: 5.023138 },
  { isoDate: "2011-07-24T00:00:00Z", dsun: 218.455538, hgln: 0.000000, hglt: 5.109615 },
  { isoDate: "2011-07-25T00:00:00Z", dsun: 218.437670, hgln: 0.000000, hglt: 5.194706 },
  { isoDate: "2011-07-26T00:00:00Z", dsun: 218.418730, hgln: -0.000000, hglt: 5.278387 },
  { isoDate: "2011-07-27T00:00:00Z", dsun: 218.398670, hgln: -0.000000, hglt: 5.360632 },
  { isoDate: "2011-07-28T00:00:00Z", dsun: 218.377445, hgln: 0.000000, hglt: 5.441413 },
  { isoDate: "2011-07-29T00:00:00Z", dsun: 218.355013, hgln: -0.000000, hglt: 5.520706 },
  { isoDate: "2011-07-30T00:00:00Z", dsun: 218.331339, hgln: 0.000000, hglt: 5.598484 },
  { isoDate: "2011-07-31T00:00:00Z", dsun: 218.306404, hgln: 0.000000, hglt: 5.674721 },
  { isoDate: "2011-08-01T00:00:00Z", dsun: 218.280201, hgln: -0.000000, hglt: 5.749391 },
  { isoDate: "2011-08-02T00:00:00Z", dsun: 218.252745, hgln: 0.000000, hglt: 5.822471 },
  { isoDate: "2011-08-03T00:00:00Z", dsun: 218.224071, hgln: -0.000000, hglt: 5.893936 },
  { isoDate: "2011-08-04T00:00:00Z", dsun: 218.194234, hgln: 0.000000, hglt: 5.963763 },
  { isoDate: "2011-08-05T00:00:00Z", dsun: 218.163304, hgln: 0.000000, hglt: 6.031933 },
  { isoDate: "2011-08-06T00:00:00Z", dsun: 218.131360, hgln: 0.000000, hglt: 6.098425 },
  { isoDate: "2011-08-07T00:00:00Z", dsun: 218.098485, hgln: 0.000000, hglt: 6.163221 },
  { isoDate: "2011-08-08T00:00:00Z", dsun: 218.064763, hgln: -0.000000, hglt: 6.226304 },
  { isoDate: "2011-08-09T00:00:00Z", dsun: 218.030270, hgln: -0.000000, hglt: 6.287657 },
  { isoDate: "2011-08-10T00:00:00Z", dsun: 217.995074, hgln: 0.000000, hglt: 6.347265 },
  { isoDate: "2011-08-11T00:00:00Z", dsun: 217.959233, hgln: -0.000000, hglt: 6.405112 },
  { isoDate: "2011-08-12T00:00:00Z", dsun: 217.922791, hgln: -0.000000, hglt: 6.461183 },
  { isoDate: "2011-08-13T00:00:00Z", dsun: 217.885781, hgln: -0.000000, hglt: 6.515464 },
  { isoDate: "2011-08-14T00:00:00Z", dsun: 217.848222, hgln: 0.000000, hglt: 6.567939 },
  { isoDate: "2011-08-15T00:00:00Z", dsun: 217.810121, hgln: -0.000000, hglt: 6.618594 },
  { isoDate: "2011-08-16T00:00:00Z", dsun: 217.771478, hgln: 0.000000, hglt: 6.667412 },
  { isoDate: "2011-08-17T00:00:00Z", dsun: 217.732279, hgln: 0.000000, hglt: 6.714380 },
  { isoDate: "2011-08-18T00:00:00Z", dsun: 217.692508, hgln: 0.000000, hglt: 6.759482 },
  { isoDate: "2011-08-19T00:00:00Z", dsun: 217.652141, hgln: 0.000000, hglt: 6.802703 },
  { isoDate: "2011-08-20T00:00:00Z", dsun: 217.611147, hgln: -0.000000, hglt: 6.844027 },
  { isoDate: "2011-08-21T00:00:00Z", dsun: 217.569496, hgln: -0.000000, hglt: 6.883439 },
  { isoDate: "2011-08-22T00:00:00Z", dsun: 217.527150, hgln: -0.000000, hglt: 6.920925 },
  { isoDate: "2011-08-23T00:00:00Z", dsun: 217.484070, hgln: 0.000000, hglt: 6.956469 },
  { isoDate: "2011-08-24T00:00:00Z", dsun: 217.440213, hgln: -0.000000, hglt: 6.990057 },
  { isoDate: "2011-08-25T00:00:00Z", dsun: 217.395532, hgln: 0.000000, hglt: 7.021675 },
  { isoDate: "2011-08-26T00:00:00Z", dsun: 217.349982, hgln: 0.000000, hglt: 7.051308 },
  { isoDate: "2011-08-27T00:00:00Z", dsun: 217.303520, hgln: -0.000000, hglt: 7.078943 },
  { isoDate: "2011-08-28T00:00:00Z", dsun: 217.256108, hgln: -0.000000, hglt: 7.104566 },
  { isoDate: "2011-08-29T00:00:00Z", dsun: 217.207722, hgln: 0.000000, hglt: 7.128165 },
  { isoDate: "2011-08-30T00:00:00Z", dsun: 217.158357, hgln: -0.000000, hglt: 7.149729 },
  { isoDate: "2011-08-31T00:00:00Z", dsun: 217.108031, hgln: -0.000000, hglt: 7.169247 },
  { isoDate: "2011-09-01T00:00:00Z", dsun: 217.056783, hgln: -0.000000, hglt: 7.186712 },
  { isoDate: "2011-09-02T00:00:00Z", dsun: 217.004677, hgln: -0.000000, hglt: 7.202116 },
  { isoDate: "2011-09-03T00:00:00Z", dsun: 216.951790, hgln: 0.000000, hglt: 7.215453 },
  { isoDate: "2011-09-04T00:00:00Z", dsun: 216.898207, hgln: -0.000000, hglt: 7.226719 },
  { isoDate: "2011-09-05T00:00:00Z", dsun: 216.844019, hgln: 0.000000, hglt: 7.235910 },
  { isoDate: "2011-09-06T00:00:00Z", dsun: 216.789310, hgln: -0.000000, hglt: 7.243022 },
  { isoDate: "2011-09-07T00:00:00Z", dsun: 216.734161, hgln: -0.000000, hglt: 7.248054 },
  { isoDate: "2011-09-08T00:00:00Z", dsun: 216.678643, hgln: -0.000000, hglt: 7.251002 },
  { isoDate: "2011-09-09T00:00:00Z", dsun: 216.622818, hgln: 0.000000, hglt: 7.251866 },
  { isoDate: "2011-09-10T00:00:00Z", dsun: 216.566738, hgln: -0.000000, hglt: 7.250642 },
  { isoDate: "2011-09-11T00:00:00Z", dsun: 216.510443, hgln: -0.000000, hglt: 7.247330 },
  { isoDate: "2011-09-12T00:00:00Z", dsun: 216.453966, hgln: 0.000000, hglt: 7.241927 },
  { isoDate: "2011-09-13T00:00:00Z", dsun: 216.397327, hgln: -0.000000, hglt: 7.234433 },
  { isoDate: "2011-09-14T00:00:00Z", dsun: 216.340538, hgln: -0.000000, hglt: 7.224846 },
  { isoDate: "2011-09-15T00:00:00Z", dsun: 216.283603, hgln: 0.000000, hglt: 7.213164 },
  { isoDate: "2011-09-16T00:00:00Z", dsun: 216.226519, hgln: -0.000000, hglt: 7.199387 },
  { isoDate: "2011-09-17T00:00:00Z", dsun: 216.169274, hgln: 0.000000, hglt: 7.183515 },
  { isoDate: "2011-09-18T00:00:00Z", dsun: 216.111852, hgln: 0.000000, hglt: 7.165547 },
  { isoDate: "2011-09-19T00:00:00Z", dsun: 216.054229, hgln: 0.000000, hglt: 7.145483 },
  { isoDate: "2011-09-20T00:00:00Z", dsun: 215.996376, hgln: -0.000000, hglt: 7.123324 },
  { isoDate: "2011-09-21T00:00:00Z", dsun: 215.938259, hgln: 0.000000, hglt: 7.099071 },
  { isoDate: "2011-09-22T00:00:00Z", dsun: 215.879839, hgln: 0.000000, hglt: 7.072725 },
  { isoDate: "2011-09-23T00:00:00Z", dsun: 215.821069, hgln: -0.000000, hglt: 7.044289 },
  { isoDate: "2011-09-24T00:00:00Z", dsun: 215.761903, hgln: 0.000000, hglt: 7.013765 },
  { isoDate: "2011-09-25T00:00:00Z", dsun: 215.702294, hgln: -0.000000, hglt: 6.981157 },
  { isoDate: "2011-09-26T00:00:00Z", dsun: 215.642201, hgln: 0.000000, hglt: 6.946472 },
  { isoDate: "2011-09-27T00:00:00Z", dsun: 215.581596, hgln: -0.000000, hglt: 6.909715 },
  { isoDate: "2011-09-28T00:00:00Z", dsun: 215.520470, hgln: -0.000000, hglt: 6.870895 },
  { isoDate: "2011-09-29T00:00:00Z", dsun: 215.458840, hgln: 0.000000, hglt: 6.830021 },
  { isoDate: "2011-09-30T00:00:00Z", dsun: 215.396747, hgln: -0.000000, hglt: 6.787106 },
  { isoDate: "2011-10-01T00:00:00Z", dsun: 215.334255, hgln: -0.000000, hglt: 6.742161 },
  { isoDate: "2011-10-02T00:00:00Z", dsun: 215.271444, hgln: 0.000000, hglt: 6.695201 },
  { isoDate: "2011-10-03T00:00:00Z", dsun: 215.208402, hgln: 0.000000, hglt: 6.646240 },
  { isoDate: "2011-10-04T00:00:00Z", dsun: 215.145218, hgln: 0.000000, hglt: 6.595291 },
  { isoDate: "2011-10-05T00:00:00Z", dsun: 215.081978, hgln: -0.000000, hglt: 6.542369 },
  { isoDate: "2011-10-06T00:00:00Z", dsun: 215.018763, hgln: 0.000000, hglt: 6.487490 },
  { isoDate: "2011-10-07T00:00:00Z", dsun: 214.955645, hgln: 0.000000, hglt: 6.430667 },
  { isoDate: "2011-10-08T00:00:00Z", dsun: 214.892689, hgln: -0.000000, hglt: 6.371916 },
  { isoDate: "2011-10-09T00:00:00Z", dsun: 214.829953, hgln: -0.000000, hglt: 6.311251 },
  { isoDate: "2011-10-10T00:00:00Z", dsun: 214.767484, hgln: -0.000000, hglt: 6.248688 },
  { isoDate: "2011-10-11T00:00:00Z", dsun: 214.705322, hgln: -0.000000, hglt: 6.184242 },
  { isoDate: "2011-10-12T00:00:00Z", dsun: 214.643498, hgln: 0.000000, hglt: 6.117928 },
  { isoDate: "2011-10-13T00:00:00Z", dsun: 214.582037, hgln: -0.000000, hglt: 6.049762 },
  { isoDate: "2011-10-14T00:00:00Z", dsun: 214.520952, hgln: -0.000000, hglt: 5.979760 },
  { isoDate: "2011-10-15T00:00:00Z", dsun: 214.460251, hgln: 0.000000, hglt: 5.907938 },
  { isoDate: "2011-10-16T00:00:00Z", dsun: 214.399931, hgln: -0.000000, hglt: 5.834312 },
  { isoDate: "2011-10-17T00:00:00Z", dsun: 214.339984, hgln: 0.000000, hglt: 5.758900 },
  { isoDate: "2011-10-18T00:00:00Z", dsun: 214.280391, hgln: 0.000000, hglt: 5.681720 },
  { isoDate: "2011-10-19T00:00:00Z", dsun: 214.221129, hgln: -0.000000, hglt: 5.602789 },
  { isoDate: "2011-10-20T00:00:00Z", dsun: 214.162164, hgln: 0.000000, hglt: 5.522126 },
  { isoDate: "2011-10-21T00:00:00Z", dsun: 214.103456, hgln: -0.000000, hglt: 5.439750 },
  { isoDate: "2011-10-22T00:00:00Z", dsun: 214.044958, hgln: 0.000000, hglt: 5.355684 },
  { isoDate: "2011-10-23T00:00:00Z", dsun: 213.986619, hgln: 0.000000, hglt: 5.269947 },
  { isoDate: "2011-10-24T00:00:00Z", dsun: 213.928387, hgln: 0.000000, hglt: 5.182564 },
  { isoDate: "2011-10-25T00:00:00Z", dsun: 213.870217, hgln: -0.000000, hglt: 5.093559 },
  { isoDate: "2011-10-26T00:00:00Z", dsun: 213.812072, hgln: 0.000000, hglt: 5.002960 },
  { isoDate: "2011-10-27T00:00:00Z", dsun: 213.753939, hgln: 0.000000, hglt: 4.910794 },
  { isoDate: "2011-10-28T00:00:00Z", dsun: 213.695828, hgln: -0.000000, hglt: 4.817091 },
  { isoDate: "2011-10-29T00:00:00Z", dsun: 213.637775, hgln: 0.000000, hglt: 4.721884 },
  { isoDate: "2011-10-30T00:00:00Z", dsun: 213.579838, hgln: -0.000000, hglt: 4.625203 },
  { isoDate: "2011-10-31T00:00:00Z", dsun: 213.522090, hgln: 0.000000, hglt: 4.527081 },
  { isoDate: "2011-11-01T00:00:00Z", dsun: 213.464614, hgln: 0.000000, hglt: 4.427549 },
  { isoDate: "2011-11-02T00:00:00Z", dsun: 213.407492, hgln: -0.000000, hglt: 4.326639 },
  { isoDate: "2011-11-03T00:00:00Z", dsun: 213.350808, hgln: 0.000000, hglt: 4.224382 },
  { isoDate: "2011-11-04T00:00:00Z", dsun: 213.294638, hgln: 0.000000, hglt: 4.120809 },
  { isoDate: "2011-11-05T00:00:00Z", dsun: 213.239052, hgln: -0.000000, hglt: 4.015950 },
  { isoDate: "2011-11-06T00:00:00Z", dsun: 213.184116, hgln: 0.000000, hglt: 3.909836 },
  { isoDate: "2011-11-07T00:00:00Z", dsun: 213.129887, hgln: -0.000000, hglt: 3.802498 },
  { isoDate: "2011-11-08T00:00:00Z", dsun: 213.076417, hgln: -0.000000, hglt: 3.693965 },
  { isoDate: "2011-11-09T00:00:00Z", dsun: 213.023751, hgln: -0.000000, hglt: 3.584269 },
  { isoDate: "2011-11-10T00:00:00Z", dsun: 212.971926, hgln: -0.000000, hglt: 3.473440 },
  { isoDate: "2011-11-11T00:00:00Z", dsun: 212.920973, hgln: 0.000000, hglt: 3.361508 },
  { isoDate: "2011-11-12T00:00:00Z", dsun: 212.870911, hgln: -0.000000, hglt: 3.248503 },
  { isoDate: "2011-11-13T00:00:00Z", dsun: 212.821755, hgln: 0.000000, hglt: 3.134458 },
  { isoDate: "2011-11-14T00:00:00Z", dsun: 212.773507, hgln: 0.000000, hglt: 3.019401 },
  { isoDate: "2011-11-15T00:00:00Z", dsun: 212.726160, hgln: 0.000000, hglt: 2.903366 },
  { isoDate: "2011-11-16T00:00:00Z", dsun: 212.679696, hgln: -0.000000, hglt: 2.786383 },
  { isoDate: "2011-11-17T00:00:00Z", dsun: 212.634090, hgln: -0.000000, hglt: 2.668485 },
  { isoDate: "2011-11-18T00:00:00Z", dsun: 212.589305, hgln: 0.000000, hglt: 2.549705 },
  { isoDate: "2011-11-19T00:00:00Z", dsun: 212.545296, hgln: 0.000000, hglt: 2.430077 },
  { isoDate: "2011-11-20T00:00:00Z", dsun: 212.502010, hgln: 0.000000, hglt: 2.309636 },
  { isoDate: "2011-11-21T00:00:00Z", dsun: 212.459388, hgln: 0.000000, hglt: 2.188418 },
  { isoDate: "2011-11-22T00:00:00Z", dsun: 212.417372, hgln: -0.000000, hglt: 2.066462 },
  { isoDate: "2011-11-23T00:00:00Z", dsun: 212.375908, hgln: 0.000000, hglt: 1.943807 },
  { isoDate: "2011-11-24T00:00:00Z", dsun: 212.334954, hgln: 0.000000, hglt: 1.820494 },
  { isoDate: "2011-11-25T00:00:00Z", dsun: 212.294486, hgln: 0.000000, hglt: 1.696565 },
  { isoDate: "2011-11-26T00:00:00Z", dsun: 212.254502, hgln: -0.000000, hglt: 1.572066 },
  { isoDate: "2011-11-27T00:00:00Z", dsun: 212.215027, hgln: 0.000000, hglt: 1.447039 },
  { isoDate: "2011-11-28T00:00:00Z", dsun: 212.176103, hgln: 0.000000, hglt: 1.321528 },
  { isoDate: "2011-11-29T00:00:00Z", dsun: 212.137791, hgln: -0.000000, hglt: 1.195577 },
  { isoDate: "2011-11-30T00:00:00Z", dsun: 212.100158, hgln: 0.000000, hglt: 1.069228 },
  { isoDate: "2011-12-01T00:00:00Z", dsun: 212.063276, hgln: -0.000000, hglt: 0.942524 },
  { isoDate: "2011-12-02T00:00:00Z", dsun: 212.027216, hgln: 0.000000, hglt: 0.815504 },
  { isoDate: "2011-12-03T00:00:00Z", dsun: 211.992046, hgln: 0.000000, hglt: 0.688210 },
  { isoDate: "2011-12-04T00:00:00Z", dsun: 211.957830, hgln: 0.000000, hglt: 0.560682 },
  { isoDate: "2011-12-05T00:00:00Z", dsun: 211.924628, hgln: -0.000000, hglt: 0.432958 },
  { isoDate: "2011-12-06T00:00:00Z", dsun: 211.892495, hgln: -0.000000, hglt: 0.305077 },
  { isoDate: "2011-12-07T00:00:00Z", dsun: 211.861483, hgln: -0.000000, hglt: 0.177079 },
  { isoDate: "2011-12-08T00:00:00Z", dsun: 211.831635, hgln: -0.000000, hglt: 0.049002 },
  { isoDate: "2011-12-09T00:00:00Z", dsun: 211.802992, hgln: -0.000000, hglt: -0.079117 },
  { isoDate: "2011-12-10T00:00:00Z", dsun: 211.775586, hgln: 0.000000, hglt: -0.207239 },
  { isoDate: "2011-12-11T00:00:00Z", dsun: 211.749441, hgln: 0.000000, hglt: -0.335328 },
  { isoDate: "2011-12-12T00:00:00Z", dsun: 211.724572, hgln: 0.000000, hglt: -0.463347 },
  { isoDate: "2011-12-13T00:00:00Z", dsun: 211.700981, hgln: 0.000000, hglt: -0.591257 },
  { isoDate: "2011-12-14T00:00:00Z", dsun: 211.678660, hgln: 0.000000, hglt: -0.719023 },
  { isoDate: "2011-12-15T00:00:00Z", dsun: 211.657587, hgln: -0.000000, hglt: -0.846605 },
  { isoDate: "2011-12-16T00:00:00Z", dsun: 211.637728, hgln: 0.000000, hglt: -0.973967 },
  { isoDate: "2011-12-17T00:00:00Z", dsun: 211.619039, hgln: 0.000000, hglt: -1.101070 },
  { isoDate: "2011-12-18T00:00:00Z", dsun: 211.601464, hgln: 0.000000, hglt: -1.227873 },
  { isoDate: "2011-12-19T00:00:00Z", dsun: 211.584939, hgln: 0.000000, hglt: -1.354338 },
  { isoDate: "2011-12-20T00:00:00Z", dsun: 211.569398, hgln: -0.000000, hglt: -1.480421 },
  { isoDate: "2011-12-21T00:00:00Z", dsun: 211.554772, hgln: 0.000000, hglt: -1.606081 },
  { isoDate: "2011-12-22T00:00:00Z", dsun: 211.540999, hgln: 0.000000, hglt: -1.731274 },
  { isoDate: "2011-12-23T00:00:00Z", dsun: 211.528028, hgln: 0.000000, hglt: -1.855955 },
  { isoDate: "2011-12-24T00:00:00Z", dsun: 211.515823, hgln: 0.000000, hglt: -1.980079 },
  { isoDate: "2011-12-25T00:00:00Z", dsun: 211.504371, hgln: 0.000000, hglt: -2.103601 },
  { isoDate: "2011-12-26T00:00:00Z", dsun: 211.493677, hgln: 0.000000, hglt: -2.226475 },
  { isoDate: "2011-12-27T00:00:00Z", dsun: 211.483765, hgln: 0.000000, hglt: -2.348657 },
  { isoDate: "2011-12-28T00:00:00Z", dsun: 211.474673, hgln: 0.000000, hglt: -2.470105 },
  { isoDate: "2011-12-29T00:00:00Z", dsun: 211.466451, hgln: 0.000000, hglt: -2.590777 },
  { isoDate: "2011-12-30T00:00:00Z", dsun: 211.459150, hgln: 0.000000, hglt: -2.710631 },
  { isoDate: "2011-12-31T00:00:00Z", dsun: 211.452826, hgln: 0.000000, hglt: -2.829629 },
  { isoDate: "2012-01-01T00:00:00Z", dsun: 211.447534, hgln: -0.000000, hglt: -2.947731 },
  { isoDate: "2012-01-02T00:00:00Z", dsun: 211.443326, hgln: 0.000000, hglt: -3.064900 },
  { isoDate: "2012-01-03T00:00:00Z", dsun: 211.440254, hgln: -0.000000, hglt: -3.181099 },
  { isoDate: "2012-01-04T00:00:00Z", dsun: 211.438366, hgln: 0.000000, hglt: -3.296291 },
  { isoDate: "2012-01-05T00:00:00Z", dsun: 211.437708, hgln: 0.000000, hglt: -3.410442 },
  { isoDate: "2012-01-06T00:00:00Z", dsun: 211.438324, hgln: 0.000000, hglt: -3.523515 },
  { isoDate: "2012-01-07T00:00:00Z", dsun: 211.440251, hgln: 0.000000, hglt: -3.635479 },
  { isoDate: "2012-01-08T00:00:00Z", dsun: 211.443523, hgln: 0.000000, hglt: -3.746298 },
  { isoDate: "2012-01-09T00:00:00Z", dsun: 211.448165, hgln: 0.000000, hglt: -3.855941 },
  { isoDate: "2012-01-10T00:00:00Z", dsun: 211.454191, hgln: 0.000000, hglt: -3.964375 },
  { isoDate: "2012-01-11T00:00:00Z", dsun: 211.461604, hgln: 0.000000, hglt: -4.071569 },
  { isoDate: "2012-01-12T00:00:00Z", dsun: 211.470390, hgln: 0.000000, hglt: -4.177491 },
  { isoDate: "2012-01-13T00:00:00Z", dsun: 211.480521, hgln: 0.000000, hglt: -4.282109 },
  { isoDate: "2012-01-14T00:00:00Z", dsun: 211.491954, hgln: 0.000000, hglt: -4.385391 },
  { isoDate: "2012-01-15T00:00:00Z", dsun: 211.504631, hgln: 0.000000, hglt: -4.487305 },
  { isoDate: "2012-01-16T00:00:00Z", dsun: 211.518485, hgln: 0.000000, hglt: -4.587817 },
  { isoDate: "2012-01-17T00:00:00Z", dsun: 211.533439, hgln: -0.000000, hglt: -4.686895 },
  { isoDate: "2012-01-18T00:00:00Z", dsun: 211.549416, hgln: 0.000000, hglt: -4.784504 },
  { isoDate: "2012-01-19T00:00:00Z", dsun: 211.566338, hgln: 0.000000, hglt: -4.880610 },
  { isoDate: "2012-01-20T00:00:00Z", dsun: 211.584136, hgln: 0.000000, hglt: -4.975178 },
  { isoDate: "2012-01-21T00:00:00Z", dsun: 211.602750, hgln: 0.000000, hglt: -5.068174 },
  { isoDate: "2012-01-22T00:00:00Z", dsun: 211.622134, hgln: 0.000000, hglt: -5.159564 },
  { isoDate: "2012-01-23T00:00:00Z", dsun: 211.642259, hgln: -0.000000, hglt: -5.249316 },
  { isoDate: "2012-01-24T00:00:00Z", dsun: 211.663113, hgln: -0.000000, hglt: -5.337396 },
  { isoDate: "2012-01-25T00:00:00Z", dsun: 211.684700, hgln: -0.000000, hglt: -5.423775 },
  { isoDate: "2012-01-26T00:00:00Z", dsun: 211.707035, hgln: 0.000000, hglt: -5.508422 },
  { isoDate: "2012-01-27T00:00:00Z", dsun: 211.730144, hgln: -0.000000, hglt: -5.591310 },
  { isoDate: "2012-01-28T00:00:00Z", dsun: 211.754061, hgln: 0.000000, hglt: -5.672412 },
  { isoDate: "2012-01-29T00:00:00Z", dsun: 211.778822, hgln: 0.000000, hglt: -5.751702 },
  { isoDate: "2012-01-30T00:00:00Z", dsun: 211.804466, hgln: 0.000000, hglt: -5.829157 },
  { isoDate: "2012-01-31T00:00:00Z", dsun: 211.831032, hgln: 0.000000, hglt: -5.904752 },
  { isoDate: "2012-02-01T00:00:00Z", dsun: 211.858561, hgln: 0.000000, hglt: -5.978466 },
  { isoDate: "2012-02-02T00:00:00Z", dsun: 211.887092, hgln: -0.000000, hglt: -6.050279 },
  { isoDate: "2012-02-03T00:00:00Z", dsun: 211.916668, hgln: -0.000000, hglt: -6.120169 },
  { isoDate: "2012-02-04T00:00:00Z", dsun: 211.947326, hgln: -0.000000, hglt: -6.188117 },
  { isoDate: "2012-02-05T00:00:00Z", dsun: 211.979106, hgln: 0.000000, hglt: -6.254106 },
  { isoDate: "2012-02-06T00:00:00Z", dsun: 212.012041, hgln: -0.000000, hglt: -6.318119 },
  { isoDate: "2012-02-07T00:00:00Z", dsun: 212.046160, hgln: -0.000000, hglt: -6.380137 },
  { isoDate: "2012-02-08T00:00:00Z", dsun: 212.081477, hgln: 0.000000, hglt: -6.440147 },
  { isoDate: "2012-02-09T00:00:00Z", dsun: 212.117997, hgln: 0.000000, hglt: -6.498131 },
  { isoDate: "2012-02-10T00:00:00Z", dsun: 212.155704, hgln: -0.000000, hglt: -6.554076 },
  { isoDate: "2012-02-11T00:00:00Z", dsun: 212.194564, hgln: 0.000000, hglt: -6.607964 },
  { isoDate: "2012-02-12T00:00:00Z", dsun: 212.234524, hgln: -0.000000, hglt: -6.659782 },
  { isoDate: "2012-02-13T00:00:00Z", dsun: 212.275516, hgln: 0.000000, hglt: -6.709512 },
  { isoDate: "2012-02-14T00:00:00Z", dsun: 212.317460, hgln: -0.000000, hglt: -6.757141 },
  { isoDate: "2012-02-15T00:00:00Z", dsun: 212.360271, hgln: -0.000000, hglt: -6.802651 },
  { isoDate: "2012-02-16T00:00:00Z", dsun: 212.403862, hgln: 0.000000, hglt: -6.846028 },
  { isoDate: "2012-02-17T00:00:00Z", dsun: 212.448150, hgln: 0.000000, hglt: -6.887257 },
  { isoDate: "2012-02-18T00:00:00Z", dsun: 212.493058, hgln: -0.000000, hglt: -6.926324 },
  { isoDate: "2012-02-19T00:00:00Z", dsun: 212.538520, hgln: -0.000000, hglt: -6.963216 },
  { isoDate: "2012-02-20T00:00:00Z", dsun: 212.584482, hgln: -0.000000, hglt: -6.997921 },
  { isoDate: "2012-02-21T00:00:00Z", dsun: 212.630904, hgln: 0.000000, hglt: -7.030428 },
  { isoDate: "2012-02-22T00:00:00Z", dsun: 212.677758, hgln: 0.000000, hglt: -7.060729 },
  { isoDate: "2012-02-23T00:00:00Z", dsun: 212.725032, hgln: -0.000000, hglt: -7.088814 },
  { isoDate: "2012-02-24T00:00:00Z", dsun: 212.772721, hgln: -0.000000, hglt: -7.114678 },
  { isoDate: "2012-02-25T00:00:00Z", dsun: 212.820834, hgln: 0.000000, hglt: -7.138316 },
  { isoDate: "2012-02-26T00:00:00Z", dsun: 212.869383, hgln: 0.000000, hglt: -7.159723 },
  { isoDate: "2012-02-27T00:00:00Z", dsun: 212.918390, hgln: 0.000000, hglt: -7.178896 },
  { isoDate: "2012-02-28T00:00:00Z", dsun: 212.967878, hgln: -0.000000, hglt: -7.195834 },
  { isoDate: "2012-02-29T00:00:00Z", dsun: 213.017876, hgln: 0.000000, hglt: -7.210536 },
  { isoDate: "2012-03-01T00:00:00Z", dsun: 213.068415, hgln: 0.000000, hglt: -7.223003 },
  { isoDate: "2012-03-02T00:00:00Z", dsun: 213.119528, hgln: -0.000000, hglt: -7.233235 },
  { isoDate: "2012-03-03T00:00:00Z", dsun: 213.171251, hgln: -0.000000, hglt: -7.241235 },
  { isoDate: "2012-03-04T00:00:00Z", dsun: 213.223624, hgln: -0.000000, hglt: -7.247006 },
  { isoDate: "2012-03-05T00:00:00Z", dsun: 213.276686, hgln: 0.000000, hglt: -7.250550 },
  { isoDate: "2012-03-06T00:00:00Z", dsun: 213.330474, hgln: -0.000000, hglt: -7.251874 },
  { isoDate: "2012-03-07T00:00:00Z", dsun: 213.385021, hgln: -0.000000, hglt: -7.250980 },
  { isoDate: "2012-03-08T00:00:00Z", dsun: 213.440351, hgln: 0.000000, hglt: -7.247874 },
  { isoDate: "2012-03-09T00:00:00Z", dsun: 213.496470, hgln: -0.000000, hglt: -7.242561 },
  { isoDate: "2012-03-10T00:00:00Z", dsun: 213.553365, hgln: 0.000000, hglt: -7.235045 },
  { isoDate: "2012-03-11T00:00:00Z", dsun: 213.611000, hgln: -0.000000, hglt: -7.225332 },
  { isoDate: "2012-03-12T00:00:00Z", dsun: 213.669320, hgln: -0.000000, hglt: -7.213425 },
  { isoDate: "2012-03-13T00:00:00Z", dsun: 213.728248, hgln: -0.000000, hglt: -7.199330 },
  { isoDate: "2012-03-14T00:00:00Z", dsun: 213.787700, hgln: -0.000000, hglt: -7.183052 },
  { isoDate: "2012-03-15T00:00:00Z", dsun: 213.847583, hgln: -0.000000, hglt: -7.164596 },
  { isoDate: "2012-03-16T00:00:00Z", dsun: 213.907808, hgln: -0.000000, hglt: -7.143970 },
  { isoDate: "2012-03-17T00:00:00Z", dsun: 213.968288, hgln: -0.000000, hglt: -7.121180 },
  { isoDate: "2012-03-18T00:00:00Z", dsun: 214.028944, hgln: 0.000000, hglt: -7.096237 },
  { isoDate: "2012-03-19T00:00:00Z", dsun: 214.089708, hgln: -0.000000, hglt: -7.069150 },
  { isoDate: "2012-03-20T00:00:00Z", dsun: 214.150522, hgln: -0.000000, hglt: -7.039931 },
  { isoDate: "2012-03-21T00:00:00Z", dsun: 214.211337, hgln: 0.000000, hglt: -7.008593 },
  { isoDate: "2012-03-22T00:00:00Z", dsun: 214.272117, hgln: 0.000000, hglt: -6.975149 },
  { isoDate: "2012-03-23T00:00:00Z", dsun: 214.332838, hgln: 0.000000, hglt: -6.939614 },
  { isoDate: "2012-03-24T00:00:00Z", dsun: 214.393482, hgln: -0.000000, hglt: -6.902005 },
  { isoDate: "2012-03-25T00:00:00Z", dsun: 214.454043, hgln: 0.000000, hglt: -6.862337 },
  { isoDate: "2012-03-26T00:00:00Z", dsun: 214.514523, hgln: -0.000000, hglt: -6.820630 },
  { isoDate: "2012-03-27T00:00:00Z", dsun: 214.574930, hgln: 0.000000, hglt: -6.776902 },
  { isoDate: "2012-03-28T00:00:00Z", dsun: 214.635278, hgln: 0.000000, hglt: -6.731171 },
  { isoDate: "2012-03-29T00:00:00Z", dsun: 214.695589, hgln: -0.000000, hglt: -6.683459 },
  { isoDate: "2012-03-30T00:00:00Z", dsun: 214.755887, hgln: 0.000000, hglt: -6.633785 },
  { isoDate: "2012-03-31T00:00:00Z", dsun: 214.816204, hgln: -0.000000, hglt: -6.582171 },
  { isoDate: "2012-04-01T00:00:00Z", dsun: 214.876575, hgln: 0.000000, hglt: -6.528638 },
  { isoDate: "2012-04-02T00:00:00Z", dsun: 214.937041, hgln: 0.000000, hglt: -6.473208 },
  { isoDate: "2012-04-03T00:00:00Z", dsun: 214.997647, hgln: -0.000000, hglt: -6.415902 },
  { isoDate: "2012-04-04T00:00:00Z", dsun: 215.058436, hgln: -0.000000, hglt: -6.356743 },
  { isoDate: "2012-04-05T00:00:00Z", dsun: 215.119451, hgln: 0.000000, hglt: -6.295752 },
  { isoDate: "2012-04-06T00:00:00Z", dsun: 215.180723, hgln: 0.000000, hglt: -6.232951 },
  { isoDate: "2012-04-07T00:00:00Z", dsun: 215.242267, hgln: -0.000000, hglt: -6.168358 },
  { isoDate: "2012-04-08T00:00:00Z", dsun: 215.304078, hgln: 0.000000, hglt: -6.101995 },
  { isoDate: "2012-04-09T00:00:00Z", dsun: 215.366124, hgln: 0.000000, hglt: -6.033880 },
  { isoDate: "2012-04-10T00:00:00Z", dsun: 215.428349, hgln: 0.000000, hglt: -5.964033 },
  { isoDate: "2012-04-11T00:00:00Z", dsun: 215.490680, hgln: 0.000000, hglt: -5.892475 },
  { isoDate: "2012-04-12T00:00:00Z", dsun: 215.553031, hgln: 0.000000, hglt: -5.819226 },
  { isoDate: "2012-04-13T00:00:00Z", dsun: 215.615310, hgln: 0.000000, hglt: -5.744307 },
  { isoDate: "2012-04-14T00:00:00Z", dsun: 215.677431, hgln: 0.000000, hglt: -5.667743 },
  { isoDate: "2012-04-15T00:00:00Z", dsun: 215.739308, hgln: -0.000000, hglt: -5.589556 },
  { isoDate: "2012-04-16T00:00:00Z", dsun: 215.800865, hgln: 0.000000, hglt: -5.509773 },
  { isoDate: "2012-04-17T00:00:00Z", dsun: 215.862035, hgln: -0.000000, hglt: -5.428420 },
  { isoDate: "2012-04-18T00:00:00Z", dsun: 215.922757, hgln: -0.000000, hglt: -5.345524 },
  { isoDate: "2012-04-19T00:00:00Z", dsun: 215.982983, hgln: 0.000000, hglt: -5.261114 },
  { isoDate: "2012-04-20T00:00:00Z", dsun: 216.042672, hgln: 0.000000, hglt: -5.175217 },
  { isoDate: "2012-04-21T00:00:00Z", dsun: 216.101790, hgln: -0.000000, hglt: -5.087865 },
  { isoDate: "2012-04-22T00:00:00Z", dsun: 216.160317, hgln: 0.000000, hglt: -4.999087 },
  { isoDate: "2012-04-23T00:00:00Z", dsun: 216.218237, hgln: -0.000000, hglt: -4.908914 },
  { isoDate: "2012-04-24T00:00:00Z", dsun: 216.275545, hgln: 0.000000, hglt: -4.817376 },
  { isoDate: "2012-04-25T00:00:00Z", dsun: 216.332243, hgln: -0.000000, hglt: -4.724507 },
  { isoDate: "2012-04-26T00:00:00Z", dsun: 216.388342, hgln: 0.000000, hglt: -4.630337 },
  { isoDate: "2012-04-27T00:00:00Z", dsun: 216.443858, hgln: 0.000000, hglt: -4.534899 },
  { isoDate: "2012-04-28T00:00:00Z", dsun: 216.498817, hgln: -0.000000, hglt: -4.438225 },
  { isoDate: "2012-04-29T00:00:00Z", dsun: 216.553252, hgln: -0.000000, hglt: -4.340347 },
  { isoDate: "2012-04-30T00:00:00Z", dsun: 216.607202, hgln: 0.000000, hglt: -4.241296 },
  { isoDate: "2012-05-01T00:00:00Z", dsun: 216.660713, hgln: -0.000000, hglt: -4.141105 },
  { isoDate: "2012-05-02T00:00:00Z", dsun: 216.713838, hgln: -0.000000, hglt: -4.039804 },
  { isoDate: "2012-05-03T00:00:00Z", dsun: 216.766629, hgln: -0.000000, hglt: -3.937423 },
  { isoDate: "2012-05-04T00:00:00Z", dsun: 216.819139, hgln: 0.000000, hglt: -3.833993 },
  { isoDate: "2012-05-05T00:00:00Z", dsun: 216.871410, hgln: 0.000000, hglt: -3.729541 },
  { isoDate: "2012-05-06T00:00:00Z", dsun: 216.923467, hgln: 0.000000, hglt: -3.624094 },
  { isoDate: "2012-05-07T00:00:00Z", dsun: 216.975313, hgln: -0.000000, hglt: -3.517678 },
  { isoDate: "2012-05-08T00:00:00Z", dsun: 217.026925, hgln: 0.000000, hglt: -3.410320 },
  { isoDate: "2012-05-09T00:00:00Z", dsun: 217.078254, hgln: 0.000000, hglt: -3.302046 },
  { isoDate: "2012-05-10T00:00:00Z", dsun: 217.129234, hgln: 0.000000, hglt: -3.192883 },
  { isoDate: "2012-05-11T00:00:00Z", dsun: 217.179787, hgln: -0.000000, hglt: -3.082859 },
  { isoDate: "2012-05-12T00:00:00Z", dsun: 217.229830, hgln: 0.000000, hglt: -2.972004 },
  { isoDate: "2012-05-13T00:00:00Z", dsun: 217.279280, hgln: -0.000000, hglt: -2.860347 },
  { isoDate: "2012-05-14T00:00:00Z", dsun: 217.328060, hgln: 0.000000, hglt: -2.747921 },
  { isoDate: "2012-05-15T00:00:00Z", dsun: 217.376101, hgln: -0.000000, hglt: -2.634757 },
  { isoDate: "2012-05-16T00:00:00Z", dsun: 217.423337, hgln: 0.000000, hglt: -2.520890 },
  { isoDate: "2012-05-17T00:00:00Z", dsun: 217.469712, hgln: -0.000000, hglt: -2.406352 },
  { isoDate: "2012-05-18T00:00:00Z", dsun: 217.515177, hgln: -0.000000, hglt: -2.291177 },
  { isoDate: "2012-05-19T00:00:00Z", dsun: 217.559690, hgln: 0.000000, hglt: -2.175401 },
  { isoDate: "2012-05-20T00:00:00Z", dsun: 217.603218, hgln: 0.000000, hglt: -2.059059 },
  { isoDate: "2012-05-21T00:00:00Z", dsun: 217.645735, hgln: 0.000000, hglt: -1.942186 },
  { isoDate: "2012-05-22T00:00:00Z", dsun: 217.687223, hgln: -0.000000, hglt: -1.824818 },
  { isoDate: "2012-05-23T00:00:00Z", dsun: 217.727674, hgln: 0.000000, hglt: -1.706989 },
  { isoDate: "2012-05-24T00:00:00Z", dsun: 217.767089, hgln: 0.000000, hglt: -1.588737 },
  { isoDate: "2012-05-25T00:00:00Z", dsun: 217.805476, hgln: -0.000000, hglt: -1.470097 },
  { isoDate: "2012-05-26T00:00:00Z", dsun: 217.842857, hgln: -0.000000, hglt: -1.351103 },
  { isoDate: "2012-05-27T00:00:00Z", dsun: 217.879258, hgln: 0.000000, hglt: -1.231792 },
  { isoDate: "2012-05-28T00:00:00Z", dsun: 217.914719, hgln: 0.000000, hglt: -1.112199 },
  { isoDate: "2012-05-29T00:00:00Z", dsun: 217.949286, hgln: 0.000000, hglt: -0.992356 },
  { isoDate: "2012-05-30T00:00:00Z", dsun: 217.983014, hgln: -0.000000, hglt: -0.872298 },
  { isoDate: "2012-05-31T00:00:00Z", dsun: 218.015964, hgln: -0.000000, hglt: -0.752056 },
  { isoDate: "2012-06-01T00:00:00Z", dsun: 218.048201, hgln: 0.000000, hglt: -0.631663 },
  { isoDate: "2012-06-02T00:00:00Z", dsun: 218.079786, hgln: -0.000000, hglt: -0.511147 },
  { isoDate: "2012-06-03T00:00:00Z", dsun: 218.110770, hgln: 0.000000, hglt: -0.390537 },
  { isoDate: "2012-06-04T00:00:00Z", dsun: 218.141188, hgln: -0.000000, hglt: -0.269861 },
  { isoDate: "2012-06-05T00:00:00Z", dsun: 218.171054, hgln: -0.000000, hglt: -0.149145 },
  { isoDate: "2012-06-06T00:00:00Z", dsun: 218.200353, hgln: -0.000000, hglt: -0.028416 },
  { isoDate: "2012-06-07T00:00:00Z", dsun: 218.229050, hgln: 0.000000, hglt: 0.092298 },
  { isoDate: "2012-06-08T00:00:00Z", dsun: 218.257092, hgln: 0.000000, hglt: 0.212969 },
  { isoDate: "2012-06-09T00:00:00Z", dsun: 218.284413, hgln: 0.000000, hglt: 0.333568 },
  { isoDate: "2012-06-10T00:00:00Z", dsun: 218.310942, hgln: 0.000000, hglt: 0.454065 },
  { isoDate: "2012-06-11T00:00:00Z", dsun: 218.336609, hgln: -0.000000, hglt: 0.574428 },
  { isoDate: "2012-06-12T00:00:00Z", dsun: 218.361348, hgln: -0.000000, hglt: 0.694626 },
  { isoDate: "2012-06-13T00:00:00Z", dsun: 218.385095, hgln: -0.000000, hglt: 0.814624 },
  { isoDate: "2012-06-14T00:00:00Z", dsun: 218.407792, hgln: -0.000000, hglt: 0.934391 },
  { isoDate: "2012-06-15T00:00:00Z", dsun: 218.429389, hgln: -0.000000, hglt: 1.053892 },
  { isoDate: "2012-06-16T00:00:00Z", dsun: 218.449838, hgln: 0.000000, hglt: 1.173094 },
  { isoDate: "2012-06-17T00:00:00Z", dsun: 218.469100, hgln: -0.000000, hglt: 1.291962 },
  { isoDate: "2012-06-18T00:00:00Z", dsun: 218.487141, hgln: 0.000000, hglt: 1.410462 },
  { isoDate: "2012-06-19T00:00:00Z", dsun: 218.503935, hgln: 0.000000, hglt: 1.528561 },
  { isoDate: "2012-06-20T00:00:00Z", dsun: 218.519462, hgln: -0.000000, hglt: 1.646223 },
  { isoDate: "2012-06-21T00:00:00Z", dsun: 218.533715, hgln: 0.000000, hglt: 1.763414 },
  { isoDate: "2012-06-22T00:00:00Z", dsun: 218.546694, hgln: 0.000000, hglt: 1.880100 },
  { isoDate: "2012-06-23T00:00:00Z", dsun: 218.558412, hgln: -0.000000, hglt: 1.996248 },
  { isoDate: "2012-06-24T00:00:00Z", dsun: 218.568893, hgln: 0.000000, hglt: 2.111824 },
  { isoDate: "2012-06-25T00:00:00Z", dsun: 218.578171, hgln: -0.000000, hglt: 2.226796 },
  { isoDate: "2012-06-26T00:00:00Z", dsun: 218.586294, hgln: -0.000000, hglt: 2.341131 },
  { isoDate: "2012-06-27T00:00:00Z", dsun: 218.593318, hgln: 0.000000, hglt: 2.454800 },
  { isoDate: "2012-06-28T00:00:00Z", dsun: 218.599310, hgln: -0.000000, hglt: 2.567771 },
  { isoDate: "2012-06-29T00:00:00Z", dsun: 218.604340, hgln: 0.000000, hglt: 2.680017 },
  { isoDate: "2012-06-30T00:00:00Z", dsun: 218.608482, hgln: 0.000000, hglt: 2.791510 },
  { isoDate: "2012-07-01T00:00:00Z", dsun: 218.611806, hgln: 0.000000, hglt: 2.902226 },
  { isoDate: "2012-07-02T00:00:00Z", dsun: 218.614371, hgln: -0.000000, hglt: 3.012136 },
  { isoDate: "2012-07-03T00:00:00Z", dsun: 218.616219, hgln: -0.000000, hglt: 3.121217 },
  { isoDate: "2012-07-04T00:00:00Z", dsun: 218.617373, hgln: 0.000000, hglt: 3.229446 },
  { isoDate: "2012-07-05T00:00:00Z", dsun: 218.617832, hgln: 0.000000, hglt: 3.336798 },
  { isoDate: "2012-07-06T00:00:00Z", dsun: 218.617574, hgln: -0.000000, hglt: 3.443248 },
  { isoDate: "2012-07-07T00:00:00Z", dsun: 218.616563, hgln: -0.000000, hglt: 3.548770 },
  { isoDate: "2012-07-08T00:00:00Z", dsun: 218.614749, hgln: 0.000000, hglt: 3.653337 },
  { isoDate: "2012-07-09T00:00:00Z", dsun: 218.612077, hgln: 0.000000, hglt: 3.756922 },
  { isoDate: "2012-07-10T00:00:00Z", dsun: 218.608493, hgln: 0.000000, hglt: 3.859498 },
  { isoDate: "2012-07-11T00:00:00Z", dsun: 218.603941, hgln: -0.000000, hglt: 3.961035 },
  { isoDate: "2012-07-12T00:00:00Z", dsun: 218.598367, hgln: 0.000000, hglt: 4.061505 },
  { isoDate: "2012-07-13T00:00:00Z", dsun: 218.591722, hgln: -0.000000, hglt: 4.160879 },
  { isoDate: "2012-07-14T00:00:00Z", dsun: 218.583961, hgln: 0.000000, hglt: 4.259127 },
  { isoDate: "2012-07-15T00:00:00Z", dsun: 218.575039, hgln: 0.000000, hglt: 4.356221 },
  { isoDate: "2012-07-16T00:00:00Z", dsun: 218.564920, hgln: -0.000000, hglt: 4.452132 },
  { isoDate: "2012-07-17T00:00:00Z", dsun: 218.553570, hgln: -0.000000, hglt: 4.546831 },
  { isoDate: "2012-07-18T00:00:00Z", dsun: 218.540960, hgln: 0.000000, hglt: 4.640288 },
  { isoDate: "2012-07-19T00:00:00Z", dsun: 218.527073, hgln: 0.000000, hglt: 4.732475 },
  { isoDate: "2012-07-20T00:00:00Z", dsun: 218.511899, hgln: 0.000000, hglt: 4.823364 },
  { isoDate: "2012-07-21T00:00:00Z", dsun: 218.495440, hgln: 0.000000, hglt: 4.912927 },
  { isoDate: "2012-07-22T00:00:00Z", dsun: 218.477712, hgln: 0.000000, hglt: 5.001137 },
  { isoDate: "2012-07-23T00:00:00Z", dsun: 218.458743, hgln: -0.000000, hglt: 5.087967 },
  { isoDate: "2012-07-24T00:00:00Z", dsun: 218.438578, hgln: -0.000000, hglt: 5.173392 },
  { isoDate: "2012-07-25T00:00:00Z", dsun: 218.417273, hgln: 0.000000, hglt: 5.257388 },
  { isoDate: "2012-07-26T00:00:00Z", dsun: 218.394897, hgln: 0.000000, hglt: 5.339932 },
  { isoDate: "2012-07-27T00:00:00Z", dsun: 218.371524, hgln: -0.000000, hglt: 5.421001 },
  { isoDate: "2012-07-28T00:00:00Z", dsun: 218.347237, hgln: -0.000000, hglt: 5.500574 },
  { isoDate: "2012-07-29T00:00:00Z", dsun: 218.322116, hgln: -0.000000, hglt: 5.578632 },
  { isoDate: "2012-07-30T00:00:00Z", dsun: 218.296236, hgln: -0.000000, hglt: 5.655156 },
  { isoDate: "2012-07-31T00:00:00Z", dsun: 218.269662, hgln: 0.000000, hglt: 5.730127 },
  { isoDate: "2012-08-01T00:00:00Z", dsun: 218.242442, hgln: -0.000000, hglt: 5.803527 },
  { isoDate: "2012-08-02T00:00:00Z", dsun: 218.214609, hgln: -0.000000, hglt: 5.875337 },
  { isoDate: "2012-08-03T00:00:00Z", dsun: 218.186173, hgln: 0.000000, hglt: 5.945539 },
  { isoDate: "2012-08-04T00:00:00Z", dsun: 218.157129, hgln: -0.000000, hglt: 6.014113 },
  { isoDate: "2012-08-05T00:00:00Z", dsun: 218.127456, hgln: -0.000000, hglt: 6.081042 },
  { isoDate: "2012-08-06T00:00:00Z", dsun: 218.097124, hgln: 0.000000, hglt: 6.146303 },
  { isoDate: "2012-08-07T00:00:00Z", dsun: 218.066095, hgln: 0.000000, hglt: 6.209878 },
  { isoDate: "2012-08-08T00:00:00Z", dsun: 218.034329, hgln: 0.000000, hglt: 6.271747 },
  { isoDate: "2012-08-09T00:00:00Z", dsun: 218.001783, hgln: 0.000000, hglt: 6.331888 },
  { isoDate: "2012-08-10T00:00:00Z", dsun: 217.968415, hgln: 0.000000, hglt: 6.390283 },
  { isoDate: "2012-08-11T00:00:00Z", dsun: 217.934184, hgln: -0.000000, hglt: 6.446910 },
  { isoDate: "2012-08-12T00:00:00Z", dsun: 217.899049, hgln: -0.000000, hglt: 6.501750 },
  { isoDate: "2012-08-13T00:00:00Z", dsun: 217.862971, hgln: 0.000000, hglt: 6.554783 },
  { isoDate: "2012-08-14T00:00:00Z", dsun: 217.825914, hgln: 0.000000, hglt: 6.605991 },
  { isoDate: "2012-08-15T00:00:00Z", dsun: 217.787844, hgln: 0.000000, hglt: 6.655355 },
  { isoDate: "2012-08-16T00:00:00Z", dsun: 217.748731, hgln: 0.000000, hglt: 6.702856 },
  { isoDate: "2012-08-17T00:00:00Z", dsun: 217.708553, hgln: -0.000000, hglt: 6.748477 },
  { isoDate: "2012-08-18T00:00:00Z", dsun: 217.667301, hgln: -0.000000, hglt: 6.792201 },
  { isoDate: "2012-08-19T00:00:00Z", dsun: 217.624975, hgln: -0.000000, hglt: 6.834013 },
  { isoDate: "2012-08-20T00:00:00Z", dsun: 217.581593, hgln: 0.000000, hglt: 6.873896 },
  { isoDate: "2012-08-21T00:00:00Z", dsun: 217.537190, hgln: -0.000000, hglt: 6.911838 },
  { isoDate: "2012-08-22T00:00:00Z", dsun: 217.491817, hgln: -0.000000, hglt: 6.947826 },
  { isoDate: "2012-08-23T00:00:00Z", dsun: 217.445543, hgln: 0.000000, hglt: 6.981848 },
  { isoDate: "2012-08-24T00:00:00Z", dsun: 217.398444, hgln: -0.000000, hglt: 7.013895 },
  { isoDate: "2012-08-25T00:00:00Z", dsun: 217.350606, hgln: 0.000000, hglt: 7.043957 },
  { isoDate: "2012-08-26T00:00:00Z", dsun: 217.302117, hgln: 0.000000, hglt: 7.072026 },
  { isoDate: "2012-08-27T00:00:00Z", dsun: 217.253063, hgln: 0.000000, hglt: 7.098093 },
  { isoDate: "2012-08-28T00:00:00Z", dsun: 217.203523, hgln: -0.000000, hglt: 7.122151 },
  { isoDate: "2012-08-29T00:00:00Z", dsun: 217.153564, hgln: -0.000000, hglt: 7.144193 },
  { isoDate: "2012-08-30T00:00:00Z", dsun: 217.103242, hgln: 0.000000, hglt: 7.164211 },
  { isoDate: "2012-08-31T00:00:00Z", dsun: 217.052594, hgln: 0.000000, hglt: 7.182197 },
  { isoDate: "2012-09-01T00:00:00Z", dsun: 217.001644, hgln: -0.000000, hglt: 7.198145 },
  { isoDate: "2012-09-02T00:00:00Z", dsun: 216.950401, hgln: -0.000000, hglt: 7.212045 },
  { isoDate: "2012-09-03T00:00:00Z", dsun: 216.898860, hgln: -0.000000, hglt: 7.223892 },
  { isoDate: "2012-09-04T00:00:00Z", dsun: 216.847009, hgln: -0.000000, hglt: 7.233676 },
  { isoDate: "2012-09-05T00:00:00Z", dsun: 216.794825, hgln: -0.000000, hglt: 7.241391 },
  { isoDate: "2012-09-06T00:00:00Z", dsun: 216.742282, hgln: 0.000000, hglt: 7.247030 },
  { isoDate: "2012-09-07T00:00:00Z", dsun: 216.689352, hgln: -0.000000, hglt: 7.250586 },
  { isoDate: "2012-09-08T00:00:00Z", dsun: 216.636002, hgln: 0.000000, hglt: 7.252053 },
  { isoDate: "2012-09-09T00:00:00Z", dsun: 216.582197, hgln: -0.000000, hglt: 7.251425 },
  { isoDate: "2012-09-10T00:00:00Z", dsun: 216.527904, hgln: -0.000000, hglt: 7.248697 },
  { isoDate: "2012-09-11T00:00:00Z", dsun: 216.473086, hgln: -0.000000, hglt: 7.243866 },
  { isoDate: "2012-09-12T00:00:00Z", dsun: 216.417705, hgln: 0.000000, hglt: 7.236927 },
  { isoDate: "2012-09-13T00:00:00Z", dsun: 216.361727, hgln: -0.000000, hglt: 7.227877 },
  { isoDate: "2012-09-14T00:00:00Z", dsun: 216.305117, hgln: -0.000000, hglt: 7.216715 },
  { isoDate: "2012-09-15T00:00:00Z", dsun: 216.247850, hgln: -0.000000, hglt: 7.203440 },
  { isoDate: "2012-09-16T00:00:00Z", dsun: 216.189909, hgln: -0.000000, hglt: 7.188053 },
  { isoDate: "2012-09-17T00:00:00Z", dsun: 216.131293, hgln: -0.000000, hglt: 7.170555 },
  { isoDate: "2012-09-18T00:00:00Z", dsun: 216.072019, hgln: -0.000000, hglt: 7.150950 },
  { isoDate: "2012-09-19T00:00:00Z", dsun: 216.012125, hgln: 0.000000, hglt: 7.129243 },
  { isoDate: "2012-09-20T00:00:00Z", dsun: 215.951670, hgln: -0.000000, hglt: 7.105438 },
  { isoDate: "2012-09-21T00:00:00Z", dsun: 215.890726, hgln: -0.000000, hglt: 7.079544 },
  { isoDate: "2012-09-22T00:00:00Z", dsun: 215.829380, hgln: -0.000000, hglt: 7.051567 },
  { isoDate: "2012-09-23T00:00:00Z", dsun: 215.767723, hgln: 0.000000, hglt: 7.021515 },
  { isoDate: "2012-09-24T00:00:00Z", dsun: 215.705847, hgln: 0.000000, hglt: 6.989397 },
  { isoDate: "2012-09-25T00:00:00Z", dsun: 215.643838, hgln: 0.000000, hglt: 6.955220 },
  { isoDate: "2012-09-26T00:00:00Z", dsun: 215.581778, hgln: -0.000000, hglt: 6.918993 },
  { isoDate: "2012-09-27T00:00:00Z", dsun: 215.519735, hgln: 0.000000, hglt: 6.880723 },
  { isoDate: "2012-09-28T00:00:00Z", dsun: 215.457766, hgln: -0.000000, hglt: 6.840420 },
  { isoDate: "2012-09-29T00:00:00Z", dsun: 215.395918, hgln: -0.000000, hglt: 6.798090 },
  { isoDate: "2012-09-30T00:00:00Z", dsun: 215.334222, hgln: 0.000000, hglt: 6.753742 },
  { isoDate: "2012-10-01T00:00:00Z", dsun: 215.272699, hgln: 0.000000, hglt: 6.707384 },
  { isoDate: "2012-10-02T00:00:00Z", dsun: 215.211357, hgln: 0.000000, hglt: 6.659026 },
  { isoDate: "2012-10-03T00:00:00Z", dsun: 215.150197, hgln: 0.000000, hglt: 6.608676 },
  { isoDate: "2012-10-04T00:00:00Z", dsun: 215.089211, hgln: -0.000000, hglt: 6.556343 },
  { isoDate: "2012-10-05T00:00:00Z", dsun: 215.028384, hgln: -0.000000, hglt: 6.502039 },
  { isoDate: "2012-10-06T00:00:00Z", dsun: 214.967698, hgln: -0.000000, hglt: 6.445773 },
  { isoDate: "2012-10-07T00:00:00Z", dsun: 214.907127, hgln: -0.000000, hglt: 6.387558 },
  { isoDate: "2012-10-08T00:00:00Z", dsun: 214.846644, hgln: 0.000000, hglt: 6.327405 },
  { isoDate: "2012-10-09T00:00:00Z", dsun: 214.786217, hgln: -0.000000, hglt: 6.265329 },
  { isoDate: "2012-10-10T00:00:00Z", dsun: 214.725811, hgln: 0.000000, hglt: 6.201342 },
  { isoDate: "2012-10-11T00:00:00Z", dsun: 214.665386, hgln: 0.000000, hglt: 6.135461 },
  { isoDate: "2012-10-12T00:00:00Z", dsun: 214.604903, hgln: 0.000000, hglt: 6.067701 },
  { isoDate: "2012-10-13T00:00:00Z", dsun: 214.544320, hgln: 0.000000, hglt: 5.998080 },
  { isoDate: "2012-10-14T00:00:00Z", dsun: 214.483605, hgln: 0.000000, hglt: 5.926618 },
  { isoDate: "2012-10-15T00:00:00Z", dsun: 214.422730, hgln: 0.000000, hglt: 5.853334 },
  { isoDate: "2012-10-16T00:00:00Z", dsun: 214.361689, hgln: 0.000000, hglt: 5.778252 },
  { isoDate: "2012-10-17T00:00:00Z", dsun: 214.300494, hgln: 0.000000, hglt: 5.701395 },
  { isoDate: "2012-10-18T00:00:00Z", dsun: 214.239180, hgln: 0.000000, hglt: 5.622788 },
  { isoDate: "2012-10-19T00:00:00Z", dsun: 214.177807, hgln: -0.000000, hglt: 5.542457 },
  { isoDate: "2012-10-20T00:00:00Z", dsun: 214.116451, hgln: 0.000000, hglt: 5.460428 },
  { isoDate: "2012-10-21T00:00:00Z", dsun: 214.055199, hgln: 0.000000, hglt: 5.376726 },
  { isoDate: "2012-10-22T00:00:00Z", dsun: 213.994143, hgln: 0.000000, hglt: 5.291379 },
  { isoDate: "2012-10-23T00:00:00Z", dsun: 213.933373, hgln: -0.000000, hglt: 5.204410 },
  { isoDate: "2012-10-24T00:00:00Z", dsun: 213.872975, hgln: 0.000000, hglt: 5.115844 },
  { isoDate: "2012-10-25T00:00:00Z", dsun: 213.813026, hgln: 0.000000, hglt: 5.025708 },
  { isoDate: "2012-10-26T00:00:00Z", dsun: 213.753595, hgln: -0.000000, hglt: 4.934024 },
  { isoDate: "2012-10-27T00:00:00Z", dsun: 213.694738, hgln: 0.000000, hglt: 4.840818 },
  { isoDate: "2012-10-28T00:00:00Z", dsun: 213.636505, hgln: -0.000000, hglt: 4.746113 },
  { isoDate: "2012-10-29T00:00:00Z", dsun: 213.578933, hgln: -0.000000, hglt: 4.649935 },
  { isoDate: "2012-10-30T00:00:00Z", dsun: 213.522049, hgln: 0.000000, hglt: 4.552309 },
  { isoDate: "2012-10-31T00:00:00Z", dsun: 213.465870, hgln: -0.000000, hglt: 4.453259 },
  { isoDate: "2012-11-01T00:00:00Z", dsun: 213.410407, hgln: 0.000000, hglt: 4.352811 },
  { isoDate: "2012-11-02T00:00:00Z", dsun: 213.355659, hgln: 0.000000, hglt: 4.250991 },
  { isoDate: "2012-11-03T00:00:00Z", dsun: 213.301619, hgln: -0.000000, hglt: 4.147826 },
  { isoDate: "2012-11-04T00:00:00Z", dsun: 213.248273, hgln: -0.000000, hglt: 4.043343 },
  { isoDate: "2012-11-05T00:00:00Z", dsun: 213.195602, hgln: 0.000000, hglt: 3.937570 },
  { isoDate: "2012-11-06T00:00:00Z", dsun: 213.143580, hgln: -0.000000, hglt: 3.830537 },
  { isoDate: "2012-11-07T00:00:00Z", dsun: 213.092173, hgln: 0.000000, hglt: 3.722273 },
  { isoDate: "2012-11-08T00:00:00Z", dsun: 213.041343, hgln: 0.000000, hglt: 3.612808 },
  { isoDate: "2012-11-09T00:00:00Z", dsun: 212.991048, hgln: -0.000000, hglt: 3.502175 },
  { isoDate: "2012-11-10T00:00:00Z", dsun: 212.941238, hgln: 0.000000, hglt: 3.390406 },
  { isoDate: "2012-11-11T00:00:00Z", dsun: 212.891865, hgln: 0.000000, hglt: 3.277536 },
  { isoDate: "2012-11-12T00:00:00Z", dsun: 212.842882, hgln: 0.000000, hglt: 3.163600 },
  { isoDate: "2012-11-13T00:00:00Z", dsun: 212.794251, hgln: 0.000000, hglt: 3.048637 },
  { isoDate: "2012-11-14T00:00:00Z", dsun: 212.745954, hgln: 0.000000, hglt: 2.932686 },
  { isoDate: "2012-11-15T00:00:00Z", dsun: 212.697991, hgln: -0.000000, hglt: 2.815787 },
  { isoDate: "2012-11-16T00:00:00Z", dsun: 212.650391, hgln: 0.000000, hglt: 2.697981 },
  { isoDate: "2012-11-17T00:00:00Z", dsun: 212.603206, hgln: 0.000000, hglt: 2.579308 },
  { isoDate: "2012-11-18T00:00:00Z", dsun: 212.556505, hgln: 0.000000, hglt: 2.459810 },
  { isoDate: "2012-11-19T00:00:00Z", dsun: 212.510371, hgln: 0.000000, hglt: 2.339525 },
  { isoDate: "2012-11-20T00:00:00Z", dsun: 212.464888, hgln: 0.000000, hglt: 2.218493 },
  { isoDate: "2012-11-21T00:00:00Z", dsun: 212.420139, hgln: -0.000000, hglt: 2.096750 },
  { isoDate: "2012-11-22T00:00:00Z", dsun: 212.376203, hgln: 0.000000, hglt: 1.974335 },
  { isoDate: "2012-11-23T00:00:00Z", dsun: 212.333152, hgln: -0.000000, hglt: 1.851283 },
  { isoDate: "2012-11-24T00:00:00Z", dsun: 212.291048, hgln: -0.000000, hglt: 1.727631 },
  { isoDate: "2012-11-25T00:00:00Z", dsun: 212.249947, hgln: -0.000000, hglt: 1.603414 },
  { isoDate: "2012-11-26T00:00:00Z", dsun: 212.209894, hgln: 0.000000, hglt: 1.478670 },
  { isoDate: "2012-11-27T00:00:00Z", dsun: 212.170929, hgln: -0.000000, hglt: 1.353432 },
  { isoDate: "2012-11-28T00:00:00Z", dsun: 212.133080, hgln: -0.000000, hglt: 1.227738 },
  { isoDate: "2012-11-29T00:00:00Z", dsun: 212.096369, hgln: 0.000000, hglt: 1.101623 },
  { isoDate: "2012-11-30T00:00:00Z", dsun: 212.060808, hgln: 0.000000, hglt: 0.975124 },
  { isoDate: "2012-12-01T00:00:00Z", dsun: 212.026402, hgln: -0.000000, hglt: 0.848276 },
  { isoDate: "2012-12-02T00:00:00Z", dsun: 211.993144, hgln: -0.000000, hglt: 0.721117 },
  { isoDate: "2012-12-03T00:00:00Z", dsun: 211.961023, hgln: -0.000000, hglt: 0.593684 },
  { isoDate: "2012-12-04T00:00:00Z", dsun: 211.930017, hgln: 0.000000, hglt: 0.466014 },
  { isoDate: "2012-12-05T00:00:00Z", dsun: 211.900098, hgln: -0.000000, hglt: 0.338147 },
  { isoDate: "2012-12-06T00:00:00Z", dsun: 211.871227, hgln: -0.000000, hglt: 0.210120 },
  { isoDate: "2012-12-07T00:00:00Z", dsun: 211.843359, hgln: 0.000000, hglt: 0.081974 },
  { isoDate: "2012-12-08T00:00:00Z", dsun: 211.816442, hgln: -0.000000, hglt: -0.046251 },
  { isoDate: "2012-12-09T00:00:00Z", dsun: 211.790418, hgln: 0.000000, hglt: -0.174513 },
  { isoDate: "2012-12-10T00:00:00Z", dsun: 211.765225, hgln: 0.000000, hglt: -0.302769 },
  { isoDate: "2012-12-11T00:00:00Z", dsun: 211.740804, hgln: 0.000000, hglt: -0.430975 },
  { isoDate: "2012-12-12T00:00:00Z", dsun: 211.717103, hgln: 0.000000, hglt: -0.559086 },
  { isoDate: "2012-12-13T00:00:00Z", dsun: 211.694087, hgln: 0.000000, hglt: -0.687056 },
  { isoDate: "2012-12-14T00:00:00Z", dsun: 211.671745, hgln: 0.000000, hglt: -0.814837 },
  { isoDate: "2012-12-15T00:00:00Z", dsun: 211.650088, hgln: 0.000000, hglt: -0.942384 },
  { isoDate: "2012-12-16T00:00:00Z", dsun: 211.629153, hgln: -0.000000, hglt: -1.069651 },
  { isoDate: "2012-12-17T00:00:00Z", dsun: 211.608997, hgln: -0.000000, hglt: -1.196594 },
  { isoDate: "2012-12-18T00:00:00Z", dsun: 211.589685, hgln: -0.000000, hglt: -1.323170 },
  { isoDate: "2012-12-19T00:00:00Z", dsun: 211.571290, hgln: 0.000000, hglt: -1.449337 },
  { isoDate: "2012-12-20T00:00:00Z", dsun: 211.553883, hgln: 0.000000, hglt: -1.575055 },
  { isoDate: "2012-12-21T00:00:00Z", dsun: 211.537530, hgln: 0.000000, hglt: -1.700284 },
  { isoDate: "2012-12-22T00:00:00Z", dsun: 211.522292, hgln: 0.000000, hglt: -1.824985 },
  { isoDate: "2012-12-23T00:00:00Z", dsun: 211.508225, hgln: 0.000000, hglt: -1.949121 },
  { isoDate: "2012-12-24T00:00:00Z", dsun: 211.495377, hgln: 0.000000, hglt: -2.072653 },
  { isoDate: "2012-12-25T00:00:00Z", dsun: 211.483790, hgln: 0.000000, hglt: -2.195545 },
  { isoDate: "2012-12-26T00:00:00Z", dsun: 211.473499, hgln: 0.000000, hglt: -2.317759 },
  { isoDate: "2012-12-27T00:00:00Z", dsun: 211.464533, hgln: 0.000000, hglt: -2.439259 },
  { isoDate: "2012-12-28T00:00:00Z", dsun: 211.456912, hgln: 0.000000, hglt: -2.560009 },
  { isoDate: "2012-12-29T00:00:00Z", dsun: 211.450648, hgln: 0.000000, hglt: -2.679972 },
  { isoDate: "2012-12-30T00:00:00Z", dsun: 211.445747, hgln: -0.000000, hglt: -2.799112 },
  { isoDate: "2012-12-31T00:00:00Z", dsun: 211.442201, hgln: 0.000000, hglt: -2.917393 },
  { isoDate: "2013-01-01T00:00:00Z", dsun: 211.439995, hgln: -0.000000, hglt: -3.034779 },
  { isoDate: "2013-01-02T00:00:00Z", dsun: 211.439104, hgln: -0.000000, hglt: -3.151233 },
  { isoDate: "2013-01-03T00:00:00Z", dsun: 211.439490, hgln: 0.000000, hglt: -3.266719 },
  { isoDate: "2013-01-04T00:00:00Z", dsun: 211.441109, hgln: 0.000000, hglt: -3.381200 },
  { isoDate: "2013-01-05T00:00:00Z", dsun: 211.443904, hgln: 0.000000, hglt: -3.494639 },
  { isoDate: "2013-01-06T00:00:00Z", dsun: 211.447811, hgln: -0.000000, hglt: -3.606997 },
  { isoDate: "2013-01-07T00:00:00Z", dsun: 211.452760, hgln: -0.000000, hglt: -3.718237 },
  { isoDate: "2013-01-08T00:00:00Z", dsun: 211.458678, hgln: -0.000000, hglt: -3.828319 },
  { isoDate: "2013-01-09T00:00:00Z", dsun: 211.465490, hgln: 0.000000, hglt: -3.937204 },
  { isoDate: "2013-01-10T00:00:00Z", dsun: 211.473134, hgln: 0.000000, hglt: -4.044853 },
  { isoDate: "2013-01-11T00:00:00Z", dsun: 211.481560, hgln: 0.000000, hglt: -4.151225 },
  { isoDate: "2013-01-12T00:00:00Z", dsun: 211.490739, hgln: -0.000000, hglt: -4.256281 },
  { isoDate: "2013-01-13T00:00:00Z", dsun: 211.500666, hgln: 0.000000, hglt: -4.359985 },
  { isoDate: "2013-01-14T00:00:00Z", dsun: 211.511358, hgln: -0.000000, hglt: -4.462298 },
  { isoDate: "2013-01-15T00:00:00Z", dsun: 211.522851, hgln: -0.000000, hglt: -4.563187 },
  { isoDate: "2013-01-16T00:00:00Z", dsun: 211.535191, hgln: 0.000000, hglt: -4.662617 },
  { isoDate: "2013-01-17T00:00:00Z", dsun: 211.548432, hgln: 0.000000, hglt: -4.760557 },
  { isoDate: "2013-01-18T00:00:00Z", dsun: 211.562628, hgln: 0.000000, hglt: -4.856976 },
  { isoDate: "2013-01-19T00:00:00Z", dsun: 211.577831, hgln: -0.000000, hglt: -4.951845 },
  { isoDate: "2013-01-20T00:00:00Z", dsun: 211.594093, hgln: 0.000000, hglt: -5.045134 },
  { isoDate: "2013-01-21T00:00:00Z", dsun: 211.611456, hgln: -0.000000, hglt: -5.136817 },
  { isoDate: "2013-01-22T00:00:00Z", dsun: 211.629963, hgln: 0.000000, hglt: -5.226866 },
  { isoDate: "2013-01-23T00:00:00Z", dsun: 211.649650, hgln: 0.000000, hglt: -5.315254 },
  { isoDate: "2013-01-24T00:00:00Z", dsun: 211.670549, hgln: -0.000000, hglt: -5.401957 },
  { isoDate: "2013-01-25T00:00:00Z", dsun: 211.692687, hgln: 0.000000, hglt: -5.486949 },
  { isoDate: "2013-01-26T00:00:00Z", dsun: 211.716083, hgln: -0.000000, hglt: -5.570205 },
  { isoDate: "2013-01-27T00:00:00Z", dsun: 211.740750, hgln: -0.000000, hglt: -5.651701 },
  { isoDate: "2013-01-28T00:00:00Z", dsun: 211.766692, hgln: 0.000000, hglt: -5.731414 },
  { isoDate: "2013-01-29T00:00:00Z", dsun: 211.793903, hgln: 0.000000, hglt: -5.809320 },
  { isoDate: "2013-01-30T00:00:00Z", dsun: 211.822362, hgln: 0.000000, hglt: -5.885396 },
  { isoDate: "2013-01-31T00:00:00Z", dsun: 211.852041, hgln: -0.000000, hglt: -5.959619 },
  { isoDate: "2013-02-01T00:00:00Z", dsun: 211.882894, hgln: 0.000000, hglt: -6.031966 },
  { isoDate: "2013-02-02T00:00:00Z", dsun: 211.914867, hgln: 0.000000, hglt: -6.102413 },
  { isoDate: "2013-02-03T00:00:00Z", dsun: 211.947891, hgln: 0.000000, hglt: -6.170939 },
  { isoDate: "2013-02-04T00:00:00Z", dsun: 211.981891, hgln: 0.000000, hglt: -6.237519 },
  { isoDate: "2013-02-05T00:00:00Z", dsun: 212.016786, hgln: 0.000000, hglt: -6.302131 },
  { isoDate: "2013-02-06T00:00:00Z", dsun: 212.052489, hgln: -0.000000, hglt: -6.364753 },
  { isoDate: "2013-02-07T00:00:00Z", dsun: 212.088921, hgln: 0.000000, hglt: -6.425362 },
  { isoDate: "2013-02-08T00:00:00Z", dsun: 212.126006, hgln: -0.000000, hglt: -6.483937 },
  { isoDate: "2013-02-09T00:00:00Z", dsun: 212.163685, hgln: -0.000000, hglt: -6.540458 },
  { isoDate: "2013-02-10T00:00:00Z", dsun: 212.201916, hgln: -0.000000, hglt: -6.594907 },
  { isoDate: "2013-02-11T00:00:00Z", dsun: 212.240678, hgln: 0.000000, hglt: -6.647265 },
  { isoDate: "2013-02-12T00:00:00Z", dsun: 212.279970, hgln: 0.000000, hglt: -6.697517 },
  { isoDate: "2013-02-13T00:00:00Z", dsun: 212.319804, hgln: -0.000000, hglt: -6.745649 },
  { isoDate: "2013-02-14T00:00:00Z", dsun: 212.360209, hgln: -0.000000, hglt: -6.791647 },
  { isoDate: "2013-02-15T00:00:00Z", dsun: 212.401218, hgln: -0.000000, hglt: -6.835501 },
  { isoDate: "2013-02-16T00:00:00Z", dsun: 212.442869, hgln: -0.000000, hglt: -6.877200 },
  { isoDate: "2013-02-17T00:00:00Z", dsun: 212.485201, hgln: 0.000000, hglt: -6.916735 },
  { isoDate: "2013-02-18T00:00:00Z", dsun: 212.528252, hgln: 0.000000, hglt: -6.954097 },
  { isoDate: "2013-02-19T00:00:00Z", dsun: 212.572059, hgln: 0.000000, hglt: -6.989279 },
  { isoDate: "2013-02-20T00:00:00Z", dsun: 212.616656, hgln: 0.000000, hglt: -7.022274 },
  { isoDate: "2013-02-21T00:00:00Z", dsun: 212.662077, hgln: -0.000000, hglt: -7.053075 },
  { isoDate: "2013-02-22T00:00:00Z", dsun: 212.708352, hgln: 0.000000, hglt: -7.081679 },
  { isoDate: "2013-02-23T00:00:00Z", dsun: 212.755508, hgln: 0.000000, hglt: -7.108079 },
  { isoDate: "2013-02-24T00:00:00Z", dsun: 212.803567, hgln: -0.000000, hglt: -7.132271 },
  { isoDate: "2013-02-25T00:00:00Z", dsun: 212.852546, hgln: -0.000000, hglt: -7.154252 },
  { isoDate: "2013-02-26T00:00:00Z", dsun: 212.902453, hgln: -0.000000, hglt: -7.174018 },
  { isoDate: "2013-02-27T00:00:00Z", dsun: 212.953283, hgln: 0.000000, hglt: -7.191565 },
  { isoDate: "2013-02-28T00:00:00Z", dsun: 213.005018, hgln: -0.000000, hglt: -7.206892 },
  { isoDate: "2013-03-01T00:00:00Z", dsun: 213.057625, hgln: 0.000000, hglt: -7.219994 },
  { isoDate: "2013-03-02T00:00:00Z", dsun: 213.111054, hgln: 0.000000, hglt: -7.230869 },
  { isoDate: "2013-03-03T00:00:00Z", dsun: 213.165242, hgln: 0.000000, hglt: -7.239515 },
  { isoDate: "2013-03-04T00:00:00Z", dsun: 213.220112, hgln: 0.000000, hglt: -7.245929 },
  { isoDate: "2013-03-05T00:00:00Z", dsun: 213.275580, hgln: -0.000000, hglt: -7.250110 },
  { isoDate: "2013-03-06T00:00:00Z", dsun: 213.331555, hgln: -0.000000, hglt: -7.252058 },
  { isoDate: "2013-03-07T00:00:00Z", dsun: 213.387946, hgln: -0.000000, hglt: -7.251773 },
  { isoDate: "2013-03-08T00:00:00Z", dsun: 213.444667, hgln: -0.000000, hglt: -7.249256 },
  { isoDate: "2013-03-09T00:00:00Z", dsun: 213.501640, hgln: -0.000000, hglt: -7.244510 },
  { isoDate: "2013-03-10T00:00:00Z", dsun: 213.558801, hgln: 0.000000, hglt: -7.237539 },
  { isoDate: "2013-03-11T00:00:00Z", dsun: 213.616100, hgln: -0.000000, hglt: -7.228349 },
  { isoDate: "2013-03-12T00:00:00Z", dsun: 213.673506, hgln: 0.000000, hglt: -7.216948 },
  { isoDate: "2013-03-13T00:00:00Z", dsun: 213.731002, hgln: -0.000000, hglt: -7.203344 },
  { isoDate: "2013-03-14T00:00:00Z", dsun: 213.788587, hgln: -0.000000, hglt: -7.187546 },
  { isoDate: "2013-03-15T00:00:00Z", dsun: 213.846270, hgln: -0.000000, hglt: -7.169565 },
  { isoDate: "2013-03-16T00:00:00Z", dsun: 213.904071, hgln: 0.000000, hglt: -7.149413 },
  { isoDate: "2013-03-17T00:00:00Z", dsun: 213.962014, hgln: 0.000000, hglt: -7.127102 },
  { isoDate: "2013-03-18T00:00:00Z", dsun: 214.020126, hgln: -0.000000, hglt: -7.102645 },
  { isoDate: "2013-03-19T00:00:00Z", dsun: 214.078438, hgln: 0.000000, hglt: -7.076055 },
  { isoDate: "2013-03-20T00:00:00Z", dsun: 214.136979, hgln: 0.000000, hglt: -7.047347 },
  { isoDate: "2013-03-21T00:00:00Z", dsun: 214.195783, hgln: 0.000000, hglt: -7.016534 },
  { isoDate: "2013-03-22T00:00:00Z", dsun: 214.254881, hgln: 0.000000, hglt: -6.983632 },
  { isoDate: "2013-03-23T00:00:00Z", dsun: 214.314305, hgln: 0.000000, hglt: -6.948656 },
  { isoDate: "2013-03-24T00:00:00Z", dsun: 214.374088, hgln: 0.000000, hglt: -6.911620 },
  { isoDate: "2013-03-25T00:00:00Z", dsun: 214.434260, hgln: 0.000000, hglt: -6.872542 },
  { isoDate: "2013-03-26T00:00:00Z", dsun: 214.494844, hgln: 0.000000, hglt: -6.831434 },
  { isoDate: "2013-03-27T00:00:00Z", dsun: 214.555858, hgln: -0.000000, hglt: -6.788314 },
  { isoDate: "2013-03-28T00:00:00Z", dsun: 214.617303, hgln: -0.000000, hglt: -6.743197 },
  { isoDate: "2013-03-29T00:00:00Z", dsun: 214.679169, hgln: 0.000000, hglt: -6.696096 },
  { isoDate: "2013-03-30T00:00:00Z", dsun: 214.741423, hgln: 0.000000, hglt: -6.647028 },
  { isoDate: "2013-03-31T00:00:00Z", dsun: 214.804015, hgln: -0.000000, hglt: -6.596008 },
  { isoDate: "2013-04-01T00:00:00Z", dsun: 214.866878, hgln: 0.000000, hglt: -6.543050 },
  { isoDate: "2013-04-02T00:00:00Z", dsun: 214.929931, hgln: -0.000000, hglt: -6.488172 },
  { isoDate: "2013-04-03T00:00:00Z", dsun: 214.993083, hgln: 0.000000, hglt: -6.431390 },
  { isoDate: "2013-04-04T00:00:00Z", dsun: 215.056241, hgln: -0.000000, hglt: -6.372724 },
  { isoDate: "2013-04-05T00:00:00Z", dsun: 215.119315, hgln: -0.000000, hglt: -6.312192 },
  { isoDate: "2013-04-06T00:00:00Z", dsun: 215.182219, hgln: -0.000000, hglt: -6.249817 },
  { isoDate: "2013-04-07T00:00:00Z", dsun: 215.244877, hgln: 0.000000, hglt: -6.185621 },
  { isoDate: "2013-04-08T00:00:00Z", dsun: 215.307224, hgln: -0.000000, hglt: -6.119627 },
  { isoDate: "2013-04-09T00:00:00Z", dsun: 215.369211, hgln: -0.000000, hglt: -6.051861 },
  { isoDate: "2013-04-10T00:00:00Z", dsun: 215.430799, hgln: -0.000000, hglt: -5.982348 },
  { isoDate: "2013-04-11T00:00:00Z", dsun: 215.491966, hgln: -0.000000, hglt: -5.911114 },
  { isoDate: "2013-04-12T00:00:00Z", dsun: 215.552700, hgln: 0.000000, hglt: -5.838187 },
  { isoDate: "2013-04-13T00:00:00Z", dsun: 215.613000, hgln: 0.000000, hglt: -5.763595 },
  { isoDate: "2013-04-14T00:00:00Z", dsun: 215.672876, hgln: -0.000000, hglt: -5.687365 },
  { isoDate: "2013-04-15T00:00:00Z", dsun: 215.732343, hgln: 0.000000, hglt: -5.609526 },
  { isoDate: "2013-04-16T00:00:00Z", dsun: 215.791421, hgln: 0.000000, hglt: -5.530105 },
  { isoDate: "2013-04-17T00:00:00Z", dsun: 215.850136, hgln: 0.000000, hglt: -5.449132 },
  { isoDate: "2013-04-18T00:00:00Z", dsun: 215.908516, hgln: -0.000000, hglt: -5.366635 },
  { isoDate: "2013-04-19T00:00:00Z", dsun: 215.966593, hgln: 0.000000, hglt: -5.282642 },
  { isoDate: "2013-04-20T00:00:00Z", dsun: 216.024403, hgln: 0.000000, hglt: -5.197181 },
  { isoDate: "2013-04-21T00:00:00Z", dsun: 216.081984, hgln: 0.000000, hglt: -5.110280 },
  { isoDate: "2013-04-22T00:00:00Z", dsun: 216.139375, hgln: 0.000000, hglt: -5.021967 },
  { isoDate: "2013-04-23T00:00:00Z", dsun: 216.196618, hgln: -0.000000, hglt: -4.932270 },
  { isoDate: "2013-04-24T00:00:00Z", dsun: 216.253747, hgln: 0.000000, hglt: -4.841215 },
  { isoDate: "2013-04-25T00:00:00Z", dsun: 216.310793, hgln: 0.000000, hglt: -4.748828 },
  { isoDate: "2013-04-26T00:00:00Z", dsun: 216.367770, hgln: 0.000000, hglt: -4.655134 },
  { isoDate: "2013-04-27T00:00:00Z", dsun: 216.424677, hgln: 0.000000, hglt: -4.560159 },
  { isoDate: "2013-04-28T00:00:00Z", dsun: 216.481488, hgln: -0.000000, hglt: -4.463926 },
  { isoDate: "2013-04-29T00:00:00Z", dsun: 216.538156, hgln: -0.000000, hglt: -4.366461 },
  { isoDate: "2013-04-30T00:00:00Z", dsun: 216.594614, hgln: 0.000000, hglt: -4.267790 },
  { isoDate: "2013-05-01T00:00:00Z", dsun: 216.650783, hgln: 0.000000, hglt: -4.167938 },
  { isoDate: "2013-05-02T00:00:00Z", dsun: 216.706572, hgln: 0.000000, hglt: -4.066935 },
  { isoDate: "2013-05-03T00:00:00Z", dsun: 216.761892, hgln: 0.000000, hglt: -3.964809 },
  { isoDate: "2013-05-04T00:00:00Z", dsun: 216.816656, hgln: -0.000000, hglt: -3.861591 },
  { isoDate: "2013-05-05T00:00:00Z", dsun: 216.870785, hgln: 0.000000, hglt: -3.757313 },
  { isoDate: "2013-05-06T00:00:00Z", dsun: 216.924209, hgln: -0.000000, hglt: -3.652007 },
  { isoDate: "2013-05-07T00:00:00Z", dsun: 216.976868, hgln: -0.000000, hglt: -3.545706 },
  { isoDate: "2013-05-08T00:00:00Z", dsun: 217.028712, hgln: 0.000000, hglt: -3.438445 },
  { isoDate: "2013-05-09T00:00:00Z", dsun: 217.079706, hgln: 0.000000, hglt: -3.330258 },
  { isoDate: "2013-05-10T00:00:00Z", dsun: 217.129823, hgln: -0.000000, hglt: -3.221180 },
  { isoDate: "2013-05-11T00:00:00Z", dsun: 217.179049, hgln: -0.000000, hglt: -3.111246 },
  { isoDate: "2013-05-12T00:00:00Z", dsun: 217.227377, hgln: -0.000000, hglt: -3.000490 },
  { isoDate: "2013-05-13T00:00:00Z", dsun: 217.274810, hgln: 0.000000, hglt: -2.888948 },
  { isoDate: "2013-05-14T00:00:00Z", dsun: 217.321361, hgln: -0.000000, hglt: -2.776654 },
  { isoDate: "2013-05-15T00:00:00Z", dsun: 217.367047, hgln: -0.000000, hglt: -2.663643 },
  { isoDate: "2013-05-16T00:00:00Z", dsun: 217.411892, hgln: 0.000000, hglt: -2.549949 },
  { isoDate: "2013-05-17T00:00:00Z", dsun: 217.455926, hgln: 0.000000, hglt: -2.435606 },
  { isoDate: "2013-05-18T00:00:00Z", dsun: 217.499184, hgln: 0.000000, hglt: -2.320647 },
  { isoDate: "2013-05-19T00:00:00Z", dsun: 217.541707, hgln: 0.000000, hglt: -2.205104 },
  { isoDate: "2013-05-20T00:00:00Z", dsun: 217.583542, hgln: -0.000000, hglt: -2.089012 },
  { isoDate: "2013-05-21T00:00:00Z", dsun: 217.624737, hgln: 0.000000, hglt: -1.972400 },
  { isoDate: "2013-05-22T00:00:00Z", dsun: 217.665346, hgln: -0.000000, hglt: -1.855300 },
  { isoDate: "2013-05-23T00:00:00Z", dsun: 217.705419, hgln: -0.000000, hglt: -1.737742 },
  { isoDate: "2013-05-24T00:00:00Z", dsun: 217.745000, hgln: 0.000000, hglt: -1.619753 },
  { isoDate: "2013-05-25T00:00:00Z", dsun: 217.784117, hgln: -0.000000, hglt: -1.501363 },
  { isoDate: "2013-05-26T00:00:00Z", dsun: 217.822780, hgln: -0.000000, hglt: -1.382597 },
  { isoDate: "2013-05-27T00:00:00Z", dsun: 217.860973, hgln: 0.000000, hglt: -1.263484 },
  { isoDate: "2013-05-28T00:00:00Z", dsun: 217.898658, hgln: -0.000000, hglt: -1.144051 },
  { isoDate: "2013-05-29T00:00:00Z", dsun: 217.935772, hgln: -0.000000, hglt: -1.024326 },
  { isoDate: "2013-05-30T00:00:00Z", dsun: 217.972241, hgln: 0.000000, hglt: -0.904340 },
  { isoDate: "2013-05-31T00:00:00Z", dsun: 218.007984, hgln: -0.000000, hglt: -0.784124 },
  { isoDate: "2013-06-01T00:00:00Z", dsun: 218.042918, hgln: -0.000000, hglt: -0.663710 },
  { isoDate: "2013-06-02T00:00:00Z", dsun: 218.076964, hgln: 0.000000, hglt: -0.543132 },
  { isoDate: "2013-06-03T00:00:00Z", dsun: 218.110053, hgln: -0.000000, hglt: -0.422424 },
  { isoDate: "2013-06-04T00:00:00Z", dsun: 218.142121, hgln: -0.000000, hglt: -0.301620 },
  { isoDate: "2013-06-05T00:00:00Z", dsun: 218.173116, hgln: -0.000000, hglt: -0.180757 },
  { isoDate: "2013-06-06T00:00:00Z", dsun: 218.202992, hgln: 0.000000, hglt: -0.059868 },
  { isoDate: "2013-06-07T00:00:00Z", dsun: 218.231717, hgln: -0.000000, hglt: 0.061010 },
  { isoDate: "2013-06-08T00:00:00Z", dsun: 218.259264, hgln: 0.000000, hglt: 0.181841 },
  { isoDate: "2013-06-09T00:00:00Z", dsun: 218.285618, hgln: 0.000000, hglt: 0.302592 },
  { isoDate: "2013-06-10T00:00:00Z", dsun: 218.310771, hgln: -0.000000, hglt: 0.423225 },
  { isoDate: "2013-06-11T00:00:00Z", dsun: 218.334726, hgln: -0.000000, hglt: 0.543708 },
  { isoDate: "2013-06-12T00:00:00Z", dsun: 218.357491, hgln: 0.000000, hglt: 0.664005 },
  { isoDate: "2013-06-13T00:00:00Z", dsun: 218.379087, hgln: 0.000000, hglt: 0.784082 },
  { isoDate: "2013-06-14T00:00:00Z", dsun: 218.399537, hgln: -0.000000, hglt: 0.903906 },
  { isoDate: "2013-06-15T00:00:00Z", dsun: 218.418876, hgln: 0.000000, hglt: 1.023443 },
  { isoDate: "2013-06-16T00:00:00Z", dsun: 218.437144, hgln: -0.000000, hglt: 1.142663 },
  { isoDate: "2013-06-17T00:00:00Z", dsun: 218.454391, hgln: -0.000000, hglt: 1.261532 },
  { isoDate: "2013-06-18T00:00:00Z", dsun: 218.470670, hgln: -0.000000, hglt: 1.380021 },
  { isoDate: "2013-06-19T00:00:00Z", dsun: 218.486043, hgln: -0.000000, hglt: 1.498099 },
  { isoDate: "2013-06-20T00:00:00Z", dsun: 218.500575, hgln: 0.000000, hglt: 1.615738 },
  { isoDate: "2013-06-21T00:00:00Z", dsun: 218.514331, hgln: 0.000000, hglt: 1.732910 },
  { isoDate: "2013-06-22T00:00:00Z", dsun: 218.527366, hgln: 0.000000, hglt: 1.849589 },
  { isoDate: "2013-06-23T00:00:00Z", dsun: 218.539723, hgln: 0.000000, hglt: 1.965748 },
  { isoDate: "2013-06-24T00:00:00Z", dsun: 218.551425, hgln: -0.000000, hglt: 2.081362 },
  { isoDate: "2013-06-25T00:00:00Z", dsun: 218.562466, hgln: 0.000000, hglt: 2.196406 },
  { isoDate: "2013-06-26T00:00:00Z", dsun: 218.572817, hgln: -0.000000, hglt: 2.310852 },
  { isoDate: "2013-06-27T00:00:00Z", dsun: 218.582429, hgln: -0.000000, hglt: 2.424673 },
  { isoDate: "2013-06-28T00:00:00Z", dsun: 218.591236, hgln: 0.000000, hglt: 2.537840 },
  { isoDate: "2013-06-29T00:00:00Z", dsun: 218.599169, hgln: 0.000000, hglt: 2.650323 },
  { isoDate: "2013-06-30T00:00:00Z", dsun: 218.606156, hgln: 0.000000, hglt: 2.762092 },
  { isoDate: "2013-07-01T00:00:00Z", dsun: 218.612131, hgln: 0.000000, hglt: 2.873115 },
  { isoDate: "2013-07-02T00:00:00Z", dsun: 218.617032, hgln: 0.000000, hglt: 2.983361 },
  { isoDate: "2013-07-03T00:00:00Z", dsun: 218.620806, hgln: 0.000000, hglt: 3.092798 },
  { isoDate: "2013-07-04T00:00:00Z", dsun: 218.623406, hgln: 0.000000, hglt: 3.201394 },
  { isoDate: "2013-07-05T00:00:00Z", dsun: 218.624793, hgln: 0.000000, hglt: 3.309118 },
  { isoDate: "2013-07-06T00:00:00Z", dsun: 218.624936, hgln: -0.000000, hglt: 3.415937 },
  { isoDate: "2013-07-07T00:00:00Z", dsun: 218.623811, hgln: -0.000000, hglt: 3.521821 },
  { isoDate: "2013-07-08T00:00:00Z", dsun: 218.621402, hgln: -0.000000, hglt: 3.626739 },
  { isoDate: "2013-07-09T00:00:00Z", dsun: 218.617703, hgln: -0.000000, hglt: 3.730659 },
  { isoDate: "2013-07-10T00:00:00Z", dsun: 218.612716, hgln: 0.000000, hglt: 3.833552 },
  { isoDate: "2013-07-11T00:00:00Z", dsun: 218.606449, hgln: 0.000000, hglt: 3.935388 },
  { isoDate: "2013-07-12T00:00:00Z", dsun: 218.598924, hgln: -0.000000, hglt: 4.036139 },
  { isoDate: "2013-07-13T00:00:00Z", dsun: 218.590170, hgln: 0.000000, hglt: 4.135776 },
  { isoDate: "2013-07-14T00:00:00Z", dsun: 218.580224, hgln: -0.000000, hglt: 4.234271 },
  { isoDate: "2013-07-15T00:00:00Z", dsun: 218.569135, hgln: -0.000000, hglt: 4.331599 },
  { isoDate: "2013-07-16T00:00:00Z", dsun: 218.556960, hgln: 0.000000, hglt: 4.427732 },
  { isoDate: "2013-07-17T00:00:00Z", dsun: 218.543764, hgln: 0.000000, hglt: 4.522646 },
  { isoDate: "2013-07-18T00:00:00Z", dsun: 218.529619, hgln: 0.000000, hglt: 4.616317 },
  { isoDate: "2013-07-19T00:00:00Z", dsun: 218.514602, hgln: 0.000000, hglt: 4.708722 },
  { isoDate: "2013-07-20T00:00:00Z", dsun: 218.498788, hgln: 0.000000, hglt: 4.799839 },
  { isoDate: "2013-07-21T00:00:00Z", dsun: 218.482245, hgln: 0.000000, hglt: 4.889646 },
  { isoDate: "2013-07-22T00:00:00Z", dsun: 218.465027, hgln: 0.000000, hglt: 4.978122 },
  { isoDate: "2013-07-23T00:00:00Z", dsun: 218.447165, hgln: 0.000000, hglt: 5.065246 },
  { isoDate: "2013-07-24T00:00:00Z", dsun: 218.428667, hgln: 0.000000, hglt: 5.150997 },
  { isoDate: "2013-07-25T00:00:00Z", dsun: 218.409516, hgln: -0.000000, hglt: 5.235352 },
  { isoDate: "2013-07-26T00:00:00Z", dsun: 218.389677, hgln: 0.000000, hglt: 5.318287 },
  { isoDate: "2013-07-27T00:00:00Z", dsun: 218.369100, hgln: -0.000000, hglt: 5.399780 },
  { isoDate: "2013-07-28T00:00:00Z", dsun: 218.347728, hgln: -0.000000, hglt: 5.479806 },
  { isoDate: "2013-07-29T00:00:00Z", dsun: 218.325504, hgln: 0.000000, hglt: 5.558341 },
  { isoDate: "2013-07-30T00:00:00Z", dsun: 218.302373, hgln: -0.000000, hglt: 5.635359 },
  { isoDate: "2013-07-31T00:00:00Z", dsun: 218.278285, hgln: 0.000000, hglt: 5.710837 },
  { isoDate: "2013-08-01T00:00:00Z", dsun: 218.253192, hgln: -0.000000, hglt: 5.784750 },
  { isoDate: "2013-08-02T00:00:00Z", dsun: 218.227055, hgln: -0.000000, hglt: 5.857074 },
  { isoDate: "2013-08-03T00:00:00Z", dsun: 218.199840, hgln: 0.000000, hglt: 5.927786 },
  { isoDate: "2013-08-04T00:00:00Z", dsun: 218.171517, hgln: -0.000000, hglt: 5.996863 },
  { isoDate: "2013-08-05T00:00:00Z", dsun: 218.142064, hgln: 0.000000, hglt: 6.064282 },
  { isoDate: "2013-08-06T00:00:00Z", dsun: 218.111465, hgln: 0.000000, hglt: 6.130021 },
  { isoDate: "2013-08-07T00:00:00Z", dsun: 218.079712, hgln: -0.000000, hglt: 6.194059 },
  { isoDate: "2013-08-08T00:00:00Z", dsun: 218.046806, hgln: 0.000000, hglt: 6.256375 },
  { isoDate: "2013-08-09T00:00:00Z", dsun: 218.012759, hgln: -0.000000, hglt: 6.316950 },
  { isoDate: "2013-08-10T00:00:00Z", dsun: 217.977591, hgln: 0.000000, hglt: 6.375765 },
  { isoDate: "2013-08-11T00:00:00Z", dsun: 217.941336, hgln: -0.000000, hglt: 6.432801 },
  { isoDate: "2013-08-12T00:00:00Z", dsun: 217.904037, hgln: -0.000000, hglt: 6.488042 },
  { isoDate: "2013-08-13T00:00:00Z", dsun: 217.865751, hgln: -0.000000, hglt: 6.541471 },
  { isoDate: "2013-08-14T00:00:00Z", dsun: 217.826543, hgln: -0.000000, hglt: 6.593073 },
  { isoDate: "2013-08-15T00:00:00Z", dsun: 217.786489, hgln: -0.000000, hglt: 6.642834 },
  { isoDate: "2013-08-16T00:00:00Z", dsun: 217.745672, hgln: 0.000000, hglt: 6.690740 },
  { isoDate: "2013-08-17T00:00:00Z", dsun: 217.704176, hgln: 0.000000, hglt: 6.736777 },
  { isoDate: "2013-08-18T00:00:00Z", dsun: 217.662085, hgln: 0.000000, hglt: 6.780934 },
  { isoDate: "2013-08-19T00:00:00Z", dsun: 217.619475, hgln: -0.000000, hglt: 6.823199 },
  { isoDate: "2013-08-20T00:00:00Z", dsun: 217.576407, hgln: 0.000000, hglt: 6.863558 },
  { isoDate: "2013-08-21T00:00:00Z", dsun: 217.532922, hgln: -0.000000, hglt: 6.902000 },
  { isoDate: "2013-08-22T00:00:00Z", dsun: 217.489037, hgln: -0.000000, hglt: 6.938511 },
  { isoDate: "2013-08-23T00:00:00Z", dsun: 217.444752, hgln: 0.000000, hglt: 6.973078 },
  { isoDate: "2013-08-24T00:00:00Z", dsun: 217.400044, hgln: 0.000000, hglt: 7.005688 },
  { isoDate: "2013-08-25T00:00:00Z", dsun: 217.354880, hgln: -0.000000, hglt: 7.036328 },
  { isoDate: "2013-08-26T00:00:00Z", dsun: 217.309220, hgln: -0.000000, hglt: 7.064983 },
  { isoDate: "2013-08-27T00:00:00Z", dsun: 217.263020, hgln: 0.000000, hglt: 7.091642 },
  { isoDate: "2013-08-28T00:00:00Z", dsun: 217.216236, hgln: 0.000000, hglt: 7.116292 },
  { isoDate: "2013-08-29T00:00:00Z", dsun: 217.168828, hgln: -0.000000, hglt: 7.138921 },
  { isoDate: "2013-08-30T00:00:00Z", dsun: 217.120757, hgln: -0.000000, hglt: 7.159517 },
  { isoDate: "2013-08-31T00:00:00Z", dsun: 217.071986, hgln: -0.000000, hglt: 7.178070 },
  { isoDate: "2013-09-01T00:00:00Z", dsun: 217.022486, hgln: 0.000000, hglt: 7.194571 },
  { isoDate: "2013-09-02T00:00:00Z", dsun: 216.972227, hgln: -0.000000, hglt: 7.209010 },
  { isoDate: "2013-09-03T00:00:00Z", dsun: 216.921185, hgln: -0.000000, hglt: 7.221380 },
  { isoDate: "2013-09-04T00:00:00Z", dsun: 216.869343, hgln: -0.000000, hglt: 7.231672 },
  { isoDate: "2013-09-05T00:00:00Z", dsun: 216.816690, hgln: -0.000000, hglt: 7.239882 },
  { isoDate: "2013-09-06T00:00:00Z", dsun: 216.763224, hgln: -0.000000, hglt: 7.246003 },
  { isoDate: "2013-09-07T00:00:00Z", dsun: 216.708954, hgln: -0.000000, hglt: 7.250031 },
  { isoDate: "2013-09-08T00:00:00Z", dsun: 216.653902, hgln: -0.000000, hglt: 7.251965 },
  { isoDate: "2013-09-09T00:00:00Z", dsun: 216.598103, hgln: 0.000000, hglt: 7.251800 },
  { isoDate: "2013-09-10T00:00:00Z", dsun: 216.541606, hgln: -0.000000, hglt: 7.249538 },
  { isoDate: "2013-09-11T00:00:00Z", dsun: 216.484475, hgln: 0.000000, hglt: 7.245176 },
  { isoDate: "2013-09-12T00:00:00Z", dsun: 216.426784, hgln: 0.000000, hglt: 7.238717 },
  { isoDate: "2013-09-13T00:00:00Z", dsun: 216.368617, hgln: -0.000000, hglt: 7.230162 },
  { isoDate: "2013-09-14T00:00:00Z", dsun: 216.310065, hgln: 0.000000, hglt: 7.219511 },
  { isoDate: "2013-09-15T00:00:00Z", dsun: 216.251217, hgln: -0.000000, hglt: 7.206767 },
  { isoDate: "2013-09-16T00:00:00Z", dsun: 216.192163, hgln: 0.000000, hglt: 7.191932 },
  { isoDate: "2013-09-17T00:00:00Z", dsun: 216.132980, hgln: -0.000000, hglt: 7.175007 },
  { isoDate: "2013-09-18T00:00:00Z", dsun: 216.073733, hgln: -0.000000, hglt: 7.155994 },
  { isoDate: "2013-09-19T00:00:00Z", dsun: 216.014470, hgln: -0.000000, hglt: 7.134894 },
  { isoDate: "2013-09-20T00:00:00Z", dsun: 215.955218, hgln: -0.000000, hglt: 7.111709 },
  { isoDate: "2013-09-21T00:00:00Z", dsun: 215.895987, hgln: 0.000000, hglt: 7.086439 },
  { isoDate: "2013-09-22T00:00:00Z", dsun: 215.836770, hgln: 0.000000, hglt: 7.059087 },
  { isoDate: "2013-09-23T00:00:00Z", dsun: 215.777550, hgln: -0.000000, hglt: 7.029655 },
  { isoDate: "2013-09-24T00:00:00Z", dsun: 215.718301, hgln: -0.000000, hglt: 6.998145 },
  { isoDate: "2013-09-25T00:00:00Z", dsun: 215.658990, hgln: -0.000000, hglt: 6.964561 },
  { isoDate: "2013-09-26T00:00:00Z", dsun: 215.599587, hgln: -0.000000, hglt: 6.928909 },
  { isoDate: "2013-09-27T00:00:00Z", dsun: 215.540057, hgln: 0.000000, hglt: 6.891193 },
  { isoDate: "2013-09-28T00:00:00Z", dsun: 215.480367, hgln: 0.000000, hglt: 6.851420 },
  { isoDate: "2013-09-29T00:00:00Z", dsun: 215.420485, hgln: 0.000000, hglt: 6.809597 },
  { isoDate: "2013-09-30T00:00:00Z", dsun: 215.360380, hgln: -0.000000, hglt: 6.765734 },
  { isoDate: "2013-10-01T00:00:00Z", dsun: 215.300022, hgln: 0.000000, hglt: 6.719838 },
  { isoDate: "2013-10-02T00:00:00Z", dsun: 215.239383, hgln: 0.000000, hglt: 6.671922 },
  { isoDate: "2013-10-03T00:00:00Z", dsun: 215.178438, hgln: 0.000000, hglt: 6.621996 },
  { isoDate: "2013-10-04T00:00:00Z", dsun: 215.117172, hgln: -0.000000, hglt: 6.570074 },
  { isoDate: "2013-10-05T00:00:00Z", dsun: 215.055574, hgln: 0.000000, hglt: 6.516170 },
  { isoDate: "2013-10-06T00:00:00Z", dsun: 214.993648, hgln: 0.000000, hglt: 6.460300 },
  { isoDate: "2013-10-07T00:00:00Z", dsun: 214.931412, hgln: -0.000000, hglt: 6.402480 },
  { isoDate: "2013-10-08T00:00:00Z", dsun: 214.868902, hgln: -0.000000, hglt: 6.342729 },
  { isoDate: "2013-10-09T00:00:00Z", dsun: 214.806170, hgln: 0.000000, hglt: 6.281065 },
  { isoDate: "2013-10-10T00:00:00Z", dsun: 214.743283, hgln: 0.000000, hglt: 6.217506 },
  { isoDate: "2013-10-11T00:00:00Z", dsun: 214.680321, hgln: 0.000000, hglt: 6.152073 },
  { isoDate: "2013-10-12T00:00:00Z", dsun: 214.617374, hgln: -0.000000, hglt: 6.084784 },
  { isoDate: "2013-10-13T00:00:00Z", dsun: 214.554536, hgln: 0.000000, hglt: 6.015659 },
  { isoDate: "2013-10-14T00:00:00Z", dsun: 214.491897, hgln: 0.000000, hglt: 5.944717 },
  { isoDate: "2013-10-15T00:00:00Z", dsun: 214.429545, hgln: 0.000000, hglt: 5.871975 },
  { isoDate: "2013-10-16T00:00:00Z", dsun: 214.367558, hgln: 0.000000, hglt: 5.797453 },
  { isoDate: "2013-10-17T00:00:00Z", dsun: 214.305999, hgln: 0.000000, hglt: 5.721168 },
  { isoDate: "2013-10-18T00:00:00Z", dsun: 214.244918, hgln: -0.000000, hglt: 5.643138 },
  { isoDate: "2013-10-19T00:00:00Z", dsun: 214.184348, hgln: 0.000000, hglt: 5.563382 },
  { isoDate: "2013-10-20T00:00:00Z", dsun: 214.124309, hgln: 0.000000, hglt: 5.481917 },
  { isoDate: "2013-10-21T00:00:00Z", dsun: 214.064805, hgln: 0.000000, hglt: 5.398763 },
  { isoDate: "2013-10-22T00:00:00Z", dsun: 214.005829, hgln: 0.000000, hglt: 5.313939 },
  { isoDate: "2013-10-23T00:00:00Z", dsun: 213.947368, hgln: 0.000000, hglt: 5.227466 },
  { isoDate: "2013-10-24T00:00:00Z", dsun: 213.889399, hgln: 0.000000, hglt: 5.139366 },
  { isoDate: "2013-10-25T00:00:00Z", dsun: 213.831898, hgln: 0.000000, hglt: 5.049661 },
  { isoDate: "2013-10-26T00:00:00Z", dsun: 213.774837, hgln: 0.000000, hglt: 4.958375 },
  { isoDate: "2013-10-27T00:00:00Z", dsun: 213.718186, hgln: 0.000000, hglt: 4.865532 },
  { isoDate: "2013-10-28T00:00:00Z", dsun: 213.661912, hgln: 0.000000, hglt: 4.771158 },
  { isoDate: "2013-10-29T00:00:00Z", dsun: 213.605982, hgln: 0.000000, hglt: 4.675280 },
  { isoDate: "2013-10-30T00:00:00Z", dsun: 213.550361, hgln: -0.000000, hglt: 4.577925 },
  { isoDate: "2013-10-31T00:00:00Z", dsun: 213.495013, hgln: 0.000000, hglt: 4.479122 },
  { isoDate: "2013-11-01T00:00:00Z", dsun: 213.439906, hgln: 0.000000, hglt: 4.378901 },
  { isoDate: "2013-11-02T00:00:00Z", dsun: 213.385009, hgln: -0.000000, hglt: 4.277294 },
  { isoDate: "2013-11-03T00:00:00Z", dsun: 213.330302, hgln: 0.000000, hglt: 4.174333 },
  { isoDate: "2013-11-04T00:00:00Z", dsun: 213.275776, hgln: 0.000000, hglt: 4.070053 },
  { isoDate: "2013-11-05T00:00:00Z", dsun: 213.221442, hgln: 0.000000, hglt: 3.964489 },
  { isoDate: "2013-11-06T00:00:00Z", dsun: 213.167328, hgln: -0.000000, hglt: 3.857675 },
  { isoDate: "2013-11-07T00:00:00Z", dsun: 213.113485, hgln: -0.000000, hglt: 3.749649 },
  { isoDate: "2013-11-08T00:00:00Z", dsun: 213.059979, hgln: -0.000000, hglt: 3.640446 },
  { isoDate: "2013-11-09T00:00:00Z", dsun: 213.006893, hgln: 0.000000, hglt: 3.530100 },
  { isoDate: "2013-11-10T00:00:00Z", dsun: 212.954314, hgln: -0.000000, hglt: 3.418646 },
  { isoDate: "2013-11-11T00:00:00Z", dsun: 212.902332, hgln: -0.000000, hglt: 3.306119 },
  { isoDate: "2013-11-12T00:00:00Z", dsun: 212.851037, hgln: -0.000000, hglt: 3.192550 },
  { isoDate: "2013-11-13T00:00:00Z", dsun: 212.800508, hgln: -0.000000, hglt: 3.077973 },
  { isoDate: "2013-11-14T00:00:00Z", dsun: 212.750816, hgln: 0.000000, hglt: 2.962419 },
  { isoDate: "2013-11-15T00:00:00Z", dsun: 212.702022, hgln: -0.000000, hglt: 2.845921 },
  { isoDate: "2013-11-16T00:00:00Z", dsun: 212.654173, hgln: -0.000000, hglt: 2.728510 },
  { isoDate: "2013-11-17T00:00:00Z", dsun: 212.607305, hgln: -0.000000, hglt: 2.610218 },
  { isoDate: "2013-11-18T00:00:00Z", dsun: 212.561438, hgln: 0.000000, hglt: 2.491077 },
  { isoDate: "2013-11-19T00:00:00Z", dsun: 212.516584, hgln: -0.000000, hglt: 2.371121 },
  { isoDate: "2013-11-20T00:00:00Z", dsun: 212.472744, hgln: 0.000000, hglt: 2.250383 },
  { isoDate: "2013-11-21T00:00:00Z", dsun: 212.429909, hgln: -0.000000, hglt: 2.128896 },
  { isoDate: "2013-11-22T00:00:00Z", dsun: 212.388063, hgln: 0.000000, hglt: 2.006697 },
  { isoDate: "2013-11-23T00:00:00Z", dsun: 212.347186, hgln: 0.000000, hglt: 1.883820 },
  { isoDate: "2013-11-24T00:00:00Z", dsun: 212.307250, hgln: -0.000000, hglt: 1.760303 },
  { isoDate: "2013-11-25T00:00:00Z", dsun: 212.268225, hgln: 0.000000, hglt: 1.636182 },
  { isoDate: "2013-11-26T00:00:00Z", dsun: 212.230075, hgln: 0.000000, hglt: 1.511495 },
  { isoDate: "2013-11-27T00:00:00Z", dsun: 212.192762, hgln: -0.000000, hglt: 1.386281 },
  { isoDate: "2013-11-28T00:00:00Z", dsun: 212.156242, hgln: 0.000000, hglt: 1.260581 },
  { isoDate: "2013-11-29T00:00:00Z", dsun: 212.120469, hgln: -0.000000, hglt: 1.134434 },
  { isoDate: "2013-11-30T00:00:00Z", dsun: 212.085395, hgln: 0.000000, hglt: 1.007882 },
  { isoDate: "2013-12-01T00:00:00Z", dsun: 212.050975, hgln: 0.000000, hglt: 0.880970 },
  { isoDate: "2013-12-02T00:00:00Z", dsun: 212.017172, hgln: -0.000000, hglt: 0.753739 },
  { isoDate: "2013-12-03T00:00:00Z", dsun: 211.983960, hgln: 0.000000, hglt: 0.626236 },
  { isoDate: "2013-12-04T00:00:00Z", dsun: 211.951335, hgln: -0.000000, hglt: 0.498505 },
  { isoDate: "2013-12-05T00:00:00Z", dsun: 211.919312, hgln: -0.000000, hglt: 0.370592 },
  { isoDate: "2013-12-06T00:00:00Z", dsun: 211.887934, hgln: -0.000000, hglt: 0.242541 },
  { isoDate: "2013-12-07T00:00:00Z", dsun: 211.857260, hgln: -0.000000, hglt: 0.114396 },
  { isoDate: "2013-12-08T00:00:00Z", dsun: 211.827365, hgln: 0.000000, hglt: -0.013801 },
  { isoDate: "2013-12-09T00:00:00Z", dsun: 211.798330, hgln: -0.000000, hglt: -0.142008 },
  { isoDate: "2013-12-10T00:00:00Z", dsun: 211.770237, hgln: -0.000000, hglt: -0.270185 },
  { isoDate: "2013-12-11T00:00:00Z", dsun: 211.743164, hgln: -0.000000, hglt: -0.398293 },
  { isoDate: "2013-12-12T00:00:00Z", dsun: 211.717181, hgln: 0.000000, hglt: -0.526294 },
  { isoDate: "2013-12-13T00:00:00Z", dsun: 211.692350, hgln: 0.000000, hglt: -0.654150 },
  { isoDate: "2013-12-14T00:00:00Z", dsun: 211.668723, hgln: 0.000000, hglt: -0.781823 },
  { isoDate: "2013-12-15T00:00:00Z", dsun: 211.646343, hgln: -0.000000, hglt: -0.909275 },
  { isoDate: "2013-12-16T00:00:00Z", dsun: 211.625240, hgln: 0.000000, hglt: -1.036469 },
  { isoDate: "2013-12-17T00:00:00Z", dsun: 211.605436, hgln: 0.000000, hglt: -1.163367 },
  { isoDate: "2013-12-18T00:00:00Z", dsun: 211.586943, hgln: 0.000000, hglt: -1.289932 },
  { isoDate: "2013-12-19T00:00:00Z", dsun: 211.569762, hgln: 0.000000, hglt: -1.416125 },
  { isoDate: "2013-12-20T00:00:00Z", dsun: 211.553888, hgln: 0.000000, hglt: -1.541908 },
  { isoDate: "2013-12-21T00:00:00Z", dsun: 211.539305, hgln: 0.000000, hglt: -1.667243 },
  { isoDate: "2013-12-22T00:00:00Z", dsun: 211.525992, hgln: 0.000000, hglt: -1.792089 },
  { isoDate: "2013-12-23T00:00:00Z", dsun: 211.513920, hgln: 0.000000, hglt: -1.916409 },
  { isoDate: "2013-12-24T00:00:00Z", dsun: 211.503055, hgln: 0.000000, hglt: -2.040162 },
  { isoDate: "2013-12-25T00:00:00Z", dsun: 211.493353, hgln: 0.000000, hglt: -2.163307 },
  { isoDate: "2013-12-26T00:00:00Z", dsun: 211.484769, hgln: 0.000000, hglt: -2.285805 },
  { isoDate: "2013-12-27T00:00:00Z", dsun: 211.477247, hgln: 0.000000, hglt: -2.407615 },
  { isoDate: "2013-12-28T00:00:00Z", dsun: 211.470728, hgln: 0.000000, hglt: -2.528694 },
  { isoDate: "2013-12-29T00:00:00Z", dsun: 211.465150, hgln: 0.000000, hglt: -2.649001 },
  { isoDate: "2013-12-30T00:00:00Z", dsun: 211.460450, hgln: 0.000000, hglt: -2.768494 },
  { isoDate: "2013-12-31T00:00:00Z", dsun: 211.456571, hgln: -0.000000, hglt: -2.887128 },
  { isoDate: "2014-01-01T00:00:00Z", dsun: 211.453468, hgln: -0.000000, hglt: -3.004862 },
  { isoDate: "2014-01-02T00:00:00Z", dsun: 211.451118, hgln: -0.000000, hglt: -3.121652 },
  { isoDate: "2014-01-03T00:00:00Z", dsun: 211.449520, hgln: -0.000000, hglt: -3.237457 },
  { isoDate: "2014-01-04T00:00:00Z", dsun: 211.448699, hgln: -0.000000, hglt: -3.352236 },
  { isoDate: "2014-01-05T00:00:00Z", dsun: 211.448702, hgln: 0.000000, hglt: -3.465949 },
  { isoDate: "2014-01-06T00:00:00Z", dsun: 211.449591, hgln: 0.000000, hglt: -3.578560 },
  { isoDate: "2014-01-07T00:00:00Z", dsun: 211.451433, hgln: -0.000000, hglt: -3.690032 },
  { isoDate: "2014-01-08T00:00:00Z", dsun: 211.454298, hgln: 0.000000, hglt: -3.800331 },
  { isoDate: "2014-01-09T00:00:00Z", dsun: 211.458250, hgln: 0.000000, hglt: -3.909422 },
  { isoDate: "2014-01-10T00:00:00Z", dsun: 211.463349, hgln: 0.000000, hglt: -4.017274 },
  { isoDate: "2014-01-11T00:00:00Z", dsun: 211.469645, hgln: 0.000000, hglt: -4.123854 },
  { isoDate: "2014-01-12T00:00:00Z", dsun: 211.477181, hgln: 0.000000, hglt: -4.229130 },
  { isoDate: "2014-01-13T00:00:00Z", dsun: 211.485992, hgln: 0.000000, hglt: -4.333070 },
  { isoDate: "2014-01-14T00:00:00Z", dsun: 211.496105, hgln: -0.000000, hglt: -4.435643 },
  { isoDate: "2014-01-15T00:00:00Z", dsun: 211.507539, hgln: -0.000000, hglt: -4.536819 },
  { isoDate: "2014-01-16T00:00:00Z", dsun: 211.520303, hgln: 0.000000, hglt: -4.636566 },
  { isoDate: "2014-01-17T00:00:00Z", dsun: 211.534399, hgln: 0.000000, hglt: -4.734855 },
  { isoDate: "2014-01-18T00:00:00Z", dsun: 211.549820, hgln: -0.000000, hglt: -4.831655 },
  { isoDate: "2014-01-19T00:00:00Z", dsun: 211.566551, hgln: 0.000000, hglt: -4.926935 },
  { isoDate: "2014-01-20T00:00:00Z", dsun: 211.584567, hgln: 0.000000, hglt: -5.020667 },
  { isoDate: "2014-01-21T00:00:00Z", dsun: 211.603837, hgln: -0.000000, hglt: -5.112818 },
  { isoDate: "2014-01-22T00:00:00Z", dsun: 211.624320, hgln: -0.000000, hglt: -5.203361 },
  { isoDate: "2014-01-23T00:00:00Z", dsun: 211.645966, hgln: 0.000000, hglt: -5.292264 },
  { isoDate: "2014-01-24T00:00:00Z", dsun: 211.668718, hgln: -0.000000, hglt: -5.379499 },
  { isoDate: "2014-01-25T00:00:00Z", dsun: 211.692512, hgln: 0.000000, hglt: -5.465035 },
  { isoDate: "2014-01-26T00:00:00Z", dsun: 211.717274, hgln: 0.000000, hglt: -5.548843 },
  { isoDate: "2014-01-27T00:00:00Z", dsun: 211.742929, hgln: 0.000000, hglt: -5.630894 },
  { isoDate: "2014-01-28T00:00:00Z", dsun: 211.769397, hgln: 0.000000, hglt: -5.711159 },
  { isoDate: "2014-01-29T00:00:00Z", dsun: 211.796606, hgln: 0.000000, hglt: -5.789610 },
  { isoDate: "2014-01-30T00:00:00Z", dsun: 211.824494, hgln: 0.000000, hglt: -5.866218 },
  { isoDate: "2014-01-31T00:00:00Z", dsun: 211.853019, hgln: 0.000000, hglt: -5.940957 },
  { isoDate: "2014-02-01T00:00:00Z", dsun: 211.882164, hgln: -0.000000, hglt: -6.013802 },
  { isoDate: "2014-02-02T00:00:00Z", dsun: 211.911936, hgln: -0.000000, hglt: -6.084731 },
  { isoDate: "2014-02-03T00:00:00Z", dsun: 211.942365, hgln: 0.000000, hglt: -6.153721 },
  { isoDate: "2014-02-04T00:00:00Z", dsun: 211.973494, hgln: 0.000000, hglt: -6.220752 },
  { isoDate: "2014-02-05T00:00:00Z", dsun: 212.005378, hgln: 0.000000, hglt: -6.285805 },
  { isoDate: "2014-02-06T00:00:00Z", dsun: 212.038069, hgln: -0.000000, hglt: -6.348864 },
  { isoDate: "2014-02-07T00:00:00Z", dsun: 212.071621, hgln: 0.000000, hglt: -6.409910 },
  { isoDate: "2014-02-08T00:00:00Z", dsun: 212.106081, hgln: 0.000000, hglt: -6.468929 },
  { isoDate: "2014-02-09T00:00:00Z", dsun: 212.141491, hgln: 0.000000, hglt: -6.525904 },
  { isoDate: "2014-02-10T00:00:00Z", dsun: 212.177887, hgln: 0.000000, hglt: -6.580822 },
  { isoDate: "2014-02-11T00:00:00Z", dsun: 212.215298, hgln: -0.000000, hglt: -6.633668 },
  { isoDate: "2014-02-12T00:00:00Z", dsun: 212.253748, hgln: 0.000000, hglt: -6.684427 },
  { isoDate: "2014-02-13T00:00:00Z", dsun: 212.293253, hgln: 0.000000, hglt: -6.733088 },
  { isoDate: "2014-02-14T00:00:00Z", dsun: 212.333824, hgln: 0.000000, hglt: -6.779636 },
  { isoDate: "2014-02-15T00:00:00Z", dsun: 212.375464, hgln: 0.000000, hglt: -6.824060 },
  { isoDate: "2014-02-16T00:00:00Z", dsun: 212.418166, hgln: 0.000000, hglt: -6.866348 },
  { isoDate: "2014-02-17T00:00:00Z", dsun: 212.461915, hgln: 0.000000, hglt: -6.906488 },
  { isoDate: "2014-02-18T00:00:00Z", dsun: 212.506687, hgln: -0.000000, hglt: -6.944469 },
  { isoDate: "2014-02-19T00:00:00Z", dsun: 212.552446, hgln: 0.000000, hglt: -6.980279 },
  { isoDate: "2014-02-20T00:00:00Z", dsun: 212.599148, hgln: -0.000000, hglt: -7.013908 },
  { isoDate: "2014-02-21T00:00:00Z", dsun: 212.646737, hgln: -0.000000, hglt: -7.045347 },
  { isoDate: "2014-02-22T00:00:00Z", dsun: 212.695146, hgln: -0.000000, hglt: -7.074585 },
  { isoDate: "2014-02-23T00:00:00Z", dsun: 212.744302, hgln: 0.000000, hglt: -7.101614 },
  { isoDate: "2014-02-24T00:00:00Z", dsun: 212.794122, hgln: -0.000000, hglt: -7.126425 },
  { isoDate: "2014-02-25T00:00:00Z", dsun: 212.844517, hgln: 0.000000, hglt: -7.149011 },
  { isoDate: "2014-02-26T00:00:00Z", dsun: 212.895400, hgln: -0.000000, hglt: -7.169365 },
  { isoDate: "2014-02-27T00:00:00Z", dsun: 212.946686, hgln: -0.000000, hglt: -7.187483 },
  { isoDate: "2014-02-28T00:00:00Z", dsun: 212.998305, hgln: -0.000000, hglt: -7.203360 },
  { isoDate: "2014-03-01T00:00:00Z", dsun: 213.050202, hgln: -0.000000, hglt: -7.216995 },
  { isoDate: "2014-03-02T00:00:00Z", dsun: 213.102346, hgln: -0.000000, hglt: -7.228387 },
  { isoDate: "2014-03-03T00:00:00Z", dsun: 213.154730, hgln: -0.000000, hglt: -7.237538 },
  { isoDate: "2014-03-04T00:00:00Z", dsun: 213.207367, hgln: 0.000000, hglt: -7.244449 },
  { isoDate: "2014-03-05T00:00:00Z", dsun: 213.260285, hgln: -0.000000, hglt: -7.249125 },
  { isoDate: "2014-03-06T00:00:00Z", dsun: 213.313521, hgln: 0.000000, hglt: -7.251569 },
  { isoDate: "2014-03-07T00:00:00Z", dsun: 213.367117, hgln: -0.000000, hglt: -7.251786 },
  { isoDate: "2014-03-08T00:00:00Z", dsun: 213.421115, hgln: 0.000000, hglt: -7.249783 },
  { isoDate: "2014-03-09T00:00:00Z", dsun: 213.475553, hgln: 0.000000, hglt: -7.245565 },
  { isoDate: "2014-03-10T00:00:00Z", dsun: 213.530467, hgln: -0.000000, hglt: -7.239137 },
  { isoDate: "2014-03-11T00:00:00Z", dsun: 213.585891, hgln: -0.000000, hglt: -7.230508 },
  { isoDate: "2014-03-12T00:00:00Z", dsun: 213.641851, hgln: -0.000000, hglt: -7.219684 },
  { isoDate: "2014-03-13T00:00:00Z", dsun: 213.698373, hgln: -0.000000, hglt: -7.206673 },
  { isoDate: "2014-03-14T00:00:00Z", dsun: 213.755475, hgln: 0.000000, hglt: -7.191483 },
  { isoDate: "2014-03-15T00:00:00Z", dsun: 213.813172, hgln: 0.000000, hglt: -7.174120 },
  { isoDate: "2014-03-16T00:00:00Z", dsun: 213.871472, hgln: -0.000000, hglt: -7.154595 },
  { isoDate: "2014-03-17T00:00:00Z", dsun: 213.930374, hgln: -0.000000, hglt: -7.132916 },
  { isoDate: "2014-03-18T00:00:00Z", dsun: 213.989868, hgln: 0.000000, hglt: -7.109090 },
  { isoDate: "2014-03-19T00:00:00Z", dsun: 214.049932, hgln: -0.000000, hglt: -7.083128 },
  { isoDate: "2014-03-20T00:00:00Z", dsun: 214.110533, hgln: 0.000000, hglt: -7.055039 },
  { isoDate: "2014-03-21T00:00:00Z", dsun: 214.171625, hgln: 0.000000, hglt: -7.024833 },
  { isoDate: "2014-03-22T00:00:00Z", dsun: 214.233148, hgln: -0.000000, hglt: -6.992520 },
  { isoDate: "2014-03-23T00:00:00Z", dsun: 214.295031, hgln: -0.000000, hglt: -6.958110 },
  { isoDate: "2014-03-24T00:00:00Z", dsun: 214.357192, hgln: 0.000000, hglt: -6.921617 },
  { isoDate: "2014-03-25T00:00:00Z", dsun: 214.419544, hgln: 0.000000, hglt: -6.883053 },
  { isoDate: "2014-03-26T00:00:00Z", dsun: 214.481993, hgln: 0.000000, hglt: -6.842432 },
  { isoDate: "2014-03-27T00:00:00Z", dsun: 214.544446, hgln: 0.000000, hglt: -6.799770 },
  { isoDate: "2014-03-28T00:00:00Z", dsun: 214.606819, hgln: 0.000000, hglt: -6.755083 },
  { isoDate: "2014-03-29T00:00:00Z", dsun: 214.669037, hgln: 0.000000, hglt: -6.708392 },
  { isoDate: "2014-03-30T00:00:00Z", dsun: 214.731042, hgln: -0.000000, hglt: -6.659716 },
  { isoDate: "2014-03-31T00:00:00Z", dsun: 214.792798, hgln: -0.000000, hglt: -6.609075 },
  { isoDate: "2014-04-01T00:00:00Z", dsun: 214.854287, hgln: -0.000000, hglt: -6.556493 },
  { isoDate: "2014-04-02T00:00:00Z", dsun: 214.915511, hgln: -0.000000, hglt: -6.501993 },
  { isoDate: "2014-04-03T00:00:00Z", dsun: 214.976485, hgln: 0.000000, hglt: -6.445596 },
  { isoDate: "2014-04-04T00:00:00Z", dsun: 215.037234, hgln: -0.000000, hglt: -6.387328 },
  { isoDate: "2014-04-05T00:00:00Z", dsun: 215.097791, hgln: -0.000000, hglt: -6.327211 },
  { isoDate: "2014-04-06T00:00:00Z", dsun: 215.158189, hgln: 0.000000, hglt: -6.265269 },
  { isoDate: "2014-04-07T00:00:00Z", dsun: 215.218463, hgln: 0.000000, hglt: -6.201526 },
  { isoDate: "2014-04-08T00:00:00Z", dsun: 215.278646, hgln: 0.000000, hglt: -6.136004 },
  { isoDate: "2014-04-09T00:00:00Z", dsun: 215.338770, hgln: -0.000000, hglt: -6.068728 },
  { isoDate: "2014-04-10T00:00:00Z", dsun: 215.398868, hgln: 0.000000, hglt: -5.999720 },
  { isoDate: "2014-04-11T00:00:00Z", dsun: 215.458967, hgln: 0.000000, hglt: -5.929004 },
  { isoDate: "2014-04-12T00:00:00Z", dsun: 215.519095, hgln: 0.000000, hglt: -5.856604 },
  { isoDate: "2014-04-13T00:00:00Z", dsun: 215.579275, hgln: 0.000000, hglt: -5.782542 },
  { isoDate: "2014-04-14T00:00:00Z", dsun: 215.639525, hgln: -0.000000, hglt: -5.706842 },
  { isoDate: "2014-04-15T00:00:00Z", dsun: 215.699855, hgln: 0.000000, hglt: -5.629526 },
  { isoDate: "2014-04-16T00:00:00Z", dsun: 215.760265, hgln: 0.000000, hglt: -5.550618 },
  { isoDate: "2014-04-17T00:00:00Z", dsun: 215.820742, hgln: 0.000000, hglt: -5.470139 },
  { isoDate: "2014-04-18T00:00:00Z", dsun: 215.881258, hgln: 0.000000, hglt: -5.388113 },
  { isoDate: "2014-04-19T00:00:00Z", dsun: 215.941769, hgln: 0.000000, hglt: -5.304563 },
  { isoDate: "2014-04-20T00:00:00Z", dsun: 216.002216, hgln: 0.000000, hglt: -5.219512 },
  { isoDate: "2014-04-21T00:00:00Z", dsun: 216.062526, hgln: 0.000000, hglt: -5.132985 },
  { isoDate: "2014-04-22T00:00:00Z", dsun: 216.122615, hgln: 0.000000, hglt: -5.045008 },
  { isoDate: "2014-04-23T00:00:00Z", dsun: 216.182393, hgln: -0.000000, hglt: -4.955607 },
  { isoDate: "2014-04-24T00:00:00Z", dsun: 216.241767, hgln: 0.000000, hglt: -4.864811 },
  { isoDate: "2014-04-25T00:00:00Z", dsun: 216.300650, hgln: 0.000000, hglt: -4.772650 },
  { isoDate: "2014-04-26T00:00:00Z", dsun: 216.358958, hgln: 0.000000, hglt: -4.679154 },
  { isoDate: "2014-04-27T00:00:00Z", dsun: 216.416624, hgln: -0.000000, hglt: -4.584355 },
  { isoDate: "2014-04-28T00:00:00Z", dsun: 216.473592, hgln: 0.000000, hglt: -4.488285 },
  { isoDate: "2014-04-29T00:00:00Z", dsun: 216.529824, hgln: 0.000000, hglt: -4.390979 },
  { isoDate: "2014-04-30T00:00:00Z", dsun: 216.585301, hgln: 0.000000, hglt: -4.292470 },
  { isoDate: "2014-05-01T00:00:00Z", dsun: 216.640016, hgln: -0.000000, hglt: -4.192791 },
  { isoDate: "2014-05-02T00:00:00Z", dsun: 216.693979, hgln: -0.000000, hglt: -4.091976 },
  { isoDate: "2014-05-03T00:00:00Z", dsun: 216.747207, hgln: -0.000000, hglt: -3.990059 },
  { isoDate: "2014-05-04T00:00:00Z", dsun: 216.799725, hgln: -0.000000, hglt: -3.887071 },
  { isoDate: "2014-05-05T00:00:00Z", dsun: 216.851562, hgln: 0.000000, hglt: -3.783046 },
  { isoDate: "2014-05-06T00:00:00Z", dsun: 216.902752, hgln: -0.000000, hglt: -3.678015 },
  { isoDate: "2014-05-07T00:00:00Z", dsun: 216.953327, hgln: 0.000000, hglt: -3.572011 },
  { isoDate: "2014-05-08T00:00:00Z", dsun: 217.003324, hgln: -0.000000, hglt: -3.465063 },
  { isoDate: "2014-05-09T00:00:00Z", dsun: 217.052780, hgln: -0.000000, hglt: -3.357204 },
  { isoDate: "2014-05-10T00:00:00Z", dsun: 217.101731, hgln: -0.000000, hglt: -3.248463 },
  { isoDate: "2014-05-11T00:00:00Z", dsun: 217.150215, hgln: 0.000000, hglt: -3.138871 },
  { isoDate: "2014-05-12T00:00:00Z", dsun: 217.198268, hgln: 0.000000, hglt: -3.028458 },
  { isoDate: "2014-05-13T00:00:00Z", dsun: 217.245921, hgln: 0.000000, hglt: -2.917251 },
  { isoDate: "2014-05-14T00:00:00Z", dsun: 217.293200, hgln: 0.000000, hglt: -2.805280 },
  { isoDate: "2014-05-15T00:00:00Z", dsun: 217.340119, hgln: -0.000000, hglt: -2.692572 },
  { isoDate: "2014-05-16T00:00:00Z", dsun: 217.386678, hgln: -0.000000, hglt: -2.579156 },
  { isoDate: "2014-05-17T00:00:00Z", dsun: 217.432857, hgln: 0.000000, hglt: -2.465058 },
  { isoDate: "2014-05-18T00:00:00Z", dsun: 217.478618, hgln: -0.000000, hglt: -2.350308 },
  { isoDate: "2014-05-19T00:00:00Z", dsun: 217.523905, hgln: -0.000000, hglt: -2.234934 },
  { isoDate: "2014-05-20T00:00:00Z", dsun: 217.568646, hgln: -0.000000, hglt: -2.118967 },
  { isoDate: "2014-05-21T00:00:00Z", dsun: 217.612760, hgln: -0.000000, hglt: -2.002437 },
  { isoDate: "2014-05-22T00:00:00Z", dsun: 217.656160, hgln: -0.000000, hglt: -1.885376 },
  { isoDate: "2014-05-23T00:00:00Z", dsun: 217.698759, hgln: 0.000000, hglt: -1.767820 },
  { isoDate: "2014-05-24T00:00:00Z", dsun: 217.740476, hgln: -0.000000, hglt: -1.649802 },
  { isoDate: "2014-05-25T00:00:00Z", dsun: 217.781238, hgln: -0.000000, hglt: -1.531357 },
  { isoDate: "2014-05-26T00:00:00Z", dsun: 217.820985, hgln: -0.000000, hglt: -1.412522 },
  { isoDate: "2014-05-27T00:00:00Z", dsun: 217.859668, hgln: -0.000000, hglt: -1.293332 },
  { isoDate: "2014-05-28T00:00:00Z", dsun: 217.897253, hgln: -0.000000, hglt: -1.173825 },
  { isoDate: "2014-05-29T00:00:00Z", dsun: 217.933720, hgln: 0.000000, hglt: -1.054036 },
  { isoDate: "2014-05-30T00:00:00Z", dsun: 217.969063, hgln: -0.000000, hglt: -0.934001 },
  { isoDate: "2014-05-31T00:00:00Z", dsun: 218.003285, hgln: -0.000000, hglt: -0.813756 },
  { isoDate: "2014-06-01T00:00:00Z", dsun: 218.036401, hgln: -0.000000, hglt: -0.693335 },
  { isoDate: "2014-06-02T00:00:00Z", dsun: 218.068432, hgln: 0.000000, hglt: -0.572772 },
  { isoDate: "2014-06-03T00:00:00Z", dsun: 218.099405, hgln: -0.000000, hglt: -0.452102 },
  { isoDate: "2014-06-04T00:00:00Z", dsun: 218.129354, hgln: 0.000000, hglt: -0.331358 },
  { isoDate: "2014-06-05T00:00:00Z", dsun: 218.158315, hgln: -0.000000, hglt: -0.210572 },
  { isoDate: "2014-06-06T00:00:00Z", dsun: 218.186328, hgln: -0.000000, hglt: -0.089776 },
  { isoDate: "2014-06-07T00:00:00Z", dsun: 218.213436, hgln: -0.000000, hglt: 0.030999 },
  { isoDate: "2014-06-08T00:00:00Z", dsun: 218.239688, hgln: -0.000000, hglt: 0.151721 },
  { isoDate: "2014-06-09T00:00:00Z", dsun: 218.265132, hgln: 0.000000, hglt: 0.272360 },
  { isoDate: "2014-06-10T00:00:00Z", dsun: 218.289817, hgln: -0.000000, hglt: 0.392887 },
  { isoDate: "2014-06-11T00:00:00Z", dsun: 218.313793, hgln: 0.000000, hglt: 0.513273 },
  { isoDate: "2014-06-12T00:00:00Z", dsun: 218.337100, hgln: -0.000000, hglt: 0.633490 },
  { isoDate: "2014-06-13T00:00:00Z", dsun: 218.359770, hgln: -0.000000, hglt: 0.753511 },
  { isoDate: "2014-06-14T00:00:00Z", dsun: 218.381816, hgln: -0.000000, hglt: 0.873307 },
  { isoDate: "2014-06-15T00:00:00Z", dsun: 218.403231, hgln: 0.000000, hglt: 0.992852 },
  { isoDate: "2014-06-16T00:00:00Z", dsun: 218.423985, hgln: 0.000000, hglt: 1.112117 },
  { isoDate: "2014-06-17T00:00:00Z", dsun: 218.444026, hgln: -0.000000, hglt: 1.231073 },
  { isoDate: "2014-06-18T00:00:00Z", dsun: 218.463289, hgln: -0.000000, hglt: 1.349690 },
  { isoDate: "2014-06-19T00:00:00Z", dsun: 218.481697, hgln: -0.000000, hglt: 1.467937 },
  { isoDate: "2014-06-20T00:00:00Z", dsun: 218.499170, hgln: -0.000000, hglt: 1.585780 },
  { isoDate: "2014-06-21T00:00:00Z", dsun: 218.515632, hgln: -0.000000, hglt: 1.703187 },
  { isoDate: "2014-06-22T00:00:00Z", dsun: 218.531010, hgln: -0.000000, hglt: 1.820124 },
  { isoDate: "2014-06-23T00:00:00Z", dsun: 218.545244, hgln: -0.000000, hglt: 1.936558 },
  { isoDate: "2014-06-24T00:00:00Z", dsun: 218.558281, hgln: 0.000000, hglt: 2.052455 },
  { isoDate: "2014-06-25T00:00:00Z", dsun: 218.570081, hgln: 0.000000, hglt: 2.167780 },
  { isoDate: "2014-06-26T00:00:00Z", dsun: 218.580614, hgln: 0.000000, hglt: 2.282500 },
  { isoDate: "2014-06-27T00:00:00Z", dsun: 218.589865, hgln: 0.000000, hglt: 2.396583 },
  { isoDate: "2014-06-28T00:00:00Z", dsun: 218.597824, hgln: 0.000000, hglt: 2.509995 },
  { isoDate: "2014-06-29T00:00:00Z", dsun: 218.604497, hgln: 0.000000, hglt: 2.622704 },
  { isoDate: "2014-06-30T00:00:00Z", dsun: 218.609895, hgln: 0.000000, hglt: 2.734680 },
  { isoDate: "2014-07-01T00:00:00Z", dsun: 218.614039, hgln: -0.000000, hglt: 2.845890 },
  { isoDate: "2014-07-02T00:00:00Z", dsun: 218.616956, hgln: 0.000000, hglt: 2.956306 },
  { isoDate: "2014-07-03T00:00:00Z", dsun: 218.618680, hgln: 0.000000, hglt: 3.065897 },
  { isoDate: "2014-07-04T00:00:00Z", dsun: 218.619252, hgln: -0.000000, hglt: 3.174634 },
  { isoDate: "2014-07-05T00:00:00Z", dsun: 218.618717, hgln: 0.000000, hglt: 3.282490 },
  { isoDate: "2014-07-06T00:00:00Z", dsun: 218.617127, hgln: -0.000000, hglt: 3.389436 },
  { isoDate: "2014-07-07T00:00:00Z", dsun: 218.614537, hgln: 0.000000, hglt: 3.495446 },
  { isoDate: "2014-07-08T00:00:00Z", dsun: 218.611009, hgln: -0.000000, hglt: 3.600493 },
  { isoDate: "2014-07-09T00:00:00Z", dsun: 218.606608, hgln: 0.000000, hglt: 3.704552 },
  { isoDate: "2014-07-10T00:00:00Z", dsun: 218.601398, hgln: -0.000000, hglt: 3.807599 },
  { isoDate: "2014-07-11T00:00:00Z", dsun: 218.595436, hgln: -0.000000, hglt: 3.909607 },
  { isoDate: "2014-07-12T00:00:00Z", dsun: 218.588769, hgln: -0.000000, hglt: 4.010555 },
  { isoDate: "2014-07-13T00:00:00Z", dsun: 218.581427, hgln: -0.000000, hglt: 4.110417 },
  { isoDate: "2014-07-14T00:00:00Z", dsun: 218.573413, hgln: -0.000000, hglt: 4.209169 },
  { isoDate: "2014-07-15T00:00:00Z", dsun: 218.564707, hgln: 0.000000, hglt: 4.306786 },
  { isoDate: "2014-07-16T00:00:00Z", dsun: 218.555268, hgln: -0.000000, hglt: 4.403243 },
  { isoDate: "2014-07-17T00:00:00Z", dsun: 218.545035, hgln: 0.000000, hglt: 4.498512 },
  { isoDate: "2014-07-18T00:00:00Z", dsun: 218.533943, hgln: -0.000000, hglt: 4.592567 },
  { isoDate: "2014-07-19T00:00:00Z", dsun: 218.521921, hgln: 0.000000, hglt: 4.685378 },
  { isoDate: "2014-07-20T00:00:00Z", dsun: 218.508902, hgln: 0.000000, hglt: 4.776919 },
  { isoDate: "2014-07-21T00:00:00Z", dsun: 218.494826, hgln: 0.000000, hglt: 4.867162 },
  { isoDate: "2014-07-22T00:00:00Z", dsun: 218.479642, hgln: 0.000000, hglt: 4.956078 },
  { isoDate: "2014-07-23T00:00:00Z", dsun: 218.463307, hgln: 0.000000, hglt: 5.043640 },
  { isoDate: "2014-07-24T00:00:00Z", dsun: 218.445786, hgln: -0.000000, hglt: 5.129822 },
  { isoDate: "2014-07-25T00:00:00Z", dsun: 218.427056, hgln: 0.000000, hglt: 5.214597 },
  { isoDate: "2014-07-26T00:00:00Z", dsun: 218.407101, hgln: -0.000000, hglt: 5.297940 },
  { isoDate: "2014-07-27T00:00:00Z", dsun: 218.385915, hgln: 0.000000, hglt: 5.379825 },
  { isoDate: "2014-07-28T00:00:00Z", dsun: 218.363502, hgln: -0.000000, hglt: 5.460228 },
  { isoDate: "2014-07-29T00:00:00Z", dsun: 218.339874, hgln: 0.000000, hglt: 5.539126 },
  { isoDate: "2014-07-30T00:00:00Z", dsun: 218.315050, hgln: 0.000000, hglt: 5.616495 },
  { isoDate: "2014-07-31T00:00:00Z", dsun: 218.289059, hgln: -0.000000, hglt: 5.692314 },
  { isoDate: "2014-08-01T00:00:00Z", dsun: 218.261936, hgln: -0.000000, hglt: 5.766561 },
  { isoDate: "2014-08-02T00:00:00Z", dsun: 218.233727, hgln: 0.000000, hglt: 5.839215 },
  { isoDate: "2014-08-03T00:00:00Z", dsun: 218.204481, hgln: -0.000000, hglt: 5.910255 },
  { isoDate: "2014-08-04T00:00:00Z", dsun: 218.174259, hgln: -0.000000, hglt: 5.979663 },
  { isoDate: "2014-08-05T00:00:00Z", dsun: 218.143125, hgln: 0.000000, hglt: 6.047420 },
  { isoDate: "2014-08-06T00:00:00Z", dsun: 218.111152, hgln: 0.000000, hglt: 6.113507 },
  { isoDate: "2014-08-07T00:00:00Z", dsun: 218.078418, hgln: 0.000000, hglt: 6.177908 },
  { isoDate: "2014-08-08T00:00:00Z", dsun: 218.044999, hgln: 0.000000, hglt: 6.240605 },
  { isoDate: "2014-08-09T00:00:00Z", dsun: 218.010969, hgln: 0.000000, hglt: 6.301581 },
  { isoDate: "2014-08-10T00:00:00Z", dsun: 217.976387, hgln: -0.000000, hglt: 6.360819 },
  { isoDate: "2014-08-11T00:00:00Z", dsun: 217.941296, hgln: -0.000000, hglt: 6.418303 },
  { isoDate: "2014-08-12T00:00:00Z", dsun: 217.905712, hgln: -0.000000, hglt: 6.474014 },
  { isoDate: "2014-08-13T00:00:00Z", dsun: 217.869625, hgln: -0.000000, hglt: 6.527936 },
  { isoDate: "2014-08-14T00:00:00Z", dsun: 217.833003, hgln: 0.000000, hglt: 6.580050 },
  { isoDate: "2014-08-15T00:00:00Z", dsun: 217.795800, hgln: -0.000000, hglt: 6.630338 },
  { isoDate: "2014-08-16T00:00:00Z", dsun: 217.757957, hgln: -0.000000, hglt: 6.678781 },
  { isoDate: "2014-08-17T00:00:00Z", dsun: 217.719418, hgln: -0.000000, hglt: 6.725361 },
  { isoDate: "2014-08-18T00:00:00Z", dsun: 217.680126, hgln: -0.000000, hglt: 6.770060 },
  { isoDate: "2014-08-19T00:00:00Z", dsun: 217.640031, hgln: -0.000000, hglt: 6.812863 },
  { isoDate: "2014-08-20T00:00:00Z", dsun: 217.599090, hgln: 0.000000, hglt: 6.853752 },
  { isoDate: "2014-08-21T00:00:00Z", dsun: 217.557265, hgln: 0.000000, hglt: 6.892712 },
  { isoDate: "2014-08-22T00:00:00Z", dsun: 217.514529, hgln: 0.000000, hglt: 6.929729 },
  { isoDate: "2014-08-23T00:00:00Z", dsun: 217.470860, hgln: -0.000000, hglt: 6.964788 },
  { isoDate: "2014-08-24T00:00:00Z", dsun: 217.426244, hgln: 0.000000, hglt: 6.997877 },
  { isoDate: "2014-08-25T00:00:00Z", dsun: 217.380675, hgln: 0.000000, hglt: 7.028984 },
  { isoDate: "2014-08-26T00:00:00Z", dsun: 217.334154, hgln: -0.000000, hglt: 7.058096 },
  { isoDate: "2014-08-27T00:00:00Z", dsun: 217.286692, hgln: 0.000000, hglt: 7.085205 },
  { isoDate: "2014-08-28T00:00:00Z", dsun: 217.238308, hgln: 0.000000, hglt: 7.110299 },
  { isoDate: "2014-08-29T00:00:00Z", dsun: 217.189032, hgln: 0.000000, hglt: 7.133371 },
  { isoDate: "2014-08-30T00:00:00Z", dsun: 217.138900, hgln: 0.000000, hglt: 7.154413 },
  { isoDate: "2014-08-31T00:00:00Z", dsun: 217.087959, hgln: 0.000000, hglt: 7.173417 },
  { isoDate: "2014-09-01T00:00:00Z", dsun: 217.036267, hgln: -0.000000, hglt: 7.190376 },
  { isoDate: "2014-09-02T00:00:00Z", dsun: 216.983888, hgln: -0.000000, hglt: 7.205286 },
  { isoDate: "2014-09-03T00:00:00Z", dsun: 216.930896, hgln: 0.000000, hglt: 7.218141 },
  { isoDate: "2014-09-04T00:00:00Z", dsun: 216.877374, hgln: -0.000000, hglt: 7.228936 },
  { isoDate: "2014-09-05T00:00:00Z", dsun: 216.823409, hgln: -0.000000, hglt: 7.237667 },
  { isoDate: "2014-09-06T00:00:00Z", dsun: 216.769087, hgln: 0.000000, hglt: 7.244329 },
  { isoDate: "2014-09-07T00:00:00Z", dsun: 216.714491, hgln: -0.000000, hglt: 7.248918 },
  { isoDate: "2014-09-08T00:00:00Z", dsun: 216.659692, hgln: -0.000000, hglt: 7.251430 },
  { isoDate: "2014-09-09T00:00:00Z", dsun: 216.604739, hgln: -0.000000, hglt: 7.251859 },
  { isoDate: "2014-09-10T00:00:00Z", dsun: 216.549659, hgln: 0.000000, hglt: 7.250200 },
  { isoDate: "2014-09-11T00:00:00Z", dsun: 216.494455, hgln: -0.000000, hglt: 7.246449 },
  { isoDate: "2014-09-12T00:00:00Z", dsun: 216.439106, hgln: -0.000000, hglt: 7.240600 },
  { isoDate: "2014-09-13T00:00:00Z", dsun: 216.383576, hgln: -0.000000, hglt: 7.232649 },
  { isoDate: "2014-09-14T00:00:00Z", dsun: 216.327822, hgln: -0.000000, hglt: 7.222593 },
  { isoDate: "2014-09-15T00:00:00Z", dsun: 216.271797, hgln: -0.000000, hglt: 7.210429 },
  { isoDate: "2014-09-16T00:00:00Z", dsun: 216.215454, hgln: 0.000000, hglt: 7.196156 },
  { isoDate: "2014-09-17T00:00:00Z", dsun: 216.158752, hgln: 0.000000, hglt: 7.179773 },
  { isoDate: "2014-09-18T00:00:00Z", dsun: 216.101653, hgln: 0.000000, hglt: 7.161282 },
  { isoDate: "2014-09-19T00:00:00Z", dsun: 216.044125, hgln: 0.000000, hglt: 7.140683 },
  { isoDate: "2014-09-20T00:00:00Z", dsun: 215.986140, hgln: 0.000000, hglt: 7.117980 },
  { isoDate: "2014-09-21T00:00:00Z", dsun: 215.927678, hgln: -0.000000, hglt: 7.093176 },
  { isoDate: "2014-09-22T00:00:00Z", dsun: 215.868722, hgln: -0.000000, hglt: 7.066275 },
  { isoDate: "2014-09-23T00:00:00Z", dsun: 215.809263, hgln: -0.000000, hglt: 7.037284 },
  { isoDate: "2014-09-24T00:00:00Z", dsun: 215.749299, hgln: 0.000000, hglt: 7.006210 },
  { isoDate: "2014-09-25T00:00:00Z", dsun: 215.688836, hgln: 0.000000, hglt: 6.973059 },
  { isoDate: "2014-09-26T00:00:00Z", dsun: 215.627891, hgln: 0.000000, hglt: 6.937842 },
  { isoDate: "2014-09-27T00:00:00Z", dsun: 215.566489, hgln: -0.000000, hglt: 6.900566 },
  { isoDate: "2014-09-28T00:00:00Z", dsun: 215.504667, hgln: 0.000000, hglt: 6.861244 },
  { isoDate: "2014-09-29T00:00:00Z", dsun: 215.442475, hgln: -0.000000, hglt: 6.819886 },
  { isoDate: "2014-09-30T00:00:00Z", dsun: 215.379971, hgln: -0.000000, hglt: 6.776503 },
  { isoDate: "2014-10-01T00:00:00Z", dsun: 215.317227, hgln: -0.000000, hglt: 6.731108 },
  { isoDate: "2014-10-02T00:00:00Z", dsun: 215.254322, hgln: 0.000000, hglt: 6.683713 },
  { isoDate: "2014-10-03T00:00:00Z", dsun: 215.191345, hgln: 0.000000, hglt: 6.634331 },
  { isoDate: "2014-10-04T00:00:00Z", dsun: 215.128388, hgln: -0.000000, hglt: 6.582974 },
  { isoDate: "2014-10-05T00:00:00Z", dsun: 215.065542, hgln: -0.000000, hglt: 6.529653 },
  { isoDate: "2014-10-06T00:00:00Z", dsun: 215.002893, hgln: 0.000000, hglt: 6.474382 },
  { isoDate: "2014-10-07T00:00:00Z", dsun: 214.940516, hgln: 0.000000, hglt: 6.417171 },
  { isoDate: "2014-10-08T00:00:00Z", dsun: 214.878465, hgln: -0.000000, hglt: 6.358032 },
  { isoDate: "2014-10-09T00:00:00Z", dsun: 214.816773, hgln: 0.000000, hglt: 6.296975 },
  { isoDate: "2014-10-10T00:00:00Z", dsun: 214.755452, hgln: 0.000000, hglt: 6.234012 },
  { isoDate: "2014-10-11T00:00:00Z", dsun: 214.694494, hgln: -0.000000, hglt: 6.169157 },
  { isoDate: "2014-10-12T00:00:00Z", dsun: 214.633873, hgln: 0.000000, hglt: 6.102421 },
  { isoDate: "2014-10-13T00:00:00Z", dsun: 214.573559, hgln: 0.000000, hglt: 6.033821 },
  { isoDate: "2014-10-14T00:00:00Z", dsun: 214.513514, hgln: 0.000000, hglt: 5.963372 },
  { isoDate: "2014-10-15T00:00:00Z", dsun: 214.453700, hgln: 0.000000, hglt: 5.891092 },
  { isoDate: "2014-10-16T00:00:00Z", dsun: 214.394080, hgln: 0.000000, hglt: 5.816998 },
  { isoDate: "2014-10-17T00:00:00Z", dsun: 214.334619, hgln: 0.000000, hglt: 5.741111 },
  { isoDate: "2014-10-18T00:00:00Z", dsun: 214.275286, hgln: 0.000000, hglt: 5.663451 },
  { isoDate: "2014-10-19T00:00:00Z", dsun: 214.216053, hgln: -0.000000, hglt: 5.584040 },
  { isoDate: "2014-10-20T00:00:00Z", dsun: 214.156893, hgln: 0.000000, hglt: 5.502901 },
  { isoDate: "2014-10-21T00:00:00Z", dsun: 214.097784, hgln: 0.000000, hglt: 5.420057 },
  { isoDate: "2014-10-22T00:00:00Z", dsun: 214.038711, hgln: 0.000000, hglt: 5.335534 },
  { isoDate: "2014-10-23T00:00:00Z", dsun: 213.979662, hgln: 0.000000, hglt: 5.249357 },
  { isoDate: "2014-10-24T00:00:00Z", dsun: 213.920634, hgln: 0.000000, hglt: 5.161553 },
  { isoDate: "2014-10-25T00:00:00Z", dsun: 213.861635, hgln: 0.000000, hglt: 5.072150 },
  { isoDate: "2014-10-26T00:00:00Z", dsun: 213.802685, hgln: -0.000000, hglt: 4.981177 },
  { isoDate: "2014-10-27T00:00:00Z", dsun: 213.743815, hgln: -0.000000, hglt: 4.888662 },
  { isoDate: "2014-10-28T00:00:00Z", dsun: 213.685073, hgln: 0.000000, hglt: 4.794636 },
  { isoDate: "2014-10-29T00:00:00Z", dsun: 213.626519, hgln: 0.000000, hglt: 4.699127 },
  { isoDate: "2014-10-30T00:00:00Z", dsun: 213.568225, hgln: 0.000000, hglt: 4.602165 },
  { isoDate: "2014-10-31T00:00:00Z", dsun: 213.510272, hgln: 0.000000, hglt: 4.503780 },
  { isoDate: "2014-11-01T00:00:00Z", dsun: 213.452751, hgln: 0.000000, hglt: 4.404000 },
  { isoDate: "2014-11-02T00:00:00Z", dsun: 213.395753, hgln: -0.000000, hglt: 4.302854 },
  { isoDate: "2014-11-03T00:00:00Z", dsun: 213.339368, hgln: -0.000000, hglt: 4.200370 },
  { isoDate: "2014-11-04T00:00:00Z", dsun: 213.283679, hgln: 0.000000, hglt: 4.096574 },
  { isoDate: "2014-11-05T00:00:00Z", dsun: 213.228756, hgln: 0.000000, hglt: 3.991493 },
  { isoDate: "2014-11-06T00:00:00Z", dsun: 213.174654, hgln: -0.000000, hglt: 3.885155 },
  { isoDate: "2014-11-07T00:00:00Z", dsun: 213.121407, hgln: 0.000000, hglt: 3.777586 },
  { isoDate: "2014-11-08T00:00:00Z", dsun: 213.069034, hgln: -0.000000, hglt: 3.668814 },
  { isoDate: "2014-11-09T00:00:00Z", dsun: 213.017533, hgln: -0.000000, hglt: 3.558868 },
  { isoDate: "2014-11-10T00:00:00Z", dsun: 212.966890, hgln: -0.000000, hglt: 3.447777 },
  { isoDate: "2014-11-11T00:00:00Z", dsun: 212.917081, hgln: 0.000000, hglt: 3.335571 },
  { isoDate: "2014-11-12T00:00:00Z", dsun: 212.868077, hgln: 0.000000, hglt: 3.222284 },
  { isoDate: "2014-11-13T00:00:00Z", dsun: 212.819844, hgln: 0.000000, hglt: 3.107947 },
  { isoDate: "2014-11-14T00:00:00Z", dsun: 212.772347, hgln: 0.000000, hglt: 2.992595 },
  { isoDate: "2014-11-15T00:00:00Z", dsun: 212.725553, hgln: -0.000000, hglt: 2.876263 },
  { isoDate: "2014-11-16T00:00:00Z", dsun: 212.679424, hgln: 0.000000, hglt: 2.758986 },
  { isoDate: "2014-11-17T00:00:00Z", dsun: 212.633929, hgln: -0.000000, hglt: 2.640802 },
  { isoDate: "2014-11-18T00:00:00Z", dsun: 212.589032, hgln: 0.000000, hglt: 2.521748 },
  { isoDate: "2014-11-19T00:00:00Z", dsun: 212.544701, hgln: 0.000000, hglt: 2.401862 },
  { isoDate: "2014-11-20T00:00:00Z", dsun: 212.500908, hgln: -0.000000, hglt: 2.281183 },
  { isoDate: "2014-11-21T00:00:00Z", dsun: 212.457626, hgln: -0.000000, hglt: 2.159753 },
  { isoDate: "2014-11-22T00:00:00Z", dsun: 212.414838, hgln: -0.000000, hglt: 2.037611 },
  { isoDate: "2014-11-23T00:00:00Z", dsun: 212.372537, hgln: -0.000000, hglt: 1.914799 },
  { isoDate: "2014-11-24T00:00:00Z", dsun: 212.330729, hgln: -0.000000, hglt: 1.791358 },
  { isoDate: "2014-11-25T00:00:00Z", dsun: 212.289437, hgln: -0.000000, hglt: 1.667332 },
  { isoDate: "2014-11-26T00:00:00Z", dsun: 212.248700, hgln: -0.000000, hglt: 1.542761 },
  { isoDate: "2014-11-27T00:00:00Z", dsun: 212.208573, hgln: -0.000000, hglt: 1.417686 },
  { isoDate: "2014-11-28T00:00:00Z", dsun: 212.169126, hgln: 0.000000, hglt: 1.292148 },
  { isoDate: "2014-11-29T00:00:00Z", dsun: 212.130439, hgln: 0.000000, hglt: 1.166187 },
  { isoDate: "2014-11-30T00:00:00Z", dsun: 212.092596, hgln: 0.000000, hglt: 1.039842 },
  { isoDate: "2014-12-01T00:00:00Z", dsun: 212.055683, hgln: -0.000000, hglt: 0.913149 },
  { isoDate: "2014-12-02T00:00:00Z", dsun: 212.019782, hgln: -0.000000, hglt: 0.786147 },
  { isoDate: "2014-12-03T00:00:00Z", dsun: 211.984965, hgln: -0.000000, hglt: 0.658871 },
  { isoDate: "2014-12-04T00:00:00Z", dsun: 211.951297, hgln: -0.000000, hglt: 0.531359 },
  { isoDate: "2014-12-05T00:00:00Z", dsun: 211.918824, hgln: -0.000000, hglt: 0.403646 },
  { isoDate: "2014-12-06T00:00:00Z", dsun: 211.887579, hgln: 0.000000, hglt: 0.275769 },
  { isoDate: "2014-12-07T00:00:00Z", dsun: 211.857580, hgln: 0.000000, hglt: 0.147765 },
  { isoDate: "2014-12-08T00:00:00Z", dsun: 211.828830, hgln: 0.000000, hglt: 0.019672 },
  { isoDate: "2014-12-09T00:00:00Z", dsun: 211.801320, hgln: -0.000000, hglt: -0.108474 },
  { isoDate: "2014-12-10T00:00:00Z", dsun: 211.775030, hgln: 0.000000, hglt: -0.236631 },
  { isoDate: "2014-12-11T00:00:00Z", dsun: 211.749935, hgln: 0.000000, hglt: -0.364761 },
  { isoDate: "2014-12-12T00:00:00Z", dsun: 211.726001, hgln: -0.000000, hglt: -0.492824 },
  { isoDate: "2014-12-13T00:00:00Z", dsun: 211.703195, hgln: 0.000000, hglt: -0.620779 },
  { isoDate: "2014-12-14T00:00:00Z", dsun: 211.681477, hgln: 0.000000, hglt: -0.748583 },
  { isoDate: "2014-12-15T00:00:00Z", dsun: 211.660806, hgln: -0.000000, hglt: -0.876196 },
  { isoDate: "2014-12-16T00:00:00Z", dsun: 211.641140, hgln: -0.000000, hglt: -1.003575 },
  { isoDate: "2014-12-17T00:00:00Z", dsun: 211.622434, hgln: -0.000000, hglt: -1.130677 },
  { isoDate: "2014-12-18T00:00:00Z", dsun: 211.604641, hgln: -0.000000, hglt: -1.257460 },
  { isoDate: "2014-12-19T00:00:00Z", dsun: 211.587715, hgln: 0.000000, hglt: -1.383878 },
  { isoDate: "2014-12-20T00:00:00Z", dsun: 211.571612, hgln: 0.000000, hglt: -1.509889 },
  { isoDate: "2014-12-21T00:00:00Z", dsun: 211.556295, hgln: -0.000000, hglt: -1.635449 },
  { isoDate: "2014-12-22T00:00:00Z", dsun: 211.541735, hgln: -0.000000, hglt: -1.760512 },
  { isoDate: "2014-12-23T00:00:00Z", dsun: 211.527920, hgln: -0.000000, hglt: -1.885036 },
  { isoDate: "2014-12-24T00:00:00Z", dsun: 211.514857, hgln: 0.000000, hglt: -2.008976 },
  { isoDate: "2014-12-25T00:00:00Z", dsun: 211.502573, hgln: 0.000000, hglt: -2.132289 },
  { isoDate: "2014-12-26T00:00:00Z", dsun: 211.491115, hgln: 0.000000, hglt: -2.254936 },
  { isoDate: "2014-12-27T00:00:00Z", dsun: 211.480544, hgln: -0.000000, hglt: -2.376874 },
  { isoDate: "2014-12-28T00:00:00Z", dsun: 211.470932, hgln: 0.000000, hglt: -2.498066 },
  { isoDate: "2014-12-29T00:00:00Z", dsun: 211.462355, hgln: -0.000000, hglt: -2.618474 },
  { isoDate: "2014-12-30T00:00:00Z", dsun: 211.454889, hgln: 0.000000, hglt: -2.738061 },
  { isoDate: "2014-12-31T00:00:00Z", dsun: 211.448602, hgln: -0.000000, hglt: -2.856791 },
  { isoDate: "2015-01-01T00:00:00Z", dsun: 211.443557, hgln: -0.000000, hglt: -2.974628 },
  { isoDate: "2015-01-02T00:00:00Z", dsun: 211.439804, hgln: -0.000000, hglt: -3.091537 },
  { isoDate: "2015-01-03T00:00:00Z", dsun: 211.437382, hgln: -0.000000, hglt: -3.207484 },
  { isoDate: "2015-01-04T00:00:00Z", dsun: 211.436319, hgln: 0.000000, hglt: -3.322433 },
  { isoDate: "2015-01-05T00:00:00Z", dsun: 211.436628, hgln: -0.000000, hglt: -3.436349 },
  { isoDate: "2015-01-06T00:00:00Z", dsun: 211.438313, hgln: -0.000000, hglt: -3.549197 },
  { isoDate: "2015-01-07T00:00:00Z", dsun: 211.441365, hgln: 0.000000, hglt: -3.660941 },
  { isoDate: "2015-01-08T00:00:00Z", dsun: 211.445767, hgln: 0.000000, hglt: -3.771547 },
  { isoDate: "2015-01-09T00:00:00Z", dsun: 211.451492, hgln: 0.000000, hglt: -3.880978 },
  { isoDate: "2015-01-10T00:00:00Z", dsun: 211.458509, hgln: 0.000000, hglt: -3.989199 },
  { isoDate: "2015-01-11T00:00:00Z", dsun: 211.466778, hgln: 0.000000, hglt: -4.096174 },
  { isoDate: "2015-01-12T00:00:00Z", dsun: 211.476257, hgln: -0.000000, hglt: -4.201868 },
  { isoDate: "2015-01-13T00:00:00Z", dsun: 211.486896, hgln: 0.000000, hglt: -4.306245 },
  { isoDate: "2015-01-14T00:00:00Z", dsun: 211.498645, hgln: 0.000000, hglt: -4.409270 },
  { isoDate: "2015-01-15T00:00:00Z", dsun: 211.511445, hgln: -0.000000, hglt: -4.510907 },
  { isoDate: "2015-01-16T00:00:00Z", dsun: 211.525236, hgln: 0.000000, hglt: -4.611121 },
  { isoDate: "2015-01-17T00:00:00Z", dsun: 211.539955, hgln: -0.000000, hglt: -4.709877 },
  { isoDate: "2015-01-18T00:00:00Z", dsun: 211.555536, hgln: 0.000000, hglt: -4.807141 },
  { isoDate: "2015-01-19T00:00:00Z", dsun: 211.571922, hgln: 0.000000, hglt: -4.902879 },
  { isoDate: "2015-01-20T00:00:00Z", dsun: 211.589062, hgln: 0.000000, hglt: -4.997057 },
  { isoDate: "2015-01-21T00:00:00Z", dsun: 211.606923, hgln: 0.000000, hglt: -5.089643 },
  { isoDate: "2015-01-22T00:00:00Z", dsun: 211.625492, hgln: -0.000000, hglt: -5.180604 },
  { isoDate: "2015-01-23T00:00:00Z", dsun: 211.644783, hgln: -0.000000, hglt: -5.269912 },
  { isoDate: "2015-01-24T00:00:00Z", dsun: 211.664827, hgln: -0.000000, hglt: -5.357539 },
  { isoDate: "2015-01-25T00:00:00Z", dsun: 211.685678, hgln: -0.000000, hglt: -5.443456 },
  { isoDate: "2015-01-26T00:00:00Z", dsun: 211.707395, hgln: -0.000000, hglt: -5.527639 },
  { isoDate: "2015-01-27T00:00:00Z", dsun: 211.730044, hgln: -0.000000, hglt: -5.610064 },
  { isoDate: "2015-01-28T00:00:00Z", dsun: 211.753689, hgln: -0.000000, hglt: -5.690705 },
  { isoDate: "2015-01-29T00:00:00Z", dsun: 211.778386, hgln: -0.000000, hglt: -5.769541 },
  { isoDate: "2015-01-30T00:00:00Z", dsun: 211.804186, hgln: 0.000000, hglt: -5.846549 },
  { isoDate: "2015-01-31T00:00:00Z", dsun: 211.831129, hgln: -0.000000, hglt: -5.921707 },
  { isoDate: "2015-02-01T00:00:00Z", dsun: 211.859246, hgln: 0.000000, hglt: -5.994993 },
  { isoDate: "2015-02-02T00:00:00Z", dsun: 211.888557, hgln: -0.000000, hglt: -6.066386 },
  { isoDate: "2015-02-03T00:00:00Z", dsun: 211.919074, hgln: -0.000000, hglt: -6.135864 },
  { isoDate: "2015-02-04T00:00:00Z", dsun: 211.950798, hgln: 0.000000, hglt: -6.203407 },
  { isoDate: "2015-02-05T00:00:00Z", dsun: 211.983721, hgln: -0.000000, hglt: -6.268995 },
  { isoDate: "2015-02-06T00:00:00Z", dsun: 212.017826, hgln: 0.000000, hglt: -6.332607 },
  { isoDate: "2015-02-07T00:00:00Z", dsun: 212.053087, hgln: -0.000000, hglt: -6.394223 },
  { isoDate: "2015-02-08T00:00:00Z", dsun: 212.089471, hgln: 0.000000, hglt: -6.453824 },
  { isoDate: "2015-02-09T00:00:00Z", dsun: 212.126937, hgln: 0.000000, hglt: -6.511391 },
  { isoDate: "2015-02-10T00:00:00Z", dsun: 212.165438, hgln: -0.000000, hglt: -6.566907 },
  { isoDate: "2015-02-11T00:00:00Z", dsun: 212.204920, hgln: -0.000000, hglt: -6.620352 },
  { isoDate: "2015-02-12T00:00:00Z", dsun: 212.245323, hgln: -0.000000, hglt: -6.671710 },
  { isoDate: "2015-02-13T00:00:00Z", dsun: 212.286578, hgln: 0.000000, hglt: -6.720964 },
  { isoDate: "2015-02-14T00:00:00Z", dsun: 212.328614, hgln: 0.000000, hglt: -6.768098 },
  { isoDate: "2015-02-15T00:00:00Z", dsun: 212.371352, hgln: -0.000000, hglt: -6.813098 },
  { isoDate: "2015-02-16T00:00:00Z", dsun: 212.414712, hgln: -0.000000, hglt: -6.855949 },
  { isoDate: "2015-02-17T00:00:00Z", dsun: 212.458618, hgln: -0.000000, hglt: -6.896638 },
  { isoDate: "2015-02-18T00:00:00Z", dsun: 212.503001, hgln: 0.000000, hglt: -6.935154 },
  { isoDate: "2015-02-19T00:00:00Z", dsun: 212.547810, hgln: -0.000000, hglt: -6.971486 },
  { isoDate: "2015-02-20T00:00:00Z", dsun: 212.593015, hgln: -0.000000, hglt: -7.005626 },
  { isoDate: "2015-02-21T00:00:00Z", dsun: 212.638615, hgln: -0.000000, hglt: -7.037567 },
  { isoDate: "2015-02-22T00:00:00Z", dsun: 212.684631, hgln: 0.000000, hglt: -7.067304 },
  { isoDate: "2015-02-23T00:00:00Z", dsun: 212.731101, hgln: -0.000000, hglt: -7.094831 },
  { isoDate: "2015-02-24T00:00:00Z", dsun: 212.778076, hgln: -0.000000, hglt: -7.120146 },
  { isoDate: "2015-02-25T00:00:00Z", dsun: 212.825611, hgln: -0.000000, hglt: -7.143245 },
  { isoDate: "2015-02-26T00:00:00Z", dsun: 212.873759, hgln: 0.000000, hglt: -7.164125 },
  { isoDate: "2015-02-27T00:00:00Z", dsun: 212.922569, hgln: -0.000000, hglt: -7.182784 },
  { isoDate: "2015-02-28T00:00:00Z", dsun: 212.972082, hgln: 0.000000, hglt: -7.199221 },
  { isoDate: "2015-03-01T00:00:00Z", dsun: 213.022332, hgln: -0.000000, hglt: -7.213434 },
  { isoDate: "2015-03-02T00:00:00Z", dsun: 213.073344, hgln: 0.000000, hglt: -7.225422 },
  { isoDate: "2015-03-03T00:00:00Z", dsun: 213.125139, hgln: -0.000000, hglt: -7.235184 },
  { isoDate: "2015-03-04T00:00:00Z", dsun: 213.177725, hgln: -0.000000, hglt: -7.242720 },
  { isoDate: "2015-03-05T00:00:00Z", dsun: 213.231106, hgln: -0.000000, hglt: -7.248030 },
  { isoDate: "2015-03-06T00:00:00Z", dsun: 213.285277, hgln: 0.000000, hglt: -7.251115 },
  { isoDate: "2015-03-07T00:00:00Z", dsun: 213.340222, hgln: -0.000000, hglt: -7.251975 },
  { isoDate: "2015-03-08T00:00:00Z", dsun: 213.395919, hgln: 0.000000, hglt: -7.250613 },
  { isoDate: "2015-03-09T00:00:00Z", dsun: 213.452338, hgln: -0.000000, hglt: -7.247030 },
  { isoDate: "2015-03-10T00:00:00Z", dsun: 213.509437, hgln: -0.000000, hglt: -7.241230 },
  { isoDate: "2015-03-11T00:00:00Z", dsun: 213.567170, hgln: -0.000000, hglt: -7.233214 },
  { isoDate: "2015-03-12T00:00:00Z", dsun: 213.625480, hgln: -0.000000, hglt: -7.222989 },
  { isoDate: "2015-03-13T00:00:00Z", dsun: 213.684300, hgln: 0.000000, hglt: -7.210557 },
  { isoDate: "2015-03-14T00:00:00Z", dsun: 213.743559, hgln: -0.000000, hglt: -7.195926 },
  { isoDate: "2015-03-15T00:00:00Z", dsun: 213.803175, hgln: 0.000000, hglt: -7.179101 },
  { isoDate: "2015-03-16T00:00:00Z", dsun: 213.863061, hgln: -0.000000, hglt: -7.160091 },
  { isoDate: "2015-03-17T00:00:00Z", dsun: 213.923129, hgln: 0.000000, hglt: -7.138905 },
  { isoDate: "2015-03-18T00:00:00Z", dsun: 213.983291, hgln: -0.000000, hglt: -7.115554 },
  { isoDate: "2015-03-19T00:00:00Z", dsun: 214.043468, hgln: 0.000000, hglt: -7.090050 },
  { isoDate: "2015-03-20T00:00:00Z", dsun: 214.103599, hgln: 0.000000, hglt: -7.062406 },
  { isoDate: "2015-03-21T00:00:00Z", dsun: 214.163645, hgln: -0.000000, hglt: -7.032639 },
  { isoDate: "2015-03-22T00:00:00Z", dsun: 214.223592, hgln: 0.000000, hglt: -7.000764 },
  { isoDate: "2015-03-23T00:00:00Z", dsun: 214.283451, hgln: 0.000000, hglt: -6.966799 },
  { isoDate: "2015-03-24T00:00:00Z", dsun: 214.343251, hgln: 0.000000, hglt: -6.930761 },
  { isoDate: "2015-03-25T00:00:00Z", dsun: 214.403034, hgln: 0.000000, hglt: -6.892667 },
  { isoDate: "2015-03-26T00:00:00Z", dsun: 214.462846, hgln: 0.000000, hglt: -6.852535 },
  { isoDate: "2015-03-27T00:00:00Z", dsun: 214.522733, hgln: 0.000000, hglt: -6.810383 },
  { isoDate: "2015-03-28T00:00:00Z", dsun: 214.582738, hgln: -0.000000, hglt: -6.766227 },
  { isoDate: "2015-03-29T00:00:00Z", dsun: 214.642899, hgln: 0.000000, hglt: -6.720084 },
  { isoDate: "2015-03-30T00:00:00Z", dsun: 214.703249, hgln: -0.000000, hglt: -6.671974 },
  { isoDate: "2015-03-31T00:00:00Z", dsun: 214.763815, hgln: 0.000000, hglt: -6.621913 },
  { isoDate: "2015-04-01T00:00:00Z", dsun: 214.824617, hgln: 0.000000, hglt: -6.569918 },
  { isoDate: "2015-04-02T00:00:00Z", dsun: 214.885671, hgln: 0.000000, hglt: -6.516008 },
  { isoDate: "2015-04-03T00:00:00Z", dsun: 214.946986, hgln: 0.000000, hglt: -6.460202 },
  { isoDate: "2015-04-04T00:00:00Z", dsun: 215.008561, hgln: 0.000000, hglt: -6.402517 },
  { isoDate: "2015-04-05T00:00:00Z", dsun: 215.070392, hgln: 0.000000, hglt: -6.342971 },
  { isoDate: "2015-04-06T00:00:00Z", dsun: 215.132463, hgln: 0.000000, hglt: -6.281585 },
  { isoDate: "2015-04-07T00:00:00Z", dsun: 215.194749, hgln: 0.000000, hglt: -6.218376 },
  { isoDate: "2015-04-08T00:00:00Z", dsun: 215.257216, hgln: 0.000000, hglt: -6.153366 },
  { isoDate: "2015-04-09T00:00:00Z", dsun: 215.319818, hgln: -0.000000, hglt: -6.086573 },
  { isoDate: "2015-04-10T00:00:00Z", dsun: 215.382500, hgln: 0.000000, hglt: -6.018019 },
  { isoDate: "2015-04-11T00:00:00Z", dsun: 215.445197, hgln: -0.000000, hglt: -5.947726 },
  { isoDate: "2015-04-12T00:00:00Z", dsun: 215.507832, hgln: 0.000000, hglt: -5.875716 },
  { isoDate: "2015-04-13T00:00:00Z", dsun: 215.570323, hgln: -0.000000, hglt: -5.802012 },
  { isoDate: "2015-04-14T00:00:00Z", dsun: 215.632579, hgln: -0.000000, hglt: -5.726640 },
  { isoDate: "2015-04-15T00:00:00Z", dsun: 215.694509, hgln: 0.000000, hglt: -5.649626 },
  { isoDate: "2015-04-16T00:00:00Z", dsun: 215.756024, hgln: 0.000000, hglt: -5.570997 },
  { isoDate: "2015-04-17T00:00:00Z", dsun: 215.817044, hgln: 0.000000, hglt: -5.490783 },
  { isoDate: "2015-04-18T00:00:00Z", dsun: 215.877506, hgln: 0.000000, hglt: -5.409013 },
  { isoDate: "2015-04-19T00:00:00Z", dsun: 215.937368, hgln: -0.000000, hglt: -5.325718 },
  { isoDate: "2015-04-20T00:00:00Z", dsun: 215.996612, hgln: 0.000000, hglt: -5.240930 },
  { isoDate: "2015-04-21T00:00:00Z", dsun: 216.055242, hgln: 0.000000, hglt: -5.154680 },
  { isoDate: "2015-04-22T00:00:00Z", dsun: 216.113280, hgln: 0.000000, hglt: -5.066999 },
  { isoDate: "2015-04-23T00:00:00Z", dsun: 216.170759, hgln: -0.000000, hglt: -4.977917 },
  { isoDate: "2015-04-24T00:00:00Z", dsun: 216.227721, hgln: 0.000000, hglt: -4.887463 },
  { isoDate: "2015-04-25T00:00:00Z", dsun: 216.284206, hgln: 0.000000, hglt: -4.795669 },
  { isoDate: "2015-04-26T00:00:00Z", dsun: 216.340256, hgln: 0.000000, hglt: -4.702561 },
  { isoDate: "2015-04-27T00:00:00Z", dsun: 216.395911, hgln: 0.000000, hglt: -4.608169 },
  { isoDate: "2015-04-28T00:00:00Z", dsun: 216.451205, hgln: 0.000000, hglt: -4.512521 },
  { isoDate: "2015-04-29T00:00:00Z", dsun: 216.506170, hgln: 0.000000, hglt: -4.415644 },
  { isoDate: "2015-04-30T00:00:00Z", dsun: 216.560835, hgln: -0.000000, hglt: -4.317568 },
  { isoDate: "2015-05-01T00:00:00Z", dsun: 216.615223, hgln: 0.000000, hglt: -4.218320 },
  { isoDate: "2015-05-02T00:00:00Z", dsun: 216.669353, hgln: 0.000000, hglt: -4.117927 },
  { isoDate: "2015-05-03T00:00:00Z", dsun: 216.723239, hgln: 0.000000, hglt: -4.016417 },
  { isoDate: "2015-05-04T00:00:00Z", dsun: 216.776886, hgln: 0.000000, hglt: -3.913818 },
  { isoDate: "2015-05-05T00:00:00Z", dsun: 216.830291, hgln: -0.000000, hglt: -3.810156 },
  { isoDate: "2015-05-06T00:00:00Z", dsun: 216.883440, hgln: -0.000000, hglt: -3.705460 },
  { isoDate: "2015-05-07T00:00:00Z", dsun: 216.936308, hgln: -0.000000, hglt: -3.599758 },
  { isoDate: "2015-05-08T00:00:00Z", dsun: 216.988857, hgln: 0.000000, hglt: -3.493077 },
  { isoDate: "2015-05-09T00:00:00Z", dsun: 217.041034, hgln: 0.000000, hglt: -3.385448 },
  { isoDate: "2015-05-10T00:00:00Z", dsun: 217.092777, hgln: 0.000000, hglt: -3.276898 },
  { isoDate: "2015-05-11T00:00:00Z", dsun: 217.144011, hgln: 0.000000, hglt: -3.167460 },
  { isoDate: "2015-05-12T00:00:00Z", dsun: 217.194653, hgln: 0.000000, hglt: -3.057164 },
  { isoDate: "2015-05-13T00:00:00Z", dsun: 217.244616, hgln: 0.000000, hglt: -2.946044 },
  { isoDate: "2015-05-14T00:00:00Z", dsun: 217.293811, hgln: -0.000000, hglt: -2.834134 },
  { isoDate: "2015-05-15T00:00:00Z", dsun: 217.342155, hgln: -0.000000, hglt: -2.721467 },
  { isoDate: "2015-05-16T00:00:00Z", dsun: 217.389573, hgln: -0.000000, hglt: -2.608081 },
  { isoDate: "2015-05-17T00:00:00Z", dsun: 217.436009, hgln: 0.000000, hglt: -2.494011 },
  { isoDate: "2015-05-18T00:00:00Z", dsun: 217.481423, hgln: 0.000000, hglt: -2.379294 },
  { isoDate: "2015-05-19T00:00:00Z", dsun: 217.525797, hgln: -0.000000, hglt: -2.263966 },
  { isoDate: "2015-05-20T00:00:00Z", dsun: 217.569132, hgln: -0.000000, hglt: -2.148063 },
  { isoDate: "2015-05-21T00:00:00Z", dsun: 217.611445, hgln: -0.000000, hglt: -2.031620 },
  { isoDate: "2015-05-22T00:00:00Z", dsun: 217.652764, hgln: -0.000000, hglt: -1.914672 },
  { isoDate: "2015-05-23T00:00:00Z", dsun: 217.693127, hgln: -0.000000, hglt: -1.797252 },
  { isoDate: "2015-05-24T00:00:00Z", dsun: 217.732573, hgln: -0.000000, hglt: -1.679392 },
  { isoDate: "2015-05-25T00:00:00Z", dsun: 217.771144, hgln: 0.000000, hglt: -1.561125 },
  { isoDate: "2015-05-26T00:00:00Z", dsun: 217.808881, hgln: -0.000000, hglt: -1.442482 },
  { isoDate: "2015-05-27T00:00:00Z", dsun: 217.845824, hgln: 0.000000, hglt: -1.323496 },
  { isoDate: "2015-05-28T00:00:00Z", dsun: 217.882013, hgln: 0.000000, hglt: -1.204196 },
  { isoDate: "2015-05-29T00:00:00Z", dsun: 217.917486, hgln: 0.000000, hglt: -1.084613 },
  { isoDate: "2015-05-30T00:00:00Z", dsun: 217.952280, hgln: -0.000000, hglt: -0.964778 },
  { isoDate: "2015-05-31T00:00:00Z", dsun: 217.986426, hgln: -0.000000, hglt: -0.844719 },
  { isoDate: "2015-06-01T00:00:00Z", dsun: 218.019953, hgln: 0.000000, hglt: -0.724467 },
  { isoDate: "2015-06-02T00:00:00Z", dsun: 218.052883, hgln: 0.000000, hglt: -0.604051 },
  { isoDate: "2015-06-03T00:00:00Z", dsun: 218.085229, hgln: -0.000000, hglt: -0.483500 },
  { isoDate: "2015-06-04T00:00:00Z", dsun: 218.116990, hgln: -0.000000, hglt: -0.362843 },
  { isoDate: "2015-06-05T00:00:00Z", dsun: 218.148152, hgln: -0.000000, hglt: -0.242110 },
  { isoDate: "2015-06-06T00:00:00Z", dsun: 218.178687, hgln: -0.000000, hglt: -0.121330 },
  { isoDate: "2015-06-07T00:00:00Z", dsun: 218.208548, hgln: -0.000000, hglt: -0.000533 },
  { isoDate: "2015-06-08T00:00:00Z", dsun: 218.237676, hgln: -0.000000, hglt: 0.120248 },
  { isoDate: "2015-06-09T00:00:00Z", dsun: 218.265999, hgln: -0.000000, hglt: 0.240982 },
  { isoDate: "2015-06-10T00:00:00Z", dsun: 218.293439, hgln: 0.000000, hglt: 0.361636 },
  { isoDate: "2015-06-11T00:00:00Z", dsun: 218.319914, hgln: -0.000000, hglt: 0.482176 },
  { isoDate: "2015-06-12T00:00:00Z", dsun: 218.345342, hgln: -0.000000, hglt: 0.602567 },
  { isoDate: "2015-06-13T00:00:00Z", dsun: 218.369649, hgln: -0.000000, hglt: 0.722774 },
  { isoDate: "2015-06-14T00:00:00Z", dsun: 218.392772, hgln: 0.000000, hglt: 0.842762 },
  { isoDate: "2015-06-15T00:00:00Z", dsun: 218.414662, hgln: -0.000000, hglt: 0.962495 },
  { isoDate: "2015-06-16T00:00:00Z", dsun: 218.435286, hgln: -0.000000, hglt: 1.081939 },
  { isoDate: "2015-06-17T00:00:00Z", dsun: 218.454629, hgln: 0.000000, hglt: 1.201058 },
  { isoDate: "2015-06-18T00:00:00Z", dsun: 218.472691, hgln: -0.000000, hglt: 1.319819 },
  { isoDate: "2015-06-19T00:00:00Z", dsun: 218.489488, hgln: 0.000000, hglt: 1.438189 },
  { isoDate: "2015-06-20T00:00:00Z", dsun: 218.505044, hgln: -0.000000, hglt: 1.556135 },
  { isoDate: "2015-06-21T00:00:00Z", dsun: 218.519393, hgln: -0.000000, hglt: 1.673626 },
  { isoDate: "2015-06-22T00:00:00Z", dsun: 218.532574, hgln: -0.000000, hglt: 1.790632 },
  { isoDate: "2015-06-23T00:00:00Z", dsun: 218.544631, hgln: -0.000000, hglt: 1.907121 },
  { isoDate: "2015-06-24T00:00:00Z", dsun: 218.555607, hgln: 0.000000, hglt: 2.023064 },
  { isoDate: "2015-06-25T00:00:00Z", dsun: 218.565549, hgln: 0.000000, hglt: 2.138432 },
  { isoDate: "2015-06-26T00:00:00Z", dsun: 218.574506, hgln: 0.000000, hglt: 2.253196 },
  { isoDate: "2015-06-27T00:00:00Z", dsun: 218.582525, hgln: 0.000000, hglt: 2.367327 },
  { isoDate: "2015-06-28T00:00:00Z", dsun: 218.589656, hgln: 0.000000, hglt: 2.480799 },
  { isoDate: "2015-06-29T00:00:00Z", dsun: 218.595948, hgln: 0.000000, hglt: 2.593583 },
  { isoDate: "2015-06-30T00:00:00Z", dsun: 218.601444, hgln: 0.000000, hglt: 2.705652 },
  { isoDate: "2015-07-01T00:00:00Z", dsun: 218.606185, hgln: -0.000000, hglt: 2.816980 },
  { isoDate: "2015-07-02T00:00:00Z", dsun: 218.610201, hgln: 0.000000, hglt: 2.927539 },
  { isoDate: "2015-07-03T00:00:00Z", dsun: 218.613508, hgln: -0.000000, hglt: 3.037302 },
  { isoDate: "2015-07-04T00:00:00Z", dsun: 218.616105, hgln: 0.000000, hglt: 3.146243 },
  { isoDate: "2015-07-05T00:00:00Z", dsun: 218.617972, hgln: -0.000000, hglt: 3.254335 },
  { isoDate: "2015-07-06T00:00:00Z", dsun: 218.619070, hgln: 0.000000, hglt: 3.361548 },
  { isoDate: "2015-07-07T00:00:00Z", dsun: 218.619344, hgln: -0.000000, hglt: 3.467854 },
  { isoDate: "2015-07-08T00:00:00Z", dsun: 218.618727, hgln: -0.000000, hglt: 3.573223 },
  { isoDate: "2015-07-09T00:00:00Z", dsun: 218.617147, hgln: -0.000000, hglt: 3.677626 },
  { isoDate: "2015-07-10T00:00:00Z", dsun: 218.614528, hgln: 0.000000, hglt: 3.781032 },
  { isoDate: "2015-07-11T00:00:00Z", dsun: 218.610800, hgln: -0.000000, hglt: 3.883410 },
  { isoDate: "2015-07-12T00:00:00Z", dsun: 218.605899, hgln: 0.000000, hglt: 3.984731 },
  { isoDate: "2015-07-13T00:00:00Z", dsun: 218.599774, hgln: -0.000000, hglt: 4.084964 },
  { isoDate: "2015-07-14T00:00:00Z", dsun: 218.592385, hgln: -0.000000, hglt: 4.184080 },
  { isoDate: "2015-07-15T00:00:00Z", dsun: 218.583706, hgln: -0.000000, hglt: 4.282049 },
  { isoDate: "2015-07-16T00:00:00Z", dsun: 218.573726, hgln: 0.000000, hglt: 4.378844 },
  { isoDate: "2015-07-17T00:00:00Z", dsun: 218.562446, hgln: -0.000000, hglt: 4.474436 },
  { isoDate: "2015-07-18T00:00:00Z", dsun: 218.549880, hgln: 0.000000, hglt: 4.568798 },
  { isoDate: "2015-07-19T00:00:00Z", dsun: 218.536051, hgln: 0.000000, hglt: 4.661905 },
  { isoDate: "2015-07-20T00:00:00Z", dsun: 218.520992, hgln: 0.000000, hglt: 4.753732 },
  { isoDate: "2015-07-21T00:00:00Z", dsun: 218.504740, hgln: 0.000000, hglt: 4.844252 },
  { isoDate: "2015-07-22T00:00:00Z", dsun: 218.487340, hgln: -0.000000, hglt: 4.933443 },
  { isoDate: "2015-07-23T00:00:00Z", dsun: 218.468840, hgln: -0.000000, hglt: 5.021280 },
  { isoDate: "2015-07-24T00:00:00Z", dsun: 218.449293, hgln: -0.000000, hglt: 5.107740 },
  { isoDate: "2015-07-25T00:00:00Z", dsun: 218.428753, hgln: 0.000000, hglt: 5.192800 },
  { isoDate: "2015-07-26T00:00:00Z", dsun: 218.407279, hgln: 0.000000, hglt: 5.276438 },
  { isoDate: "2015-07-27T00:00:00Z", dsun: 218.384933, hgln: -0.000000, hglt: 5.358633 },
  { isoDate: "2015-07-28T00:00:00Z", dsun: 218.361779, hgln: 0.000000, hglt: 5.439363 },
  { isoDate: "2015-07-29T00:00:00Z", dsun: 218.337877, hgln: -0.000000, hglt: 5.518606 },
  { isoDate: "2015-07-30T00:00:00Z", dsun: 218.313286, hgln: 0.000000, hglt: 5.596342 },
  { isoDate: "2015-07-31T00:00:00Z", dsun: 218.288053, hgln: 0.000000, hglt: 5.672550 },
  { isoDate: "2015-08-01T00:00:00Z", dsun: 218.262210, hgln: -0.000000, hglt: 5.747209 },
  { isoDate: "2015-08-02T00:00:00Z", dsun: 218.235770, hgln: -0.000000, hglt: 5.820296 },
  { isoDate: "2015-08-03T00:00:00Z", dsun: 218.208721, hgln: 0.000000, hglt: 5.891792 },
  { isoDate: "2015-08-04T00:00:00Z", dsun: 218.181033, hgln: -0.000000, hglt: 5.961673 },
  { isoDate: "2015-08-05T00:00:00Z", dsun: 218.152656, hgln: -0.000000, hglt: 6.029917 },
  { isoDate: "2015-08-06T00:00:00Z", dsun: 218.123528, hgln: -0.000000, hglt: 6.096503 },
  { isoDate: "2015-08-07T00:00:00Z", dsun: 218.093584, hgln: 0.000000, hglt: 6.161407 },
  { isoDate: "2015-08-08T00:00:00Z", dsun: 218.062758, hgln: -0.000000, hglt: 6.224609 },
  { isoDate: "2015-08-09T00:00:00Z", dsun: 218.030990, hgln: 0.000000, hglt: 6.286086 },
  { isoDate: "2015-08-10T00:00:00Z", dsun: 217.998227, hgln: 0.000000, hglt: 6.345820 },
  { isoDate: "2015-08-11T00:00:00Z", dsun: 217.964427, hgln: -0.000000, hglt: 6.403788 },
  { isoDate: "2015-08-12T00:00:00Z", dsun: 217.929559, hgln: -0.000000, hglt: 6.459974 },
  { isoDate: "2015-08-13T00:00:00Z", dsun: 217.893603, hgln: 0.000000, hglt: 6.514357 },
  { isoDate: "2015-08-14T00:00:00Z", dsun: 217.856551, hgln: 0.000000, hglt: 6.566922 },
  { isoDate: "2015-08-15T00:00:00Z", dsun: 217.818404, hgln: -0.000000, hglt: 6.617651 },
  { isoDate: "2015-08-16T00:00:00Z", dsun: 217.779176, hgln: 0.000000, hglt: 6.666529 },
  { isoDate: "2015-08-17T00:00:00Z", dsun: 217.738889, hgln: 0.000000, hglt: 6.713540 },
  { isoDate: "2015-08-18T00:00:00Z", dsun: 217.697574, hgln: -0.000000, hglt: 6.758670 },
  { isoDate: "2015-08-19T00:00:00Z", dsun: 217.655268, hgln: 0.000000, hglt: 6.801905 },
  { isoDate: "2015-08-20T00:00:00Z", dsun: 217.612016, hgln: -0.000000, hglt: 6.843233 },
  { isoDate: "2015-08-21T00:00:00Z", dsun: 217.567868, hgln: -0.000000, hglt: 6.882640 },
  { isoDate: "2015-08-22T00:00:00Z", dsun: 217.522882, hgln: -0.000000, hglt: 6.920114 },
  { isoDate: "2015-08-23T00:00:00Z", dsun: 217.477118, hgln: 0.000000, hglt: 6.955644 },
  { isoDate: "2015-08-24T00:00:00Z", dsun: 217.430646, hgln: 0.000000, hglt: 6.989219 },
  { isoDate: "2015-08-25T00:00:00Z", dsun: 217.383535, hgln: 0.000000, hglt: 7.020829 },
  { isoDate: "2015-08-26T00:00:00Z", dsun: 217.335864, hgln: -0.000000, hglt: 7.050461 },
  { isoDate: "2015-08-27T00:00:00Z", dsun: 217.287708, hgln: -0.000000, hglt: 7.078107 },
  { isoDate: "2015-08-28T00:00:00Z", dsun: 217.239141, hgln: -0.000000, hglt: 7.103756 },
  { isoDate: "2015-08-29T00:00:00Z", dsun: 217.190225, hgln: 0.000000, hglt: 7.127398 },
  { isoDate: "2015-08-30T00:00:00Z", dsun: 217.141008, hgln: -0.000000, hglt: 7.149022 },
  { isoDate: "2015-08-31T00:00:00Z", dsun: 217.091514, hgln: 0.000000, hglt: 7.168617 },
  { isoDate: "2015-09-01T00:00:00Z", dsun: 217.041742, hgln: 0.000000, hglt: 7.186172 },
  { isoDate: "2015-09-02T00:00:00Z", dsun: 216.991668, hgln: 0.000000, hglt: 7.201679 },
  { isoDate: "2015-09-03T00:00:00Z", dsun: 216.941249, hgln: 0.000000, hglt: 7.215125 },
  { isoDate: "2015-09-04T00:00:00Z", dsun: 216.890431, hgln: -0.000000, hglt: 7.226503 },
  { isoDate: "2015-09-05T00:00:00Z", dsun: 216.839156, hgln: -0.000000, hglt: 7.235804 },
  { isoDate: "2015-09-06T00:00:00Z", dsun: 216.787368, hgln: -0.000000, hglt: 7.243021 },
  { isoDate: "2015-09-07T00:00:00Z", dsun: 216.735015, hgln: -0.000000, hglt: 7.248147 },
  { isoDate: "2015-09-08T00:00:00Z", dsun: 216.682053, hgln: -0.000000, hglt: 7.251178 },
  { isoDate: "2015-09-09T00:00:00Z", dsun: 216.628448, hgln: 0.000000, hglt: 7.252110 },
  { isoDate: "2015-09-10T00:00:00Z", dsun: 216.574174, hgln: -0.000000, hglt: 7.250939 },
  { isoDate: "2015-09-11T00:00:00Z", dsun: 216.519215, hgln: 0.000000, hglt: 7.247663 },
  { isoDate: "2015-09-12T00:00:00Z", dsun: 216.463562, hgln: 0.000000, hglt: 7.242282 },
  { isoDate: "2015-09-13T00:00:00Z", dsun: 216.407218, hgln: -0.000000, hglt: 7.234795 },
  { isoDate: "2015-09-14T00:00:00Z", dsun: 216.350192, hgln: 0.000000, hglt: 7.225202 },
  { isoDate: "2015-09-15T00:00:00Z", dsun: 216.292505, hgln: -0.000000, hglt: 7.213505 },
  { isoDate: "2015-09-16T00:00:00Z", dsun: 216.234183, hgln: 0.000000, hglt: 7.199705 },
  { isoDate: "2015-09-17T00:00:00Z", dsun: 216.175264, hgln: 0.000000, hglt: 7.183806 },
  { isoDate: "2015-09-18T00:00:00Z", dsun: 216.115789, hgln: -0.000000, hglt: 7.165810 },
  { isoDate: "2015-09-19T00:00:00Z", dsun: 216.055811, hgln: -0.000000, hglt: 7.145723 },
  { isoDate: "2015-09-20T00:00:00Z", dsun: 215.995388, hgln: -0.000000, hglt: 7.123547 },
  { isoDate: "2015-09-21T00:00:00Z", dsun: 215.934586, hgln: -0.000000, hglt: 7.099288 },
  { isoDate: "2015-09-22T00:00:00Z", dsun: 215.873477, hgln: -0.000000, hglt: 7.072950 },
  { isoDate: "2015-09-23T00:00:00Z", dsun: 215.812142, hgln: -0.000000, hglt: 7.044541 },
  { isoDate: "2015-09-24T00:00:00Z", dsun: 215.750665, hgln: 0.000000, hglt: 7.014063 },
  { isoDate: "2015-09-25T00:00:00Z", dsun: 215.689134, hgln: -0.000000, hglt: 6.981524 },
  { isoDate: "2015-09-26T00:00:00Z", dsun: 215.627631, hgln: 0.000000, hglt: 6.946929 },
  { isoDate: "2015-09-27T00:00:00Z", dsun: 215.566232, hgln: 0.000000, hglt: 6.910281 },
  { isoDate: "2015-09-28T00:00:00Z", dsun: 215.504993, hgln: -0.000000, hglt: 6.871586 },
  { isoDate: "2015-09-29T00:00:00Z", dsun: 215.443948, hgln: 0.000000, hglt: 6.830850 },
  { isoDate: "2015-09-30T00:00:00Z", dsun: 215.383104, hgln: 0.000000, hglt: 6.788076 },
  { isoDate: "2015-10-01T00:00:00Z", dsun: 215.322446, hgln: 0.000000, hglt: 6.743271 },
  { isoDate: "2015-10-02T00:00:00Z", dsun: 215.261937, hgln: -0.000000, hglt: 6.696443 },
  { isoDate: "2015-10-03T00:00:00Z", dsun: 215.201532, hgln: -0.000000, hglt: 6.647600 },
  { isoDate: "2015-10-04T00:00:00Z", dsun: 215.141179, hgln: 0.000000, hglt: 6.596752 },
  { isoDate: "2015-10-05T00:00:00Z", dsun: 215.080829, hgln: -0.000000, hglt: 6.543911 },
  { isoDate: "2015-10-06T00:00:00Z", dsun: 215.020436, hgln: 0.000000, hglt: 6.489090 },
  { isoDate: "2015-10-07T00:00:00Z", dsun: 214.959964, hgln: 0.000000, hglt: 6.432303 },
  { isoDate: "2015-10-08T00:00:00Z", dsun: 214.899378, hgln: 0.000000, hglt: 6.373564 },
  { isoDate: "2015-10-09T00:00:00Z", dsun: 214.838656, hgln: 0.000000, hglt: 6.312890 },
  { isoDate: "2015-10-10T00:00:00Z", dsun: 214.777778, hgln: 0.000000, hglt: 6.250299 },
  { isoDate: "2015-10-11T00:00:00Z", dsun: 214.716735, hgln: 0.000000, hglt: 6.185807 },
  { isoDate: "2015-10-12T00:00:00Z", dsun: 214.655523, hgln: 0.000000, hglt: 6.119433 },
  { isoDate: "2015-10-13T00:00:00Z", dsun: 214.594147, hgln: -0.000000, hglt: 6.051198 },
  { isoDate: "2015-10-14T00:00:00Z", dsun: 214.532621, hgln: 0.000000, hglt: 5.981122 },
  { isoDate: "2015-10-15T00:00:00Z", dsun: 214.470964, hgln: 0.000000, hglt: 5.909225 },
  { isoDate: "2015-10-16T00:00:00Z", dsun: 214.409208, hgln: 0.000000, hglt: 5.835530 },
  { isoDate: "2015-10-17T00:00:00Z", dsun: 214.347392, hgln: 0.000000, hglt: 5.760058 },
  { isoDate: "2015-10-18T00:00:00Z", dsun: 214.285564, hgln: 0.000000, hglt: 5.682831 },
  { isoDate: "2015-10-19T00:00:00Z", dsun: 214.223782, hgln: -0.000000, hglt: 5.603873 },
  { isoDate: "2015-10-20T00:00:00Z", dsun: 214.162110, hgln: -0.000000, hglt: 5.523207 },
  { isoDate: "2015-10-21T00:00:00Z", dsun: 214.100626, hgln: -0.000000, hglt: 5.440854 },
  { isoDate: "2015-10-22T00:00:00Z", dsun: 214.039410, hgln: 0.000000, hglt: 5.356839 },
  { isoDate: "2015-10-23T00:00:00Z", dsun: 213.978551, hgln: -0.000000, hglt: 5.271184 },
  { isoDate: "2015-10-24T00:00:00Z", dsun: 213.918140, hgln: 0.000000, hglt: 5.183911 },
  { isoDate: "2015-10-25T00:00:00Z", dsun: 213.858264, hgln: -0.000000, hglt: 5.095042 },
  { isoDate: "2015-10-26T00:00:00Z", dsun: 213.799003, hgln: 0.000000, hglt: 5.004599 },
  { isoDate: "2015-10-27T00:00:00Z", dsun: 213.740417, hgln: 0.000000, hglt: 4.912602 },
  { isoDate: "2015-10-28T00:00:00Z", dsun: 213.682545, hgln: 0.000000, hglt: 4.819074 },
  { isoDate: "2015-10-29T00:00:00Z", dsun: 213.625400, hgln: -0.000000, hglt: 4.724036 },
  { isoDate: "2015-10-30T00:00:00Z", dsun: 213.568974, hgln: 0.000000, hglt: 4.627513 },
  { isoDate: "2015-10-31T00:00:00Z", dsun: 213.513237, hgln: -0.000000, hglt: 4.529528 },
  { isoDate: "2015-11-01T00:00:00Z", dsun: 213.458150, hgln: 0.000000, hglt: 4.430110 },
  { isoDate: "2015-11-02T00:00:00Z", dsun: 213.403669, hgln: 0.000000, hglt: 4.329284 },
  { isoDate: "2015-11-03T00:00:00Z", dsun: 213.349748, hgln: 0.000000, hglt: 4.227082 },
  { isoDate: "2015-11-04T00:00:00Z", dsun: 213.296345, hgln: 0.000000, hglt: 4.123533 },
  { isoDate: "2015-11-05T00:00:00Z", dsun: 213.243421, hgln: 0.000000, hglt: 4.018668 },
  { isoDate: "2015-11-06T00:00:00Z", dsun: 213.190943, hgln: -0.000000, hglt: 3.912521 },
  { isoDate: "2015-11-07T00:00:00Z", dsun: 213.138882, hgln: 0.000000, hglt: 3.805124 },
  { isoDate: "2015-11-08T00:00:00Z", dsun: 213.087214, hgln: 0.000000, hglt: 3.696511 },
  { isoDate: "2015-11-09T00:00:00Z", dsun: 213.035919, hgln: -0.000000, hglt: 3.586717 },
  { isoDate: "2015-11-10T00:00:00Z", dsun: 212.984986, hgln: -0.000000, hglt: 3.475777 },
  { isoDate: "2015-11-11T00:00:00Z", dsun: 212.934408, hgln: 0.000000, hglt: 3.363727 },
  { isoDate: "2015-11-12T00:00:00Z", dsun: 212.884185, hgln: 0.000000, hglt: 3.250603 },
  { isoDate: "2015-11-13T00:00:00Z", dsun: 212.834327, hgln: -0.000000, hglt: 3.136442 },
  { isoDate: "2015-11-14T00:00:00Z", dsun: 212.784855, hgln: 0.000000, hglt: 3.021282 },
  { isoDate: "2015-11-15T00:00:00Z", dsun: 212.735797, hgln: 0.000000, hglt: 2.905159 },
  { isoDate: "2015-11-16T00:00:00Z", dsun: 212.687196, hgln: 0.000000, hglt: 2.788111 },
  { isoDate: "2015-11-17T00:00:00Z", dsun: 212.639102, hgln: -0.000000, hglt: 2.670175 },
  { isoDate: "2015-11-18T00:00:00Z", dsun: 212.591578, hgln: 0.000000, hglt: 2.551388 },
  { isoDate: "2015-11-19T00:00:00Z", dsun: 212.544698, hgln: -0.000000, hglt: 2.431785 },
  { isoDate: "2015-11-20T00:00:00Z", dsun: 212.498540, hgln: 0.000000, hglt: 2.311404 },
  { isoDate: "2015-11-21T00:00:00Z", dsun: 212.453191, hgln: 0.000000, hglt: 2.190280 },
  { isoDate: "2015-11-22T00:00:00Z", dsun: 212.408738, hgln: -0.000000, hglt: 2.068447 },
  { isoDate: "2015-11-23T00:00:00Z", dsun: 212.365267, hgln: 0.000000, hglt: 1.945939 },
  { isoDate: "2015-11-24T00:00:00Z", dsun: 212.322850, hgln: 0.000000, hglt: 1.822791 },
  { isoDate: "2015-11-25T00:00:00Z", dsun: 212.281547, hgln: 0.000000, hglt: 1.699036 },
  { isoDate: "2015-11-26T00:00:00Z", dsun: 212.241396, hgln: -0.000000, hglt: 1.574709 },
  { isoDate: "2015-11-27T00:00:00Z", dsun: 212.202412, hgln: -0.000000, hglt: 1.449843 },
  { isoDate: "2015-11-28T00:00:00Z", dsun: 212.164591, hgln: 0.000000, hglt: 1.324475 },
  { isoDate: "2015-11-29T00:00:00Z", dsun: 212.127910, hgln: -0.000000, hglt: 1.198642 },
  { isoDate: "2015-11-30T00:00:00Z", dsun: 212.092334, hgln: -0.000000, hglt: 1.072382 },
  { isoDate: "2015-12-01T00:00:00Z", dsun: 212.057823, hgln: 0.000000, hglt: 0.945733 },
  { isoDate: "2015-12-02T00:00:00Z", dsun: 212.024332, hgln: 0.000000, hglt: 0.818737 },
  { isoDate: "2015-12-03T00:00:00Z", dsun: 211.991818, hgln: -0.000000, hglt: 0.691434 },
  { isoDate: "2015-12-04T00:00:00Z", dsun: 211.960238, hgln: 0.000000, hglt: 0.563865 },
  { isoDate: "2015-12-05T00:00:00Z", dsun: 211.929553, hgln: 0.000000, hglt: 0.436073 },
  { isoDate: "2015-12-06T00:00:00Z", dsun: 211.899725, hgln: -0.000000, hglt: 0.308100 },
  { isoDate: "2015-12-07T00:00:00Z", dsun: 211.870718, hgln: -0.000000, hglt: 0.179988 },
  { isoDate: "2015-12-08T00:00:00Z", dsun: 211.842501, hgln: -0.000000, hglt: 0.051781 },
  { isoDate: "2015-12-09T00:00:00Z", dsun: 211.815046, hgln: -0.000000, hglt: -0.076478 },
  { isoDate: "2015-12-10T00:00:00Z", dsun: 211.788328, hgln: 0.000000, hglt: -0.204745 },
  { isoDate: "2015-12-11T00:00:00Z", dsun: 211.762332, hgln: -0.000000, hglt: -0.332977 },
  { isoDate: "2015-12-12T00:00:00Z", dsun: 211.737050, hgln: 0.000000, hglt: -0.461130 },
  { isoDate: "2015-12-13T00:00:00Z", dsun: 211.712484, hgln: 0.000000, hglt: -0.589161 },
  { isoDate: "2015-12-14T00:00:00Z", dsun: 211.688650, hgln: 0.000000, hglt: -0.717027 },
  { isoDate: "2015-12-15T00:00:00Z", dsun: 211.665578, hgln: 0.000000, hglt: -0.844685 },
  { isoDate: "2015-12-16T00:00:00Z", dsun: 211.643309, hgln: 0.000000, hglt: -0.972092 },
  { isoDate: "2015-12-17T00:00:00Z", dsun: 211.621900, hgln: -0.000000, hglt: -1.099209 },
  { isoDate: "2015-12-18T00:00:00Z", dsun: 211.601415, hgln: -0.000000, hglt: -1.225994 },
  { isoDate: "2015-12-19T00:00:00Z", dsun: 211.581931, hgln: -0.000000, hglt: -1.352408 },
  { isoDate: "2015-12-20T00:00:00Z", dsun: 211.563525, hgln: 0.000000, hglt: -1.478412 },
  { isoDate: "2015-12-21T00:00:00Z", dsun: 211.546276, hgln: 0.000000, hglt: -1.603969 },
  { isoDate: "2015-12-22T00:00:00Z", dsun: 211.530259, hgln: 0.000000, hglt: -1.729041 },
  { isoDate: "2015-12-23T00:00:00Z", dsun: 211.515537, hgln: -0.000000, hglt: -1.853591 },
  { isoDate: "2015-12-24T00:00:00Z", dsun: 211.502161, hgln: 0.000000, hglt: -1.977581 },
  { isoDate: "2015-12-25T00:00:00Z", dsun: 211.490164, hgln: 0.000000, hglt: -2.100976 },
  { isoDate: "2015-12-26T00:00:00Z", dsun: 211.479559, hgln: 0.000000, hglt: -2.223737 },
  { isoDate: "2015-12-27T00:00:00Z", dsun: 211.470343, hgln: -0.000000, hglt: -2.345827 },
  { isoDate: "2015-12-28T00:00:00Z", dsun: 211.462495, hgln: 0.000000, hglt: -2.467206 },
  { isoDate: "2015-12-29T00:00:00Z", dsun: 211.455984, hgln: 0.000000, hglt: -2.587836 },
  { isoDate: "2015-12-30T00:00:00Z", dsun: 211.450771, hgln: 0.000000, hglt: -2.707678 },
  { isoDate: "2015-12-31T00:00:00Z", dsun: 211.446812, hgln: -0.000000, hglt: -2.826691 },
  { isoDate: "2016-01-01T00:00:00Z", dsun: 211.444058, hgln: -0.000000, hglt: -2.944837 },
  { isoDate: "2016-01-02T00:00:00Z", dsun: 211.442463, hgln: -0.000000, hglt: -3.062074 },
  { isoDate: "2016-01-03T00:00:00Z", dsun: 211.441977, hgln: 0.000000, hglt: -3.178364 },
  { isoDate: "2016-01-04T00:00:00Z", dsun: 211.442552, hgln: 0.000000, hglt: -3.293666 },
  { isoDate: "2016-01-05T00:00:00Z", dsun: 211.444139, hgln: 0.000000, hglt: -3.407941 },
  { isoDate: "2016-01-06T00:00:00Z", dsun: 211.446689, hgln: -0.000000, hglt: -3.521150 },
  { isoDate: "2016-01-07T00:00:00Z", dsun: 211.450157, hgln: 0.000000, hglt: -3.633254 },
  { isoDate: "2016-01-08T00:00:00Z", dsun: 211.454498, hgln: -0.000000, hglt: -3.744213 },
  { isoDate: "2016-01-09T00:00:00Z", dsun: 211.459674, hgln: 0.000000, hglt: -3.853991 },
  { isoDate: "2016-01-10T00:00:00Z", dsun: 211.465656, hgln: 0.000000, hglt: -3.962549 },
  { isoDate: "2016-01-11T00:00:00Z", dsun: 211.472426, hgln: -0.000000, hglt: -4.069851 },
  { isoDate: "2016-01-12T00:00:00Z", dsun: 211.479982, hgln: 0.000000, hglt: -4.175859 },
  { isoDate: "2016-01-13T00:00:00Z", dsun: 211.488337, hgln: 0.000000, hglt: -4.280541 },
  { isoDate: "2016-01-14T00:00:00Z", dsun: 211.497523, hgln: 0.000000, hglt: -4.383861 },
  { isoDate: "2016-01-15T00:00:00Z", dsun: 211.507587, hgln: 0.000000, hglt: -4.485787 },
  { isoDate: "2016-01-16T00:00:00Z", dsun: 211.518587, hgln: -0.000000, hglt: -4.586287 },
  { isoDate: "2016-01-17T00:00:00Z", dsun: 211.530589, hgln: 0.000000, hglt: -4.685331 },
  { isoDate: "2016-01-18T00:00:00Z", dsun: 211.543664, hgln: 0.000000, hglt: -4.782889 },
  { isoDate: "2016-01-19T00:00:00Z", dsun: 211.557877, hgln: 0.000000, hglt: -4.878931 },
  { isoDate: "2016-01-20T00:00:00Z", dsun: 211.573292, hgln: -0.000000, hglt: -4.973430 },
  { isoDate: "2016-01-21T00:00:00Z", dsun: 211.589960, hgln: 0.000000, hglt: -5.066356 },
  { isoDate: "2016-01-22T00:00:00Z", dsun: 211.607921, hgln: 0.000000, hglt: -5.157682 },
  { isoDate: "2016-01-23T00:00:00Z", dsun: 211.627198, hgln: 0.000000, hglt: -5.247379 },
  { isoDate: "2016-01-24T00:00:00Z", dsun: 211.647803, hgln: 0.000000, hglt: -5.335420 },
  { isoDate: "2016-01-25T00:00:00Z", dsun: 211.669730, hgln: 0.000000, hglt: -5.421777 },
  { isoDate: "2016-01-26T00:00:00Z", dsun: 211.692960, hgln: -0.000000, hglt: -5.506423 },
  { isoDate: "2016-01-27T00:00:00Z", dsun: 211.717464, hgln: 0.000000, hglt: -5.589330 },
  { isoDate: "2016-01-28T00:00:00Z", dsun: 211.743203, hgln: 0.000000, hglt: -5.670470 },
  { isoDate: "2016-01-29T00:00:00Z", dsun: 211.770133, hgln: 0.000000, hglt: -5.749818 },
  { isoDate: "2016-01-30T00:00:00Z", dsun: 211.798204, hgln: 0.000000, hglt: -5.827347 },
  { isoDate: "2016-01-31T00:00:00Z", dsun: 211.827363, hgln: 0.000000, hglt: -5.903031 },
  { isoDate: "2016-02-01T00:00:00Z", dsun: 211.857553, hgln: -0.000000, hglt: -5.976846 },
  { isoDate: "2016-02-02T00:00:00Z", dsun: 211.888717, hgln: -0.000000, hglt: -6.048766 },
  { isoDate: "2016-02-03T00:00:00Z", dsun: 211.920793, hgln: 0.000000, hglt: -6.118768 },
  { isoDate: "2016-02-04T00:00:00Z", dsun: 211.953718, hgln: -0.000000, hglt: -6.186829 },
  { isoDate: "2016-02-05T00:00:00Z", dsun: 211.987429, hgln: 0.000000, hglt: -6.252927 },
  { isoDate: "2016-02-06T00:00:00Z", dsun: 212.021862, hgln: -0.000000, hglt: -6.317041 },
  { isoDate: "2016-02-07T00:00:00Z", dsun: 212.056958, hgln: -0.000000, hglt: -6.379150 },
  { isoDate: "2016-02-08T00:00:00Z", dsun: 212.092665, hgln: 0.000000, hglt: -6.439235 },
  { isoDate: "2016-02-09T00:00:00Z", dsun: 212.128945, hgln: 0.000000, hglt: -6.497279 },
  { isoDate: "2016-02-10T00:00:00Z", dsun: 212.165777, hgln: -0.000000, hglt: -6.553264 },
  { isoDate: "2016-02-11T00:00:00Z", dsun: 212.203161, hgln: 0.000000, hglt: -6.607177 },
  { isoDate: "2016-02-12T00:00:00Z", dsun: 212.241117, hgln: 0.000000, hglt: -6.659001 },
  { isoDate: "2016-02-13T00:00:00Z", dsun: 212.279684, hgln: -0.000000, hglt: -6.708726 },
  { isoDate: "2016-02-14T00:00:00Z", dsun: 212.318913, hgln: 0.000000, hglt: -6.756337 },
  { isoDate: "2016-02-15T00:00:00Z", dsun: 212.358864, hgln: -0.000000, hglt: -6.801825 },
  { isoDate: "2016-02-16T00:00:00Z", dsun: 212.399596, hgln: 0.000000, hglt: -6.845178 },
  { isoDate: "2016-02-17T00:00:00Z", dsun: 212.441169, hgln: -0.000000, hglt: -6.886386 },
  { isoDate: "2016-02-18T00:00:00Z", dsun: 212.483633, hgln: -0.000000, hglt: -6.925439 },
  { isoDate: "2016-02-19T00:00:00Z", dsun: 212.527028, hgln: 0.000000, hglt: -6.962327 },
  { isoDate: "2016-02-20T00:00:00Z", dsun: 212.571386, hgln: -0.000000, hglt: -6.997040 },
  { isoDate: "2016-02-21T00:00:00Z", dsun: 212.616726, hgln: 0.000000, hglt: -7.029570 },
  { isoDate: "2016-02-22T00:00:00Z", dsun: 212.663054, hgln: -0.000000, hglt: -7.059909 },
  { isoDate: "2016-02-23T00:00:00Z", dsun: 212.710365, hgln: -0.000000, hglt: -7.088047 },
  { isoDate: "2016-02-24T00:00:00Z", dsun: 212.758642, hgln: 0.000000, hglt: -7.113978 },
  { isoDate: "2016-02-25T00:00:00Z", dsun: 212.807858, hgln: -0.000000, hglt: -7.137694 },
  { isoDate: "2016-02-26T00:00:00Z", dsun: 212.857978, hgln: 0.000000, hglt: -7.159190 },
  { isoDate: "2016-02-27T00:00:00Z", dsun: 212.908960, hgln: -0.000000, hglt: -7.178459 },
  { isoDate: "2016-02-28T00:00:00Z", dsun: 212.960754, hgln: -0.000000, hglt: -7.195497 },
  { isoDate: "2016-02-29T00:00:00Z", dsun: 213.013305, hgln: -0.000000, hglt: -7.210300 },
  { isoDate: "2016-03-01T00:00:00Z", dsun: 213.066554, hgln: -0.000000, hglt: -7.222865 },
  { isoDate: "2016-03-02T00:00:00Z", dsun: 213.120437, hgln: -0.000000, hglt: -7.233190 },
  { isoDate: "2016-03-03T00:00:00Z", dsun: 213.174885, hgln: 0.000000, hglt: -7.241273 },
  { isoDate: "2016-03-04T00:00:00Z", dsun: 213.229825, hgln: -0.000000, hglt: -7.247115 },
  { isoDate: "2016-03-05T00:00:00Z", dsun: 213.285181, hgln: -0.000000, hglt: -7.250716 },
  { isoDate: "2016-03-06T00:00:00Z", dsun: 213.340875, hgln: -0.000000, hglt: -7.252080 },
  { isoDate: "2016-03-07T00:00:00Z", dsun: 213.396831, hgln: -0.000000, hglt: -7.251208 },
  { isoDate: "2016-03-08T00:00:00Z", dsun: 213.452982, hgln: -0.000000, hglt: -7.248107 },
  { isoDate: "2016-03-09T00:00:00Z", dsun: 213.509271, hgln: -0.000000, hglt: -7.242783 },
  { isoDate: "2016-03-10T00:00:00Z", dsun: 213.565664, hgln: -0.000000, hglt: -7.235243 },
  { isoDate: "2016-03-11T00:00:00Z", dsun: 213.622148, hgln: 0.000000, hglt: -7.225497 },
  { isoDate: "2016-03-12T00:00:00Z", dsun: 213.678736, hgln: -0.000000, hglt: -7.213553 },
  { isoDate: "2016-03-13T00:00:00Z", dsun: 213.735461, hgln: -0.000000, hglt: -7.199423 },
  { isoDate: "2016-03-14T00:00:00Z", dsun: 213.792368, hgln: 0.000000, hglt: -7.183115 },
  { isoDate: "2016-03-15T00:00:00Z", dsun: 213.849514, hgln: 0.000000, hglt: -7.164641 },
  { isoDate: "2016-03-16T00:00:00Z", dsun: 213.906951, hgln: -0.000000, hglt: -7.144011 },
  { isoDate: "2016-03-17T00:00:00Z", dsun: 213.964732, hgln: -0.000000, hglt: -7.121236 },
  { isoDate: "2016-03-18T00:00:00Z", dsun: 214.022902, hgln: 0.000000, hglt: -7.096325 },
  { isoDate: "2016-03-19T00:00:00Z", dsun: 214.081495, hgln: 0.000000, hglt: -7.069289 },
  { isoDate: "2016-03-20T00:00:00Z", dsun: 214.140539, hgln: -0.000000, hglt: -7.040141 },
  { isoDate: "2016-03-21T00:00:00Z", dsun: 214.200051, hgln: 0.000000, hglt: -7.008889 },
  { isoDate: "2016-03-22T00:00:00Z", dsun: 214.260039, hgln: -0.000000, hglt: -6.975548 },
  { isoDate: "2016-03-23T00:00:00Z", dsun: 214.320500, hgln: -0.000000, hglt: -6.940127 },
  { isoDate: "2016-03-24T00:00:00Z", dsun: 214.381423, hgln: -0.000000, hglt: -6.902641 },
  { isoDate: "2016-03-25T00:00:00Z", dsun: 214.442787, hgln: -0.000000, hglt: -6.863102 },
  { isoDate: "2016-03-26T00:00:00Z", dsun: 214.504563, hgln: 0.000000, hglt: -6.821524 },
  { isoDate: "2016-03-27T00:00:00Z", dsun: 214.566714, hgln: 0.000000, hglt: -6.777922 },
  { isoDate: "2016-03-28T00:00:00Z", dsun: 214.629197, hgln: 0.000000, hglt: -6.732310 },
  { isoDate: "2016-03-29T00:00:00Z", dsun: 214.691958, hgln: -0.000000, hglt: -6.684705 },
  { isoDate: "2016-03-30T00:00:00Z", dsun: 214.754941, hgln: 0.000000, hglt: -6.635123 },
  { isoDate: "2016-03-31T00:00:00Z", dsun: 214.818081, hgln: 0.000000, hglt: -6.583583 },
  { isoDate: "2016-04-01T00:00:00Z", dsun: 214.881308, hgln: -0.000000, hglt: -6.530102 },
  { isoDate: "2016-04-02T00:00:00Z", dsun: 214.944543, hgln: 0.000000, hglt: -6.474701 },
  { isoDate: "2016-04-03T00:00:00Z", dsun: 215.007705, hgln: -0.000000, hglt: -6.417399 },
  { isoDate: "2016-04-04T00:00:00Z", dsun: 215.070709, hgln: -0.000000, hglt: -6.358220 },
  { isoDate: "2016-04-05T00:00:00Z", dsun: 215.133469, hgln: 0.000000, hglt: -6.297185 },
  { isoDate: "2016-04-06T00:00:00Z", dsun: 215.195908, hgln: 0.000000, hglt: -6.234320 },
  { isoDate: "2016-04-07T00:00:00Z", dsun: 215.257961, hgln: -0.000000, hglt: -6.169650 },
  { isoDate: "2016-04-08T00:00:00Z", dsun: 215.319585, hgln: -0.000000, hglt: -6.103202 },
  { isoDate: "2016-04-09T00:00:00Z", dsun: 215.380761, hgln: 0.000000, hglt: -6.035004 },
  { isoDate: "2016-04-10T00:00:00Z", dsun: 215.441497, hgln: -0.000000, hglt: -5.965081 },
  { isoDate: "2016-04-11T00:00:00Z", dsun: 215.501822, hgln: -0.000000, hglt: -5.893462 },
  { isoDate: "2016-04-12T00:00:00Z", dsun: 215.561780, hgln: 0.000000, hglt: -5.820172 },
  { isoDate: "2016-04-13T00:00:00Z", dsun: 215.621422, hgln: 0.000000, hglt: -5.745237 },
  { isoDate: "2016-04-14T00:00:00Z", dsun: 215.680800, hgln: -0.000000, hglt: -5.668682 },
  { isoDate: "2016-04-15T00:00:00Z", dsun: 215.739963, hgln: 0.000000, hglt: -5.590533 },
  { isoDate: "2016-04-16T00:00:00Z", dsun: 215.798954, hgln: 0.000000, hglt: -5.510814 },
  { isoDate: "2016-04-17T00:00:00Z", dsun: 215.857810, hgln: -0.000000, hglt: -5.429549 },
  { isoDate: "2016-04-18T00:00:00Z", dsun: 215.916559, hgln: -0.000000, hglt: -5.346763 },
  { isoDate: "2016-04-19T00:00:00Z", dsun: 215.975221, hgln: 0.000000, hglt: -5.262481 },
  { isoDate: "2016-04-20T00:00:00Z", dsun: 216.033811, hgln: 0.000000, hglt: -5.176728 },
  { isoDate: "2016-04-21T00:00:00Z", dsun: 216.092333, hgln: 0.000000, hglt: -5.089528 },
  { isoDate: "2016-04-22T00:00:00Z", dsun: 216.150786, hgln: 0.000000, hglt: -5.000908 },
  { isoDate: "2016-04-23T00:00:00Z", dsun: 216.209159, hgln: 0.000000, hglt: -4.910892 },
  { isoDate: "2016-04-24T00:00:00Z", dsun: 216.267434, hgln: 0.000000, hglt: -4.819507 },
  { isoDate: "2016-04-25T00:00:00Z", dsun: 216.325582, hgln: 0.000000, hglt: -4.726778 },
  { isoDate: "2016-04-26T00:00:00Z", dsun: 216.383569, hgln: 0.000000, hglt: -4.632734 },
  { isoDate: "2016-04-27T00:00:00Z", dsun: 216.441351, hgln: 0.000000, hglt: -4.537401 },
  { isoDate: "2016-04-28T00:00:00Z", dsun: 216.498876, hgln: 0.000000, hglt: -4.440808 },
  { isoDate: "2016-04-29T00:00:00Z", dsun: 216.556082, hgln: 0.000000, hglt: -4.342983 },
  { isoDate: "2016-04-30T00:00:00Z", dsun: 216.612902, hgln: -0.000000, hglt: -4.243955 },
  { isoDate: "2016-05-01T00:00:00Z", dsun: 216.669258, hgln: 0.000000, hglt: -4.143756 },
  { isoDate: "2016-05-02T00:00:00Z", dsun: 216.725068, hgln: 0.000000, hglt: -4.042416 },
  { isoDate: "2016-05-03T00:00:00Z", dsun: 216.780245, hgln: -0.000000, hglt: -3.939968 },
  { isoDate: "2016-05-04T00:00:00Z", dsun: 216.834704, hgln: -0.000000, hglt: -3.836445 },
  { isoDate: "2016-05-05T00:00:00Z", dsun: 216.888363, hgln: -0.000000, hglt: -3.731881 },
  { isoDate: "2016-05-06T00:00:00Z", dsun: 216.941159, hgln: 0.000000, hglt: -3.626312 },
  { isoDate: "2016-05-07T00:00:00Z", dsun: 216.993045, hgln: -0.000000, hglt: -3.519773 },
  { isoDate: "2016-05-08T00:00:00Z", dsun: 217.044003, hgln: 0.000000, hglt: -3.412300 },
  { isoDate: "2016-05-09T00:00:00Z", dsun: 217.094038, hgln: 0.000000, hglt: -3.303926 },
  { isoDate: "2016-05-10T00:00:00Z", dsun: 217.143176, hgln: -0.000000, hglt: -3.194687 },
  { isoDate: "2016-05-11T00:00:00Z", dsun: 217.191461, hgln: 0.000000, hglt: -3.084615 },
  { isoDate: "2016-05-12T00:00:00Z", dsun: 217.238941, hgln: 0.000000, hglt: -2.973741 },
  { isoDate: "2016-05-13T00:00:00Z", dsun: 217.285667, hgln: 0.000000, hglt: -2.862098 },
  { isoDate: "2016-05-14T00:00:00Z", dsun: 217.331690, hgln: 0.000000, hglt: -2.749717 },
  { isoDate: "2016-05-15T00:00:00Z", dsun: 217.377055, hgln: 0.000000, hglt: -2.636626 },
  { isoDate: "2016-05-16T00:00:00Z", dsun: 217.421803, hgln: -0.000000, hglt: -2.522857 },
  { isoDate: "2016-05-17T00:00:00Z", dsun: 217.465967, hgln: 0.000000, hglt: -2.408439 },
  { isoDate: "2016-05-18T00:00:00Z", dsun: 217.509579, hgln: 0.000000, hglt: -2.293403 },
  { isoDate: "2016-05-19T00:00:00Z", dsun: 217.552660, hgln: -0.000000, hglt: -2.177777 },
  { isoDate: "2016-05-20T00:00:00Z", dsun: 217.595229, hgln: 0.000000, hglt: -2.061592 },
  { isoDate: "2016-05-21T00:00:00Z", dsun: 217.637295, hgln: 0.000000, hglt: -1.944877 },
  { isoDate: "2016-05-22T00:00:00Z", dsun: 217.678863, hgln: 0.000000, hglt: -1.827663 },
  { isoDate: "2016-05-23T00:00:00Z", dsun: 217.719926, hgln: 0.000000, hglt: -1.709979 },
  { isoDate: "2016-05-24T00:00:00Z", dsun: 217.760472, hgln: 0.000000, hglt: -1.591856 },
  { isoDate: "2016-05-25T00:00:00Z", dsun: 217.800477, hgln: -0.000000, hglt: -1.473324 },
  { isoDate: "2016-05-26T00:00:00Z", dsun: 217.839906, hgln: 0.000000, hglt: -1.354414 },
  { isoDate: "2016-05-27T00:00:00Z", dsun: 217.878716, hgln: -0.000000, hglt: -1.235158 },
  { isoDate: "2016-05-28T00:00:00Z", dsun: 217.916851, hgln: 0.000000, hglt: -1.115587 },
  { isoDate: "2016-05-29T00:00:00Z", dsun: 217.954248, hgln: 0.000000, hglt: -0.995735 },
  { isoDate: "2016-05-30T00:00:00Z", dsun: 217.990834, hgln: 0.000000, hglt: -0.875634 },
  { isoDate: "2016-05-31T00:00:00Z", dsun: 218.026528, hgln: 0.000000, hglt: -0.755319 },
  { isoDate: "2016-06-01T00:00:00Z", dsun: 218.061247, hgln: 0.000000, hglt: -0.634825 },
  { isoDate: "2016-06-02T00:00:00Z", dsun: 218.094910, hgln: 0.000000, hglt: -0.514187 },
  { isoDate: "2016-06-03T00:00:00Z", dsun: 218.127441, hgln: 0.000000, hglt: -0.393440 },
  { isoDate: "2016-06-04T00:00:00Z", dsun: 218.158780, hgln: 0.000000, hglt: -0.272622 },
  { isoDate: "2016-06-05T00:00:00Z", dsun: 218.188886, hgln: -0.000000, hglt: -0.151767 },
  { isoDate: "2016-06-06T00:00:00Z", dsun: 218.217743, hgln: 0.000000, hglt: -0.030913 },
  { isoDate: "2016-06-07T00:00:00Z", dsun: 218.245357, hgln: -0.000000, hglt: 0.089908 },
  { isoDate: "2016-06-08T00:00:00Z", dsun: 218.271753, hgln: 0.000000, hglt: 0.210661 },
  { isoDate: "2016-06-09T00:00:00Z", dsun: 218.296970, hgln: -0.000000, hglt: 0.331313 },
  { isoDate: "2016-06-10T00:00:00Z", dsun: 218.321058, hgln: -0.000000, hglt: 0.451833 },
  { isoDate: "2016-06-11T00:00:00Z", dsun: 218.344068, hgln: 0.000000, hglt: 0.572190 },
  { isoDate: "2016-06-12T00:00:00Z", dsun: 218.366051, hgln: -0.000000, hglt: 0.692353 },
  { isoDate: "2016-06-13T00:00:00Z", dsun: 218.387057, hgln: -0.000000, hglt: 0.812291 },
  { isoDate: "2016-06-14T00:00:00Z", dsun: 218.407134, hgln: -0.000000, hglt: 0.931976 },
  { isoDate: "2016-06-15T00:00:00Z", dsun: 218.426325, hgln: 0.000000, hglt: 1.051378 },
  { isoDate: "2016-06-16T00:00:00Z", dsun: 218.444669, hgln: 0.000000, hglt: 1.170467 },
  { isoDate: "2016-06-17T00:00:00Z", dsun: 218.462204, hgln: 0.000000, hglt: 1.289213 },
  { isoDate: "2016-06-18T00:00:00Z", dsun: 218.478960, hgln: -0.000000, hglt: 1.407589 },
  { isoDate: "2016-06-19T00:00:00Z", dsun: 218.494964, hgln: -0.000000, hglt: 1.525565 },
  { isoDate: "2016-06-20T00:00:00Z", dsun: 218.510235, hgln: 0.000000, hglt: 1.643112 },
  { isoDate: "2016-06-21T00:00:00Z", dsun: 218.524785, hgln: 0.000000, hglt: 1.760200 },
  { isoDate: "2016-06-22T00:00:00Z", dsun: 218.538614, hgln: 0.000000, hglt: 1.876801 },
  { isoDate: "2016-06-23T00:00:00Z", dsun: 218.551713, hgln: 0.000000, hglt: 1.992885 },
  { isoDate: "2016-06-24T00:00:00Z", dsun: 218.564059, hgln: 0.000000, hglt: 2.108423 },
  { isoDate: "2016-06-25T00:00:00Z", dsun: 218.575615, hgln: 0.000000, hglt: 2.223384 },
  { isoDate: "2016-06-26T00:00:00Z", dsun: 218.586335, hgln: 0.000000, hglt: 2.337738 },
  { isoDate: "2016-06-27T00:00:00Z", dsun: 218.596158, hgln: 0.000000, hglt: 2.451454 },
  { isoDate: "2016-06-28T00:00:00Z", dsun: 218.605016, hgln: -0.000000, hglt: 2.564501 },
  { isoDate: "2016-06-29T00:00:00Z", dsun: 218.612833, hgln: 0.000000, hglt: 2.676846 },
  { isoDate: "2016-06-30T00:00:00Z", dsun: 218.619534, hgln: 0.000000, hglt: 2.788458 },
  { isoDate: "2016-07-01T00:00:00Z", dsun: 218.625042, hgln: -0.000000, hglt: 2.899304 },
  { isoDate: "2016-07-02T00:00:00Z", dsun: 218.629294, hgln: 0.000000, hglt: 3.009352 },
  { isoDate: "2016-07-03T00:00:00Z", dsun: 218.632238, hgln: -0.000000, hglt: 3.118571 },
  { isoDate: "2016-07-04T00:00:00Z", dsun: 218.633840, hgln: -0.000000, hglt: 3.226929 },
  { isoDate: "2016-07-05T00:00:00Z", dsun: 218.634089, hgln: 0.000000, hglt: 3.334395 },
  { isoDate: "2016-07-06T00:00:00Z", dsun: 218.632991, hgln: -0.000000, hglt: 3.440941 },
  { isoDate: "2016-07-07T00:00:00Z", dsun: 218.630572, hgln: 0.000000, hglt: 3.546536 },
  { isoDate: "2016-07-08T00:00:00Z", dsun: 218.626869, hgln: -0.000000, hglt: 3.651154 },
  { isoDate: "2016-07-09T00:00:00Z", dsun: 218.621929, hgln: 0.000000, hglt: 3.754765 },
  { isoDate: "2016-07-10T00:00:00Z", dsun: 218.615804, hgln: 0.000000, hglt: 3.857345 },
  { isoDate: "2016-07-11T00:00:00Z", dsun: 218.608547, hgln: 0.000000, hglt: 3.958866 },
  { isoDate: "2016-07-12T00:00:00Z", dsun: 218.600213, hgln: 0.000000, hglt: 4.059302 },
  { isoDate: "2016-07-13T00:00:00Z", dsun: 218.590855, hgln: 0.000000, hglt: 4.158628 },
  { isoDate: "2016-07-14T00:00:00Z", dsun: 218.580526, hgln: -0.000000, hglt: 4.256819 },
  { isoDate: "2016-07-15T00:00:00Z", dsun: 218.569277, hgln: -0.000000, hglt: 4.353849 },
  { isoDate: "2016-07-16T00:00:00Z", dsun: 218.557158, hgln: -0.000000, hglt: 4.449692 },
  { isoDate: "2016-07-17T00:00:00Z", dsun: 218.544215, hgln: 0.000000, hglt: 4.544326 },
  { isoDate: "2016-07-18T00:00:00Z", dsun: 218.530492, hgln: 0.000000, hglt: 4.637724 },
  { isoDate: "2016-07-19T00:00:00Z", dsun: 218.516026, hgln: 0.000000, hglt: 4.729863 },
  { isoDate: "2016-07-20T00:00:00Z", dsun: 218.500846, hgln: -0.000000, hglt: 4.820717 },
  { isoDate: "2016-07-21T00:00:00Z", dsun: 218.484968, hgln: -0.000000, hglt: 4.910262 },
  { isoDate: "2016-07-22T00:00:00Z", dsun: 218.468396, hgln: 0.000000, hglt: 4.998474 },
  { isoDate: "2016-07-23T00:00:00Z", dsun: 218.451120, hgln: 0.000000, hglt: 5.085328 },
  { isoDate: "2016-07-24T00:00:00Z", dsun: 218.433110, hgln: 0.000000, hglt: 5.170798 },
  { isoDate: "2016-07-25T00:00:00Z", dsun: 218.414324, hgln: 0.000000, hglt: 5.254859 },
  { isoDate: "2016-07-26T00:00:00Z", dsun: 218.394708, hgln: -0.000000, hglt: 5.337487 },
  { isoDate: "2016-07-27T00:00:00Z", dsun: 218.374196, hgln: -0.000000, hglt: 5.418655 },
  { isoDate: "2016-07-28T00:00:00Z", dsun: 218.352721, hgln: -0.000000, hglt: 5.498339 },
  { isoDate: "2016-07-29T00:00:00Z", dsun: 218.330211, hgln: 0.000000, hglt: 5.576515 },
  { isoDate: "2016-07-30T00:00:00Z", dsun: 218.306605, hgln: -0.000000, hglt: 5.653158 },
  { isoDate: "2016-07-31T00:00:00Z", dsun: 218.281847, hgln: 0.000000, hglt: 5.728244 },
  { isoDate: "2016-08-01T00:00:00Z", dsun: 218.255897, hgln: 0.000000, hglt: 5.801751 },
  { isoDate: "2016-08-02T00:00:00Z", dsun: 218.228729, hgln: -0.000000, hglt: 5.873656 },
  { isoDate: "2016-08-03T00:00:00Z", dsun: 218.200336, hgln: -0.000000, hglt: 5.943939 },
  { isoDate: "2016-08-04T00:00:00Z", dsun: 218.170727, hgln: 0.000000, hglt: 6.012578 },
  { isoDate: "2016-08-05T00:00:00Z", dsun: 218.139926, hgln: -0.000000, hglt: 6.079555 },
  { isoDate: "2016-08-06T00:00:00Z", dsun: 218.107968, hgln: 0.000000, hglt: 6.144850 },
  { isoDate: "2016-08-07T00:00:00Z", dsun: 218.074898, hgln: 0.000000, hglt: 6.208444 },
  { isoDate: "2016-08-08T00:00:00Z", dsun: 218.040767, hgln: -0.000000, hglt: 6.270319 },
  { isoDate: "2016-08-09T00:00:00Z", dsun: 218.005628, hgln: 0.000000, hglt: 6.330458 },
  { isoDate: "2016-08-10T00:00:00Z", dsun: 217.969540, hgln: -0.000000, hglt: 6.388843 },
  { isoDate: "2016-08-11T00:00:00Z", dsun: 217.932562, hgln: 0.000000, hglt: 6.445457 },
  { isoDate: "2016-08-12T00:00:00Z", dsun: 217.894754, hgln: -0.000000, hglt: 6.500285 },
  { isoDate: "2016-08-13T00:00:00Z", dsun: 217.856177, hgln: -0.000000, hglt: 6.553308 },
  { isoDate: "2016-08-14T00:00:00Z", dsun: 217.816893, hgln: 0.000000, hglt: 6.604512 },
  { isoDate: "2016-08-15T00:00:00Z", dsun: 217.776964, hgln: -0.000000, hglt: 6.653881 },
  { isoDate: "2016-08-16T00:00:00Z", dsun: 217.736449, hgln: -0.000000, hglt: 6.701398 },
  { isoDate: "2016-08-17T00:00:00Z", dsun: 217.695403, hgln: -0.000000, hglt: 6.747049 },
  { isoDate: "2016-08-18T00:00:00Z", dsun: 217.653871, hgln: -0.000000, hglt: 6.790817 },
  { isoDate: "2016-08-19T00:00:00Z", dsun: 217.611888, hgln: -0.000000, hglt: 6.832687 },
  { isoDate: "2016-08-20T00:00:00Z", dsun: 217.569469, hgln: -0.000000, hglt: 6.872644 },
  { isoDate: "2016-08-21T00:00:00Z", dsun: 217.526615, hgln: -0.000000, hglt: 6.910673 },
  { isoDate: "2016-08-22T00:00:00Z", dsun: 217.483302, hgln: -0.000000, hglt: 6.946758 },
  { isoDate: "2016-08-23T00:00:00Z", dsun: 217.439494, hgln: -0.000000, hglt: 6.980884 },
  { isoDate: "2016-08-24T00:00:00Z", dsun: 217.395138, hgln: -0.000000, hglt: 7.013039 },
  { isoDate: "2016-08-25T00:00:00Z", dsun: 217.350175, hgln: 0.000000, hglt: 7.043207 },
  { isoDate: "2016-08-26T00:00:00Z", dsun: 217.304541, hgln: -0.000000, hglt: 7.071378 },
  { isoDate: "2016-08-27T00:00:00Z", dsun: 217.258176, hgln: 0.000000, hglt: 7.097539 },
  { isoDate: "2016-08-28T00:00:00Z", dsun: 217.211026, hgln: 0.000000, hglt: 7.121680 },
  { isoDate: "2016-08-29T00:00:00Z", dsun: 217.163048, hgln: -0.000000, hglt: 7.143791 },
  { isoDate: "2016-08-30T00:00:00Z", dsun: 217.114210, hgln: 0.000000, hglt: 7.163864 },
  { isoDate: "2016-08-31T00:00:00Z", dsun: 217.064493, hgln: 0.000000, hglt: 7.181891 },
  { isoDate: "2016-09-01T00:00:00Z", dsun: 217.013894, hgln: 0.000000, hglt: 7.197865 },
  { isoDate: "2016-09-02T00:00:00Z", dsun: 216.962421, hgln: 0.000000, hglt: 7.211781 },
  { isoDate: "2016-09-03T00:00:00Z", dsun: 216.910096, hgln: -0.000000, hglt: 7.223632 },
  { isoDate: "2016-09-04T00:00:00Z", dsun: 216.856952, hgln: 0.000000, hglt: 7.233414 },
  { isoDate: "2016-09-05T00:00:00Z", dsun: 216.803029, hgln: -0.000000, hglt: 7.241123 },
  { isoDate: "2016-09-06T00:00:00Z", dsun: 216.748376, hgln: -0.000000, hglt: 7.246754 },
  { isoDate: "2016-09-07T00:00:00Z", dsun: 216.693046, hgln: -0.000000, hglt: 7.250305 },
  { isoDate: "2016-09-08T00:00:00Z", dsun: 216.637097, hgln: 0.000000, hglt: 7.251772 },
  { isoDate: "2016-09-09T00:00:00Z", dsun: 216.580591, hgln: 0.000000, hglt: 7.251153 },
  { isoDate: "2016-09-10T00:00:00Z", dsun: 216.523593, hgln: -0.000000, hglt: 7.248446 },
  { isoDate: "2016-09-11T00:00:00Z", dsun: 216.466171, hgln: -0.000000, hglt: 7.243648 },
  { isoDate: "2016-09-12T00:00:00Z", dsun: 216.408397, hgln: -0.000000, hglt: 7.236757 },
  { isoDate: "2016-09-13T00:00:00Z", dsun: 216.350344, hgln: -0.000000, hglt: 7.227773 },
  { isoDate: "2016-09-14T00:00:00Z", dsun: 216.292086, hgln: -0.000000, hglt: 7.216695 },
  { isoDate: "2016-09-15T00:00:00Z", dsun: 216.233691, hgln: -0.000000, hglt: 7.203519 },
  { isoDate: "2016-09-16T00:00:00Z", dsun: 216.175222, hgln: 0.000000, hglt: 7.188246 },
  { isoDate: "2016-09-17T00:00:00Z", dsun: 216.116726, hgln: -0.000000, hglt: 7.170874 },
  { isoDate: "2016-09-18T00:00:00Z", dsun: 216.058233, hgln: 0.000000, hglt: 7.151403 },
  { isoDate: "2016-09-19T00:00:00Z", dsun: 215.999749, hgln: 0.000000, hglt: 7.129831 },
  { isoDate: "2016-09-20T00:00:00Z", dsun: 215.941259, hgln: -0.000000, hglt: 7.106159 },
  { isoDate: "2016-09-21T00:00:00Z", dsun: 215.882727, hgln: -0.000000, hglt: 7.080389 },
  { isoDate: "2016-09-22T00:00:00Z", dsun: 215.824104, hgln: -0.000000, hglt: 7.052523 },
  { isoDate: "2016-09-23T00:00:00Z", dsun: 215.765335, hgln: 0.000000, hglt: 7.022565 },
  { isoDate: "2016-09-24T00:00:00Z", dsun: 215.706361, hgln: -0.000000, hglt: 6.990521 },
  { isoDate: "2016-09-25T00:00:00Z", dsun: 215.647131, hgln: 0.000000, hglt: 6.956397 },
  { isoDate: "2016-09-26T00:00:00Z", dsun: 215.587598, hgln: 0.000000, hglt: 6.920201 },
  { isoDate: "2016-09-27T00:00:00Z", dsun: 215.527724, hgln: 0.000000, hglt: 6.881943 },
  { isoDate: "2016-09-28T00:00:00Z", dsun: 215.467485, hgln: 0.000000, hglt: 6.841631 },
  { isoDate: "2016-09-29T00:00:00Z", dsun: 215.406865, hgln: 0.000000, hglt: 6.799278 },
  { isoDate: "2016-09-30T00:00:00Z", dsun: 215.345859, hgln: -0.000000, hglt: 6.754894 },
  { isoDate: "2016-10-01T00:00:00Z", dsun: 215.284475, hgln: 0.000000, hglt: 6.708492 },
  { isoDate: "2016-10-02T00:00:00Z", dsun: 215.222729, hgln: 0.000000, hglt: 6.660085 },
  { isoDate: "2016-10-03T00:00:00Z", dsun: 215.160648, hgln: 0.000000, hglt: 6.609686 },
  { isoDate: "2016-10-04T00:00:00Z", dsun: 215.098268, hgln: 0.000000, hglt: 6.557309 },
  { isoDate: "2016-10-05T00:00:00Z", dsun: 215.035631, hgln: 0.000000, hglt: 6.502969 },
  { isoDate: "2016-10-06T00:00:00Z", dsun: 214.972785, hgln: 0.000000, hglt: 6.446680 },
  { isoDate: "2016-10-07T00:00:00Z", dsun: 214.909786, hgln: -0.000000, hglt: 6.388458 },
  { isoDate: "2016-10-08T00:00:00Z", dsun: 214.846694, hgln: -0.000000, hglt: 6.328318 },
  { isoDate: "2016-10-09T00:00:00Z", dsun: 214.783576, hgln: 0.000000, hglt: 6.266275 },
  { isoDate: "2016-10-10T00:00:00Z", dsun: 214.720501, hgln: 0.000000, hglt: 6.202345 },
  { isoDate: "2016-10-11T00:00:00Z", dsun: 214.657547, hgln: 0.000000, hglt: 6.136545 },
  { isoDate: "2016-10-12T00:00:00Z", dsun: 214.594793, hgln: 0.000000, hglt: 6.068890 },
  { isoDate: "2016-10-13T00:00:00Z", dsun: 214.532322, hgln: 0.000000, hglt: 5.999397 },
  { isoDate: "2016-10-14T00:00:00Z", dsun: 214.470214, hgln: 0.000000, hglt: 5.928082 },
  { isoDate: "2016-10-15T00:00:00Z", dsun: 214.408541, hgln: 0.000000, hglt: 5.854959 },
  { isoDate: "2016-10-16T00:00:00Z", dsun: 214.347363, hgln: 0.000000, hglt: 5.780045 },
  { isoDate: "2016-10-17T00:00:00Z", dsun: 214.286718, hgln: 0.000000, hglt: 5.703357 },
  { isoDate: "2016-10-18T00:00:00Z", dsun: 214.226617, hgln: -0.000000, hglt: 5.624911 },
  { isoDate: "2016-10-19T00:00:00Z", dsun: 214.167050, hgln: 0.000000, hglt: 5.544726 },
  { isoDate: "2016-10-20T00:00:00Z", dsun: 214.107984, hgln: 0.000000, hglt: 5.462820 },
  { isoDate: "2016-10-21T00:00:00Z", dsun: 214.049371, hgln: -0.000000, hglt: 5.379217 },
  { isoDate: "2016-10-22T00:00:00Z", dsun: 213.991160, hgln: 0.000000, hglt: 5.293937 },
  { isoDate: "2016-10-23T00:00:00Z", dsun: 213.933295, hgln: -0.000000, hglt: 5.207007 },
  { isoDate: "2016-10-24T00:00:00Z", dsun: 213.875727, hgln: 0.000000, hglt: 5.118451 },
  { isoDate: "2016-10-25T00:00:00Z", dsun: 213.818413, hgln: 0.000000, hglt: 5.028296 },
  { isoDate: "2016-10-26T00:00:00Z", dsun: 213.761319, hgln: 0.000000, hglt: 4.936570 },
  { isoDate: "2016-10-27T00:00:00Z", dsun: 213.704418, hgln: 0.000000, hglt: 4.843300 },
  { isoDate: "2016-10-28T00:00:00Z", dsun: 213.647693, hgln: 0.000000, hglt: 4.748517 },
  { isoDate: "2016-10-29T00:00:00Z", dsun: 213.591134, hgln: 0.000000, hglt: 4.652249 },
  { isoDate: "2016-10-30T00:00:00Z", dsun: 213.534742, hgln: 0.000000, hglt: 4.554528 },
  { isoDate: "2016-10-31T00:00:00Z", dsun: 213.478524, hgln: 0.000000, hglt: 4.455382 },
  { isoDate: "2016-11-01T00:00:00Z", dsun: 213.422498, hgln: 0.000000, hglt: 4.354844 },
  { isoDate: "2016-11-02T00:00:00Z", dsun: 213.366688, hgln: 0.000000, hglt: 4.252944 },
  { isoDate: "2016-11-03T00:00:00Z", dsun: 213.311127, hgln: -0.000000, hglt: 4.149714 },
  { isoDate: "2016-11-04T00:00:00Z", dsun: 213.255856, hgln: -0.000000, hglt: 4.045186 },
  { isoDate: "2016-11-05T00:00:00Z", dsun: 213.200921, hgln: 0.000000, hglt: 3.939391 },
  { isoDate: "2016-11-06T00:00:00Z", dsun: 213.146378, hgln: 0.000000, hglt: 3.832361 },
  { isoDate: "2016-11-07T00:00:00Z", dsun: 213.092287, hgln: 0.000000, hglt: 3.724129 },
  { isoDate: "2016-11-08T00:00:00Z", dsun: 213.038718, hgln: 0.000000, hglt: 3.614725 },
  { isoDate: "2016-11-09T00:00:00Z", dsun: 212.985746, hgln: -0.000000, hglt: 3.504182 },
  { isoDate: "2016-11-10T00:00:00Z", dsun: 212.933451, hgln: 0.000000, hglt: 3.392531 },
  { isoDate: "2016-11-11T00:00:00Z", dsun: 212.881919, hgln: 0.000000, hglt: 3.279803 },
  { isoDate: "2016-11-12T00:00:00Z", dsun: 212.831233, hgln: 0.000000, hglt: 3.166029 },
  { isoDate: "2016-11-13T00:00:00Z", dsun: 212.781470, hgln: -0.000000, hglt: 3.051239 },
  { isoDate: "2016-11-14T00:00:00Z", dsun: 212.732694, hgln: -0.000000, hglt: 2.935465 },
  { isoDate: "2016-11-15T00:00:00Z", dsun: 212.684947, hgln: 0.000000, hglt: 2.818736 },
  { isoDate: "2016-11-16T00:00:00Z", dsun: 212.638245, hgln: 0.000000, hglt: 2.701086 },
  { isoDate: "2016-11-17T00:00:00Z", dsun: 212.592579, hgln: 0.000000, hglt: 2.582546 },
  { isoDate: "2016-11-18T00:00:00Z", dsun: 212.547917, hgln: -0.000000, hglt: 2.463152 },
  { isoDate: "2016-11-19T00:00:00Z", dsun: 212.504215, hgln: 0.000000, hglt: 2.342938 },
  { isoDate: "2016-11-20T00:00:00Z", dsun: 212.461419, hgln: -0.000000, hglt: 2.221943 },
  { isoDate: "2016-11-21T00:00:00Z", dsun: 212.419476, hgln: -0.000000, hglt: 2.100204 },
  { isoDate: "2016-11-22T00:00:00Z", dsun: 212.378335, hgln: 0.000000, hglt: 1.977761 },
  { isoDate: "2016-11-23T00:00:00Z", dsun: 212.337951, hgln: -0.000000, hglt: 1.854653 },
  { isoDate: "2016-11-24T00:00:00Z", dsun: 212.298286, hgln: -0.000000, hglt: 1.730922 },
  { isoDate: "2016-11-25T00:00:00Z", dsun: 212.259305, hgln: -0.000000, hglt: 1.606606 },
  { isoDate: "2016-11-26T00:00:00Z", dsun: 212.220984, hgln: -0.000000, hglt: 1.481749 },
  { isoDate: "2016-11-27T00:00:00Z", dsun: 212.183303, hgln: -0.000000, hglt: 1.356390 },
  { isoDate: "2016-11-28T00:00:00Z", dsun: 212.146248, hgln: -0.000000, hglt: 1.230571 },
  { isoDate: "2016-11-29T00:00:00Z", dsun: 212.109815, hgln: -0.000000, hglt: 1.104334 },
  { isoDate: "2016-11-30T00:00:00Z", dsun: 212.074005, hgln: 0.000000, hglt: 0.977720 },
  { isoDate: "2016-12-01T00:00:00Z", dsun: 212.038828, hgln: 0.000000, hglt: 0.850771 },
  { isoDate: "2016-12-02T00:00:00Z", dsun: 212.004301, hgln: 0.000000, hglt: 0.723528 },
  { isoDate: "2016-12-03T00:00:00Z", dsun: 211.970452, hgln: -0.000000, hglt: 0.596033 },
  { isoDate: "2016-12-04T00:00:00Z", dsun: 211.937316, hgln: 0.000000, hglt: 0.468326 },
  { isoDate: "2016-12-05T00:00:00Z", dsun: 211.904937, hgln: -0.000000, hglt: 0.340448 },
  { isoDate: "2016-12-06T00:00:00Z", dsun: 211.873368, hgln: 0.000000, hglt: 0.212440 },
  { isoDate: "2016-12-07T00:00:00Z", dsun: 211.842670, hgln: -0.000000, hglt: 0.084342 },
  { isoDate: "2016-12-08T00:00:00Z", dsun: 211.812913, hgln: -0.000000, hglt: -0.043807 },
  { isoDate: "2016-12-09T00:00:00Z", dsun: 211.784174, hgln: 0.000000, hglt: -0.171969 },
  { isoDate: "2016-12-10T00:00:00Z", dsun: 211.756533, hgln: 0.000000, hglt: -0.300103 },
  { isoDate: "2016-12-11T00:00:00Z", dsun: 211.730070, hgln: -0.000000, hglt: -0.428173 },
  { isoDate: "2016-12-12T00:00:00Z", dsun: 211.704859, hgln: 0.000000, hglt: -0.556141 },
  { isoDate: "2016-12-13T00:00:00Z", dsun: 211.680961, hgln: 0.000000, hglt: -0.683969 },
  { isoDate: "2016-12-14T00:00:00Z", dsun: 211.658415, hgln: 0.000000, hglt: -0.811619 },
  { isoDate: "2016-12-15T00:00:00Z", dsun: 211.637239, hgln: 0.000000, hglt: -0.939053 },
  { isoDate: "2016-12-16T00:00:00Z", dsun: 211.617423, hgln: 0.000000, hglt: -1.066232 },
  { isoDate: "2016-12-17T00:00:00Z", dsun: 211.598937, hgln: -0.000000, hglt: -1.193115 },
  { isoDate: "2016-12-18T00:00:00Z", dsun: 211.581736, hgln: 0.000000, hglt: -1.319662 },
  { isoDate: "2016-12-19T00:00:00Z", dsun: 211.565767, hgln: 0.000000, hglt: -1.445832 },
  { isoDate: "2016-12-20T00:00:00Z", dsun: 211.550975, hgln: -0.000000, hglt: -1.571581 },
  { isoDate: "2016-12-21T00:00:00Z", dsun: 211.537303, hgln: 0.000000, hglt: -1.696869 },
  { isoDate: "2016-12-22T00:00:00Z", dsun: 211.524701, hgln: 0.000000, hglt: -1.821652 },
  { isoDate: "2016-12-23T00:00:00Z", dsun: 211.513120, hgln: -0.000000, hglt: -1.945888 },
  { isoDate: "2016-12-24T00:00:00Z", dsun: 211.502516, hgln: -0.000000, hglt: -2.069535 },
  { isoDate: "2016-12-25T00:00:00Z", dsun: 211.492851, hgln: 0.000000, hglt: -2.192552 },
  { isoDate: "2016-12-26T00:00:00Z", dsun: 211.484089, hgln: 0.000000, hglt: -2.314896 },
  { isoDate: "2016-12-27T00:00:00Z", dsun: 211.476201, hgln: 0.000000, hglt: -2.436525 },
  { isoDate: "2016-12-28T00:00:00Z", dsun: 211.469163, hgln: -0.000000, hglt: -2.557400 },
  { isoDate: "2016-12-29T00:00:00Z", dsun: 211.462957, hgln: -0.000000, hglt: -2.677478 },
  { isoDate: "2016-12-30T00:00:00Z", dsun: 211.457574, hgln: 0.000000, hglt: -2.796720 },
  { isoDate: "2016-12-31T00:00:00Z", dsun: 211.453012, hgln: 0.000000, hglt: -2.915087 },
  { isoDate: "2017-01-01T00:00:00Z", dsun: 211.449282, hgln: 0.000000, hglt: -3.032539 },
  { isoDate: "2017-01-02T00:00:00Z", dsun: 211.446403, hgln: 0.000000, hglt: -3.149037 },
  { isoDate: "2017-01-03T00:00:00Z", dsun: 211.444406, hgln: 0.000000, hglt: -3.264544 },
  { isoDate: "2017-01-04T00:00:00Z", dsun: 211.443335, hgln: -0.000000, hglt: -3.379023 },
  { isoDate: "2017-01-05T00:00:00Z", dsun: 211.443241, hgln: -0.000000, hglt: -3.492437 },
  { isoDate: "2017-01-06T00:00:00Z", dsun: 211.444186, hgln: 0.000000, hglt: -3.604751 },
  { isoDate: "2017-01-07T00:00:00Z", dsun: 211.446239, hgln: 0.000000, hglt: -3.715931 },
  { isoDate: "2017-01-08T00:00:00Z", dsun: 211.449471, hgln: 0.000000, hglt: -3.825942 },
  { isoDate: "2017-01-09T00:00:00Z", dsun: 211.453955, hgln: 0.000000, hglt: -3.934750 },
  { isoDate: "2017-01-10T00:00:00Z", dsun: 211.459754, hgln: 0.000000, hglt: -4.042321 },
  { isoDate: "2017-01-11T00:00:00Z", dsun: 211.466922, hgln: 0.000000, hglt: -4.148624 },
  { isoDate: "2017-01-12T00:00:00Z", dsun: 211.475493, hgln: 0.000000, hglt: -4.253625 },
  { isoDate: "2017-01-13T00:00:00Z", dsun: 211.485477, hgln: 0.000000, hglt: -4.357289 },
  { isoDate: "2017-01-14T00:00:00Z", dsun: 211.496866, hgln: -0.000000, hglt: -4.459585 },
  { isoDate: "2017-01-15T00:00:00Z", dsun: 211.509631, hgln: 0.000000, hglt: -4.560479 },
  { isoDate: "2017-01-16T00:00:00Z", dsun: 211.523728, hgln: 0.000000, hglt: -4.659937 },
  { isoDate: "2017-01-17T00:00:00Z", dsun: 211.539103, hgln: 0.000000, hglt: -4.757926 },
  { isoDate: "2017-01-18T00:00:00Z", dsun: 211.555698, hgln: -0.000000, hglt: -4.854413 },
  { isoDate: "2017-01-19T00:00:00Z", dsun: 211.573455, hgln: -0.000000, hglt: -4.949366 },
  { isoDate: "2017-01-20T00:00:00Z", dsun: 211.592313, hgln: 0.000000, hglt: -5.042752 },
  { isoDate: "2017-01-21T00:00:00Z", dsun: 211.612214, hgln: 0.000000, hglt: -5.134541 },
  { isoDate: "2017-01-22T00:00:00Z", dsun: 211.633105, hgln: -0.000000, hglt: -5.224701 },
  { isoDate: "2017-01-23T00:00:00Z", dsun: 211.654930, hgln: 0.000000, hglt: -5.313203 },
  { isoDate: "2017-01-24T00:00:00Z", dsun: 211.677639, hgln: 0.000000, hglt: -5.400017 },
  { isoDate: "2017-01-25T00:00:00Z", dsun: 211.701185, hgln: -0.000000, hglt: -5.485115 },
  { isoDate: "2017-01-26T00:00:00Z", dsun: 211.725523, hgln: -0.000000, hglt: -5.568469 },
  { isoDate: "2017-01-27T00:00:00Z", dsun: 211.750614, hgln: 0.000000, hglt: -5.650053 },
  { isoDate: "2017-01-28T00:00:00Z", dsun: 211.776429, hgln: 0.000000, hglt: -5.729840 },
  { isoDate: "2017-01-29T00:00:00Z", dsun: 211.802946, hgln: 0.000000, hglt: -5.807805 },
  { isoDate: "2017-01-30T00:00:00Z", dsun: 211.830156, hgln: 0.000000, hglt: -5.883925 },
  { isoDate: "2017-01-31T00:00:00Z", dsun: 211.858065, hgln: -0.000000, hglt: -5.958176 },
  { isoDate: "2017-02-01T00:00:00Z", dsun: 211.886690, hgln: 0.000000, hglt: -6.030537 },
  { isoDate: "2017-02-02T00:00:00Z", dsun: 211.916065, hgln: -0.000000, hglt: -6.100987 },
  { isoDate: "2017-02-03T00:00:00Z", dsun: 211.946235, hgln: 0.000000, hglt: -6.169504 },
  { isoDate: "2017-02-04T00:00:00Z", dsun: 211.977253, hgln: 0.000000, hglt: -6.236071 },
  { isoDate: "2017-02-05T00:00:00Z", dsun: 212.009182, hgln: -0.000000, hglt: -6.300667 },
  { isoDate: "2017-02-06T00:00:00Z", dsun: 212.042085, hgln: 0.000000, hglt: -6.363274 },
  { isoDate: "2017-02-07T00:00:00Z", dsun: 212.076022, hgln: 0.000000, hglt: -6.423875 },
  { isoDate: "2017-02-08T00:00:00Z", dsun: 212.111049, hgln: -0.000000, hglt: -6.482451 },
  { isoDate: "2017-02-09T00:00:00Z", dsun: 212.147207, hgln: 0.000000, hglt: -6.538986 },
  { isoDate: "2017-02-10T00:00:00Z", dsun: 212.184521, hgln: 0.000000, hglt: -6.593462 },
  { isoDate: "2017-02-11T00:00:00Z", dsun: 212.223000, hgln: 0.000000, hglt: -6.645862 },
  { isoDate: "2017-02-12T00:00:00Z", dsun: 212.262634, hgln: -0.000000, hglt: -6.696172 },
  { isoDate: "2017-02-13T00:00:00Z", dsun: 212.303394, hgln: 0.000000, hglt: -6.744374 },
  { isoDate: "2017-02-14T00:00:00Z", dsun: 212.345240, hgln: -0.000000, hglt: -6.790454 },
  { isoDate: "2017-02-15T00:00:00Z", dsun: 212.388122, hgln: -0.000000, hglt: -6.834398 },
  { isoDate: "2017-02-16T00:00:00Z", dsun: 212.431982, hgln: -0.000000, hglt: -6.876193 },
  { isoDate: "2017-02-17T00:00:00Z", dsun: 212.476760, hgln: 0.000000, hglt: -6.915825 },
  { isoDate: "2017-02-18T00:00:00Z", dsun: 212.522392, hgln: 0.000000, hglt: -6.953285 },
  { isoDate: "2017-02-19T00:00:00Z", dsun: 212.568815, hgln: -0.000000, hglt: -6.988560 },
  { isoDate: "2017-02-20T00:00:00Z", dsun: 212.615964, hgln: -0.000000, hglt: -7.021642 },
  { isoDate: "2017-02-21T00:00:00Z", dsun: 212.663776, hgln: -0.000000, hglt: -7.052522 },
  { isoDate: "2017-02-22T00:00:00Z", dsun: 212.712186, hgln: 0.000000, hglt: -7.081193 },
  { isoDate: "2017-02-23T00:00:00Z", dsun: 212.761130, hgln: 0.000000, hglt: -7.107648 },
  { isoDate: "2017-02-24T00:00:00Z", dsun: 212.810549, hgln: -0.000000, hglt: -7.131882 },
  { isoDate: "2017-02-25T00:00:00Z", dsun: 212.860385, hgln: 0.000000, hglt: -7.153891 },
  { isoDate: "2017-02-26T00:00:00Z", dsun: 212.910587, hgln: -0.000000, hglt: -7.173672 },
  { isoDate: "2017-02-27T00:00:00Z", dsun: 212.961118, hgln: 0.000000, hglt: -7.191223 },
  { isoDate: "2017-02-28T00:00:00Z", dsun: 213.011952, hgln: -0.000000, hglt: -7.206543 },
  { isoDate: "2017-03-01T00:00:00Z", dsun: 213.063081, hgln: 0.000000, hglt: -7.219633 },
  { isoDate: "2017-03-02T00:00:00Z", dsun: 213.114514, hgln: 0.000000, hglt: -7.230492 },
  { isoDate: "2017-03-03T00:00:00Z", dsun: 213.166279, hgln: -0.000000, hglt: -7.239124 },
  { isoDate: "2017-03-04T00:00:00Z", dsun: 213.218415, hgln: -0.000000, hglt: -7.245530 },
  { isoDate: "2017-03-05T00:00:00Z", dsun: 213.270975, hgln: -0.000000, hglt: -7.249712 },
  { isoDate: "2017-03-06T00:00:00Z", dsun: 213.324015, hgln: 0.000000, hglt: -7.251673 },
  { isoDate: "2017-03-07T00:00:00Z", dsun: 213.377592, hgln: 0.000000, hglt: -7.251416 },
  { isoDate: "2017-03-08T00:00:00Z", dsun: 213.431761, hgln: -0.000000, hglt: -7.248944 },
  { isoDate: "2017-03-09T00:00:00Z", dsun: 213.486565, hgln: 0.000000, hglt: -7.244261 },
  { isoDate: "2017-03-10T00:00:00Z", dsun: 213.542040, hgln: 0.000000, hglt: -7.237368 },
  { isoDate: "2017-03-11T00:00:00Z", dsun: 213.598206, hgln: -0.000000, hglt: -7.228272 },
  { isoDate: "2017-03-12T00:00:00Z", dsun: 213.655068, hgln: -0.000000, hglt: -7.216975 },
  { isoDate: "2017-03-13T00:00:00Z", dsun: 213.712618, hgln: 0.000000, hglt: -7.203483 },
  { isoDate: "2017-03-14T00:00:00Z", dsun: 213.770833, hgln: 0.000000, hglt: -7.187803 },
  { isoDate: "2017-03-15T00:00:00Z", dsun: 213.829678, hgln: -0.000000, hglt: -7.169940 },
  { isoDate: "2017-03-16T00:00:00Z", dsun: 213.889109, hgln: 0.000000, hglt: -7.149903 },
  { isoDate: "2017-03-17T00:00:00Z", dsun: 213.949074, hgln: -0.000000, hglt: -7.127700 },
  { isoDate: "2017-03-18T00:00:00Z", dsun: 214.009517, hgln: 0.000000, hglt: -7.103341 },
  { isoDate: "2017-03-19T00:00:00Z", dsun: 214.070377, hgln: -0.000000, hglt: -7.076835 },
  { isoDate: "2017-03-20T00:00:00Z", dsun: 214.131588, hgln: 0.000000, hglt: -7.048195 },
  { isoDate: "2017-03-21T00:00:00Z", dsun: 214.193086, hgln: -0.000000, hglt: -7.017433 },
  { isoDate: "2017-03-22T00:00:00Z", dsun: 214.254799, hgln: 0.000000, hglt: -6.984563 },
  { isoDate: "2017-03-23T00:00:00Z", dsun: 214.316658, hgln: 0.000000, hglt: -6.949598 },
  { isoDate: "2017-03-24T00:00:00Z", dsun: 214.378590, hgln: 0.000000, hglt: -6.912554 },
  { isoDate: "2017-03-25T00:00:00Z", dsun: 214.440521, hgln: 0.000000, hglt: -6.873449 },
  { isoDate: "2017-03-26T00:00:00Z", dsun: 214.502382, hgln: 0.000000, hglt: -6.832299 },
  { isoDate: "2017-03-27T00:00:00Z", dsun: 214.564109, hgln: 0.000000, hglt: -6.789122 },
  { isoDate: "2017-03-28T00:00:00Z", dsun: 214.625649, hgln: -0.000000, hglt: -6.743940 },
  { isoDate: "2017-03-29T00:00:00Z", dsun: 214.686965, hgln: 0.000000, hglt: -6.696772 },
  { isoDate: "2017-03-30T00:00:00Z", dsun: 214.748041, hgln: 0.000000, hglt: -6.647639 },
  { isoDate: "2017-03-31T00:00:00Z", dsun: 214.808881, hgln: -0.000000, hglt: -6.596563 },
  { isoDate: "2017-04-01T00:00:00Z", dsun: 214.869511, hgln: 0.000000, hglt: -6.543565 },
  { isoDate: "2017-04-02T00:00:00Z", dsun: 214.929972, hgln: 0.000000, hglt: -6.488665 },
  { isoDate: "2017-04-03T00:00:00Z", dsun: 214.990316, hgln: 0.000000, hglt: -6.431886 },
  { isoDate: "2017-04-04T00:00:00Z", dsun: 215.050599, hgln: 0.000000, hglt: -6.373246 },
  { isoDate: "2017-04-05T00:00:00Z", dsun: 215.110875, hgln: 0.000000, hglt: -6.312766 },
  { isoDate: "2017-04-06T00:00:00Z", dsun: 215.171196, hgln: 0.000000, hglt: -6.250467 },
  { isoDate: "2017-04-07T00:00:00Z", dsun: 215.231602, hgln: -0.000000, hglt: -6.186367 },
  { isoDate: "2017-04-08T00:00:00Z", dsun: 215.292126, hgln: 0.000000, hglt: -6.120489 },
  { isoDate: "2017-04-09T00:00:00Z", dsun: 215.352786, hgln: 0.000000, hglt: -6.052851 },
  { isoDate: "2017-04-10T00:00:00Z", dsun: 215.413591, hgln: 0.000000, hglt: -5.983475 },
  { isoDate: "2017-04-11T00:00:00Z", dsun: 215.474539, hgln: 0.000000, hglt: -5.912383 },
  { isoDate: "2017-04-12T00:00:00Z", dsun: 215.535614, hgln: -0.000000, hglt: -5.839596 },
  { isoDate: "2017-04-13T00:00:00Z", dsun: 215.596791, hgln: -0.000000, hglt: -5.765136 },
  { isoDate: "2017-04-14T00:00:00Z", dsun: 215.658037, hgln: -0.000000, hglt: -5.689029 },
  { isoDate: "2017-04-15T00:00:00Z", dsun: 215.719310, hgln: 0.000000, hglt: -5.611296 },
  { isoDate: "2017-04-16T00:00:00Z", dsun: 215.780562, hgln: -0.000000, hglt: -5.531964 },
  { isoDate: "2017-04-17T00:00:00Z", dsun: 215.841739, hgln: -0.000000, hglt: -5.451057 },
  { isoDate: "2017-04-18T00:00:00Z", dsun: 215.902783, hgln: -0.000000, hglt: -5.368601 },
  { isoDate: "2017-04-19T00:00:00Z", dsun: 215.963630, hgln: 0.000000, hglt: -5.284624 },
  { isoDate: "2017-04-20T00:00:00Z", dsun: 216.024212, hgln: 0.000000, hglt: -5.199154 },
  { isoDate: "2017-04-21T00:00:00Z", dsun: 216.084457, hgln: -0.000000, hglt: -5.112217 },
  { isoDate: "2017-04-22T00:00:00Z", dsun: 216.144289, hgln: -0.000000, hglt: -5.023845 },
  { isoDate: "2017-04-23T00:00:00Z", dsun: 216.203631, hgln: 0.000000, hglt: -4.934065 },
  { isoDate: "2017-04-24T00:00:00Z", dsun: 216.262405, hgln: 0.000000, hglt: -4.842911 },
  { isoDate: "2017-04-25T00:00:00Z", dsun: 216.320538, hgln: 0.000000, hglt: -4.750412 },
  { isoDate: "2017-04-26T00:00:00Z", dsun: 216.377971, hgln: 0.000000, hglt: -4.656602 },
  { isoDate: "2017-04-27T00:00:00Z", dsun: 216.434660, hgln: 0.000000, hglt: -4.561513 },
  { isoDate: "2017-04-28T00:00:00Z", dsun: 216.490584, hgln: -0.000000, hglt: -4.465178 },
  { isoDate: "2017-04-29T00:00:00Z", dsun: 216.545747, hgln: 0.000000, hglt: -4.367630 },
  { isoDate: "2017-04-30T00:00:00Z", dsun: 216.600177, hgln: -0.000000, hglt: -4.268899 },
  { isoDate: "2017-05-01T00:00:00Z", dsun: 216.653917, hgln: -0.000000, hglt: -4.169017 },
  { isoDate: "2017-05-02T00:00:00Z", dsun: 216.707023, hgln: 0.000000, hglt: -4.068014 },
  { isoDate: "2017-05-03T00:00:00Z", dsun: 216.759551, hgln: 0.000000, hglt: -3.965919 },
  { isoDate: "2017-05-04T00:00:00Z", dsun: 216.811559, hgln: -0.000000, hglt: -3.862762 },
  { isoDate: "2017-05-05T00:00:00Z", dsun: 216.863096, hgln: 0.000000, hglt: -3.758570 },
  { isoDate: "2017-05-06T00:00:00Z", dsun: 216.914205, hgln: 0.000000, hglt: -3.653372 },
  { isoDate: "2017-05-07T00:00:00Z", dsun: 216.964920, hgln: -0.000000, hglt: -3.547197 },
  { isoDate: "2017-05-08T00:00:00Z", dsun: 217.015265, hgln: 0.000000, hglt: -3.440074 },
  { isoDate: "2017-05-09T00:00:00Z", dsun: 217.065257, hgln: -0.000000, hglt: -3.332031 },
  { isoDate: "2017-05-10T00:00:00Z", dsun: 217.114900, hgln: 0.000000, hglt: -3.223097 },
  { isoDate: "2017-05-11T00:00:00Z", dsun: 217.164192, hgln: 0.000000, hglt: -3.113302 },
  { isoDate: "2017-05-12T00:00:00Z", dsun: 217.213119, hgln: 0.000000, hglt: -3.002675 },
  { isoDate: "2017-05-13T00:00:00Z", dsun: 217.261662, hgln: 0.000000, hglt: -2.891247 },
  { isoDate: "2017-05-14T00:00:00Z", dsun: 217.309792, hgln: -0.000000, hglt: -2.779049 },
  { isoDate: "2017-05-15T00:00:00Z", dsun: 217.357472, hgln: -0.000000, hglt: -2.666110 },
  { isoDate: "2017-05-16T00:00:00Z", dsun: 217.404659, hgln: 0.000000, hglt: -2.552463 },
  { isoDate: "2017-05-17T00:00:00Z", dsun: 217.451305, hgln: -0.000000, hglt: -2.438140 },
  { isoDate: "2017-05-18T00:00:00Z", dsun: 217.497352, hgln: -0.000000, hglt: -2.323173 },
  { isoDate: "2017-05-19T00:00:00Z", dsun: 217.542738, hgln: -0.000000, hglt: -2.207595 },
  { isoDate: "2017-05-20T00:00:00Z", dsun: 217.587394, hgln: -0.000000, hglt: -2.091438 },
  { isoDate: "2017-05-21T00:00:00Z", dsun: 217.631245, hgln: -0.000000, hglt: -1.974738 },
  { isoDate: "2017-05-22T00:00:00Z", dsun: 217.674214, hgln: -0.000000, hglt: -1.857529 },
  { isoDate: "2017-05-23T00:00:00Z", dsun: 217.716220, hgln: -0.000000, hglt: -1.739845 },
  { isoDate: "2017-05-24T00:00:00Z", dsun: 217.757191, hgln: -0.000000, hglt: -1.621722 },
  { isoDate: "2017-05-25T00:00:00Z", dsun: 217.797062, hgln: -0.000000, hglt: -1.503196 },
  { isoDate: "2017-05-26T00:00:00Z", dsun: 217.835789, hgln: -0.000000, hglt: -1.384304 },
  { isoDate: "2017-05-27T00:00:00Z", dsun: 217.873352, hgln: 0.000000, hglt: -1.265079 },
  { isoDate: "2017-05-28T00:00:00Z", dsun: 217.909758, hgln: 0.000000, hglt: -1.145557 },
  { isoDate: "2017-05-29T00:00:00Z", dsun: 217.945036, hgln: 0.000000, hglt: -1.025772 },
  { isoDate: "2017-05-30T00:00:00Z", dsun: 217.979232, hgln: 0.000000, hglt: -0.905756 },
  { isoDate: "2017-05-31T00:00:00Z", dsun: 218.012406, hgln: -0.000000, hglt: -0.785541 },
  { isoDate: "2017-06-01T00:00:00Z", dsun: 218.044618, hgln: -0.000000, hglt: -0.665157 },
  { isoDate: "2017-06-02T00:00:00Z", dsun: 218.075926, hgln: -0.000000, hglt: -0.544637 },
  { isoDate: "2017-06-03T00:00:00Z", dsun: 218.106385, hgln: 0.000000, hglt: -0.424010 },
  { isoDate: "2017-06-04T00:00:00Z", dsun: 218.136042, hgln: -0.000000, hglt: -0.303306 },
  { isoDate: "2017-06-05T00:00:00Z", dsun: 218.164938, hgln: -0.000000, hglt: -0.182555 },
  { isoDate: "2017-06-06T00:00:00Z", dsun: 218.193107, hgln: -0.000000, hglt: -0.061788 },
  { isoDate: "2017-06-07T00:00:00Z", dsun: 218.220572, hgln: 0.000000, hglt: 0.058966 },
  { isoDate: "2017-06-08T00:00:00Z", dsun: 218.247353, hgln: 0.000000, hglt: 0.179676 },
  { isoDate: "2017-06-09T00:00:00Z", dsun: 218.273460, hgln: -0.000000, hglt: 0.300311 },
  { isoDate: "2017-06-10T00:00:00Z", dsun: 218.298894, hgln: -0.000000, hglt: 0.420842 },
  { isoDate: "2017-06-11T00:00:00Z", dsun: 218.323652, hgln: -0.000000, hglt: 0.541236 },
  { isoDate: "2017-06-12T00:00:00Z", dsun: 218.347718, hgln: 0.000000, hglt: 0.661465 },
  { isoDate: "2017-06-13T00:00:00Z", dsun: 218.371070, hgln: -0.000000, hglt: 0.781495 },
  { isoDate: "2017-06-14T00:00:00Z", dsun: 218.393678, hgln: -0.000000, hglt: 0.901297 },
  { isoDate: "2017-06-15T00:00:00Z", dsun: 218.415503, hgln: 0.000000, hglt: 1.020837 },
  { isoDate: "2017-06-16T00:00:00Z", dsun: 218.436497, hgln: 0.000000, hglt: 1.140085 },
  { isoDate: "2017-06-17T00:00:00Z", dsun: 218.456605, hgln: 0.000000, hglt: 1.259008 },
  { isoDate: "2017-06-18T00:00:00Z", dsun: 218.475762, hgln: -0.000000, hglt: 1.377573 },
  { isoDate: "2017-06-19T00:00:00Z", dsun: 218.493899, hgln: -0.000000, hglt: 1.495748 },
  { isoDate: "2017-06-20T00:00:00Z", dsun: 218.510939, hgln: 0.000000, hglt: 1.613500 },
  { isoDate: "2017-06-21T00:00:00Z", dsun: 218.526807, hgln: 0.000000, hglt: 1.730795 },
  { isoDate: "2017-06-22T00:00:00Z", dsun: 218.541430, hgln: -0.000000, hglt: 1.847600 },
  { isoDate: "2017-06-23T00:00:00Z", dsun: 218.554750, hgln: -0.000000, hglt: 1.963883 },
  { isoDate: "2017-06-24T00:00:00Z", dsun: 218.566724, hgln: -0.000000, hglt: 2.079609 },
  { isoDate: "2017-06-25T00:00:00Z", dsun: 218.577337, hgln: 0.000000, hglt: 2.194747 },
  { isoDate: "2017-06-26T00:00:00Z", dsun: 218.586598, hgln: -0.000000, hglt: 2.309266 },
  { isoDate: "2017-06-27T00:00:00Z", dsun: 218.594539, hgln: 0.000000, hglt: 2.423136 },
  { isoDate: "2017-06-28T00:00:00Z", dsun: 218.601210, hgln: 0.000000, hglt: 2.536326 },
  { isoDate: "2017-06-29T00:00:00Z", dsun: 218.606669, hgln: -0.000000, hglt: 2.648807 },
  { isoDate: "2017-06-30T00:00:00Z", dsun: 218.610981, hgln: -0.000000, hglt: 2.760552 },
  { isoDate: "2017-07-01T00:00:00Z", dsun: 218.614208, hgln: 0.000000, hglt: 2.871532 },
  { isoDate: "2017-07-02T00:00:00Z", dsun: 218.616408, hgln: -0.000000, hglt: 2.981719 },
  { isoDate: "2017-07-03T00:00:00Z", dsun: 218.617637, hgln: -0.000000, hglt: 3.091085 },
  { isoDate: "2017-07-04T00:00:00Z", dsun: 218.617944, hgln: -0.000000, hglt: 3.199604 },
  { isoDate: "2017-07-05T00:00:00Z", dsun: 218.617371, hgln: -0.000000, hglt: 3.307247 },
  { isoDate: "2017-07-06T00:00:00Z", dsun: 218.615957, hgln: -0.000000, hglt: 3.413987 },
  { isoDate: "2017-07-07T00:00:00Z", dsun: 218.613733, hgln: 0.000000, hglt: 3.519797 },
  { isoDate: "2017-07-08T00:00:00Z", dsun: 218.610726, hgln: 0.000000, hglt: 3.624649 },
  { isoDate: "2017-07-09T00:00:00Z", dsun: 218.606954, hgln: -0.000000, hglt: 3.728516 },
  { isoDate: "2017-07-10T00:00:00Z", dsun: 218.602427, hgln: 0.000000, hglt: 3.831371 },
  { isoDate: "2017-07-11T00:00:00Z", dsun: 218.597146, hgln: -0.000000, hglt: 3.933185 },
  { isoDate: "2017-07-12T00:00:00Z", dsun: 218.591105, hgln: -0.000000, hglt: 4.033933 },
  { isoDate: "2017-07-13T00:00:00Z", dsun: 218.584284, hgln: -0.000000, hglt: 4.133585 },
  { isoDate: "2017-07-14T00:00:00Z", dsun: 218.576655, hgln: 0.000000, hglt: 4.232114 },
  { isoDate: "2017-07-15T00:00:00Z", dsun: 218.568179, hgln: 0.000000, hglt: 4.329493 },
  { isoDate: "2017-07-16T00:00:00Z", dsun: 218.558807, hgln: -0.000000, hglt: 4.425695 },
  { isoDate: "2017-07-17T00:00:00Z", dsun: 218.548481, hgln: -0.000000, hglt: 4.520691 },
  { isoDate: "2017-07-18T00:00:00Z", dsun: 218.537136, hgln: -0.000000, hglt: 4.614455 },
  { isoDate: "2017-07-19T00:00:00Z", dsun: 218.524699, hgln: 0.000000, hglt: 4.706959 },
  { isoDate: "2017-07-20T00:00:00Z", dsun: 218.511102, hgln: -0.000000, hglt: 4.798176 },
  { isoDate: "2017-07-21T00:00:00Z", dsun: 218.496278, hgln: 0.000000, hglt: 4.888080 },
  { isoDate: "2017-07-22T00:00:00Z", dsun: 218.480176, hgln: 0.000000, hglt: 4.976645 },
  { isoDate: "2017-07-23T00:00:00Z", dsun: 218.462760, hgln: 0.000000, hglt: 5.063846 },
  { isoDate: "2017-07-24T00:00:00Z", dsun: 218.444021, hgln: -0.000000, hglt: 5.149657 },
  { isoDate: "2017-07-25T00:00:00Z", dsun: 218.423970, hgln: -0.000000, hglt: 5.234056 },
  { isoDate: "2017-07-26T00:00:00Z", dsun: 218.402640, hgln: -0.000000, hglt: 5.317018 },
  { isoDate: "2017-07-27T00:00:00Z", dsun: 218.380082, hgln: -0.000000, hglt: 5.398523 },
  { isoDate: "2017-07-28T00:00:00Z", dsun: 218.356355, hgln: 0.000000, hglt: 5.478546 },
  { isoDate: "2017-07-29T00:00:00Z", dsun: 218.331522, hgln: 0.000000, hglt: 5.557066 },
  { isoDate: "2017-07-30T00:00:00Z", dsun: 218.305648, hgln: -0.000000, hglt: 5.634063 },
  { isoDate: "2017-07-31T00:00:00Z", dsun: 218.278799, hgln: 0.000000, hglt: 5.709514 },
  { isoDate: "2017-08-01T00:00:00Z", dsun: 218.251033, hgln: 0.000000, hglt: 5.783398 },
  { isoDate: "2017-08-02T00:00:00Z", dsun: 218.222410, hgln: 0.000000, hglt: 5.855695 },
  { isoDate: "2017-08-03T00:00:00Z", dsun: 218.192984, hgln: 0.000000, hglt: 5.926384 },
  { isoDate: "2017-08-04T00:00:00Z", dsun: 218.162805, hgln: -0.000000, hglt: 5.995444 },
  { isoDate: "2017-08-05T00:00:00Z", dsun: 218.131919, hgln: -0.000000, hglt: 6.062855 },
  { isoDate: "2017-08-06T00:00:00Z", dsun: 218.100368, hgln: 0.000000, hglt: 6.128597 },
  { isoDate: "2017-08-07T00:00:00Z", dsun: 218.068186, hgln: 0.000000, hglt: 6.192649 },
  { isoDate: "2017-08-08T00:00:00Z", dsun: 218.035401, hgln: 0.000000, hglt: 6.254992 },
  { isoDate: "2017-08-09T00:00:00Z", dsun: 218.002029, hgln: 0.000000, hglt: 6.315606 },
  { isoDate: "2017-08-10T00:00:00Z", dsun: 217.968076, hgln: 0.000000, hglt: 6.374472 },
  { isoDate: "2017-08-11T00:00:00Z", dsun: 217.933537, hgln: -0.000000, hglt: 6.431570 },
  { isoDate: "2017-08-12T00:00:00Z", dsun: 217.898391, hgln: 0.000000, hglt: 6.486881 },
  { isoDate: "2017-08-13T00:00:00Z", dsun: 217.862606, hgln: 0.000000, hglt: 6.540387 },
  { isoDate: "2017-08-14T00:00:00Z", dsun: 217.826140, hgln: 0.000000, hglt: 6.592069 },
  { isoDate: "2017-08-15T00:00:00Z", dsun: 217.788937, hgln: -0.000000, hglt: 6.641910 },
  { isoDate: "2017-08-16T00:00:00Z", dsun: 217.750936, hgln: 0.000000, hglt: 6.689893 },
  { isoDate: "2017-08-17T00:00:00Z", dsun: 217.712073, hgln: 0.000000, hglt: 6.736002 },
  { isoDate: "2017-08-18T00:00:00Z", dsun: 217.672285, hgln: -0.000000, hglt: 6.780221 },
  { isoDate: "2017-08-19T00:00:00Z", dsun: 217.631516, hgln: 0.000000, hglt: 6.822537 },
  { isoDate: "2017-08-20T00:00:00Z", dsun: 217.589721, hgln: 0.000000, hglt: 6.862934 },
  { isoDate: "2017-08-21T00:00:00Z", dsun: 217.546876, hgln: -0.000000, hglt: 6.901402 },
  { isoDate: "2017-08-22T00:00:00Z", dsun: 217.502974, hgln: -0.000000, hglt: 6.937926 },
  { isoDate: "2017-08-23T00:00:00Z", dsun: 217.458029, hgln: -0.000000, hglt: 6.972497 },
  { isoDate: "2017-08-24T00:00:00Z", dsun: 217.412073, hgln: -0.000000, hglt: 7.005103 },
  { isoDate: "2017-08-25T00:00:00Z", dsun: 217.365153, hgln: -0.000000, hglt: 7.035734 },
  { isoDate: "2017-08-26T00:00:00Z", dsun: 217.317327, hgln: -0.000000, hglt: 7.064379 },
  { isoDate: "2017-08-27T00:00:00Z", dsun: 217.268655, hgln: 0.000000, hglt: 7.091028 },
  { isoDate: "2017-08-28T00:00:00Z", dsun: 217.219204, hgln: 0.000000, hglt: 7.115672 },
  { isoDate: "2017-08-29T00:00:00Z", dsun: 217.169040, hgln: 0.000000, hglt: 7.138302 },
  { isoDate: "2017-08-30T00:00:00Z", dsun: 217.118228, hgln: 0.000000, hglt: 7.158908 },
  { isoDate: "2017-08-31T00:00:00Z", dsun: 217.066833, hgln: -0.000000, hglt: 7.177482 },
  { isoDate: "2017-09-01T00:00:00Z", dsun: 217.014918, hgln: -0.000000, hglt: 7.194015 },
  { isoDate: "2017-09-02T00:00:00Z", dsun: 216.962545, hgln: -0.000000, hglt: 7.208499 },
  { isoDate: "2017-09-03T00:00:00Z", dsun: 216.909774, hgln: 0.000000, hglt: 7.220927 },
  { isoDate: "2017-09-04T00:00:00Z", dsun: 216.856659, hgln: -0.000000, hglt: 7.231290 },
  { isoDate: "2017-09-05T00:00:00Z", dsun: 216.803253, hgln: -0.000000, hglt: 7.239581 },
  { isoDate: "2017-09-06T00:00:00Z", dsun: 216.749597, hgln: -0.000000, hglt: 7.245794 },
  { isoDate: "2017-09-07T00:00:00Z", dsun: 216.695723, hgln: -0.000000, hglt: 7.249921 },
  { isoDate: "2017-09-08T00:00:00Z", dsun: 216.641651, hgln: -0.000000, hglt: 7.251956 },
  { isoDate: "2017-09-09T00:00:00Z", dsun: 216.587384, hgln: 0.000000, hglt: 7.251895 },
  { isoDate: "2017-09-10T00:00:00Z", dsun: 216.532910, hgln: 0.000000, hglt: 7.249731 },
  { isoDate: "2017-09-11T00:00:00Z", dsun: 216.478201, hgln: 0.000000, hglt: 7.245461 },
  { isoDate: "2017-09-12T00:00:00Z", dsun: 216.423216, hgln: 0.000000, hglt: 7.239083 },
  { isoDate: "2017-09-13T00:00:00Z", dsun: 216.367903, hgln: -0.000000, hglt: 7.230593 },
  { isoDate: "2017-09-14T00:00:00Z", dsun: 216.312204, hgln: 0.000000, hglt: 7.219991 },
  { isoDate: "2017-09-15T00:00:00Z", dsun: 216.256062, hgln: 0.000000, hglt: 7.207277 },
  { isoDate: "2017-09-16T00:00:00Z", dsun: 216.199420, hgln: -0.000000, hglt: 7.192454 },
  { isoDate: "2017-09-17T00:00:00Z", dsun: 216.142232, hgln: -0.000000, hglt: 7.175524 },
  { isoDate: "2017-09-18T00:00:00Z", dsun: 216.084461, hgln: 0.000000, hglt: 7.156490 },
  { isoDate: "2017-09-19T00:00:00Z", dsun: 216.026088, hgln: -0.000000, hglt: 7.135357 },
  { isoDate: "2017-09-20T00:00:00Z", dsun: 215.967110, hgln: 0.000000, hglt: 7.112130 },
  { isoDate: "2017-09-21T00:00:00Z", dsun: 215.907539, hgln: -0.000000, hglt: 7.086815 },
  { isoDate: "2017-09-22T00:00:00Z", dsun: 215.847405, hgln: -0.000000, hglt: 7.059419 },
  { isoDate: "2017-09-23T00:00:00Z", dsun: 215.786748, hgln: 0.000000, hglt: 7.029946 },
  { isoDate: "2017-09-24T00:00:00Z", dsun: 215.725619, hgln: 0.000000, hglt: 6.998405 },
  { isoDate: "2017-09-25T00:00:00Z", dsun: 215.664076, hgln: 0.000000, hglt: 6.964803 },
  { isoDate: "2017-09-26T00:00:00Z", dsun: 215.602179, hgln: 0.000000, hglt: 6.929146 },
  { isoDate: "2017-09-27T00:00:00Z", dsun: 215.539995, hgln: 0.000000, hglt: 6.891442 },
  { isoDate: "2017-09-28T00:00:00Z", dsun: 215.477588, hgln: 0.000000, hglt: 6.851699 },
  { isoDate: "2017-09-29T00:00:00Z", dsun: 215.415026, hgln: -0.000000, hglt: 6.809925 },
  { isoDate: "2017-09-30T00:00:00Z", dsun: 215.352379, hgln: 0.000000, hglt: 6.766130 },
  { isoDate: "2017-10-01T00:00:00Z", dsun: 215.289716, hgln: 0.000000, hglt: 6.720321 },
  { isoDate: "2017-10-02T00:00:00Z", dsun: 215.227106, hgln: 0.000000, hglt: 6.672509 },
  { isoDate: "2017-10-03T00:00:00Z", dsun: 215.164616, hgln: -0.000000, hglt: 6.622702 },
  { isoDate: "2017-10-04T00:00:00Z", dsun: 215.102310, hgln: -0.000000, hglt: 6.570910 },
  { isoDate: "2017-10-05T00:00:00Z", dsun: 215.040246, hgln: 0.000000, hglt: 6.517144 },
  { isoDate: "2017-10-06T00:00:00Z", dsun: 214.978467, hgln: 0.000000, hglt: 6.461413 },
  { isoDate: "2017-10-07T00:00:00Z", dsun: 214.917006, hgln: 0.000000, hglt: 6.403728 },
  { isoDate: "2017-10-08T00:00:00Z", dsun: 214.855871, hgln: 0.000000, hglt: 6.344103 },
  { isoDate: "2017-10-09T00:00:00Z", dsun: 214.795057, hgln: 0.000000, hglt: 6.282548 },
  { isoDate: "2017-10-10T00:00:00Z", dsun: 214.734535, hgln: 0.000000, hglt: 6.219080 },
  { isoDate: "2017-10-11T00:00:00Z", dsun: 214.674265, hgln: -0.000000, hglt: 6.153712 },
  { isoDate: "2017-10-12T00:00:00Z", dsun: 214.614196, hgln: 0.000000, hglt: 6.086462 },
  { isoDate: "2017-10-13T00:00:00Z", dsun: 214.554270, hgln: -0.000000, hglt: 6.017349 },
  { isoDate: "2017-10-14T00:00:00Z", dsun: 214.494434, hgln: -0.000000, hglt: 5.946392 },
  { isoDate: "2017-10-15T00:00:00Z", dsun: 214.434636, hgln: -0.000000, hglt: 5.873612 },
  { isoDate: "2017-10-16T00:00:00Z", dsun: 214.374835, hgln: 0.000000, hglt: 5.799031 },
  { isoDate: "2017-10-17T00:00:00Z", dsun: 214.315000, hgln: -0.000000, hglt: 5.722671 },
  { isoDate: "2017-10-18T00:00:00Z", dsun: 214.255112, hgln: 0.000000, hglt: 5.644555 },
  { isoDate: "2017-10-19T00:00:00Z", dsun: 214.195168, hgln: 0.000000, hglt: 5.564707 },
  { isoDate: "2017-10-20T00:00:00Z", dsun: 214.135174, hgln: 0.000000, hglt: 5.483152 },
  { isoDate: "2017-10-21T00:00:00Z", dsun: 214.075153, hgln: 0.000000, hglt: 5.399914 },
  { isoDate: "2017-10-22T00:00:00Z", dsun: 214.015135, hgln: 0.000000, hglt: 5.315017 },
  { isoDate: "2017-10-23T00:00:00Z", dsun: 213.955161, hgln: -0.000000, hglt: 5.228487 },
  { isoDate: "2017-10-24T00:00:00Z", dsun: 213.895279, hgln: 0.000000, hglt: 5.140348 },
  { isoDate: "2017-10-25T00:00:00Z", dsun: 213.835542, hgln: 0.000000, hglt: 5.050626 },
  { isoDate: "2017-10-26T00:00:00Z", dsun: 213.776009, hgln: 0.000000, hglt: 4.959346 },
  { isoDate: "2017-10-27T00:00:00Z", dsun: 213.716742, hgln: -0.000000, hglt: 4.866534 },
  { isoDate: "2017-10-28T00:00:00Z", dsun: 213.657805, hgln: 0.000000, hglt: 4.772215 },
  { isoDate: "2017-10-29T00:00:00Z", dsun: 213.599267, hgln: 0.000000, hglt: 4.676416 },
  { isoDate: "2017-10-30T00:00:00Z", dsun: 213.541200, hgln: -0.000000, hglt: 4.579163 },
  { isoDate: "2017-10-31T00:00:00Z", dsun: 213.483677, hgln: -0.000000, hglt: 4.480482 },
  { isoDate: "2017-11-01T00:00:00Z", dsun: 213.426773, hgln: -0.000000, hglt: 4.380399 },
  { isoDate: "2017-11-02T00:00:00Z", dsun: 213.370561, hgln: -0.000000, hglt: 4.278942 },
  { isoDate: "2017-11-03T00:00:00Z", dsun: 213.315106, hgln: -0.000000, hglt: 4.176136 },
  { isoDate: "2017-11-04T00:00:00Z", dsun: 213.260465, hgln: 0.000000, hglt: 4.072008 },
  { isoDate: "2017-11-05T00:00:00Z", dsun: 213.206674, hgln: 0.000000, hglt: 3.966586 },
  { isoDate: "2017-11-06T00:00:00Z", dsun: 213.153750, hgln: -0.000000, hglt: 3.859899 },
  { isoDate: "2017-11-07T00:00:00Z", dsun: 213.101687, hgln: -0.000000, hglt: 3.751975 },
  { isoDate: "2017-11-08T00:00:00Z", dsun: 213.050455, hgln: -0.000000, hglt: 3.642845 },
  { isoDate: "2017-11-09T00:00:00Z", dsun: 213.000011, hgln: -0.000000, hglt: 3.532542 },
  { isoDate: "2017-11-10T00:00:00Z", dsun: 212.950301, hgln: -0.000000, hglt: 3.421100 },
  { isoDate: "2017-11-11T00:00:00Z", dsun: 212.901266, hgln: -0.000000, hglt: 3.308551 },
  { isoDate: "2017-11-12T00:00:00Z", dsun: 212.852852, hgln: 0.000000, hglt: 3.194934 },
  { isoDate: "2017-11-13T00:00:00Z", dsun: 212.805007, hgln: -0.000000, hglt: 3.080283 },
  { isoDate: "2017-11-14T00:00:00Z", dsun: 212.757691, hgln: -0.000000, hglt: 2.964636 },
  { isoDate: "2017-11-15T00:00:00Z", dsun: 212.710869, hgln: 0.000000, hglt: 2.848030 },
  { isoDate: "2017-11-16T00:00:00Z", dsun: 212.664521, hgln: -0.000000, hglt: 2.730503 },
  { isoDate: "2017-11-17T00:00:00Z", dsun: 212.618634, hgln: -0.000000, hglt: 2.612093 },
  { isoDate: "2017-11-18T00:00:00Z", dsun: 212.573206, hgln: -0.000000, hglt: 2.492839 },
  { isoDate: "2017-11-19T00:00:00Z", dsun: 212.528246, hgln: -0.000000, hglt: 2.372777 },
  { isoDate: "2017-11-20T00:00:00Z", dsun: 212.483772, hgln: -0.000000, hglt: 2.251947 },
  { isoDate: "2017-11-21T00:00:00Z", dsun: 212.439810, hgln: 0.000000, hglt: 2.130387 },
  { isoDate: "2017-11-22T00:00:00Z", dsun: 212.396393, hgln: 0.000000, hglt: 2.008135 },
  { isoDate: "2017-11-23T00:00:00Z", dsun: 212.353563, hgln: 0.000000, hglt: 1.885228 },
  { isoDate: "2017-11-24T00:00:00Z", dsun: 212.311365, hgln: 0.000000, hglt: 1.761706 },
  { isoDate: "2017-11-25T00:00:00Z", dsun: 212.269851, hgln: 0.000000, hglt: 1.637605 },
  { isoDate: "2017-11-26T00:00:00Z", dsun: 212.229078, hgln: 0.000000, hglt: 1.512965 },
  { isoDate: "2017-11-27T00:00:00Z", dsun: 212.189109, hgln: -0.000000, hglt: 1.387822 },
  { isoDate: "2017-11-28T00:00:00Z", dsun: 212.150011, hgln: -0.000000, hglt: 1.262215 },
  { isoDate: "2017-11-29T00:00:00Z", dsun: 212.111855, hgln: 0.000000, hglt: 1.136180 },
  { isoDate: "2017-11-30T00:00:00Z", dsun: 212.074717, hgln: 0.000000, hglt: 1.009756 },
  { isoDate: "2017-12-01T00:00:00Z", dsun: 212.038673, hgln: -0.000000, hglt: 0.882980 },
  { isoDate: "2017-12-02T00:00:00Z", dsun: 212.003792, hgln: -0.000000, hglt: 0.755889 },
  { isoDate: "2017-12-03T00:00:00Z", dsun: 211.970134, hgln: 0.000000, hglt: 0.628520 },
  { isoDate: "2017-12-04T00:00:00Z", dsun: 211.937740, hgln: 0.000000, hglt: 0.500910 },
  { isoDate: "2017-12-05T00:00:00Z", dsun: 211.906628, hgln: 0.000000, hglt: 0.373098 },
  { isoDate: "2017-12-06T00:00:00Z", dsun: 211.876790, hgln: 0.000000, hglt: 0.245123 },
  { isoDate: "2017-12-07T00:00:00Z", dsun: 211.848195, hgln: -0.000000, hglt: 0.117025 },
  { isoDate: "2017-12-08T00:00:00Z", dsun: 211.820792, hgln: 0.000000, hglt: -0.011156 },
  { isoDate: "2017-12-09T00:00:00Z", dsun: 211.794523, hgln: -0.000000, hglt: -0.139377 },
  { isoDate: "2017-12-10T00:00:00Z", dsun: 211.769326, hgln: 0.000000, hglt: -0.267596 },
  { isoDate: "2017-12-11T00:00:00Z", dsun: 211.745139, hgln: 0.000000, hglt: -0.395771 },
  { isoDate: "2017-12-12T00:00:00Z", dsun: 211.721908, hgln: 0.000000, hglt: -0.523858 },
  { isoDate: "2017-12-13T00:00:00Z", dsun: 211.699585, hgln: 0.000000, hglt: -0.651815 },
  { isoDate: "2017-12-14T00:00:00Z", dsun: 211.678128, hgln: 0.000000, hglt: -0.779599 },
  { isoDate: "2017-12-15T00:00:00Z", dsun: 211.657506, hgln: 0.000000, hglt: -0.907167 },
  { isoDate: "2017-12-16T00:00:00Z", dsun: 211.637694, hgln: -0.000000, hglt: -1.034476 },
  { isoDate: "2017-12-17T00:00:00Z", dsun: 211.618677, hgln: -0.000000, hglt: -1.161484 },
  { isoDate: "2017-12-18T00:00:00Z", dsun: 211.600445, hgln: -0.000000, hglt: -1.288149 },
  { isoDate: "2017-12-19T00:00:00Z", dsun: 211.583000, hgln: 0.000000, hglt: -1.414429 },
  { isoDate: "2017-12-20T00:00:00Z", dsun: 211.566350, hgln: 0.000000, hglt: -1.540284 },
  { isoDate: "2017-12-21T00:00:00Z", dsun: 211.550509, hgln: -0.000000, hglt: -1.665671 },
  { isoDate: "2017-12-22T00:00:00Z", dsun: 211.535502, hgln: 0.000000, hglt: -1.790550 },
  { isoDate: "2017-12-23T00:00:00Z", dsun: 211.521360, hgln: 0.000000, hglt: -1.914881 },
  { isoDate: "2017-12-24T00:00:00Z", dsun: 211.508119, hgln: 0.000000, hglt: -2.038625 },
  { isoDate: "2017-12-25T00:00:00Z", dsun: 211.495825, hgln: 0.000000, hglt: -2.161741 },
  { isoDate: "2017-12-26T00:00:00Z", dsun: 211.484530, hgln: 0.000000, hglt: -2.284190 },
  { isoDate: "2017-12-27T00:00:00Z", dsun: 211.474293, hgln: 0.000000, hglt: -2.405934 },
  { isoDate: "2017-12-28T00:00:00Z", dsun: 211.465179, hgln: -0.000000, hglt: -2.526935 },
  { isoDate: "2017-12-29T00:00:00Z", dsun: 211.457257, hgln: 0.000000, hglt: -2.647155 },
  { isoDate: "2017-12-30T00:00:00Z", dsun: 211.450600, hgln: 0.000000, hglt: -2.766556 },
  { isoDate: "2017-12-31T00:00:00Z", dsun: 211.445276, hgln: 0.000000, hglt: -2.885101 },
  { isoDate: "2018-01-01T00:00:00Z", dsun: 211.441343, hgln: -0.000000, hglt: -3.002754 },
  { isoDate: "2018-01-02T00:00:00Z", dsun: 211.438840, hgln: -0.000000, hglt: -3.119476 },
  { isoDate: "2018-01-03T00:00:00Z", dsun: 211.437784, hgln: 0.000000, hglt: -3.235232 },
  { isoDate: "2018-01-04T00:00:00Z", dsun: 211.438165, hgln: -0.000000, hglt: -3.349984 },
  { isoDate: "2018-01-05T00:00:00Z", dsun: 211.439947, hgln: 0.000000, hglt: -3.463693 },
  { isoDate: "2018-01-06T00:00:00Z", dsun: 211.443077, hgln: 0.000000, hglt: -3.576323 },
  { isoDate: "2018-01-07T00:00:00Z", dsun: 211.447492, hgln: 0.000000, hglt: -3.687836 },
  { isoDate: "2018-01-08T00:00:00Z", dsun: 211.453122, hgln: 0.000000, hglt: -3.798193 },
  { isoDate: "2018-01-09T00:00:00Z", dsun: 211.459901, hgln: -0.000000, hglt: -3.907358 },
  { isoDate: "2018-01-10T00:00:00Z", dsun: 211.467765, hgln: 0.000000, hglt: -4.015294 },
  { isoDate: "2018-01-11T00:00:00Z", dsun: 211.476657, hgln: 0.000000, hglt: -4.121965 },
  { isoDate: "2018-01-12T00:00:00Z", dsun: 211.486525, hgln: -0.000000, hglt: -4.227335 },
  { isoDate: "2018-01-13T00:00:00Z", dsun: 211.497322, hgln: 0.000000, hglt: -4.331368 },
  { isoDate: "2018-01-14T00:00:00Z", dsun: 211.509010, hgln: -0.000000, hglt: -4.434031 },
  { isoDate: "2018-01-15T00:00:00Z", dsun: 211.521556, hgln: 0.000000, hglt: -4.535290 },
  { isoDate: "2018-01-16T00:00:00Z", dsun: 211.534933, hgln: 0.000000, hglt: -4.635112 },
  { isoDate: "2018-01-17T00:00:00Z", dsun: 211.549121, hgln: 0.000000, hglt: -4.733463 },
  { isoDate: "2018-01-18T00:00:00Z", dsun: 211.564109, hgln: 0.000000, hglt: -4.830313 },
  { isoDate: "2018-01-19T00:00:00Z", dsun: 211.579893, hgln: 0.000000, hglt: -4.925630 },
  { isoDate: "2018-01-20T00:00:00Z", dsun: 211.596478, hgln: 0.000000, hglt: -5.019385 },
  { isoDate: "2018-01-21T00:00:00Z", dsun: 211.613878, hgln: 0.000000, hglt: -5.111547 },
  { isoDate: "2018-01-22T00:00:00Z", dsun: 211.632116, hgln: 0.000000, hglt: -5.202087 },
  { isoDate: "2018-01-23T00:00:00Z", dsun: 211.651224, hgln: -0.000000, hglt: -5.290979 },
  { isoDate: "2018-01-24T00:00:00Z", dsun: 211.671243, hgln: -0.000000, hglt: -5.378193 },
  { isoDate: "2018-01-25T00:00:00Z", dsun: 211.692223, hgln: 0.000000, hglt: -5.463703 },
  { isoDate: "2018-01-26T00:00:00Z", dsun: 211.714221, hgln: -0.000000, hglt: -5.547483 },
  { isoDate: "2018-01-27T00:00:00Z", dsun: 211.737299, hgln: -0.000000, hglt: -5.629507 },
  { isoDate: "2018-01-28T00:00:00Z", dsun: 211.761522, hgln: 0.000000, hglt: -5.709750 },
  { isoDate: "2018-01-29T00:00:00Z", dsun: 211.786953, hgln: 0.000000, hglt: -5.788186 },
  { isoDate: "2018-01-30T00:00:00Z", dsun: 211.813644, hgln: -0.000000, hglt: -5.864791 },
  { isoDate: "2018-01-31T00:00:00Z", dsun: 211.841630, hgln: -0.000000, hglt: -5.939540 },
  { isoDate: "2018-02-01T00:00:00Z", dsun: 211.870922, hgln: 0.000000, hglt: -6.012411 },
  { isoDate: "2018-02-02T00:00:00Z", dsun: 211.901510, hgln: 0.000000, hglt: -6.083378 },
  { isoDate: "2018-02-03T00:00:00Z", dsun: 211.933357, hgln: -0.000000, hglt: -6.152419 },
  { isoDate: "2018-02-04T00:00:00Z", dsun: 211.966410, hgln: 0.000000, hglt: -6.219513 },
  { isoDate: "2018-02-05T00:00:00Z", dsun: 212.000603, hgln: -0.000000, hglt: -6.284636 },
  { isoDate: "2018-02-06T00:00:00Z", dsun: 212.035866, hgln: 0.000000, hglt: -6.347770 },
  { isoDate: "2018-02-07T00:00:00Z", dsun: 212.072125, hgln: 0.000000, hglt: -6.408893 },
  { isoDate: "2018-02-08T00:00:00Z", dsun: 212.109312, hgln: 0.000000, hglt: -6.467988 },
  { isoDate: "2018-02-09T00:00:00Z", dsun: 212.147360, hgln: 0.000000, hglt: -6.525035 },
  { isoDate: "2018-02-10T00:00:00Z", dsun: 212.186207, hgln: 0.000000, hglt: -6.580020 },
  { isoDate: "2018-02-11T00:00:00Z", dsun: 212.225794, hgln: 0.000000, hglt: -6.632925 },
  { isoDate: "2018-02-12T00:00:00Z", dsun: 212.266067, hgln: -0.000000, hglt: -6.683735 },
  { isoDate: "2018-02-13T00:00:00Z", dsun: 212.306979, hgln: -0.000000, hglt: -6.732436 },
  { isoDate: "2018-02-14T00:00:00Z", dsun: 212.348484, hgln: -0.000000, hglt: -6.779016 },
  { isoDate: "2018-02-15T00:00:00Z", dsun: 212.390545, hgln: 0.000000, hglt: -6.823462 },
  { isoDate: "2018-02-16T00:00:00Z", dsun: 212.433131, hgln: -0.000000, hglt: -6.865763 },
  { isoDate: "2018-02-17T00:00:00Z", dsun: 212.476222, hgln: -0.000000, hglt: -6.905908 },
  { isoDate: "2018-02-18T00:00:00Z", dsun: 212.519805, hgln: -0.000000, hglt: -6.943888 },
  { isoDate: "2018-02-19T00:00:00Z", dsun: 212.563879, hgln: -0.000000, hglt: -6.979694 },
  { isoDate: "2018-02-20T00:00:00Z", dsun: 212.608456, hgln: -0.000000, hglt: -7.013319 },
  { isoDate: "2018-02-21T00:00:00Z", dsun: 212.653557, hgln: 0.000000, hglt: -7.044755 },
  { isoDate: "2018-02-22T00:00:00Z", dsun: 212.699216, hgln: 0.000000, hglt: -7.073996 },
  { isoDate: "2018-02-23T00:00:00Z", dsun: 212.745477, hgln: -0.000000, hglt: -7.101035 },
  { isoDate: "2018-02-24T00:00:00Z", dsun: 212.792391, hgln: 0.000000, hglt: -7.125866 },
  { isoDate: "2018-02-25T00:00:00Z", dsun: 212.840016, hgln: -0.000000, hglt: -7.148485 },
  { isoDate: "2018-02-26T00:00:00Z", dsun: 212.888411, hgln: 0.000000, hglt: -7.168886 },
  { isoDate: "2018-02-27T00:00:00Z", dsun: 212.937630, hgln: -0.000000, hglt: -7.187065 },
  { isoDate: "2018-02-28T00:00:00Z", dsun: 212.987719, hgln: -0.000000, hglt: -7.203017 },
  { isoDate: "2018-03-01T00:00:00Z", dsun: 213.038708, hgln: -0.000000, hglt: -7.216739 },
  { isoDate: "2018-03-02T00:00:00Z", dsun: 213.090606, hgln: -0.000000, hglt: -7.228227 },
  { isoDate: "2018-03-03T00:00:00Z", dsun: 213.143402, hgln: -0.000000, hglt: -7.237480 },
  { isoDate: "2018-03-04T00:00:00Z", dsun: 213.197062, hgln: -0.000000, hglt: -7.244494 },
  { isoDate: "2018-03-05T00:00:00Z", dsun: 213.251538, hgln: -0.000000, hglt: -7.249271 },
  { isoDate: "2018-03-06T00:00:00Z", dsun: 213.306769, hgln: -0.000000, hglt: -7.251811 },
  { isoDate: "2018-03-07T00:00:00Z", dsun: 213.362687, hgln: 0.000000, hglt: -7.252115 },
  { isoDate: "2018-03-08T00:00:00Z", dsun: 213.419221, hgln: -0.000000, hglt: -7.250188 },
  { isoDate: "2018-03-09T00:00:00Z", dsun: 213.476299, hgln: -0.000000, hglt: -7.246033 },
  { isoDate: "2018-03-10T00:00:00Z", dsun: 213.533852, hgln: 0.000000, hglt: -7.239654 },
  { isoDate: "2018-03-11T00:00:00Z", dsun: 213.591811, hgln: -0.000000, hglt: -7.231059 },
  { isoDate: "2018-03-12T00:00:00Z", dsun: 213.650109, hgln: -0.000000, hglt: -7.220255 },
  { isoDate: "2018-03-13T00:00:00Z", dsun: 213.708684, hgln: -0.000000, hglt: -7.207248 },
  { isoDate: "2018-03-14T00:00:00Z", dsun: 213.767474, hgln: 0.000000, hglt: -7.192049 },
  { isoDate: "2018-03-15T00:00:00Z", dsun: 213.826422, hgln: -0.000000, hglt: -7.174667 },
  { isoDate: "2018-03-16T00:00:00Z", dsun: 213.885476, hgln: 0.000000, hglt: -7.155112 },
  { isoDate: "2018-03-17T00:00:00Z", dsun: 213.944590, hgln: -0.000000, hglt: -7.133397 },
  { isoDate: "2018-03-18T00:00:00Z", dsun: 214.003728, hgln: -0.000000, hglt: -7.109534 },
  { isoDate: "2018-03-19T00:00:00Z", dsun: 214.062865, hgln: 0.000000, hglt: -7.083536 },
  { isoDate: "2018-03-20T00:00:00Z", dsun: 214.121990, hgln: 0.000000, hglt: -7.055416 },
  { isoDate: "2018-03-21T00:00:00Z", dsun: 214.181106, hgln: 0.000000, hglt: -7.025188 },
  { isoDate: "2018-03-22T00:00:00Z", dsun: 214.240232, hgln: 0.000000, hglt: -6.992868 },
  { isoDate: "2018-03-23T00:00:00Z", dsun: 214.299398, hgln: 0.000000, hglt: -6.958468 },
  { isoDate: "2018-03-24T00:00:00Z", dsun: 214.358648, hgln: 0.000000, hglt: -6.922005 },
  { isoDate: "2018-03-25T00:00:00Z", dsun: 214.418034, hgln: 0.000000, hglt: -6.883492 },
  { isoDate: "2018-03-26T00:00:00Z", dsun: 214.477610, hgln: 0.000000, hglt: -6.842944 },
  { isoDate: "2018-03-27T00:00:00Z", dsun: 214.537433, hgln: -0.000000, hglt: -6.800375 },
  { isoDate: "2018-03-28T00:00:00Z", dsun: 214.597552, hgln: 0.000000, hglt: -6.755801 },
  { isoDate: "2018-03-29T00:00:00Z", dsun: 214.658010, hgln: -0.000000, hglt: -6.709236 },
  { isoDate: "2018-03-30T00:00:00Z", dsun: 214.718833, hgln: 0.000000, hglt: -6.660695 },
  { isoDate: "2018-03-31T00:00:00Z", dsun: 214.780029, hgln: 0.000000, hglt: -6.610195 },
  { isoDate: "2018-04-01T00:00:00Z", dsun: 214.841591, hgln: 0.000000, hglt: -6.557751 },
  { isoDate: "2018-04-02T00:00:00Z", dsun: 214.903494, hgln: 0.000000, hglt: -6.503383 },
  { isoDate: "2018-04-03T00:00:00Z", dsun: 214.965698, hgln: 0.000000, hglt: -6.447107 },
  { isoDate: "2018-04-04T00:00:00Z", dsun: 215.028151, hgln: 0.000000, hglt: -6.388944 },
  { isoDate: "2018-04-05T00:00:00Z", dsun: 215.090795, hgln: -0.000000, hglt: -6.328915 },
  { isoDate: "2018-04-06T00:00:00Z", dsun: 215.153566, hgln: 0.000000, hglt: -6.267039 },
  { isoDate: "2018-04-07T00:00:00Z", dsun: 215.216398, hgln: -0.000000, hglt: -6.203341 },
  { isoDate: "2018-04-08T00:00:00Z", dsun: 215.279224, hgln: 0.000000, hglt: -6.137842 },
  { isoDate: "2018-04-09T00:00:00Z", dsun: 215.341974, hgln: 0.000000, hglt: -6.070566 },
  { isoDate: "2018-04-10T00:00:00Z", dsun: 215.404582, hgln: 0.000000, hglt: -6.001539 },
  { isoDate: "2018-04-11T00:00:00Z", dsun: 215.466979, hgln: 0.000000, hglt: -5.930784 },
  { isoDate: "2018-04-12T00:00:00Z", dsun: 215.529098, hgln: 0.000000, hglt: -5.858328 },
  { isoDate: "2018-04-13T00:00:00Z", dsun: 215.590874, hgln: 0.000000, hglt: -5.784198 },
  { isoDate: "2018-04-14T00:00:00Z", dsun: 215.652244, hgln: -0.000000, hglt: -5.708419 },
  { isoDate: "2018-04-15T00:00:00Z", dsun: 215.713153, hgln: 0.000000, hglt: -5.631021 },
  { isoDate: "2018-04-16T00:00:00Z", dsun: 215.773553, hgln: -0.000000, hglt: -5.552031 },
  { isoDate: "2018-04-17T00:00:00Z", dsun: 215.833412, hgln: 0.000000, hglt: -5.471478 },
  { isoDate: "2018-04-18T00:00:00Z", dsun: 215.892712, hgln: 0.000000, hglt: -5.389390 },
  { isoDate: "2018-04-19T00:00:00Z", dsun: 215.951454, hgln: 0.000000, hglt: -5.305796 },
  { isoDate: "2018-04-20T00:00:00Z", dsun: 216.009657, hgln: 0.000000, hglt: -5.220724 },
  { isoDate: "2018-04-21T00:00:00Z", dsun: 216.067355, hgln: -0.000000, hglt: -5.134202 },
  { isoDate: "2018-04-22T00:00:00Z", dsun: 216.124597, hgln: 0.000000, hglt: -5.046256 },
  { isoDate: "2018-04-23T00:00:00Z", dsun: 216.181437, hgln: 0.000000, hglt: -4.956916 },
  { isoDate: "2018-04-24T00:00:00Z", dsun: 216.237935, hgln: 0.000000, hglt: -4.866206 },
  { isoDate: "2018-04-25T00:00:00Z", dsun: 216.294145, hgln: 0.000000, hglt: -4.774153 },
  { isoDate: "2018-04-26T00:00:00Z", dsun: 216.350118, hgln: -0.000000, hglt: -4.680784 },
  { isoDate: "2018-04-27T00:00:00Z", dsun: 216.405893, hgln: -0.000000, hglt: -4.586125 },
  { isoDate: "2018-04-28T00:00:00Z", dsun: 216.461498, hgln: 0.000000, hglt: -4.490202 },
  { isoDate: "2018-04-29T00:00:00Z", dsun: 216.516947, hgln: -0.000000, hglt: -4.393044 },
  { isoDate: "2018-04-30T00:00:00Z", dsun: 216.572238, hgln: -0.000000, hglt: -4.294676 },
  { isoDate: "2018-05-01T00:00:00Z", dsun: 216.627356, hgln: -0.000000, hglt: -4.195128 },
  { isoDate: "2018-05-02T00:00:00Z", dsun: 216.682274, hgln: 0.000000, hglt: -4.094429 },
  { isoDate: "2018-05-03T00:00:00Z", dsun: 216.736954, hgln: 0.000000, hglt: -3.992607 },
  { isoDate: "2018-05-04T00:00:00Z", dsun: 216.791351, hgln: -0.000000, hglt: -3.889693 },
  { isoDate: "2018-05-05T00:00:00Z", dsun: 216.845414, hgln: -0.000000, hglt: -3.785717 },
  { isoDate: "2018-05-06T00:00:00Z", dsun: 216.899085, hgln: -0.000000, hglt: -3.680710 },
  { isoDate: "2018-05-07T00:00:00Z", dsun: 216.952306, hgln: 0.000000, hglt: -3.574704 },
  { isoDate: "2018-05-08T00:00:00Z", dsun: 217.005014, hgln: 0.000000, hglt: -3.467731 },
  { isoDate: "2018-05-09T00:00:00Z", dsun: 217.057145, hgln: 0.000000, hglt: -3.359823 },
  { isoDate: "2018-05-10T00:00:00Z", dsun: 217.108631, hgln: 0.000000, hglt: -3.251013 },
  { isoDate: "2018-05-11T00:00:00Z", dsun: 217.159405, hgln: 0.000000, hglt: -3.141335 },
  { isoDate: "2018-05-12T00:00:00Z", dsun: 217.209397, hgln: 0.000000, hglt: -3.030821 },
  { isoDate: "2018-05-13T00:00:00Z", dsun: 217.258541, hgln: 0.000000, hglt: -2.919505 },
  { isoDate: "2018-05-14T00:00:00Z", dsun: 217.306773, hgln: -0.000000, hglt: -2.807423 },
  { isoDate: "2018-05-15T00:00:00Z", dsun: 217.354042, hgln: 0.000000, hglt: -2.694608 },
  { isoDate: "2018-05-16T00:00:00Z", dsun: 217.400307, hgln: -0.000000, hglt: -2.581094 },
  { isoDate: "2018-05-17T00:00:00Z", dsun: 217.445551, hgln: -0.000000, hglt: -2.466917 },
  { isoDate: "2018-05-18T00:00:00Z", dsun: 217.489774, hgln: -0.000000, hglt: -2.352109 },
  { isoDate: "2018-05-19T00:00:00Z", dsun: 217.533002, hgln: 0.000000, hglt: -2.236703 },
  { isoDate: "2018-05-20T00:00:00Z", dsun: 217.575275, hgln: 0.000000, hglt: -2.120732 },
  { isoDate: "2018-05-21T00:00:00Z", dsun: 217.616650, hgln: -0.000000, hglt: -2.004227 },
  { isoDate: "2018-05-22T00:00:00Z", dsun: 217.657187, hgln: -0.000000, hglt: -1.887220 },
  { isoDate: "2018-05-23T00:00:00Z", dsun: 217.696950, hgln: -0.000000, hglt: -1.769742 },
  { isoDate: "2018-05-24T00:00:00Z", dsun: 217.735997, hgln: -0.000000, hglt: -1.651821 },
  { isoDate: "2018-05-25T00:00:00Z", dsun: 217.774382, hgln: -0.000000, hglt: -1.533490 },
  { isoDate: "2018-05-26T00:00:00Z", dsun: 217.812147, hgln: 0.000000, hglt: -1.414777 },
  { isoDate: "2018-05-27T00:00:00Z", dsun: 217.849323, hgln: 0.000000, hglt: -1.295714 },
  { isoDate: "2018-05-28T00:00:00Z", dsun: 217.885932, hgln: 0.000000, hglt: -1.176331 },
  { isoDate: "2018-05-29T00:00:00Z", dsun: 217.921982, hgln: 0.000000, hglt: -1.056659 },
  { isoDate: "2018-05-30T00:00:00Z", dsun: 217.957471, hgln: 0.000000, hglt: -0.936729 },
  { isoDate: "2018-05-31T00:00:00Z", dsun: 217.992386, hgln: -0.000000, hglt: -0.816572 },
  { isoDate: "2018-06-01T00:00:00Z", dsun: 218.026704, hgln: 0.000000, hglt: -0.696221 },
  { isoDate: "2018-06-02T00:00:00Z", dsun: 218.060396, hgln: -0.000000, hglt: -0.575708 },
  { isoDate: "2018-06-03T00:00:00Z", dsun: 218.093425, hgln: 0.000000, hglt: -0.455064 },
  { isoDate: "2018-06-04T00:00:00Z", dsun: 218.125747, hgln: -0.000000, hglt: -0.334322 },
  { isoDate: "2018-06-05T00:00:00Z", dsun: 218.157313, hgln: -0.000000, hglt: -0.213515 },
  { isoDate: "2018-06-06T00:00:00Z", dsun: 218.188072, hgln: -0.000000, hglt: -0.092677 },
  { isoDate: "2018-06-07T00:00:00Z", dsun: 218.217964, hgln: -0.000000, hglt: 0.028160 },
  { isoDate: "2018-06-08T00:00:00Z", dsun: 218.246929, hgln: 0.000000, hglt: 0.148963 },
  { isoDate: "2018-06-09T00:00:00Z", dsun: 218.274901, hgln: 0.000000, hglt: 0.269698 },
  { isoDate: "2018-06-10T00:00:00Z", dsun: 218.301810, hgln: -0.000000, hglt: 0.390332 },
  { isoDate: "2018-06-11T00:00:00Z", dsun: 218.327588, hgln: 0.000000, hglt: 0.510830 },
  { isoDate: "2018-06-12T00:00:00Z", dsun: 218.352171, hgln: 0.000000, hglt: 0.631159 },
  { isoDate: "2018-06-13T00:00:00Z", dsun: 218.375501, hgln: -0.000000, hglt: 0.751286 },
  { isoDate: "2018-06-14T00:00:00Z", dsun: 218.397541, hgln: 0.000000, hglt: 0.871177 },
  { isoDate: "2018-06-15T00:00:00Z", dsun: 218.418270, hgln: 0.000000, hglt: 0.990799 },
  { isoDate: "2018-06-16T00:00:00Z", dsun: 218.437695, hgln: 0.000000, hglt: 1.110120 },
  { isoDate: "2018-06-17T00:00:00Z", dsun: 218.455847, hgln: -0.000000, hglt: 1.229108 },
  { isoDate: "2018-06-18T00:00:00Z", dsun: 218.472776, hgln: -0.000000, hglt: 1.347732 },
  { isoDate: "2018-06-19T00:00:00Z", dsun: 218.488543, hgln: 0.000000, hglt: 1.465962 },
  { isoDate: "2018-06-20T00:00:00Z", dsun: 218.503217, hgln: 0.000000, hglt: 1.583767 },
  { isoDate: "2018-06-21T00:00:00Z", dsun: 218.516867, hgln: 0.000000, hglt: 1.701119 },
  { isoDate: "2018-06-22T00:00:00Z", dsun: 218.529557, hgln: 0.000000, hglt: 1.817987 },
  { isoDate: "2018-06-23T00:00:00Z", dsun: 218.541344, hgln: 0.000000, hglt: 1.934344 },
  { isoDate: "2018-06-24T00:00:00Z", dsun: 218.552276, hgln: 0.000000, hglt: 2.050158 },
  { isoDate: "2018-06-25T00:00:00Z", dsun: 218.562392, hgln: 0.000000, hglt: 2.165401 },
  { isoDate: "2018-06-26T00:00:00Z", dsun: 218.571724, hgln: 0.000000, hglt: 2.280044 },
  { isoDate: "2018-06-27T00:00:00Z", dsun: 218.580291, hgln: 0.000000, hglt: 2.394057 },
  { isoDate: "2018-06-28T00:00:00Z", dsun: 218.588105, hgln: 0.000000, hglt: 2.507411 },
  { isoDate: "2018-06-29T00:00:00Z", dsun: 218.595169, hgln: -0.000000, hglt: 2.620076 },
  { isoDate: "2018-06-30T00:00:00Z", dsun: 218.601475, hgln: 0.000000, hglt: 2.732023 },
  { isoDate: "2018-07-01T00:00:00Z", dsun: 218.607011, hgln: -0.000000, hglt: 2.843221 },
  { isoDate: "2018-07-02T00:00:00Z", dsun: 218.611752, hgln: 0.000000, hglt: 2.953642 },
  { isoDate: "2018-07-03T00:00:00Z", dsun: 218.615672, hgln: 0.000000, hglt: 3.063256 },
  { isoDate: "2018-07-04T00:00:00Z", dsun: 218.618733, hgln: -0.000000, hglt: 3.172033 },
  { isoDate: "2018-07-05T00:00:00Z", dsun: 218.620893, hgln: 0.000000, hglt: 3.279944 },
  { isoDate: "2018-07-06T00:00:00Z", dsun: 218.622103, hgln: -0.000000, hglt: 3.386958 },
  { isoDate: "2018-07-07T00:00:00Z", dsun: 218.622308, hgln: 0.000000, hglt: 3.493048 },
  { isoDate: "2018-07-08T00:00:00Z", dsun: 218.621446, hgln: 0.000000, hglt: 3.598183 },
  { isoDate: "2018-07-09T00:00:00Z", dsun: 218.619452, hgln: -0.000000, hglt: 3.702335 },
  { isoDate: "2018-07-10T00:00:00Z", dsun: 218.616259, hgln: 0.000000, hglt: 3.805474 },
  { isoDate: "2018-07-11T00:00:00Z", dsun: 218.611801, hgln: -0.000000, hglt: 3.907572 },
  { isoDate: "2018-07-12T00:00:00Z", dsun: 218.606026, hgln: 0.000000, hglt: 4.008602 },
  { isoDate: "2018-07-13T00:00:00Z", dsun: 218.598893, hgln: -0.000000, hglt: 4.108534 },
  { isoDate: "2018-07-14T00:00:00Z", dsun: 218.590388, hgln: 0.000000, hglt: 4.207342 },
  { isoDate: "2018-07-15T00:00:00Z", dsun: 218.580522, hgln: 0.000000, hglt: 4.305000 },
  { isoDate: "2018-07-16T00:00:00Z", dsun: 218.569332, hgln: 0.000000, hglt: 4.401480 },
  { isoDate: "2018-07-17T00:00:00Z", dsun: 218.556871, hgln: -0.000000, hglt: 4.496758 },
  { isoDate: "2018-07-18T00:00:00Z", dsun: 218.543210, hgln: 0.000000, hglt: 4.590808 },
  { isoDate: "2018-07-19T00:00:00Z", dsun: 218.528421, hgln: 0.000000, hglt: 4.683604 },
  { isoDate: "2018-07-20T00:00:00Z", dsun: 218.512578, hgln: 0.000000, hglt: 4.775123 },
  { isoDate: "2018-07-21T00:00:00Z", dsun: 218.495750, hgln: 0.000000, hglt: 4.865339 },
  { isoDate: "2018-07-22T00:00:00Z", dsun: 218.477999, hgln: 0.000000, hglt: 4.954229 },
  { isoDate: "2018-07-23T00:00:00Z", dsun: 218.459382, hgln: -0.000000, hglt: 5.041766 },
  { isoDate: "2018-07-24T00:00:00Z", dsun: 218.439947, hgln: 0.000000, hglt: 5.127928 },
  { isoDate: "2018-07-25T00:00:00Z", dsun: 218.419734, hgln: -0.000000, hglt: 5.212690 },
  { isoDate: "2018-07-26T00:00:00Z", dsun: 218.398778, hgln: -0.000000, hglt: 5.296029 },
  { isoDate: "2018-07-27T00:00:00Z", dsun: 218.377104, hgln: 0.000000, hglt: 5.377919 },
  { isoDate: "2018-07-28T00:00:00Z", dsun: 218.354729, hgln: 0.000000, hglt: 5.458339 },
  { isoDate: "2018-07-29T00:00:00Z", dsun: 218.331663, hgln: 0.000000, hglt: 5.537264 },
  { isoDate: "2018-07-30T00:00:00Z", dsun: 218.307908, hgln: 0.000000, hglt: 5.614670 },
  { isoDate: "2018-07-31T00:00:00Z", dsun: 218.283456, hgln: -0.000000, hglt: 5.690536 },
  { isoDate: "2018-08-01T00:00:00Z", dsun: 218.258292, hgln: -0.000000, hglt: 5.764839 },
  { isoDate: "2018-08-02T00:00:00Z", dsun: 218.232392, hgln: 0.000000, hglt: 5.837556 },
  { isoDate: "2018-08-03T00:00:00Z", dsun: 218.205722, hgln: 0.000000, hglt: 5.908665 },
  { isoDate: "2018-08-04T00:00:00Z", dsun: 218.178243, hgln: 0.000000, hglt: 5.978145 },
  { isoDate: "2018-08-05T00:00:00Z", dsun: 218.149906, hgln: -0.000000, hglt: 6.045974 },
  { isoDate: "2018-08-06T00:00:00Z", dsun: 218.120654, hgln: -0.000000, hglt: 6.112132 },
  { isoDate: "2018-08-07T00:00:00Z", dsun: 218.090427, hgln: 0.000000, hglt: 6.176600 },
  { isoDate: "2018-08-08T00:00:00Z", dsun: 218.059159, hgln: -0.000000, hglt: 6.239357 },
  { isoDate: "2018-08-09T00:00:00Z", dsun: 218.026792, hgln: 0.000000, hglt: 6.300385 },
  { isoDate: "2018-08-10T00:00:00Z", dsun: 217.993272, hgln: -0.000000, hglt: 6.359666 },
  { isoDate: "2018-08-11T00:00:00Z", dsun: 217.958567, hgln: -0.000000, hglt: 6.417182 },
  { isoDate: "2018-08-12T00:00:00Z", dsun: 217.922665, hgln: -0.000000, hglt: 6.472917 },
  { isoDate: "2018-08-13T00:00:00Z", dsun: 217.885582, hgln: 0.000000, hglt: 6.526855 },
  { isoDate: "2018-08-14T00:00:00Z", dsun: 217.847355, hgln: -0.000000, hglt: 6.578978 },
  { isoDate: "2018-08-15T00:00:00Z", dsun: 217.808044, hgln: 0.000000, hglt: 6.629273 },
  { isoDate: "2018-08-16T00:00:00Z", dsun: 217.767718, hgln: 0.000000, hglt: 6.677722 },
  { isoDate: "2018-08-17T00:00:00Z", dsun: 217.726452, hgln: -0.000000, hglt: 6.724310 },
  { isoDate: "2018-08-18T00:00:00Z", dsun: 217.684322, hgln: -0.000000, hglt: 6.769023 },
  { isoDate: "2018-08-19T00:00:00Z", dsun: 217.641402, hgln: 0.000000, hglt: 6.811844 },
  { isoDate: "2018-08-20T00:00:00Z", dsun: 217.597760, hgln: 0.000000, hglt: 6.852761 },
  { isoDate: "2018-08-21T00:00:00Z", dsun: 217.553458, hgln: -0.000000, hglt: 6.891757 },
  { isoDate: "2018-08-22T00:00:00Z", dsun: 217.508555, hgln: -0.000000, hglt: 6.928820 },
  { isoDate: "2018-08-23T00:00:00Z", dsun: 217.463101, hgln: -0.000000, hglt: 6.963934 },
  { isoDate: "2018-08-24T00:00:00Z", dsun: 217.417143, hgln: -0.000000, hglt: 6.997088 },
  { isoDate: "2018-08-25T00:00:00Z", dsun: 217.370721, hgln: -0.000000, hglt: 7.028268 },
  { isoDate: "2018-08-26T00:00:00Z", dsun: 217.323867, hgln: -0.000000, hglt: 7.057461 },
  { isoDate: "2018-08-27T00:00:00Z", dsun: 217.276605, hgln: 0.000000, hglt: 7.084655 },
  { isoDate: "2018-08-28T00:00:00Z", dsun: 217.228951, hgln: -0.000000, hglt: 7.109839 },
  { isoDate: "2018-08-29T00:00:00Z", dsun: 217.180913, hgln: -0.000000, hglt: 7.133002 },
  { isoDate: "2018-08-30T00:00:00Z", dsun: 217.132486, hgln: -0.000000, hglt: 7.154133 },
  { isoDate: "2018-08-31T00:00:00Z", dsun: 217.083657, hgln: 0.000000, hglt: 7.173222 },
  { isoDate: "2018-09-01T00:00:00Z", dsun: 217.034402, hgln: 0.000000, hglt: 7.190261 },
  { isoDate: "2018-09-02T00:00:00Z", dsun: 216.984686, hgln: -0.000000, hglt: 7.205241 },
  { isoDate: "2018-09-03T00:00:00Z", dsun: 216.934465, hgln: -0.000000, hglt: 7.218154 },
  { isoDate: "2018-09-04T00:00:00Z", dsun: 216.883687, hgln: 0.000000, hglt: 7.228995 },
  { isoDate: "2018-09-05T00:00:00Z", dsun: 216.832294, hgln: -0.000000, hglt: 7.237757 },
  { isoDate: "2018-09-06T00:00:00Z", dsun: 216.780228, hgln: -0.000000, hglt: 7.244437 },
  { isoDate: "2018-09-07T00:00:00Z", dsun: 216.727432, hgln: -0.000000, hglt: 7.249031 },
  { isoDate: "2018-09-08T00:00:00Z", dsun: 216.673861, hgln: 0.000000, hglt: 7.251536 },
  { isoDate: "2018-09-09T00:00:00Z", dsun: 216.619486, hgln: 0.000000, hglt: 7.251951 },
  { isoDate: "2018-09-10T00:00:00Z", dsun: 216.564298, hgln: 0.000000, hglt: 7.250273 },
  { isoDate: "2018-09-11T00:00:00Z", dsun: 216.508315, hgln: 0.000000, hglt: 7.246504 },
  { isoDate: "2018-09-12T00:00:00Z", dsun: 216.451573, hgln: 0.000000, hglt: 7.240641 },
  { isoDate: "2018-09-13T00:00:00Z", dsun: 216.394128, hgln: -0.000000, hglt: 7.232684 },
  { isoDate: "2018-09-14T00:00:00Z", dsun: 216.336048, hgln: -0.000000, hglt: 7.222633 },
  { isoDate: "2018-09-15T00:00:00Z", dsun: 216.277404, hgln: -0.000000, hglt: 7.210487 },
  { isoDate: "2018-09-16T00:00:00Z", dsun: 216.218273, hgln: -0.000000, hglt: 7.196247 },
  { isoDate: "2018-09-17T00:00:00Z", dsun: 216.158727, hgln: -0.000000, hglt: 7.179913 },
  { isoDate: "2018-09-18T00:00:00Z", dsun: 216.098839, hgln: 0.000000, hglt: 7.161485 },
  { isoDate: "2018-09-19T00:00:00Z", dsun: 216.038675, hgln: -0.000000, hglt: 7.140965 },
  { isoDate: "2018-09-20T00:00:00Z", dsun: 215.978302, hgln: 0.000000, hglt: 7.118355 },
  { isoDate: "2018-09-21T00:00:00Z", dsun: 215.917780, hgln: -0.000000, hglt: 7.093656 },
  { isoDate: "2018-09-22T00:00:00Z", dsun: 215.857165, hgln: 0.000000, hglt: 7.066871 },
  { isoDate: "2018-09-23T00:00:00Z", dsun: 215.796509, hgln: -0.000000, hglt: 7.038003 },
  { isoDate: "2018-09-24T00:00:00Z", dsun: 215.735858, hgln: 0.000000, hglt: 7.007056 },
  { isoDate: "2018-09-25T00:00:00Z", dsun: 215.675252, hgln: -0.000000, hglt: 6.974034 },
  { isoDate: "2018-09-26T00:00:00Z", dsun: 215.614717, hgln: 0.000000, hglt: 6.938942 },
  { isoDate: "2018-09-27T00:00:00Z", dsun: 215.554274, hgln: 0.000000, hglt: 6.901785 },
  { isoDate: "2018-09-28T00:00:00Z", dsun: 215.493928, hgln: 0.000000, hglt: 6.862570 },
  { isoDate: "2018-09-29T00:00:00Z", dsun: 215.433673, hgln: 0.000000, hglt: 6.821305 },
  { isoDate: "2018-09-30T00:00:00Z", dsun: 215.373488, hgln: -0.000000, hglt: 6.777998 },
  { isoDate: "2018-10-01T00:00:00Z", dsun: 215.313344, hgln: 0.000000, hglt: 6.732658 },
  { isoDate: "2018-10-02T00:00:00Z", dsun: 215.253196, hgln: 0.000000, hglt: 6.685296 },
  { isoDate: "2018-10-03T00:00:00Z", dsun: 215.192994, hgln: 0.000000, hglt: 6.635924 },
  { isoDate: "2018-10-04T00:00:00Z", dsun: 215.132683, hgln: 0.000000, hglt: 6.584555 },
  { isoDate: "2018-10-05T00:00:00Z", dsun: 215.072206, hgln: 0.000000, hglt: 6.531203 },
  { isoDate: "2018-10-06T00:00:00Z", dsun: 215.011514, hgln: -0.000000, hglt: 6.475884 },
  { isoDate: "2018-10-07T00:00:00Z", dsun: 214.950564, hgln: 0.000000, hglt: 6.418613 },
  { isoDate: "2018-10-08T00:00:00Z", dsun: 214.889330, hgln: -0.000000, hglt: 6.359408 },
  { isoDate: "2018-10-09T00:00:00Z", dsun: 214.827806, hgln: 0.000000, hglt: 6.298285 },
  { isoDate: "2018-10-10T00:00:00Z", dsun: 214.766005, hgln: -0.000000, hglt: 6.235263 },
  { isoDate: "2018-10-11T00:00:00Z", dsun: 214.703958, hgln: 0.000000, hglt: 6.170359 },
  { isoDate: "2018-10-12T00:00:00Z", dsun: 214.641713, hgln: 0.000000, hglt: 6.103590 },
  { isoDate: "2018-10-13T00:00:00Z", dsun: 214.579328, hgln: 0.000000, hglt: 6.034975 },
  { isoDate: "2018-10-14T00:00:00Z", dsun: 214.516869, hgln: 0.000000, hglt: 5.964531 },
  { isoDate: "2018-10-15T00:00:00Z", dsun: 214.454404, hgln: 0.000000, hglt: 5.892277 },
  { isoDate: "2018-10-16T00:00:00Z", dsun: 214.392003, hgln: -0.000000, hglt: 5.818232 },
  { isoDate: "2018-10-17T00:00:00Z", dsun: 214.329737, hgln: 0.000000, hglt: 5.742413 },
  { isoDate: "2018-10-18T00:00:00Z", dsun: 214.267674, hgln: 0.000000, hglt: 5.664841 },
  { isoDate: "2018-10-19T00:00:00Z", dsun: 214.205882, hgln: -0.000000, hglt: 5.585535 },
  { isoDate: "2018-10-20T00:00:00Z", dsun: 214.144426, hgln: 0.000000, hglt: 5.504515 },
  { isoDate: "2018-10-21T00:00:00Z", dsun: 214.083372, hgln: -0.000000, hglt: 5.421802 },
  { isoDate: "2018-10-22T00:00:00Z", dsun: 214.022780, hgln: -0.000000, hglt: 5.337416 },
  { isoDate: "2018-10-23T00:00:00Z", dsun: 213.962706, hgln: 0.000000, hglt: 5.251380 },
  { isoDate: "2018-10-24T00:00:00Z", dsun: 213.903200, hgln: -0.000000, hglt: 5.163714 },
  { isoDate: "2018-10-25T00:00:00Z", dsun: 213.844302, hgln: 0.000000, hglt: 5.074442 },
  { isoDate: "2018-10-26T00:00:00Z", dsun: 213.786039, hgln: -0.000000, hglt: 4.983587 },
  { isoDate: "2018-10-27T00:00:00Z", dsun: 213.728424, hgln: -0.000000, hglt: 4.891173 },
  { isoDate: "2018-10-28T00:00:00Z", dsun: 213.671454, hgln: 0.000000, hglt: 4.797225 },
  { isoDate: "2018-10-29T00:00:00Z", dsun: 213.615109, hgln: -0.000000, hglt: 4.701769 },
  { isoDate: "2018-10-30T00:00:00Z", dsun: 213.559356, hgln: -0.000000, hglt: 4.604833 },
  { isoDate: "2018-10-31T00:00:00Z", dsun: 213.504150, hgln: -0.000000, hglt: 4.506445 },
  { isoDate: "2018-11-01T00:00:00Z", dsun: 213.449436, hgln: 0.000000, hglt: 4.406634 },
  { isoDate: "2018-11-02T00:00:00Z", dsun: 213.395159, hgln: 0.000000, hglt: 4.305433 },
  { isoDate: "2018-11-03T00:00:00Z", dsun: 213.341262, hgln: -0.000000, hglt: 4.202871 },
  { isoDate: "2018-11-04T00:00:00Z", dsun: 213.287697, hgln: 0.000000, hglt: 4.098981 },
  { isoDate: "2018-11-05T00:00:00Z", dsun: 213.234424, hgln: 0.000000, hglt: 3.993796 },
  { isoDate: "2018-11-06T00:00:00Z", dsun: 213.181416, hgln: -0.000000, hglt: 3.887350 },
  { isoDate: "2018-11-07T00:00:00Z", dsun: 213.128663, hgln: 0.000000, hglt: 3.779675 },
  { isoDate: "2018-11-08T00:00:00Z", dsun: 213.076170, hgln: 0.000000, hglt: 3.670807 },
  { isoDate: "2018-11-09T00:00:00Z", dsun: 213.023959, hgln: -0.000000, hglt: 3.560777 },
  { isoDate: "2018-11-10T00:00:00Z", dsun: 212.972062, hgln: 0.000000, hglt: 3.449620 },
  { isoDate: "2018-11-11T00:00:00Z", dsun: 212.920526, hgln: -0.000000, hglt: 3.337370 },
  { isoDate: "2018-11-12T00:00:00Z", dsun: 212.869401, hgln: 0.000000, hglt: 3.224060 },
  { isoDate: "2018-11-13T00:00:00Z", dsun: 212.818747, hgln: 0.000000, hglt: 3.109724 },
  { isoDate: "2018-11-14T00:00:00Z", dsun: 212.768622, hgln: 0.000000, hglt: 2.994395 },
  { isoDate: "2018-11-15T00:00:00Z", dsun: 212.719090, hgln: 0.000000, hglt: 2.878108 },
  { isoDate: "2018-11-16T00:00:00Z", dsun: 212.670215, hgln: -0.000000, hglt: 2.760897 },
  { isoDate: "2018-11-17T00:00:00Z", dsun: 212.622061, hgln: -0.000000, hglt: 2.642795 },
  { isoDate: "2018-11-18T00:00:00Z", dsun: 212.574694, hgln: 0.000000, hglt: 2.523839 },
  { isoDate: "2018-11-19T00:00:00Z", dsun: 212.528181, hgln: -0.000000, hglt: 2.404062 },
  { isoDate: "2018-11-20T00:00:00Z", dsun: 212.482586, hgln: -0.000000, hglt: 2.283499 },
  { isoDate: "2018-11-21T00:00:00Z", dsun: 212.437972, hgln: 0.000000, hglt: 2.162187 },
  { isoDate: "2018-11-22T00:00:00Z", dsun: 212.394396, hgln: 0.000000, hglt: 2.040159 },
  { isoDate: "2018-11-23T00:00:00Z", dsun: 212.351905, hgln: 0.000000, hglt: 1.917452 },
  { isoDate: "2018-11-24T00:00:00Z", dsun: 212.310532, hgln: 0.000000, hglt: 1.794102 },
  { isoDate: "2018-11-25T00:00:00Z", dsun: 212.270293, hgln: -0.000000, hglt: 1.670145 },
  { isoDate: "2018-11-26T00:00:00Z", dsun: 212.231185, hgln: -0.000000, hglt: 1.545621 },
  { isoDate: "2018-11-27T00:00:00Z", dsun: 212.193184, hgln: -0.000000, hglt: 1.420565 },
  { isoDate: "2018-11-28T00:00:00Z", dsun: 212.156249, hgln: 0.000000, hglt: 1.295019 },
  { isoDate: "2018-11-29T00:00:00Z", dsun: 212.120326, hgln: -0.000000, hglt: 1.169022 },
  { isoDate: "2018-11-30T00:00:00Z", dsun: 212.085356, hgln: 0.000000, hglt: 1.042615 },
  { isoDate: "2018-12-01T00:00:00Z", dsun: 212.051277, hgln: 0.000000, hglt: 0.915839 },
  { isoDate: "2018-12-02T00:00:00Z", dsun: 212.018029, hgln: -0.000000, hglt: 0.788735 },
  { isoDate: "2018-12-03T00:00:00Z", dsun: 211.985559, hgln: 0.000000, hglt: 0.661345 },
  { isoDate: "2018-12-04T00:00:00Z", dsun: 211.953824, hgln: 0.000000, hglt: 0.533712 },
  { isoDate: "2018-12-05T00:00:00Z", dsun: 211.922793, hgln: -0.000000, hglt: 0.405877 },
  { isoDate: "2018-12-06T00:00:00Z", dsun: 211.892444, hgln: 0.000000, hglt: 0.277883 },
  { isoDate: "2018-12-07T00:00:00Z", dsun: 211.862773, hgln: -0.000000, hglt: 0.149770 },
  { isoDate: "2018-12-08T00:00:00Z", dsun: 211.833783, hgln: 0.000000, hglt: 0.021580 },
  { isoDate: "2018-12-09T00:00:00Z", dsun: 211.805493, hgln: -0.000000, hglt: -0.106644 },
  { isoDate: "2018-12-10T00:00:00Z", dsun: 211.777927, hgln: 0.000000, hglt: -0.234863 },
  { isoDate: "2018-12-11T00:00:00Z", dsun: 211.751121, hgln: -0.000000, hglt: -0.363036 },
  { isoDate: "2018-12-12T00:00:00Z", dsun: 211.725114, hgln: 0.000000, hglt: -0.491121 },
  { isoDate: "2018-12-13T00:00:00Z", dsun: 211.699954, hgln: 0.000000, hglt: -0.619078 },
  { isoDate: "2018-12-14T00:00:00Z", dsun: 211.675687, hgln: 0.000000, hglt: -0.746867 },
  { isoDate: "2018-12-15T00:00:00Z", dsun: 211.652369, hgln: 0.000000, hglt: -0.874447 },
  { isoDate: "2018-12-16T00:00:00Z", dsun: 211.630053, hgln: -0.000000, hglt: -1.001779 },
  { isoDate: "2018-12-17T00:00:00Z", dsun: 211.608801, hgln: 0.000000, hglt: -1.128822 },
  { isoDate: "2018-12-18T00:00:00Z", dsun: 211.588673, hgln: 0.000000, hglt: -1.255536 },
  { isoDate: "2018-12-19T00:00:00Z", dsun: 211.569734, hgln: 0.000000, hglt: -1.381883 },
  { isoDate: "2018-12-20T00:00:00Z", dsun: 211.552046, hgln: -0.000000, hglt: -1.507821 },
  { isoDate: "2018-12-21T00:00:00Z", dsun: 211.535669, hgln: 0.000000, hglt: -1.633312 },
  { isoDate: "2018-12-22T00:00:00Z", dsun: 211.520653, hgln: 0.000000, hglt: -1.758315 },
  { isoDate: "2018-12-23T00:00:00Z", dsun: 211.507035, hgln: 0.000000, hglt: -1.882793 },
  { isoDate: "2018-12-24T00:00:00Z", dsun: 211.494831, hgln: 0.000000, hglt: -2.006704 },
  { isoDate: "2018-12-25T00:00:00Z", dsun: 211.484032, hgln: 0.000000, hglt: -2.130009 },
  { isoDate: "2018-12-26T00:00:00Z", dsun: 211.474608, hgln: -0.000000, hglt: -2.252667 },
  { isoDate: "2018-12-27T00:00:00Z", dsun: 211.466510, hgln: 0.000000, hglt: -2.374640 },
  { isoDate: "2018-12-28T00:00:00Z", dsun: 211.459673, hgln: 0.000000, hglt: -2.495885 },
  { isoDate: "2018-12-29T00:00:00Z", dsun: 211.454029, hgln: -0.000000, hglt: -2.616363 },
  { isoDate: "2018-12-30T00:00:00Z", dsun: 211.449506, hgln: 0.000000, hglt: -2.736034 },
  { isoDate: "2018-12-31T00:00:00Z", dsun: 211.446037, hgln: 0.000000, hglt: -2.854857 },
  { isoDate: "2019-01-01T00:00:00Z", dsun: 211.443563, hgln: 0.000000, hglt: -2.972793 },
  { isoDate: "2019-01-02T00:00:00Z", dsun: 211.442031, hgln: 0.000000, hglt: -3.089803 },
  { isoDate: "2019-01-03T00:00:00Z", dsun: 211.441400, hgln: -0.000000, hglt: -3.205848 },
  { isoDate: "2019-01-04T00:00:00Z", dsun: 211.441638, hgln: 0.000000, hglt: -3.320889 },
  { isoDate: "2019-01-05T00:00:00Z", dsun: 211.442722, hgln: -0.000000, hglt: -3.434890 },
  { isoDate: "2019-01-06T00:00:00Z", dsun: 211.444640, hgln: -0.000000, hglt: -3.547811 },
  { isoDate: "2019-01-07T00:00:00Z", dsun: 211.447390, hgln: 0.000000, hglt: -3.659617 },
  { isoDate: "2019-01-08T00:00:00Z", dsun: 211.450977, hgln: 0.000000, hglt: -3.770272 },
  { isoDate: "2019-01-09T00:00:00Z", dsun: 211.455415, hgln: 0.000000, hglt: -3.879739 },
  { isoDate: "2019-01-10T00:00:00Z", dsun: 211.460726, hgln: 0.000000, hglt: -3.987983 },
  { isoDate: "2019-01-11T00:00:00Z", dsun: 211.466938, hgln: 0.000000, hglt: -4.094970 },
  { isoDate: "2019-01-12T00:00:00Z", dsun: 211.474082, hgln: -0.000000, hglt: -4.200664 },
  { isoDate: "2019-01-13T00:00:00Z", dsun: 211.482198, hgln: 0.000000, hglt: -4.305033 },
  { isoDate: "2019-01-14T00:00:00Z", dsun: 211.491329, hgln: -0.000000, hglt: -4.408042 },
  { isoDate: "2019-01-15T00:00:00Z", dsun: 211.501525, hgln: 0.000000, hglt: -4.509659 },
  { isoDate: "2019-01-16T00:00:00Z", dsun: 211.512839, hgln: -0.000000, hglt: -4.609851 },
  { isoDate: "2019-01-17T00:00:00Z", dsun: 211.525330, hgln: -0.000000, hglt: -4.708586 },
  { isoDate: "2019-01-18T00:00:00Z", dsun: 211.539058, hgln: 0.000000, hglt: -4.805832 },
  { isoDate: "2019-01-19T00:00:00Z", dsun: 211.554081, hgln: 0.000000, hglt: -4.901558 },
  { isoDate: "2019-01-20T00:00:00Z", dsun: 211.570450, hgln: 0.000000, hglt: -4.995732 },
  { isoDate: "2019-01-21T00:00:00Z", dsun: 211.588200, hgln: 0.000000, hglt: -5.088325 },
  { isoDate: "2019-01-22T00:00:00Z", dsun: 211.607347, hgln: -0.000000, hglt: -5.179306 },
  { isoDate: "2019-01-23T00:00:00Z", dsun: 211.627879, hgln: 0.000000, hglt: -5.268645 },
  { isoDate: "2019-01-24T00:00:00Z", dsun: 211.649761, hgln: 0.000000, hglt: -5.356313 },
  { isoDate: "2019-01-25T00:00:00Z", dsun: 211.672933, hgln: -0.000000, hglt: -5.442282 },
  { isoDate: "2019-01-26T00:00:00Z", dsun: 211.697326, hgln: 0.000000, hglt: -5.526523 },
  { isoDate: "2019-01-27T00:00:00Z", dsun: 211.722861, hgln: 0.000000, hglt: -5.609010 },
  { isoDate: "2019-01-28T00:00:00Z", dsun: 211.749459, hgln: -0.000000, hglt: -5.689716 },
  { isoDate: "2019-01-29T00:00:00Z", dsun: 211.777045, hgln: 0.000000, hglt: -5.768616 },
  { isoDate: "2019-01-30T00:00:00Z", dsun: 211.805550, hgln: 0.000000, hglt: -5.845685 },
  { isoDate: "2019-01-31T00:00:00Z", dsun: 211.834914, hgln: 0.000000, hglt: -5.920899 },
  { isoDate: "2019-02-01T00:00:00Z", dsun: 211.865083, hgln: -0.000000, hglt: -5.994234 },
  { isoDate: "2019-02-02T00:00:00Z", dsun: 211.896012, hgln: 0.000000, hglt: -6.065670 },
  { isoDate: "2019-02-03T00:00:00Z", dsun: 211.927662, hgln: 0.000000, hglt: -6.135183 },
  { isoDate: "2019-02-04T00:00:00Z", dsun: 211.960006, hgln: 0.000000, hglt: -6.202753 },
  { isoDate: "2019-02-05T00:00:00Z", dsun: 211.993021, hgln: 0.000000, hglt: -6.268361 },
  { isoDate: "2019-02-06T00:00:00Z", dsun: 212.026695, hgln: -0.000000, hglt: -6.331986 },
  { isoDate: "2019-02-07T00:00:00Z", dsun: 212.061022, hgln: -0.000000, hglt: -6.393610 },
  { isoDate: "2019-02-08T00:00:00Z", dsun: 212.096005, hgln: -0.000000, hglt: -6.453216 },
  { isoDate: "2019-02-09T00:00:00Z", dsun: 212.131655, hgln: -0.000000, hglt: -6.510786 },
  { isoDate: "2019-02-10T00:00:00Z", dsun: 212.167989, hgln: -0.000000, hglt: -6.566303 },
  { isoDate: "2019-02-11T00:00:00Z", dsun: 212.205032, hgln: 0.000000, hglt: -6.619753 },
  { isoDate: "2019-02-12T00:00:00Z", dsun: 212.242818, hgln: -0.000000, hglt: -6.671119 },
  { isoDate: "2019-02-13T00:00:00Z", dsun: 212.281385, hgln: -0.000000, hglt: -6.720386 },
  { isoDate: "2019-02-14T00:00:00Z", dsun: 212.320780, hgln: 0.000000, hglt: -6.767541 },
  { isoDate: "2019-02-15T00:00:00Z", dsun: 212.361056, hgln: -0.000000, hglt: -6.812570 },
  { isoDate: "2019-02-16T00:00:00Z", dsun: 212.402269, hgln: 0.000000, hglt: -6.855460 },
  { isoDate: "2019-02-17T00:00:00Z", dsun: 212.444475, hgln: 0.000000, hglt: -6.896198 },
  { isoDate: "2019-02-18T00:00:00Z", dsun: 212.487723, hgln: -0.000000, hglt: -6.934771 },
  { isoDate: "2019-02-19T00:00:00Z", dsun: 212.532047, hgln: -0.000000, hglt: -6.971169 },
  { isoDate: "2019-02-20T00:00:00Z", dsun: 212.577462, hgln: -0.000000, hglt: -7.005380 },
  { isoDate: "2019-02-21T00:00:00Z", dsun: 212.623956, hgln: 0.000000, hglt: -7.037394 },
  { isoDate: "2019-02-22T00:00:00Z", dsun: 212.671491, hgln: 0.000000, hglt: -7.067203 },
  { isoDate: "2019-02-23T00:00:00Z", dsun: 212.720009, hgln: -0.000000, hglt: -7.094799 },
  { isoDate: "2019-02-24T00:00:00Z", dsun: 212.769435, hgln: 0.000000, hglt: -7.120176 },
  { isoDate: "2019-02-25T00:00:00Z", dsun: 212.819688, hgln: 0.000000, hglt: -7.143328 },
  { isoDate: "2019-02-26T00:00:00Z", dsun: 212.870686, hgln: -0.000000, hglt: -7.164251 },
  { isoDate: "2019-02-27T00:00:00Z", dsun: 212.922351, hgln: -0.000000, hglt: -7.182943 },
  { isoDate: "2019-02-28T00:00:00Z", dsun: 212.974606, hgln: 0.000000, hglt: -7.199400 },
  { isoDate: "2019-03-01T00:00:00Z", dsun: 213.027384, hgln: -0.000000, hglt: -7.213623 },
  { isoDate: "2019-03-02T00:00:00Z", dsun: 213.080622, hgln: 0.000000, hglt: -7.225610 },
  { isoDate: "2019-03-03T00:00:00Z", dsun: 213.134264, hgln: -0.000000, hglt: -7.235361 },
  { isoDate: "2019-03-04T00:00:00Z", dsun: 213.188260, hgln: -0.000000, hglt: -7.242879 },
  { isoDate: "2019-03-05T00:00:00Z", dsun: 213.242568, hgln: -0.000000, hglt: -7.248166 },
  { isoDate: "2019-03-06T00:00:00Z", dsun: 213.297150, hgln: 0.000000, hglt: -7.251223 },
  { isoDate: "2019-03-07T00:00:00Z", dsun: 213.351978, hgln: 0.000000, hglt: -7.252055 },
  { isoDate: "2019-03-08T00:00:00Z", dsun: 213.407032, hgln: -0.000000, hglt: -7.250666 },
  { isoDate: "2019-03-09T00:00:00Z", dsun: 213.462299, hgln: -0.000000, hglt: -7.247061 },
  { isoDate: "2019-03-10T00:00:00Z", dsun: 213.517779, hgln: 0.000000, hglt: -7.241245 },
  { isoDate: "2019-03-11T00:00:00Z", dsun: 213.573476, hgln: -0.000000, hglt: -7.233224 },
  { isoDate: "2019-03-12T00:00:00Z", dsun: 213.629407, hgln: -0.000000, hglt: -7.223005 },
  { isoDate: "2019-03-13T00:00:00Z", dsun: 213.685599, hgln: -0.000000, hglt: -7.210594 },
  { isoDate: "2019-03-14T00:00:00Z", dsun: 213.742085, hgln: -0.000000, hglt: -7.195999 },
  { isoDate: "2019-03-15T00:00:00Z", dsun: 213.798910, hgln: -0.000000, hglt: -7.179228 },
  { isoDate: "2019-03-16T00:00:00Z", dsun: 213.856124, hgln: -0.000000, hglt: -7.160288 },
  { isoDate: "2019-03-17T00:00:00Z", dsun: 213.913781, hgln: -0.000000, hglt: -7.139187 },
  { isoDate: "2019-03-18T00:00:00Z", dsun: 213.971936, hgln: -0.000000, hglt: -7.115934 },
  { isoDate: "2019-03-19T00:00:00Z", dsun: 214.030636, hgln: -0.000000, hglt: -7.090538 },
  { isoDate: "2019-03-20T00:00:00Z", dsun: 214.089917, hgln: 0.000000, hglt: -7.063007 },
  { isoDate: "2019-03-21T00:00:00Z", dsun: 214.149793, hgln: -0.000000, hglt: -7.033352 },
  { isoDate: "2019-03-22T00:00:00Z", dsun: 214.210254, hgln: -0.000000, hglt: -7.001584 },
  { isoDate: "2019-03-23T00:00:00Z", dsun: 214.271269, hgln: 0.000000, hglt: -6.967716 },
  { isoDate: "2019-03-24T00:00:00Z", dsun: 214.332782, hgln: -0.000000, hglt: -6.931760 },
  { isoDate: "2019-03-25T00:00:00Z", dsun: 214.394726, hgln: -0.000000, hglt: -6.893732 },
  { isoDate: "2019-03-26T00:00:00Z", dsun: 214.457024, hgln: -0.000000, hglt: -6.853646 },
  { isoDate: "2019-03-27T00:00:00Z", dsun: 214.519599, hgln: 0.000000, hglt: -6.811521 },
  { isoDate: "2019-03-28T00:00:00Z", dsun: 214.582370, hgln: 0.000000, hglt: -6.767373 },
  { isoDate: "2019-03-29T00:00:00Z", dsun: 214.645265, hgln: -0.000000, hglt: -6.721221 },
  { isoDate: "2019-03-30T00:00:00Z", dsun: 214.708210, hgln: 0.000000, hglt: -6.673084 },
  { isoDate: "2019-03-31T00:00:00Z", dsun: 214.771140, hgln: 0.000000, hglt: -6.622982 },
  { isoDate: "2019-04-01T00:00:00Z", dsun: 214.833991, hgln: 0.000000, hglt: -6.570936 },
  { isoDate: "2019-04-02T00:00:00Z", dsun: 214.896707, hgln: -0.000000, hglt: -6.516965 },
  { isoDate: "2019-04-03T00:00:00Z", dsun: 214.959235, hgln: 0.000000, hglt: -6.461092 },
  { isoDate: "2019-04-04T00:00:00Z", dsun: 215.021528, hgln: -0.000000, hglt: -6.403339 },
  { isoDate: "2019-04-05T00:00:00Z", dsun: 215.083547, hgln: 0.000000, hglt: -6.343728 },
  { isoDate: "2019-04-06T00:00:00Z", dsun: 215.145261, hgln: 0.000000, hglt: -6.282281 },
  { isoDate: "2019-04-07T00:00:00Z", dsun: 215.206647, hgln: 0.000000, hglt: -6.219023 },
  { isoDate: "2019-04-08T00:00:00Z", dsun: 215.267696, hgln: 0.000000, hglt: -6.153976 },
  { isoDate: "2019-04-09T00:00:00Z", dsun: 215.328409, hgln: -0.000000, hglt: -6.087163 },
  { isoDate: "2019-04-10T00:00:00Z", dsun: 215.388798, hgln: 0.000000, hglt: -6.018610 },
  { isoDate: "2019-04-11T00:00:00Z", dsun: 215.448889, hgln: 0.000000, hglt: -5.948338 },
  { isoDate: "2019-04-12T00:00:00Z", dsun: 215.508719, hgln: 0.000000, hglt: -5.876372 },
  { isoDate: "2019-04-13T00:00:00Z", dsun: 215.568334, hgln: 0.000000, hglt: -5.802736 },
  { isoDate: "2019-04-14T00:00:00Z", dsun: 215.627786, hgln: 0.000000, hglt: -5.727452 },
  { isoDate: "2019-04-15T00:00:00Z", dsun: 215.687132, hgln: -0.000000, hglt: -5.650544 },
  { isoDate: "2019-04-16T00:00:00Z", dsun: 215.746429, hgln: -0.000000, hglt: -5.572036 },
  { isoDate: "2019-04-17T00:00:00Z", dsun: 215.805724, hgln: 0.000000, hglt: -5.491952 },
  { isoDate: "2019-04-18T00:00:00Z", dsun: 215.865052, hgln: -0.000000, hglt: -5.410314 },
  { isoDate: "2019-04-19T00:00:00Z", dsun: 215.924433, hgln: 0.000000, hglt: -5.327147 },
  { isoDate: "2019-04-20T00:00:00Z", dsun: 215.983861, hgln: -0.000000, hglt: -5.242478 },
  { isoDate: "2019-04-21T00:00:00Z", dsun: 216.043312, hgln: 0.000000, hglt: -5.156331 },
  { isoDate: "2019-04-22T00:00:00Z", dsun: 216.102745, hgln: 0.000000, hglt: -5.068733 },
  { isoDate: "2019-04-23T00:00:00Z", dsun: 216.162102, hgln: -0.000000, hglt: -4.979712 },
  { isoDate: "2019-04-24T00:00:00Z", dsun: 216.221320, hgln: 0.000000, hglt: -4.889297 },
  { isoDate: "2019-04-25T00:00:00Z", dsun: 216.280329, hgln: 0.000000, hglt: -4.797517 },
  { isoDate: "2019-04-26T00:00:00Z", dsun: 216.339057, hgln: -0.000000, hglt: -4.704401 },
  { isoDate: "2019-04-27T00:00:00Z", dsun: 216.397436, hgln: 0.000000, hglt: -4.609980 },
  { isoDate: "2019-04-28T00:00:00Z", dsun: 216.455397, hgln: 0.000000, hglt: -4.514284 },
  { isoDate: "2019-04-29T00:00:00Z", dsun: 216.512873, hgln: 0.000000, hglt: -4.417344 },
  { isoDate: "2019-04-30T00:00:00Z", dsun: 216.569802, hgln: 0.000000, hglt: -4.319191 },
  { isoDate: "2019-05-01T00:00:00Z", dsun: 216.626122, hgln: -0.000000, hglt: -4.219856 },
  { isoDate: "2019-05-02T00:00:00Z", dsun: 216.681776, hgln: -0.000000, hglt: -4.119371 },
  { isoDate: "2019-05-03T00:00:00Z", dsun: 216.736711, hgln: 0.000000, hglt: -4.017768 },
  { isoDate: "2019-05-04T00:00:00Z", dsun: 216.790880, hgln: 0.000000, hglt: -3.915079 },
  { isoDate: "2019-05-05T00:00:00Z", dsun: 216.844246, hgln: 0.000000, hglt: -3.811335 },
  { isoDate: "2019-05-06T00:00:00Z", dsun: 216.896781, hgln: -0.000000, hglt: -3.706570 },
  { isoDate: "2019-05-07T00:00:00Z", dsun: 216.948471, hgln: -0.000000, hglt: -3.600815 },
  { isoDate: "2019-05-08T00:00:00Z", dsun: 216.999316, hgln: 0.000000, hglt: -3.494102 },
  { isoDate: "2019-05-09T00:00:00Z", dsun: 217.049332, hgln: 0.000000, hglt: -3.386463 },
  { isoDate: "2019-05-10T00:00:00Z", dsun: 217.098550, hgln: -0.000000, hglt: -3.277928 },
  { isoDate: "2019-05-11T00:00:00Z", dsun: 217.147013, hgln: 0.000000, hglt: -3.168529 },
  { isoDate: "2019-05-12T00:00:00Z", dsun: 217.194774, hgln: -0.000000, hglt: -3.058296 },
  { isoDate: "2019-05-13T00:00:00Z", dsun: 217.241894, hgln: 0.000000, hglt: -2.947260 },
  { isoDate: "2019-05-14T00:00:00Z", dsun: 217.288434, hgln: 0.000000, hglt: -2.835450 },
  { isoDate: "2019-05-15T00:00:00Z", dsun: 217.334452, hgln: -0.000000, hglt: -2.722897 },
  { isoDate: "2019-05-16T00:00:00Z", dsun: 217.380000, hgln: 0.000000, hglt: -2.609631 },
  { isoDate: "2019-05-17T00:00:00Z", dsun: 217.425116, hgln: 0.000000, hglt: -2.495682 },
  { isoDate: "2019-05-18T00:00:00Z", dsun: 217.469822, hgln: -0.000000, hglt: -2.381080 },
  { isoDate: "2019-05-19T00:00:00Z", dsun: 217.514122, hgln: -0.000000, hglt: -2.265855 },
  { isoDate: "2019-05-20T00:00:00Z", dsun: 217.558004, hgln: 0.000000, hglt: -2.150041 },
  { isoDate: "2019-05-21T00:00:00Z", dsun: 217.601439, hgln: -0.000000, hglt: -2.033667 },
  { isoDate: "2019-05-22T00:00:00Z", dsun: 217.644388, hgln: 0.000000, hglt: -1.916766 },
  { isoDate: "2019-05-23T00:00:00Z", dsun: 217.686799, hgln: 0.000000, hglt: -1.799371 },
  { isoDate: "2019-05-24T00:00:00Z", dsun: 217.728620, hgln: 0.000000, hglt: -1.681515 },
  { isoDate: "2019-05-25T00:00:00Z", dsun: 217.769789, hgln: -0.000000, hglt: -1.563231 },
  { isoDate: "2019-05-26T00:00:00Z", dsun: 217.810249, hgln: -0.000000, hglt: -1.444551 },
  { isoDate: "2019-05-27T00:00:00Z", dsun: 217.849936, hgln: -0.000000, hglt: -1.325510 },
  { isoDate: "2019-05-28T00:00:00Z", dsun: 217.888792, hgln: 0.000000, hglt: -1.206141 },
  { isoDate: "2019-05-29T00:00:00Z", dsun: 217.926754, hgln: 0.000000, hglt: -1.086476 },
  { isoDate: "2019-05-30T00:00:00Z", dsun: 217.963762, hgln: -0.000000, hglt: -0.966551 },
  { isoDate: "2019-05-31T00:00:00Z", dsun: 217.999758, hgln: 0.000000, hglt: -0.846398 },
  { isoDate: "2019-06-01T00:00:00Z", dsun: 218.034685, hgln: 0.000000, hglt: -0.726051 },
  { isoDate: "2019-06-02T00:00:00Z", dsun: 218.068493, hgln: -0.000000, hglt: -0.605544 },
  { isoDate: "2019-06-03T00:00:00Z", dsun: 218.101139, hgln: -0.000000, hglt: -0.484910 },
  { isoDate: "2019-06-04T00:00:00Z", dsun: 218.132593, hgln: -0.000000, hglt: -0.364183 },
  { isoDate: "2019-06-05T00:00:00Z", dsun: 218.162841, hgln: -0.000000, hglt: -0.243395 },
  { isoDate: "2019-06-06T00:00:00Z", dsun: 218.191885, hgln: -0.000000, hglt: -0.122579 },
  { isoDate: "2019-06-07T00:00:00Z", dsun: 218.219748, hgln: 0.000000, hglt: -0.001768 },
  { isoDate: "2019-06-08T00:00:00Z", dsun: 218.246467, hgln: 0.000000, hglt: 0.119006 },
  { isoDate: "2019-06-09T00:00:00Z", dsun: 218.272099, hgln: 0.000000, hglt: 0.239712 },
  { isoDate: "2019-06-10T00:00:00Z", dsun: 218.296705, hgln: 0.000000, hglt: 0.360319 },
  { isoDate: "2019-06-11T00:00:00Z", dsun: 218.320354, hgln: 0.000000, hglt: 0.480795 },
  { isoDate: "2019-06-12T00:00:00Z", dsun: 218.343115, hgln: 0.000000, hglt: 0.601110 },
  { isoDate: "2019-06-13T00:00:00Z", dsun: 218.365050, hgln: 0.000000, hglt: 0.721234 },
  { isoDate: "2019-06-14T00:00:00Z", dsun: 218.386212, hgln: -0.000000, hglt: 0.841135 },
  { isoDate: "2019-06-15T00:00:00Z", dsun: 218.406646, hgln: -0.000000, hglt: 0.960783 },
  { isoDate: "2019-06-16T00:00:00Z", dsun: 218.426379, hgln: 0.000000, hglt: 1.080148 },
  { isoDate: "2019-06-17T00:00:00Z", dsun: 218.445425, hgln: -0.000000, hglt: 1.199199 },
  { isoDate: "2019-06-18T00:00:00Z", dsun: 218.463786, hgln: 0.000000, hglt: 1.317904 },
  { isoDate: "2019-06-19T00:00:00Z", dsun: 218.481447, hgln: 0.000000, hglt: 1.436233 },
  { isoDate: "2019-06-20T00:00:00Z", dsun: 218.498385, hgln: -0.000000, hglt: 1.554155 },
  { isoDate: "2019-06-21T00:00:00Z", dsun: 218.514568, hgln: 0.000000, hglt: 1.671638 },
  { isoDate: "2019-06-22T00:00:00Z", dsun: 218.529956, hgln: 0.000000, hglt: 1.788652 },
  { isoDate: "2019-06-23T00:00:00Z", dsun: 218.544505, hgln: 0.000000, hglt: 1.905165 },
  { isoDate: "2019-06-24T00:00:00Z", dsun: 218.558168, hgln: 0.000000, hglt: 2.021146 },
  { isoDate: "2019-06-25T00:00:00Z", dsun: 218.570894, hgln: 0.000000, hglt: 2.136564 },
  { isoDate: "2019-06-26T00:00:00Z", dsun: 218.582630, hgln: 0.000000, hglt: 2.251389 },
  { isoDate: "2019-06-27T00:00:00Z", dsun: 218.593320, hgln: -0.000000, hglt: 2.365589 },
  { isoDate: "2019-06-28T00:00:00Z", dsun: 218.602909, hgln: 0.000000, hglt: 2.479133 },
  { isoDate: "2019-06-29T00:00:00Z", dsun: 218.611337, hgln: 0.000000, hglt: 2.591992 },
  { isoDate: "2019-06-30T00:00:00Z", dsun: 218.618548, hgln: -0.000000, hglt: 2.704136 },
  { isoDate: "2019-07-01T00:00:00Z", dsun: 218.624490, hgln: -0.000000, hglt: 2.815533 },
  { isoDate: "2019-07-02T00:00:00Z", dsun: 218.629117, hgln: 0.000000, hglt: 2.926154 },
  { isoDate: "2019-07-03T00:00:00Z", dsun: 218.632397, hgln: -0.000000, hglt: 3.035970 },
  { isoDate: "2019-07-04T00:00:00Z", dsun: 218.634317, hgln: -0.000000, hglt: 3.144951 },
  { isoDate: "2019-07-05T00:00:00Z", dsun: 218.634885, hgln: 0.000000, hglt: 3.253069 },
  { isoDate: "2019-07-06T00:00:00Z", dsun: 218.634130, hgln: 0.000000, hglt: 3.360294 },
  { isoDate: "2019-07-07T00:00:00Z", dsun: 218.632101, hgln: 0.000000, hglt: 3.466598 },
  { isoDate: "2019-07-08T00:00:00Z", dsun: 218.628864, hgln: -0.000000, hglt: 3.571953 },
  { isoDate: "2019-07-09T00:00:00Z", dsun: 218.624491, hgln: -0.000000, hglt: 3.676332 },
  { isoDate: "2019-07-10T00:00:00Z", dsun: 218.619059, hgln: 0.000000, hglt: 3.779707 },
  { isoDate: "2019-07-11T00:00:00Z", dsun: 218.612641, hgln: 0.000000, hglt: 3.882049 },
  { isoDate: "2019-07-12T00:00:00Z", dsun: 218.605306, hgln: 0.000000, hglt: 3.983333 },
  { isoDate: "2019-07-13T00:00:00Z", dsun: 218.597111, hgln: 0.000000, hglt: 4.083531 },
  { isoDate: "2019-07-14T00:00:00Z", dsun: 218.588106, hgln: 0.000000, hglt: 4.182615 },
  { isoDate: "2019-07-15T00:00:00Z", dsun: 218.578326, hgln: -0.000000, hglt: 4.280559 },
  { isoDate: "2019-07-16T00:00:00Z", dsun: 218.567796, hgln: -0.000000, hglt: 4.377336 },
  { isoDate: "2019-07-17T00:00:00Z", dsun: 218.556530, hgln: -0.000000, hglt: 4.472920 },
  { isoDate: "2019-07-18T00:00:00Z", dsun: 218.544531, hgln: -0.000000, hglt: 4.567284 },
  { isoDate: "2019-07-19T00:00:00Z", dsun: 218.531790, hgln: 0.000000, hglt: 4.660402 },
  { isoDate: "2019-07-20T00:00:00Z", dsun: 218.518293, hgln: 0.000000, hglt: 4.752248 },
  { isoDate: "2019-07-21T00:00:00Z", dsun: 218.504017, hgln: 0.000000, hglt: 4.842796 },
  { isoDate: "2019-07-22T00:00:00Z", dsun: 218.488933, hgln: 0.000000, hglt: 4.932022 },
  { isoDate: "2019-07-23T00:00:00Z", dsun: 218.473006, hgln: 0.000000, hglt: 5.019900 },
  { isoDate: "2019-07-24T00:00:00Z", dsun: 218.456197, hgln: 0.000000, hglt: 5.106405 },
  { isoDate: "2019-07-25T00:00:00Z", dsun: 218.438463, hgln: 0.000000, hglt: 5.191513 },
  { isoDate: "2019-07-26T00:00:00Z", dsun: 218.419756, hgln: -0.000000, hglt: 5.275201 },
  { isoDate: "2019-07-27T00:00:00Z", dsun: 218.400024, hgln: 0.000000, hglt: 5.357444 },
  { isoDate: "2019-07-28T00:00:00Z", dsun: 218.379211, hgln: 0.000000, hglt: 5.438219 },
  { isoDate: "2019-07-29T00:00:00Z", dsun: 218.357262, hgln: 0.000000, hglt: 5.517504 },
  { isoDate: "2019-07-30T00:00:00Z", dsun: 218.334123, hgln: -0.000000, hglt: 5.595276 },
  { isoDate: "2019-07-31T00:00:00Z", dsun: 218.309747, hgln: -0.000000, hglt: 5.671514 },
  { isoDate: "2019-08-01T00:00:00Z", dsun: 218.284103, hgln: 0.000000, hglt: 5.746197 },
  { isoDate: "2019-08-02T00:00:00Z", dsun: 218.257179, hgln: -0.000000, hglt: 5.819302 },
  { isoDate: "2019-08-03T00:00:00Z", dsun: 218.228987, hgln: 0.000000, hglt: 5.890809 },
  { isoDate: "2019-08-04T00:00:00Z", dsun: 218.199563, hgln: -0.000000, hglt: 5.960698 },
  { isoDate: "2019-08-05T00:00:00Z", dsun: 218.168966, hgln: 0.000000, hglt: 6.028948 },
  { isoDate: "2019-08-06T00:00:00Z", dsun: 218.137269, hgln: -0.000000, hglt: 6.095539 },
  { isoDate: "2019-08-07T00:00:00Z", dsun: 218.104552, hgln: 0.000000, hglt: 6.160450 },
  { isoDate: "2019-08-08T00:00:00Z", dsun: 218.070898, hgln: -0.000000, hglt: 6.223662 },
  { isoDate: "2019-08-09T00:00:00Z", dsun: 218.036384, hgln: -0.000000, hglt: 6.285154 },
  { isoDate: "2019-08-10T00:00:00Z", dsun: 218.001084, hgln: -0.000000, hglt: 6.344907 },
  { isoDate: "2019-08-11T00:00:00Z", dsun: 217.965060, hgln: 0.000000, hglt: 6.402903 },
  { isoDate: "2019-08-12T00:00:00Z", dsun: 217.928369, hgln: 0.000000, hglt: 6.459122 },
  { isoDate: "2019-08-13T00:00:00Z", dsun: 217.891054, hgln: -0.000000, hglt: 6.513545 },
  { isoDate: "2019-08-14T00:00:00Z", dsun: 217.853152, hgln: 0.000000, hglt: 6.566156 },
  { isoDate: "2019-08-15T00:00:00Z", dsun: 217.814688, hgln: -0.000000, hglt: 6.616937 },
  { isoDate: "2019-08-16T00:00:00Z", dsun: 217.775681, hgln: 0.000000, hglt: 6.665870 },
  { isoDate: "2019-08-17T00:00:00Z", dsun: 217.736139, hgln: 0.000000, hglt: 6.712939 },
  { isoDate: "2019-08-18T00:00:00Z", dsun: 217.696063, hgln: -0.000000, hglt: 6.758129 },
  { isoDate: "2019-08-19T00:00:00Z", dsun: 217.655443, hgln: 0.000000, hglt: 6.801423 },
  { isoDate: "2019-08-20T00:00:00Z", dsun: 217.614267, hgln: -0.000000, hglt: 6.842808 },
  { isoDate: "2019-08-21T00:00:00Z", dsun: 217.572512, hgln: -0.000000, hglt: 6.882268 },
  { isoDate: "2019-08-22T00:00:00Z", dsun: 217.530151, hgln: -0.000000, hglt: 6.919790 },
  { isoDate: "2019-08-23T00:00:00Z", dsun: 217.487149, hgln: -0.000000, hglt: 6.955362 },
  { isoDate: "2019-08-24T00:00:00Z", dsun: 217.443464, hgln: -0.000000, hglt: 6.988972 },
  { isoDate: "2019-08-25T00:00:00Z", dsun: 217.399051, hgln: -0.000000, hglt: 7.020606 },
  { isoDate: "2019-08-26T00:00:00Z", dsun: 217.353858, hgln: 0.000000, hglt: 7.050256 },
  { isoDate: "2019-08-27T00:00:00Z", dsun: 217.307828, hgln: 0.000000, hglt: 7.077911 },
  { isoDate: "2019-08-28T00:00:00Z", dsun: 217.260910, hgln: -0.000000, hglt: 7.103562 },
  { isoDate: "2019-08-29T00:00:00Z", dsun: 217.213057, hgln: -0.000000, hglt: 7.127200 },
  { isoDate: "2019-08-30T00:00:00Z", dsun: 217.164237, hgln: -0.000000, hglt: 7.148817 },
  { isoDate: "2019-08-31T00:00:00Z", dsun: 217.114439, hgln: -0.000000, hglt: 7.168406 },
  { isoDate: "2019-09-01T00:00:00Z", dsun: 217.063678, hgln: 0.000000, hglt: 7.185959 },
  { isoDate: "2019-09-02T00:00:00Z", dsun: 217.011994, hgln: -0.000000, hglt: 7.201469 },
  { isoDate: "2019-09-03T00:00:00Z", dsun: 216.959451, hgln: 0.000000, hglt: 7.214929 },
  { isoDate: "2019-09-04T00:00:00Z", dsun: 216.906124, hgln: -0.000000, hglt: 7.226332 },
  { isoDate: "2019-09-05T00:00:00Z", dsun: 216.852097, hgln: 0.000000, hglt: 7.235669 },
  { isoDate: "2019-09-06T00:00:00Z", dsun: 216.797455, hgln: -0.000000, hglt: 7.242936 },
  { isoDate: "2019-09-07T00:00:00Z", dsun: 216.742279, hgln: 0.000000, hglt: 7.248125 },
  { isoDate: "2019-09-08T00:00:00Z", dsun: 216.686645, hgln: -0.000000, hglt: 7.251231 },
  { isoDate: "2019-09-09T00:00:00Z", dsun: 216.630621, hgln: 0.000000, hglt: 7.252248 },
  { isoDate: "2019-09-10T00:00:00Z", dsun: 216.574268, hgln: 0.000000, hglt: 7.251171 },
  { isoDate: "2019-09-11T00:00:00Z", dsun: 216.517641, hgln: -0.000000, hglt: 7.247997 },
  { isoDate: "2019-09-12T00:00:00Z", dsun: 216.460784, hgln: -0.000000, hglt: 7.242722 },
  { isoDate: "2019-09-13T00:00:00Z", dsun: 216.403737, hgln: -0.000000, hglt: 7.235343 },
  { isoDate: "2019-09-14T00:00:00Z", dsun: 216.346529, hgln: -0.000000, hglt: 7.225859 },
  { isoDate: "2019-09-15T00:00:00Z", dsun: 216.289183, hgln: 0.000000, hglt: 7.214267 },
  { isoDate: "2019-09-16T00:00:00Z", dsun: 216.231714, hgln: 0.000000, hglt: 7.200567 },
  { isoDate: "2019-09-17T00:00:00Z", dsun: 216.174127, hgln: 0.000000, hglt: 7.184759 },
  { isoDate: "2019-09-18T00:00:00Z", dsun: 216.116419, hgln: 0.000000, hglt: 7.166844 },
  { isoDate: "2019-09-19T00:00:00Z", dsun: 216.058579, hgln: 0.000000, hglt: 7.146825 },
  { isoDate: "2019-09-20T00:00:00Z", dsun: 216.000589, hgln: -0.000000, hglt: 7.124703 },
  { isoDate: "2019-09-21T00:00:00Z", dsun: 215.942420, hgln: -0.000000, hglt: 7.100482 },
  { isoDate: "2019-09-22T00:00:00Z", dsun: 215.884037, hgln: 0.000000, hglt: 7.074167 },
  { isoDate: "2019-09-23T00:00:00Z", dsun: 215.825395, hgln: 0.000000, hglt: 7.045764 },
  { isoDate: "2019-09-24T00:00:00Z", dsun: 215.766445, hgln: 0.000000, hglt: 7.015278 },
  { isoDate: "2019-09-25T00:00:00Z", dsun: 215.707132, hgln: -0.000000, hglt: 6.982717 },
  { isoDate: "2019-09-26T00:00:00Z", dsun: 215.647405, hgln: 0.000000, hglt: 6.948090 },
  { isoDate: "2019-09-27T00:00:00Z", dsun: 215.587215, hgln: -0.000000, hglt: 6.911406 },
  { isoDate: "2019-09-28T00:00:00Z", dsun: 215.526532, hgln: 0.000000, hglt: 6.872674 },
  { isoDate: "2019-09-29T00:00:00Z", dsun: 215.465344, hgln: 0.000000, hglt: 6.831905 },
  { isoDate: "2019-09-30T00:00:00Z", dsun: 215.403666, hgln: 0.000000, hglt: 6.789109 },
  { isoDate: "2019-10-01T00:00:00Z", dsun: 215.341535, hgln: 0.000000, hglt: 6.744297 },
  { isoDate: "2019-10-02T00:00:00Z", dsun: 215.279012, hgln: 0.000000, hglt: 6.697479 },
  { isoDate: "2019-10-03T00:00:00Z", dsun: 215.216171, hgln: -0.000000, hglt: 6.648665 },
  { isoDate: "2019-10-04T00:00:00Z", dsun: 215.153092, hgln: 0.000000, hglt: 6.597867 },
  { isoDate: "2019-10-05T00:00:00Z", dsun: 215.089859, hgln: 0.000000, hglt: 6.545095 },
  { isoDate: "2019-10-06T00:00:00Z", dsun: 215.026551, hgln: -0.000000, hglt: 6.490361 },
  { isoDate: "2019-10-07T00:00:00Z", dsun: 214.963246, hgln: 0.000000, hglt: 6.433676 },
  { isoDate: "2019-10-08T00:00:00Z", dsun: 214.900014, hgln: 0.000000, hglt: 6.375053 },
  { isoDate: "2019-10-09T00:00:00Z", dsun: 214.836920, hgln: -0.000000, hglt: 6.314506 },
  { isoDate: "2019-10-10T00:00:00Z", dsun: 214.774025, hgln: 0.000000, hglt: 6.252048 },
  { isoDate: "2019-10-11T00:00:00Z", dsun: 214.711382, hgln: 0.000000, hglt: 6.187695 },
  { isoDate: "2019-10-12T00:00:00Z", dsun: 214.649040, hgln: 0.000000, hglt: 6.121460 },
  { isoDate: "2019-10-13T00:00:00Z", dsun: 214.587039, hgln: 0.000000, hglt: 6.053360 },
  { isoDate: "2019-10-14T00:00:00Z", dsun: 214.525413, hgln: -0.000000, hglt: 5.983413 },
  { isoDate: "2019-10-15T00:00:00Z", dsun: 214.464187, hgln: 0.000000, hglt: 5.911634 },
  { isoDate: "2019-10-16T00:00:00Z", dsun: 214.403377, hgln: -0.000000, hglt: 5.838043 },
  { isoDate: "2019-10-17T00:00:00Z", dsun: 214.342989, hgln: -0.000000, hglt: 5.762659 },
  { isoDate: "2019-10-18T00:00:00Z", dsun: 214.283018, hgln: -0.000000, hglt: 5.685501 },
  { isoDate: "2019-10-19T00:00:00Z", dsun: 214.223451, hgln: 0.000000, hglt: 5.606591 },
  { isoDate: "2019-10-20T00:00:00Z", dsun: 214.164260, hgln: -0.000000, hglt: 5.525950 },
  { isoDate: "2019-10-21T00:00:00Z", dsun: 214.105410, hgln: 0.000000, hglt: 5.443602 },
  { isoDate: "2019-10-22T00:00:00Z", dsun: 214.046858, hgln: 0.000000, hglt: 5.359569 },
  { isoDate: "2019-10-23T00:00:00Z", dsun: 213.988552, hgln: 0.000000, hglt: 5.273877 },
  { isoDate: "2019-10-24T00:00:00Z", dsun: 213.930436, hgln: 0.000000, hglt: 5.186551 },
  { isoDate: "2019-10-25T00:00:00Z", dsun: 213.872456, hgln: 0.000000, hglt: 5.097617 },
  { isoDate: "2019-10-26T00:00:00Z", dsun: 213.814566, hgln: 0.000000, hglt: 5.007104 },
  { isoDate: "2019-10-27T00:00:00Z", dsun: 213.756730, hgln: 0.000000, hglt: 4.915037 },
  { isoDate: "2019-10-28T00:00:00Z", dsun: 213.698935, hgln: 0.000000, hglt: 4.821447 },
  { isoDate: "2019-10-29T00:00:00Z", dsun: 213.641190, hgln: -0.000000, hglt: 4.726359 },
  { isoDate: "2019-10-30T00:00:00Z", dsun: 213.583523, hgln: -0.000000, hglt: 4.629803 },
  { isoDate: "2019-10-31T00:00:00Z", dsun: 213.525987, hgln: -0.000000, hglt: 4.531806 },
  { isoDate: "2019-11-01T00:00:00Z", dsun: 213.468644, hgln: 0.000000, hglt: 4.432396 },
  { isoDate: "2019-11-02T00:00:00Z", dsun: 213.411566, hgln: -0.000000, hglt: 4.331602 },
  { isoDate: "2019-11-03T00:00:00Z", dsun: 213.354827, hgln: 0.000000, hglt: 4.229452 },
  { isoDate: "2019-11-04T00:00:00Z", dsun: 213.298502, hgln: -0.000000, hglt: 4.125974 },
  { isoDate: "2019-11-05T00:00:00Z", dsun: 213.242663, hgln: -0.000000, hglt: 4.021198 },
  { isoDate: "2019-11-06T00:00:00Z", dsun: 213.187379, hgln: 0.000000, hglt: 3.915152 },
  { isoDate: "2019-11-07T00:00:00Z", dsun: 213.132716, hgln: -0.000000, hglt: 3.807868 },
  { isoDate: "2019-11-08T00:00:00Z", dsun: 213.078736, hgln: 0.000000, hglt: 3.699376 },
  { isoDate: "2019-11-09T00:00:00Z", dsun: 213.025497, hgln: -0.000000, hglt: 3.589706 },
  { isoDate: "2019-11-10T00:00:00Z", dsun: 212.973052, hgln: -0.000000, hglt: 3.478890 },
  { isoDate: "2019-11-11T00:00:00Z", dsun: 212.921449, hgln: 0.000000, hglt: 3.366959 },
  { isoDate: "2019-11-12T00:00:00Z", dsun: 212.870730, hgln: 0.000000, hglt: 3.253947 },
  { isoDate: "2019-11-13T00:00:00Z", dsun: 212.820927, hgln: 0.000000, hglt: 3.139886 },
  { isoDate: "2019-11-14T00:00:00Z", dsun: 212.772062, hgln: -0.000000, hglt: 3.024809 },
  { isoDate: "2019-11-15T00:00:00Z", dsun: 212.724145, hgln: -0.000000, hglt: 2.908752 },
  { isoDate: "2019-11-16T00:00:00Z", dsun: 212.677174, hgln: 0.000000, hglt: 2.791748 },
  { isoDate: "2019-11-17T00:00:00Z", dsun: 212.631132, hgln: -0.000000, hglt: 2.673833 },
  { isoDate: "2019-11-18T00:00:00Z", dsun: 212.585991, hgln: 0.000000, hglt: 2.555043 },
  { isoDate: "2019-11-19T00:00:00Z", dsun: 212.541709, hgln: -0.000000, hglt: 2.435416 },
  { isoDate: "2019-11-20T00:00:00Z", dsun: 212.498237, hgln: -0.000000, hglt: 2.314988 },
  { isoDate: "2019-11-21T00:00:00Z", dsun: 212.455516, hgln: 0.000000, hglt: 2.193797 },
  { isoDate: "2019-11-22T00:00:00Z", dsun: 212.413487, hgln: -0.000000, hglt: 2.071883 },
  { isoDate: "2019-11-23T00:00:00Z", dsun: 212.372091, hgln: 0.000000, hglt: 1.949284 },
  { isoDate: "2019-11-24T00:00:00Z", dsun: 212.331278, hgln: -0.000000, hglt: 1.826039 },
  { isoDate: "2019-11-25T00:00:00Z", dsun: 212.291010, hgln: -0.000000, hglt: 1.702188 },
  { isoDate: "2019-11-26T00:00:00Z", dsun: 212.251264, hgln: 0.000000, hglt: 1.577771 },
  { isoDate: "2019-11-27T00:00:00Z", dsun: 212.212040, hgln: -0.000000, hglt: 1.452828 },
  { isoDate: "2019-11-28T00:00:00Z", dsun: 212.173354, hgln: -0.000000, hglt: 1.327397 },
  { isoDate: "2019-11-29T00:00:00Z", dsun: 212.135239, hgln: 0.000000, hglt: 1.201519 },
  { isoDate: "2019-11-30T00:00:00Z", dsun: 212.097743, hgln: -0.000000, hglt: 1.075232 },
  { isoDate: "2019-12-01T00:00:00Z", dsun: 212.060921, hgln: 0.000000, hglt: 0.948576 },
  { isoDate: "2019-12-02T00:00:00Z", dsun: 212.024832, hgln: 0.000000, hglt: 0.821590 },
  { isoDate: "2019-12-03T00:00:00Z", dsun: 211.989539, hgln: 0.000000, hglt: 0.694314 },
  { isoDate: "2019-12-04T00:00:00Z", dsun: 211.955104, hgln: -0.000000, hglt: 0.566786 },
  { isoDate: "2019-12-05T00:00:00Z", dsun: 211.921588, hgln: -0.000000, hglt: 0.439047 },
  { isoDate: "2019-12-06T00:00:00Z", dsun: 211.889051, hgln: 0.000000, hglt: 0.311135 },
  { isoDate: "2019-12-07T00:00:00Z", dsun: 211.857552, hgln: 0.000000, hglt: 0.183092 },
  { isoDate: "2019-12-08T00:00:00Z", dsun: 211.827147, hgln: 0.000000, hglt: 0.054956 },
  { isoDate: "2019-12-09T00:00:00Z", dsun: 211.797893, hgln: -0.000000, hglt: -0.073232 },
  { isoDate: "2019-12-10T00:00:00Z", dsun: 211.769838, hgln: -0.000000, hglt: -0.201432 },
  { isoDate: "2019-12-11T00:00:00Z", dsun: 211.743028, hgln: -0.000000, hglt: -0.329604 },
  { isoDate: "2019-12-12T00:00:00Z", dsun: 211.717499, hgln: 0.000000, hglt: -0.457708 },
  { isoDate: "2019-12-13T00:00:00Z", dsun: 211.693277, hgln: 0.000000, hglt: -0.585703 },
  { isoDate: "2019-12-14T00:00:00Z", dsun: 211.670373, hgln: -0.000000, hglt: -0.713548 },
  { isoDate: "2019-12-15T00:00:00Z", dsun: 211.648780, hgln: -0.000000, hglt: -0.841203 },
  { isoDate: "2019-12-16T00:00:00Z", dsun: 211.628478, hgln: -0.000000, hglt: -0.968627 },
  { isoDate: "2019-12-17T00:00:00Z", dsun: 211.609428, hgln: -0.000000, hglt: -1.095778 },
  { isoDate: "2019-12-18T00:00:00Z", dsun: 211.591578, hgln: 0.000000, hglt: -1.222615 },
  { isoDate: "2019-12-19T00:00:00Z", dsun: 211.574867, hgln: -0.000000, hglt: -1.349097 },
  { isoDate: "2019-12-20T00:00:00Z", dsun: 211.559229, hgln: 0.000000, hglt: -1.475183 },
  { isoDate: "2019-12-21T00:00:00Z", dsun: 211.544593, hgln: 0.000000, hglt: -1.600831 },
  { isoDate: "2019-12-22T00:00:00Z", dsun: 211.530896, hgln: -0.000000, hglt: -1.726001 },
  { isoDate: "2019-12-23T00:00:00Z", dsun: 211.518081, hgln: -0.000000, hglt: -1.850650 },
  { isoDate: "2019-12-24T00:00:00Z", dsun: 211.506102, hgln: -0.000000, hglt: -1.974738 },
  { isoDate: "2019-12-25T00:00:00Z", dsun: 211.494928, hgln: 0.000000, hglt: -2.098225 },
  { isoDate: "2019-12-26T00:00:00Z", dsun: 211.484543, hgln: 0.000000, hglt: -2.221069 },
  { isoDate: "2019-12-27T00:00:00Z", dsun: 211.474947, hgln: 0.000000, hglt: -2.343232 },
  { isoDate: "2019-12-28T00:00:00Z", dsun: 211.466154, hgln: 0.000000, hglt: -2.464673 },
  { isoDate: "2019-12-29T00:00:00Z", dsun: 211.458188, hgln: 0.000000, hglt: -2.585352 },
  { isoDate: "2019-12-30T00:00:00Z", dsun: 211.451086, hgln: -0.000000, hglt: -2.705231 },
  { isoDate: "2019-12-31T00:00:00Z", dsun: 211.444886, hgln: -0.000000, hglt: -2.824271 },
  { isoDate: "2020-01-01T00:00:00Z", dsun: 211.439633, hgln: -0.000000, hglt: -2.942433 },
  { isoDate: "2020-01-02T00:00:00Z", dsun: 211.435375, hgln: -0.000000, hglt: -3.059678 },
  { isoDate: "2020-01-03T00:00:00Z", dsun: 211.432159, hgln: 0.000000, hglt: -3.175969 },
  { isoDate: "2020-01-04T00:00:00Z", dsun: 211.430036, hgln: 0.000000, hglt: -3.291268 },
  { isoDate: "2020-01-05T00:00:00Z", dsun: 211.429057, hgln: 0.000000, hglt: -3.405537 },
  { isoDate: "2020-01-06T00:00:00Z", dsun: 211.429272, hgln: 0.000000, hglt: -3.518739 },
  { isoDate: "2020-01-07T00:00:00Z", dsun: 211.430733, hgln: -0.000000, hglt: -3.630837 },
  { isoDate: "2020-01-08T00:00:00Z", dsun: 211.433490, hgln: -0.000000, hglt: -3.741795 },
  { isoDate: "2020-01-09T00:00:00Z", dsun: 211.437588, hgln: -0.000000, hglt: -3.851576 },
  { isoDate: "2020-01-10T00:00:00Z", dsun: 211.443067, hgln: 0.000000, hglt: -3.960144 },
  { isoDate: "2020-01-11T00:00:00Z", dsun: 211.449953, hgln: 0.000000, hglt: -4.067464 },
  { isoDate: "2020-01-12T00:00:00Z", dsun: 211.458256, hgln: -0.000000, hglt: -4.173501 },
  { isoDate: "2020-01-13T00:00:00Z", dsun: 211.467966, hgln: -0.000000, hglt: -4.278220 },
  { isoDate: "2020-01-14T00:00:00Z", dsun: 211.479054, hgln: -0.000000, hglt: -4.381587 },
  { isoDate: "2020-01-15T00:00:00Z", dsun: 211.491472, hgln: -0.000000, hglt: -4.483568 },
  { isoDate: "2020-01-16T00:00:00Z", dsun: 211.505154, hgln: -0.000000, hglt: -4.584131 },
  { isoDate: "2020-01-17T00:00:00Z", dsun: 211.520027, hgln: 0.000000, hglt: -4.683243 },
  { isoDate: "2020-01-18T00:00:00Z", dsun: 211.536013, hgln: 0.000000, hglt: -4.780872 },
  { isoDate: "2020-01-19T00:00:00Z", dsun: 211.553034, hgln: 0.000000, hglt: -4.876987 },
  { isoDate: "2020-01-20T00:00:00Z", dsun: 211.571017, hgln: 0.000000, hglt: -4.971557 },
  { isoDate: "2020-01-21T00:00:00Z", dsun: 211.589897, hgln: 0.000000, hglt: -5.064553 },
  { isoDate: "2020-01-22T00:00:00Z", dsun: 211.609621, hgln: -0.000000, hglt: -5.155945 },
  { isoDate: "2020-01-23T00:00:00Z", dsun: 211.630145, hgln: 0.000000, hglt: -5.245703 },
  { isoDate: "2020-01-24T00:00:00Z", dsun: 211.651441, hgln: 0.000000, hglt: -5.333800 },
  { isoDate: "2020-01-25T00:00:00Z", dsun: 211.673490, hgln: -0.000000, hglt: -5.420207 },
  { isoDate: "2020-01-26T00:00:00Z", dsun: 211.696286, hgln: -0.000000, hglt: -5.504898 },
  { isoDate: "2020-01-27T00:00:00Z", dsun: 211.719833, hgln: -0.000000, hglt: -5.587846 },
  { isoDate: "2020-01-28T00:00:00Z", dsun: 211.744144, hgln: 0.000000, hglt: -5.669024 },
  { isoDate: "2020-01-29T00:00:00Z", dsun: 211.769240, hgln: -0.000000, hglt: -5.748407 },
  { isoDate: "2020-01-30T00:00:00Z", dsun: 211.795145, hgln: 0.000000, hglt: -5.825969 },
  { isoDate: "2020-01-31T00:00:00Z", dsun: 211.821890, hgln: -0.000000, hglt: -5.901687 },
  { isoDate: "2020-02-01T00:00:00Z", dsun: 211.849510, hgln: 0.000000, hglt: -5.975537 },
  { isoDate: "2020-02-02T00:00:00Z", dsun: 211.878040, hgln: -0.000000, hglt: -6.047494 },
  { isoDate: "2020-02-03T00:00:00Z", dsun: 211.907523, hgln: -0.000000, hglt: -6.117537 },
  { isoDate: "2020-02-04T00:00:00Z", dsun: 211.938002, hgln: 0.000000, hglt: -6.185644 },
  { isoDate: "2020-02-05T00:00:00Z", dsun: 211.969525, hgln: -0.000000, hglt: -6.251791 },
  { isoDate: "2020-02-06T00:00:00Z", dsun: 212.002138, hgln: -0.000000, hglt: -6.315960 },
  { isoDate: "2020-02-07T00:00:00Z", dsun: 212.035889, hgln: 0.000000, hglt: -6.378128 },
  { isoDate: "2020-02-08T00:00:00Z", dsun: 212.070818, hgln: -0.000000, hglt: -6.438277 },
  { isoDate: "2020-02-09T00:00:00Z", dsun: 212.106952, hgln: 0.000000, hglt: -6.496388 },
  { isoDate: "2020-02-10T00:00:00Z", dsun: 212.144304, hgln: -0.000000, hglt: -6.552442 },
  { isoDate: "2020-02-11T00:00:00Z", dsun: 212.182862, hgln: 0.000000, hglt: -6.606423 },
  { isoDate: "2020-02-12T00:00:00Z", dsun: 212.222590, hgln: -0.000000, hglt: -6.658314 },
  { isoDate: "2020-02-13T00:00:00Z", dsun: 212.263433, hgln: 0.000000, hglt: -6.708101 },
  { isoDate: "2020-02-14T00:00:00Z", dsun: 212.305315, hgln: 0.000000, hglt: -6.755770 },
  { isoDate: "2020-02-15T00:00:00Z", dsun: 212.348154, hgln: -0.000000, hglt: -6.801308 },
  { isoDate: "2020-02-16T00:00:00Z", dsun: 212.391864, hgln: -0.000000, hglt: -6.844704 },
  { isoDate: "2020-02-17T00:00:00Z", dsun: 212.436358, hgln: -0.000000, hglt: -6.885948 },
  { isoDate: "2020-02-18T00:00:00Z", dsun: 212.481559, hgln: 0.000000, hglt: -6.925029 },
  { isoDate: "2020-02-19T00:00:00Z", dsun: 212.527395, hgln: -0.000000, hglt: -6.961937 },
  { isoDate: "2020-02-20T00:00:00Z", dsun: 212.573805, hgln: -0.000000, hglt: -6.996666 },
  { isoDate: "2020-02-21T00:00:00Z", dsun: 212.620735, hgln: -0.000000, hglt: -7.029206 },
  { isoDate: "2020-02-22T00:00:00Z", dsun: 212.668144, hgln: 0.000000, hglt: -7.059552 },
  { isoDate: "2020-02-23T00:00:00Z", dsun: 212.716000, hgln: -0.000000, hglt: -7.087696 },
  { isoDate: "2020-02-24T00:00:00Z", dsun: 212.764280, hgln: -0.000000, hglt: -7.113633 },
  { isoDate: "2020-02-25T00:00:00Z", dsun: 212.812971, hgln: 0.000000, hglt: -7.137358 },
  { isoDate: "2020-02-26T00:00:00Z", dsun: 212.862068, hgln: 0.000000, hglt: -7.158866 },
  { isoDate: "2020-02-27T00:00:00Z", dsun: 212.911573, hgln: 0.000000, hglt: -7.178154 },
  { isoDate: "2020-02-28T00:00:00Z", dsun: 212.961496, hgln: 0.000000, hglt: -7.195218 },
  { isoDate: "2020-02-29T00:00:00Z", dsun: 213.011853, hgln: 0.000000, hglt: -7.210056 },
  { isoDate: "2020-03-01T00:00:00Z", dsun: 213.062666, hgln: -0.000000, hglt: -7.222666 },
  { isoDate: "2020-03-02T00:00:00Z", dsun: 213.113962, hgln: -0.000000, hglt: -7.233045 },
  { isoDate: "2020-03-03T00:00:00Z", dsun: 213.165774, hgln: -0.000000, hglt: -7.241195 },
  { isoDate: "2020-03-04T00:00:00Z", dsun: 213.218142, hgln: -0.000000, hglt: -7.247113 },
  { isoDate: "2020-03-05T00:00:00Z", dsun: 213.271110, hgln: 0.000000, hglt: -7.250800 },
  { isoDate: "2020-03-06T00:00:00Z", dsun: 213.324724, hgln: -0.000000, hglt: -7.252258 },
  { isoDate: "2020-03-07T00:00:00Z", dsun: 213.379032, hgln: 0.000000, hglt: -7.251487 },
  { isoDate: "2020-03-08T00:00:00Z", dsun: 213.434080, hgln: -0.000000, hglt: -7.248490 },
  { isoDate: "2020-03-09T00:00:00Z", dsun: 213.489898, hgln: 0.000000, hglt: -7.243269 },
  { isoDate: "2020-03-10T00:00:00Z", dsun: 213.546502, hgln: -0.000000, hglt: -7.235828 },
  { isoDate: "2020-03-11T00:00:00Z", dsun: 213.603882, hgln: -0.000000, hglt: -7.226172 },
  { isoDate: "2020-03-12T00:00:00Z", dsun: 213.662003, hgln: -0.000000, hglt: -7.214306 },
  { isoDate: "2020-03-13T00:00:00Z", dsun: 213.720808, hgln: 0.000000, hglt: -7.200240 },
  { isoDate: "2020-03-14T00:00:00Z", dsun: 213.780219, hgln: -0.000000, hglt: -7.183980 },
  { isoDate: "2020-03-15T00:00:00Z", dsun: 213.840151, hgln: 0.000000, hglt: -7.165538 },
  { isoDate: "2020-03-16T00:00:00Z", dsun: 213.900514, hgln: -0.000000, hglt: -7.144923 },
  { isoDate: "2020-03-17T00:00:00Z", dsun: 213.961221, hgln: 0.000000, hglt: -7.122148 },
  { isoDate: "2020-03-18T00:00:00Z", dsun: 214.022192, hgln: -0.000000, hglt: -7.097224 },
  { isoDate: "2020-03-19T00:00:00Z", dsun: 214.083352, hgln: 0.000000, hglt: -7.070166 },
  { isoDate: "2020-03-20T00:00:00Z", dsun: 214.144633, hgln: 0.000000, hglt: -7.040986 },
  { isoDate: "2020-03-21T00:00:00Z", dsun: 214.205979, hgln: 0.000000, hglt: -7.009698 },
  { isoDate: "2020-03-22T00:00:00Z", dsun: 214.267339, hgln: 0.000000, hglt: -6.976317 },
  { isoDate: "2020-03-23T00:00:00Z", dsun: 214.328671, hgln: 0.000000, hglt: -6.940858 },
  { isoDate: "2020-03-24T00:00:00Z", dsun: 214.389941, hgln: 0.000000, hglt: -6.903337 },
  { isoDate: "2020-03-25T00:00:00Z", dsun: 214.451123, hgln: 0.000000, hglt: -6.863770 },
  { isoDate: "2020-03-26T00:00:00Z", dsun: 214.512199, hgln: 0.000000, hglt: -6.822173 },
  { isoDate: "2020-03-27T00:00:00Z", dsun: 214.573162, hgln: 0.000000, hglt: -6.778563 },
  { isoDate: "2020-03-28T00:00:00Z", dsun: 214.634009, hgln: -0.000000, hglt: -6.732956 },
  { isoDate: "2020-03-29T00:00:00Z", dsun: 214.694748, hgln: -0.000000, hglt: -6.685372 },
  { isoDate: "2020-03-30T00:00:00Z", dsun: 214.755393, hgln: 0.000000, hglt: -6.635827 },
  { isoDate: "2020-03-31T00:00:00Z", dsun: 214.815967, hgln: -0.000000, hglt: -6.584340 },
  { isoDate: "2020-04-01T00:00:00Z", dsun: 214.876501, hgln: -0.000000, hglt: -6.530929 },
  { isoDate: "2020-04-02T00:00:00Z", dsun: 214.937033, hgln: 0.000000, hglt: -6.475613 },
  { isoDate: "2020-04-03T00:00:00Z", dsun: 214.997607, hgln: 0.000000, hglt: -6.418411 },
  { isoDate: "2020-04-04T00:00:00Z", dsun: 215.058275, hgln: -0.000000, hglt: -6.359343 },
  { isoDate: "2020-04-05T00:00:00Z", dsun: 215.119088, hgln: 0.000000, hglt: -6.298427 },
  { isoDate: "2020-04-06T00:00:00Z", dsun: 215.180096, hgln: 0.000000, hglt: -6.235684 },
  { isoDate: "2020-04-07T00:00:00Z", dsun: 215.241337, hgln: -0.000000, hglt: -6.171133 },
  { isoDate: "2020-04-08T00:00:00Z", dsun: 215.302831, hgln: 0.000000, hglt: -6.104797 },
  { isoDate: "2020-04-09T00:00:00Z", dsun: 215.364574, hgln: -0.000000, hglt: -6.036697 },
  { isoDate: "2020-04-10T00:00:00Z", dsun: 215.426538, hgln: -0.000000, hglt: -5.966855 },
  { isoDate: "2020-04-11T00:00:00Z", dsun: 215.488669, hgln: -0.000000, hglt: -5.895297 },
  { isoDate: "2020-04-12T00:00:00Z", dsun: 215.550896, hgln: 0.000000, hglt: -5.822046 },
  { isoDate: "2020-04-13T00:00:00Z", dsun: 215.613139, hgln: 0.000000, hglt: -5.747128 },
  { isoDate: "2020-04-14T00:00:00Z", dsun: 215.675314, hgln: -0.000000, hglt: -5.670570 },
  { isoDate: "2020-04-15T00:00:00Z", dsun: 215.737337, hgln: 0.000000, hglt: -5.592399 },
  { isoDate: "2020-04-16T00:00:00Z", dsun: 215.799129, hgln: 0.000000, hglt: -5.512642 },
  { isoDate: "2020-04-17T00:00:00Z", dsun: 215.860617, hgln: -0.000000, hglt: -5.431326 },
  { isoDate: "2020-04-18T00:00:00Z", dsun: 215.921734, hgln: 0.000000, hglt: -5.348480 },
  { isoDate: "2020-04-19T00:00:00Z", dsun: 215.982421, hgln: 0.000000, hglt: -5.264132 },
  { isoDate: "2020-04-20T00:00:00Z", dsun: 216.042622, hgln: 0.000000, hglt: -5.178309 },
  { isoDate: "2020-04-21T00:00:00Z", dsun: 216.102291, hgln: -0.000000, hglt: -5.091041 },
  { isoDate: "2020-04-22T00:00:00Z", dsun: 216.161387, hgln: 0.000000, hglt: -5.002356 },
  { isoDate: "2020-04-23T00:00:00Z", dsun: 216.219878, hgln: 0.000000, hglt: -4.912284 },
  { isoDate: "2020-04-24T00:00:00Z", dsun: 216.277740, hgln: 0.000000, hglt: -4.820852 },
  { isoDate: "2020-04-25T00:00:00Z", dsun: 216.334955, hgln: 0.000000, hglt: -4.728091 },
  { isoDate: "2020-04-26T00:00:00Z", dsun: 216.391518, hgln: -0.000000, hglt: -4.634029 },
  { isoDate: "2020-04-27T00:00:00Z", dsun: 216.447433, hgln: -0.000000, hglt: -4.538696 },
  { isoDate: "2020-04-28T00:00:00Z", dsun: 216.502712, hgln: -0.000000, hglt: -4.442121 },
  { isoDate: "2020-04-29T00:00:00Z", dsun: 216.557380, hgln: 0.000000, hglt: -4.344333 },
  { isoDate: "2020-04-30T00:00:00Z", dsun: 216.611470, hgln: -0.000000, hglt: -4.245362 },
  { isoDate: "2020-05-01T00:00:00Z", dsun: 216.665024, hgln: 0.000000, hglt: -4.145236 },
  { isoDate: "2020-05-02T00:00:00Z", dsun: 216.718094, hgln: -0.000000, hglt: -4.043986 },
  { isoDate: "2020-05-03T00:00:00Z", dsun: 216.770737, hgln: 0.000000, hglt: -3.941639 },
  { isoDate: "2020-05-04T00:00:00Z", dsun: 216.823011, hgln: 0.000000, hglt: -3.838225 },
  { isoDate: "2020-05-05T00:00:00Z", dsun: 216.874971, hgln: 0.000000, hglt: -3.733774 },
  { isoDate: "2020-05-06T00:00:00Z", dsun: 216.926663, hgln: -0.000000, hglt: -3.628315 },
  { isoDate: "2020-05-07T00:00:00Z", dsun: 216.978112, hgln: -0.000000, hglt: -3.521877 },
  { isoDate: "2020-05-08T00:00:00Z", dsun: 217.029322, hgln: 0.000000, hglt: -3.414490 },
  { isoDate: "2020-05-09T00:00:00Z", dsun: 217.080274, hgln: -0.000000, hglt: -3.306186 },
  { isoDate: "2020-05-10T00:00:00Z", dsun: 217.130927, hgln: 0.000000, hglt: -3.196996 },
  { isoDate: "2020-05-11T00:00:00Z", dsun: 217.181220, hgln: 0.000000, hglt: -3.086951 },
  { isoDate: "2020-05-12T00:00:00Z", dsun: 217.231086, hgln: 0.000000, hglt: -2.976084 },
  { isoDate: "2020-05-13T00:00:00Z", dsun: 217.280451, hgln: -0.000000, hglt: -2.864427 },
  { isoDate: "2020-05-14T00:00:00Z", dsun: 217.329241, hgln: -0.000000, hglt: -2.752012 },
  { isoDate: "2020-05-15T00:00:00Z", dsun: 217.377384, hgln: 0.000000, hglt: -2.638874 },
  { isoDate: "2020-05-16T00:00:00Z", dsun: 217.424812, hgln: -0.000000, hglt: -2.525045 },
  { isoDate: "2020-05-17T00:00:00Z", dsun: 217.471462, hgln: -0.000000, hglt: -2.410557 },
  { isoDate: "2020-05-18T00:00:00Z", dsun: 217.517275, hgln: -0.000000, hglt: -2.295444 },
  { isoDate: "2020-05-19T00:00:00Z", dsun: 217.562197, hgln: 0.000000, hglt: -2.179739 },
  { isoDate: "2020-05-20T00:00:00Z", dsun: 217.606178, hgln: -0.000000, hglt: -2.063474 },
  { isoDate: "2020-05-21T00:00:00Z", dsun: 217.649176, hgln: -0.000000, hglt: -1.946684 },
  { isoDate: "2020-05-22T00:00:00Z", dsun: 217.691155, hgln: 0.000000, hglt: -1.829400 },
  { isoDate: "2020-05-23T00:00:00Z", dsun: 217.732085, hgln: -0.000000, hglt: -1.711655 },
  { isoDate: "2020-05-24T00:00:00Z", dsun: 217.771948, hgln: -0.000000, hglt: -1.593484 },
  { isoDate: "2020-05-25T00:00:00Z", dsun: 217.810736, hgln: -0.000000, hglt: -1.474917 },
  { isoDate: "2020-05-26T00:00:00Z", dsun: 217.848454, hgln: 0.000000, hglt: -1.355988 },
  { isoDate: "2020-05-27T00:00:00Z", dsun: 217.885118, hgln: 0.000000, hglt: -1.236730 },
  { isoDate: "2020-05-28T00:00:00Z", dsun: 217.920757, hgln: 0.000000, hglt: -1.117175 },
  { isoDate: "2020-05-29T00:00:00Z", dsun: 217.955413, hgln: 0.000000, hglt: -0.997354 },
  { isoDate: "2020-05-30T00:00:00Z", dsun: 217.989138, hgln: -0.000000, hglt: -0.877300 },
  { isoDate: "2020-05-31T00:00:00Z", dsun: 218.021991, hgln: 0.000000, hglt: -0.757045 },
  { isoDate: "2020-06-01T00:00:00Z", dsun: 218.054039, hgln: -0.000000, hglt: -0.636621 },
  { isoDate: "2020-06-02T00:00:00Z", dsun: 218.085347, hgln: 0.000000, hglt: -0.516058 },
  { isoDate: "2020-06-03T00:00:00Z", dsun: 218.115976, hgln: -0.000000, hglt: -0.395390 },
  { isoDate: "2020-06-04T00:00:00Z", dsun: 218.145977, hgln: 0.000000, hglt: -0.274646 },
  { isoDate: "2020-06-05T00:00:00Z", dsun: 218.175382, hgln: 0.000000, hglt: -0.153860 },
  { isoDate: "2020-06-06T00:00:00Z", dsun: 218.204204, hgln: 0.000000, hglt: -0.033062 },
  { isoDate: "2020-06-07T00:00:00Z", dsun: 218.232435, hgln: -0.000000, hglt: 0.087716 },
  { isoDate: "2020-06-08T00:00:00Z", dsun: 218.260047, hgln: 0.000000, hglt: 0.208442 },
  { isoDate: "2020-06-09T00:00:00Z", dsun: 218.286998, hgln: -0.000000, hglt: 0.329084 },
  { isoDate: "2020-06-10T00:00:00Z", dsun: 218.313236, hgln: -0.000000, hglt: 0.449610 },
  { isoDate: "2020-06-11T00:00:00Z", dsun: 218.338701, hgln: -0.000000, hglt: 0.569987 },
  { isoDate: "2020-06-12T00:00:00Z", dsun: 218.363333, hgln: 0.000000, hglt: 0.690184 },
  { isoDate: "2020-06-13T00:00:00Z", dsun: 218.387071, hgln: 0.000000, hglt: 0.810169 },
  { isoDate: "2020-06-14T00:00:00Z", dsun: 218.409857, hgln: 0.000000, hglt: 0.929909 },
  { isoDate: "2020-06-15T00:00:00Z", dsun: 218.431633, hgln: 0.000000, hglt: 1.049374 },
  { isoDate: "2020-06-16T00:00:00Z", dsun: 218.452344, hgln: -0.000000, hglt: 1.168530 },
  { isoDate: "2020-06-17T00:00:00Z", dsun: 218.471941, hgln: 0.000000, hglt: 1.287347 },
  { isoDate: "2020-06-18T00:00:00Z", dsun: 218.490372, hgln: 0.000000, hglt: 1.405793 },
  { isoDate: "2020-06-19T00:00:00Z", dsun: 218.507596, hgln: 0.000000, hglt: 1.523837 },
  { isoDate: "2020-06-20T00:00:00Z", dsun: 218.523573, hgln: 0.000000, hglt: 1.641447 },
  { isoDate: "2020-06-21T00:00:00Z", dsun: 218.538274, hgln: 0.000000, hglt: 1.758593 },
  { isoDate: "2020-06-22T00:00:00Z", dsun: 218.551678, hgln: 0.000000, hglt: 1.875243 },
  { isoDate: "2020-06-23T00:00:00Z", dsun: 218.563778, hgln: 0.000000, hglt: 1.991367 },
  { isoDate: "2020-06-24T00:00:00Z", dsun: 218.574582, hgln: 0.000000, hglt: 2.106933 },
  { isoDate: "2020-06-25T00:00:00Z", dsun: 218.584113, hgln: 0.000000, hglt: 2.221911 },
  { isoDate: "2020-06-26T00:00:00Z", dsun: 218.592408, hgln: 0.000000, hglt: 2.336272 },
  { isoDate: "2020-06-27T00:00:00Z", dsun: 218.599520, hgln: 0.000000, hglt: 2.449983 },
  { isoDate: "2020-06-28T00:00:00Z", dsun: 218.605513, hgln: -0.000000, hglt: 2.563017 },
  { isoDate: "2020-06-29T00:00:00Z", dsun: 218.610457, hgln: 0.000000, hglt: 2.675341 },
  { isoDate: "2020-06-30T00:00:00Z", dsun: 218.614426, hgln: 0.000000, hglt: 2.786927 },
  { isoDate: "2020-07-01T00:00:00Z", dsun: 218.617494, hgln: 0.000000, hglt: 2.897744 },
  { isoDate: "2020-07-02T00:00:00Z", dsun: 218.619725, hgln: 0.000000, hglt: 3.007765 },
  { isoDate: "2020-07-03T00:00:00Z", dsun: 218.621175, hgln: -0.000000, hglt: 3.116958 },
  { isoDate: "2020-07-04T00:00:00Z", dsun: 218.621883, hgln: 0.000000, hglt: 3.225295 },
  { isoDate: "2020-07-05T00:00:00Z", dsun: 218.621871, hgln: -0.000000, hglt: 3.332748 },
  { isoDate: "2020-07-06T00:00:00Z", dsun: 218.621143, hgln: 0.000000, hglt: 3.439287 },
  { isoDate: "2020-07-07T00:00:00Z", dsun: 218.619686, hgln: 0.000000, hglt: 3.544885 },
  { isoDate: "2020-07-08T00:00:00Z", dsun: 218.617476, hgln: -0.000000, hglt: 3.649514 },
  { isoDate: "2020-07-09T00:00:00Z", dsun: 218.614478, hgln: 0.000000, hglt: 3.753145 },
  { isoDate: "2020-07-10T00:00:00Z", dsun: 218.610651, hgln: 0.000000, hglt: 3.855750 },
  { isoDate: "2020-07-11T00:00:00Z", dsun: 218.605948, hgln: -0.000000, hglt: 3.957304 },
  { isoDate: "2020-07-12T00:00:00Z", dsun: 218.600323, hgln: -0.000000, hglt: 4.057777 },
  { isoDate: "2020-07-13T00:00:00Z", dsun: 218.593727, hgln: -0.000000, hglt: 4.157144 },
  { isoDate: "2020-07-14T00:00:00Z", dsun: 218.586112, hgln: -0.000000, hglt: 4.255378 },
  { isoDate: "2020-07-15T00:00:00Z", dsun: 218.577429, hgln: 0.000000, hglt: 4.352452 },
  { isoDate: "2020-07-16T00:00:00Z", dsun: 218.567630, hgln: -0.000000, hglt: 4.448340 },
  { isoDate: "2020-07-17T00:00:00Z", dsun: 218.556669, hgln: -0.000000, hglt: 4.543017 },
  { isoDate: "2020-07-18T00:00:00Z", dsun: 218.544501, hgln: -0.000000, hglt: 4.636457 },
  { isoDate: "2020-07-19T00:00:00Z", dsun: 218.531086, hgln: 0.000000, hglt: 4.728635 },
  { isoDate: "2020-07-20T00:00:00Z", dsun: 218.516393, hgln: -0.000000, hglt: 4.819525 },
  { isoDate: "2020-07-21T00:00:00Z", dsun: 218.500400, hgln: 0.000000, hglt: 4.909102 },
  { isoDate: "2020-07-22T00:00:00Z", dsun: 218.483101, hgln: 0.000000, hglt: 4.997343 },
  { isoDate: "2020-07-23T00:00:00Z", dsun: 218.464507, hgln: 0.000000, hglt: 5.084221 },
  { isoDate: "2020-07-24T00:00:00Z", dsun: 218.444647, hgln: 0.000000, hglt: 5.169713 },
  { isoDate: "2020-07-25T00:00:00Z", dsun: 218.423570, hgln: 0.000000, hglt: 5.253795 },
  { isoDate: "2020-07-26T00:00:00Z", dsun: 218.401340, hgln: 0.000000, hglt: 5.336441 },
  { isoDate: "2020-07-27T00:00:00Z", dsun: 218.378030, hgln: -0.000000, hglt: 5.417627 },
  { isoDate: "2020-07-28T00:00:00Z", dsun: 218.353721, hgln: 0.000000, hglt: 5.497329 },
  { isoDate: "2020-07-29T00:00:00Z", dsun: 218.328495, hgln: -0.000000, hglt: 5.575525 },
  { isoDate: "2020-07-30T00:00:00Z", dsun: 218.302430, hgln: -0.000000, hglt: 5.652189 },
  { isoDate: "2020-07-31T00:00:00Z", dsun: 218.275595, hgln: -0.000000, hglt: 5.727300 },
  { isoDate: "2020-08-01T00:00:00Z", dsun: 218.248049, hgln: -0.000000, hglt: 5.800834 },
  { isoDate: "2020-08-02T00:00:00Z", dsun: 218.219837, hgln: 0.000000, hglt: 5.872770 },
  { isoDate: "2020-08-03T00:00:00Z", dsun: 218.190990, hgln: 0.000000, hglt: 5.943085 },
  { isoDate: "2020-08-04T00:00:00Z", dsun: 218.161524, hgln: -0.000000, hglt: 6.011759 },
  { isoDate: "2020-08-05T00:00:00Z", dsun: 218.131444, hgln: 0.000000, hglt: 6.078771 },
  { isoDate: "2020-08-06T00:00:00Z", dsun: 218.100740, hgln: -0.000000, hglt: 6.144102 },
  { isoDate: "2020-08-07T00:00:00Z", dsun: 218.069396, hgln: 0.000000, hglt: 6.207731 },
  { isoDate: "2020-08-08T00:00:00Z", dsun: 218.037388, hgln: -0.000000, hglt: 6.269639 },
  { isoDate: "2020-08-09T00:00:00Z", dsun: 218.004684, hgln: 0.000000, hglt: 6.329809 },
  { isoDate: "2020-08-10T00:00:00Z", dsun: 217.971252, hgln: 0.000000, hglt: 6.388223 },
  { isoDate: "2020-08-11T00:00:00Z", dsun: 217.937055, hgln: 0.000000, hglt: 6.444862 },
  { isoDate: "2020-08-12T00:00:00Z", dsun: 217.902054, hgln: -0.000000, hglt: 6.499709 },
  { isoDate: "2020-08-13T00:00:00Z", dsun: 217.866206, hgln: 0.000000, hglt: 6.552749 },
  { isoDate: "2020-08-14T00:00:00Z", dsun: 217.829469, hgln: -0.000000, hglt: 6.603966 },
  { isoDate: "2020-08-15T00:00:00Z", dsun: 217.791798, hgln: -0.000000, hglt: 6.653343 },
  { isoDate: "2020-08-16T00:00:00Z", dsun: 217.753148, hgln: 0.000000, hglt: 6.700866 },
  { isoDate: "2020-08-17T00:00:00Z", dsun: 217.713478, hgln: -0.000000, hglt: 6.746520 },
  { isoDate: "2020-08-18T00:00:00Z", dsun: 217.672752, hgln: 0.000000, hglt: 6.790291 },
  { isoDate: "2020-08-19T00:00:00Z", dsun: 217.630948, hgln: 0.000000, hglt: 6.832166 },
  { isoDate: "2020-08-20T00:00:00Z", dsun: 217.588057, hgln: -0.000000, hglt: 6.872131 },
  { isoDate: "2020-08-21T00:00:00Z", dsun: 217.544095, hgln: -0.000000, hglt: 6.910172 },
  { isoDate: "2020-08-22T00:00:00Z", dsun: 217.499097, hgln: -0.000000, hglt: 6.946276 },
  { isoDate: "2020-08-23T00:00:00Z", dsun: 217.453119, hgln: -0.000000, hglt: 6.980429 },
  { isoDate: "2020-08-24T00:00:00Z", dsun: 217.406235, hgln: -0.000000, hglt: 7.012619 },
  { isoDate: "2020-08-25T00:00:00Z", dsun: 217.358527, hgln: -0.000000, hglt: 7.042831 },
  { isoDate: "2020-08-26T00:00:00Z", dsun: 217.310084, hgln: -0.000000, hglt: 7.071055 },
  { isoDate: "2020-08-27T00:00:00Z", dsun: 217.260991, hgln: 0.000000, hglt: 7.097277 },
  { isoDate: "2020-08-28T00:00:00Z", dsun: 217.211329, hgln: -0.000000, hglt: 7.121485 },
  { isoDate: "2020-08-29T00:00:00Z", dsun: 217.161172, hgln: -0.000000, hglt: 7.143670 },
  { isoDate: "2020-08-30T00:00:00Z", dsun: 217.110582, hgln: 0.000000, hglt: 7.163819 },
  { isoDate: "2020-08-31T00:00:00Z", dsun: 217.059611, hgln: -0.000000, hglt: 7.181925 },
  { isoDate: "2020-09-01T00:00:00Z", dsun: 217.008297, hgln: -0.000000, hglt: 7.197977 },
  { isoDate: "2020-09-02T00:00:00Z", dsun: 216.956669, hgln: -0.000000, hglt: 7.211968 },
  { isoDate: "2020-09-03T00:00:00Z", dsun: 216.904745, hgln: 0.000000, hglt: 7.223890 },
  { isoDate: "2020-09-04T00:00:00Z", dsun: 216.852531, hgln: 0.000000, hglt: 7.233737 },
  { isoDate: "2020-09-05T00:00:00Z", dsun: 216.800027, hgln: -0.000000, hglt: 7.241503 },
  { isoDate: "2020-09-06T00:00:00Z", dsun: 216.747222, hgln: 0.000000, hglt: 7.247183 },
  { isoDate: "2020-09-07T00:00:00Z", dsun: 216.694103, hgln: -0.000000, hglt: 7.250772 },
  { isoDate: "2020-09-08T00:00:00Z", dsun: 216.640646, hgln: -0.000000, hglt: 7.252268 },
  { isoDate: "2020-09-09T00:00:00Z", dsun: 216.586827, hgln: -0.000000, hglt: 7.251666 },
  { isoDate: "2020-09-10T00:00:00Z", dsun: 216.532615, hgln: -0.000000, hglt: 7.248965 },
  { isoDate: "2020-09-11T00:00:00Z", dsun: 216.477974, hgln: 0.000000, hglt: 7.244164 },
  { isoDate: "2020-09-12T00:00:00Z", dsun: 216.422865, hgln: -0.000000, hglt: 7.237262 },
  { isoDate: "2020-09-13T00:00:00Z", dsun: 216.367244, hgln: 0.000000, hglt: 7.228258 },
  { isoDate: "2020-09-14T00:00:00Z", dsun: 216.311067, hgln: -0.000000, hglt: 7.217154 },
  { isoDate: "2020-09-15T00:00:00Z", dsun: 216.254287, hgln: -0.000000, hglt: 7.203952 },
  { isoDate: "2020-09-16T00:00:00Z", dsun: 216.196867, hgln: -0.000000, hglt: 7.188652 },
  { isoDate: "2020-09-17T00:00:00Z", dsun: 216.138779, hgln: -0.000000, hglt: 7.171259 },
  { isoDate: "2020-09-18T00:00:00Z", dsun: 216.080013, hgln: 0.000000, hglt: 7.151773 },
  { isoDate: "2020-09-19T00:00:00Z", dsun: 216.020583, hgln: -0.000000, hglt: 7.130200 },
  { isoDate: "2020-09-20T00:00:00Z", dsun: 215.960528, hgln: 0.000000, hglt: 7.106541 },
  { isoDate: "2020-09-21T00:00:00Z", dsun: 215.899908, hgln: 0.000000, hglt: 7.080799 },
  { isoDate: "2020-09-22T00:00:00Z", dsun: 215.838801, hgln: 0.000000, hglt: 7.052979 },
  { isoDate: "2020-09-23T00:00:00Z", dsun: 215.777294, hgln: 0.000000, hglt: 7.023083 },
  { isoDate: "2020-09-24T00:00:00Z", dsun: 215.715478, hgln: 0.000000, hglt: 6.991116 },
  { isoDate: "2020-09-25T00:00:00Z", dsun: 215.653441, hgln: 0.000000, hglt: 6.957082 },
  { isoDate: "2020-09-26T00:00:00Z", dsun: 215.591265, hgln: -0.000000, hglt: 6.920987 },
  { isoDate: "2020-09-27T00:00:00Z", dsun: 215.529025, hgln: 0.000000, hglt: 6.882836 },
  { isoDate: "2020-09-28T00:00:00Z", dsun: 215.466786, hgln: 0.000000, hglt: 6.842638 },
  { isoDate: "2020-09-29T00:00:00Z", dsun: 215.404604, hgln: -0.000000, hglt: 6.800398 },
  { isoDate: "2020-09-30T00:00:00Z", dsun: 215.342527, hgln: 0.000000, hglt: 6.756127 },
  { isoDate: "2020-10-01T00:00:00Z", dsun: 215.280593, hgln: 0.000000, hglt: 6.709833 },
  { isoDate: "2020-10-02T00:00:00Z", dsun: 215.218829, hgln: 0.000000, hglt: 6.661527 },
  { isoDate: "2020-10-03T00:00:00Z", dsun: 215.157256, hgln: 0.000000, hglt: 6.611219 },
  { isoDate: "2020-10-04T00:00:00Z", dsun: 215.095884, hgln: 0.000000, hglt: 6.558921 },
  { isoDate: "2020-10-05T00:00:00Z", dsun: 215.034716, hgln: -0.000000, hglt: 6.504646 },
  { isoDate: "2020-10-06T00:00:00Z", dsun: 214.973749, hgln: 0.000000, hglt: 6.448408 },
  { isoDate: "2020-10-07T00:00:00Z", dsun: 214.912970, hgln: -0.000000, hglt: 6.390221 },
  { isoDate: "2020-10-08T00:00:00Z", dsun: 214.852361, hgln: 0.000000, hglt: 6.330100 },
  { isoDate: "2020-10-09T00:00:00Z", dsun: 214.791898, hgln: 0.000000, hglt: 6.268061 },
  { isoDate: "2020-10-10T00:00:00Z", dsun: 214.731548, hgln: 0.000000, hglt: 6.204120 },
  { isoDate: "2020-10-11T00:00:00Z", dsun: 214.671274, hgln: -0.000000, hglt: 6.138295 },
  { isoDate: "2020-10-12T00:00:00Z", dsun: 214.611031, hgln: 0.000000, hglt: 6.070604 },
  { isoDate: "2020-10-13T00:00:00Z", dsun: 214.550771, hgln: 0.000000, hglt: 6.001067 },
  { isoDate: "2020-10-14T00:00:00Z", dsun: 214.490445, hgln: 0.000000, hglt: 5.929702 },
  { isoDate: "2020-10-15T00:00:00Z", dsun: 214.430008, hgln: 0.000000, hglt: 5.856531 },
  { isoDate: "2020-10-16T00:00:00Z", dsun: 214.369426, hgln: 0.000000, hglt: 5.781575 },
  { isoDate: "2020-10-17T00:00:00Z", dsun: 214.308685, hgln: 0.000000, hglt: 5.704853 },
  { isoDate: "2020-10-18T00:00:00Z", dsun: 214.247794, hgln: 0.000000, hglt: 5.626389 },
  { isoDate: "2020-10-19T00:00:00Z", dsun: 214.186787, hgln: -0.000000, hglt: 5.546204 },
  { isoDate: "2020-10-20T00:00:00Z", dsun: 214.125723, hgln: -0.000000, hglt: 5.464318 },
  { isoDate: "2020-10-21T00:00:00Z", dsun: 214.064677, hgln: -0.000000, hglt: 5.380754 },
  { isoDate: "2020-10-22T00:00:00Z", dsun: 214.003734, hgln: 0.000000, hglt: 5.295534 },
  { isoDate: "2020-10-23T00:00:00Z", dsun: 213.942981, hgln: 0.000000, hglt: 5.208680 },
  { isoDate: "2020-10-24T00:00:00Z", dsun: 213.882504, hgln: 0.000000, hglt: 5.120216 },
  { isoDate: "2020-10-25T00:00:00Z", dsun: 213.822384, hgln: 0.000000, hglt: 5.030165 },
  { isoDate: "2020-10-26T00:00:00Z", dsun: 213.762693, hgln: 0.000000, hglt: 4.938551 },
  { isoDate: "2020-10-27T00:00:00Z", dsun: 213.703497, hgln: -0.000000, hglt: 4.845399 },
  { isoDate: "2020-10-28T00:00:00Z", dsun: 213.644856, hgln: -0.000000, hglt: 4.750735 },
  { isoDate: "2020-10-29T00:00:00Z", dsun: 213.586821, hgln: -0.000000, hglt: 4.654585 },
  { isoDate: "2020-10-30T00:00:00Z", dsun: 213.529434, hgln: 0.000000, hglt: 4.556976 },
  { isoDate: "2020-10-31T00:00:00Z", dsun: 213.472733, hgln: -0.000000, hglt: 4.457935 },
  { isoDate: "2020-11-01T00:00:00Z", dsun: 213.416744, hgln: -0.000000, hglt: 4.357492 },
  { isoDate: "2020-11-02T00:00:00Z", dsun: 213.361487, hgln: 0.000000, hglt: 4.255674 },
  { isoDate: "2020-11-03T00:00:00Z", dsun: 213.306973, hgln: -0.000000, hglt: 4.152511 },
  { isoDate: "2020-11-04T00:00:00Z", dsun: 213.253203, hgln: 0.000000, hglt: 4.048033 },
  { isoDate: "2020-11-05T00:00:00Z", dsun: 213.200172, hgln: -0.000000, hglt: 3.942272 },
  { isoDate: "2020-11-06T00:00:00Z", dsun: 213.147863, hgln: 0.000000, hglt: 3.835258 },
  { isoDate: "2020-11-07T00:00:00Z", dsun: 213.096253, hgln: 0.000000, hglt: 3.727023 },
  { isoDate: "2020-11-08T00:00:00Z", dsun: 213.045309, hgln: 0.000000, hglt: 3.617601 },
  { isoDate: "2020-11-09T00:00:00Z", dsun: 212.994989, hgln: -0.000000, hglt: 3.507023 },
  { isoDate: "2020-11-10T00:00:00Z", dsun: 212.945246, hgln: 0.000000, hglt: 3.395325 },
  { isoDate: "2020-11-11T00:00:00Z", dsun: 212.896025, hgln: 0.000000, hglt: 3.282539 },
  { isoDate: "2020-11-12T00:00:00Z", dsun: 212.847270, hgln: 0.000000, hglt: 3.168702 },
  { isoDate: "2020-11-13T00:00:00Z", dsun: 212.798929, hgln: -0.000000, hglt: 3.053847 },
  { isoDate: "2020-11-14T00:00:00Z", dsun: 212.750960, hgln: 0.000000, hglt: 2.938012 },
  { isoDate: "2020-11-15T00:00:00Z", dsun: 212.703339, hgln: -0.000000, hglt: 2.821230 },
  { isoDate: "2020-11-16T00:00:00Z", dsun: 212.656066, hgln: -0.000000, hglt: 2.703540 },
  { isoDate: "2020-11-17T00:00:00Z", dsun: 212.609166, hgln: 0.000000, hglt: 2.584976 },
  { isoDate: "2020-11-18T00:00:00Z", dsun: 212.562686, hgln: -0.000000, hglt: 2.465575 },
  { isoDate: "2020-11-19T00:00:00Z", dsun: 212.516691, hgln: 0.000000, hglt: 2.345373 },
  { isoDate: "2020-11-20T00:00:00Z", dsun: 212.471254, hgln: -0.000000, hglt: 2.224406 },
  { isoDate: "2020-11-21T00:00:00Z", dsun: 212.426454, hgln: 0.000000, hglt: 2.102711 },
  { isoDate: "2020-11-22T00:00:00Z", dsun: 212.382368, hgln: -0.000000, hglt: 1.980326 },
  { isoDate: "2020-11-23T00:00:00Z", dsun: 212.339067, hgln: 0.000000, hglt: 1.857286 },
  { isoDate: "2020-11-24T00:00:00Z", dsun: 212.296622, hgln: 0.000000, hglt: 1.733630 },
  { isoDate: "2020-11-25T00:00:00Z", dsun: 212.255093, hgln: -0.000000, hglt: 1.609395 },
  { isoDate: "2020-11-26T00:00:00Z", dsun: 212.214539, hgln: 0.000000, hglt: 1.484620 },
  { isoDate: "2020-11-27T00:00:00Z", dsun: 212.175010, hgln: -0.000000, hglt: 1.359342 },
  { isoDate: "2020-11-28T00:00:00Z", dsun: 212.136551, hgln: 0.000000, hglt: 1.233601 },
  { isoDate: "2020-11-29T00:00:00Z", dsun: 212.099202, hgln: -0.000000, hglt: 1.107436 },
  { isoDate: "2020-11-30T00:00:00Z", dsun: 212.062994, hgln: 0.000000, hglt: 0.980885 },
  { isoDate: "2020-12-01T00:00:00Z", dsun: 212.027949, hgln: -0.000000, hglt: 0.853989 },
  { isoDate: "2020-12-02T00:00:00Z", dsun: 211.994082, hgln: -0.000000, hglt: 0.726787 },
  { isoDate: "2020-12-03T00:00:00Z", dsun: 211.961397, hgln: -0.000000, hglt: 0.599319 },
  { isoDate: "2020-12-04T00:00:00Z", dsun: 211.929886, hgln: 0.000000, hglt: 0.471625 },
  { isoDate: "2020-12-05T00:00:00Z", dsun: 211.899532, hgln: 0.000000, hglt: 0.343745 },
  { isoDate: "2020-12-06T00:00:00Z", dsun: 211.870306, hgln: -0.000000, hglt: 0.215719 },
  { isoDate: "2020-12-07T00:00:00Z", dsun: 211.842168, hgln: -0.000000, hglt: 0.087590 },
  { isoDate: "2020-12-08T00:00:00Z", dsun: 211.815069, hgln: -0.000000, hglt: -0.040604 },
  { isoDate: "2020-12-09T00:00:00Z", dsun: 211.788952, hgln: -0.000000, hglt: -0.168821 },
  { isoDate: "2020-12-10T00:00:00Z", dsun: 211.763753, hgln: -0.000000, hglt: -0.297020 },
  { isoDate: "2020-12-11T00:00:00Z", dsun: 211.739408, hgln: -0.000000, hglt: -0.425161 },
  { isoDate: "2020-12-12T00:00:00Z", dsun: 211.715855, hgln: 0.000000, hglt: -0.553201 },
  { isoDate: "2020-12-13T00:00:00Z", dsun: 211.693043, hgln: -0.000000, hglt: -0.681100 },
  { isoDate: "2020-12-14T00:00:00Z", dsun: 211.670937, hgln: 0.000000, hglt: -0.808816 },
  { isoDate: "2020-12-15T00:00:00Z", dsun: 211.649525, hgln: 0.000000, hglt: -0.936309 },
  { isoDate: "2020-12-16T00:00:00Z", dsun: 211.628814, hgln: 0.000000, hglt: -1.063537 },
  { isoDate: "2020-12-17T00:00:00Z", dsun: 211.608835, hgln: -0.000000, hglt: -1.190458 },
  { isoDate: "2020-12-18T00:00:00Z", dsun: 211.589633, hgln: 0.000000, hglt: -1.317032 },
  { isoDate: "2020-12-19T00:00:00Z", dsun: 211.571265, hgln: -0.000000, hglt: -1.443217 },
  { isoDate: "2020-12-20T00:00:00Z", dsun: 211.553791, hgln: 0.000000, hglt: -1.568973 },
  { isoDate: "2020-12-21T00:00:00Z", dsun: 211.537275, hgln: 0.000000, hglt: -1.694258 },
  { isoDate: "2020-12-22T00:00:00Z", dsun: 211.521776, hgln: 0.000000, hglt: -1.819033 },
  { isoDate: "2020-12-23T00:00:00Z", dsun: 211.507352, hgln: 0.000000, hglt: -1.943256 },
  { isoDate: "2020-12-24T00:00:00Z", dsun: 211.494060, hgln: 0.000000, hglt: -2.066887 },
  { isoDate: "2020-12-25T00:00:00Z", dsun: 211.481949, hgln: 0.000000, hglt: -2.189885 },
  { isoDate: "2020-12-26T00:00:00Z", dsun: 211.471066, hgln: 0.000000, hglt: -2.312212 },
  { isoDate: "2020-12-27T00:00:00Z", dsun: 211.461456, hgln: 0.000000, hglt: -2.433826 },
  { isoDate: "2020-12-28T00:00:00Z", dsun: 211.453156, hgln: 0.000000, hglt: -2.554688 },
  { isoDate: "2020-12-29T00:00:00Z", dsun: 211.446199, hgln: -0.000000, hglt: -2.674759 },
  { isoDate: "2020-12-30T00:00:00Z", dsun: 211.440608, hgln: -0.000000, hglt: -2.793999 },
  { isoDate: "2020-12-31T00:00:00Z", dsun: 211.436397, hgln: -0.000000, hglt: -2.912370 },
  { isoDate: "2021-01-01T00:00:00Z", dsun: 211.433571, hgln: 0.000000, hglt: -3.029833 },
  { isoDate: "2021-01-02T00:00:00Z", dsun: 211.432117, hgln: 0.000000, hglt: -3.146350 },
  { isoDate: "2021-01-03T00:00:00Z", dsun: 211.432014, hgln: 0.000000, hglt: -3.261884 },
  { isoDate: "2021-01-04T00:00:00Z", dsun: 211.433222, hgln: -0.000000, hglt: -3.376397 },
  { isoDate: "2021-01-05T00:00:00Z", dsun: 211.435691, hgln: 0.000000, hglt: -3.489853 },
  { isoDate: "2021-01-06T00:00:00Z", dsun: 211.439360, hgln: 0.000000, hglt: -3.602215 },
  { isoDate: "2021-01-07T00:00:00Z", dsun: 211.444160, hgln: 0.000000, hglt: -3.713448 },
  { isoDate: "2021-01-08T00:00:00Z", dsun: 211.450015, hgln: -0.000000, hglt: -3.823515 },
  { isoDate: "2021-01-09T00:00:00Z", dsun: 211.456852, hgln: 0.000000, hglt: -3.932383 },
  { isoDate: "2021-01-10T00:00:00Z", dsun: 211.464599, hgln: 0.000000, hglt: -4.040015 },
  { isoDate: "2021-01-11T00:00:00Z", dsun: 211.473197, hgln: 0.000000, hglt: -4.146377 },
  { isoDate: "2021-01-12T00:00:00Z", dsun: 211.482600, hgln: -0.000000, hglt: -4.251436 },
  { isoDate: "2021-01-13T00:00:00Z", dsun: 211.492781, hgln: 0.000000, hglt: -4.355157 },
  { isoDate: "2021-01-14T00:00:00Z", dsun: 211.503730, hgln: -0.000000, hglt: -4.457506 },
  { isoDate: "2021-01-15T00:00:00Z", dsun: 211.515457, hgln: -0.000000, hglt: -4.558450 },
  { isoDate: "2021-01-16T00:00:00Z", dsun: 211.527984, hgln: 0.000000, hglt: -4.657956 },
  { isoDate: "2021-01-17T00:00:00Z", dsun: 211.541347, hgln: 0.000000, hglt: -4.755990 },
  { isoDate: "2021-01-18T00:00:00Z", dsun: 211.555586, hgln: 0.000000, hglt: -4.852520 },
  { isoDate: "2021-01-19T00:00:00Z", dsun: 211.570744, hgln: -0.000000, hglt: -4.947516 },
  { isoDate: "2021-01-20T00:00:00Z", dsun: 211.586867, hgln: -0.000000, hglt: -5.040944 },
  { isoDate: "2021-01-21T00:00:00Z", dsun: 211.603999, hgln: -0.000000, hglt: -5.132775 },
  { isoDate: "2021-01-22T00:00:00Z", dsun: 211.622185, hgln: -0.000000, hglt: -5.222978 },
  { isoDate: "2021-01-23T00:00:00Z", dsun: 211.641468, hgln: -0.000000, hglt: -5.311523 },
  { isoDate: "2021-01-24T00:00:00Z", dsun: 211.661889, hgln: 0.000000, hglt: -5.398383 },
  { isoDate: "2021-01-25T00:00:00Z", dsun: 211.683488, hgln: 0.000000, hglt: -5.483527 },
  { isoDate: "2021-01-26T00:00:00Z", dsun: 211.706301, hgln: 0.000000, hglt: -5.566928 },
  { isoDate: "2021-01-27T00:00:00Z", dsun: 211.730361, hgln: 0.000000, hglt: -5.648560 },
  { isoDate: "2021-01-28T00:00:00Z", dsun: 211.755693, hgln: 0.000000, hglt: -5.728396 },
  { isoDate: "2021-01-29T00:00:00Z", dsun: 211.782312, hgln: 0.000000, hglt: -5.806410 },
  { isoDate: "2021-01-30T00:00:00Z", dsun: 211.810221, hgln: 0.000000, hglt: -5.882578 },
  { isoDate: "2021-01-31T00:00:00Z", dsun: 211.839408, hgln: 0.000000, hglt: -5.956876 },
  { isoDate: "2021-02-01T00:00:00Z", dsun: 211.869841, hgln: -0.000000, hglt: -6.029282 },
  { isoDate: "2021-02-02T00:00:00Z", dsun: 211.901474, hgln: 0.000000, hglt: -6.099774 },
  { isoDate: "2021-02-03T00:00:00Z", dsun: 211.934245, hgln: 0.000000, hglt: -6.168331 },
  { isoDate: "2021-02-04T00:00:00Z", dsun: 211.968079, hgln: 0.000000, hglt: -6.234933 },
  { isoDate: "2021-02-05T00:00:00Z", dsun: 212.002897, hgln: 0.000000, hglt: -6.299562 },
  { isoDate: "2021-02-06T00:00:00Z", dsun: 212.038612, hgln: -0.000000, hglt: -6.362200 },
  { isoDate: "2021-02-07T00:00:00Z", dsun: 212.075142, hgln: -0.000000, hglt: -6.422828 },
  { isoDate: "2021-02-08T00:00:00Z", dsun: 212.112410, hgln: 0.000000, hglt: -6.481430 },
  { isoDate: "2021-02-09T00:00:00Z", dsun: 212.150347, hgln: -0.000000, hglt: -6.537989 },
  { isoDate: "2021-02-10T00:00:00Z", dsun: 212.188900, hgln: 0.000000, hglt: -6.592490 },
  { isoDate: "2021-02-11T00:00:00Z", dsun: 212.228029, hgln: -0.000000, hglt: -6.644917 },
  { isoDate: "2021-02-12T00:00:00Z", dsun: 212.267709, hgln: 0.000000, hglt: -6.695255 },
  { isoDate: "2021-02-13T00:00:00Z", dsun: 212.307931, hgln: -0.000000, hglt: -6.743491 },
  { isoDate: "2021-02-14T00:00:00Z", dsun: 212.348697, hgln: 0.000000, hglt: -6.789608 },
  { isoDate: "2021-02-15T00:00:00Z", dsun: 212.390021, hgln: -0.000000, hglt: -6.833596 },
  { isoDate: "2021-02-16T00:00:00Z", dsun: 212.431923, hgln: 0.000000, hglt: -6.875440 },
  { isoDate: "2021-02-17T00:00:00Z", dsun: 212.474430, hgln: 0.000000, hglt: -6.915128 },
  { isoDate: "2021-02-18T00:00:00Z", dsun: 212.517571, hgln: 0.000000, hglt: -6.952649 },
  { isoDate: "2021-02-19T00:00:00Z", dsun: 212.561378, hgln: 0.000000, hglt: -6.987993 },
  { isoDate: "2021-02-20T00:00:00Z", dsun: 212.605884, hgln: -0.000000, hglt: -7.021150 },
  { isoDate: "2021-02-21T00:00:00Z", dsun: 212.651126, hgln: 0.000000, hglt: -7.052109 },
  { isoDate: "2021-02-22T00:00:00Z", dsun: 212.697140, hgln: 0.000000, hglt: -7.080864 },
  { isoDate: "2021-02-23T00:00:00Z", dsun: 212.743962, hgln: -0.000000, hglt: -7.107406 },
  { isoDate: "2021-02-24T00:00:00Z", dsun: 212.791630, hgln: -0.000000, hglt: -7.131728 },
  { isoDate: "2021-02-25T00:00:00Z", dsun: 212.840178, hgln: -0.000000, hglt: -7.153824 },
  { isoDate: "2021-02-26T00:00:00Z", dsun: 212.889637, hgln: -0.000000, hglt: -7.173690 },
  { isoDate: "2021-02-27T00:00:00Z", dsun: 212.940026, hgln: 0.000000, hglt: -7.191321 },
  { isoDate: "2021-02-28T00:00:00Z", dsun: 212.991350, hgln: 0.000000, hglt: -7.206714 },
  { isoDate: "2021-03-01T00:00:00Z", dsun: 213.043599, hgln: 0.000000, hglt: -7.219867 },
  { isoDate: "2021-03-02T00:00:00Z", dsun: 213.096738, hgln: 0.000000, hglt: -7.230780 },
  { isoDate: "2021-03-03T00:00:00Z", dsun: 213.150716, hgln: 0.000000, hglt: -7.239452 },
  { isoDate: "2021-03-04T00:00:00Z", dsun: 213.205463, hgln: -0.000000, hglt: -7.245887 },
  { isoDate: "2021-03-05T00:00:00Z", dsun: 213.260895, hgln: 0.000000, hglt: -7.250086 },
  { isoDate: "2021-03-06T00:00:00Z", dsun: 213.316923, hgln: 0.000000, hglt: -7.252053 },
  { isoDate: "2021-03-07T00:00:00Z", dsun: 213.373456, hgln: 0.000000, hglt: -7.251793 },
  { isoDate: "2021-03-08T00:00:00Z", dsun: 213.430407, hgln: 0.000000, hglt: -7.249310 },
  { isoDate: "2021-03-09T00:00:00Z", dsun: 213.487694, hgln: 0.000000, hglt: -7.244611 },
  { isoDate: "2021-03-10T00:00:00Z", dsun: 213.545248, hgln: -0.000000, hglt: -7.237701 },
  { isoDate: "2021-03-11T00:00:00Z", dsun: 213.603009, hgln: -0.000000, hglt: -7.228587 },
  { isoDate: "2021-03-12T00:00:00Z", dsun: 213.660927, hgln: -0.000000, hglt: -7.217277 },
  { isoDate: "2021-03-13T00:00:00Z", dsun: 213.718969, hgln: -0.000000, hglt: -7.203777 },
  { isoDate: "2021-03-14T00:00:00Z", dsun: 213.777111, hgln: -0.000000, hglt: -7.188096 },
  { isoDate: "2021-03-15T00:00:00Z", dsun: 213.835341, hgln: -0.000000, hglt: -7.170242 },
  { isoDate: "2021-03-16T00:00:00Z", dsun: 213.893657, hgln: -0.000000, hglt: -7.150225 },
  { isoDate: "2021-03-17T00:00:00Z", dsun: 213.952063, hgln: -0.000000, hglt: -7.128053 },
  { isoDate: "2021-03-18T00:00:00Z", dsun: 214.010572, hgln: -0.000000, hglt: -7.103736 },
  { isoDate: "2021-03-19T00:00:00Z", dsun: 214.069203, hgln: -0.000000, hglt: -7.077286 },
  { isoDate: "2021-03-20T00:00:00Z", dsun: 214.127977, hgln: 0.000000, hglt: -7.048713 },
  { isoDate: "2021-03-21T00:00:00Z", dsun: 214.186922, hgln: 0.000000, hglt: -7.018029 },
  { isoDate: "2021-03-22T00:00:00Z", dsun: 214.246067, hgln: 0.000000, hglt: -6.985247 },
  { isoDate: "2021-03-23T00:00:00Z", dsun: 214.305448, hgln: -0.000000, hglt: -6.950378 },
  { isoDate: "2021-03-24T00:00:00Z", dsun: 214.365102, hgln: -0.000000, hglt: -6.913437 },
  { isoDate: "2021-03-25T00:00:00Z", dsun: 214.425071, hgln: 0.000000, hglt: -6.874438 },
  { isoDate: "2021-03-26T00:00:00Z", dsun: 214.485394, hgln: -0.000000, hglt: -6.833394 },
  { isoDate: "2021-03-27T00:00:00Z", dsun: 214.546109, hgln: -0.000000, hglt: -6.790322 },
  { isoDate: "2021-03-28T00:00:00Z", dsun: 214.607245, hgln: 0.000000, hglt: -6.745236 },
  { isoDate: "2021-03-29T00:00:00Z", dsun: 214.668814, hgln: 0.000000, hglt: -6.698155 },
  { isoDate: "2021-03-30T00:00:00Z", dsun: 214.730812, hgln: 0.000000, hglt: -6.649096 },
  { isoDate: "2021-03-31T00:00:00Z", dsun: 214.793207, hgln: -0.000000, hglt: -6.598077 },
  { isoDate: "2021-04-01T00:00:00Z", dsun: 214.855948, hgln: -0.000000, hglt: -6.545118 },
  { isoDate: "2021-04-02T00:00:00Z", dsun: 214.918961, hgln: -0.000000, hglt: -6.490240 },
  { isoDate: "2021-04-03T00:00:00Z", dsun: 214.982162, hgln: 0.000000, hglt: -6.433465 },
  { isoDate: "2021-04-04T00:00:00Z", dsun: 215.045458, hgln: -0.000000, hglt: -6.374814 },
  { isoDate: "2021-04-05T00:00:00Z", dsun: 215.108760, hgln: -0.000000, hglt: -6.314310 },
  { isoDate: "2021-04-06T00:00:00Z", dsun: 215.171979, hgln: 0.000000, hglt: -6.251976 },
  { isoDate: "2021-04-07T00:00:00Z", dsun: 215.235037, hgln: 0.000000, hglt: -6.187836 },
  { isoDate: "2021-04-08T00:00:00Z", dsun: 215.297862, hgln: -0.000000, hglt: -6.121912 },
  { isoDate: "2021-04-09T00:00:00Z", dsun: 215.360394, hgln: 0.000000, hglt: -6.054229 },
  { isoDate: "2021-04-10T00:00:00Z", dsun: 215.422582, hgln: 0.000000, hglt: -5.984811 },
  { isoDate: "2021-04-11T00:00:00Z", dsun: 215.484384, hgln: -0.000000, hglt: -5.913682 },
  { isoDate: "2021-04-12T00:00:00Z", dsun: 215.545772, hgln: -0.000000, hglt: -5.840866 },
  { isoDate: "2021-04-13T00:00:00Z", dsun: 215.606722, hgln: 0.000000, hglt: -5.766389 },
  { isoDate: "2021-04-14T00:00:00Z", dsun: 215.667225, hgln: -0.000000, hglt: -5.690276 },
  { isoDate: "2021-04-15T00:00:00Z", dsun: 215.727275, hgln: 0.000000, hglt: -5.612551 },
  { isoDate: "2021-04-16T00:00:00Z", dsun: 215.786878, hgln: 0.000000, hglt: -5.533240 },
  { isoDate: "2021-04-17T00:00:00Z", dsun: 215.846045, hgln: 0.000000, hglt: -5.452369 },
  { isoDate: "2021-04-18T00:00:00Z", dsun: 215.904793, hgln: 0.000000, hglt: -5.369964 },
  { isoDate: "2021-04-19T00:00:00Z", dsun: 215.963146, hgln: 0.000000, hglt: -5.286051 },
  { isoDate: "2021-04-20T00:00:00Z", dsun: 216.021137, hgln: 0.000000, hglt: -5.200656 },
  { isoDate: "2021-04-21T00:00:00Z", dsun: 216.078800, hgln: -0.000000, hglt: -5.113807 },
  { isoDate: "2021-04-22T00:00:00Z", dsun: 216.136178, hgln: 0.000000, hglt: -5.025531 },
  { isoDate: "2021-04-23T00:00:00Z", dsun: 216.193320, hgln: 0.000000, hglt: -4.935854 },
  { isoDate: "2021-04-24T00:00:00Z", dsun: 216.250274, hgln: 0.000000, hglt: -4.844804 },
  { isoDate: "2021-04-25T00:00:00Z", dsun: 216.307087, hgln: 0.000000, hglt: -4.752409 },
  { isoDate: "2021-04-26T00:00:00Z", dsun: 216.363800, hgln: 0.000000, hglt: -4.658696 },
  { isoDate: "2021-04-27T00:00:00Z", dsun: 216.420437, hgln: 0.000000, hglt: -4.563695 },
  { isoDate: "2021-04-28T00:00:00Z", dsun: 216.477000, hgln: -0.000000, hglt: -4.467434 },
  { isoDate: "2021-04-29T00:00:00Z", dsun: 216.533466, hgln: 0.000000, hglt: -4.369942 },
  { isoDate: "2021-04-30T00:00:00Z", dsun: 216.589789, hgln: 0.000000, hglt: -4.271250 },
  { isoDate: "2021-05-01T00:00:00Z", dsun: 216.645901, hgln: -0.000000, hglt: -4.171388 },
  { isoDate: "2021-05-02T00:00:00Z", dsun: 216.701720, hgln: -0.000000, hglt: -4.070387 },
  { isoDate: "2021-05-03T00:00:00Z", dsun: 216.757161, hgln: 0.000000, hglt: -3.968278 },
  { isoDate: "2021-05-04T00:00:00Z", dsun: 216.812137, hgln: -0.000000, hglt: -3.865092 },
  { isoDate: "2021-05-05T00:00:00Z", dsun: 216.866566, hgln: 0.000000, hglt: -3.760861 },
  { isoDate: "2021-05-06T00:00:00Z", dsun: 216.920375, hgln: 0.000000, hglt: -3.655616 },
  { isoDate: "2021-05-07T00:00:00Z", dsun: 216.973496, hgln: 0.000000, hglt: -3.549390 },
  { isoDate: "2021-05-08T00:00:00Z", dsun: 217.025870, hgln: -0.000000, hglt: -3.442212 },
  { isoDate: "2021-05-09T00:00:00Z", dsun: 217.077448, hgln: 0.000000, hglt: -3.334116 },
  { isoDate: "2021-05-10T00:00:00Z", dsun: 217.128187, hgln: -0.000000, hglt: -3.225134 },
  { isoDate: "2021-05-11T00:00:00Z", dsun: 217.178055, hgln: 0.000000, hglt: -3.115296 },
  { isoDate: "2021-05-12T00:00:00Z", dsun: 217.227025, hgln: -0.000000, hglt: -3.004634 },
  { isoDate: "2021-05-13T00:00:00Z", dsun: 217.275083, hgln: 0.000000, hglt: -2.893181 },
  { isoDate: "2021-05-14T00:00:00Z", dsun: 217.322220, hgln: 0.000000, hglt: -2.780969 },
  { isoDate: "2021-05-15T00:00:00Z", dsun: 217.368438, hgln: 0.000000, hglt: -2.668029 },
  { isoDate: "2021-05-16T00:00:00Z", dsun: 217.413747, hgln: 0.000000, hglt: -2.554394 },
  { isoDate: "2021-05-17T00:00:00Z", dsun: 217.458163, hgln: -0.000000, hglt: -2.440095 },
  { isoDate: "2021-05-18T00:00:00Z", dsun: 217.501714, hgln: -0.000000, hglt: -2.325164 },
  { isoDate: "2021-05-19T00:00:00Z", dsun: 217.544433, hgln: 0.000000, hglt: -2.209634 },
  { isoDate: "2021-05-20T00:00:00Z", dsun: 217.586364, hgln: -0.000000, hglt: -2.093537 },
  { isoDate: "2021-05-21T00:00:00Z", dsun: 217.627557, hgln: 0.000000, hglt: -1.976905 },
  { isoDate: "2021-05-22T00:00:00Z", dsun: 217.668068, hgln: -0.000000, hglt: -1.859770 },
  { isoDate: "2021-05-23T00:00:00Z", dsun: 217.707957, hgln: -0.000000, hglt: -1.742165 },
  { isoDate: "2021-05-24T00:00:00Z", dsun: 217.747282, hgln: 0.000000, hglt: -1.624120 },
  { isoDate: "2021-05-25T00:00:00Z", dsun: 217.786095, hgln: 0.000000, hglt: -1.505670 },
  { isoDate: "2021-05-26T00:00:00Z", dsun: 217.824429, hgln: -0.000000, hglt: -1.386845 },
  { isoDate: "2021-05-27T00:00:00Z", dsun: 217.862299, hgln: 0.000000, hglt: -1.267677 },
  { isoDate: "2021-05-28T00:00:00Z", dsun: 217.899691, hgln: 0.000000, hglt: -1.148199 },
  { isoDate: "2021-05-29T00:00:00Z", dsun: 217.936568, hgln: -0.000000, hglt: -1.028444 },
  { isoDate: "2021-05-30T00:00:00Z", dsun: 217.972872, hgln: 0.000000, hglt: -0.908443 },
  { isoDate: "2021-05-31T00:00:00Z", dsun: 218.008534, hgln: 0.000000, hglt: -0.788228 },
  { isoDate: "2021-06-01T00:00:00Z", dsun: 218.043478, hgln: -0.000000, hglt: -0.667832 },
  { isoDate: "2021-06-02T00:00:00Z", dsun: 218.077628, hgln: 0.000000, hglt: -0.547287 },
  { isoDate: "2021-06-03T00:00:00Z", dsun: 218.110912, hgln: 0.000000, hglt: -0.426626 },
  { isoDate: "2021-06-04T00:00:00Z", dsun: 218.143262, hgln: 0.000000, hglt: -0.305881 },
  { isoDate: "2021-06-05T00:00:00Z", dsun: 218.174618, hgln: -0.000000, hglt: -0.185084 },
  { isoDate: "2021-06-06T00:00:00Z", dsun: 218.204926, hgln: -0.000000, hglt: -0.064267 },
  { isoDate: "2021-06-07T00:00:00Z", dsun: 218.234139, hgln: 0.000000, hglt: 0.056536 },
  { isoDate: "2021-06-08T00:00:00Z", dsun: 218.262216, hgln: -0.000000, hglt: 0.177295 },
  { isoDate: "2021-06-09T00:00:00Z", dsun: 218.289124, hgln: 0.000000, hglt: 0.297977 },
  { isoDate: "2021-06-10T00:00:00Z", dsun: 218.314837, hgln: 0.000000, hglt: 0.418550 },
  { isoDate: "2021-06-11T00:00:00Z", dsun: 218.339338, hgln: 0.000000, hglt: 0.538982 },
  { isoDate: "2021-06-12T00:00:00Z", dsun: 218.362618, hgln: 0.000000, hglt: 0.659241 },
  { isoDate: "2021-06-13T00:00:00Z", dsun: 218.384677, hgln: -0.000000, hglt: 0.779295 },
  { isoDate: "2021-06-14T00:00:00Z", dsun: 218.405528, hgln: 0.000000, hglt: 0.899111 },
  { isoDate: "2021-06-15T00:00:00Z", dsun: 218.425190, hgln: 0.000000, hglt: 1.018659 },
  { isoDate: "2021-06-16T00:00:00Z", dsun: 218.443696, hgln: 0.000000, hglt: 1.137905 },
  { isoDate: "2021-06-17T00:00:00Z", dsun: 218.461087, hgln: 0.000000, hglt: 1.256819 },
  { isoDate: "2021-06-18T00:00:00Z", dsun: 218.477415, hgln: -0.000000, hglt: 1.375368 },
  { isoDate: "2021-06-19T00:00:00Z", dsun: 218.492740, hgln: -0.000000, hglt: 1.493520 },
  { isoDate: "2021-06-20T00:00:00Z", dsun: 218.507129, hgln: 0.000000, hglt: 1.611245 },
  { isoDate: "2021-06-21T00:00:00Z", dsun: 218.520652, hgln: 0.000000, hglt: 1.728510 },
  { isoDate: "2021-06-22T00:00:00Z", dsun: 218.533378, hgln: -0.000000, hglt: 1.845283 },
  { isoDate: "2021-06-23T00:00:00Z", dsun: 218.545365, hgln: 0.000000, hglt: 1.961535 },
  { isoDate: "2021-06-24T00:00:00Z", dsun: 218.556658, hgln: 0.000000, hglt: 2.077234 },
  { isoDate: "2021-06-25T00:00:00Z", dsun: 218.567279, hgln: 0.000000, hglt: 2.192350 },
  { isoDate: "2021-06-26T00:00:00Z", dsun: 218.577227, hgln: 0.000000, hglt: 2.306853 },
  { isoDate: "2021-06-27T00:00:00Z", dsun: 218.586479, hgln: -0.000000, hglt: 2.420714 },
  { isoDate: "2021-06-28T00:00:00Z", dsun: 218.594991, hgln: 0.000000, hglt: 2.533904 },
  { isoDate: "2021-06-29T00:00:00Z", dsun: 218.602708, hgln: 0.000000, hglt: 2.646392 },
  { isoDate: "2021-06-30T00:00:00Z", dsun: 218.609571, hgln: 0.000000, hglt: 2.758151 },
  { isoDate: "2021-07-01T00:00:00Z", dsun: 218.615516, hgln: 0.000000, hglt: 2.869151 },
  { isoDate: "2021-07-02T00:00:00Z", dsun: 218.620482, hgln: 0.000000, hglt: 2.979364 },
  { isoDate: "2021-07-03T00:00:00Z", dsun: 218.624412, hgln: -0.000000, hglt: 3.088761 },
  { isoDate: "2021-07-04T00:00:00Z", dsun: 218.627254, hgln: 0.000000, hglt: 3.197314 },
  { isoDate: "2021-07-05T00:00:00Z", dsun: 218.628959, hgln: -0.000000, hglt: 3.304994 },
  { isoDate: "2021-07-06T00:00:00Z", dsun: 218.629484, hgln: -0.000000, hglt: 3.411773 },
  { isoDate: "2021-07-07T00:00:00Z", dsun: 218.628791, hgln: 0.000000, hglt: 3.517622 },
  { isoDate: "2021-07-08T00:00:00Z", dsun: 218.626846, hgln: -0.000000, hglt: 3.622515 },
  { isoDate: "2021-07-09T00:00:00Z", dsun: 218.623625, hgln: -0.000000, hglt: 3.726422 },
  { isoDate: "2021-07-10T00:00:00Z", dsun: 218.619108, hgln: 0.000000, hglt: 3.829316 },
  { isoDate: "2021-07-11T00:00:00Z", dsun: 218.613288, hgln: -0.000000, hglt: 3.931169 },
  { isoDate: "2021-07-12T00:00:00Z", dsun: 218.606166, hgln: 0.000000, hglt: 4.031954 },
  { isoDate: "2021-07-13T00:00:00Z", dsun: 218.597755, hgln: -0.000000, hglt: 4.131642 },
  { isoDate: "2021-07-14T00:00:00Z", dsun: 218.588082, hgln: -0.000000, hglt: 4.230206 },
  { isoDate: "2021-07-15T00:00:00Z", dsun: 218.577186, hgln: -0.000000, hglt: 4.327619 },
  { isoDate: "2021-07-16T00:00:00Z", dsun: 218.565117, hgln: -0.000000, hglt: 4.423852 },
  { isoDate: "2021-07-17T00:00:00Z", dsun: 218.551937, hgln: -0.000000, hglt: 4.518878 },
  { isoDate: "2021-07-18T00:00:00Z", dsun: 218.537718, hgln: -0.000000, hglt: 4.612670 },
  { isoDate: "2021-07-19T00:00:00Z", dsun: 218.522537, hgln: 0.000000, hglt: 4.705201 },
  { isoDate: "2021-07-20T00:00:00Z", dsun: 218.506472, hgln: 0.000000, hglt: 4.796444 },
  { isoDate: "2021-07-21T00:00:00Z", dsun: 218.489599, hgln: 0.000000, hglt: 4.886372 },
  { isoDate: "2021-07-22T00:00:00Z", dsun: 218.471985, hgln: 0.000000, hglt: 4.974961 },
  { isoDate: "2021-07-23T00:00:00Z", dsun: 218.453679, hgln: -0.000000, hglt: 5.062184 },
  { isoDate: "2021-07-24T00:00:00Z", dsun: 218.434715, hgln: 0.000000, hglt: 5.148018 },
  { isoDate: "2021-07-25T00:00:00Z", dsun: 218.415101, hgln: -0.000000, hglt: 5.232438 },
  { isoDate: "2021-07-26T00:00:00Z", dsun: 218.394830, hgln: 0.000000, hglt: 5.315421 },
  { isoDate: "2021-07-27T00:00:00Z", dsun: 218.373874, hgln: -0.000000, hglt: 5.396946 },
  { isoDate: "2021-07-28T00:00:00Z", dsun: 218.352197, hgln: 0.000000, hglt: 5.476989 },
  { isoDate: "2021-07-29T00:00:00Z", dsun: 218.329755, hgln: -0.000000, hglt: 5.555529 },
  { isoDate: "2021-07-30T00:00:00Z", dsun: 218.306500, hgln: 0.000000, hglt: 5.632544 },
  { isoDate: "2021-07-31T00:00:00Z", dsun: 218.282384, hgln: 0.000000, hglt: 5.708013 },
  { isoDate: "2021-08-01T00:00:00Z", dsun: 218.257362, hgln: 0.000000, hglt: 5.781916 },
  { isoDate: "2021-08-02T00:00:00Z", dsun: 218.231388, hgln: -0.000000, hglt: 5.854231 },
  { isoDate: "2021-08-03T00:00:00Z", dsun: 218.204421, hgln: 0.000000, hglt: 5.924939 },
  { isoDate: "2021-08-04T00:00:00Z", dsun: 218.176420, hgln: 0.000000, hglt: 5.994019 },
  { isoDate: "2021-08-05T00:00:00Z", dsun: 218.147350, hgln: -0.000000, hglt: 6.061451 },
  { isoDate: "2021-08-06T00:00:00Z", dsun: 218.117179, hgln: 0.000000, hglt: 6.127216 },
  { isoDate: "2021-08-07T00:00:00Z", dsun: 218.085879, hgln: 0.000000, hglt: 6.191294 },
  { isoDate: "2021-08-08T00:00:00Z", dsun: 218.053430, hgln: 0.000000, hglt: 6.253666 },
  { isoDate: "2021-08-09T00:00:00Z", dsun: 218.019823, hgln: 0.000000, hglt: 6.314314 },
  { isoDate: "2021-08-10T00:00:00Z", dsun: 217.985059, hgln: 0.000000, hglt: 6.373219 },
  { isoDate: "2021-08-11T00:00:00Z", dsun: 217.949154, hgln: -0.000000, hglt: 6.430361 },
  { isoDate: "2021-08-12T00:00:00Z", dsun: 217.912140, hgln: -0.000000, hglt: 6.485722 },
  { isoDate: "2021-08-13T00:00:00Z", dsun: 217.874062, hgln: 0.000000, hglt: 6.539283 },
  { isoDate: "2021-08-14T00:00:00Z", dsun: 217.834982, hgln: -0.000000, hglt: 6.591027 },
  { isoDate: "2021-08-15T00:00:00Z", dsun: 217.794971, hgln: 0.000000, hglt: 6.640934 },
  { isoDate: "2021-08-16T00:00:00Z", dsun: 217.754112, hgln: 0.000000, hglt: 6.688988 },
  { isoDate: "2021-08-17T00:00:00Z", dsun: 217.712490, hgln: -0.000000, hglt: 6.735170 },
  { isoDate: "2021-08-18T00:00:00Z", dsun: 217.670189, hgln: 0.000000, hglt: 6.779464 },
  { isoDate: "2021-08-19T00:00:00Z", dsun: 217.627291, hgln: 0.000000, hglt: 6.821854 },
  { isoDate: "2021-08-20T00:00:00Z", dsun: 217.583865, hgln: -0.000000, hglt: 6.862325 },
  { isoDate: "2021-08-21T00:00:00Z", dsun: 217.539968, hgln: -0.000000, hglt: 6.900862 },
  { isoDate: "2021-08-22T00:00:00Z", dsun: 217.495639, hgln: -0.000000, hglt: 6.937452 },
  { isoDate: "2021-08-23T00:00:00Z", dsun: 217.450898, hgln: 0.000000, hglt: 6.972081 },
  { isoDate: "2021-08-24T00:00:00Z", dsun: 217.405753, hgln: -0.000000, hglt: 7.004739 },
  { isoDate: "2021-08-25T00:00:00Z", dsun: 217.360192, hgln: -0.000000, hglt: 7.035414 },
  { isoDate: "2021-08-26T00:00:00Z", dsun: 217.314198, hgln: 0.000000, hglt: 7.064095 },
  { isoDate: "2021-08-27T00:00:00Z", dsun: 217.267741, hgln: 0.000000, hglt: 7.090773 },
  { isoDate: "2021-08-28T00:00:00Z", dsun: 217.220789, hgln: -0.000000, hglt: 7.115439 },
  { isoDate: "2021-08-29T00:00:00Z", dsun: 217.173310, hgln: 0.000000, hglt: 7.138084 },
  { isoDate: "2021-08-30T00:00:00Z", dsun: 217.125265, hgln: -0.000000, hglt: 7.158699 },
  { isoDate: "2021-08-31T00:00:00Z", dsun: 217.076619, hgln: -0.000000, hglt: 7.177278 },
  { isoDate: "2021-09-01T00:00:00Z", dsun: 217.027336, hgln: -0.000000, hglt: 7.193812 },
  { isoDate: "2021-09-02T00:00:00Z", dsun: 216.977379, hgln: 0.000000, hglt: 7.208296 },
  { isoDate: "2021-09-03T00:00:00Z", dsun: 216.926712, hgln: -0.000000, hglt: 7.220723 },
  { isoDate: "2021-09-04T00:00:00Z", dsun: 216.875302, hgln: -0.000000, hglt: 7.231087 },
  { isoDate: "2021-09-05T00:00:00Z", dsun: 216.823119, hgln: -0.000000, hglt: 7.239383 },
  { isoDate: "2021-09-06T00:00:00Z", dsun: 216.770139, hgln: 0.000000, hglt: 7.245606 },
  { isoDate: "2021-09-07T00:00:00Z", dsun: 216.716348, hgln: -0.000000, hglt: 7.249752 },
  { isoDate: "2021-09-08T00:00:00Z", dsun: 216.661745, hgln: -0.000000, hglt: 7.251816 },
  { isoDate: "2021-09-09T00:00:00Z", dsun: 216.606346, hgln: 0.000000, hglt: 7.251793 },
  { isoDate: "2021-09-10T00:00:00Z", dsun: 216.550187, hgln: 0.000000, hglt: 7.249681 },
  { isoDate: "2021-09-11T00:00:00Z", dsun: 216.493318, hgln: -0.000000, hglt: 7.245475 },
  { isoDate: "2021-09-12T00:00:00Z", dsun: 216.435810, hgln: 0.000000, hglt: 7.239172 },
  { isoDate: "2021-09-13T00:00:00Z", dsun: 216.377744, hgln: -0.000000, hglt: 7.230768 },
  { isoDate: "2021-09-14T00:00:00Z", dsun: 216.319208, hgln: -0.000000, hglt: 7.220263 },
  { isoDate: "2021-09-15T00:00:00Z", dsun: 216.260293, hgln: 0.000000, hglt: 7.207652 },
  { isoDate: "2021-09-16T00:00:00Z", dsun: 216.201089, hgln: 0.000000, hglt: 7.192937 },
  { isoDate: "2021-09-17T00:00:00Z", dsun: 216.141676, hgln: 0.000000, hglt: 7.176116 },
  { isoDate: "2021-09-18T00:00:00Z", dsun: 216.082128, hgln: -0.000000, hglt: 7.157191 },
  { isoDate: "2021-09-19T00:00:00Z", dsun: 216.022503, hgln: -0.000000, hglt: 7.136162 },
  { isoDate: "2021-09-20T00:00:00Z", dsun: 215.962847, hgln: 0.000000, hglt: 7.113034 },
  { isoDate: "2021-09-21T00:00:00Z", dsun: 215.903190, hgln: -0.000000, hglt: 7.087808 },
  { isoDate: "2021-09-22T00:00:00Z", dsun: 215.843551, hgln: 0.000000, hglt: 7.060491 },
  { isoDate: "2021-09-23T00:00:00Z", dsun: 215.783934, hgln: -0.000000, hglt: 7.031086 },
  { isoDate: "2021-09-24T00:00:00Z", dsun: 215.724334, hgln: 0.000000, hglt: 6.999601 },
  { isoDate: "2021-09-25T00:00:00Z", dsun: 215.664739, hgln: -0.000000, hglt: 6.966041 },
  { isoDate: "2021-09-26T00:00:00Z", dsun: 215.605130, hgln: 0.000000, hglt: 6.930416 },
  { isoDate: "2021-09-27T00:00:00Z", dsun: 215.545483, hgln: -0.000000, hglt: 6.892732 },
  { isoDate: "2021-09-28T00:00:00Z", dsun: 215.485771, hgln: 0.000000, hglt: 6.853000 },
  { isoDate: "2021-09-29T00:00:00Z", dsun: 215.425965, hgln: 0.000000, hglt: 6.811227 },
  { isoDate: "2021-09-30T00:00:00Z", dsun: 215.366032, hgln: 0.000000, hglt: 6.767425 },
  { isoDate: "2021-10-01T00:00:00Z", dsun: 215.305938, hgln: -0.000000, hglt: 6.721605 },
  { isoDate: "2021-10-02T00:00:00Z", dsun: 215.245645, hgln: 0.000000, hglt: 6.673778 },
  { isoDate: "2021-10-03T00:00:00Z", dsun: 215.185117, hgln: 0.000000, hglt: 6.623955 },
  { isoDate: "2021-10-04T00:00:00Z", dsun: 215.124318, hgln: -0.000000, hglt: 6.572150 },
  { isoDate: "2021-10-05T00:00:00Z", dsun: 215.063217, hgln: 0.000000, hglt: 6.518375 },
  { isoDate: "2021-10-06T00:00:00Z", dsun: 215.001791, hgln: 0.000000, hglt: 6.462645 },
  { isoDate: "2021-10-07T00:00:00Z", dsun: 214.940035, hgln: 0.000000, hglt: 6.404972 },
  { isoDate: "2021-10-08T00:00:00Z", dsun: 214.877959, hgln: 0.000000, hglt: 6.345372 },
  { isoDate: "2021-10-09T00:00:00Z", dsun: 214.815598, hgln: 0.000000, hglt: 6.283858 },
  { isoDate: "2021-10-10T00:00:00Z", dsun: 214.753008, hgln: 0.000000, hglt: 6.220446 },
  { isoDate: "2021-10-11T00:00:00Z", dsun: 214.690261, hgln: -0.000000, hglt: 6.155151 },
  { isoDate: "2021-10-12T00:00:00Z", dsun: 214.627444, hgln: 0.000000, hglt: 6.087988 },
  { isoDate: "2021-10-13T00:00:00Z", dsun: 214.564647, hgln: -0.000000, hglt: 6.018975 },
  { isoDate: "2021-10-14T00:00:00Z", dsun: 214.501963, hgln: 0.000000, hglt: 5.948127 },
  { isoDate: "2021-10-15T00:00:00Z", dsun: 214.439479, hgln: 0.000000, hglt: 5.875462 },
  { isoDate: "2021-10-16T00:00:00Z", dsun: 214.377278, hgln: 0.000000, hglt: 5.801000 },
  { isoDate: "2021-10-17T00:00:00Z", dsun: 214.315429, hgln: -0.000000, hglt: 5.724759 },
  { isoDate: "2021-10-18T00:00:00Z", dsun: 214.253993, hgln: 0.000000, hglt: 5.646761 },
  { isoDate: "2021-10-19T00:00:00Z", dsun: 214.193018, hgln: -0.000000, hglt: 5.567024 },
  { isoDate: "2021-10-20T00:00:00Z", dsun: 214.132543, hgln: 0.000000, hglt: 5.485572 },
  { isoDate: "2021-10-21T00:00:00Z", dsun: 214.072592, hgln: 0.000000, hglt: 5.402427 },
  { isoDate: "2021-10-22T00:00:00Z", dsun: 214.013180, hgln: 0.000000, hglt: 5.317612 },
  { isoDate: "2021-10-23T00:00:00Z", dsun: 213.954315, hgln: -0.000000, hglt: 5.231150 },
  { isoDate: "2021-10-24T00:00:00Z", dsun: 213.895993, hgln: 0.000000, hglt: 5.143067 },
  { isoDate: "2021-10-25T00:00:00Z", dsun: 213.838205, hgln: 0.000000, hglt: 5.053386 },
  { isoDate: "2021-10-26T00:00:00Z", dsun: 213.780934, hgln: -0.000000, hglt: 4.962135 },
  { isoDate: "2021-10-27T00:00:00Z", dsun: 213.724161, hgln: 0.000000, hglt: 4.869339 },
  { isoDate: "2021-10-28T00:00:00Z", dsun: 213.667858, hgln: 0.000000, hglt: 4.775024 },
  { isoDate: "2021-10-29T00:00:00Z", dsun: 213.611995, hgln: -0.000000, hglt: 4.679219 },
  { isoDate: "2021-10-30T00:00:00Z", dsun: 213.556535, hgln: 0.000000, hglt: 4.581952 },
  { isoDate: "2021-10-31T00:00:00Z", dsun: 213.501438, hgln: -0.000000, hglt: 4.483249 },
  { isoDate: "2021-11-01T00:00:00Z", dsun: 213.446661, hgln: -0.000000, hglt: 4.383141 },
  { isoDate: "2021-11-02T00:00:00Z", dsun: 213.392159, hgln: 0.000000, hglt: 4.281657 },
  { isoDate: "2021-11-03T00:00:00Z", dsun: 213.337889, hgln: 0.000000, hglt: 4.178827 },
  { isoDate: "2021-11-04T00:00:00Z", dsun: 213.283819, hgln: -0.000000, hglt: 4.074681 },
  { isoDate: "2021-11-05T00:00:00Z", dsun: 213.229930, hgln: 0.000000, hglt: 3.969250 },
  { isoDate: "2021-11-06T00:00:00Z", dsun: 213.176227, hgln: 0.000000, hglt: 3.862565 },
  { isoDate: "2021-11-07T00:00:00Z", dsun: 213.122735, hgln: -0.000000, hglt: 3.754657 },
  { isoDate: "2021-11-08T00:00:00Z", dsun: 213.069508, hgln: 0.000000, hglt: 3.645558 },
  { isoDate: "2021-11-09T00:00:00Z", dsun: 213.016615, hgln: 0.000000, hglt: 3.535300 },
  { isoDate: "2021-11-10T00:00:00Z", dsun: 212.964139, hgln: 0.000000, hglt: 3.423916 },
  { isoDate: "2021-11-11T00:00:00Z", dsun: 212.912169, hgln: 0.000000, hglt: 3.311439 },
  { isoDate: "2021-11-12T00:00:00Z", dsun: 212.860792, hgln: -0.000000, hglt: 3.197902 },
  { isoDate: "2021-11-13T00:00:00Z", dsun: 212.810090, hgln: 0.000000, hglt: 3.083339 },
  { isoDate: "2021-11-14T00:00:00Z", dsun: 212.760140, hgln: 0.000000, hglt: 2.967784 },
  { isoDate: "2021-11-15T00:00:00Z", dsun: 212.711009, hgln: 0.000000, hglt: 2.851272 },
  { isoDate: "2021-11-16T00:00:00Z", dsun: 212.662753, hgln: -0.000000, hglt: 2.733838 },
  { isoDate: "2021-11-17T00:00:00Z", dsun: 212.615421, hgln: 0.000000, hglt: 2.615517 },
  { isoDate: "2021-11-18T00:00:00Z", dsun: 212.569053, hgln: -0.000000, hglt: 2.496347 },
  { isoDate: "2021-11-19T00:00:00Z", dsun: 212.523677, hgln: 0.000000, hglt: 2.376362 },
  { isoDate: "2021-11-20T00:00:00Z", dsun: 212.479313, hgln: 0.000000, hglt: 2.255600 },
  { isoDate: "2021-11-21T00:00:00Z", dsun: 212.435974, hgln: -0.000000, hglt: 2.134097 },
  { isoDate: "2021-11-22T00:00:00Z", dsun: 212.393661, hgln: -0.000000, hglt: 2.011891 },
  { isoDate: "2021-11-23T00:00:00Z", dsun: 212.352370, hgln: -0.000000, hglt: 1.889020 },
  { isoDate: "2021-11-24T00:00:00Z", dsun: 212.312087, hgln: 0.000000, hglt: 1.765521 },
  { isoDate: "2021-11-25T00:00:00Z", dsun: 212.272793, hgln: -0.000000, hglt: 1.641433 },
  { isoDate: "2021-11-26T00:00:00Z", dsun: 212.234459, hgln: 0.000000, hglt: 1.516793 },
  { isoDate: "2021-11-27T00:00:00Z", dsun: 212.197053, hgln: -0.000000, hglt: 1.391640 },
  { isoDate: "2021-11-28T00:00:00Z", dsun: 212.160533, hgln: -0.000000, hglt: 1.266013 },
  { isoDate: "2021-11-29T00:00:00Z", dsun: 212.124851, hgln: 0.000000, hglt: 1.139951 },
  { isoDate: "2021-11-30T00:00:00Z", dsun: 212.089954, hgln: -0.000000, hglt: 1.013493 },
  { isoDate: "2021-12-01T00:00:00Z", dsun: 212.055786, hgln: 0.000000, hglt: 0.886679 },
  { isoDate: "2021-12-02T00:00:00Z", dsun: 212.022292, hgln: 0.000000, hglt: 0.759547 },
  { isoDate: "2021-12-03T00:00:00Z", dsun: 211.989424, hgln: -0.000000, hglt: 0.632138 },
  { isoDate: "2021-12-04T00:00:00Z", dsun: 211.957150, hgln: 0.000000, hglt: 0.504492 },
  { isoDate: "2021-12-05T00:00:00Z", dsun: 211.925458, hgln: -0.000000, hglt: 0.376650 },
  { isoDate: "2021-12-06T00:00:00Z", dsun: 211.894365, hgln: -0.000000, hglt: 0.248652 },
  { isoDate: "2021-12-07T00:00:00Z", dsun: 211.863908, hgln: 0.000000, hglt: 0.120539 },
  { isoDate: "2021-12-08T00:00:00Z", dsun: 211.834147, hgln: -0.000000, hglt: -0.007646 },
  { isoDate: "2021-12-09T00:00:00Z", dsun: 211.805155, hgln: -0.000000, hglt: -0.135863 },
  { isoDate: "2021-12-10T00:00:00Z", dsun: 211.777009, hgln: 0.000000, hglt: -0.264070 },
  { isoDate: "2021-12-11T00:00:00Z", dsun: 211.749786, hgln: 0.000000, hglt: -0.392226 },
  { isoDate: "2021-12-12T00:00:00Z", dsun: 211.723558, hgln: 0.000000, hglt: -0.520289 },
  { isoDate: "2021-12-13T00:00:00Z", dsun: 211.698393, hgln: 0.000000, hglt: -0.648218 },
  { isoDate: "2021-12-14T00:00:00Z", dsun: 211.674348, hgln: 0.000000, hglt: -0.775972 },
  { isoDate: "2021-12-15T00:00:00Z", dsun: 211.651477, hgln: 0.000000, hglt: -0.903509 },
  { isoDate: "2021-12-16T00:00:00Z", dsun: 211.629822, hgln: -0.000000, hglt: -1.030787 },
  { isoDate: "2021-12-17T00:00:00Z", dsun: 211.609421, hgln: -0.000000, hglt: -1.157767 },
  { isoDate: "2021-12-18T00:00:00Z", dsun: 211.590302, hgln: 0.000000, hglt: -1.284407 },
  { isoDate: "2021-12-19T00:00:00Z", dsun: 211.572487, hgln: -0.000000, hglt: -1.410667 },
  { isoDate: "2021-12-20T00:00:00Z", dsun: 211.555987, hgln: 0.000000, hglt: -1.536505 },
  { isoDate: "2021-12-21T00:00:00Z", dsun: 211.540805, hgln: -0.000000, hglt: -1.661881 },
  { isoDate: "2021-12-22T00:00:00Z", dsun: 211.526938, hgln: 0.000000, hglt: -1.786756 },
  { isoDate: "2021-12-23T00:00:00Z", dsun: 211.514370, hgln: 0.000000, hglt: -1.911089 },
  { isoDate: "2021-12-24T00:00:00Z", dsun: 211.503077, hgln: 0.000000, hglt: -2.034841 },
  { isoDate: "2021-12-25T00:00:00Z", dsun: 211.493028, hgln: 0.000000, hglt: -2.157973 },
  { isoDate: "2021-12-26T00:00:00Z", dsun: 211.484181, hgln: 0.000000, hglt: -2.280444 },
  { isoDate: "2021-12-27T00:00:00Z", dsun: 211.476487, hgln: 0.000000, hglt: -2.402218 },
  { isoDate: "2021-12-28T00:00:00Z", dsun: 211.469886, hgln: -0.000000, hglt: -2.523254 },
  { isoDate: "2021-12-29T00:00:00Z", dsun: 211.464314, hgln: 0.000000, hglt: -2.643516 },
  { isoDate: "2021-12-30T00:00:00Z", dsun: 211.459702, hgln: 0.000000, hglt: -2.762964 },
  { isoDate: "2021-12-31T00:00:00Z", dsun: 211.455981, hgln: 0.000000, hglt: -2.881560 },
  { isoDate: "2022-01-01T00:00:00Z", dsun: 211.453089, hgln: 0.000000, hglt: -2.999268 },
  { isoDate: "2022-01-02T00:00:00Z", dsun: 211.450979, hgln: 0.000000, hglt: -3.116048 },
  { isoDate: "2022-01-03T00:00:00Z", dsun: 211.449622, hgln: 0.000000, hglt: -3.231862 },
  { isoDate: "2022-01-04T00:00:00Z", dsun: 211.449018, hgln: 0.000000, hglt: -3.346672 },
  { isoDate: "2022-01-05T00:00:00Z", dsun: 211.449186, hgln: 0.000000, hglt: -3.460440 },
  { isoDate: "2022-01-06T00:00:00Z", dsun: 211.450169, hgln: 0.000000, hglt: -3.573126 },
  { isoDate: "2022-01-07T00:00:00Z", dsun: 211.452020, hgln: 0.000000, hglt: -3.684693 },
  { isoDate: "2022-01-08T00:00:00Z", dsun: 211.454800, hgln: 0.000000, hglt: -3.795104 },
  { isoDate: "2022-01-09T00:00:00Z", dsun: 211.458571, hgln: 0.000000, hglt: -3.904320 },
  { isoDate: "2022-01-10T00:00:00Z", dsun: 211.463393, hgln: -0.000000, hglt: -4.012305 },
  { isoDate: "2022-01-11T00:00:00Z", dsun: 211.469319, hgln: -0.000000, hglt: -4.119023 },
  { isoDate: "2022-01-12T00:00:00Z", dsun: 211.476400, hgln: -0.000000, hglt: -4.224438 },
  { isoDate: "2022-01-13T00:00:00Z", dsun: 211.484680, hgln: 0.000000, hglt: -4.328516 },
  { isoDate: "2022-01-14T00:00:00Z", dsun: 211.494197, hgln: 0.000000, hglt: -4.431222 },
  { isoDate: "2022-01-15T00:00:00Z", dsun: 211.504983, hgln: 0.000000, hglt: -4.532522 },
  { isoDate: "2022-01-16T00:00:00Z", dsun: 211.517065, hgln: 0.000000, hglt: -4.632383 },
  { isoDate: "2022-01-17T00:00:00Z", dsun: 211.530464, hgln: -0.000000, hglt: -4.730773 },
  { isoDate: "2022-01-18T00:00:00Z", dsun: 211.545190, hgln: -0.000000, hglt: -4.827660 },
  { isoDate: "2022-01-19T00:00:00Z", dsun: 211.561248, hgln: 0.000000, hglt: -4.923013 },
  { isoDate: "2022-01-20T00:00:00Z", dsun: 211.578630, hgln: 0.000000, hglt: -5.016802 },
  { isoDate: "2022-01-21T00:00:00Z", dsun: 211.597320, hgln: -0.000000, hglt: -5.108998 },
  { isoDate: "2022-01-22T00:00:00Z", dsun: 211.617289, hgln: -0.000000, hglt: -5.199571 },
  { isoDate: "2022-01-23T00:00:00Z", dsun: 211.638499, hgln: 0.000000, hglt: -5.288494 },
  { isoDate: "2022-01-24T00:00:00Z", dsun: 211.660899, hgln: 0.000000, hglt: -5.375740 },
  { isoDate: "2022-01-25T00:00:00Z", dsun: 211.684428, hgln: 0.000000, hglt: -5.461283 },
  { isoDate: "2022-01-26T00:00:00Z", dsun: 211.709016, hgln: -0.000000, hglt: -5.545097 },
  { isoDate: "2022-01-27T00:00:00Z", dsun: 211.734586, hgln: -0.000000, hglt: -5.627157 },
  { isoDate: "2022-01-28T00:00:00Z", dsun: 211.761058, hgln: -0.000000, hglt: -5.707439 },
  { isoDate: "2022-01-29T00:00:00Z", dsun: 211.788350, hgln: 0.000000, hglt: -5.785918 },
  { isoDate: "2022-01-30T00:00:00Z", dsun: 211.816391, hgln: 0.000000, hglt: -5.862570 },
  { isoDate: "2022-01-31T00:00:00Z", dsun: 211.845120, hgln: -0.000000, hglt: -5.937373 },
  { isoDate: "2022-02-01T00:00:00Z", dsun: 211.874496, hgln: -0.000000, hglt: -6.010303 },
  { isoDate: "2022-02-02T00:00:00Z", dsun: 211.904499, hgln: 0.000000, hglt: -6.081336 },
  { isoDate: "2022-02-03T00:00:00Z", dsun: 211.935131, hgln: 0.000000, hglt: -6.150450 },
  { isoDate: "2022-02-04T00:00:00Z", dsun: 211.966413, hgln: -0.000000, hglt: -6.217621 },
  { isoDate: "2022-02-05T00:00:00Z", dsun: 211.998378, hgln: -0.000000, hglt: -6.282828 },
  { isoDate: "2022-02-06T00:00:00Z", dsun: 212.031069, hgln: 0.000000, hglt: -6.346049 },
  { isoDate: "2022-02-07T00:00:00Z", dsun: 212.064531, hgln: 0.000000, hglt: -6.407263 },
  { isoDate: "2022-02-08T00:00:00Z", dsun: 212.098807, hgln: -0.000000, hglt: -6.466451 },
  { isoDate: "2022-02-09T00:00:00Z", dsun: 212.133941, hgln: 0.000000, hglt: -6.523593 },
  { isoDate: "2022-02-10T00:00:00Z", dsun: 212.169974, hgln: -0.000000, hglt: -6.578673 },
  { isoDate: "2022-02-11T00:00:00Z", dsun: 212.206941, hgln: 0.000000, hglt: -6.631672 },
  { isoDate: "2022-02-12T00:00:00Z", dsun: 212.244877, hgln: -0.000000, hglt: -6.682574 },
  { isoDate: "2022-02-13T00:00:00Z", dsun: 212.283811, hgln: -0.000000, hglt: -6.731365 },
  { isoDate: "2022-02-14T00:00:00Z", dsun: 212.323769, hgln: 0.000000, hglt: -6.778030 },
  { isoDate: "2022-02-15T00:00:00Z", dsun: 212.364772, hgln: 0.000000, hglt: -6.822556 },
  { isoDate: "2022-02-16T00:00:00Z", dsun: 212.406834, hgln: 0.000000, hglt: -6.864930 },
  { isoDate: "2022-02-17T00:00:00Z", dsun: 212.449959, hgln: 0.000000, hglt: -6.905141 },
  { isoDate: "2022-02-18T00:00:00Z", dsun: 212.494143, hgln: -0.000000, hglt: -6.943179 },
  { isoDate: "2022-02-19T00:00:00Z", dsun: 212.539368, hgln: 0.000000, hglt: -6.979035 },
  { isoDate: "2022-02-20T00:00:00Z", dsun: 212.585601, hgln: -0.000000, hglt: -7.012701 },
  { isoDate: "2022-02-21T00:00:00Z", dsun: 212.632799, hgln: 0.000000, hglt: -7.044169 },
  { isoDate: "2022-02-22T00:00:00Z", dsun: 212.680903, hgln: -0.000000, hglt: -7.073434 },
  { isoDate: "2022-02-23T00:00:00Z", dsun: 212.729841, hgln: -0.000000, hglt: -7.100491 },
  { isoDate: "2022-02-24T00:00:00Z", dsun: 212.779534, hgln: 0.000000, hglt: -7.125336 },
  { isoDate: "2022-02-25T00:00:00Z", dsun: 212.829894, hgln: -0.000000, hglt: -7.147964 },
  { isoDate: "2022-02-26T00:00:00Z", dsun: 212.880832, hgln: 0.000000, hglt: -7.168374 },
  { isoDate: "2022-02-27T00:00:00Z", dsun: 212.932260, hgln: 0.000000, hglt: -7.186562 },
  { isoDate: "2022-02-28T00:00:00Z", dsun: 212.984101, hgln: 0.000000, hglt: -7.202528 },
  { isoDate: "2022-03-01T00:00:00Z", dsun: 213.036285, hgln: -0.000000, hglt: -7.216268 },
  { isoDate: "2022-03-02T00:00:00Z", dsun: 213.088764, hgln: 0.000000, hglt: -7.227783 },
  { isoDate: "2022-03-03T00:00:00Z", dsun: 213.141504, hgln: -0.000000, hglt: -7.237071 },
  { isoDate: "2022-03-04T00:00:00Z", dsun: 213.194493, hgln: 0.000000, hglt: -7.244131 },
  { isoDate: "2022-03-05T00:00:00Z", dsun: 213.247732, hgln: -0.000000, hglt: -7.248963 },
  { isoDate: "2022-03-06T00:00:00Z", dsun: 213.301238, hgln: -0.000000, hglt: -7.251568 },
  { isoDate: "2022-03-07T00:00:00Z", dsun: 213.355035, hgln: 0.000000, hglt: -7.251947 },
  { isoDate: "2022-03-08T00:00:00Z", dsun: 213.409152, hgln: -0.000000, hglt: -7.250102 },
  { isoDate: "2022-03-09T00:00:00Z", dsun: 213.463622, hgln: -0.000000, hglt: -7.246036 },
  { isoDate: "2022-03-10T00:00:00Z", dsun: 213.518478, hgln: 0.000000, hglt: -7.239752 },
  { isoDate: "2022-03-11T00:00:00Z", dsun: 213.573752, hgln: 0.000000, hglt: -7.231255 },
  { isoDate: "2022-03-12T00:00:00Z", dsun: 213.629477, hgln: -0.000000, hglt: -7.220551 },
  { isoDate: "2022-03-13T00:00:00Z", dsun: 213.685685, hgln: -0.000000, hglt: -7.207645 },
  { isoDate: "2022-03-14T00:00:00Z", dsun: 213.742406, hgln: -0.000000, hglt: -7.192544 },
  { isoDate: "2022-03-15T00:00:00Z", dsun: 213.799669, hgln: 0.000000, hglt: -7.175256 },
  { isoDate: "2022-03-16T00:00:00Z", dsun: 213.857499, hgln: -0.000000, hglt: -7.155790 },
  { isoDate: "2022-03-17T00:00:00Z", dsun: 213.915916, hgln: -0.000000, hglt: -7.134156 },
  { isoDate: "2022-03-18T00:00:00Z", dsun: 213.974934, hgln: -0.000000, hglt: -7.110364 },
  { isoDate: "2022-03-19T00:00:00Z", dsun: 214.034551, hgln: -0.000000, hglt: -7.084425 },
  { isoDate: "2022-03-20T00:00:00Z", dsun: 214.094754, hgln: 0.000000, hglt: -7.056352 },
  { isoDate: "2022-03-21T00:00:00Z", dsun: 214.155514, hgln: -0.000000, hglt: -7.026158 },
  { isoDate: "2022-03-22T00:00:00Z", dsun: 214.216783, hgln: 0.000000, hglt: -6.993858 },
  { isoDate: "2022-03-23T00:00:00Z", dsun: 214.278497, hgln: -0.000000, hglt: -6.959465 },
  { isoDate: "2022-03-24T00:00:00Z", dsun: 214.340579, hgln: 0.000000, hglt: -6.922997 },
  { isoDate: "2022-03-25T00:00:00Z", dsun: 214.402942, hgln: 0.000000, hglt: -6.884470 },
  { isoDate: "2022-03-26T00:00:00Z", dsun: 214.465494, hgln: 0.000000, hglt: -6.843899 },
  { isoDate: "2022-03-27T00:00:00Z", dsun: 214.528141, hgln: 0.000000, hglt: -6.801304 },
  { isoDate: "2022-03-28T00:00:00Z", dsun: 214.590797, hgln: 0.000000, hglt: -6.756701 },
  { isoDate: "2022-03-29T00:00:00Z", dsun: 214.653380, hgln: 0.000000, hglt: -6.710107 },
  { isoDate: "2022-03-30T00:00:00Z", dsun: 214.715824, hgln: 0.000000, hglt: -6.661543 },
  { isoDate: "2022-03-31T00:00:00Z", dsun: 214.778074, hgln: -0.000000, hglt: -6.611025 },
  { isoDate: "2022-04-01T00:00:00Z", dsun: 214.840092, hgln: -0.000000, hglt: -6.558573 },
  { isoDate: "2022-04-02T00:00:00Z", dsun: 214.901856, hgln: -0.000000, hglt: -6.504206 },
  { isoDate: "2022-04-03T00:00:00Z", dsun: 214.963356, hgln: -0.000000, hglt: -6.447943 },
  { isoDate: "2022-04-04T00:00:00Z", dsun: 215.024595, hgln: 0.000000, hglt: -6.389804 },
  { isoDate: "2022-04-05T00:00:00Z", dsun: 215.085585, hgln: 0.000000, hglt: -6.329809 },
  { isoDate: "2022-04-06T00:00:00Z", dsun: 215.146345, hgln: 0.000000, hglt: -6.267980 },
  { isoDate: "2022-04-07T00:00:00Z", dsun: 215.206897, hgln: -0.000000, hglt: -6.204336 },
  { isoDate: "2022-04-08T00:00:00Z", dsun: 215.267270, hgln: 0.000000, hglt: -6.138901 },
  { isoDate: "2022-04-09T00:00:00Z", dsun: 215.327492, hgln: -0.000000, hglt: -6.071696 },
  { isoDate: "2022-04-10T00:00:00Z", dsun: 215.387595, hgln: -0.000000, hglt: -6.002745 },
  { isoDate: "2022-04-11T00:00:00Z", dsun: 215.447612, hgln: 0.000000, hglt: -5.932070 },
  { isoDate: "2022-04-12T00:00:00Z", dsun: 215.507577, hgln: 0.000000, hglt: -5.859695 },
  { isoDate: "2022-04-13T00:00:00Z", dsun: 215.567527, hgln: -0.000000, hglt: -5.785645 },
  { isoDate: "2022-04-14T00:00:00Z", dsun: 215.627496, hgln: 0.000000, hglt: -5.709944 },
  { isoDate: "2022-04-15T00:00:00Z", dsun: 215.687515, hgln: 0.000000, hglt: -5.632618 },
  { isoDate: "2022-04-16T00:00:00Z", dsun: 215.747607, hgln: -0.000000, hglt: -5.553692 },
  { isoDate: "2022-04-17T00:00:00Z", dsun: 215.807784, hgln: 0.000000, hglt: -5.473192 },
  { isoDate: "2022-04-18T00:00:00Z", dsun: 215.868042, hgln: -0.000000, hglt: -5.391146 },
  { isoDate: "2022-04-19T00:00:00Z", dsun: 215.928356, hgln: 0.000000, hglt: -5.307579 },
  { isoDate: "2022-04-20T00:00:00Z", dsun: 215.988681, hgln: 0.000000, hglt: -5.222520 },
  { isoDate: "2022-04-21T00:00:00Z", dsun: 216.048953, hgln: 0.000000, hglt: -5.135997 },
  { isoDate: "2022-04-22T00:00:00Z", dsun: 216.109093, hgln: -0.000000, hglt: -5.048038 },
  { isoDate: "2022-04-23T00:00:00Z", dsun: 216.169011, hgln: 0.000000, hglt: -4.958672 },
  { isoDate: "2022-04-24T00:00:00Z", dsun: 216.228617, hgln: 0.000000, hglt: -4.867927 },
  { isoDate: "2022-04-25T00:00:00Z", dsun: 216.287821, hgln: 0.000000, hglt: -4.775833 },
  { isoDate: "2022-04-26T00:00:00Z", dsun: 216.346538, hgln: 0.000000, hglt: -4.682418 },
  { isoDate: "2022-04-27T00:00:00Z", dsun: 216.404693, hgln: -0.000000, hglt: -4.587711 },
  { isoDate: "2022-04-28T00:00:00Z", dsun: 216.462222, hgln: 0.000000, hglt: -4.491743 },
  { isoDate: "2022-04-29T00:00:00Z", dsun: 216.519073, hgln: 0.000000, hglt: -4.394542 },
  { isoDate: "2022-04-30T00:00:00Z", dsun: 216.575207, hgln: -0.000000, hglt: -4.296138 },
  { isoDate: "2022-05-01T00:00:00Z", dsun: 216.630598, hgln: -0.000000, hglt: -4.196562 },
  { isoDate: "2022-05-02T00:00:00Z", dsun: 216.685232, hgln: 0.000000, hglt: -4.095842 },
  { isoDate: "2022-05-03T00:00:00Z", dsun: 216.739103, hgln: -0.000000, hglt: -3.994009 },
  { isoDate: "2022-05-04T00:00:00Z", dsun: 216.792217, hgln: -0.000000, hglt: -3.891094 },
  { isoDate: "2022-05-05T00:00:00Z", dsun: 216.844587, hgln: -0.000000, hglt: -3.787127 },
  { isoDate: "2022-05-06T00:00:00Z", dsun: 216.896232, hgln: 0.000000, hglt: -3.682139 },
  { isoDate: "2022-05-07T00:00:00Z", dsun: 216.947176, hgln: -0.000000, hglt: -3.576161 },
  { isoDate: "2022-05-08T00:00:00Z", dsun: 216.997448, hgln: 0.000000, hglt: -3.469224 },
  { isoDate: "2022-05-09T00:00:00Z", dsun: 217.047083, hgln: 0.000000, hglt: -3.361360 },
  { isoDate: "2022-05-10T00:00:00Z", dsun: 217.096117, hgln: 0.000000, hglt: -3.252599 },
  { isoDate: "2022-05-11T00:00:00Z", dsun: 217.144593, hgln: 0.000000, hglt: -3.142974 },
  { isoDate: "2022-05-12T00:00:00Z", dsun: 217.192555, hgln: 0.000000, hglt: -3.032517 },
  { isoDate: "2022-05-13T00:00:00Z", dsun: 217.240050, hgln: 0.000000, hglt: -2.921259 },
  { isoDate: "2022-05-14T00:00:00Z", dsun: 217.287124, hgln: -0.000000, hglt: -2.809233 },
  { isoDate: "2022-05-15T00:00:00Z", dsun: 217.333813, hgln: 0.000000, hglt: -2.696471 },
  { isoDate: "2022-05-16T00:00:00Z", dsun: 217.380143, hgln: -0.000000, hglt: -2.583004 },
  { isoDate: "2022-05-17T00:00:00Z", dsun: 217.426123, hgln: -0.000000, hglt: -2.468864 },
  { isoDate: "2022-05-18T00:00:00Z", dsun: 217.471737, hgln: -0.000000, hglt: -2.354085 },
  { isoDate: "2022-05-19T00:00:00Z", dsun: 217.516945, hgln: 0.000000, hglt: -2.238697 },
  { isoDate: "2022-05-20T00:00:00Z", dsun: 217.561689, hgln: -0.000000, hglt: -2.122732 },
  { isoDate: "2022-05-21T00:00:00Z", dsun: 217.605891, hgln: 0.000000, hglt: -2.006222 },
  { isoDate: "2022-05-22T00:00:00Z", dsun: 217.649468, hgln: -0.000000, hglt: -1.889200 },
  { isoDate: "2022-05-23T00:00:00Z", dsun: 217.692334, hgln: -0.000000, hglt: -1.771697 },
  { isoDate: "2022-05-24T00:00:00Z", dsun: 217.734407, hgln: 0.000000, hglt: -1.653745 },
  { isoDate: "2022-05-25T00:00:00Z", dsun: 217.775609, hgln: 0.000000, hglt: -1.535376 },
  { isoDate: "2022-05-26T00:00:00Z", dsun: 217.815873, hgln: -0.000000, hglt: -1.416623 },
  { isoDate: "2022-05-27T00:00:00Z", dsun: 217.855143, hgln: -0.000000, hglt: -1.297518 },
  { isoDate: "2022-05-28T00:00:00Z", dsun: 217.893370, hgln: -0.000000, hglt: -1.178092 },
  { isoDate: "2022-05-29T00:00:00Z", dsun: 217.930520, hgln: 0.000000, hglt: -1.058379 },
  { isoDate: "2022-05-30T00:00:00Z", dsun: 217.966564, hgln: 0.000000, hglt: -0.938411 },
  { isoDate: "2022-05-31T00:00:00Z", dsun: 218.001488, hgln: 0.000000, hglt: -0.818221 },
  { isoDate: "2022-06-01T00:00:00Z", dsun: 218.035284, hgln: -0.000000, hglt: -0.697841 },
  { isoDate: "2022-06-02T00:00:00Z", dsun: 218.067956, hgln: 0.000000, hglt: -0.577304 },
  { isoDate: "2022-06-03T00:00:00Z", dsun: 218.099514, hgln: 0.000000, hglt: -0.456643 },
  { isoDate: "2022-06-04T00:00:00Z", dsun: 218.129975, hgln: 0.000000, hglt: -0.335890 },
  { isoDate: "2022-06-05T00:00:00Z", dsun: 218.159365, hgln: -0.000000, hglt: -0.215079 },
  { isoDate: "2022-06-06T00:00:00Z", dsun: 218.187716, hgln: 0.000000, hglt: -0.094243 },
  { isoDate: "2022-06-07T00:00:00Z", dsun: 218.215065, hgln: 0.000000, hglt: 0.026586 },
  { isoDate: "2022-06-08T00:00:00Z", dsun: 218.241457, hgln: -0.000000, hglt: 0.147375 },
  { isoDate: "2022-06-09T00:00:00Z", dsun: 218.266942, hgln: 0.000000, hglt: 0.268090 },
  { isoDate: "2022-06-10T00:00:00Z", dsun: 218.291578, hgln: -0.000000, hglt: 0.388699 },
  { isoDate: "2022-06-11T00:00:00Z", dsun: 218.315422, hgln: -0.000000, hglt: 0.509169 },
  { isoDate: "2022-06-12T00:00:00Z", dsun: 218.338534, hgln: 0.000000, hglt: 0.629467 },
  { isoDate: "2022-06-13T00:00:00Z", dsun: 218.360966, hgln: -0.000000, hglt: 0.749562 },
  { isoDate: "2022-06-14T00:00:00Z", dsun: 218.382760, hgln: 0.000000, hglt: 0.869421 },
  { isoDate: "2022-06-15T00:00:00Z", dsun: 218.403934, hgln: 0.000000, hglt: 0.989013 },
  { isoDate: "2022-06-16T00:00:00Z", dsun: 218.424485, hgln: 0.000000, hglt: 1.108308 },
  { isoDate: "2022-06-17T00:00:00Z", dsun: 218.444384, hgln: -0.000000, hglt: 1.227275 },
  { isoDate: "2022-06-18T00:00:00Z", dsun: 218.463578, hgln: -0.000000, hglt: 1.345884 },
  { isoDate: "2022-06-19T00:00:00Z", dsun: 218.482001, hgln: -0.000000, hglt: 1.464105 },
  { isoDate: "2022-06-20T00:00:00Z", dsun: 218.499577, hgln: -0.000000, hglt: 1.581908 },
  { isoDate: "2022-06-21T00:00:00Z", dsun: 218.516231, hgln: -0.000000, hglt: 1.699265 },
  { isoDate: "2022-06-22T00:00:00Z", dsun: 218.531890, hgln: -0.000000, hglt: 1.816144 },
  { isoDate: "2022-06-23T00:00:00Z", dsun: 218.546488, hgln: 0.000000, hglt: 1.932517 },
  { isoDate: "2022-06-24T00:00:00Z", dsun: 218.559966, hgln: 0.000000, hglt: 2.048352 },
  { isoDate: "2022-06-25T00:00:00Z", dsun: 218.572275, hgln: 0.000000, hglt: 2.163621 },
  { isoDate: "2022-06-26T00:00:00Z", dsun: 218.583373, hgln: 0.000000, hglt: 2.278293 },
  { isoDate: "2022-06-27T00:00:00Z", dsun: 218.593227, hgln: 0.000000, hglt: 2.392337 },
  { isoDate: "2022-06-28T00:00:00Z", dsun: 218.601814, hgln: 0.000000, hglt: 2.505724 },
  { isoDate: "2022-06-29T00:00:00Z", dsun: 218.609118, hgln: 0.000000, hglt: 2.618424 },
  { isoDate: "2022-06-30T00:00:00Z", dsun: 218.615131, hgln: 0.000000, hglt: 2.730407 },
  { isoDate: "2022-07-01T00:00:00Z", dsun: 218.619857, hgln: -0.000000, hglt: 2.841641 },
  { isoDate: "2022-07-02T00:00:00Z", dsun: 218.623306, hgln: -0.000000, hglt: 2.952097 },
  { isoDate: "2022-07-03T00:00:00Z", dsun: 218.625496, hgln: -0.000000, hglt: 3.061746 },
  { isoDate: "2022-07-04T00:00:00Z", dsun: 218.626456, hgln: -0.000000, hglt: 3.170555 },
  { isoDate: "2022-07-05T00:00:00Z", dsun: 218.626221, hgln: 0.000000, hglt: 3.278496 },
  { isoDate: "2022-07-06T00:00:00Z", dsun: 218.624835, hgln: 0.000000, hglt: 3.385539 },
  { isoDate: "2022-07-07T00:00:00Z", dsun: 218.622352, hgln: -0.000000, hglt: 3.491652 },
  { isoDate: "2022-07-08T00:00:00Z", dsun: 218.618832, hgln: 0.000000, hglt: 3.596808 },
  { isoDate: "2022-07-09T00:00:00Z", dsun: 218.614340, hgln: 0.000000, hglt: 3.700975 },
  { isoDate: "2022-07-10T00:00:00Z", dsun: 218.608950, hgln: -0.000000, hglt: 3.804125 },
  { isoDate: "2022-07-11T00:00:00Z", dsun: 218.602733, hgln: 0.000000, hglt: 3.906230 },
  { isoDate: "2022-07-12T00:00:00Z", dsun: 218.595755, hgln: 0.000000, hglt: 4.007259 },
  { isoDate: "2022-07-13T00:00:00Z", dsun: 218.588068, hgln: 0.000000, hglt: 4.107188 },
  { isoDate: "2022-07-14T00:00:00Z", dsun: 218.579704, hgln: 0.000000, hglt: 4.205987 },
  { isoDate: "2022-07-15T00:00:00Z", dsun: 218.570672, hgln: -0.000000, hglt: 4.303633 },
  { isoDate: "2022-07-16T00:00:00Z", dsun: 218.560953, hgln: 0.000000, hglt: 4.400100 },
  { isoDate: "2022-07-17T00:00:00Z", dsun: 218.550508, hgln: 0.000000, hglt: 4.495363 },
  { isoDate: "2022-07-18T00:00:00Z", dsun: 218.539283, hgln: -0.000000, hglt: 4.589399 },
  { isoDate: "2022-07-19T00:00:00Z", dsun: 218.527215, hgln: -0.000000, hglt: 4.682183 },
  { isoDate: "2022-07-20T00:00:00Z", dsun: 218.514242, hgln: 0.000000, hglt: 4.773692 },
  { isoDate: "2022-07-21T00:00:00Z", dsun: 218.500302, hgln: 0.000000, hglt: 4.863902 },
  { isoDate: "2022-07-22T00:00:00Z", dsun: 218.485341, hgln: -0.000000, hglt: 4.952788 },
  { isoDate: "2022-07-23T00:00:00Z", dsun: 218.469307, hgln: 0.000000, hglt: 5.040328 },
  { isoDate: "2022-07-24T00:00:00Z", dsun: 218.452160, hgln: 0.000000, hglt: 5.126496 },
  { isoDate: "2022-07-25T00:00:00Z", dsun: 218.433862, hgln: -0.000000, hglt: 5.211270 },
  { isoDate: "2022-07-26T00:00:00Z", dsun: 218.414384, hgln: -0.000000, hglt: 5.294626 },
  { isoDate: "2022-07-27T00:00:00Z", dsun: 218.393705, hgln: 0.000000, hglt: 5.376540 },
  { isoDate: "2022-07-28T00:00:00Z", dsun: 218.371808, hgln: -0.000000, hglt: 5.456988 },
  { isoDate: "2022-07-29T00:00:00Z", dsun: 218.348688, hgln: -0.000000, hglt: 5.535947 },
  { isoDate: "2022-07-30T00:00:00Z", dsun: 218.324346, hgln: -0.000000, hglt: 5.613394 },
  { isoDate: "2022-07-31T00:00:00Z", dsun: 218.298793, hgln: -0.000000, hglt: 5.689305 },
  { isoDate: "2022-08-01T00:00:00Z", dsun: 218.272050, hgln: 0.000000, hglt: 5.763658 },
  { isoDate: "2022-08-02T00:00:00Z", dsun: 218.244147, hgln: -0.000000, hglt: 5.836429 },
  { isoDate: "2022-08-03T00:00:00Z", dsun: 218.215127, hgln: 0.000000, hglt: 5.907595 },
  { isoDate: "2022-08-04T00:00:00Z", dsun: 218.185040, hgln: -0.000000, hglt: 5.977134 },
  { isoDate: "2022-08-05T00:00:00Z", dsun: 218.153947, hgln: 0.000000, hglt: 6.045023 },
  { isoDate: "2022-08-06T00:00:00Z", dsun: 218.121919, hgln: 0.000000, hglt: 6.111240 },
  { isoDate: "2022-08-07T00:00:00Z", dsun: 218.089033, hgln: 0.000000, hglt: 6.175764 },
  { isoDate: "2022-08-08T00:00:00Z", dsun: 218.055372, hgln: -0.000000, hglt: 6.238574 },
  { isoDate: "2022-08-09T00:00:00Z", dsun: 218.021017, hgln: 0.000000, hglt: 6.299649 },
  { isoDate: "2022-08-10T00:00:00Z", dsun: 217.986044, hgln: -0.000000, hglt: 6.358970 },
  { isoDate: "2022-08-11T00:00:00Z", dsun: 217.950515, hgln: -0.000000, hglt: 6.416518 },
  { isoDate: "2022-08-12T00:00:00Z", dsun: 217.914472, hgln: 0.000000, hglt: 6.472276 },
  { isoDate: "2022-08-13T00:00:00Z", dsun: 217.877933, hgln: 0.000000, hglt: 6.526228 },
  { isoDate: "2022-08-14T00:00:00Z", dsun: 217.840894, hgln: -0.000000, hglt: 6.578358 },
  { isoDate: "2022-08-15T00:00:00Z", dsun: 217.803330, hgln: -0.000000, hglt: 6.628651 },
  { isoDate: "2022-08-16T00:00:00Z", dsun: 217.765203, hgln: -0.000000, hglt: 6.677094 },
  { isoDate: "2022-08-17T00:00:00Z", dsun: 217.726464, hgln: 0.000000, hglt: 6.723671 },
  { isoDate: "2022-08-18T00:00:00Z", dsun: 217.687066, hgln: -0.000000, hglt: 6.768371 },
  { isoDate: "2022-08-19T00:00:00Z", dsun: 217.646959, hgln: -0.000000, hglt: 6.811179 },
  { isoDate: "2022-08-20T00:00:00Z", dsun: 217.606097, hgln: 0.000000, hglt: 6.852082 },
  { isoDate: "2022-08-21T00:00:00Z", dsun: 217.564440, hgln: -0.000000, hglt: 6.891067 },
  { isoDate: "2022-08-22T00:00:00Z", dsun: 217.521950, hgln: 0.000000, hglt: 6.928122 },
  { isoDate: "2022-08-23T00:00:00Z", dsun: 217.478595, hgln: 0.000000, hglt: 6.963234 },
  { isoDate: "2022-08-24T00:00:00Z", dsun: 217.434348, hgln: 0.000000, hglt: 6.996391 },
  { isoDate: "2022-08-25T00:00:00Z", dsun: 217.389186, hgln: 0.000000, hglt: 7.027580 },
  { isoDate: "2022-08-26T00:00:00Z", dsun: 217.343093, hgln: 0.000000, hglt: 7.056791 },
  { isoDate: "2022-08-27T00:00:00Z", dsun: 217.296061, hgln: 0.000000, hglt: 7.084011 },
  { isoDate: "2022-08-28T00:00:00Z", dsun: 217.248090, hgln: 0.000000, hglt: 7.109231 },
  { isoDate: "2022-08-29T00:00:00Z", dsun: 217.199189, hgln: -0.000000, hglt: 7.132438 },
  { isoDate: "2022-08-30T00:00:00Z", dsun: 217.149381, hgln: 0.000000, hglt: 7.153623 },
  { isoDate: "2022-08-31T00:00:00Z", dsun: 217.098698, hgln: -0.000000, hglt: 7.172775 },
  { isoDate: "2022-09-01T00:00:00Z", dsun: 217.047187, hgln: -0.000000, hglt: 7.189884 },
  { isoDate: "2022-09-02T00:00:00Z", dsun: 216.994906, hgln: -0.000000, hglt: 7.204941 },
  { isoDate: "2022-09-03T00:00:00Z", dsun: 216.941924, hgln: 0.000000, hglt: 7.217937 },
  { isoDate: "2022-09-04T00:00:00Z", dsun: 216.888321, hgln: -0.000000, hglt: 7.228863 },
  { isoDate: "2022-09-05T00:00:00Z", dsun: 216.834183, hgln: -0.000000, hglt: 7.237711 },
  { isoDate: "2022-09-06T00:00:00Z", dsun: 216.779600, hgln: 0.000000, hglt: 7.244476 },
  { isoDate: "2022-09-07T00:00:00Z", dsun: 216.724661, hgln: -0.000000, hglt: 7.249150 },
  { isoDate: "2022-09-08T00:00:00Z", dsun: 216.669445, hgln: -0.000000, hglt: 7.251729 },
  { isoDate: "2022-09-09T00:00:00Z", dsun: 216.614020, hgln: 0.000000, hglt: 7.252209 },
  { isoDate: "2022-09-10T00:00:00Z", dsun: 216.558436, hgln: -0.000000, hglt: 7.250587 },
  { isoDate: "2022-09-11T00:00:00Z", dsun: 216.502720, hgln: 0.000000, hglt: 7.246861 },
  { isoDate: "2022-09-12T00:00:00Z", dsun: 216.446880, hgln: -0.000000, hglt: 7.241031 },
  { isoDate: "2022-09-13T00:00:00Z", dsun: 216.390906, hgln: -0.000000, hglt: 7.233096 },
  { isoDate: "2022-09-14T00:00:00Z", dsun: 216.334774, hgln: -0.000000, hglt: 7.223057 },
  { isoDate: "2022-09-15T00:00:00Z", dsun: 216.278454, hgln: -0.000000, hglt: 7.210914 },
  { isoDate: "2022-09-16T00:00:00Z", dsun: 216.221908, hgln: 0.000000, hglt: 7.196671 },
  { isoDate: "2022-09-17T00:00:00Z", dsun: 216.165100, hgln: -0.000000, hglt: 7.180328 },
  { isoDate: "2022-09-18T00:00:00Z", dsun: 216.107992, hgln: -0.000000, hglt: 7.161889 },
  { isoDate: "2022-09-19T00:00:00Z", dsun: 216.050550, hgln: -0.000000, hglt: 7.141355 },
  { isoDate: "2022-09-20T00:00:00Z", dsun: 215.992740, hgln: 0.000000, hglt: 7.118732 },
  { isoDate: "2022-09-21T00:00:00Z", dsun: 215.934532, hgln: 0.000000, hglt: 7.094021 },
  { isoDate: "2022-09-22T00:00:00Z", dsun: 215.875897, hgln: 0.000000, hglt: 7.067229 },
  { isoDate: "2022-09-23T00:00:00Z", dsun: 215.816811, hgln: -0.000000, hglt: 7.038359 },
  { isoDate: "2022-09-24T00:00:00Z", dsun: 215.757253, hgln: -0.000000, hglt: 7.007416 },
  { isoDate: "2022-09-25T00:00:00Z", dsun: 215.697210, hgln: -0.000000, hglt: 6.974407 },
  { isoDate: "2022-09-26T00:00:00Z", dsun: 215.636675, hgln: 0.000000, hglt: 6.939338 },
  { isoDate: "2022-09-27T00:00:00Z", dsun: 215.575655, hgln: -0.000000, hglt: 6.902215 },
  { isoDate: "2022-09-28T00:00:00Z", dsun: 215.514169, hgln: 0.000000, hglt: 6.863045 },
  { isoDate: "2022-09-29T00:00:00Z", dsun: 215.452249, hgln: -0.000000, hglt: 6.821835 },
  { isoDate: "2022-09-30T00:00:00Z", dsun: 215.389945, hgln: 0.000000, hglt: 6.778594 },
  { isoDate: "2022-10-01T00:00:00Z", dsun: 215.327321, hgln: -0.000000, hglt: 6.733331 },
  { isoDate: "2022-10-02T00:00:00Z", dsun: 215.264452, hgln: -0.000000, hglt: 6.686053 },
  { isoDate: "2022-10-03T00:00:00Z", dsun: 215.201424, hgln: -0.000000, hglt: 6.636773 },
  { isoDate: "2022-10-04T00:00:00Z", dsun: 215.138330, hgln: -0.000000, hglt: 6.585499 },
  { isoDate: "2022-10-05T00:00:00Z", dsun: 215.075261, hgln: 0.000000, hglt: 6.532245 },
  { isoDate: "2022-10-06T00:00:00Z", dsun: 215.012308, hgln: 0.000000, hglt: 6.477022 },
  { isoDate: "2022-10-07T00:00:00Z", dsun: 214.949552, hgln: 0.000000, hglt: 6.419844 },
  { isoDate: "2022-10-08T00:00:00Z", dsun: 214.887060, hgln: 0.000000, hglt: 6.360725 },
  { isoDate: "2022-10-09T00:00:00Z", dsun: 214.824885, hgln: 0.000000, hglt: 6.299680 },
  { isoDate: "2022-10-10T00:00:00Z", dsun: 214.763062, hgln: 0.000000, hglt: 6.236726 },
  { isoDate: "2022-10-11T00:00:00Z", dsun: 214.701607, hgln: 0.000000, hglt: 6.171879 },
  { isoDate: "2022-10-12T00:00:00Z", dsun: 214.640524, hgln: 0.000000, hglt: 6.105157 },
  { isoDate: "2022-10-13T00:00:00Z", dsun: 214.579801, hgln: 0.000000, hglt: 6.036577 },
  { isoDate: "2022-10-14T00:00:00Z", dsun: 214.519419, hgln: -0.000000, hglt: 5.966160 },
  { isoDate: "2022-10-15T00:00:00Z", dsun: 214.459354, hgln: 0.000000, hglt: 5.893922 },
  { isoDate: "2022-10-16T00:00:00Z", dsun: 214.399577, hgln: -0.000000, hglt: 5.819886 },
  { isoDate: "2022-10-17T00:00:00Z", dsun: 214.340058, hgln: 0.000000, hglt: 5.744070 },
  { isoDate: "2022-10-18T00:00:00Z", dsun: 214.280766, hgln: 0.000000, hglt: 5.666495 },
  { isoDate: "2022-10-19T00:00:00Z", dsun: 214.221668, hgln: 0.000000, hglt: 5.587183 },
  { isoDate: "2022-10-20T00:00:00Z", dsun: 214.162733, hgln: 0.000000, hglt: 5.506155 },
  { isoDate: "2022-10-21T00:00:00Z", dsun: 214.103928, hgln: 0.000000, hglt: 5.423433 },
  { isoDate: "2022-10-22T00:00:00Z", dsun: 214.045223, hgln: 0.000000, hglt: 5.339041 },
  { isoDate: "2022-10-23T00:00:00Z", dsun: 213.986588, hgln: 0.000000, hglt: 5.253001 },
  { isoDate: "2022-10-24T00:00:00Z", dsun: 213.928000, hgln: 0.000000, hglt: 5.165337 },
  { isoDate: "2022-10-25T00:00:00Z", dsun: 213.869443, hgln: 0.000000, hglt: 5.076073 },
  { isoDate: "2022-10-26T00:00:00Z", dsun: 213.810913, hgln: 0.000000, hglt: 4.985235 },
  { isoDate: "2022-10-27T00:00:00Z", dsun: 213.752421, hgln: 0.000000, hglt: 4.892847 },
  { isoDate: "2022-10-28T00:00:00Z", dsun: 213.693996, hgln: 0.000000, hglt: 4.798936 },
  { isoDate: "2022-10-29T00:00:00Z", dsun: 213.635686, hgln: 0.000000, hglt: 4.703527 },
  { isoDate: "2022-10-30T00:00:00Z", dsun: 213.577556, hgln: 0.000000, hglt: 4.606648 },
  { isoDate: "2022-10-31T00:00:00Z", dsun: 213.519685, hgln: 0.000000, hglt: 4.508327 },
  { isoDate: "2022-11-01T00:00:00Z", dsun: 213.462160, hgln: -0.000000, hglt: 4.408592 },
  { isoDate: "2022-11-02T00:00:00Z", dsun: 213.405072, hgln: 0.000000, hglt: 4.307472 },
  { isoDate: "2022-11-03T00:00:00Z", dsun: 213.348513, hgln: 0.000000, hglt: 4.204996 },
  { isoDate: "2022-11-04T00:00:00Z", dsun: 213.292568, hgln: -0.000000, hglt: 4.101195 },
  { isoDate: "2022-11-05T00:00:00Z", dsun: 213.237314, hgln: -0.000000, hglt: 3.996098 },
  { isoDate: "2022-11-06T00:00:00Z", dsun: 213.182814, hgln: 0.000000, hglt: 3.889739 },
  { isoDate: "2022-11-07T00:00:00Z", dsun: 213.129120, hgln: 0.000000, hglt: 3.782147 },
  { isoDate: "2022-11-08T00:00:00Z", dsun: 213.076268, hgln: -0.000000, hglt: 3.673355 },
  { isoDate: "2022-11-09T00:00:00Z", dsun: 213.024281, hgln: -0.000000, hglt: 3.563396 },
  { isoDate: "2022-11-10T00:00:00Z", dsun: 212.973169, hgln: 0.000000, hglt: 3.452302 },
  { isoDate: "2022-11-11T00:00:00Z", dsun: 212.922929, hgln: 0.000000, hglt: 3.340106 },
  { isoDate: "2022-11-12T00:00:00Z", dsun: 212.873550, hgln: -0.000000, hglt: 3.226842 },
  { isoDate: "2022-11-13T00:00:00Z", dsun: 212.825016, hgln: 0.000000, hglt: 3.112544 },
  { isoDate: "2022-11-14T00:00:00Z", dsun: 212.777303, hgln: 0.000000, hglt: 2.997245 },
  { isoDate: "2022-11-15T00:00:00Z", dsun: 212.730383, hgln: 0.000000, hglt: 2.880981 },
  { isoDate: "2022-11-16T00:00:00Z", dsun: 212.684226, hgln: 0.000000, hglt: 2.763785 },
  { isoDate: "2022-11-17T00:00:00Z", dsun: 212.638799, hgln: -0.000000, hglt: 2.645693 },
  { isoDate: "2022-11-18T00:00:00Z", dsun: 212.594063, hgln: 0.000000, hglt: 2.526741 },
  { isoDate: "2022-11-19T00:00:00Z", dsun: 212.549982, hgln: -0.000000, hglt: 2.406964 },
  { isoDate: "2022-11-20T00:00:00Z", dsun: 212.506512, hgln: -0.000000, hglt: 2.286398 },
  { isoDate: "2022-11-21T00:00:00Z", dsun: 212.463614, hgln: 0.000000, hglt: 2.165079 },
  { isoDate: "2022-11-22T00:00:00Z", dsun: 212.421249, hgln: 0.000000, hglt: 2.043045 },
  { isoDate: "2022-11-23T00:00:00Z", dsun: 212.379384, hgln: -0.000000, hglt: 1.920333 },
  { isoDate: "2022-11-24T00:00:00Z", dsun: 212.338002, hgln: 0.000000, hglt: 1.796979 },
  { isoDate: "2022-11-25T00:00:00Z", dsun: 212.297098, hgln: -0.000000, hglt: 1.673024 },
  { isoDate: "2022-11-26T00:00:00Z", dsun: 212.256693, hgln: 0.000000, hglt: 1.548505 },
  { isoDate: "2022-11-27T00:00:00Z", dsun: 212.216826, hgln: -0.000000, hglt: 1.423462 },
  { isoDate: "2022-11-28T00:00:00Z", dsun: 212.177558, hgln: 0.000000, hglt: 1.297936 },
  { isoDate: "2022-11-29T00:00:00Z", dsun: 212.138964, hgln: 0.000000, hglt: 1.171965 },
  { isoDate: "2022-11-30T00:00:00Z", dsun: 212.101127, hgln: 0.000000, hglt: 1.045591 },
  { isoDate: "2022-12-01T00:00:00Z", dsun: 212.064132, hgln: 0.000000, hglt: 0.918855 },
  { isoDate: "2022-12-02T00:00:00Z", dsun: 212.028064, hgln: 0.000000, hglt: 0.791796 },
  { isoDate: "2022-12-03T00:00:00Z", dsun: 211.992997, hgln: 0.000000, hglt: 0.664457 },
  { isoDate: "2022-12-04T00:00:00Z", dsun: 211.958999, hgln: 0.000000, hglt: 0.536877 },
  { isoDate: "2022-12-05T00:00:00Z", dsun: 211.926129, hgln: -0.000000, hglt: 0.409097 },
  { isoDate: "2022-12-06T00:00:00Z", dsun: 211.894430, hgln: 0.000000, hglt: 0.281158 },
  { isoDate: "2022-12-07T00:00:00Z", dsun: 211.863938, hgln: -0.000000, hglt: 0.153100 },
  { isoDate: "2022-12-08T00:00:00Z", dsun: 211.834674, hgln: 0.000000, hglt: 0.024965 },
  { isoDate: "2022-12-09T00:00:00Z", dsun: 211.806652, hgln: -0.000000, hglt: -0.103209 },
  { isoDate: "2022-12-10T00:00:00Z", dsun: 211.779871, hgln: 0.000000, hglt: -0.231380 },
  { isoDate: "2022-12-11T00:00:00Z", dsun: 211.754326, hgln: 0.000000, hglt: -0.359509 },
  { isoDate: "2022-12-12T00:00:00Z", dsun: 211.730001, hgln: 0.000000, hglt: -0.487556 },
  { isoDate: "2022-12-13T00:00:00Z", dsun: 211.706873, hgln: 0.000000, hglt: -0.615479 },
  { isoDate: "2022-12-14T00:00:00Z", dsun: 211.684916, hgln: 0.000000, hglt: -0.743240 },
  { isoDate: "2022-12-15T00:00:00Z", dsun: 211.664095, hgln: 0.000000, hglt: -0.870799 },
  { isoDate: "2022-12-16T00:00:00Z", dsun: 211.644371, hgln: -0.000000, hglt: -0.998116 },
  { isoDate: "2022-12-17T00:00:00Z", dsun: 211.625702, hgln: 0.000000, hglt: -1.125151 },
  { isoDate: "2022-12-18T00:00:00Z", dsun: 211.608037, hgln: 0.000000, hglt: -1.251864 },
  { isoDate: "2022-12-19T00:00:00Z", dsun: 211.591323, hgln: 0.000000, hglt: -1.378216 },
  { isoDate: "2022-12-20T00:00:00Z", dsun: 211.575505, hgln: -0.000000, hglt: -1.504167 },
  { isoDate: "2022-12-21T00:00:00Z", dsun: 211.560526, hgln: 0.000000, hglt: -1.629678 },
  { isoDate: "2022-12-22T00:00:00Z", dsun: 211.546335, hgln: -0.000000, hglt: -1.754708 },
  { isoDate: "2022-12-23T00:00:00Z", dsun: 211.532894, hgln: 0.000000, hglt: -1.879216 },
  { isoDate: "2022-12-24T00:00:00Z", dsun: 211.520182, hgln: -0.000000, hglt: -2.003162 },
  { isoDate: "2022-12-25T00:00:00Z", dsun: 211.508202, hgln: -0.000000, hglt: -2.126505 },
  { isoDate: "2022-12-26T00:00:00Z", dsun: 211.496981, hgln: 0.000000, hglt: -2.249202 },
  { isoDate: "2022-12-27T00:00:00Z", dsun: 211.486567, hgln: 0.000000, hglt: -2.371212 },
  { isoDate: "2022-12-28T00:00:00Z", dsun: 211.477025, hgln: -0.000000, hglt: -2.492493 },
  { isoDate: "2022-12-29T00:00:00Z", dsun: 211.468429, hgln: 0.000000, hglt: -2.613004 },
  { isoDate: "2022-12-30T00:00:00Z", dsun: 211.460851, hgln: 0.000000, hglt: -2.732703 },
  { isoDate: "2022-12-31T00:00:00Z", dsun: 211.454364, hgln: 0.000000, hglt: -2.851550 },
  { isoDate: "2023-01-01T00:00:00Z", dsun: 211.449033, hgln: -0.000000, hglt: -2.969506 },
  { isoDate: "2023-01-02T00:00:00Z", dsun: 211.444912, hgln: 0.000000, hglt: -3.086530 },
  { isoDate: "2023-01-03T00:00:00Z", dsun: 211.442050, hgln: -0.000000, hglt: -3.202585 },
  { isoDate: "2023-01-04T00:00:00Z", dsun: 211.440485, hgln: 0.000000, hglt: -3.317632 },
  { isoDate: "2023-01-05T00:00:00Z", dsun: 211.440247, hgln: 0.000000, hglt: -3.431634 },
  { isoDate: "2023-01-06T00:00:00Z", dsun: 211.441354, hgln: 0.000000, hglt: -3.544554 },
  { isoDate: "2023-01-07T00:00:00Z", dsun: 211.443817, hgln: -0.000000, hglt: -3.656356 },
  { isoDate: "2023-01-08T00:00:00Z", dsun: 211.447638, hgln: 0.000000, hglt: -3.767003 },
  { isoDate: "2023-01-09T00:00:00Z", dsun: 211.452810, hgln: -0.000000, hglt: -3.876463 },
  { isoDate: "2023-01-10T00:00:00Z", dsun: 211.459317, hgln: 0.000000, hglt: -3.984698 },
  { isoDate: "2023-01-11T00:00:00Z", dsun: 211.467135, hgln: 0.000000, hglt: -4.091677 },
  { isoDate: "2023-01-12T00:00:00Z", dsun: 211.476233, hgln: 0.000000, hglt: -4.197365 },
  { isoDate: "2023-01-13T00:00:00Z", dsun: 211.486574, hgln: -0.000000, hglt: -4.301729 },
  { isoDate: "2023-01-14T00:00:00Z", dsun: 211.498111, hgln: -0.000000, hglt: -4.404738 },
  { isoDate: "2023-01-15T00:00:00Z", dsun: 211.510791, hgln: -0.000000, hglt: -4.506360 },
  { isoDate: "2023-01-16T00:00:00Z", dsun: 211.524555, hgln: 0.000000, hglt: -4.606563 },
  { isoDate: "2023-01-17T00:00:00Z", dsun: 211.539336, hgln: -0.000000, hglt: -4.705316 },
  { isoDate: "2023-01-18T00:00:00Z", dsun: 211.555063, hgln: 0.000000, hglt: -4.802589 },
  { isoDate: "2023-01-19T00:00:00Z", dsun: 211.571663, hgln: 0.000000, hglt: -4.898352 },
  { isoDate: "2023-01-20T00:00:00Z", dsun: 211.589067, hgln: 0.000000, hglt: -4.992575 },
  { isoDate: "2023-01-21T00:00:00Z", dsun: 211.607217, hgln: 0.000000, hglt: -5.085226 },
  { isoDate: "2023-01-22T00:00:00Z", dsun: 211.626075, hgln: 0.000000, hglt: -5.176275 },
  { isoDate: "2023-01-23T00:00:00Z", dsun: 211.645623, hgln: -0.000000, hglt: -5.265693 },
  { isoDate: "2023-01-24T00:00:00Z", dsun: 211.665873, hgln: 0.000000, hglt: -5.353447 },
  { isoDate: "2023-01-25T00:00:00Z", dsun: 211.686857, hgln: 0.000000, hglt: -5.439507 },
  { isoDate: "2023-01-26T00:00:00Z", dsun: 211.708623, hgln: -0.000000, hglt: -5.523843 },
  { isoDate: "2023-01-27T00:00:00Z", dsun: 211.731232, hgln: -0.000000, hglt: -5.606427 },
  { isoDate: "2023-01-28T00:00:00Z", dsun: 211.754742, hgln: -0.000000, hglt: -5.687229 },
  { isoDate: "2023-01-29T00:00:00Z", dsun: 211.779212, hgln: -0.000000, hglt: -5.766223 },
  { isoDate: "2023-01-30T00:00:00Z", dsun: 211.804695, hgln: -0.000000, hglt: -5.843382 },
  { isoDate: "2023-01-31T00:00:00Z", dsun: 211.831237, hgln: 0.000000, hglt: -5.918682 },
  { isoDate: "2023-02-01T00:00:00Z", dsun: 211.858876, hgln: 0.000000, hglt: -5.992098 },
  { isoDate: "2023-02-02T00:00:00Z", dsun: 211.887644, hgln: 0.000000, hglt: -6.063607 },
  { isoDate: "2023-02-03T00:00:00Z", dsun: 211.917565, hgln: -0.000000, hglt: -6.133187 },
  { isoDate: "2023-02-04T00:00:00Z", dsun: 211.948657, hgln: -0.000000, hglt: -6.200818 },
  { isoDate: "2023-02-05T00:00:00Z", dsun: 211.980929, hgln: -0.000000, hglt: -6.266478 },
  { isoDate: "2023-02-06T00:00:00Z", dsun: 212.014382, hgln: -0.000000, hglt: -6.330149 },
  { isoDate: "2023-02-07T00:00:00Z", dsun: 212.049009, hgln: -0.000000, hglt: -6.391812 },
  { isoDate: "2023-02-08T00:00:00Z", dsun: 212.084795, hgln: -0.000000, hglt: -6.451451 },
  { isoDate: "2023-02-09T00:00:00Z", dsun: 212.121714, hgln: -0.000000, hglt: -6.509048 },
  { isoDate: "2023-02-10T00:00:00Z", dsun: 212.159733, hgln: -0.000000, hglt: -6.564589 },
  { isoDate: "2023-02-11T00:00:00Z", dsun: 212.198809, hgln: 0.000000, hglt: -6.618058 },
  { isoDate: "2023-02-12T00:00:00Z", dsun: 212.238890, hgln: 0.000000, hglt: -6.669443 },
  { isoDate: "2023-02-13T00:00:00Z", dsun: 212.279914, hgln: 0.000000, hglt: -6.718729 },
  { isoDate: "2023-02-14T00:00:00Z", dsun: 212.321811, hgln: -0.000000, hglt: -6.765905 },
  { isoDate: "2023-02-15T00:00:00Z", dsun: 212.364504, hgln: 0.000000, hglt: -6.810960 },
  { isoDate: "2023-02-16T00:00:00Z", dsun: 212.407909, hgln: 0.000000, hglt: -6.853881 },
  { isoDate: "2023-02-17T00:00:00Z", dsun: 212.451941, hgln: 0.000000, hglt: -6.894658 },
  { isoDate: "2023-02-18T00:00:00Z", dsun: 212.496517, hgln: 0.000000, hglt: -6.933282 },
  { isoDate: "2023-02-19T00:00:00Z", dsun: 212.541567, hgln: -0.000000, hglt: -6.969741 },
  { isoDate: "2023-02-20T00:00:00Z", dsun: 212.587036, hgln: -0.000000, hglt: -7.004026 },
  { isoDate: "2023-02-21T00:00:00Z", dsun: 212.632893, hgln: 0.000000, hglt: -7.036127 },
  { isoDate: "2023-02-22T00:00:00Z", dsun: 212.679133, hgln: 0.000000, hglt: -7.066033 },
  { isoDate: "2023-02-23T00:00:00Z", dsun: 212.725770, hgln: -0.000000, hglt: -7.093736 },
  { isoDate: "2023-02-24T00:00:00Z", dsun: 212.772840, hgln: -0.000000, hglt: -7.119227 },
  { isoDate: "2023-02-25T00:00:00Z", dsun: 212.820383, hgln: 0.000000, hglt: -7.142498 },
  { isoDate: "2023-02-26T00:00:00Z", dsun: 212.868448, hgln: -0.000000, hglt: -7.163544 },
  { isoDate: "2023-02-27T00:00:00Z", dsun: 212.917080, hgln: -0.000000, hglt: -7.182359 },
  { isoDate: "2023-02-28T00:00:00Z", dsun: 212.966323, hgln: 0.000000, hglt: -7.198938 },
  { isoDate: "2023-03-01T00:00:00Z", dsun: 213.016216, hgln: -0.000000, hglt: -7.213279 },
  { isoDate: "2023-03-02T00:00:00Z", dsun: 213.066791, hgln: 0.000000, hglt: -7.225381 },
  { isoDate: "2023-03-03T00:00:00Z", dsun: 213.118077, hgln: -0.000000, hglt: -7.235241 },
  { isoDate: "2023-03-04T00:00:00Z", dsun: 213.170098, hgln: 0.000000, hglt: -7.242860 },
  { isoDate: "2023-03-05T00:00:00Z", dsun: 213.222871, hgln: -0.000000, hglt: -7.248239 },
  { isoDate: "2023-03-06T00:00:00Z", dsun: 213.276407, hgln: 0.000000, hglt: -7.251380 },
  { isoDate: "2023-03-07T00:00:00Z", dsun: 213.330713, hgln: 0.000000, hglt: -7.252287 },
  { isoDate: "2023-03-08T00:00:00Z", dsun: 213.385783, hgln: -0.000000, hglt: -7.250962 },
  { isoDate: "2023-03-09T00:00:00Z", dsun: 213.441607, hgln: -0.000000, hglt: -7.247411 },
  { isoDate: "2023-03-10T00:00:00Z", dsun: 213.498160, hgln: -0.000000, hglt: -7.241641 },
  { isoDate: "2023-03-11T00:00:00Z", dsun: 213.555410, hgln: -0.000000, hglt: -7.233656 },
  { isoDate: "2023-03-12T00:00:00Z", dsun: 213.613312, hgln: 0.000000, hglt: -7.223466 },
  { isoDate: "2023-03-13T00:00:00Z", dsun: 213.671810, hgln: -0.000000, hglt: -7.211077 },
  { isoDate: "2023-03-14T00:00:00Z", dsun: 213.730836, hgln: 0.000000, hglt: -7.196499 },
  { isoDate: "2023-03-15T00:00:00Z", dsun: 213.790313, hgln: 0.000000, hglt: -7.179742 },
  { isoDate: "2023-03-16T00:00:00Z", dsun: 213.850156, hgln: -0.000000, hglt: -7.160815 },
  { isoDate: "2023-03-17T00:00:00Z", dsun: 213.910273, hgln: -0.000000, hglt: -7.139729 },
  { isoDate: "2023-03-18T00:00:00Z", dsun: 213.970574, hgln: -0.000000, hglt: -7.116494 },
  { isoDate: "2023-03-19T00:00:00Z", dsun: 214.030970, hgln: 0.000000, hglt: -7.091123 },
  { isoDate: "2023-03-20T00:00:00Z", dsun: 214.091383, hgln: -0.000000, hglt: -7.063625 },
  { isoDate: "2023-03-21T00:00:00Z", dsun: 214.151755, hgln: 0.000000, hglt: -7.034013 },
  { isoDate: "2023-03-22T00:00:00Z", dsun: 214.212044, hgln: 0.000000, hglt: -7.002298 },
  { isoDate: "2023-03-23T00:00:00Z", dsun: 214.272235, hgln: -0.000000, hglt: -6.968494 },
  { isoDate: "2023-03-24T00:00:00Z", dsun: 214.332329, hgln: -0.000000, hglt: -6.932611 },
  { isoDate: "2023-03-25T00:00:00Z", dsun: 214.392347, hgln: 0.000000, hglt: -6.894665 },
  { isoDate: "2023-03-26T00:00:00Z", dsun: 214.452319, hgln: -0.000000, hglt: -6.854669 },
  { isoDate: "2023-03-27T00:00:00Z", dsun: 214.512280, hgln: 0.000000, hglt: -6.812638 },
  { isoDate: "2023-03-28T00:00:00Z", dsun: 214.572267, hgln: 0.000000, hglt: -6.768588 },
  { isoDate: "2023-03-29T00:00:00Z", dsun: 214.632318, hgln: -0.000000, hglt: -6.722535 },
  { isoDate: "2023-03-30T00:00:00Z", dsun: 214.692467, hgln: 0.000000, hglt: -6.674498 },
  { isoDate: "2023-03-31T00:00:00Z", dsun: 214.752748, hgln: 0.000000, hglt: -6.624494 },
  { isoDate: "2023-04-01T00:00:00Z", dsun: 214.813189, hgln: -0.000000, hglt: -6.572542 },
  { isoDate: "2023-04-02T00:00:00Z", dsun: 214.873817, hgln: 0.000000, hglt: -6.518662 },
  { isoDate: "2023-04-03T00:00:00Z", dsun: 214.934655, hgln: -0.000000, hglt: -6.462873 },
  { isoDate: "2023-04-04T00:00:00Z", dsun: 214.995723, hgln: 0.000000, hglt: -6.405198 },
  { isoDate: "2023-04-05T00:00:00Z", dsun: 215.057032, hgln: 0.000000, hglt: -6.345656 },
  { isoDate: "2023-04-06T00:00:00Z", dsun: 215.118589, hgln: -0.000000, hglt: -6.284270 },
  { isoDate: "2023-04-07T00:00:00Z", dsun: 215.180390, hgln: -0.000000, hglt: -6.221063 },
  { isoDate: "2023-04-08T00:00:00Z", dsun: 215.242418, hgln: 0.000000, hglt: -6.156057 },
  { isoDate: "2023-04-09T00:00:00Z", dsun: 215.304645, hgln: -0.000000, hglt: -6.089277 },
  { isoDate: "2023-04-10T00:00:00Z", dsun: 215.367029, hgln: 0.000000, hglt: -6.020746 },
  { isoDate: "2023-04-11T00:00:00Z", dsun: 215.429512, hgln: 0.000000, hglt: -5.950488 },
  { isoDate: "2023-04-12T00:00:00Z", dsun: 215.492024, hgln: 0.000000, hglt: -5.878528 },
  { isoDate: "2023-04-13T00:00:00Z", dsun: 215.554485, hgln: -0.000000, hglt: -5.804890 },
  { isoDate: "2023-04-14T00:00:00Z", dsun: 215.616805, hgln: -0.000000, hglt: -5.729601 },
  { isoDate: "2023-04-15T00:00:00Z", dsun: 215.678893, hgln: -0.000000, hglt: -5.652684 },
  { isoDate: "2023-04-16T00:00:00Z", dsun: 215.740656, hgln: 0.000000, hglt: -5.574167 },
  { isoDate: "2023-04-17T00:00:00Z", dsun: 215.802008, hgln: 0.000000, hglt: -5.494073 },
  { isoDate: "2023-04-18T00:00:00Z", dsun: 215.862876, hgln: 0.000000, hglt: -5.412429 },
  { isoDate: "2023-04-19T00:00:00Z", dsun: 215.923198, hgln: -0.000000, hglt: -5.329261 },
  { isoDate: "2023-04-20T00:00:00Z", dsun: 215.982935, hgln: 0.000000, hglt: -5.244596 },
  { isoDate: "2023-04-21T00:00:00Z", dsun: 216.042064, hgln: -0.000000, hglt: -5.158460 },
  { isoDate: "2023-04-22T00:00:00Z", dsun: 216.100580, hgln: 0.000000, hglt: -5.070881 },
  { isoDate: "2023-04-23T00:00:00Z", dsun: 216.158494, hgln: 0.000000, hglt: -4.981886 },
  { isoDate: "2023-04-24T00:00:00Z", dsun: 216.215827, hgln: 0.000000, hglt: -4.891503 },
  { isoDate: "2023-04-25T00:00:00Z", dsun: 216.272607, hgln: 0.000000, hglt: -4.799760 },
  { isoDate: "2023-04-26T00:00:00Z", dsun: 216.328864, hgln: 0.000000, hglt: -4.706687 },
  { isoDate: "2023-04-27T00:00:00Z", dsun: 216.384635, hgln: 0.000000, hglt: -4.612313 },
  { isoDate: "2023-04-28T00:00:00Z", dsun: 216.439951, hgln: 0.000000, hglt: -4.516667 },
  { isoDate: "2023-04-29T00:00:00Z", dsun: 216.494850, hgln: -0.000000, hglt: -4.419779 },
  { isoDate: "2023-04-30T00:00:00Z", dsun: 216.549363, hgln: -0.000000, hglt: -4.321679 },
  { isoDate: "2023-05-01T00:00:00Z", dsun: 216.603527, hgln: -0.000000, hglt: -4.222397 },
  { isoDate: "2023-05-02T00:00:00Z", dsun: 216.657371, hgln: -0.000000, hglt: -4.121965 },
  { isoDate: "2023-05-03T00:00:00Z", dsun: 216.710928, hgln: 0.000000, hglt: -4.020412 },
  { isoDate: "2023-05-04T00:00:00Z", dsun: 216.764221, hgln: 0.000000, hglt: -3.917771 },
  { isoDate: "2023-05-05T00:00:00Z", dsun: 216.817272, hgln: 0.000000, hglt: -3.814071 },
  { isoDate: "2023-05-06T00:00:00Z", dsun: 216.870088, hgln: 0.000000, hglt: -3.709344 },
  { isoDate: "2023-05-07T00:00:00Z", dsun: 216.922665, hgln: -0.000000, hglt: -3.603622 },
  { isoDate: "2023-05-08T00:00:00Z", dsun: 216.974983, hgln: -0.000000, hglt: -3.496935 },
  { isoDate: "2023-05-09T00:00:00Z", dsun: 217.027005, hgln: 0.000000, hglt: -3.389313 },
  { isoDate: "2023-05-10T00:00:00Z", dsun: 217.078677, hgln: 0.000000, hglt: -3.280789 },
  { isoDate: "2023-05-11T00:00:00Z", dsun: 217.129928, hgln: 0.000000, hglt: -3.171393 },
  { isoDate: "2023-05-12T00:00:00Z", dsun: 217.180679, hgln: 0.000000, hglt: -3.061155 },
  { isoDate: "2023-05-13T00:00:00Z", dsun: 217.230841, hgln: 0.000000, hglt: -2.950107 },
  { isoDate: "2023-05-14T00:00:00Z", dsun: 217.280326, hgln: -0.000000, hglt: -2.838280 },
  { isoDate: "2023-05-15T00:00:00Z", dsun: 217.329048, hgln: -0.000000, hglt: -2.725704 },
  { isoDate: "2023-05-16T00:00:00Z", dsun: 217.376928, hgln: -0.000000, hglt: -2.612410 },
  { isoDate: "2023-05-17T00:00:00Z", dsun: 217.423899, hgln: 0.000000, hglt: -2.498431 },
  { isoDate: "2023-05-18T00:00:00Z", dsun: 217.469908, hgln: 0.000000, hglt: -2.383799 },
  { isoDate: "2023-05-19T00:00:00Z", dsun: 217.514916, hgln: -0.000000, hglt: -2.268544 },
  { isoDate: "2023-05-20T00:00:00Z", dsun: 217.558902, hgln: 0.000000, hglt: -2.152701 },
  { isoDate: "2023-05-21T00:00:00Z", dsun: 217.601859, hgln: 0.000000, hglt: -2.036301 },
  { isoDate: "2023-05-22T00:00:00Z", dsun: 217.643791, hgln: 0.000000, hglt: -1.919377 },
  { isoDate: "2023-05-23T00:00:00Z", dsun: 217.684714, hgln: 0.000000, hglt: -1.801962 },
  { isoDate: "2023-05-24T00:00:00Z", dsun: 217.724652, hgln: -0.000000, hglt: -1.684091 },
  { isoDate: "2023-05-25T00:00:00Z", dsun: 217.763633, hgln: -0.000000, hglt: -1.565795 },
  { isoDate: "2023-05-26T00:00:00Z", dsun: 217.801692, hgln: -0.000000, hglt: -1.447109 },
  { isoDate: "2023-05-27T00:00:00Z", dsun: 217.838863, hgln: -0.000000, hglt: -1.328065 },
  { isoDate: "2023-05-28T00:00:00Z", dsun: 217.875186, hgln: -0.000000, hglt: -1.208698 },
  { isoDate: "2023-05-29T00:00:00Z", dsun: 217.910701, hgln: -0.000000, hglt: -1.089041 },
  { isoDate: "2023-05-30T00:00:00Z", dsun: 217.945451, hgln: -0.000000, hglt: -0.969127 },
  { isoDate: "2023-05-31T00:00:00Z", dsun: 217.979480, hgln: -0.000000, hglt: -0.848989 },
  { isoDate: "2023-06-01T00:00:00Z", dsun: 218.012832, hgln: -0.000000, hglt: -0.728662 },
  { isoDate: "2023-06-02T00:00:00Z", dsun: 218.045548, hgln: -0.000000, hglt: -0.608177 },
  { isoDate: "2023-06-03T00:00:00Z", dsun: 218.077663, hgln: 0.000000, hglt: -0.487568 },
  { isoDate: "2023-06-04T00:00:00Z", dsun: 218.109202, hgln: 0.000000, hglt: -0.366866 },
  { isoDate: "2023-06-05T00:00:00Z", dsun: 218.140176, hgln: -0.000000, hglt: -0.246105 },
  { isoDate: "2023-06-06T00:00:00Z", dsun: 218.170575, hgln: -0.000000, hglt: -0.125314 },
  { isoDate: "2023-06-07T00:00:00Z", dsun: 218.200371, hgln: 0.000000, hglt: -0.004526 },
  { isoDate: "2023-06-08T00:00:00Z", dsun: 218.229514, hgln: -0.000000, hglt: 0.116231 },
  { isoDate: "2023-06-09T00:00:00Z", dsun: 218.257938, hgln: 0.000000, hglt: 0.236924 },
  { isoDate: "2023-06-10T00:00:00Z", dsun: 218.285566, hgln: 0.000000, hglt: 0.357525 },
  { isoDate: "2023-06-11T00:00:00Z", dsun: 218.312315, hgln: -0.000000, hglt: 0.478003 },
  { isoDate: "2023-06-12T00:00:00Z", dsun: 218.338105, hgln: -0.000000, hglt: 0.598328 },
  { isoDate: "2023-06-13T00:00:00Z", dsun: 218.362859, hgln: 0.000000, hglt: 0.718469 },
  { isoDate: "2023-06-14T00:00:00Z", dsun: 218.386509, hgln: 0.000000, hglt: 0.838395 },
  { isoDate: "2023-06-15T00:00:00Z", dsun: 218.408996, hgln: -0.000000, hglt: 0.958075 },
  { isoDate: "2023-06-16T00:00:00Z", dsun: 218.430277, hgln: 0.000000, hglt: 1.077477 },
  { isoDate: "2023-06-17T00:00:00Z", dsun: 218.450318, hgln: 0.000000, hglt: 1.196571 },
  { isoDate: "2023-06-18T00:00:00Z", dsun: 218.469100, hgln: 0.000000, hglt: 1.315323 },
  { isoDate: "2023-06-19T00:00:00Z", dsun: 218.486616, hgln: 0.000000, hglt: 1.433702 },
  { isoDate: "2023-06-20T00:00:00Z", dsun: 218.502870, hgln: -0.000000, hglt: 1.551676 },
  { isoDate: "2023-06-21T00:00:00Z", dsun: 218.517875, hgln: 0.000000, hglt: 1.669212 },
  { isoDate: "2023-06-22T00:00:00Z", dsun: 218.531651, hgln: -0.000000, hglt: 1.786278 },
  { isoDate: "2023-06-23T00:00:00Z", dsun: 218.544228, hgln: -0.000000, hglt: 1.902842 },
  { isoDate: "2023-06-24T00:00:00Z", dsun: 218.555639, hgln: 0.000000, hglt: 2.018871 },
  { isoDate: "2023-06-25T00:00:00Z", dsun: 218.565924, hgln: 0.000000, hglt: 2.134334 },
  { isoDate: "2023-06-26T00:00:00Z", dsun: 218.575125, hgln: 0.000000, hglt: 2.249199 },
  { isoDate: "2023-06-27T00:00:00Z", dsun: 218.583291, hgln: 0.000000, hglt: 2.363433 },
  { isoDate: "2023-06-28T00:00:00Z", dsun: 218.590474, hgln: 0.000000, hglt: 2.477006 },
  { isoDate: "2023-06-29T00:00:00Z", dsun: 218.596731, hgln: 0.000000, hglt: 2.589886 },
  { isoDate: "2023-06-30T00:00:00Z", dsun: 218.602118, hgln: 0.000000, hglt: 2.702042 },
  { isoDate: "2023-07-01T00:00:00Z", dsun: 218.606694, hgln: -0.000000, hglt: 2.813444 },
  { isoDate: "2023-07-02T00:00:00Z", dsun: 218.610511, hgln: 0.000000, hglt: 2.924062 },
  { isoDate: "2023-07-03T00:00:00Z", dsun: 218.613611, hgln: -0.000000, hglt: 3.033867 },
  { isoDate: "2023-07-04T00:00:00Z", dsun: 218.616020, hgln: 0.000000, hglt: 3.142830 },
  { isoDate: "2023-07-05T00:00:00Z", dsun: 218.617740, hgln: 0.000000, hglt: 3.250925 },
  { isoDate: "2023-07-06T00:00:00Z", dsun: 218.618754, hgln: 0.000000, hglt: 3.358125 },
  { isoDate: "2023-07-07T00:00:00Z", dsun: 218.619017, hgln: 0.000000, hglt: 3.464404 },
  { isoDate: "2023-07-08T00:00:00Z", dsun: 218.618471, hgln: -0.000000, hglt: 3.569736 },
  { isoDate: "2023-07-09T00:00:00Z", dsun: 218.617046, hgln: 0.000000, hglt: 3.674096 },
  { isoDate: "2023-07-10T00:00:00Z", dsun: 218.614669, hgln: -0.000000, hglt: 3.777458 },
  { isoDate: "2023-07-11T00:00:00Z", dsun: 218.611267, hgln: -0.000000, hglt: 3.879795 },
  { isoDate: "2023-07-12T00:00:00Z", dsun: 218.606775, hgln: 0.000000, hglt: 3.981082 },
  { isoDate: "2023-07-13T00:00:00Z", dsun: 218.601135, hgln: 0.000000, hglt: 4.081292 },
  { isoDate: "2023-07-14T00:00:00Z", dsun: 218.594301, hgln: -0.000000, hglt: 4.180398 },
  { isoDate: "2023-07-15T00:00:00Z", dsun: 218.586234, hgln: -0.000000, hglt: 4.278373 },
  { isoDate: "2023-07-16T00:00:00Z", dsun: 218.576909, hgln: -0.000000, hglt: 4.375191 },
  { isoDate: "2023-07-17T00:00:00Z", dsun: 218.566307, hgln: 0.000000, hglt: 4.470823 },
  { isoDate: "2023-07-18T00:00:00Z", dsun: 218.554425, hgln: 0.000000, hglt: 4.565243 },
  { isoDate: "2023-07-19T00:00:00Z", dsun: 218.541264, hgln: 0.000000, hglt: 4.658424 },
  { isoDate: "2023-07-20T00:00:00Z", dsun: 218.526837, hgln: 0.000000, hglt: 4.750339 },
  { isoDate: "2023-07-21T00:00:00Z", dsun: 218.511165, hgln: 0.000000, hglt: 4.840959 },
  { isoDate: "2023-07-22T00:00:00Z", dsun: 218.494277, hgln: -0.000000, hglt: 4.930260 },
  { isoDate: "2023-07-23T00:00:00Z", dsun: 218.476208, hgln: 0.000000, hglt: 5.018213 },
  { isoDate: "2023-07-24T00:00:00Z", dsun: 218.457000, hgln: 0.000000, hglt: 5.104793 },
  { isoDate: "2023-07-25T00:00:00Z", dsun: 218.436702, hgln: -0.000000, hglt: 5.189974 },
  { isoDate: "2023-07-26T00:00:00Z", dsun: 218.415370, hgln: 0.000000, hglt: 5.273730 },
  { isoDate: "2023-07-27T00:00:00Z", dsun: 218.393064, hgln: -0.000000, hglt: 5.356035 },
  { isoDate: "2023-07-28T00:00:00Z", dsun: 218.369853, hgln: -0.000000, hglt: 5.436864 },
  { isoDate: "2023-07-29T00:00:00Z", dsun: 218.345808, hgln: 0.000000, hglt: 5.516194 },
  { isoDate: "2023-07-30T00:00:00Z", dsun: 218.321000, hgln: 0.000000, hglt: 5.594001 },
  { isoDate: "2023-07-31T00:00:00Z", dsun: 218.295499, hgln: 0.000000, hglt: 5.670261 },
  { isoDate: "2023-08-01T00:00:00Z", dsun: 218.269361, hgln: -0.000000, hglt: 5.744954 },
  { isoDate: "2023-08-02T00:00:00Z", dsun: 218.242627, hgln: 0.000000, hglt: 5.818059 },
  { isoDate: "2023-08-03T00:00:00Z", dsun: 218.215313, hgln: -0.000000, hglt: 5.889556 },
  { isoDate: "2023-08-04T00:00:00Z", dsun: 218.187410, hgln: 0.000000, hglt: 5.959427 },
  { isoDate: "2023-08-05T00:00:00Z", dsun: 218.158885, hgln: 0.000000, hglt: 6.027654 },
  { isoDate: "2023-08-06T00:00:00Z", dsun: 218.129687, hgln: 0.000000, hglt: 6.094220 },
  { isoDate: "2023-08-07T00:00:00Z", dsun: 218.099757, hgln: -0.000000, hglt: 6.159108 },
  { isoDate: "2023-08-08T00:00:00Z", dsun: 218.069032, hgln: -0.000000, hglt: 6.222299 },
  { isoDate: "2023-08-09T00:00:00Z", dsun: 218.037450, hgln: 0.000000, hglt: 6.283776 },
  { isoDate: "2023-08-10T00:00:00Z", dsun: 218.004958, hgln: 0.000000, hglt: 6.343521 },
  { isoDate: "2023-08-11T00:00:00Z", dsun: 217.971507, hgln: 0.000000, hglt: 6.401517 },
  { isoDate: "2023-08-12T00:00:00Z", dsun: 217.937057, hgln: 0.000000, hglt: 6.457746 },
  { isoDate: "2023-08-13T00:00:00Z", dsun: 217.901579, hgln: 0.000000, hglt: 6.512190 },
  { isoDate: "2023-08-14T00:00:00Z", dsun: 217.865048, hgln: 0.000000, hglt: 6.564831 },
  { isoDate: "2023-08-15T00:00:00Z", dsun: 217.827452, hgln: 0.000000, hglt: 6.615652 },
  { isoDate: "2023-08-16T00:00:00Z", dsun: 217.788785, hgln: -0.000000, hglt: 6.664636 },
  { isoDate: "2023-08-17T00:00:00Z", dsun: 217.749049, hgln: 0.000000, hglt: 6.711765 },
  { isoDate: "2023-08-18T00:00:00Z", dsun: 217.708257, hgln: 0.000000, hglt: 6.757022 },
  { isoDate: "2023-08-19T00:00:00Z", dsun: 217.666429, hgln: 0.000000, hglt: 6.800391 },
  { isoDate: "2023-08-20T00:00:00Z", dsun: 217.623593, hgln: -0.000000, hglt: 6.841857 },
  { isoDate: "2023-08-21T00:00:00Z", dsun: 217.579787, hgln: -0.000000, hglt: 6.881402 },
  { isoDate: "2023-08-22T00:00:00Z", dsun: 217.535056, hgln: -0.000000, hglt: 6.919012 },
  { isoDate: "2023-08-23T00:00:00Z", dsun: 217.489454, hgln: -0.000000, hglt: 6.954673 },
  { isoDate: "2023-08-24T00:00:00Z", dsun: 217.443043, hgln: -0.000000, hglt: 6.988368 },
  { isoDate: "2023-08-25T00:00:00Z", dsun: 217.395893, hgln: -0.000000, hglt: 7.020087 },
  { isoDate: "2023-08-26T00:00:00Z", dsun: 217.348082, hgln: -0.000000, hglt: 7.049814 },
  { isoDate: "2023-08-27T00:00:00Z", dsun: 217.299693, hgln: 0.000000, hglt: 7.077538 },
  { isoDate: "2023-08-28T00:00:00Z", dsun: 217.250809, hgln: -0.000000, hglt: 7.103248 },
  { isoDate: "2023-08-29T00:00:00Z", dsun: 217.201511, hgln: -0.000000, hglt: 7.126933 },
  { isoDate: "2023-08-30T00:00:00Z", dsun: 217.151869, hgln: 0.000000, hglt: 7.148585 },
  { isoDate: "2023-08-31T00:00:00Z", dsun: 217.101935, hgln: -0.000000, hglt: 7.168197 },
  { isoDate: "2023-09-01T00:00:00Z", dsun: 217.051736, hgln: -0.000000, hglt: 7.185760 },
  { isoDate: "2023-09-02T00:00:00Z", dsun: 217.001273, hgln: -0.000000, hglt: 7.201270 },
  { isoDate: "2023-09-03T00:00:00Z", dsun: 216.950526, hgln: -0.000000, hglt: 7.214722 },
  { isoDate: "2023-09-04T00:00:00Z", dsun: 216.899454, hgln: 0.000000, hglt: 7.226111 },
  { isoDate: "2023-09-05T00:00:00Z", dsun: 216.848012, hgln: 0.000000, hglt: 7.235433 },
  { isoDate: "2023-09-06T00:00:00Z", dsun: 216.796145, hgln: 0.000000, hglt: 7.242683 },
  { isoDate: "2023-09-07T00:00:00Z", dsun: 216.743805, hgln: -0.000000, hglt: 7.247857 },
  { isoDate: "2023-09-08T00:00:00Z", dsun: 216.690944, hgln: -0.000000, hglt: 7.250951 },
  { isoDate: "2023-09-09T00:00:00Z", dsun: 216.637524, hgln: 0.000000, hglt: 7.251962 },
  { isoDate: "2023-09-10T00:00:00Z", dsun: 216.583510, hgln: -0.000000, hglt: 7.250887 },
  { isoDate: "2023-09-11T00:00:00Z", dsun: 216.528875, hgln: -0.000000, hglt: 7.247721 },
  { isoDate: "2023-09-12T00:00:00Z", dsun: 216.473598, hgln: -0.000000, hglt: 7.242463 },
  { isoDate: "2023-09-13T00:00:00Z", dsun: 216.417664, hgln: -0.000000, hglt: 7.235110 },
  { isoDate: "2023-09-14T00:00:00Z", dsun: 216.361067, hgln: -0.000000, hglt: 7.225659 },
  { isoDate: "2023-09-15T00:00:00Z", dsun: 216.303807, hgln: -0.000000, hglt: 7.214111 },
  { isoDate: "2023-09-16T00:00:00Z", dsun: 216.245893, hgln: -0.000000, hglt: 7.200463 },
  { isoDate: "2023-09-17T00:00:00Z", dsun: 216.187342, hgln: -0.000000, hglt: 7.184715 },
  { isoDate: "2023-09-18T00:00:00Z", dsun: 216.128182, hgln: -0.000000, hglt: 7.166867 },
  { isoDate: "2023-09-19T00:00:00Z", dsun: 216.068450, hgln: -0.000000, hglt: 7.146921 },
  { isoDate: "2023-09-20T00:00:00Z", dsun: 216.008194, hgln: -0.000000, hglt: 7.124877 },
  { isoDate: "2023-09-21T00:00:00Z", dsun: 215.947470, hgln: -0.000000, hglt: 7.100738 },
  { isoDate: "2023-09-22T00:00:00Z", dsun: 215.886346, hgln: -0.000000, hglt: 7.074506 },
  { isoDate: "2023-09-23T00:00:00Z", dsun: 215.824898, hgln: -0.000000, hglt: 7.046185 },
  { isoDate: "2023-09-24T00:00:00Z", dsun: 215.763212, hgln: -0.000000, hglt: 7.015779 },
  { isoDate: "2023-09-25T00:00:00Z", dsun: 215.701378, hgln: 0.000000, hglt: 6.983295 },
  { isoDate: "2023-09-26T00:00:00Z", dsun: 215.639486, hgln: -0.000000, hglt: 6.948737 },
  { isoDate: "2023-09-27T00:00:00Z", dsun: 215.577623, hgln: -0.000000, hglt: 6.912115 },
  { isoDate: "2023-09-28T00:00:00Z", dsun: 215.515867, hgln: 0.000000, hglt: 6.873436 },
  { isoDate: "2023-09-29T00:00:00Z", dsun: 215.454276, hgln: -0.000000, hglt: 6.832709 },
  { isoDate: "2023-09-30T00:00:00Z", dsun: 215.392883, hgln: 0.000000, hglt: 6.789945 },
  { isoDate: "2023-10-01T00:00:00Z", dsun: 215.331702, hgln: -0.000000, hglt: 6.745155 },
  { isoDate: "2023-10-02T00:00:00Z", dsun: 215.270721, hgln: -0.000000, hglt: 6.698351 },
  { isoDate: "2023-10-03T00:00:00Z", dsun: 215.209914, hgln: 0.000000, hglt: 6.649544 },
  { isoDate: "2023-10-04T00:00:00Z", dsun: 215.149244, hgln: -0.000000, hglt: 6.598747 },
  { isoDate: "2023-10-05T00:00:00Z", dsun: 215.088669, hgln: 0.000000, hglt: 6.545972 },
  { isoDate: "2023-10-06T00:00:00Z", dsun: 215.028148, hgln: -0.000000, hglt: 6.491233 },
  { isoDate: "2023-10-07T00:00:00Z", dsun: 214.967641, hgln: 0.000000, hglt: 6.434543 },
  { isoDate: "2023-10-08T00:00:00Z", dsun: 214.907113, hgln: -0.000000, hglt: 6.375916 },
  { isoDate: "2023-10-09T00:00:00Z", dsun: 214.846532, hgln: -0.000000, hglt: 6.315367 },
  { isoDate: "2023-10-10T00:00:00Z", dsun: 214.785869, hgln: 0.000000, hglt: 6.252910 },
  { isoDate: "2023-10-11T00:00:00Z", dsun: 214.725102, hgln: -0.000000, hglt: 6.188560 },
  { isoDate: "2023-10-12T00:00:00Z", dsun: 214.664213, hgln: 0.000000, hglt: 6.122334 },
  { isoDate: "2023-10-13T00:00:00Z", dsun: 214.603188, hgln: 0.000000, hglt: 6.054249 },
  { isoDate: "2023-10-14T00:00:00Z", dsun: 214.542021, hgln: 0.000000, hglt: 5.984320 },
  { isoDate: "2023-10-15T00:00:00Z", dsun: 214.480714, hgln: 0.000000, hglt: 5.912567 },
  { isoDate: "2023-10-16T00:00:00Z", dsun: 214.419278, hgln: 0.000000, hglt: 5.839006 },
  { isoDate: "2023-10-17T00:00:00Z", dsun: 214.357735, hgln: -0.000000, hglt: 5.763659 },
  { isoDate: "2023-10-18T00:00:00Z", dsun: 214.296117, hgln: 0.000000, hglt: 5.686543 },
  { isoDate: "2023-10-19T00:00:00Z", dsun: 214.234472, hgln: 0.000000, hglt: 5.607680 },
  { isoDate: "2023-10-20T00:00:00Z", dsun: 214.172856, hgln: 0.000000, hglt: 5.527091 },
  { isoDate: "2023-10-21T00:00:00Z", dsun: 214.111341, hgln: -0.000000, hglt: 5.444798 },
  { isoDate: "2023-10-22T00:00:00Z", dsun: 214.050005, hgln: 0.000000, hglt: 5.360824 },
  { isoDate: "2023-10-23T00:00:00Z", dsun: 213.988936, hgln: 0.000000, hglt: 5.275193 },
  { isoDate: "2023-10-24T00:00:00Z", dsun: 213.928226, hgln: 0.000000, hglt: 5.187929 },
  { isoDate: "2023-10-25T00:00:00Z", dsun: 213.867968, hgln: 0.000000, hglt: 5.099057 },
  { isoDate: "2023-10-26T00:00:00Z", dsun: 213.808248, hgln: 0.000000, hglt: 5.008603 },
  { isoDate: "2023-10-27T00:00:00Z", dsun: 213.749143, hgln: -0.000000, hglt: 4.916593 },
  { isoDate: "2023-10-28T00:00:00Z", dsun: 213.690709, hgln: 0.000000, hglt: 4.823055 },
  { isoDate: "2023-10-29T00:00:00Z", dsun: 213.632987, hgln: 0.000000, hglt: 4.728015 },
  { isoDate: "2023-10-30T00:00:00Z", dsun: 213.575995, hgln: -0.000000, hglt: 4.631500 },
  { isoDate: "2023-10-31T00:00:00Z", dsun: 213.519729, hgln: 0.000000, hglt: 4.533539 },
  { isoDate: "2023-11-01T00:00:00Z", dsun: 213.464174, hgln: 0.000000, hglt: 4.434160 },
  { isoDate: "2023-11-02T00:00:00Z", dsun: 213.409303, hgln: 0.000000, hglt: 4.333391 },
  { isoDate: "2023-11-03T00:00:00Z", dsun: 213.355081, hgln: -0.000000, hglt: 4.231262 },
  { isoDate: "2023-11-04T00:00:00Z", dsun: 213.301474, hgln: -0.000000, hglt: 4.127800 },
  { isoDate: "2023-11-05T00:00:00Z", dsun: 213.248445, hgln: 0.000000, hglt: 4.023036 },
  { isoDate: "2023-11-06T00:00:00Z", dsun: 213.195958, hgln: -0.000000, hglt: 3.916999 },
  { isoDate: "2023-11-07T00:00:00Z", dsun: 213.143980, hgln: 0.000000, hglt: 3.809721 },
  { isoDate: "2023-11-08T00:00:00Z", dsun: 213.092480, hgln: 0.000000, hglt: 3.701232 },
  { isoDate: "2023-11-09T00:00:00Z", dsun: 213.041426, hgln: -0.000000, hglt: 3.591564 },
  { isoDate: "2023-11-10T00:00:00Z", dsun: 212.990792, hgln: -0.000000, hglt: 3.480747 },
  { isoDate: "2023-11-11T00:00:00Z", dsun: 212.940554, hgln: 0.000000, hglt: 3.368816 },
  { isoDate: "2023-11-12T00:00:00Z", dsun: 212.890693, hgln: -0.000000, hglt: 3.255802 },
  { isoDate: "2023-11-13T00:00:00Z", dsun: 212.841198, hgln: -0.000000, hglt: 3.141740 },
  { isoDate: "2023-11-14T00:00:00Z", dsun: 212.792066, hgln: 0.000000, hglt: 3.026662 },
  { isoDate: "2023-11-15T00:00:00Z", dsun: 212.743309, hgln: -0.000000, hglt: 2.910606 },
  { isoDate: "2023-11-16T00:00:00Z", dsun: 212.694952, hgln: 0.000000, hglt: 2.793605 },
  { isoDate: "2023-11-17T00:00:00Z", dsun: 212.647032, hgln: -0.000000, hglt: 2.675697 },
  { isoDate: "2023-11-18T00:00:00Z", dsun: 212.599607, hgln: 0.000000, hglt: 2.556917 },
  { isoDate: "2023-11-19T00:00:00Z", dsun: 212.552742, hgln: 0.000000, hglt: 2.437305 },
  { isoDate: "2023-11-20T00:00:00Z", dsun: 212.506517, hgln: -0.000000, hglt: 2.316897 },
  { isoDate: "2023-11-21T00:00:00Z", dsun: 212.461017, hgln: -0.000000, hglt: 2.195732 },
  { isoDate: "2023-11-22T00:00:00Z", dsun: 212.416331, hgln: 0.000000, hglt: 2.073849 },
  { isoDate: "2023-11-23T00:00:00Z", dsun: 212.372543, hgln: 0.000000, hglt: 1.951286 },
  { isoDate: "2023-11-24T00:00:00Z", dsun: 212.329735, hgln: 0.000000, hglt: 1.828083 },
  { isoDate: "2023-11-25T00:00:00Z", dsun: 212.287974, hgln: -0.000000, hglt: 1.704279 },
  { isoDate: "2023-11-26T00:00:00Z", dsun: 212.247315, hgln: 0.000000, hglt: 1.579910 },
  { isoDate: "2023-11-27T00:00:00Z", dsun: 212.207793, hgln: -0.000000, hglt: 1.455018 },
  { isoDate: "2023-11-28T00:00:00Z", dsun: 212.169428, hgln: -0.000000, hglt: 1.329639 },
  { isoDate: "2023-11-29T00:00:00Z", dsun: 212.132224, hgln: 0.000000, hglt: 1.203811 },
  { isoDate: "2023-11-30T00:00:00Z", dsun: 212.096169, hgln: 0.000000, hglt: 1.077574 },
  { isoDate: "2023-12-01T00:00:00Z", dsun: 212.061243, hgln: -0.000000, hglt: 0.950965 },
  { isoDate: "2023-12-02T00:00:00Z", dsun: 212.027416, hgln: -0.000000, hglt: 0.824023 },
  { isoDate: "2023-12-03T00:00:00Z", dsun: 211.994655, hgln: 0.000000, hglt: 0.696786 },
  { isoDate: "2023-12-04T00:00:00Z", dsun: 211.962924, hgln: 0.000000, hglt: 0.569293 },
  { isoDate: "2023-12-05T00:00:00Z", dsun: 211.932186, hgln: -0.000000, hglt: 0.441582 },
  { isoDate: "2023-12-06T00:00:00Z", dsun: 211.902401, hgln: 0.000000, hglt: 0.313693 },
  { isoDate: "2023-12-07T00:00:00Z", dsun: 211.873529, hgln: -0.000000, hglt: 0.185665 },
  { isoDate: "2023-12-08T00:00:00Z", dsun: 211.845530, hgln: -0.000000, hglt: 0.057538 },
  { isoDate: "2023-12-09T00:00:00Z", dsun: 211.818364, hgln: 0.000000, hglt: -0.070649 },
  { isoDate: "2023-12-10T00:00:00Z", dsun: 211.791991, hgln: -0.000000, hglt: -0.198857 },
  { isoDate: "2023-12-11T00:00:00Z", dsun: 211.766375, hgln: 0.000000, hglt: -0.327043 },
  { isoDate: "2023-12-12T00:00:00Z", dsun: 211.741485, hgln: -0.000000, hglt: -0.455168 },
  { isoDate: "2023-12-13T00:00:00Z", dsun: 211.717302, hgln: -0.000000, hglt: -0.583190 },
  { isoDate: "2023-12-14T00:00:00Z", dsun: 211.693817, hgln: 0.000000, hglt: -0.711068 },
  { isoDate: "2023-12-15T00:00:00Z", dsun: 211.671041, hgln: -0.000000, hglt: -0.838759 },
  { isoDate: "2023-12-16T00:00:00Z", dsun: 211.649003, hgln: 0.000000, hglt: -0.966219 },
  { isoDate: "2023-12-17T00:00:00Z", dsun: 211.627747, hgln: 0.000000, hglt: -1.093406 },
  { isoDate: "2023-12-18T00:00:00Z", dsun: 211.607338, hgln: 0.000000, hglt: -1.220277 },
  { isoDate: "2023-12-19T00:00:00Z", dsun: 211.587845, hgln: -0.000000, hglt: -1.346787 },
  { isoDate: "2023-12-20T00:00:00Z", dsun: 211.569348, hgln: 0.000000, hglt: -1.472894 },
  { isoDate: "2023-12-21T00:00:00Z", dsun: 211.551927, hgln: 0.000000, hglt: -1.598555 },
  { isoDate: "2023-12-22T00:00:00Z", dsun: 211.535655, hgln: -0.000000, hglt: -1.723728 },
  { isoDate: "2023-12-23T00:00:00Z", dsun: 211.520602, hgln: -0.000000, hglt: -1.848371 },
  { isoDate: "2023-12-24T00:00:00Z", dsun: 211.506824, hgln: 0.000000, hglt: -1.972445 },
  { isoDate: "2023-12-25T00:00:00Z", dsun: 211.494366, hgln: -0.000000, hglt: -2.095908 },
  { isoDate: "2023-12-26T00:00:00Z", dsun: 211.483259, hgln: 0.000000, hglt: -2.218721 },
  { isoDate: "2023-12-27T00:00:00Z", dsun: 211.473519, hgln: 0.000000, hglt: -2.340846 },
  { isoDate: "2023-12-28T00:00:00Z", dsun: 211.465150, hgln: -0.000000, hglt: -2.462244 },
  { isoDate: "2023-12-29T00:00:00Z", dsun: 211.458144, hgln: 0.000000, hglt: -2.582877 },
  { isoDate: "2023-12-30T00:00:00Z", dsun: 211.452480, hgln: 0.000000, hglt: -2.702709 },
  { isoDate: "2023-12-31T00:00:00Z", dsun: 211.448133, hgln: 0.000000, hglt: -2.821701 },
  { isoDate: "2024-01-01T00:00:00Z", dsun: 211.445069, hgln: -0.000000, hglt: -2.939817 },
  { isoDate: "2024-01-02T00:00:00Z", dsun: 211.443250, hgln: -0.000000, hglt: -3.057021 },
  { isoDate: "2024-01-03T00:00:00Z", dsun: 211.442633, hgln: -0.000000, hglt: -3.173276 }
];
