import "raf/polyfill";

import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import DateInputParameter from "./DateInputParameter";
import { DateParameterProps } from "../Interfaces";
import searchStore from "../../Stores/SearchStore";

configure({ enforceActions: "always" });

@observer
class DateParameter extends React.Component<DateParameterProps> {
  id = uuidv4();
  handleOnBlur = ev => {
    searchStore.setPopDownCriterionOverlayIsActive(false);
    this.props.onBlur(ev);
  };

  handleOnDoubleClick = ev => {
    this.props.criterion.toggleExclusion();
  };

  handleMouseEnter = ev => {
    searchStore.popDownCriterionHookElement = document.getElementById(this.id);
    searchStore.setPopDownCriterionOverlayIsActive(true);
  };

  handleMouseLeave = ev => {
    searchStore.setPopDownCriterionOverlayIsActive(false);
  };

  render() {
    const excludedClassName = this.props.criterion.excluded ? "criterion-name-excluded" : "";

    return (
      <div id={this.id} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <div onDoubleClick={this.handleOnDoubleClick}>
          <span className={`description ${excludedClassName}`}>{this.props.criterion.description}</span>
        </div>
        <div className="criterion" style={{ width: `${this.props.criterion.width()}px` }}>
          <span className="minValue">
            <DateInputParameter
              criterion={this.props.criterion}
              onValueChange={this.props.criterion.handleValueChange}
              onBlur={this.handleOnBlur}
              value={this.props.criterion.value}
              canHandleRelativeDate={false}
              inputFieldId={"Min"}
              minSelectableDate={null}
            />
          </span>
        </div>
      </div>
    );
  }
}

export default DateParameter;
