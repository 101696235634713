import "raf/polyfill";
import React from "react";
import { action, configure, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import appStore from "../../Stores/AppStore";
import { baseUrl, isIncluded } from "../../Utilities/Utilities";

configure({ enforceActions: "always" });

interface IProps {
  hideComponent: () => void;
  handleAddElement: (element) => void;
  handleRemoveElement: (element) => void;
  draggable: boolean;
  handleDragStart: (elementName) => void;
  handleDragOver: (e, elementName) => void;
  handleDragEnd: (e, elementName) => void;
  handleDrop: (elementName) => void;
  leftColumnElements: () => any[];
  rightColumnElements: () => any[];
  overlayCSSClassName: string;
  id: string;
}

@observer
class TransferListComponent extends React.Component<IProps> {
  id = this.props.id;
  componentDestroyInProgress = false;

  @observable
  leftColumnFilter = "";

  @observable
  rightColumnFilter = "";

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  setLeftColumnFilter = value => (this.leftColumnFilter = value);

  @action
  setRightColumnFilter = value => (this.rightColumnFilter = value);

  componentDidMount() {
    appStore.globalOnMouseDownHandlers.push({ id: this.id, function: this.handleGlobalMouseDown });
    appStore.globalOnFocusHandlers.push({ id: this.id, function: this.handleGlobalOnFocus });
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
  }

  handleGlobalMouseDown = ev => {
    const myDiv = document.getElementById(this.id);
    if (this.componentDestroyInProgress || !myDiv) {
      return;
    }
    const isChildElement = myDiv?.contains(ev.target);
    if (!isChildElement) {
      this.componentDestroyInProgress = true;
      this.props.hideComponent();
    }
  };

  handleGlobalOnFocus = ev => {
    const myDiv = document.getElementById(this.id);
    if (this.componentDestroyInProgress || !myDiv) {
      return;
    }
    const isChildElement = myDiv?.contains(ev.target);
    if (!isChildElement) {
      this.componentDestroyInProgress = true;
      this.props.hideComponent();
    }
  };

  handleChangeFilterValue = (ev, setter) => setter(ev.target.value);

  dragProps = elementName => {
    if (!this.props.draggable) {
      return null;
    }

    return {
      draggable: true,
      onDragStart: e => this.props.handleDragStart(elementName),
      onDragOver: e => this.props.handleDragOver(e, elementName),
      onDrop: e => this.props.handleDrop(elementName),
      onDragEnd: e => this.props.handleDragEnd(e, elementName),
      style: { cursor: "grab" }
    };
  };

  renderFilter = (field, setter, id) => (
    <input
      id={`edit-selector-input-${id}`}
      placeholder=" FILTER VALUES"
      type="text"
      value={field}
      className="criterion"
      onChange={ev => this.handleChangeFilterValue(ev, setter)}
    />
  );

  renderHeader = (line1, line2, isClosable) => (
    <div className="base-flexbox-row">
      <h3 style={{ textAlign: "center", whiteSpace: "nowrap", width: "100%" }}>
        {line1}
        <br />
        {line2}
      </h3>
      {isClosable ? (
        <div style={{ textAlign: "right", height: "100%" }}>
          <img
            className="click-button close-icon"
            src={baseUrl() + "/icons/close.png"}
            alt="X"
            onClick={this.props.hideComponent}
          />
        </div>
      ) : null}
    </div>
  );

  renderColumn = (
    columnElements,
    columnFilter,
    columnFilterSetter,
    handleOnClick,
    headerLine1,
    headerLine2,
    isClosable = false
  ) => {
    const filteredElements = columnElements().filter(element => isIncluded(element, columnFilter));
    const nameMaxLength = filteredElements.reduce((acc, element) => Math.max(acc, element.name.length), 0);
    const elements = filteredElements.map(element => {
      const dragProps = this.dragProps(element.name);
      return (
        <button
          key={uuidv4()}
          onClick={ev => handleOnClick(element)}
          className="click-button edit-selector-line"
          {...dragProps}
        >
          {`${element.name.padEnd(nameMaxLength, "\u00A0")} : ${element.fullDescription}`}
        </button>
      );
    });
    return (
      <div className="selector-edit-criteria">
        {this.renderHeader(headerLine1, headerLine2, isClosable)}
        {this.renderFilter(columnFilter, columnFilterSetter, "current")}
        <div className="selector-edit-criteria-list">{elements}</div>
      </div>
    );
  };

  renderLeftColumn = () =>
    this.renderColumn(
      this.props.leftColumnElements,
      this.leftColumnFilter,
      this.setLeftColumnFilter,
      this.props.handleRemoveElement,
      "CURRENT ELEMENTS",
      "click to remove"
    );

  renderRightColumn = () =>
    this.renderColumn(
      this.props.rightColumnElements,
      this.rightColumnFilter,
      this.setRightColumnFilter,
      this.props.handleAddElement,
      "AVAILABLE ELEMENTS",
      "click to add",
      true
    );

  render() {
    return (
      <div className={this.props.overlayCSSClassName} id={this.id}>
        {this.renderLeftColumn()}
        {this.renderRightColumn()}
      </div>
    );
  }
}
export default TransferListComponent;
