import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { labeledUuidV4 } from "../Utilities/Utilities";
import appStore from "../Stores/AppStore";
import resultsStore from "../Stores/ResultsStore";

const itemLabels = {
  saveHovered: "Save hovered files to cart (S)",
  altSaveHovered: "Unsave hovered files (Alt-S)",
  hideHovered: "Hide hovered files (H)",
  altHideHovered: "Unhide hovered files (Alt-H)",
  trashHovered: "Delete hovered files (D)",
  altTrashHovered: "Restore hovered files (Alt-D)",
  moveTo: "View from Earth/SolO (V)",
  centre: "View from observer (V)",
  pinTime: "Pin/unpin time (1)",
  saveSelected: "Save all selected files to cart (Cmd-S)",
  altSaveSelected: "Unsave all selected files (Cmd-Alt-S)",
  hideSelected: "Hide(remove) all selected files (Cmd-H)",
  altHideSelected: "Unhide selected files (Cmd-Alt-H)",
  trashSelected: "Delete all selected files (Cmd-D)",
  altTrashSelected: "Restore all selected files (Cmd-Alt-D)",
  selectAll: "Select/unselect all (Cmd-A)"
};

@observer
class ContextMenu extends React.Component {
  id = "context-menu-overlay";
  activeItemWasDisplayed = false;

  componentDidMount() {
    appStore.globalOnMouseDownHandlers.push({ id: this.id, function: this.handleGlobalMouseDown });
    appStore.globalKeyDownHandlers.push({ id: this.id, function: this.handleGlobalKeyDown });
    appStore.globalOnScrollHandlers.push({ id: this.id, function: this.handleGlobalScroll });
  }

  componentWillUnmount() {
    appStore.hideContextMenu();
    appStore.removeGlobalHandlers(this.id);
  }

  handleGlobalMouseDown = ev => {
    const myDiv = document.getElementById(this.id);
    if (!myDiv) {
      return;
    }
    const isChildElement = myDiv?.contains(ev.target);
    if (!isChildElement) {
      appStore.hideContextMenu();
    }
  };

  handleGlobalKeyDown = ev => {
    if (ev.key === "Escape") {
      appStore.hideContextMenu();
    }
  };

  handleGlobalScroll = ev => {
    appStore.hideContextMenu();
  };

  renderMenuItem = (label: string, icon: string, onClick: () => void) => {
    if (label) {
      this.activeItemWasDisplayed = true;
    }
    if (!label) {
      return this.activeItemWasDisplayed ? (
        <li key={labeledUuidV4("context-menu-item")} className="contextMenu-item">
          &nbsp;
        </li>
      ) : null;
    }
    const image = icon ? (
      <img loading="lazy" fetchpriority="low" className="menu-icon" src={`icons/${icon}`} alt={label} />
    ) : (
      <span> &nbsp; &nbsp;</span>
    );
    const handleOnClick = () => {
      onClick();
      appStore.hideContextMenu();
      resultsStore.syncFilesToThree();
    };
    return (
      <li key={labeledUuidV4("context-menu-item")} className="contextMenu-item">
        <button className="contextMenu-button" onClick={handleOnClick}>
          {image}
          &nbsp;
          {label}
          &nbsp;
        </button>
      </li>
    );
  };

  getDescription(normalText, altText) {
    const altPatchedText = appStore.altKey ? altText : normalText;
    const shiftPatchedText = appStore.shiftKey
      ? altPatchedText.replace("selected", "unselected").replace("Cmd-", "Shift-Cmd-")
      : altPatchedText;
    return shiftPatchedText;
  }

  unhoveredFiles = () => {
    return appStore.contextMenuAllFiles.filter(file => appStore.contextMenuHoveredFiles.indexOf(file) === -1);
  };

  targetHoveredFiles() {
    return appStore.contextMenuHoveredFiles;
  }

  targetSelectedFiles() {
    return appStore.contextMenuAllFiles.filter(file => (appStore.shiftKey ? !file.selected : file.selected));
  }

  performActionOnTargetFiles(targetFiles: any[], action: string) {
    if (appStore.altKey) {
      resultsStore.removeStatusFromFiles(targetFiles, action);
    } else {
      resultsStore.setStatusForFiles(targetFiles, action);
    }
  }

  render() {
    this.activeItemWasDisplayed = false;
    const patchedItemLabels = { ...itemLabels, ...appStore.contextMenuLabels };
    const saveHoveredText = this.getDescription(patchedItemLabels.saveHovered, patchedItemLabels.altSaveHovered);
    const hideHoveredText = this.getDescription(patchedItemLabels.hideHovered, patchedItemLabels.altHideHovered);
    const trashHoveredText = this.getDescription(patchedItemLabels.trashHovered, patchedItemLabels.altTrashHovered);
    const saveSelectedText = this.getDescription(patchedItemLabels.saveSelected, patchedItemLabels.altSaveSelected);
    const hideSelectedText = this.getDescription(patchedItemLabels.hideSelected, patchedItemLabels.altHideSelected);
    const trashSelectedText = this.getDescription(patchedItemLabels.trashSelected, patchedItemLabels.altTrashSelected);
    const visible = appStore.contextMenuIsActive;
    if (!visible) {
      return null;
    }
    const style = { left: appStore.contextMenuHookPoint.x, top: appStore.contextMenuHookPoint.y } as CSSProperties;
    return (
      <div>
        <ul className="contextMenu contextMenu-light" style={style} id="context-menu-overlay">
          {this.renderMenuItem(saveHoveredText, "cart.png", () => {
            this.performActionOnTargetFiles(this.targetHoveredFiles(), "cart");
            resultsStore.removeFromSelection(this.targetSelectedFiles());
          })}
          {this.renderMenuItem(hideHoveredText, "hidden.png", () => {
            this.performActionOnTargetFiles(this.targetHoveredFiles(), "hidden");
            resultsStore.removeFromSelection(this.targetSelectedFiles());
          })}
          {this.renderMenuItem(trashHoveredText, "trash.png", () => {
            this.performActionOnTargetFiles(this.targetHoveredFiles(), "trash");
            resultsStore.removeFromSelection(this.targetSelectedFiles());
          })}
          {this.renderMenuItem(patchedItemLabels.centre, "centre-file.png", () => {
            appStore.handleCentreMove();
          })}
          {this.renderMenuItem(patchedItemLabels.pinTime, "pinpoint.png", () => {
            resultsStore.handlePinUnpinTime(this.targetHoveredFiles()[0]);
          })}
          <li className="contextMenu-divider"></li>
          {this.renderMenuItem(saveSelectedText, "cart.png", () => {
            this.performActionOnTargetFiles(this.targetSelectedFiles(), "cart");
            if (!appStore.shiftKey) {
              resultsStore.removeFromSelection(this.targetSelectedFiles());
            }
          })}
          {this.renderMenuItem(hideSelectedText, "hidden.png", () => {
            this.performActionOnTargetFiles(this.targetSelectedFiles(), "hidden");
            if (!appStore.shiftKey) {
              resultsStore.removeFromSelection(this.targetSelectedFiles());
            }
          })}
          {this.renderMenuItem(trashSelectedText, "trash.png", () => {
            this.performActionOnTargetFiles(this.targetSelectedFiles(), "trash");
            if (!appStore.shiftKey) {
              resultsStore.removeFromSelection(this.targetSelectedFiles());
            }
          })}
          {this.renderMenuItem(patchedItemLabels.selectAll, "invert-selection.png", () => {
            resultsStore.toggleSelected(appStore.contextMenuAllFiles);
          })}
        </ul>
      </div>
    );
  }
}

export default ContextMenu;
