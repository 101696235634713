import { observer } from "mobx-react";
import React, { Fragment } from "react";
import { CriteriaTypes } from "../../classes/Criterion";
import appStore from "../../Stores/AppStore";
import searchStore from "../../Stores/SearchStore";
import { labeledUuidV4 } from "../../Utilities/Utilities";

@observer
export default class PopDownOverlay extends React.Component {
  id = labeledUuidV4("pop-down-overlay");

  handleGlobalKeyUp = ev => {
    if (ev.key === "Escape") {
      searchStore.setPopDownCriterionOverlayIsActive(false);
    }
  };

  componentDidMount() {
    appStore.globalKeyUpHandlers.push({ id: this.id, function: this.handleGlobalKeyUp });
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
  }

  numericDescription = () => (
    <Fragment>
      {searchStore.criterionForOverlay.description} {"(number)"}
    </Fragment>
  );
  rangeDescription = () => (
    <Fragment>
      {searchStore.criterionForOverlay.description} {"<min> ... <max>"}
    </Fragment>
  );
  dateRangeDescription = () => (
    <Fragment>
      {searchStore.criterionForOverlay.description} {"<begin> ... <end>"}
    </Fragment>
  );
  dateDescription = () => (
    <Fragment>
      {searchStore.criterionForOverlay.description} {"(date)"}
    </Fragment>
  );
  enumDescription = () => (
    <Fragment>
      {searchStore.criterionForOverlay.description} {"(selection)"}
    </Fragment>
  );
  stringDescription = () => (
    <Fragment>
      {searchStore.criterionForOverlay.description} {"(string)"}
    </Fragment>
  );

  render() {
    if (
      !searchStore.popDownCriterionHookElement ||
      !searchStore.popDownCriterionOverlayIsActive ||
      !searchStore.criterionForOverlay
    ) {
      return null;
    }
    const geometry = searchStore.popDownCriterionHookElement.getBoundingClientRect();
    const topOffset = searchStore.criterionForOverlay.popDownIsOver ? -25 : geometry?.height + 10;
    const top = geometry?.top + topOffset;
    const left = geometry?.left;
    const descriptionChooser = {};
    descriptionChooser[CriteriaTypes.numeric] = this.numericDescription;
    descriptionChooser[CriteriaTypes.range] = this.rangeDescription;
    descriptionChooser[CriteriaTypes.dateRange] = this.dateRangeDescription;
    descriptionChooser[CriteriaTypes.date] = this.dateDescription;
    descriptionChooser[CriteriaTypes.enum] = this.enumDescription;
    descriptionChooser[CriteriaTypes.string] = this.stringDescription;
    return (
      <div style={{ top: `${top}px`, left: `${left}px` }} className="pop-down-overlay ">
        {descriptionChooser[searchStore.criterionForOverlay.type]()}
      </div>
    );
  }
}
