import VisualElement from "./VisualElement";
import * as THREE from "three";
import {
  BORDERCOLOR1,
  BORDERCOLOR1_HOVERED,
  BORDER_CLEAR_DEPTH,
  BORDER_RENDER_ORDER,
  LAYER_HEIGHT,
  RSUN,
  universeScaleFactor
} from "../../utilities/three-constants";
import CommonQuasiFitsAndGeometryParameters from "./CommonQuasiFitsAndGeometryUtilities";
import layerManager from "../../LayerManager";
export class Border extends VisualElement {
  vertices: Float32Array;
  indices: number[];
  startingPositionsArray = [] as Float32Array[];
  rotationSpeedsRadiansArray = [] as Float32Array[];
  lines = [] as THREE.Object3D[];
  material: THREE.MeshBasicMaterial;
  borderLayer;
  hovered = false;

  constructor(q: CommonQuasiFitsAndGeometryParameters, name, scene, threeObservation) {
    super(q, name, scene, threeObservation);
    this.borderLayer = layerManager.getBorderLayer();
    this.createBorderBoxes();
  }

  public applyDeltaTimeDays = (deltaTimeDays, { reapply } = { reapply: false }) => {
    deltaTimeDays = reapply ? this.currentDeltaTimeDays : deltaTimeDays;
    const shouldApply = reapply || deltaTimeDays !== this.currentDeltaTimeDays;
    if (!shouldApply) {
      return;
    }
    this.lines.forEach((line, i) => {
      const startingPositions = this.startingPositionsArray[i];
      const rotationSpeedsRadians = this.rotationSpeedsRadiansArray[i];
      this.applyDifferentialRotation(line, startingPositions, rotationSpeedsRadians, deltaTimeDays);
    });
    this.currentDeltaTimeDays = deltaTimeDays;
  };

  setBorderVisibility = (borderVisibilityStatus: boolean) => {
    if (borderVisibilityStatus) {
      this.lines.forEach(line => {
        line.visible = true;
        this.scene.add(line);
      });
    } else {
      this.lines.forEach(line => {
        line.visible = false;
        this.scene.remove(line);
      });
    }
  };

  flash = (times = 7) => {
    const evenAddition = times % 2 ? 0 : 1;
    times = times + evenAddition; // Make sure it's odd
    let i;
    for (i = 0; i < times; i++) {
      const color = i % 2 === 0 ? BORDERCOLOR1_HOVERED : 0xff0000;
      setTimeout(() => {
        this.material.color.setHex(color);
      }, 90 * i);
    }
    setTimeout(() => {
      this.setBorderHovered(this.hovered);
    }, 90 * (i - 1) + 5);
  };

  setBorderLayer = newLayer => {
    if (newLayer === this.borderLayer) {
      return;
    }
    const visibility = this.lines[0].visible;
    this.setBorderVisibility(false); // Remove from scene
    this.dispose();
    this.lines = [];
    this.borderLayer = newLayer;
    this.createBorderBoxes();
    this.applyDeltaTimeDays(0, { reapply: true });
    this.setBorderVisibility(visibility);
  };

  public setBorderHovered = hovered => {
    this.hovered = hovered;
    this.setBorderLayer(hovered ? layerManager.getBorderLayer() + 10 : layerManager.getBorderLayer());
    this.material.color.setHex(hovered ? BORDERCOLOR1_HOVERED : BORDERCOLOR1);
    this.lines.forEach(line => {
      line.onBeforeRender = renderer => (hovered ? renderer.clearDepth() : null);
    });
  };

  // VERTICES calculation *********************************************************************
  // Keep sane: consider surfacePoints along the lower edge of the observation.
  // Then we can use the terms outwards, along, and upwards/downwards.
  // As long as we get our vector math right, it'll work for all borders.

  private createEntitiesForBorderBoxVertexCalculations = borderSurfacePoints => {
    const naxis1 = borderSurfacePoints.length - 1; // FITS number of pixels, not points
    const naxis2 = 1;
    const numVertices = (naxis1 + 1) * (naxis2 + 1);

    const borderWidth = 10 * universeScaleFactor;
    const parameters = {
      borderWidth,
      extensionLength: borderWidth / 1.3,
      out: new THREE.Vector3(),
      south: new THREE.Vector3(),
      along: new THREE.Vector3(),
      extension: new THREE.Vector3(),
      point: new THREE.Vector3(),
      displacement: new THREE.Vector3(),
      borderHeight: LAYER_HEIGHT * this.borderLayer,
      naxis1,
      naxis2,
      numVertices,
      vertices0: new Float32Array(Array(3 * numVertices).fill(0))
    };
    return parameters;
  };

  private createBoxVertexFromSurfaceBorderPoints = (borderSurfacePoints, ix, iy, e) => {
    e.point.copy(borderSurfacePoints[ix]);
    const isCoronaPoint = e.point.z <= this.q.coronaPlaneZCoord; // -0.06955
    const next = ix < e.naxis1 ? borderSurfacePoints[ix + 1] : borderSurfacePoints[ix];

    if (ix < e.naxis1) {
      e.along.subVectors(next, e.point).normalize();
    } else {
      // For ix === naxis1 we just use the last one
    }

    if (ix === 0) {
      e.extension.copy(e.along).multiplyScalar(-e.extensionLength);
      e.point.add(e.extension);
    }
    if (ix === e.naxis1) {
      e.extension.copy(e.along).multiplyScalar(e.extensionLength);
      e.point.add(e.extension);
    }

    if (iy === 1) {
      e.out.copy(e.point).normalize();
      if (isCoronaPoint) {
        e.out.set(0, 0, 1);
      }
      e.south.crossVectors(e.out, e.along).normalize().multiplyScalar(-1);
      e.point.add(e.south.multiplyScalar(e.borderWidth)); // out + south
    }

    if (!isCoronaPoint) {
      e.point.multiplyScalar((RSUN + e.borderHeight) / RSUN);
    }
    return e.point.toArray();
  };

  private createBoxVertices0FromSurfacePoints = (borderSurfacePoints: THREE.Vector3[]) => {
    const entities = this.createEntitiesForBorderBoxVertexCalculations(borderSurfacePoints);
    let vertexOffset = 0;
    for (let iy = 0; iy < entities.naxis2 + 1; iy++) {
      for (let ix = 0; ix < entities.naxis1 + 1; ix++) {
        const vertex = this.createBoxVertexFromSurfaceBorderPoints(borderSurfacePoints, ix, iy, entities);
        entities.vertices0.set(vertex, vertexOffset);
        vertexOffset += 3;
      }
    }
    return { vertices0: entities.vertices0, naxis1: entities.naxis1, naxis2: entities.naxis2 };
  };

  private createBorderBox = (borderSurfacePoints: THREE.Vector3[], meshName) => {
    const { vertices0, naxis1, naxis2 } = this.createBoxVertices0FromSurfacePoints(borderSurfacePoints);
    const indices = this.q.generateIndices(naxis1, naxis2);
    const geometry = this.assembleThreeGeometry(vertices0, null, indices);
    const verticesArray = geometry.attributes.position.array;
    this.q.applyObserverPositionToVertices(verticesArray);
    const rotationSpeedsRadians = this.q.differentialRotationSpeedsRadians(verticesArray);
    this.rotationSpeedsRadiansArray.push(rotationSpeedsRadians);
    this.startingPositionsArray!.push(Float32Array.from(verticesArray));
    const mesh = new THREE.Mesh(geometry, this.material);
    mesh.renderOrder = BORDER_RENDER_ORDER;
    mesh.onBeforeRender = renderer => (BORDER_CLEAR_DEPTH ? renderer.clearDepth() : null);
    mesh.name = meshName;
    mesh.userData.threeObservation = this.threeObservationReference;
    geometry.name = meshName + " geometry";
    geometry.userData.threeObservation = this.threeObservationReference;
    this.lines.push(mesh);
    this.disposables.push(geometry);
  };

  private createBorderBoxes = () => {
    const { naxis1, naxis2 } = this.q;
    this.material = new THREE.MeshBasicMaterial({
      color: BORDERCOLOR1,
      side: THREE.DoubleSide,
      opacity: 1,
      transparent: true
    });
    this.disposables.push(this.material);

    const lowerLeft = this.q.pixelCoordinatesToHplnHplt(0.5, 0.5);
    const lowerRight = this.q.pixelCoordinatesToHplnHplt(naxis1 + 0.5, 0.5);
    const upperRight = this.q.pixelCoordinatesToHplnHplt(naxis1 + 0.5, naxis2 + 0.5);
    const upperLeft = this.q.pixelCoordinatesToHplnHplt(0.5, naxis2 + 0.5);

    const pixelSize = this.q.pixelSize / 2;
    const lowerBorder = this.q.surfacePointsFromAtoB(lowerLeft, lowerRight, pixelSize);
    const rightBorder = this.q.surfacePointsFromAtoB(lowerRight, upperRight, pixelSize);
    const topBorder = this.q.surfacePointsFromAtoB(upperRight, upperLeft, pixelSize);
    const leftBorder = this.q.surfacePointsFromAtoB(upperLeft, lowerLeft, pixelSize);

    this.createBorderBox(lowerBorder, this.name + " border box 0 (lower) @ " + this.borderLayer);
    this.createBorderBox(rightBorder, this.name + " border box 1 (right) @ " + this.borderLayer);
    this.createBorderBox(topBorder, this.name + " border box 2 (top) @ " + this.borderLayer);
    this.createBorderBox(leftBorder, this.name + " border box 3 (left) @ " + this.borderLayer);
  };
}
