import ThreeObservation from "./components/ThreeObservation/ThreeObservation";
import { MyLogger } from "../../Utilities/Utilities";
import File from "../../classes/File";
import threeStore from "../../Stores/ThreeStore";

// When a ThreeObservation is created, the layer is undefined. Surface/corona meshes are not automatically created,
// so no problem with that. Borders are layer-independent (but maybe they should have a specific renderOrder,
// equal to the layer ??)
//
// An observation *must* be placed in the LayerManager before it can be added to the
// scene (or at least before the surface/corona meshes are made visible)
//
export class LayerManager {
  maxLayer;
  offset;
  frozen = 0;
  backgrounds = [] as ThreeObservation[];
  observations = [] as (ThreeObservation | null | undefined)[];

  constructor(offset, maxLayer) {
    this.offset = offset;
    this.maxLayer = maxLayer;
  }

  private reportedCompressionErrors = 0;
  checkCompressionErrors = () => {
    if (this.observations.length + this.offset > this.maxLayer) {
      if (this.reportedCompressionErrors++ % 100 === 0) {
        MyLogger.red("Reminder: Too many layers after compression:", this.observations.length, " (FIXME!)");
      }
    }
  };

  private compress = () => {
    this.observations = this.observations.filter(observation => observation);
    this.checkCompressionErrors();
  };

  public getBorderLayer = () => {
    return 1;
    // const nominalLayer = this.offset + this.observations.length + 10;
    // const ceiledLayer = Math.ceil(nominalLayer / 10) * 10;
    // return 0 * nominalLayer; // ceiledLayer;
  };

  lastBorderLayer = -1;
  private updateBorderLayers = () => {
    if (false) {
      const layer = this.getBorderLayer();
      if (layer === this.lastBorderLayer) {
        return;
      }
      this.lastBorderLayer = layer;
      threeStore.three.onCanvasObservations.forEach(observation => {
        observation.setBorderLayer(layer);
      });
    }
  };

  private apply = () => {
    if (this.frozen) {
      return;
    }
    this.compress();
    for (var i = 0; i < this.observations.length; i++) {
      this.observations[i]!.setLayer(i + this.offset);
    }
    this.updateBorderLayers();
  };

  updateNeeded = false;
  freeze = () => {
    this.frozen++;
    this.updateNeeded = false;
  };

  unfreeze = () => {
    this.frozen--;
    if (!this.frozen && this.updateNeeded) {
      this.apply();
    }
  };

  public allocateLayer = (thisObservation: ThreeObservation) => {
    this.compress(); // Don't allocate a higher layer than necessary
    if (thisObservation.props.isBackground) {
      this.backgrounds[0] = thisObservation;
      return 0;
    }
    if (this.observations.some(observation => observation === thisObservation)) {
      console.error("Observation already in layer manager ", thisObservation.file!.id);
      return NaN;
    }
    this.observations.push(thisObservation);
    const allocatedLayer = this.observations.length - 1 + this.offset;
    this.updateBorderLayers();
    return allocatedLayer;
  };

  public freeLayer = (observation: ThreeObservation) => {
    this.observations = this.observations.map(obs => (obs === observation ? undefined : obs));
    this.updateNeeded = true;
    this.apply();
  };

  public pushToBackByFile = (file: File) => {
    const observation = this.observations.find(observation => observation?.file === file);
    if (!observation) {
      console.error("pushToBackByFile, file not found in layer manager:", file.id);
      return;
    }
    MyLogger.red("Pushing to back:", observation.name);
    this.observations = this.observations.filter(obs => obs !== observation);
    this.observations.unshift(observation);
    this.apply();
  };

  public pullToFrontByFile = (file: File) => {
    const thisObservation = this.observations.find(observation => observation?.file === file);
    if (!thisObservation) {
      console.error("pullToFrontByFile, file not found in layer manager:", file.id);
      return;
    }
    MyLogger.red("Pulling to front:", thisObservation.name);
    this.observations = this.observations.filter(observation => observation !== thisObservation);
    this.observations.push(thisObservation);
    this.apply();
  };
}

const layerManager = new LayerManager(2, 70);
export default layerManager;
