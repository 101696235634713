import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";
import appStore from "../Stores/AppStore";

configure({ enforceActions: "always" });

@observer
class HoverText extends React.Component {
  text = "";

  render() {
    const geometry = appStore.hoverTextHookElement?.getBoundingClientRect();
    const className = appStore.hoveredTextIsInverted ? "hover-text hover-text-inverted" : "hover-text";
    const top = geometry?.top + geometry?.height + 10;
    const left = geometry?.left;
    return appStore.hoverTextIsActive ? (
      <div style={{ top: `${top}px`, left: `${left}px` }} className={className}>
        {appStore.hoverText}
      </div>
    ) : null;
  }
}
export default HoverText;
