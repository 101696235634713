import * as THREE from "three";
import CommonQuasiFitsAndGeometryParameters from "./CommonQuasiFitsAndGeometryUtilities";

export default class VisualElement {
  name: string;
  scene: THREE.Scene;
  disposables = [] as any[];
  currentDeltaTimeDays;
  threeObservationReference;
  q: CommonQuasiFitsAndGeometryParameters;

  constructor(q: CommonQuasiFitsAndGeometryParameters, name: string, scene: THREE.Scene, threeObservationReference) {
    this.q = q;
    this.currentDeltaTimeDays = 0;
    this.name = name;
    this.scene = scene;
    this.threeObservationReference = threeObservationReference;
  }

  public dispose = () => this.disposables.forEach(disposable => disposable.dispose());

  applyDifferentialRotation = (mesh, initialPositions, rotationSpeedRadians, deltaTimeDays) => {
    const positions = mesh.geometry.attributes.position.array;
    for (let i = 0; i < initialPositions.length; i += 3) {
      this.q.applyDifferentialRotationToPoint(initialPositions, positions, rotationSpeedRadians, i, deltaTimeDays);
    }
    mesh.geometry.attributes.position.needsUpdate = true;
    mesh.geometry.computeBoundingBox();
    mesh.geometry.computeBoundingSphere();
  };

  assembleThreeGeometry = (vertices, uvs, indices) => {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
    if (uvs) {
      geometry.setAttribute("uv", new THREE.BufferAttribute(uvs, 2));
    }
    geometry.setIndex(indices);
    return geometry;
  };
}
