import File from "./File";
import resultsStore from "../Stores/ResultsStore";
import { MyLogger, infoPopup } from "../Utilities/Utilities";
import { reaction } from "mobx";

class ResponseLineHandler {
  constructor(selector) {
    this.selector = selector;
  }

  handleStatisticsLine = line => {
    const searchStatistics = JSON.parse(line.replace("<pre>", ""));
    resultsStore.addStatistics(this.selector.id, searchStatistics);
    this.selector.updateAmountOfReceivedData(searchStatistics);
  };

  setAssociatedStatus = (file, associatedStatus) => (associatedStatus ? null : (file.lastAccessDate = Date.now()));

  handleNewLine = line => {
    if (line.includes("files_matching")) {
      this.handleStatisticsLine(line);
      return;
    }
    let file;
    try {
      const lineAsObject = JSON.parse(line);
      file = new File(lineAsObject);
    } catch (e) {
      console.error("Data handle input line error:", line);
      this.selector.searchEnded();
      throw e;
    }
    resultsStore.handleNewFile(file, this.selector);
    reaction(
      () => file.isAssociated(),
      associatedStatus => this.setAssociatedStatus(file, associatedStatus)
    );
  };

  handleEndOfResults = () => {
    this.selector.searchEnded();
    MyLogger.normal("Handle end of results");
  };

  handleError = err => {
    this.selector.searchEnded();
    infoPopup("error", `Error in ${this.selector.name} search:`, err);
    console.error("Handle error");
  };
}

export default ResponseLineHandler;
