import "raf/polyfill";
import React from "react";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import TransferListComponent from "../ResultsSpan/TransferListComponent";
import { CriterionFactory } from "../../classes/Criterion";
import searchStore from "../../Stores/SearchStore";
import appStore from "../../Stores/AppStore";

configure({ enforceActions: "always" });

@observer
class EditSelectorOverlay extends React.Component {
  id = uuidv4();
  componentDestroyInProgress = false;
  mousePointerWasOverOverlay = false;

  componentDidMount() {
    appStore.globalOnMouseOverHandlers.push({ id: this.id, function: this.handleGlobalMouseOver });
  }

  componentWillUnmount() {
    appStore.removeGlobalHandlers(this.id);
  }

  handleAddCriterion = criterionDef => {
    const criterion = CriterionFactory.createCriterionFromDefinition(searchStore.selectorForOverlay!, criterionDef);
    searchStore.selectorForOverlay!.addCriterion(criterion);
  };

  handleRemoveCriterion = criterionToRemove => {
    const updatedCriteriaList = searchStore.selectorForOverlay!.criteria.filter(
      criterion => criterion.name !== criterionToRemove.name
    );
    searchStore.selectorForOverlay!.replaceCriteria(updatedCriteriaList);
  };

  handleGlobalMouseOver = ev => {
    const myDiv = document.getElementById(this.id);
    if (this.componentDestroyInProgress || !myDiv) {
      return;
    }
    const isChildElement = myDiv?.contains(ev.target);
    if (!isChildElement && this.mousePointerWasOverOverlay) {
      searchStore.hideEditSelectorOverlay();
    }
    if (!this.mousePointerWasOverOverlay && isChildElement) {
      this.mousePointerWasOverOverlay = true;
    }
  };

  criterionDef = criterionName => {
    if (searchStore.criteriaDefinitions[criterionName]) {
      return searchStore.criteriaDefinitions[criterionName];
    }
    if (searchStore.criteriaDefinitions[criterionName]) {
      return searchStore.criteriaDefinitions[criterionName];
    }
  };

  criterionIsInSelector = criterionName =>
    searchStore.selectorForOverlay!.criteria.some(criterion => criterion.name === criterionName);

  criterionIsAvailableForSelector = criterionDef => {
    if (!criterionDef || criterionDef.type === "no-type") {
      return false;
    }
    if (!criterionDef.instruments) {
      return true;
    }
    const isAllowed = criterionDef.instruments.some(
      instrument => instrument === searchStore.selectorForOverlay!.datasetName
    );
    const allAllowed = criterionDef.instruments.length === 0;
    return isAllowed || allAllowed;
  };

  criteriaAvailableForSelector = () => {
    const criteria: any[] = [];
    for (let criterionName in searchStore.criteriaDefinitions) {
      const criterionDef = searchStore.criteriaDefinitions[criterionName];
      const criterionIsInSelector = this.criterionIsInSelector(criterionName);
      const criterionIsAvailableForSelector = this.criterionIsAvailableForSelector(criterionDef);
      if (!criterionIsInSelector && criterionIsAvailableForSelector && criterionDef.type !== "no-type") {
        criteria.push(criterionDef);
      }
    }
    return criteria;
  };

  criteriaInCurrentSelector = () => searchStore.selectorForOverlay!.criteria;

  render() {
    const props = {
      leftColumnElements: this.criteriaInCurrentSelector,
      rightColumnElements: this.criteriaAvailableForSelector,
      handleAddElement: this.handleAddCriterion,
      handleRemoveElement: this.handleRemoveCriterion,
      hideComponent: searchStore.hideEditSelectorOverlay,
      draggable: false,
      handleDragStart: () => {},
      handleDragOver: () => {},
      handleDragEnd: () => {},
      handleDrop: () => {},
      overlayCSSClassName: "selector-edit-overlay",
      id: this.id
    };

    return (
      <div id={this.id} style={{ height: 0 }}>
        <TransferListComponent {...props} />;
      </div>
    );
  }
}
export default EditSelectorOverlay;
