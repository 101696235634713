import { observer } from "mobx-react";
import React, { Component, CSSProperties, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

import resultsStore from "../../Stores/ResultsStore";
import ThumbImageInIconImagesOverlay from "./ThumbImageInIconImagesOverlay";
import appStore from "../../Stores/AppStore";
import { THUMBWIDTH_INITIAL_VALUE } from "../../constants";
import { baseUrl, labeledUuidV4, macKeys, shutdownEvent } from "../../Utilities/Utilities";

const OVERLAY_RIGHT_MARGIN = 0;
const OVERLAY_LEFT_MARGIN = 10;
const TOP_MARGIN = 10;
const BOTTOM_MARGIN = 10;
const PADDING = 3;
const BORDER = 2;
const OVERLAY_HORIZONTAL_WHITE_SPACE = OVERLAY_LEFT_MARGIN + OVERLAY_RIGHT_MARGIN + 2 * PADDING + 2 * BORDER;

@observer
class IconImagesOverlay extends Component {
  id = labeledUuidV4("icon-images-overlay");
  divTriggeringOverlayGeometry;
  componentDestroyInProgress = false;
  fullSizeImageUrls: string[] = [];

  componentDidMount() {
    appStore.globalOnMouseOverHandlers.push({ id: this.id, function: this.handleGlobalMouseOver });
    appStore.globalKeyDownHandlers.push({ id: this.id, function: this.handleGlobalKeyDown });
    appStore.globalKeyUpHandlers.push({ id: this.id, function: this.handleGlobalKeyUp });
  }

  componentDidUpdate() {
    if (resultsStore.overlayBoxFile.selectionImageUrl) {
      const myGeometry = document.getElementById("iconImagesOverlay")!.getBoundingClientRect();
      resultsStore.setIconImagesOverlayGeometry(myGeometry);
    }
  }

  componentWillUnmount() {
    resultsStore.hideFullSizeImageOverlay();
    appStore.removeGlobalHandlers(this.id);
  }

  handleGlobalMouseOver = ev => {
    const myDiv = document.getElementById(this.id);
    if (this.componentDestroyInProgress || !myDiv) {
      return;
    }
    const fullSizeImageDiv = document.getElementById("fullSizeImageOverlay");
    const isMyChildElement = myDiv?.contains(ev.target);
    const idFullSizeImageOverlayChildElement = fullSizeImageDiv?.contains(ev.target);
    const isChildElementOfFOVAndImages = resultsStore.currentResultsRowDiv?.contains(ev.target);
    if (!isMyChildElement && !isChildElementOfFOVAndImages && !idFullSizeImageOverlayChildElement) {
      this.componentDestroyInProgress = true;
      resultsStore.hideIconImagesOverlay();
      resultsStore.setThumbWidth(THUMBWIDTH_INITIAL_VALUE);
    }
  };

  handleGlobalKeyDown = ev => {
    if (this.fullSizeImageUrls.length === 0 || !resultsStore.fullSizeImageOverlayIsActive) {
      return;
    }
    const currentIndex = this.fullSizeImageUrls.indexOf(resultsStore.fullSizeImageUrl);
    let newIndex = 0;
    switch (ev.key) {
      case "ArrowRight":
        if (currentIndex !== -1) {
          newIndex = currentIndex + 1;
          if (newIndex >= this.fullSizeImageUrls.length) {
            newIndex--;
          }
        }
        shutdownEvent(ev);
        break;
      case "ArrowLeft":
        if (currentIndex !== -1) {
          newIndex = currentIndex - 1;
          if (newIndex < 0) {
            newIndex = 0;
          }
        }
        shutdownEvent(ev);
        break;
      default:
        return;
    }
    resultsStore.initializeFullSizeImageData(this.fullSizeImageUrls[newIndex]);
    const thumbImageUrl = this.fullSizeImageUrls[newIndex].replace(".jpg", "_thumb.png");
    resultsStore.overlayBoxFile.selectionImageUrl = thumbImageUrl;
    resultsStore.hideTempImageInCanvas(resultsStore.overlayBoxFile);
  };

  handleGlobalKeyUp = ev => {
    const length = this.fullSizeImageUrls.length;
    if (ev.key === "Shift" && length > 0 && resultsStore.fullSizeImageOverlayIsActive) {
      resultsStore.initializeFullSizeImageData(this.fullSizeImageUrls[length - 1]);
    }
  };

  handleShowFullSizeImageOnThumbClick = ev => {
    const { noMacKeys } = macKeys(ev);
    if (noMacKeys && resultsStore.fullSizeImageOverlayIsActive) {
      const fullSizeImage = ev.target.src.replace("_thumb.png", ".jpg");
      if (this.fullSizeImageUrls.length > 0) {
        const currentImageIndex = this.fullSizeImageUrls.findIndex(url => url === resultsStore.fullSizeImageUrl);
        if (currentImageIndex !== -1) {
          this.fullSizeImageUrls.splice(currentImageIndex + 1);
        }
      }
      this.fullSizeImageUrls.push(fullSizeImage);
      resultsStore.initializeFullSizeImageData(fullSizeImage);
      resultsStore.showFullSizeImageOverlay();
      return;
    }
    if (noMacKeys && !resultsStore.fullSizeImageOverlayIsActive) {
      const fullSizeImage = ev.target.src.replace("_thumb.png", ".jpg");
      resultsStore.initializeFullSizeImageData(fullSizeImage);
      resultsStore.showFullSizeImageOverlay();
      this.fullSizeImageUrls = [];
      this.fullSizeImageUrls.push(fullSizeImage);
    }
  };

  handleHideOverlay = ev => resultsStore.hideIconImagesOverlay();

  private getBoxConstraints = () => {
    const headerTd = document.getElementById("resultsHeader");
    const headerTdGeometry = headerTd!.getBoundingClientRect();
    const resultsDiv = document.getElementById("m-results-cell-div");
    const resultsDivGeometry = resultsDiv!.getBoundingClientRect();
    this.divTriggeringOverlayGeometry = resultsStore.divTriggeringOverlay.getBoundingClientRect();
    const imageTdLeft = this.divTriggeringOverlayGeometry ? this.divTriggeringOverlayGeometry.x : 0;
    const maxWidth = imageTdLeft - headerTdGeometry.x - OVERLAY_HORIZONTAL_WHITE_SPACE - 65;
    const headerHeight = headerTdGeometry.bottom - headerTdGeometry.y;
    const topHeight = resultsDivGeometry.y + headerHeight;
    const minBoxTop = topHeight + TOP_MARGIN + PADDING + BORDER;
    const maxBoxBottom = window.innerHeight - BOTTOM_MARGIN - PADDING - BORDER - 5;
    const maxHeight = maxBoxBottom - minBoxTop - BOTTOM_MARGIN - TOP_MARGIN - 2 * PADDING - 2 * BORDER;
    const resultsTableOffset = resultsDivGeometry.y - headerTdGeometry.y;
    const resultsDivOffset = resultsDivGeometry.y;
    return {
      maxWidth,
      maxHeight,
      imageTdSpanLeft: imageTdLeft,
      minBoxTop,
      maxBoxBottom,
      resultsTableOffset,
      resultsDivOffset
    };
  };

  private calculateOverlayBoxDimensions = ({ maxWidth, maxHeight }) => {
    const boxDisplayModeIsActive = resultsStore.overlayBoxFile.fovAndImagesData.boxImagesData ? true : false;
    const overlayDataImages = boxDisplayModeIsActive
      ? [...resultsStore.overlayBoxFile.fovAndImagesData.boxImagesData]
      : [...resultsStore.overlayBoxFile.fovAndImagesData.images];
    const idealWidthForList = (resultsStore.thumbWidth + 8) * overlayDataImages.length + 25;
    const idealWidthForBox = (resultsStore.thumbWidth + 8) * overlayDataImages.length + 25;
    const idealOverlayBoxWidth = boxDisplayModeIsActive ? idealWidthForBox : idealWidthForList;
    const overlayBoxWidth = Math.min(idealOverlayBoxWidth, maxWidth);
    const rowHeight = boxDisplayModeIsActive ? 3 * (66 + 5) : 66 + 5;
    const rowNumber = Math.ceil(idealOverlayBoxWidth / maxWidth);
    const idealOverlayBoxHeigth = rowNumber * rowHeight + 10;
    const overlayBoxHeight = Math.min(idealOverlayBoxHeigth, maxHeight);
    return { overlayBoxWidth, overlayBoxHeight };
  };

  private calculateOverlayBoxPosition = geometryParams => {
    // The box is never too wide so we just use the ideal formula for the horizontal position
    const overlayBoxLeft = 65 + geometryParams.maxWidth - geometryParams.overlayBoxWidth - OVERLAY_RIGHT_MARGIN;

    // This is not the case for the box top, since it should ideally be centered on the image TD. The size is
    // correct (clamped to max size), but it will sometimes have to be shifted down to prevent
    // going above the header, and somethmes shifted up to not go below the page.
    const imageTdHalfHeight = this.divTriggeringOverlayGeometry.height / 2;
    const overlayBoxHalfHeight = geometryParams.overlayBoxHeight / 2;
    let overlayBoxTop = this.divTriggeringOverlayGeometry.y + imageTdHalfHeight - overlayBoxHalfHeight;
    overlayBoxTop = Math.max(overlayBoxTop, geometryParams.minBoxTop);
    overlayBoxTop = Math.min(overlayBoxTop, geometryParams.maxBoxBottom - geometryParams.overlayBoxHeight);
    overlayBoxTop = overlayBoxTop - geometryParams.resultsDivOffset + geometryParams.resultsTableOffset - PADDING;
    return {
      overlayBoxLeft,
      overlayBoxTop
    };
  };

  private overlayBoxGeometry() {
    const overlayBoxConstraints = this.getBoxConstraints();
    const overlayBoxDimensions = this.calculateOverlayBoxDimensions(overlayBoxConstraints);
    const overlayBoxPosition = this.calculateOverlayBoxPosition({ ...overlayBoxDimensions, ...overlayBoxConstraints });
    return {
      top: overlayBoxPosition.overlayBoxTop,
      left: overlayBoxPosition.overlayBoxLeft,
      marginLeft: OVERLAY_LEFT_MARGIN,
      marginRight: OVERLAY_RIGHT_MARGIN,
      height: overlayBoxDimensions.overlayBoxHeight,
      width: overlayBoxDimensions.overlayBoxWidth,
      maxHeight: overlayBoxDimensions.overlayBoxHeight,
      maxWidth: overlayBoxDimensions.overlayBoxWidth
    } as CSSProperties;
  }

  private overlayBoxConnectorLineGeometry() {
    const resultsDiv = document.getElementById("resultsTable");
    const resultsDivGeometry = resultsDiv!.getBoundingClientRect();
    const headerTd = document.getElementById("resultsHeader");
    const headerTdGeometry = headerTd!.getBoundingClientRect();
    const resultsTableOffset = resultsDivGeometry.y - headerTdGeometry.y;
    const imageTdSpanTop = this.divTriggeringOverlayGeometry.top;
    const imageTdSpanHalfHeight = this.divTriggeringOverlayGeometry.height / 2;
    const resultsDivTop = resultsDivGeometry.top;
    const overlayBoxConnectorLineTop = imageTdSpanTop - resultsDivTop + resultsTableOffset + imageTdSpanHalfHeight - 10;
    const overlayBoxConnectorLineLeft = this.divTriggeringOverlayGeometry.x - resultsDivGeometry.x - 8;
    return { top: overlayBoxConnectorLineTop, left: overlayBoxConnectorLineLeft };
  }

  renderImage = (image, handleShowFullSizeImage) => (
    <ThumbImageInIconImagesOverlay
      file={resultsStore.overlayBoxFile}
      key={image.url}
      imageUrl={image.url}
      imageTitle={image.description}
      onClick={handleShowFullSizeImage}
    />
  );

  render() {
    const overlayDataImagePaths = [...resultsStore.overlayBoxFile.fovAndImagesData.images];
    const boxDisplayModeIsActive = resultsStore.overlayBoxFile.fovAndImagesData.boxImagesData ? true : false;

    const imagesAsList = overlayDataImagePaths.map(image =>
      this.renderImage(image, this.handleShowFullSizeImageOnThumbClick)
    );
    const imagesAsBoxes = boxDisplayModeIsActive
      ? resultsStore.overlayBoxFile.fovAndImagesData.boxImagesData.map(imagesInBox => {
          const images = imagesInBox.map(image => (
            <div key={uuidv4()} style={{ maxWidth: resultsStore.thumbWidth + 1 }}>
              {this.renderImage(image, this.handleShowFullSizeImageOnThumbClick)}
            </div>
          ));
          return (
            <div key={uuidv4()} className="imagesBox">
              {images}
            </div>
          );
        })
      : null;
    if (overlayDataImagePaths.length === 0) {
      return <Fragment />;
    }
    const overlayBoxGeometry = this.overlayBoxGeometry();
    const overlayBoxConnectorLineGeometry = this.overlayBoxConnectorLineGeometry();
    const overlayDataImages = boxDisplayModeIsActive ? imagesAsBoxes : imagesAsList;

    return (
      <div id={this.id}>
        <div style={overlayBoxGeometry} id="iconImagesOverlay">
          {overlayDataImages}
        </div>
        <div style={overlayBoxConnectorLineGeometry} className="iconImagesOverlayPointer">
          <img
            className="click-button close-icon"
            src={baseUrl() + "/icons/close.png"}
            alt="X"
            onClick={this.handleHideOverlay}
          />
        </div>
      </div>
    );
  }
}
export default IconImagesOverlay;
